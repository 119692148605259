import { Globals } from './../../globals';
import { AuthService } from 'src/app/API/auth.service';
import { DatePipe, DecimalPipe, Time } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { WPApiService } from 'src/app/API/wpapi.service';
@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2Table',
  templateUrl: 'BioPureHX2Table.component.html',
  styleUrls: ['BioPureHX2Table.component.css']
})

export class BioPureHX2TableComponent {
  title = 'BioPure HX2 Table View';

  constructor(
  private router: Router,
  private datePipe: DatePipe,
  private decimalPipe: DecimalPipe,
  private wpapi: WPApiService,
  private auth: AuthService,
  public dialog: MatDialog
) { }

RouteClick(value) {
  this.router.navigate([value]);
}

filterHX2List(col) {
  return Globals.lstHX2Data.filter((item, index) => item.col == col);
}

timer:any;
timerCheckDateTime:any;
interval:any;
timeLeft: number = 60;

communicationstatus = '';
DataFromMachine = '0';
dateDiff = '0';

LocalROTimeDiff = '0';

ngOnInit() {
  this.FetchAndSetValues();
  this.timer = setInterval(() => {
    this.FetchAndSetValues();
  }, 30000);

  // this.CheckServiceDateTime();
  this.timerCheckDateTime = setInterval(() => {
  this.CheckServiceDateTime();
  }, 100000);

if(Globals.lstHX2Data.find(c => c.id == 'LocalTime').value == '')
  this.startTimer();
}

startTimer() {
  this.interval = setInterval(() => {

    if(this.timeLeft > 0) {
      this.timeLeft--;
      this.communicationstatus = 'Retrieving Data (00:' + (this.decimalPipe.transform(this.timeLeft , '2.0')).toString() + ')' ;
    } else {
      clearInterval(this.interval);

      if(this.DataFromMachine == '0')
      this.communicationstatus = "Communication Error, There is some issue in HX2 to Local PC Communication.";
    else
      this.communicationstatus = "Communication Error, There is some issue in Fetch Service or Local PC to Server Communication.";

    this.RODate = '';
    }

  },1000)
}

stopTimer() {
  clearInterval(this.interval);
}

ngOnDestroy() {
  clearInterval(this.timer);
  clearInterval(this.timerCheckDateTime);
  clearInterval(this.interval);
}

lstHX2LatestData : any;

FetchAndSetValues(){

  this.wpapi.FetchHX2Data().subscribe(
    success => {

      this.lstHX2LatestData = [];
      this.lstHX2LatestData = success;
      success.forEach(element => {
        //
        const HX2Data =  Globals.lstHX2Data.find(c=>c.id==element.fieldName);
        if(HX2Data!=undefined) {
          var format ='';
          var FieldValue = '';

          if(HX2Data.format!=''){
            //
            format=HX2Data.format;
            FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;

            if(format.length>1)
              FieldValue = (this.decimalPipe.transform(parseFloat(FieldValue) , format)).toString();
            else
              FieldValue = (parseInt(FieldValue)).toString();
//
              if(FieldValue.indexOf('-') == 0){
                FieldValue =FieldValue.replace(/^[-]*0+/, '');
                if(FieldValue.indexOf('.')==0)
                    FieldValue='-0'+FieldValue;
              } else {

              if(FieldValue != '0'){
                  FieldValue =FieldValue.replace(/^0+/, '');
                  if(FieldValue.indexOf('.')==0)
                      FieldValue='0'+FieldValue;

                } else if(FieldValue == '0' && format.length>1) {
                  FieldValue = '0.0';
                }
              }
          } else {
            FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
          }
          if(HX2Data.id == '{[HX2]STOP_ALARMS_DISABLED}'){
            Globals.lstHX2Data.find(c => c.id == element.fieldName && c.col == 1).value = FieldValue;
            Globals.lstHX2Data.find(c => c.id == element.fieldName && c.col == 2).value = FieldValue;

          } else {
            Globals.lstHX2Data.find(c=> c.id == element.fieldName).value = FieldValue;
          }
          //  this.lstHX2Data.find(c=>c.id==element.fieldName).value = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
          // this.lstHX2Data.find(c=>c.id==element.fieldName).value = success.find(c=>c.fieldName==element.fieldName).fieldValue;

        }
        //
        console.log(Globals.lstHX2Data);
      });

      //
      var PI3605_Outlet = Globals.lstHX2Data.find(c => c.id == '{[HX2]PI3605.Val}').value;
      var PI3600_Inlet = Globals.lstHX2Data.find(c => c.id == '{[HX2]PI3600.Val}').value;

      var temp_CPI9850_Delta = parseFloat(PI3600_Inlet) - parseFloat(PI3605_Outlet);

      if(temp_CPI9850_Delta<0)
        temp_CPI9850_Delta = (temp_CPI9850_Delta) * -1;

      Globals.lstHX2Data.find(c => c.id == '{[HX2]CPI9850.Val}').value =
        (this.decimalPipe.transform(temp_CPI9850_Delta , '1.1-1')).toString();

      //
      var PI3715_Outlet = Globals.lstHX2Data.find(c => c.id == '{[HX2]PI3715.Val}').value;
      var PI3705_Inlet = Globals.lstHX2Data.find(c => c.id == '{[HX2]PI3705.Val}').value;

      var temp_CPI9852_Delta = parseFloat(PI3705_Inlet) - parseFloat(PI3715_Outlet);

      if(temp_CPI9852_Delta<0)
        temp_CPI9852_Delta = (temp_CPI9852_Delta) * -1;

      Globals.lstHX2Data.find(c => c.id == '{[HX2]CPI9852.Val}').value =
        (this.decimalPipe.transform(temp_CPI9852_Delta , '1.1-1')).toString();

      Globals.lstHX2Data.find(c => c.id == 'LocalPCDate').value =
      this.datePipe.transform(success.find(c=>c.fieldName=='LocalTime').createdOn, 'MM/dd/yyyy');

      Globals.lstHX2Data.find(c => c.id == 'LocalPCTime').value =
      this.datePipe.transform(success.find(c=>c.fieldName=='LocalTime').createdOn, 'HH:mm:ss');

      this.DataFromMachine = Globals.lstHX2Data.find(c => c.id == 'DataFromMachine').value;
      this.dateDiff = Globals.lstHX2Data.find(c=> c.id == 'DateDiff').value;

      this.LocalROTimeDiff = this.lstHX2LatestData.find(c=> c.fieldName == 'LocalTime').localROTimeDiff;

      if(parseInt(this.dateDiff) > 300 || this.DataFromMachine == '0')
      {
        this.stopTimer();
        this.IsLocalTimeRed = true;
        if(this.DataFromMachine == '0')
          this.communicationstatus = "Communication Error, There is some issue in HX2 to Local PC Communication.";
        else
          this.communicationstatus = "Communication Error, There is some issue in Fetch Service or Local PC to Server Communication.";

      }
      else {
        this.stopTimer();
        this.communicationstatus = '';
        this.IsLocalTimeRed = false;
      }

      if(this.RODate == "" || this.RODate == undefined)
        this.CheckServiceDateTime();
    },
    error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    }
  );
  }

  CheckServiceDateTime(){
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {

    this.today = new Date(currentDate);
    });

    let FetchedDate = Globals.lstHX2Data.find(c=>c.id=='LocalDate').value;
    let FetchedTime = Globals.lstHX2Data.find(c=>c.id=='LocalTime').value;

    this.dateArray = FetchedDate.split("/");
    this.timeArray = FetchedTime.split(":");

    if(this.dateArray.length > 1){
      let dateTime =this.dateArray[2] + "-" + this.dateArray[0] + "-" + this.dateArray[1];
      this.LastFetchedRODateTime = new Date(dateTime).toUTCString();

      if((FetchedDate==this.RODate && FetchedTime == this.ROTime) || this.IsLocalTimeRed || parseInt(this.LocalROTimeDiff)>5){
        this.IsTimeRed = true;
      } else {
        this.IsTimeRed = false;
      }
    }
    if(FetchedDate != ''){
      if(this.datePipe.transform(this.today, 'MM/dd/yyyy') != FetchedDate)
        this.IsDateRed = true;
      else
        this.IsDateRed = false;
    }

    if(this.IsTimeRed) {
      this.stopTimer();

      if(this.DataFromMachine == '0')
        this.communicationstatus = "Communication Error, There is some issue in HX2 to Local PC Communication.";
      else
        this.communicationstatus = "Communication Error, There is some issue in Fetch Service or Local PC to Server Communication.";

    } else {
      this.communicationstatus = '';
    }

    this.RODate = FetchedDate;
    this.ROTime = FetchedTime;

    // let dateTime =this.dateArray[2] + "-" + this.dateArray[0] + "-" + this.dateArray[1]+'T'+this.timeArray[0] + ":" + this.timeArray[1] + ":" + this.timeArray[2];

  }

RODate : any;
ROTime : any;
dateArray : any;
timeArray : any;
LastFetchedRODateTime : any;
IsTimeRed = false;
IsDateRed = false;

IsLocalTimeRed = false;
IsLocalDateRed = false;
private today = new Date();
}

<div style='width:100%;margin: 10px 0px;'>
    <div style='width:10%;float:left'>{{Title}}</div>
    <div style='width:90%;float:left;height:15px;position:relative;margin-top:3px'>
        <div
            style="height: 100%"
            [style.width.%]="Value * (100/TotalValue)"
            [style.background-color]="BarColor"
        ></div><div style="position: absolute;padding-left:5px;top:-2px;font-weight: bold" [style.left.%]="(Value * (100/TotalValue))" title="Total Task: {{Value}}">{{Value}}</div>
        <div style="position: absolute;width:5px;height:100%;top:0px;background-color:#000" title="Completed Task: {{MarkedValue}}" [style.left.%]="(MarkedValue * (100/TotalValue))"></div>
    </div>
</div>
<div class="clearfix"></div>

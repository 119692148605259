<div [formGroup]="otherForm" novalidate *ngIf="otherForm">
  <div class="modal-header  col-sm-12" >
    <h4 class="modal-title col-sm-8">Other</h4>
    <div class="text-right  col-sm-4" style="padding:0">
      <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(true)">Close</button>
      <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'" (click)="saveOtherNote()">Save</button>
    </div>
  </div>
  <div class="modal-body">
      <div class="box-row col-sm-12" style="padding-top:20px;">
          <label class="col-sm-1 control-label">Note:</label>
          <div class="col-sm-10">
            <textarea autosize class="form-textarea" *ngIf="IsPurposeCompleted != 'Yes'" formControlName="Issue"
            style="min-height: 75px;"></textarea>
            <span
              *ngIf="of.Issue.errors?.required && (of.Issue.dirty || of.Issue.touched || formSubmitted)"
              [ngClass]="'error'">Required!</span>
          </div>
          <div class="col-sm-10" style="width: 75.7%;word-break: break-word;" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText">{{of.Issue.value}}</label>
          </div>
      </div>
  </div>
</div>

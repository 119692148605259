import { Component, OnInit, ViewChild, Optional, Output, Input, EventEmitter } from '@angular/core';
import { DMApiService } from 'src/app/API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';
import { WPApiService } from 'src/app/API/wpapi.service';
import { AppComponent } from 'src/app/app.component';
import { WaterProcessCustomValidators } from 'src/app/Validator/WaterProcessCustomValidators';
import { debug } from 'util';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'WPDailySatelliteDetail',
  templateUrl: './WPDailySatelliteDetail.component.html'
})
export class WPDailySatelliteDetailComponent implements OnInit, CanComponentDeactivate {
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @Input() divisionShortText = '';
  @Input() division = 0;

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  Status='';
  noteType = '';
  currentUserId = this.auth.GetUserId();
  currentUserName = this.auth.GetUserName();

  IsDisinfectSecondaryRODisplayed = true;
  IsFirstDisinfectionCompleted = false;
  IsSecondDisinfectionCompleted = false;
  DisinfectionStatus = '';
  isValidFormSubmitted = true;
  ROVerificationStatus = '';
  displayRemainingFields = false;
  displayRORemainingFields = false;

  AMUserName = '';
  MIDUserName = '';
  PMUserName = '';
  WeeklyUserName = '';
  UserName = '';
  DisinfectionUserName1 = '';
  DisinfectionUserName2 = '';
  RecordDate = '';
  MainDUName = '';
  DUName = '';
  IsWeeklyDisplayed = true;

  public today = new Date();

  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;

  collapseTwoAMClass = 'collapsed';
  collapseTwoAMariaexpanded = true;
  collapseTwoAMpanelClass = 'panel-collapse collapse';

  collapseTwoMidClass = 'collapsed';
  collapseTwoMidariaexpanded = false;
  collapseTwoMidpanelClass = 'panel-collapse collapse';

  collapseTwoPMClass = 'collapsed';
  collapseTwoPMariaexpanded = false;
  collapseTwoPMpanelClass = 'panel-collapse collapse';

  collapseTwoWeeklyClass = 'collapsed';
  collapseTwoWeeklyariaexpanded = false;
  collapseTwoWeeklypanelClass = 'panel-collapse collapse';

  tempServerTodayDate = new Date();

  VerifyROlable = '';
  VerifyROlablewithoutOff = '';
  VerifyingCWWPROText = '';

  VerifyROPresentMAIN3E = [
    { value: "", text: "Select" },
    { value: "A", text: "RO-A" },
    { value: "B", text: "RO-B" },
  ];


  // @ViewChild('weeklySoftenerNotes') weeklySoftenerNotes: NotesComponent;
  @ViewChild('weeklyROVerificationNotes', { static: true }) weeklyROVerificationNotes: NotesComponent;
  @ViewChild('weeklyDisinfectionNotes', { static: false }) weeklyDisinfectionNotes: NotesComponent;
  @ViewChild('amNotes', { static: true }) amNotes: NotesComponent;
  @ViewChild('pmNotes', { static: true }) pmNotes: NotesComponent;
  @ViewChild('midNotes', { static: true }) midNotes: NotesComponent;

  ngOnInit() {
    this.setValidations('ngOnInit', '');
    this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
      this.today = this.tempServerTodayDate = new Date(list);
      // this.f.TestDisinfectionDate.setValue({
      //   date: {
      //     year: tempFormDate.getFullYear(),
      //     month: tempFormDate.getMonth() + 1,
      //     day: tempFormDate.getDate()
      //   },
      //   jsdate: tempFormDate
      // });
    });


    this.PresentlyInUseChanged();

    this.f.selectedDialysisUnits.setValue(this.division);
    this.f.selectedMainDivision.setValue(this.divisionShortText);

    var test = this.route.queryParams.subscribe(params => {
      this.f.GRWaterprocessDailyrecordsID.setValue(params['id'] || '0');

      if (this.f.GRWaterprocessDailyrecordsID.value != "0") {
        setTimeout(() => {
          this.setModel();
        }, 200);
      }
      else {
        this.HideShowPanel();
      }
    });
  }

  ngAfterViewInit() {
    this.clearNotes();

    //attachment changes
    if (this.attachment != undefined) {
      this.attachment.DocId = this.f.GRWaterProcessDailySatelliteDetailId.value;

      this.attachment.moduleName = 'DailySatellite';
      this.attachment.UploadBy = this.currentUserId;
    }
  }


  constructor(private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private wpapi: WPApiService,
    private datePipe: DatePipe,
    private formBuilder: UntypedFormBuilder) { }


  WPDailySatelliteDetailForm = new UntypedFormGroup({
    GRWaterProcessDailySatelliteDetailId: new UntypedFormControl(''),
    GRWaterprocessDailyrecordsID: new UntypedFormControl(''),
    AMChlorineTestLeftBank: new UntypedFormControl(''),
    AMChlorineTestRightBank: new UntypedFormControl(''),
    MIDChlorineTestLeftBank: new UntypedFormControl(''),
    MIDChlorineTestRightBank: new UntypedFormControl(''),
    PMChlorineTestLeftBank: new UntypedFormControl(''),
    PMChlorineTestRightBank: new UntypedFormControl(''),
    HardnessPreSoft: new UntypedFormControl(''),
    HardnessPostSoftenerS2: new UntypedFormControl(''),
    HardnessPostSoftenerS3: new UntypedFormControl(''),
    PreSoftnerPressure: new UntypedFormControl(''),
    PostSoftnerPressure: new UntypedFormControl(''),
    SaltLevelVerifications: new UntypedFormControl(''),
    ROPreSoftnerFilterPressure: new UntypedFormControl(''),
    ROPostSoftnerFilterPressure: new UntypedFormControl(''),
    ROAFeedConductivity: new UntypedFormControl(''),
    ROBFeedConductivity: new UntypedFormControl(''),
    ROAProductConductivity: new UntypedFormControl(''),
    ROBProductConductivity: new UntypedFormControl(''),
    ROARecoveryRate: new UntypedFormControl(''),
    ROBRecoveryRate: new UntypedFormControl(''),
    ROARejectionRate: new UntypedFormControl(''),
    ROBRejectionRate: new UntypedFormControl(''),
    ROAAlarmHistory: new UntypedFormControl(''),
    ROBAlarmHistory: new UntypedFormControl(''),
    LoopReturnTemp: new UntypedFormControl(''),
    DialoxLevels: new UntypedFormControl(''),
    CWPRODisinfected: new UntypedFormControl(''),
    CWPRODisinfected2: new UntypedFormControl(''),
    DisinfectionDate: new UntypedFormControl(''),
    ResidualTestResult: new UntypedFormControl(''),
    ResidualTestResultsec: new UntypedFormControl(''),
    ChemicalDisinfectionUser: new UntypedFormControl(''),
    DisinfectSecondaryRO: new UntypedFormControl(''),
    DisinfectionDateSec: new UntypedFormControl(''),
    ChemicalDisinfectionUserSec: new UntypedFormControl(''),
    WeeklyROVerificationDetailsEnteredOn: new UntypedFormControl(''),
    AMDetailsEnteredBy: new UntypedFormControl(''),
    AMDetailsEnteredOn: new UntypedFormControl(''),
    PMDetailsEnteredBy: new UntypedFormControl(''),
    PMDetailsEnteredOn: new UntypedFormControl(''),
    MidDetailsEnteredBy: new UntypedFormControl(''),
    MidDetailsEnteredOn: new UntypedFormControl(''),
    WeeklyDetailsEnteredBy: new UntypedFormControl(''),
    WeeklyDetailsEnteredOn: new UntypedFormControl(''),
    formDate: new UntypedFormControl(''),
    eventId: new UntypedFormControl(''),
    selectedMainDivision: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl(''),
    Status: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl(''),
    ModifyDate: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    IsDelete: new UntypedFormControl(''),
    TestDisinfectionDate: new UntypedFormControl(''),
    ROPresentlyInUse: new UntypedFormControl(''),

    /* New Fields */
    BoosterPumpP4 : new UntypedFormControl(''),
    PreCarbonFilter : new UntypedFormControl(''),
    PreROFilter : new UntypedFormControl(''),
    PreCarbonFilterDelta : new UntypedFormControl(''),
    PreROFilterDelta : new UntypedFormControl(''),
    pHPreCarbon : new UntypedFormControl(''),
    pHPostCarbon : new UntypedFormControl(''),
    IronPostSoftener : new UntypedFormControl(''),
    VerifyTestingChemicalLevels : new UntypedFormControl(''),
    TempTank : new UntypedFormControl(''),
    selectedVerifyROPresently : new UntypedFormControl(''),
    VerifyROReverse: new UntypedFormControl(''),
    LoopReturnTempB: new UntypedFormControl(''),
    ROHours: new UntypedFormControl(''),
    RODisinfected: new UntypedFormControl('')
  });



  get f() {
    return this.WPDailySatelliteDetailForm.controls;
  }

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.WPDailySatelliteDetailForm.dirty ||
        this.notes.Note != '' ||
        this.attachment.Queue().length > 0)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else return true;
  }


  executeGRWPSatelliteCreateUpdate = function (Source = '', type = '') {
    this.isValidFormSubmitted = false;
    this.displayRemainingFields = false;



    if(Source !== 'Notes') {
      this.setValidations(Source, type);
    }

    if (type === "Weekly" && Source == 'Disinfection') {
      if (this.f.ResidualTestResult.value === 'Positive') {
        AppComponent.addAlert('This weekly section of the daily water form cannot be saved while there is a “Positive” Chemical Residual Test Result, continue to test for chemical residual until result is Negative', 'error');
        return;
      }
      if (this.f.DisinfectSecondaryRO.value === "Yes") {
        if (this.f.ResidualTestResultsec.value === 'Positive') {
          AppComponent.addAlert('This weekly section of the daily water form cannot be saved while there is a “Positive” Chemical Residual Test Result Secondary, continue to test for chemical residual until result is Negative', 'error');
          return;
        }
      }

    }



    // if(Source !== 'Notes'){
    AppComponent.loading = true;

    if (this.WPDailySatelliteDetailForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      if(Source == 'ngOnInit'){
        this.setValidations('ngOnInit', '');
      }
      AppComponent.loading = false;
      window.scrollBy(0, 500);
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      Object.keys(this.WPDailySatelliteDetailForm.controls).forEach(key => {
      });
      return;
    }
  // }
    this.isValidFormSubmitted = true;

    var GRWPSatellite = this.prepareCreateViewModelGRWPSatelite(Source, type);
    if (GRWPSatellite.GRWaterprocessDailyrecordsID == '0') {

      this.wpapi.CreateGRWaterProcessDailySatelliteDetail(GRWPSatellite).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          // if (Source != 'Notes') {
          //   this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          // } else {
          //   this.f.GRWaterprocessDailyrecordsID.setValue(success.success.gRwaterprocessdailyrecordsID);
          //   this.f.GRWaterProcessDailySatelliteDetailId.setValue(success.success.GRWaterProcessDailySatelliteDetailId);
          //   this.setModel();
          // }

          if(Source != 'Softener' && Source != 'ROVerification'){
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          }

          this.setModel();
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.wpapi.UpdateGRWaterProcessDailySatelliteDetail(GRWPSatellite).subscribe(
        success => {

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source != 'Notes') {
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          } else {

            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  }

  PresentlyInUseChanged(){
    if(this.f.ROPresentlyInUse.value == "RO-A"){
      this.f.CWPRODisinfected.setValue('RO-B');
      this.f.CWPRODisinfected2.setValue('RO-A');
    }
    else{
      this.f.CWPRODisinfected.setValue('RO-A');
      this.f.CWPRODisinfected2.setValue('RO-B');
    }
  }


  saveAndValidate(Source = '', type = '') {
    this.displayRemainingFields = false;
    this.displayRORemainingFields = false;

    if (Source == "Softener" && (
      (!this.f.HardnessPreSoft.value || this.f.HardnessPreSoft.errors ||
        !this.f.HardnessPostSoftenerS2.value || this.f.HardnessPostSoftenerS2.errors ||
        !this.f.HardnessPostSoftenerS3.value || this.f.HardnessPostSoftenerS3.errors ||
        !this.f.PreSoftnerPressure.value || this.f.PreSoftnerPressure.errors ||
        !this.f.PostSoftnerPressure.value || this.f.PostSoftnerPressure.errors ||
        !this.f.SaltLevelVerifications.value || this.f.SaltLevelVerifications.errors
      ))) {

      this.matDialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.matDialogRef.componentInstance.confirmMessage =
        'Some fields is missing! Do you want to Save?.';
      this.matDialogRef.afterClosed().subscribe(result => {

        if (result)
          this.executeGRWPSatelliteCreateUpdate(Source, type);
        else
          this.displayRemainingFields = true;

        return;
      });
    }
    else if (Source != "Softener" && (!this.f.HardnessPreSoft.value || this.f.HardnessPreSoft.errors ||
      !this.f.ROPresentlyInUse.value || this.f.ROPresentlyInUse.errors ||
      !this.f.HardnessPostSoftenerS2.value || this.f.HardnessPostSoftenerS2.errors ||
      !this.f.HardnessPostSoftenerS3.value || this.f.HardnessPostSoftenerS3.errors ||
      !this.f.PostSoftnerPressure.value || this.f.PostSoftnerPressure.errors ||
      !this.f.SaltLevelVerifications.value || this.f.SaltLevelVerifications.errors ||
      !this.f.ROPreSoftnerFilterPressure.value || this.f.ROPreSoftnerFilterPressure.errors ||
      (this.f.selectedVerifyROPresently.value == "A" && (!this.f.ROAProductConductivity.value || this.f.ROAProductConductivity.errors ||
      !this.f.ROAFeedConductivity.value || this.f.ROAFeedConductivity.errors ||
      !this.f.ROARecoveryRate.value || this.f.ROARecoveryRate.errors ||
      !this.f.ROARejectionRate.value || this.f.ROARejectionRate.errors)) ||
      (this.f.selectedVerifyROPresently.value == 'B' && (!this.f.ROBProductConductivity.value || this.f.ROBProductConductivity.errors ||
      !this.f.ROBFeedConductivity.value || this.f.ROBFeedConductivity.errors ||
      !this.f.ROBRecoveryRate.value || this.f.ROBRecoveryRate.errors ||
      !this.f.ROBRejectionRate.value || this.f.ROBRejectionRate.errors)) ||
      !this.f.LoopReturnTemp.value || this.f.LoopReturnTemp.errors ||
      !this.f.ROAAlarmHistory.value || this.f.ROAAlarmHistory.errors ||
      !this.f.ROBAlarmHistory.value || this.f.ROBAlarmHistory.errors ||
      !this.f.DialoxLevels.value || this.f.DialoxLevels.errors)
    ) {


      this.executeGRWPSatelliteCreateUpdate(Source, type);

      // this.matDialogRef = this.dialog.open(ConfirmationDialog, {
      //   disableClose: true
      // });
      // this.matDialogRef.componentInstance.confirmMessage =
      //   'Some fields is missing! Do you want to Save?.';
      // this.matDialogRef.afterClosed().subscribe(result => {
      //
      //   if (result){
      //     this.executeGRWPSatelliteCreateUpdate(Source, type);
      //   }
      //   else{
      //     this.displayRemainingFields = true;
      //     this.displayRORemainingFields = true;
      //   }
      //   return;
      // });

    }
    else {
      this.executeGRWPSatelliteCreateUpdate(Source, type);
    }
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void { }

  mainSave() {
    return new Promise((resolve, reject) => {
      this.executeGRWPSatelliteCreateUpdate();
      resolve(true);
    });
  }

  setValidations(Source, type) {
    this.clearValidations();

    if (Source == '') {
      if (type == 'AM' || type == '') {
        this.f.AMChlorineTestLeftBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.AMChlorineTestRightBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.PMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.PMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.MIDChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.MIDChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
      }
      else if (type == 'PM') {
        this.f.PMChlorineTestLeftBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.PMChlorineTestRightBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);

        this.f.AMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.AMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.MIDChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.MIDChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
      }
      else if (type == 'MID') {
        this.f.MIDChlorineTestLeftBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.MIDChlorineTestRightBank.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);

        this.f.AMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.AMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.PMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
        this.f.PMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
      }
    }

    else if (Source == 'Softener') {

      this.setMainValidations();
      this.setROVerifications();
    }
    else if (Source == 'ROVerification') {
      this.setROVerifications();
      this.setMainValidations();
    }
    else if (Source == 'Disinfection') {
      this.f.ResidualTestResult.setValidators([Validators.required]);
      this.f.DisinfectSecondaryRO.setValidators([Validators.required]);

      if (this.f.DisinfectSecondaryRO.value == 'Yes') {
        this.f.ResidualTestResultsec.setValidators([Validators.required]);
      }

      this.setMainValidations();
    }
    else if (Source == 'ngOnInit') {

      this.setMainValidations();
      this.f.ROAProductConductivity.setValidators([Validators.pattern(Globals.NumberRegexThreePrecisionThreeFraction)]);
      this.f.ROBProductConductivity.setValidators([Validators.pattern(Globals.NumberRegexThreePrecisionThreeFraction)]);
      this.f.ROARecoveryRate.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROBRecoveryRate.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROARejectionRate.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROBRejectionRate.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.LoopReturnTemp.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
    }


    this.updateValidations();
  }

  setROVerifications(isManual=true) {

    let elm = document.getElementById("weeklyPanel");
    if ((elm.className == 'collapsed' && this.f.WeeklyDetailsEnteredBy.value == null) || isManual == true) {
    this.f.HardnessPreSoft.setValidators([Validators.required]);
    this.f.HardnessPreSoft.updateValueAndValidity();

    this.f.HardnessPostSoftenerS2.setValidators([Validators.required]);
    this.f.HardnessPostSoftenerS2.updateValueAndValidity();

    this.f.HardnessPostSoftenerS3.setValidators([Validators.required]);
    this.f.HardnessPostSoftenerS3.updateValueAndValidity();

    this.f.SaltLevelVerifications.setValidators([Validators.required]);
    this.f.SaltLevelVerifications.updateValueAndValidity();

    this.f.PostSoftnerPressure.setValidators([Validators.required]);
    this.f.PostSoftnerPressure.updateValueAndValidity();

    this.f.DialoxLevels.setValidators([Validators.required]);
    this.f.DialoxLevels.updateValueAndValidity();

    this.f.ROAAlarmHistory.setValidators([Validators.required]);
    this.f.ROAAlarmHistory.updateValueAndValidity();

    this.f.ROBAlarmHistory.setValidators([Validators.required]);
    this.f.ROBAlarmHistory.updateValueAndValidity();

    this.f.BoosterPumpP4.setValidators([Validators.required]);
    this.f.BoosterPumpP4.updateValueAndValidity();

    this.f.PreCarbonFilter.setValidators([Validators.required]);
    this.f.PreCarbonFilter.updateValueAndValidity();

    this.f.PreROFilter.setValidators([Validators.required]);
    this.f.PreROFilter.updateValueAndValidity();

    this.f.PreCarbonFilterDelta.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
    this.f.PreCarbonFilterDelta.updateValueAndValidity();

    this.f.PreROFilterDelta.setValidators([Validators.required, , Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
    this.f.PreROFilterDelta.updateValueAndValidity();

    this.f.ROHours.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);
    this.f.ROHours.updateValueAndValidity();

    this.f.RODisinfected.setValidators(Validators.required);
    this.f.RODisinfected.updateValueAndValidity();

    if(this.f.selectedVerifyROPresently.value == 'B')
    {
      this.f.ROBFeedConductivity.setValidators([Validators.required]);
      this.f.ROBFeedConductivity.updateValueAndValidity();

      this.f.ROBProductConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexThreePrecisionThreeFraction)]);
      this.f.ROBProductConductivity.updateValueAndValidity();

      this.f.ROBRecoveryRate.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROBRecoveryRate.updateValueAndValidity();

      this.f.ROBRejectionRate.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROBRejectionRate.updateValueAndValidity();

      this.f.LoopReturnTempB.setValidators(Validators.required);
      this.f.LoopReturnTempB.updateValueAndValidity();

    }


    if(this.f.selectedVerifyROPresently.value =='A')
    {
      this.f.ROARejectionRate.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROARejectionRate.updateValueAndValidity();

      this.f.ROARecoveryRate.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ROARecoveryRate.updateValueAndValidity();

      this.f.ROAProductConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexThreePrecisionThreeFraction)]);
      this.f.ROAProductConductivity.updateValueAndValidity();

      this.f.ROAFeedConductivity.setValidators([Validators.required]);
      this.f.ROAFeedConductivity.updateValueAndValidity();

      this.f.TempTank.setValidators([Validators.required]);
      this.f.TempTank.updateValueAndValidity();

      this.f.LoopReturnTemp.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.LoopReturnTemp.updateValueAndValidity();

    }


    this.f.pHPreCarbon.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
    this.f.pHPreCarbon.updateValueAndValidity();

    this.f.pHPostCarbon.setValidators([Validators.required,Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
    this.f.pHPostCarbon.updateValueAndValidity();

    this.f.IronPostSoftener.setValidators([Validators.required]);
    this.f.IronPostSoftener.updateValueAndValidity();

    this.f.VerifyTestingChemicalLevels.setValidators([Validators.required]);
    this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

    this.f.selectedVerifyROPresently.setValidators([Validators.required]);
    this.f.selectedVerifyROPresently.updateValueAndValidity();

    this.f.VerifyROReverse.setValidators([Validators.required]);
    this.f.VerifyROReverse.updateValueAndValidity();

    if(!(this.f.HardnessPreSoft.value &&
      this.f.HardnessPostSoftenerS2.value &&
      this.f.HardnessPostSoftenerS3.value &&
      this.f.SaltLevelVerifications.value &&
      this.f.PostSoftnerPressure.value &&
      this.f.DialoxLevels.value &&
      this.f.ROAAlarmHistory.value &&
      this.f.ROBAlarmHistory.value &&
      this.f.BoosterPumpP4.value &&
      this.f.PreCarbonFilter.value &&
      this.f.PreROFilter.value &&
      this.f.PreCarbonFilterDelta.value &&
      this.f.PreROFilterDelta.value &&
      this.f.PreROFilterDelta.value &&
      this.f.selectedVerifyROPresently.value &&
      this.f.ROHours.value &&
      this.f.RODisinfected.value &&
      ((this.f.selectedVerifyROPresently.value == 'B' &&
      this.f.ROBFeedConductivity.value &&
      this.f.ROBProductConductivity.value &&
      this.f.ROBRecoveryRate.value &&
      this.f.ROBRejectionRate.value &&
      this.f.LoopReturnTempB.value) ||
      (this.f.selectedVerifyROPresently.value == 'A' &&
      this.f.ROARejectionRate.value &&
      this.f.ROARejectionRate.value &&
      this.f.ROARecoveryRate.value &&
      this.f.ROAProductConductivity.value &&
      this.f.ROAFeedConductivity.value &&
      this.f.TempTank.value &&
      this.f.LoopReturnTemp.value)) &&
      this.f.pHPreCarbon.value &&
      this.f.pHPostCarbon.value &&
      this.f.IronPostSoftener.value &&
      this.f.VerifyTestingChemicalLevels.value &&
      this.f.selectedVerifyROPresently.value &&
      this.f.VerifyROReverse.value)) {
      this.WPDailySatelliteDetailForm.setErrors({ 'invalid': true });
      return true;
    }

  } else {
    this.f.HardnessPreSoft.clearValidators();
    this.f.HardnessPreSoft.updateValueAndValidity();

    this.f.HardnessPostSoftenerS2.clearValidators();
    this.f.HardnessPostSoftenerS2.updateValueAndValidity();

    this.f.HardnessPostSoftenerS3.clearValidators();
    this.f.HardnessPostSoftenerS3.updateValueAndValidity();

    this.f.SaltLevelVerifications.clearValidators();
    this.f.SaltLevelVerifications.updateValueAndValidity();

    this.f.PostSoftnerPressure.clearValidators();
    this.f.PostSoftnerPressure.updateValueAndValidity();

    this.f.DialoxLevels.clearValidators();
    this.f.DialoxLevels.updateValueAndValidity();

    this.f.ROAAlarmHistory.clearValidators();
    this.f.ROAAlarmHistory.updateValueAndValidity();

    this.f.ROBAlarmHistory.clearValidators();
    this.f.ROBAlarmHistory.updateValueAndValidity();

    this.f.BoosterPumpP4.clearValidators();
    this.f.BoosterPumpP4.updateValueAndValidity();

    this.f.PreCarbonFilter.clearValidators();
    this.f.PreCarbonFilter.updateValueAndValidity();

    this.f.PreROFilter.clearValidators();
    this.f.PreROFilter.updateValueAndValidity();

    this.f.PreCarbonFilterDelta.clearValidators();
    this.f.PreCarbonFilterDelta.updateValueAndValidity();

    this.f.PreROFilterDelta.clearValidators();
    this.f.PreROFilterDelta.updateValueAndValidity();

    this.f.ROHours.clearValidators();
    this.f.ROHours.updateValueAndValidity();

    this.f.RODisinfected.clearValidators();
    this.f.RODisinfected.updateValueAndValidity();

      this.f.ROBFeedConductivity.clearValidators();
      this.f.ROBFeedConductivity.updateValueAndValidity();

      this.f.ROBProductConductivity.clearValidators();
      this.f.ROBProductConductivity.updateValueAndValidity();

      this.f.ROBRecoveryRate.clearValidators();
      this.f.ROBRecoveryRate.updateValueAndValidity();

      this.f.ROBRejectionRate.clearValidators();
      this.f.ROBRejectionRate.updateValueAndValidity();

      this.f.LoopReturnTempB.clearValidators();
      this.f.LoopReturnTempB.updateValueAndValidity();

      this.f.ROARejectionRate.clearValidators();
      this.f.ROARejectionRate.updateValueAndValidity();

      this.f.ROARecoveryRate.clearValidators();
      this.f.ROARecoveryRate.updateValueAndValidity();

      this.f.ROAProductConductivity.clearValidators();
      this.f.ROAProductConductivity.updateValueAndValidity();

      this.f.ROAFeedConductivity.clearValidators();
      this.f.ROAFeedConductivity.updateValueAndValidity();

      this.f.TempTank.clearValidators();
      this.f.TempTank.updateValueAndValidity();

      this.f.LoopReturnTemp.clearValidators();
      this.f.LoopReturnTemp.updateValueAndValidity();


    this.f.pHPreCarbon.clearValidators();
    this.f.pHPreCarbon.updateValueAndValidity();

    this.f.pHPostCarbon.clearValidators();
    this.f.pHPostCarbon.updateValueAndValidity();

    this.f.IronPostSoftener.clearValidators();
    this.f.IronPostSoftener.updateValueAndValidity();

    this.f.VerifyTestingChemicalLevels.clearValidators();
    this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

    this.f.selectedVerifyROPresently.clearValidators();
    this.f.selectedVerifyROPresently.updateValueAndValidity();

    this.f.VerifyROReverse.clearValidators();
    this.f.VerifyROReverse.updateValueAndValidity();
  }
  }


  setMainValidations() {
    this.f.AMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
    this.f.AMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
    this.f.PMChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
    this.f.PMChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
    this.f.MIDChlorineTestLeftBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
    this.f.MIDChlorineTestRightBank.setValidators([Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
  }

  clearValidations() {
    this.f.AMChlorineTestLeftBank.clearValidators();
    this.f.AMChlorineTestRightBank.clearValidators();
    this.f.PMChlorineTestLeftBank.clearValidators();
    this.f.PMChlorineTestRightBank.clearValidators();
    this.f.MIDChlorineTestLeftBank.clearValidators();
    this.f.MIDChlorineTestRightBank.clearValidators();

    this.f.HardnessPreSoft.clearValidators();
    this.f.HardnessPostSoftenerS2.clearValidators();
    this.f.HardnessPostSoftenerS3.clearValidators();
    this.f.PreSoftnerPressure.clearValidators();
    this.f.PostSoftnerPressure.clearValidators();
    this.f.SaltLevelVerifications.clearValidators();

    this.f.ROPreSoftnerFilterPressure.clearValidators();
    this.f.ROPostSoftnerFilterPressure.clearValidators();
    this.f.ROAFeedConductivity.clearValidators();
    this.f.ROBFeedConductivity.clearValidators();
    this.f.ROAProductConductivity.clearValidators();
    this.f.ROBProductConductivity.clearValidators();
    this.f.ROARecoveryRate.clearValidators();
    this.f.ROBRecoveryRate.clearValidators();
    this.f.ROARejectionRate.clearValidators();
    this.f.ROBRejectionRate.clearValidators();
    this.f.ROAAlarmHistory.clearValidators();
    this.f.ROBAlarmHistory.clearValidators();
    this.f.LoopReturnTemp.clearValidators();
    this.f.DialoxLevels.clearValidators();

    this.f.BoosterPumpP4.clearValidators();
    this.f.PreCarbonFilter.clearValidators();
    this.f.PreROFilter.clearValidators();
    this.f.PreCarbonFilterDelta.clearValidators();
    this.f.PreROFilterDelta.clearValidators();
    this.f.ROHours.clearValidators();
    this.f.RODisinfected.clearValidators();
    this.f.pHPreCarbon.clearValidators();
    this.f.pHPostCarbon.clearValidators();
    this.f.IronPostSoftener.clearValidators();
    this.f.VerifyTestingChemicalLevels.clearValidators();
    this.f.LoopReturnTempB.clearValidators();
    this.f.TempTank.clearValidators();

    this.f.ResidualTestResult.clearValidators();
    this.f.DisinfectSecondaryRO.clearValidators();
    this.f.ResidualTestResult.clearValidators();
  }

  updateValidations() {
    this.f.AMChlorineTestLeftBank.updateValueAndValidity();
    this.f.AMChlorineTestRightBank.updateValueAndValidity();
    this.f.PMChlorineTestLeftBank.updateValueAndValidity();
    this.f.PMChlorineTestRightBank.updateValueAndValidity();
    this.f.MIDChlorineTestLeftBank.updateValueAndValidity();
    this.f.MIDChlorineTestRightBank.updateValueAndValidity();

    this.f.HardnessPreSoft.updateValueAndValidity();
    this.f.HardnessPostSoftenerS2.updateValueAndValidity();
    this.f.HardnessPostSoftenerS3.updateValueAndValidity();
    this.f.PreSoftnerPressure.updateValueAndValidity();
    this.f.PostSoftnerPressure.updateValueAndValidity();
    this.f.SaltLevelVerifications.updateValueAndValidity();

    this.f.ROPreSoftnerFilterPressure.updateValueAndValidity();
    this.f.ROPostSoftnerFilterPressure.updateValueAndValidity();
    this.f.ROAFeedConductivity.updateValueAndValidity();
    this.f.ROBFeedConductivity.updateValueAndValidity();
    this.f.ROAProductConductivity.updateValueAndValidity();
    this.f.ROBProductConductivity.updateValueAndValidity();
    this.f.ROARecoveryRate.updateValueAndValidity();
    this.f.ROBRecoveryRate.updateValueAndValidity();
    this.f.ROARejectionRate.updateValueAndValidity();
    this.f.ROBRejectionRate.updateValueAndValidity();
    this.f.ROAAlarmHistory.updateValueAndValidity();
    this.f.ROBAlarmHistory.updateValueAndValidity();
    this.f.LoopReturnTemp.updateValueAndValidity();
    this.f.DialoxLevels.updateValueAndValidity();

    this.f.BoosterPumpP4.updateValueAndValidity();
    this.f.PreCarbonFilter.updateValueAndValidity();
    this.f.PreROFilter.updateValueAndValidity();
    this.f.PreCarbonFilterDelta.updateValueAndValidity();
    this.f.PreROFilterDelta.updateValueAndValidity();
    this.f.ROHours.updateValueAndValidity();
    this.f.RODisinfected.updateValueAndValidity();
    this.f.pHPreCarbon.updateValueAndValidity();
    this.f.pHPostCarbon.updateValueAndValidity();
    this.f.IronPostSoftener.updateValueAndValidity();
    this.f.VerifyTestingChemicalLevels.updateValueAndValidity();
    this.f.TempTank.updateValueAndValidity();

    this.f.ResidualTestResult.updateValueAndValidity();
    this.f.DisinfectSecondaryRO.updateValueAndValidity();
    this.f.ResidualTestResultsec.updateValueAndValidity();
  }




  prepareCreateViewModelGRWPSatelite(source, type) {

    var GRWPSatelite = <any>{};

    GRWPSatelite.GRWaterprocessDailyrecordsID = this.f.GRWaterprocessDailyrecordsID.value;
    GRWPSatelite.EventId = this.f.eventId.value;

    GRWPSatelite.DivisionId = this.f.selectedDialysisUnits.value;
    GRWPSatelite.FormType = this.f.selectedMainDivision.value;
    GRWPSatelite.RecordDate = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    GRWPSatelite.FormDate = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    GRWPSatelite.IsDelete = false;


    if(source == '')
      GRWPSatelite.WPDailySectionType = type;
    else if(source == 'Notes') {
      GRWPSatelite.WPDailySectionType = type + ',' + source;
    }
    else {

        if(type == 'Weekly' && source == 'Softener' && !this.displayRemainingFields){
          GRWPSatelite.WPDailySectionType = 'Softener';
        }
        else if(type == 'Weekly' && source == 'ROVerification' && !this.displayRORemainingFields && !this.displayRemainingFields){
          GRWPSatelite.WPDailySectionType = 'Weekly';
        }
        else if(type == 'Weekly' && source == 'ROVerification' && !this.displayRORemainingFields){
          GRWPSatelite.WPDailySectionType = 'Weekly';
        }
        else if(type == 'Weekly' && source == 'Disinfection' && this.IsFirstDisinfectionCompleted == false &&
        this.IsSecondDisinfectionCompleted == false && this.f.DisinfectSecondaryRO.value === "Yes")
        {
          GRWPSatelite.WPDailySectionType = 'Full Disinfection';
        }
        else if (type == 'Weekly' && source == 'Disinfection' && this.f.DisinfectSecondaryRO.value === "Yes")
        {
          GRWPSatelite.WPDailySectionType = 'Disinfection2';
        }
        else if(type == 'Weekly' && source == 'Disinfection')
        {
          GRWPSatelite.WPDailySectionType = 'Disinfection';
        }
        else
        {
          GRWPSatelite.WPDailySectionType = source;
        }
    }

    GRWPSatelite.GRWaterProcessDailySatelliteDetailId = this.f.GRWaterProcessDailySatelliteDetailId.value;
    // GRWPSatelite.note = this.notes.Note;
    // GRWPSatelite.noteType = this.noteType;

    GRWPSatelite.GRWaterprocessDailyrecordsID = this.f.GRWaterprocessDailyrecordsID.value;
    GRWPSatelite.AMChlorineTestLeftBank = this.f.AMChlorineTestLeftBank.value;
    GRWPSatelite.AMChlorineTestRightBank = this.f.AMChlorineTestRightBank.value;
    GRWPSatelite.PMChlorineTestLeftBank = this.f.PMChlorineTestLeftBank.value;
    GRWPSatelite.PMChlorineTestRightBank = this.f.PMChlorineTestRightBank.value;
    GRWPSatelite.MIDChlorineTestLeftBank = this.f.MIDChlorineTestLeftBank.value;
    GRWPSatelite.MIDChlorineTestRightBank = this.f.MIDChlorineTestRightBank.value;
    GRWPSatelite.HardnessPreSoft = this.f.HardnessPreSoft.value;
    GRWPSatelite.HardnessPostSoftenerS2 = this.f.HardnessPostSoftenerS2.value;
    GRWPSatelite.HardnessPostSoftenerS3 = this.f.HardnessPostSoftenerS3.value;
    GRWPSatelite.PreSoftnerPressure = this.f.PreSoftnerPressure.value;
    GRWPSatelite.PostSoftnerPressure = this.f.PostSoftnerPressure.value;
    GRWPSatelite.SaltLevelVerifications = this.f.SaltLevelVerifications.value;

    GRWPSatelite.ROPresentlyInUse = this.f.selectedVerifyROPresently.value;
    GRWPSatelite.ROHours = this.f.ROHours.value;
    GRWPSatelite.RODisinfected = this.f.RODisinfected.value;


    GRWPSatelite.ROPreSoftnerFilterPressure = this.f.ROPreSoftnerFilterPressure.value;
    GRWPSatelite.ROPostSoftnerFilterPressure = this.f.ROPostSoftnerFilterPressure.value;
    GRWPSatelite.ROAFeedConductivity = this.f.ROAFeedConductivity.value;
    GRWPSatelite.ROBFeedConductivity = this.f.ROBFeedConductivity.value;
    GRWPSatelite.ROAProductConductivity = this.f.ROAProductConductivity.value;
    GRWPSatelite.ROBProductConductivity = this.f.ROBProductConductivity.value;
    GRWPSatelite.ROARecoveryRate = this.f.ROARecoveryRate.value;
    GRWPSatelite.ROBRecoveryRate = this.f.ROBRecoveryRate.value;
    GRWPSatelite.ROARejectionRate = this.f.ROARejectionRate.value;
    GRWPSatelite.ROBRejectionRate = this.f.ROBRejectionRate.value;
    GRWPSatelite.ROAAlarmHistory = this.f.ROAAlarmHistory.value;
    GRWPSatelite.ROBAlarmHistory = this.f.ROBAlarmHistory.value;
    GRWPSatelite.LoopReturnTemp = this.f.LoopReturnTemp.value;
    GRWPSatelite.DialoxLevels = this.f.DialoxLevels.value;
    GRWPSatelite.saveType = type;

    GRWPSatelite.BoosterPumpP4 = this.f.BoosterPumpP4.value;
    GRWPSatelite.PreCarbonFilter = this.f.PreCarbonFilter.value;
    GRWPSatelite.PreROFilter = this.f.PreROFilter.value;
    GRWPSatelite.PreCarbonFilterDelta = this.f.PreCarbonFilterDelta.value;
    GRWPSatelite.PreROFilterDelta = this.f.PreROFilterDelta.value;
    GRWPSatelite.pHPreCarbon = this.f.pHPreCarbon.value;
    GRWPSatelite.pHPostCarbon = this.f.pHPostCarbon.value;
    GRWPSatelite.IronPostSoftener = this.f.IronPostSoftener.value;
    GRWPSatelite.VerifyTestingChemicalLevels = this.f.VerifyTestingChemicalLevels.value;
    GRWPSatelite.TempTank = this.f.TempTank.value;
    GRWPSatelite.VerifyROReverse = this.f.VerifyROReverse.value;
    GRWPSatelite.LoopReturnTempB = this.f.LoopReturnTempB.value;


    if (source == 'Disinfection' || this.f.DisinfectionDate.value || this.f.DisinfectionDateSec.value) {

      GRWPSatelite.CWPRODisinfected = this.f.CWPRODisinfected.value;
      GRWPSatelite.DisinfectionDate = this.f.DisinfectionDate.value ? this.f.DisinfectionDate.value : Globals.GetAPIDate(this.f.TestDisinfectionDate.value.jsdate);
      GRWPSatelite.ResidualTestResult = this.f.ResidualTestResult.value;
      GRWPSatelite.ChemicalDisinfectionUser = this.f.ChemicalDisinfectionUser.value ? this.f.ChemicalDisinfectionUser.value : this.currentUserName;

      if (this.f.DisinfectSecondaryRO.value)
        GRWPSatelite.DisinfectSecondaryRO = this.f.DisinfectSecondaryRO.value;

      if (this.f.DisinfectSecondaryRO.value === "Yes") {
        GRWPSatelite.DisinfectionDateSec = this.f.DisinfectionDateSec.value ? this.f.DisinfectionDateSec.value : Globals.GetAPIDate(this.f.TestDisinfectionDate.value.jsdate);
        GRWPSatelite.ChemicalDisinfectionUserSec = this.f.ChemicalDisinfectionUserSec.value ? this.f.ChemicalDisinfectionUserSec.value : this.currentUserName;
      }
      else {
        GRWPSatelite.ChemicalDisinfectionUserSec = null;
        GRWPSatelite.DisinfectionDateSec = null;
      }

    }

    GRWPSatelite.AMDetailsEnteredBy = this.f.AMDetailsEnteredBy.value ? this.f.AMDetailsEnteredBy.value : this.currentUserId;
    GRWPSatelite.AMDetailsEnteredOn = this.f.AMDetailsEnteredOn.value;
    GRWPSatelite.PMDetailsEnteredBy = this.f.PMDetailsEnteredBy.value ? this.f.PMDetailsEnteredBy.value : this.currentUserId;;
    GRWPSatelite.PMDetailsEnteredOn = this.f.PMDetailsEnteredOn.value;
    GRWPSatelite.MidDetailsEnteredBy = this.f.MidDetailsEnteredBy.value ? this.f.MidDetailsEnteredBy.value : this.currentUserId;;
    GRWPSatelite.MidDetailsEnteredOn = this.f.MidDetailsEnteredOn.value;
    GRWPSatelite.WeeklyDetailsEnteredBy = this.f.WeeklyDetailsEnteredBy.value ? this.f.WeeklyDetailsEnteredBy.value : this.currentUserId;;
    GRWPSatelite.WeeklyDetailsEnteredOn = this.f.WeeklyDetailsEnteredOn.value;



    GRWPSatelite.WeeklyROVerificationDetailsEnteredOn = this.f.WeeklyROVerificationDetailsEnteredOn.value;

    if (this.f.CreatedDate.value)
      GRWPSatelite.CreatedDate = Globals.GetAPIDate(this.f.CreatedDate.value.jsdate);
    else
      GRWPSatelite.CreatedDate = Globals.GetAPIDate(this.today);

    GRWPSatelite.ModifyDate = Globals.GetAPIDate(this.f.ModifyDate.value.jsdate);

    if (this.f.CreatedBy.value)
      GRWPSatelite.CreatedBy = this.f.CreatedBy.value;
    else
      GRWPSatelite.CreatedBy = this.currentUserId;

    GRWPSatelite.ModifiedBy = this.currentUserId;
    GRWPSatelite.IsDelete = this.f.IsDelete.value;
    GRWPSatelite.Status = this.f.Status.value;

    GRWPSatelite.noteType = type;
    if (source == 'Notes') {
      if (type == "Disinfection")
        GRWPSatelite.note = this.weeklyDisinfectionNotes.Note;
      // if (type == "Softner")
      //   GRWPSatelite.note = this.weeklySoftenerNotes.Note;
      if (type == "Weekly")
        GRWPSatelite.note = this.weeklyROVerificationNotes.Note;
      if (type == "AM")
        GRWPSatelite.note = this.amNotes.Note;
      if (type == "PM")
        GRWPSatelite.note = this.pmNotes.Note;
      if (type == "MID")
        GRWPSatelite.note = this.midNotes.Note;

    }


    GRWPSatelite.attachmentDetails = this.attachment.GenerateAttachmentList();
    //console.log(GRWPSatelite);
    return GRWPSatelite;
  }

  setModel() {

    AppComponent.ShowLoader();
    this.clearNotes();
    this.notes.showHours = true;
    this.attachment.clearQueue();

    var GRwpdailyCreate = { Id: this.f.GRWaterprocessDailyrecordsID.value };

    this.wpapi.GetGRWaterProcessDailySatelliteDetailIdByID(GRwpdailyCreate).subscribe(
      success => {

        if (success) {
console.log(success);
console.log(this.tempServerTodayDate);
console.log(this.today);

          this.f.GRWaterProcessDailySatelliteDetailId.setValue(success.grWaterProcessDailySatelliteDetailId);
          this.f.GRWaterprocessDailyrecordsID.setValue(success.grWaterprocessDailyrecordsID);
          this.f.AMChlorineTestLeftBank.setValue(success.amChlorineTestLeftBank);
          this.f.AMChlorineTestRightBank.setValue(success.amChlorineTestRightBank);

          this.f.PMChlorineTestLeftBank.setValue(success.pmChlorineTestLeftBank);
          this.f.PMChlorineTestRightBank.setValue(success.pmChlorineTestRightBank);

          this.f.MIDChlorineTestLeftBank.setValue(success.midChlorineTestLeftBank);
          this.f.MIDChlorineTestRightBank.setValue(success.midChlorineTestRightBank);


          if(success.weeklyDetailsEnteredOn != null) {
          if(success.preSoftnerPressure != null && success.preSoftnerPressure != "")
            this.f.PreSoftnerPressure.setValue(success.preSoftnerPressure);
          else
            this.f.PreSoftnerPressure.setValue('N/A');

          if(success.roPresentlyInUse != null && success.roPresentlyInUse != "")
            this.f.selectedVerifyROPresently.setValue(success.roPresentlyInUse);
          else
            this.f.selectedVerifyROPresently.setValue('N/A');

          if(success.roPreSoftnerFilterPressure != null && success.roPreSoftnerFilterPressure != "")
            this.f.ROPreSoftnerFilterPressure.setValue(success.roPreSoftnerFilterPressure);
          else
            this.f.ROPreSoftnerFilterPressure.setValue('N/A');

          if(success.roPostSoftnerFilterPressure != null && success.roPostSoftnerFilterPressure != "")
            this.f.ROPostSoftnerFilterPressure.setValue(success.roPostSoftnerFilterPressure);
          else
            this.f.ROPostSoftnerFilterPressure.setValue('N/A');

          if(success.loopReturnTemp != null  && success.loopReturnTemp != "")
            this.f.LoopReturnTemp.setValue(success.loopReturnTemp);
          else
            this.f.LoopReturnTemp.setValue('N/A');

          if(success.dialoxLevels != null  && success.dialoxLevels != "")
            this.f.DialoxLevels.setValue(success.dialoxLevels);
          else
            this.f.DialoxLevels.setValue('N/A');

          if(success.roaAlarmHistory != null  && success.roaAlarmHistory != "")
            this.f.ROAAlarmHistory.setValue(success.roaAlarmHistory);
          else
            this.f.ROAAlarmHistory.setValue('N/A');

          if(success.robAlarmHistory != null  && success.robAlarmHistory != "")
            this.f.ROBAlarmHistory.setValue(success.robAlarmHistory);
          else
            this.f.ROBAlarmHistory.setValue('N/A');

          if(success.hardnessPreSoft != null  && success.hardnessPreSoft != "")
            this.f.HardnessPreSoft.setValue(success.hardnessPreSoft);
          else
            this.f.HardnessPreSoft.setValue('N/A');

          if(success.hardnessPostSoftenerS2 !=  null  && success.hardnessPostSoftenerS2 != "")
            this.f.HardnessPostSoftenerS2.setValue(success.hardnessPostSoftenerS2);
          else
            this.f.HardnessPostSoftenerS2.setValue('N/A');

          if(success.hardnessPostSoftenerS3 != null && success.hardnessPostSoftenerS3 != "")
            this.f.HardnessPostSoftenerS3.setValue(success.hardnessPostSoftenerS3);
          else
            this.f.HardnessPostSoftenerS3.setValue('N/A');

          if(success.saltLevelVerifications != null  && success.saltLevelVerifications != "")
            this.f.SaltLevelVerifications.setValue(success.saltLevelVerifications);
          else
            this.f.SaltLevelVerifications.setValue('N/A');

          if(success.postSoftnerPressure != null  && success.postSoftnerPressure != "")
            this.f.PostSoftnerPressure.setValue(success.postSoftnerPressure);
          else
            this.f.PostSoftnerPressure.setValue('N/A');

          if(success.roHours != null  && success.roHours != "")
            this.f.ROHours.setValue(success.roHours);
          else
            this.f.ROHours.setValue('N/A');

          if(success.roDisinfected != null  && success.roDisinfected != "")
            this.f.RODisinfected.setValue(success.roDisinfected);
          else
            this.f.RODisinfected.setValue('N/A');

          if(success.boosterPumpP4 != null  && success.boosterPumpP4 != "")
            this.f.BoosterPumpP4.setValue(success.boosterPumpP4);
          else
            this.f.BoosterPumpP4.setValue('N/A');

          if(success.preCarbonFilter != null  && success.preCarbonFilter != "")
            this.f.PreCarbonFilter.setValue(success.preCarbonFilter);
          else
            this.f.PreCarbonFilter.setValue('N/A');

          if(success.preROFilter != null  && success.preROFilter != "")
            this.f.PreROFilter.setValue(success.preROFilter);
          else
          this.f.PreROFilter.setValue('N/A');

          if(success.preCarbonFilterDelta != null  && success.preCarbonFilterDelta != "")
            this.f.PreCarbonFilterDelta.setValue(success.preCarbonFilterDelta);
          else
          this.f.PreCarbonFilterDelta.setValue('N/A');

          if(success.preROFilterDelta != null  && success.preROFilterDelta != "")
            this.f.PreROFilterDelta.setValue(success.preROFilterDelta);
          else
            this.f.PreROFilterDelta.setValue('N/A');

          if(success.pHPreCarbon != null  && success.pHPreCarbon != "")
            this.f.pHPreCarbon.setValue(success.pHPreCarbon);
          else
            this.f.pHPreCarbon.setValue('N/A');

          if(success.pHPostCarbon != null  && success.pHPostCarbon != "")
            this.f.pHPostCarbon.setValue(success.pHPostCarbon);
          else
            this.f.pHPostCarbon.setValue('N/A');

          if(success.ironPostSoftener != null  && success.ironPostSoftener != "")
            this.f.IronPostSoftener.setValue(success.ironPostSoftener);
          else
            this.f.IronPostSoftener.setValue('N/A');

          if(success.verifyTestingChemicalLevels != null  && success.verifyTestingChemicalLevels != "")
            this.f.VerifyTestingChemicalLevels.setValue(success.verifyTestingChemicalLevels);
          else
            this.f.VerifyTestingChemicalLevels.setValue('N/A');

          if(success.tempTank != null  && success.tempTank != "")
            this.f.TempTank.setValue(success.tempTank);
          else
            this.f.TempTank.setValue('N/A');

          if(success.verifyROReverse != null  && success.verifyROReverse != "")
            this.f.VerifyROReverse.setValue(success.verifyROReverse);
          else
            this.f.VerifyROReverse.setValue('N/A');

          if(success.loopReturnTempB != null  && success.loopReturnTempB != "")
            this.f.LoopReturnTempB.setValue(success.loopReturnTempB);
          else
            this.f.LoopReturnTempB.setValue('N/A');

          if(success.roaFeedConductivity != null  && success.roaFeedConductivity != "")
            this.f.ROAFeedConductivity.setValue(success.roaFeedConductivity);
          else
            this.f.ROAFeedConductivity.setValue('N/A');

          if(success.roaProductConductivity != null  && success.roaProductConductivity != "")
            this.f.ROAProductConductivity.setValue(success.roaProductConductivity);
          else
            this.f.ROAProductConductivity.setValue('N/A');

          if(success.roaRecoveryRate != null  && success.roaRecoveryRate != "")
            this.f.ROARecoveryRate.setValue(success.roaRecoveryRate);
          else
            this.f.ROARecoveryRate.setValue('N/A');

          if(success.roaRejectionRate != null  && success.roaRejectionRate != "")
            this.f.ROARejectionRate.setValue(success.roaRejectionRate);
          else
            this.f.ROARejectionRate.setValue('N/A');

          if(success.robFeedConductivity != null  && success.robFeedConductivity != "")
            this.f.ROBFeedConductivity.setValue(success.robFeedConductivity);
          else
            this.f.ROBFeedConductivity.setValue('N/A');

          if(success.robProductConductivity != null  && success.robProductConductivity != "")
            this.f.ROBProductConductivity.setValue(success.robProductConductivity);
          else
            this.f.ROBProductConductivity.setValue('N/A');

          if(success.robRecoveryRate != null && success.robRecoveryRate != "")
            this.f.ROBRecoveryRate.setValue(success.robRecoveryRate);
          else
            this.f.ROBRecoveryRate.setValue('N/A');

          if(success.robRejectionRate != null && success.robRejectionRate != "")
            this.f.ROBRejectionRate.setValue(success.robRejectionRate);
          else
            this.f.ROBRejectionRate.setValue('N/A');

          }
          if (success.cwproDisinfected)
            this.f.CWPRODisinfected.setValue(success.cwproDisinfected);

          if (success.disinfectionDate){
            this.f.DisinfectionDate.setValue(success.disinfectionDate);
            this.IsFirstDisinfectionCompleted = true;
          }
          if (success.residualTestResult)
            this.f.ResidualTestResult.setValue(success.residualTestResult);

          this.f.ChemicalDisinfectionUser.setValue(success.chemicalDisinfectionUser);

          if (success.disinfectSecondaryRO)
            this.f.DisinfectSecondaryRO.setValue(success.disinfectSecondaryRO);

          this.f.CreatedDate.setValue(success.createdDate);
          this.f.ModifyDate.setValue(success.modifyDate);
          this.f.CreatedBy.setValue(success.createdBy);
          this.f.ModifiedBy.setValue(success.modifiedBy);
          this.f.IsDelete.setValue(success.isDelete);
          this.f.AMDetailsEnteredBy.setValue(success.amDetailsEnteredBy);
          this.f.AMDetailsEnteredOn.setValue(success.amDetailsEnteredOn);
          this.f.PMDetailsEnteredBy.setValue(success.pmDetailsEnteredBy);
          this.f.PMDetailsEnteredOn.setValue(success.pmDetailsEnteredOn);
          this.f.MidDetailsEnteredBy.setValue(success.midDetailsEnteredBy);
          this.f.MidDetailsEnteredOn.setValue(success.midDetailsEnteredOn);
          this.f.WeeklyDetailsEnteredBy.setValue(success.weeklyDetailsEnteredBy);
          this.f.WeeklyDetailsEnteredOn.setValue(success.weeklyDetailsEnteredOn);

          if (success.disinfectionDateSec){
            this.f.DisinfectionDateSec.setValue(success.disinfectionDateSec);
            this.IsSecondDisinfectionCompleted = true;
          }

          this.f.ChemicalDisinfectionUserSec.setValue(success.chemicalDisinfectionUserSec);

          this.f.WeeklyROVerificationDetailsEnteredOn.setValue(success.weeklyROVerificationDetailsEnteredOn);

          this.AMUserName = success.amUserName;
          this.MIDUserName = success.midUserName;
          this.PMUserName = success.pmUserName;
          this.WeeklyUserName = success.weeklyUserName;
          if (this.WeeklyUserName === null || this.WeeklyUserName == '') {
            this.WeeklyUserName = this.auth.GetUserFirstName();
          }
          this.UserName = success.amUserName;

          // this.f.CreatedDate.setValue(new Date(success.createdDate));

          const tempcreatedOn = new Date(success.createdDate);

          this.f.CreatedDate.setValue({
            date: {
              year: tempcreatedOn.getFullYear(),
              month: tempcreatedOn.getMonth() + 1,
              day: tempcreatedOn.getDate()
            },
            jsdate: success.formDate
          });


          this.f.Status.setValue(success.status);
          this.Status = success.status;
          this.f.eventId.setValue(success.eventId);

          this.RecordDate = success.recordDate;
          const tempformDate = new Date(success.formDate);

          this.f.formDate.setValue({
            date: {
              year: tempformDate.getFullYear(),
              month: tempformDate.getMonth() + 1,
              day: tempformDate.getDate()
            },
            jsdate: success.formDate
          });

          this.DUName = success.divisionName;
          this.MainDUName = success.formType;
          this.f.selectedMainDivision.setValue(success.formType);

          this.setROVerificationStatus();
          this.PresentlyInUseChanged();

          var satelleiteObj = { Id: this.f.GRWaterProcessDailySatelliteDetailId.value };

          this.wpapi
            .GetattachemnetsByyGRWaterProcessDailySatelliteRecordID(satelleiteObj)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });


          this.wpapi.GetNotesByGRWaterProcessDailySatelliteRecordID(satelleiteObj).subscribe(list => {
            // this.notes.NoteList = list;
            this.amNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "AM");
            this.midNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "MID");
            this.pmNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "PM");

            setTimeout(() => {
              if (this.weeklyROVerificationNotes)
                this.weeklyROVerificationNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "Weekly");

              // if (this.weeklySoftenerNotes)
              //   this.weeklySoftenerNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "Softner");

              if (this.weeklyDisinfectionNotes)
                this.weeklyDisinfectionNotes.NoteList = list.filter((item, index) => item.moduleName == "GRWaterProcessDailySatelliteRecords_" + "Disinfection");

            }, 1000);
          });

          let RecordDay = Globals.GetAPIDate(this.f.formDate.value.jsdate);
          if ((new Date(RecordDay).setHours(0, 0, 0, 0) == new Date(this.tempServerTodayDate).setHours(0, 0, 0, 0)
            || this.f.WeeklyDetailsEnteredOn.value || this.f.WeeklyROVerificationDetailsEnteredOn.value) &&
            (this.f.AMDetailsEnteredOn.value || this.f.PMDetailsEnteredOn.value || this.f.MidDetailsEnteredOn.value))
            this.IsWeeklyDisplayed = true;
          else
            this.IsWeeklyDisplayed = false;
        }
        this.setValidations('ngOnInit', '');
        this.HideShowPanel();
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  HideShowPanel() {

    this.collapseAllPanel();
    if (!this.f.AMDetailsEnteredOn.value && (this.datePipe.transform(this.f.CreatedDate.value.jsdate, 'yyyy-MM-dd')) == (this.datePipe.transform(this.today, 'yyyy-MM-dd')))
    {
      this.collapseTwoAMClass = '';
      this.collapseTwoAMariaexpanded = true;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse show';
    }
    else if (!this.f.MidDetailsEnteredOn.value && (this.datePipe.transform(this.f.CreatedDate.value.jsdate, 'yyyy-MM-dd')) == (this.datePipe.transform(this.today, 'yyyy-MM-dd')))
    {
      this.collapseTwoMidClass = '';
      this.collapseTwoMidariaexpanded = true;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse show';
    }
    else if (!this.f.PMDetailsEnteredOn.value && (this.datePipe.transform(this.f.CreatedDate.value.jsdate, 'yyyy-MM-dd')) == (this.datePipe.transform(this.today, 'yyyy-MM-dd')))
    {
      this.collapseTwoPMClass = '';
      this.collapseTwoPMariaexpanded = true;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse show';
    }
    else if (!this.f.DisinfectionDate.value && this.IsWeeklyDisplayed &&
      (this.f.AMDetailsEnteredOn.value && this.f.PMDetailsEnteredOn.value && this.f.MidDetailsEnteredOn.value)) {
      this.collapseTwoWeeklyClass = '';
      this.collapseTwoWeeklyariaexpanded = true;
      this.collapseTwoWeeklypanelClass = 'panel-collapse collapse show';
      this.setROVerifications(true);
    }
  }


  collapseAllPanel() {
    this.collapseTwoAMClass = 'collapsed';
    this.collapseTwoAMariaexpanded = true;
    this.collapseTwoAMpanelClass = 'panel-collapse collapse';

    this.collapseTwoMidClass = 'collapsed';
    this.collapseTwoMidariaexpanded = false;
    this.collapseTwoMidpanelClass = 'panel-collapse collapse';

    this.collapseTwoPMClass = 'collapsed';
    this.collapseTwoPMariaexpanded = false;
    this.collapseTwoPMpanelClass = 'panel-collapse collapse';

    this.collapseTwoWeeklyClass = 'collapsed';
    this.collapseTwoWeeklyariaexpanded = false;
    this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';
  }

  formatDecimal(val) {
    //let val = this.f[ctl].value;
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        val = val + ".0"
      }
      return val;
    }
  }


  // ReplaceLeadingZero(ctl, count = 1, checkIfZero = true) {
  //   let val = this.f[ctl].value;

  //   if (checkIfZero)
  //     this.CheckIfZero(ctl)

  //   if (val != null)
  //     val = val.replace(/^0+/, '');

  //   if (val == 0)
  //     val = "";

  //   if (val < 1) {
  //     let valDecimal = this.decimalPlaces(val);
  //     if (valDecimal === 1) {
  //       val = "0" + val;
  //     }
  //     if (valDecimal === 2 && count === 2) {
  //       val = "0" + val;
  //     }
  //   }
  //   if (val.length > 0) {
  //     if (val.indexOf('.') === -1) {
  //       if (count == 2)
  //         val = val + ".00";
  //       else
  //         val = val + ".0";
  //     }
  //     else if (Number.isInteger(parseFloat(val)) && val.indexOf('0.') === -1 && val.indexOf('.') !== -1 && val.indexOf('.0') === -1) {
  //       if (count == 2)
  //         val = val + "00";
  //       else
  //         val = val + "0";
  //     }
  //     else if (val.indexOf('.') == 1 && val.length == 3) {
  //       if (count == 2)
  //         val = val + "0";
  //     }
  //     else if (val.indexOf('.') == 1 && val.length == 4) {
  //       if (count == 3)
  //         val = val + "0";
  //     }

  //   }
  //   this.f[ctl].setValue(val);
  // }

  ReplaceLeadingZero(ctl, count = 1) {

    let val = this.f[ctl].value;
    val = val.replace(/^0+/, '');

    if (val == 0)
      val = "";

    if (val < 1) {
      let valDecimal = this.decimalPlaces(val);
      if (valDecimal === 1) {
        val = "0" + val;
      }
      if (valDecimal === 2 && count === 2) {
        val = "0" + val;
      }
    }
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        if (ctl == 'IronTotalPostSoftenerS14')
          val = val + ".00";
        else
          val = val + ".0";
      }
      else if (Number.isInteger(parseFloat(val)) && val.indexOf('0.') === -1 && val.indexOf('.') !== -1 && val.indexOf('.0') === -1) {
        if (ctl == 'IronTotalPostSoftenerS14')
          val = val + "00";
        else
          val = val + "0";
      }
      else if (val.indexOf('.') == 1 && val.length == 3 && ctl == 'IronTotalPostSoftenerS14') {
        val = val + "0";
      }
    }
    this.f[ctl].setValue(val);
  }

  FormatDecimal(ctl, precision = 1, scale= 2, checkIfZero = true) {
    let val = this.f[ctl].value;
    let val2 = '';

    if (checkIfZero)
      this.CheckIfZero(ctl);

    if (val){
      val = val.replace(/^0+/, '');
      val = val.replace('.','');
    }

    if (val == 0)
    val = "";

    if(val.length < (precision + scale)){
      let zeros = (precision + scale) - val.length;
      let temp = '';
      for(let i=0;i<zeros;i++){
        temp+='0';
      }
      val = temp + val;
    }

    if(val != 0)
      val2 = val.substr(0,precision) + '.' + val.substr(precision,scale);

    this.f[ctl].setValue(val2);
  }

  CheckIfZero(ctl, count = 1) {
    let val = this.f[ctl].value;
    val = val.replace(/^0+/, '');

    if (val == 0)
      val = "";

    this.f[ctl].setValue(val);
  }


  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  numberOnlyWithoutDot(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearNotes() {

    setTimeout(() => {
      if (this.amNotes) {
        // this.amNotes.showHours = false;
        this.amNotes.Note = "";
      }
      if (this.midNotes) {
        // this.midNotes.showHours = false;
        this.midNotes.Note = "";
      }
      if (this.pmNotes) {
        // this.pmNotes.showHours = false;
        this.pmNotes.Note = "";
      }
      if (this.weeklyDisinfectionNotes) {
        // this.weeklyDisinfectionNotes.showHours = false;
        this.weeklyDisinfectionNotes.Note = "";
      }
      if (this.weeklyROVerificationNotes) {
        // this.weeklyROVerificationNotes.showHours = false;
        this.weeklyROVerificationNotes.Note = "";
      }
      // if (this.weeklySoftenerNotes) {
      //   // this.weeklySoftenerNotes.showHours = false;
      //   this.weeklySoftenerNotes.Note = "";
      // }
    }, 100);
  }

  setROVerificationStatus() {

    if (this.f.HardnessPreSoft.value
      && this.f.HardnessPostSoftenerS2.value
      && this.f.HardnessPostSoftenerS3.value
      && this.f.SaltLevelVerifications.value
      && this.f.selectedVerifyROPresently.value
      && this.f.ROHours.value
      && this.f.RODisinfected.value
      && this.f.PreROFilter.value
      && (((this.f.selectedVerifyROPresently.value == "RO-A" || this.f.selectedVerifyROPresently.value == "A" || this.f.selectedVerifyROPresently.value == "N/A") && this.f.ROAFeedConductivity.value
          && this.f.ROAProductConductivity.value && this.f.ROARecoveryRate.value
          && this.f.ROARejectionRate.value && this.f.LoopReturnTemp.value) ||
          ((this.f.selectedVerifyROPresently.value == "RO-B" || this.f.selectedVerifyROPresently.value == "B" || this.f.selectedVerifyROPresently.value == "N/A") && this.f.ROBFeedConductivity.value
          && this.f.ROBProductConductivity.value && this.f.ROBRecoveryRate.value
          && this.f.ROBRejectionRate.value && this.f.LoopReturnTempB.value))
      && this.f.ROAAlarmHistory.value
      && this.f.ROBAlarmHistory.value
      && this.f.DialoxLevels.value) {

      this.ROVerificationStatus = 'Completed';
      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        let tempFormDate = new Date(list);
        this.f.TestDisinfectionDate.setValue({
          date: {
            year: tempFormDate.getFullYear(),
            month: tempFormDate.getMonth() + 1,
            day: tempFormDate.getDate()
          },
          jsdate: tempFormDate
        });
      });
    }
    else {
      this.ROVerificationStatus = 'Incompleted';
    }
  }

  preGreaterPostPlusTen(preVal, postVal, isGreaterCheck = true) {

    if (isGreaterCheck)
      return preVal && postVal && (parseInt(preVal) <= (parseInt(postVal)) || parseInt(preVal) > (parseInt(postVal) + 10));
    else
      return preVal && postVal && (parseInt(preVal) > (parseInt(postVal) + 10));
  }

  checkRange(control, MinValue, MaxValue) {
    if (control.value != '' && control.value != null) {
      return !(control.value >= MinValue && control.value <= MaxValue);
    }
  }

  VerifyROPresentlyDropDownChanged() {

    if (this.f.selectedVerifyROPresently.value != '') {
      let val = this.f.selectedVerifyROPresently.value;

      if (val.substring(0, 1) === 'A') {
          this.VerifyROlable = 'RO-B is Off ?';
          this.VerifyingCWWPROText = 'RO-A';
          this.VerifyROlablewithoutOff = 'RO-B';
      }
      else if (val.substring(0, 1) === 'B') {
          this.VerifyROlable = 'RO-A is Off ?';
          this.VerifyingCWWPROText = 'RO-B';
          this.VerifyROlablewithoutOff = 'RO-A';
        }
      else {
          this.VerifyROlable = 'RO-A is Off ?';
          this.VerifyingCWWPROText = 'RO-B';
          this.VerifyROlablewithoutOff = 'RO-A';
        }
      }
      else {
        this.VerifyROlable = '';
        this.VerifyingCWWPROText = '';
        this.VerifyROlablewithoutOff = '';

      }
    }

    isWeeklypanelFilled = false;
  IsWeeklyPanelFill(source)
  {

    if(this.f.HardnessPreSoft.value ||
      this.f.HardnessPostSoftenerS2.value ||
      this.f.HardnessPostSoftenerS3.value ||
      this.f.SaltLevelVerifications.value ||
      this.f.PostSoftnerPressure.value ||
      this.f.DialoxLevels.value ||
      this.f.ROAAlarmHistory.value ||
      this.f.ROBAlarmHistory.value ||
      this.f.BoosterPumpP4.value ||
      this.f.PreCarbonFilter.value ||
      this.f.PreROFilter.value ||
      this.f.PreCarbonFilterDelta.value ||
      this.f.PreROFilterDelta.value ||
      this.f.PreROFilterDelta.value ||
      this.f.selectedVerifyROPresently.value ||
      this.f.ROHours.value ||
      this.f.RODisinfected.value ||
      this.f.ROBFeedConductivity.value ||
      this.f.ROBProductConductivity.value ||
      this.f.ROBRecoveryRate.value ||
      this.f.ROBRejectionRate.value ||
      this.f.LoopReturnTempB.value ||
      this.f.ROARejectionRate.value ||
      this.f.ROARejectionRate.value ||
      this.f.ROARecoveryRate.value ||
      this.f.ROAProductConductivity.value ||
      this.f.ROAFeedConductivity.value ||
      this.f.TempTank.value ||
      this.f.LoopReturnTemp.value ||
      this.f.pHPreCarbon.value ||
      this.f.pHPostCarbon.value ||
      this.f.IronPostSoftener.value ||
      this.f.VerifyTestingChemicalLevels.value ||
      this.f.selectedVerifyROPresently.value ||
      this.f.VerifyROReverse.value)
      {
        this.isWeeklypanelFilled = true;
        // if(source != 'Notes')
          this.setROVerifications(true);
      }
      else
      {
        this.isWeeklypanelFilled = false;
        this.setROVerifications(false);
        // this.WPDailySatelliteDetailForm.setErrors({ 'invalid': false });
      }

  }

}

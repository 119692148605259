import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { TrobleshootingDialog } from '../../Core/TrobleshootingDialog/TrobleshootingDialog';
import * as _ from 'underscore';
import { DMRepairCustomValidators } from '../../Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from '../../Validator/CommonValidator';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import { HomeService } from 'src/app/API/home.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
//import printJS from 'print-js';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { debug } from 'util';

@Component({
  selector: 'IncidentDetail',
  templateUrl: './IncidentDetail.component.html'
})
export class IncidentDetailComponent implements OnInit, CanComponentDeactivate {
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  troubleShootingDialogRef: MatDialogRef<TrobleshootingDialog>;
  isValidFormSubmitted = null;
  ListURL = '/DialysisMachine/IncidentSearch';
  status = '';
  loading = true;
  DMIncidentsIdvar = '0';
  EquipmentSerialNo: '';
  EquipmentInServiceDate: '';
  DialysisUnits = [];
  Equipments = [];
  DialysisStation = [];
  DMRepailDetail = [];
  eventId = '[AutoNumber]';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  selectedDialysisUnitsobj;
  selectedDialysisUnitstext;
  selectedUsertext;
  selectedEquipmenttext;
  selectedDialysisStationtext;
  TroubleShootingTimeText;
  StatusText;
  blnshowSave = false;
  blnshowDelete = false;
  ErrorDetail = [];
  TrobleshootingData = [];
  errorList = [];
  errorLists = [];
  blnatLeastOne = false;
  UserName = '';
  incidentStartDateLabel;
  divisionName;
  duplicateErrorCodes : boolean = false;
  selectedEquipmentHours = 0;
  public hrsTracking = true;
  TroubleShootingTimeList = [
    { value: '' , text: 'Select' },
    { value: 0.15, text: '15 min' },
    { value: 0.3, text: '30 min' },
    { value: 0.45, text: '45 min' },
    { value: 1.0, text: '1.0 Hr'  },
    { value: 1.5, text: '1.5 Hrs' },
    { value: 2.0, text: '2.0 Hrs' },
    { value: 2.5, text: '2.5 Hrs' },
    { value: 3.0, text: '3.0 Hrs' },
    { value: 3.5, text: '3.5 Hrs' },
    { value: 4.0, text: '4.0 Hrs' }
    // { value: 4.5, text: '4.5 Hrs' },
    // { value: 5.0, text: '5.0 Hrs' }
  ];

  FormDesignationList = [
    { value: '', text: 'Select' },
    { value: 'I', text: 'Event' },
    { value: 'R', text: 'Repair' }
  ];
  IncidentForm = new UntypedFormGroup({

    dmIncidentsId: new UntypedFormControl(''),
    FormDate: new UntypedFormControl('', Validators.required),
    IncidentDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedDialysisStation: new UntypedFormControl(''),
    ErrorSearchTerm: new UntypedFormControl(''),
    selectedEquipment: new UntypedFormControl('', Validators.required),
    ErrorDescription: new UntypedFormControl(''),
    TroubleShootingTime: new UntypedFormControl(''),
    FormDesignation: new UntypedFormControl('', Validators.required),
    selectedouthydraulicdis: new UntypedFormControl(''),
    selecthydrolicDropDownList: new UntypedFormControl(''),
    hours: new UntypedFormControl('')
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // console.log(this.IncidentForm.dirty,this.isValidFormSubmitted);
    if (
      (this.IncidentForm.dirty ||
      this.notes.Note !== '' ||
      this.attachment.Queue().length > 0 ) && (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    }  else { return true; }
  }


  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private homeApi: HomeService,
    private auth: AuthService,
    private commonapi: CommonApiService
  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.notes.showHours = true;
    // attachment changes
    this.attachment.DocId = this.f.dmIncidentsId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Incident];
    this.attachment.UploadBy = this.currentUserId;
    this.UserName = this.auth.GetUserFLName();

    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.

      this.fillDialysisUnit();
      this.f.dmIncidentsId.setValue(params['id'] || '0');

      this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
        let tempDate = this.today = new Date(currentDate);
        this.setDateOptions();
        this.f.FormDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });

        let tempIncidentDate = this.today;
        this.f.IncidentDate.setValue({
          date: {
            year: tempIncidentDate.getFullYear(),
            month: tempIncidentDate.getMonth() + 1,
            day: tempIncidentDate.getDate()
          },
          jsdate: tempIncidentDate
        });



      // this.f.hours.setValidators([
      //     Validators.pattern(Globals.NumberNumericRegexWithoutZero)
      // ]);

      // this.f.hours.setAsyncValidators([
      //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
      //     this.f.selectedEquipment,
      //     this.f.dmIncidentsId,
      //     this.dmapi
      //   )
      // ]);
      // this.f.hours.updateValueAndValidity();

        if (this.f.dmIncidentsId.value !== '0') {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {

            if(division.length===1){
                this.f.selectedDialysisUnits.setValue(division[0]);
                this.fillEquipment(division[0]);
                this.fillDialysisStation(division[0]);
            }
            else{
              this.fillEquipment(0);
              this.fillDialysisStation(0);
            }

            this.selectedUsertext = this.auth.GetUserName();

            this.f.TroubleShootingTime.setValue('');
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        }
      });
      this.SetPageRights();


      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });

  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }


  get f() {
    return this.IncidentForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const IncidentCreate = { Id: this.f.dmIncidentsId.value };
    this.fillTroubleshottingData(this.f.dmIncidentsId.value);
    this.ErrorTrans = [];

    this.dmapi.GetIncidentDMRepairDetailsByID(IncidentCreate).subscribe(
      success => {

        this.f.dmIncidentsId.setValue(success.dmIncidentDetailsId);
        this.eventId = success.incidentId;
        this.notes.Note = '';
        this.UserName = success.createdByName;
        this.attachment.clearQueue();

        if (success.hours == null) {
          this.hrsTracking = false;
        }
        else {
          this.hrsTracking = true;
        }

        this.incidentStartDateLabel = success.incidentDate;

        const tempIncidentDate = new Date(success.incidentDate);
        this.f.IncidentDate.setValue({
          date: {
            year: tempIncidentDate.getFullYear(),
            month: tempIncidentDate.getMonth() + 1,
            day: tempIncidentDate.getDate()
          },
          jsdate: tempIncidentDate
        });

        const tempDate = new Date(success.createdOn);

        this.f.FormDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });

        // this.f.hours.setValidators([
        //   Validators.pattern(Globals.NumberNumericRegexWithoutZero)
        // ]);

        // this.f.hours.setAsyncValidators([
        //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        //     this.f.selectedEquipment,
        //     this.f.dmIncidentsId,
        //     this.dmapi
        //   )
        // ]);

        // this.f.hours.updateValueAndValidity();

        var equipmentId = { Id: this.f.selectedEquipment.value };
        this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
          if (result) {
            this.selectedEquipmentHours = result;
          } else {
            this.selectedEquipmentHours = 0;
          }
        });

        this.f.selectedDialysisUnits.setValue(success.divisionId);
        this.f.selectedDialysisStation.setValue(success.stationId);
        this.f.selectedEquipment.setValue(success.equipmentId);
        this.f.hours.setValue(success.hours);
        this.selectedEquipmenttext = success.equipmentName;
        this.selectedDialysisStationtext = success.stationName;
        this.status = success.status;
        this.selectedUsertext = success.createdByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;

        this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
        this.selectedDialysisUnitstext = success.divisionName;

        this.f.selectedEquipment.setValue(success.equipmentId.toString());

        this.f.TroubleShootingTime.setValue(success.troubleshootingHours);

        this.f.FormDesignation.setValue(success.status);
        const time = this.TroubleShootingTimeList.filter(
          (item, index) => item.value === this.f.TroubleShootingTime.value
        );
        if (time.length > 0) {
        this.TroubleShootingTimeText = time[0].text;
        } else {
          this.TroubleShootingTimeText = success.troubleshootingHours;
        }

        this.StatusText = this.FormDesignationList.filter(
          (item, index) => item.value === this.f.FormDesignation.value
        )[0].text;

        this.DialysisUnitDropDownChanged();

        const IncidentObj = { Id: this.f.dmIncidentsId.value };
        this.dmapi
          .GetdmIncidentTransselectBydmIncidentDetaiId(IncidentObj)
          .subscribe(list => {
            list.forEach((errObj) => {
              // console.log(errObj);
              let obj = <any>{};
              obj.repairId = errObj.repairId;
              obj.errorCodeid = errObj.errorCodeId;
              obj.ErrorCode = errObj.errorCode;
              obj.errorDescription = errObj.errorDesc;
              obj.createdBy = errObj.createdby;
              obj.createdOn = errObj.createdOn;
              obj.steps = '<p>loading...</p>';
              obj.showSteps = false;
              this.ErrorTrans.push(obj);
              this.errorList.push(obj.errorCodeid);
            });
            this.ErrorTrans.forEach((errObj) => {
              const errObjId = { Id: errObj.errorCodeid };
              this.dmapi
          .GetErrorCodeWithStepsByID(errObjId)
          .subscribe(steplist => {
            // console.log(steplist);
            if ( steplist.length > 0 ) {
                errObj.steps = steplist[0].errorCodeTroubleShottingSteps;
                }
              });
          });
        } , error => {
                  AppComponent.addAlert(error.message,'error');
                }
        );
        this.dmapi
          .GetIncidentattachmentDetailsbydmIncidentRepairDetailid(IncidentObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });

        this.dmapi.GetIncidentnotesbydmIncidentRepairDetailid(IncidentObj).subscribe(list => {
          this.notes.NoteList = list;

        });

        this.selectedDialysisStationtext = success.stationName;
        this.selectedEquipmenttext = success.equipmentName;

        if(success.stationId != null)
        this.f.selectedDialysisStation.setValue(success.stationId.toString());
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }


  // GetErrorStepsHtml (steps: any) {
  //   console.log(steps);
  //   let result = '<table>';
  //   steps.forEach(step => {
  //     result +=  '<tr><td style="width:20%"><b>Step ' + step.stepno + ' </b><td style="width:80%"> <td>' + step.stepsDetail + '</td><tr>';
  //   });
  //   result += '</table>';
  //   return result;
  // }

  onDateChanged(event: IMyDateModel): void {}

  fillDialysisUnit() {

    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DialysisUnits = list.filter(
      //   x => x.text != "Home Program"
      // );

      // list.filter(x=> x.text == 'Home Program')[0].text = 'Home Pool';

      this.commonapi.FillDivision().subscribe(list => {
      // if(list.filter(x=>x.text == 'Home Program').length != 0)
      // {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }

      this.DialysisUnits = list;

      if (this.f.dmIncidentsId.value !== '0') {
        this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
          x => x.value === this.f.selectedDialysisUnits.toString()
        );
        // this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
      }
    });
  }

  fillEquipment(divisionID) {

    if (divisionID !== 0) {

      this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
        x => x.value === divisionID
      );
      if(this.selectedDialysisUnitsobj.length > 0 && this.selectedDialysisUnitsobj[0].text == 'Home Program')
      {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({text: 'Select', value: ''});
          this.Equipments = list;
        });
      }
      else
      {
        this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
          this.Equipments = list;
        });
      }
    } else {
      this.Equipments = [{ value: '', text: 'Select' }];
      this.f.selectedEquipment.setValue('');
    }
  }

  fillDialysisStation(divisionID) {

    if (divisionID !== 0) {
      this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
        this.DialysisStation = list;
      });
    } else {
      this.DialysisStation = [{ value: '', text: 'Select' }];
      this.f.selectedDialysisStation.setValue('');
    }
  }

  executeIncidentCreateUpdate = function(Source) {
    //console.log(this.IncidentForm.invalid);
    if (!this.IncidentForm.invalid)
    {
      if ( this.ErrorTrans.length == 0 && this.notes.Note == '' && this.f.dmIncidentsId.value == 0)
    {
        this.blnatLeastOne = true;
        return;
        //remarkCustomValidator.ErrorMessage = "Enter at least one note or error message require.";
    }
    else
    {
      this.blnatLeastOne = false;
    }
  }

    AppComponent.loading = true;
    // this.f.hoursout.updateValueAndValidity();
    // this.f.selectedOutsurfacedis.updateValueAndValidity();
    // this.f.selectedouthydraulicdis.updateValueAndValidity();

    this.isValidFormSubmitted = false;
    if (this.IncidentForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const IncidentCreate = this.prepareCreateViewModel();

    if (IncidentCreate.dmincidentdetailsid === '0') {
      this.dmapi.CreateDMIncidentDetails(IncidentCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.f.dmIncidentsId.setValue(success.dmincidentdetailsid);
          this.setModel();
          setTimeout(() => {
            if(this.divisionName != 'Home Program')
            {
              Globals.print();
            }

            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            }
         }, 3000);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.dmapi.UpdateDMIncidentDetails(IncidentCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.setModel();
          setTimeout(() => {
            Globals.print();
            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            }
         },3000);
          // if (Source !== 'Notes') {
          //   Globals.print();
          //   this.router.navigate([this.ListURL]);

          // } else {
          //   this.setModel();
          //   Globals.print();
          // }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    let IncidentCreate = <any>{};


    IncidentCreate.dmincidentdetailsid = this.f.dmIncidentsId.value;
    IncidentCreate.incidentid = this.eventId;
    if (this.f.IncidentDate.value !== null &&  this.f.IncidentDate.value !== undefined) {
      IncidentCreate.IncidentDate = Globals.GetAPIDate( this.f.IncidentDate.value.jsdate);
    }

    IncidentCreate.createdon = Globals.GetAPIDate(this.today);
    IncidentCreate.createdby = this.currentUserId;

    IncidentCreate.modifiedon = Globals.GetAPIDate(this.today);
    IncidentCreate.modifiedby = this.currentUserId;

    IncidentCreate.status = this.f.FormDesignation.value;
    IncidentCreate.divisionid = this.f.selectedDialysisUnits.value;
    IncidentCreate.equipmentid = this.f.selectedEquipment.value;
    IncidentCreate.stationid = this.f.selectedDialysisStation.value;
    IncidentCreate.troubleshootingHours = this.f.TroubleShootingTime.value;
    IncidentCreate.note = this.notes.Note;
    IncidentCreate.hours = this.f.hours.value;
    // errorcode
    IncidentCreate.dmRepairErrorIncidentTrans = this.ErrorTrans;
    // attachments
    IncidentCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    // Trouble shooting
    this.GenerateTroubleshottingList();
    IncidentCreate.troubleShootingIncidentTran = this.TroubleshottingData;
    // console.log(IncidentCreate);
    return IncidentCreate;
  }

  // TroubleShotting
  TroubleshottingData = [];
  fillTroubleshottingData(DMRepairId) {
    var dmrepairCreate = { Id: DMRepairId };
    this.dmapi
      .GetdmRepairTroubleShootinglstelectByDmrepairidAndErrorId(dmrepairCreate)
      .subscribe(list => {
        this.TroubleshottingData = list;
        //  console.log(this.TroubleshottingData);
      });
  }
  GenerateTroubleshottingList() {
    //   console.log(this.TroubleshottingData);
    for (let childObj in this.TroubleshottingData) {
      this.TroubleshottingData[
        childObj
      ].dmrepairId = this.f.dmRepairDetailsId.value;
      this.TroubleshottingData[childObj].createdby = this.currentUserId;
      this.TroubleshottingData[childObj].createdOn = this.today;
    }
  }
  ShowTroubleshooting(event) {
    event.target.style.color = 'red';
    if (
      this.ErrorTrans == undefined ||
      this.ErrorTrans == null ||
      this.ErrorTrans.length == 0
    ) {
      AppComponent.addAlert(
        "There is no error select for troubleshooting.",
        "error"
      );
      return false;
    }

    this.troubleShootingDialogRef = this.dialog.open(TrobleshootingDialog, {
      disableClose: true
    });

    var errorList = [];
    for (let childObj in this.ErrorTrans) {
      errorList.push(this.ErrorTrans[childObj].errorCodeid);
    }
    this.troubleShootingDialogRef.componentInstance.completed =
      this.status == "Completed";
    this.troubleShootingDialogRef.componentInstance.ErrorList = errorList;
    this.troubleShootingDialogRef.componentInstance.TrobleshootingData = this.TroubleshottingData;
    this.troubleShootingDialogRef.componentInstance.dialogRef.updateSize(
      "95%",
      "95%"
    );
    this.troubleShootingDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.TroubleshottingData = this.troubleShootingDialogRef.componentInstance.TrobleshootingData;
      }
      this.troubleShootingDialogRef = null;
    });
  }


  DialysisUnitDropDownChanged() {

    this.fillEquipment(this.f.selectedDialysisUnits.value);
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);

    var divisionSelected = this.DialysisUnits.filter(
      x => x.value === this.f.selectedDialysisUnits.value.toString()
      );

    if (divisionSelected.length > 0) {
      this.divisionName = divisionSelected[0].text;
    }

    if(this.f.dmIncidentsId.value === '0')
    {
      this.f.selectedEquipment.setValue('');
      this.f.hours.setValue('');
      this.selectedEquipmentHours = 0;
    }
  }

  EquipmentDropDownChanged() {

    if (this.f.dmIncidentsId.value === '0') {
      this.f.selectedEquipment.setAsyncValidators([
        DMRepairCustomValidators.EquipmentRepairStatusValidate(
          this.f.dmIncidentsId,
          this.dmapi
        )
      ]);
      this.f.selectedEquipment.updateValueAndValidity();
      var IncidentCreate = { Id: this.f.selectedEquipment.value };
      this.dmapi.GetEquipmentDetailsByID(IncidentCreate).subscribe(success => {

        this.EquipmentSerialNo = success.serialnumber;
        this.EquipmentInServiceDate = success.inservicedate;
        this.selectedEquipmenttext = success.equipmentcode;
        this.hrsTracking = success.hrsTracking;
        //this.setHoursInValidation(this.hrsTracking);
      });

      var equipmentId = { Id: this.f.selectedEquipment.value };
      this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });

    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const IncidentObj = { Id: this.f.dmIncidentsId.value };
        this.dmapi.DeleteDMIncidentDetailsByID(IncidentObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'DM Incident detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  // Error variables

  //ErrorDescription='';
  ErrorSearchTerm = '';
  errorSearchResult = [];
  ErrorAutoComplete = 'test';
  ErrorSelectedItem = { code: '', id: 0, desc: '' };
  ErrorTrans = [];
  ErrorCodeChange(type,fetchAll:boolean=true) {

    if(this.f.selectedEquipment.value > 0)
    {
      var IncidentCreate = <any>{};
      if(type == "code"){
        IncidentCreate.errorCodeSearchtext = this.f.ErrorSearchTerm.value;
        IncidentCreate.errorDesc = "";
      }
      else{
        IncidentCreate.errorDesc = this.f.ErrorDescription.value;
        IncidentCreate.errorCodeSearchtext = "";
      }
      IncidentCreate.equipmentId = this.f.selectedEquipment.value;
      if(fetchAll)
      this.errorSearchResult = [];
      this.dmapi.search_word(IncidentCreate).subscribe(list => {
        if(fetchAll){
        let y = list.split('\n');
        for (let childObj in y) {
          let stringToSplit = y[childObj];
          let x = stringToSplit.split('|');
          if(x[0] !== null && x[0] !== '')
          {
            //console.log(x[0]);
            this.errorSearchResult.push({ code: x[0], id: x[1], desc: x[2] });
          }
        }
      }

      var allSelectedPane = document.querySelectorAll(".cdk-overlay-pane");
        this.duplicateErrorCodes = this.checkDuplicateInObject('code',this.errorSearchResult,this.f.ErrorSearchTerm.value);
          if(this.duplicateErrorCodes){
            allSelectedPane[allSelectedPane.length - 1]['style']['min-width']='70%';
          }
          else{
            allSelectedPane[allSelectedPane.length - 1]['style']['min-width']='auto';
          }

      });
    }
  }

  getErrorPosts(option,type) {

    this.ErrorSelectedItem = this.errorSearchResult.filter(
      x => x.id === option.value
    )[0];

    if(this.ErrorSelectedItem && this.ErrorSelectedItem.code)
    this.f.ErrorSearchTerm.setValue(this.ErrorSelectedItem.code);
  }
  AddError() {
    if (this.ErrorSelectedItem.id !== null && this.ErrorSelectedItem.id > 0) {
      var temp = this.ErrorTrans.filter(
        x => x.errorCodeid == this.ErrorSelectedItem.id
      );
      // console.log(this.ErrorTrans);
      //   console.log(temp);
      if (temp.length == 0) {
        var obj = <any>{};

        obj.repairId = this.f.dmIncidentsId.value;

        obj.errorCodeid = this.ErrorSelectedItem.id;
        obj.ErrorCode = this.ErrorSelectedItem.code;
        obj.errorDescription = this.ErrorSelectedItem.desc;
        obj.createdBy = this.currentUserId;
        obj.createdOn = this.today;
        obj.showDelete = false;
        obj.steps = '<p>loading...</p>';
        obj.showSteps = false;
        if (obj.createdBy == this.currentUserId) {
          obj.showDelete = true;
        }
        this.ErrorTrans.push(obj);
        const errObjId = { Id: obj.errorCodeid };
        this.dmapi
        .GetErrorCodeWithStepsByID(errObjId)
        .subscribe(steplist => {
          if ( steplist.length > 0 ) {
              obj.steps = steplist[0].errorCodeTroubleShottingSteps;
              }
            });
        this.f.ErrorSearchTerm.setValue('');
        this.ErrorSelectedItem.desc = '';
        this.errorSearchResult = [];

      }
    }
  }
  DeleteError(index) {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ErrorTrans.splice(index, 1);
      }
      this.dialogRef = null;
    });
  }



  SetPageRights() {
    this.auth.GetPageRights('DM Incident').subscribe(list => {
      if (this.f.dmIncidentsId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.dmIncidentsId.value != '0') {
		  this.blnshowDelete = true;
		}
  }
  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }

  check4000HoursValidation(): boolean {

    if (this.f.hours.value - this.selectedEquipmentHours >= 4000) {
      // console.log('Inside If loop');
      return true;
    } else {
      // console.log('Inside Else Looop');
      return false;
    }
  }

  numberOnly(event): boolean {
    // this.f.hours.setValidators([
    //     Validators.pattern(Globals.NumberNumericRegexWithoutZero)
    // ]);

    // this.f.hours.setAsyncValidators([
    //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
    //     this.f.selectedEquipment,
    //     this.f.dmIncidentsId,
    //     this.dmapi
    //   )
    // ]);
    // this.f.hours.updateValueAndValidity();
    this.setHoursInValidation();

    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkDuplicateInObject(propertyName, inputArray,searchterm='') {
    var seenDuplicate = false;

    var result = _.groupBy(inputArray, c => c['code']);
        for (let prop in result) {
            if (result[prop].length > 1) {
                result[prop].forEach(function (item, newKey) {
                        item['duplicate'] = true;
                        seenDuplicate=true;
                });
            }
          }
    // var testObject = {};

    // inputArray.map(function(item) {
    //   var itemPropertyName = item[propertyName];
    //   if (itemPropertyName in testObject) {
    //     testObject[itemPropertyName].duplicate = true;
    //     item.duplicate = true;
    //     seenDuplicate = true;
    //   }
    //   else {
    //     testObject[itemPropertyName] = item;
    //     delete item.duplicate;
    //   }
    // });
    // else
    // if(inputArray && inputArray.length > 1 && inputArray.filter(x=>x.code==inputArray[0].code).length == inputArray.length)
    // {
    //   seenDuplicate = true;
    // }

    return seenDuplicate;
  }
  // GreaterValueCheck(){
  //   this.f.hoursout.updateValueAndValidity();
  //   this.f.selectedOutsurfacedis.updateValueAndValidity();
  //   this.f.selectedouthydraulicdis.updateValueAndValidity();

  // }

  setHoursInValidation(enable = true){

    if(enable){

      this.f.hours.setValidators([
        Validators.pattern(Globals.NumberNumericRegexWithoutZero)
    ]);

    this.f.hours.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.selectedEquipment,
        this.f.dmIncidentsId,
        this.dmapi
      )
    ]);
    this.f.hours.updateValueAndValidity();
    }
    else{
      this.f.hours.clearAsyncValidators();
      this.f.hours.clearValidators();
      this.f.hours.updateValueAndValidity();
    }

  }

}

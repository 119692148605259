import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { ErrorCodeApiService } from 'src/app/API/errorCodeApi.service';
import { InvApiService } from 'src/app/API/invapi.service';


@Component({
  selector: 'ErrorCodeSearch',
  templateUrl: './ErrorCodeSearch.component.html',

})
export class ErrorCodeSearchComponent {

  ShowSearch = false;
  searchText = 'Search Records';
  ErrorCode = '';
  ErrorCodeDescription = '';
  Manufacturer = '';
  Model = '';
  Category = '';
  blnDirtySearch = false;
  ManufacturerList = [];
  ModelList = [];
  CategoryList = [];

  constructor(private errorCodeAPI: ErrorCodeApiService, private router: Router,
    private commonapi: CommonApiService,
    private invapi: InvApiService,
    private dataGridService: TableHeadsService, ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = '/Admin/ErrorCodeDetail';
  currentPageNumber = 1;
  sortExpression = 'errorCode';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Error Code';
  defaultSort;


  ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'errorCodeId', '5');
    this.dataGridService.addHeader('Error Code', 'errorCode', '30');
    this.dataGridService.addHeader('Error Description', 'errorCodeDescription', '65');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('errorCode', false);
    this.fillCategories();
    this.fillManufacturers();
    this.fillModelList();
    this.CheckDirtySearch();
  }

  executeErrorCodeSearchClear() {
    this.ErrorCode = '';
    this.ErrorCodeDescription = '';
    this.Model = '';
    this.Manufacturer = '';
    this.Category = '';
    this.fillManufacturers();
    this.executeErrorCodeSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.ErrorCode || this.ErrorCodeDescription || this.Model || this.Manufacturer || this.Category
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeErrorCodeSearch();
  }

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeErrorCodeSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.ShowSearch = false;
    this.pageheader.searchText = 'Search Records';
    this.CheckDirtySearch();

    const errorCodeSearch = this.prepareSearch();
    this.errorCodeAPI.GetErrorCodeSerachDetails(errorCodeSearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.errorCodeList;
      //  console.log(pagedData);
      AppComponent.HideLoader();
    }
      ,
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    // tslint:disable-next-line:prefer-const
    let ErrorCodesearch = <any>{};
    ErrorCodesearch.currentPageNumber = this.currentPageNumber;
    ErrorCodesearch.sortExpression = this.sortExpression;
    ErrorCodesearch.sortDirection = this.sortDirection;
    ErrorCodesearch.PageSize = this.pageSize;

    if (this.ErrorCode) {
      ErrorCodesearch.errorCode = this.ErrorCode;
    }

    if (this.ErrorCodeDescription) {
      ErrorCodesearch.errorCodeDescription = this.ErrorCodeDescription;
    }

    if (this.Model) {
      const modelObj = this.ModelList.filter(
        x => x.value == this.Model
      );
      if (modelObj && modelObj.length > 0)
        ErrorCodesearch.Model = modelObj[0].text;
    }

    if (this.Manufacturer) {
      const manf = this.ManufacturerList.filter(
        x => x.value == this.Manufacturer
      );
      if (manf && manf.length > 0)
        ErrorCodesearch.Manufacture = manf[0].text;
    }
    return ErrorCodesearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeErrorCodeSearch();
  }

  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }


  fillModelList() {
    const manufacturerId = { categoryId: this.Category };
    this.invapi
      .FillModels(manufacturerId)
      .subscribe(list => {
        this.ModelList = list;
        this.Model='';
      });
  }

  fillManufacturers() {    
    const categoryId = { Id: this.Category };

    this.invapi.FillManufacturerList(categoryId).subscribe(list => {
      this.ManufacturerList = list;
      this.fillModelList();
      this.Model='';
      this.Manufacturer=''
    });
  }

  

fillCategories() {
  this.invapi.FillCategoryList().subscribe(list => {
    this.CategoryList = list;
  });
}
}


export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}


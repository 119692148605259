<form
    [formGroup]="DailyForm"
    (ngSubmit)="executeDailyCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Central Delivery Bicarb Powder
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    (addNoteClick)="notes.AddNote()"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeDailyCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">

                    <div class="box-body hidden">
                        <div class="row">
                            <div class="col-sm-12 box-row">
                                <label class="col-sm-2 control-label">Id</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="Id"

                                        formControlName="Id"
                                    >
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="box ibox box-info">

                        <div class="box-body">

                            <div class="row">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label">
                                            Event ID:
                                        </label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText">{{eventId}}</label>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    class="col-sm-12 box-row"
                                    *ngIf="f.Id.value > 0"
                                >
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label">
                                            Date:
                                        </label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText">
                                                {{f.DailyDate.value.jsdate | date:'dd/MM/yyyy'}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="col-sm-7 control-label">Time:</label>
                                        <div class="col-sm-5">
                                            <label class="readOnlyText">
                                                {{f.DailyDate.value.jsdate | date:'HH:mm'}}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>

                    <div class="box ibox box-info">
                        <div class="box-header with-border">
                            <h3 class="box-title">Dialysis Unit Details</h3>
                        </div>

                        <div class="box-body">

                            <div class="row">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label">
                                            Division:
                                        </label>
                                        <div
                                            class="col-sm-7"
                                            *ngIf="f.Id.value > 0"
                                        >
                                            <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                        </div>
                                        <div
                                            class="col-sm-7"
                                            *ngIf="f.Id.value == 0"
                                        >

                                            <select
                                                class="form-control mdInput"
                                                formControlName="selectedDialysisUnits"
                                                name="selectedDialysisUnits"

                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of DialysisUnits"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                            <span
                                                *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span>

                                        </div>
                                    </div>
                                    <div
                                        class="col-sm-6"
                                        *ngIf="f.Id.value > 0"
                                    >
                                        <label class="col-sm-7 control-label">
                                            Unit Staff:
                                        </label>

                                        <div class="col-sm-5">
                                            <label class="readOnlyText">{{selectedUsertext}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div class="box ibox box-info">
                                <div class="box-header with-border">
                                    <h3 class="box-title">Details</h3>
                                </div>

                                <div class="box-body">

                                    <div class="row">
                                      <div class="col-sm-12 box-row">
                                        <div class="col-sm-6">
                                            <label class="col-sm-5 control-label">
                                                Vendor:
                                            </label>
                                            <div
                                                class="col-sm-7"
                                                *ngIf="f.Id.value > 0"
                                            >
                                                <label class="readOnlyText">{{VendorName}}</label>
                                            </div>
                                            <div
                                                class="col-sm-7"
                                                *ngIf="f.Id.value == 0"
                                            >

                                                <select
                                                    class="form-control mdInput"
                                                    formControlName="selectedVender"
                                                    name="selectedVender"
                                                >
                                                    <option
                                                        value="{{item.value}}"
                                                        *ngFor="let item of VendorList"
                                                    >
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                                <span
                                                    *ngIf="f.selectedVender.errors?.required && (f.selectedVender.dirty || f.selectedVender.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>
                                                <span
                                                    *ngIf="f.selectedVender.errors?.pattern"
                                                    [ngClass]="'error'"
                                                >
                                                    Invalid number
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <label class="col-sm-5 control-label">
                                              Lot Number:
                                          </label>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.Id.value > 0"
                                          >
                                              <label class="readOnlyText">{{f.LotNumber.value}}</label>
                                          </div>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.Id.value == 0"
                                          >

                                              <input
                                                  class="form-control mdInput"
                                                  formControlName="LotNumber"
                                                  name="LotNumber"
                                              >

                                              <span
                                                  *ngIf="f.LotNumber.errors?.required && (f.LotNumber.dirty || f.LotNumber.touched ||  isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"
                                              >
                                                  Required!
                                              </span>

                                          </div>
                                      </div>
                                    </div>
                                    <div class="clearfix"></div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    Number of Units:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.Id.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.SelectedNumberOfUnit.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.Id.value == 0"
                                                >

                                                <select
                                                class="form-control mdInput"
                                                formControlName="SelectedNumberOfUnit"
                                                name="SelectedNumberOfUnit"
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of UnitList"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                                    <span
                                                        *ngIf="f.SelectedNumberOfUnit.errors?.required && (f.SelectedNumberOfUnit.dirty || f.SelectedNumberOfUnit.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    Size:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.Id.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.SelectedSize.value}} Kg.</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.Id.value == 0"
                                                >

                                                <select
                                                class="form-control mdInput"
                                                formControlName="SelectedSize"
                                                name="SelectedSize"
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of SizeList"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                                    <span
                                                        *ngIf="f.SelectedSize.errors?.required && (f.SelectedSize.dirty || f.SelectedSize.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>


                                    </div>
                                </div>
                            </div>

                    <notes
                        [isValidForm]="this.DailyForm.invalid"
                        (addNoteClick)="executeDailyCreateUpdate('Notes')"
                        #notes
                    >

                    </notes>
                    <attachment
                        (RaiseError)="AttachmentError($event)"
                        #attachment
                    >

                    </attachment>

            <PageHeader
                [showNote]="false"
                (addNoteClick)="notes.AddNote()"
                [showAddNew]="false"
                [showSearch]="false"
                [mainlisturl]="ListURL"
                [showSave]="blnshowSave"
                (SaveEvent)="executeDailyCreateUpdate('')"
                [showDelete]="blnshowDelete"
                (DeleteEvent)="DeleteEvent()"
                #pageheader
            ></PageHeader>
          </div>
        </div>
        </div>

    </section>
</form>

import { AuthService } from 'src/app/API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { InvApiService } from 'src/app/API/invapi.service';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { DMApiService } from 'src/app/API/dmapi.service';
import { Component, AfterViewInit, Inject, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickHomeEquipmentTransferModel } from './QuickHomeEquipmentTransferModel';
import { HomeService } from 'src/app/API/home.service';

@Component({
  selector: 'quick-home-equipment-transfer-dialog',
  templateUrl: './QuickHomeEquipmentTransfer.html',
  styleUrls: ['./QuickHomeEquipmentTransfer.css'],
})
export class QuickHomeEquipmentTransfer implements AfterViewInit {

  currentUserId = this.auth.GetUserId();
  DivisionList = [];
  dmEquipmentList = [];
  roEquipmentList = [];
  newEquipmentList = [];

  isValidFormSubmitted = false;
  data = { errormsg: '' };
  public quickTransferModel: QuickHomeEquipmentTransferModel;

  dialysisStationForm = new UntypedFormGroup({
    dialysisUnitId: new UntypedFormControl('0'),
    divisionId: new UntypedFormControl(''),
    dialysisStationName: new UntypedFormControl(''),
    dmEquipmentId: new UntypedFormControl('', Validators.required),
    roEquipmentId: new UntypedFormControl('', Validators.required),
    newEquipmentId: new UntypedFormControl('', Validators.required),
    modifiedBy: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
  });


  constructor(public dialogRef: MatDialogRef<QuickHomeEquipmentTransfer>,
    private commonapi: CommonApiService,
    private auth: AuthService,
    private invapi: InvApiService,
    private homeApi: HomeService,
    @Inject(MAT_DIALOG_DATA) public odata: QuickHomeEquipmentTransferModel
  ) {
    if (odata) {
      this.quickTransferModel = odata;
      this.f.dialysisStationName.setValue(this.quickTransferModel.stationName);
      this.f.dialysisUnitId.setValue(this.quickTransferModel.stationId);
      this.f.dmEquipmentId.setValue(this.quickTransferModel.dmEquipmentId);
      this.f.roEquipmentId.setValue(this.quickTransferModel.roEquipmentId);
      this.f.dmEquipmentId.disable();
      this.f.roEquipmentId.disable();
    }
  }

  @Output() SaveEvent = new EventEmitter<QuickHomeEquipmentTransferModel>();

  ngOnInit() {
    this.fillDMEquipments();
    this.fillNewDMEquipments();

    this.fillROEquipments();
    this.fillNewROEquipments();
  }

  ngAfterViewInit() {

  }

  executeStationsCreateUpdate = function () {
    AppComponent.loading = true;
    this.isValidFormSubmitted = true;
    if (this.dialysisStationForm.invalid) {
      this.data.errormsg = Globals.FormErrorMessage;
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      return;
    }

    const modelUpdate = this.prepareCreateViewModel();

    if (this.f.dialysisUnitId.value != '0'
    ) {
      this.homeApi.UpdateDialysisStationDetails(modelUpdate).subscribe(
        success => {
          AppComponent.loading = false;
          this.isValidFormSubmitted = false;

          AppComponent.HideLoader();
          AppComponent.addAlert('Data Saved successfully!', 'success');

          this.quickTransferModel.dmEquipmentId = success.dmEquipmentId;
          this.quickTransferModel.roEquipmentId = success.roEquipmentId;
          this.SaveEvent.emit(this.quickTransferModel);
          this.dialogRef.close(true);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const modelUpdate = <any>{};
    modelUpdate.dialysisUnitId = this.f.dialysisUnitId.value;
    modelUpdate.divisionId = this.f.divisionId.value;
    modelUpdate.dialysisStationName = this.f.dialysisStationName.value;
    modelUpdate.modifiedBy = this.currentUserId;
    modelUpdate.dmEquipmentId = this.f.dmEquipmentId.value;
    modelUpdate.roEquipmentId = this.f.roEquipmentId.value;
    const selectedDMEquipment = this.dmEquipmentList.filter(
      (item, index) => item.value == this.f.dmEquipmentId.value
    );
    if (selectedDMEquipment.length > 0) {
      modelUpdate.dmEquipmentName = selectedDMEquipment[0].text;
    }

    const selectedROEquipment = this.roEquipmentList.filter(
      (item, index) => item.value == this.f.roEquipmentId.value
    );
    if (selectedROEquipment.length > 0) {
      modelUpdate.roEquipmentName = selectedROEquipment[0].text;
    }


    if (this.quickTransferModel.type == "DM") {
      modelUpdate.dmEquipmentId = this.f.newEquipmentId.value;

      const selectedDMEquipment = this.dmEquipmentList.filter(
        (item, index) => item.value == this.f.newEquipmentId.value
      );
      if (selectedDMEquipment.length > 0) {
        modelUpdate.dmEquipmentName = selectedDMEquipment[0].text;
      }
    }
    else if (this.quickTransferModel.type == "RO") {
      modelUpdate.roEquipmentId = this.f.newEquipmentId.value;

      const selectedROEquipment = this.roEquipmentList.filter(
        (item, index) => item.value == this.f.newEquipmentId.value
      );
      if (selectedROEquipment.length > 0) {
        modelUpdate.roEquipmentName = selectedROEquipment[0].text;
      }
    }
    
    
    modelUpdate.modifiedBy = this.currentUserId;
    modelUpdate.modifiedOn = new Date();
    return modelUpdate;
  }


  fillDMEquipments() {
    const obj = {
      DialysisStationId: this.f.dialysisUnitId.value,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.dmEquipmentList = list;
    });
  }


  fillNewDMEquipments() {
    const obj = {
      DialysisStationId: -1,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      if (this.quickTransferModel.type == "DM"){
        this.newEquipmentList = list.filter(
          (item, index) => item.value != this.f.dmEquipmentId.value
        );
      }
    });
  }

  fillROEquipments() {
    const obj = {
      DialysisStationId: this.f.dialysisUnitId.value,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.roEquipmentList = list;
    });
  }

  fillNewROEquipments() {
    const obj = {
      DialysisStationId: -1,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      if (this.quickTransferModel.type == "RO"){
        this.newEquipmentList = list.filter(
          (item, index) => item.value != this.f.roEquipmentId.value
        );
      }
    });
  }

  get f() {
    return this.dialysisStationForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }
}


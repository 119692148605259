import { Component, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'ScheduleColor-dialog',
  templateUrl: './ColorDialog.html',
})
export class ScheduleColorDialog {

  public ScheduleColors = [];
  title = 'Schedule Color';

  constructor(public dialogRef: MatDialogRef<ScheduleColorDialog>) {
  }


  ngOnInit()
  {

  }

}

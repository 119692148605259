<div [formGroup]="equipmentExchangeForm" novalidate *ngIf="equipmentExchangeForm"
  style="border:1px solid lightgray;padding: 10px">
  <div class="modal-header  col-sm-12">
    <h4 class="modal-title col-sm-8">Equipment Exchange</h4>

    <div class="text-right  col-sm-4" style="padding:0">
      <!-- <button type="button" class="btn btn-danger" style="margin-right:5px"
        (click)="dialogRef.close(true)">Close</button>
      <button type="button" *ngIf="f.EquipmentExchangeId.value == ''" class="btn btn-primary" (click)="saveEquipmentExchange()">Save</button> -->
      <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>
    </div>
  </div>
  <div class="modal-body" style="position: static;">
    <div *ngIf="errormsg" class="box-row col-sm-12">
      <span [ngClass]="'error'">
        {{errormsg}}
      </span>
    </div>
    <div class="clearfix"></div>
    <div class="box-row marginBottom clearfix" style="padding-top: 20px;">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">Equipment Exchange Type:</label>
        <div class="col-sm-5" >
            <!-- *ngIf="f.EquipmentExchangeId.value == ''" -->
          <select formControlName="EquipmentExchangeType" class="form-control"
            (change)="EquipmentExchangeTypeChanged()">

            <option value="{{item.value}}" *ngFor="let item of CategoryList">{{item.text}}</option>
          </select>
          <span
            *ngIf="f.EquipmentExchangeType.errors?.required && (f.EquipmentExchangeType.dirty || f.EquipmentExchangeType.touched ||  formSubmitted)"
            [ngClass]="'error'"> Required!</span>
        </div>
        <!-- <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{ EquipmentExchangeTypeText }}</label>
        </div> -->
      </div>
    </div>

    <div *ngIf="f.EquipmentExchangeType.value != ''">
    <div class="box-row marginBottom clearfix">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">Old Equipment OUT:</label>
        <div class="col-sm-4" *ngIf="f.EquipmentExchangeId.value == ''">
          <select formControlName="OldEquipmentId" class="form-control" (change)="OldEquipmentIdChanged()">
            <option value="">Select</option>
            <option value="{{item.value}}" *ngFor="let item of OldEquipmentList">{{item.text}}</option>
          </select>
        </div>
        <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{OldEquipmentName}}</label>
        </div>
      </div>
      <div class="col-sm-5">
        <label class="control-label col-sm-6" style="padding-left: 10px;">Equipment Hours OUT:</label>
        <div class="col-sm-6" *ngIf="f.EquipmentExchangeId.value == ''">
          <input class="form-control smInput" formControlName="HoursOut" name="HoursOut" maxlength="5"
            (keypress)="numberOnly($event)">
          <label class="readOnlyTextForHours" style="padding: 4px 5px;"
            *ngIf="selectedOldEquipmentHours!=0 && selectedOldEquipmentHours!=null">({{selectedOldEquipmentHours}})</label>
          <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedOldEquipmentHours==0">(N/A)</label>
          <br />
          <span *ngIf="f.HoursOut.errors?.GreaterHoursOut" [ngClass]="'error'">
            Hrs IN => previous Hrs Out
          </span>
          <span *ngIf="check4000HoursValidation(false)" [ngClass]="'error'">
            New Hours >= 4000 from previous Hours Out.
          </span>
          <span
          *ngIf="f.HoursOut.errors?.required && (f.HoursOut.dirty || f.HoursOut.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>

        </div>
        <div class="col-sm-6" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{f.HoursOut.value }}</label>
        </div>
      </div>
    </div>
    <div class="box-row marginBottom clearfix">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">New Equipment IN:</label>
        <div class="col-sm-4" *ngIf="f.EquipmentExchangeId.value == ''">
          <select formControlName="NewEquipmentId" class="form-control" (change)="NewEquipmentIdChanged()">
            <option value="">Select</option>
            <option value="{{item.value}}" *ngFor="let item of NewEquipmentList">{{item.text}}</option>
          </select>
          <span
          *ngIf="f.NewEquipmentId.errors?.required && (f.NewEquipmentId.dirty || f.NewEquipmentId.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>

        </div>
        <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{NewEquipmentName}}</label>
        </div>
      </div>
      <div class="col-sm-5">
        <label class="control-label col-sm-6">Equipment Hours IN:</label>
        <div class="col-sm-6" *ngIf="f.EquipmentExchangeId.value == ''">
          <input class="form-control smInput" formControlName="HoursIn" name="HoursIn" maxlength="5"
            (keypress)="numberOnly($event)">
          <label class="readOnlyTextForHours" style="padding: 4px 5px;"
            *ngIf="selectedNewEquipmentHours!=0 && selectedNewEquipmentHours!=null">({{selectedNewEquipmentHours}})</label>
          <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedNewEquipmentHours==0">(N/A)</label>
          <br />
          <span *ngIf="f.HoursIn.errors?.GreaterHoursOut" [ngClass]="'error'">
            Hrs IN => previous Hrs Out
          </span>
          <span *ngIf="check4000HoursValidation(true)" [ngClass]="'error'">
            New Hours >= 4000 from previous Hours Out.
          </span>
          <span
          *ngIf="f.HoursIn.errors?.required && (f.HoursIn.dirty || f.HoursIn.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>

        </div>
        <div class="col-sm-6" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{f.HoursIn.value }}</label>
        </div>

      </div>
    </div>

    <div class="box-row marginBottom clearfix">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">Verify DM Patient Service Settings:</label>
        <div class="col-sm-4" *ngIf="f.EquipmentExchangeId.value == ''">
          <select formControlName="DMPtServiceSetting" class="form-control"
            [style.color]="getColor(f.DMPtServiceSetting.value,YesNoList)">
            <option value="{{item.value}}" *ngFor="let item of YesNoList"
              [style.color]="item.color ? item.color : textColor">{{item.text}}</option>
          </select>
          <span
          *ngIf="f.DMPtServiceSetting.errors?.required && (f.DMPtServiceSetting.dirty || f.DMPtServiceSetting.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>
        </div>
        <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{f.DMPtServiceSetting.value }}</label>
        </div>
      </div>

    </div>
    <div class="box-row marginBottom clearfix">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">Verify DM Acid Formulations:</label>
        <div class="col-sm-4" *ngIf="f.EquipmentExchangeId.value == ''">
          <select formControlName="DMAcidFormulation" class="form-control"
            [style.color]="getColor(f.DMAcidFormulation.value,YesNoList)">
            <option value="{{item.value}}" *ngFor="let item of YesNoList"
              [style.color]="item.color ? item.color : textColor">{{item.text}}</option>
          </select>
          <span
          *ngIf="f.DMAcidFormulation.errors?.required && (f.DMAcidFormulation.dirty || f.DMAcidFormulation.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>
        </div>
        <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText"
            [style.color]="getColor(f.DMAcidFormulation.value,YesNoList)">{{f.DMAcidFormulation.value }}</label>
        </div>
      </div>

    </div>
    <div class="box-row marginBottom clearfix">
      <div class="col-sm-7">
        <label class="control-label col-sm-7">Verify Equipment Proper Functioning:</label>
        <div class="col-sm-4" *ngIf="f.EquipmentExchangeId.value == ''">
          <select formControlName="EquipmentProperFunctioning" class="form-control"
            [style.color]="getColor(f.EquipmentProperFunctioning.value,PassFailList)">
            <option value="{{item.value}}" *ngFor="let item of PassFailList"
              [style.color]="item.color ? item.color : textColor">{{item.text}}</option>
          </select>
          <span
          *ngIf="f.EquipmentProperFunctioning.errors?.required && (f.EquipmentProperFunctioning.dirty || f.EquipmentProperFunctioning.touched ||  formSubmitted)"
          [ngClass]="'error'"> Required!</span>
        </div>
        <div class="col-sm-5" *ngIf="f.EquipmentExchangeId.value != ''">
          <label class="readOnlyText">{{f.EquipmentProperFunctioning.value }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class ErrorCodeApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}


    GetErrorCodeSerachDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/GetErrorCodeSerachDetails',
        SearchOptions,
        httpOptions
      );
    }

    CreateErrorCodeDetails(errorCodeDetails) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/CreateErrorCodeDetails',
        errorCodeDetails,
        httpOptions
      );
    }


    UpdateErrorCodeDetails(errorCodeDetails) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/UpdateErrorCodeDetails',
        errorCodeDetails,
        httpOptions
      );
    }

    DeleteErrorCodeDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/DeleteErrorCodeDetails',
        value,
        httpOptions
      );
    }

    GetErrorCodeByID(errorCode) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/GetErrorCodeByID',
        errorCode,
        httpOptions
      );
    }

    GetTroubleShootingStepsByErrorCodeId(errorCode) {
      return this.http.post<any>(
        this.apiUrl + 'ErrorCodeService/GetTroubleShootingStepsByErrorCodeId',
        errorCode,
        httpOptions
      );
    }


    GetErrorCodeNotes(value) {
      return this.http.post<any>(
        this.apiUrl +
          'ErrorCodeService/GetErrorCodeNotes',
        value,
        httpOptions
      );
    }

    GetErrorCodesAttachments(value) {
      return this.http.post<any>(
        this.apiUrl +
          'ErrorCodeService/GetErrorCodesAttachments',
        value,
        httpOptions
      );
    }

  }

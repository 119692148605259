import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Globals, actionType, attachmentFormModules } from 'src/app/globals';
import { DesignationApiService } from 'src/app/API/designationapi.service';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { SingleAttachmentComponent } from 'src/app/Core/Shared/SingleAttachmentComponent/SingleAttachmentComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { ContactApiService } from 'src/app/API/contactapi.service';



@Component({
  selector: 'DesignationDetail',
  templateUrl: './DesignationDetail.component.html'
})
export class DesignationDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  isValidFormSubmitted = null;

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachmentList: AttachmentComponent;

  ListURL = 'Admin/UserDesignationSearch';
  loading = true;
  today = new Date();
  blnshowSave = true;
  blnshowDelete = false;
  title = "User Designation Detail";
  DivisionList = [];


  DesignationForm = new UntypedFormGroup({
    designationId: new UntypedFormControl(''),
    designationName: new UntypedFormControl('', Validators.required),
    divisions: new UntypedFormControl('', Validators.required),
    ALLdivisionId: new UntypedFormControl(''),
    showAllSchedule: new UntypedFormControl(false),
    createdOn: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    isDelete: new UntypedFormControl(''),
  });

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.DesignationForm.dirty ||
        (this.notes !== undefined && this.notes.Note !== '' ) || this.attachmentList.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private designationAPI: DesignationApiService,
    private contactApi: ContactApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.fillDivisionList();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.f.designationId.setValue(params['id'] || '0');
        if (this.f.designationId.value !== '0') {
          this.setModel();
        } else {
        }
      });
      this.SetDesignationrights();

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();

    if(this.notes){
      this.notes.Note = '';
      this.notes.showHours = false;
    }

    if (this.attachmentList) {
      this.attachmentList.DocId = this.f.designationId.value;
      this.attachmentList.moduleName =
        attachmentFormModules[attachmentFormModules.designations];
      this.attachmentList.UploadBy = this.currentUserId;
    }

  }

  get f() {
    return this.DesignationForm.controls;
  }


  setModel() {
    AppComponent.ShowLoader();
    const DesignationCreate = { Id: this.f.designationId.value };

    this.designationAPI.GetDesignationByID(DesignationCreate).subscribe(
      success => {

        if(success){
        //this.f.designationId.setValue(success.designationId);
        this.f.designationName.setValue(success.designationname);
        this.f.designationId.setValue(success.designationid);
        this.f.showAllSchedule.setValue(success.showAllSchedule);
        this.f.createdOn.setValue(success.createdon);
        this.f.createdBy.setValue(success.createdby);
        this.f.isDelete.setValue(success.isdelete);


        if(this.f.designationName.value == 'Administrator' || this.f.designationName.value == 'Technologist' || this.f.designationName.value == 'Senior Technologist' || this.f.designationName.value == 'Admin-Technologist')
          this.f.designationName.disable();
        else
          this.f.designationName.enable();

        this.designationAPI
        .GetDivisionsByDesignationId(DesignationCreate)
        .subscribe(success1 => {
          let divisionIds = [];
          for (let childObj in success1) {
            divisionIds.push(success1[childObj].divisionid.toString());
          }
          this.f.divisions.setValue(divisionIds);

          if (this.DivisionList.length == divisionIds.length) {
            this.f.ALLdivisionId.setValue(['all']);
          }
        });


        if(this.notes){
          this.notes.Note = '';
          this.notes.showHours = false;
          this.designationAPI.GetDesignationNotes(DesignationCreate).subscribe(list => {
            this.notes.NoteList = list;
          });
        }

        if(this.attachmentList)
        {
          this.attachmentList.clearQueue();
          this.designationAPI
          .GetDesignationsAttachments(DesignationCreate)
          .subscribe(list => {
            this.attachmentList.PrevAttachments = list;

          });
        }

        AppComponent.HideLoader();
        }
        else{
          AppComponent.HideLoader();
        }
      },
      error => {

        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  fillDivisionList() {
      this.contactApi
      .FillDivisionWithPrefix()
      .subscribe(
        list =>
          (this.DivisionList = list.filter((item, index) => item.value !== ''))
      );
  }

  SetAllButtons(args)
  {
    if(args.source.checked==true)
   {
    let divisionIds = [];
    for (let childObj in this.DivisionList) {
      divisionIds.push(this.DivisionList[childObj].value);
    }
    this.f.divisions.setValue(divisionIds);
  }
  else
  {
    this.f.divisions.reset();
  }
}


CheckUncheckAll(){
  if (this.DivisionList.length == this.f.divisions.value.length) {
    this.f.ALLdivisionId.setValue(['all']);
  }
  else{
    this.f.ALLdivisionId.setValue([]);
  }
}

  onDateChanged(event: IMyDateModel): void { }

  executeDesignationCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.DesignationForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const DesignationCreate = this.prepareCreateViewModel();
    if (DesignationCreate.designationId &&  DesignationCreate.designationId === '0') {
      this.designationAPI.CreateDesignationDetails(DesignationCreate).subscribe(
        success => {
          console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else{
            this.f.designationId.setValue(success.designationId);
            this.setModel();
          }
        },
        error => {

          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.designationAPI.UpdateDesignationDetails(DesignationCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else{
            this.setModel();
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const DesignationCreate = <any>{};
    DesignationCreate.designationId = this.f.designationId.value;
    DesignationCreate.designationName = this.f.designationName.value;
    DesignationCreate.showAllSchedule = this.f.showAllSchedule.value;
    DesignationCreate.isDelete = this.f.isDelete.value;
    DesignationCreate.createdOn = this.f.createdOn.value;
    DesignationCreate.divisionIds = this.f.divisions.value;
    DesignationCreate.createdBy = this.f.createdBy.value ? this.f.createdBy.value : this.currentUserId;
    DesignationCreate.modifiedBy = this.currentUserId;



    if(this.notes)
      DesignationCreate.notes = this.notes.Note;
    if(this.attachmentList)
      DesignationCreate.attachmentDetails = this.attachmentList.GenerateAttachmentList();

    return DesignationCreate;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const DesignationObj = { Id: this.f.designationId.value };
        this.designationAPI.DeleteDesignationDetails(DesignationObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Designation detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  SetDesignationrights() {
  //   this.auth.GetPageRights('User Designation Form').subscribe(list => {
  //     if (this.f.designationId.value !== '0') {
  //       this.blnshowSave = list[actionType.edit] === '1';
  //       // this.blnshowDelete = list[actionType.delete] === '1';
  //     } else {
  //       this.blnshowSave = list[actionType.create] === '1';
  //       // this.blnshowDelete = false;
  //     }
  //   });

  //   if (this.userDesignation == 'Administrator' && this.f.designationId.value != '0') {
  //     this.blnshowDelete = true;
  //   }
   }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class ForumApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    GetAllForumCategories(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/GetAllForumCategories',
        vm,
        httpOptions
      );
    }

    CreateForumCategories(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/CreateForumCategories',
        vm,
        httpOptions
      );
    }

    UpdateForumCategories(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/UpdateForumCategories',
        vm,
        httpOptions
      );
    }

    DeleteForumCategoriesByID(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/DeleteForumCategoriesByID',
        vm,
        httpOptions
      );
    }

    GetForumCategoryByID(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/GetForumCategoryByID',
        vm,
        httpOptions
      );
    }

    GetForumCategoryDD() {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/GetForumCategoryDD',
        '',
        httpOptions
      );
    }

    CreateForumTopics(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/CreateForumTopics',
        vm,
        httpOptions
      );
    }

    UpdateForumTopics(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/UpdateForumTopics',
        vm,
        httpOptions
      );
    }

    DeleteForumTopicsByID(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/DeleteForumTopicsByID',
        vm,
        httpOptions
      );
    }

    GetForumSearchDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/GetForumSearchDetails',
        SearchOptions,
        httpOptions
      );
    }

    CreateForumPosts(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/CreateForumPosts',
        vm,
        httpOptions
      );
    }

    UpdateForumPosts(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/UpdateForumPosts',
        vm,
        httpOptions
      );
    }

    DeleteForumPostsByID(vm) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/DeleteForumPostsByID',
        vm,
        httpOptions
      );
    }

    SelectForumByID(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'ForumService/SelectForumByID',
        SearchOptions,
        httpOptions
      );
    }

    GetDivisionSearchDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetDivisionSearchDetails',
        SearchOptions,
        httpOptions
      );
    }

    CreateDivisionDetails(divisionDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/CreateDivisionDetails',
        divisionDetails,
        httpOptions
      );
    }


    UpdateDivisionDetails(divisionDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/UpdateDivisionDetails',
        divisionDetails,
        httpOptions
      );
    }

    DeleteDivision(value) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/DeleteDivisionDetails',
        value,
        httpOptions
      );
    }

    GetDivisionByID(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetDivisionByID',
        division,
        httpOptions
      );
    }


    GetContainerDetailsByDivisionId(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetContainersByDivisionId',
        division,
        httpOptions
      );
    }

    GetStationsDetailsByDivisionId(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetStationsByDivisionId',
        division,
        httpOptions
      );
    }

    GetDivisionNotes(value) {
      return this.http.post<any>(
        this.apiUrl +
          'DivisionService/GetDivisionNotes',
        value,
        httpOptions
      );
    }

    FillCountry() {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillCountry',
        '',
        httpOptions
      );
    }

    FillState(value) {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillState',
        value,
        httpOptions
      );
    }
  }

import { DMApiService } from 'src/app/API/dmapi.service';
import { AuthService } from './../../../API/auth.service';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { AppComponent } from 'src/app/app.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { PurposeInitialSiteVisitComponent } from '../../Purpose/purpose-initialsitevisit/purpose-initialsitevisit.component';
import { PurposeFullInstallationComponent } from '../../Purpose/purpose-fullinstallation/purpose-fullinstallation.component';
import { PurposeUninstallationComponent } from '../../Purpose/purpose-uninstallation/purpose-uninstallation.component';
import { PurposeMaintenanceComponent } from '../../Purpose/purpose-maintenance/purpose-maintenance.component';
import { PurposeRenovationComponent } from '../../Purpose/purpose-renovation/purpose-renovation.component';
import { PurposeOtherComponent } from '../../Purpose/purpose-other/purpose-other.component';
import { PurposeRepairComponent } from '../../Purpose/purpose-repair/purpose-repair.component';
import { PurposeTestingComponent } from '../../Purpose/purpose-testing/purpose-testing.component';
import { PurposeEquipmentExchangeComponent } from '../../Purpose/purpose-equipmentexchange/purpose-equipmentexchange.component';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SectionPurposeDetailDialog } from '../../Section/section-purposedetail/section-purposedetail.component';
import { Globals } from 'src/app/globals';
import { InvApiService } from 'src/app/API/invapi.service';
import { SectionPrepdetailDialog } from '../section-prepdetail/section-prepdetail.component';


@Component({
  selector: 'app-panel-purpose-detail',
  templateUrl: './panel-purpose-detail.component.html',
  styleUrls: ['./panel-purpose-detail.component.css']
})
export class PanelPurposeDetailComponent implements OnInit {

  public today = new Date();

  isCollapsed = false;
  PurposeDetailsEntered = false;
  purposeInProgress = false;
  // @Input() MRMHomeStationName = "";
  // @Input() HFServiceVisitPurpose = "";
  // @Input() HFSStationId = 0;
  @Input() HFMasterServiceId = 0;
  @Input() HFServiceConnectedId = 0;
  @Input() PrevSectionDetails: any;
  @Input() MasterStationID = 0;
  @Input() MasterCompleted: boolean = false;
  @Input() LastDepartureDate: Date = this.today;
  sectionDetailsFetched: boolean = false;
  @Input() isLastAdded : boolean = false;

  @Output() SaveEvent = new EventEmitter<any>();


  @ViewChild('notesVisitPurposePlus', { static: true }) notesVisitPurposePlus: NotesComponent;
  @ViewChild('attachmentVisitPurposePlus', { static: false }) attachmentVisitPurposePlus: AttachmentComponent;

  @ViewChild(PurposeTestingComponent, { static: false })
  purposeTesting: PurposeTestingComponent;

  @ViewChild(PurposeInitialSiteVisitComponent, { static: false })
  purposeInitialSiteVisit: PurposeInitialSiteVisitComponent;

  @ViewChild(PurposeFullInstallationComponent, { static: false })
  purposeFullInstallation: PurposeFullInstallationComponent;

  @ViewChild(PurposeUninstallationComponent, { static: false })
  purposeUnInstallation: PurposeUninstallationComponent;

  @ViewChild(PurposeEquipmentExchangeComponent, { static: false })
  purposeEquipmentExchange: PurposeEquipmentExchangeComponent;

  @ViewChild(PurposeMaintenanceComponent, { static: false })
  purposeMaintenance: PurposeMaintenanceComponent;

  @ViewChild(PurposeRenovationComponent, { static: false })
  purposeRenovation: PurposeRenovationComponent;

  @ViewChild(PurposeOtherComponent, { static: false })
  purposeOther: PurposeOtherComponent;

  @ViewChild(PurposeRepairComponent, { static: false })
  purposeRepair: PurposeRepairComponent;

  isBluePanelSection = false;
  MRMHomeStationName = '';
  isAdditionalAddArrival = false;
  isAdditionalAddDeparture = false;
  purposeSelectedId = '';
  PurposeDetailsList = [];
  PrepDetailsList = [];
  EquipmentExchangeCategoryList = [];
  RepairCategoryList = [];
  noteModule = "";
  DivisionList = [];
  selectedDivisionObj;
  PartTrans = [];
  TechnologistsList = [];

  prepTimeSummary;
  travellingTimeToPtHome;
  travellingTimeSpentAtPtHome;
  travellingTimeToHospital;
  totalTime: string = '';
  totalTechTime: string = '';
  totTechTimeDigits;
  totalPrepTime;
  totalPrepDetailTime: string = '';
  totalTechTestingTime: string = '';
  totalTravellingTime: string = '';

  PrepTimeList = [
    { value: '', text: 'Select' },
    { value: '0.5', text: '0.5' },
    { value: '1.0', text: '1.0' },
    { value: '1.5', text: '1.5' },
    { value: '2.0', text: '2.0' },
    { value: '2.5', text: '2.5' },
    { value: '3.0', text: '3.0' },
  ];

  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: false,
    enableCheckAll: false
  };

  visitPurposeWithoutRenovationList = [
    { value: 'Prep Details', text: 'Prep Details', isFormSubmitted: false },
    { value: 'Equipment Exchange', text: 'Equipment Exchange', isFormSubmitted: false },
    { value: 'Maintenance & Testing', text: 'Maintenance & Testing', isFormSubmitted: false },
    { value: 'Repair', text: 'Repair', isFormSubmitted: false },
    { value: 'Other', text: 'Other', isFormSubmitted: false },
  ];

  visitPurposeRenovationList = [
    { value: 'Prep Details', text: 'Prep Details', isFormSubmitted: false },
    { value: 'Renovation & Full Installation', text: 'Renovation & Full Installation', isFormSubmitted: false },
  ];

  YesNoList = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];

  currentUserId = this.auth.GetUserId();

  panelPurposeForm = new UntypedFormGroup({
    HFServiceMasterId: new UntypedFormControl('0'),
    HFServiceConnectedId: new UntypedFormControl(''),
    HFServiceStationId: new UntypedFormControl(''),
    HFServicePTHMPurposeCompletedOn: new UntypedFormControl(''),
    HFServiceVisitPurpose: new UntypedFormControl(''),
    HFServicePTHMArrivalTime: new UntypedFormControl(''),
    HFServicePTHMDepartureTime: new UntypedFormControl(''),
    HFServicePTHMPurposeCompleted: new UntypedFormControl(''),
    HFServiceStatus: new UntypedFormControl(''),
    HFServicePTHMPrevDepartureTime: new UntypedFormControl(''),

    HFServiceCode: new UntypedFormControl(''),
    HFServiceStationName: new UntypedFormControl(''),
    HFServiceVisitDate: new UntypedFormControl(''),
    HFServiceVisitNotification: new UntypedFormControl(''),
    HFServiceVisitNotificationNotes: new UntypedFormControl(''),
    HFServiceVisitDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVisitDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceVehiclePrepTime: new UntypedFormControl(''),
    HFServiceVehiclePrepDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVehiclePrepDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceVehicleStartTime: new UntypedFormControl(''),
    HFServiceVehicleTechnologistId: new UntypedFormControl(''),
    HFServiceVehicleTechnologistName: new UntypedFormControl(''),
    HFServiceVehicleId: new UntypedFormControl(''),
    HFServiceVehicleName: new UntypedFormControl(''),
    HFServiceVehicleStartedKM: new UntypedFormControl(''),
    HFServiceVehicleInspectionStatus: new UntypedFormControl(null),
    HFServiceVehicleInspectionNotes: new UntypedFormControl(''),
    HFServiceVehicleDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVehicleDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceNextStep: new UntypedFormControl(''),
    HFServicePTHMPurposeCompletedBy: new UntypedFormControl(''),
    HFServiceReturnVehicleEndTime: new UntypedFormControl(''),
    HFServiceReturnVehicleEndedKM: new UntypedFormControl(''),
    HFServiceFuelAdded: new UntypedFormControl(''),
    HFServiceFuelCompany: new UntypedFormControl(''),
    HFServiceFuelAmount: new UntypedFormControl(''),
    HFServiceFuelPaymentBy: new UntypedFormControl(''),
    HFServiceFuelReceiptPath: new UntypedFormControl(''),
    HFServiceReturnDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceReturnDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceCreatedBy: new UntypedFormControl(''),
    HFServiceCreatedOn: new UntypedFormControl(''),
    HFServiceModifiedBy: new UntypedFormControl(''),
    HFServiceModifiedOn: new UntypedFormControl(''),
    HFServicelastModifiedSection: new UntypedFormControl(''),
    HFServiceCategory: new UntypedFormControl(''),
    HFServiceTaskCategory: new UntypedFormControl(''),
    HFServiceIssue: new UntypedFormControl(''),
    IsDelete: new UntypedFormControl(''),
    HFServiceOtherStationId: new UntypedFormControl(''),
    ISVStationId: new UntypedFormControl(''),
    ISVPhoneNumber: new UntypedFormControl(''),
    ISVAddress: new UntypedFormControl(''),
    ISVProvince: new UntypedFormControl('Ontario'),
    ISVCity: new UntypedFormControl(''),
    ISVPostalCode: new UntypedFormControl(''),
    ISVDirections: new UntypedFormControl(''),
    ISVAccessCode: new UntypedFormControl(''),
    HFServiceVisitNotificationEnteredOn: new UntypedFormControl(''),
    HFServiceVisitNotificationEnteredBy: new UntypedFormControl(''),
    poPlumber: new UntypedFormControl(''),
    poElectrician: new UntypedFormControl(''),
    poMovers: new UntypedFormControl(''),
    poPlumberNote: new UntypedFormControl(''),
    poElectricianNote: new UntypedFormControl(''),
    poMoversNote: new UntypedFormControl(''),
    isHFSReferenced: new UntypedFormControl(false),
    TaskId: new UntypedFormControl(''),
  });

  constructor(
    private dmapi: DMApiService,
    private homefieldApi: HomeFieldService,
    public dialog: MatDialog,
    private auth: AuthService,
    private invapi: InvApiService,
  ) { }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.LastDepartureDate = this.today = new Date(currentDate);

    this.fillUserList();
    this.f.HFServiceMasterId.setValue(this.HFMasterServiceId);
    this.f.HFServiceConnectedId.setValue(this.HFServiceConnectedId);
    // this.f.HFServiceVisitPurpose.setValue(this.HFServiceVisitPurpose);
    // this.f.HFServiceStationId.setValue(this.HFSStationId);


    if(this.MasterCompleted)
      this.isBluePanelSection = true;

    setTimeout(() => {
      this.notesVisitPurposePlus.showHours = false;
      this.setModel();
      this.fillEquipmentCategories();
    }, 100);
    }, error => {
    });
  }

  ngAfterViewInit() {
    this.setAttachmentModule();
  }

  executeHomeFieldServiceCreateUpdate(isInnerSaved = false) {
    this.refreshSections();
    //
    if (this.notesVisitPurposePlus.Note && this.notesVisitPurposePlus.Note.includes('Other Purpose - '))
      this.AddOtherPurpose(this.notesVisitPurposePlus.Note);



    // if(!this.PurposeDetailsEntered || this.f.HFServicePTHMPurposeCompleted.value != 'Yes') {
    //   this.OpenPurposeDetail(this.purposeInProgress);
    //   return;
    // }
    if (!isInnerSaved) {
      if (this.purposeInProgress) {
        if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
          this.purposeInitialSiteVisit.save().then(() => {
            if (!this.purposeInitialSiteVisit.ISVForm.invalid && this.purposeInProgress) {
              this.OpenPurposeDetail(true);
              return;
            }
            this.afterSaveEvent();
          }, () => {
            AppComponent.addAlert("Error", 'error');
            AppComponent.HideLoader();
          });
        }
        //} else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
        // this.purposeFullInstallation.save().then(() => {
        //   if (!this.purposeFullInstallation.fullInstallationForm.invalid && this.purposeInProgress) {
        //     this.OpenPurposeDetail(true);
        //     return;
        //   }
        //   this.afterSaveEvent();
        // }, () => {
        //   AppComponent.addAlert("Error", 'error');
        //   AppComponent.HideLoader();
        // });
        //}
        else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {

          this.purposeUnInstallation.save().then(() => {
            if (!this.purposeUnInstallation.unInstallationForm.invalid && this.purposeInProgress) {

              this.OpenPurposeDetail(true);
              return;
            }
            this.afterSaveEvent();
          }, () => {
            AppComponent.addAlert("Error", 'error');
            AppComponent.HideLoader();
          });
        }
        else {
          this.OpenPurposeDetail(true);
          return;
        }
      }
      else {
        this.OpenPurposeDetail();
        return;
      }
    } else {
      const modelCreate = this.prepareCreateViewModel();

      if (this.f.HFServiceConnectedId.value === '0') {
        this.homefieldApi.CreateHomeFieldService_Master(modelCreate).subscribe(
          success => {
            // this.f.HFServiceConnectedId.value = success.hfServiceId;
            if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
              this.purposeInitialSiteVisit.save().then(() => {
                this.afterSaveEvent();
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            //else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
            // this.purposeFullInstallation.save().then(() => {
            //   this.afterSaveEvent();
            // }, () => {
            //   AppComponent.addAlert("Error", 'error');
            //   AppComponent.HideLoader();
            // });
            //}
            else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {
              this.purposeUnInstallation.save().then(() => {
                this.afterSaveEvent();
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Other') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Repair') {

            }
            else {
              this.afterSaveEvent();
            }
          },
          error => {
            if (error.error && error.error.returnMessage) {
              AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            }
            else
              AppComponent.addAlert(error.message, 'error');

            AppComponent.HideLoader();
          }
        );
      } else {
        AppComponent.HideLoader();
        this.homefieldApi.UpdateHomeFieldService_Master(modelCreate).subscribe(
          success => {

            if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
              this.purposeInitialSiteVisit.save().then(() => {
                this.afterSaveEvent();
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
              // this.purposeFullInstallation.save().then(() => {
              //   this.afterSaveEvent();
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {
              // this.afterSaveEvent();
              this.purposeUnInstallation.save().then(() => {
                this.afterSaveEvent();
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Other') {
              this.afterSaveEvent();
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Repair') {
              this.afterSaveEvent();
            }
            else {
              this.afterSaveEvent();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
    }
    // AppComponent.loading = true;




  }


  afterSaveEvent() {
    this.SaveEvent.emit();
    this.setModel();
  }

  get f() {
    return this.panelPurposeForm.controls;
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  displaySaveButton(type) {

    if (type == "VisitPurposePlus") {
      if (this.f.HFServiceStatus.value == 'Completed') {
        if (this.attachmentVisitPurposePlus && this.attachmentVisitPurposePlus.uploader &&
          this.attachmentVisitPurposePlus.uploader.queue && this.attachmentVisitPurposePlus.uploader.queue.length > 0) {
          return true;
        }
      }
      else {
        if (this.f.HFServicePTHMPurposeCompleted.value == 'Yes') {
          return true;
        }
      }
    }
    return false;
  }

  fetchPurposeSectionDetails(showLoader = true) {
    return new Promise((resolve, reject) => {

      if (showLoader)
        AppComponent.ShowLoader();
        //
      this.homefieldApi.GetHomeFieldService_SectionPurposeDetails({ Id: this.f.HFServiceConnectedId.value }).subscribe(
        success => {
//
          this.sectionDetailsFetched = true;
          this.PurposeDetailsList = success.filter(x => x.enteredByHFServiceId == this.f.HFServiceMasterId.value);
          var tempArrival = false;
          if (this.PurposeDetailsList && this.PurposeDetailsList.length > 0) {

            this.f.HFServicePTHMArrivalTime.setValue(this.PurposeDetailsList[0].hfServicePTHMArrivalTime);
            this.f.HFServicePTHMDepartureTime.setValue(this.PurposeDetailsList[0].hfServicePTHMDepartureTime);
            this.f.HFServicePTHMPurposeCompleted.setValue(this.PurposeDetailsList[0].hfServicePTHMPurposeCompleted);
            tempArrival = true;
          }

          setTimeout(() => {

            if((this.f.HFServicelastModifiedSection.value == 'VisitVehicleDetails' || this.f.HFServicelastModifiedSection.value == 'VisitPrepDetails')  && this.f.isHFSReferenced.value == true && this.isLastAdded){
              this.isAdditionalAddArrival = (!tempArrival || (this.f.HFServicePTHMArrivalTime.value && this.f.HFServicePTHMDepartureTime.value)) && this.f.HFServicePTHMPurposeCompleted.value != 'Yes' && !this.MasterCompleted;
              this.isAdditionalAddDeparture = (tempArrival && this.f.HFServicePTHMArrivalTime.value && !this.f.HFServicePTHMDepartureTime.value) && this.f.HFServicePTHMPurposeCompleted.value != 'Yes' && !this.MasterCompleted;
            }

            if (this.isAdditionalAddArrival || this.isAdditionalAddDeparture){
              this.ExpandSection(true);
              this.isBluePanelSection = true;
            }
            else
              this.ExpandSection(false);

            resolve(true);
          }, 100);


          if (showLoader)
            AppComponent.HideLoader();

        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            reject(error.error.returnMessage.toString());
          }
          else {
            AppComponent.addAlert(error.message, 'error');
            reject(error.message.toString());
          }

          if (showLoader)
            AppComponent.HideLoader();
        }
      );

    });
  }

  purposeSelection(item) {

    if (item.value == "Equipment Exchange") {
      this.OpenEquipmentExchangePurpose();
    }
    else if (item.value == "Maintenance & Testing") {
      this.OpenMaintenancePurpose(item.value);
    }
    else if (item.value == "Repair") {
      this.OpenRepairPurpose();
    }
    else if (item.value == "Renovation & Full Installation") {
      this.OpenMaintenancePurpose(item.value);
    }
    else if (item.value == "Other") {
      this.notesVisitPurposePlus.AddNote('Other Purpose - ');
    }
    else if (item.value == "Prep Details") {
      this.OpenPrepDetail();
    }
  }

  SectionPurposeDetailDialogRef: MatDialogRef<SectionPurposeDetailDialog>;
  OpenPurposeDetail(inProgress = false) {

    // if (inProgress && (this.f.HFServiceVisitPurpose.value != 'Initial Site Visit' &&
    //   this.f.HFServiceVisitPurpose.value != 'Renovation & Full Installation' &&
    //   this.f.HFServiceVisitPurpose.value != 'Uninstallation')) {
    //   var PurposeCompleted = this.visitPurposeWithoutRenovationList.filter((item, index) => item.text == this.f.HFServiceVisitPurpose.value);

    //   //Temp Disable Validation to Save One Purpose

    //   // if (PurposeCompleted && !PurposeCompleted[0].isFormSubmitted) {
    //   //   AppComponent.addAlert('Before Departure, Tech needs to complete "' + this.f.HFServiceVisitPurpose.value + '" purpose.', 'error');
    //   //   this.purposeSelectedId = this.f.HFServiceVisitPurpose.value;
    //   //   setTimeout(() => {
    //   //     this.purposeSelection(PurposeCompleted[0]);
    //   //   }, 1000);
    //   //   AppComponent.HideLoader();
    //   //   return;
    //   // }
    // }
    //
    if (inProgress && (this.f.HFServiceVisitPurpose.value != 'Initial Site Visit' &&
      // this.f.HFServiceVisitPurpose.value != 'Renovation & Full Installation' &&
      this.f.HFServiceVisitPurpose.value != 'Uninstallation')) {
      var PurposeCompleted = this.visitPurposeWithoutRenovationList.filter((item, index) => item.text == this.f.HFServiceVisitPurpose.value);
      if (PurposeCompleted && !PurposeCompleted[0].isFormSubmitted && (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing' || this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation')) {
        AppComponent.addAlert('Before Departure, Tech needs to complete "' + this.f.HFServiceVisitPurpose.value + '" purpose.', 'error');
        this.purposeSelectedId = this.f.HFServiceVisitPurpose.value;
        setTimeout(() => {
          this.purposeSelection(PurposeCompleted[0]);
        }, 1000);
        AppComponent.HideLoader();
        return;
      }

      var purposeSubmitted = this.visitPurposeWithoutRenovationList.filter((item, index) => item.isFormSubmitted == true);
      if ((purposeSubmitted && purposeSubmitted.length == 0) && (this.notesVisitPurposePlus.NoteList.length == 0)) {
        AppComponent.addAlert('Before Departure, Tech needs to complete atleast one purpose or need to add one note.', 'error');
        AppComponent.HideLoader();
        return;
      }
    }

    AppComponent.ShowLoader();
    this.SectionPurposeDetailDialogRef = this.dialog.open(SectionPurposeDetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    this.SectionPurposeDetailDialogRef.componentInstance.showDepartureDetail = inProgress;
    this.SectionPurposeDetailDialogRef.componentInstance.YesNoList = this.YesNoList;
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.DialysisUnitId.setValue(this.MasterStationID);
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.EnteredByHFServiceId.setValue(this.f.HFServiceMasterId.value);

    //Reverse for Saving Reference Details
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceConnectedId.setValue(this.f.HFServiceMasterId.value);
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceVehicleStartTime.setValue(this.LastDepartureDate);

    // if (inProgress)
    //   this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServicePTHMDepartureTime.setValue(this.today);
    // else
    //   this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServicePTHMArrivalTime.setValue(this.today);

    let inProgressPurposeDetails = this.PurposeDetailsList.filter(x => x.hfServicePTHMDepartureTime == '' || x.hfServicePTHMDepartureTime == null);
    this.SectionPurposeDetailDialogRef.componentInstance.setModel(inProgressPurposeDetails, inProgress);

    this.SectionPurposeDetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      this.f.HFServicelastModifiedSection.setValue("VisitPurposePlusDetails");

      if (evt.hfServicePTHMPurposeCompleted) {
        this.f.HFServicePTHMPurposeCompleted.setValue(evt.hfServicePTHMPurposeCompleted);
        this.f.isHFSReferenced.setValue(false);

        if (evt.hfServicePTHMPurposeCompleted == 'Yes')
          this.f.HFServiceStatus.setValue('Completed');
        else {
          this.f.HFServiceReturnDetailsEnteredOn.setValue(evt.hfServicePTHMDepartureTime);
          this.f.HFServiceReturnVehicleEndTime.setValue(evt.hfServicePTHMDepartureTime);
          this.f.HFServiceReturnDetailsEnteredBy.setValue(this.currentUserId);
          this.f.HFServicelastModifiedSection.setValue("ReturnDetails");
        }
        this.f.HFServicePTHMPurposeCompletedOn.setValue(evt.hfServicePTHMDepartureTime);
        this.saveHFSVehicleDetails(evt);
      }

      this.onChangeArrival();
      this.SaveEvent.emit(evt);

      this.fetchPurposeSectionDetails();
      // this.executeHomeFieldServiceCreateUpdate('Section5', false, true);

      AppComponent.addAlert('Data Saved successfully!', 'success');
    });
    this.SectionPurposeDetailDialogRef.afterClosed().subscribe(result => {


      if (result && !this.f.HFServicePTHMArrivalTime.value && result['HFServicePTHMArrivalTime']) {
        this.f.HFServicePTHMArrivalTime.setValue(result['HFServicePTHMArrivalTime']);
      }

      if (result && !this.f.HFServicePTHMDepartureTime.value && result['HFServicePTHMDepartureTime']) {
        this.f.HFServicePTHMDepartureTime.setValue(result['HFServicePTHMDepartureTime']);
        this.f.HFServicePTHMPurposeCompleted.setValue(result['HFServicePTHMPurposeCompleted']);
      }

      this.onChangeArrival();
      this.setChildMRMID();
      this.SectionPurposeDetailDialogRef = null;

      if (inProgress && result)
        this.executeHomeFieldServiceCreateUpdate(true);
    });

    AppComponent.HideLoader();

  }

  saveHFSVehicleDetails(evt) {
    const createVehicleModel = this.vehicleViewModel();

    if (this.PrevSectionDetails) {
      this.homefieldApi.CreateHomeFieldService_Section_VehicleDetails(createVehicleModel).subscribe(
        success => {

          if (success) {
            this.saveHFSReturnDetails(success, evt);
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
          }
          else {
            AppComponent.addAlert(error.message, 'error');
          }
        }
      );
    }
  }

  vehicleViewModel() {
    const modelCreate = <any>{};
    // modelCreate.HFServiceVehicleDetailId = ;
    modelCreate.HFServiceVehicleId = this.PrevSectionDetails.hfServiceVehicleId;
    modelCreate.HFServiceVehicleName = this.PrevSectionDetails.hfServiceVehicleName;

    modelCreate.HFServiceVehicleStartedKM = this.PrevSectionDetails.hfServiceVehicleStartedKM;
    modelCreate.HFServiceVehicleStartTime = this.PrevSectionDetails.hfServiceVehicleStartTime;
    modelCreate.HFServiceVehicleInspectionStatus = this.PrevSectionDetails.hfServiceVehicleInspectionStatus;
    modelCreate.CreatedOn = this.PrevSectionDetails.createdOn;
    modelCreate.CreatedBy = this.PrevSectionDetails.createdBy;
    modelCreate.HFServiceId = this.f.HFServiceConnectedId.value;
    modelCreate.isAddedFromMasterHFS = true;
    return modelCreate;
  }

  saveHFSReturnDetails(vehicleSuccess, evt) {
    const returnCreate = this.returnViewModel(vehicleSuccess.hfServiceVehicleDetailId, evt);
    this.homefieldApi.CreateHomeFieldService_Section_ReturnDetails(returnCreate).subscribe(
      success => {
      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  returnViewModel(HFServiceVehicleDetailId, evt) {
    const modelCreate = <any>{};

    //modelCreate.HFServiceReturnDetailsId = ;
    modelCreate.HFServiceId = this.f.HFServiceConnectedId.value;
    modelCreate.HFServiceReturnVehicleEndTime = Globals.GetAPIDateWithoutSeconds(evt.hfServicePTHMDepartureTime);
    modelCreate.HFServiceReturnVehicleEndedKM = this.PrevSectionDetails.hfServiceVehicleEndedKM;
    modelCreate.HFServiceFuelAmount = 0;
    modelCreate.CreatedOn = this.today;
    modelCreate.CreatedBy = this.currentUserId;
    modelCreate.isAddedFromMasterHFS = true;

    modelCreate.HFServiceVehicleDetailId = HFServiceVehicleDetailId;
    return modelCreate;
  }

  onChangeArrival(): void {

    switch (this.f.HFServiceVisitPurpose.value) {
      case "Initial Site Visit": {
        if (this.purposeInitialSiteVisit)
          this.purposeInitialSiteVisit.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Renovation & Full Installation": {
        if (this.purposeFullInstallation)
          this.purposeFullInstallation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Uninstallation": {
        if (this.purposeUnInstallation)
          this.purposeUnInstallation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Equipment Exchange": {
        if (this.purposeEquipmentExchange)
          this.purposeEquipmentExchange.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Maintenance & Testing": {
        if (this.purposeMaintenance) {
          this.purposeMaintenance.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
          this.purposeMaintenance.PtHomeArrivalTime = this.f.HFServicePTHMArrivalTime.value;
        }
        break;
      }
      // case "Renovation": {
      //   if (this.purposeRenovation)
      //     this.purposeRenovation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
      //   break;
      // }
      case "Other": {
        if (this.purposeOther)
          this.purposeOther.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Repair": {
        if (this.purposeRepair)
          this.purposeRepair.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      default: {
        break;
      }
    }
  }

  setChildMRMID() {

    setTimeout(() => {

      if (this.f.HFServiceConnectedId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Testing' && this.purposeTesting)
        this.purposeTesting.mrmHomeId = this.f.HFServiceStationId.value;
      else if (this.f.HFServiceConnectedId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Initial Site Visit' && this.purposeInitialSiteVisit) {
        this.purposeInitialSiteVisit.insvf.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
        this.purposeInitialSiteVisit.currentUserId = this.currentUserId;
        this.purposeInitialSiteVisit.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
        this.purposeInitialSiteVisit.setModel();
      }
      // else if (this.f.HFServiceConnectedId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation' && this.purposeFullInstallation) {
      //   this.purposeFullInstallation.fif.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
      //   this.purposeFullInstallation.fif.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeFullInstallation.currentUserId = this.currentUserId;
      //   this.purposeFullInstallation.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

      //   this.purposeFullInstallation.setModel();
      // }
      else if (this.f.HFServiceConnectedId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Uninstallation' && this.purposeUnInstallation) {
        this.purposeUnInstallation.fif.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
        this.purposeUnInstallation.fif.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
        this.purposeUnInstallation.currentUserId = this.currentUserId;
        this.purposeUnInstallation.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

        this.purposeUnInstallation.setModel();
      }
      let puposeSelction = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == this.f.HFServiceVisitPurpose.value);

      if (puposeSelction.length > 0) {
        this.purposeSelectedId = puposeSelction[0].value;
      }

      this.onChangeArrival();

    }, 500);
  }

  //** Open Purpose Dialogs */

  PurposeEquipmentExchangeComponentDialogRef: MatDialogRef<PurposeEquipmentExchangeComponent>;
  OpenEquipmentExchangePurpose() {

    AppComponent.ShowLoader();

    this.PurposeEquipmentExchangeComponentDialogRef = this.dialog.open(PurposeEquipmentExchangeComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '70%',
      maxHeight: '100vh'
    });



    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

    if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {
      this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.EquipmentExchangeType.setValue(this.getExchangeTypeValue(this.f.HFServiceCategory.value));
      this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.EquipmentExchangeTypeChanged();
    }

    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.setModel();
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.formChange(this.f.HFServicePTHMPurposeCompleted.value != 'Yes');
    // this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.EquipmentExchangeChangeEvent();
    this.PurposeEquipmentExchangeComponentDialogRef.afterClosed().subscribe(result => {
    });

    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Equipment Exchange");
      if (completedPurpose.length > 0) {
        completedPurpose[0].isFormSubmitted = true;
      }

      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    AppComponent.HideLoader();

  }

  PurposeRepairComponentDialogRef: MatDialogRef<PurposeRepairComponent>;
  OpenRepairPurpose() {

    AppComponent.ShowLoader();


    this.PurposeRepairComponentDialogRef = this.dialog.open(PurposeRepairComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '90%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == 'Repair');
    let isFormSubmitted = false;
    if (completedPurpose.length > 0) {
      isFormSubmitted = completedPurpose[0].isFormSubmitted;
    }

    this.PurposeRepairComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    this.PurposeRepairComponentDialogRef.componentInstance.isFormSubmitted = isFormSubmitted;
    this.PurposeRepairComponentDialogRef.componentInstance.PurposeType = this.f.HFServiceVisitPurpose.value;
    if (this.f.HFServiceVisitPurpose.value == 'Repair') {
      this.PurposeRepairComponentDialogRef.componentInstance.RepairType = this.f.HFServiceCategory.value;
    }
    this.PurposeRepairComponentDialogRef.componentInstance.RepairCategoryList = this.RepairCategoryList;
    this.PurposeRepairComponentDialogRef.componentInstance.HFServiceId = this.f.HFServiceConnectedId.value;
    this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisStation = this.f.HFServiceStationId.value;
    this.PurposeRepairComponentDialogRef.componentInstance.remarks = this.f.HFServiceIssue.value;
    this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisStationtext = this.MRMHomeStationName;
    if (this.f.HFServicePTHMPurposeCompleted.value == 'Yes')
      this.PurposeRepairComponentDialogRef.componentInstance.fetchCompletedTypes();




    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.DivisionList = list;
      this.selectedDivisionObj = this.DivisionList.filter(x => x.text === 'Home Program');
      this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisUnits = this.selectedDivisionObj[0].value;
    });


    this.PurposeRepairComponentDialogRef.afterClosed().subscribe(result => {
      if (result)
        this.PartTrans = result;

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == 'Repair');
      if (!(completedPurpose && completedPurpose.length > 0 && completedPurpose[0].isFormSubmitted == true))
        this.fetchCompletedPurposes();

    });

    AppComponent.HideLoader();
  }

  PurposeMaintenanceComponentDialogRef: MatDialogRef<PurposeMaintenanceComponent>;
  OpenMaintenancePurpose(HFSPurpose) {

    AppComponent.ShowLoader();

    this.PurposeMaintenanceComponentDialogRef = this.dialog.open(PurposeMaintenanceComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '95%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    // this.PurposeMaintenanceComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {
    //   AppComponent.addAlert('Data Saved successfully!', 'success');
    // });

    this.PurposeMaintenanceComponentDialogRef.componentInstance.HFSPurpose = HFSPurpose;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.HFServiceTaskCategory = this.f.HFServiceTaskCategory.value;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.DialysisUnitName.setValue(this.MRMHomeStationName);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.dialysisUnitName.setValue(this.MRMHomeStationName);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.PtHomeArrivalTime = this.f.HFServicePTHMArrivalTime.value;

    this.PurposeMaintenanceComponentDialogRef.componentInstance.setModel();
    this.PurposeMaintenanceComponentDialogRef.componentInstance.formChange(this.f.HFServicePTHMPurposeCompleted.value != 'Yes');

    this.PurposeMaintenanceComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == HFSPurpose);
      if (completedPurpose.length > 0) {
        completedPurpose[0].isFormSubmitted = true;
      }


      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    this.PurposeMaintenanceComponentDialogRef.componentInstance.addNoteClick.subscribe(evt => {

      this.notesVisitPurposePlus.Note = evt.note;
      // this.notesVisitPurposePlusChildren.forEach((child) => {
      //   child.Note = evt.note;
      // });
      this.noteModule = evt.noteModule;
      this.executeHomeFieldServiceCreateUpdate();
    });

    // this.PurposeMaintenanceComponentDialogRef.componentInstance.save('');

    this.PurposeMaintenanceComponentDialogRef.afterClosed().subscribe(result => {
    });

    AppComponent.HideLoader();

  }


  getExchangeTypeValue(text) {
    var ExchangeType = this.EquipmentExchangeCategoryList.filter(
      (item, index) => item.text == text);

    if (ExchangeType.length > 0)
      return ExchangeType[0].value;
    else
      return 0;
  }


  setModel() {
    this.refreshSections();

    this.homefieldApi.GetHomeFieldService_MasterByID({ Id: this.f.HFServiceConnectedId.value }).subscribe(
      success => {
        if (success) {

          // Visit Purpose Details
          if (success.hfServiceVisitDate) {
            const tempDate = new Date(success.hfServiceVisitDate);

            this.f.HFServiceVisitDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: success.hfServiceVisitDate
            });
          }

          if (success.stationName)
            this.MRMHomeStationName = success.stationName;

          this.f.HFServiceCode.setValue(success.hfServiceCode);
          this.f.HFServiceStationId.setValue(success.hfServiceStationId);
          this.f.HFServiceVisitPurpose.setValue(success.hfServiceVisitPurpose);
          this.f.HFServiceVisitNotification.setValue(success.hfServiceVisitNotification);
          this.f.HFServiceCreatedOn.setValue(success.hfServiceCreatedOn);
          this.f.HFServiceCreatedBy.setValue(success.hfServiceCreatedBy);
          this.f.HFServiceStatus.setValue(success.hfServiceStatus);
          this.f.HFServiceVisitNotificationEnteredOn.setValue(success.hfServiceVisitNotificationEnteredOn);
          this.f.HFServiceVisitNotificationEnteredBy.setValue(success.hfServiceVisitNotificationEnteredBy);

          if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation')
            this.visitPurposeWithoutRenovationList = this.visitPurposeRenovationList;


          this.f.HFServiceIssue.setValue(success.hfServiceIssue);
          this.f.ISVStationId.setValue(success.isvStationId);
          this.f.ISVPhoneNumber.setValue(success.isvPhoneNumber);
          this.f.ISVAddress.setValue(success.isvAddress);
          this.f.ISVProvince.setValue(success.isvProvince);
          this.f.ISVCity.setValue(success.isvCity);
          this.f.ISVPostalCode.setValue(success.isvPostalCode);
          this.f.ISVDirections.setValue(success.isvDirections);
          this.f.ISVAccessCode.setValue(success.isvAccessCode);
          this.f.HFServiceVehicleTechnologistId.setValue(success.hfServiceVehicleTechnologistId);
          this.f.HFServiceVisitDetailsEnteredOn.setValue(success.hfServiceVisitDetailsEnteredOn);
          this.f.HFServiceVisitDetailsEnteredBy.setValue(success.hfServiceVisitDetailsEnteredBy);
          this.f.HFServiceVehiclePrepTime.setValue(success.hfServiceVehiclePrepTime);
          this.f.HFServiceVehiclePrepDetailsEnteredOn.setValue(success.hfServiceVehiclePrepDetailsEnteredOn);
          this.f.HFServiceVehiclePrepDetailsEnteredBy.setValue(success.hfServiceVehiclePrepDetailsEnteredBy);
          this.f.HFServiceVehicleStartTime.setValue(success.hfServiceVehicleStartTime);
          this.f.HFServiceVehicleId.setValue(success.hfServiceVehicleId);
          this.f.HFServiceVehicleName.setValue(success.hfServiceVehicleName);
          this.f.HFServiceVehicleStartedKM.setValue(success.hfServiceVehicleStartedKM);
          this.f.HFServiceVehicleInspectionStatus.setValue(success.hfServiceVehicleInspectionStatus);
          this.f.HFServiceVehicleDetailsEnteredOn.setValue(success.hfServiceVehicleDetailsEnteredOn);
          this.f.HFServiceVehicleDetailsEnteredBy.setValue(success.hfServiceVehicleDetailsEnteredBy);

          if (this.f.HFServiceVehicleDetailsEnteredOn.value) {
            if (!success.hfServiceVisitDate) {
              this.f.HFServiceVisitDate.setValue({
                date: {
                  year: this.today.getFullYear(),
                  month: this.today.getMonth() + 1,
                  day: this.today.getDate()
                },
                jsdate: this.today
              });
            }
          }
          // Visit Inner Purpose Details

          if (success.hfServicePTHMArrivalTime && success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMArrivalTime.setValue(success.hfServicePTHMArrivalTime);
          else if (success.hfServicePTHMArrivalTime && !success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMArrivalTime.setValue(this.homefieldApi.convertDateTimeToTime(success.hfServicePTHMArrivalTime));

          this.f.HFServicePTHMDepartureTime.setValue(success.hfServicePTHMDepartureTime);
          this.f.HFServicePTHMPurposeCompleted.setValue(success.hfServicePTHMPurposeCompleted);

          if (success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMPurposeCompletedOn.setValue(success.hfServicePTHMPurposeCompletedOn);

          // Return Details
          //this.f.HFServiceReturnVehicleEndTime.setValue(this.homefieldApi.convertDateTimeToTime(success.hfServiceReturnVehicleEndTime));

          if (success.hfServiceReturnVehicleEndTime)
            this.f.HFServiceReturnVehicleEndTime.setValue(success.hfServiceReturnVehicleEndTime);

          this.f.HFServiceReturnVehicleEndedKM.setValue(success.hfServiceReturnVehicleEndedKM);
          this.f.HFServiceFuelAdded.setValue(success.hfServiceFuelAdded);
          this.f.HFServiceFuelCompany.setValue(success.hfServiceFuelCompany);
          this.f.HFServiceFuelAmount.setValue(success.hfServiceFuelAmount);
          this.f.HFServiceFuelPaymentBy.setValue(success.hfServiceFuelPaymentBy);
          this.f.HFServiceReturnDetailsEnteredOn.setValue(success.hfServiceReturnDetailsEnteredOn);
          this.f.HFServiceReturnDetailsEnteredBy.setValue(success.hfServiceReturnDetailsEnteredBy);
          this.f.HFServiceCategory.setValue(success.hfServiceCategory);
          this.f.HFServiceTaskCategory.setValue(success.hfServiceTaskCategory);

          this.f.HFServiceFuelReceiptPath.setValue(success.hfServiceFuelReceiptPath);
          this.f.poPlumber.setValue(success.poPlumber ? success.poPlumber : 'N/A');
          this.f.poElectrician.setValue(success.poElectrician ? success.poElectrician : 'N/A');
          this.f.poMovers.setValue(success.poMovers ? success.poMovers : 'N/A');
          this.f.poPlumberNote.setValue(success.poPlumberNote ? success.poPlumberNote : '');
          this.f.poElectricianNote.setValue(success.poElectricianNote ? success.poElectricianNote : '');
          this.f.poMoversNote.setValue(success.poMoversNote ? success.poMoversNote : '');

          this.f.isHFSReferenced.setValue(success.isHFSReferenced);
          // this.f.isHFSReferenced.setValue(success.isHFSReferenced == 0 ? false : true);

          //if (this.f.HFServiceVisitPurpose.value == "Repair")
          //this.fetchRepairParts();

          this.f.HFServicelastModifiedSection.setValue(success.hfServicelastModifiedSection);
          this.f.TaskId.setValue(success.taskId);
          setTimeout(() => {
            this.sectionDetailsFetched = false;
            // this.homeUnitChange();
            this.clearNotes();
            this.fetchPrepDetails();
            this.getTechTestingTime();
            this.fetchNotes();
            this.fetchAttachments();
            this.setChildMRMID();
            this.fetchPurposeSectionDetails();
            this.fetchCompletedPurposes();

          }, 500);

        }

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }




  fetchNotes() {
    if (this.f.HFServiceConnectedId.value && this.f.HFServiceConnectedId.value != '0') {
      const hfs = { Id: this.f.HFServiceConnectedId.value };
      this.homefieldApi.GetHomeFieldService_MasterNotes(hfs).subscribe(list => {
        if (list.length > 0) {
          this.notesVisitPurposePlus.NoteList = list.filter((item, index) => item.moduleName == 'Visit Purpose Detail');
        }
      });
    }
  }

  fetchAttachments() {
    //
    if (this.f.HFServiceVisitPurpose.value != 'Repair') {
      this.attachmentVisitPurposePlus.clearQueue();


      const hfs = { Id: this.f.HFServiceConnectedId.value };
      this.homefieldApi.GetHomeFieldService_MasterAttachments(hfs).subscribe(list => {
        if (list.length > 0) {
          this.attachmentVisitPurposePlus.PrevAttachments = list.filter((item, index) => item.modulename == 'HFS_VisitPurposePlus');

        }
      });
    }
  }


  fetchCompletedPurposes() {
    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_PurposeListbyServiceID({ Id: this.f.HFServiceConnectedId.value }).subscribe(
      success => {

        if (success) {
          success.forEach((i, newKey) => {
            let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == i);
            if (completedPurpose.length > 0) {
              completedPurpose[0].isFormSubmitted = true;
            }
          });

          if (this.f.HFServiceStatus.value === 'Completed' || this.f.HFServicePTHMPurposeCompleted.value === 'Yes'
            || this.MasterCompleted) {
            this.visitPurposeWithoutRenovationList = this.visitPurposeWithoutRenovationList.filter((item, index) => item.isFormSubmitted == true);
          }
        }
        AppComponent.HideLoader();
      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  ExpandSection(val = null) {
    if (val == true || val == false)
      this.isCollapsed = val;
    else
      this.isCollapsed = !this.isCollapsed;
  }

  refreshSections() {
    this.purposeInProgress = this.PurposeDetailsList.filter(x => x.hfServicePTHMDepartureTime == '' || x.hfServicePTHMDepartureTime == null).length > 0;

    if (this.PurposeDetailsList.filter(x => x.hfServicePTHMPurposeCompleted == 'Yes').length > 0)
      this.f.HFServicePTHMPurposeCompleted.setValue('Yes');

    if (this.PurposeDetailsList.length > 0 && !this.purposeInProgress)
      this.PurposeDetailsEntered = true;
    else
      this.PurposeDetailsEntered = false;
  }


  AddOtherPurpose(value) {
    const RModelCreate = <any>{};
    RModelCreate.HFServiceID = this.f.HFServiceConnectedId.value;
    RModelCreate.DialysisUnitId = this.f.HFServiceStationId.value;
    RModelCreate.Issue = value ? value.replace('Other Purpose - ', '') : '';
    RModelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    RModelCreate.CreatedBy = this.currentUserId;
    RModelCreate.ModifiedOn = Globals.GetAPIDate(this.today);
    RModelCreate.ModifiedBy = this.currentUserId;

    this.homefieldApi.CreateHomeFieldService_Other(RModelCreate).subscribe(
      success => {
        let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Other");
        if (completedPurpose.length > 0) {
          completedPurpose[0].isFormSubmitted = true;
        }
        AppComponent.addAlert('Data Saved successfully!', 'success');
      },
      error => {

      }
    );

  }

  prepareCreateViewModel() {
    const modelCreate = <any>{};
    modelCreate.HfServiceId = this.f.HFServiceConnectedId.value;
    modelCreate.HFServiceStationId = this.f.HFServiceStationId.value;
    modelCreate.HFServiceStationName = this.f.HFServiceStationName.value;
    modelCreate.HFServiceVisitPurpose = this.f.HFServiceVisitPurpose.value;
    modelCreate.HFServiceVisitNotification = this.f.HFServiceVisitNotification.value;
    modelCreate.HFServiceCode = this.f.HFServiceCode.value;

    if (this.f.HFServiceVisitDate.value) {
      modelCreate.HFServiceVisitDate = Globals.GetAPIDateWithoutSeconds(this.f.HFServiceVisitDate.value.jsdate);
    }
    modelCreate.ISVStationId = this.f.ISVStationId.value;
    modelCreate.ISVPhoneNumber = this.f.ISVPhoneNumber.value;
    modelCreate.ISVAddress = this.f.ISVAddress.value;
    modelCreate.ISVProvince = this.f.ISVProvince.value;
    modelCreate.ISVCity = this.f.ISVCity.value;
    modelCreate.ISVPostalCode = (this.f.ISVPostalCode.value) ? this.f.ISVPostalCode.value.toUpperCase() : '';
    modelCreate.ISVDirections = this.f.ISVDirections.value;
    modelCreate.ISVAccessCode = this.f.ISVAccessCode.value;
    modelCreate.HFServiceVisitDetailsEnteredOn = this.f.HFServiceVisitDetailsEnteredOn.value;
    modelCreate.HFServiceVisitDetailsEnteredBy = this.f.HFServiceVisitDetailsEnteredBy.value;
    modelCreate.HFServiceVehiclePrepTime = this.f.HFServiceVehiclePrepTime.value;
    modelCreate.HFServiceVehiclePrepDetailsEnteredOn = this.f.HFServiceVehiclePrepDetailsEnteredOn.value;
    modelCreate.HFServiceVehiclePrepDetailsEnteredBy = this.f.HFServiceVehiclePrepDetailsEnteredBy.value;
    modelCreate.poPlumber = this.f.poPlumber.value;
    modelCreate.poElectrician = this.f.poElectrician.value;
    modelCreate.poMovers = this.f.poMovers.value;
    modelCreate.poPlumberNote = this.f.poPlumberNote.value;
    modelCreate.poElectricianNote = this.f.poElectricianNote.value;
    modelCreate.poMoversNote = this.f.poMoversNote.value;
    modelCreate.HFServiceVehicleStartTime = Globals.GetAPIDateWithoutSeconds(this.f.HFServiceVehicleStartTime.value);
    modelCreate.HFServiceVehicleId = this.f.HFServiceVehicleId.value;
    modelCreate.HFServiceVehicleName = this.f.HFServiceVehicleName.value;
    modelCreate.HFServiceVehicleStartedKM = this.f.HFServiceVehicleStartedKM.value;
    modelCreate.HFServiceVehicleInspectionStatus = this.f.HFServiceVehicleInspectionStatus.value;
    modelCreate.HFServiceVehicleDetailsEnteredOn = this.f.HFServiceVehicleDetailsEnteredOn.value;
    modelCreate.HFServiceVehicleDetailsEnteredBy = this.f.HFServiceVehicleDetailsEnteredBy.value;
    modelCreate.HFServicePTHMArrivalTime = this.f.HFServicePTHMArrivalTime.value;
    modelCreate.HFServicePTHMDepartureTime = this.f.HFServicePTHMDepartureTime.value;
    modelCreate.HFServicePTHMPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    modelCreate.HFServiceReturnVehicleEndTime = this.f.HFServiceReturnVehicleEndTime.value;
    modelCreate.HFServiceReturnVehicleEndedKM = this.f.HFServiceReturnVehicleEndedKM.value;
    modelCreate.HFServiceFuelAdded = this.f.HFServiceFuelAdded.value;
    modelCreate.HFServiceFuelCompany = this.f.HFServiceFuelCompany.value;
    modelCreate.HFServiceFuelAmount = this.currenctyToNumber(this.f.HFServiceFuelAmount.value);
    modelCreate.HFServiceFuelPaymentBy = this.f.HFServiceFuelPaymentBy.value;
    modelCreate.HFServiceFuelReceiptPath = this.f.HFServiceFuelReceiptPath.value;
    modelCreate.HFServiceCategory = this.f.HFServiceCategory.value;
    modelCreate.HFServiceIssue = this.f.HFServiceIssue.value;
    modelCreate.HFServiceStatus = this.f.HFServiceStatus.value;
    modelCreate.HFServiceReturnDetailsEnteredOn = this.f.HFServiceReturnDetailsEnteredOn.value;
    modelCreate.HFServiceReturnDetailsEnteredBy = this.f.HFServiceReturnDetailsEnteredBy.value;
    modelCreate.HFServiceCreatedOn = this.f.HFServiceCreatedOn.value;
    modelCreate.HFServiceCreatedBy = this.f.HFServiceCreatedBy.value;
    modelCreate.HFServiceModifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.HFServiceModifiedBy = this.currentUserId;
    modelCreate.HFServicePTHMPurposeCompletedOn = this.f.HFServicePTHMPurposeCompletedOn.value;
    modelCreate.HFServicePTHMPurposeCompletedBy = this.f.HFServicePTHMPurposeCompletedBy.value;

    modelCreate.HFServiceVehicleTechnologistId = this.f.HFServiceVehicleTechnologistId.value;

    modelCreate.note = this.notesVisitPurposePlus.Note;
    if (this.noteModule)
      modelCreate.noteModule = this.noteModule;
    else
      modelCreate.noteModule = "Visit Purpose Detail";


    modelCreate.HFServicelastModifiedSection = this.f.HFServicelastModifiedSection.value;
    if (this.f.HFServiceVisitPurpose.value != 'Repair') {
      var attachmentVisitPurposePlus = this.attachmentVisitPurposePlus.GenerateAttachmentList();
      modelCreate.attachmentDetails = attachmentVisitPurposePlus;
    }

    modelCreate.isHFSReferenced = this.f.isHFSReferenced.value;
    modelCreate.TaskId = this.f.TaskId.value;
    return modelCreate;
  }

  setAttachmentModule() {
    this.attachmentVisitPurposePlus.DocId = this.f.HFServiceConnectedId.value;
    this.attachmentVisitPurposePlus.moduleName = "HFS_VisitPurposePlus";
    this.attachmentVisitPurposePlus.UploadBy = this.currentUserId;
    this.attachmentVisitPurposePlus.uploadID = "ng2FileuploaderPanel" + this.f.HFServiceConnectedId.value;
  }

  currenctyToNumber(value) {
    if (value && value != null)
      return Number(value.toString().replace(/[^0-9.-]+/g, ""));
  }

  clearNotes() {
    this.notesVisitPurposePlus.Note = '';

    if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
      this.notesVisitPurposePlus.PlusNoteHeader = "Additional Notes";
    else
      this.notesVisitPurposePlus.PlusNoteHeader = "Notes";

  }

  fillEquipmentCategories() {
    this.invapi.FillCategoryList().subscribe(list => {

      this.EquipmentExchangeCategoryList = list.filter(
        x => x.text !== 'Other Equipment'
      );

      this.RepairCategoryList = this.EquipmentExchangeCategoryList;
    });
  }


  //** Open Prep Details */
  SectionPrepdetailDialogRef: MatDialogRef<SectionPrepdetailDialog>;
  OpenPrepDetail() {
    AppComponent.ShowLoader();

    // EquipId, SerialNo, Name, InserviceDate
    this.SectionPrepdetailDialogRef = this.dialog.open(SectionPrepdetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      maxHeight: '100vh'
    });

    // if (this.PrepDetailsList.length > 0)
    //   this.SectionPrepdetailDialogRef.componentInstance.IsDisplayPODetails = false;
    // else
    //   this.SectionPrepdetailDialogRef.componentInstance.IsDisplayPODetails = true;

    this.SectionPrepdetailDialogRef.componentInstance.PrepTimeList = this.PrepTimeList;
    this.SectionPrepdetailDialogRef.componentInstance.TechnologistsList = this.TechnologistsList;

    this.SectionPrepdetailDialogRef.componentInstance.dropdownSettings = this.dropdownSettings;
    this.SectionPrepdetailDialogRef.componentInstance.HFServiceStationId = this.f.HFServiceStationId.value;
    this.SectionPrepdetailDialogRef.componentInstance.HFServiceVisitPurpose = this.f.HFServiceVisitPurpose.value;
    this.SectionPrepdetailDialogRef.componentInstance.DisplayPODetails();
    this.SectionPrepdetailDialogRef.componentInstance.prepf.HFServiceId.setValue(this.f.HFServiceConnectedId.value);
    this.SectionPrepdetailDialogRef.componentInstance.prepf.EnteredByHFServiceId.setValue(this.f.HFServiceMasterId.value);

    this.SectionPrepdetailDialogRef.componentInstance.displayList = true;
    this.SectionPrepdetailDialogRef.componentInstance.fetchPrepDetails(this.f.HFServiceMasterId.value);

    this.SectionPrepdetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      AppComponent.addAlert('Data Saved successfully!', 'success');
      this.fetchPrepDetails();

    });
    this.SectionPrepdetailDialogRef.afterClosed().subscribe(result => {

      if (result && !this.f.HFServiceVehiclePrepDetailsEnteredOn.value) {
        this.f.HFServiceVehiclePrepTime.setValue(result['PrepTime']);
        this.f.HFServiceVehicleTechnologistId.setValue(result['PrepTechnologistIds']);

        this.f.poPlumber.setValue(result['poPlumber']);
        this.f.poElectrician.setValue(result['poElectrician']);
        this.f.poMovers.setValue(result['poMovers']);
        this.f.poPlumberNote.setValue(result['poPlumberNote']);
        this.f.poElectricianNote.setValue(result['poElectricianNote']);
        this.f.poMoversNote.setValue(result['poMoversNote']);
      }

    });

    AppComponent.HideLoader();
  }

  fillUserList() {
    this.dmapi.GetUsersDD().subscribe(list => {
      this.TechnologistsList = list.filter((item, index) => item.value !== '' && item.value != this.currentUserId);
    });
  }


  //** Fetch Section Details */
  fetchPrepDetails() {

    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_SectionPrepDetails({ Id: this.f.HFServiceConnectedId.value }).subscribe(
      success => {
        // this.PrepDetailsList = success.filter(x => x.enteredByHFServiceId == this.f.HFServiceMasterId.value);
        this.PrepDetailsList = success;
        setTimeout(() => {
          let prepTime = 0;
          this.PrepDetailsList.forEach((item, newKey) => {
            prepTime += parseFloat(item.prepTime);
          });

          this.totalPrepDetailTime = prepTime.toString();
        }, 100);

        AppComponent.HideLoader();

      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  techTestingTime = 0;
  getTechTestingTime() {
    this.homefieldApi.GetHomeFieldService_MasterTechTestingTime({ Id: this.f.HFServiceConnectedId.value }).subscribe(
      success => {
        if (success) {

          this.techTestingTime = success;
        }
      },
      error => {
        AppComponent.HideLoader();
      });
  }
}

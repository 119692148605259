import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { ForumApiService } from '../../API/forumapi.service';

import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';


import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';

import {
  Globals,
  actionType,

} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ForumCategoryDetail',
  templateUrl: './ForumCategoryDetail.component.html'
})
export class ForumCategoryDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  isValidFormSubmitted = null;

  ListURL = 'Masters/ForumCategory/ForumCategorySearch';
  loading = true;
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  blnshowSave = false;
  blnshowDelete = false;

  ForumCategoryForm = new UntypedFormGroup({
    ForumCategoryId: new UntypedFormControl(''),
    ForumCategoryName: new UntypedFormControl('', Validators.required),
    ForumCategoryDesc: new UntypedFormControl('', Validators.required),
  });

  public data = [{
    text: 'Select',
    value: ''
  }, {
    text: 'Monza',
    value: '#cf000f'
  }, {
    text: 'RebeccaPurple',
    value: '#663399'
  }, {
    text: 'Kournikova',
    value: '#fad859'
  }, {
    text: 'Hoki',
    value: '#67809f'
  }, {
    text: 'Salem',
    value: '#1e824c'
  }, {
    text: 'Sherpa Blue',
    value: '#013243'
  }, {
    text: 'Spray',
    value: '#81cfe0'
  }];


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.ForumCategoryForm.dirty) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private fmcapi: ForumApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.f.ForumCategoryId.setValue(params['id'] || '0');
      if (this.f.ForumCategoryId.value !== '0') {
        this.setModel();
      } else {
        
        console.log(this.f.ForumCategoryId.value);
      }
    });
    this.SetForumCategoryrights();

    AppComponent.HideLoader();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();

  }

  get f() {
    return this.ForumCategoryForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const ForumCategoryCreate = { Id: this.f.ForumCategoryId.value };

    this.fmcapi.GetForumCategoryByID(ForumCategoryCreate).subscribe(
      success => {
        console.log(success);
        this.f.ForumCategoryId.setValue(success.id);
        this.f.ForumCategoryName.setValue(success.name);
        this.f.ForumCategoryDesc.setValue(success.description);               
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {}

  executeForumCategoryCreateUpdate = function(Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.ForumCategoryForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;
    const ForumCategoryCreate = this.prepareCreateViewModel();

    if (ForumCategoryCreate.id === '0') {
      console.log(ForumCategoryCreate);
      this.fmcapi.CreateForumCategories(ForumCategoryCreate).subscribe(
        success => {
           console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
           this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      console.log(ForumCategoryCreate);
  this.fmcapi.UpdateForumCategories(ForumCategoryCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
            this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    
    const ForumCategoryCreate = <any>{};
    console.log(this.f.ForumCategoryId.value);
    
    ForumCategoryCreate.id = this.f.ForumCategoryId.value;
    ForumCategoryCreate.name = this.f.ForumCategoryName.value;  
    ForumCategoryCreate.description = this.f.ForumCategoryDesc.value;
    ForumCategoryCreate.isDelete = false;
    ForumCategoryCreate.createdon = new Date();
    ForumCategoryCreate.createdby = this.currentUserId;
    ForumCategoryCreate.modifiedon = new Date();
    ForumCategoryCreate.modifiedby = this.currentUserId;    
    return ForumCategoryCreate;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const ForumCategoryObj = { Id: this.f.ForumCategoryId.value };
        this.fmcapi.DeleteForumCategoriesByID(ForumCategoryObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Task Type detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetForumCategoryrights() {
    this.auth.GetPageRights('DM Repair Form').subscribe(list => {
      if (this.f.ForumCategoryId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.ForumCategoryId.value != '0') {
		  this.blnshowDelete = true;
		}
  }
}

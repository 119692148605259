
<form [formGroup]="errorLogForm" (ngSubmit)="executeErrorLogCreate()" novalidate>

<div class="modal-header tab" >
  <h4 class="modal-title col-sm-6" [ngClass]="{active: type === 'Support'}" (click)="SetOption('Support')">Errors & Support
  </h4>
  <h4 class="modal-title  col-sm-6" [ngClass]="{active: type === 'Remote'}"  (click)="SetOption('Remote')">Remote Assist</h4>
  <!-- <button  type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title col-sm-5" style="float:right;text-align: right" *ngIf="type=='Support'"> <a href="javascript:void(0)" (click)="SetOption('Remote')">Remote Assist</a></h4>
  <h4 class="modal-title  col-sm-5" style="float:right;text-align: right" *ngIf="type=='Remote'"><a href="javascript:void(0)" (click)="SetOption('Support')">Errors & Support</a></h4> -->

  </div>

  <div  class="modal-body tabcontent" *ngIf="type=='Support'" style="overflow: auto; overflow-x: hidden; max-height: 520px;">
      <div  class="row form-group">

          <div class="col-sm-12 box-row">
              <label class="col-sm-3 control-label">Support ID:</label>
              <div class="col-sm-3">
                  <label  class="control-label" style="font-weight:100;">{{f.supportId.value}}</label>
              </div>
          </div>
          <div class="col-sm-12 box-row">
              <label class="col-sm-3 control-label">Application:</label>
              <div class="col-sm-3">
                  <label  class="control-label" style="font-weight:100;">Technical</label>
              </div>
          </div>

          <div class="col-sm-12 box-row">
            <label class="col-sm-3 control-label">Site:</label>
            <div class="col-sm-9">
              <label name="siteName"  class="control-label" style="font-weight:100;">{{siteName}}</label>
            </div>
          </div>


          <div class="col-sm-12 box-row">
            <label class="col-sm-3 control-label">User:</label>
            <div class="col-sm-3">
                <label name="userName" class="control-label" style="font-weight:100;">{{userName}}</label>
            </div>
          </div>

          <div class="col-sm-12 box-row">
            <label class="col-sm-3 control-label">Error Date & Time:</label>
            <div class="col-sm-9">
              <label name="errorDateTime" class="control-label"  style="font-weight:100;">{{errorDateTime | date:'dd/MM/yyyy HH:mm'}}</label>
            </div>
          </div>

          <div class="col-sm-12 box-row">
              <label class="col-sm-3 control-label">Page URL:</label>
              <div class="col-sm-9">
                  <label width="auto" name="pageURL" class="control-label" style="word-wrap:break-word;font-weight:100;text-align: left">{{pageURL}}</label>
              </div>
          </div>

          <div class="col-sm-12 box-row">
              <label style="display:none;"  class="col-sm-3 control-label"><span class="text-red inputrequired">*</span>Type:</label>
              <div class="col-sm-9" style="display:none;">
                <div class="input-group" style="padding-top: 6px;">
                      <select maxlength='dropdown' class='form-control' formControlName='noteType'>

                          <option value="Select" [selected]="true">Select</option>
                          <option value="Issue/Error" >Issue/Error</option>
                          <option value="Support Request" >Support Request</option>

                          <!-- <option value="{{item.text}}" *ngFor="let item of NoteTypeList"
                          >{{item.text}}</option> -->
                      </select>
                      <!-- <span *ngIf='f.noteType.errors?.required && (f.noteType.dirty || f.noteType.touched || isValidFormSubmitted==false)'
                      [ngClass]="'error'"> Required! </span> -->
              </div>
          </div>

          <div class="box-body">
              <div class="col-sm-12 box-row">
                      <label class='control-label col-md-3' style="width:23%;">Notes:</label>
                      <div  class="col-md-9" style="width: 77%;padding-top:6px;" [formGroup]="errorLogForm">
                        <textarea rows="2" autosize class="form-textarea" formControlName='note'></textarea>
                           <!-- <textarea rows="2" autosize class="form-textarea" formControlName='note' ng-required="true"
                           [ngClass]="{'has-danger': f.note.errors?.required && (f.note.dirty || f.note.touched || isValidFormSubmitted==false)}"></textarea> -->
                                            <!-- <span *ngIf='f.note.errors?.required && (f.note.dirty || f.note.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span> -->
                      </div>
              </div>



          </div>

          <attachment   #attachment name="attachmentError" uploadID='errorAttachment'>

          </attachment>

      </div>
  </div>
  </div>
  <div  class="modal-body" *ngIf="type=='Remote'" >
    <div  class="row form-group" style="padding-top:18%;padding-bottom:18%">
      <div class="col-sm-12 box-row text-center">
        <img src="./../assets/img/MRM2.png" style="max-height: 75px" />
      </div>
        <div class="col-sm-12 ">
          <iframe width="100%" height="120px" src="https://assist.zoho.com/login/embed-remote-support.jsp" frameborder="0"></iframe>

        </div>


    </div>
</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" *ngIf="type=='Support'" (click)="executeErrorLogCreate()">Send</button>
    <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close</button>
  </div>
</form>


// import { H2OReportSearchComponent } from './Reports/H2OReport/H2OReportSearch.component';
// import { AccessRestrictedComponent } from './UnderConstruction/AccessRestricted.component';
import { CookieService } from 'ngx-cookie-service';
import { DefaultComponent } from './Home/default.component';
import { UnderConstructionComponent } from './UnderConstruction/UnderConstruction.component';
import { DailyUnderConstructionComponent } from './UnderConstruction/DailyUnderConstruction.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS} from '@danielmoncada/angular-datetime-picker';
import { MatRadioModule } from '@angular/material/radio';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { TooltipModule } from 'ng2-tooltip-directive';
import {DpDatePickerModule} from 'ng2-date-picker';
import { DragAndDropModule } from 'angular-draggable-droppable';
// import {TooltipModule as tooltip2Module} from "ngx-tooltip";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RouterModule } from '@angular/router';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
// import { FullCalendarModule } from '@fullcalendar/angular';
import { FullCalendarModule } from 'ng-fullcalendar';
// import { PopoverModule, Popover } from "ngx-popover";
// import printJS from 'print-js';

import { AuthInterceptor } from './auth.interceptor';
import { HeaderComponent } from './Core/header.component';
import { PageHeaderComponent } from './Core/pageheader.component';
import { TableHeadsService } from './Core/tableheads.service';
import { ConfirmationDialog } from './Core/ConfirmationDialog';
import { NoteDialog } from './Core/NotesModel/NoteDialog';
import { MachineHistoryDialog } from './Core/MachineHistory/MachineHistoryDialog';
import { CalibrationsDialog } from './Core/Calibrations/CalibrationsDialog';
import { ElectricalDialog } from './Core/ElectricalDialog/ElectricalDialog';
import { TrobleshootingDialog } from './Core/TrobleshootingDialog/TrobleshootingDialog';
import { URLDialog } from './Core/Shared/URLDialog/URLDialog';
import { ScheduleColorDialog } from './Core/Shared/ColorDialog/ColorDialog';
import { CreateRequestGuardService } from './Core/Shared/CanComponentDeactivate';
import { PartsComponent } from './Core/Shared/PartsControl/PartsComponent';
import { BulletChartControl } from './Core/Shared/BulletChart/BulletChart';
import { RepairDetailSearchComponent } from './Core/Shared/Repairs/RepairDetailSearch.component';
import { RepairDetailComponent } from './Core/Shared/Repairs/RepairDetail.component';
import { ImageViewerDialog } from './Core/Shared/ImageViewerDialog/ImageViewerDialog';
import { DisableControlDirective } from './Core/Shared/DisableControlDirective';
import { ErrorDialog } from './Core/Shared/ErrorDialog/ErrorDialog';
import {MailDialog} from './Core/Shared/MailDialog/MailDialog';
import { AcidLevelComponent } from './Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from './Core/Shared/AttachmentComponent/AttachmentComponent';
import { SingleAttachmentComponent } from './Core/Shared/SingleAttachmentComponent/SingleAttachmentComponent';
import { NotesComponent } from './Core/Shared/NotesComponent/NotesComponent';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatCheckboxModule} from '@angular/material/checkbox';
// services
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './API/api.service';
import { DMApiService } from './API/dmapi.service';
import { AuthService } from './API/auth.service';
import { WPApiService } from './API/wpapi.service';
import { SchApiService } from './API/schapi.service';
import { CDApiService } from './API/cdapi.service';
import { ContactApiService } from './API/contactapi.service';
import { TasksApiService } from './API/tasksapi.service';
import { RPTApiService } from './API/rptapi.service';
import { InvApiService } from './API/invapi.service';
import { DMTransferService } from './API/dmtapi.service';
import { MailApiService } from './API/mailapi.service';
import { ForumApiService } from './API/forumapi.service';

import { OrganizationalUnitApiService } from './API/organizationalunitapi.service';
import { DivisionApiService } from './API/divisionapi.service';

import { FeedbackDetailApiService } from './API/feedbackapi.service';

import { Globals, customTooltipDefaults,MY_MOMENT_FORMATS } from './globals';

import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload';


import { HomeComponent } from './Home/home.component';
import { CommonCustomValidators } from './Validator/CommonValidator';
import { DMRepairCustomValidators } from './Validator/EuipmentRepairStatus';
import { DMPMCustomValidators } from './Validator/PMValidator';
import { ValidateUser } from './Validator/ValidateUser';

import { NotFoundComponent } from './Core/not-found/not-found.component';
import { AuthGuardService } from './Core/services/auth-guard.service';
import { LoginComponent } from './Core/login/login.component';
import { SSOComponent } from './Core/sso/sso.component';

// helpler
import { ScrollHelper } from './Core/Helper/ScrollHelper';
// dialysis machaine
import { DMRepairDetailSearchComponent } from './DialysisMachines/DMRepairDetailSearch.component';
import { DMRepairDetailComponent } from './DialysisMachines/DMRepairDetail.component';
import { IncidentSearchComponent } from './DialysisMachines/Incident/IncidentSearch.component';
import { IncidentDetailComponent } from './DialysisMachines/Incident/IncidentDetail.component';
import { FilterSearchComponent } from './DialysisMachines/Filter/FilterSearch.component';
import { FilterDetailComponent } from './DialysisMachines/Filter/FilterDetail.component';
import { DMHourDetailComponent } from './DialysisMachines/DMHour/DMHourDetail.component';
import { DMHourSearchComponent } from './DialysisMachines/DMHour/DMHourSearch.component';
import { DMUpgradeModificationsDetailComponent } from './DialysisMachines/DMUpgradeModifications/DMUpgradeModificationsDetail.component';
import { DMUpgradeModificationsSearchComponent } from './DialysisMachines/DMUpgradeModifications/DMUpgradeModificationsSearch.component';
import { DmMachineHistorySearchComponent } from './DialysisMachines/DmMachineHistory/DmMachineHistorySearch.component';
import { OtherMachineHistoryComponent } from './ORepairs/OtherMachineHistory/OtherMachineHistory.component';
import { BacteriaDetailComponent } from './DialysisMachines/Bacteria/BacteriaDetail.component';
import { BacteriaSearchComponent } from './DialysisMachines/Bacteria/BacteriaSearch.component';
import { GRBacteriaDetailComponent } from './DialysisMachines/GRBacteria/GRBacteriaDetail.component';
import { GRBacteriaSearchComponent } from './DialysisMachines/GRBacteria/GRBacteriaSearch.component';
import { GRPyrogenDetailComponent } from './DialysisMachines/GRPyrogen/GRPyrogenDetail.component';
import { GRPyrogenSearchComponent } from './DialysisMachines/GRPyrogen/GRPyrogenSearch.component';
import { FieldServiceDetailComponent } from './DialysisMachines/FieldService/FieldServiceDetail.component';
import { FieldServiceSearchComponent } from './DialysisMachines/FieldService/FieldServiceSearch.component';

import { MRMHistoryUploadDetailComponent } from './DialysisMachines/MRMHistoryUpload/MRMHistoryUploadDetail.component';
import { MRMHistoryUploadSearchComponent } from './DialysisMachines/MRMHistoryUpload/MRMHistoryUploadSearch.component';


// Preventive Maintenance Form
import { PreventativeMaintenanceDetailComponent } from './DialysisMachines/PreventativeMaintenance/PreventativeMaintenanceDetail.component';
import { PreventativeMaintenanceSearchComponent } from './DialysisMachines/PreventativeMaintenance/PreventativeMaintenanceSearch.component';
// Water process form
import {BioPureHX2Component} from './WaterProcess/RemoteView/BioPureHX2.component';
import { BioPureHX2TableComponent } from './WaterProcess/RemoteView/BioPureHX2Table.component';
import {BioPureHX2AlarmsComponent} from './WaterProcess/RemoteView/BioPureHX2Alarms.component';
import { BioPureHX2SetPointsComponent } from './WaterProcess/RemoteView/BioPureHX2SetPoints.component';
import {BioPureHX2DocumentComponent} from './WaterProcess/RemoteView/BioPureHX2Document.component';
import {BioPureHX2DataLogsComponent} from './WaterProcess/RemoteView/BioPureHX2DataLogs.component';
import {BioPureHX2SettingsComponent} from './WaterProcess/RemoteView/BioPureHX2Settings.component';


import { RemoteviewComponent } from './WaterProcess/RemoteView/RemoteView.component';
import { WPDailySearchComponent } from './WaterProcess/Daliy/SCSCDU/WPDailySearch.component';
import { WPDailyDetailComponent } from './WaterProcess/Daliy/SCSCDU/WPDailyDetail.component';

import { WPDailyMain3ESearchComponent } from './WaterProcess/Daliy/Main3E/WPDailyMain3ESearch.component';
import { WPDailyMain3EDetailComponent } from './WaterProcess/Daliy/Main3E/WPDailyMain3EDetail.component';

import { HX2Main3EDetailComponent } from './WaterProcess/Daliy/HX2Main3E/HX2Main3EDetail.component';

import { GRWPDailySearchComponent } from './WaterProcess/Daliy/GRWPDailySearch.component';
import { GRWPDailyDetailComponent } from './WaterProcess/Daliy/GRWPDailyDetail.component';

import { NurseCheckDetailComponent } from './WaterProcess/NurseCheck/NurseCheckDetail.component';
import { NurseCheckSearchComponent } from './WaterProcess/NurseCheck/NurseCheckSearch.component';
import { AnalysisDetailComponent } from './WaterProcess/Analysis/AnalysisDetail.component';
import { WaterAnalysisSearchComponent } from './WaterProcess/Analysis/AnalysisSearch.component';

import { WPRepairDetailSearchComponent } from './WaterProcess/Repairs/WPRepairDetailSearch.component';
import { WPRepairDetailComponent } from './WaterProcess/Repairs/WPRepairDetail.component';

import { GRWPDailyDemoDetailComponent } from './WaterProcess/DaliyDemo/GRWPDailyDetail.component';
import { GRWPDailyDemoSearchComponent } from './WaterProcess/DaliyDemo/GRWPDailySearch.component';

import { WPDailyMain3ESearchDemoComponent } from './WaterProcess/DaliyDemo/Main3E/WPDailyMain3ESearch.component';
import { WPDailyMain3EDemoDetailComponent } from './WaterProcess/DaliyDemo/Main3E/WPDailyMain3EDetail.component';
import { WPDailyDemoDetailComponent } from './WaterProcess/DaliyDemo/WPDailyDetail.component';
import { WPDailyDemoSearchComponent  } from './WaterProcess/DaliyDemo/WPDailySearch.component';

// Schedule
import { ScheduleStaffComponent } from './Schedule/ScheduleStaff.component';
import { ScheduleTechnologistComponent } from './Schedule/ScheduleTech.component';

// Master
import { TaskTypeSearchComponent } from './Masters/TaskTypeSearch.component';
import { TaskTypeDetailComponent } from './Masters/TaskTypeDetail.component';


import { ForumCategorySearchComponent } from './Masters/ForumCategory/ForumCategorySearch.component';
import { ForumCategoryDetailComponent } from './Masters/ForumCategory/ForumCategoryDetail.component';

// contact
import { ContactDetailComponent } from './Contact/ContactDetail.component';
import { ContactSearchComponent } from './Contact/ContactSearch.component';

// Concentrates
import { AcidDetailComponent } from './Concentrates/Acid/AcidDetail.component';
import { AcidSearchComponent } from './Concentrates/Acid/AcidSearch.component';
import { DailySearchComponent } from './Concentrates/Daily/DailySearch.component';
import { DailyDetailComponent } from './Concentrates/Daily/DailyDetail.component';
import { CDRepairDetailSearchComponent } from './Concentrates/Repairs/CDRepairDetailSearch.component';
import { CDRepairDetailComponent } from './Concentrates/Repairs/CDRepairDetail.component';

//Others Repairs
import { ORRepairDetailSearchComponent } from './ORepairs/Repairs/ORRepairDetailSearch.component';
import { ORRepairDetailComponent } from './ORepairs/Repairs/ORRepairDetail.component';

// Reports
import { PagerDetailComponent } from './Reports/Pager/PagerDetail.component';
import { PagerSearchComponent } from './Reports/Pager/PagerSearch.component';

import { DMReportSearchComponent } from './Reports/DMReport/DMReportSearch.component';

// Task
import { TaskViewerComponent } from './Task/TaskViewer.component';
import { TaskDetailComponent } from './Task/TaskDetail.component';
import { AutoTaskDetailComponent } from './Home/AutoTask/AutoTaskDetail.component';
import { AutoTaskViewerComponent } from './Home/AutoTask/AutoTaskViewer.component';
// import {PagerSearchComponent} from './Reports/Pager/PagerSearch.component';


// Mail
import { MailSearchComponent} from './Mail/MailSearch.component';
import { MailComposeComponent } from './Mail/MailCompose.component';

// inventory
import { InventoryDetailComponent } from './Inventory/Inventory/InventoryDetail.component';
import { InventorySearchComponent } from './Inventory/Inventory/InventorySearch.component';
import { OrganizationalUnitComponent } from './OrganizationalUnit/OrganizationalUnit.component';

// Bootstrap Switch
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

// image Resize
// import { Ng2ImgMaxModule } from 'ng2-img-max';
import { FeedbackdetailComponent } from './feedback/feedbackdetail.component';
// import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ImageViewerModule } from 'ngx-image-viewer';
import { PMNoteDialog } from './Core/PMNotesModel/PMNoteDialog';
import { PMNotesComponent } from './Core/Shared/PMNotesComponent/PMNotesComponent';
import { PMPartsComponent } from './Core/Shared/PMPartsControl/PMPartsComponent';

//DashBoard
import { DashBoardDetailsComponent } from './Dashboard/DashBoardDetails.component';
import { DivisionSearchComponent } from './Admin/Division/DivisionSearch.component';
import { DivisionDetailComponent } from './Admin/Division/DivisionDetail.component';

//JMeet
import { JMeetComponent } from './JMeet/JMeet.component';

//Forum
import { ForumSearchComponent } from './Forum/ForumSearch.component';
import { ForumComposeComponent } from './Forum/ForumCompose.component';
import { ForumComponent } from './Forum/forum.component';


// Transfer
import { DMTransferDetailComponent } from './DialysisMachines/DMTransfer/DMTransferDetail.component';
import { DMTransferSearchComponent } from './DialysisMachines/DMTransfer/DMTransferSearch.component';


// import { GaugeChartModule } from 'angular-gauge-chart';
import { OrderModule } from 'ngx-order-pipe';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';

import { TermsAndConditionDialog } from './Core/TermsAndConditionDialog/TermsAndConditionDialog';
import { PrivacyPolicyDialog } from './Core/PrivacyPolicyDialog/PrivacyPolicyDialog';
import { CommonApiService } from './API/commonapi.service.';
import { MenuSharedService } from './Core/services/menu-shared.service';
import { QuickAddModel } from './Core/QuickAddModel/QuickAddModel';
import { QuickAddModelDialog } from './Core/QuickAddModel/QuickAddModelDialog';
import { PreviousRouteService } from './Core/services/previous-route.service';
import { HomeStationSearchComponent } from './Home/HomeStations/HomeStationSearch.component';
import { HomeService } from './API/home.service';
import { HomeFieldService } from './API/homefield.service';

import { HomeStationDetailComponent } from './Home/HomeStations/HomeStationDetail.component';


import { RECAPTCHA_SETTINGS,RecaptchaSettings,RecaptchaLoaderService,RecaptchaModule} from 'ng-recaptcha';
import { WaterProcessMachineHistoryComponent } from './WaterProcess/WaterProcessMachineHistory/WaterProcessMachineHistory.component';
import { MachineHistorySearchComponent } from './Core/Shared/MachineHistoryComponent/MachineHistorySearch.component';
import { HistoryUploadSearchComponent } from './Core/Shared/HistoryUpload/HistoryUploadSearch.component';
import { HistoryUploadDetailComponent } from './Core/Shared/HistoryUpload/HistoryUploadDetail.component';
import { OtherHistoryUploadDetailComponent } from './ORepairs/OtherHistoryUpload/OtherHistoryUploadDetail.component';
import { WPHistoryUploadDetailComponent } from './WaterProcess/WPHistoryUpload/WPHistoryUploadDetail.component';
import { WPHistoryUploadDetailSearchComponent } from './WaterProcess/WPHistoryUpload/WPHistoryUploadDetailSearch.component';
import { OtherHistoryUploadDetailSearchComponent } from './ORepairs/OtherHistoryUpload/OtherHistoryUploadDetailSearch.component';
import { QuickAddPartDialog } from './Core/QuickAddPart/QuickAddPartDialog';
import { HomeFieldServiceSearchComponent } from './HomeFieldService/HomeFieldServiceSearch.component';
import { HomeFieldServiceDetailComponent } from './HomeFieldService/HomeFieldServiceDetail.component';
import { PurposeRenovationComponent } from './HomeFieldService/Purpose/purpose-renovation/purpose-renovation.component';
import { PurposeInitialSiteVisitComponent } from './HomeFieldService/Purpose/purpose-initialsitevisit/purpose-initialsitevisit.component';
import { PurposeFullInstallationComponent } from './HomeFieldService/Purpose/purpose-fullinstallation/purpose-fullinstallation.component';
import { PurposeUninstallationComponent } from './HomeFieldService/Purpose/purpose-uninstallation/purpose-uninstallation.component';
import { PurposeRepairComponent } from './HomeFieldService/Purpose/purpose-repair/purpose-repair.component';
import { QuickAddWaterAnalysisDialog } from './Core/QuickAddWaterAnalysis/QuickAddWaterAnalysis';
import { PurposeTestingComponent } from './HomeFieldService/Purpose/purpose-testing/purpose-testing.component';
import { PurposeMaintenanceComponent } from './HomeFieldService/Purpose/purpose-maintenance/purpose-maintenance.component';
import { PurposeOtherComponent } from './HomeFieldService/Purpose/purpose-other/purpose-other.component';
import { PurposeEquipmentExchangeComponent } from './HomeFieldService/Purpose/purpose-equipmentexchange/purpose-equipmentexchange.component';
import { WaterProcessAnalysisComponent } from './Core/Shared/WaterProcessAnalysisComponent/WaterProcessAnalysisComponent.component';
import { BacteriaComponent } from './Core/Shared/BacteriaComponent/Bacteria.component';
import { PyrogenComponent } from './Core/Shared/PyrogenComponent/Pyrogen.component';
import { HFSNoteDialog } from './Core/HFSNotesModel/HFSNoteDialog';
import { QuickHomeEquipmentTransfer } from './Core/QuickHomeEquipmentTransfer/QuickHomeEquipmentTransfer';
import {SectionPrepdetailDialog} from './HomeFieldService/Section/section-prepdetail/section-prepdetail.component';
import {SectionVehicledetailDialog} from './HomeFieldService/Section/section-vehicledetail/section-vehicledetail.component';

import { PreventiveMaintenanceCommonDetailComponent } from './Core/Shared/PreventiveMaintenanceCommon/PreventiveMaintenanceCommonDetail.component';
import { PreventiveMaintenanceCommonSearchComponent } from './Core/Shared/PreventiveMaintenanceCommon/PreventiveMaintenanceCommonSearch.component';
import { HomePreventiveMaintenanceDetailComponent } from './Home/PreventiveMaintenance/HomePreventiveMaintenanceDetail.component';
import { HomePreventiveMaintenanceSearchComponent } from './Home/PreventiveMaintenance/HomePreventiveMaintenanceSearch.component';
import { SectionPurposeDetailDialog } from './HomeFieldService/Section/section-purposedetail/section-purposedetail.component';
import { SectionReturnDetailDialog } from './HomeFieldService/Section/section-returndetail/section-returndetail.component';
import { informationdialog} from './HomeFieldService/Section/informationdialog/informationdialog.component';
import { HFSVehicleHistoryDialog } from './HomeFieldService/Section/HFSVehicleHistoryDialog/HFSVehicleHistoryDialog.component';
import { MaintenanceCleanOrTestHistory } from './HomeFieldService/Section/MaintenanceCleanOrTestHistory/MaintenanceCleanOrTestHistory.component';

import { PanelPurposeDetailComponent } from './HomeFieldService/Section/panel-purpose-detail/panel-purpose-detail.component';
import { PanelOtherPurposeDetailComponent } from './HomeFieldService/Section/panel-otherPurpose-detail/panel-otherPurpose-detail.component';
import { WPDailySatelliteDetailComponent } from './WaterProcess/Daliy/Satellite/WPDailySatelliteDetail.component';
import { DesignationDetailComponent } from './Admin/Designation/DesignationDetail.component';
import { DesignationSearchComponent } from './Admin/Designation/DesignationSearch.component';
import { DesignationApiService } from './API/designationapi.service';
import { ScheduleColorApiService } from './API/schColorapi.service';
import { ScheduleColorDetailComponent } from './Admin/ScheduleColor/ScheduleColorDetail.component';
import { ScheduleColorSearchComponent } from './Admin/ScheduleColor/ScheduleColorSearch.component';
import { ErrorCodeApiService } from './API/errorCodeApi.service';
import { ErrorCodeDetailComponent } from './Admin/ErrorCode/ErrorCodeDetail.component';
import { ErrorCodeSearchComponent } from './Admin/ErrorCode/ErrorCodeSearch.component';
import { HomeISVContactDetailComponent } from './Home/Home_ISV_Contact/HomeISVContactDetail.component';

import { FieldMasterApiService } from './API/fieldmasterapi.service';
import { FieldMasterDetailComponent } from './Admin/FieldMaster/FieldMasterDetail.component';
import { FieldMasterSearchComponent } from './Admin/FieldMaster/FieldMasterSearch.component';

import { MediaStreamComponent } from './Core/Barcode/barcode/media-stream/media-stream.component';
import { BarcodeDecoderService } from './Core/Barcode/barcode/services/barcode-decoder.service';
import { BarcodeValidatorService } from './Core/Barcode/barcode/services/barcode-validator.service';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { BarcodeScannerComponent } from './Core/Barcode/barcode-scanner/barcode-scanner.component';
import { AttachmentDialog } from './Core/AttachmentDialog/AttachmentDialog';
import { ForumMessagesDialog } from './Core/ForumMessages/ForumMessagesDialog';

import { MatTabsModule } from '@angular/material/tabs';
import { VersionCheckService } from './API/version-check.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { NgxSpinnerModule } from "ngx-spinner";

//  Generate Site Key from : https://www.google.com/recaptcha/admin/create
const globalSettings: RecaptchaSettings = { siteKey: '6LdeJJgUAAAAALIaJo5FmnD-ucMA-kijUaorZ5ch' };


const routes =
  [
    {
      path: '',
      canActivate: [AuthGuardService], component: DashBoardDetailsComponent
    },
    {
      path: 'Home',
      canActivate: [AuthGuardService], component: HomeComponent
    },
    {
      path: 'DialysisMachine/DMRepairDetailSearch',
      canActivate: [AuthGuardService], component: DMRepairDetailSearchComponent
    },
    {
      path: 'DialysisMachine/DMRepairDetail',
      canActivate: [AuthGuardService], component: DMRepairDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/IncidentSearch',
      canActivate: [AuthGuardService], component: IncidentSearchComponent
    },
    {
      path: 'DialysisMachine/IncidentDetail',
      canActivate: [AuthGuardService], component: IncidentDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/FilterSearch',
      canActivate: [AuthGuardService], component: FilterSearchComponent
    },
    {
      path: 'DialysisMachine/FilterDetail',
      canActivate: [AuthGuardService], component: FilterDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'DialysisMachine/DMUpgradeModificationsSearch',
      canActivate: [AuthGuardService], component: DMUpgradeModificationsSearchComponent
    },
    {
      path: 'DialysisMachine/DMUpgradeModificationsDetail',
      canActivate: [AuthGuardService], component: DMUpgradeModificationsDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/DMMachineHistory',
      canActivate: [AuthGuardService], component: DmMachineHistorySearchComponent
    },
    {
      path: 'WaterProcess/WaterProcessMachineHistory',
      canActivate: [AuthGuardService], component: WaterProcessMachineHistoryComponent
    },
    {
      path: 'ORepairs/OtherMachineHistory',
      canActivate: [AuthGuardService], component: OtherMachineHistoryComponent
    },
    {
      path: 'DialysisMachine/DMHoursUpdateSearch',
      canActivate: [AuthGuardService], component: DMHourSearchComponent
    },
    {
      path: 'DialysisMachine/DMHoursUpdateDetail',
      canActivate: [AuthGuardService], component: DMHourDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/BacteriaSearch',
      canActivate: [AuthGuardService], component: BacteriaSearchComponent
    },
    {
      path: 'DialysisMachine/BacteriaDetail',
      canActivate: [AuthGuardService], component: BacteriaDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/BacteriasDetail',
      canActivate: [AuthGuardService], component: GRBacteriaDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/BacteriasSearch',
      canActivate: [AuthGuardService], component: GRBacteriaSearchComponent
    },
    {
      path: 'DialysisMachine/PyrogensDetail',
      canActivate: [AuthGuardService], component: GRPyrogenDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/PyrogensSearch',
      canActivate: [AuthGuardService], component: GRPyrogenSearchComponent
    },
    {
      path: 'DialysisMachine/FieldServiceDetail',
      canActivate: [AuthGuardService], component: FieldServiceDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/FieldServiceSearch',
      canActivate: [AuthGuardService], component: FieldServiceSearchComponent
    },
    {
      path: 'feedback/feedbackdetail',
      canActivate: [AuthGuardService], component: FeedbackdetailComponent
    },
    {
      path: 'DialysisMachine/PreventiveMaintenance',
      canActivate: [AuthGuardService], component: PreventativeMaintenanceDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'DialysisMachine/PreventiveMaintenanceSearch',
      canActivate: [AuthGuardService], component: PreventativeMaintenanceSearchComponent
    },
    {
      path: 'Home/PreventiveMaintenance',
      canActivate: [AuthGuardService], component: HomePreventiveMaintenanceDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Home/PreventiveMaintenanceSearch',
      canActivate: [AuthGuardService], component: HomePreventiveMaintenanceSearchComponent
    },
    {
      path: 'DialysisMachine/MRMHistoryUploadDetail',
      canActivate: [AuthGuardService], component: MRMHistoryUploadDetailComponent
    },
    {
      path: 'DialysisMachine/MRMHistoryUploadSearch',
      canActivate: [AuthGuardService], component: MRMHistoryUploadSearchComponent
    },
    {
      path: 'WaterProcess/WPHistoryUploadDetail',
      canActivate: [AuthGuardService], component: WPHistoryUploadDetailComponent
    },
    {
      path: 'WaterProcess/WPHistoryUploadSearch',
      canActivate: [AuthGuardService], component: WPHistoryUploadDetailSearchComponent
    },
    {
      path: 'Other/OtherHistoryUploadDetail',
      canActivate: [AuthGuardService], component: OtherHistoryUploadDetailComponent
    },
    {
      path: 'Other/OtherHistoryUploadSearch',
      canActivate: [AuthGuardService], component: OtherHistoryUploadDetailSearchComponent
    },
    {
      path: 'Contact/ContactList',
      canActivate: [AuthGuardService], component: ContactSearchComponent
    },
    {
      path: 'Contact/ContactDetail',
      canActivate: [AuthGuardService], component: ContactDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Admin/UserDesignationSearch',
      canActivate: [AuthGuardService], component: DesignationSearchComponent
    },
    {
      path: 'Admin/UserDesignationDetail',
      canActivate: [AuthGuardService], component: DesignationDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Admin/ErrorCodeSearch',
      canActivate: [AuthGuardService], component: ErrorCodeSearchComponent
    },
    {
      path: 'Admin/ErrorCodeDetail',
      canActivate: [AuthGuardService], component: ErrorCodeDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Admin/FieldMasterSearch',
      canActivate: [AuthGuardService], component: FieldMasterSearchComponent
    },
    {
      path: 'Admin/FieldMasterDetail',
      canActivate: [AuthGuardService], component: FieldMasterDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Admin/ScheduleColorSearch',
      canActivate: [AuthGuardService], component: ScheduleColorSearchComponent
    },
    {
      path: 'Admin/ScheduleColorDetail',
      canActivate: [AuthGuardService], component: ScheduleColorDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Masters/TaskTypeSearch',
      canActivate: [AuthGuardService], component: TaskTypeSearchComponent
    },
    {
      path: 'Masters/TaskTypeDetail',
      canActivate: [AuthGuardService], component: TaskTypeDetailComponent
    },
    {
      path: 'WaterProcess/RemoteView/RemoteView',
      canActivate: [AuthGuardService], component: RemoteviewComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2',
      canActivate: [AuthGuardService], component: BioPureHX2Component
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2Table',
      canActivate: [AuthGuardService], component: BioPureHX2TableComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2Alarms',
      canActivate: [AuthGuardService], component: BioPureHX2AlarmsComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2Document',
      canActivate: [AuthGuardService], component: BioPureHX2DocumentComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2DataLogs',
      canActivate: [AuthGuardService], component: BioPureHX2DataLogsComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2SetPoints',
      canActivate: [AuthGuardService], component: BioPureHX2SetPointsComponent
    },
    {
      path: 'WaterProcess/RemoteView/BioPureHX2Settings',
      canActivate: [AuthGuardService], component: BioPureHX2SettingsComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'WaterProcess/Daily/WPDailySearch',
      canActivate: [AuthGuardService], component: WPDailySearchComponent
    },
    {
      path: 'WaterProcess/Daily/Main3E/WPDailyMain3ESearch',
      canActivate: [AuthGuardService], component: WPDailyMain3ESearchComponent
    },
    {
      path: 'WaterProcess/Daily/Main3E/WPDailyMain3EDetail',
      canActivate: [AuthGuardService], component: WPDailyMain3EDetailComponent
    },
    {
      path: 'WaterProcess/Daily/HX2Main3E/HX2Main3EDetail',
      canActivate: [AuthGuardService], component: HX2Main3EDetailComponent
    },
    {
      path: 'WaterProcess/Daily/Satellite/WPDailySatelliteDetail',
      canActivate: [AuthGuardService], component: WPDailySatelliteDetailComponent
    },
    {
      path: 'WaterProcess/Daily/GRWPDailySearch',
      canActivate: [AuthGuardService], component: GRWPDailySearchComponent
    },
    {
      path: 'WaterProcess/Daily/GRWPDailyDetail',
      canActivate: [AuthGuardService], component: GRWPDailyDetailComponent
    },
    {
      path: 'WaterProcess/Daily/DEWPDailySearch',
      canActivate: [AuthGuardService], component: GRWPDailyDemoSearchComponent
    },
    {
      path: 'WaterProcess/Daily/DEWPDailyDetail',
      canActivate: [AuthGuardService], component: GRWPDailyDemoDetailComponent
    },
    {
      path: 'WaterProcess/SaturdayWaterRecordSearch',
      canActivate: [AuthGuardService], component: NurseCheckSearchComponent
    },
    {
      path: 'WaterProcess/SaturdayWaterRecordDetail',
      canActivate: [AuthGuardService], component: NurseCheckDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'WaterProcess/Analysis/AnalysisDetail',
      canActivate: [AuthGuardService], component: AnalysisDetailComponent, CanDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'WaterProcess/Analysis/AnalysisSearch',
      canActivate: [AuthGuardService], component: WaterAnalysisSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'WaterProcess/Repairs/RepairDetailSearch',
      canActivate: [AuthGuardService], component: WPRepairDetailSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'WaterProcess/Repairs/RepairDetail',
      canActivate: [AuthGuardService], component: WPRepairDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Concentrates/AcidDrumFilling/AcidDrumSearch',
      canActivate: [AuthGuardService], component: AcidSearchComponent
    },
    {
      path: 'Concentrates/AcidDrumFilling/AcidDrumDetail',
      canActivate: [AuthGuardService], component: AcidDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Concentrates/Daily/CDBicarbPowderSearch',
      canActivate: [AuthGuardService], component: DailySearchComponent
    },
    {
      path: 'Concentrates/Daily/CDBicarbPowderDetail',
      canActivate: [AuthGuardService], component: DailyDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Concentrates/Repairs/RepairDetailSearch',
      canActivate: [AuthGuardService], component: CDRepairDetailSearchComponent, CanDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Concentrates/Repairs/RepairDetail',
      canActivate: [AuthGuardService], component: CDRepairDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'ORepairs/Repairs/RepairDetailSearch',
      canActivate: [AuthGuardService], component: ORRepairDetailSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'ORepairs/Repairs/RepairDetail',
      canActivate: [AuthGuardService], component: ORRepairDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'Schedule/Staff',
      canActivate: [AuthGuardService], component: ScheduleStaffComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'Inventory/InventorySearch',
      canActivate: [AuthGuardService], component: InventorySearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Inventory/InventoryDetail',
      canActivate: [AuthGuardService], component: InventoryDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'DMTransfer/DMTransferSearch',
      canActivate: [AuthGuardService], component: DMTransferSearchComponent
    },
    {
      path: 'DMTransfer/DMTransferDetail',
      canActivate: [AuthGuardService], component: DMTransferDetailComponent, canDeactivate: [CreateRequestGuardService]
    },


    {
      path: 'Reports/PagingReportDetail',
      canActivate: [AuthGuardService], component: PagerDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'Reports/PagingReportSearch',
      canActivate: [AuthGuardService], component: PagerSearchComponent
    },
    {
      path: 'Reports/DMReportSearch',
      canActivate: [AuthGuardService], component: DMReportSearchComponent
    },
    // {
    //   path: 'Reports/H2OReportSearch',
    //   canActivate: [AuthGuardService], component: H2OReportSearchComponent
    // },
    {
      path: 'Task/TaskViewer',
      canActivate: [AuthGuardService], component: TaskViewerComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Task/TaskDetail',
      canActivate: [AuthGuardService], component: TaskDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Home/AutoTask/AutoTaskDetail',
      canActivate: [AuthGuardService], component: AutoTaskDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Mail/MailSearch',
      canActivate: [AuthGuardService], component: MailSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Mail/MailCompose',
      canActivate: [AuthGuardService], component: MailComposeComponent, canDeactivate: [CreateRequestGuardService]
    },

    // {path: 'Dashboard/DashBoardDetails',
    {
      path: 'Dashboard',
      canActivate: [AuthGuardService], component: DashBoardDetailsComponent
    },

    {
      path: 'Schedule/Technologist',
      canActivate: [AuthGuardService], component: ScheduleTechnologistComponent
    },
    {
      path: 'WaterProcess/Daily/WPDailyDetail',
      canActivate: [AuthGuardService], component: WPDailyDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'sso',
      component: SSOComponent
    },
    {
      path: 'login',
      component: LoginComponent
    },

    {
      path: 'OrganizationalUnit',
      canActivate: [AuthGuardService], component: OrganizationalUnitComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Admin/DivisionSearch',
      canActivate: [AuthGuardService], component: DivisionSearchComponent
    },
    {
      path: 'Admin/Division',
      canActivate: [AuthGuardService], component: DivisionDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Home/HomeStationSearch',
      canActivate: [AuthGuardService], component: HomeStationSearchComponent
    },
    {
      path: 'Home/HomeStationDetail',
      canActivate: [AuthGuardService], component: HomeStationDetailComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'HomeFieldService/HomeFieldServiceSearch',
      canActivate: [AuthGuardService], component: HomeFieldServiceSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Home/AutoTask/AutoTaskList',
      canActivate: [AuthGuardService], component: AutoTaskViewerComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'HomeFieldService/HomeFieldServiceDetail',
      canActivate: [AuthGuardService], component: HomeFieldServiceDetailComponent, canDeactivate: [CreateRequestGuardService]
    },

    {
      path: 'Masters/ForumCategory/ForumCategorySearch',
      canActivate: [AuthGuardService], component: ForumCategorySearchComponent
    },
    {
      path: 'Masters/ForumCategory/ForumCategoryDetail',
      canActivate: [AuthGuardService], component: ForumCategoryDetailComponent
    },
    {
      path: 'Forum/ForumCompose',
      canActivate: [AuthGuardService], component: ForumComposeComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Forum/ForumSearch',
      canActivate: [AuthGuardService], component: ForumSearchComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'JMeet/Live',
      canActivate: [AuthGuardService], component: JMeetComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Forum',
      canActivate: [AuthGuardService], component: ForumComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'UnderConstruction/UnderConstruction',
      canActivate: [AuthGuardService], component: UnderConstructionComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: 'Inventory/Barcode',
      component: MediaStreamComponent
    },
    {
      path: 'Inventory/BarcodeScanner',
      component: BarcodeScannerComponent
    },
    {
      path: 'UnderConstruction/DailyUnderConstruction',
      canActivate: [AuthGuardService], component: DailyUnderConstructionComponent, canDeactivate: [CreateRequestGuardService]
    },
    // {
    //   path: 'UnderConstruction/AccessRestricted',
    //   canActivate: [AuthGuardService], component: AccessRestrictedComponent, canDeactivate: [CreateRequestGuardService]
    // },
    {
      path: 'Home/default',
      canActivate: [AuthGuardService], component: DefaultComponent, canDeactivate: [CreateRequestGuardService]
    },
    {
      path: '**',
      component: NotFoundComponent
    },

  ];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        PageHeaderComponent,
        PrivacyPolicyDialog,
        ForumMessagesDialog,
        TermsAndConditionDialog,
        ConfirmationDialog,
        NoteDialog,
        HFSNoteDialog,
        QuickAddModelDialog,
        QuickHomeEquipmentTransfer,
        QuickAddPartDialog,
        QuickAddWaterAnalysisDialog,
        PMNoteDialog,
        ImageViewerDialog,
        DisableControlDirective,
        MachineHistoryDialog,
        CalibrationsDialog,
        ElectricalDialog,
        TrobleshootingDialog,
        URLDialog,
        ScheduleColorDialog,
        PartsComponent,
        WaterProcessAnalysisComponent,
        BacteriaComponent,
        PyrogenComponent,
        RepairDetailSearchComponent,
        RepairDetailComponent,
        PMNotesComponent,
        PMPartsComponent,
        BulletChartControl,
        NotFoundComponent,
        LoginComponent,
        SSOComponent,
        AcidLevelComponent,
        AttachmentComponent,
        SingleAttachmentComponent,
        NotesComponent,
        ErrorDialog,
        ImageViewerDialog,
        AttachmentDialog,
        // printJS,
        // DM
        DMRepairDetailSearchComponent,
        DMRepairDetailComponent,
        IncidentDetailComponent,
        IncidentSearchComponent,
        FilterDetailComponent,
        FilterSearchComponent,
        DMHourDetailComponent,
        DMHourSearchComponent,
        DmMachineHistorySearchComponent,
        MachineHistorySearchComponent,
        WaterProcessMachineHistoryComponent,
        OtherMachineHistoryComponent,
        PreventativeMaintenanceDetailComponent,
        PreventativeMaintenanceSearchComponent,
        PreventiveMaintenanceCommonDetailComponent,
        PreventiveMaintenanceCommonSearchComponent,
        HomePreventiveMaintenanceDetailComponent,
        HomePreventiveMaintenanceSearchComponent,
        DMUpgradeModificationsSearchComponent,
        DMUpgradeModificationsDetailComponent,
        BacteriaDetailComponent,
        BacteriaSearchComponent,
        GRBacteriaDetailComponent,
        GRBacteriaSearchComponent,
        GRPyrogenDetailComponent,
        GRPyrogenSearchComponent,
        MRMHistoryUploadDetailComponent,
        MRMHistoryUploadSearchComponent,
        HistoryUploadDetailComponent,
        HistoryUploadSearchComponent,
        WPHistoryUploadDetailComponent,
        WPHistoryUploadDetailSearchComponent,
        OtherHistoryUploadDetailComponent,
        OtherHistoryUploadDetailSearchComponent,
        FieldServiceDetailComponent,
        FieldServiceSearchComponent,
        // Water Process
        RemoteviewComponent,
        BioPureHX2Component,
        BioPureHX2TableComponent,
        BioPureHX2AlarmsComponent,
        BioPureHX2SetPointsComponent,
        BioPureHX2DocumentComponent,
        BioPureHX2DataLogsComponent,
        BioPureHX2SettingsComponent,
        WPDailySearchComponent,
        WPDailyMain3ESearchComponent,
        WPDailyMain3EDetailComponent,
        HX2Main3EDetailComponent,
        WPDailySatelliteDetailComponent,
        GRWPDailySearchComponent,
        GRWPDailyDetailComponent,
        WPDailyDetailComponent,
        NurseCheckDetailComponent,
        NurseCheckSearchComponent,
        AnalysisDetailComponent,
        WaterAnalysisSearchComponent,
        WPRepairDetailSearchComponent,
        WPRepairDetailComponent,
        WPDailyDemoSearchComponent,
        WPDailyMain3ESearchDemoComponent,
        WPDailyMain3EDemoDetailComponent,
        GRWPDailyDemoSearchComponent,
        GRWPDailyDemoDetailComponent,
        WPDailyDemoDetailComponent,
        // Concentrates
        AcidDetailComponent,
        AcidSearchComponent,
        DailyDetailComponent,
        DailySearchComponent,
        CDRepairDetailSearchComponent,
        CDRepairDetailComponent,
        //Other Repairs
        ORRepairDetailSearchComponent,
        ORRepairDetailComponent,
        // Schdule
        ScheduleStaffComponent,
        ScheduleTechnologistComponent,
        // Masters
        TaskTypeSearchComponent,
        TaskTypeDetailComponent,
        // Designation
        DesignationDetailComponent,
        DesignationSearchComponent,
        // Error Code
        ErrorCodeDetailComponent,
        ErrorCodeSearchComponent,
        //Field Master
        FieldMasterSearchComponent,
        FieldMasterDetailComponent,
        // Designation
        ScheduleColorDetailComponent,
        ScheduleColorSearchComponent,
        // Contact
        ContactDetailComponent,
        ContactSearchComponent,
        // pager
        PagerDetailComponent,
        PagerSearchComponent,
        DMReportSearchComponent,
        // H2OReportSearchComponent,
        // Task
        TaskViewerComponent,
        TaskDetailComponent,
        AutoTaskDetailComponent,
        AutoTaskViewerComponent,
        //Forum
        ForumCategorySearchComponent,
        ForumCategoryDetailComponent,
        ForumSearchComponent,
        ForumComposeComponent,
        ForumComponent,
        //JMeet
        JMeetComponent,
        //Mails
        MailSearchComponent,
        MailComposeComponent,
        //DashBoard
        DashBoardDetailsComponent,
        //Chart
        // GaugeChartModule,
        // inventory
        InventoryDetailComponent,
        InventorySearchComponent,
        DMTransferSearchComponent,
        DMTransferDetailComponent,
        OrganizationalUnitComponent,
        FeedbackdetailComponent,
        //Division
        DivisionSearchComponent,
        DivisionDetailComponent,
        HomeStationSearchComponent,
        HomeStationDetailComponent,
        HomeFieldServiceSearchComponent,
        HomeFieldServiceDetailComponent,
        PurposeRenovationComponent,
        PurposeInitialSiteVisitComponent,
        PurposeFullInstallationComponent,
        PurposeUninstallationComponent,
        PurposeRepairComponent,
        PurposeTestingComponent,
        PurposeMaintenanceComponent,
        PurposeOtherComponent,
        PurposeEquipmentExchangeComponent,
        UnderConstructionComponent,
        DailyUnderConstructionComponent,
        MailDialog,
        SectionPrepdetailDialog,
        SectionPurposeDetailDialog,
        SectionReturnDetailDialog,
        SectionVehicledetailDialog,
        informationdialog,
        MaintenanceCleanOrTestHistory,
        HFSVehicleHistoryDialog,
        PanelPurposeDetailComponent,
        PanelOtherPurposeDetailComponent,
        BarcodeScannerComponent,
        MediaStreamComponent,
        DefaultComponent,
        HomeISVContactDetailComponent,
        // AccessRestrictedComponent
    ],
    imports: [
        BrowserModule,
        DragAndDropModule,
        // AngularFontAwesomeModule,
        FontAwesomeModule,
        ColorPickerModule,
        BrowserAnimationsModule,
        JwBootstrapSwitchNg2Module,
        NgbModule,
        TextareaAutosizeModule,
        MatInputModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatAutocompleteModule, MatButtonModule, MatIconModule, MatProgressBarModule, MatTooltipModule, MatDialogModule, MatButtonToggleModule, MatCheckboxModule,
        ReactiveFormsModule,
        FileUploadModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(routes),
        // NgxMyDatePickerModule.forRoot(),
        AngularMyDatePickerModule,
        NgxDatatableModule,
        NgxMaterialTimepickerModule.forRoot(),
        // Ng2ImgMaxModule,
        ImageViewerModule.forRoot(),
        // PinchZoomModule,
        FullCalendarModule,
        OrderModule,
        // GaugeChartModule,
        // PopoverModule,
        RecaptchaModule,
        // TooltipModule,
        MatRadioModule,
        MatExpansionModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        DpDatePickerModule,
        ZXingScannerModule,
        MatTabsModule,
        // ScrollbarHelper
        // ,
        // NgxSpinnerModule
        // ZXingScannerModule
        // tooltip2Module
    ],
    // schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [ApiService, Globals, DMApiService, TableHeadsService,
        CommonCustomValidators, DMRepairCustomValidators, DMPMCustomValidators, AuthService, AuthGuardService, WPApiService, CreateRequestGuardService, CDApiService,
        ValidateUser,
        SchApiService,
        ContactApiService,
        CommonApiService,
        OrganizationalUnitApiService,
        DivisionApiService,
        DesignationApiService,
        ErrorCodeApiService,
        FieldMasterApiService,
        ScheduleColorApiService,
        FeedbackDetailApiService,
        TasksApiService,
        InvApiService,
        DMTransferService,
        HomeService,
        HomeFieldService,
        RPTApiService,
        MenuSharedService,
        PreviousRouteService,
        MailApiService,
        ForumApiService,
        BarcodeDecoderService,
        BarcodeValidatorService,
        VersionCheckService,
        CookieService,
        DatePipe,
        CurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        DecimalPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: globalSettings,
        },
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}

<style>
    .disable-whole-div__cover {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: transparent;
        opacity: 0.3;
    }
</style>

<div class="box ibox box-info">
    <!-- <div class="disable-whole-div__cover" [hidden]="disableControl"></div> -->
    <div class="box-header with-border" *ngIf="((PartTrans && PartTrans.length > 0) || status!='Completed') && showPartHearder==true">
        <h3 class="box-title">Replaced Part</h3>
    </div>
    <div class="box-body" style="margin-right: 20px;">
        <div *ngIf="showPartHearder != true">
            <div class="row" *ngIf="PartTrans.length > 0">
                <div class="col-sm-12 box-row">
                    <div class="col-sm-2 text-center" style="padding-left:0px;">
                        <strong>Date</strong>
                    </div>
                    <div class="col-sm-1 text-center" style="padding-left:0px;">
                        <strong>User</strong>
                    </div>
                    <div class="col-sm-1 text-center">
                        <strong>Part ID</strong>
                    </div>
                    <div class="col-sm-5 text-center">
                        <strong>Part Description</strong>
                    </div>
                    <div class="col-sm-1 text-center" style="padding-left:0px;">
                        <strong>Part Qty.</strong>
                    </div>
                    <div class="col-sm-1 text-right" style="padding-left:0px;">
                        <strong>Part Cost</strong>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of PartTrans;let index=index;" class="row">
                <div class="col-sm-12 box-row">
                    <div class="col-sm-2 text-center" style="padding-left:0px;" *ngIf="(item.createdOnDisplay)">
                        {{item.createdOnDisplay | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                    <div class="col-sm-2 text-center" style="padding-left:0px;" *ngIf="(!item.createdOnDisplay)">
                        {{item.createdOn | date:'dd/MM/yyyy HH:mm'}}
                    </div>
                    <div class="col-sm-1 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap; padding-left: 0px;" title="{{item.createdByName}}">
                        {{item.createdByName}}</div>
                    <div class="col-sm-1 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;" title="{{item.PartCode}}">
                        {{item.PartCode}}
                    </div>
                    <div class="col-sm-5 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;" title="{{item.PartDescription}}">
                        {{item.PartDescription}}
                    </div>

                    <div class="col-sm-1 text-center" style="padding-left:0px;">
                        {{item.quantity}}
                    </div>
                    <div class="col-sm-1 text-right" style="padding-left:0px;">
                        {{(item.PartCost * item.quantity | currency:"CAD":"symbol-narrow") }}
                    </div>
                    <div class="col-sm-1 text-center">
                        <button (click)="DeletePart(index)" type="button" *ngIf="(status!='Completed') && (item.showPartDelete)" class="btn btn-danger btn-label-left center-block btn-xs"> <span><i class="fa fa-times"></i></span>
                                                </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showPartHearder == true">
            <div class="row" *ngIf="PartTrans.length > 0">
                <div class="col-sm-12 box-row">
                    <div class="col-sm-2 text-center">
                        <strong>Part ID</strong>
                    </div>
                    <div class="col-sm-5 text-center">
                        <strong>Part Description</strong>
                    </div>
                    <div class="col-sm-2 text-center" style="padding-left:0px;">
                        <strong>Part Qty.</strong>
                    </div>
                    <div class="col-sm-2 text-right" style="padding-left:0px;">
                        <strong>Part Cost</strong>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of PartTrans;let index=index;" class="row">
                <div class="col-sm-12 box-row">
                    <div class="col-sm-2 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;" title="{{item.PartCode}}">
                        {{item.PartCode}}
                    </div>
                    <div class="col-sm-5 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;" title="{{item.PartDescription}}">
                        {{item.PartDescription}}
                    </div>
                    <div class="col-sm-2 text-center" style="padding-left:0px;">
                        {{item.quantity}}
                    </div>
                    <div class="col-sm-2 text-right" style="padding-left:0px;">
                        {{(item.PartCost * item.quantity | currency:"CAD":"symbol-narrow") }}
                    </div>
                    <div class="col-sm-1 text-center">
                        <button (click)="DeletePart(index)" type="button" *ngIf="(status!='Completed') && (item.showPartDelete)" class="btn btn-danger btn-label-left center-block btn-xs"> <span><i class="fa fa-times"></i></span>
                                </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="row" *ngIf="status!='Completed'"><br></div>
        <div class="row" *ngIf="status!='Completed'">
            <div class="col-sm-12 box-row">
                <div class="col-sm-2 text-center">
                    <strong>Part ID</strong>
                </div>
                <div class="col-sm-5 text-center">
                    <strong>Part Description</strong>
                </div>
                <!-- <div class="col-sm-2 text-center">
            <strong>Location</strong>
            </div> -->
                <div class="col-sm-2 text-center">
                    <strong>Part Quantity</strong>
                </div>
                <div class="col-sm-2 text-center">
                    <strong>Part Cost</strong>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="status!='Completed'">
            <div class="col-sm-12 box-row">
                <div class="col-sm-3 text-center" *ngIf="showPartHearder == true">
                    <input type="text" class="form-control" (keyup)="PartCodeChange('code')" [(ngModel)]="PartSearchTerm" matInput="PartSearchTerm" name="PartSearchTerm" [matAutocomplete]="autopart" [attr.disabled]="disableControl?'true':null">
                    <mat-autocomplete (optionSelected)='getPartPosts($event.option,"code")' #autopart="matAutocomplete">
                        <mat-option (click)="AddNewPartInventory()">
                            <i class="fa fa-plus"></i> Add new
                        </mat-option>

                        <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentcode">
                            {{ item.equipmentcode }}
                        </mat-option>
                    </mat-autocomplete>

                </div>
                <div class="col-sm-4" *ngIf="showPartHearder == true">
                    <input type="text" (keyup)="PartCodeChange('desc')" class="form-control" matInput name="PartDescription" [value]="PartSelectedItem.desc" [(ngModel)]="PartDescription" [matAutocomplete]="autopartdesc" [attr.disabled]="disableControl?'true':null">
                    <mat-autocomplete (optionSelected)='getPartPosts($event.option,"desc")' #autopartdesc="matAutocomplete">
                        <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentDesc">
                            {{ item.equipmentDesc }}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-sm-2 text-center" *ngIf="showPartHearder != true">
                    <input type="text" class="form-control" (keyup)="PartCodeChange('code')" [(ngModel)]="PartSearchTerm" matInput="PartSearchTerm" name="PartSearchTerm" [matAutocomplete]="autopart" [attr.disabled]="disableControl?'true':null">
                    <mat-autocomplete (optionSelected)='getPartPosts($event.option,"code")' #autopart="matAutocomplete">
                        <mat-option (click)="AddNewPartInventory()">
                            <i class="fa fa-plus"></i> Add new
                        </mat-option>

                        <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentcode">
                            {{ item.equipmentcode }}
                        </mat-option>
                    </mat-autocomplete>

                </div>
                <div class="col-sm-5" *ngIf="showPartHearder != true">
                    <input type="text" (keyup)="PartCodeChange('desc')" class="form-control" matInput name="PartDescription" [value]="PartSelectedItem.desc" [(ngModel)]="PartDescription" [matAutocomplete]="autopartdesc" [attr.disabled]="disableControl?'true':null">
                    <mat-autocomplete (optionSelected)='getPartPosts($event.option,"desc")' #autopartdesc="matAutocomplete">
                        <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentDesc">
                            {{ item.equipmentDesc }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <!-- <div class="col-sm-2">
                              <select  class="form-control mdInput" formControlName="PartLocation" name="PartLocation">
                                      <option  value="{{item.value}}" *ngFor="let item of PartLocations">{{item.text}}</option>
                                    </select>
                      </div> -->
                <div class="col-sm-2 text-center">
                    <select class="form-control mdInput center-block" style="width:75px;float:none" (change)="SetCost()" [(ngModel)]="PartQuantity" name="PartQuantity" [attr.disabled]="disableControl?'true':null">
                                      <option  value="{{item.value}}" *ngFor="let item of PartQtyList">{{item.text}}</option>
                                    </select>
                </div>
                <div class="col-sm-2 text-center ">
                    <div class="form-control readOnlyText">
                        {{PartCost | currency:"CAD":"symbol-narrow"}}
                    </div>

                </div>
                <div class="col-sm-1">
                    <button (click)="AddPart()" class="btn btn-primary btn-label-left center-block" type="button" [attr.disabled]="disableControl?'true':null">
                                      <span><i class="fa fa-plus"></i></span>
                                      <b>Add</b>
                                  </button>
                </div>
            </div>
        </div>

    </div>
</div>
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DMApiService } from '../../../API/dmapi.service';
import { WPApiService } from '../../../API/wpapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../../Core/ConfirmationDialog';
import { NoteDialog } from '../../../Core/NotesModel/NoteDialog';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../API/auth.service';
import { AcidLevelComponent } from '../../../Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from '../../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { WaterProcessCustomValidators } from '../../../Validator/WaterProcessCustomValidators';
import { NotesComponent } from '../../../Core/Shared/NotesComponent/NotesComponent';
import { CanComponentDeactivate } from '../../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map, ignoreElements } from 'rxjs/operators';
import { ScrollHelper } from '../../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../../globals';
import { debug } from 'util';
// import { id } from '@swimlane/ngx-datatable/release/utils';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { DatePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'WPDailyDetail',
  templateUrl: './WPDailyDetail.component.html',
  styleUrls: ['./WPDailyDetailStyle.css'],
})
export class WPDailyDetailComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  @ViewChild(AcidLevelComponent, { static: true })
  acidLevel: AcidLevelComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(UntypedFormControl, { static: false })
  mainform: UntypedFormControl;
  noteDialogRef: MatDialogRef<NoteDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  pageRights = '';
  IsSecondShiftWithFirstShiftDate : boolean = false;
  isValidFormSubmitted = null;
  ListURL = 'WaterProcess/Daily/GRWPDailySearch';

  eventId = '[AutoNumber]';

  loading = true;

  wpdailyDetailsIdvar = '0';

  DialysisUnits = [];

  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;

  HardNessList = [];
  Status = 'In Progress';
  DUName = '';
  FirstShiftUserName = '';
  FirstShiftDate = '';
  SecondShiftUserName = '';
  SecondShiftDate = '';
  private Today = new Date();
  morningDetailsEnteredBy = '0';
  morningDetailsEnteredOn;
  noonDetailsEnteredBy = '0';
  noonDetailsEnteredOn;
  createdOn = this.Today;
  modifiedOn = this.Today;

  formDate = '';
  blnshowSave = false;
  blnshowDelete = false;
  IsShepardForm = false;
  //NumberRegex = '^[0-9]+(.[0-9]{0,2})?$';
  IsEnablePM = false;

  DIBackupOptions = [
    { name: 'RO Online' },
    { name: 'DI BackUp' }
  ]

  DIBackupSelectedValue = ['RO Online'];


  @Input() division = 0;
  @Input() divisionShortText = '';


  public AcidLevelGroup: UntypedFormGroup = new UntypedFormGroup({});
  // -------------------------------------End------------------------------------------------

  // Form
  // -------------------------------------Start----------------------------------------------
  dailyDetailForm = new UntypedFormGroup({
    WPDailyDetailsId: new UntypedFormControl(''),
    GRWaterprocessDailyrecordsID : new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl(''),
    selectedMainDivision: new UntypedFormControl(''),
    RawWaterFiltersP: new UntypedFormControl('', [Validators.required,
      Validators.pattern(Globals.NumberRegex)]),
    RawWaterFiltersQ: new UntypedFormControl('', [Validators.required,
      Validators.pattern(Globals.NumberRegex)]),
    BlendingValve: new UntypedFormControl('', Validators.required),
    CapacityRemaining: new UntypedFormControl('', Validators.required),
    TotalHardness: new UntypedFormControl('', Validators.required),
    DPDTestPreTotalChlorine: new UntypedFormControl('', Validators.required),
    DPDTestPreFreeChlorine: new UntypedFormControl(''),
    DPDTestPostTotalChlorine: new UntypedFormControl('', Validators.required),
    DPDTestPostFreeChlorine: new UntypedFormControl(''),
    ROPreW: new UntypedFormControl('', Validators.required),
    ROPreX: new UntypedFormControl('', Validators.required),
    ROClock: new UntypedFormControl(''),
    ROFeedConductivity: new UntypedFormControl('', Validators.required),
    ROPermeateConductivity: new UntypedFormControl('', Validators.required),
    RORejection: new UntypedFormControl(''),
    ROPermeateFlow: new UntypedFormControl('', Validators.required),
    ROPrefilterIn: new UntypedFormControl(''),
    ROPrefilterOut: new UntypedFormControl(''),
    ROPumpPressure: new UntypedFormControl(''),
    RORingPressure: new UntypedFormControl(''),
    ThorntonMeterMorning: new UntypedFormControl('', Validators.required),
    DIRinsed: new UntypedFormControl('', Validators.required),
    SecondShiftTotalChlorineTextBox: new UntypedFormControl(''),
    SecondShiftFreeChlorineTextBox: new UntypedFormControl(''),
    ThorntonMeterAfternoonTextBox: new UntypedFormControl(''),
    UserName: new UntypedFormControl(''),
    Password: new UntypedFormControl(''),
    AcidLevels: this.AcidLevelGroup,
    P6_PSI: new UntypedFormControl(''),
    P7_PSI: new UntypedFormControl(''),
    P8_PSI: new UntypedFormControl(''),
    P9_PSI: new UntypedFormControl(''),
    P12_PSI: new UntypedFormControl(''),
    P13_PSI: new UntypedFormControl(''),
    P14_PSI: new UntypedFormControl(''),
    P15_PSI: new UntypedFormControl(''),
    PRE_FILTER_INLET_P13600_PSI: new UntypedFormControl(''),
    PRE_FILTER_OUT_P13605_PSI: new UntypedFormControl(''),
    FEED_CONDUCTIVTY_A13635: new UntypedFormControl(''),
    SP_Rejection: new UntypedFormControl(''),
    PUMP_1_CLOCK: new UntypedFormControl(''),
    PUMP_2_CLOCK: new UntypedFormControl(''),
    _1ST_PASS_PRODUCT_QUALITY_A13665: new UntypedFormControl(''),
    _2ND_PASS_PRODUCT_FLOW_A13710_GPM: new UntypedFormControl(''),
    _2ND_PASS_PRODUCT_QUALITY_A13665: new UntypedFormControl(''),
    LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG: new UntypedFormControl(''),
    LOOP_RETURN_FLOW_F13620_GPM: new UntypedFormControl(''),
    WorkingQuality: new UntypedFormControl(''),
    PolisherQuality: new UntypedFormControl(''),
    WorkingQualityPM: new UntypedFormControl(''),
    PolisherQualityPM: new UntypedFormControl(''),
    EnableDIBackupMode: new UntypedFormControl(''),
    EnableDIBackupModePM : new UntypedFormControl('')
  });

  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private wpapi: WPApiService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) {}
  // implementation of canDeactivate
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.dailyDetailForm.dirty ||
      this.notes.Note != '' ||
      this.attachment.Queue().length > 0 ) && (this.isValidFormSubmitted==false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
         //  console.log('res', res);
          return res;
        })
      );
    } else return true;
  }
  // -------------------------------------End------------------------------------------------


  ngAfterViewChecked() {
    //console.log('scroll');

    this.scrollHelper.doScroll();
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.Today.getFullYear(), month: this.Today.getMonth() + 1, day: this.Today.getDate() + 1 }
  };

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
      this.createdOn = this.modifiedOn = this.Today = new Date(list);
      this.setDateOptions();
    });

    this.SetPageRights();
    AppComponent.ShowLoader();
    //this.uploader.autoUpload = true;
    this.FirstShiftUserName = this.auth.GetUserFLName();
    //attachment changes
    this.attachment.DocId = this.f.WPDailyDetailsId.value;
    this.attachment.moduleName = 'WP_DailyWater';
    this.attachment.UploadBy = this.currentUserId;
    //this.notes.showHours = false;
    this.f.selectedDialysisUnits.setValue(this.division);
    this.f.selectedMainDivision.setValue(this.divisionShortText);
    // this.DialysisUnitDropDownChanged();

    var test = this.route.queryParams.subscribe(params => {
      this.f.GRWaterprocessDailyrecordsID.setValue(params['id'] || '0');
      //console.log(this.f.WPDailyDetailsId.value);

      while (this.HardNessList.length < 15) {
        this.HardNessList.push({
          text: (this.HardNessList.length + 1).toString(),
          value: (this.HardNessList.length + 1).toString()
        });
      }

      this.f.selectedDialysisUnits.updateValueAndValidity();

      this.HardNessList.unshift({ text: '<1', value: '<1' });
      this.HardNessList.unshift({ text: "Select", value: "" });
//
//       if(!this.f.EnableDIBackupMode.value){
//
//         this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.pattern(Globals.NumberRegex),
//           WaterProcessCustomValidators.CheckedSecondShift(
//             this.f.ThorntonMeterAfternoonTextBox
//           )]
//         );
//         this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();
//       }

//       this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.pattern(Globals.NumberRegex),
//         WaterProcessCustomValidators.CheckedSecondShift(
//           this.f.SecondShiftTotalChlorineTextBox
//         )]
//       );
//       this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();

      this.f.TotalHardness.setValue('');

      this.f.BlendingValve.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.CapacityRemaining.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.DPDTestPreTotalChlorine.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.DPDTestPostTotalChlorine.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.DPDTestPreFreeChlorine.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.DPDTestPostFreeChlorine.setValidators(Validators.pattern(Globals.NumberRegex));

      this.f.ROPreW.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROPreW.updateValueAndValidity();

      this.f.ROPreX.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROPreX.updateValueAndValidity();

      this.f.P6_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P7_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P8_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P9_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P12_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P13_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P14_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.P15_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.WorkingQuality.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PolisherQuality.setValidators(Validators.pattern(Globals.NumberRegex));

      this.f.WorkingQualityPM.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PolisherQualityPM.setValidators(Validators.pattern(Globals.NumberRegex));

      //this.f.DIRinsed.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROClock.setValidators(Validators.pattern(Globals.NumberRegex));

      this.f.ROFeedConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROFeedConductivity.updateValueAndValidity();

      this.f.ROPermeateConductivity.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.RORejection.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROPermeateFlow.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROPrefilterIn.setValidators(Validators.pattern(Globals.NumberRegex));
     // this.f.ROPrefilterOut.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ROPumpPressure.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.RORingPressure.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PRE_FILTER_INLET_P13600_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PRE_FILTER_OUT_P13605_PSI.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.FEED_CONDUCTIVTY_A13635.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.SP_Rejection.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PUMP_1_CLOCK.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PUMP_2_CLOCK.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.SecondShiftFreeChlorineTextBox.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.LOOP_RETURN_FLOW_F13620_GPM.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.WorkingQuality.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PolisherQuality.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.WorkingQualityPM.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.PolisherQualityPM.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.SecondShiftTotalChlorineTextBox.setValidators(Validators.pattern(Globals.NumberRegex));
      this.f.ThorntonMeterAfternoonTextBox.setValidators(Validators.pattern(Globals.NumberRegex));

      if (this.f.GRWaterprocessDailyrecordsID.value != "0") {
        this.setModel();
      } else {
        this.fillDialysisUnit();
        this.ValidateDailyForm();
        this.DialysisUnitDropDownChanged();
        // this.f.selectedDialysisUnits.setValidators([Validators.required]);
        // this.f.selectedDialysisUnits.setAsyncValidators(
        //   WaterProcessCustomValidators.WaterProcessDivisionValidate(this.wpapi)
        // );
        // this.f.selectedDialysisUnits.updateValueAndValidity();
      }
    });

    AppComponent.HideLoader();
  }

  get f() {
    return this.dailyDetailForm.controls;
  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.Today.getFullYear(), month: this.Today.getMonth() + 1, day: this.Today.getDate() + 1 }
    };
  }

  setModel() {

    AppComponent.ShowLoader();
    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.clearAsyncValidators();
    if (this.attachment)
    this.attachment.clearQueue();

  if (this.notes) {
    this.notes.Note = '';
    //this.notes.showHours = false;
  }
    var wpdailyCreate = { Id: this.f.GRWaterprocessDailyrecordsID.value };
    this.fillDialysisUnit();
    this.wpapi.GetWaterProcessDailyRecordByID(wpdailyCreate).subscribe(
      success => {
        //  console.log(success);

        if(success.grWaterprocessDailyrecordsID)
          this.f.GRWaterprocessDailyrecordsID.setValue(success.grWaterprocessDailyrecordsID);

        this.f.WPDailyDetailsId.setValue(success.dailywaterrecordid);
        this.f.selectedDialysisUnits.setValue(success.duid);

        this.f.selectedMainDivision.setValue(success.formType);
        // this.formDate = success.formDate;

        this.f.RawWaterFiltersP.setValue(success.rawwaterfilterp);
        this.f.RawWaterFiltersQ.setValue(success.rawwaterfilterq);

        this.f.BlendingValve.setValue(success.temparatureblendingvalve);

        this.f.CapacityRemaining.setValue(success.softenercapacityremaining);

        this.f.TotalHardness.setValue(success.totalhardness);
debugger;
        this.f.DPDTestPreTotalChlorine.setValue(
          success.dpdtestprecarbontotalchlorine
        );
        this.f.DPDTestPreFreeChlorine.setValue(
          success.dpdtestprecarbonfreechlorine
        );

        this.f.DPDTestPostTotalChlorine.setValue(
          success.dpdtestpostcarbontotalchlorine
        );
        this.f.DPDTestPostFreeChlorine.setValue(
          success.dpdtestpostcarbonfreechlorine
        );

        this.f.DIRinsed.setValue(success.ditanksdi);

        if(success.secondshifttotalchlorine != '0'){
          this.f.SecondShiftTotalChlorineTextBox.setValue(
            success.secondshifttotalchlorine
          );
        }

        if(success.secondshiftfreechlorine != '0'){
          this.f.SecondShiftFreeChlorineTextBox.setValue(
            success.secondshiftfreechlorine
          );
        }

        if(success.thorntonmeterafternoon != '0'){
          this.f.ThorntonMeterAfternoonTextBox.setValue(
            success.thorntonmeterafternoon
          );
        }

        this.f.UserName.setValue(success.morningContact);

        this.Status = success.status;
        this.eventId = success.eventid;
        this.DUName = success.divisionName;
        this.FirstShiftUserName = success.morningContact;
        this.FirstShiftDate = success.morningDetailsEnteredOn;
        this.SecondShiftUserName = success.noonContact;
        this.morningDetailsEnteredBy = success.morningDetailsEnteredBy;
        this.noonDetailsEnteredBy = success.noonDetailsEnteredBy;

        if (!this.SecondShiftUserName) {
          this.SecondShiftUserName = this.auth.GetUserName();
        }
        this.SecondShiftDate = success.noonDetailsEnteredOn;
        this.createdOn = new Date(success.morningDetailsEnteredOn);
        this.f.WorkingQuality.setValue(success.workerQuality);
        this.f.PolisherQuality.setValue(success.polisherQuality);

        if(success.workerQualitypm != '0'){
          this.f.WorkingQualityPM.setValue(success.workerQualitypm);
        }
        if(success.polisherQualitypm != '0'){
          this.f.PolisherQualityPM.setValue(success.polisherQualitypm);
        }

        this.f.EnableDIBackupMode.setValue(success.enableDIBackup);
        this.f.EnableDIBackupModePM.setValue(success.enableDIBackupPM);

        if(success.enableDIBackupPM == true)
        {
          this.DIBackupSelectedValue = ['DI BackUp'];
        }
        else
        {
          this.DIBackupSelectedValue = ['RO Online'];
        }

        this.ValidateDailyForm();
        this.f.ThorntonMeterMorning.setValue(success.thorntonmetermorning);

        // console.log(this.IsShepardForm);
        if (this.IsShepardForm) {
         //  console.log(success);
          this.f.P6_PSI.setValue(success.roprefilterP6);
          this.f.P7_PSI.setValue(success.roprefilterP7);
          this.f.P8_PSI.setValue(success.roprefilterP8);
          this.f.P9_PSI.setValue(success.roprefilterP9);
          this.f.P12_PSI.setValue(success.roprefilterP12);
          this.f.P13_PSI.setValue(success.roprefilterP13);
          this.f.P14_PSI.setValue(success.roprefilterP14);
          this.f.P15_PSI.setValue(success.roprefilterP15);
          this.f.PRE_FILTER_INLET_P13600_PSI.setValue(success.ropreinlet);
          this.f.PRE_FILTER_OUT_P13605_PSI.setValue(success.ropreoutP13605);
          this.f.FEED_CONDUCTIVTY_A13635.setValue(
            success.rofeedConductivityA13635
          );
          this.f.SP_Rejection.setValue(success.rorejection);
          this.f.PUMP_1_CLOCK.setValue(success.ropump1clock);
          this.f.PUMP_2_CLOCK.setValue(success.ropump2clock);
          this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValue(
            success.ro1stpassprodcutQltA13665
          );
          this.f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.setValue(
            success.ro2ndpassprodcutflowA13710
          );
          this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValue(
            success.ro2ndpassprodcutQltA13695
          );
          this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValue(
            success.roloopfilterP13715
          );
          this.f.LOOP_RETURN_FLOW_F13620_GPM.setValue(
            success.roloopreturnflowF13620
          );
        } else {
          this.f.ROPreW.setValue(success.roprefilterw);
          this.f.ROPreX.setValue(success.roprefilterx);

          this.f.ROClock.setValue(success.roclock);
          this.f.ROFeedConductivity.setValue(success.feedconductivity);
          this.f.ROPermeateConductivity.setValue(success.permeateconductivity);
          this.f.RORejection.setValue(success.rorejection);
          this.f.ROPermeateFlow.setValue(success.permeateflow);
          this.f.ROPrefilterIn.setValue(success.prefilterin);
          this.f.ROPrefilterOut.setValue(success.roprefilterout);
          this.f.ROPumpPressure.setValue(success.pumppressure);
          this.f.RORingPressure.setValue(success.ringpressure);
        }

        this.IsSecondShiftWithFirstShiftDate = (this.datePipe.transform(this.FirstShiftDate,'dd/MM/yyyy')) == (this.datePipe.transform(this.SecondShiftDate,'dd/MM/yyyy'));

        this.GreaterValueCheck();
        var wpDailyObj = { Id: this.f.WPDailyDetailsId.value };
        this.wpapi
          .GetNotesBywaterprocessdailyrecordID(wpDailyObj)
          .subscribe(list => {
            this.notes.NoteList = list;
          });

        this.wpapi
          .GetattachemnetsBywaterprocessdailyrecordID(wpDailyObj)
          .subscribe(list => {
            //this.attachment.attachmentList = [];
            this.attachment.PrevAttachments = list;
          });
        var wpCreate = { Id: this.f.WPDailyDetailsId.value };
        this.wpapi
          .SelectAcidLevelsBydailywaterrecordid(wpCreate)
          .subscribe(success => {

            if (this.acidLevel)
              this.acidLevel.AcidLevelData = [];

            for (let childObj in success) {

              var obj = {
                optiondisplayas: success[childObj].caption,
                readonly: !(this.f.WPDailyDetailsId.value == '0'),
                controlname: success[childObj].caption.replace(/\s+/g, '_'),
                result: success[childObj].acidlevel
              };

              if (this.acidLevel)
                this.acidLevel.AcidLevelData.push(obj);

              let control: UntypedFormControl = new UntypedFormControl(
                success[childObj].acidlevel,
                []
              );
              this.AcidLevelGroup.addControl(
                success[childObj].caption.replace(/\s+/g, '_'),
                control
              );
            }
            if (this.acidLevel) {
              this.f.AcidLevels = this.AcidLevelGroup;
              this.acidLevel.AcidLevelFormGroup = this.AcidLevelGroup;
            }

            // console.log(this.acidLevel.AcidLevelData); // this.acidLevel.AcidLevelData=success;
          });


          // this.FirstShiftDate == this.SecondShiftDate

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  SetPageRights() {
    // this.auth.GetPageRights('Water Process Daily Record').subscribe(list => {
    //   if (this.f.WPDailyDetailsId.value != '0') {
    //     this.blnshowSave = list[actionType.edit] == '1';
    //     // this.blnshowDelete = list[actionType.delete] == '1';
    //   } else {
    //     this.blnshowSave = list[actionType.create] == '1';
    //     // this.blnshowDelete = false;
    //   }
    // });
    this.blnshowSave = true;

    if (this.userDesignation == 'Administrator' && this.f.WPDailyDetailsId.value != '0') {
      this.blnshowDelete = true;
    }
  }


  mainSave() {
    return new Promise((resolve, reject) => {
      this.executeWPDailyCreateUpdate('');
      resolve(true);
    });
  }
  //Functions/Methods
  //-------------------------------------Start----------------------------------------------

  fillDialysisUnit() {
    this.dmapi
      .GetDialysisUnitWP()
      .subscribe(list => (this.DialysisUnits = list));
  }

  executeWPDailyCreateUpdate = function(Source) {

    AppComponent.loading = true;
   //  console.log(this.dailyDetailForm);
    this.GreaterValueCheck();
    this.isValidFormSubmitted = false;

    if (this.acidLevel)
      this.acidLevel.isValidFormSubmitted = this.isValidFormSubmitted;

    if ((this.dailyDetailForm.invalid && Source != 'Notes') ||
      (this.dailyDetailForm.invalid && Source == 'Notes' && (!this.f.WPDailyDetailsId.value || this.f.WPDailyDetailsId.value == '0'))) {
      console.log(this.f);
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      //window.scrollBy(0,500);
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      Object.keys(this.dailyDetailForm.controls).forEach(key => {
        //const controlErrors: any = this.dailyDetailForm.get(key).errors;
        // if (controlErrors != null) {
        //   Object.keys(controlErrors).forEach(keyError => {
        //     console.log(
        //       'Key control: ' +
        //         key +
        //         ', keyError: ' +
        //         keyError +
        //         ', err value: ',
        //       controlErrors[keyError]
        //     );
        //   });
        // }
      });
      return;
    }
    this.isValidFormSubmitted = true;
    var wpdailyCreate = this.prepareCreateViewModel(Source);
    if (!wpdailyCreate.dailyWaterRecordId || wpdailyCreate.dailyWaterRecordId == '0') {
      this.wpapi.CreateWaterProcessDailyRecord(wpdailyCreate).subscribe(
        success => {

          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source != 'Notes') {
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          } else {
            this.f.WPDailyDetailsId.setValue(success.dailyWaterRecordId);
            this.f.GRWaterprocessDailyrecordsID.setValue(success.grWaterprocessDailyrecordsID);
            this.setModel();
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.wpapi.UpdateWaterProcessDailyRecord(wpdailyCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source != 'Notes') {
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel(Source) {
    var wpdailyCreate = <any>{};
debugger;
    //wpdailyCreate.Status = 'Completed';
    wpdailyCreate.Status = this.Status;
    wpdailyCreate.GRWaterprocessDailyrecordsID = this.f.GRWaterprocessDailyrecordsID.value;
    wpdailyCreate.dailyWaterRecordId = this.f.WPDailyDetailsId.value;
    wpdailyCreate.divisionId = this.f.selectedDialysisUnits.value;
    wpdailyCreate.FormType = this.f.selectedMainDivision.value;
    if(wpdailyCreate.GRWaterprocessDailyrecordsID != 0){
      wpdailyCreate.FormDate = this.formDate;
    }
    wpdailyCreate.rawWaterFilterP = this.f.RawWaterFiltersP.value;
    wpdailyCreate.rawWaterFilterQ = this.f.RawWaterFiltersQ.value;
    wpdailyCreate.temparatureBlendingValve = this.f.BlendingValve.value;
    wpdailyCreate.softenerCapacityRemaining = this.f.CapacityRemaining.value;
    wpdailyCreate.totalHardness = this.f.TotalHardness.value;
    wpdailyCreate.dpdtestprecarbonTotalChlorine = this.f.DPDTestPreTotalChlorine.value;
    wpdailyCreate.dpdtestprecarbonFreeChlorine = this.f.DPDTestPreFreeChlorine.value;
    wpdailyCreate.dpdtestpostcarbonTotalChlorine = this.f.DPDTestPostTotalChlorine.value;
    wpdailyCreate.dpdtestpostcarbonFreeChlorine = this.f.DPDTestPostFreeChlorine.value;

    wpdailyCreate.thorntonmetermorning = this.f.ThorntonMeterMorning.value;
    wpdailyCreate.ditanksdi = this.f.DIRinsed.value;
    wpdailyCreate.secondshifttotalchlorine = this.f.SecondShiftTotalChlorineTextBox.value;
    wpdailyCreate.secondshiftfreechlorine = this.f.SecondShiftFreeChlorineTextBox.value;
    wpdailyCreate.thorntonmeterafternoon = this.f.ThorntonMeterAfternoonTextBox.value;
    wpdailyCreate.eventId = this.eventId;
    wpdailyCreate.createdBy = this.currentUserId;
    wpdailyCreate.WorkerQuality = this.f.WorkingQuality.value;
    wpdailyCreate.PolisherQuality = this.f.PolisherQuality.value;
    wpdailyCreate.WorkerQualityPM = this.f.WorkingQualityPM.value;
    wpdailyCreate.PolisherQualityPM = this.f.PolisherQualityPM.value;
    wpdailyCreate.EnableDIBackup = this.f.EnableDIBackupMode.value;

    if (this.f.WPDailyDetailsId.value === '0' || this.f.WPDailyDetailsId.value == '') {
      wpdailyCreate.EnableDIBackupPM = this.f.EnableDIBackupModePM.value;
      wpdailyCreate.morningDetailsEnteredBy = this.currentUserId;
      wpdailyCreate.morningDetailsEnteredOn = Globals.GetAPIDate(this.Today);
      wpdailyCreate.WPDailySectionType = 'AM';
      if (
        wpdailyCreate.secondshifttotalchlorine != '0' && wpdailyCreate.secondshifttotalchlorine != '' &&
        wpdailyCreate.thorntonmeterafternoon != '0' && wpdailyCreate.thorntonmeterafternoon != ''
      ) {
        wpdailyCreate.noonDetailsEnteredBy = this.currentUserId;
        wpdailyCreate.noonDetailsEnteredOn = Globals.GetAPIDate(this.Today);
      }
    } else {
      if(this.DIBackupSelectedValue[0] == 'DI BackUp')
      {
        wpdailyCreate.EnableDIBackupPM = true;
      }
      else
      {
        wpdailyCreate.EnableDIBackupPM = false;
      }

      wpdailyCreate.modifiedBy = this.currentUserId;
      wpdailyCreate.modifiedOn = this.Today;

      wpdailyCreate.morningDetailsEnteredBy = this.morningDetailsEnteredBy;
      wpdailyCreate.morningDetailsEnteredOn = this.FirstShiftDate;

      if (
        wpdailyCreate.secondshifttotalchlorine != '0' &&
        wpdailyCreate.thorntonmeterafternoon != '0' &&
        wpdailyCreate.secondshifttotalchlorine != '' &&
        wpdailyCreate.thorntonmeterafternoon != ''
      ) {
        wpdailyCreate.noonDetailsEnteredBy = this.currentUserId;
        wpdailyCreate.noonDetailsEnteredOn = Globals.GetAPIDate(this.Today);
        wpdailyCreate.WPDailySectionType = 'PM';
      }
      if (this.Status == 'Completed') {
        wpdailyCreate.noonDetailsEnteredBy = this.noonDetailsEnteredBy;
        wpdailyCreate.noonDetailsEnteredOn = this.SecondShiftDate;
        wpdailyCreate.WPDailySectionType = 'Notes';
      }
    }


    wpdailyCreate.notes = this.notes.Note;

    //attachments
    wpdailyCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    if (this.acidLevel)
      wpdailyCreate.dailyRecordAcidLevelDetail = this.acidLevel.GetAcidLevel();

    //sphered new form
    // this.ValidateDailyForm();
    if (this.IsShepardForm) {
      wpdailyCreate.roprefilterP6 = this.f.P6_PSI.value;
      wpdailyCreate.roprefilterP7 = this.f.P7_PSI.value;
      wpdailyCreate.roprefilterP8 = this.f.P8_PSI.value;
      wpdailyCreate.roprefilterP9 = this.f.P9_PSI.value;
      wpdailyCreate.roprefilterP12 = this.f.P12_PSI.value;
      wpdailyCreate.roprefilterP13 = this.f.P13_PSI.value;
      wpdailyCreate.roprefilterP14 = this.f.P14_PSI.value;
      wpdailyCreate.roprefilterP15 = this.f.P15_PSI.value;
      wpdailyCreate.ropreinlet = this.f.PRE_FILTER_INLET_P13600_PSI.value;
      wpdailyCreate.ropreoutP13605 = this.f.PRE_FILTER_OUT_P13605_PSI.value;
      wpdailyCreate.rofeedConductivityA13635 = this.f.FEED_CONDUCTIVTY_A13635.value;
      wpdailyCreate.rorejection = this.f.SP_Rejection.value;
      wpdailyCreate.ropump1clock = this.f.PUMP_1_CLOCK.value;
      wpdailyCreate.ropump2clock = this.f.PUMP_2_CLOCK.value;
      wpdailyCreate.ro1stpassprodcutQltA13665 = this.f._1ST_PASS_PRODUCT_QUALITY_A13665.value;
      wpdailyCreate.ro2ndpassprodcutflowA13710 = this.f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.value;
      wpdailyCreate.ro2ndpassprodcutQltA13695 = this.f._2ND_PASS_PRODUCT_QUALITY_A13665.value;
      wpdailyCreate.roloopfilterP13715 = this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.value;
      wpdailyCreate.roloopreturnflowF13620 = this.f.LOOP_RETURN_FLOW_F13620_GPM.value;
    } else {
      wpdailyCreate.roprefilterW = this.f.ROPreW.value;
      wpdailyCreate.roprefilterX = this.f.ROPreX.value;
      wpdailyCreate.roclock = this.f.ROClock.value;
      wpdailyCreate.feedconductivity = this.f.ROFeedConductivity.value;
      wpdailyCreate.permeateconductivity = this.f.ROPermeateConductivity.value;
      wpdailyCreate.rorejection = this.f.RORejection.value;
      wpdailyCreate.permeateflow = this.f.ROPermeateFlow.value;
      wpdailyCreate.prefilterin = this.f.ROPrefilterIn.value;
      wpdailyCreate.roprefilterout = this.f.ROPrefilterOut.value;
      wpdailyCreate.pumppressure = this.f.ROPumpPressure.value;
      wpdailyCreate.ringpressure = this.f.RORingPressure.value;
    }
     //  console.log(wpdailyCreate);
    return wpdailyCreate;
  }

  validateSussexForm(){

    this.f.P6_PSI.setValue('');
    this.f.P6_PSI.clearValidators();
    this.f.P6_PSI.updateValueAndValidity();

    this.f.P7_PSI.setValue('');
    this.f.P7_PSI.clearValidators();
    this.f.P7_PSI.updateValueAndValidity();

    this.f.P8_PSI.setValue('');
    this.f.P8_PSI.clearValidators();
    this.f.P8_PSI.updateValueAndValidity();

    this.f.P9_PSI.setValue('');
    this.f.P9_PSI.clearValidators();
    this.f.P9_PSI.updateValueAndValidity();

    this.f.P12_PSI.setValue('');
    this.f.P12_PSI.clearValidators();
    this.f.P12_PSI.updateValueAndValidity();

    this.f.P13_PSI.setValue('');
    this.f.P13_PSI.clearValidators();
    this.f.P13_PSI.updateValueAndValidity();

    this.f.P14_PSI.setValue('');
    this.f.P14_PSI.clearValidators();
    this.f.P14_PSI.updateValueAndValidity();

    this.f.P15_PSI.setValue('');
    this.f.P15_PSI.clearValidators();
    this.f.P15_PSI.updateValueAndValidity();

    this.f.SP_Rejection.setValue('');
    this.f.SP_Rejection.clearValidators();
    this.f.SP_Rejection.updateValueAndValidity();

    this.f.FEED_CONDUCTIVTY_A13635.setValue('');
    this.f.FEED_CONDUCTIVTY_A13635.clearValidators();
    this.f.FEED_CONDUCTIVTY_A13635.updateValueAndValidity();

    this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValue('');
    this.f._1ST_PASS_PRODUCT_QUALITY_A13665.clearValidators();
    this.f._1ST_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValue('');
    this.f._2ND_PASS_PRODUCT_QUALITY_A13665.clearValidators();
    this.f._2ND_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValue('');
    this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.clearValidators();
    this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.updateValueAndValidity();

    this.f.LOOP_RETURN_FLOW_F13620_GPM.setValue('');
    this.f.LOOP_RETURN_FLOW_F13620_GPM.clearValidators();
    this.f.LOOP_RETURN_FLOW_F13620_GPM.updateValueAndValidity();

    this.f.ThorntonMeterMorning.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex)]);
    this.f.ThorntonMeterMorning.updateValueAndValidity();

    this.f.ROPreX.setValidators([
      Validators.required,Validators.pattern(Globals.NumberRegex),
      WaterProcessCustomValidators.GreaterRestrictCustomValidation(
        this.f.ROPreW
      )
    ]);
    this.f.ROPreX.updateValueAndValidity();

    this.f.ROPreW.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.ROPreW.updateValueAndValidity();

    if (this.f.EnableDIBackupMode.value == false || this.f.EnableDIBackupMode.value == '') {
      this.f.ROClock.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex)]);
      this.f.ROClock.updateValueAndValidity();

      this.f.ROFeedConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROFeedConductivity.updateValueAndValidity();

      this.f.ROPermeateConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROPermeateConductivity.updateValueAndValidity();

      this.f.RORejection.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.RORejection.updateValueAndValidity();

      this.f.ROPermeateFlow.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROPermeateFlow.updateValueAndValidity();

      this.f.ROPrefilterIn.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROPrefilterIn.updateValueAndValidity();

      this.f.ROPrefilterOut.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex),
        WaterProcessCustomValidators.GreaterRestrictCustomValidation(
          this.f.ROPrefilterIn
        )]
      );
      this.f.ROPrefilterOut.updateValueAndValidity();

      this.f.ROPumpPressure.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.ROPumpPressure.updateValueAndValidity();

      this.f.RORingPressure.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.RORingPressure.updateValueAndValidity();
    }
    if (this.f.EnableDIBackupMode.value == true) {
      this.f.ROClock.setValue('');
      this.f.ROFeedConductivity.setValue('');
      this.f.ROPermeateConductivity.setValue('');
      this.f.RORejection.setValue('');
      this.f.ROPermeateFlow.setValue('');
      this.f.ROPrefilterIn.setValue('');
      this.f.ROPrefilterOut.setValue('');
      this.f.ROPumpPressure.setValue('');
      this.f.RORingPressure.setValue('');

      this.f.ROClock.clearValidators();
      this.f.ROClock.updateValueAndValidity();

      this.f.RORejection.clearValidators();
      this.f.RORejection.updateValueAndValidity();

      this.f.ROPrefilterIn.clearValidators();
      this.f.ROPrefilterIn.updateValueAndValidity();

      this.f.ROPrefilterOut.clearValidators();
      this.f.ROPrefilterOut.updateValueAndValidity();

      this.f.ROPumpPressure.clearValidators();
      this.f.ROPumpPressure.updateValueAndValidity();

      this.f.RORingPressure.clearValidators();
      this.f.RORingPressure.updateValueAndValidity();

      this.f.ROFeedConductivity.clearValidators();
      this.f.ROFeedConductivity.updateValueAndValidity();

      this.f.ROPermeateConductivity.clearValidators();
      this.f.ROPermeateConductivity.updateValueAndValidity();

      this.f.ROPermeateFlow.clearValidators();
      this.f.ROPermeateFlow.updateValueAndValidity();

    }
    if (this.FirstShiftDate) {
      this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
      this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

      this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
      this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
    }
  }

  validateSheppardForm(){

    if(this.FirstShiftDate)
    {
      this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
      this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

      this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
      this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();

      if(this.DIBackupSelectedValue[0] == 'DI BackUp')
      {
        this.f.WorkingQualityPM.setValidators([Validators.required]);
        this.f.WorkingQualityPM.updateValueAndValidity();

        this.f.PolisherQualityPM.setValidators([Validators.required]);
        this.f.PolisherQualityPM.updateValueAndValidity();

        this.f.ThorntonMeterAfternoonTextBox.setValue('');
        this.f.ThorntonMeterAfternoonTextBox.clearValidators();
        this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
      }
      else
      {
        this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
        this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();

        this.f.WorkingQualityPM.setValue('');
        this.f.WorkingQualityPM.clearValidators();
        this.f.WorkingQualityPM.updateValueAndValidity();

        this.f.PolisherQualityPM.setValue('');
        this.f.PolisherQualityPM.clearValidators();
        this.f.PolisherQualityPM.updateValueAndValidity();
      }
    }
    else
    {
    this.f.P6_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P6_PSI.updateValueAndValidity();

    this.f.P7_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P7_PSI.updateValueAndValidity();

    this.f.P8_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P8_PSI.updateValueAndValidity();

    this.f.P9_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P9_PSI.updateValueAndValidity();

    this.f.P12_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P12_PSI.updateValueAndValidity();

    this.f.P13_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P13_PSI.updateValueAndValidity();

    this.f.P14_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P14_PSI.updateValueAndValidity();

    this.f.P15_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    this.f.P15_PSI.updateValueAndValidity();

    if (this.f.EnableDIBackupMode.value === false || this.f.EnableDIBackupMode.value == '') {
      this.f.SP_Rejection.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex)]);
      this.f.SP_Rejection.updateValueAndValidity();

      this.f.FEED_CONDUCTIVTY_A13635.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.FEED_CONDUCTIVTY_A13635.updateValueAndValidity();

      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.updateValueAndValidity();

      this.f.LOOP_RETURN_FLOW_F13620_GPM.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.LOOP_RETURN_FLOW_F13620_GPM.updateValueAndValidity();

      this.f.WorkingQuality.clearValidators();
      this.f.WorkingQuality.updateValueAndValidity();

      this.f.PolisherQuality.clearValidators();
      this.f.PolisherQuality.updateValueAndValidity();

      if(!this.IsEnablePM)
        this.f.EnableDIBackupModePM.setValue(false);

      // if (this.FirstShiftDate) {
      //   this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
      //   this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

      //   this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
      //   this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
      // }
    }
    if (this.f.EnableDIBackupMode.value === true) {
      this.f.WorkingQuality.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex)]);
      this.f.WorkingQuality.updateValueAndValidity();

      this.f.PolisherQuality.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
      this.f.PolisherQuality.updateValueAndValidity();

      this.f.SP_Rejection.clearValidators();
      this.f.SP_Rejection.updateValueAndValidity();

      this.f.FEED_CONDUCTIVTY_A13635.clearValidators();
      this.f.FEED_CONDUCTIVTY_A13635.updateValueAndValidity();

      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.clearValidators();
      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.clearValidators();
      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.clearValidators();
      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.updateValueAndValidity();

      this.f.LOOP_RETURN_FLOW_F13620_GPM.clearValidators();
      this.f.LOOP_RETURN_FLOW_F13620_GPM.updateValueAndValidity();


      this.f.SP_Rejection.setValue('');
      this.f.FEED_CONDUCTIVTY_A13635.setValue('');
      this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValue('');
      this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValue('');
      this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValue('');
      this.f.LOOP_RETURN_FLOW_F13620_GPM.setValue('');

      if(!this.IsEnablePM)
        this.f.EnableDIBackupModePM.setValue(true);

      // if (this.FirstShiftDate) {
      //   this.f.WorkingQualityPM.setValidators([Validators.required]);
      //   this.f.WorkingQualityPM.updateValueAndValidity();

      //   this.f.PolisherQualityPM.setValidators([Validators.required]);
      //   this.f.PolisherQualityPM.updateValueAndValidity();

      //   this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
      //   this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();
      // }

    }
  }

    this.f.ROClock.setValue('');
    this.f.ROClock.clearValidators();
    this.f.ROClock.updateValueAndValidity();

    this.f.RORejection.setValue('');
    this.f.RORejection.clearValidators();
    this.f.RORejection.updateValueAndValidity();

    this.f.ROPrefilterIn.setValue('');
    this.f.ROPrefilterIn.clearValidators();
    this.f.ROPrefilterIn.updateValueAndValidity();

    this.f.ROPumpPressure.setValue('');
    this.f.ROPumpPressure.clearValidators();
    this.f.ROPumpPressure.updateValueAndValidity();

    this.f.RORingPressure.setValue('');
    this.f.RORingPressure.clearValidators();
    this.f.RORingPressure.updateValueAndValidity();

    this.f.ROPreX.setValue('');
    this.f.ROPreX.clearValidators();
    this.f.ROPreX.updateValueAndValidity();

    this.f.ROPreW.setValue('');
    this.f.ROPreW.clearValidators();
    this.f.ROPreW.updateValueAndValidity();

    this.f.ROFeedConductivity.setValue('');
    this.f.ROFeedConductivity.clearValidators();
    this.f.ROFeedConductivity.updateValueAndValidity();

    this.f.ROPermeateConductivity.setValue('');
    this.f.ROPermeateConductivity.clearValidators();
    this.f.ROPermeateConductivity.updateValueAndValidity();

    this.f.ROPermeateFlow.setValue('');
    this.f.ROPermeateFlow.clearValidators();
    this.f.ROPermeateFlow.updateValueAndValidity();

    this.f.ROPrefilterOut.setValue('');
    this.f.ROPrefilterOut.clearValidators();
    this.f.ROPrefilterOut.updateValueAndValidity();

    this.f.ThorntonMeterMorning.setValue('');
    this.f.ThorntonMeterMorning.clearValidators();
    this.f.ThorntonMeterMorning.updateValueAndValidity();
  }

  ValidateDailyForm() {


    // console.log('test' + this.f.selectedDialysisUnits.value , this.createdOn);
    this.IsShepardForm =
      this.division == 2 &&
      this.createdOn > new Date('2018-08-15T10:00:00.00');

      this.f.RawWaterFiltersQ.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex),
        WaterProcessCustomValidators.GreaterRestrictCustomValidation(
          this.f.RawWaterFiltersP
        )]);

        this.f.RawWaterFiltersQ.updateValueAndValidity();

        // this.f.ROPreX.setValidators([
        //   Validators.required,Validators.pattern(Globals.NumberRegex),
        //   WaterProcessCustomValidators.GreaterRestrictCustomValidation(
        //     this.f.ROPreW
        //   )
        // ]);
        // this.f.ROPreX.updateValueAndValidity();
        // this.f.ROPreX.setValidators(
        //   WaterProcessCustomValidators.GreaterRestrictCustomValidation(
        //     this.f.ROPreW
        //   )
        // );

        // this.f.ROPrefilterOut.setValidators([Validators.pattern(Globals.NumberRegex),
        //   WaterProcessCustomValidators.GreaterRestrictCustomValidation(
        //     this.f.ROPrefilterIn
        //   )]
        // );
        // this.f.ROPrefilterOut.updateValueAndValidity();

    if (this.IsShepardForm == true) {
      this.validateSheppardForm();
    }
    if (this.IsShepardForm == false) {
      this.validateSussexForm();
    }

    // if (this.IsShepardForm === true || this.f.EnableDIBackupMode.value === true) {

    //   if(this.IsShepardForm == true && this.f.EnableDIBackupMode.value === true)
    //   {
    //
    //     this.f.PRE_FILTER_INLET_P13600_PSI.setValue('');
    //     this.f.PRE_FILTER_OUT_P13605_PSI.setValue('');
    //     this.f.FEED_CONDUCTIVTY_A13635.setValue('');
    //     this.f.SP_Rejection.setValue('');
    //     this.f.PUMP_1_CLOCK.setValue('');
    //     this.f.PUMP_2_CLOCK.setValue('');
    //     this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValue('');
    //     this.f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.setValue('');
    //     this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValue('');
    //     this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValue('');
    //     this.f.LOOP_RETURN_FLOW_F13620_GPM.setValue('');

    //     this.f.ThorntonMeterMorning.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.ThorntonMeterMorning.updateValueAndValidity();

    //     this.f.WorkingQuality.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.WorkingQuality.updateValueAndValidity();

    //     this.f.PolisherQuality.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.PolisherQuality.updateValueAndValidity();

    //     this.f.SP_Rejection.clearValidators();
    //     this.f.SP_Rejection.updateValueAndValidity();

    //     this.f.FEED_CONDUCTIVTY_A13635.clearValidators();
    //     this.f.FEED_CONDUCTIVTY_A13635.updateValueAndValidity();

    //     this.f._1ST_PASS_PRODUCT_QUALITY_A13665.clearValidators();
    //     this.f._1ST_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    //     this.f._2ND_PASS_PRODUCT_QUALITY_A13665.clearValidators();
    //     this.f._2ND_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    //     this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.clearValidators();
    //     this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.updateValueAndValidity();

    //     this.f.LOOP_RETURN_FLOW_F13620_GPM.clearValidators();
    //     this.f.LOOP_RETURN_FLOW_F13620_GPM.updateValueAndValidity();

    //     if(this.FirstShiftDate)
    //     {
    //       this.f.WorkingQualityPM.setValidators([Validators.required]);
    //       this.f.WorkingQualityPM.updateValueAndValidity();

    //       this.f.PolisherQualityPM.setValidators([Validators.required]);
    //       this.f.PolisherQualityPM.updateValueAndValidity();

    //       this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
    //       this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();
    //     }

    //   }
    //   //this.f.ROPreX.setValue('');
    //   this.f.ROClock.setValue('');
    //   this.f.ROFeedConductivity.setValue('');
    //   this.f.ROPermeateConductivity.setValue('');
    //   this.f.RORejection.setValue('');
    //   this.f.ROPermeateFlow.setValue('');
    //   this.f.ROPrefilterIn.setValue('');
    //   this.f.ROPrefilterOut.setValue('');
    //   this.f.ROPumpPressure.setValue('');
    //   this.f.RORingPressure.setValue('');
    //   this.f.ThorntonMeterMorning.setValue('');

    //   this.f.ROClock.clearValidators();
    //   this.f.ROClock.updateValueAndValidity();

    //   this.f.RORejection.clearValidators();
    //   this.f.RORejection.updateValueAndValidity();

    //   this.f.ROPrefilterIn.clearValidators();
    //   this.f.ROPrefilterIn.updateValueAndValidity();

    //   this.f.ROPumpPressure.clearValidators();
    //   this.f.ROPumpPressure.updateValueAndValidity();

    //   this.f.RORingPressure.clearValidators();
    //   this.f.RORingPressure.updateValueAndValidity();

    //   this.f.ROPreX.clearValidators();
    //   this.f.ROPreX.updateValueAndValidity();

    //   this.f.ROPreW.clearValidators();
    //   this.f.ROPreW.updateValueAndValidity();

    //   this.f.ROFeedConductivity.clearValidators();
    //   this.f.ROFeedConductivity.updateValueAndValidity();

    //   this.f.ROPermeateConductivity.clearValidators();
    //   this.f.ROPermeateConductivity.updateValueAndValidity();

    //   this.f.ROPermeateFlow.clearValidators();
    //   this.f.ROPermeateFlow.updateValueAndValidity();

    //   this.f.ROPrefilterOut.clearValidators();
    //   this.f.ROPrefilterOut.updateValueAndValidity();

    //   this.f.ThorntonMeterMorning.clearValidators();
    //   this.f.ThorntonMeterMorning.updateValueAndValidity();
    //   if(this.IsShepardForm == false)
    //   {
    //     if(this.FirstShiftDate)
    //     {
    //       this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
    //       this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

    //       this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
    //       this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
    //     }
    //   }
    //   if(this.IsShepardForm == true)
    //   {
    //   this.f.P6_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P6_PSI.updateValueAndValidity();

    //   this.f.P7_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P7_PSI.updateValueAndValidity();

    //   this.f.P8_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P8_PSI.updateValueAndValidity();

    //   this.f.P9_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P9_PSI.updateValueAndValidity();

    //   this.f.P12_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P12_PSI.updateValueAndValidity();

    //   this.f.P13_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P13_PSI.updateValueAndValidity();

    //   this.f.P14_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P14_PSI.updateValueAndValidity();

    //   this.f.P15_PSI.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.P15_PSI.updateValueAndValidity();

    //   if(this.f.EnableDIBackupMode.value === false || this.f.EnableDIBackupMode.value == '')
    //   {
    //     this.f.SP_Rejection.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.SP_Rejection.updateValueAndValidity();

    //     this.f.FEED_CONDUCTIVTY_A13635.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.FEED_CONDUCTIVTY_A13635.updateValueAndValidity();

    //     this.f._1ST_PASS_PRODUCT_QUALITY_A13665.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f._1ST_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    //     this.f._2ND_PASS_PRODUCT_QUALITY_A13665.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f._2ND_PASS_PRODUCT_QUALITY_A13665.updateValueAndValidity();

    //     this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.updateValueAndValidity();

    //     this.f.LOOP_RETURN_FLOW_F13620_GPM.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //     this.f.LOOP_RETURN_FLOW_F13620_GPM.updateValueAndValidity();

    //     this.f.WorkingQuality.clearValidators();
    //     this.f.WorkingQuality.updateValueAndValidity();

    //     this.f.PolisherQuality.clearValidators();
    //     this.f.PolisherQuality.updateValueAndValidity();

    //     if(this.FirstShiftDate)
    //     {
    //       this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
    //       this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

    //       this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
    //       this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
    //     }
    //   }
    //   }
    // } else {
    //
    //   if((this.f.EnableDIBackupMode.value == false || this.f.EnableDIBackupMode.value == '') && this.FirstShiftDate)
    //   {
    //     this.f.SecondShiftTotalChlorineTextBox.setValidators([Validators.required]);
    //     this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();

    //     this.f.ThorntonMeterAfternoonTextBox.setValidators([Validators.required]);
    //     this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
    //   }

    //   this.f.ROPreX.setValidators([
    //     Validators.required,Validators.pattern(Globals.NumberRegex),
    //     WaterProcessCustomValidators.GreaterRestrictCustomValidation(
    //       this.f.ROPreW
    //     )
    //   ]);
    //   this.f.ROPreX.updateValueAndValidity();

    //   this.f.ROPreW.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROPreW.updateValueAndValidity();

    //   this.f.ROClock.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROClock.updateValueAndValidity();

    //   this.f.ROFeedConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROFeedConductivity.updateValueAndValidity();

    //   this.f.ROPermeateConductivity.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROPermeateConductivity.updateValueAndValidity();

    //   this.f.RORejection.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.RORejection.updateValueAndValidity();

    //   this.f.ROPermeateFlow.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROPermeateFlow.updateValueAndValidity();

    //   this.f.ROPrefilterIn.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROPrefilterIn.updateValueAndValidity();

    //   this.f.ROPumpPressure.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ROPumpPressure.updateValueAndValidity();

    //   this.f.RORingPressure.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.RORingPressure.updateValueAndValidity();

    //   this.f.ROPrefilterOut.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex),
    //     WaterProcessCustomValidators.GreaterRestrictCustomValidation(
    //       this.f.ROPrefilterIn
    //     )]
    //   );
    //   this.f.ROPrefilterOut.updateValueAndValidity();

    //   this.f.ThorntonMeterMorning.setValidators([Validators.required,Validators.pattern(Globals.NumberRegex)]);
    //   this.f.ThorntonMeterMorning.updateValueAndValidity();


    // }
  }

  ValidateDailyFormPM(event) {
    this.IsEnablePM = true;
    if (event == 'DI BackUp') {
      this.DIBackupSelectedValue = ['DI BackUp'];
    }
    else {
      this.DIBackupSelectedValue = ['RO Online'];
    }
    this.ValidateDailyForm();
  }

  DialysisUnitDropDownChanged() {
    debugger;
    this.AcidLevelGroup = new UntypedFormGroup({});
    // console.log(this.AcidLevelGroup.controls);
    if (this.division != 0) {
      // this.f.selectedDialysisUnits.setValue(this.division);
      this.ValidateDailyForm();
      var wpCreate = { Id: this.division };
      this.wpapi.FillAcidLevelOptions(wpCreate).subscribe(success => {

        if (this.acidLevel)
          this.acidLevel.AcidLevelData = [];

        for (let childObj in success) {

          // var obj = {
          //   optiondisplayas: success[childObj].containerName,
          //   readonly: !(this.f.WPDailyDetailsId.value == '0' || this.f.WPDailyDetailsId.value == ''),
          //   controlname: success[childObj].containerName.replace(/\s+/g, '_'),
          //   result: ''
          // };
          if (success[childObj].text != 'Select') {
            var obj = {
              optiondisplayas: success[childObj].text,
              readonly: !(this.f.WPDailyDetailsId.value == '0' || this.f.WPDailyDetailsId.value == ''),
              controlname: success[childObj].text.replace(/\s+/g, '_'),
              result: ''
            };

            let control: UntypedFormControl = new UntypedFormControl(
              success[childObj].result,
              [Validators.required,
              Validators.pattern(Globals.NumberRegex)]
            );
            if (this.f.WPDailyDetailsId.value == '0' || this.f.WPDailyDetailsId.value == '') {
              this.AcidLevelGroup.addControl(
                success[childObj].text.replace(/\s+/g, '_'),
                control
              );
            }

            if (this.acidLevel)
              this.acidLevel.AcidLevelData.push(obj);
          }
        }

        if (this.acidLevel) {
          this.f.AcidLevels = this.AcidLevelGroup;
          this.acidLevel.AcidLevelFormGroup = this.AcidLevelGroup;
        }
        //console.log(this.f.AcidLevels);
        // console.log(this.acidLevel.AcidLevelData); // this.acidLevel.AcidLevelData=success;
      });

    }

  }

  checkRange(control, MinValue, MaxValue) {
    if (control.value != '' && control.value != null) {
      //console.log(this.dailyDetailForm);
      return !(control.value >= MinValue && control.value <= MaxValue);
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        var wpDailyObj = { Id: this.f.WPDailyDetailsId.value };
        this.wpapi.DeleteWaterProcessDailyRecord(wpDailyObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Water process daily record deleted successfully!',
              'success'
            );
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  CheckSecondShiftCompleted() {
    return (
      // this.Status != 'Completed' &&
      // new Date(this.createdOn).setHours(0, 0, 0, 0) ==
      // new Date(this.Today).setHours(0, 0, 0, 0)
      this.Status != 'Completed'
    );
  }

  GreaterValueCheck() {
    this.f.RawWaterFiltersQ.updateValueAndValidity();
    this.f.ROPreX.updateValueAndValidity();
    this.f.ROPrefilterOut.updateValueAndValidity();

    this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();

    this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();
  }

  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }


  showRefreshHX2AM = false;
  showRefreshHX2PM = false;
  showRefreshModulaAM = false;
  showRefreshModulaPM = false;

  OpenPopup(Shift){

    if(Shift=='AM'){
      document.getElementById('PopUpImageAM').style.display = 'block';
    this.showRefreshHX2AM = true;

    } else {
      document.getElementById('PopUpImagePM').style.display = 'block';
    this.showRefreshHX2PM = true;

    }
    this.FetchAndSetValues();
  }

  ClosePopup(Shift){

    if(Shift=='AM'){
      document.getElementById('PopUpImageAM').style.display = 'none';
      this.showRefreshHX2AM = false;
    }
      else{
      document.getElementById('PopUpImagePM').style.display = 'none';
      this.showRefreshHX2PM = false;
    }

  }

  FetchAndSetValues(){

    this.wpapi.FetchHX2DataH2ODaily().subscribe(
      success => {
        success.forEach(element => {

          const HX2Data =  Globals.lstHX2Data.find(c=>c.id==element.fieldName);
          if(HX2Data!=undefined) {
            var format ='';
            var FieldValue = '';

            if(HX2Data.format!=''){
              format=HX2Data.format;
              FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
              if(format.length>1)
                FieldValue = (this.decimalPipe.transform(parseFloat(FieldValue) , format)).toString();
              else
                FieldValue = (parseInt(FieldValue)).toString();


                if(FieldValue.indexOf('-') == 0){
                  FieldValue =FieldValue.replace(/^[-]*0+/, '');
                  if(FieldValue.indexOf('.')==0)
                      FieldValue='-0'+FieldValue;
                } else {

                if(FieldValue != '0'){
                    FieldValue =FieldValue.replace(/^0+/, '');
                    if(FieldValue.indexOf('.')==0)
                        FieldValue='0'+FieldValue;

                  }  else if(FieldValue == '0' && format.length>1) {
                    FieldValue = '0.0';
                  }
                }
            } else {
              FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
            }

              Globals.lstHX2Data.find(c=> c.id == element.fieldName).value = FieldValue;

          }
          //
          console.log(Globals.lstHX2Data);
        });


        this.dateDiff = Globals.lstHX2Data.find(c=> c.id == 'DateDiff').value;
    this.AI3635 = Globals.lstHX2Data.find(c=> c.id == '{[HX2]AI3635.Val}').value;
    this.CQI9864 = Globals.lstHX2Data.find(c=> c.id == '{[HX2]CQI9864.Val}').value;
    this.AI3665 = Globals.lstHX2Data.find(c=> c.id == '{[HX2]AI3665.Val}').value;
    this.AI3695 = Globals.lstHX2Data.find(c=> c.id == '{[HX2]AI3695.Val}').value;
    this.FI3710 = Globals.lstHX2Data.find(c=> c.id == '{[HX2]FI3710.Val}').value;
    this.FI3620 =  Globals.lstHX2Data.find(c=> c.id == '{[HX2]FI3620.Val}').value;

    this.RODate =  Globals.lstHX2Data.find(c => c.id == 'LocalDate').value;
    this.ROTime =  Globals.lstHX2Data.find(c => c.id == 'LocalTime').value;

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );


  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46, charCode);
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      return false;
    }
    return true;
  }

  CheckIfZero(ctl, count = 1) {

    let val = this.f[ctl].value;
    if (parseFloat(val) == 0)
      val = "";

    this.f[ctl].setValue(val);
  }


  dateDiff = '0' ;
  RODate = ' ';
  ROTime = ' ';
  AI3635 = ' ';
  CQI9864 = ' ';
  AI3665 = ' ';
  AI3695 = ' ';
  FI3710 = ' ';
  FI3620 = ' ';

}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'TermsAndConditionDialog',
  templateUrl: './TermsAndConditionDialog.html'
})
export class TermsAndConditionDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<TermsAndConditionDialog>) { }

  ngOnInit() {
  }

}

<section class="content-header">
    <h1>
        {{headerText}}
        <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
    </h1>

</section>
<section class="content">
    <section>
        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader  [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)" [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">
                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Date In:</label>
                                        <div class="col-sm-6">
                                            <div class="input-group">
                                                <input (focus)="datein.toggleCalendar()" (click)="datein.openCalendar()"
                                                class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker name="dateIn"
                                                       [(ngModel)]="dateIn" [options]="myOptions" #datein="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-sm-6 control-label">Hours In:</label>
                                                <div class="col-sm-6">
                                                    <input type="number" class="form-control" [(ngModel)]="hoursIn">
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>

                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Division:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="selectedDivision"  [attr.disabled]="disableDivision?true:null">
                                                    <option value="">Select</option>
                                                  <option  value="{{item.text}}" *ngFor="let item of DivisionList">{{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Equipment:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="selectedEquipment">
                                                <option  value="{{item.value}}" *ngFor="let item of EquipmentList">{{item.text}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Status:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="selectedStatus">
                                                    <option  value="{{item.value}}" *ngFor="let item of StatusList">{{item.text}}</option>
                                                  </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                        <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label class="col-sm-6 control-label">In Hydraulic Disinfection:</label>
                                                    <div class="col-sm-6">
                                                        <select class="form-control" [(ngModel)]="inHydraulicDisinfection">
                                                                <option  value="{{item.value}}" *ngFor="let item of inhydraulicdisOptions">{{item.text}}</option>
                                                              </select>
                                                    </div>

                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label class="col-sm-6 control-label">In Surface Disinfection:</label>
                                                        <div class="col-sm-6">
                                                            <select class="form-control" [(ngModel)]="inSurfaceDisinfection">
                                                                    <option  value="{{item.value}}" *ngFor="let item of insurfacedisOptions">{{item.text}}</option>
                                                                  </select>
                                                        </div>

                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <!-- <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-sm-6 control-label">PM Code:</label>
                                                <div class="col-sm-6">
                                                    <input type="text" class="form-control" [(ngModel)]="PMCode">
                                                </div>

                                            </div>
                                            <div class="clearfix"></div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail" (click)="executePreventiveSearch()">
                                        <span><i class="fa fa-search"></i></span>
                                        <b>&nbsp;Search</b>
                                    </button>
                                  </div>
                                  </div>
                        </div>
                    </div>

                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10"
                                        [directionLinks]="true"></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <!-- <option value="">All Divisions</option> -->
                                        <option value="{{item.text}}" *ngFor="let item of DivisionList">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executePreventiveSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                            <thead>
                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders" [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)" style="white-space:pre-line">{{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let DMPMDetail of rows;let index=index;" (click)="_onRowClick(DMPMDetail.id,DMPMDetail.status,DMPMDetail.isUploadHistory)"
                                    class="pointer">

                                    <td>
                                         {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>
                                       <!-- <span *ngIf="DMPMDetail.isUploadHistory == true" class='text-red inputrequired'> * </span>   -->
                                       {{DMPMDetail.pmCode}}
                                    </td>
                                    <td>{{DMPMDetail.divisionName}}</td>
                                    <td>{{DMPMDetail.equipmentName}} </td>
                                    <td>
                                        <span
                                        class='text-red inputrequired' *ngIf="(CheckFormDate(DMPMDetail.createdOn,DMPMDetail.dateIn) && DMPMDetail.isUploadHistory == false) || DMPMDetail.isUploadHistory == true">*</span>
                                        {{DMPMDetail.dateIn | date:'dd/MM/yyyy'}} </td>
                                    <td>{{DMPMDetail.hoursIn}} </td>
                                    <td>{{DMPMDetail.inHydraulicDisinfection}} </td>
                                    <td>{{DMPMDetail.inSurfaceDisinfection}} </td>
                                    <td>{{DMPMDetail.dateOut | date:'dd/MM/yyyy'}} </td>
                                    <td>{{DMPMDetail.hoursOut}} </td>
                                    <td>{{DMPMDetail.createdByName}}</td>
                                    <!-- <td>{{DMPMDetail.status}} </td> -->

                                    <td matTooltip="{{DMPMDetail.status}}">
                                        <div class="progressbar-wrapper">
                                            <ul class="progressBar">
                                                <!-- <li [ngClass]="{'progressBarlightGreen':DMPMDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': DMPMDetail.status == 'In Progress', 'fa':DMPMDetail.status != '' }"></i>
                                                </li> -->
                                                
                                                <li [ngClass]="{'progressBarlightGreen': DMPMDetail.status == 'Completed','progressBarlightBlue': DMPMDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': DMPMDetail.status == 'Completed', 'fa-arrow-right':DMPMDetail.status == 'In Progress', 'fa':DMPMDetail.status != '' }">
                                                    </i>
                                                </li>
                                            </ul> 
                                        </div>                                    
                                    </td>	
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10"
                                        [directionLinks]="true"></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executePreventiveSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { Validators, FormArray } from '@angular/forms';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { OnInit } from '@angular/core';
import { Page } from './../../DialysisMachines/FieldService/FieldServiceSearch.component';
import { InvApiService } from './../../API/invapi.service';
import { FieldMasterApiService } from './../../API/fieldmasterapi.service';
import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';
import { Globals } from 'src/app/globals';
import { DMApiService } from 'src/app/API/dmapi.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'FieldMasterDetail',
  templateUrl: './FieldMasterDetail.component.html',
})

export class FieldMasterDetailComponent {
  blnshowSave = false;
  blnDirtySearch = false;
  ModelList = [];
  CategoryList = [];
  Category = '';
  Model = '';
  onText = "Yes";
  offText = "No";
  onColor = "success";
  offColor = "danger";
  ModelName = '';
  CategoryName = '';
  today = new Date();
  currentUserId = this.auth.GetUserId();
  Equipments = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: false
  };
  isValidFormSubmitted = null;

  constructor(private fieldMasterApi: FieldMasterApiService, private router: Router,
    private commonapi: CommonApiService,
    private invapi: InvApiService,
    private dataGridService: TableHeadsService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dmapi: DMApiService

  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  ListURL = '/Admin/FieldMasterSearch';
  currentPageNumber = 1;
  sortExpression = 'ModelName';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Field Master';
  defaultSort;

  FieldMasterForm = new UntypedFormGroup({
    FieldMasterId: new UntypedFormControl('0'),
    ModelId: new UntypedFormControl(''),
    EquipmentId: new UntypedFormControl('',Validators.required),
    FieldName: new UntypedFormControl('',Validators.required),
    MRMFieldName: new UntypedFormControl('',Validators.required),
    Unit: new UntypedFormControl('',Validators.required),
    MinVal: new UntypedFormControl('',Validators.required),
    MaxVal: new UntypedFormControl('',Validators.required),
    Frequency: new UntypedFormControl('',Validators.required),
    FrequencyType: new UntypedFormControl(''),
    NotificationRangeError: new UntypedFormControl(false),
    NotificationRangeToNormal: new UntypedFormControl(false),
    NotificationRangeErrorFrequency: new UntypedFormControl(false),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    ModifiledOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    IsDelete: new UntypedFormControl(''),
    categoryId: new UntypedFormControl(''),
  });

  ngOnInit() {

    const test = this.route.queryParams.subscribe(params => {
      this.f.ModelId.setValue(params['id'] || '0');
    });

    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'FieldMasterId', '5');
    this.dataGridService.addHeader('Device Field Name ID', 'FieldName', '20');
    this.dataGridService.addHeader('BMR Reference ID', 'MRMFieldName', '20');
    this.dataGridService.addHeader('Unit of Measurement', 'FrequencyType', '15');
    this.dataGridService.addHeader('Frequency', 'Frequency', '10');
    this.dataGridService.addHeader('Min. Value', 'MinVal','15');
    this.dataGridService.addHeader('Max. Value', 'MaxVal', '15');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('MRMFieldName', false);
    this.fillCategories();
    this.fillModelList();
    this.fillEquipment();
    this.executeFieldMasterSearch();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);


      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }


  executeFieldMasterCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;

    if (this.FieldMasterForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const FieldMasterCreate = this.prepareCreateViewModel();
    if (FieldMasterCreate.FieldMasterId == '' || FieldMasterCreate.FieldMasterId === '0') {
      this.fieldMasterApi.CreateFieldMaster(FieldMasterCreate).subscribe(
        success => {
          console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.clearFields();
            this.executeFieldMasterSearch();
          }
          else {
          }
        },
        error => {

          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.fieldMasterApi.UpdateFieldMasterDetails(FieldMasterCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.clearFields();
            this.executeFieldMasterSearch();
          }
          else {
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };

  clearFields() {
    this.f.FieldMasterId.reset('0');
    this.f.FieldName.reset('');
    this.f.MRMFieldName.reset('');
    this.f.Unit.reset('');
    this.f.MinVal.reset('');
    this.f.MaxVal.reset('');
    this.f.Frequency.reset('');
    this.f.FrequencyType.reset('');
    this.f.NotificationRangeError.reset(false);
    this.f.NotificationRangeToNormal.reset(false);
    this.f.NotificationRangeErrorFrequency.reset(false);
    this.f.EquipmentId.reset('');
    this.isValidFormSubmitted = null;
    this.fillEquipment();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46, charCode);
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      return false;
    }
    return true;
  }

  prepareCreateViewModel() {
    const FieldMasterCreate = <any>{};
    FieldMasterCreate.FieldMasterId = this.f.FieldMasterId.value;
    FieldMasterCreate.ModelId = this.f.ModelId.value;
    FieldMasterCreate.FieldName = this.f.FieldName.value;
    FieldMasterCreate.MRMFieldName = this.f.MRMFieldName.value;
    FieldMasterCreate.Unit = this.f.Unit.value;
    FieldMasterCreate.MinVal = this.f.MinVal.value;
    FieldMasterCreate.MaxVal = this.f.MaxVal.value;
    FieldMasterCreate.Frequency = this.f.Frequency.value;
    FieldMasterCreate.FrequencyType = "Day";
    FieldMasterCreate.NotificationRangeError = this.f.NotificationRangeError.value;
    FieldMasterCreate.NotificationRangeToNormal = this.f.NotificationRangeToNormal.value;
    FieldMasterCreate.NotificationRangeErrorFrequency = this.f.NotificationRangeErrorFrequency.value;




    FieldMasterCreate.CreatedOn = this.f.CreatedOn.value;
    FieldMasterCreate.CreatedBy = this.f.CreatedBy.value ? this.f.CreatedBy.value : this.currentUserId;
    FieldMasterCreate.ModifiedBy = this.currentUserId;

    // if (this.notes)
    //   FieldMasterCreate.notes = this.notes.Note;
    // if (this.attachmentList)
    //   FieldMasterCreate.attachmentDetails = this.attachmentList.GenerateAttachmentList();

    // var steps = this.FieldMasterForm.get(
    //   "tSteps"
    // )["controls"];
    const equipmentList = [];
    if(this.f.EquipmentId.value && this.f.EquipmentId.value.length > 0){
      if(this.f.EquipmentId.value.length  == this.Equipments.length){
        FieldMasterCreate.EquipmentId = 'SelectedAll';
      }
      else{
        FieldMasterCreate.EquipmentId = 'Selected';
      }

      this.f.EquipmentId.value.forEach((eq) => {
        equipmentList.push({
          EquipmentId: eq.value.toString(),
          FieldMasterId: this.f.FieldMasterId.value,
        });

      });
    }
    else
    {
      FieldMasterCreate.EquipmentId = 'Select';
    }

    FieldMasterCreate.fieldEquipmentMappingList = equipmentList;

    return FieldMasterCreate;
  }


  setModel() {
    AppComponent.ShowLoader();
    const FieldMasterModel = { Id: this.f.FieldMasterId.value };

    this.fieldMasterApi.GetFieldMasterByID(FieldMasterModel).subscribe(
      success => {
        this.clearFields();
        if (success) {

          this.f.FieldMasterId.setValue(success.fieldMasterId);

          if (success.equipmentId && success.equipmentId != 'Select'){
            this.GetEquipments();
          }

          this.f.FieldName.setValue(success.fieldName);
          this.f.MRMFieldName.setValue(success.mrmFieldName);

          if (success.unit)
            this.f.Unit.setValue(success.unit);

          this.f.MinVal.setValue(success.minVal);
          this.f.MaxVal.setValue(success.maxVal);
          this.f.Frequency.setValue(success.frequency);
          this.f.FrequencyType.setValue(success.frequencyType);

          if (success.notificationRangeError)
            this.f.NotificationRangeError.setValue(success.notificationRangeError);

          if (success.notificationRangeToNormal)
            this.f.NotificationRangeToNormal.setValue(success.notificationRangeToNormal);

          if (success.notificationRangeErrorFrequency)
            this.f.NotificationRangeErrorFrequency.setValue(success.notificationRangeErrorFrequency);


          this.f.CreatedOn.setValue(success.createdOn);
          this.f.CreatedBy.setValue(success.createdBy);
          this.f.IsDelete.setValue(success.isDelete);


          // this.FieldMasterAPI
          //   .GetTroubleShootingStepsByFieldMasterId(FieldMasterCreate)
          //   .subscribe(troubleshootData => {

          //     var steps = this.FieldMasterForm.get(
          //       "tSteps"
          //     )["controls"];

          //     steps.forEach((step, newItemKey) => {
          //       troubleshootData.forEach((data, newKey) => {
          //         if (step.controls.stepno.value == data.stepno) {
          //           step.controls.stepsDetail.setValue(data.stepsdetail);
          //           step.controls.FieldMasterId.setValue(data.FieldMasterId);
          //         }
          //       });
          //     });
          //   });


          // if (this.notes) {
          //   this.notes.Note = '';
          //   this.notes.showHours = false;
          //   this.FieldMasterAPI.GetFieldMasterNotes(FieldMasterCreate).subscribe(list => {
          //     this.notes.NoteList = list;
          //   });
          // }

          // if (this.attachmentList) {
          //   this.attachmentList.clearQueue();
          //   this.FieldMasterAPI
          //     .GetFieldMastersAttachments(FieldMasterCreate)
          //     .subscribe(list => {
          //       this.attachmentList.PrevAttachments = list;

          //     });
          // }

          AppComponent.HideLoader();
        }
        else {
          AppComponent.HideLoader();
        }
      },
      error => {

        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  get f() {
    return this.FieldMasterForm.controls;
  }


  onChange(e) {

  }

  executeFieldMasterSearchClear() {
    this.executeFieldMasterSearch();
  }



  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeFieldMasterSearch();
  }

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeFieldMasterSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;

    const FieldMasterSearch = this.prepareSearch();
    this.fieldMasterApi.GetFieldMasterSerachDetails(FieldMasterSearch).subscribe(pagedData => {

      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.fieldMasterList;

      this.ModelName = pagedData.modelName;
      this.CategoryName = pagedData.categoryName;
      this.f.categoryId.setValue(pagedData.categoryId);
      AppComponent.HideLoader();
    }
      ,
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }



  prepareSearch() {
    let FieldMastersearch = <any>{};
    FieldMastersearch.ModelId = this.f.ModelId.value;
    FieldMastersearch.currentPageNumber = this.currentPageNumber;
    FieldMastersearch.sortExpression = this.sortExpression;
    FieldMastersearch.sortDirection = this.sortDirection;
    FieldMastersearch.PageSize = this.pageSize;

    return FieldMastersearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeFieldMasterSearch();
  }

  _onRowClick(row) {
    this.f.FieldMasterId.setValue(row);
    this.setModel();
    //this.router.navigate([this.ListURL], { queryParams: { id: row } });
  }


  fillModelList() {
    const categoryId = { categoryId: this.Category };
    this.invapi
      .FillModels(categoryId)
      .subscribe(list => {
        this.ModelList = list;
        this.Model = '';
      });
  }


  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }

  fillEquipment(equipmentId =0) {
    this.dmapi.GetEquipmentDD(equipmentId).subscribe(list => {
      let eqsList = [];
      list.forEach(function (eq) {
        eqsList.push({
          text: eq.text,
          value: eq.value
        });
      });
      this.Equipments = eqsList.filter(function (item, index) { return item.text !== 'Select'; });
    });

  }

  GetEquipments(){
    const fieldMaster = { Id: this.f.FieldMasterId.value };
    this.fieldMasterApi.GetFieldMasterEquipmentsByID(fieldMaster).subscribe(
      success => {
        const eqList = [];

        success.forEach(function (eq) {
          eqList.push({
            text: eq.equipmentCode,
            value: eq.equipmentId.toString()
          });
        });
        // console.log(assignedUsers);
        this.f.EquipmentId.setValue(eqList);
        this.fillEquipment();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );
  }
}

<form
    [formGroup]="BacteriaForm"
    (ngSubmit)="executeBacteriaCreateUpdate('')"
    novalidate
>

    
    <section class="content-header">
        <h1>
Bacteria Record Details
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"         
                    (addNoteClick)="notes.AddNote()"                    
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeBacteriaCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">BacteriasId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="BacteriasId"
                                        [value]="f.BacteriasId"
                                        formControlName="BacteriasId"
                                    >
                                </div>
                            </div>

                            
                            <div class="box ibox box-info">

                                <div class="box-body">
                                    <div class="row">
                                        <div
                                            class="col-sm-12 box-row"

                                        >
                                            <div class="col-sm-6">
                                                <label class="col-sm-4 control-label">

                                                    Event ID:
                                                </label>

                                                <div class="col-sm-8">
                                                    <label class="readOnlyText">{{EventCode}}</label>
                                                </div>
                                            </div>
                                            <div
                                        class="col-sm-6"
                                        *ngIf="f.BacteriasId.value > 0"
                                    >
                                        <label class="col-sm-5 control-label">Date:</label>
                                        <div
                                            class="col-sm-7"
                                            *ngIf="f.BacteriasId.value > 0"
                                        >
                                            <label class="readOnlyText">
                                                {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                            </label>
                                        </div>

                                    </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="box ibox box-info">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Collection Details</h3>
                                   
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Date:</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="f.BacteriasId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.CollectionDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="f.BacteriasId.value == 0"
                                                >
                                                <input (focus)="rCollectionDate.toggleCalendar()" (click)="rCollectionDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="CollectionDate"
                                                  formControlName="CollectionDate" [options]="myOptions"
                                                  #rCollectionDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                />
                                                <span  *ngIf="f.CollectionDate.errors?.required && (f.CollectionDate.dirty || f.CollectionDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Collection Time:</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="f.BacteriasId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.CollectionTime.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="f.BacteriasId.value == 0"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="CollectionTime"
                                                  formControlName="CollectionTime" 
                                                />
                                                <span  *ngIf="f.CollectionTime.errors?.required && (f.CollectionTime.dirty || f.CollectionTime.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Staff:</label>
                                                <div
                                                    class="col-sm-5"
                                                    
                                                >
                                                    <label class="readOnlyText">{{CollectionByName}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div *ngIf="stage >= 3" class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Date (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage != 3"
                                                >
                                                    <label class="readOnlyText">{{f.CollectionRetestDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage == 3"
                                                >
                                                <input  (focus)="rCollectionRetestDate.toggleCalendar()" (click)="rCollectionRetestDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="CollectionRetestDate"
                                                  formControlName="CollectionRetestDate" [options]="myOptions"
                                                  #rCollectionRetestDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                />
                                                <span  *ngIf="f.CollectionRetestDate.errors?.required && (f.CollectionRetestDate.dirty || f.CollectionRetestDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Collection Time (Retest):</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage != 3"
                                                >
                                                    <label class="readOnlyText">{{f.CollectionRetestTime.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage == 3"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="CollectionRetestTime"
                                                  formControlName="CollectionRetestTime" 
                                                />
                                                <span  *ngIf="f.CollectionRetestTime.errors?.required && (f.CollectionRetestTime.dirty || f.CollectionRetestTime.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Staff (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                   
                                                >
                                                    <label class="readOnlyText">{{CollectionRetestByName}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info" *ngIf="stage >= 1">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Funnel Details</h3>
                                   
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Funnel Lot #:</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage != 1"
                                                >
                                                    <label class="readOnlyText">{{f.FunnelLot.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage == 1"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="FunnelLot"
                                                  formControlName="FunnelLot" 
                                                />
                                                <span  *ngIf="f.FunnelLot.errors?.required && (f.FunnelLot.dirty || f.FunnelLot.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Cassette Lot #:</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage != 1"
                                                >
                                                    <label class="readOnlyText">{{f.CassetteLot.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage == 1"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="CassetteLot"
                                                  formControlName="CassetteLot" 
                                                />
                                                <span  *ngIf="f.CassetteLot.errors?.required && (f.CassetteLot.dirty || f.CassetteLot.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Funnel Staff:</label>
                                                <div
                                                    class="col-sm-5"
                                                   
                                                >
                                                    <label class="readOnlyText">{{FunnelStaffByName}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row" *ngIf="stage >= 3">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Funnel Lot # (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage != 3"
                                                >
                                                    <label class="readOnlyText">{{f.FunnelLotRetest.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage == 3"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="FunnelLotRetest"
                                                  formControlName="FunnelLotRetest" 
                                                />
                                                <span  *ngIf="f.FunnelLotRetest.errors?.required && (f.FunnelLotRetest.dirty || f.FunnelLotRetest.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Cassette Lot # (Retest):</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage != 3"
                                                >
                                                    <label class="readOnlyText">{{f.CassetteLotRetest.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage == 3"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="CassetteLotRetest"
                                                  formControlName="CassetteLotRetest" 
                                                />
                                                <span  *ngIf="f.CassetteLotRetest.errors?.required && (f.CassetteLotRetest.dirty || f.CassetteLotRetest.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Funnel Staff (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                    
                                                >
                                                    <label class="readOnlyText">{{FunnelStaffByNameRetest}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Dialysis Unit Details</h3>
                                   
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6" style="padding-left: 25px;">
                                                <label class="col-sm-4 control-label">Division:</label>
                                                <div
                                                    class="col-sm-8"
                                                    *ngIf="f.BacteriasId.value > 0"
                                                >
                                                <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-8"
                                                    *ngIf="f.BacteriasId.value == 0"
                                                >
                                                <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                           
                                        </div>
                                        <div class="clearfix"></div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info">


                                <div class="box-header with-border">
                                    <h3 class="box-title col-sm-6" >Test Results</h3>
                                    <h3 class="box-title col-sm-6" >Retest Results</h3>
                                   
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row" *ngIf="stage == 0">
                                            <div class="col-sm-12" style="padding-left: 25px;">
                                                <label class="col-sm-2 control-label">Populate:</label>
                                                
                                                
                                                <div
                                                    class="col-sm-10"
                                                   
                                                >
                                               
                                                <select
                                                        class="form-control mdInput" style="margin-left: 15px"
                                                        formControlName="selectedPopulatetype"
                                                        name="selectedPopulatetype" 
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of Populatetypess"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <select style="margin-left: 15px"
                                                    class="form-control smInput"
                                                    *ngIf="f.selectedPopulatetype.value == 'Dialysis Machines'"
                                                    formControlName= "selectedDMEquipment" 
                                                    name="selectedDMEquipment" 
                                                >
                                                    <option
                                                        value="{{item.value}}"
                                                        *ngFor="let item of DMEquipments"
                                                    >
                                                        {{item.text}}
                                                    </option>
                                                </select>
                                                <input style="margin-left: 15px" *ngIf="f.selectedPopulatetype.value == 'Dialysis Machines'"
                                                class="form-control smInput" placeholder="Hours" name="TestHours"
                                                  formControlName="TestHours" 
                                                />
                                                <select style="margin-left: 15px"
                                                class="form-control smInput"
                                                *ngIf="f.selectedPopulatetype.value == 'Water Process'"
                                                name="selectedWPEquipment" 
                                                formControlName="selectedWPEquipment" 
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of WPEquipments"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <select style="margin-left: 15px"
                                            class="form-control mdInput"
                                            
                                            name="selectedStation" 
                                            formControlName="selectedStation" 
                                            *ngIf="f.selectedPopulatetype.value == 'Central Delivery'"
                                        >
                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of Stations"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select> <button style="display: inline-block;margin-left: 15px" (click)="AddTest()"  class="btn btn-primary  no-print btn-label-left center-block " type="button">
                                            <span><i class="fa fa-plus"></i></span>
                                        </button>
                                                    <span  style="margin-left: 20px"
                                                    
                                                        [ngClass]="'error'"
                                                    >
                                                    {{errorAddTest}}
                                                    </span>
                                                </div>
                                              
                                            </div>
                                           
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider">
                                            <strong>Dialysis Machine</strong>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div  *ngFor="let equip of DMTestList;" class="box-row col-sm-12">
                                                <div class="col-sm-6">
                                                   
                                                        <label class="col-sm-6"> {{equip.equipmentName}} [{{equip.dialysisMachineHours}}] </label>
                                                        <label class="col-sm-6" *ngIf="stage != 2"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                        <div class="col-sm-6"  *ngIf="stage == 2">
                                                                <select
                                                                (change)="UpdateSampleValue($event)"
                                                                name="{{'testresult_dm_' + equip.bacteriaMachaineId}}" 
                                                            >
                                                                <option
                                                                    value="{{item.value}}"
                                                                    *ngFor="let item of TestResultList"
                                                                >
                                                                    {{item.text}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                </div>
                                                <div class="col-sm-6" *ngIf="stage >= 4">
                                                        <label class="col-sm-6"> {{equip.equipmentName}} [{{equip.dialysisMachineHours}}] (Retest)</label>
                                                        <label class="col-sm-6" *ngIf="stage != 4"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                        <div class="col-sm-6"  *ngIf="stage == 4"> <select
                                                            (change)="UpdateSampleValue($event)"
                                                            name="{{'testresultretest_dm_' + equip.bacteriaMachaineId}}" 
                                                        >
                                                            <option
                                                                value="{{item.value}}"
                                                                *ngFor="let item of TestResultList"
                                                            >
                                                                {{item.text}}
                                                            </option>
                                                        </select></div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider">
                                                <strong>Water Process</strong>
                                            </div>

                                            <div class="clearfix"></div>
                                            <div  *ngFor="let equip of WPTestList;" class="box-row col-sm-12">
                                                    <div class="col-sm-6">
                                                       
                                                            <label class="col-sm-6"> {{equip.equipmentName}} </label>
                                                            <label class="col-sm-6" *ngIf="stage != 2"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                            <div class="col-sm-6" *ngIf="stage == 2">
                                                                    <select
                                                                    (change)="UpdateSampleValue($event)"
                                                                    name="{{'testresult_wp_' + equip.bacteriaMachaineId}}" 
                                                                >
                                                                    <option
                                                                        value="{{item.value}}"
                                                                        *ngFor="let item of TestResultList"
                                                                    >
                                                                        {{item.text}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                    </div>
                                                    <div class="col-sm-6"  *ngIf="stage >= 4">
                                                            <label class="col-sm-6"> {{equip.equipmentName}} (Retest)</label>
                                                            <label class="col-sm-6" *ngIf="stage != 4"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                            <div class="col-sm-6" *ngIf="stage == 4"> <select
                                                                (change)="UpdateSampleValue($event)"
                                                                name="{{'testresultretest_wp_' + equip.bacteriaMachaineId}}" 
                                                            >
                                                                <option
                                                                    value="{{item.value}}"
                                                                    *ngFor="let item of TestResultList"
                                                                >
                                                                    {{item.text}}
                                                                </option>
                                                            </select></div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            <div class="box-row col-sm-12 divider">
                                                    <strong>Central Delivery</strong>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div  *ngFor="let equip of CDTestList;" class="box-row col-sm-12">
                                                        <div class="col-sm-6" >
                                                           
                                                                <label class="col-sm-6"> {{equip.stationname}} </label>
                                                                <label class="col-sm-6" *ngIf="stage != 2"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                                <div class="col-sm-6"  *ngIf="stage == 2" >
                                                                        <select
                                                                        (change)="UpdateSampleValue($event)"
                                                                        name="{{'testresult_cd_' + equip.bacteriaDialysisStationsId}}" 
                                                                    >
                                                                        <option
                                                                            value="{{item.value}}"
                                                                            *ngFor="let item of TestResultList"
                                                                        >
                                                                            {{item.text}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                        </div>
                                                        <div class="col-sm-6"  *ngIf="stage >= 4">
                                                                <label class="col-sm-6"> {{equip.stationname}} (Retest)</label>
                                                                <label class="col-sm-6" *ngIf="stage != 4"> {{equip.sampleResult==""?"Select":equip.sampleResult}}</label>
                                                                <div class="col-sm-6"  *ngIf="stage == 4"> <select
                                                                    (change)="UpdateSampleValue($event)"
                                                                    name="{{'testresultretest_cd_' + equip.bacteriaDialysisStationsId}}" 
                                                                >
                                                                    <option
                                                                        value="{{item.value}}"
                                                                        *ngFor="let item of TestResultList"
                                                                    >
                                                                        {{item.text}}
                                                                    </option>
                                                                </select></div>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info" *ngIf="stage >= 2">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Read Info</h3>
                                    
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Sample Read Date:</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage != 2"
                                                >
                                                    <label class="readOnlyText">{{f.SampleReadDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage == 2"
                                                >
                                                <input (focus)="rSampleReadDate.toggleCalendar()" (click)="rSampleReadDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="SampleReadDate"
                                                  formControlName="SampleReadDate" [options]="myOptions"
                                                  #rSampleReadDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                />
                                                <span  *ngIf="f.SampleReadDate.errors?.required && (f.SampleReadDate.dirty || f.SampleReadDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Sample Read Time:</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage != 2"
                                                >
                                                    <label class="readOnlyText">{{f.SampleReadTime.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage == 2"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="SampleReadTime"
                                                  formControlName="SampleReadTime" 
                                                />
                                                <span  *ngIf="f.SampleReadTime.errors?.required && (f.SampleReadTime.dirty || f.SampleReadTime.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Read Staff:</label>
                                                <div
                                                    class="col-sm-5"
                                                   
                                                >
                                                    <label class="readOnlyText">{{SampleReadByName}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row" *ngIf="stage >= 4">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Sample Read Date (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage != 4"
                                                >
                                                    <label class="readOnlyText">{{f.SampleReadRetestDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="stage == 4"
                                                >
                                                <input (focus)="rSampleReadRetestDate.toggleCalendar()" 
                                                (click)="rSampleReadRetestDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="SampleReadRetestDate"
                                                  formControlName="SampleReadRetestDate" [options]="myOptions"
                                                  #rSampleReadRetestDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                />
                                                <span  *ngIf="f.SampleReadRetestDate.errors?.required && (f.SampleReadRetestDate.dirty || f.SampleReadRetestDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Sample Read Time (Retest):</label>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage != 4"
                                                >
                                                    <label class="readOnlyText">{{f.SampleReadRetestTime.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-4"
                                                    *ngIf="stage == 4"
                                                >
                                                <input 
                                                class="form-control mdInput"  name="SampleReadRetestTime"
                                                  formControlName="SampleReadRetestTime" 
                                                />
                                                <span  *ngIf="f.SampleReadRetestTime.errors?.required && (f.SampleReadRetestTime.dirty || f.SampleReadRetestTime.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                              
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Read Staff (Retest):</label>
                                                <div
                                                    class="col-sm-5"
                                                    
                                                >
                                                    <label class="readOnlyText">{{SampleReadRetestByName}}</label>
                                                </div>
                                                
                                              
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <notes
                                [isValidForm]="this.BacteriaForm.invalid"
                                (addNoteClick)="executeBacteriaCreateUpdate('Notes')"
                                #notes
                            >
                            </notes>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"         
                        (addNoteClick)="notes.AddNote()"               
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeBacteriaCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

<div class="modal-content">
  <form [formGroup]="dialysisStationForm" novalidate>
    <div class="modal-header">
      <h4 class="modal-title">Transfer Machine</h4>
      <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="data.errormsg" class="box-row col-sm-12">
        <span [ngClass]="'error'">
          {{data.errormsg}}
        </span>
      </div>
      <div class="box-row col-sm-12">
        Station:
        <label class="readOnlyText"><b>
            {{f.dialysisStationName.value}}</b></label>
      </div>

      <div class="box-row col-sm-12" *ngIf="quickTransferModel.type == 'DM'">
        DM Equipment:
        <select class="form-control mdInput" formControlName="dmEquipmentId" name="dmEquipmentId">
          <option value="">Select</option>
          <option value="{{item.value}}" *ngFor="let item of dmEquipmentList">{{item.text}}</option>
        </select>
      </div>

      <div class="box-row col-sm-12" *ngIf="quickTransferModel.type == 'RO'">
        RO Equipment:
        <select class="form-control mdInput" formControlName="roEquipmentId" name="roEquipmentId">
          <option value="">Select</option>
          <option value="{{item.value}}" *ngFor="let item of roEquipmentList">{{item.text}}</option>
        </select>
      </div>

      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>New Equipment:
        <select class="form-control mdInput" formControlName="newEquipmentId" name="newEquipmentId">
          <option value="">Select</option>
          <option value="{{item.value}}" *ngFor="let item of newEquipmentList">{{item.text}}</option>
        </select>
        <span
          *ngIf="f.newEquipmentId.errors?.required && (f.newEquipmentId.dirty || f.newEquipmentId.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="executeStationsCreateUpdate()">Save</button>
    </div>
  </form>

</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { InvApiService } from '../../API/invapi.service';
import { ContactApiService } from '../../API/contactapi.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import {
  Router, ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';

import { Globals, actionType, attachmentFormModules } from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { CurrencyPipe } from '@angular/common';
import { QuickAddModelDialog } from 'src/app/Core/QuickAddModel/QuickAddModelDialog';
import { QuickAddModel } from 'src/app/Core/QuickAddModel/QuickAddModel';
import { BarcodeScannerComponent } from 'src/app/Core/Barcode/barcode-scanner/barcode-scanner.component';
import { MediaStreamComponent } from 'src/app/Core/Barcode/barcode/media-stream/media-stream.component';
import { InventoryCustomValidators } from 'src/app/Validator/InventoryCustomValidators';

@Component({
  selector: 'InventoryDetail',
  templateUrl: './InventoryDetail.component.html',
  styleUrls: ['./InventoryDetail.component.css']
})
export class InventoryDetailComponent
  implements OnInit, CanComponentDeactivate {

  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  CurrentUser = this.auth.GetUserName();
  quickAddDialogRef: MatDialogRef<QuickAddModelDialog>;
  quickBarcodeDialogRef: MatDialogRef<BarcodeScannerComponent>;
  quickBarcodeMdeiaDialogRef: MatDialogRef<MediaStreamComponent>;


  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;


  isValidFormSubmitted = null;
  ListURL = 'Inventory/InventorySearch';
  status = '';
  loading = true;
  CategoryName = '';
  CategoryList = [];
  DivisionList = [];
  ManufacturerList = [];
  ItemGroupList = [];
  ModelList = [];
  VendorList = [];
  formatteditemCost = '';
  formattedPreviousCost = '';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  ItemID = true;
  scheduledPMList = [{
    scheduleHours: 'NA',
    dateOut: 'NA'
  }];

  onDays = 'Days';
  onHours = 'Hours';

  statusList = [
    { value: 'Active', text: 'Active' },
    { value: 'Inservice', text: 'Inservice' },
    { value: 'InActive', text: 'InActive' }
  ];

  equipmentTypeList = [
    { value: '', text: 'Select' },
    { value: 'EQUIPMENT', text: 'EQUIPMENT' },
    { value: 'PARTS', text: 'PARTS' }
  ];

  modeOfPaymentList = [
    { value: '', text: 'Select' },
    { value: 'Cheque Req', text: 'Cheque Req' },
    { value: 'Credit Card', text: 'Credit Card' },
    { value: 'Petty Cash', text: 'Petty Cash' },
    { value: 'PO', text: 'PO' }
  ];


  InventoryForm = new UntypedFormGroup({
    equipementId: new UntypedFormControl('0'),
    equipmentCode: new UntypedFormControl('', Validators.required),
    equipmentPreviousCode: new UntypedFormControl(''),
    hrsTrackingItemGroup: new UntypedFormControl(true),
    hrsTracking: new UntypedFormControl(true),
    equipmentName: new UntypedFormControl('', Validators.required),
    equipmentType: new UntypedFormControl('', Validators.required),
    equipmentDesc: new UntypedFormControl('', Validators.required),
    equipmentPicFileName: new UntypedFormControl(''),
    equipmentPicFilePath: new UntypedFormControl(''),
    categoryId: new UntypedFormControl('', Validators.required),
    serialNumber: new UntypedFormControl(''),
    barCode: new UntypedFormControl(''),
    barcode2: new UntypedFormControl(''),
    vendorId: new UntypedFormControl('', Validators.required),
    vendorNumber: new UntypedFormControl(''),
    vendorPhone: new UntypedFormControl(''),
    // modeOfPayment: new FormControl('', Validators.required),
    modeOfPayment: new UntypedFormControl(''),
    paymentDetails: new UntypedFormControl(''),
    // expenseCode: new FormControl('', Validators.required),
    expenseCode: new UntypedFormControl(''),
    itemCost: new UntypedFormControl('', Validators.compose([Validators.max(999999)])),
    previousCost: new UntypedFormControl('', Validators.max(999999)),
    inServiceDate: new UntypedFormControl(''),
    inServiceHours: new UntypedFormControl(''),
    divisionId: new UntypedFormControl(''),
    lastUpdateDate: new UntypedFormControl(''),
    lastUpdateHrs: new UntypedFormControl(''),
    manufacturer: new UntypedFormControl(''),
    // manufacturerId: new FormControl('', Validators.required),
    manufacturerId: new UntypedFormControl(''),
    modelId: new UntypedFormControl('', Validators.required),
    model: new UntypedFormControl(''),
    warrantyPeriodDays: new UntypedFormControl(''),
    warrantyPeriodHrs: new UntypedFormControl(''),
    warrantyPeriodForPartsDays: new UntypedFormControl(''),
    warrantyPeriodForPartsHrs: new UntypedFormControl(''),
    scheduledHours: new UntypedFormControl({ value: '', disabled: true }),
    notes: new UntypedFormControl(''),
    PMScheduleStatus: new UntypedFormControl(''),
    PMScheduleStatusType: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    createdOn: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
    presentStatus: new UntypedFormControl('', Validators.required),
  });

  public myOptions: IAngularMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.InventoryForm.dirty || this.notes.Note ||
        this.attachment.Queue().length > 0) &&
      (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private contactApi: ContactApiService,
    private invapi: InvApiService,
    private commonapi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit() {
    AppComponent.loading = true;
    AppComponent.ShowLoader();
    this.f.presentStatus.setValue('Active');
    this.SetInventoryights();

    // attachment changes
    this.attachment.DocId = this.f.equipementId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.equipmentDetails];
    this.attachment.UploadBy = this.currentUserId;
    this.notes.showHours = false;

    this.fillCategories();
    // this.fillManufacturers();
    this.fillVendors();
    this.fillDivisions();

    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.f.equipementId.setValue(params['id'] || '0');

      if (this.f.equipementId.value !== '0') {
        this.setModel();
        this.getScheduledHours();
        AppComponent.HideLoader();
      }
      else {
        //
        // this.f.equipmentCode.setAsyncValidators([
        //   InventoryCustomValidators.InventoryItemIDValidate(
        //     this.commonapi,
        //     this.f.equipmentCode,
        //     this.f.equipmentType
        //   )
        // ]);
        // this.f.equipmentCode.updateValueAndValidity();

        this.DisableControls();
        AppComponent.HideLoader();
      }
    });
    //this.barecodeScanner.start();
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.InventoryForm.controls;
  }

  setModel() {

    AppComponent.ShowLoader();

    const inventory = {
      Id: this.f.equipementId.value
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {

          this.attachment.clearQueue();
          this.f.equipmentCode.setValue(success.equipmentcode);
          this.f.hrsTracking.setValue(success.hrsTracking);
          this.f.hrsTrackingItemGroup.setValue(success.hrsTrackingItemGroup);
          this.f.equipmentPreviousCode.setValue(success.equipmentpreviouscode);
          this.f.equipmentName.setValue(success.equipmentname);
          this.f.equipmentType.setValue(success.equipmenttype);
          this.f.equipmentDesc.setValue(success.equipmentdesc);
          this.f.equipmentPicFileName.setValue(success.equipmentpicfilename);
          this.f.equipmentPicFilePath.setValue(success.equipmentpicfilepath);
          this.f.categoryId.setValue(success.categoryid);
          this.f.serialNumber.setValue(success.serialnumber);
          this.f.barCode.setValue(success.barcode);
          this.f.barcode2.setValue(success.barcode2);


          this.f.modeOfPayment.setValue(success.modeofpayment);
          this.f.paymentDetails.setValue(success.paymentdetails);
          this.f.expenseCode.setValue(success.expensecode);
          this.f.itemCost.setValue(success.itemcost);
          this.f.previousCost.setValue(success.previouscost);
          this.f.inServiceHours.setValue(success.inservicehours);

          this.f.PMScheduleStatus.setValue(success.pmScheduleStatus);
          this.f.PMScheduleStatusType.setValue(success.pmScheduleStatusType);

          if (success.inservicedate != null) {
            const tempDate = new Date(success.inservicedate);

            this.f.inServiceDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: success.inservicedate
            });
          }
          this.f.divisionId.setValue(success.divisionid);
          // this.f.lastUpdateDate.setValue(success.lastupdatedate);

          // this.f.lastUpdateHrs.setValue(success.lastupdatehrs);

          this.fillManufacturers();
          this.f.manufacturer.setValue(success.manufacturer);
          this.f.model.setValue(success.model);

          if (success.manufacturerid == null)
            this.f.manufacturerId.setValue('');
          else
            this.f.manufacturerId.setValue(success.manufacturerid);

          if (success.modelid == null)
            this.f.modelId.setValue('');
          else
            this.f.modelId.setValue(success.modelid);

          if (success.vendorid == null)
            this.f.vendorId.setValue('');
          else
            this.f.vendorId.setValue(success.vendorid);

          this.f.warrantyPeriodDays.setValue(success.warrantyperioddays);
          this.f.warrantyPeriodHrs.setValue(success.warrantyperiodhrs);
          this.f.warrantyPeriodForPartsDays.setValue(success.warrantyperiodforpartsdays);
          this.f.warrantyPeriodForPartsHrs.setValue(success.warrantyperiodforpartshrs);
          this.f.presentStatus.setValue(success.presentstaus);
          this.DisableControls();
          setTimeout(() => {
            this.fillModelList();
            this.fillItemGroups();
            this.onVendorChange();
            this.onEquipmentTypeChange();
            this.transformitemCost();
            this.transformPreviousCost();
          }, 500);



          this.invapi.GetInventoryNotes(inventory).subscribe(list => {
            this.notes.NoteList = list;

          });

          this.invapi
            .GetInventoryAttachments(inventory)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });
        }

      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');

        AppComponent.HideLoader();
      }
    );

    this.invapi.GetLastUpdateHoursDate(inventory).subscribe(
      success => {
        if (success) {

          if (success.hoursoutdate != null) {
            const tDate = new Date(success.hoursoutdate);

            const year = tDate.getFullYear();
            const month = tDate.getMonth() + 1;
            const day = tDate.getDate();

            this.f.lastUpdateDate.setValue(this.padDate(day) + '/' + this.padDate(month) + '/' + this.padDate(year));
          }
          this.f.lastUpdateHrs.setValue(success.hoursout);
        }
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');

        AppComponent.HideLoader();
      });
    this.notes.Note = '';
  }

  padDate(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }


  onDateChanged(event: IMyDateModel): void { }

  fillDivisions() {
    this.invapi.FillDivision().subscribe(list => {
      this.DivisionList = list;
    });
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }

  fillManufacturers() {
    const categoryId = { Id: this.f.categoryId.value };

    this.invapi.FillManufacturerList(categoryId).subscribe(list => {
      this.ManufacturerList = list;
    });
  }

  fillItemGroups() {
    const categoryId = { Id: this.f.categoryId.value };

    this.commonapi.FillItemGroups(categoryId).subscribe(list => {
      this.ItemGroupList = list;
    });
  }

  fillVendors() {
    this.invapi.FillVendorList().subscribe(list => {

      this.VendorList = list;

    });
  }

  fillModelList() {
    if((this.f.manufacturerId.disabled && this.f.modelId.disabled) || this.f.equipmentType.value == 'PARTS') {
      const CategoryManufacturerId = { categoryId: this.f.categoryId.value, manufacturerId:this.f.manufacturerId.value };
      this.invapi
        .FillModels(CategoryManufacturerId)
        .subscribe(list => (this.ModelList = list));
    } else {
      const CategoryManufacturerId = { categoryId: this.f.categoryId.value, manufacturerId: this.f.manufacturerId.value };
    this.invapi
      .FillModels(CategoryManufacturerId)
      .subscribe(list => (this.ModelList = list));
    }
  }

  fillAndSetModelList() {

    if (this.f.manufacturerId.value != "-1") {
      if(this.f.modelId.enabled){
      this.f.modelId.setValue('');
      this.fillModelList();
    } else {
      this.f.manufacturerId.clearValidators();
      this.f.manufacturerId.updateValueAndValidity();
    }
    }
    else {
      this.f.manufacturerId.setValue('');
      this.quickAddDialog('Manufacturer');
    }
  }

  modelChange() {
    if (this.f.modelId.value == "-1") {
      this.f.modelId.setValue('');
      this.quickAddDialog('Model');
    }
  }

  itemGroupChange() {
    if (this.f.equipmentName.value == "-1") {
      this.f.equipmentName.setValue('');
      this.quickAddDialog('ItemGroup');
    } else {
      let itemGroup = this.ItemGroupList.filter(x => x.text == this.f.equipmentName.value);

      if (itemGroup && itemGroup.length > 0 && itemGroup[0]) {
        this.f.hrsTrackingItemGroup.setValue(itemGroup[0].hrsTracking);
        this.f.hrsTracking.setValue(itemGroup[0].hrsTracking);
      }
    }
  }

  onVendorChange() {

    if (this.f.vendorId.value != "-1") {
      var vendorSelected = this.VendorList.filter(
        x => x.contactId === parseInt(this.f.vendorId.value)
      );

      if (vendorSelected.length > 0) {
        this.f.vendorNumber.setValue(vendorSelected[0].contactNumber);
        this.f.vendorPhone.setValue(vendorSelected[0].phone);
      } else {
        this.f.vendorNumber.setValue('');
        this.f.vendorPhone.setValue('');
      }
    }
    else {
      this.f.vendorNumber.setValue('');
      this.f.vendorPhone.setValue('');
      this.f.vendorId.setValue('');
      this.quickAddDialog('Vendor');
    }
  }

  executeInventoryCreateUpdate = function (Source = '') {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.InventoryForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const inventoryCreate = this.prepareCreateViewModel();
    if (inventoryCreate.equipementId == '0') {
      this.invapi.CreateInventoryDetails(inventoryCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source != 'Notes') {
            this.router.navigate(['Inventory/InventorySearch']);
          } else {
            this.f.equipementId.setValue(success.equipementId);

            this.setModel();
            AppComponent.HideLoader();

          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.invapi.UpdateInventoryDetails(inventoryCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source != 'Notes') {
            this.router.navigate(['Inventory/InventorySearch']);
          } else {
            this.setModel();
            AppComponent.HideLoader();

          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };

  barcodeOpen()
  {
    // this.router.navigate(['Inventory/Barcode']);
    this.f.serialNumber.setValue('');
    this.quickBarcodeMdeiaDialogRef = this.dialog.open(MediaStreamComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
    });
    this.quickBarcodeMdeiaDialogRef.afterClosed().subscribe(result => {
    });

    this.quickBarcodeMdeiaDialogRef.componentInstance.SaveEvent.subscribe(opt => {
      this.f.serialNumber.setValue(opt);
    });
  }

  openBarcodeScanner()
  {
    this.f.serialNumber.setValue('');
    this.quickBarcodeDialogRef = this.dialog.open(BarcodeScannerComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
    });
    this.quickBarcodeDialogRef.afterClosed().subscribe(result => {
    });

    this.quickBarcodeDialogRef.componentInstance.SaveEvent.subscribe(opt => {
      this.f.serialNumber.setValue(opt);
    });
  }

  prepareCreateViewModel() {
    const inventoryCreate = <any>{};

    inventoryCreate.equipementId = this.f.equipementId.value;
    if(this.GetSiteName()=='SCSCDU')
      inventoryCreate.equipmentCode = this.f.equipmentCode.value;
    else
      inventoryCreate.equipmentCode = this.f.equipmentCode.value.toUpperCase();
    inventoryCreate.hrsTracking = this.f.hrsTracking.value;
    inventoryCreate.hrsTrackingItemGroup = this.f.hrsTrackingItemGroup.value;
    inventoryCreate.equipmentPreviousCode = this.f.equipmentPreviousCode.value;
    inventoryCreate.equipmentName = this.f.equipmentName.value;
    inventoryCreate.equipmentType = this.f.equipmentType.value;
    inventoryCreate.equipmentDesc = this.f.equipmentDesc.value;
    inventoryCreate.equipmentPicFileName = this.f.equipmentPicFileName.value;
    inventoryCreate.equipmentPicFilePath = this.f.equipmentPicFilePath.value;
    inventoryCreate.categoryId = this.f.categoryId.value;

    if (this.f.equipmentType.value == 'PARTS')
      inventoryCreate.serialNumber = this.f.equipmentCode.value.toUpperCase();
    else {
      inventoryCreate.serialNumber = this.f.serialNumber.value.toUpperCase();
      inventoryCreate.divisionId = this.f.divisionId.value;
    }

    inventoryCreate.barCode = this.f.barCode.value;
    inventoryCreate.barcode2 = this.f.barcode2.value;
    inventoryCreate.vendorId = this.f.vendorId.value;
    inventoryCreate.modeOfPayment = this.f.modeOfPayment.value;
    inventoryCreate.paymentDetails = this.f.paymentDetails.value;
    inventoryCreate.expenseCode = this.f.expenseCode.value;

    inventoryCreate.itemCost = this.currenctyToNumber(this.f.itemCost.value);
    inventoryCreate.previousCost = this.currenctyToNumber(this.f.previousCost.value);
    inventoryCreate.notes = this.notes.Note;

    if (this.f.inServiceDate.value !== null && this.f.inServiceDate.value !== undefined) {
      inventoryCreate.inServiceDate = Globals.GetAPIDate(this.f.inServiceDate.value.jsdate);
    }

    inventoryCreate.inServiceHours = this.f.inServiceHours.value;

    inventoryCreate.lastUpdateDate = this.f.lastUpdateDate.value;
    inventoryCreate.lastUpdateHrs = this.f.lastUpdateHrs.value;

    inventoryCreate.manufacturerId = this.f.manufacturerId.value;

    var manufacturerSelected = this.ManufacturerList.filter(
      x => x.value === this.f.manufacturerId.value.toString()
    );

    if (manufacturerSelected.length > 0) {
      inventoryCreate.manufacturer = manufacturerSelected[0].text;
    }

    inventoryCreate.model = this.f.model.value;

    var modelSelected = this.ModelList.filter(
      x => x.value === this.f.modelId.value.toString()
    );

    if (modelSelected.length > 0) {
      inventoryCreate.model = modelSelected[0].text;
    }

    inventoryCreate.modelId = this.f.modelId.value;
    inventoryCreate.warrantyPeriodDays = this.f.warrantyPeriodDays.value;
    inventoryCreate.warrantyPeriodHrs = this.f.warrantyPeriodHrs.value;
    inventoryCreate.warrantyPeriodForPartsDays = this.f.warrantyPeriodForPartsDays.value;
    inventoryCreate.warrantyPeriodForPartsHrs = this.f.warrantyPeriodForPartsHrs.value;
    inventoryCreate.presentStatus = this.f.presentStatus.value;

    inventoryCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    inventoryCreate.PMScheduleStatus = this.f.PMScheduleStatus.value;
    inventoryCreate.PMScheduleStatusType = this.f.PMScheduleStatusType.value;
    inventoryCreate.createdOn = new Date();
    inventoryCreate.createdBy = this.currentUserId;
    inventoryCreate.modifiedOn = new Date();
    inventoryCreate.modifiedBy = this.currentUserId;
    return inventoryCreate;
  }




  SetInventoryights() {
    this.auth.GetPageRights('Inventory Management').subscribe(list => {
      if (this.f.equipementId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        //this.blnshowDelete = list[actionType.delete] === '1';
        const accesToken = this.auth.getAccessToken();
        if (accesToken.DesignationName == "Administrator")
          this.blnshowDelete = true;
        else
          this.blnshowDelete = false;
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  DisableControls() {

    if (this.f.equipementId.value != '0') {
      this.f.categoryId.disable();
      this.f.equipmentType.disable();

      // if (this.f.manufacturerId.value != '')
      //   this.f.manufacturerId.disable();

      // if (this.f.modelId.value != '')
      //   this.f.modelId.disable();

      if (this.f.equipmentType.value == 'EQUIPMENT')
        this.f.equipmentName.disable();

      // if(this.f.equipmentType.value == 'EQUIPMENT')
      // this.f.equipmentName.disable();

      this.f.warrantyPeriodDays.disable();
      this.f.warrantyPeriodHrs.disable();
      this.f.warrantyPeriodForPartsDays.disable();
      this.f.warrantyPeriodForPartsHrs.disable();

      // if (this.f.inServiceDate.value == '')
      //   this.f.inServiceDate.enable();
      // else
      //   this.f.inServiceDate.disable();

      this.f.inServiceHours.disable();

      if (this.f.PMScheduleStatus.value)
        this.f.PMScheduleStatus.disable();

      if (this.f.PMScheduleStatusType.value)
        this.f.PMScheduleStatusType.disable();

      this.f.divisionId.disable();
    }
    this.f.barCode.disable();
    this.f.lastUpdateDate.disable();
    this.f.lastUpdateHrs.disable();
  }

  onCategoryChange() {

    this.fillManufacturers();
    this.fillItemGroups();
    this.fillModelList();

    this.f.equipmentName.setValue('');
    this.f.manufacturerId.setValue('');

    var categorySelected = this.CategoryList.filter(
      x => x.value === this.f.categoryId.value.toString()
    );

    if (categorySelected.length > 0) {
      this.CategoryName = categorySelected[0].text;
    }

    if (this.f.equipmentType.value == 'EQUIPMENT' && this.CategoryName == 'Dialysis Machines' && this.f.equipementId.value == '0') {
      this.f.equipmentName.setValue('Dialysis Machine');
      // this.f.equipmentName.disable();
    }
    else if (this.f.equipmentType.value == 'EQUIPMENT' && this.CategoryName == 'Water Process' && this.f.equipementId.value == '0') {
      this.f.equipmentName.setValue('Portable RO');
    }
    else {
      if (this.f.equipementId.value == '0') {
        this.f.equipmentName.setValue('');
        // this.f.equipmentName.enable();
      }
    }
  }

  onEquipmentTypeChange() {

    var categorySelected = this.CategoryList.filter(
      x => x.value === this.f.categoryId.value.toString()
    );

    if (categorySelected.length > 0) {
      this.CategoryName = categorySelected[0].text;
    }

    if (this.f.equipementId.value != '0') {

      if (this.f.equipmentType.value == 'PARTS')
        this.f.equipmentCode.disable();
      else
        this.f.equipmentCode.enable();

      this.f.serialNumber.disable();
    }

    if (this.f.equipmentType.value == 'PARTS') {
      // this.f.inServiceDate.clearValidators();
      this.f.divisionId.clearValidators();
      this.f.serialNumber.clearValidators();
      this.f.divisionId.setValue('');
    }
    else {
      if (this.f.equipementId.value == '0' && this.CategoryName !== 'Other Equipment') {
        this.f.divisionId.setValidators(Validators.required);
      }

      if (this.f.equipementId.value == '0' && !this.f.divisionId.value){
        this.auth.GetCurrentUserDivision().subscribe(division => {

          if(division.length===1){
            this.f.divisionId.setValue(division[0]);
          }
        }, error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        });
      }
      // this.f.inServiceDate.setValidators(Validators.required);
      this.f.serialNumber.setValidators(Validators.required);

    }



    if (this.f.equipmentType.value == 'EQUIPMENT' && this.CategoryName == 'Dialysis Machines' && this.f.equipementId.value == '0') {
      this.f.equipmentName.setValue('Dialysis Machine');
      // this.f.equipmentName.disable();
    }
    else if (this.f.equipmentType.value == 'EQUIPMENT' && this.CategoryName == 'Water Process' && this.f.equipementId.value == '0') {
      this.f.equipmentName.setValue('Portable RO');
    }
    else {
      if (this.f.equipementId.value == '0') {
        this.f.equipmentName.setValue('');
        // this.f.equipmentName.enable();
      }
    }

    this.f.inServiceDate.updateValueAndValidity();
    this.f.divisionId.updateValueAndValidity();
    this.f.serialNumber.updateValueAndValidity();

    this.onSerialNumberChange();
    this.fillModelList();
  }

  forceInputUppercase(e) {
    if(e.target.name == 'equipmentCode' && this.GetSiteName() !='SCSCDU')
      this.commonapi.forceInputUppercase(e);
    // else
    //   this.commonapi.forceInputUppercase(e);

  }

  GetSiteName() {
    return Globals.Website;
  }

  onSerialNumberChange() {
    if(this.f.equipmentType.value == 'EQUIPMENT' && this.f.serialNumber.value != '')
    {
      this.f.serialNumber.setAsyncValidators([
        InventoryCustomValidators.InventoryItemIDValidate(
          this.commonapi,
          this.f.serialNumber.value,
          "SerialNumber"
        )
      ]);
      this.f.serialNumber.updateValueAndValidity();
    }
    else
    {
      this.f.equipmentCode.setAsyncValidators([
        InventoryCustomValidators.InventoryItemIDValidate(
          this.commonapi,
          this.f.equipmentCode.value.toUpperCase(),
          "ItemID"
        )
      ]);
      this.f.equipmentCode.updateValueAndValidity();
    }

    if (this.f.equipmentType.value == 'PARTS') {
      this.f.serialNumber.setValue(this.f.equipmentCode.value.toUpperCase());
      this.f.barCode.setValue(this.f.equipmentCode.value.toUpperCase());
    }
    else if (this.f.equipmentType.value == 'EQUIPMENT') {
      this.f.barCode.setValue(this.f.serialNumber.value.toUpperCase());
    }
  }


  onFileSelected(event) {
    AppComponent.ShowLoader();
    var files = event.target.files;

    const frmData = new FormData();

    for (var i = 0; i < files.length; i++) {
      frmData.append("file", files[i]);
    }

    this.invapi.FileUpload(frmData).subscribe(
      success => {

        this.f.equipmentPicFileName.setValue(success.originalfilename);
        this.f.equipmentPicFilePath.setValue(success.uploadfilename);
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  GetInventoryImagePath() {
    return Globals.AttachmentUrl + '/' + this.f.equipmentPicFilePath.value;
  }

  transformitemCost() {
    if (this.f.itemCost.value != 0) {
      this.formatteditemCost = this.currencyPipe.transform(this.currenctyToNumber(this.f.itemCost.value), '$', '$', '1.2-2');
    }
    else {
      this.f.itemCost.setValue('');
    }
  }

  transformPreviousCost() {
    if (this.f.previousCost.value != 0) {
      this.formattedPreviousCost = this.currencyPipe.transform(this.currenctyToNumber(this.f.previousCost.value), 'CAD', '$', '1.2-2');
    }
    else {
      this.f.previousCost.setValue('');
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  clearImage() {
    this.f.equipmentPicFilePath.setValue("");
    this.f.equipmentPicFileName.setValue("");
    document.getElementById('ng2File')['value'] = '';
  }

  currenctyToNumber(value) { return Number(value.toString().replace(/[^0-9.-]+/g, "")); }


  quickAddDialog(type) {
    if (type != 'Vendor') {
      if (this.f.categoryId.value == '' && type != 'Manufacturer') {
        if (type == 'Model')
          AppComponent.addAlert('Category Required to Add Model.', 'error');
        else if (type == 'ItemGroup')
          AppComponent.addAlert('Category Required to Add ItemGroup.', 'error');

        AppComponent.loading = false;
        return;
      }
      else if (type == 'Model' && this.f.manufacturerId.value == '') {
        AppComponent.addAlert('Manufacturer Required to Add Model.', 'error');
        AppComponent.loading = false;
        return;
      }
    }
    this.quickAddDialogRef = this.dialog.open(QuickAddModelDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '33%',
    });
    this.quickAddDialogRef.componentInstance.quickAddModel.title = type;
    this.quickAddDialogRef.afterClosed().subscribe(result => {
    });

    this.quickAddDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      if (evt.title == 'Manufacturer') {
        this.saveManufacturer(evt.name,evt.Category);
      }
      else if (evt.title == 'Model') {
        this.saveModel(evt.name);
      }
      else if (evt.title == 'ItemGroup') {
        this.saveItemGroup(evt.name, evt.hrsTracking);
      }
      else if (evt.title == 'Vendor') {

        this.saveVendor(evt.name, evt.mailingcountry, evt.mailingstate,evt.mailingcity,evt.mailingaddress,
          evt.mailingzipCode,evt.phone);
      }
    });
  }

  saveManufacturer(name,Category) {
    AppComponent.ShowLoader();

    const manufacturerCreate = <any>{};
    manufacturerCreate.ManufacturerName = name;
    manufacturerCreate.createdBy = this.createdBy;
    manufacturerCreate.modifiedBy = this.createdBy;
    manufacturerCreate.Category = Category;
    this.invapi.CreateManufacturerDetails(manufacturerCreate).subscribe(
      success => {
        AppComponent.addAlert('Manufacturer Saved successfully!', 'success');
        this.f.manufacturerId.setValue(success.manufacturerId);
        this.f.manufacturer.setValue(success.manufacturerName);

        this.fillManufacturers();
        AppComponent.HideLoader();
      },
      error => {
        if (error && error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const InventoryObj = { Id: this.f.equipementId.value };
        this.invapi.DeleteInventoryDetail(InventoryObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              this.f.equipmentType.value + ' deleted successfully!',
              'success'
            );
            this.router.navigate(['Inventory/InventorySearch']);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  saveModel(name) {
    AppComponent.ShowLoader();
    const modelCreate = <any>{};
    modelCreate.ModelName = name;
    modelCreate.CategoryId = this.f.categoryId.value;
    modelCreate.ManufactureId = this.f.manufacturerId.value;
    modelCreate.createdBy = this.createdBy;
    modelCreate.modifiedBy = this.createdBy;

    this.invapi.CreateModelDetails(modelCreate).subscribe(
      success => {
        AppComponent.addAlert('Model Saved successfully!', 'success');
        this.f.modelId.setValue(success.modelId);
        this.f.model.setValue(success.modelName);

        this.fillModelList();
        AppComponent.HideLoader();

      },
      error => {
        if (error && error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  saveItemGroup(name, hrsTracking) {
    AppComponent.ShowLoader();
    const itemGroupCreate = <any>{};
    itemGroupCreate.itemGroupName = name;
    itemGroupCreate.CategoryId = this.f.categoryId.value;
    itemGroupCreate.ManufactureId = this.f.manufacturerId.value;
    itemGroupCreate.hrsTracking = hrsTracking;
    itemGroupCreate.createdBy = this.createdBy;
    itemGroupCreate.modifiedBy = this.createdBy;

    this.commonapi.CreateItemGroupDetails(itemGroupCreate).subscribe(
      success => {
        AppComponent.addAlert('Item Group Saved successfully!', 'success');
        this.f.equipmentName.setValue(success.itemGroupName);
        this.f.hrsTrackingItemGroup.setValue(success.hrsTracking);
        this.f.hrsTracking.setValue(success.hrsTracking);

        this.fillItemGroups();
        AppComponent.HideLoader();

      },
      error => {
        if (error && error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  saveVendor(name, mailingcountry, mailingstate, mailingcity, mailingaddress, mailingzipCode, phone) {

    AppComponent.ShowLoader();
    const modelCreate = <any>{};
    modelCreate.contactid = 0;
    modelCreate.givenname = name;
    modelCreate.createdby = this.createdBy;
    modelCreate.modifiedby = this.createdBy;
    modelCreate.mailingcountry = mailingcountry;
    modelCreate.mailingstate = mailingstate;
    modelCreate.mailingcity = mailingcity;
    modelCreate.mailingaddress = mailingaddress;
    modelCreate.mailingzipCode = mailingzipCode;
    modelCreate.phone = phone;

    this.contactApi.CreateVendorDetails(modelCreate).subscribe(
      success => {
        AppComponent.addAlert('Vendor Saved successfully!', 'success');
        this.fillVendors();
        this.f.vendorId.setValue(success.contactid);
        setTimeout(() => {
          this.onVendorChange();
        }, 500);
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }


  getScheduledHours() {
    AppComponent.ShowLoader();
    const inventory = {
      Id: this.f.equipementId.value
    };

    this.invapi.GetScheduledHours(inventory).subscribe(
      success => {
        if (success.length > 0) {
          this.scheduledPMList = success;
        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.HideLoader();
      }
    );
  }

  hrsTrackingItemGroupChecked(){

    if(this.f.hrsTrackingItemGroup.value){
      this.f.hrsTracking.setValue(true);
    } else {
      this.f.hrsTracking.setValue(false);
    }
  }

  hrsTrackingVisible(){
    if(this.f.equipmentType.value == 'EQUIPMENT'
      && (this.f.equipmentName.value!='Dialysis Machine'
      && this.f.equipmentName.value!='Portable RO'
      && this.f.equipmentName.value!='CWP RO'
      && this.f.equipmentName.value!='MODULA S5'
      && this.f.equipmentName.value!='NeprhoSafe'
      && this.f.equipmentName.value!='BioPure HX2')){
        return true;
    } else {
        return false;
    }
  }

}

import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewerDialog } from '../../Core/Shared/ImageViewerDialog/ImageViewerDialog';

@Component({
    // moduleId: module.id,
    selector: 'RemoteView',
    templateUrl: 'RemoteView.component.html',
    styleUrls: ['RemoteView.component.css']
})

export class RemoteviewComponent {
  constructor(
    private router: Router,
    public dialog: MatDialog
  ) { }
  title = "Remote View";

  RouteClick(value) {
    this.router.navigate([value]);
  }

  openImageDialog(imagePath, Header): void {
    if(Header!== 'BioPure HX2'){
        const dialogRef = this.dialog.open(ImageViewerDialog, {
          position: { right: '0', top: '0' },
          width: '50%',
          height: '100%',
          data: { imgURL: imagePath, title : Header}
        });

        dialogRef.afterClosed().subscribe(result => {
          // console.log('Image dialog was closed');
         });
      } else {
        const dialogRef = this.dialog.open(ImageViewerDialog, {
          position: { right: '0', top: '0' },
          width: '90%',
          height: '100%',
          data: { imgURL: imagePath, title : Header}
        });

        dialogRef.afterClosed().subscribe(result => {
          // console.log('Image dialog was closed');
         });
      }

  }
}

<section class="content-header">
  <h1>
    <h1>{{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary">
        <div class="row"></div>
        <div class="col-sm-12">
          <div class="col-sm-4 text-center">
            <div class="dvImageMachine">
              <img
                class="imgStyle"
                src="/assets/img/Remote_View/ModulaS.png"
                (click)="
                  openImageDialog(
                    '/assets/img/Remote_View/ModulaS_Layout.png',
                    'Modula S5'
                  )
                "
              />
              <span class="spanMachine">Modula S5</span>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="dvImageMachine">
              <img
                class="imgStyle"
                src="/assets/img/Remote_View/NephroSafe.png"
                (click)="
                  openImageDialog(
                    '/assets/img/Remote_View/NephroSafe_Layout.png',
                    'NephroSAFE'
                  )
                "
              />
              <span class="spanMachine">NephroSAFE</span>
            </div>
          </div>
          <div class="col-sm-4 text-center">
            <div class="dvImageMachine">
              <img
                class="imgStyle"
                src="/assets/img/Remote_View/CeCon.png"
                (click)="
                  openImageDialog(
                    '/assets/img/Remote_View/CeCon_Layout.png',
                    'CeCON'
                  )
                "
              />
              <span class="spanMachine">CeCON</span>
            </div>
          </div>
          <!-- <div class="col-sm-3 text-center">
            <div class="dvImageMachine">
              <img
                class="imgStyle"
                src="/assets/img/Remote_View/ccms.png"
                (click)="
                  openImageDialog(
                    '/assets/img/Remote_View/ccms_Layout.PNG',
                    'CCMS'
                  )
                "
              />
              <span class="spanMachine">CCMS</span>
            </div>
          </div> -->
        </div>

        <div class="col-sm-12">
            <div class="col-sm-4 text-center" style="margin-top:50px;">
            <div class="dvImageMachine">
              <img
                class="imgStyle"
                src="/assets/img/Remote_View/HX2.png"
                (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')"
              />
              <!-- (click)="
              openImageDialog(
                '/assets/img/Remote_View/HX2_Layout.svg',
                'BioPure HX2'
              )
            " -->
              <!-- (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')" -->
              <span class="spanMachine">BioPure HX2</span>
            </div>
            </div>

            <div class="col-sm-4 text-center" style="margin-top:50px;">
              <div class="dvImageMachine">
                <img
                  class="imgStyle"
                  src="/assets/img/Remote_View/CWP100.png"
                  (click)="
                    openImageDialog(
                      '/assets/img/Remote_View/CWP100.png',
                      'CWP 100'
                    )
                  "
                />
                <span class="spanMachine">CWP 100</span>
              </div>
            </div>
            <div class="col-sm-4 text-center" style="margin-top:50px;">
              <div class="dvImageMachine">
                <img
                  class="imgStyle"
                  src="/assets/img/Remote_View/CWP800.png"
                  (click)="
                    openImageDialog(
                      '/assets/img/Remote_View/CWP800.png',
                      'CWP 800'
                    )
                  "
                />
                <span class="spanMachine">CWP 800</span>
              </div>
            </div>
          </div>
        </div>
      </div>

  </section>
</section>

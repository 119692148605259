import { CookieService } from 'ngx-cookie-service';
import { ForumMessagesDialog } from './ForumMessages/ForumMessagesDialog';
import { OrganizationalUnitApiService } from './../API/organizationalunitapi.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { AuthService } from '../API/auth.service';
import { AppComponent } from '../app.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialog } from './Shared/ErrorDialog/ErrorDialog';
import { MenuSharedService } from './services/menu-shared.service';
import { ContactApiService } from '../API/contactapi.service';
import domtoimage from 'dom-to-image';
import * as html2canvas from 'html2canvas';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'header1',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  public loginName: string;
  public loginNameWithdesg: string;

  public userRole: string;
  public renalUrl = '#';
  public RedirectUrl = '#';
  public OldRedirectUrl = '#';
  public WebSiteType = '';
  UrgentMessage = '0';
  UrgentTasks = '0';
  lst = [];
  menuPadding = 0;
  dataRefresher;
  ForumURL: string;
  lastLoginDateTime  = Date.now();
  userDesignation = this.authService.GetDesignationName();
  Efficiency_Today = 0;
  userId = 0;
  userEfficiencyDisplay =  false;
  constructor(
    private router: Router,
    private globals: Globals,
    private authService: AuthService,
    public dialog: MatDialog,
    public menuSharedService: MenuSharedService,
    private contactApi: ContactApiService,
    private commonapi: CommonApiService,
    private orgUnitApi: OrganizationalUnitApiService,
    private _elementRef: ElementRef,
    private cookieService: CookieService
  ) { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    this.GetForumLink();
    const accesToken = this.authService.getAccessToken();
    if (accesToken != null) {
      this.loginName = decodeURI(accesToken.FullName);
      const ssoEmail = this.authService.GetSSOEmail();

      if (ssoEmail !== '') {
        if(this.GetSiteName() == 'MRMDemo')
        {
          this.renalUrl = Globals.mrmrenalwebsite + 'sso/Dashboard?id=' + this.authService.GetSSOEmail();
        }
        else
        {
          this.renalUrl = Globals.mrmrenalwebsite + '/sso?id=' + this.authService.GetSSOEmail();

          this.WebSiteType =Globals.WebsiteType;
          if(Globals.WebsiteType == 'Live')
            this.RedirectUrl = Globals.StagingUrl + '/sso?token='+ ssoEmail;
          else
            this.RedirectUrl = Globals.LiveUrl + '/sso?token='+ ssoEmail;

            this.OldRedirectUrl = Globals.OldLiveUrl + '/sso?token='+ ssoEmail;
        }
      }
      this.userRole = accesToken.DesignationName;
      this.loginNameWithdesg = decodeURI(accesToken.FullName).replace(
        '<br/>',
        ' '
      );

      this.userDesignation = accesToken.DesignationName;
      this.authService
        .GetMenus(
          accesToken.ContactId,
          accesToken.DesignationId,
          accesToken.DesignationName,
          ''
        )
        .subscribe(list => {
          // tslint:disable-next-line:forin
          for (const childObj in list) {
            this.lst.push(list[childObj].dbName);
            // tslint:disable-next-line:forin
            for (const childObj1 in list[childObj].subMenu) {
              this.lst.push(list[childObj].subMenu[childObj1].dbName);
            }
          }
          // const menuWidth =  document.getElementsByName("navBar")[0].clientWidth;
          // console.log("ajax",menuWidth);
          //   this.menuPadding = (AppComponent.MenuWidth - menuWidth) / 2;
        }

        );
    //  this.setNotification();
      // setInterval(() => {
      //   this.setNotification();
      // }, 30000);
      // AppComponent.refreshData();


      this.menuSharedService.getEmittedValue()
        .subscribe(item => {
          this.RefreshMenus(accesToken);
        });

        this.userId = accesToken.ContactId;
        const contactCreate = { Id:  accesToken.ContactId};
        this.contactApi.GetContactDetailsByID(contactCreate).subscribe(
          success => {
          Globals.lastLoginDateTime =  this.lastLoginDateTime = success.lastLoginDateTime;
          });

          this.getOrganizationDetail();

        if (this.userEfficiencyDisplay) {
            this.commonapi.GetMRMHoursEfficiency_Today(contactCreate).subscribe(eff => {
              this.Efficiency_Today = eff;
            });
        }
    }
  }

  getOrganizationDetail() {
    const accesToken = this.authService.getAccessToken();
    if (accesToken != null) {
    const orgUnit = { Id: 1 };
    this.orgUnitApi.GetOrgUnitDetails(orgUnit).subscribe(
      success => {
        if (success) {
          this.userEfficiencyDisplay = success.userEfficiencyDisplay ? success.userEfficiencyDisplay : false;
        }
      },
        error => {

          console.log(error.message);
          // AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  }

  GetTodayHourEfficeincy() {
    const accesToken = this.authService.getAccessToken();
    if (accesToken != null) {
        this.getOrganizationDetail();

        if (this.userEfficiencyDisplay) {
        const contactCreate = { Id:  this.userId};

        this.commonapi.GetMRMHoursEfficiency_Today(contactCreate).subscribe(eff => {
          this.Efficiency_Today = eff;
        });
      }
    }
  }

  defaultPageLink() {
    if(this.userDesignation == 'Administrator' || this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist') {
      return 'Task/TaskViewer';
    } else {
      return 'Home/default';
    }
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    // const menuWidth =  document.getElementsByName("navBar")[0].clientWidth;
    //       console.log(menuWidth);
    //         this.menuPadding = ("AppComponent.MenuWidth - menuWidth) / 2;
    // var x = document.getElementsByClassName("dropdown-menu hide");
    // var i;
    // for (i = 0; i < x.length; i++) {
    //   x[i].className = "dropdown-menu";
    // }

  }

  RefreshMenus(accesToken) {
    AppComponent.ShowLoader();
    this.lst = [];
    this.authService
      .GetMenus(
        accesToken.ContactId,
        accesToken.DesignationId,
        accesToken.DesignationName,
        ''
      )
      .subscribe(list => {
        // tslint:disable-next-line:forin
        for (const childObj in list) {
          this.lst.push(list[childObj].dbName);
          // tslint:disable-next-line:forin
          for (const childObj1 in list[childObj].subMenu) {
            this.lst.push(list[childObj].subMenu[childObj1].dbName);
          }
        }
        AppComponent.HideLoader();

      });
  }

  logout() {
    this.authService.removeToken();
    if (Globals.Version === 'local') {
      this.router.navigate(['/login']);
    } else if (Globals.Version === 'publish') {
      this.router.navigate(['/login']);
      window.location.reload();
      // location.href = Globals.mrmrenalLogOutwebsite;
    }
  }

  checkCompany(cmpname) {

    if (Globals.Website === cmpname) {
      return true;
    }
    else {
      return false;
    }
  }

  IsLiveVersion(){
    if (Globals.WebsiteType == 'Live') {
      return true;
    }
    else {
      return false;
    }
  }

  checkAvailable(MenuText) {
    return this.lst.filter(x => x === MenuText).length > 0;
  }

  RouteClick(value) {
    //
    this.router.navigate([value]);
    document.getElementById('navbar-collapse').className = 'navbar-collapse pull-left no-print text-center collapse';

    // var x = document.getElementsByClassName("dropdown-menu");
    // var i;
    // for (i = 0; i < x.length; i++) {
    //   x[i].className = "dropdown-menu hide";
    // }
  }

  setNotification() {
    this.authService.GetUrgentMessages().subscribe(list => {
      this.UrgentMessage = list;

    });
    this.authService.GetUrgentTasks().subscribe(list => {
      this.UrgentTasks = list;
    });
  }

  MenuClick(value) {
    // AppComponent.ShowLoader();
    this.authService.GetPasswordLoggedInUser().subscribe(list => {
      this.globals.url =
        Globals.oldUrl +
        '/login.aspx?source=MRMWEB' +
        '&returnURL=' +
        value +
        '&UName=' +
        this.authService.GetUserEmail() +
        '&PWD=' +
        list.userPassword;
      this.router.navigate(['Home']);

      // setTimeout(() => AppComponent.HideLoader(), 4000);
    });
  }

GetForumLink() {
  this.authService.GetEncryptedDetailForForum().subscribe(
    v => {
      this.ForumURL ="http://forum.mrmrenal.com/autologin.aspx?user=" + v.userdetail;
      console.log(v);
      });
}
GetSiteName() {
  return Globals.Website;
}
  openImageDialog(transaction): void {

    let alert: any = document.querySelectorAll('ngb-alert div')[0];
    let footer: any = document.getElementById('grFooter');
    let mywrapper: any = document.getElementById('mywrapper');
    let content: any = document.getElementsByClassName('content')[0];

    if (alert) {
      alert.style.position = 'relative';
      alert.parentElement.style.bottom = '-18px';
      alert.style.bottom = '-18px';

    }

    if (content) {
      content.style.paddingBottom='0px';
    }

    footer.classList.remove("no-print");
    footer.style.position = 'relative';

    mywrapper.style.paddingBottom = "0px";
    var dom = document.getElementById('mainBody');


    // let dom = this.domcontainer.nativeElement
    if (dom) {
      AppComponent.ShowLoader();
      var thisvar = this;
      // setTimeout(() => {
      //   domtoimage.toPng(dom)
      //   .then(function (dataUrl) {
      //     AppComponent.HideLoader();
      //     thisvar.OpenErrorDialog(dataUrl);
      //   })
      //   .catch(function (error) {
      //     AppComponent.HideLoader();
      //     console.error('oops, something went wrong while capturing image!', error);
      //   })
      // });


      //  NEED TO UPDATE AFTER WARDS
      // html2canvas(dom).then(canvas => {
      //   const img = canvas.toDataURL();
      //   AppComponent.HideLoader();

      //   thisvar.OpenErrorDialog(img);
      //   if (alert) {
      //     alert.removeAttribute("style");
      //   }

      //   footer.classList.add("no-print");
      //   footer.removeAttribute("style");
      //   mywrapper.removeAttribute("style");
      //   if (content) {
      //     content.removeAttribute("style");
      //   }


      // }).catch(function (error) {
      //   AppComponent.HideLoader();
      //   console.error('oops, something went wrong while capturing image!', error);
      //   if (alert) {
      //     alert.removeAttribute("style");
      //     alert.parentElement.removeAttribute("style");
      //   }

      //   footer.classList.add("no-print");
      //   footer.removeAttribute("style");
      //   mywrapper.removeAttribute("style");
      //   if (content) {
      //     content.removeAttribute("style");
      //   }
      // });
    }
    else {
      this.OpenErrorDialog(null);
    }
  }

  OpenErrorDialog(img) {
    const dialogRef = this.dialog.open(ErrorDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: '700px',
      data: { imgURL: img, title: 'Error Image' }
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('Image dialog was closed');
    });
  }
  GetMenuWidth() {
    return AppComponent.MenuWidth;
  }

  showStagingLiveLink(){
    if(this.userDesignation == 'Administrator' || this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist')
      return true;
    else
    return false;
  }

  setWindowName(){
    const dateNow = new Date();
    dateNow.setMinutes(dateNow.getMinutes() + 2);
    var cookie = this.cookieService.get('mrmCookies');
      if(cookie == '')
        this.cookieService.set('mrmCookies','mrmrenal.com', dateNow, '/', 'mrmrenal.com', null, null);
    location.href = this.RedirectUrl;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class TasksApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    GetTaskTypeList(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTaskTypeList',
        value,
        httpOptions
      );
    }
    CreateTaskType(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/CreateTaskType',
        value,
        httpOptions
      );
    }

    UpdateTasksType(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/UpdateTasksType',
        value,
        httpOptions
      );
    }

    DeleteTasksTypeByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/DeleteTasksTypeByID',
        value,
        httpOptions
      );
    }
    GetTasksTypeByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTasksTypeByID',
        value,
        httpOptions
      );
    }

    FillDivision() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillDivision',
        '',
        httpOptions
      );
    }

    FillTaskType() {
      return this.http.post<any>(
        this.apiUrl + 'DMRepairFillService/GetTaskTypeDD',
        '',
        httpOptions
      );
    }

    FillInventoryCategory() {
      return this.http.post<any>(
        this.apiUrl + 'DMRepairFillService/GetInventoryCategoryDD',
        '',
        httpOptions
      );
    }

    GetUsersDDByDivisionID(value) {
      return this.http.post<any>(
        this.apiUrl + 'DMRepairFillService/GetUsersDDByDivisionID',
        value,
        httpOptions
      );
    }

    GetDivisionByID(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetDivisionByID',
        division,
        httpOptions
      );
    }

    GetEquipmentByCategoryDD(value) {
      return this.http.post<any>(
        this.apiUrl + 'DMRepairFillService/GetEquipmentByCategoryDD',
        value,
        httpOptions
      );
    }

    CreateTaskDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/CreateTaskDetails',
        value,
        httpOptions
      );
    }
    UpdateTaskDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/UpdateTasksDetails',
        value,
        httpOptions
      );
    }
    GetTaskDetailList(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTaskDetailList',
        value,
        httpOptions
      );
    }
    GetTaskDetailsByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTasksDetailsByID',
        value,
        httpOptions
      );
    }

    DeleteTasksDetailsByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/DeleteTasksDetailsByID',
        value,
        httpOptions
      );
    }

    GetTaskNextScheduledDate(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTaskNextScheduledDate',
        value,
        httpOptions
      );
    }

    GetTasksMappingDetailsBytaskID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetTasksMappingDetailsBytaskID',
        value,
        httpOptions
      );
    }
    GetattachmentDetailsbytaskID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetattachmentDetailsbytaskID',
        value,
        httpOptions
      );
    }
    GetnotesbyTaskDetailsID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetnotesbyTaskDetailsID',
        value,
        httpOptions
      );
    }

    

    UpdateTasksDate(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/UpdateTasksDate',
        value,
        httpOptions
      );
    }

    GetAutoTaskStationList() {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetAutoTaskStationList',
        '',
        httpOptions
      );
    }

    CreateAutoTasks(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/CreateAutoTasks',
        value,
        httpOptions
      );
    }

    DeleteAutoTasks(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/DeleteAutoTasks',
        value,
        httpOptions
      );
    }

    UpdateAutoTasks(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/UpdateAutoTasks',
        value,
        httpOptions
      );
    }

    GetAutoTasksbyID(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetAutoTasksbyID',
        value,
        httpOptions
      );
    }

    GetDialysisStationByID(value) {
      return this.http.post<any>(
        this.apiUrl +
        'Home/GetDialysisStationByID',
        value,
        httpOptions
      );
    }
    GetAutoTaskList() {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetAutoTaskList',
        '',
        httpOptions
      );
    }

    UpdateAutoTaskBaseDate(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/UpdateAutoTaskBaseDate',
        value,
        httpOptions
      );
    }

    GetMonthlyFirstTaskDate(value) {
      return this.http.post<any>(
        this.apiUrl + 'TasksService/GetMonthlyFirstTaskDate',
        value,
        httpOptions
      );
    }

  }

import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NoteDialog } from '../../Core/NotesModel/NoteDialog';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { AcidLevelComponent } from '../../Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { WaterProcessCustomValidators } from '../../Validator/WaterProcessCustomValidators';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';

@Component({
  selector: 'FieldServiceDetail',
  templateUrl: './FieldServiceDetail.component.html',
  styleUrls:['./FieldService.css']
})
export class FieldServiceDetailComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  @ViewChild(AcidLevelComponent, { static: false })
  acidLevel: AcidLevelComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(UntypedFormControl, { static: false })
  mainform: UntypedFormControl;
  noteDialogRef: MatDialogRef<NoteDialog>;
  currentUserId = this.auth.GetUserId();
  pageRights = '';

  isValidFormSubmitted = null;
  ListURL = 'DialysisMachine/FieldServiceSearch';
  DialysisStation = [];
  EventCode = '[AutoNumber]';

  loading = true;

  FieldServiceDetailsIdvar = '0';

  DialysisUnits = [];

  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  UserList = [];
  
  Status = 'In Progress';
  DUName = '';
  FirstShiftUserName = '';
  FirstShiftDate = '';
  SecondShiftUserName = '';
  SecondShiftDate = '';
  Today = new Date();
  morningDetailsEnteredBy = '0';
  morningDetailsEnteredOn;
  noonDetailsEnteredBy = '0';
  noonDetailsEnteredOn;
  CurrentUserName='';
  blnshowSave = false;
  blnshowDelete = false;
  IsShepardForm = false;
  lstYesNo = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];
  lstCarbon = [
    { value: '', text: 'Select' },
    { value: '1(TECH.CT1) 1 Tank-1 Tech', text: '1(TECH.CT1) 1 Tank-1 Tech' },
    { value: '2(TECH.CT2) 2 Tanks-2 Techs)', text: '2(TECH.CT2) 2 Tanks-2 Techs)' },
  ];
  lstROProduct = [
    { value: '', text: 'Select' },
    { value: 'Private Well (TECH.PWP)', text: 'Private Well (TECH.PWP)' },
    { value: 'Municipal (TECH.PWM)', text: 'Municipal (TECH.PWM)' },
  ];
  lstFeed = [
    { value: '', text: 'Select' },
    { value: 'Private Well (TECH.FWP)', text: 'Private Well (TECH.FWM)' },
    { value: 'Municipal (TECH.PWM)', text: 'Municipal (TECH.PWM)' },
  ];
  lstPurpose = [
    { value: '', text: 'Select'},
    { value: 'Initial Site Visit', text: 'Initial Site Visit'},
    { value: 'Renovation', text: 'Renovation'},
    { value: 'Full Installation', text: 'Full Installation'},
    { value: 'UnInstallation', text: 'UnInstallation'},
    { value: 'Repair', text: 'Repair'},
    { value: 'Testing', text: 'Testing'},
    { value: 'Maintenance', text: 'Maintenance'},
    { value: 'Other', text: 'Other'},
  ];

  lstPrepTime = [
    { value: '', text: 'select'},
{ value: '0.5', text: '0.5'},
{ value: '1', text: '1.0'},
{ value: '1.5', text: '1.5'},
{ value: '2', text: '2.0'},
{ value: '2.5', text: '2.5'},
{ value: '3', text: '3.0'},

  ];

  lstVehicle = [
    { value: '', text: 'Select'},
    { value: 'GRVan', text: 'GRVan'},
    { value: 'GRCar', text: 'GRCar'},
    { value: 'Other', text: 'Other'},
  ];

  lstHPCResult = [
    { value: '', text: 'Select'},
    { value: '<10', text: '<10'},
{ value: '10', text: '10.0'},
{ value: '11', text: '11.0'},
{ value: '12', text: '12.0'},
{ value: '13', text: '13.0'},
{ value: '14', text: '14.0'},
{ value: '15', text: '15.0'},
{ value: '16', text: '16.0'},
{ value: '17', text: '17.0'},
{ value: '18', text: '18.0'},
{ value: '19', text: '19.0'},
{ value: '>20', text: '>20'},

  ];
  lalResult = [
    { value: '', text: 'Select' },
    { value: 'Pass', text: 'Pass' },
    { value: 'Fail', text: 'Fail' },
  ];
  EquipmentExchangeTypeResult = [
    { value: '', text: 'Select' },
    { value: 'RO', text: 'RO' },
    { value: 'Water Process', text: 'Water Process' },
    { value: 'D. Machine', text: 'D. Machine' },
    { value: 'Other', text: 'Other' },
  ];

  lstPaymentBy = [
    { value: '', text: 'Select' },
    { value: 'Visa', text: 'Visa' },
    { value: 'Mastercard', text: 'Mastercard' },
    { value: 'Cash', text: 'Cash' },
    { value: 'Other', text: 'Other' },
  ];

  lstFuelCompany = [
    { value: '', text: 'Select' },
    { value: 'Canadian Tire', text: 'Canadian Tire' },
    { value: 'Chevron', text: 'Chevron' },
    { value: 'Esso', text: 'Esso' },
    { value: 'Husky', text: 'Husky' },
    { value: 'Petro-Canada', text: 'Petro-Canada' },
    { value: 'Pioneer', text: 'Pioneer' },
    { value: 'Shell Canada', text: 'Shell Canada' },
    { value: 'Ultramar', text: 'Ultramar' },
    { value: 'Other', text: 'Other' },
  ];
  dropdownSettings;
  //NumberRegex = '^[0-9]+(.[0-9]{0,2})?$';

  public AcidLevelGroup: UntypedFormGroup = new UntypedFormGroup({});
  // -------------------------------------End------------------------------------------------

  // Form
  // -------------------------------------Start----------------------------------------------
  FieldServiceForm = new UntypedFormGroup({
    FieldServiceDetailsId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl(''),
    EventDate: new UntypedFormControl(''),
    Technologists: new UntypedFormControl(''),
  });

  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
  ) {}
  // implementation of canDeactivate
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.FieldServiceForm.dirty ||
      this.notes.Note != '' ||
      this.attachment.Queue().length > 0 ) && (this.isValidFormSubmitted==false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
         //  console.log('res', res);
          return res;
        })
      );
    } else return true;
  }
  // -------------------------------------End------------------------------------------------


  ngAfterViewChecked() {
    //console.log('scroll');

    this.scrollHelper.doScroll();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 6,
      allowSearchFilter: false
    };
    //this.SetPageRights();
    AppComponent.ShowLoader();
    this.CurrentUserName = this.auth.GetUserFLName();
    this.fillDialysisStation();
    this.fillUserList();
    this.fillDialysisUnit();
    const tempDate = new Date();
      this.f.CreatedDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
    AppComponent.HideLoader();
  }

  fillDialysisStation() {
      this.dmapi.GetDialyisStationDD(12).subscribe(list => {
        this.DialysisStation = list;
      });
  }

  get f() {
    return this.FieldServiceForm.controls;
  }

  setModel() {
    //AppComponent.ShowLoader();
    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.clearAsyncValidators();
    this.attachment.clearQueue();
    this.notes.Note='';
    //var wpdailyCreate = { Id: this.f.FieldServiceDetailsId.value };
    this.fillDialysisUnit();
  }

  SetPageRights() {
    this.auth.GetPageRights('Water Process Daily Record').subscribe(list => {
      if (this.f.FieldServiceDetailsId.value != '0') {
        this.blnshowSave = list[actionType.edit] == '1';
        this.blnshowDelete = list[actionType.delete] == '1';
      } else {
        this.blnshowSave = list[actionType.create] == '1';
        this.blnshowDelete = false;
      }
    });
  }
  //Functions/Methods
  //-------------------------------------Start----------------------------------------------

  fillDialysisUnit() {
    // this.dmapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => (this.DialysisUnits = list));

      this.dmapi
      .GetDialysisUnitWP()
      .subscribe(list => (this.DialysisUnits = list));
  }

  fillUserList() {
    this.dmapi.GetUsersDD().subscribe(list => {
      this.UserList = list.filter((item, index) => item.value !== '');
    });
  }
  onDateChanged(event: IMyDateModel): void {}
  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }
  executeFieldServiceCreateUpdate = function(Source) {
  //   AppComponent.loading = true;
  //  //  console.log(this.FieldService);
  //   this.GreaterValueCheck();
  //   this.isValidFormSubmitted = false;

  //   this.acidLevel.isValidFormSubmitted = this.isValidFormSubmitted;
  //   if (this.FieldService.invalid) {
  //     AppComponent.addAlert(Globals.FormErrorMessage, 'error');
  //     AppComponent.loading = false;

  //     //window.scrollBy(0,500);
  //     this.scrollHelper.scrollToFirst('ng-invalid');
  //     AppComponent.ScrollDown();
  //     Object.keys(this.FieldService.controls).forEach(key => {
  //       //const controlErrors: any = this.FieldService.get(key).errors;
  //       // if (controlErrors != null) {
  //       //   Object.keys(controlErrors).forEach(keyError => {
  //       //     console.log(
  //       //       'Key control: ' +
  //       //         key +
  //       //         ', keyError: ' +
  //       //         keyError +
  //       //         ', err value: ',
  //       //       controlErrors[keyError]
  //       //     );
  //       //   });
  //       // }
  //     });
  //     return;
  //   }
  //   this.isValidFormSubmitted = true;
  //   var wpdailyCreate = this.prepareCreateViewModel();
  //   if (wpdailyCreate.dailyWaterRecordId == '0') {
  //     this.wpapi.CreateWaterProcessDailyRecord(wpdailyCreate).subscribe(
  //       success => {
  //         this.DMRepailDetail = success.dmRepailDetails;
  //         AppComponent.loading = false;
  //         AppComponent.addAlert('Data Saved successfully!', 'success');
  //         if (Source != 'Notes') {
  //           this.router.navigate(['WaterProcess/Daily/WPDailySearch']);
  //         } else {
  //           this.f.FieldServiceDetailsId.setValue(success.dailyWaterRecordId);

  //           this.setModel();
  //         }
  //       },
  //       error => {
  //         AppComponent.addAlert(error.message, 'error');
  //         AppComponent.HideLoader();
  //       }
  //     );
  //   } else {
  //     this.wpapi.UpdateWaterProcessDailyRecord(wpdailyCreate).subscribe(
  //       success => {
  //         this.DMRepailDetail = success.dmRepailDetails;
  //         AppComponent.loading = false;
  //         AppComponent.addAlert('Data Saved successfully!', 'success');

  //         if (Source != 'Notes') {
  //           this.router.navigate(['WaterProcess/Daily/WPDailySearch']);
  //         } else {
  //           this.setModel();
  //         }
  //       },
  //       error => {
  //         AppComponent.addAlert(error.message, 'error');
  //         AppComponent.HideLoader();
  //       }
  //     );
  //   }
  };

  prepareCreateViewModel() {
    // var wpdailyCreate = <any>{};

    // wpdailyCreate.dailyWaterRecordId = this.f.FieldServiceDetailsId.value;
    // wpdailyCreate.divisionId = this.f.selectedDialysisUnits.value;
    // wpdailyCreate.rawWaterFilterP = this.f.RawWaterFiltersP.value;
    // wpdailyCreate.rawWaterFilterQ = this.f.RawWaterFiltersQ.value;
    // wpdailyCreate.temparatureBlendingValve = this.f.BlendingValve.value;
    // wpdailyCreate.softenerCapacityRemaining = this.f.CapacityRemaining.value;
    // wpdailyCreate.totalHardness = this.f.TotalHardness.value;
    // wpdailyCreate.dpdtestprecarbonTotalChlorine = this.f.DPDTestPreTotalChlorine.value;
    // wpdailyCreate.dpdtestprecarbonFreeChlorine = this.f.DPDTestPreFreeChlorine.value;
    // wpdailyCreate.dpdtestpostcarbonTotalChlorine = this.f.DPDTestPostTotalChlorine.value;
    // wpdailyCreate.dpdtestpostcarbonFreeChlorine = this.f.DPDTestPostFreeChlorine.value;

    // wpdailyCreate.thorntonmetermorning = this.f.ThorntonMeterMorning.value;
    // wpdailyCreate.ditanksdi = this.f.DIRinsed.value;
    // wpdailyCreate.secondshifttotalchlorine = this.f.SecondShiftTotalChlorineTextBox.value;
    // wpdailyCreate.secondshiftfreechlorine = this.f.SecondShiftFreeChlorineTextBox.value;
    // wpdailyCreate.thorntonmeterafternoon = this.f.ThorntonMeterAfternoonTextBox.value;
    // // wpdailyCreate.eventId = this.eventId;
    // wpdailyCreate.createdBy = this.currentUserId;
    // wpdailyCreate.WorkerQuality = this.f.WorkingQuality.value;
    // wpdailyCreate.PolisherQuality = this.f.PolisherQuality.value;
    // wpdailyCreate.EnableDIBackup = this.f.EnableDIBackupMode.value;
    // if (this.f.FieldServiceDetailsId.value === '0') {
    //   wpdailyCreate.morningDetailsEnteredBy = this.currentUserId;
    //   wpdailyCreate.morningDetailsEnteredOn = new Date();
    // } else {
    //   wpdailyCreate.modifiedBy = this.currentUserId;
    //   wpdailyCreate.modifiedOn = new Date();

    //   wpdailyCreate.morningDetailsEnteredBy = this.morningDetailsEnteredBy;
    //   wpdailyCreate.morningDetailsEnteredOn = this.FirstShiftDate;
    //   if (
    //     wpdailyCreate.secondshifttotalchlorine != '0' &&
    //     wpdailyCreate.thorntonmeterafternoon != '0'
    //   ) {
    //     wpdailyCreate.noonDetailsEnteredBy = this.currentUserId;
    //     wpdailyCreate.noonDetailsEnteredOn = new Date();
    //   }
    //   if (this.Status == 'Completed') {
    //     wpdailyCreate.noonDetailsEnteredBy = this.noonDetailsEnteredBy;
    //     wpdailyCreate.noonDetailsEnteredOn = this.SecondShiftDate;
    //   }
    // }
    // wpdailyCreate.notes = this.notes.Note;

    // //attachments
    // wpdailyCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    // wpdailyCreate.dailyRecordAcidLevelDetail = this.acidLevel.GetAcidLevel();

    // //sphered new form
    // this.SetSpherdForm();
    // if (this.IsShepardForm) {
    //   wpdailyCreate.roprefilterP6 = this.f.P6_PSI.value;
    //   wpdailyCreate.roprefilterP7 = this.f.P7_PSI.value;
    //   wpdailyCreate.roprefilterP8 = this.f.P8_PSI.value;
    //   wpdailyCreate.roprefilterP9 = this.f.P9_PSI.value;
    //   wpdailyCreate.roprefilterP12 = this.f.P12_PSI.value;
    //   wpdailyCreate.roprefilterP13 = this.f.P13_PSI.value;
    //   wpdailyCreate.roprefilterP14 = this.f.P14_PSI.value;
    //   wpdailyCreate.roprefilterP15 = this.f.P15_PSI.value;
    //   wpdailyCreate.ropreinlet = this.f.PRE_FILTER_INLET_P13600_PSI.value;
    //   wpdailyCreate.ropreoutP13605 = this.f.PRE_FILTER_OUT_P13605_PSI.value;
    //   wpdailyCreate.rofeedConductivityA13635 = this.f.FEED_CONDUCTIVTY_A13635.value;
    //   wpdailyCreate.rorejection = this.f.SP_Rejection.value;
    //   wpdailyCreate.ropump1clock = this.f.PUMP_1_CLOCK.value;
    //   wpdailyCreate.ropump2clock = this.f.PUMP_2_CLOCK.value;
    //   wpdailyCreate.ro1stpassprodcutQltA13665 = this.f._1ST_PASS_PRODUCT_QUALITY_A13665.value;
    //   wpdailyCreate.ro2ndpassprodcutflowA13710 = this.f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.value;
    //   wpdailyCreate.ro2ndpassprodcutQltA13695 = this.f._2ND_PASS_PRODUCT_QUALITY_A13665.value;
    //   wpdailyCreate.roloopfilterP13715 = this.f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.value;
    //   wpdailyCreate.roloopreturnflowF13620 = this.f.LOOP_RETURN_FLOW_F13620_GPM.value;
    // } else {
    //   wpdailyCreate.roprefilterW = this.f.ROPreW.value;
    //   wpdailyCreate.roprefilterX = this.f.ROPreX.value;
    //   wpdailyCreate.roclock = this.f.ROClock.value;
    //   wpdailyCreate.feedconductivity = this.f.ROFeedConductivity.value;
    //   wpdailyCreate.permeateconductivity = this.f.ROPermeateConductivity.value;
    //   wpdailyCreate.rorejection = this.f.RORejection.value;
    //   wpdailyCreate.permeateflow = this.f.ROPermeateFlow.value;
    //   wpdailyCreate.prefilterin = this.f.ROPrefilterIn.value;
    //   wpdailyCreate.roprefilterout = this.f.ROPrefilterOut.value;
    //   wpdailyCreate.pumppressure = this.f.ROPumpPressure.value;
    //   wpdailyCreate.ringpressure = this.f.RORingPressure.value;
    // }
    //  //  console.log(wpdailyCreate);
    // return wpdailyCreate;
  }

  

  
  // checkRange(control, MinValue, MaxValue) {
  //   if (control.value != '' && control.value != null) {
  //     //console.log(this.FieldService);
  //     return !(control.value >= MinValue && control.value <= MaxValue);
  //   }
  // }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        var wpDailyObj = { Id: this.f.FieldServiceDetailsId.value };
        // this.wpapi.DeleteWaterProcessDailyRecord(wpDailyObj).subscribe(
        //   list => {
        //     AppComponent.HideLoader();
        //     AppComponent.addAlert(
        //       'Water process daily record deleted successfully!',
        //       'success'
        //     );
        //     this.router.navigate(['WaterProcess/Daily/WPDailySearch']);
        //   },
        //   error => {
        //     AppComponent.addAlert(error.message, 'error');
        //     AppComponent.HideLoader();
        //   }
        // );
      }
      this.dialogRef = null;
    });
  }

  CheckSecondShiftCompleted() {
    return (
      this.Status != 'Completed' &&
      new Date(this.createdOn).setHours(0, 0, 0, 0) ==
        new Date().setHours(0, 0, 0, 0)
    );
  }
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };
  GreaterValueCheck() {
    
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PMNoteDialog } from '../../PMNotesModel/PMNoteDialog';
import { PMPartsComponent } from '../PMPartsControl/PMPartsComponent';
import { PMNotesModel, PMComponentModel, PMPartsModel } from './PMNotesModel';

@Component({
  selector: 'pmnotes',
  templateUrl: './PMNotesComponent.html'
})
export class PMNotesComponent {
  noteDialogRef: MatDialogRef<PMNoteDialog>;

  public NoteList = <any>[];
  public componentDetails: PMComponentModel;
  public notesDetails: PMNotesModel;
  public partsDetails: PMPartsModel;

  @Output() addNoteClick = new EventEmitter<boolean>();
  @Output() openNoteClick = new EventEmitter<boolean>();
  @Output() CheckValidForm = new EventEmitter<boolean>();
  @Input() isValidForm: boolean = false;

  constructor(public dialog: MatDialog) {
    this.notesDetails = new PMNotesModel();
    this.partsDetails = new PMPartsModel();
    this.componentDetails = new PMComponentModel();
  }


  ngOnInit() {
  }

  // Notes

  onNoteSave($event) {

    if (this.notesDetails.description)
      this.NoteList.push(this.notesDetails);

    if (this.componentDetails.showParts) {
      var parts = this.noteDialogRef.componentInstance.NoteParts.PartTrans.concat();

      var fetchQParts = this.componentDetails.AllParts.filter(x => x.questionId == this.notesDetails.questionId && x.questionId != '');
      if (fetchQParts) {
        for (let childObj in fetchQParts) {
          this.componentDetails.AllParts.splice(this.componentDetails.AllParts.indexOf(fetchQParts[childObj]), 1);
        }
      }

      this.componentDetails.AllParts = this.componentDetails.AllParts.concat(parts);
    }
    this.addNoteClick.emit();
  }

  openNotes(){
    this.openNoteClick.emit();
  }

  public AddNote() {

    this.CheckValidForm.emit();
    setTimeout(() => {
      if (this.isValidForm === true) {
        this.addNoteClick.emit();
        return;
      }

      this.noteDialogRef = this.dialog.open(PMNoteDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        width: '90%',
      });

      this.noteDialogRef.componentInstance.notesDetails = this.notesDetails;
      this.noteDialogRef.componentInstance.componentDetails = this.componentDetails;
      this.noteDialogRef.componentInstance.SaveEvent.subscribe(evt =>
        this.onNoteSave(evt)
      );

      if (this.componentDetails.showParts === false) {
        // this.noteDialogRef.componentInstance.dialogRef.updateSize('700px', 'auto');
      } else {
        // this.noteDialogRef.componentInstance.dialogRef.updateSize('90%', 'auto');

        this.noteDialogRef.componentInstance.partsDetails = this.partsDetails;
        this.noteDialogRef.componentInstance.componentDetails = this.componentDetails;
      }

      this.noteDialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
        this.noteDialogRef = null;
      });
    }, 500);
  }
}


<div class="modal-header">
  <h4 class="modal-title">Quick Add {{quickAddModel.title}}</h4>
  <!-- <button  type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button> -->

  <div class="text-right  col-sm-4" style="padding:0;float:right">
    <button type="button" class="btn btn-danger" style="margin-right:5px"
      (click)="dialogRef.close(false)">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</div>
<div class="modal-body">
  <div class="col-sm-12 box-row">
    <label class='col-sm-4 control-label' style="font-weight: 100;padding-right: 10px;">Name:</label>
    <div class='col-sm-5' >
      <textarea name="name" autosize maxlength="80" [(ngModel)]="quickAddModel.name" class="form-textarea"></textarea>
    <span class="error" *ngIf="quickAddModel.errormsg">{{quickAddModel.errormsg}}</span>
    </div>

  </div>
  <div class="col-sm-12 box-row" *ngIf="quickAddModel.title=='Vendor'">
    <label class='col-sm-4 control-label' style="font-weight: 100;padding-right: 10px;">Work Phone:</label>
    <div class='col-sm-5' >
      <input  name='phone' placeholder="(###) ###-####" (blur)="formatPhone('phone')" [(ngModel)]="quickAddModel.phone" maxlength='20' class="form-control"  (keypress)="numberOnly($event)"
      >
     <span class="error" *ngIf="quickAddModel.errormsg1">{{quickAddModel.errormsg1}}</span>
    </div>
  </div>

<div class="clearfix"></div>
  <div *ngIf="quickAddModel.title=='Vendor'" class="box-body box ibox box-info" style="padding:0px;">
    <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="dmMailingAddress">
            <h4 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmMailingAdd"
                    aria-expanded="false" aria-controls="dmMailingAdd">
                    <div class="box-header with-border" style="padding: 0px;">
                        <h3 class="box-title">Mailing Address
                          <span><i class="fa fa-plus-square faplusicon"></i></span>
                        </h3>
                    </div>
                  </a>
            </h4>
        </div>

    <div id="dmMailingAdd"  class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmMailingAddress">
    <div class="panel-body">
        <div class="col-sm-12 box-row">
          <label class='col-sm-4 control-label' style="font-weight: 100;">Country:</label>
          <div class='col-sm-8' >
              <select  name='mailingcountry' maxlength='dropdown'  [(ngModel)]="quickAddModel.mailingcountry"
               (change)="CountryStateDropDownChanged()" class="form-control">
              <option  value="" >Select</option>
              <option  value="{{item.text}}" *ngFor="let item of CountryList">{{item.text}}</option>
              </select>
          </div>
        </div>

        <div class="col-sm-12 box-row">
          <label class='col-sm-4 control-label' style="font-weight: 100;">Province/State:</label>
            <div class='col-sm-8' >
                <select  name='mailingstate' (blur)="UpdateBillingAddress()" maxlength='dropdown'
                     [(ngModel)]="quickAddModel.mailingstate" class="form-control">
                    <option  value="" >Select</option>
                    <option  value="{{item.text}}" *ngFor="let item of StateList">{{item.text}}</option>
                </select>
            </div>
        </div>

        <div class="col-sm-12 box-row">
          <label class='col-sm-4 control-label' style="font-weight: 100;">City:</label>
          <div class='col-sm-8' >
              <input  name='mailingcity' (blur)="UpdateBillingAddress()" maxlength='30'  style="text-transform: capitalize;"
                   [(ngModel)]="quickAddModel.mailingcity" class="form-control">
          </div>
        </div>

        <div class="col-sm-12 box-row">
            <label class='col-sm-4 control-label' style="font-weight: 100;">Address:</label>
            <div class='col-sm-8' >
                <textarea  name='mailingaddress' (blur)="UpdateBillingAddress()"  class="form-textarea" autosize
                     [(ngModel)]="quickAddModel.mailingaddress"></textarea>
            </div>
        </div>


        <div class="col-sm-12 box-row">
            <label class='col-sm-4 control-label' style="font-weight: 100;padding-right: 10px;">Postal/Zip Code:</label>
            <div class='col-sm-8' >
                <input  name='mailingzipCode' maxlength='10'  class="form-control"
                     (blur)="UpdateBillingAddress()" [(ngModel)]="quickAddModel.mailingzipCode" >
            </div>
        </div>
        <div class="clearfix"></div>

    </div>
    </div>
</div>
</div>

  <div *ngIf="quickAddModel.title=='ItemGroup'">
    <mat-checkbox [(ngModel)]="quickAddModel.hrsTracking" name="hrsTracking">
      Hrs Tracking Required
    </mat-checkbox>
  </div>

  <div *ngIf="quickAddModel.title=='Manufacturer'">
      <div class="col-sm-12 box-row">
          <label class='col-sm-4 control-label' style="font-weight: 100;padding-right: 10px;">Category:</label>
          <div class='col-sm-8' >
              <ng-multiselect-dropdown style="background-color:aliceblue;"
              [placeholder]="'Select Category'" [data]="CategoryList"
              [(ngModel)]='quickAddModel.Category'
              [settings]="dropdownSettings" name="Category">
          </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="col-sm-offset-4 col-sm-8 box-row">
        <span class="error" *ngIf="quickAddModel.errormsg1">{{quickAddModel.errormsg1}}</span>
      </div>
      </div>
</div>


<!-- <div class="modal-footer">

    </div> -->

<div class="box" style="margin-bottom: 0px">


        <iframe  [src]='sanitizer.bypassSecurityTrustResourceUrl(globals.url)'  width="100%" height="1000px" style="border:none"></iframe>





</div>

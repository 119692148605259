import { Component, OnInit, ViewChild } from "@angular/core";
import { DMApiService } from "../../API/dmapi.service";
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgForm } from "@angular/forms";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationDialog } from "../../Core/ConfirmationDialog";

import { AttachmentComponent } from "../../Core/Shared/AttachmentComponent/AttachmentComponent";
import { CanComponentDeactivate } from "../../Core/Shared/CanComponentDeactivate";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ScrollHelper } from "../../Core/Helper/ScrollHelper";
import { DMRepairCustomValidators } from "src/app/Validator/EuipmentRepairStatus";
import { CommonCustomValidators } from "src/app/Validator/CommonValidator";

import { Globals, actionType, attachmentFormModules } from "../../globals";
import { AppComponent } from "../../app.component";
import { AuthService } from "../../API/auth.service";
import { HomeService } from 'src/app/API/home.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "DMHourDetail",
  templateUrl: "./DMHourDetail.component.html"
})
export class DMHourDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  private today = new Date();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  userDesignation = this.auth.GetDesignationName();
  isValidFormSubmitted = null;
  ListURL = "DialysisMachine/DMHoursUpdateSearch";
  status = "";
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  EventCode = "[AutoNumber]";
  selectedEquipmenttext;
  selectedUsertext;
  hourStartDateLabel;
  selectedEquipmentHours = 0;
  DMHourForm = new UntypedFormGroup({
    DMHoursId: new UntypedFormControl(""),
    CreatedDate: new UntypedFormControl("", Validators.required),
    HourStartDate: new UntypedFormControl("", Validators.required),
    Hours: new UntypedFormControl("", [
      Validators.required,
      Validators.pattern(Globals.NumberNumericRegexWithoutZero)
    ]),
    selectedEquipment: new UntypedFormControl("", Validators.required),
    selectedDialysisUnits: new UntypedFormControl("", Validators.required)
  });





  Equipments = [];

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.DMHourForm.dirty || this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        "You have unsaved changes! If you leave, your changes will be lost.";
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private homeApi: HomeService,
    private auth: AuthService,
    private commonapi: CommonApiService
  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    // attachment changes
    this.attachment.DocId = this.f.DMHoursId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Hours];
    this.attachment.UploadBy = this.currentUserId;
    this.fillDialysisUnit();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.f.DMHoursId.setValue(params["id"] || "0");

        if (this.f.DMHoursId.value !== "0") {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {
            this.setValidators();
            this.selectedUsertext = this.auth.GetUserFLName();

            const tempDate = this.today;
            this.f.CreatedDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            const tempHourStartDate = this.today;
            this.f.HourStartDate.setValue({
              date: {
                year: tempHourStartDate.getFullYear(),
                month: tempHourStartDate.getMonth() + 1,
                day: tempHourStartDate.getDate()
              },
              jsdate: tempHourStartDate
            });

            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillEquipment(division[0]);
            }
            else {
              this.fillEquipment(0);
            }

            if (this.f.DMHoursId.value == 0) {

              this.f.selectedEquipment.setValidators([Validators.required]);
              this.f.selectedEquipment.setAsyncValidators([
                DMRepairCustomValidators.EquipmentRepairStatusValidate(
                  this.f.DMHoursId,
                  this.dmapi
                )
              ]);
              this.f.selectedEquipment.updateValueAndValidity();
            }
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        }
      });
      this.SetDMHourights();

      this.setDateOptions();
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: "dd/mm/yyyy",
    closeSelectorOnDateSelect: false,
    disableSince: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() + 1
    }
  };

  setDateOptions() {
    this.myOptions = {
      // other options...
      dateFormat: "dd/mm/yyyy",
      closeSelectorOnDateSelect: false,
      disableSince: {
        year: this.today.getFullYear(),
        month: this.today.getMonth() + 1,
        day: this.today.getDate() + 1
      }
    };
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.DMHourForm.controls;
  }

  fillEquipment(divisionID) {
    if (divisionID != "0") {

      this.selectedDialysisUnitstext = this.DialysisUnits.filter(
        x => x.value === divisionID
      );

      if (this.selectedDialysisUnitstext.length > 0 && this.selectedDialysisUnitstext[0].text == 'Home Program') {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({ text: 'Select', value: '' });
          this.Equipments = list;
        });
      }
      else {
        this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
          this.Equipments = list;
        });
      }
    } else {
      this.Equipments = [{ value: "", text: "Select" }];
      this.f.selectedEquipment.setValue("");
    }
  }

  setModel() {
    AppComponent.ShowLoader();
    const DMHourCreate = { Id: this.f.DMHoursId.value };
    this.attachment.clearQueue();
    this.dmapi.GetDMHoursDetailsByID(DMHourCreate).subscribe(
      success => {
        //console.log(success);

        this.EventCode = success.EventCode;

        this.hourStartDateLabel = success.dmhoursupdatedate;

        const tempHoursDate = new Date(success.dmhoursupdatedate);
        this.f.HourStartDate.setValue({
          date: {
            year: tempHoursDate.getFullYear(),
            month: tempHoursDate.getMonth() + 1,
            day: tempHoursDate.getDate()
          },
          jsdate: success.dmhoursupdatedate
        });

        const tempDate = new Date(success.createdon);

        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdon
        });

        this.EventCode = success.eventcode;
        this.selectedUsertext = success.createdby;

        this.selectedUsertext = success.createByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.divisionid.toString());
        this.selectedDialysisUnitstext = success.divisionName;
        this.f.selectedEquipment.setValue(success.equipmentid.toString());
        this.f.Hours.setValue(success.dialysismachinehours);
        this.selectedEquipmenttext = success.equipmentname;
        const DMHourObj = { Id: this.f.DMHoursId.value };
        this.dmapi
          .GetDMHoursAttachmentDetailsbydmHoursUpdateId(DMHourObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void { }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DialysisUnits = list.filter(
      //   x => x.text != "Home Program"
      // );
      this.commonapi.FillDivision().subscribe(list => {
      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }

      this.DialysisUnits = list
    });
  }

  executeDMHourCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.DMHourForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst("ng-invalid");
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const DMHourCreate = this.prepareCreateViewModel();
    if (DMHourCreate.dmHoursUpdateId === "0") {
      this.dmapi.CreateDMHoursDetails(DMHourCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;

          AppComponent.loading = false;
          AppComponent.addAlert("Data Saved successfully!", "success");
          this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    } else {
      this.dmapi.UpdateDMHoursDetails(DMHourCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert("Data Saved successfully!", "success");
          this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const DMHourCreate = <any>{};

    DMHourCreate.dmHoursUpdateId = this.f.DMHoursId.value;
    if (
      this.f.HourStartDate.value !== null &&
      this.f.HourStartDate.value !== undefined
    ) {
      DMHourCreate.dmhoursupdateDate = Globals.GetAPIDate(
        this.f.HourStartDate.value.jsdate
      );
    }
    DMHourCreate.eventCode = this.EventCode;
    DMHourCreate.createdon = Globals.GetAPIDate(this.today);
    DMHourCreate.createdby = this.currentUserId;
    DMHourCreate.modifiedon = Globals.GetAPIDate(this.today);
    DMHourCreate.modifiedby = this.currentUserId;
    // DMHourCreate.dmhoursupdatedate = new Date();
    DMHourCreate.dialysismachinehours = this.f.Hours.value;
    DMHourCreate.divisionid = this.f.selectedDialysisUnits.value;
    DMHourCreate.equipmentid = this.f.selectedEquipment.value;
    // DMHourCreate.note = this.notes.Note;
    // attachments
    DMHourCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    return DMHourCreate;
  }

  DialysisUnitDropDownChanged() {
    this.fillEquipment(this.f.selectedDialysisUnits.value);
    if (this.f.DMHoursId.value == 0) {
      this.f.selectedEquipment.setValue('');
      this.f.Hours.setValue('');
      this.selectedEquipmentHours = 0;
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const DMHourObj = { Id: this.f.DMHoursId.value };
        this.dmapi.DeleteDMHoursDetailsBydmHoursUpdateId(DMHourObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              "Paging Report detail deleted successfully!",
              "success"
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, "error");
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetDMHourights() {
    this.auth.GetPageRights("Hours Update").subscribe(list => {
      if (this.f.DMHoursId.value !== "0") {
        this.blnshowSave = list[actionType.edit] === "1";
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === "1";
        // this.blnshowDelete = false;
      }
    });

    if (
      this.userDesignation == "Administrator" &&
      this.f.DMHoursId.value != "0"
    ) {
      this.blnshowDelete = true;
    }
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, "error");
  }

  setValidators() {
    this.f.Hours.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.selectedEquipment,
        this.f.DMHoursId,
        this.dmapi
      )
    ]);
    this.f.Hours.updateValueAndValidity();
  }

  onEquipmentChanged() {

    this.f.Hours.setValue('');
    var equipmentId = { Id: this.f.selectedEquipment.value };
    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {

      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(): boolean {
    if (this.f.Hours.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }
}

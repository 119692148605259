import { Component, ViewChild, Input } from '@angular/core';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { DMApiService } from 'src/app/API/dmapi.service';
import { TableHeadsService } from '../../tableheads.service';
import { PageHeaderComponent } from '../../pageheader.component';
import { Page } from '../Common/PageModel';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { WPApiService } from 'src/app/API/wpapi.service';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'HistoryUploadSearch',
  templateUrl: 'HistoryUploadSearch.component.html',

})
export class HistoryUploadSearchComponent {


//todo
@Input() detailsType  ="Water Process"
@Input() headerTextWP = "Water Process Upload History Details";
@Input() headerTextOther = "Other History Upload";
@Input() AddURL = "WaterProcess/WPHistoryUploadDetail";


  constructor(private wpapi: WPApiService, private dmapi: DMApiService, private router: Router, private dataGridService: TableHeadsService,
    private auth: AuthService,
    private commonapi: CommonApiService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];
    Equipments=[];
  Users=[];
  Parts=[];
  Statuses=[];
  Patients=[];
  DMRepailDetail =[];
  selectedDivision = "All Divisions";
  selectedEquipment = "";

  //selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  RepairId="";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef="";
  OutFromDate;
OutToDate;
tableHeaders;
page = new Page();
rows ;

workOrderRefNo="";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch=false;

  searchText="Search Records"
defaultSort;

  ngOnInit() {
    //AppComponent.loading=true;
    this.auth.GetCurrentUserDivision().subscribe(division => {

      this.dataGridService.clearHeader();

      this.dataGridService.addHeader("#", "repairid", "4");
      this.dataGridService.addHeader("Code", "repairDetailId", "10");
      this.dataGridService.addHeader("Division", "divisionname", "10");
      this.dataGridService.addHeader("Date IN", "timeStarted", "10");
      this.dataGridService.addHeader("Date Out", "timefinished", "10");
      this.dataGridService.addHeader("Tech", "createdbyName", "10");
      this.dataGridService.addHeader("Equipment", "equipmentName", "20");
      this.dataGridService.addHeader("Status", "status", "10%");
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort("default", true);
      //console.log(this.tableHeaders);
      // this.setPage({ offset: 0 });
      // this.executeDmrepairSearch();
      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else{
        this.fillDialysisUnit();
        this.fillEquipment();
      }
      this.fillUser();
      // this.fillEquipment();
      this.fillParts();
      this.fillStatus();
      this.fillPatients();
      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
    }

  onSearchShowChange(value)
  {
    this.ShowSearch=value;
  }

changeSorting(column){
  console.log(column);
  if (column =="#")
  {
      return;
  }
  //console.log(this.tableHeaders);
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDmrepairSearch();

};

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDmrepairSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();
    var repairsearch = this.prepareSearch();
    this.wpapi.GetWaterProcessRepairsRecords(repairsearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      console.log(pagedData);
      this.rows = pagedData.repailDetails;
      AppComponent.HideLoader()
    }
      ,
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {

    var dmrepairsearch = <any>{};
    dmrepairsearch.RepairId = this.RepairId;
    dmrepairsearch.RepairType = this.detailsType;
    dmrepairsearch.isUploadHistory = true;
    if (this.FromDate != undefined && this.FromDate != "") {
      dmrepairsearch.FromDate = Globals.GetAPIDate(this.FromDate.jsdate);
    }
    if (this.ToDate != undefined && this.ToDate != "") {
      dmrepairsearch.ToDate = Globals.GetAPIDate(this.ToDate.jsdate);
    }


  if (this.selectedDivision != undefined && this.selectedDivision !="" && this.selectedDivision !="0" && this.selectedDivision !="All Divisions" ) {
      dmrepairsearch.Division = this.selectedDivision;
  }


  if (this.selectedEquipment != undefined && this.selectedEquipment !="") {
      dmrepairsearch.EquipmentId = this.selectedEquipment;
  }


  if (this.selectedUser != undefined && this.selectedUser != "") {
      dmrepairsearch.createdBy = this.selectedUser;
  }



  if (this.selectedStatus != undefined && this.selectedStatus != "") {
      dmrepairsearch.Status = this.selectedStatus;
  }

  dmrepairsearch.currentPageNumber = this.currentPageNumber;
  dmrepairsearch.sortExpression = this.sortExpression;
  dmrepairsearch.sortDirection = this.sortDirection;
  dmrepairsearch.pageSize = this.pageSize;
  // console.log(dmrepairsearch);
  return dmrepairsearch;

}

  setPage(){
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber=this.page.pageNumber;
    //this.page.pageNumber = pageInfo.offset;
    console.log('set page')
    this.executeDmrepairSearch();
    }
}
 _onRowClick(row,DetailTypes) {

      // console.log(row);
      // console.log(DetailTypes);

      if(DetailTypes == "Water Process")
      {
        this.router.navigate(['WaterProcess/Repairs/RepairDetail'], { queryParams: { id: row , detailType : DetailTypes} });
      }
      else if(DetailTypes == "Other")
      {
        this.router.navigate(['ORepairs/Repairs/RepairDetail'], { queryParams: { id: row , detailType : DetailTypes} });
      }
      else if(DetailTypes == "DM")
      {
        //this.router.navigate(['DialysisMachine/DMRepairDetail'], { queryParams: { id: row , detailType : DetailTypes} });
      }
      else if(DetailTypes == "PM")
      {
        //     var href = '';
        //   this.dmapi.PrintPreventivePDF({ Id: row }).subscribe(list => {
        //     href = Globals.AttachmentUrl + list;
        //     window.open(href,'_blank');
        // }, error => {
        //     console.log(error);
        // })
      }

}

public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect:false,
  disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
};



onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit(defaultValue='') {
    this.commonapi.FillDivision(true).subscribe(list => {

      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        // this.DialysisUnits = list.filter(
        //   x => x.text != "Home Program"
        // );
        this.DialysisUnits = list;
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          this.fillEquipment();
          this.executeDmrepairSearch();
        }
        else
          this.executeDmrepairSearch();
      }
      else
        this.executeDmrepairSearch();
    });
  }

  fillEquipment() {
    let divisionId;
    let division = this.DialysisUnits.filter(
      x => x.text == this.selectedDivision
    );

    if (division && division.length > 0 && division[0]) {
      divisionId = division[0].value;
    }

    const value = {
      DivisionId: divisionId,
      CategoryName: this.detailsType,
      Type: 'EQUIPMENT'
    };
    this.wpapi.GetEquipmentByCategoryDD(value).subscribe(list => this.Equipments=list);
  }

  fillUser()
  {
    this.dmapi.GetUsersDD().subscribe(list => this.Users=list);

  }

  fillParts()
  {
    this.dmapi.GetPartsDD().subscribe(list => this.Parts=list);
  }

  fillStatus()
  {
    //this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.Statuses= [
      { value: "", text: "Select" },
      // { value: "Not Started", text: "Not Started" },
      // { value: "Open", text: "Open" },
      { value: "Completed", text: "Completed" }
    ];

  }

  fillPatients()
  {
    this.dmapi.GetPatientsDD().subscribe(list => this.Patients=list);

  }

  executeDmrepairSearchClear()
  {
    this.selectedDivision = "All Divisions";
    this.selectedEquipment = "";
    this.selectedStatus = "";
    this.selectedUser = "";
    this.RepairId = "";
    this.FromDate = "";
    this.ToDate = "";

    // this.executeDmrepairSearch();
    this.fillDialysisUnit();
    this.fillEquipment();
  }

  CheckDirtySearch()
  {
    this.blnDirtySearch= false;

    if(
      (this.selectedDivision != "" && this.selectedDivision != 'All Divisions')
    || this.selectedEquipment  != ""
    || this.selectedStatus != ""
    || this.selectedUser != ""
    || this.RepairId != ""
    || (this.FromDate != null && this.FromDate != "")
    || (this.ToDate != null && this.ToDate != "")
    )
    {
      this.blnDirtySearch = true;

    }
    // console.log(this.blnDirtySearch);
    return this.blnDirtySearch;



    // if(this.FromDate != null && this.FromDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }
    // if(this.ToDate != null && this.ToDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }

  }

  DialysisUnitDropDownChanged() {
    this.selectedEquipment = "";
    this.fillEquipment();
    setTimeout(()=> this.executeDmrepairSearch());
  }
}

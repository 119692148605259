import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class ScheduleColorApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetScheduleColorSerachDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/GetScheduleColorSerachDetails',
      value,
      httpOptions
    );
  }

  GetScheduleColor(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/GetScheduleColor',
      value,
      httpOptions
    );
  }

 
  CreateScheduleColor(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/CreateScheduleColor',
      value,
      httpOptions
    );
  }

  UpdateScheduleColor(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/UpdateScheduleColor',
      value,
      httpOptions
    );
  }

  DeleteScheduleColor(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/DeleteScheduleColor',
      value,
      httpOptions
    );
  }

  GetScheduleColorNotes(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/GetScheduleColorNotes',
      value,
      httpOptions
    );
  }

  GetScheduleColorAttachments(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleColorService/GetScheduleColorAttachments',
      value,
      httpOptions
    );
  }
}

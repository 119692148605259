<section class="content-header">
    <h1>
        {{title}}
    </h1>
</section>
<section class="content">
    <section>

        <div class="row">
            <div class="box  box-primary">
                <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
                    [showSave]="blnshowSave" #pageheader></PageHeader>

                <!-- <div id="divSearch row" *ngIf="ShowSearch">

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>



                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Category:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="Category"
                                                    (change)="fillModelList()">
                                                    <option value="{{item.value}}" *ngFor="let item of CategoryList">
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Model:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="Model">
                                                    <option value="">Select</option>
                                                    <option value="{{item.value}}" *ngFor="let item of ModelList">
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="box-body row">
                                <div class="col-sm-12">
                                    <div class="col text-center">
                                        <button class="btn btn-primary" id="btnSearchDetail"
                                            (click)="executeFieldMasterSearch()">
                                            <span>
                                                <i class="fa fa-search"></i>
                                            </span>
                                            <b>Search</b>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div> -->

                <div class="clearfix"></div>
                <div class="box">
                    <form [formGroup]="FieldMasterForm" (ngSubmit)="executeFieldMasterCreateUpdate('')" novalidate>
                        <div class="box box-body ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="fieldmasterAdds" tooltip-class="tooltip-dark">
                                    <h4 class="panel-title">
                                        <a data-toggle="collapse" data-parent="#accordion" href="#fieldmasterAdd"
                                            aria-expanded="true" aria-controls="fieldmasterAddRef"
                                            id="fieldmasterAddRefID">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <div class="col-sm-4">
                                                    <h3 class="box-title">Add/Edit Field Master Details </h3>
                                                </div>
                                                <div class="col-sm-4">
                                                    <b>Category</b>: {{CategoryName}}
                                                </div>
                                                <div class="col-sm-4">
                                                    <b>Equipment Device Name</b>: {{ModelName}}
                                                </div>
                                                <!-- <span
                                                    *ngIf="status != 'Completed'"
                                                    style="font-size: 14px;color: red;margin-left: 15px;"> (Click here
                                                    for filling the details to Complete Repair) </span> -->

                                            </div>
                                        </a>
                                    </h4>
                                </div>

                                <div id="fieldmasterAdd" class="panel-collapse collapse show" role="tabpanel"
                                    aria-labelledby="fieldmasterAdds">
                                    <div class="box-body">
                                        <div class="row ">
                                            <div class="col-sm-12 box-row">
                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">Equipment ID:
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <!-- <select name='EquipmentId' class='form-control' formControlName="EquipmentId">
                                                            <option  value="{{item.text}}" *ngFor="let item of Equipments">{{item.text}}</option>
                                                        </select> -->

                                                        <ng-multiselect-dropdown style="background-color:aliceblue;"
                                                            [placeholder]="'Select'" [data]="Equipments"
                                                            formControlName='EquipmentId' [settings]="dropdownSettings"
                                                            (onSelect)="onItemSelect($event)"
                                                            (onSelectAll)="onSelectAll($event)" name="EquipmentId">
                                                        </ng-multiselect-dropdown>
                                                        <span
                                                            *ngIf="f.EquipmentId.errors?.required && ((f.EquipmentId.dirty || f.EquipmentId.touched) ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">Device Field Name
                                                        ID:</label>
                                                    <div class="col-sm-6">
                                                        <input name='FieldName' maxlength='50' class='form-control'
                                                            formControlName="FieldName">
                                                            <span
                                                            *ngIf="f.FieldName.errors?.required && (f.FieldName.dirty || f.FieldName.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">BMR Reference
                                                        ID:</label>
                                                    <div class="col-sm-6">
                                                        <input name='MRMFieldName' maxlength='50' class='form-control'
                                                            formControlName="MRMFieldName">
                                                            <span
                                                            *ngIf="f.MRMFieldName.errors?.required && (f.MRMFieldName.dirty || f.MRMFieldName.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 box-row">
                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">Unit of Measurement:
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <select name='Unit' class='form-control' formControlName="Unit">
                                                            <option value="">Select</option>
                                                            <option value="psi">psi</option>
                                                            <option value="°C">°C</option>
                                                            <option value="mg/L">mg/L</option>
                                                            <option value="gpg">gpg</option>
                                                            <option value="µS/cm">µS/cm</option>
                                                        </select>
                                                        <span
                                                            *ngIf="f.Unit.errors?.required && (f.Unit.dirty || f.Unit.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">Min. Value:</label>
                                                    <div class="col-sm-6">
                                                        <input name='MinVal' maxlength='10' class='form-control'
                                                            formControlName="MinVal" (keypress)="numberOnly($event)">
                                                        <span
                                                            *ngIf="f.MinVal.errors?.required && (f.MinVal.dirty || f.MinVal.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <label class="col-sm-6 control-label">Max. Value:</label>
                                                    <div class="col-sm-6">
                                                        <input name='MaxVal' maxlength='10' class='form-control'
                                                            formControlName="MaxVal" (keypress)="numberOnly($event)">
                                                        <span
                                                            *ngIf="f.MaxVal.errors?.required && (f.MaxVal.dirty || f.MaxVal.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 box-row">
                                                <div class="col-sm-6">
                                                    <label class="col-sm-8 control-label">Frequency of Range
                                                        Error
                                                        Occurrence. (#/day):</label>
                                                    <div class="col-sm-3">
                                                        <input name='Frequency' maxlength='10' class='form-control'
                                                            formControlName="Frequency" (keypress)="numberOnly($event)">
                                                        <span
                                                            *ngIf="f.Frequency.errors?.required && (f.Frequency.dirty || f.Frequency.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label class="col-sm-8 control-label">Notification Required
                                                        of
                                                        each Range Error?:</label>
                                                    <div class="col-sm-3">
                                                        <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                            [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                            (onChangeState)="onChange($event)"
                                                            name='NotificationRangeError'
                                                            formControlName="NotificationRangeError">
                                                        </bSwitch>
                                                        <span
                                                            *ngIf="f.NotificationRangeError.errors?.required && (f.NotificationRangeError.dirty || f.NotificationRangeError.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 box-row">
                                                <div class="col-sm-6">
                                                    <label class="col-sm-10 control-label">Notification Required
                                                        of
                                                        each Range Return to Normal Range?:</label>
                                                    <div class="col-sm-2">
                                                        <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                            [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                            (onChangeState)="onChange($event)"
                                                            name='NotificationRangeToNormal'
                                                            formControlName="NotificationRangeToNormal">
                                                        </bSwitch>
                                                        <span
                                                            *ngIf="f.NotificationRangeToNormal.errors?.required && (f.NotificationRangeToNormal.dirty || f.NotificationRangeToNormal.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label class="col-sm-8 control-label">Notification Required
                                                        Range Error Frequency?:</label>
                                                    <div class="col-sm-3">
                                                        <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                            [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                            (onChangeState)="onChange($event)"
                                                            name='NotificationRangeErrorFrequency'
                                                            formControlName="NotificationRangeErrorFrequency">
                                                        </bSwitch>
                                                        <span
                                                            *ngIf="f.NotificationRangeErrorFrequency.errors?.required && (f.NotificationRangeErrorFrequency.dirty || f.NotificationRangeErrorFrequency.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-sm-12 box-row text-right">
                                                <div class="col-sm-12"
                                                    style="border-top: 3px solid #d2d6de;padding-top: 10px;">
                                                    <a class="btn btn-danger" href="javascript:void(0)"
                                                        (click)="clearFields()" id="btnClear"><span>Clear</span></a>
                                                    <a class="btn btn-primary" href="javascript:void(0)" id="btnAdd"
                                                        (click)="executeFieldMasterCreateUpdate('')"
                                                        style="margin-left: 10px;">Save</a>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <div class="box-body" id="dvboxbody" *ngIf="rows">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-9 pull-right">
                                    <div class="col text-right">
                                        <ngb-pagination pageSize="{{pageSize}}" [boundaryLinks]="true"
                                            [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                            (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                        </ngb-pagination>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="col text-left noofrecords">
                                        <span><b> <i>{{page.totalElements}}
                                                    {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b>
                                        </span>
                                        <span *ngIf="blnDirtySearch">
                                            <a style="cursor:pointer" (click)="executeFieldMasterSearchClear()">
                                                (<span><i class="fa fa-trash-o"></i></span>
                                                <b>Clear Search</b>
                                            </a>)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                                role="grid">
                                <thead>
                                    <tr>
                                        <th *ngFor="let tableHeader of tableHeaders"
                                            [ngClass]="setSortIndicator(tableHeader.Label)"
                                            [style.width.%]="tableHeader.Width"
                                            (click)="changeSorting(tableHeader.Label)">
                                            {{tableHeader.Label}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let fieldMasterDetails of rows;let index=index;"
                                        (click)="_onRowClick(fieldMasterDetails.fieldMasterId)" class="pointer">

                                        <td>
                                            {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                        </td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.fieldName}}</td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.mrmFieldName}}</td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.unit}}</td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.frequency}}</td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.minVal}}</td>
                                        <td style="white-space:normal;">{{fieldMasterDetails.maxVal}}</td>
                                    </tr>
                                </tbody>


                            </table>


                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-9 pull-right">
                                    <div class="col text-right">
                                        <ngb-pagination pageSize="{{pageSize}}" [boundaryLinks]="true"
                                            [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                            (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                        </ngb-pagination>


                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="col text-left noofrecords">
                                        <span><b> <i>{{page.totalElements}}
                                                    {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b>
                                        </span>
                                        <span *ngIf="blnDirtySearch">
                                            <a style="cursor:pointer" (click)="executeFieldMasterSearchClear()">
                                                (<span><i class="fa fa-trash-o"></i></span>
                                                <b>Clear Search</b>
                                            </a>)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { Component, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'Electrical-dialog',
  templateUrl: './ElectricalDialog.html',
})
export class ElectricalDialog {
  public EquipId:string;
  public MachineName:string;
  public completed =false;
  title="Bellco Electrical Test Form";
  current="Machine";
  CurrnetType="";
  constructor(public dialogRef: MatDialogRef<ElectricalDialog>) {
  }
  
  public ElectricalData=[];
  SetCurrentType(type)
  {
    
    if(type==this.CurrnetType)
    {
      return false;
    }
    else
    {
      this.CurrnetType=type;
      return true;
    }
  }
  save()
  {
    for (let childObj in this.ElectricalData){
      if(this.ElectricalData[childObj].tempresult != "Not Tested")
      {
      this.ElectricalData[childObj].result=this.ElectricalData[childObj].tempresult;
      }
    }
    this.dialogRef.close(true);
  }
  ngOnInit()
  {
    
  }

}
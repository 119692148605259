export class QuickAddModel {
    name:string;
    title : string;
    hrsTracking : boolean = true;
    errormsg : string;
    mailingcountry: string;
    mailingstate: string;
    mailingcity: string;
    mailingaddress: string;
    mailingzipCode: string;
    phone: string;
    errormsg1: string;
    Category: any;
  }

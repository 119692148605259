import { Component, ViewChild } from '@angular/core';
import { CDApiService } from '../../API/cdapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { AuthService } from 'src/app/API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
@Component({
  selector: 'AcidSearch',
  templateUrl: './AcidSearch.component.html'
})
export class AcidSearchComponent {
  constructor(
    private cdapi: CDApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private auth: AuthService,
    private commonapi: CommonApiService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  Siloes = [];
  AcidDetail = [];
  selectedDivision = '';
  selectedSilo = '';
  LotNo = '';
  EventId = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'Concentrates/AcidDrumFilling/AcidDrumDetail';
  workOrderRefNo = '';
  currentPageNumber = 1;
  sortExpression = 'fillingdate';
  sortDirection = 'DESC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Acid Drum Filling';
  searchText = 'Search Records';
  defaultSort;

  ngOnInit() {

    this.auth.GetCurrentUserDivision().subscribe(division => {
       
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'eventid', '4');
      this.dataGridService.addHeader('Event Code', 'eventid', '8');
      this.dataGridService.addHeader('Date', 'fillingdate', '10');
      this.dataGridService.addHeader('Division', 'divisionName', '15');
      this.dataGridService.addHeader('Silo', 'containerName', '15');
      this.dataGridService.addHeader('Total Amt Added (L)', 'lotnumber', '15');
      this.dataGridService.addHeader('Technologist', 'technologistName', '20');
      this.dataGridService.addHeader('Supply Vendor', 'vendorName', '20');
      this.sortDirection = 'DESC';
      this.sortExpression = 'fillingdate';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Date', true);
      this.fillSiloes();

      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.CheckDirtySearch();

    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeAcidSearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeAcidSearch() {
   
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const AcidSearch = this.prepareSearch();
    this.cdapi.GetCentralDeliveryAcidDrumFillingRecords(AcidSearch).subscribe(
      pagedData => {
        //console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.centralDeliveryAcidDrumFillingDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    let AcidSearch = <any>{};

    AcidSearch.eventId = this.EventId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      AcidSearch.fromDate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      AcidSearch.toDate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      AcidSearch.duid = this.selectedDivision;
    }

    if (this.selectedSilo !== undefined && this.selectedSilo !== '') {
      AcidSearch.acidContainer = this.selectedSilo;
    }

    AcidSearch.lotNumber = this.LotNo;
    AcidSearch.currentPageNumber = this.currentPageNumber;
    AcidSearch.sortExpression = this.sortExpression;
    AcidSearch.sortDirection = this.sortDirection;
    AcidSearch.pageSize = this.pageSize;
    //console.log(AcidSearch);
    return AcidSearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeAcidSearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue = '') {
    // this.cdapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => {
      this.commonapi.FillDivision(true).subscribe(list => {
        this.DialysisUnits = list;

        if (defaultValue) {
            this.selectedDivision = defaultValue;
            setTimeout(() => this.executeAcidSearch());
          }
      });
  }

  fillSiloes() {
    const num = { Id: 0 };
    this.cdapi
      .FillAcidLevelOptions(num)
      .subscribe(list => (this.Siloes = list));
  }

  executeAcidSearchClear() {
    this.selectedDivision = '';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';
    this.LotNo = '';
    this.selectedSilo ='';
    this.executeAcidSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision !== '' && this.selectedDivision != 'All Divisions') ||  this.selectedSilo !== '' ||  this.LotNo !== ''
      || this.EventId !== '' ||
      (this.FromDate !== undefined && this.FromDate !== '') ||  
      (this.ToDate !== undefined && this.ToDate !== '')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executeAcidSearch();
  }
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

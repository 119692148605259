import { Component } from '@angular/core';

import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TermsAndConditionDialog } from './Core/TermsAndConditionDialog/TermsAndConditionDialog';
import { PrivacyPolicyDialog } from './Core/PrivacyPolicyDialog/PrivacyPolicyDialog';
import { Globals } from './globals';
import { VersionCheckService } from './API/version-check.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // Sets initial value to true to show loading spinner on first load
 public static loading = false;
 public static alerts = [];
 static UpdateNotification: any;
public static MenuWidth = 0;
public dialogRef: MatDialogRef<TermsAndConditionDialog>;

constructor(
  public dialog: MatDialog,
  private router: Router,
  private versionCheck: VersionCheckService
) {}


public static addAlert(message, typeP) {

    AppComponent.alerts.filter(x=>x.successMessage==true).map(x=>x.successMessage=null);

    let timeoutSeconds = parseInt(Globals.defaultTimeForAlert);

    if(typeP == "error" && message != Globals.FormErrorMessage)
      timeoutSeconds = parseInt(Globals.defaultTimeForErrorAlert);

    const _success = {msg: message, type: typeP, successMessage: true};
    setTimeout(() => _success.successMessage = null, timeoutSeconds);
    AppComponent.alerts.push(_success);

    setTimeout(() =>{
      let errorText = document.querySelectorAll('ngb-alert div span')
      try {
        errorText[errorText.length - 1].innerHTML ='OK';
      } catch (error) {

      }

    },50);
  }
today = new Date();
static refreshData() {
  AppComponent.UpdateNotification =
    setInterval(() => {
     // console.log('test');
    }, 30000);
}

static cancelPageRefresh() {
  if (AppComponent.UpdateNotification) {
      clearInterval(AppComponent.UpdateNotification );
  }
}

ngAfterViewInit() {
  const totalWidth = document.querySelectorAll(".navbar-static-top")[0].clientWidth;
  const logoWidth = document.getElementsByName("techLogo")[0].clientWidth;
  const renalWidth = document.getElementsByName("renalLogo")[0].clientWidth;

  const dropWidth = document.querySelectorAll(".dropdown-toggle")[0].clientWidth;
  AppComponent.MenuWidth = totalWidth - (logoWidth + renalWidth + dropWidth + 50);
}

  public static ScrollDown() {
    setTimeout(() => {

    const element = document.getElementsByClassName('wrapper')[0];
    element.scrollTop = element.scrollTop - 100;
    }, 100);

  }

  public static ShowLoader() {

    if (AppComponent.loading !== true) {
      AppComponent.loading = true;
    }
  }
  public static HideLoader() {
    if (AppComponent.loading !== false) {
      AppComponent.loading = false;
    }
  }
  getLoading() {
    return AppComponent.loading;
  }
  getAlerts() {
    return AppComponent.alerts;
  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    (document.querySelectorAll('.main-header')[0]  as HTMLElement).removeAttribute('style');
    if (event instanceof NavigationStart) {
      AppComponent.ShowLoader();
    }
    if (event instanceof NavigationEnd) {
      AppComponent.HideLoader();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      AppComponent.HideLoader();
    }
    if (event instanceof NavigationError) {
      AppComponent.HideLoader();
    }
  }

  openTermsAndConditionDialog(): void {
     this.dialogRef = this.dialog.open(TermsAndConditionDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: '850px'
    });
    this.dialogRef.afterClosed().subscribe(result => {
    });
  }

  openPrivacyPolicy(): void {
    this.dialogRef = this.dialog.open(PrivacyPolicyDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: '850px'
    });
    this.dialogRef.afterClosed().subscribe(result => {
    });
  }

  openJMeet(): void {
      //var url = Globals.apiUrl.replace('api/','') + "views/JMeet.html";
      //window.open(url,"_blank");
      this.router.navigate(['JMeet/Live']);
  }

  ngOnInit(){
    this.versionCheck.initVersionCheck(Globals.CurrentSiteUrl + '/version.json');
  }
}

import { Component, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { PreventiveMaintenanceCommonDetailComponent } from "src/app/Core/Shared/PreventiveMaintenanceCommon/PreventiveMaintenanceCommonDetail.component";

@Component({
  selector: "PreventativeMaintenanceDetail",
  templateUrl: "./PreventativeMaintenanceDetail.component.html",
  // styleUrls: ["./PreventativeMaintenanceDetail.css"]
})
export class PreventativeMaintenanceDetailComponent {
  @ViewChild(PreventiveMaintenanceCommonDetailComponent, { static: true }) child;


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.child.canDeactivate();
  }
  constructor() { }
  
  ngOnInit() {
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { PyrogenComponent } from 'src/app/Core/Shared/PyrogenComponent/Pyrogen.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'GRPyrogenDetail',
  templateUrl: './GRPyrogenDetail.component.html',
})
export class GRPyrogenDetailComponent implements OnInit, CanComponentDeactivate {

  @ViewChild(PyrogenComponent, { static: true }) child;


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.child.canDeactivate();
  }

  constructor() { }

  ngOnInit() {
  }
}

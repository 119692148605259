  <section class="content-header">
    <h1 *ngIf="detailsType == 'Dialysis Machines'">
        {{headerTextDM}}
    </h1>
    <h1 *ngIf="detailsType == 'Water Process'">
        {{headerTextH2O}}
    </h1>
    <h1 *ngIf="detailsType == 'Other Equipment'">
        {{headerTextOther}}
    </h1>
</section>
<section class="content">

    <section>
        <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader

                    [showAddNew]="false"
                    [showSearch]="false"
                    #pageheader
                    [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch"
                    (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"
                ></PageHeader>
                <!-- <div
                    id="divSearch row"
                    *ngIf="ShowSearch"
                >

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                    <div class="col-sm-6">
                                      <div>
                                          <label class="col-sm-6 control-label">Division:</label>
                                          <div class="col-sm-6">
                                              <select
                                                  class="form-control"
                                                  [(ngModel)]="selectedDivision"
                                              >
                                                  <option
                                                      value="{{item.value}}"
                                                      *ngFor="let item of DialysisUnits"
                                                  >
                                                      {{item.text}}
                                                  </option>
                                              </select>

                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12"></div>
                            </div>
                        </div>

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button
                                        class="btn btn-primary"
                                        id="btnSearchDetail"
                                        (click)="executeDmMachineHistorySearch()"
                                    >
                                        <span>
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <b>Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> -->
                <div class="clearfix"></div>
                <div
                    class="box-body"
                    id="dvboxbody"
                >
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination
                                        pageSize="50"
                                        [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber"
                                        (pageChange)="setPage()"
                                        [maxSize]="10"
                                        [directionLinks]="true"
                                    ></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <option value="{{item.value}}" *ngFor="let item of DialysisUnits">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                          <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a
                                            style="cursor:pointer"
                                            (click)="executeDmMachineHistorySearchClear()"
                                        >
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table
                            id="OptionList"
                            class="table table-bordered table-striped dataTable no-footer"
                            role="grid"
                        >
                            <thead>

                                <tr>
                                    <th
                                        *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width"
                                        (click)="changeSorting(tableHeader.Label)"
                                    >
                                        {{tableHeader.Label}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr
                                    *ngFor="let DmMachineHistoryDetail of rows;let index=index;"
                                    (click)="_onRowClick(DmMachineHistoryDetail.equipementid,DmMachineHistoryDetail.serialnumber, DmMachineHistoryDetail.equipmentcode,DmMachineHistoryDetail.inservicedate,DmMachineHistoryDetail.pmScheduleStatus,DmMachineHistoryDetail.pmScheduleStatusType )"
                                    class="pointer"
                                >

                                    <td>
                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>{{DmMachineHistoryDetail.equipmentcode}}</td>

                                  <td>{{DmMachineHistoryDetail.serialnumber}}</td>
                                    <td matTooltip="{{DmMachineHistoryDetail.equipmentdesc}}">
                                      <div style="word-wrap: break-word;word-break: break-all">
                                      <!-- {{DmMachineHistoryDetail.equipmentdesc}} -->
                                      {{ (DmMachineHistoryDetail.equipmentdesc.length>60)? (DmMachineHistoryDetail.equipmentdesc | slice:0:60)+'..':(DmMachineHistoryDetail.equipmentdesc) }}
                                    </div>
                                  </td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination
                                        pageSize="50"
                                        [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber"
                                        (pageChange)="setPage()"
                                        [maxSize]="10"
                                        [directionLinks]="true"
                                    ></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                          <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a
                                            style="cursor:pointer"
                                            (click)="executeDmMachineHistorySearchClear()"
                                        >
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

 import { Component, OnInit,   ViewChild } from '@angular/core';
// import { WPApiService } from '../../API/wpapi.service';
// import { DMApiService } from '../../API/dmapi.service';
// import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
// import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// import { NgForm } from '@angular/forms';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
// import { MachineHistoryDialog } from '../../Core/MachineHistory/MachineHistoryDialog';
// import { CalibrationsDialog } from '../../Core/Calibrations/CalibrationsDialog';
// import { ElectricalDialog } from '../../Core/ElectricalDialog/ElectricalDialog';
// import { TrobleshootingDialog } from '../../Core/TrobleshootingDialog/TrobleshootingDialog';

// import { DMRepairCustomValidators } from '../../Validator/EuipmentRepairStatus';
// import { CommonCustomValidators } from '../../Validator/CommonValidator';
// import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
// import { PartsComponent } from '../../Core/Shared/PartsControl/PartsComponent';
// import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
// import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
// import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
// import {
//   Globals,
//   actionType,
//   NoteModuleName,
//   attachmentFormModules
// } from '../../globals';
// import { AppComponent } from '../../app.component';
// import { AuthService } from '../../API/auth.service';
// import { ImageViewerDialog } from '../../Core/Shared/ImageViewerDialog/ImageViewerDialog';


import { RepairDetailComponent } from '../../Core/Shared/Repairs/RepairDetail.component';

@Component({
  selector: 'CDRepairDetail',
  templateUrl: './CDRepairDetail.component.html'
})
export class CDRepairDetailComponent
  {
//   private scrollHelper: ScrollHelper = new ScrollHelper();
//   dialogRef: MatDialogRef<ConfirmationDialog>;
//   currentUserId = this.auth.GetUserId();
//   @ViewChild(AttachmentComponent)
//   attachment: AttachmentComponent;
//   @ViewChild(NotesComponent)
//   notes: NotesComponent;
//   @ViewChild(PartsComponent)
//   parts: PartsComponent;
//   ShowParts = true;
//   //ShowErrors = true;
//   UserName = '';
//   isValidFormSubmitted = null;
//   ListURL = 'WaterProcess/Repairs/RepairDetailSearch';
//   EventRepairOptions = [
//     { value: '', text: 'Select' },
//     { value: 'E', text: 'Event' },
//     { value: 'R', text: 'Repair' }
//   ];

//   loading = true;
//   RepairDetailsIdvar = '0';
//   EquipmentSerialNo: '';
//   EquipmentInServiceDate: '';
//   DialysisUnits = [];
//   Equipments = [];
//   Users = [];
//   Parts = [];
//   Statuses = [];
//   DialysisStation = [];
//   DMRepailDetail = [];
//   HydraulicDocList = [];

//   //selectedUser = '0';
//   RepairDetailsId = '0';
//   repairId = '[AutoNumber]';
//   status = '';
//   createdBy = this.currentUserId;
//   modifiedBy = this.currentUserId;
//   createdOn = new Date();
//   modifiedOn = new Date();
//   IsHoursInIsGreaterThanHoursOut = true;
//   blnHoursInVal = false;
//   RepairStatus = true;
//   selectedDialysisUnitsobj;
//   selectedDialysisUnitstext;
//   selectedEventRepairtext;

//   selectedEquipmenttext;
//   timeStartedLabel;
//   timeFinishedLabel;
//   calibrationsTran = [];
//   electricalTran = [];
//   Note = '';
//   blnshowSave = false;
//   blnshowDelete = false;
//   detailsType  ="Water Process"


//   repairDetailForm = new FormGroup({
//     RepairDetailsId: new FormControl(''),
//     timeStarted: new FormControl('', Validators.required),
//     selectedDialysisUnits: new FormControl('', Validators.required),
//     selectedRepairEvent: new FormControl('',Validators.required),
//     remarks: new FormControl('', Validators.required),
//     ErrorSearchTerm: new FormControl(''),
//     selectedEquipment: new FormControl(''),
//     ErrorDescription: new FormControl(''),
//     hoursin: new FormControl('', Validators.required),
//     PartSearchTerm: new FormControl(''),
//     PartDescription: new FormControl(''),
//     PartLocation: new FormControl(''),
//     PartQuantity: new FormControl(''),
//     workOrderRefNo: new FormControl(''),
//     hoursout: new FormControl(''),
//     timeFinished: new FormControl(''),

//   });
//   // implementation of canDeactivate
//   canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
//     if (
//       (this.repairDetailForm.dirty ||
//       this.notes.Note != '' ||
//       this.attachment.Queue().length > 0 ) && (this.isValidFormSubmitted==false)
//     ) {
//       this.dialogRef = this.dialog.open(ConfirmationDialog, {
//         disableClose: true
//       });
//       this.dialogRef.componentInstance.confirmMessage =
//         'You have unsaved changes! If you leave, your changes will be lost.';
//       return this.dialogRef.afterClosed().pipe(
//         map((res: any) => {
//           // console.log('res', res);
//           return res;
//         })
//       );
//     } else return true;
//   }

//   ngAfterViewChecked() {
//     this.scrollHelper.doScroll();
//   }

//   constructor(
//     private dmapi: DMApiService,
//     private wpapi: WPApiService,
//     private route: ActivatedRoute,
//     private router: Router,
//     public dialog: MatDialog,
//     private auth: AuthService
//   ) {}

//   ngAfterViewInit(){
//  // Parts
//  //console.log(this.ShowParts);
//  if(this.ShowParts == true){
//  this.parts.partscategoryname = 'Dialysis Machines';
//  this.parts.currentUserId = this.currentUserId;
//  this.parts.API = this.dmapi;
//  this.parts.status = this.status;
//  }



//   }
//   ngOnInit() {
//     AppComponent.ShowLoader();
//     // this.uploader.autoUpload = true;
//     this.SetPageRights();




//     // attachment changes
//     this.attachment.DocId = this.f.RepairDetailsId.value;
//     this.attachment.moduleName =
//       attachmentFormModules[attachmentFormModules.DM_Repair];
//     this.attachment.UploadBy = this.currentUserId;


//     // notes
//     this.notes.showParts = true;
//     this.notes.CurrentUserId = this.currentUserId;
//     this.notes.API = this.dmapi;
//     this.notes.partscategoryname = 'Dialysis Machines';
//     this.notes.status = this.status;

//     this.UserName = this.auth.GetUserFirstName();

//     const test = this.route.queryParams.subscribe(params => {
//       // Defaults to 0 if no query param provided.

//       this.f.RepairDetailsId.setValue(params['id'] || '0');
//       const tempDate = new Date();
//       this.f.timeStarted.setValue({
//         date: {
//           year: tempDate.getFullYear(),
//           month: tempDate.getMonth() + 1,
//           day: tempDate.getDate()
//         },
//         jsdate: tempDate
//       });
//       if (this.f.RepairDetailsId.value != '0') {
//         this.setModel();
//       } else {
//         this.fillDialysisUnit();
//         this.fillEquipment(0);
//       }


//       if (this.f.RepairDetailsId.value == 0) {
//         this.f.selectedEquipment.setValidators([Validators.required]);
//         this.f.selectedEquipment.updateValueAndValidity();
//         this.f.selectedEquipment.setAsyncValidators([
//           DMRepairCustomValidators.EquipmentRepairStatusValidate(
//             this.f.RepairDetailsId,
//             this.dmapi
//           )
//         ]);

//         this.f.hoursin.setValidators([Validators.required,Validators.pattern(Globals.NumberNumericRegex)]);

//         this.f.hoursin.setAsyncValidators([
//           DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
//             this.f.selectedEquipment,
//             this.f.RepairDetailsId,
//             this.dmapi
//           )
//         ]);
//         this.f.hoursin.updateValueAndValidity();
//       }

//       this.f.hoursout.setValidators([,Validators.pattern(Globals.NumberNumericRegex),
//         CommonCustomValidators.CheckDateoutCustomValidation(
//           this.f.timeFinished
//         ),
//         CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
//           this.f.hoursin
//         )
//       ]);
//       this.f.hoursout.updateValueAndValidity();


//     });

//     AppComponent.HideLoader();
//   }

//   get f() {
//     return this.repairDetailForm.controls;
//   }


//   setModel() {
//     AppComponent.ShowLoader();
//     const repairCreate = { Id: this.f.RepairDetailsId.value };

//     this.wpapi.GetWaterProcessRepairsRecordByID(repairCreate).subscribe(
//       success => {
//         console.log(success);

//         this.f.RepairDetailsId.setValue(success.repairDetailId);
//         this.f.selectedRepairEvent.setValue(success.statusType.toString());

//         this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
//         this.f.selectedEquipment.setValue(success.equipmentId.toString());

//         this.repairId = success.repairId;
//         this.UserName = success.createdbyName;
//         this.f.workOrderRefNo.setValue(success.workOrderNo);

//         this.timeStartedLabel = success.timeStarted;
//         this.notes.Note = '';
//         this.attachment.clearQueue();

//         const tempDate = new Date(success.timeStarted);
//         this.f.timeStarted.setValue({
//           date: {
//             year: tempDate.getFullYear(),
//             month: tempDate.getMonth() + 1,
//             day: tempDate.getDate()
//           },
//           jsdate: success.timeStarted
//         });


//         if (
//           success.timefinished != undefined &&
//           success.timefinished != null
//         ) {
//           this.timeFinishedLabel = success.timefinished;
//           const tempDateoutateout = new Date(success.timefinished);

//           this.f.timeFinished.setValue({
//             date: {
//               year: tempDateoutateout.getFullYear(),
//               month: tempDateoutateout.getMonth() + 1,
//               day: tempDateoutateout.getDate()
//             },
//             jsdate: success.timefinished
//           });
//         }

//         this.f.hoursout.setValue(success.hoursout);

//         if(success.hoursin == 0)
//         {
//           this.blnHoursInVal = true;
//         }

//         this.f.hoursin.setValue(success.hoursin);
//         this.status = success.status;

//         this.f.remarks.setValue(success.remark);

//         this.createdOn = success.createdOn;
//         this.createdBy = success.createdBy;
//         this.modifiedOn = success.modifiedOn;
//         this.modifiedBy = success.modifiedBy;

//         //console.log(success);



//         //this.parts.SelectedDialysisUnits = this.f.selectedEquipment.value;

//         this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;

//         this.notes.DivisionId = this.f.selectedDialysisUnits.value;
//         this.parts.status = this.status;
//         this.notes.status = this.status;
//         this.selectedDialysisUnitstext = success.divisionName;

//         if(success.statusType == "E")
//         {
//         this.selectedEventRepairtext = "Event";
//         }
//         else
//         {
//         this.selectedEventRepairtext = "Repair";

//         }


//         if (success.nurseId != null) {
// //          this.f.selectedUser.setValue(success.nurseId.toString());
//         }
//         this.f.selectedEquipment.setValue(success.equipmentId.toString());
//         var repairCreate = { Id: this.f.selectedEquipment.value };



//         var dmReparirObj = { Id: this.f.RepairDetailsId.value };


//         this.wpapi
//           .GetRepairPartsDetailsbydmRepairDetailid(dmReparirObj)
//           .subscribe(list => {
//             console.log(list);
//             this.parts.PartTrans = [];
//             //console.log(list);
//             for (let childObj in list) {
//               var temp = list[childObj];
//               var obj = <any>{};
//               obj.RepairDetailsId = temp.RepairDetailsId;
//               obj.equipmentid = temp.equipmentId;
//               obj.quantity = temp.quantity;
//               obj.PartCode = temp.equipmentCode;
//               obj.PartDescription = temp.equipmentDesc;
//               obj.PartCost = temp.itemCost;
//               obj.createdBy = temp.createdBy;
//               obj.createdByName = temp.createdByName;
//               obj.createdOn = temp.repairPartDetailsEnteredOn;
//               obj.modifiedBy = temp.modifiedBy;
//               obj.modifiedOn = temp.modifiedOn;
//               obj.LocationText = '0';
//               obj.showPartDelete = false;
//               // tslint:disable-next-line:triple-equals
//               if (obj.createdBy == this.currentUserId) {
//                 obj.showPartDelete = true;
//               }
//               this.parts.PartTrans.push(obj);
//             }

//             this.notes.Parts = this.parts.PartTrans;
//             this.ShowParts = (this.status !='Completed' || this.parts.PartTrans.length > 0)
//           });


//         this.wpapi
//           .GetattachmentDetailsbyRepairDetailid(dmReparirObj)
//           .subscribe(list => {
//             this.attachment.PrevAttachments = list;
//           });

//         this.wpapi.GetnotesbydmRepairDetailid(dmReparirObj).subscribe(list => {
//           this.notes.NoteList = list;
//         });


//         this.selectedEquipmenttext = success.equipmentName;

//         this.fillEquipment(this.f.selectedDialysisUnits.value);

//         AppComponent.HideLoader();
//       },
//       error => {
//         AppComponent.addAlert(error.message, 'error');
//         AppComponent.HideLoader();
//       }
//     );
//   }

//   public myOptions: IAngularMyDpOptions = {
//     // other options...
//     dateFormat: 'dd/mm/yyyy',
//     closeSelectorOnDateSelect:false
//   };

//   onDateChanged(event: IMyDateModel): void {}


//   fillEquipment(divisionID) {
//     if (divisionID != 0) {
//       const value = {
//         DivisionId: this.f.selectedDialysisUnits.value,
//         CategoryName: this.detailsType,
//         Type: 'EQUIPMENT'
//       };
//       this.wpapi.GetEquipmentByCategoryDD(value).subscribe(
//         list => (this.Equipments = list)
//       );
//     } else {
//       this.Equipments = [{ value: '', text: 'Select' }];
//       this.f.selectedEquipment.setValue('');
//     }

//   }


//   fillParts() {
//     const value = {
//       DivisionId: this.f.selectedDialysisUnits.value,
//       CategoryName: this.detailsType,
//       Type: 'PARTS'
//     };
//     this.wpapi.GetPartsDDByCategory(value).subscribe(list => this.Parts=list);
//   }
//   executerepairCreateUpdate = function(Source) {
//     AppComponent.loading = true;
//     if (Source === 'Notes' && this.notes.showParts === true && this.notes.Parts.length > 0) {
//       const partTrans = this.parts.PartTrans;
//       //console.log(this.parts);
//       this.notes.Parts.forEach(function (value) {
//         partTrans.push(value);
//       });
//       this.parts.PartTrans = partTrans;
//     }
//     this.f.hoursout.updateValueAndValidity();
//     this.isValidFormSubmitted = false;
//     if (this.repairDetailForm.invalid) {
//       AppComponent.addAlert(Globals.FormErrorMessage, 'error');
//       AppComponent.loading = false;

//       this.scrollHelper.scrollToFirst('ng-invalid');
//       AppComponent.ScrollDown();
//       return;
//     }
//     this.isValidFormSubmitted = true;

//     const repairCreate = this.prepareCreateViewModel();

//     if (repairCreate.repairdetailid == '0') {
//       this.wpapi.CreateWatrerProcessRepairsRecord(repairCreate).subscribe(
//         success => {
//           this.DMRepailDetail = success.dmRepailDetails;
//           AppComponent.loading = false;
//           AppComponent.addAlert('Data Saved successfully!', 'success');
//           //this.router.navigate(['DialysisMachine/DMRepairDetailSearch']);
//           if (Source != 'Notes') {
//             this.router.navigate(['WaterProcess/Repairs/RepairDetailSearch']);
//           } else {
//             this.f.RepairDetailsId.setValue(success.repairdetailid);

//             this.setModel();
//           }
//         },
//         error => {
//           AppComponent.addAlert(error.message, 'error');
//           AppComponent.HideLoader();
//         }
//       );
//     } else {
//       this.wpapi.UpdateWatrerProcessRepairsRecord(repairCreate).subscribe(
//         success => {
//           console.log(success);
//           this.DMRepailDetail = success.dmRepailDetails;
//           AppComponent.loading = false;
//           AppComponent.addAlert('Data Saved successfully!', 'success');
//           //alert('Data Saved successfully!');
//           if (Source != 'Notes') {
//             this.router.navigate(['WaterProcess/Repairs/RepairDetailSearch']);
//           } else {
//             this.setModel();
//           }
//         },
//         error => {
//           AppComponent.addAlert(error.message, 'error');
//           AppComponent.HideLoader();
//         }
//       );
//     }
//   };

//   prepareCreateViewModel() {
//     const repairCreate = <any>{};

//     repairCreate.repairdetailid = this.f.RepairDetailsId.value;
//     repairCreate.repairtype = this.detailsType;
//     repairCreate.prefix = this.f.selectedRepairEvent.value;

//     repairCreate.repairId = this.repairId;

//     if (this.f.timeStarted.value !== null &&  this.f.timeStarted.value !== undefined) {
//       repairCreate.timeStarted = Globals.GetAPIDate( this.f.timeStarted.value.jsdate);
//     }

//     repairCreate.hoursin = this.f.hoursin.value;
//     repairCreate.hoursout = this.f.hoursout.value;

//     repairCreate.divisionId =this.f.selectedDialysisUnits.value;
//     repairCreate.equipmentId = this.f.selectedEquipment.value;

//     repairCreate.workorderid = null;
//     repairCreate.workOrderRefNo = this.f.workOrderRefNo.value;

//     repairCreate.createdOn = new Date();
//     repairCreate.createdBy = this.currentUserId;

//     repairCreate.modifiedOn = new Date();
//     repairCreate.modifiedBy = this.currentUserId;

//     if (this.f.timeFinished.value !== null && this.f.timeFinished.value !== undefined) {
//       repairCreate.timefinished = Globals.GetAPIDate( this.f.timeFinished.value.jsdate);
//     }


//     repairCreate.remark = this.f.remarks.value;
//     repairCreate.status = this.status;
//     repairCreate.note = this.notes.Note;

//     // parts
//     if(this.parts !=null && this.parts.PartTrans!=null)
//       repairCreate.repairPartsDetails = this.parts.PartTrans;

//     // attachments
//     repairCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

//     // console.log(repairCreate);
//     return repairCreate;
//   }


//   EquipmentDropDownChanged() {
//     this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;
//     this.parts.SelectedEquipment = this.f.selectedEquipment.value;
//     console.log(this.f.selectedEquipment.value);
//     this.notes.DivisionId = this.f.selectedDialysisUnits.value;
//     // if (this.f.RepairDetailsId.value == '0') {
//     //   var repairCreate = { Id: this.f.selectedEquipment.value };
//     //   this.dmapi.GetEquipmentDetailsByID(repairCreate).subscribe(success => {
//     //     this.EquipmentSerialNo = success.serialnumber;
//     //     this.EquipmentInServiceDate = success.inservicedate;
//     //     this.selectedEquipmenttext = success.equipmentcode;
//     //   });
//     // }
//   }

//   DeleteEvent() {
//     this.dialogRef = this.dialog.open(ConfirmationDialog, {
//       disableClose: true
//     });
//     this.dialogRef.componentInstance.confirmMessage =
//       'Are you sure you want to delete?';
//     this.dialogRef.afterClosed().subscribe(result => {
//       if (result) {
//         AppComponent.ShowLoader();
//         const dmReparirObj = { Id: this.f.RepairDetailsId.value };
//         this.wpapi.DeleteWatrerRepairsRecord(dmReparirObj).subscribe(
//           list => {
//             AppComponent.HideLoader();
//             AppComponent.addAlert(
//               'Repair detail deleted successfully!',
//               'success'
//             );
//             this.router.navigate(['WaterProcess/Repairs/RepairDetailSearch']);
//           },
//           error => {
//             AppComponent.addAlert(error.message, 'error');
//             AppComponent.HideLoader();
//           }
//         );
//       }
//       this.dialogRef = null;
//     });
//   }





//   // Parts variables

//   showDoc() {
//     if (this.f.selecthydrolicDropDownList.value.value != '') {
//       var openURL =
//         Globals.DocumentResourcesUrl + this.f.selecthydrolicDropDownList.value;
//       window.open(openURL);
//     }
//   }

//   showImage(args): void {
//     //
//     if (args.target.value != 'Select')
//     {
//     const dialogRef = this.dialog.open(ImageViewerDialog, {
//       height: '580px',
//       width: '620px',
//       data: { imgURL: args.target.value, title: args.target.options[args.target.selectedIndex].text }
//     });

//     dialogRef.afterClosed().subscribe(result => {
//       console.log('Image dialog was closed');
//     });
//   }
//   }


//   fillDialysisUnit() {
//     this.dmapi.GetDialysisUnitDD().subscribe(list => {
//       this.DialysisUnits = list;
//       if (this.f.RepairDetailsId.value != '0') {
//         this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
//           x => x.value === this.f.selectedDialysisUnits.toString()
//         );
//         if (this.selectedDialysisUnitsobj.length > 0) {
//         this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
//         }
//       }
//     });
//   }


//   DialysisUnitDropDownChanged() {
//     this.parts.SelectedDialysisUnits = this.f.selectedEquipment.value;
//     this.notes.DivisionId = this.f.selectedDialysisUnits.value;
//     this.fillEquipment(this.f.selectedDialysisUnits.value);

//   }

//   SetPageRights() {
//     this.auth.GetPageRights('DM Repair Form').subscribe(list => {
//       if (this.f.RepairDetailsId.value != '0') {
//         this.blnshowSave = list[actionType.edit] == '1';
//         this.blnshowDelete = list[actionType.delete] == '1';
//       } else {
//         this.blnshowSave = list[actionType.create] == '1';
//         this.blnshowDelete = false;
//       }
//     });
//   }
//   AttachmentError(Error)
//   {

//     AppComponent.addAlert(Error, 'error');
//   }
//   GreaterValueCheck() {
//     this.f.hoursout.updateValueAndValidity();


//   }
}

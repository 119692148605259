import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class SchApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetDialysisUnitDD() {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/GetUserDivisionList',
      '',
      httpOptions
    );
  }

  GetAllScheduleColors() {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/GetAllScheduleColors',
      '',
      httpOptions
    );
  }

  SetList(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/SetList',
      value,
      httpOptions
    );
  }

  SetListWeek(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/SetListWeekDays',
      value,
      httpOptions
    );
  }

  GetHolidays(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/GetHolidays',
      value,
      httpOptions
    );
  }

  CreateScheduleDetails(value) {
    //console.log(value);
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/CreateScheduleDetails',
      value,
      httpOptions
    );
  }

  GetallowScheduleEdit() {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/GetallowScheduleEdit',
      '',
      httpOptions
    );
  }

  GetscheduleusertypeDesg() {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/GetscheduleusertypeDesg',
      '',
      httpOptions
    );
  }

  SetNote(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/SetNote',
      value,
      httpOptions
    );
  }

  PrintSchedulePDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'ScheduleService/PrintSchedulePDF',
      value,
      httpOptions
    );
  }
}

import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { ImageViewerDialog } from 'src/app/Core/Shared/ImageViewerDialog/ImageViewerDialog';
import { InvApiService } from 'src/app/API/invapi.service';
import { CurrencyPipe } from '@angular/common';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';

@Component({
  selector: 'SectionReturnDetailDialog',
  templateUrl: './section-returndetail.component.html',
  styleUrls: ['./section-returndetail.component.css']
})

export class SectionReturnDetailDialog implements AfterViewInit {

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  @Output() SaveEvent = new EventEmitter<any>();
  isValidFormSubmitted = false;
  errormsg = "";

  YesNoList=[];
  FuelCompanyList=[];
  PaymentByList=[];
  formattedHFServiceFuelAmount='';
  HFServiceVehicleDetailId='';

  returnSectionForm = new UntypedFormGroup({
    HFServiceReturnDetailsId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl(''),
    HFServiceReturnVehicleEndTime: new UntypedFormControl('', Validators.required),
    HFServiceVehiclePrevStartedKm :new UntypedFormControl(''),
    HFServicePTHMDepartureTime: new UntypedFormControl(''),
    HFServiceVehicleStartedKM: new UntypedFormControl(''),
    HFServiceReturnVehicleEndedKM: new UntypedFormControl('',Validators.required),
    HFServiceFuelAdded: new UntypedFormControl('',Validators.required),
    HFServiceFuelCompany: new UntypedFormControl(''),
    HFServiceFuelAmount: new UntypedFormControl(''),
    HFServiceFuelPaymentBy: new UntypedFormControl(''),
    HFServiceFuelReceiptPath: new UntypedFormControl(''),
    HFServiceReturnDetailNotes: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
  });

  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    private invapi: InvApiService,
    private currencyPipe: CurrencyPipe,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SectionReturnDetailDialog>
  ) { }


  TechnologistsList = [];
  PrepTimeList = [];
  today = new Date();
  UserName = this.auth.GetUserName();
  UserId = this.auth.GetUserId();
  dropdownSettings;

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

    this.rf.HFServiceReturnVehicleEndTime.setValidators([
      CommonCustomValidators.CheckDateAndTimeGreaterValidation(
        this.rf.HFServicePTHMDepartureTime, this.rf.HFServiceReturnVehicleEndTime
      ), Validators.required
    ]);

    this.rf.HFServiceReturnVehicleEndedKM.setValidators([
      CommonCustomValidators.CheckKMSGreaterValidation(
        this.rf.HFServiceVehiclePrevStartedKm, this.rf.HFServiceReturnVehicleEndedKM
      ), Validators.required
    ]);

  }, error => {
  });
  }

  prepareCreateViewModel(Source = "") {
    const modelCreate = <any>{};

    modelCreate.HFServiceReturnDetailsId = this.rf.HFServiceReturnDetailsId.value;
    modelCreate.HFServiceId = this.rf.HFServiceId.value;
    modelCreate.HFServiceReturnVehicleEndTime =  Globals.GetAPIDateWithoutSeconds(this.rf.HFServiceReturnVehicleEndTime.value);
    modelCreate.HFServiceReturnVehicleEndedKM = this.rf.HFServiceReturnVehicleEndedKM.value;
    modelCreate.HFServiceFuelAdded = this.rf.HFServiceFuelAdded.value;
    modelCreate.HFServiceFuelCompany = this.rf.HFServiceFuelCompany.value;
    modelCreate.HFServiceFuelAmount = this.currenctyToNumber(this.rf.HFServiceFuelAmount.value);;
    modelCreate.HFServiceFuelPaymentBy = this.rf.HFServiceFuelPaymentBy.value;
    modelCreate.HFServiceFuelReceiptPath = this.rf.HFServiceFuelReceiptPath.value;
    modelCreate.HFServiceReturnDetailNotes = this.rf.HFServiceReturnDetailNotes.value;
    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.UserId;

    modelCreate.HFServiceVehicleDetailId = this.HFServiceVehicleDetailId.toString();
    return modelCreate;
  }

  ngAfterViewInit() {

  }

  saveAndClose() {
    if (this.returnSectionForm.dirty) {
      if (this.returnSectionForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

            if(result)
              this.dialogRef.close(false);
            else
              this.save();
          });
      }
      else{
          this.save();
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  save() {
    AppComponent.loading = true;
    this.isValidFormSubmitted = true;
    if (this.returnSectionForm.invalid) {
      // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      // this.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      return;
    }

    const returnCreate = this.prepareCreateViewModel();
    this.homefieldApi.CreateHomeFieldService_Section_ReturnDetails(returnCreate).subscribe(
      success => {
        AppComponent.loading = false;
        this.SaveEvent.emit(success);
        this.dialogRef.close(returnCreate);
      },
      error => {
        if (error.error && error.error.returnMessage) {
          this.errormsg = error.error.returnMessage.toString();
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          this.errormsg = error.message;
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  };


  get rf() {
    return this.returnSectionForm.controls;
  }

  transformHFServiceFuelAmount() {
    if (this.rf.HFServiceFuelAmount.value) {
      this.formattedHFServiceFuelAmount = this.currencyPipe.transform(
        this.currenctyToNumber(this.rf.HFServiceFuelAmount.value), 'CAD', '$', '1.2-2');
    }
  }

  currenctyToNumber(value) {

    if (value != null)
      return Number(value.toString().replace(/[^0-9.-]+/g, ""));
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }


  SetValidations() {
    if (this.rf.HFServiceFuelAdded.value == "Yes") {
      this.rf.HFServiceFuelAdded.setValidators([Validators.required]);
      this.rf.HFServiceFuelCompany.setValidators([Validators.required]);
      this.rf.HFServiceFuelAmount.setValidators([Validators.required]);
      this.rf.HFServiceFuelPaymentBy.setValidators([Validators.required]);


    }
    else{
      this.rf.HFServiceFuelAdded.clearValidators();
      this.rf.HFServiceFuelCompany.clearValidators();
      this.rf.HFServiceFuelAmount.clearValidators();
      this.rf.HFServiceFuelPaymentBy.clearValidators();
    }

    this.rf.HFServiceFuelAdded.updateValueAndValidity();
      this.rf.HFServiceFuelCompany.updateValueAndValidity();
      this.rf.HFServiceFuelAmount.updateValueAndValidity();
      this.rf.HFServiceFuelPaymentBy.updateValueAndValidity();

  }

  //** Receipt Upload */
  clearImage() {
    this.rf.HFServiceFuelReceiptPath.setValue("");
    document.getElementById('ng2Fileuploader')['value'] = '';
  }

  getDisplayTime(val){
    if(val)
      return new Date(val);
    else
      return this.today;
  }

  onFileSelected(event) {
    AppComponent.ShowLoader();
    var files = event.target.files;

    const frmData = new FormData();

    for (var i = 0; i < files.length; i++) {
      frmData.append("file", files[i]);
    }

    this.invapi.FileUpload(frmData).subscribe(
      success => {

        this.rf.HFServiceFuelReceiptPath.setValue(success.uploadfilename);
        //this.rf.HFServiceFuelReceiptFileName.setValue(success.originalfilename);
        this.GetImagePath();
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

  }

  GetImagePath() {
    return Globals.AttachmentUrl + '/' + this.rf.HFServiceFuelReceiptPath.value;
  }

  openImageDialog(): void {
    const dialogRef = this.dialog.open(ImageViewerDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: "620px",
      data: {
        imgURL: this.GetImagePath(),
        title: 'Receipt'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('Image dialog was closed');
    });
  }
  //** Receipt Upload */

//   getCurrentTime(){
//     return  new Date().getHours() + ":" + new Date().getMinutes();
// }
getCurrentTime(){
  return  this.today;
}

getToday(){
  let tod = new Date(this.today);
  tod.setHours(0,0,0,0);
  return tod;
}

}

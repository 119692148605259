import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartsComponent } from '../Shared/PartsControl/PartsComponent';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AttachmentComponent } from '../Shared/AttachmentComponent/AttachmentComponent';
import { DMRepairDetailComponent } from 'src/app/DialysisMachines/DMRepairDetail.component';
import { DMApiService } from '../../API/dmapi.service';
import { ImageViewerDialog } from "../Shared/ImageViewerDialog/ImageViewerDialog";
import { Globals } from "src/app/globals";
import { URLDialog } from '../Shared/URLDialog/URLDialog';

@Component({
  selector: 'attachment-dialog',
  templateUrl: './AttachmentDialog.html'
})
export class AttachmentDialog implements AfterViewInit {
  matDialogRef: MatDialogRef<ConfirmationDialog>;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AttachmentDialog>,public urlDialogRef: MatDialogRef<URLDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentDialogData, private dmapi: DMApiService) {
  }

  repairID: any;
  moduleName: string;
  public Attachments = [];

  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log(this.data);
    var equipObj = {Id : this.data.repairID, str : this.data.moduleName};
    this.dmapi.GetAttachmentDetailsbyID(equipObj).subscribe(list => {
      this.Attachments = list;
    });
  }

  // openImageDialog(attachmentName:string, attachmentPath:any): void {
  //
  //   const dialogRef = this.dialog.open(ImageViewerDialog, {
  //     position: { right: '0', top: '0' },
  //     height: '100%',
  //     width: "620px",
  //     data: {
  //       imgURL: attachmentPath,
  //       title: attachmentName
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     // console.log('Image dialog was closed');
  //   });
  // }

  openImageDialog(FileName, URL): void {

    let extension = FileName.split('.').pop();

    if(extension.toLowerCase() === 'png' || extension.toLowerCase() === 'jpg' ||
    extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'jpeg' )
    {
      const dialogRef = this.dialog.open(ImageViewerDialog, {
        position: { right: '0', top: '0' },
        height: '100%',
        width: '70%',
        data: { imgURL: URL, title : FileName }
      });
     // console.log(FileName,URL);
      dialogRef.afterClosed().subscribe(result => {
       // console.log('Image dialog was closed');
      });
    }
    else
    {
      this.urlDialogRef = this.dialog.open(URLDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
      });
     // console.log(FileName,URL)
      this.urlDialogRef.componentInstance.URL = URL;
      this.urlDialogRef.componentInstance.dialogRef.updateSize('80%', '80%');
      this.urlDialogRef.componentInstance.PageTitle = FileName;
      this.urlDialogRef.afterClosed().subscribe(result => {
        this.urlDialogRef = null;
      });
    }
  }

  saveAndClose()
  {
    this.dialogRef.close(false);
  }

  SetAttachmentUrl(fileName) {
    return Globals.AttachmentUrl + '/' + fileName;
  }

  public confirmMessage: string;
}

export interface AttachmentDialogData {
  repairID: any;
  moduleName: string;
}

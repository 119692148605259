import { OrganizationalUnitApiService } from './../API/organizationalunitapi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactApiService } from '../API/contactapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { SingleAttachmentComponent } from "../Core/Shared/SingleAttachmentComponent/SingleAttachmentComponent";
import { Globals, actionType , attachmentFormModules} from '../globals';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';
import { ContactCustomValidators } from "../Validator/ContactValidator";
import { AttachmentComponent } from '../Core/Shared/AttachmentComponent/AttachmentComponent';
import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import { NgxMaterialTimepickerHoursFace } from 'ngx-material-timepicker/src/app/material-timepicker/components/timepicker-hours-face/ngx-material-timepicker-hours-face';

@Component({
  selector: 'ContactDetail',
  templateUrl: './ContactDetail.component.html'
})
export class ContactDetailComponent implements OnInit, CanComponentDeactivate {
  allClearValue = "ALL";
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  @ViewChild(SingleAttachmentComponent, { static: false })
  attachment: SingleAttachmentComponent;
  @ViewChild(AttachmentComponent, { static: false })
  attachmentList: AttachmentComponent;
  currentUserId = this.auth.GetUserId();
  isValidFormSubmitted = null;
  loading = true;
  onText = "Yes";
  offText = "No";
  status = '';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  blnshowSave = false;
  blnshowEdit = false;
  blnshowDelete = false;
  ListURL = '/Contact/ContactList';
  CountryList = [];
  BillingStateList = [];
  StateList = [];
  ContactTypeList = [];
  ContactTypeListWithoutUser = [];
  DivisionList = [];
  DesignationList = [];
  StatusList = [];
  HintQuestionList = [];
  ScheduleUserTypeList = [];
  JobStatusList = [];
  TitleList = [];
  BillingMeanList = [];
  AttachMentUpdated = false;
  isDisabled = false;
  setscheduleusertypeid = "0";
  contactnumber = '[AutoNumber]';
  LoggedinUserDesignationID = '';
  userDesignation = this.auth.GetDesignationName();
  AlldivisionIds =[];
  onYes = 'Yes';
  offNo = 'No';
  isHomeProgram = false;
  contactDesignation = '';
  collapsedContactDetailsClass = '';
  collapseariaexpanded = true;
  collapsedContactDetails = 'panel-collapse collapse show';

  collapsedUserLoginClass = '';
  collapseariaexpandedUserLogin = true;
  collapsedUserLoginDetails = 'panel-collapse collapse show';
  disabled= false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 6,
    allowSearchFilter: false
  };
  NonAdminUserList = [];

  isAdminPermissionLoggedInUser = this.auth.GetIsAdminPermission();

  contactDetailForm = new UntypedFormGroup({
    contactType: new UntypedFormControl('', Validators.required),
    contactid: new UntypedFormControl(''),
    title: new UntypedFormControl(''),
    givenName: new UntypedFormControl('', Validators.required),
    familyName: new UntypedFormControl(''),

    miscName: new UntypedFormControl(''),
    divisionId: new UntypedFormControl('', Validators.required),
    ALLdivisionId: new UntypedFormControl(''),
    mailingaddress: new UntypedFormControl('', Validators.required),
    mailingcity: new UntypedFormControl('', Validators.required),
    mailingcountry: new UntypedFormControl('', Validators.required),
    mailingzipCode: new UntypedFormControl('', Validators.required),
    mailingstate: new UntypedFormControl('', Validators.required),
    billingaddress: new UntypedFormControl(''),
    billingcity: new UntypedFormControl(''),
    billingcountry: new UntypedFormControl(''),
    billingzipCode: new UntypedFormControl(''),
    billingstate: new UntypedFormControl(''),
    phone: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.PhoneNumberRegex)]),
    extension: new UntypedFormControl(''),
    homePhone: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    pager: new UntypedFormControl(''),
    tollFreeFax: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    emailAddress: new UntypedFormControl('', Validators.pattern(Globals.EmailRegex)),
    permanentNotes: new UntypedFormControl(''),
    tollFreeNumber: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    cellPhone: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.PhoneNumberRegex)]),
    fax: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    otherNumber: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    // miscName: new FormControl(''),
    patientdoctor: new UntypedFormControl(''),
    patienthomehospital: new UntypedFormControl(''),
    designationId: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    scheduleusertypeid: new UntypedFormControl(''),
    allowScheduleEdit: new UntypedFormControl(''),
    AllowTechScheduleEdit: new UntypedFormControl(''),
    signaturepicfilepath: new UntypedFormControl(''),
    signaturepicfilename: new UntypedFormControl(''),
    jobstatus: new UntypedFormControl(''),
    userName: new UntypedFormControl('', Validators.pattern(Globals.EmailRegex)),
    VerifyContactUsername: new UntypedFormControl('', Validators.pattern(Globals.EmailRegex)),
    password: new UntypedFormControl(''),
    VerifyPassword: new UntypedFormControl(''),
    ContactPinid: new UntypedFormControl(''),
    VerifyPinID: new UntypedFormControl(''),
    hintquestion: new UntypedFormControl(''),
    hintanswer: new UntypedFormControl(''),
    SameAsMailingAddress: new UntypedFormControl(''),
    PONo: new UntypedFormControl(''),
    previousPONo: new UntypedFormControl(''),
    billingMeans: new UntypedFormControl(''),
    billingTerms: new UntypedFormControl(''),
    vendoraccountno: new UntypedFormControl(''),
    creditcardno: new UntypedFormControl(''),
    defaultTaskHome: new UntypedFormControl(''),
    IsAdminPermission: new UntypedFormControl(''),
  });

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if ((this.contactDetailForm.dirty || (this.notes !== undefined && this.notes.Note !== '' ) )
    && (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else {
      return true;
    }
  }

  ngAfterViewChecked() {

    this.scrollHelper.doScroll();
        if (this.attachment !== undefined && this.AttachMentUpdated === false) {
          this.attachment.DocId = this.f.contactid.value;
          this.attachment.ImageName = this.f.signaturepicfilename.value;
          this.attachment.ImagePath = this.f.signaturepicfilepath.value;
          this.AttachMentUpdated = true;
        }
  }

  constructor(
    private contactApi: ContactApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private orgUnitApi: OrganizationalUnitApiService
  ) {}

  ngAfterViewInit() {

    if (this.f.contactid.value == '0') {
    this.f.VerifyContactUsername.setValue('');
    this.f.ContactPinid.setValue('');
    }
  }

  ngOnInit() {

    AppComponent.ShowLoader();

    const accesToken = this.auth.getAccessToken();
    this.LoggedinUserDesignationID = accesToken.DesignationName;
    this.SetPageRights();
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.f.contactid.setValue(params['id'] || '0');

      this.fillNonAdminUserList();

      if (this.f.contactid.value !== '0') {
        this.setModel();
      } else {
        this.fillDivisionList();
        this.fillTitles();
        this.fillContactTypeList();
        this.fillCountryList();
        this.fillDesignationList();
        this.fillStatus();
        this.fillHintQuestions();
        this.fillScheduleUserType();
        this.fillJobStatus();
        this.fillBillingMeans();
        this.f.VerifyContactUsername.setValue('');
        this.f.ContactPinid.setValue('');
        this.auth.GetCurrentUserDivision().subscribe(division => {
          if(division.length===1){
            let divisions = [];
            divisions.push(division[0].toString());
            this.f.divisionId.setValue(divisions);
        }
      }, error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      });
      }
    });
    AppComponent.HideLoader();
  }

  get f() {
    return this.contactDetailForm.controls;
  }

  setModel() {

    AppComponent.ShowLoader();
    const contactCreate = { Id: this.f.contactid.value };
    this.fillDivisionList();
    this.fillTitles();
    this.fillContactTypeList();
    this.fillCountryList();
    this.fillDesignationList();
    this.fillStatus();
    this.fillHintQuestions();
    this.fillScheduleUserType();
    this.fillJobStatus();
    this.fillBillingMeans();
    this.getOrganizationDetail();
    this.contactApi.GetContactDetailsByID(contactCreate).subscribe(
      success => {
        //console.log(success);


        this.contactnumber = success.contactnumber;

        this.f.contactType.setValue(success.contacttype);
        this.f.contactid.setValue(success.contactid);

        // if(this.f.contactType.value == 'Vendor' && (this.userDesignation == 'Senior Technologist'  || this.userDesignation == 'Senior Technologist'))
        // {
        //   this.blnshowSave = true;
        //   this.blnshowEdit = true;
        // }
        // else
        // {
        //   this.blnshowSave = false;
        //   this.blnshowEdit = false;
        // }

        this.notes.Note = '';
        this.notes.showHours = false;
        this.f.title.setValue(success.title);
        this.f.givenName.setValue(success.givenname);
        this.f.familyName.setValue(success.familyname);
        this.f.miscName.setValue(success.miscname);
        this.f.divisionId.setValue(success.divisionId);
        this.contactApi
          .GetContactDivisionIds(contactCreate)
          .subscribe(success1 => {
            let divisionIds = [];
            for (let childObj in success1) {
              divisionIds.push(success1[childObj].divisionid.toString());
            }
            this.f.divisionId.setValue(divisionIds);

            if (this.DivisionList.length == divisionIds.length) {
              this.f.ALLdivisionId.setValue(['all']);
              this.allClearValue = "Clear";
            }
            else{
              this.allClearValue = "ALL";
            }
          });


//
//           this.AlldivisionIds.push('all');
//           this.f.ALLdivisionId.setValue(this.AlldivisionIds);
        this.f.mailingaddress.setValue(success.mailingaddress);
        this.f.mailingcity.setValue(success.mailingcity);
        this.f.mailingcountry.setValue(success.mailingcountry);
        this.fillCountryStateList(success.mailingcountry);
        this.fillBillingCountryStateList(success.billingcountry);

        this.f.mailingzipCode.setValue(success.mailingzipcode);

        this.f.billingaddress.setValue(success.billingaddress);
        this.f.billingcity.setValue(success.billingcity);
        this.f.billingcountry.setValue(success.billingcountry);
        this.f.billingzipCode.setValue(success.billingzipCode);
        this.f.phone.setValue(success.phone);
        this.f.extension.setValue(success.extension);
        this.f.homePhone.setValue(success.homephone);
        this.f.pager.setValue(success.pager);
        this.f.tollFreeFax.setValue(success.tollfreefax);
        this.f.emailAddress.setValue(success.emailaddress);
        this.f.permanentNotes.setValue(success.permanentnotes);
        this.f.tollFreeNumber.setValue(success.tollfreenumber);
        this.f.cellPhone.setValue(success.cellphone);
        this.f.fax.setValue(success.fax);
        this.f.otherNumber.setValue(success.othernumber);
        // this.f.miscName.setValue(success.miscname);
        this.f.patientdoctor.setValue(success.patientdoctor);
        this.f.patienthomehospital.setValue(success.patienthomehospital);
        this.f.mailingstate.setValue(success.mailingstate);
        this.f.billingstate.setValue(success.billingstate);

        if (success.designationid != null && success.designationid != undefined) {
          this.f.designationId.setValue(success.designationid.toString());
        }
        this.f.status.setValue(success.status);

        if (success.designation)
          this.contactDesignation = success.designation;

        this.f.scheduleusertypeid.setValue(success.scheduleusertypeid ? success.scheduleusertypeid : '');
        if (success.allowScheduleEdit.substring(0, 1) === '1') {
          this.f.allowScheduleEdit.setValue(true);
        }
        if (success.allowScheduleEdit.substring(1, 2) === '1') {
          this.f.AllowTechScheduleEdit.setValue(true);
        }
        else {
          this.f.AllowTechScheduleEdit.setValue(false);

        }


        // this.f.signaturepicfilepath.setValue(success.signaturepicfilepath);
        this.f.jobstatus.setValue(success.jobstatus);
        this.f.userName.setValue(success.username);
        this.f.VerifyContactUsername.setValue(success.username);
        this.f.password.setValue(success.password);
        this.f.VerifyPassword.setValue(success.password);
        this.f.ContactPinid.setValue(success.pinid);
        this.f.VerifyPinID.setValue(success.pinid);
        this.f.hintquestion.setValue(success.hintquestion);
        this.f.hintanswer.setValue(success.hintanswer);
        // this.f.miscName.setValue(success.miscName);
        this.f.PONo.setValue(success.pono);
        this.f.previousPONo.setValue(success.previouspono);
        this.f.billingMeans.setValue(success.billingmeans);
        this.f.billingTerms.setValue(success.billingterms);
        this.f.vendoraccountno.setValue(success.vendoraccountno);
        this.f.creditcardno.setValue(success.creditcardno);
        this.f.signaturepicfilepath.setValue(success.signaturepicfilepath);
        this.f.signaturepicfilename.setValue(success.signaturepicfilename);
        // this.isAdminPermission = success.isAdminPermission;

        this.f.defaultTaskHome.setValue(success.defaultTaskHome);
        if (this.f.defaultTaskHome.value == 1) {
          this.f.defaultTaskHome.setValue("true");
        }
        if (this.f.defaultTaskHome.value == 0) {
          this.f.defaultTaskHome.setValue("false");
        }

        this.f.IsAdminPermission.setValue(success.isAdminPermission);

        // if (this.notes.NoteList.length > 0) {
          this.contactApi.GetContactNotesByID(contactCreate).subscribe(list => {
            if(this.notes)
              this.notes.NoteList = list;
          });
        // }

        if (this.attachmentList.PrevAttachments.length > 0) {
          this.contactApi
          .GetContactattachementsByID(contactCreate)
          .subscribe(list => {
            this.attachmentList.PrevAttachments = list;

          });
        }
        this.ContactTypeDropDownChanged();
        this.designationIdDropDownChanged();
        this.fillNonAdminUserList();

        this.collapsedContactDetailsClass = 'collapsed';
        this.collapseariaexpanded = false;
        this.collapsedContactDetails = 'panel-collapse collapse';

        this.collapsedUserLoginClass = 'collapsed';
        this.collapseariaexpandedUserLogin = false;
        this.collapsedUserLoginDetails = 'panel-collapse collapse';

        this.DisableControls();
        this.SetPageRights();
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  getOrganizationDetail() {

    const orgUnit = { Id: 1 };
    this.orgUnitApi.GetOrgUnitDetails(orgUnit).subscribe(
      success => {
        if (success) {

          this.isHomeProgram = success.isHomeProgram ? success.isHomeProgram : false;
        }
      },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
  }


  DisableControls() {
    this.disabled = true;
    this.f.contactType.disable();
    this.f.contactid.disable();
  //this.notes.disable();
  //this.notes.showHours = false;
  this.f.title.disable();
  this.f.givenName.disable();
  this.f.familyName.disable();
  this.f.miscName.disable();
  this.f.ALLdivisionId.disable();
  this.f.divisionId.disable();
  this.f.mailingaddress.disable();
  this.f.mailingcity.disable();
  this.f.mailingcountry.disable();

  this.f.mailingzipCode.disable();

  this.f.billingaddress.disable();
  this.f.billingcity.disable();
  this.f.billingcountry.disable();
  this.f.billingzipCode.disable();
  this.f.phone.disable();
  this.f.extension.disable();
  this.f.homePhone.disable();
  this.f.pager.disable();
  this.f.tollFreeFax.disable();
  this.f.emailAddress.disable();
  this.f.permanentNotes.disable();
  this.f.tollFreeNumber.disable();
  this.f.cellPhone.disable();
  this.f.fax.disable();
  this.f.otherNumber.disable();
  // this.f.miscName.setValue(success.miscname);
  this.f.patientdoctor.disable();
  this.f.patienthomehospital.disable();
  this.f.mailingstate.disable();
  this.f.billingstate.disable();
  this.f.designationId.disable();
  this.f.status.disable();

  this.f.scheduleusertypeid.disable();
  this.f.allowScheduleEdit.disable();
  this.f.AllowTechScheduleEdit.disable();

  // this.f.signaturepicfilepath.setValue(success.signaturepicfilepath);
  this.f.jobstatus.disable();
  this.f.IsAdminPermission.disable();
  this.f.userName.disable();
  this.f.VerifyContactUsername.disable();
  this.f.password.disable();
  this.f.VerifyPassword.disable();
  this.f.ContactPinid.disable();
  this.f.VerifyPinID.disable();

  this.f.hintquestion.disable();
  this.f.hintanswer.disable();
  // this.f.miscName.setValue(success.miscName);
  this.f.PONo.disable();
  this.f.previousPONo.disable();
  this.f.billingMeans.disable();
  this.f.billingTerms.disable();
  this.f.vendoraccountno.disable();
  this.f.creditcardno.disable();
  this.f.signaturepicfilepath.disable();
  this.f.signaturepicfilename.disable();
  this.f.defaultTaskHome.disable();
}

  EnableControls() {
    this.disabled = false;

    // this.f.contactType.enable();
    this.f.contactid.enable();
  //this.notes.enable()
  //this.notes.showHours = false;
  this.f.title.enable();
  this.f.givenName.enable();
  this.f.familyName.enable();
  this.f.miscName.enable();
  this.f.ALLdivisionId.enable();
  this.f.divisionId.enable();
  this.f.mailingaddress.enable();
  this.f.mailingcity.enable();
  this.f.mailingcountry.enable();

  this.f.mailingzipCode.enable();
  this.f.billingaddress.enable();
  this.f.billingcity.enable();
  this.f.billingcountry.enable();
  this.f.billingzipCode.enable();
  this.f.phone.enable();
  this.f.extension.enable();
  this.f.homePhone.enable();
  this.f.pager.enable();
  this.f.tollFreeFax.enable();
  this.f.emailAddress.enable();
  this.f.permanentNotes.enable();
  this.f.tollFreeNumber.enable();
  this.f.cellPhone.enable();
  this.f.fax.enable();
  this.f.otherNumber.enable();
  // this.f.miscName.setValue(success.miscname);
  this.f.patientdoctor.enable();
  this.f.patienthomehospital.enable();
  this.f.mailingstate.enable();
  this.f.billingstate.enable();
  this.f.designationId.enable()
  this.f.status.enable();

  this.f.scheduleusertypeid.enable();
  this.f.allowScheduleEdit.enable();
  this.f.AllowTechScheduleEdit.enable();

    // this.f.signaturepicfilepath.setValue(success.signaturepicfilepath);
    this.f.jobstatus.enable();
    this.f.IsAdminPermission.enable();
    this.f.userName.enable();
    this.f.VerifyContactUsername.enable();
    if (this.currentUserId == this.f.contactid.value || this.userDesignation == 'Administrator') {
      this.f.password.enable();
      this.f.VerifyPassword.enable();
      this.f.ContactPinid.enable();
      this.f.VerifyPinID.enable();
      this.f.hintquestion.enable();
      this.f.hintanswer.enable();
    }
    // this.f.miscName.setValue(success.miscName);
    this.f.PONo.enable();
    this.f.previousPONo.enable();
    this.f.billingMeans.enable();
    this.f.billingTerms.enable();
    this.f.vendoraccountno.enable();
    this.f.creditcardno.enable();
    this.f.signaturepicfilepath.enable();
    this.f.signaturepicfilename.enable();
    this.f.defaultTaskHome.enable();
  }


executeEnableDisablectls = function(Source) {
  this.EnableControls();
};

  executeContactCreateUpdate = function(Source) {


    AppComponent.loading = true;

    if (this.f.contactType.value === 'User') {
      this.f.VerifyContactUsername.setValidators([Validators.required, Validators.pattern(Globals.EmailRegex)
        , ContactCustomValidators.UserNameMatch(this.f.userName)]);
      this.f.VerifyContactUsername.updateValueAndValidity();

      // this.f.VerifyPassword.setValidators([Validators.required,ContactCustomValidators.passwordsMatch(this.f.password)]);
      // this.f.VerifyPassword.updateValueAndValidity();

      this.f.VerifyPinID.setValidators([Validators.required,ContactCustomValidators.passwordsMatch(this.f.ContactPinid)]);
      this.f.VerifyPinID.updateValueAndValidity();

    } else {
      this.f.password.clearValidators();
      this.f.password.updateValueAndValidity();
      this.f.VerifyPassword.clearValidators();
      this.f.VerifyPassword.updateValueAndValidity();

      this.f.VerifyPinID.clearValidators();
      this.f.VerifyPinID.updateValueAndValidity();
    }
    this.isValidFormSubmitted = false;
    if (this.contactDetailForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;
    const contactCreate = this.prepareCreateViewModel();

    if (contactCreate.contactid === '0') {
      this.contactApi.CreateContactDetails(contactCreate).subscribe(
        success => {
          // this.contactCreate = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.router.navigate([this.ListURL]);
        },
        error => {
          if (
            error &&
            error.error &&
            error.error.returnMessage &&
            error.error.returnMessage[0]
          ) {
            AppComponent.addAlert(error.error.returnMessage[0], "error");
            AppComponent.HideLoader();
          }
          else {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        }
      );
    } else {
      this.contactApi.UpdateContactDetails(contactCreate).subscribe(
        success => {
          // this.contactCreate = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          if (
            error &&
            error.error &&
            error.error.returnMessage &&
            error.error.returnMessage[0]
          ) {
            AppComponent.addAlert(error.error.returnMessage[0], "error");
            AppComponent.HideLoader();
          }
          else {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        }
      );
    }
  };

  prepareCreateViewModel() {
    const contactCreate = <any>{};


    contactCreate.contactType = this.f.contactType.value;
    contactCreate.contactid = this.f.contactid.value;
    contactCreate.contactnumber = this.contactnumber;
    contactCreate.title = this.f.title.value;
    contactCreate.givenName = this.f.givenName.value;
    contactCreate.familyName = this.f.familyName.value;
    contactCreate.familyName = this.f.familyName.value;
    contactCreate.miscName = this.f.miscName.value;
    contactCreate.divisionIds = this.f.divisionId.value;

    if (this.f.defaultTaskHome.value === true) {

     const divi = this.DivisionList.filter(
        division => division.text === 'HOME');
        if(divi.length > 0) {
          contactCreate.divisionId = divi[0].value;
        }

    } else {
      contactCreate.divisionId = null;
    }

    contactCreate.mailingaddress = this.f.mailingaddress.value;
    contactCreate.mailingcity = this.f.mailingcity.value;
    contactCreate.mailingcountry = this.f.mailingcountry.value;
    contactCreate.mailingzipCode = this.f.mailingzipCode.value;
    contactCreate.mailingstate = this.f.mailingstate.value;
    contactCreate.billingaddress = this.f.billingaddress.value;
    contactCreate.billingcity = this.f.billingcity.value;
    contactCreate.billingcountry = this.f.billingcountry.value;
    contactCreate.billingzipCode = this.f.billingzipCode.value;
    contactCreate.billingstate = this.f.billingstate.value;
    contactCreate.phone = this.f.phone.value;
    contactCreate.extension = this.f.extension.value;
    contactCreate.homePhone = this.f.homePhone.value;
    contactCreate.pager = this.f.pager.value;
    contactCreate.tollFreeFax = this.f.tollFreeFax.value;
    contactCreate.emailAddress = this.f.emailAddress.value;
    contactCreate.permanentNotes = this.f.permanentNotes.value;
    contactCreate.tollFreeNumber = this.f.tollFreeNumber.value;
    contactCreate.cellPhone = this.f.cellPhone.value;
    contactCreate.fax = this.f.fax.value;
    contactCreate.otherNumber = this.f.otherNumber.value;
    contactCreate.miscName = this.f.miscName.value;
    contactCreate.patientdoctor = this.f.patientdoctor.value;
    contactCreate.patienthomehospital = this.f.patienthomehospital.value;
    contactCreate.designationId = this.f.designationId.value;
    if (this.notes !== undefined) {
    contactCreate.notes = this.notes.Note;
    }

    contactCreate.designationName = this.DesignationList.filter(
      (item, index) => item.value === this.f.designationId.value
    )[0].text;
    contactCreate.status = this.f.status.value;

    contactCreate.scheduleusertypeid = this.f.scheduleusertypeid.value;
    let allowScheduleEdit = '';
    if (this.f.allowScheduleEdit.value === true) {
      allowScheduleEdit += '1';
    } else {
      allowScheduleEdit += '0';
    }

    if (this.f.AllowTechScheduleEdit.value === true) {
      allowScheduleEdit += '1';
    } else {
      allowScheduleEdit += '0';
    }

    contactCreate.allowScheduleEdit = allowScheduleEdit;
    contactCreate.signaturepicfilepath = this.f.signaturepicfilepath.value;
    contactCreate.jobstatus = this.f.jobstatus.value;
    contactCreate.userName = this.f.userName.value;
    contactCreate.VerifyUsername = this.f.VerifyContactUsername.value;
    contactCreate.password = this.f.password.value;
    contactCreate.VerifyPassword = this.f.VerifyPassword.value;
    contactCreate.pinid = this.f.ContactPinid.value;
    contactCreate.VerifyPinID = this.f.VerifyPinID.value;
    contactCreate.hintquestion = this.f.hintquestion.value;
    contactCreate.hintanswer = this.f.hintanswer.value;
    contactCreate.miscName = this.f.miscName.value;
    contactCreate.PONo = this.f.PONo.value;
    contactCreate.previousPONo = this.f.previousPONo.value;
    contactCreate.billingMeans = this.f.billingMeans.value;
    contactCreate.billingTerms = this.f.billingTerms.value;
    contactCreate.vendoraccountno = this.f.vendoraccountno.value;
    contactCreate.creditcardno = this.f.creditcardno.value;
    contactCreate.createdBy = this.createdBy;
    contactCreate.modifiedBy = this.createdBy;
    contactCreate.defaultTaskHome = this.f.defaultTaskHome.value;
    contactCreate.IsAdminPermission = this.f.IsAdminPermission.value;
    // if (this.LoggedinUserDesignationID == 'Administrator' && this.f.contactType.value == 'User' && this.contactDesignation == 'Administrator') {
    //   if (this.f.IsAdminPermission.value && this.f.IsAdminPermission.value.length > 0) {
    //     const objAdminPermissionList = [];

    //     this.f.IsAdminPermission.value.forEach((user) => {
    //       objAdminPermissionList.push({
    //         value: user.value,
    //         text: user.text,
    //         isAdminPermission: user.isAdminPermission,
    //       });
    //     });

    //     contactCreate.adminPermissionList = objAdminPermissionList;
    //   }
    // }

    if (this.attachmentList != null && this.attachmentList != undefined) {
      this.attachmentList.moduleName = attachmentFormModules[attachmentFormModules.contacts];
      this.attachmentList.UploadBy = this.currentUserId;

      contactCreate.attachmentDetails = this.attachmentList.GenerateAttachmentList();
    }

    if (this.attachment !== undefined) {
       contactCreate.signaturepicfilepath = this.attachment.ImagePath;
       contactCreate.signaturepicfilename = this.attachment.ImageName;
    }
    //console.log(contactCreate);
    return contactCreate;
  }
  fillDivisionList() {
    // this.contactApi
    //   .FillDivision()
    //   .subscribe(
    //     list =>
    //       (this.DivisionList = list.filter((item, index) => item.value !== ''))
    //   );
    //
      this.contactApi
      .FillDivisionWithPrefix()
      .subscribe(
        list =>
          (this.DivisionList = list.filter((item, index) => item.value !== ''))
      );
  }

  fillContactTypeList() {

    this.contactApi.FillUserType().subscribe(list =>
      {
        this.ContactTypeList = list;
        if(this.f.contactid.value == 0)
        {

          if((this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist') && (!this.isAdminPermissionLoggedInUser))
          {
              this.ContactTypeList = list.filter(x => x.text != "User");
          }
        }
      }
      );
  }

  fillDesignationList() {
    this.contactApi
      .FillDesignation()
      .subscribe(list => (this.DesignationList = list));
  }

  fillStatus() {
    this.StatusList = [
      { value: '', text: 'Select' },
      { value: 'Active', text: 'Active' },
      { value: 'Inactive', text: 'Inactive' }
    ];
  }

  fillScheduleUserType() {

    this.contactApi
      .FillScheduleUserType()
      .subscribe(list => (this.ScheduleUserTypeList = list));
    // this.ScheduleUserTypeList = [
    //   {value:'',text:'Select'},
    //   {value:'1',text:'Administrator'},
    //   {value:'3',text:'Technologist'},
    //   {value:'5',text:'Clinic Co-Ordinator'},
    //   {value:'6',text:'Nurse'},
    //   {value:'7',text:'Secretary'},
    //   {value:'8',text:'Dialysis Assistant'},
    //   {value:'9',text:'Manager'}

    // ];
  }

  fillJobStatus() {
    this.JobStatusList = [
      { value: '1', text: 'Full Time' },
      { value: '2', text: 'Part Time' },
      { value: '3', text: 'Casual' }
    ];
  }

  fillHintQuestions() {
    this.HintQuestionList = [
      { value: '', text: 'Select' },
      {
        value: 'What is your place of Birth?',
        text: 'What is your place of Birth?'
      },
      {
        value: 'What is the name of your first school?',
        text: 'What is the name of your first school?'
      },
      {
        value: 'Which is your favorite pet?',
        text: 'Which is your favorite pet?'
      },
      {
        value: 'What is your mother’s maiden name?',
        text: 'What is your mother’s maiden name?'
      }
    ];
  }

  fillBillingMeans() {
    this.BillingMeanList = [
      { value: '', text: 'Select' },
      {
        value: 'Cheque',
        text: 'Cheque'
      },
      {
        value: 'PO',
        text: 'PO'
      },
      {
        value: 'Cash',
        text: 'Cash'
      },
      {
        value: 'COD',
        text: 'COD'
      }
    ];
  }

  fillTitles() {
    this.TitleList = [
      { value: '', text: 'Select' },

      {
        value: '1',
        text: 'Mr.'
      },
      {
        value: '2',
        text: 'Mrs.'
      },
      {
        value: '3',
        text: 'Miss'
      },
      {
        value: '4',
        text: 'Ms'
      },
      {
        value: '5',
        text: 'Dr'
      }
    ];
  }

  fillCountryList() {
    this.contactApi.FillCountry().subscribe(list => (this.CountryList = list));
  }

  fillCountryStateList(CountryId) {
    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.contactApi
        .FillState(CountryIds)
        .subscribe(list => (this.StateList = list));
    } else {
      this.StateList = [];
    }
  }

  fillBillingCountryStateList(CountryId) {
    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.contactApi
        .FillState(CountryIds)
        .subscribe(list => (this.BillingStateList = list));
    } else {
      this.BillingStateList = [];
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const contactObj = { Id: this.f.contactid.value };
        this.contactApi.DeleteContactDetails(contactObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Contact detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  formatPhone(phone) {
    let val = this.f[phone].value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    val = val.substring(0, 14);
    this.f[phone].setValue(val);
  }

  SetPageRights() {

    // this.auth.GetPageRights('Contact').subscribe(list => {
    //   // console.log(list);
    //   if (this.f.contactid.value !== '0') {
    //     // this.blnshowSave = list[actionType.edit] === '1';
    //     //this.blnshowDelete = list[actionType.delete] === '1';
    //     // this.blnshowEdit = true;

    //   } else {
    //     // this.blnshowSave = list[actionType.create] === '1';
    //     //this.blnshowDelete = false;
    //   // this.blnshowEdit = false;

    //   }
    // });



    this.blnshowEdit = false;
    this.blnshowSave = false;

    if ((this.userDesignation == 'Administrator') && (this.f.contactid.value !== '0' && this.f.contactid.value != '')) {
      this.blnshowDelete = true;
      this.blnshowEdit = true;
    }
    // if(this.userDesignation == 'Administrator' || this.currentUserId == this.f.contactid.value)
    if (this.userDesignation == 'Administrator' || this.isAdminPermissionLoggedInUser || this.currentUserId == this.f.contactid.value || ((this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist') && (this.f.contactType.value == 'Vendor' || this.f.contactType.value == 'Misc'))) {
      if (!(this.isAdminPermissionLoggedInUser && this.contactDesignation == 'Adminstrator')) {

        if (this.f.contactid.value) {
          this.blnshowEdit = true;
        }
        this.blnshowSave = true;
      }
    }

    if((this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist')
      && this.f.contactType.value != 'User')
    {
      this.blnshowSave = true;
    }
  }

  CountryStateDropDownChanged() {
    this.fillCountryStateList(this.f.mailingcountry.value);
  }

  CountryStateBillingDropDownChanged() {
    this.fillBillingCountryStateList(this.f.billingcountry.value);
  }

  ContactTypeDropDownChanged() {
    if (this.f.contactType.value === "User") {

      this.f.emailAddress.setValidators([Validators.pattern(Globals.EmailRegex)]);
      this.f.emailAddress.updateValueAndValidity();

      this.f.userName.setValidators([Validators.required, Validators.pattern(Globals.EmailRegex)]);
      this.f.userName.updateValueAndValidity();

      this.f.VerifyContactUsername.setValidators([Validators.required,
      Validators.pattern(Globals.EmailRegex), ContactCustomValidators.UserNameMatch(this.f.userName)]);
      this.f.VerifyContactUsername.updateValueAndValidity();

      // this.f.password.setValidators([Validators.pattern(Globals.PasswordRegx),Validators.required]);
      // this.f.password.updateValueAndValidity();

      // this.f.VerifyPassword.setValidators([Validators.required,ContactCustomValidators.passwordsMatch(this.f.password)]);
      // this.f.VerifyPassword.updateValueAndValidity();

      this.f.ContactPinid.setValidators([Validators.required, Validators.pattern(Globals.MinDigit)]);
      this.f.ContactPinid.updateValueAndValidity();

      this.f.VerifyPinID.setValidators([Validators.required, ContactCustomValidators.passwordsMatch(this.f.ContactPinid)]);
      this.f.VerifyPinID.updateValueAndValidity();

      this.f.familyName.setValidators(Validators.required);
      this.f.familyName.updateValueAndValidity();

      this.f.designationId.setValidators(Validators.required);
      this.f.designationId.updateValueAndValidity();

      this.f.jobstatus.setValidators(Validators.required);
      this.f.jobstatus.updateValueAndValidity();

      this.f.status.setValidators(Validators.required);
      this.f.status.updateValueAndValidity();

      this.getOrganizationDetail();

    } else {
      this.f.emailAddress.clearValidators();
      this.f.emailAddress.updateValueAndValidity();

      this.f.userName.clearValidators();
      this.f.userName.updateValueAndValidity();

      this.f.VerifyContactUsername.clearValidators();
      this.f.VerifyContactUsername.updateValueAndValidity();

      this.f.password.clearValidators();
      this.f.password.updateValueAndValidity();

      this.f.VerifyPassword.clearValidators();
      this.f.VerifyPassword.updateValueAndValidity();

      this.f.ContactPinid.clearValidators();
      this.f.ContactPinid.updateValueAndValidity();

      this.f.VerifyPinID.clearValidators();
      this.f.VerifyPinID.updateValueAndValidity();

      this.f.familyName.clearValidators();
      this.f.familyName.updateValueAndValidity();

      this.f.designationId.clearValidators();
      this.f.designationId.updateValueAndValidity();

      this.f.jobstatus.clearValidators();
      this.f.jobstatus.updateValueAndValidity();

      this.f.status.clearValidators();
      this.f.status.updateValueAndValidity();
    }

    if (this.f.contactType.value === "Vendor" || this.f.contactType.value === "User" || this.f.contactType.value === "Building Services" || this.f.contactType.value === "Misc") {

      if (this.f.contactType.value === "Vendor" || this.f.contactType.value === "Misc" || this.f.contactType.value === "User") {
        this.f.mailingaddress.clearValidators();
        this.f.mailingaddress.updateValueAndValidity();

        this.f.mailingcity.clearValidators();
        this.f.mailingcity.updateValueAndValidity();

        this.f.mailingcountry.clearValidators();
        this.f.mailingcountry.updateValueAndValidity();

        this.f.mailingzipCode.clearValidators();
        this.f.mailingzipCode.updateValueAndValidity();

        this.f.mailingstate.clearValidators();
        this.f.mailingstate.updateValueAndValidity();

        this.f.phone.clearValidators();
        this.f.phone.updateValueAndValidity();
      }

      if (this.f.contactType.value === "User") {
        this.f.cellPhone.setValidators(Validators.required);
        this.f.cellPhone.updateValueAndValidity();
      }
      else {
        this.f.cellPhone.clearValidators();
        this.f.cellPhone.updateValueAndValidity();
      }

    }
    else {
      this.f.mailingaddress.setValidators(Validators.required);
      this.f.mailingaddress.updateValueAndValidity();

      this.f.mailingcity.setValidators(Validators.required);
      this.f.mailingcity.updateValueAndValidity();

      this.f.mailingcountry.setValidators(Validators.required);
      this.f.mailingcountry.updateValueAndValidity();

      this.f.mailingzipCode.setValidators(Validators.required);
      this.f.mailingzipCode.updateValueAndValidity();

      this.f.mailingstate.setValidators(Validators.required);
      this.f.mailingstate.updateValueAndValidity();

      this.f.phone.setValidators(Validators.required);
      this.f.phone.updateValueAndValidity();
      if (this.f.contactType.value === "User") {
        this.f.cellPhone.setValidators(Validators.required);
        this.f.cellPhone.updateValueAndValidity();
      }
      else {
        this.f.cellPhone.clearValidators();
        this.f.cellPhone.updateValueAndValidity();
      }
    }

    if (this.f.contactType.value === "User") {
      this.f.phone.clearValidators();
      this.f.phone.updateValueAndValidity();
    }

    if (this.f.contactType.value === "Municipal Services" || this.f.contactType.value === "Building Services" || this.f.contactType.value === "User") {
      this.f.familyName.setValidators(Validators.required);
      this.f.familyName.updateValueAndValidity();

    }
    else {
      this.f.familyName.clearValidators();
      this.f.familyName.updateValueAndValidity();

    }





    //  this.f.billingzipCode.setValidators([Validators.pattern(Globals.PostalcodeRegx)]);
    //  this.f.billingzipCode.updateValueAndValidity();

    //  this.f.mailingzipCode.setValidators([Validators.pattern(Globals.PostalcodeRegx)]);
    //  this.f.mailingzipCode.updateValueAndValidity();
  }

  designationIdDropDownChanged() {
    // if(this.f.designationId.value === "7")
    // {
    //   this.f.scheduleusertypeid.setValue("1");
    // }
  }

  ChangeSameAsMailing() {
    if (this.f.SameAsMailingAddress.value === true) {
      this.f.billingaddress.disable();
      this.f.billingcity.disable();
      this.f.billingcountry.disable();
      this.f.billingzipCode.disable();
      this.f.billingstate.disable();
      this.UpdateBillingAddress();
    } else {
      this.f.billingaddress.enable();
      this.f.billingcity.enable();
      this.f.billingcountry.enable();
      this.f.billingzipCode.enable();
      this.f.billingstate.enable();
    }
  }

  UpdateBillingAddress() {
    if (this.f.SameAsMailingAddress.value === true) {
      this.f.billingaddress.setValue(this.f.mailingaddress.value);
      this.f.billingcity.setValue(this.f.mailingcity.value);
      this.f.billingcountry.setValue(this.f.mailingcountry.value);
      this.f.billingzipCode.setValue(this.f.mailingzipCode.value);
      this.BillingStateList = this.StateList;
      this.f.billingstate.setValue(this.f.mailingstate.value);
    }
  }

  CheckUserNameMismatch() {
    this.f.VerifyContactUsername.setValidators([Validators.required, Validators.pattern(Globals.EmailRegex), ContactCustomValidators.UserNameMatch(this.f.userName)]);
    this.f.VerifyContactUsername.updateValueAndValidity();
  }

  CheckPasswordMismatch() {
    // this.f.VerifyPassword.setValidators([Validators.required,ContactCustomValidators.passwordsMatch(this.f.password)]);
    //   this.f.VerifyPassword.updateValueAndValidity();
  }

  CheckpinIDMismatch(event) {
    this.f.ContactPinid.setValidators([Validators.required, ContactCustomValidators.checkpassword(this.f.ContactPinid), ContactCustomValidators.checkpindId(this.f.ContactPinid)]);
    this.f.ContactPinid.updateValueAndValidity();

    this.f.VerifyPinID.setValidators([Validators.required, ContactCustomValidators.passwordsMatch(this.f.ContactPinid)]);
    this.f.VerifyPinID.updateValueAndValidity();
  }

  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }

  SetAllButtons(args) {


    if (args.source.checked == true) {
      let divisionIds = [];
      for (let childObj in this.DivisionList) {
        divisionIds.push(this.DivisionList[childObj].value);
      }
      this.f.divisionId.setValue(divisionIds);
      this.allClearValue = "Clear";

      // this.f.divisionId.disable();
    }
    else {
      this.f.divisionId.reset();
      this.allClearValue = "ALL";
      // this.f.divisionId.enable();

    }
  }

  isChecked(val) {
    if (this.f.divisionId.value && this.f.divisionId.value.includes(val))
      return true;
    else
      return false;
  }

  CheckUncheckAll() {
    if (this.DivisionList.length == this.f.divisionId.value.length) {
      this.f.ALLdivisionId.setValue(['all']);
      this.allClearValue = "Clear";
    }
    else {
      this.f.ALLdivisionId.setValue([]);
      this.allClearValue = "ALL";
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 && charCode != 40 && charCode != 41) {
      return false;
    }
    return true;
  }

  fillNonAdminUserList() {

      // this.contactApi.GetNonAdminContactDetails().subscribe(list => {
      //   this.NonAdminUserList = list;

      //   const nonAdminUserPermissionList = [];
      //   list.filter((item, index) => item.isAdminPermission == true).forEach((user) => {
      //     nonAdminUserPermissionList.push({
      //       value: user.value,
      //       text: user.text,
      //       isAdminPermission: user.isAdminPermission,
      //     });
      //   });

      //   this.f.IsAdminPermission.setValue(nonAdminUserPermissionList);
      // });
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

}


import { CommonApiService } from 'src/app/API/commonapi.service.';
import { Component, ViewChild } from '@angular/core';
import { ContactApiService } from '../API/contactapi.service';
import { TableHeadsService } from '../Core/tableheads.service';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';


@Component({
  selector: 'ContactSearch',
  templateUrl: './ContactSearch.component.html',

})
export class ContactSearchComponent {

  constructor( private commonapi : CommonApiService, private contactapi: ContactApiService, private router: Router, private dataGridService: TableHeadsService
    , private auth: AuthService) {
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    this.setPage();

  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  alphaBetsList = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  loading = true;
  GivenName = '';
  UserName = '';
  FamilyName = '';
  ContactTypeList = [];
  DivisionList = [];
  DesignationList = [];
  StatusList = [];
  Status = '';
  ContactType = '';
  Division = '';
  Designation = '';
  ContactId = '';
  prefixAlphabet='';
  blnDirtySearch = false;
  tableHeaders;
  page = new Page();
  rows ;
  AddUrl = '/Contact/ContactDetail';
  currentPageNumber = 1;
  sortExpression = 'givenName';
  sortDirection = 'ASC';
  pageSize = 100000;
  ShowSearch = false;
  title = 'Contact';
  searchText = 'Search Records';
  defaultSort;
  blnshowAddNew = false;
  userDesignation = this.auth.GetDesignationName();
  isAdminPermissionLoggedInUser = this.auth.GetIsAdminPermission();
  ngOnInit() {
    this.SetPageRights();
    this.auth.GetCurrentUserDivision().subscribe(division => {
      // AppComponent.loading=true;
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'id', '4');
      this.dataGridService.addHeader('Name / Business Name', 'givenname', '25');
      // this.dataGridService.addHeader('Contact Id', 'contactnumber', '8');
      this.dataGridService.addHeader('E-mail', 'emailaddress', '27');
      this.dataGridService.addHeader('Phone', 'cellphone', '14')
      this.dataGridService.addHeader('Contact Type', 'contacttype', '12');
      this.dataGridService.addHeader('Designation', 'designation', '10');
      this.dataGridService.addHeader('Status', 'status', '8');
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('givenname', false);
      this.fillStatus();
      this.fillContactTypeList();

      if (division.length === 1)
        this.fillDivisionList(division[0]);
      else
        this.fillDivisionList();

      this.fillDesignationList();
      this.CheckDirtySearch();

    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  SetPageRights() {
     
    if ((this.userDesignation == 'Administrator' || this.userDesignation == 'Technologist' || 
          this.userDesignation == 'Senior Technologist' ||   this.userDesignation == 'Admin-Technologist' ||  this.isAdminPermissionLoggedInUser))
    {
      this.blnshowAddNew = true;
    }
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeContactSearch();
  }

  setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeContactSearch() {
    AppComponent.ShowLoader();

    if(this.pageheader){
     this.pageheader.isSearchOpen = false;
     this.pageheader.searchText = 'Search Records';
    }

    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const contanctsearch = this.prepareSearch();
    this.contactapi.GetContactDetails(contanctsearch).subscribe(pagedData  => {
       
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.contactssearchDetails;
        if(pagedData.contactssearchDetails.designation == 'Technologist' 
        || pagedData.contactssearchDetails.designation == 'Senior Technologist'
        || pagedData.contactssearchDetails.designation == 'Administrator'
        || pagedData.contactssearchDetails.designation == 'Admin-Technologist')
        {
          this.blnshowAddNew = true;
        }
        // console.log(pagedData);
        AppComponent.HideLoader();
      }
    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );
  }

prepareSearch() {

  // tslint:disable-next-line:prefer-const
  let contactsearch = <any>{};

  contactsearch.ContactId = this.ContactId;

  contactsearch.name = this.GivenName;

  if (this.Division !== undefined && this.Division !== '' ) {
      contactsearch.divisionId = this.Division;
  }


  if (this.Designation !== undefined && this.Designation !== '') {
      contactsearch.designationId = this.Designation;
  }

  if (this.ContactType !== undefined && this.ContactType !== '') {
      contactsearch.userType = this.ContactType;
  }

  if (this.Status !== undefined && this.Status !== '') {
      contactsearch.status = this.Status;
  }

  if (this.prefixAlphabet)
    contactsearch.alphabetPrefix = this.prefixAlphabet;


  contactsearch.contactInfo = this.ContactId;
  contactsearch.familyName = this.FamilyName;
  contactsearch.username = this.UserName;

  contactsearch.currentPageNumber = this.currentPageNumber;
  contactsearch.sortExpression = this.sortExpression;
  contactsearch.sortDirection = this.sortDirection;
  contactsearch.PageSize = this.pageSize;
   // console.log(contactsearch);
  return contactsearch;

}

  setPage() {

    this.currentPageNumber = (this.page && this.page.pageNumber) ? this.page.pageNumber : 1;
    this.executeContactSearch();
  }
   _onRowClick(row) {
        this.router.navigate(['Contact/ContactDetail'], { queryParams: { id: row} });
  }

  fillDivisionList(defaultValue='') {
    this.commonapi.FillDivision(true).subscribe(list =>{
      this.DivisionList = list;

      if (defaultValue) {
          this.Division = defaultValue;
          setTimeout(()=> this.executeContactSearch());
        }
    });
  }

  fillContactTypeList() {
    this.contactapi.FillUserType().subscribe(list => this.ContactTypeList = list);

  }

  fillDesignationList() {
     this.contactapi.FillDesignation().subscribe(list => this.DesignationList = list);
  }

  fillStatus() {
    this.StatusList = [
      { value: '', text: 'Select' },
      { value: 'Active', text: 'Active' },
      { value: 'Inactive', text: 'Inactive' },
    ];
  }

  executeContactSearchClear() {
    this.GivenName = '';
    this.FamilyName = '';
    this.ContactId = '';
    this.Status = '';
    this.ContactType = '';
    this.Division = '';
    this.Designation ='';
    this.UserName = '';
    this.executeContactSearch();
  }

  alphabetChange(alphabet){
    this.prefixAlphabet= alphabet;
    this.executeContactSearch();
  }

  clearAlphabet(){
    this.prefixAlphabet = '';
    this.executeContactSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;
    if (
      this.GivenName !== ''
    || this.FamilyName  !== ''
    || this.ContactId !== ''
    || this.Status !== ''
    || this.ContactType !== ''
    || this.Division !== ''
    || this.Designation !== ''
    || this.UserName !== ''

    ) {
      this.blnDirtySearch = true;
    }
    //console.log(this.blnDirtySearch);
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executeContactSearch();
  }
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { IDayCalendarConfig, DatePickerComponent, SingleCalendarValue } from "ng2-date-picker";

import {
  TasksApiService
} from '../../API/tasksapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import {
  Router,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import {
  NgForm
} from '@angular/forms';

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  DatePipe
} from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  MatButtonToggleModule
} from '@angular/material/button-toggle';
import {
  ConfirmationDialog
} from '../../Core/ConfirmationDialog';
import {
  CanComponentDeactivate
} from '../../Core/Shared/CanComponentDeactivate';
import {
  Observable
} from 'rxjs';
import {
  map
} from 'rxjs/operators';
import {
  ScrollHelper
} from '../../Core/Helper/ScrollHelper';
import {
  AppComponent
} from '../../app.component';
import {
  AuthService
} from '../../API/auth.service';
// import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
// import { AttachmentComponent } from '../Core/Shared/AttachmentComponent/AttachmentComponent';
import {
  ValidateUser
} from '../../Validator/ValidateUser';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import {
  ApiService
} from '../../API/api.service';
@Component({
  selector: 'AutoTaskDetail',
  templateUrl: './AutoTaskDetail.component.html',
  styleUrls: [
    './AutoTaskDetailStyle.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AutoTaskDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef < ConfirmationDialog > ;
  currentUserId = this.auth.GetUserId();
  // @ViewChild(AttachmentComponent)
  // attachment: AttachmentComponent;
  // @ViewChild(NotesComponent)
  // notes: NotesComponent;
  CreatedByUser = this.auth.GetUserName();
  CreatedDate = new Date();
  isValidFormSubmitted = null;
  loading = true;
  status = '';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  AutoTaskDate = new Date();
  blnshowSave = false;
  blnshowDelete = false;
  ListURL = 'Home/AutoTask/AutoTaskList';
  todayDate:SingleCalendarValue = '01-09-2019';
  // CategoryList = [];
  // EquipmentList = [];
  // DayList = [];
  // UserList = [];
  // DivisionList = [];
  // TaskTypeList = [];
  // dropdownSettings;
  onText = '6M';
  offText = '1Yr';
  onYes = 'Yes';
  offNo = 'No';
  onColor = 'success';
  offColor = 'danger';
  categoryName;
  schedule;
  // UserMappingData = [];
  // OldCompletedStatus = '';
  // ReoccurancePattern = [
  //   { value: 'Daily', text: 'Daily' },
  //   { value: 'Weekly', text: 'Weekly' },
  //   { value: 'Monthly', text: 'Monthly' },
  //   { value: 'Yearly', text: 'Yearly' }
  // ];
  tasknumber = '[AutoNumber]';
  AutoTaskDetailForm = new UntypedFormGroup({
    HomeStationID: new UntypedFormControl('', Validators.required),
    AutoTaskID: new UntypedFormControl(''),
    TaskType: new UntypedFormControl(''),
    ROMicroBiology: new UntypedFormControl(''),
    ROMicroBiologyLastDate: new UntypedFormControl(''),

    DMMicroBiology: new UntypedFormControl(''),
    DMMicroBiologyLastDate: new UntypedFormControl(''),

    ROPreFilters: new UntypedFormControl(''),
    ROPreFiltersLastDate: new UntypedFormControl(''),
    IronFilters: new UntypedFormControl(''),
    IronFiltersLastDate: new UntypedFormControl(''),
    FeedWaterAnalysis: new UntypedFormControl(''),
    FeedWaterAnalysisFrequency: new UntypedFormControl(''),
    FeedWaterAnalysisLastDate: new UntypedFormControl(''),
    ProductWaterAnalysis: new UntypedFormControl(''),
    ProductWaterAnalysisFrequency: new UntypedFormControl(''),
    ProductWaterAnalysisLastDate: new UntypedFormControl(''),
    ROGFITesting: new UntypedFormControl(''),
    ROGFITestingLastDate: new UntypedFormControl(''),
    DMGFITesting: new UntypedFormControl(''),
    DMGFITestingLastDate: new UntypedFormControl(''),
    IsActive: new UntypedFormControl(''),

  });
  divisionName: any;
  divisionId: 0;
  stationName: any;
  dmEquipId: 0;
  roEquipId: 0;
  taskTypeName: 'Auto Populating HM Task';
  designationName: any;

  // implementation of canDeactivate
  canDeactivate(): Observable < boolean > | Promise < boolean > | boolean {
    // if (
    //   this.AutoTaskDetailForm.dirty &&
    //   (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    // ) {
    //   this.dialogRef = this.dialog.open(ConfirmationDialog, {
    //     disableClose: true
    //   });
    //   this.dialogRef.componentInstance.confirmMessage =
    //     'You have unsaved changes! If you leave, your changes will be lost.';
    //   return this.dialogRef.afterClosed().pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );
    // } else {
    //   return true;
    // }
    return true;
  }

  constructor(
    private API: TasksApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private datePipe: DatePipe
  ) {}

  getWeekNumber(thisDate) {
    var dt = new Date(thisDate);
    var thisDay = dt.getDate();

    var newDate = dt;
    newDate.setDate(1); // first day of month
    var digit = newDate.getDay();

    var Q = (thisDay + digit) / 7;

    var R = (thisDay + digit) % 7;

    if (R !== 0)  Q = Math.ceil(Q);
    
    switch(Q) { 
      case 1: { 
         return '1st';
        // break; 
      } 
      case 2: { 
        return '2nd';
        // break; 
      } 
      case 3: { 
        return '3rd';
         //break; 
      } 
      case 4: { 
        return '4th';
         //break; 
      } 
      default: { 
        return '';
         //break; 
      } 
   } 

  }

  ngOnInit() {
    AppComponent.ShowLoader();
    //console.log('today',this.datePipe.transform(new Date(), 'MMM/yyyy'),this.datePipe.transform(this.todayDate, 'mmm/yyyy'));
        this.taskTypeName = 'Auto Populating HM Task';
    
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.SetPageRights();
      
      this.f.HomeStationID.setValue(params['stationid'] || '0');
      let dateNum = (params['date'] || null);
      if (this.f.HomeStationID.value == 0 || dateNum == null) {
        this.router.navigate([this.ListURL]);
      }
      this.AutoTaskDate = dateNum;
      console.log(this.f.HomeStationID.value);
      //console.log(this.AutoTaskDate , dateNum);
      
      this.API.GetAutoTasksbyID({
        Id: this.f.HomeStationID.value
      }).subscribe(
        success => {
          console.log(success);
          console.log(success == null || success.autoTaskID == null);
          if (success == null || success.autoTaskID == null) {
            this.API.GetDialysisStationByID({
              Id: this.f.HomeStationID.value
            }).subscribe(
              success1 => {
                this.f.AutoTaskID.setValue(0);
                this.f.ROMicroBiology.setValue("true");
      //this.f.DMMicroBiology.setValue("true");
      this.f.ROPreFilters.setValue("true");
      // this.f.IronFilters.setValue("true");
      this.f.FeedWaterAnalysis.setValue("true");
      this.f.ProductWaterAnalysis.setValue("true");
      this.f.ROGFITesting.setValue("true");
      this.f.DMGFITesting.setValue("true");
      this.f.FeedWaterAnalysisFrequency.setValue("6M");
      this.f.ProductWaterAnalysisFrequency.setValue("6M");
                if (success1) {
                  //console.log(success);
                  this.stationName = success1.dialysisStationName;
                  this.dmEquipId = success1.dmEquipmentId;
                  this.roEquipId = success1.roEquipmentId;
                  this.divisionId = success1.divisionId;
                  const division = {
                    Id: this.divisionId
                  };
                  this.API.GetDivisionByID(division).subscribe(
                    dsuccess => {
                      this.divisionName = dsuccess.divisionName;
                    },
                    error => {
                      AppComponent.addAlert(error.message, 'error');
                      AppComponent.HideLoader();
                    });
                }
                AppComponent.HideLoader();
              },
              error => {
                AppComponent.addAlert(error.message, 'error');
                AppComponent.HideLoader();
              }
            );
          } else {
            console.log(success.autoTaskID);
            this.stationName = success.dialysisStationName;
                  this.dmEquipId = success.dmEquipmentId;
                  this.roEquipId = success.roEquipmentId;
                  this.divisionId = success.divisionID;
                  this.divisionName = success.divisionName;
            this.f.AutoTaskID.setValue(success.autoTaskID);
            this.f.ROMicroBiology.setValue(success.roMicroBiology==true?"true":'');
            this.f.DMMicroBiology.setValue(success.dmMicroBiology==true?"true":'');
            this.f.ROPreFilters.setValue(success.roPreFilters==true?"true":'');
            this.f.IronFilters.setValue(success.ironFilters==true?"true":'');
            this.f.FeedWaterAnalysis.setValue(success.feedWaterAnalysis==true?"true":'');
            this.f.ProductWaterAnalysis.setValue(success.productWaterAnalysis==true?"true":'');
            this.f.ROGFITesting.setValue(success.rogfiTesting==true?"true":'');
            this.f.DMGFITesting.setValue(success.dmgfiTesting==true?"true":'');
            this.f.FeedWaterAnalysisFrequency.setValue(success.feedWaterAnalysisFrequency == "6M" ? this.onText : this.offText);
            this.f.ProductWaterAnalysisFrequency.setValue(success.productWaterAnalysisFrequency == "6M" ? this.onText : this.offText);
            let tempDate = new Date(success.roPreFiltersLastDate);
if(success.roPreFiltersLastDate != null)
{
  this.f.ROPreFiltersLastDate.setValue(this.datePipe.transform(success.roPreFiltersLastDate, 'MMM/yyyy'));
  
        // this.f.ROPreFiltersLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.roPreFiltersLastDate
        // });
      }
      if(success.ironFiltersLastDate != null)
{
  this.f.IronFiltersLastDate.setValue(this.datePipe.transform(success.ironFiltersLastDate, 'MMM/yyyy'));
        // tempDate = new Date(success.ironFiltersLastDate);

        // this.f.IronFiltersLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.ironFiltersLastDate
        // });
      }
        
        if(success.feedWaterAnalysisLastDate != null)
        {
          this.f.FeedWaterAnalysisLastDate.setValue(this.datePipe.transform(success.feedWaterAnalysisLastDate, 'MMM/yyyy'));
        //   tempDate = new Date(success.feedWaterAnalysisLastDate);
        // this.f.FeedWaterAnalysisLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.feedWaterAnalysisLastDate
        // });
      }

      if(success.productWaterAnalysisLastDate != null)
        {
          //console.log(this.f.ProductWaterAnalysisLastDate);
          this.f.ProductWaterAnalysisLastDate.setValue(this.datePipe.transform(success.productWaterAnalysisLastDate, 'MMM/yyyy'));
          //console.log(success.roPreFiltersLastDate);
        // tempDate = new Date(success.productWaterAnalysisLastDate);

        // this.f.ProductWaterAnalysisLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.productWaterAnalysisLastDate
        // });
      }

      if(success.rogfiTestingLastDate != null)
        {
          this.f.ROGFITestingLastDate.setValue(this.datePipe.transform(success.rogfiTestingLastDate, 'MMM/yyyy'));
        // tempDate = new Date(success.rogfiTestingLastDate);

        // this.f.ROGFITestingLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.rogfiTestingLastDate
        // });
      }
      if(success.dmgfiTestingLastDate != null)
        {
          this.f.DMGFITestingLastDate.setValue(this.datePipe.transform(success.dmgfiTestingLastDate, 'MMM/yyyy'));
        // tempDate = new Date(success.dmgfiTestingLastDate);

        // this.f.DMGFITestingLastDate.setValue({
        //   date: {
        //     year: tempDate.getFullYear(),
        //     month: tempDate.getMonth() + 1,
        //     day: tempDate.getDate()
        //   },
        //   jsdate: success.dmgfiTestingLastDate
        // });
      }
          }
          AppComponent.HideLoader();
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );


      // if (this.f.AutoTaskID.value !== '0') {
      //   this.setModel();
      // }
    });
    const accesToken = this.auth.getAccessToken();
    this.designationName = accesToken.DesignationName;

  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }
  get f() {
    return this.AutoTaskDetailForm.controls;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const FilterObj = {
          Id: this.f.AutoTaskID.value
        };
        this.API.DeleteTasksDetailsByID(FilterObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Task detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  onChange(e) {
    // console.log(this.f.IsRecurrence);
    // console.log(this.f.AssignUserIds);
  }

  GetPatternName(pattern) {
    switch (pattern) {
      case 'Daily':
        return 'day(s)';
      case 'Monthly':
        return 'month(s)';
      case 'Weekly':
        return 'week';
      case 'Yearly':
        return 'year';
      default:
        return '';
    }
  }

  GetWeekDaysName(days) {
    // console.log(days);
    // const daysA = days.split(',');
    let result = '';
    days.forEach(function (d) {
      switch (d) {
        case 'M':
          result += 'Monday, ';
          break;
        case 'T':
          result += 'Tuesday, ';
          break;
        case 'W':
          result += 'Wednesday, ';
          break;
        case 'Th':
          result += 'Thrusday, ';
          break;
        case 'F':
          result += 'Friday, ';
          break;
        case 'Sa':
          result += 'Saturday, ';
          break;
        case 'S':
          result += 'Sunday, ';
          break;
        default:
          break;
      }
    });

    return result.substring(0, result.length - 1);
  }

  RecStatusChange() {
    // this.taskTypeName = this.TaskTypeList.filter(
    //   (item, index) => item.value == this.f.taskType.value
    // )[0].text;
    // if(this.taskTypeName == 'Meeting') {
    //   this.offText = "Date(s)";
    // } else {
    //   this.offText = "Due Date(s)";
    // }
    // // console.log(this.offText,this.f.taskType);
    this.GreaterValueCheck();
  }

  GreaterValueCheck() {
    
    if (this.f.ROPreFilters.value == 'true') {
      this.f.ROPreFiltersLastDate.setValidators([Validators.required]);
    } else {
      this.f.ROPreFiltersLastDate.clearValidators();
    }

    if (this.f.IronFilters.value == 'true') {
      this.f.IronFiltersLastDate.setValidators([Validators.required]);
    } else {
      this.f.IronFiltersLastDate.clearValidators();
    }

    if (this.f.FeedWaterAnalysis.value == 'true') {
      this.f.FeedWaterAnalysisLastDate.setValidators([Validators.required]);
    } else {
      this.f.FeedWaterAnalysisLastDate.clearValidators();
    }

    if (this.f.ProductWaterAnalysis.value == 'true') {
      this.f.ProductWaterAnalysisLastDate.setValidators([Validators.required]);
    } else {
      this.f.ProductWaterAnalysisLastDate.clearValidators();
    }
    if (this.f.ROGFITesting.value == 'true') {
      this.f.ROGFITestingLastDate.setValidators([Validators.required]);
    } else {
      this.f.ROGFITestingLastDate.clearValidators();
    }
    if (this.f.DMGFITesting.value == 'true') {
      this.f.DMGFITestingLastDate.setValidators([Validators.required]);
    } else {
      this.f.DMGFITestingLastDate.clearValidators();
    }
    
    this.f.ROPreFiltersLastDate.updateValueAndValidity();
    this.f.IronFiltersLastDate.updateValueAndValidity();
    this.f.FeedWaterAnalysisLastDate.updateValueAndValidity();
    this.f.ProductWaterAnalysisLastDate.updateValueAndValidity();
    this.f.ROGFITestingLastDate.updateValueAndValidity();
    this.f.DMGFITestingLastDate.updateValueAndValidity();

  }
  executeAutoTaskCreateUpdate = function (Source) {
    AppComponent.loading = true;
    //console.log(this.f);
    if (Source !== 'novalidate') {
      this.GreaterValueCheck();
    }

    this.isValidFormSubmitted = false;
    if (this.AutoTaskDetailForm.status === 'PENDING') {
      setTimeout(() => {
        this.executeAutoTaskCreateUpdate('novalidate');
      }, 300);
      return;
    }

    this.isValidFormSubmitted = false;
    // console.log(this.AutoTaskDetailForm);
    if (this.AutoTaskDetailForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }

    this.isValidFormSubmitted = true;
    const taskCreate = this.prepareCreateViewModel();

    // AppComponent.HideLoader();
    // return;
    console.log(taskCreate.AutoTaskID == '0' || taskCreate.AutoTaskID == '');
    if (taskCreate.AutoTaskID == '0' || taskCreate.AutoTaskID == '') {

      this.API.CreateAutoTasks(taskCreate).subscribe(
        success => {
          // this.taskCreate = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.API.UpdateAutoTasks(taskCreate).subscribe(
        success => {
          // this.taskCreate = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const taskCreate = < any > {};

    taskCreate.AutoTaskID = this.f.AutoTaskID.value;

    taskCreate.DivisionID = this.divisionId;
    taskCreate.HomeStationID = this.f.HomeStationID.value;
    taskCreate.TaskType = this.taskTypeName;
    taskCreate.ROMicroBiology = this.f.ROMicroBiology.value;
    taskCreate.ROMicroBiologyLastDate = this.datePipe.transform(this.f.ROMicroBiologyLastDate.value, 'yyyy-MM-dd');;
    taskCreate.DMMicroBiology = this.f.DMMicroBiology.value;
    taskCreate.DMMicroBiologyLastDate = this.datePipe.transform(this.f.DMMicroBiologyLastDate.value, 'yyyy-MM-dd');;
    taskCreate.ROPreFilters = this.f.ROPreFilters.value;
    taskCreate.ROPreFiltersLastDate = this.datePipe.transform(this.f.ROPreFiltersLastDate.value, 'yyyy-MM-dd');;
    taskCreate.IronFilters = this.f.IronFilters.value;
    taskCreate.Basedate = this.datePipe.transform(this.AutoTaskDate, 'yyyy-MM-dd');;;
    taskCreate.IronFiltersLastDate = this.datePipe.transform(this.f.IronFiltersLastDate.value, 'yyyy-MM-dd');;
    taskCreate.FeedWaterAnalysis = this.f.FeedWaterAnalysis.value;
    taskCreate.FeedWaterAnalysisFrequency = this.f.FeedWaterAnalysisFrequency.value;
    taskCreate.FeedWaterAnalysisLastDate = this.datePipe.transform(this.f.FeedWaterAnalysisLastDate.value, 'yyyy-MM-dd');;
    taskCreate.ProductWaterAnalysis = this.f.ProductWaterAnalysis.value;
    taskCreate.ProductWaterAnalysisFrequency = this.f.ProductWaterAnalysisFrequency.value;
    taskCreate.ProductWaterAnalysisLastDate = this.datePipe.transform(this.f.ProductWaterAnalysisLastDate.value, 'yyyy-MM-dd');
    taskCreate.ROGFITesting = this.f.ROGFITesting.value;
    taskCreate.ROGFITestingLastDate = this.datePipe.transform(this.f.ROGFITestingLastDate.value, 'yyyy-MM-dd');;
    taskCreate.DMGFITesting = this.f.DMGFITesting.value;
    taskCreate.DMGFITestingLastDate = this.datePipe.transform(this.f.DMGFITestingLastDate.value, 'yyyy-MM-dd');;
    taskCreate.IsActive = 1;

    taskCreate.createdBy = this.createdBy;

    taskCreate.modifiedBy = this.createdBy;
    console.log(taskCreate);
    return taskCreate;
  }
  // fillDivisionList() {
  //   this.API.FillDivision().subscribe(list => {
  //     list[0].text = "All Division";
  //     list[1].value =0;
  //     this.DivisionList = list;
  //   });
  // }

  // GetAssignedUser(){
  //   let userlist = "";
  //   this.f.AssignUserIds.value.forEach(function(user) {
  //     if(userlist=="") {
  //       userlist = user.text;
  //     } else {
  //       userlist += ", " + user.text;
  //     }
  //   });
  //   if (userlist=="") {
  //     userlist = "Not Assigned";
  //   }
  //   return userlist;
  // }

  // fillTaskTypeList() {
  //   this.API.FillTaskType().subscribe(list =>
  //     {
  //       this.TaskTypeList = list.filter((item) => item.text == 'Meeting' || item.text == 'Other' ||item.text == 'Select');

  //       if(this.f.taskid.value == 0) {
  //         this.f.taskType.setValue(list.filter((item) => item.text == 'Other')[0].value);
  //       }
  //     }
  //     );
  //}

  // fillCategoryList() {
  //   this.API.FillInventoryCategory().subscribe(
  //     list => (this.CategoryList = list)
  //   );
  // }

  // fillUserList(DivisionId) {
  //   this.API.GetUsersDDByDivisionID(DivisionId).subscribe(list => {
  //     //console.log(DivisionId,list);
  //     this.UserList = list.filter((item, index) => item.value !== '');
  //   });
  // }

  // DivisionDropDownChanged() {
  //   const categoryName = this.CategoryList.filter(
  //     (item, index) => item.value === this.f.category.value
  //   );

  //   // console.log( this.f.category);
  // }

  SetPageRights() {
    this.auth.GetPageRights('Tasks/Communication').subscribe(list => {
      // console.log(list);
      if (this.f.AutoTaskID.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    // disableSince: {
    //   year: this.todayDate.getFullYear(),
    //   month: this.todayDate.getMonth() + 1,
    //   day: this.todayDate.getDate()
    // }
  };

  public dayPickerConfig = <IDayCalendarConfig>{
    format: "MMM/YYYY",
    monthFormat: "MMMM, YYYY",
    showGoToCurrent:false,
    max: this.datePipe.transform(new Date(), 'MMM/yyyy'),
    min: 'Jan/2018'
  };

  
  AcceptTaskClick() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to accept the current task?';
    this.dialogRef.afterClosed().subscribe(result => {
        if (result) {

          const assignedUsers = [];
          // console.log(success);

          assignedUsers.push({
            text: this.auth.GetUserName(),
            value: this.currentUserId
          });

          // console.log(assignedUsers);
          this.f.AssignUserIds.setValue(assignedUsers);
          this.executeAutoTaskCreateUpdate('');
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

    this.dialogRef = null;

  }

  onDateChanged(event: IMyDateModel): void {}
  handleInput(event) {
    if (event.keyCode === 13 || event.charCode === 13) {
      this.executeAutoTaskCreateUpdate('');
    }
  }
}

import { Component, ViewChild } from '@angular/core';
import { CDApiService } from '../../API/cdapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'DailySearch',
  templateUrl: './DailySearch.component.html'
})
export class DailySearchComponent {
  constructor(
    private cdapi: CDApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  Siloes = [];
  DailyDetail = [];
  selectedDivision = 'All Divisions';
  selectedSilo = '';
  LotNo = '';
  EventId = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'Concentrates/Daily/CDBicarbPowderDetail';
  workOrderRefNo = '';
  currentPageNumber = 1;
  sortExpression = 'Dailyid';
  sortDirection = 'ASC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Bicarb Powder';
  searchText = 'Search Records';
  defaultSort;

  ngOnInit() {

    this.auth.GetCurrentUserDivision().subscribe(division => {
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'eventid', '4');
      this.dataGridService.addHeader('Event Code', 'eventid', '8');
      this.dataGridService.addHeader('Division', 'divisionName', '15');
      this.dataGridService.addHeader('Date', 'createdon', '10');
      this.dataGridService.addHeader('Lot Number', 'lotnumber', '15');
      this.dataGridService.addHeader('Number of Units', 'numberofbags', '15');
      this.dataGridService.addHeader('Size of Units', 'bagSize', '20');
      this.sortDirection = 'DESC';
      this.sortExpression = 'createdon';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Date', true);

      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.CheckDirtySearch();

    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDailySearch();
  }

  setSortIndicator = function(column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDailySearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const DailySearch = this.prepareSearch();
    this.cdapi.GetCentralDeliveryDailyBicarbPowderRecords(DailySearch).subscribe(
      pagedData => {
        console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.centralDeliveryBicarbPowderDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    let DailySearch = <any>{};

    DailySearch.eventId = this.EventId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      DailySearch.fromDate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      DailySearch.toDate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' &&  this.selectedDivision !== 'All Divisions') {
      DailySearch.division = this.selectedDivision;
    }

    if (this.selectedSilo !== undefined && this.selectedSilo !== '') {
      DailySearch.DailyContainer = this.selectedSilo;
    }

    DailySearch.lotNumber = this.LotNo;
    DailySearch.currentPageNumber = this.currentPageNumber;
    DailySearch.sortExpression = this.sortExpression;
    DailySearch.sortDirection = this.sortDirection;
    DailySearch.pageSize = this.pageSize;
    //console.log(DailySearch);
    return DailySearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDailySearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue = '') {
    // this.cdapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => (this.DialysisUnits = list));
    this.commonapi.FillDivision(true).subscribe(list => {
      this.DialysisUnits = list;

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(() => this.executeDailySearch());
        }
      }
    });
  }

  executeDailySearchClear() {
    this.selectedDivision = 'All Divisions';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';
    this.LotNo = '';
    this.executeDailySearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision !== '' && this.selectedDivision != 'All Divisions') ||  this.LotNo !== ''
      || this.EventId !== '' ||
      (this.FromDate !== undefined && this.FromDate !== '') ||
      (this.ToDate !== undefined && this.ToDate !== '')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executeDailySearch();
  }
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { DecimalPipe, DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { WPApiService } from 'src/app/API/wpapi.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { Globals } from '../../globals';
// import { NgxSpinnerService } from "ngx-spinner";

@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2Alarms',
  templateUrl: 'BioPureHX2Alarms.component.html',
  styleUrls: ['BioPureHX2Alarms.component.css']
})

export class BioPureHX2AlarmsComponent {
  title =  'BioPure HX2 Alarms History';

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  private router: Router,
  private wpapi: WPApiService,
  public dialog: MatDialog,
  private dataGridService: TableHeadsService
  // ,
  // private spinner: NgxSpinnerService
) { }



timer:any;
tableHeaders;
defaultSort;
sortExpression = 'createdOn';
sortDirection = 'DESC';
lstHX2AlarmEventData:any;
private today = new Date();
allClearValue = "ALL";

lstHX2AlarmEventFields = [
  { value: 'Main Status', text: 'Main Status' },
  { value: 'Matrix Mode', text: 'Matrix Mode' },
  { value: 'System Mode', text: 'System Mode' },
  { value: 'System Sequence Step', text: 'System Sequence Step' },
  { value: 'User Access', text: 'User Access' },
  { value: 'Alarm', text: 'Alarm' }
];

HX2AlarmForm = new UntypedFormGroup({
  AlarmDate: new UntypedFormControl(''),
  fieldName: new UntypedFormControl(''),
  ALLfieldName: new UntypedFormControl('')
});

public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect: false,
  closeSelectorOnDocumentClick: true,
  disableSince: {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate() + 1
  }
};

onDateChanged(event: IMyDateModel): void {
  setTimeout(() => {
    this.sortExpression = 'createdOn';
    this.sortDirection = 'DESC';
    this.FetchAndSetValues();
  }, 500);
  // date selected
}

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

  RouteClick(value) {
    this.router.navigate([value]);
  }

  get f() {
    return this.HX2AlarmForm.controls;
  }

ngOnInit() {
  setTimeout(() => {
  const tempDate = this.today;
            this.f.AlarmDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

  this.dataGridService.clearHeader();
  this.dataGridService.addHeader('#', 'intId', '5');
  this.dataGridService.addHeader('Field Name', 'fieldLabelName', '35');
  this.dataGridService.addHeader('Field Value', 'fieldDisplayValue', '');
  this.dataGridService.addHeader('Date & Time', 'createdOn', '15');
  this.tableHeaders = this.dataGridService.setTableHeaders();
  this.defaultSort = this.dataGridService.setDefaultSort('createdOn', true);

  AppComponent.ShowLoader();
  this.FetchAndSetValues();
  // AppComponent.HideLoader();
  // this.timer = setInterval(() => {
  //   this.FetchAndSetValues();
  // }, 60000);
  }, 500);
}


changeSorting(column) {

  if (column === '#') {
      return;
  }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);

    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.FetchAndSetValues();

}

ngOnDestroy() {
  clearInterval(this.timer);
}


FetchAndSetValues(){
  // this.spinner.show();
  AppComponent.ShowLoader();
  const  HX2AlarmEventSearch = this.prepareSearch();
  this.wpapi.FetchHX2AlarmEventData(HX2AlarmEventSearch).subscribe(
    success => {
      this.lstHX2AlarmEventData = success;

    AppComponent.HideLoader();
    // this.spinner.hide();
    },
    error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    }
  );
  }

  prepareSearch() {

    const HX2AlarmEventSearch = <any>{};
    if (this.f.AlarmDate !== undefined && this.f.AlarmDate.value !== '' ) {
      HX2AlarmEventSearch.alarmdate = Globals.GetAPIDate( this.f.AlarmDate.value.jsdate);
    }
    HX2AlarmEventSearch.SortExpression = this.sortExpression;
    HX2AlarmEventSearch.SortDirection = this.sortDirection;
   // console.log(HX2AlarmEventSearch);
    return HX2AlarmEventSearch;

  }

  SetAllButtons(args) {


    if (args.source.checked == true) {
      let fieldNames = [];
      for (let childObj in this.lstHX2AlarmEventFields) {
        fieldNames.push(this.lstHX2AlarmEventFields[childObj].value);
      }
      this.f.fieldName.setValue(fieldNames);
      this.allClearValue = "Clear";

      // this.f.fieldName.disable();
    }
    else {
      this.f.fieldName.reset();
      this.allClearValue = "ALL";
      // this.f.fieldName.enable();

    }
  }

  isChecked(val) {
    if (this.f.fieldName.value && this.f.fieldName.value.includes(val))
      return true;
    else
      return false;
  }

  CheckUncheckAll() {
    if (this.lstHX2AlarmEventFields.length == this.f.fieldName.value.length) {
      this.f.ALLfieldName.setValue(['all']);
      this.allClearValue = "Clear";
    }
    else {
      this.f.ALLfieldName.setValue([]);
      this.allClearValue = "ALL";
    }
  }
}


import { Component, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
import { Globals } from '../../../globals';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { URLDialog } from '../URLDialog/URLDialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { Options } from 'selenium-webdriver/ie';

@Component({
  selector: 'singleattachment',
  templateUrl: './SingleAttachmentComponent.html'
})
export class SingleAttachmentComponent {
  public PrevAttachments = [];
  public DocId;
  public UploadBy;
  public ImagePath = '';
  public ImageName = '';
  

  @ViewChild('myInput', { static: false })
  myInputVariable: ElementRef;

  @Input() isUploadDisabled : boolean = false;

  urlDialogRef: MatDialogRef<URLDialog>;
  @Output()
  RaiseError = new EventEmitter();

  public uploader: FileUploader = new FileUploader({
    url: Globals.apiUrl + 'SignatureFiles/Upload',
    autoUpload: true,
    method: 'POST',
    allowedFileType: ['image'],
    maxFileSize: 1 * 1024 * 1024
  });

  ngAfterViewInit() {
    // console.log(this.uploader);
    // this.uploader.onAfterAddingFile = item => {
    //   console.log("tst");

    // };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      const json = JSON.parse(response);
      item.index = json.uploadfilename;
      this.ImagePath = json.uploadfilename;
    };
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);
    this.uploader.onAfterAddingFile = f => {
      f.withCredentials = false;

      if (this.uploader.queue.length > 1) {
      this.uploader.queue.splice(0, 1);
    }

    this.ImageName = this.uploader.queue[0].file.name;
  };
  }
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.uploader.autoUpload = true;
  }

  UploadFiles() {
    document.getElementById('ng2Fileuploader').click();
  }

  SetAttachmentUrl(fileName) {
    return Globals.AttachmentUrl + '/Signatures/' + fileName;
  }

  OpenFile(FileName, URL) {
    this.urlDialogRef = this.dialog.open(URLDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
    });

    this.urlDialogRef.componentInstance.URL = URL;
    this.urlDialogRef.componentInstance.dialogRef.updateSize('80%', '80%');
    this.urlDialogRef.componentInstance.PageTitle = FileName;
    this.urlDialogRef.afterClosed().subscribe(result => {
      this.urlDialogRef = null;
    });
  }
  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    let errorMessage;
    switch (filter.name) {
        case 'fileSize':
            errorMessage = `Maximum upload size exceeded (${Math.round((item.size / 1024 / 1024))} MB of 20 MB allowed)`;
            break;
        case 'mimeType':
            errorMessage = `Type '${item.type} is not allowed. Allowed types: '(JPG, GIF, PNG, JPEG)'`;
            break;
        default:
            errorMessage = `Type '${item.type} is not allowed. Allowed types: '(JPG, GIF, PNG, JPEG)'`;
    }
      this.RaiseError.emit(errorMessage);
}

  reset(){
    this.ImagePath = '';
    document.getElementById('ng2Fileuploader')['value'] = '';
  }
}

import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { DMApiService } from '../API/dmapi.service';
import { WPApiService } from '../API/wpapi.service';

import { map } from 'rxjs/operators';
import { DMTransferService } from '../API/dmtapi.service';

// export class ValidateEmailNotTaken {
//   static createValidator(signupService: SignupService) {
//     return (control: AbstractControl) => {
//       return signupService.checkEmailNotTaken(control.value).map(res => {
//         return res ? null : { emailTaken: true };
//       });
//     };
//   }
// }

// create your class that extends the angular validator class
export class DMRepairCustomValidators  {
  static EquipmentRepairStatusValidate(Dmrepairdetailsid:AbstractControl,dmapi:DMApiService, isDmRepair:boolean=false){
    //console.log('hello');
    return (control: AbstractControl) => {


    var dmrepairCreate = {equipmentId:control.value,dmrepairdetailsid:Dmrepairdetailsid.value,isDMRepair:isDmRepair};

        return dmapi.GetRepairStatusByEquipmentId(dmrepairCreate).pipe(map(res => {
         // console.log(res)  ;
          return res==true ? null : { RepairStatus: true };
            //return {false res };
           }));
          };
  }

  static WPOtherEquipmentRepairStatusValidate(Dmrepairdetailsid:AbstractControl,dmapi:DMApiService, isDmRepair:boolean=false){
    //console.log('hello');
    return (control: AbstractControl) => {


    var repairCreate = {equipmentId:control.value,dmrepairdetailsid:Dmrepairdetailsid.value,isDMRepair:isDmRepair};

        return dmapi.GetWpOtherRepairStatusByEquipmentId(repairCreate).pipe(map(res => {
         // console.log(res)  ;
          return res==true ? null : { RepairStatus: true };
            //return {false res };
           }));
          };
  }

  static EquipmentRepairHoursOutOfPreviousRepair(EquipmentId:AbstractControl,Dmrepairdetailsid:AbstractControl,dmapi:DMApiService){
    //console.log('hello');

    return (control: AbstractControl) => {
      if(control && control.value && control.value != ""){
    var dmrepairCreate = {equipmentId:EquipmentId.value,hoursin:control.value
      ,dmrepairdetailsid:Dmrepairdetailsid.value};

        return dmapi.GetHoursOutOfPreviousRepair(dmrepairCreate).pipe(map(res => {
         // console.log(res);
          return res == true ? null : { GreaterHoursOut: true } ;
           }));
          }
          else{
            return null;
          };
        }
      }


          static hoursGreaterValidation(hours : string){
            return (control: AbstractControl) => {

              return ((control && control.value && hours) &&
                (parseFloat(control.value) < parseFloat(hours))) ? { GreaterHoursOut: true } : null;
            };
          };

  static EquipmentRepairDetailHoursOutOfPreviousRepairDetail(EquipmentId:AbstractControl,repairdetailsid:AbstractControl,wpapi:WPApiService){
    //console.log('hello');
    return (control: AbstractControl) => {


      if(control.value != ""){
    var repairCreate = {equipmentId:EquipmentId.value,hoursin:control.value
      ,repairdetailsid:repairdetailsid.value};

        return wpapi.GetHoursOutOfPreviousRepairDetail(repairCreate).pipe(map(res => {
         // console.log(res);
          return res == true ? null : { GreaterHoursOut: true } ;
           }));
          };
        }
      }

      static TransferHoursOutOfPreviousTransfer(EquipmentId:AbstractControl,Dmrepairdetailsid:AbstractControl,dmapi:DMApiService){
        //console.log('hello');
        return (control: AbstractControl) => {


          if(control.value != ""){
        var dmrepairCreate = {equipmentId:EquipmentId.value,hoursin:control.value
          ,dmrepairdetailsid:Dmrepairdetailsid.value};

            return dmapi.GetHoursOutOfPreviousRepair(dmrepairCreate).pipe(map(res => {
             // console.log(res);
              return res == true ? null : { MAXHoursOut: true } ;
               }));
              };
            }
          }
}


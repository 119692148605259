import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class DivisionApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}


    GetDivisionSearchDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetDivisionSearchDetails',
        SearchOptions,
        httpOptions
      );
    }

    CreateDivisionDetails(divisionDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/CreateDivisionDetails',
        divisionDetails,
        httpOptions
      );
    }


    UpdateDivisionDetails(divisionDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/UpdateDivisionDetails',
        divisionDetails,
        httpOptions
      );
    }

    DeleteDivision(value) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/DeleteDivisionDetails',
        value,
        httpOptions
      );
    }

    GetDivisionByID(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetDivisionByID',
        division,
        httpOptions
      );
    }


    GetContainerDetailsByDivisionId(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetContainersByDivisionId',
        division,
        httpOptions
      );
    }

    GetStationsDetailsByDivisionId(division) {
      return this.http.post<any>(
        this.apiUrl + 'DivisionService/GetStationsByDivisionId',
        division,
        httpOptions
      );
    }

    GetDivisionNotes(value) {
      return this.http.post<any>(
        this.apiUrl +
          'DivisionService/GetDivisionNotes',
        value,
        httpOptions
      );
    }

    FillCountry() {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillCountry',
        '',
        httpOptions
      );
    }

    FillState(value) {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillState',
        value,
        httpOptions
      );
    }
  }

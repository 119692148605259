import { Component, ViewChild } from '@angular/core';
import { RPTApiService } from '../../API/rptapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';
import { DMApiService } from 'src/app/API/dmapi.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MachineHistoryDialog } from '../../Core/MachineHistory/MachineHistoryDialog';

@Component({
    selector: 'DMReportSearch',
    templateUrl: './DMReportSearch.component.html'
  })
  export class DMReportSearchComponent {
    UserList: any;
    machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;
    constructor(
      private rptapi: RPTApiService,
      private router: Router,
      private dataGridService: TableHeadsService,
      private commonapi: CommonApiService,
      private auth: AuthService,
      private dmapi: DMApiService,
      public dialog: MatDialog
    ) {
      this.page.pageNumber = 0;
      this.page.size = 25;
    }

    @ViewChild(PageHeaderComponent, { static: false }) pageheader: PageHeaderComponent;
    DialysisUnits = [];
    page = new Page();
    ListURL = 'Reports/DMReportSearch';
    DMReportSearchOptions = [
      { name: 'Interval' },
      { name: 'Custom Dates' }
    ]
    DMReportSelectedValue = ['Interval'];
    selectedDivision = "";
    selectedInterval = "Month";

    DMReportIntervalOptions = [
      { value: 'Month', text: 'Month' },
      { value: '3 Months', text: '3 Months' },
      { value: '6 Months', text: '6 Months' },
      { value: '9 Months', text: '9 Months' },
      { value: 'Year', text: 'Year'}
    ]

    tableHeaders;
    defaultSort;
    currentPageNumber = 1;
    sortExpression = "default";
    sortDirection = "ASC";
    pageSize = 25;
    ShowSearch = false;
    FromDate;
    ToDate;
    searchText = "Search Records";
    blnDirtySearch = false;
    rows;
    FromDateerrormsg = '';
    ToDateerrormsg = '';
    detailsType = "Dialysis Machines";

    ngOnInit() {
        this.fillDialysisUnit();
        this.dataGridService.clearHeader();
        this.dataGridService.addHeader("#", "repairid", "3");
        this.dataGridService.addHeader('Division Name', "divisionName", "5");
        this.dataGridService.addHeader("Equipment ID", "equipmentCode", "6");
        this.dataGridService.addHeader("Serial Number", "serialNumber", "6");
        this.dataGridService.addHeader("Total No. of Repairs", "no__of_Repairs", "6");
        this.dataGridService.addHeader("Total No. of Incidents", "no__of_Repairs", "7");
        this.dataGridService.addHeader("Last Machine Hours", "machine_Hours", "7");
        this.dataGridService.addHeader("Total ($) Parts Non-Wnty", "part_Cost", "8");
        this.dataGridService.addHeader("Total ($) Parts Wnty", "part_Cost_Warranty", "7");
        this.dataGridService.addHeader("Total Part Costs ($)", "part_Cost_NonWarranty", "6");
        this.dataGridService.addHeader("Total Tech Hrs", "tech_Repair_Hours", "6");
        this.dataGridService.addHeader("Total Event Hrs","tech_Event_Hours", "6");
        this.dataGridService.addHeader("Total Cost Labour ($)","labour_Hours","7");
        this.dataGridService.addHeader("Total Cost DM ($)","totalCostDM","6");

        this.tableHeaders = this.dataGridService.setTableHeaders();
        this.defaultSort = this.dataGridService.setDefaultSort("default", true);
        this.selectedInterval = "Month";
    }

    changeSorting(column) {
      if (column == "#") {
        return;
      }
      this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
      this.defaultSort = this.dataGridService.getSort();
      this.sortDirection = this.dataGridService.getSortDirection();
      this.sortExpression = this.dataGridService.getSortExpression();
      this.currentPageNumber = 1;
      this.page.pageNumber = 1;
    };

    setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
    };

    fillDialysisUnit(defaultValue = '') {
      this.commonapi.FillDivision(true).subscribe(list => {
        this.DialysisUnits = list;
      });
    }

    public myOptions: IAngularMyDpOptions = {
      // other options...
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
    };

    onDateChanged(event: IMyDateModel): void {
      // date selected
    }

    _onRowClick(EquipId, SerialNo, equipmentCode, ServiceDate,PMScheduleStatus,PMScheduleStatusType) {

      if(SerialNo !='Total'){
      this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        width: '70%',
        height: '100%',

      });
      this.machineHistoryDialogRef.componentInstance.detailsType = this.detailsType;
      this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
      this.machineHistoryDialogRef.componentInstance.SrNo = SerialNo;
      this.machineHistoryDialogRef.componentInstance.MachineName = equipmentCode;
      this.machineHistoryDialogRef.componentInstance.InServiceDate = ServiceDate;
      if(PMScheduleStatus)
        this.machineHistoryDialogRef.componentInstance.PMStatusHoursFromMachine = PMScheduleStatus;
      if(PMScheduleStatusType)
        this.machineHistoryDialogRef.componentInstance.PMStatusType = PMScheduleStatusType;

      this.machineHistoryDialogRef.afterClosed().subscribe(result => {
        this.machineHistoryDialogRef = null;
      });
    }
    }

    enableDisableOptions(event) {

      if (event == 'Interval') {
        this.DMReportSelectedValue = ['Interval'];
        this.FromDate = "";
        this.ToDate = "";
        this.selectedInterval = "Month";
      }
      else {
        this.DMReportSelectedValue = ['Custom Dates'];
        this.selectedInterval = "";
      }
    }

    executeDmReportSearch() {
      AppComponent.ShowLoader();

      if(this.DMReportSelectedValue[0] == 'Custom Dates')
      {
        if((this.FromDate == undefined || this.FromDate == '' || this.FromDate == null)
            && (this.ToDate == undefined || this.ToDate == '' || this.ToDate == null))
        {
          this.FromDateerrormsg = 'Required';
          this.ToDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if((this.FromDate == undefined || this.FromDate == '' || this.FromDate == null))
        {
          this.FromDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if((this.ToDate == undefined || this.ToDate == '' || this.ToDate == null))
        {
          this.ToDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if(this.ToDate != undefined && this.ToDate != '' &&
                this.FromDate != undefined && this.FromDate != '')
        {

            if(Globals.GetAPIDate(this.FromDate.jsdate) > Globals.GetAPIDate(this.ToDate.jsdate))
            {
              this.ToDateerrormsg = 'From Date >= To Date.'
              this.FromDateerrormsg = '';
              AppComponent.HideLoader();
              return false;
            }
            else
            {
              this.FromDateerrormsg = '';
              this.ToDateerrormsg = '';
            }
        }
        else
        {
          this.FromDateerrormsg = '';
          this.ToDateerrormsg = '';
        }
      }

      var dmReportSearch = this.prepareSearch();

      this.dmapi.GetDMReportDetails(dmReportSearch).subscribe(pagedData => {

        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.dmReportDetails;
        AppComponent.HideLoader()
      },
        error => {
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    }

    total_no__of_Repairs = 0;
    CheckDirtySearch() {
      this.blnDirtySearch = false;
      if (
        ((this.FromDate != null && this.FromDate != "")
        || (this.ToDate != null && this.ToDate != "")))
        {
          this.blnDirtySearch = true;
        }
    }

    executeDmReportPrint() {
      var href = '';
      AppComponent.ShowLoader();
      if(this.DMReportSelectedValue[0] == 'Custom Dates')
      {
        if((this.FromDate == undefined || this.FromDate == '' || this.FromDate == null)
            && (this.ToDate == undefined || this.ToDate == '' || this.ToDate == null))
        {
          this.FromDateerrormsg = 'Required';
          this.ToDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if((this.FromDate == undefined || this.FromDate == '' || this.FromDate == null))
        {
          this.FromDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if((this.ToDate == undefined || this.ToDate == '' || this.ToDate == null))
        {
          this.ToDateerrormsg = 'Required';
          AppComponent.HideLoader();
          return false;
        }
        else if(this.ToDate != undefined && this.ToDate != '' &&
                this.FromDate != undefined && this.FromDate != '')
        {

            if(Globals.GetAPIDate(this.FromDate.jsdate) > Globals.GetAPIDate(this.ToDate.jsdate))
            {
              this.ToDateerrormsg = 'From Date >= To Date.'
              this.FromDateerrormsg = '';
              AppComponent.HideLoader();
              return false;
            }
            else
            {
              this.FromDateerrormsg = '';
              this.ToDateerrormsg = '';
            }
        }
        else
        {
          this.FromDateerrormsg = '';
          this.ToDateerrormsg = '';
        }
      }
      var dmReportSearch = this.prepareSearch();
      this.dmapi.PrintReportDetails(dmReportSearch).subscribe(list => {
        href = Globals.WorkOrderPFDsUrl + list;
        window.open(href,'_blank');
        AppComponent.HideLoader()
      },
        error => {
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    }

    prepareSearch() {

      var dmreportSearch = <any>{};
      if (this.selectedDivision != undefined && this.selectedDivision != "") {
        dmreportSearch.DivisionId = this.selectedDivision;
      }

      if (this.FromDate != undefined && this.FromDate != "") {
        dmreportSearch.StartDate = Globals.GetAPIDate(this.FromDate.jsdate);
      }

      if (this.ToDate != undefined && this.ToDate != "") {
        dmreportSearch.EndDate = Globals.GetAPIDate(this.ToDate.jsdate);
      }



      if(this.selectedInterval != undefined && this.selectedInterval != "") {
        dmreportSearch.Span = this.selectedInterval;
      }

      dmreportSearch.currentPageNumber = this.currentPageNumber;
      dmreportSearch.sortExpression = this.sortExpression;
      dmreportSearch.sortDirection = this.sortDirection;
      dmreportSearch.pageSize = this.pageSize;

      return dmreportSearch;

    }


}

export class Page {
    // The number of elements in the page
    size = 0;
    // The total number of elements
    totalElements = 0;
    // The total number of pages
    totalPages = 0;
    // The current page number
    pageNumber = 0;
  }

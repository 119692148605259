import { CommonApiService } from 'src/app/API/commonapi.service.';
import { Component, Input, Output, OnInit, ViewChild } from '@angular/core';
import { WPApiService } from '../../../API/wpapi.service';
import { DMApiService } from '../../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../../Core/ConfirmationDialog';
import { MachineHistoryDialog } from '../../../Core/MachineHistory/MachineHistoryDialog';
import { CalibrationsDialog } from '../../../Core/Calibrations/CalibrationsDialog';
import { ElectricalDialog } from '../../../Core/ElectricalDialog/ElectricalDialog';
import { TrobleshootingDialog } from '../../../Core/TrobleshootingDialog/TrobleshootingDialog';
import { InvApiService } from 'src/app/API/invapi.service';
import { DMRepairCustomValidators } from '../../../Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from '../../../Validator/CommonValidator';
import { NotesComponent } from '../../../Core/Shared/NotesComponent/NotesComponent';
import { PartsComponent } from '../../../Core/Shared/PartsControl/PartsComponent';
import { AttachmentComponent } from '../../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../../globals';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../API/auth.service';
import { ImageViewerDialog } from '../../../Core/Shared/ImageViewerDialog/ImageViewerDialog';
import { TableHeadsService } from '../../../Core/tableheads.service';
import { PageHeaderComponent } from '../../../Core/pageheader.component';
import { QuickHomeEquipmentTransfer } from 'src/app/Core/QuickHomeEquipmentTransfer/QuickHomeEquipmentTransfer';
import { HomeService } from 'src/app/API/home.service';

@Component({
  selector: 'RepairDetail',
  templateUrl: './RepairDetail.component.html'
})
export class RepairDetailComponent implements OnInit, CanComponentDeactivate {
  public hrsTracking = true;
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(PageHeaderComponent, { static: false }) pageheader: PageHeaderComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(PartsComponent, { static: false })
  parts: PartsComponent;
  ShowParts = true;
  machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;
  quickUpdateTransferDialogRef: MatDialogRef<QuickHomeEquipmentTransfer>;
  //ShowErrors = true;
  UserName = '';
  isValidFormSubmitted = null;
  userDesignation = this.auth.GetDesignationName();
  tooltipText = 'Click To Add';
  selectedDialysisStationtext = '';
  selectedDialysisStationId = '';
  public homeService = false;
  public disableControl = false;
  showRemarks = true;
  //todo
  @Input() detailsType = "Water Process"
  @Input() title = "Water Process  Repair / Event / PM";
  @Input() ListURL = "WaterProcess/Repairs/RepairDetailSearch";
  @Input() headerTextCD = "Central Delivery Repair/Event";
  @Input() headerTextOther = "Other Event / Repair";

  EventRepairOptions = [
    { value: '', text: 'Select' },
    { value: 'E', text: 'Event' },
    { value: 'R', text: 'Repair' },
    { value: 'PM', text: 'PM' }
  ];

  loading = true;
  RepairDetailsIdvar = '0';
  EquipmentSerialNo: '';
  EquipmentInServiceDate: '';
  DialysisUnits = [];
  DialysisUnitsWithoutHome = [];
  Equipments = [];
  Users = [];
  Parts = [];
  Statuses = [];
  DialysisStation = [];
  DMRepailDetail = [];
  HydraulicDocList = [];

  //selectedUser = '0';
  RepairDetailsId = '0';
  repairId = '[AutoNumber]';
  status = '';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  today = new Date();
  createdOn = this.today;
  modifiedOn = this.today;
  IsHoursInIsGreaterThanHoursOut = true;
  blnHoursInVal = false;
  RepairStatus = true;
  selectedDialysisUnitsobj;
  selectedDialysisUnitstext;
  selectedEventRepairtext;



  selectedEquipmenttext;
  timeStartedLabel;
  timeFinishedLabel;
  calibrationsTran = [];
  electricalTran = [];
  Note = '';
  blnshowSave = false;
  blnshowDelete = false;
  selectedEquipmentHours = 0;
  hfcompleted = true;
  collapsedmEquiInClass = '';
  collapseariaexpanded = true;
  collapsedmEquiIn = 'panel-collapse collapse show';
  totalCost = 0;

  HomeServiceSetModel() {
    const dmrepairCreate = { Id: this.f.HFServiceId.value };
    this.wpapi.GetRepairIdFromHomeserviceId(dmrepairCreate).subscribe(
      success => {
        console.log("dd", success);
        if (success.id > 0) {
          this.f.RepairDetailsId.setValue(success.id);
          this.setModel();
        } else {
          this.fillDialysisUnit();
          this.fillEquipment(0);
          const tempDate = this.today;
          this.f.timeStarted.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.formDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          // this.purposeRepair.wprepair.f.timeStarted.setValue({
          //   date:  this.f.HFServiceVisitDate.value.date,
          //   jsdate: this.f.HFServiceVisitDate.value.jsdate
          // });
          // this.purposeRepair.wprepair.f.formDate.setValue({
          //   date:  this.f.HFServiceVisitDate.value.date,
          //   jsdate: this.f.HFServiceVisitDate.value.jsdate
          // });
        }

        if (this.f.RepairDetailsId.value == 0) {
          this.f.selectedEquipment.setValidators([Validators.required]);
          this.f.selectedEquipment.updateValueAndValidity();
          this.f.selectedEquipment.setAsyncValidators([
            DMRepairCustomValidators.WPOtherEquipmentRepairStatusValidate(
              this.f.RepairDetailsId,
              this.dmapi,
              true
            )
          ]);


          this.setHoursInValidation();
        }

        this.setHoursOutValidation();

        if (this.f.RepairDetailsId.value && this.f.RepairDetailsId.value != '0') {
          this.f.timeFinished.setValidators([
            Validators.required,
            CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
              this.f.timeStarted
            )
          ]);
        }
        else {
          this.f.timeFinished.setValidators([
            CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
              this.f.timeStarted
            )
          ]);
        }

        this.f.timeFinished.updateValueAndValidity();

      },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  setHoursInValidation(enable = true) {

    if (enable && this.status != 'Completed') {
      this.f.hoursin.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

      this.f.hoursin.setAsyncValidators([
        DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
          this.f.selectedEquipment,
          this.f.RepairDetailsId,
          this.dmapi
        )
      ]);
      this.f.hoursin.updateValueAndValidity();
    }
    else {
      this.f.hoursin.clearAsyncValidators();
      this.f.hoursin.clearValidators();
      this.f.hoursin.updateValueAndValidity();
    }

  }


  setHoursOutValidation(enable = true) {

    if (enable && this.status != 'Completed') {
      if(this.f.RepairDetailsId.value == 0)
      {
        // this.f.hoursout.setValidators([Validators.required]);
      this.f.hoursout.setValidators([ Validators.pattern(Globals.NumberNumericRegexWithoutZero),
        CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.timeFinished
        ),
        CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
          this.f.hoursin
        )
      ]);
      this.f.hoursout.updateValueAndValidity();
      }
      else
      {

        this.f.hoursout.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero),
          CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.timeFinished
          ),
          CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
            this.f.selectedHoursOut
          )
        ]);
        this.f.hoursout.updateValueAndValidity();
      }
    }
    else {
      this.f.hoursout.clearValidators();
      this.f.hoursout.clearAsyncValidators();
      this.f.hoursout.updateValueAndValidity();
    }

}

  // hydraulicDisinfectionList = [
  //   { value: '', text: 'Select' },
  //   { value: 'Heat', text: 'Heat' },
  //   { value: 'Heat Citric', text: 'Heat Citric' },
  //   { value: 'Chemical', text: 'Chemical' },
  //   { value: 'No', text: 'No' }
  // ];

  hydraulicDisinfectionList = [
    { value: '', text: 'Select' },
    { value: 'Chemical', text: 'Chemical' },
    { value: 'Heat Cleaned', text: 'Heat Cleaned' },
    { value: 'N/A', text: 'N/A'}
  ];

  repairDetailForm = new UntypedFormGroup({
    RepairDetailsId: new UntypedFormControl(''),
    HFServiceId: new UntypedFormControl('0'),
    timeStarted: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedRepairEvent: new UntypedFormControl('', Validators.required),
    remarks: new UntypedFormControl('', Validators.required),
    ErrorSearchTerm: new UntypedFormControl(''),
    selectedEquipment: new UntypedFormControl(''),
    ErrorDescription: new UntypedFormControl(''),
    hoursin: new UntypedFormControl(''),
    PartSearchTerm: new UntypedFormControl(''),
    PartDescription: new UntypedFormControl(''),
    PartLocation: new UntypedFormControl(''),
    PartQuantity: new UntypedFormControl(''),
    workOrderRefNo: new UntypedFormControl(''),
    hoursout: new UntypedFormControl(''),
    timeFinished: new UntypedFormControl(''),
    outHydraulicDisinfection: new UntypedFormControl(''),
    formDate: new UntypedFormControl("", Validators.required),
    selectedHoursOut: new UntypedFormControl("")
  });
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.repairDetailForm.dirty ||
        this.notes.Note != '' ||
        this.attachment.Queue().length > 0) && (this.isValidFormSubmitted == false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else return true;
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
    this.totalCost = 0;
    if (this.parts != undefined && this.parts.PartTrans != undefined) {
      for (let childObj in this.parts.PartTrans) {
        var temp = this.parts.PartTrans[childObj];
        this.totalCost = this.totalCost + (temp.PartCost * temp.quantity);
      }
    }
  }

  constructor(
    private commonapi : CommonApiService,
    private invapi: InvApiService,
    private dmapi: DMApiService,
    private wpapi: WPApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private homeApi: HomeService,
    private auth: AuthService
  ) { }

  ngAfterViewInit() {
    // Parts
    //console.log(this.ShowParts);
    if (this.ShowParts == true) {
      this.parts.partscategoryname = 'Dialysis Machines';
      this.parts.currentUserId = this.currentUserId;
      this.parts.API = this.dmapi;
      this.parts.status = this.status;
      this.parts.showPartHearder = false;

    }



  }
  ngOnInit() {
    setTimeout(() => {
      AppComponent.ShowLoader();

      this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
        this.today = this.createdOn = this.modifiedOn = new Date(currentDate);
        this.setDateOptions();
        // this.homeService = this.route.toString().indexOf('HomeFieldServiceDetail') > 0;
        // this.uploader.autoUpload = true;

    // attachment changes
    this.attachment.DocId = this.f.RepairDetailsId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Repair];
    this.attachment.UploadBy = this.currentUserId;


    // notes
    this.notes.showParts = true;
    this.notes.CurrentUserId = this.currentUserId;
    this.notes.API = this.dmapi;
    this.notes.partscategoryname = 'Dialysis Machines';
    this.notes.status = this.status;

    this.UserName = this.auth.GetUserFLName();

    if (this.homeService !== true) {

      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.

        this.f.RepairDetailsId.setValue(params['id'] || '0');
        let source = params['detailType'] || '0';



            const tempDate = this.today;
            this.f.timeStarted.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            let tempFormDate = this.today;
            this.f.formDate.setValue({
              date: {
                year: tempFormDate.getFullYear(),
                month: tempFormDate.getMonth() + 1,
                day: tempFormDate.getDate()
              },
              jsdate: tempFormDate
            });

        if (this.f.RepairDetailsId.value != '0') {
          this.setModel();

          if (source === "Water Process")
            this.ListURL = "WaterProcess/WPHistoryUploadSearch";
          else if (source === "Other")
            this.ListURL = "Other/OtherHistoryUploadSearch";
        } else {
          this.fillDialysisUnit();

          this.auth.GetCurrentUserDivision().subscribe(division => {

            if(division.length===1){
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillEquipment(division[0]);
          }
          else{
            this.fillEquipment(0);
          }
          // this.fillEquipment(0);
        }, error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        });
        }


            if (this.f.RepairDetailsId.value == 0) {
              this.f.selectedEquipment.setValidators([Validators.required]);
              this.f.selectedEquipment.updateValueAndValidity();
              this.f.selectedEquipment.setAsyncValidators([
                DMRepairCustomValidators.WPOtherEquipmentRepairStatusValidate(
                  this.f.RepairDetailsId,
                  this.dmapi,
                  true
                )
              ]);

         this.setHoursInValidation(this.hrsTracking);
        }

        // this.setHoursOutValidation(this.hrsTracking);

        //     this.f.timeFinished.setValidators([
        //       CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        //         this.f.timeStarted
        //       )
        //     ]);
        //     this.f.timeFinished.updateValueAndValidity();
          });
        } else {
          this.f.RepairDetailsId.setValue('0');
          this.fillDialysisUnit();
          this.fillEquipment(0);
        }
        this.SetPageRights();

        AppComponent.HideLoader();
      }, error => {
        AppComponent.HideLoader();
      });
    }, 500);
  }

  get f() {
    return this.repairDetailForm.controls;
  }


  setModel() {
    AppComponent.ShowLoader();
    const repairCreate = { Id: this.f.RepairDetailsId.value };

    this.wpapi.GetWaterProcessRepairsRecordByID(repairCreate).subscribe(
      success => {
        console.log(success);
        if (success) {
          this.f.RepairDetailsId.setValue(success.repairDetailId);
          this.f.selectedRepairEvent.setValue(success.statusType.toString());

          this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
          if (success.equipmentId != null) {
            this.f.selectedEquipment.setValue(success.equipmentId.toString());

            var repairCreate = { Id: this.f.selectedEquipment.value };
            this.dmapi.GetEquipmentDetailsByID(repairCreate).subscribe(success => {

              this.hrsTracking = success.hrsTracking;
                // this.setHoursInValidation(this.hrsTracking);
                // this.setHoursOutValidation(this.hrsTracking);

                // if(this.status != 'Completed')
                // {

                  if(!this.hrsTracking)
                    this.setHoursInValidation(this.hrsTracking);

                //  this.setHoursOutValidation(this.hrsTracking);
                // }
              this.collapsedmEquiInClass = 'collapsed';
              this.collapseariaexpanded = false;
              this.collapsedmEquiIn = 'panel-collapse collapse';

            });
          }

          this.repairId = success.repairId;

          this.UserName = success.createdbyname;
          this.f.workOrderRefNo.setValue(success.workOrderNo);

          this.timeStartedLabel = success.timeStarted;
          this.notes.Note = '';
          this.attachment.clearQueue();

          const tempDate = new Date(success.timeStarted);
          this.f.timeStarted.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.timeStarted
          });

          const tempcreatedOn = new Date(success.craetedOn);
          this.f.formDate.setValue({
            date: {
              year: tempcreatedOn.getFullYear(),
              month: tempcreatedOn.getMonth() + 1,
              day: tempcreatedOn.getDate()
            },
            jsdate: success.craetedOn
          });

          if (
            success.timefinished != undefined &&
            success.timefinished != null
          ) {
            this.timeFinishedLabel = success.timefinished;
            const tempDateoutateout = new Date(success.timefinished);

            this.f.timeFinished.setValue({
              date: {
                year: tempDateoutateout.getFullYear(),
                month: tempDateoutateout.getMonth() + 1,
                day: tempDateoutateout.getDate()
              },
              jsdate: success.timefinished
            });
          }

          this.f.hoursout.setValue(success.hoursout);

          if (success.hoursin == 0 && !success.isUploadHistory) {
            this.blnHoursInVal = true;
          }

          this.f.hoursin.setValue(success.hoursin);
          this.status = success.status;

          this.f.remarks.setValue(success.remark);
          this.f.outHydraulicDisinfection.setValue(success.outHydraulicDisinfection);

          this.createdOn = success.createdOn;
          this.createdBy = success.createdBy;
          this.modifiedOn = success.modifiedOn;
          this.modifiedBy = success.modifiedBy;

          //console.log(success);

          var equipmentId = { Id: this.f.selectedEquipment.value };
          this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
            if (result) {
              this.selectedEquipmentHours = result;
              this.f.selectedHoursOut.setValue(result);
            } else {
              this.selectedEquipmentHours = 0;
              this.f.selectedHoursOut.setValue("");

            }
          });


          if (this.parts !== undefined) {
            this.parts.SelectedEquipment = this.f.selectedEquipment.value;

            this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;
            this.parts.status = this.status;
          }

          this.notes.DivisionId = this.f.selectedDialysisUnits.value;
          this.notes.status = this.status;
          this.selectedDialysisUnitstext = success.divisionName;
          this.notes.EquipementId = this.f.selectedEquipment.value;

          if (success.statusType == "E") {
            this.selectedEventRepairtext = "Event";
          }
          else if (success.statusType == "P") {
            this.selectedEventRepairtext = "PM";
          }
          else {
            this.selectedEventRepairtext = "Repair";
          }


          if (success.nurseId != null) {
            //          this.f.selectedUser.setValue(success.nurseId.toString());
          }
          if (success.equipmentId != null) {
            this.f.selectedEquipment.setValue(success.equipmentId.toString());
          }
          var repairCreate = { Id: this.f.selectedEquipment.value };

          if (this.status == "Completed") {
            this.tooltipText = "Click To View";
          }

          var dmReparirObj = { Id: this.f.RepairDetailsId.value };

          this.wpapi
            .GetRepairPartsDetailsbydmRepairDetailid(dmReparirObj)
            .subscribe(list => {
              console.log(list);
              this.parts.PartTrans = [];
              //console.log(list);
              for (let childObj in list) {
                var temp = list[childObj];
                var obj = <any>{};
                obj.RepairDetailsId = temp.RepairDetailsId;
                obj.equipmentid = temp.equipmentId;
                obj.quantity = temp.quantity;
                obj.PartCode = temp.equipmentCode;
                //obj.PartDescription = temp.equipmentName;
                obj.PartDescription = temp.equipmentDesc;
                obj.PartCost = temp.itemCost;
                obj.createdBy = temp.repairPartDetailsEnteredBy;
                obj.repairPartDetailsEnteredBy = temp.repairPartDetailsEnteredBy;
                obj.createdByName = temp.createdByName;
                obj.createdOn = temp.repairPartDetailsEnteredOn;
                obj.repairPartDetailsEnteredOn = temp.repairPartDetailsEnteredOn;
                obj.modifiedBy = temp.modifiedBy;
                obj.modifiedOn = temp.modifiedOn;
                obj.LocationText = '0';
                obj.showPartDelete = false;
                // tslint:disable-next-line:triple-equals
                if (obj.createdBy == this.currentUserId) {
                  obj.showPartDelete = true;
                }
                this.parts.PartTrans.push(obj);
              }

              this.notes.Parts = this.parts.PartTrans;
              this.ShowParts = (this.status != 'Completed' || this.parts.PartTrans.length > 0)
            });


          this.wpapi
            .GetattachmentDetailsbyRepairDetailid(dmReparirObj)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });

          this.wpapi.GetnotesbydmRepairDetailid(dmReparirObj).subscribe(list => {
            this.notes.NoteList = list;
          });


          this.selectedEquipmenttext = success.equipmentName;

          this.fillEquipment(this.f.selectedDialysisUnits.value);
        }
        AppComponent.HideLoader();

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  dmEquipOutChange(event) {

    let elm = document.getElementById("dmEquipOutRefID");

    if (elm.className === "collapsed") {
      // if(this.status == 'Open')
      //     {


      // if (this.hrsTracking && this.f.RepairDetailsId.value && this.f.RepairDetailsId.value != '0') {
      //   this.f.hoursout.setValidators([Validators.required]);
      //   this.f.hoursout.updateValueAndValidity();
      // }

      this.setHoursOutValidation(this.hrsTracking);
      // this.f.hoursout.updateValueAndValidity();

      // if (this.f.RepairDetailsId.value && this.f.RepairDetailsId.value != '0') {
        this.f.timeFinished.setValidators([
          Validators.required,
          CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
            this.f.timeStarted
          )
        ]);
      // }
      // else {
      //   this.f.timeFinished.setValidators([
      //     CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
      //       this.f.timeStarted
      //     )
      //   ]);
      // }
      this.f.timeFinished.updateValueAndValidity();
      // if (this.f.RepairDetailsId.value && this.f.RepairDetailsId.value != '0') {
        this.f.outHydraulicDisinfection.setValidators([Validators.required]);
        this.f.outHydraulicDisinfection.updateValueAndValidity();
      // }
      // else {
      //   this.f.outHydraulicDisinfection.clearValidators();
      //   this.f.outHydraulicDisinfection.updateValueAndValidity();
      // }
      // }
    } else {
      if (this.status != "Completed") {
        this.f.hoursout.setValue("");
        this.f.timeFinished.setValue("");
        this.f.outHydraulicDisinfection.setValue("");
      }
      this.f.hoursout.clearValidators();
      this.f.hoursout.updateValueAndValidity();

      this.f.timeFinished.clearValidators();
      this.f.timeFinished.updateValueAndValidity();

      this.f.outHydraulicDisinfection.clearValidators();
      this.f.outHydraulicDisinfection.updateValueAndValidity();
      // }
    }

    // alert('Test');
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  onDateChanged(event: IMyDateModel): void { }


  fillEquipment(divisionID) {
    console.log(this.detailsType);
    if (divisionID != 0) {

      this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
        x => x.value === divisionID
      );
      if (this.selectedDialysisUnitsobj && this.selectedDialysisUnitsobj[0] && this.selectedDialysisUnitsobj[0].text == 'Home Program') {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Water Process'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {

          list.unshift({ text: 'Select', value: '' });
          this.Equipments = list;
        });
      }
      else {
        const value = {
          DivisionId: this.f.selectedDialysisUnits.value,
          CategoryName: this.detailsType,
          Type: 'EQUIPMENT'
        };
        console.log(value);
        this.wpapi.GetEquipmentByCategoryDD(value).subscribe(
          list => (this.Equipments = list)
        );
      }
    } else {
      this.Equipments = [{ value: '', text: 'Select' }];

      if (this.homeService !== true)
        this.f.selectedEquipment.setValue('');
    }

  }


  fillParts() {
    const value = {
      DivisionId: this.f.selectedDialysisUnits.value,
      CategoryName: this.detailsType,
      Type: 'PARTS'
    };
    this.wpapi.GetPartsDDByCategory(value).subscribe(list => this.Parts = list);
  }
  executerepairCreateUpdate = function (Source, isFlyout = false) {
    return new Promise((resolve, reject) => {


this.isValidFormSubmitted = false;
if (this.repairDetailForm.invalid) {
  AppComponent.addAlert(Globals.FormErrorMessage, 'error');
  AppComponent.loading = false;
  resolve({ 'isSubmitted': false, 'errormsg': Globals.FormErrorMessage });
  if(!isFlyout)
    this.scrollHelper.scrollToFirst('ng-invalid');
  AppComponent.ScrollDown();
  return;
}
this.isValidFormSubmitted = true;

    AppComponent.loading = true;
    if (Source === 'Notes' && this.notes.showParts === true && this.notes.Parts.length > 0) {
      const partTrans = this.parts.PartTrans;
      //console.log(this.parts);
      this.notes.Parts.forEach(function (value) {
        partTrans.push(value);
      });
      this.parts.PartTrans = partTrans;
    }
    // this.f.hoursin.updateValueAndValidity();
    // this.f.hoursout.updateValueAndValidity();


    const repairCreate = this.prepareCreateViewModel();

    if (this.notes.NoteList.length == 0 && this.notes.Note == '') {
      AppComponent.loading = false;

      let Type = this.EventRepairOptions.find(o => o.value == this.f.selectedRepairEvent.value).text;


      AppComponent.addAlert('Please add at least one '+Type+' Note to save this '+Type+'.', 'error');

      resolve({ 'isSubmitted': false, 'errormsg': 'Please add at least one '+Type+' Note to save this '+Type+'.' });

      this.scrollHelper.scrollToFirst('panel-collapse collapse show');
      AppComponent.HideLoader();
      let elm = document.getElementById("dmPartsRef");
      elm.click();
      return;
    }
    else {
      if (repairCreate.repairdetailid == '0') {
        this.wpapi.CreateWatrerProcessRepairsRecord(repairCreate).subscribe(
          success => {
            this.DMRepailDetail = success.dmRepailDetails;
            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            //this.router.navigate(['DialysisMachine/DMRepairDetailSearch']);

            // if (!this.homeService) {
            //   if (Source != 'Notes') {
            //     this.router.navigate([this.ListURL]);
            //   } else {
            //     this.f.RepairDetailsId.setValue(success.repairdetailid);

            //     this.setModel();
            //   }
            // } else {
            //   if (Source != 'Notes') {
            //     this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
            //   } else {
            //     this.setModel();
            //   }
            // }
            if (Source != "Notes" && !isFlyout) {
              if (this.homeService != true) {
                this.router.navigate([this.ListURL]);
              }
              else {
                this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
              }
            } else {
              this.f.RepairDetailsId.setValue(success.repairdetailid);
              this.setModel();
            }
            resolve({ 'isSubmitted': true, 'errormsg': 'Data Saved successfully!' });

          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      } else {
        this.wpapi.UpdateWatrerProcessRepairsRecord(repairCreate).subscribe(
          success => {
            console.log(success);
            this.DMRepailDetail = success.dmRepailDetails;
            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            //alert('Data Saved successfully!');
            // if (!this.homeService) {
            //   if (Source != 'Notes') {
            //     this.router.navigate([this.ListURL]);
            //   } else {
            //     this.setModel();
            //   }
            // } else {
            //   if (Source != 'Notes') {
            //     this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
            //   } else {
            //     this.setModel();
            //     if (isFlyout)
            //         resolve({ 'isSubmitted': true, 'errormsg': 'Data Saved successfully!' });
            //   }
            // }

            if (Source != "Notes" && !isFlyout) {
              if (this.homeService != true) {
                  this.router.navigate([this.ListURL]);
              } else {
                this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
              }
            } else {
              this.setModel();
              if (isFlyout)
                resolve({ 'isSubmitted': true, 'errormsg': 'Data Saved successfully!' });
            }

          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }

      this.f.hoursin.clearAsyncValidators();
      this.f.hoursin.clearValidators();
      this.f.hoursin.updateValueAndValidity();

      this.f.hoursout.clearAsyncValidators();
      this.f.hoursout.clearValidators();
      this.f.hoursout.updateValueAndValidity();
    }
    });
  };

  prepareCreateViewModel() {
    const repairCreate = <any>{};

    repairCreate.repairdetailid = this.f.RepairDetailsId.value;
    repairCreate.repairtype = this.detailsType;
    repairCreate.prefix = this.f.selectedRepairEvent.value;
    repairCreate.HFServiceId = this.f.HFServiceId.value;
    repairCreate.repairId = this.repairId;

    if (this.f.timeStarted.value !== null && this.f.timeStarted.value !== undefined) {
      repairCreate.timeStarted = Globals.GetAPIDate(this.f.timeStarted.value.jsdate);
    }

    repairCreate.hoursin = this.f.hoursin.value;
    repairCreate.hoursout = this.f.hoursout.value;

    repairCreate.divisionId = this.f.selectedDialysisUnits.value;
    repairCreate.equipmentId = this.f.selectedEquipment.value;

    repairCreate.workorderid = null;
    repairCreate.workOrderRefNo = this.f.workOrderRefNo.value;

    repairCreate.createdOn = Globals.GetAPIDate(this.today);
    repairCreate.createdBy = this.currentUserId;

    repairCreate.modifiedOn = Globals.GetAPIDate(this.today);
    repairCreate.modifiedBy = this.currentUserId;

    if (this.f.timeFinished.value !== null && this.f.timeFinished.value !== undefined) {
      repairCreate.timefinished = Globals.GetAPIDate(this.f.timeFinished.value.jsdate);
    }


    repairCreate.remark = this.f.remarks.value;
    repairCreate.outHydraulicDisinfection = this.f.outHydraulicDisinfection.value;

    repairCreate.status = this.status;
    repairCreate.note = this.notes.Note;

    // parts
    if (this.parts != null && this.parts.PartTrans != null)
      repairCreate.repairPartsDetails = this.parts.PartTrans;

    // attachments
    repairCreate.attachmentDetails = this.attachment.GenerateAttachmentList();


    repairCreate.hrsTracking = this.hrsTracking;
    // console.log(repairCreate);
    return repairCreate;
  }


  EquipmentDropDownChanged() {

    this.f.hoursin.setValue('');

    this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;
    this.parts.SelectedEquipment = this.f.selectedEquipment.value;
    console.log(this.f.selectedEquipment.value);
    this.notes.DivisionId = this.f.selectedDialysisUnits.value;
    this.notes.EquipementId = this.f.selectedEquipment.value;

    //if (this.f.RepairDetailsId.value == '0') {
    var repairCreate = { Id: this.f.selectedEquipment.value };
    this.dmapi.GetEquipmentDetailsByID(repairCreate).subscribe(success => {
      // this.EquipmentSerialNo = success.serialnumber;
      // this.EquipmentInServiceDate = success.inservicedate;
      this.selectedEquipmenttext = success.equipmentcode;

      this.hrsTracking = success.hrsTracking;

      this.setHoursInValidation(this.hrsTracking);
      this.setHoursOutValidation(this.hrsTracking);

    });
    //}

    var eqId = { Id: this.f.selectedEquipment.value };
    this.dmapi.GetmachaineHistoryMaxHours(eqId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
        this.f.selectedHoursOut.setValue(result);
      } else {
        this.selectedEquipmentHours = 0;
        this.f.selectedHoursOut.setValue("");
      }
      // var temp = this.Equipments.filter(
      //   x => x.value == this.f.selectedEquipment.value
      // );
      // console.log(temp,this.Equipments,this.f.selectedEquipment.value);
      // if(temp.length > 0) {
      //   this.selectedEquipmenttext = temp[0].text;
      // }
    });
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const dmReparirObj = { Id: this.f.RepairDetailsId.value };
        this.wpapi.DeleteWatrerRepairsRecord(dmReparirObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Repair detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }





  // Parts variables

  showDoc() {
    if (this.f.selecthydrolicDropDownList.value.value != '') {
      var openURL =
        Globals.DocumentResourcesUrl + this.f.selecthydrolicDropDownList.value;
      window.open(openURL);
    }
  }

  showImage(args): void {

    if (args.target.value != 'Select') {
      const dialogRef = this.dialog.open(ImageViewerDialog, {
        position: { right: '0', top: '0' },
        height: '100%',
        width: '620px',
        data: { imgURL: args.target.value, title: args.target.options[args.target.selectedIndex].text }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('Image dialog was closed');
      });
    }
  }


  fillDialysisUnit() {
    this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;

      // this.DialysisUnitsWithoutHome = list.filter(
      //   x => x.text != "Home Program"
      // );
      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }

       this.DialysisUnitsWithoutHome = list;

      console.log(this.homeService);
      if (this.f.RepairDetailsId.value != '0') {
        this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
          x => x.value === this.f.selectedDialysisUnits.toString()
        );
        if (this.selectedDialysisUnitsobj.length > 0) {
          this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
        }
      }
      else {

        if (this.homeService) {
          this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
            x => x.text === 'Home Program'
          );
        }
        if (this.selectedDialysisUnitsobj.length > 0) {
          this.f.selectedDialysisUnits.setValue(this.selectedDialysisUnitsobj[0].value);
          this.fillEquipment(this.selectedDialysisUnitsobj[0].value);
        }
      }
    });
  }

  SetHomeServiceHoursoutValidation(enable) {
    console.log(enable);
    this.hfcompleted = enable;
    if (enable == true) {
      this.f.hoursout.setValidators([
        ,
        Validators.pattern(Globals.NumberNumericRegexWithoutZero),
        CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.outTimeOfEquipment
        ),
        CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
          this.f.hoursin
        )
      ]);

      this.f.hoursout.updateValueAndValidity();

    } else {
      this.f.hoursout.clearValidators();
      this.f.hoursout.updateValueAndValidity();

    }
  }

  DialysisUnitDropDownChanged() {
    this.parts.SelectedDialysisUnits = this.f.selectedEquipment.value;
    this.notes.DivisionId = this.f.selectedDialysisUnits.value;
    this.fillEquipment(this.f.selectedDialysisUnits.value);
    if (this.f.RepairDetailsId.value == 0) {
      this.f.selectedEquipment.setValue('');
      this.selectedEquipmentHours = 0;
      this.f.selectedHoursOut.setValue("");
      this.f.hoursin.setValue('');
    }
  }

  SetPageRights() {
    this.auth.GetPageRights('DM Repair Form').subscribe(list => {
      if (this.f.RepairDetailsId.value != '0') {
        this.blnshowSave = list[actionType.edit] == '1';
        //this.blnshowDelete = list[actionType.delete] == '1';
      } else {
        this.blnshowSave = list[actionType.create] == '1';
        //this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.RepairDetailsId.value != '0') {
      this.blnshowDelete = true;
    }
  }
  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }
  GreaterValueCheck() {
    this.f.hoursout.updateValueAndValidity();
    this.f.timeFinished.updateValueAndValidity();
    this.f.outHydraulicDisinfection.updateValueAndValidity();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(): boolean {
    if (this.f.hoursin.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }

  /*equipment button*/
  OpenHistory(EquipId) {

    AppComponent.ShowLoader();

    const inventory = {
      Id: EquipId
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {
          // EquipId, SerialNo, Name, InserviceDate
          this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
            disableClose: true,
            position: { right: '0', top: '0' },
            height: '100%',
            width: '70%',
            // maxWidth: '60vh',
            maxHeight: '100vh'
          });

          this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
          this.machineHistoryDialogRef.componentInstance.SrNo = success.serialnumber;
          this.machineHistoryDialogRef.componentInstance.MachineName = success.equipmentcode;
          this.machineHistoryDialogRef.componentInstance.InServiceDate = success.inservicedate;
          // this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
          //   '95%',
          //   '95%'
          // );
          this.machineHistoryDialogRef.afterClosed().subscribe(result => {
            this.machineHistoryDialogRef = null;
          });

        }
        AppComponent.HideLoader();
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');

        AppComponent.HideLoader();
      }
    );

  }
  transferStationEquipment(type) {

    this.quickUpdateTransferDialogRef = this.dialog.open(QuickHomeEquipmentTransfer, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      maxWidth: '50vh',
      maxHeight: '100vh',
      data: {
        type: type,
        stationId: this.selectedDialysisStationId,
        stationName: this.selectedDialysisStationtext,
        roEquipmentId: this.f.selectedEquipment.value,
      }
    });

    this.quickUpdateTransferDialogRef.afterClosed().subscribe(result => {
    });

    this.quickUpdateTransferDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      console.log(evt);
      this.f.selectedEquipment.setValue(evt.roEquipmentId);
      this.EquipmentDropDownChanged();
    });
  }
  /*end equipment button*/
  checkHoursInOutDifference(): boolean {
    if (
      this.f.hoursout.value == null ||
      this.f.hoursout.value == "" ||
      this.f.hoursout.value == undefined
    ) {
      return false;
    } else {
      // if(this.status != 'Completed')
      // {
      //   this.setHoursOutValidation(this.hrsTracking);
      // }
      if (this.f.hoursout.value - this.f.hoursin.value >= 100) {

        if (document.getElementById("spanInGreaterOut") != null && document.getElementById("spanInGreaterOut").style.display == "block")
          document.getElementById("spanInGreaterOut").style.display = "none";
        return true;
      } else {
        return false;
      }
    }
  }
}

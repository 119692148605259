import { HomeService } from './../../../API/home.service';
import { InvApiService } from './../../../API/invapi.service';
import { HomeFieldService } from './../../../API/homefield.service';
import { AppComponent } from 'src/app/app.component';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DMApiService } from "../../../API/dmapi.service";
import { Globals } from '../../../globals';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeEquipmentExchange',
  templateUrl: './purpose-equipmentexchange.component.html',
  styleUrls: ['./purpose-equipmentexchange.component.css']
})

export class PurposeEquipmentExchangeComponent implements OnInit {
  matDialogRef: MatDialogRef<ConfirmationDialog>;
  @Output() SaveEvent = new EventEmitter<any>();
  errormsg = '';
  currentUserId;
  CategoryList = [];
  NewEquipmentList = [];
  OldEquipmentList = [];
  NewEquipmentIdHours = 0;
  OldEquipmentIdHours = 0;
  IsPurposeCompleted = '';
  OldEquipmentName = '';
  NewEquipmentName = '';
  selectedOldEquipmentHours = 0;
  selectedNewEquipmentHours = 0;
  purposeCompleted: boolean = false;
  textColor = Globals.textColor;
  formSubmitted = false;
  EquipmentExchangeTypeText = '';
  YesNoList = [
    { value: '', text: 'Select' },
    { value: 'N/A', text: 'N/A' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No', color: 'red' },
  ];

  PassFailList = [
    { value: '', text: 'Select' },
    { value: 'Pass', text: 'Pass' },
    { value: 'Fail', text: 'Fail', color: 'red' },
  ];

  equipmentExchangeForm = new UntypedFormGroup({
    EquipmentExchangeId: new UntypedFormControl('0'),
    dialysisUnitId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl('0'),
    EquipmentExchangeType: new UntypedFormControl('', Validators.required),
    OldEquipmentId: new UntypedFormControl(''),
    NewEquipmentId: new UntypedFormControl('', Validators.required),
    HoursIn: new UntypedFormControl('', Validators.required),
    HoursOut: new UntypedFormControl('', Validators.required),
    DMPtServiceSetting: new UntypedFormControl('', Validators.required),
    DMAcidFormulation: new UntypedFormControl('', Validators.required),
    EquipmentProperFunctioning: new UntypedFormControl('', Validators.required),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
  });

  today = new Date();

  ngOnInit() {

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

    this.fillCategories();

    this.f.HoursIn.setValidators([
      Validators.required,
      Validators.pattern(Globals.NumberNumericRegexWithoutZero)
    ]);

    this.f.HoursIn.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.NewEquipmentId,
        this.f.EquipmentExchangeId,
        this.dmapi
      )
    ]);
    this.f.HoursIn.updateValueAndValidity();

    this.f.HoursOut.setValidators([
      Validators.required,
      Validators.pattern(Globals.NumberNumericRegexWithoutZero)
    ]);

    this.f.HoursOut.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.OldEquipmentId,
        this.f.EquipmentExchangeId,
        this.dmapi
      )
    ]);

    this.f.HoursOut.updateValueAndValidity();
    this.f.EquipmentExchangeType.setValue(this.f.EquipmentExchangeType.value);
  }, error => {
  });
  }

  constructor(private invapi: InvApiService, private commonapi: CommonApiService,
    private homeApi: HomeService, private homefieldApi: HomeFieldService, private dmapi: DMApiService
    , public dialog: MatDialog, public auth : AuthService
    , public dialogRef: MatDialogRef<PurposeEquipmentExchangeComponent>) { }

  get f() {
    return this.equipmentExchangeForm.controls;
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list.filter(
        x => x.text !== 'Other Equipment'
      );
    });

    if(this.IsPurposeCompleted == 'Yes')
      this.fetchCompletedTypes();

    if (!this.f.EquipmentExchangeType.value)
      this.f.EquipmentExchangeType.setValue('');
  }


  fetchCompletedTypes(){
    this.homefieldApi.GetHomeFieldService_PurposeCompletedTypeListbyServiceID({ Id: this.f.HFServiceId.value, type:'Equipment Exchange' }).subscribe(
      success => {

        if (success) {
          let catList = [];
          catList.push({value:'',text:'Select'});
          success.forEach((i, newKey) => {
            let category = this.CategoryList.filter(
              x => x.text == i
            );
            if(category && category.length > 0)
              catList.push(category[0]);



          });
          this.CategoryList = catList;
        }
      },
      error => {
          AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  EquipmentExchangeTypeChanged() {

    AppComponent.ShowLoader();

    this.f.EquipmentExchangeId.setValue('');
    this.f.DMPtServiceSetting.setValue('');
    this.f.DMAcidFormulation.setValue('');
    this.f.EquipmentProperFunctioning.setValue('');

    this.f.NewEquipmentId.setValue('');
    this.f.HoursIn.setValue('');
    this.f.HoursOut.setValue('');

    this.fetchEquipmentsFromDialysisStations();
    this.f.OldEquipmentId.disable();
    this.fillDMROList();

    this.setModel();

    AppComponent.HideLoader();
  }

  fetchEquipmentsFromDialysisStations() {
    this.homeApi.GetDialysisStationByID({ Id: this.f.dialysisUnitId.value }).subscribe(
      success => {
        if (success) {
          if (this.getExchangeTypeText(this.f.EquipmentExchangeType.value) == 'Dialysis Machines') {
            this.f.OldEquipmentId.setValue(success.dmEquipmentId ? success.dmEquipmentId : '');
          }
          else if (this.getExchangeTypeText(this.f.EquipmentExchangeType.value) == 'Water Process') {
            this.f.OldEquipmentId.setValue(success.roEquipmentId ? success.roEquipmentId : '');
          }
          this.OldEquipmentIdChanged();
        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  fillDMROList() {
    const obj = {
      DialysisStationId: this.f.dialysisUnitId.value,
      CategoryName: this.getExchangeTypeText(this.f.EquipmentExchangeType.value)
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.OldEquipmentList = list;
      this.NewEquipmentList = list.filter(
        (item, index) => item.value != this.f.OldEquipmentId.value.toString()
      );
    });

    // this.f.NewEquipmentId.setValue('');
    this.NewEquipmentIdChanged(false);
    this.OldEquipmentIdChanged(false);
    // this.selectedNewEquipmentHours = 0;
    // this.selectedOldEquipmentHours = 0;
  }


  NewEquipmentIdChanged(clearNewVal: boolean = true) {
    var newEquipmentId = { Id: this.f.NewEquipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(newEquipmentId).subscribe(result => {
      if (result) {
        this.selectedNewEquipmentHours = result;
      } else {
        this.selectedNewEquipmentHours = 0;
      }
      // if (clearNewVal) {
      //   this.f.HoursIn.setValue('');
      // }
    });
  }

  OldEquipmentIdChanged(clearNewVal: boolean = true) {
    var oldEquipmentId = { Id: this.f.OldEquipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(oldEquipmentId).subscribe(result => {
      if (result && this.f.EquipmentExchangeType.value && this.f.EquipmentExchangeType.value != '') {
        this.selectedOldEquipmentHours = result;
      } else {
        this.selectedOldEquipmentHours = 0;
      }
      // if (clearNewVal && !this.purposeCompleted) {
      //   this.f.NewEquipmentId.setValue('');
      //   this.f.HoursIn.setValue('');
      //   this.f.HoursOut.setValue('');
      // }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(IsExchangedEquipment): boolean {
    if (IsExchangedEquipment) {
      if (this.f.HoursIn.value - this.selectedNewEquipmentHours >= 4000) {
        return true;
      } else {
        return false;
      }
    }
    else {
      if (this.f.HoursOut.value - this.selectedOldEquipmentHours >= 4000) {
        return true;
      } else {
        return false;
      }

    }
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  setModel() {

    // this.fillDMEquipments();
    AppComponent.ShowLoader();
    AppComponent.loading = true;
    // this.fillROEquipments();
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list.filter(
        x => x.text !== 'Other Equipment'
      );

      this.homefieldApi.GetHomeFieldService_EquipmentExchangebyServiceID({ Id: this.f.HFServiceId.value, type: this.getExchangeTypeText(this.f.EquipmentExchangeType.value) }).subscribe(
        success => {
          if (success) {

            setTimeout(() => {

              this.f.EquipmentExchangeId.setValue(success.equipmentExchangeId);
              this.f.EquipmentExchangeType.setValue(this.getExchangeTypeValue(success.equipmentExchangeType));
              this.EquipmentExchangeTypeText = success.equipmentExchangeType;
              this.f.OldEquipmentId.setValue(success.oldEquipmentId);
              this.f.NewEquipmentId.setValue(success.newEquipmentId);

              this.OldEquipmentName = success.oldEquipment;
              this.NewEquipmentName = success.newEquipment;
              this.f.HoursIn.setValue(success.hoursIn);
              this.f.HoursOut.setValue(success.hoursOut);
              this.f.DMPtServiceSetting.setValue(success.dmPtServiceSetting);
              this.f.DMAcidFormulation.setValue(success.dmAcidFormulation);
              this.f.EquipmentProperFunctioning.setValue(success.equipmentProperFunctioning);
              this.f.CreatedOn.setValue(success.createdOn);
              this.f.CreatedBy.setValue(success.createdBy);
              this.f.ModifiedOn.setValue(success.modifiedOn);
              this.f.ModifiedBy.setValue(success.modifiedBy);
              this.fillDMROList();
              // this.setOldNewEquipmentNames();
              this.f.OldEquipmentId.disable();
            }, 500);
          }
          // this.EquipmentExchangeTypeChanged();
          AppComponent.HideLoader();
          AppComponent.loading = false;

        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
          AppComponent.loading = false;
        }
      );
    },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
        AppComponent.loading = false;
      });
  }


  setOldNewEquipmentNames() {
    const obj = {
      CategoryName: this.getExchangeTypeText(this.f.EquipmentExchangeType.value)
    };

    this.commonapi.FillEquipmentsByCategory(obj).subscribe(list => {
      let oldEquipment = list.filter(
        x => x.value == this.f.OldEquipmentId.value
      );

      if (oldEquipment.length > 0)
        this.OldEquipmentName = oldEquipment[0].text;

      let newEquipment = list.filter(
        x => x.value == this.f.NewEquipmentId.value
      );

      if (newEquipment.length > 0)
        this.NewEquipmentName = newEquipment[0].text;
    });
  }

  prepareCreateViewModel(Source = "") {
    const modelInitCreate = <any>{};

    modelInitCreate.EquipmentExchangeId = this.f.EquipmentExchangeId.value;
    modelInitCreate.HFServiceId = this.f.HFServiceId.value;
    // modelInitCreate.EquipmentExchangeType = this.f.EquipmentExchangeType.value;
    modelInitCreate.EquipmentExchangeType = this.getExchangeTypeText(this.f.EquipmentExchangeType.value);
    modelInitCreate.OldEquipmentId = this.f.OldEquipmentId.value;
    modelInitCreate.NewEquipmentId = this.f.NewEquipmentId.value;
    modelInitCreate.HoursIn = this.f.HoursIn.value;
    modelInitCreate.HoursOut = this.f.HoursOut.value;
    modelInitCreate.DMPtServiceSetting = this.f.DMPtServiceSetting.value;
    modelInitCreate.DMAcidFormulation = this.f.DMAcidFormulation.value;
    modelInitCreate.EquipmentProperFunctioning = this.f.EquipmentProperFunctioning.value;
    modelInitCreate.createdOn = Globals.GetAPIDate(this.today);
    modelInitCreate.createdBy = this.currentUserId;
    modelInitCreate.modifiedOn = Globals.GetAPIDate(this.today);
    modelInitCreate.modifiedBy = this.currentUserId;
    modelInitCreate.purposeCompleted = this.purposeCompleted;
    modelInitCreate.OldEquipmentName = this.getOldEquipmentText(this.f.OldEquipmentId.value);
    modelInitCreate.NewEquipmentName = this.getNewEquipmentText(this.f.NewEquipmentId.value);

    return modelInitCreate;
  }

  getNewEquipmentText(value) {

    var NewEquipmentName = this.NewEquipmentList.filter(
      (item, index) => item.value == value);
    if (NewEquipmentName.length > 0)
      return NewEquipmentName[0].text;
    else
      return '';
  }

  getOldEquipmentText(value) {

    var OldEquipmentName = this.OldEquipmentList.filter(
      (item, index) => item.value == value);
    if (OldEquipmentName.length > 0)
      return OldEquipmentName[0].text;
    else
      return '';
  }

  getExchangeTypeText(value) {
    var ExchangeType = this.CategoryList.filter(
      (item, index) => item.value == value);
    if (ExchangeType.length > 0)
      return ExchangeType[0].text;
    else
      return '';
  }

  getExchangeTypeValue(text) {
    var ExchangeType = this.CategoryList.filter(
      (item, index) => item.text == text);

    if (ExchangeType.length > 0)
      return ExchangeType[0].value;
    else
      return 0;
  }

  saveAndClose() {

    if (this.equipmentExchangeForm.dirty) {
      if (this.equipmentExchangeForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

            if(result)
              this.dialogRef.close(false);
            else
              this.saveEquipmentExchange();
          });
      }
      else{
          this.saveEquipmentExchange();
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  save() {
    return new Promise((resolve, reject) => {

      this.formSubmitted = true;


      if (this.equipmentExchangeForm.invalid) {

        // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        // this.errormsg= Globals.FormErrorMessage;
        AppComponent.loading = false;

        AppComponent.ScrollDown();
        return;
      }

      const FIModelCreate = this.prepareCreateViewModel();

      if (!this.f.EquipmentExchangeId.value || this.f.EquipmentExchangeId.value === '0'
      ) {
        this.homefieldApi.CreateHomeFieldService_EquipmentExchange(FIModelCreate).subscribe(
          success => {
            this.f.EquipmentExchangeId.setValue(success.EquipmentExchangeId);
            resolve(true);
          },
          error => {
            reject();
          }
        );
      } else {
        this.homefieldApi.UpdateHomeFieldService_EquipmentExchange(FIModelCreate).subscribe(
          success => {
            resolve(true);
          },
          error => {
            reject();
          }
        );

      }
    });
  }

  formChange(enable = false) {
    if (enable) {
      // this.equipmentExchangeForm.enable();

      this.f.OldEquipmentId.enable();
      this.f.NewEquipmentId.enable();
      this.f.HoursIn.enable();
      this.f.HoursOut.enable();
      this.f.DMPtServiceSetting.enable();
      this.f.DMAcidFormulation.enable();
      this.f.EquipmentProperFunctioning.enable();
    }
    else {
      // this.equipmentExchangeForm.disable();
      this.f.OldEquipmentId.disable();
      this.f.NewEquipmentId.disable();
      this.f.HoursIn.disable();
      this.f.HoursOut.disable();
      this.f.DMPtServiceSetting.disable();
      this.f.DMAcidFormulation.disable();
      this.f.EquipmentProperFunctioning.disable();
    }



    this.EquipmentExchangeChangeEvent();
  }


  saveEquipmentExchange() {
    this.save().then(() => {
      this.SaveEvent.emit();
      this.close();
    }, () => {
      AppComponent.addAlert("Error", 'error');
    });
  }

  EquipmentExchangeChangeEvent() {
    // this.f.EquipmentExchangeType.disable();
    setTimeout(() => {
      this.fillDMROList();
      this.fetchEquipmentsFromDialysisStations();
    }, 700);

    this.f.OldEquipmentId.disable();

  }
  getColor(item, colorList) {
    if (colorList.filter(x => x.color == "red" && x.value == item).length > 0)
      return "red";
    else
      return "#555";
  }

  close() {
    this.dialogRef.close(false);
  }

}

import { Component, ViewChild } from '@angular/core';
import { WPApiService } from '../../API/wpapi.service';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'NurseCheckSearch',
  templateUrl: './NurseCheckSearch.component.html',

})
export class NurseCheckSearchComponent {

  constructor( private dmapi: DMApiService,
    private commonapi : CommonApiService,
    private wpapi: WPApiService, private router: Router,
    private auth: AuthService,
    private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];

  Statuses = [
    { value: '', text: 'Select' },
    { value: 'In Progress', text: 'In Progress' },
    { value: 'Completed', text: 'Completed' }
  ];
  selectedDivision = 'All Divisions';
  selectedStatus = '';
  EventId = '';
  FromDate;
  ToDate;
  tableHeaders;
  blnDirtySearch = false;
  page = new Page();
    rows ;
  AddUrl = 'WaterProcess/SaturdayWaterRecordDetail';

  currentPageNumber = 1;
  sortExpression = 'createdon';
  sortDirection = 'DESC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Nurse Checks';
  searchText = 'Search Records';
  defaultSort;
  isPageLoaded = false;

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {

            this.dataGridService.clearHeader();
            this.dataGridService.addHeader('#', 'eventId', '4');
            this.dataGridService.addHeader('Event Code', 'eventid', '15');
            this.dataGridService.addHeader('Date', 'createdon', '15');
            this.dataGridService.addHeader('Dialysis Unit', 'divisionName', '25');
            this.dataGridService.addHeader('1st Shift', 'morningContact', '15');
            this.dataGridService.addHeader('2nd Shift', 'noonContact', '15');
            this.dataGridService.addHeader('Status', 'status', '10');
            this.tableHeaders = this.dataGridService.setTableHeaders();
            this.defaultSort = this.dataGridService.setDefaultSort('createdon', true);

            this.executeNurseCheckSearch();

            if (division.length === 1)
            this.fillDialysisUnit(division[0]);
          else
            this.fillDialysisUnit();

            this.CheckDirtySearch();
           // this.fillStatus();
           AppComponent.HideLoader();
}, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

changeSorting(column) {

  if (column === '#') {
      return;
  }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);

    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeNurseCheckSearch();

}

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

executeNurseCheckSearch() {
  AppComponent.ShowLoader();
  this.pageheader.isSearchOpen = false;
  this.pageheader.searchText = 'Search Records';
  this.ShowSearch = false;
  this.searchText = 'Search Records';
  this.CheckDirtySearch();
  const  NurseChecksearch = this.prepareSearch();
  this.wpapi.GetSaturdayWaterprocessDailyRecords(NurseChecksearch).subscribe(pagedData  => {
    this.page.totalElements = pagedData.totalRows;
    this.page.totalPages = pagedData.totalPages;
    // console.log(pagedData);
    this.rows = pagedData.saturdaywaterrecordDetails;
   // console.log(this.rows);
    this.isPageLoaded = true;
    AppComponent.HideLoader();
  }
  ,
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
  );
}

prepareSearch() {

  const NurseChecksearch = <any>{};
  NurseChecksearch.eventid = this.EventId;
  if (this.FromDate !== undefined && this.FromDate !== '' ) {
    NurseChecksearch.fromdate = Globals.GetAPIDate( this.FromDate.jsdate);
  }
  if (this.ToDate !== undefined && this.ToDate !== '' ) {
    NurseChecksearch.todate = Globals.GetAPIDate( this.ToDate.jsdate);
  }


  if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision != 'All Divisions') {
    NurseChecksearch.duid = this.selectedDivision;
  }



  if (this.selectedStatus !== undefined && this.selectedStatus !== '') {
    NurseChecksearch.status = this.selectedStatus;
  }

  NurseChecksearch.CurrentPageNumber = this.currentPageNumber;
  NurseChecksearch.SortExpression = this.sortExpression;
  NurseChecksearch.SortDirection = this.sortDirection;
  NurseChecksearch.PageSize = this.pageSize;
 // console.log(NurseChecksearch);
  return NurseChecksearch;

}

  setPage() {
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber = this.page.pageNumber;
    if (this.isPageLoaded === true) {
  //  console.log('setpage');
    this.executeNurseCheckSearch();
    }
  }
}
 _onRowClick(row) {
      this.router.navigate([this.AddUrl], { queryParams: { id: row} });
}



onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit(defaultValue='') {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits = list);
    this.commonapi.FillDivision(true).subscribe(list =>{

      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        this.DialysisUnits = list.filter(
          x => x.text != "Home Program"
        );
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(()=> this.executeNurseCheckSearch());
        }
      }
    });
  }

  executeNurseCheckSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.selectedStatus = '';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';

    this.executeNurseCheckSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if ( this.EventId !== '' ||
      (this.selectedDivision !== '' && this.selectedDivision !== 'All Divisions')
    || this.selectedStatus  !== ''
    || (this.FromDate != null && this.FromDate !== '')
    || (this.ToDate != null && this.ToDate !== '')) {

      this.blnDirtySearch = true;

    }
   //  console.log(this.blnDirtySearch);
    return this.blnDirtySearch;



    // if(this.FromDate != null && this.FromDate != '')
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }
    // if(this.ToDate != null && this.ToDate != '')
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }

  }

  DialysisUnitDropDownChanged() {

    this.executeNurseCheckSearch();
  }
}

export class Page {
  // The number of elements in the page
  // tslint:disable-next-line:no-inferrable-types
  size: number = 25;
  // The total number of elements
  // tslint:disable-next-line:no-inferrable-types
  totalElements: number = 0;
  //  The total number of pages
  // tslint:disable-next-line:no-inferrable-types
  totalPages: number = 0;
  // The current page number
  // tslint:disable-next-line:no-inferrable-types
  pageNumber: number = 0;
}

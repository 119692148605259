import { Globals } from './../../globals';
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  selector: 'ForumMessagesDialog',
  templateUrl: './ForumMessagesDialog.html'
})
export class ForumMessagesDialog implements OnInit {
  allForums : any;
  newMessageForum : any;
  latestMessageForum : any;

  constructor(public dialogRef: MatDialogRef<ForumMessagesDialog>, public commonApi : CommonApiService) { }

  ngOnInit() {

    this.commonApi.GetLatestForums().subscribe(
      success => {
        this.allForums = success;
        console.log(this.allForums);

        this.newMessageForum = this.allForums.filter((item) => item.CreationDate >= Globals.lastLoginDateTime);
        this.latestMessageForum = this.allForums.filter((item) => item.CreationDate < Globals.lastLoginDateTime);
      },
      error => {
        console.log(error);
      });
  }

  formatHTML(args){

    var text = args.replace(/<\/?[^>]+>/ig, "");
    text = text.replace(/(?:\\[rn]|[\r\n]+)+/g, "");
    return text;
  }

}

import { DMApiService } from 'src/app/API/dmapi.service';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { AppComponent } from 'src/app/app.component';
import { PurposeOtherComponent } from '../../Purpose/purpose-other/purpose-other.component';
import { Globals } from 'src/app/globals';
import { InvApiService } from 'src/app/API/invapi.service';
import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/API/auth.service';
import { SectionPurposeDetailDialog } from '../section-purposedetail/section-purposedetail.component';

@Component({
  selector: 'app-panel-otherPurpose-detail',
  templateUrl: './panel-otherPurpose-detail.component.html',
  styleUrls: ['./panel-otherPurpose-detail.component.css']
})
export class PanelOtherPurposeDetailComponent implements OnInit {

  public today = new Date();

  isCollapsed = false;  
  sectionInProgress = false;
  @Input() HFMasterServiceId = 0;
  @Input() OtherId = 0; 
  isValidFormSubmitted = true;
  @Output() SaveEvent = new EventEmitter<any>();
  @Input() MasterCompleted: boolean = false;
  @Input() isLastAdded : boolean = false;
  isBluePanelSection = false;
YesNoList = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];

  currentUserId = this.auth.GetUserId();

  otherForm = new UntypedFormGroup({
    OtherId: new UntypedFormControl('0'),
    HFServiceID: new UntypedFormControl(''),
    DialysisUnitId: new UntypedFormControl(''),
    Issue: new UntypedFormControl('',Validators.required),
    CreatedBy: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl('')
  });

  constructor(
    private dmapi: DMApiService,
    private homefieldApi: HomeFieldService,
    public dialog: MatDialog,
    private auth: AuthService    
  ) { }

  ngOnInit() {
    this.f.HFServiceID.setValue(this.HFMasterServiceId);
    this.f.OtherId.setValue(this.OtherId);   

    if(this.MasterCompleted)
    this.isBluePanelSection = true;
    
    setTimeout(() => {     
      this.setModel();      
    }, 100);

  }

  ngAfterViewInit() {
  }

  executeOtherSection() {
    this.isValidFormSubmitted =false;
    if(this.otherForm.invalid)
    {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');      
      AppComponent.loading = false;
      AppComponent.ScrollDown();
      return;
    }
    else
      this.OpenPurposeDetail();         
  }

  get f() {
    return this.otherForm.controls;
  }

  SectionPurposeDetailDialogRef: MatDialogRef<SectionPurposeDetailDialog>;
  OpenPurposeDetail() {    

    AppComponent.ShowLoader();
    this.SectionPurposeDetailDialogRef = this.dialog.open(SectionPurposeDetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceId.setValue(this.f.HFServiceID.value);    
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.OtherId.setValue(this.f.OtherId.value);    
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.DialysisUnitId.setValue(this.f.DialysisUnitId.value);    
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServicePurposeDetailNotes.setValue(this.f.Issue.value);
    this.SectionPurposeDetailDialogRef.componentInstance.YesNoList = this.YesNoList;    
    this.SectionPurposeDetailDialogRef.componentInstance.showDepartureDetail = true;    
    this.SectionPurposeDetailDialogRef.componentInstance.isOtherSection = true;    
    this.SectionPurposeDetailDialogRef.componentInstance.setOtherSectionModel();    
    
    //this.SectionPurposeDetailDialogRef.componentInstance.setModel(inProgressPurposeDetails, inProgress);

    this.SectionPurposeDetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      this.SaveEvent.emit(evt);
      this.setModel();
      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    AppComponent.HideLoader();
  }

  setModel() {

    this.homefieldApi.GetHomeFieldService_OtherbyID({ Id: this.f.OtherId.value }).subscribe(
      success => {
        if (success) {
          this.f.OtherId.setValue(success.otherId);
          this.f.HFServiceID.setValue(success.hfServiceID);
          this.f.DialysisUnitId.setValue(success.dialysisUnitId);
          this.f.Issue.setValue(success.issue);
          this.f.CreatedBy.setValue(success.createdBy);
          this.f.CreatedOn.setValue(success.createdOn);
          this.f.ModifiedBy.setValue(success.modifiedBy);
          this.f.ModifiedOn.setValue(success.modifiedOn);

          if(!success.modifiedOn){
            this.sectionInProgress = true;
            this.isBluePanelSection = true;
            this.ExpandSection();
          }
        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  ExpandSection() {   
      this.isCollapsed = !this.isCollapsed;
  }

}

import { ContactApiService } from 'src/app/API/contactapi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';
import { SchApiService } from '../API/schapi.service';
import { map, filter } from 'rxjs/operators';
import { ScheduleColorDialog } from '../Core/Shared/ColorDialog/ColorDialog';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../globals';


@Component({
  selector: 'SchTech',
  templateUrl: './ScheduleTech.component.html'
})
export class ScheduleTechnologistComponent
  implements OnInit, CanComponentDeactivate {
  // Variables
  // -------------------------------------Start----------------------------------------------
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  DialysisUnits: any = [];
  pageRights = '';
  loading = true;
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDivision = 0;
  StartDate = new Date();
  ScheduleType = 0; // Technologist = 0, Staff = 1, All =2
  DateList = [];
  SchList: any = [];
  CopySchList: any = [];
  ScheduleUserTypeList: any = [];
  PrevDate: Date;
  NextDate: Date;
  Note = '';
  exportfilename = '';
  IsLock = true;
  ColorList = [];
  HolidayList = [];
  searchText = 'Search Records';
  ShowSearch = false;
  blnShowNote = false;
  blnShowLock = false;
  blnShowDivision = false;
  colorDialogRef: MatDialogRef<ScheduleColorDialog>;
  // -------------------------------------End------------------------------------------------

  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private schapi: SchApiService,
    // dialogRef: MatDialogRef<ConfirmationDialog>,
    public dialog: MatDialog,
    private auth: AuthService,
    private contactApi: ContactApiService
  ) {}

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.IsLock) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
         // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }
  // -------------------------------------End------------------------------------------------

  ngOnInit() {
    this.SetPageRights();
    this.fillScheduleUserType();

    setTimeout(() => {
      this.fillDialysisUnit();
    }, 200);

    AppComponent.ShowLoader();
    this.schapi.GetAllScheduleColors().subscribe(list => {
      this.ColorList = list;
    });
    AppComponent.HideLoader();
  }

  fillScheduleUserType() {

    this.contactApi
      .FillScheduleUserType()
      .subscribe(list => {

        this.ScheduleUserTypeList = list;
      });
  }

  filterScheduleItems(type) {
    return this.SchList.filter(x => x.userDesc === type);
  }

  SetPageRights() {
    this.auth.GetPageRights('Technologist Schedule').subscribe(list => {
      this.blnShowNote = list[actionType.addnotes] === '1';
    });
    this.schapi.GetallowScheduleEdit().subscribe(list => {
      this.blnShowLock = list[1] === '1';
    });

  }

  CopyScheduleList() {
    this.CopySchList = [];
    //this.CopySchedule = this.SchList;
    this.SchList.forEach((nos) => {
     this.CopySchList.push(nos);
  })
  AppComponent.addAlert(
    'Schedule records copied successfully!',
    'success'
  );
  console.log(this.CopySchList,this.SchList);
  }

PasteSchedule() {
  this.dialogRef = this.dialog.open(ConfirmationDialog, {
    disableClose: true
  });
  this.dialogRef.componentInstance.confirmMessage =
    'Are you sure you replace the schedule?';
  this.dialogRef.afterClosed().subscribe(result => {
    if (result) {
      AppComponent.ShowLoader();
  this.SchList = [];
  this.CopySchList.forEach((nos) => { // foreach statement
    this.SchList.push(nos);
 })
 console.log(this.CopySchList,this.SchList);
 AppComponent.addAlert(
  'Schedule records pasted successfully!',
  'success'
);
 AppComponent.HideLoader();
      }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  // Functions/Methods
  // -------------------------------------Start----------------------------------------------

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  fillDialysisUnit() {
    //this.schapi.GetDialysisUnitDD().subscribe(list => {
      this.schapi.GetDialysisUnitDD().subscribe(list => {

      this.DialysisUnits = list;
          this.StartDate = null;
          this.blnShowDivision = true;
          console.log(list);
          // this.selectedDivision = this.DialysisUnits[this.DialysisUnits.length - 1 ].value;
          this.SetScheduleList();
        });
  }

  DialysisUnitDropDownChanged() {

    this.StartDate = null;
    this.SetScheduleList();
  }

  PreviousList() {

    this.StartDate = this.PrevDate;
    this.SetScheduleList();
  }

  NextList() {

    this.StartDate = this.NextDate;
    this.SetScheduleList();
  }

  ChangeLock(status) {
    // if (this.selectedDivision < 1) {
    //   this.IsLock = true;
    //   return;
    // }
    if (this.IsLock !== status && status === true) {
      let schInsertList = [];
      this.SchList.forEach((item, index) => {
        for (let _i = 0; _i < 42; _i++) {
          let iItem = <any>{};
          iItem.scheduletype = this.ScheduleType;
          iItem.contactid = item.userid;
          iItem.description = item['date' + (_i + 1)];
          iItem.scheduledate = this.DateList[_i];
          iItem.createdby = this.currentUserId;
          iItem.modifiedby = this.currentUserId;

          schInsertList.push(iItem);
        }
      });

      let schData = <any>{};
      schData.notes = this.Note;
      schData.schedulefromdate = this.DateList[0];
      schData.scheduletodate = this.DateList[this.DateList.length - 1];
      schData.divisionid = this.selectedDivision;
      schData.createdBy = this.currentUserId;
      schData.Schedulelst = schInsertList;
      // schData.scheduleusertypeid =  3;
      const scheduleTypeCls = this.ScheduleUserTypeList.filter(x => x.text === 'Technologist');
      if(scheduleTypeCls.length > 0)
        schData.scheduleusertypeid = scheduleTypeCls[0].value;
      this.schapi.CreateScheduleDetails(schData).subscribe(list => {
      });

      let temp = this.SchList;
      this.SchList = [];
      this.SchList = temp;
    }
    this.IsLock = status;
  }

  SetColor(date, Value) {
    let color = '';
    if (this.HolidayList !== undefined && this.HolidayList.length > 0) {
      if (this.HolidayList.filter(x => x.userDesc === date).length > 0) {
        color = '#ccc';
      }
    }
    if (this.ColorList.length > 0) {
      const tempVal = Value.substring(0, 1);
      const colorCls = this.ColorList.filter(x => x.symbol === tempVal);
      if (colorCls.length > 0) {
        color = colorCls[0].color;
      }
    }
    return color;
  }

  DisplayDesc(value) {
    if (this.ColorList.length > 0) {
      const tempVal = value.substring(0, 1);
      const colorCls = this.ColorList.filter(x => x.symbol === tempVal);
      if (colorCls.length > 0) {
        if (value.length > 1) {
          return value.substring(1, value.length);
        } else {
          return '';
        }
      }
    }
    return value;
  }

  ShowColors() {
    this.colorDialogRef = this.dialog.open(ScheduleColorDialog, {
      disableClose: true
    });
    this.colorDialogRef.componentInstance.ScheduleColors = this.ColorList;
  }

  Print() {

    let params = <any>{};
    AppComponent.ShowLoader();
    params.startDate = this.DateList[0];
    params.division = this.selectedDivision;
    // params.scheduleusertypeid = 3;
    const scheduleTypeCls = this.ScheduleUserTypeList.filter(x => x.text === 'Technologist');
    if(scheduleTypeCls.length > 0)
      params.scheduleusertypeid = scheduleTypeCls[0].value;

   // console.log(params);
    this.schapi.PrintSchedulePDF(params).subscribe(list => {
      this.exportfilename = list;
      AppComponent.HideLoader();
      const openURL = Globals.WorkOrderPFDsUrl + this.exportfilename;
      window.open(openURL);
   });
  }

  SetScheduleList() {

    AppComponent.ShowLoader();
    let params = <any>{};
    params.startDate = this.StartDate;
    params.division = this.selectedDivision;
    params.ScheduleType = this.ScheduleType;
    const scheduleTypeCls = this.ScheduleUserTypeList.filter(x => x.text === 'Technologist');
    if(scheduleTypeCls.length > 0)
      params.scheduleusertypeid = scheduleTypeCls[0].value;
    // console.log(params);
    this.schapi.SetList(params).subscribe(list => {

      this.DateList = list.datesList;
      this.PrevDate = list.prevDate;
      this.NextDate = list.nextDate;
      this.SchList = list.scheduleLst;
      this.Note = list.notes;
      this.HolidayList = list.Holidayslst;
      AppComponent.HideLoader();
    });
  }
}

<div class="modal-content">
  <form [formGroup]="InventoryForm" (ngSubmit)="executeInventoryCreateUpdate()" novalidate>
    <div class="modal-header">
      <h4 class="modal-title">Quick Add Part</h4>
      <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="data.errormsg" class="box-row col-sm-12">
        <span [ngClass]="'error'">
          {{data.errormsg}}
        </span>
      </div>
      <div class="box-row col-sm-12">

        <span class='text-red inputrequired'>*</span>Manufacturer:
        <select class="form-control" formControlName="manufacturerId" name="manufacturerId">
          <option value="">Select</option>
          <option value="{{item.value}}" *ngFor="let item of ManufacturerList">{{item.text}}</option>
        </select>
        <span
          *ngIf="f.manufacturerId.errors?.required && (f.manufacturerId.dirty || f.manufacturerId.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>

      <div class="box-row col-sm-12" style="display:none !important">
        <span class='text-red inputrequired'>*</span>Model:
        <select class="form-control" formControlName="modelId" name="modelId">
          <option value="">Select</option>
          <option value="{{item.value}}" *ngFor="let item of ModelList">
            {{item.text}}</option>
        </select>
        <span
          *ngIf="f.modelId.errors?.required && (f.modelId.dirty || f.modelId.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>

      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>Item Name:
        <input name='equipmentName' maxlength='80' class='form-control' formControlName='equipmentName'>
        <span
          *ngIf='f.equipmentName.errors?.required && (f.equipmentName.dirty || f.equipmentName.touched || isValidFormSubmitted==false)'
          [ngClass]="'error'"> Required! </span>
      </div>

      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>Item ID:
        <input name='equipmentCode' maxlength='50' class='form-control' formControlName='equipmentCode'
          (keyup)="forceInputUppercase($event)">
        <span
          *ngIf='f.equipmentCode.errors?.required && (f.equipmentCode.dirty || f.equipmentCode.touched || isValidFormSubmitted==false)'
          [ngClass]="'error'"> Required! </span>
        <span
        *ngIf="f.equipmentCode.errors?.ItemID" [ngClass] = "'error'">
         Item ID already exists.
        </span>

      </div>
      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>Description:
        <textarea name='equipmentDesc' rows='3' class="form-textarea" formControlName='equipmentDesc'></textarea>
        <span
          *ngIf="f.equipmentDesc.errors?.required && (f.equipmentDesc.dirty || f.equipmentDesc.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>
      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>Purchase Cost Each:
        <input name='itemCost' maxlength='10' class='form-control' formControlName='itemCost' (focusout)="transformitemCost()"
          (blur)="transformitemCost()" [(value)]="formatteditemCost" (keypress)="numberOnly($event)">
        <span
          *ngIf="f.itemCost.errors?.required && (f.itemCost.dirty || f.itemCost.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Required!
        </span>
        <span
          *ngIf="f.itemCost.errors?.maxlength && (f.itemCost.dirty || f.itemCost.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Purchase Cost Each must be less than $999,999
        </span>
       
      </div>
      <div class="box-row col-sm-12">
        <span class='text-red inputrequired'>*</span>Vendor:
        <select class="form-control" formControlName="vendorId" name="vendorId">
          <option value="">Select</option>
          <option value="{{item.contactId}}" *ngFor="let item of VendorList">
            {{item.givenName}}</option>
        </select>
        <span
          *ngIf="f.vendorId.errors?.required && (f.vendorId.dirty || f.vendorId.touched ||  isValidFormSubmitted==false)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="executeInventoryCreateUpdate()">Save</button>
    </div>
  </form>

</div>

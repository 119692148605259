import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ImageViewerDialogData {
  imgURL: string;
  title: string;
}

@Component({
  selector: 'image-viewer-dialog',
  templateUrl: 'ImageViewerDialog.html',
  styleUrls: ['ImageViewerDialog.css'],
})
export class ImageViewerDialog {
  zoomMode = "click";
  imageIndex =0;
  images = [
    ];
  constructor(
    public dialogRef: MatDialogRef<ImageViewerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerDialogData) {
      this.images.push(data.imgURL);

    }

  onOkClick(): void {
    this.dialogRef.close();
  }

  config = {
    wheelZoom: true
  }
}

<section class="content-header">
    <h1>
        {{title}}
        <sup *ngIf="title!=''" class="beta">
            [demo]
        </sup>
    </h1>
</section>

<section class="content">
    <div class="row">
        <div class="box  box-primary">
            <PageHeader [title]="title" [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)" [showMainListing]="false">
            </PageHeader>
            <div id="divSearch row" *ngIf="ShowSearch">

                <div class="col-md-12 box ibox box-primary">
                    <div class="box-header">
                        <h3 class="box-title">Search Records</h3>
                    </div>

                    <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Home Visit Purpose:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="HFServiceVisitPurpose">
                                                    <option value="{{item.value}}" *ngFor="let item of visitPurpose">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="col-sm-6">
                                            <div>
                                                <label class="col-sm-6 control-label">BMR Home Unit:</label>
                                                <div class="col-sm-6">
                                                    <select class="form-control" [(ngModel)]="HFServiceStationName">
                                                            <option value="">Select</option>
                                                            <option value="{{item.dialysisStationName}}" *ngFor="let item of dialysisStations">
                                                              {{item.dialysisStationName}}
                                                            </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>

                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <div class="col-sm-6">
                                    <div>
                                        <label class="col-sm-6 control-label">Home Field Service Code:</label>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" [(ngModel)]="HFServiceCode">
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="col-sm-6 control-label">Status:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="HFServiceStatus">
                                                <option value="{{item.value}}" *ngFor="let item of HFServiceStatuses">
                                                    {{item.text}}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>

                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>


                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-6">
                                    <label class="col-sm-6 control-label">Date From:</label>
                                    <div class="col-sm-6">
                                        <input (focus)="fromDate.toggleCalendar()" (click)="fromDate.openCalendar()"
                                            class="form-control" style="float:none" placeholder="Select a date"
                                            angular-mydatepicker name="FromDate" [(ngModel)]="FromDate"
                                            [options]="myOptions" #fromDate="angular-mydatepicker"
                                            (dateChanged)="onDateChanged($event)" />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="">
                                        <label class="col-sm-6 control-label">Date To:</label>
                                        <div class="col-sm-6">
                                            <input (focus)="toDate.toggleCalendar()" (click)="toDate.openCalendar()"
                                                class="form-control" style="float:none" placeholder="Select a date"
                                                angular-mydatepicker name="ToDate" [(ngModel)]="ToDate"
                                                [options]="myOptions" #toDate="angular-mydatepicker"
                                                (dateChanged)="onDateChanged($event)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>



                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-6">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-body row">
                        <div class="col-sm-12">

                            <button class="btn btn-primary btn-label-left center-block" id="btnSearchDetail"
                                (click)="executeHomeFieldServiceSearch()">
                                <span><i class="fa fa-search"></i></span>
                                <b>&nbsp;Search</b>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="box-body" id="dvboxbody">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-9 pull-right">
                            <div class="col text-right">
                                <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                    [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                    (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col text-left noofrecords">
                                <span><b> <i>{{page.totalElements}} Records Found.</i></b></span>
                                <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                        (click)="executeHomeFieldServiceSearchClear()">
                                        (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                        <b>Clear Search</b>
                                    </a>)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <table id="OptionList" style="width:100%" class="table table-bordered table-striped dataTable no-footer" role="grid">
                        <thead>
                            <tr>
                                <th *ngFor="let tableHeader of tableHeaders"
                                    [ngClass]="setSortIndicator(tableHeader.Label)" [style.width.%]="tableHeader.Width"
                                    (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let homefield of rows;let index=index;"
                                (click)="_onRowClick(homefield.hfServiceId, homefield.isHFSReferenced, homefield.hfServiceMasterCode)" class="pointer">
                                <td>
                                    {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                </td>
                                <td>
                                  <span *ngIf="homefield.isReferencedHFS" class='text-red inputrequired'> * </span>
                                    {{homefield.hfServiceCode}}
                                </td>
                                <td [matTooltip]="homefield.hfServiceVisitDates && homefield.hfServiceVisitDates.includes(',') ? homefield.hfServiceVisitDates : null" >{{homefield.hfServiceVisitDate | date:'dd/MM/yyyy'}}{{ homefield.hfServiceVisitDates && homefield.hfServiceVisitDates.includes(',') ? '...' : ''}}</td>
                                <td>{{homefield.stationName}}</td>
                                <td>
                                    {{homefield.hfServiceVisitPurpose}}
                                </td>
                                <td>
                                    {{homefield.hfServiceCategory}}
                                </td>
                                <td class="text-left">
                                    <div class="break-words">
                                    {{homefield.hfServiceIssue}}
                                    </div>
                                </td>

                                <!-- <td>{{homefield.hfServiceStatus}}</td> -->
                                <td matTooltip="{{homefield.hfServiceStatus}}">
                                    <div class="progressbar-wrapper">
                                        <ul class="progressBar">				
                                            <!-- <li [ngClass]="{'progressBarlightGreen':homefield.hfServiceStatus !== 'Completed'}"><i class="fa fa-check"></i></li>
                                            <li [ngClass]="{'progressBarlightGreen':(homefield.hfServiceStatus == 'Pending' || homefield.hfServiceStatus == 'In Progress'),'progressBarlightBlue':homefield.hfServiceStatus == 'Not Started' }">
                                                <i [ngClass]="{'fa-check': (homefield.hfServiceStatus == 'Pending' || homefield.hfServiceStatus == 'In Progress'),'fa-arrow-right': homefield.hfServiceStatus == 'Not Started', 'fa':homefield.hfServiceStatus != '' }"></i>
                                            </li>
                                            <li [ngClass]="{'progressBarlightGreen':homefield.hfServiceStatus == 'In Progress','progressBarlightBlue':homefield.hfServiceStatus == 'Pending','progressbarEmpty': homefield.hfServiceStatus == 'Not Started' }">
                                                <i [ngClass]="{'fa-check': homefield.hfServiceStatus == 'In Progress','fa-arrow-right': (homefield.hfServiceStatus != 'Completed' && homefield.hfServiceStatus == 'Pending'), 'fa':homefield.hfServiceStatus != '' }"></i>
                                            </li>            
                                            <li [ngClass]="{'progressBarlightGreen': homefield.hfServiceStatus == 'Completed','progressBarlightBlue': homefield.hfServiceStatus == 'In Progress' ,'progressbarEmpty': (homefield.hfServiceStatus == 'Not Started' || homefield.hfServiceStatus == 'Pending') }">
                                                <i [ngClass]="{'fa-check': homefield.hfServiceStatus == 'Completed', 'fa-arrow-right':homefield.hfServiceStatus == 'In Progress', 'fa':homefield.hfServiceStatus != '' }">
                                                </i>
                                            </li> -->
                                            <li [ngClass]="{'progressBarlightGreen':homefield.hfServiceStatus == 'Completed',
                                                            'progressBarlightBlue':homefield.hfServiceStatus.includes('In Progress'),
                                                            'progressbarEmptyRed': homefield.hfServiceStatus == 'Not Started' }">
                                            <i [ngClass]="{'fa-check': homefield.hfServiceStatus == 'Completed',
                                                           'fa-arrow-right': homefield.hfServiceStatus.includes('In Progress'),
                                                           'fa':homefield.hfServiceStatus != '' }"> 	
                                            </i>
                                            </li> 
                                            <li [ngClass]="{'progressbarEmptyRed': homefield.hfServiceStatus == 'Pending'}">
                                                <i [ngClass]="{'fa-arrow-right':homefield.hfServiceStatus == 'Pending', 'fa':homefield.hfServiceStatus != ''}">
                                                </i>
                                            </li>   
                                        </ul> 
                                    </div>                                    
                                </td>	
                                <td>
                                    <!-- <button *ngIf="homefield.hfServiceStatus == 'Completed'" (click)="OpenPDF(homefield.hfServiceId);" class="btn btn-primary center-block btn-xs"> -->
                                        <span *ngIf="homefield.hfServiceStatus == 'Completed'" (click)="OpenPDF(homefield.hfServiceId);" style="font-size: 18px;" title="Open PDF"><i class="fa fa-file-pdf-o "></i></span>
                                    <!-- </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-9 pull-right">
                            <div class="col text-right">
                                <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                    [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                    (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="col text-left noofrecords">
                                <span><b> <i>{{page.totalElements}} Records Found.</i></b></span>
                                <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                        (click)="executeHomeFieldServiceSearchClear()">
                                        (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                        <b>Clear Search</b>
                                    </a>)
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

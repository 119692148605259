<div [formGroup]="panelPurposeForm" novalidate>
  <div class="box-body box ibox box-info">
    <div class="panel panel-default">
      <!-- always blue bcoz panel shows only when active or HFS Completed -->
      <div class="panel-heading-blue"        
      [ngClass]="{'panel-heading-blue': isBluePanelSection==true,'panel-heading': isBluePanelSection==false}"
        role="tab">
        <!--  -->
        <h4 class="panel-title row">
          <div class="col-sm-10" style="cursor:pointer" (click)="ExpandSection()">
            <a class="collapsed" data-parent="#accordion" aria-controls="HFS_SectionRef" [attr.aria-expanded]="isCollapsed">
              <div class="box-header with-border" style="padding: 6px 0px;">
                <h3 class="box-title col-sm-4 container">Visit Purpose Detail - Child
                  <img src="../../assets/img/completed_icon.png"
                    style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                    *ngIf="f.HFServicePTHMPurposeCompletedOn.value">

                </h3>
                <h3 *ngIf="MRMHomeStationName!=''" class="col-sm-6 text-center box-title container">
                  <b>{{MRMHomeStationName}} </b> <b *ngIf="MRMHomeStationName!='' && f.HFServiceVisitPurpose.value!=''"> - </b><b *ngIf="f.HFServiceVisitPurpose.value!=''">
                    ({{f.HFServiceVisitPurpose.value}})</b></h3>
              </div>
            </a>
          </div>
          <div class="row col-sm-2" style="text-align:right">
            <button type="button" class="row btn btn-primary"
               *ngIf="isAdditionalAddDeparture"
              (click)="executeHomeFieldServiceCreateUpdate()">
              <i class="fa fa-clock-o"></i> &nbsp; Departure Details</button>
              

            <button type="button" class="row btn btn-primary"
               tooltip="Add Arrival Detail"
               *ngIf="isAdditionalAddArrival"
              (click)="executeHomeFieldServiceCreateUpdate()">
              <i class="fa fa-clock-o"></i> &nbsp; Arrival Details
            </button>
            <!-- *ngIf="isAdditionalAddArrival" -->


          </div>
        </h4>
      </div>

      <div id="HFS_Section" class="panel-collapse collapse" [ngbCollapse]="!isCollapsed"
        role="tabpanel" aria-labelledby="HFS_Section">
        <div class="box-body">
          <div class="box-row col-sm-12"  *ngIf="f.HFServicePTHMArrivalTime.value">
              <!-- *ngIf="f.HFServicePTHMArrivalTime.value" -->

            <span class="border border-primary col-sm-12" style="height: auto;text-align:center"
            *ngIf="f.HFServiceVisitPurpose.value!='Initial Site Visit' &&
                f.HFServiceVisitPurpose.value!='Uninstallation'">

              <mat-button-toggle-group [(ngModel)]="purposeSelectedId"
                                    name="purposeSelectedId" style="margin-left:1%"
                                    [ngModelOptions]="{standalone: true}">
                  <mat-button-toggle *ngFor="let item of visitPurposeWithoutRenovationList" [ngClass]="item.value == 'Prep Details' ? 'btnBlue' : ''"
                        value="{{item.value}}" (change)="purposeSelection(item)" [style.color]="item.isFormSubmitted ? '#4f68e1' : '' ">
                            {{item.text}}
                  </mat-button-toggle>
              </mat-button-toggle-group>
            </span>

            <span class="border border-primary" style="height: auto"
            *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit' ||                
                f.HFServiceVisitPurpose.value=='Uninstallation'">

              <!-- Add Dynamic Component based on the Purpose-->

              <PurposeInitialSiteVisit *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
              </PurposeInitialSiteVisit>
              <!-- <PurposeFullInstallation
                *ngIf="f.HFServiceVisitPurpose.value=='Renovation & Full Installation'">
              </PurposeFullInstallation> -->
              <PurposeUninstallation *ngIf="f.HFServiceVisitPurpose.value=='Uninstallation'">
              </PurposeUninstallation>

            </span>
          </div>
            <div class="box-body col-sm-12" *ngIf="PurposeDetailsList.length > 0">
                <table style="width:100%" align="center" class="table InnerTable table-responsive">
                  <!-- <tr>
                    <th class="thead-light" width="100%" colspan="5">
                      Visit Purpose Details List
                    </th>
                  </tr> -->
                  <tr>
                    <!-- <th class="InnerTableHead" style="width:5%">

                    </th> -->
                    <th class="InnerTableHead"  style="width:12%">
                      Pt. Hm Arrival Time
                    </th>
                    <th class="InnerTableHead"  style="width:12%">
                      Pt. Hm Dep.Time
                    </th>
                    <th class="InnerTableHead"  style="width:12%">
                      Purpose Completed?
                    </th>
                    <th class="InnerTableHead"  style="width:64%">
                      Notes
                    </th>

                  </tr>
                  <tbody *ngFor="let item of PurposeDetailsList;let index=index;">
                    <tr>
                      <!-- <td>
                        {{(index + 1)}}
                      </td> -->
                      <td>
                        {{item.hfServicePTHMArrivalTime  | date:'dd/MM/yyyy HH:mm'}}
                      </td>
                      <td>
                        {{item.hfServicePTHMDepartureTime  | date:'dd/MM/yyyy HH:mm'}}
                      </td>
                      <td>
                        {{item.hfServicePTHMPurposeCompleted}}
                      </td>
                      <td style="text-align: left">
                        <div class="break-words">
                        {{item.hfServicePurposeDetailNotes}}
                      </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            <!-- <div class="col-sm-12"  style="margin-top: 15px;">
              <notes [hidden]="!(f.HFServiceVisitPurpose.value=='Other')" #notesVisitPurposePlus
                (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section5',true,true)">
              </notes>
            </div> -->

            
          <div class="col-sm-12"  style="margin-top: 15px;" >
            <!-- [hidden]="!f.HFServicePTHMArrivalTime.value"  -->
            <notes
              #notesVisitPurposePlus
              (addNoteClick)="executeHomeFieldServiceCreateUpdate(true)">
            </notes>

            <attachment *ngIf="f.HFServiceVisitPurpose.value!='Repair'" #attachmentVisitPurposePlus
              id="attachmentVisitPurposePlus" (RaiseError)="AttachmentError($event)">
            </attachment>
          </div>
          <div class="text-right col-sm-12">
              <button type="button" class="btn btn-primary"
              *ngIf="displaySaveButton('VisitPurposePlus')"
              (click)="executeHomeFieldServiceCreateUpdate(true)">
              <i class="fa fa-save"></i> &nbsp; Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

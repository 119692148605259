<form [formGroup]="ScheduleColorForm" (ngSubmit)="executeScheduleColorCreateUpdate('')" novalidate>
    <section class="content-header">
        <h1>
            {{title}}
        </h1>
    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">

                <PageHeader   [showNote]="false" [showAddNew]="false" [showSearch]="false"
                    [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeScheduleColorCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Symbol:
                                    </label>
                                    <div class="col-sm-10"><input type="text" class="form-control mdInput"
                                            formControlName="symbol" name="symbol" maxlength="1">
                                        <span
                                            *ngIf="f.symbol.errors?.required && (f.symbol.dirty || f.symbol.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Pick Color:</label>
                                    <div class="col-sm-10">
                                        <!-- <input type="text" class="form-control mdInput"
                                            formControlName="color" name="color" maxlength="10"> -->
                                            <!-- <select class="form-control mdInput" formControlName="color" name="color">
                                                <option *ngFor="let datas of data" [ngStyle]="{'background-color': datas.value}" [value]="datas.value"  [style.color]="'white'" >{{datas.text}}</option>
                                            </select> -->
                                            <input [style.background-color]="f.color.value" class="form-control mdInput"
                                            [(colorPicker)]="f.color" formControlName="color"
                                            [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                                            (colorPickerChange)="setColor($event)"
                                            >
                                        <span
                                            *ngIf="f.color.errors?.required && (f.color.dirty || f.color.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label">Description:</label>

                                    <div class="col-sm-10">
                                        <textarea rows='3' autosize class="form-textarea" formControlName="description" name="description"></textarea>
                                        <span
                                            *ngIf="f.description.errors?.required && (f.description.dirty || f.description.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                                <notes [isValidForm]="this.ScheduleColorForm.invalid"
                                    (addNoteClick)="executeScheduleColorCreateUpdate('Notes')" #notes></notes>
                                <attachment (RaiseError)="AttachmentError($event)" #attachment>
                                </attachment>
                            </div>
                        </div>
                        <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
                            [showSave]="blnshowSave" (SaveEvent)="executeScheduleColorCreateUpdate('')"
                            [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                    </div>
                </div>
            </div>
        </div>

    </section>
</form>

<form [formGroup]="AutoTaskDetailForm" (ngSubmit)="executeAutoTaskCreateUpdate('')" novalidate>


  <!-- <RepairPart></RepairPart> -->
  <section class="content-header">
    <h1>
      Auto Task
      <!-- <sup class="beta">[beta]</sup> -->


    </h1>


  </section>
  <section class="content" style="padding-bottom: 20px;margin-bottom: 30px">


    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary" style="padding-bottom: 40px">


        <PageHeader   [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
          [showSave]="blnshowSave" [showEdit]="false" (SaveEvent)="executeAutoTaskCreateUpdate('')"
          MainListingText="Cancel" [showDelete]="false" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
        <div class="clearfix"></div>
        <div class="box">
          <div class="box-body">
            <div class="box ibox box-info">
              <!-- <div class="box-header with-border">
                            <h3 class="box-title">AM</h3>
                        </div> -->
              <div class="box-body">
                <!-- <div class='col-sm-2'>
                                            <label class='col-sm-12 control-label'></label>
                                            </div>
                                            <div class="col-sm-12 box-row">
                                                    <div class='col-sm-5'>
                                                        
                                                        </div>
                                                        
                                                        <div class='col-sm-7'>
                                                            <label class='col-sm-12'>Auto Populate:</label>
                                                           
                                                            </div>
                                                    </div>
                
                                                <div class="clearfix"></div> -->
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Auto Task Id:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{tasknumber}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>RO Microbiology (Monthly):</label>
                    <div class='col-sm-8'>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <mat-button-toggle-group *ngIf="roEquipId>0" value="true" formControlName="ROMicroBiology"
                              name="ROMicroBiology" #ROMicroBiologygroup="matButtonToggleGroup">

                              <mat-button-toggle value="true" class="btnsuccess">

                                {{onYes}}

                              </mat-button-toggle>
                            </mat-button-toggle-group>

                          </div>
                        </div>
                        <!-- <label class="col-sm-2  control-label" *ngIf ="f.ROMicroBiology.value=='true'">Last:</label>
                                                  <div class="col-sm-5">
                                                  <input (focus)="rROMicroBiologyLastDate.toggleCalendar()"  *ngIf ="f.ROMicroBiology.value=='true'" 
                                                  (click)="rROMicroBiologyLastDate.openCalendar()" placeholder="Last date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="ROMicroBiologyLastDate"
                                                  formControlName="ROMicroBiologyLastDate" [options]="myOptions"
                                                  #rROMicroBiologyLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                />
                                            </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Home Station ID:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{stationName}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>DM Microbiology (Monthly):</label>
                    <div class='col-sm-8'>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <mat-button-toggle-group  value="" formControlName="DMMicroBiology"
                              name="DMMicroBiology" form #DMMicroBiologygroup="matButtonToggleGroup">

                              <mat-button-toggle value="true" class="btnsuccess">

                                {{onYes}}

                              </mat-button-toggle>

                              <mat-button-toggle class="btndanger" value="">

                                {{offNo}}

                              </mat-button-toggle>



                            </mat-button-toggle-group>
                            
                            <!-- <label *ngIf ="f.DMMicroBiology.value=='true'" class="col-sm-2  control-label">Last:</label>
                                                              <div class="col-sm-5">
                                                              <input *ngIf ="f.DMMicroBiology.value=='true'" (focus)="rDMMicroBiologyLastDate.toggleCalendar()"  
                                                              (click)="rDMMicroBiologyLastDate.openCalendar()" placeholder="Last date"
                                                            class="form-control mdInput" style="float:none"
                                                            angular-mydatepicker name="DMMicroBiologyLastDate"
                                                              formControlName="DMMicroBiologyLastDate" [options]="myOptions"
                                                              #rDMMicroBiologyLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                            /> 
                                                        </div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Created Date:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{CreatedDate | date:'dd/MM/yyyy HH:mm'}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>RO Pre Filteres (6 Months):</label>
                    <div class='col-sm-8'>
                      
                        <div class="row">
                            <div class="col-sm-12">
                          <div class="col-sm-5">
                            <mat-button-toggle-group  value="true" 
                              formControlName="ROPreFilters" name="ROPreFilters" form
                              #ROPreFiltersgroup="matButtonToggleGroup">

                              <mat-button-toggle value="true" class="btnsuccess">

                                {{onYes}}

                              </mat-button-toggle>





                            </mat-button-toggle-group>
                           
                          </div>
                          <div class="col-sm-7">
                            <label *ngIf="f.ROPreFilters.value=='true'" class="col-sm-3  control-label">Last:</label>
                            <div class="col-sm-9">
                              <!-- <input *ngIf="f.ROPreFilters.value=='true'"
                                (focus)="rROPreFiltersLastDate.toggleCalendar()"
                                (click)="rROPreFiltersLastDate.openCalendar()" placeholder="Last date"
                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                name="ROPreFiltersLastDate" formControlName="ROPreFiltersLastDate" [options]="myOptions"
                                #rROPreFiltersLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" /> -->
                               
                                <!-- <input type="text" *ngIf="f.ROPreFilters.value=='true'"
           #rROPreFiltersLastDate="dpDayPicker"
           [dpDayPicker]="dayPickerConfig"
           [theme]="'dp-material'"
           formControlName="ROPreFiltersLastDate"
           Name="ROPreFiltersLastDate"
           [mode]="'month'"
           class="form-control mdInput"
           placeholder="Last date"
           (onChange)="date = $event" /> -->
                                <span  *ngIf="(f.ROPreFiltersLastDate.errors?.required && (f.ROPreFiltersLastDate.dirty
                                 || f.ROPreFiltersLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                  Required!
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Created By:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{CreatedByUser}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>Iron Filters (6 Months):</label>
                    <div class='col-sm-8'>
                        <div class="row">
                        <div class="col-sm-12">
                        

                          <div class="col-sm-5">
                            <mat-button-toggle-group  value="true" 
                              formControlName="IronFilters" name="IronFilters" form
                              #IronFiltersgroup="matButtonToggleGroup">

                              <mat-button-toggle value="true" class="btnsuccess">

                                {{onYes}}

                              </mat-button-toggle>

                              <mat-button-toggle class="btndanger" value="">

                                {{offNo}}

                              </mat-button-toggle>



                            </mat-button-toggle-group>
                            
                          </div>


                          <div class="col-sm-7">
                            <label *ngIf="f.IronFilters.value=='true'" class="col-sm-3  control-label">Last:</label>
                            <div class="col-sm-9">
                              <!-- <input *ngIf="f.IronFilters.value=='true'" (focus)="rIronFiltersLastDate.toggleCalendar()"
                                (click)="rIronFiltersLastDate.openCalendar()" placeholder="Last date"
                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                name="IronFiltersLastDate" formControlName="IronFiltersLastDate" [options]="myOptions"
                                #rIronFiltersLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" /> -->
                            
                                <!-- <input type="text" *ngIf="f.IronFilters.value=='true'"
           #rIronFiltersLastDate="dpDayPicker"
           [dpDayPicker]="dayPickerConfig"
           [theme]="'dp-material'"
           formControlName="IronFiltersLastDate"
           Name="IronFiltersLastDate"
           [mode]="'month'"
           class="form-control mdInput"
           placeholder="Last date"
           (onChange)="date = $event" /> -->
                                <span  *ngIf="(f.IronFiltersLastDate.errors?.required && (f.IronFiltersLastDate.dirty
                                 || f.IronFiltersLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                  Required!
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Type:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{taskTypeName}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>Feed Water Analysis:</label>
                    <div class='col-sm-8'>

                      
                          <div class="row">
                              <div class="col-sm-12">
                                  <div class="col-sm-5">
                        <mat-button-toggle-group  value="" 
                          formControlName="FeedWaterAnalysis" name="FeedWaterAnalysis" form
                          #FeedWaterAnalysisgroup="matButtonToggleGroup">

                          <mat-button-toggle value="true" class="btnsuccess">

                            {{onYes}}

                          </mat-button-toggle>


                        </mat-button-toggle-group>

                        
                        <mat-button-toggle-group value="6M" style="margin-left: 10px" *ngIf="f.FeedWaterAnalysis.value=='true'" 
                          formControlName="FeedWaterAnalysisFrequency" name="FeedWaterAnalysisFrequency" form
                          #FeedWaterAnalysisFrequencygroup="matButtonToggleGroup">

                          <mat-button-toggle value="6M" class="btnsuccess">

                            {{onText}}

                          </mat-button-toggle>

                          <mat-button-toggle class="btndanger" value="1Yr">

                            {{offText}}

                          </mat-button-toggle>
                        </mat-button-toggle-group>
                                  </div>
                                  <div class="col-sm-7">
                        <label *ngIf="f.FeedWaterAnalysis.value=='true'" class="col-sm-3  control-label">Last:</label>
                        <div class="col-sm-9">
                          <!-- <input *ngIf="f.FeedWaterAnalysis.value=='true'"
                            (focus)="rFeedWaterAnalysisLastDate.toggleCalendar()"
                            (click)="rFeedWaterAnalysisLastDate.openCalendar()" placeholder="Last date"
                            class="form-control mdInput" style="float:none" angular-mydatepicker
                            name="FeedWaterAnalysisLastDate" formControlName="FeedWaterAnalysisLastDate"
                            [options]="myOptions" #rFeedWaterAnalysisLastDate="angular-mydatepicker"
                            (dateChanged)="onDateChanged($event)" /> -->
                       
                            <!-- <input type="text" 
           #rFeedWaterAnalysisLastDate="dpDayPicker"
           [dpDayPicker]="dayPickerConfig"
           [theme]="'dp-material'"
           formControlName="FeedWaterAnalysisLastDate"
           Name="FeedWaterAnalysisLastDate"
           [mode]="'month'"
           class="form-control mdInput"
           placeholder="Last date"
           *ngIf="f.FeedWaterAnalysis.value=='true'"
           (onChange)="date = $event" /> -->
                            <span  *ngIf="(f.FeedWaterAnalysisLastDate.errors?.required && (f.FeedWaterAnalysisLastDate.dirty
                                 || f.FeedWaterAnalysisLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                  Required!
                              </span>
                        </div>
                      </div>
                      </div>

                    </div>
                  </div>
                </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Division:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{divisionName}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>Product Water Analysis:</label>
                    <div class='col-sm-8'>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-5">
                        <mat-button-toggle-group  value="" 
                          formControlName="ProductWaterAnalysis" name="ProductWaterAnalysis" form
                          #ProductWaterAnalysisgroup="matButtonToggleGroup">

                          <mat-button-toggle value="true" class="btnsuccess">

                            {{onYes}}

                          </mat-button-toggle>


                        </mat-button-toggle-group>

                        
                        <mat-button-toggle-group style="margin-left: 10px" value="6M" *ngIf="f.ProductWaterAnalysis.value=='true'"
                          formControlName="ProductWaterAnalysisFrequency"
                          name="ProductWaterAnalysisFrequency" form
                          #ProductWaterAnalysisFrequencygroup="matButtonToggleGroup">

                          <mat-button-toggle value="6M" class="btnsuccess">

                            {{onText}}

                          </mat-button-toggle>

                          <mat-button-toggle class="btndanger" value="1Yr">

                            {{offText}}

                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                      <div class="col-sm-7">
                        <label *ngIf="f.ProductWaterAnalysis.value=='true'"
                          class="col-sm-3  control-label">Last:</label>
                        <div class="col-sm-9">
                          <!-- <input *ngIf="f.ProductWaterAnalysis.value=='true'"
                            (focus)="rProductWaterAnalysisLastDate.toggleCalendar()"
                            (click)="rProductWaterAnalysisLastDate.openCalendar()" placeholder="Last date"
                            class="form-control mdInput" style="float:none" angular-mydatepicker
                            name="ProductWaterAnalysisLastDate" formControlName="ProductWaterAnalysisLastDate"
                            [options]="myOptions" #rProductWaterAnalysisLastDate="angular-mydatepicker"
                            (dateChanged)="onDateChanged($event)" /> -->
                         
                            <!-- <input type="text" 
           #rProductWaterAnalysisLastDate="dpDayPicker"
           [dpDayPicker]="dayPickerConfig"
           [theme]="'dp-material'"
           formControlName="ProductWaterAnalysisLastDate"
           *ngIf="f.ProductWaterAnalysis.value=='true'"
           Name="ProductWaterAnalysisLastDate"
           [mode]="'month'"
           class="form-control mdInput"
           placeholder="Last date"
           (onChange)="date = $event" /> -->
                            <span  *ngIf="(f.ProductWaterAnalysisLastDate.errors?.required && (f.ProductWaterAnalysisLastDate.dirty || f.ProductWaterAnalysisLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                                Required!
                                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>
                    <label class='col-sm-5 control-label'>Master Date:</label>
                    <div class='col-sm-7'>
                      <label class=" readOnlyText">{{getWeekNumber(AutoTaskDate)}} {{AutoTaskDate | date:'EEEE'}}</label>
                    </div>
                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>RO GFI Testing (Yearly):</label>
                    <div class='col-sm-8'>
                        <div class="row">
                        <div class="col-sm-12">
                      <div class="col-sm-5">

                        
                        <mat-button-toggle-group  value="" 
                          formControlName="ROGFITesting" name="ROGFITesting" form
                          #ROGFITestinggroup="matButtonToggleGroup">

                          <mat-button-toggle value="true" class="btnsuccess">

                            {{onYes}}

                          </mat-button-toggle>


                        </mat-button-toggle-group>
                      </div>
                      <div class="col-sm-7">
                        <label *ngIf="f.ROGFITesting.value=='true'" class="col-sm-3  control-label">Last:</label>
                        <div class="col-sm-9">
                          <!-- <input *ngIf="f.ROGFITesting.value=='true'" (focus)="rROGFITestingLastDate.toggleCalendar()"
                            (click)="rROGFITestingLastDate.openCalendar()" placeholder="Last date"
                            class="form-control mdInput" style="float:none" angular-mydatepicker name="ROGFITestingLastDate"
                            formControlName="ROGFITestingLastDate" [options]="myOptions"
                            #rROGFITestingLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" /> -->
                           
                            <!-- <input type="text" 
                            #rROGFITestingLastDate="dpDayPicker"
                            *ngIf="f.ROGFITesting.value=='true'"
                            [dpDayPicker]="dayPickerConfig"
                            [theme]="'dp-material'"
                            formControlName="ROGFITestingLastDate"
                            Name="ROGFITestingLastDate"
                            [mode]="'month'"
                            class="form-control mdInput"
                            placeholder="Last date"
                            (onChange)="date = $event" /> -->
                            <span  *ngIf="(f.ROGFITestingLastDate.errors?.required && (f.ROGFITestingLastDate.dirty
                                 || f.ROGFITestingLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                  Required!
                              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 box-row">
                  <div class='col-sm-5'>

                  </div>

                  <div class='col-sm-7'>
                    <label class='col-sm-4 control-label'>DM GFI Testing (Yearly):</label>
                    <div class='col-sm-8'>
                        <div class="row">
                            <div class="col-sm-12">
                          <div class="col-sm-5">

                        <mat-button-toggle-group value=""  formControlName="DMGFITesting"
                          name="DMGFITesting" form #ROGFITestinggroup="matButtonToggleGroup" >

                          <mat-button-toggle value="true" class="btnsuccess">

                            {{onYes}}

                          </mat-button-toggle>


                        </mat-button-toggle-group>
                       
                      </div>
                      <div class="col-sm-7">
                        <label *ngIf="f.DMGFITesting.value=='true'" class="col-sm-3  control-label">Last:</label>
                        <div class="col-sm-9">
                            
                          <!-- <input type="text" 
                            #rDMGFITestingLastDate="dpDayPicker" *ngIf="f.DMGFITesting.value=='true'"                            
                            [dpDayPicker]="dayPickerConfig"
                            [theme]="'dp-material'"
                            formControlName="DMGFITestingLastDate"
                            Name="DMGFITestingLastDate"
                            [mode]="'month'"
                            class="form-control mdInput"
                            placeholder="Last date"
                            (onChange)="date = $event" /> -->
                          <!-- <input *ngIf="f.DMGFITesting.value=='true'" (focus)="rDMGFITestingLastDate.toggleCalendar()"
                            (click)="rDMGFITestingLastDate.openCalendar()" placeholder="Last date"
                            class="form-control mdInput" style="float:none" angular-mydatepicker name="DMGFITestingLastDate"
                            formControlName="DMGFITestingLastDate" [options]="myOptions"
                            #rDMGFITestingLastDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" /> -->
                            <span  *ngIf="(f.DMGFITestingLastDate.errors?.required && (f.DMGFITestingLastDate.dirty
                                 || f.DMGFITestingLastDate.touched ||  isValidFormSubmitted==false))" [ngClass] = "'error'">
                                  Required!
                              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>

              <!-- <notes *ngIf="f.taskid.value > 0"
                                [isValidForm]="this.TaskDetailForm.invalid"
                                (addNoteClick)="executeTaskCreateUpdate('Notes')"
                                #notes
                            >

                            </notes>
                            <attachment *ngIf="f.taskid.value > 0"
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment> -->
            </div>
          </div>

          <PageHeader   [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
            [showEdit]="false" [showSave]="blnshowSave" (SaveEvent)="executeAutoTaskCreateUpdate('')"
            MainListingText="Cancel" [showDelete]="false" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>


        </div>
      </div>
    </div>
  </section>


</form>

<section class="content">
    <div class="row">
        <div class="box  box-primary">
            <div
                class="box"
                id="dvboxbody"
            >
                <div class="row">
                    <div class="col-sm-2" style="display: none;">
                        <div
                            class="box ibox box-primary"
                            style="background-color: #3a4dab;color:#fff;padding-left: 15px;padding-top: 8px"
                        >
                            <br>
                            <strong>{{UserName}}</strong>
                            <br>
                            <strong>{{DesignationName}}</strong>
                            <br>
                            Last Login In: {{lastLoginDateTime | date:'dd/MM/yyyy'}}
                            <br>
                            Page Time: {{current_date | date:'HH:mm'}}
                            <br>
                            <br>
                            <strong>
                                <u>Open Repairs:</u>
                            </strong>
                            <br>
                            <label
                                *ngIf="checkAvailable('DM Repair Form')"
                                style="cursor:pointer;padding-left: 10px;"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('DialysisMachine/DMRepairDetailSearch')"
                                >
                                    D.Repairs:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right:10px;">{{openDmRepairs}}</span> </a>
                        </label>
                            <br>
                            <label
                                *ngIf="checkAvailable('Water Process Repair/Events')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('WaterProcess/Repairs/RepairDetailSearch')"
                                >
                                    H2O Process:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{openDmH2oProcess}}</span> </a>
                        </label>
                            <!-- <br>
                            <label
                                *ngIf="checkAvailable('Water Process Repair/Events')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="MenuClick('~/CentralDelivery/centralDeliveryRepairSearch.aspx');"
                                >
                                    Concentrates:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{openDmConcentrates}}</span>
                        </a>
                    </label> -->

                            <br>
                            <label
                                *ngIf="checkAvailable('Water Process Repair/Events')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('ORepairs/Repairs/RepairDetailSearch')"
                                >
                                    O.Repairs:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{openDmoRepairs}}</span>
                        </a>
                    </label>

                            <br>
                            <br>
                            <strong>
                                <u>Messages:</u>
                            </strong>
                            <br>
                            <label
                                *ngIf="checkAvailable('Bulletin')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="MenuClick('~/bulletin/bulletinSearch.aspx');"
                                >
                                    Unread Message:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{unReadMessage}}</span>
                        </a>
                    </label>
                            <br>
                            <br>


                            <strong>
                                <u>Tasks:</u>
                            </strong>
                            <br>
                            <label
                                *ngIf="checkAvailable('Bulletin')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('Task/TaskViewer')"
                                >
                                    Assign Tasks:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{AssignTasks}}</span>
                        </a>
                    </label><br>
                            <label
                                *ngIf="checkAvailable('Bulletin')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('Task/TaskViewer')"
                                >
                                    Unassigned Tasks:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{unAssignTasks}}</span>
                        </a>
                    </label><br>
                            <label
                                *ngIf="checkAvailable('Bulletin')"
                                style="cursor:pointer;padding-left: 10px"
                            >
                                <a
                                    href="javascript:void(0)"
                                    style="color: #fff;font-weight:normal;"
                                    (click)="RouteClick('Task/TaskViewer')"
                                >
                                    Over Due Tasks:

                            &nbsp;&nbsp;
                            <span style="color: #fff;font-weight:normal;padding-right: 10px;">{{unTasksoverDue}}</span>
                        </a>
                    </label>
                    <br>
                            <br><br>
                            <br><br>
                            <br><br>
                            <br><br> <br><br>
                            <br>
                        </div>


                        <div class="clearfix"></div>
                    </div>
                    <div class="col-sm-10 col-sm-offset-1">
                        <section class="content-header">
                            <h1 class="text-center">
                                {{current_date | date:'MMMM yyyy'}}
                                <!-- <sup class="beta">[beta]</sup> -->
                            </h1>



                        </section>
                        <section class="content">
                            <div class="row">
                                <!-- /.box -->

                                    <div class="box">
                                        <div class="box ibox box-info" style ="margin-bottom:0px">

                                            <div class="box-body" style ="padding:5px 10px">
                                            <div class="row">
                                                <div class="col-lg-3 col-sm-6">
                                                    <!-- <rg-gauge-chart
                                                        [canvasWidth]="canvasWidth"
                                                        [needleValue]="needleValueTotalTaskCompleted"
                                                        [centralLabel]="centralLabelTotalTaskCompleted"
                                                        [options]="options"
                                                        [name]="nameTotalTaskCompleted"
                                                    [bottomLabel]="bottomLabel"></rg-gauge-chart> -->
                                                </div>
                                                <div class="col-lg-3 col-sm-6">
                                                    <!-- <rg-gauge-chart
                                                        [canvasWidth]="canvasWidth"
                                                        [needleValue]="needleValueDmRepairsCompleted"
                                                        [centralLabel]="centralLabelDmRepairsCompleted"
                                                        [options]="options"
                                                        [name]="nameDmRepairsCompleted"
                                                    [bottomLabel]="bottomLabel"></rg-gauge-chart> -->
                                                </div>
                                                <div class="col-lg-3 col-sm-6">
                                                    <!-- <rg-gauge-chart
                                                        [canvasWidth]="canvasWidth"
                                                        [needleValue]="needleValueTotalRepairs"
                                                        [centralLabel]="centralLabelTotalRepairs"
                                                        [options]="optionsTotalRepairs"
                                                        [name]="nameTotalRepairs"
                                                    [bottomLabel]="bottomLabel"></rg-gauge-chart> -->
                                                </div>
                                                <div class="col-lg-3 col-sm-6">
                                                    <!-- <rg-gauge-chart
                                                        [canvasWidth]="canvasWidth"
                                                        [needleValue]="needleValueMonthtoMonthEfficiency"
                                                        [centralLabel]="centralLabelMonthtoMonthEfficiency"
                                                        [options]="optionsMonthtoMonthEfficiency"
                                                        [name]="nameTotalMonthtoMonthEfficiency"
                                                    [bottomLabel]="bottomLabel"></rg-gauge-chart> -->
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-sm-6">
                                        <div class="box ibox box-info" style ="margin-bottom:0px">
                                            <div class="box-header with-border">
                                                <h2 class="box-title"><strong>Monthly Tasks</strong></h2>
                                            </div>
                                            <div class="box-body" style ="padding:5px 10px">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                          <ng-container *ngFor="let item of divisionTaskList;let i = index">
                                                            <a href="javascript:void(0)" (click)="RouteClick('Task/TaskViewer')">
                                                    <BulletChart Title="{{item.divisionName}}"
                                                            MarkedValue="{{item.totTaskMonthlyCompleted}}"
                                                            Value="{{item.totalTasks}}" BarColor = "{{colorlist[i]}}"
                                                             > </BulletChart>
                                                        </a>
                                                          </ng-container>
                                                        </div>
                                                    </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-sm-6">
                                        <div class="box ibox box-info" style ="margin-bottom:0px">
                                            <div class="box-header with-border">
                                                <h2 class="box-title"><strong>Monthly Repairs</strong></h2>
                                            </div>
                                            <div class="box-body" style ="padding:5px 10px">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <ng-container *ngFor="let item of divisionRepairList;let i = index">
                                                            <a href="javascript:void(0)" >
                                                                <BulletChart Title="{{item.divisionName}}"
                                                              MarkedValue="{{item.totRepairsMonthlyCompleted}}"
                                                              Value="{{item.totalRepairs}}" BarColor = "{{ item.color ? item.color : colorlist[i]}}"
                                                               > </BulletChart></a>
                                                          </ng-container>
                                                        </div>
                                                    </div>
                                          </div>
                                        </div>
                                        </div>
                                      </div>
                                      <br>
                                        <div class="row" style="display: none;">
                                        <div class="col-md-12">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="panel-heading" role="tab" id="headingOne">
                                                     <h4 class="panel-title">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                     <strong>Task Schedule</strong>
                                                </a>
                                              </h4>

                                                </div>
                                                <div id="collapseOne" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
                                                    <div class="panel-body" (click)="RouteClick('Task/TaskViewer')"> 
													<div class="box-body" style ="padding:5px 10px">
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                              <div *ngIf="calendarOptions">
                                                                    <!-- <ng-fullcalendar #ucCalendar 
                                                                    [options]="calendarOptions" 
                                                                    (viewRender) = "initialized($event)" 
                                                                    (eventClick)="eventClick($event.detail)" 
																	[(eventsModel)]="events"></ng-fullcalendar> -->

                                                                    <full-calendar
                                                                        #ucCalendar
                                                                        [options]="calendarOptions"
                                                                        (eventClick)="eventClick($event)"
                                                                        (datesSet)="initialized($event)">
                                                                        </full-calendar>
                                                            </div>
                                                            </div>
                                                        </div>
                                                  </div>
												  </div>
                                                </div>
                                            </div>
                                            <div class="panel panel-default" style="display:none">
                                                <div class="panel-heading" role="tab" id="headingTwo">
                                                     <h4 class="panel-title">
                                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>Staff Schedule</strong>
                                                </a>
                                              </h4>

                                                </div>
                                                <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                    <div class="panel-body"> 
													
													<div class="col-sm-12">
                                                        <div
                                                        (click)="RouteClick('Schedule/Technologist')"
                                                        class="col-sm-12 box-row text-center">
														
														<table style="width:100%"  align="center"  class="schedule_cal">

                                                            <tbody>

                                                                <tr>

                                                                    <th

                                                                        colspan="2"

                                                                        scope="col"

                                                                        width="65px"

                                                                    >



                                                                    </th>

                                                                    <ng-container *ngFor="let item of DateList; index as i;">



                                                                            <th>{{item | date:'MMM dd'}}</th>



                                                                    </ng-container>



                                                                </tr>

                                                                <tr>

                                                                    <td

                                                                        colspan="2"

                                                                        width="65px"

                                                                    ></td>

                                                                    <ng-container *ngFor="let item of DateList; index as i;">



                                                                            <td>{{(item | date:'E')}}</td>



                                                                    </ng-container>

                                                                </tr>



                                                                <tr *ngFor="let item of SchList">

                                                                    <td

                                                                        width="60px"

                                                                        nowrap="nowrap"

                                                                        style="text-align: left"

                                                                    >

                                                                        <input

                                                                            type="hidden"

                                                                            [(ngModel)]="item.userid"

                                                                            value="{{item.userid}}"

                                                                        >

                                                                        {{item.userName}}

                                                                    </td>

                                                                    <td

                                                                        width="5px"

                                                                        style="text-align: left"

                                                                    >

                                                                        {{item.jobstatus}}

                                                                    </td>
                                                                    <td width="81px" [style.background-color]="SetColor(DateList[0],item.date1)">
                                                                        <a href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date1)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date1"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[1],item.date2)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date2)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date2"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[2],item.date3)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date3)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date3"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[3],item.date4)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date4)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date4"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[4],item.date5)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date5)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date5"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[5],item.date6)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date6)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date6"

                                                                        >

                                                                    </td>

                                                                    <td width="81px" [style.background-color]="SetColor(DateList[6],item.date7)">

                                                                        <a
                                                                        href="javascript:void(0)"
                                                                        style="color: #fff;font-weight:normal;text-decoration: none;" 
                                                                        (click)="RouteClick('Schedule/Technologist')"
                                                                    > <span *ngIf="IsLock">{{DisplayDesc(item.date7)}}</span></a>

                                                                        <input

                                                                            *ngIf="!IsLock"

                                                                            type="text"

                                                                            maxlength="10"

                                                                            size="8"

                                                                            [(ngModel)]="item.date7"

                                                                        >

                                                                    </td>

                                                                    </tr>

                                                            </tbody>

                                                        </table>



                                                    </div>
                                                </div></div>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                        </div>




                                        </div>

                                </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

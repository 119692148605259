import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Optional } from '@angular/core';
import { DMApiService } from 'src/app/API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';

import { Globals, actionType, attachmentFormModules } from 'src/app/globals';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/API/auth.service';
// import { padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { HomeService } from 'src/app/API/home.service';
import { ContactApiService } from 'src/app/API/contactapi.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { padNumber } from '../../Helper/utils';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'Bacteria',
  templateUrl: './Bacteria.component.html',
  styleUrls: ['./Bacteria.component.css']
})

export class BacteriaComponent
  implements OnInit, CanComponentDeactivate {
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  selectedROHours = 0;
  selectedDMHours = 0;

  @Input() FullScreen: boolean = true;
  @Output() SaveEvent = new EventEmitter<any>();
  data = {
    errormsg: ''
  }
  public hrsTracking = false;
  isValidFormSubmitted = null;
  ListURL = 'DialysisMachine/BacteriasSearch';
  status = '';
  stage = 0;
  loading = true;
  DialysisUnits = [];
  DialysisUnitsWithoutHome = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  userDesignation = this.auth.GetDesignationName();
  EventCode = '[AutoNumber]';
  selectedWPEquipment;
  selectedDMEquipment;
  selectedStation;
  TestHours = 0;
  //selectedPopulatetype='Dialysis Machines';
  errorAddTest = '';
  selectedUsertext;
  CollectionByName = this.auth.GetUserFLName();
  CollectionById = this.currentUserId;
  CollectionRetestByName;
  CollectionByRetestId;
  SampleReadByName;
  SampleReadById;
  SampleReadRetestByName;
  SampleReadByRetestId;
  FunnelStaffByName = this.auth.GetUserFLName();
  FunnelStaffById;
  FunnelStaffByNameRetest;
  FunnelStaffByIdRetest;
  DMTestList = [];
  WPTestList = [];
  CDTestList = [];
  OTHTestList = [];
  DMRepairStatus = false;
  RepairStatus = false;
  ReadUserByName = this.auth.GetUserFLName();

  Users = [];
  Detailtype = '';
  TestResultList = [{ value: '', text: 'Select' }];
  collapseFunnelexpanded = false;
  collapseFunnelClass = 'collapsed';
  collapseFunnel =  'panel-collapse collapse';
  funnelInfoDisplay = false;
  sampleResultDisplay = false;
  isFunnelPanelOpen = false;
  SetModelCompleted = false;

  BacteriaForm = new UntypedFormGroup({
    BacteriasId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    CollectionDate: new UntypedFormControl('', Validators.required),
    CollectionTime: new UntypedFormControl('', [
      Validators.required, Validators.pattern(Globals.TimeRegex)
    ]),
    CollectionRetestDate: new UntypedFormControl(''),
    CollectionRetestTime: new UntypedFormControl(''),
    SampleReadDate: new UntypedFormControl('', [Validators.required]),
    SampleReadTime: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(Globals.TimeRegex)
    ]),
    SampleReadRetestDate: new UntypedFormControl(''),
    SampleReadRetestTime: new UntypedFormControl(''),
    FunnelLot: new UntypedFormControl(''),
    CassetteLot: new UntypedFormControl(''),
    FunnelLotRetest: new UntypedFormControl('', [
      Validators.pattern(Globals.NumberRegex)
    ]),
    CassetteLotRetest: new UntypedFormControl('', [
      Validators.pattern(Globals.NumberRegex)
    ]),
    selectedWPEquipment: new UntypedFormControl(''),
    selectedDMEquipment: new UntypedFormControl(''),
    selectedCDEquipment: new UntypedFormControl(''),
    // selectedStation: new FormControl(''),
    OtherEquipment: new UntypedFormControl(''),
    TestHours: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    selectedPopulatetype: new UntypedFormControl(''),
    TestingLab: new UntypedFormControl('', [Validators.required]),
    ReportId: new UntypedFormControl('', [Validators.pattern(Globals.AlphaNumericRegex)]),
    SampleReadById: new UntypedFormControl('', [Validators.required]),
    chkNAReportID: new UntypedFormControl(''),
    funnelReadDate: new UntypedFormControl('')
  });

  WPEquipments = [];
  DMEquipments = [];
  CDEquipments = [];

  // Stations = [];
  selectedEquipmentHours = 0;


  Populatetypes = [
    { value: '', text: 'Select' },
    { value: 'Dialysis Machines', text: 'Dialysis Machines' },
    { value: 'Water Process', text: 'Water Process' },
    // { value: 'Central Delivery', text: 'Central Delivery' },
    { value: 'Other', text: 'Other' }
  ];

  TestingLabs = [
    { value: '', text: 'Select' },
    { value: 'Internal Lab', text: 'Internal Lab' },
    { value: 'Internal (Tech)', text: 'Internal (Tech)'},
    { value: 'External Lab', text: 'External Lab' },
    { value: 'See Notes', text: 'See Notes' }
  ];

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() + 1
    }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }



  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.BacteriaForm.dirty || this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private homeApi: HomeService,
    private contactapi : ContactApiService,
    @Optional() public dialogBacteriaRef: MatDialogRef<BacteriaComponent>,
    private commonapi: CommonApiService
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();

    this.DMTestList = [];
    this.WPTestList = [];
    this.CDTestList = [];
    this.OTHTestList = [];

    this.fillDialysisUnit();
    this.fillInventoryList(this.f.selectedDialysisUnits.value,'Dialysis Machines');
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Water Process');
    // this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Central Delivery');
    // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    this.fillUser();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.setDateOptions();

      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        if (this.FullScreen)
          this.f.BacteriasId.setValue(params["id"] || "0");
        const tempDate = this.today;
        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        if (this.f.BacteriasId.value !== "0" && this.f.BacteriasId.value) {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {

            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillInventoryList(this.f.selectedDialysisUnits.value, "Water Process");
              this.fillInventoryList(this.f.selectedDialysisUnits.value,"Dialysis Machines");
            }

            this.selectedUsertext = this.auth.GetUserFirstName();
            //this.fillEquipment(0);
            //this.f.selectedPopulatetype.setValue('Dialysis Machines');

            this.f.CollectionDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.CollectionTime.setValue(
              padNumber(tempDate.getHours()) +
              ":" +
              padNumber(tempDate.getMinutes())
            );
            this.f.SampleReadDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            this.f.funnelReadDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            })

            this.f.SampleReadTime.setValue(
              padNumber(tempDate.getHours()) +
              ":" +
              padNumber(tempDate.getMinutes())
            );

            if (this.FullScreen) {
              var temp = this.Users.filter(c => c.value == this.currentUserId);
              if (temp.length > 0)
                this.f.SampleReadById.setValue(this.currentUserId);
              else
                this.f.SampleReadById.setValue("");
            } else {
              this.f.SampleReadById.setValue(this.currentUserId);
            }
          }, error => {
            AppComponent.HideLoader();
          });
        }
      });
      // this.f.ReportId.setValidators([
      //   CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.SampleReadRetestDate
      //   )
      // ]);

      this.f.ReportId.setValidators([Validators.pattern(Globals.AlphaNumericRegex)]);
      this.f.ReportId.updateValueAndValidity();

      // this.f.SampleReadRetestDate.setValidators([
      //   CommonCustomValidators.CheckDateoutCustomValidation(this.f.ReportId)
      // ]);
      this.f.funnelReadDate.setValidators([CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        this.f.SampleReadDate
      )]);
      this.f.funnelReadDate.updateValueAndValidity();



      this.SetBacteriaights();

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  ngAfterViewInit() {

    if (this.attachment) {
      // this.uploader.autoUpload = true;
      // attachment changes
      this.attachment.DocId = this.f.BacteriasId.value;

      this.attachment.moduleName =
        attachmentFormModules[attachmentFormModules.GeneralBacteria];
      this.attachment.UploadBy = this.currentUserId;
    }

    if (this.notes)
      this.notes.showHours = true;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.BacteriaForm.controls;
  }

  // fillUser() {

  //   this.dmapi.GetUsersDD().subscribe(list => {
  //     this.Users = list;
  //   });
  // }

  fillUser() {

      let designationName = { str: 'Technologist' };
      this.contactapi.GetContactDetailsByDesignationName(designationName
      ).subscribe(list => {
        this.Users = list;
      });
  }

  HoursCheckOnQuickSave() {
    this.errorAddTest = '';
    if (this.DMTestList.length > 0) {
      if (!this.DMTestList[0].dialysisMachineHours ||
        this.DMTestList[0].dialysisMachineHours == '0') {
        this.errorAddTest = 'Please enter equipment hours';
        return false;
      } else {

        if (this.selectedDMHours != 0 && parseFloat(this.DMTestList[0].dialysisMachineHours) < parseFloat(this.selectedDMHours.toString())) {
          this.errorAddTest = 'Hrs => previous Hrs Out';
          return false;
        }

      }

    }
    else if (this.WPTestList.length > 0) {
      if (!this.WPTestList[0].dialysisMachineHours ||
        this.WPTestList[0].dialysisMachineHours == '0') {
        this.errorAddTest = 'Please enter equipment hours';
        return false;
      } else {
        if (this.selectedROHours != 0 && parseFloat(this.WPTestList[0].dialysisMachineHours) < parseFloat(this.selectedROHours.toString())) {
          this.errorAddTest = 'Hrs => previous Hrs Out';
          return false;
        }
      }
    }
    return true;
  }

  AddTest() {

    this.errorAddTest = "";

    switch (this.f.selectedPopulatetype.value) {
      case "Dialysis Machines":
        if (
          this.f.selectedDMEquipment.value == "" &&
          this.f.TestHours.value == ""  && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter equipment and hours";
        } else if (this.f.selectedDMEquipment.value == "") {
          this.errorAddTest = "Please select the equipment from the list.";
        } else if (
          (this.f.TestHours.value == "" ||
          this.f.TestHours.value == "0") && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter the hours.";
        } else {

          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {
            // console.log(dmrepairCreate);
            if (this.hrsTracking) {
              AppComponent.loading = true;
              var dmrepairCreate = {
                equipmentId: this.f.selectedDMEquipment.value,
                hoursin: this.f.TestHours.value,
                dmrepairdetailsid: 0
              };
              this.dmapi
                .GetHoursOutOfPreviousRepair(dmrepairCreate)
                .subscribe(success => {
                  // console.log(success);
                  AppComponent.loading = false;
                  if (success == false) {
                    this.errorAddTest =
                      "Hrs IN => previous Hrs Out";
                  }
                  else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.DMEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedDMEquipment.value
                    );
                    this.DMTestList.push({
                      bacteriaMachaineId: 0,
                      bacteriaId: 0,
                      equipmentId: this.f.selectedDMEquipment.value,
                      sampleResult: "",
                      sampleResultReset: "",
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.DMEquipments.findIndex(
                      order => order.value === this.f.selectedDMEquipment.value
                    );
                    this.DMEquipments.splice(index, 1);
                    this.f.selectedDMEquipment.setValue("");
                    this.f.TestHours.setValue("");
                  }
                });
            } else {



              const equi = this.DMEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedDMEquipment.value
              );
              this.DMTestList.push({
                bacteriaMachaineId: 0,
                bacteriaId: 0,
                equipmentId: this.f.selectedDMEquipment.value,
                sampleResult: '',
                sampleResultReset: '',
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.DMEquipments.findIndex(
                order => order.value === this.f.selectedDMEquipment.value
              );
              this.DMEquipments.splice(index, 1);
              this.f.selectedDMEquipment.setValue("");
              this.f.TestHours.setValue("");


              AppComponent.loading = false;
            }
            //  this.errorAddTest = 'Added';
            // this.hrsTracking = false;
          }
        }

        break;
      case "Water Process":
        if (
          this.f.selectedWPEquipment.value == "" &&
          this.f.TestHours.value == "" && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter equipment and hours";
        } else if (this.f.selectedWPEquipment.value == "") {
          this.errorAddTest = "Please select the equipment from the list.";
        } else if (
          (this.f.TestHours.value == "" ||
            this.f.TestHours.value == "0") && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter the hours.";
        } else {


          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {
            if (this.hrsTracking) {

              AppComponent.loading = true;
              var dmrepairCreate = {
                equipmentId: this.f.selectedWPEquipment.value,
                hoursin: this.f.TestHours.value,
                dmrepairdetailsid: 0
              };
              // console.log(dmrepairCreate);

              this.dmapi
                .GetHoursOutOfPreviousRepair(dmrepairCreate)
                .subscribe(success => {
                  // console.log(success);
                  AppComponent.loading = false;
                  if (success == false) {
                    this.errorAddTest =
                      "Hrs IN => previous Hrs Out";
                  } else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.WPEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedWPEquipment.value
                    );
                    this.WPTestList.push({
                      bacteriaMachaineId: 0,
                      bacteriaId: 0,
                      equipmentId: this.f.selectedWPEquipment.value,
                      sampleResult: '',
                      sampleResultReset: '',
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.WPEquipments.findIndex(
                      order => order.value === this.f.selectedWPEquipment.value
                    );
                    this.WPEquipments.splice(index, 1);
                    this.f.selectedWPEquipment.setValue("");
                    this.f.TestHours.setValue("");
                  }
                });
            } else {
              const equi = this.WPEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedWPEquipment.value
              );
              this.WPTestList.push({
                bacteriaMachaineId: 0,
                bacteriaId: 0,
                equipmentId: this.f.selectedWPEquipment.value,
                sampleResult: "",
                sampleResultReset: "",
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.WPEquipments.findIndex(
                order => order.value === this.f.selectedWPEquipment.value
              );
              this.WPEquipments.splice(index, 1);
              this.f.selectedWPEquipment.setValue("");
              this.f.TestHours.setValue("");
              AppComponent.loading = false;
            }
          }

          // this.errorAddTest = 'Added';
          //this.hrsTracking = false;
        }
        break;
        case 'Central Delivery':
        if (
          this.f.selectedCDEquipment.value == "" &&
          this.f.TestHours.value == ""  && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter equipment and hours";
        } else if (this.f.selectedCDEquipment.value == "") {
          this.errorAddTest = "Please select the equipment from the list.";
        } else if ((
          this.f.TestHours.value == "" ||
          this.f.TestHours.value == "0")  && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter the hours.";
        } else {

          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {

            if (this.hrsTracking) {
              AppComponent.loading = true;
              var dmrepairCreate = {
                equipmentId: this.f.selectedCDEquipment.value,
                hoursin: this.f.TestHours.value,
                dmrepairdetailsid: 0
              };
              // console.log(dmrepairCreate);

              this.dmapi
                .GetHoursOutOfPreviousRepair(dmrepairCreate)
                .subscribe(success => {
                  // console.log(success);
                  AppComponent.loading = false;
                  if (success == false) {
                    this.errorAddTest =
                      "Hrs IN => previous Hrs Out";
                  } else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.CDEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedCDEquipment.value
                    );
                    this.CDTestList.push({
                      PyrogenMachaineId: 0,
                      PyrogenId: 0,
                      equipmentId: this.f.selectedCDEquipment.value,
                      sampleResult: '',
                      sampleResultReset: '',
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.CDEquipments.findIndex(order => order.value
                      === this.f.selectedCDEquipment.value);
                    this.CDEquipments.splice(index, 1);
                    this.f.selectedCDEquipment.setValue('');
                    this.f.TestHours.setValue("");
                  }
                });
              // this.errorAddTest = 'Added';
            } else {

              this.selectedEquipmentHours = 0;

              const equi = this.CDEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedCDEquipment.value
              );
              this.CDTestList.push({
                PyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: this.f.selectedCDEquipment.value,
                sampleResult: '',
                sampleResultReset: '',
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.CDEquipments.findIndex(order => order.value
                === this.f.selectedCDEquipment.value);
              this.CDEquipments.splice(index, 1);
              this.f.selectedCDEquipment.setValue('');
              this.f.TestHours.setValue("");
            }
          }
          // this.hrsTracking = false;
        }
        break;
      case 'Other':
        if (
          this.f.OtherEquipment.value == '') {
          this.errorAddTest = "Please enter equipment name";
        } else {
          this.OTHTestList.push({
            PyrogenMachaineId: 0,
            PyrogenId: 0,
            equipmentId: null,
            sampleResult: '',
            sampleResultReset: '',
            dialysisMachineHours: '',
            equipmentName: this.f.OtherEquipment.value
          });

        this.f.OtherEquipment.setValue('');
      }
      break;
      default:
        this.errorAddTest = "Please enter the populate.";
        break;
    }

  }

  selectedPopulatetypeChanged() {
    this.f.TestHours.setValue('');
    this.f.selectedDMEquipment.setValue('');
  }

  setModel() {

    AppComponent.ShowLoader();
    const BacteriaCreate = { Id: this.f.BacteriasId.value };

    if (this.attachment)
      this.attachment.clearQueue();

    if (this.notes)
      this.notes.Note = "";

    this.dmapi.GetBacteriaDetailsByID(BacteriaCreate).subscribe(
      success => {
        // console.log(success);


        this.collapseFunnelexpanded = true;
        this.collapseFunnelClass = '';
        this.collapseFunnel = 'panel-collapse collapse show';

        this.EventCode = success.eventId;
        let tempDate = new Date(success.createdOn);

        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdOn
        });
        if (success.collectionDate != null || success.collectionDate != "") {
          tempDate = new Date(success.collectionDate);
          this.f.CollectionDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.CollectionTime.setValue(
            padNumber(tempDate.getHours()) +
            ":" +
            padNumber(tempDate.getMinutes())
          );
        }

        if (success.funnelReadDate != null && success.funnelReadDate != "") {
          let tempReadDate = new Date(success.funnelReadDate);
          this.f.funnelReadDate.setValue({
            date: {
              year: tempReadDate.getFullYear(),
              month: tempReadDate.getMonth() + 1,
              day: tempReadDate.getDate()
            },
            jsdate: tempReadDate
          });
        }
        else {
          let tempProcessingDate = new Date(this.today);
          this.f.funnelReadDate.setValue({
            date: {
              year: tempProcessingDate.getFullYear(),
              month: tempProcessingDate.getMonth() + 1,
              day: tempProcessingDate.getDate()
            },
            jsdate: tempProcessingDate
          });
        }

        if (
          success.collectionRetestDate != null ||
          success.collectionRetestDate != ""
        ) {
          tempDate = new Date(success.collectionRetestDate);
          if (tempDate.getFullYear() > 2000) {
            this.f.CollectionRetestDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.CollectionRetestTime.setValue(
              padNumber(tempDate.getHours()) +
              ":" +
              padNumber(tempDate.getMinutes())
            );
          }
        }
        this.CollectionByRetestId = success.collectionRetestBy;
        this.CollectionRetestByName = success.collectionretestenteredbyName;
        if (
          success.sampleRetestReadDate != null ||
          success.sampleRetestReadDate != ""
        ) {
          tempDate = new Date(success.sampleRetestReadDate);
          if (tempDate.getFullYear() > 2000) {
            this.f.SampleReadRetestDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.SampleReadRetestTime.setValue(
              padNumber(tempDate.getHours()) +
              ":" +
              padNumber(tempDate.getMinutes())
            );
          }
        }
        this.SampleReadRetestByName = success.sampleretestreadbyName;
        this.SampleReadByRetestId = success.sampleRetestReadBy;

        if (success.sampleReadDate != null || success.sampleReadDate != "") {
          tempDate = new Date(success.sampleReadDate);
          // console.log(tempDate);
          if (tempDate.getFullYear() > 2000) {
            this.f.SampleReadDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            this.f.SampleReadTime.setValue(
              padNumber(tempDate.getHours()) +
              ":" +
              padNumber(tempDate.getMinutes())
            );
          }
        }

        if (!this.FullScreen) {
          this.f.SampleReadDate.disable();
          this.f.SampleReadTime.disable();
        }

        this.SampleReadById = success.sampleReadBy;
        this.f.SampleReadById.setValue(success.sampleReadBy);
        this.SampleReadByName = success.samplereadbyName;

        //this.EventCode = success.eventcode;
        this.selectedDialysisUnitstext = success.divisionName;

        this.Detailtype = success.detailsType;
        //this.selectedUsertext = success.createByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
        this.selectedDialysisUnitstext = success.divisionName;
        this.stage = success.stage;

        this.status = success.status;

        if (success.status == "Retest")
          this.status = "Completed";

        if (success.status != "Failure" && success.status != "Completed") {
          this.stage = this.stage + 1;
        }


        // console.log(this.stage);
        this.f.FunnelLot.setValue(success.funnelLotNumber);
        this.f.CassetteLot.setValue(success.cassetteLotNumber);
        this.f.FunnelLotRetest.setValue(success.funnelRetestLotNumber);
        this.f.CassetteLotRetest.setValue(success.cassetteRetestLotNumber);
        this.FunnelStaffById = success.funnelReadby;
        this.FunnelStaffByName = success.funnelReadbyName;
        if ((this.f.FunnelLot.value == null || this.f.FunnelLot.value == '')) {
          this.FunnelStaffById = this.currentUserId;
          this.FunnelStaffByName = this.auth.GetUserFLName();
        }

        if (success.funnelReadretestby == '' || success.funnelReadretestby == null) {
          this.FunnelStaffByIdRetest = this.currentUserId;
          this.FunnelStaffByNameRetest = this.auth.GetUserFLName();
        }
        else
        {
          this.FunnelStaffByIdRetest = success.funnelReadretestby;
          this.FunnelStaffByNameRetest = success.funnelReadretestbyName;
        }

        this.CollectionByName = success.formUser;
        this.f.TestingLab.setValue(success.testinglab);

        this.f.ReportId.setValue(success.reportId);

        if (this.TestResultList.length < 12) {
          this.TestResultList.push({
            text: "< 10",
            value: "< 10"
          });
        }

        while (this.TestResultList.length < 12) {
          this.TestResultList.push({
            text: (this.TestResultList.length + 8).toString(),
            value: (this.TestResultList.length + 8).toString()
          });
        }

        if (this.TestResultList.length < 14) {
          this.TestResultList.push({
            text: "> 20",
            value: "> 20"
          });


          this.TestResultList.push({
            text: ">20 <49",
            value: ">20 <49"
          });

          this.TestResultList.push({
            text: "> 50",
            value: "> 50"
          });



          this.TestResultList.push({
            text: "TNTC",
            value: "TNTC"
          });
        }

        const BacteriaDMObj = { Id: this.f.BacteriasId.value, str: 'Dialysis Machines' };


        this.dmapi.GetMachineListByBacteriaID(BacteriaDMObj).subscribe(list => {

          this.DMTestList = list;
        });

        const BacteriaWPObj = { Id: this.f.BacteriasId.value, str: 'Water Process' };

        this.dmapi.GetMachineListByBacteriaID(BacteriaWPObj).subscribe(list => {

            this.WPTestList = list;
        });

        const BacteriaCDObj = { Id: this.f.BacteriasId.value, str: 'Central Delivery' };

        this.dmapi.GetMachineListByBacteriaID(BacteriaCDObj).subscribe(list => {

          this.CDTestList = list;
        });

        const BacteriaOTHObj = { Id: this.f.BacteriasId.value, str: 'Other' };

        this.dmapi.GetMachineListByBacteriaID(BacteriaOTHObj).subscribe(list => {

          this.OTHTestList = list;
        });


        const BacteriaObj = { Id: this.f.BacteriasId.value };

        if (this.attachment) {
          this.dmapi
            .GetAttachmentDetailsbyBacteriaid(BacteriaObj)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });
        }

        if (this.notes) {
          this.dmapi.GetNotesbyBacteriaId(BacteriaObj).subscribe(list => {
            this.notes.NoteList = list;
          });
        }

        if (this.f.FunnelLot.value != '' && this.f.CassetteLot.value != '') {
          this.funnelInfoDisplay = true;
        }
        else {
          this.funnelInfoDisplay = false;
        }

        if (this.status !== 'Completed') {
          if (this.f.TestingLab.value == 'Internal (Tech)') {
            this.f.SampleReadRetestDate.setValidators([CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
              this.f.funnelReadDate
            )]);
            this.f.SampleReadRetestDate.updateValueAndValidity();
          }
          else {
            this.f.SampleReadRetestDate.setValidators([CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
              this.f.SampleReadDate
            )]);
            this.f.SampleReadRetestDate.updateValueAndValidity();
          }
        }
        AppComponent.HideLoader();

        setTimeout(() => {
          this.SetModelCompleted = true;
        }, 1500);
      },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );

  }

  onDateChanged(event: IMyDateModel): void { }

  CheckSampleValue() {
    let result = true;
    this.DMTestList.forEach(function (value) {
      if (value.sampleResult == "") {
        result = false;
      }
    });

    this.WPTestList.forEach(function (value) {
      if (value.sampleResult == "") {
        result = false;
      }
    });

    this.CDTestList.forEach(function (value) {
      if (value.sampleResult == "") {
        result = false;
      }
    });

    this.OTHTestList.forEach(function (value) {
      if (value.sampleResult == "") {
        result = false;
      }
    });
    return result;
  }
  CheckRed() {
    let result = true;

    this.DMTestList.forEach(function (value) {

      if (value.sampleResult == 'TNTC' || value.sampleResult == '> 20') {
        result = false;
      }
    });

    this.WPTestList.forEach(function (value) {
      if (value.sampleResult == 'TNTC' || value.sampleResult == '> 20') {
        result = false;
      }
    });

    this.CDTestList.forEach(function (value) {
      if (value.sampleResult == 'TNTC' || value.sampleResult == '> 20') {
        result = false;
      }
    });

    this.OTHTestList.forEach(function (value) {
      if (value.sampleResult == 'TNTC' || value.sampleResult == '> 20') {
        result = false;
      }
    });
    return result;
  }

  CheckSamleResetValue() {
    let result = true;
    this.DMTestList.forEach(function (value) {
      if (value.sampleResultReset == "") {
        result = false;
      }
    });

    this.WPTestList.forEach(function (value) {
      if (value.sampleResultReset == "") {
        result = false;
      }
    });

    this.CDTestList.forEach(function (value) {
      if (value.sampleResultReset == "") {
        result = false;
      }
    });

    this.OTHTestList.forEach(function (value) {
      if (value.sampleResultReset == "") {
        result = false;
      }
    });
    return result;
  }

  executeBacteriaCreateUpdate = function (Source) {
    if (this.status !== 'Completed') {

      AppComponent.loading = true;
      this.isValidFormSubmitted = false;
      this.f.ReportId.updateValueAndValidity();

      if (this.isFunnelPanelOpen == true && this.f.BacteriasId.value == 0 && Source == 'Notes'
        && this.f.TestingLab.value == 'Internal (Tech)' && (this.f.CassetteLot.value == '' || this.f.FunnelLot.value == '')) {
        AppComponent.addAlert("For adding notes, need to close the Funnel and Cassette Info section.", "error");
        this.data.errormsg = "For adding notes, need to close the Funnel and Cassette Info section.";
        AppComponent.loading = false;
        this.scrollHelper.scrollToFirst("ng-invalid");
        AppComponent.ScrollDown();
        return;
      }


    if (this.BacteriaForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
      this.data.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst("ng-invalid");
      AppComponent.ScrollDown();
      return;
    }

    //this.f.SampleReadRetestDate.updateValueAndValidity();
    if (
      this.DMTestList.length == 0 &&
      this.WPTestList.length == 0 &&
      this.CDTestList.length == 0 &&
      this.OTHTestList.length == 0

    ) {
      AppComponent.addAlert(
        "Please add atleast one item to the test result.",
        "error"
      );
      this.data.errormsg = "Please add atleast one item to the test result.";
      AppComponent.loading = false;
      return;
    }

    if (!this.FullScreen && !this.HoursCheckOnQuickSave()) {
      AppComponent.loading = false;
      return;
    }

    const sampleValid = this.CheckSampleValue();

      // if(this.f.BacteriasId.value > 0 && Source !== 'Notes')
      if ((sampleValid || Source !== 'Notes') && this.f.BacteriasId.value > 0) {
        this.setTestingLabValidations();
      }

      if (
        this.f.SampleReadRetestDate.value != undefined &&
        this.FullScreen &&
        this.f.SampleReadRetestDate.value != "" &&
        sampleValid == false

      ) {
        AppComponent.addAlert(
          "Please select value for the each test result.",
          "error"
        );
        this.data.errormsg = "Please select value for the each test result.";
        AppComponent.loading = false;
        return;
      }

      if (Source !== 'Notes'
        && this.FullScreen
        && this.notes.NoteList.length < 1
        && this.CheckRed() == false) {
        AppComponent.addAlert('There are result(s) that are greater than acceptable limits and require a Note entry to complete this form.', 'error');
        AppComponent.loading = false;
        return;
      }

      if(Source !== 'Notes'
      && this.FullScreen
      && this.notes.NoteList.length < 1
      && this.f.TestingLab.value == 'See Notes') {
        AppComponent.addAlert('Require a Note entry to complete this form.', 'error');
        AppComponent.loading = false;
        return;
      }

    if (this.BacteriaForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
      this.data.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst("ng-invalid");
      AppComponent.ScrollDown();
      return;
    }

    this.isValidFormSubmitted = true;
  }
    const BacteriaCreate = this.prepareCreateViewModel(Source);
    // if (BacteriaCreate.BacteriaId === '0') {
    this.dmapi.GRCreateUpdateBacteria(BacteriaCreate).subscribe(
      success => {
        //  console.log(success);
        // this.DMRepailDetail = success.dmRepailDetails;

        AppComponent.loading = false;
        AppComponent.addAlert("Data Saved successfully!", "success");
        if (Source !== "Notes" && this.FullScreen) {
          this.router.navigate([this.ListURL]);
        } else {
          this.SaveEvent.emit(success);

          if (this.dialogBacteriaRef)
            this.dialogBacteriaRef.close(true);

          this.f.BacteriasId.setValue(success.bacteriaId);
          this.setModel();
        }
      },
      error => {
        this.data.errormsg = error.message;
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
    // } else {
    //   this.dmapi.CreateUpdateBacteria(BacteriaCreate).subscribe(
    //     success => {
    //      // this.DMRepailDetail = success.dmRepailDetails;
    //       AppComponent.loading = false;
    //       AppComponent.addAlert('Data Saved successfully!', 'success');
    //       if (Source !== 'Notes') {
    //         this.router.navigate([this.ListURL]);
    //       } else {

    //         this.setModel();
    //       }
    //     },
    //     error => {
    //       AppComponent.addAlert(error.message, 'error');
    //       AppComponent.HideLoader();
    //     }
    //   );
    // }
  };

  SetValidatorForNotes(){

    if(this.SetModelCompleted) {
      const sampleValid = this.CheckSampleValue();

      if ((sampleValid) && this.f.BacteriasId.value > 0 && this.status !== 'Completed') {
        this.setTestingLabValidations();
      }

      if (sampleValid === false
        && ( this.f.SampleReadRetestDate.value != undefined &&
          this.FullScreen &&
          this.f.SampleReadRetestDate.value != "")) {
            this.BacteriaForm.setErrors({ 'invalid': true });
            return;
            // this.PyrogenForm.
            //  var alertMessage = '';
            // if(this.f.TestMethod.value == 'LAL') {
            //   alertMessage = 'Please select all Result & Pos. Control for the each testing device.';
            // } else {
            //   alertMessage = 'Please select all Result for the each testing device.';
            // }
            // AppComponent.addAlert(alertMessage, 'error');
            // this.data.errormsg = alertMessage;
            // AppComponent.loading = false;
            // return;
      }
    }
  }

  setTestingLabValidations() {
    if (this.f.TestingLab.value == 'Internal (Tech)') {
      this.f.FunnelLot.setValidators(Validators.required);
      this.f.FunnelLot.updateValueAndValidity();

      this.f.CassetteLot.setValidators(Validators.required);
      this.f.CassetteLot.updateValueAndValidity();

      this.f.SampleReadRetestDate.clearValidators();
      this.f.SampleReadRetestDate.updateValueAndValidity();
    }

    if (this.f.TestingLab.value == 'Internal (Tech)' && this.sampleResultDisplay == true) {
      this.f.SampleReadRetestDate.setValidators(Validators.required);
      this.f.SampleReadRetestDate.updateValueAndValidity();
    }

    if (this.f.TestingLab.value != 'Internal (Tech)') {
      this.f.SampleReadRetestDate.setValidators(Validators.required);
      this.f.SampleReadRetestDate.updateValueAndValidity();

      this.f.ReportId.setValidators(Validators.required);
      this.f.ReportId.updateValueAndValidity();
    }
  }

  prepareCreateViewModel(Source) {
    const BacteriaCreate = <any>{};

    BacteriaCreate.BacteriaId = this.f.BacteriasId.value;
    BacteriaCreate.detailstype = this.Detailtype;
    BacteriaCreate.createdon = Globals.GetAPIDate(this.today);
    BacteriaCreate.createdby = this.currentUserId;
    BacteriaCreate.currentUser = this.currentUserId;
    BacteriaCreate.modifiedon = Globals.GetAPIDate(this.today);
    BacteriaCreate.modifiedby = this.currentUserId;

    BacteriaCreate.testinglab = this.f.TestingLab.value;
    BacteriaCreate.reportid = this.f.ReportId.value;
    BacteriaCreate.cassetteretestlotnumber = this.f.CassetteLotRetest.value;



    BacteriaCreate.sampleretestreadby = this.SampleReadByRetestId;
    BacteriaCreate.samplereadby = this.f.SampleReadById.value;
    BacteriaCreate.stage = this.stage;

    if (this.f.CollectionDate.value !== '' &&
      this.f.CollectionDate.value !== null &&
      this.f.CollectionDate.value !== undefined
    ) {
      let dt = this.f.CollectionDate.value.jsdate;
      // console.log(dt);
      if (this.f.CollectionTime.value != "") {
        const splitted = this.f.CollectionTime.value.split(":");
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.collectiondate = Globals.GetAPIDate(dt);
    }

    if (this.f.funnelReadDate.value != null &&
      this.f.funnelReadDate.value != undefined) {
      BacteriaCreate.funnelReadDate = Globals.GetAPIDate(this.f.funnelReadDate.value.jsdate);
    }

    if (this.f.CollectionRetestDate.value !== '' &&
      this.f.CollectionRetestDate.value !== null &&
      this.f.CollectionRetestDate.value !== undefined
    ) {
      let dt = this.f.CollectionRetestDate.value.jsdate;
      if (this.f.CollectionRetestTime.value != "") {
        const splitted = this.f.CollectionRetestTime.value.split(":");
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.collectionretestdate = Globals.GetAPIDate(dt);
    }


    if (
      this.f.SampleReadDate.value !== '' &&
      this.f.SampleReadDate.value !== null &&
      this.f.SampleReadDate.value !== undefined
    ) {
      let dt = this.f.SampleReadDate.value.jsdate;
      if (this.f.SampleReadTime.value != "") {
        const splitted = this.f.SampleReadTime.value.split(":");
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.samplereaddate = Globals.GetAPIDate(dt);
      BacteriaCreate.Bacteria_Step = 'Collection';

    }

    BacteriaCreate.cassettelotnumber = this.f.CassetteLot.value;
    BacteriaCreate.funnelretestlotnumber = this.f.FunnelLotRetest.value;
    BacteriaCreate.funnellotnumber = this.f.FunnelLot.value;
    BacteriaCreate.collectionretestby = this.CollectionByRetestId;
    BacteriaCreate.collectionby = this.CollectionById;
    BacteriaCreate.divisionid = this.f.selectedDialysisUnits.value;
    BacteriaCreate.eventid = this.EventCode;


    var isFunnelDetailAdded = false;
    if(this.f.CassetteLot.value && this.f.CassetteLot.value != '' && this.f.CassetteLot.value != null &&
    this.f.FunnelLot.value && this.f.FunnelLot.value != '' && this.f.FunnelLot.value != null ) {
      BacteriaCreate.Bacteria_Step = 'Funnel';
      isFunnelDetailAdded = true;
    }


    var isReadDetailAdded = false;

    if (this.f.SampleReadRetestDate.value !== '' &&
      this.f.SampleReadRetestDate.value !== null &&
      this.f.SampleReadRetestDate.value !== undefined
    ) {
      let dt = this.f.SampleReadRetestDate.value.jsdate;
      if (this.f.SampleReadRetestTime.value != "") {
        const splitted = this.f.SampleReadRetestTime.value.split(":");
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.sampleretestreaddate = Globals.GetAPIDate(dt);
      BacteriaCreate.Bacteria_Step = 'Read';
      isReadDetailAdded = true;
    }
    // BacteriaCreate.samplereaddate = this.f.samplereaddate.value;
    // BacteriaCreate.sampleretestreaddate = this.f.SampleReadRetestDate.value;
    // BacteriaCreate.collectionretestdate = this.f.collectionretestdate.value;
    // BacteriaCreate.collectiondate = this.f.collectiondate.value;


    if (this.f.BacteriasId.value == 0)
    {
      BacteriaCreate.funnelReadby = this.currentUserId;
    }
    else if(this.f.FunnelLot.value == null && this.f.FunnelLot.value == '' && this.f.BacteriasId.value != 0)
    {
      BacteriaCreate.funnelReadby = this.currentUserId;
    }
    else
    {
      BacteriaCreate.funnelReadby = this.FunnelStaffById;
    }

    if (
      this.f.SampleReadRetestDate.value !== null &&
      this.f.SampleReadRetestDate.value !== undefined &&
      this.f.SampleReadRetestDate.value != ''
    )
    {
      if(this.status!=='Completed')
        BacteriaCreate.funnelReadretestby =  this.currentUserId;
      else
        BacteriaCreate.funnelReadretestby =  this.FunnelStaffByIdRetest;
    }
    // else
    // {
    //   BacteriaCreate.funnelReadretestby =  this.FunnelStaffByIdRetest;
    // }

    if (this.status == 'Completed') {
      BacteriaCreate.Bacteria_Step = 'Notes';
    } else {
      if(this.f.BacteriasId.value == 0 && isFunnelDetailAdded) {
        BacteriaCreate.Bacteria_Step = 'Collection_Funnel';
      } else if(this.f.BacteriasId.value !== 0 && isFunnelDetailAdded && isReadDetailAdded && !this.funnelInfoDisplay) {
        BacteriaCreate.Bacteria_Step = 'Funnel_Read';
      }
    }

    BacteriaCreate.machaineList = [];
    this.DMTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push({
        bacteriaMachaineId: value.bacteriaMachaineId,
        equipmentId: value.equipmentId,
        sampleResult: value.sampleResult,
        sampleResultReset: value.sampleResultReset,
        Type: 'Dialysis Machines',
        dialysisMachineHours: value.dialysisMachineHours,
        equipmentName: value.equipmentName
      });
    });

    this.WPTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push({
        bacteriaMachaineId: value.bacteriaMachaineId,
        equipmentId: value.equipmentId,
        sampleResult: value.sampleResult,
        sampleResultReset: value.sampleResultReset,
        Type: "Water Process",
        dialysisMachineHours: value.dialysisMachineHours,
        equipmentName: value.equipmentName
      });
    });

    this.CDTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push(
        {
          bacteriaMachaineId: value.bacteriaMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          Type: 'Central Delivery',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });

    this.OTHTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push(
        {
          bacteriaMachaineId: value.bacteriaMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          Type: 'Other',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });

    // BacteriaCreate.stationsList = [];
    // this.CDTestList.forEach(function (value) {
    //   BacteriaCreate.stationsList.push({
    //     bacteriaDialysisStationsId: value.bacteriaDialysisStationsId,
    //     dialysisStationId: value.dialysisStationId,
    //     sampleResult: value.sampleResult,
    //     sampleResultReset: value.sampleResultReset,
    //     Type: "Central Delivery"
    //   });
    // });

    if (this.notes)
      BacteriaCreate.notes = this.notes.Note;
    // attachments
    if (this.attachment) {
      BacteriaCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    }

    return BacteriaCreate;
  }

  DialysisUnitDropDownChanged() {


    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Dialysis Machines');
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Water Process');
    // this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Central Delivery');

    if (this.f.BacteriasId.value == 0) {
      this.f.selectedDMEquipment.setValue('');
      this.f.selectedWPEquipment.setValue('');
      this.selectedEquipmentHours = 0;
      this.f.TestHours.setValue('');
    }
  }

  // fillDialysisStation(divisionID) {
  //   if (divisionID !== 0) {
  //     this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
  //       this.Stations = list;
  //     });
  //   } else {
  //     this.selectedStation = [{ value: "", text: "Select" }];
  //     this.f.selectedDialysisStation.setValue("");
  //   }
  // }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {

      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }
      this.DialysisUnitsWithoutHome = list;
      // this.DialysisUnitsWithoutHome = list.filter(
      //   x => x.text != "Home Program"
      // );
    });
  }

  setDialysisUnitAndMachines(name, stationId, type, hours = "", HFSPurpose = "", EquipmentId = "", EquipmentName = "") {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;
      var selectedDialysisUnitsobjTemp = this.DialysisUnits.filter(
        x => x.text === name
      );
      if (selectedDialysisUnitsobjTemp && selectedDialysisUnitsobjTemp.length > 0) {
        this.selectedDialysisUnitstext = selectedDialysisUnitsobjTemp[0].text;
        this.f.selectedDialysisUnits.setValue(selectedDialysisUnitsobjTemp[0].value);
        this.DialysisUnitDropDownChanged();
        this.f.selectedDialysisUnits.disable();

        if (!this.f.BacteriasId.value || this.f.BacteriasId.value == '0') {
          this.f.SampleReadDate.setValue({
            date: {
              year: this.today.getFullYear(),
              month: this.today.getMonth() + 1,
              day: this.today.getDate()
            },
            jsdate: this.today
          });

          //this.f.TestingLab.disable();
          this.f.SampleReadDate.disable();

          this.f.SampleReadTime.setValue(this.addZeroBeforeHours(this.today.getHours().toString()) + ":" + this.addZeroBeforeHours(this.today.getMinutes().toString()));
          this.f.SampleReadTime.disable();
        }

        this.f.SampleReadRetestDate.disable();
        this.f.ReportId.disable();
        this.f.SampleReadById.disable();
        if(HFSPurpose == "Maintenance & Testing")
          this.fetchMachines(stationId, type, hours);
        else if (HFSPurpose == "Renovation & Full Installation")
          this.fetchInstallationMachines(EquipmentId, EquipmentName, type, hours);
      }
    });
  }

  addZeroBeforeHours(n) {
    return (n < 10 ? '0' : '') + n;
  }

  fetchInstallationMachines(EquipmentId, EquipmentName, type, hours){
    if(type == 'RO'){
      this.WPTestList.push({
        bacteriaMachaineId: 0,
        bacteriaId: 0,
        equipmentId: EquipmentId,
        sampleResult: "",
        sampleResultReset: "",
        dialysisMachineHours: hours,
        equipmentName: EquipmentName,
        hours: hours
      });

      this.GetSelectedRODMHours(type, EquipmentId);
    } else if (type = 'DM'){
      this.DMTestList.push({
        bacteriaMachaineId: 0,
        bacteriaId: 0,
        equipmentId: EquipmentId,
        sampleResult: "",
        sampleResultReset: "",
        dialysisMachineHours: hours,
        equipmentName: EquipmentName,
        hours: hours
      });

      this.GetSelectedRODMHours(type, EquipmentId);
    }

    this.TestResultList.push({
      text: "< 10",
      value: "< 10"
    });

    while (this.TestResultList.length < 12) {
      this.TestResultList.push({
        text: (this.TestResultList.length + 8).toString(),
        value: (this.TestResultList.length + 8).toString()
      });
    }
    this.TestResultList.push({
      text: "> 20",
      value: "> 20"
    });
    this.TestResultList.push({
      text: "TNTC",
      value: "TNTC"
    });
  }

  fetchMachines(stationId, type = '', hours) {
    this.homeApi.GetDialysisStationByID({ Id: stationId }).subscribe(
      success => {
        if (success) {
          if (success.dmEquipmentId && (type == "" || type == "DM")) {

            if (this.DMTestList.filter(x => x.equipmentId == success.dmEquipmentId).length <= 0) {

              this.DMTestList.push({
                bacteriaMachaineId: 0,
                bacteriaId: 0,
                equipmentId: success.dmEquipmentId,
                sampleResult: "",
                sampleResultReset: "",
                dialysisMachineHours: hours,
                equipmentName: success.dmEquipmentName,
                hours: hours
              });

              this.GetSelectedRODMHours(type, success.dmEquipmentId);
            }

          }
          if (success.roEquipmentId && (type == "" || type == "RO")) {

            if (this.WPTestList.filter(x => x.equipmentId == success.roEquipmentId).length <= 0) {

              this.WPTestList.push({
                bacteriaMachaineId: 0,
                bacteriaId: 0,
                equipmentId: success.roEquipmentId,
                sampleResult: "",
                sampleResultReset: "",
                dialysisMachineHours: hours,
                equipmentName: success.roEquipmentName,
                hours: hours
              });

              this.GetSelectedRODMHours(type, success.roEquipmentId);
            }

            this.TestResultList.push({
              text: "< 10",
              value: "< 10"
            });

            while (this.TestResultList.length < 12) {
              this.TestResultList.push({
                text: (this.TestResultList.length + 8).toString(),
                value: (this.TestResultList.length + 8).toString()
              });
            }
            this.TestResultList.push({
              text: "> 20",
              value: "> 20"
            });
            this.TestResultList.push({
              text: "TNTC",
              value: "TNTC"
            });
          }

        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }


  GetSelectedRODMHours(type, eqID) {
    var EquipmentId = { Id: eqID };
    this.dmapi.GetmachaineHistoryMaxHours(EquipmentId).subscribe(result => {
      if (result) {
        if (type == "DM")
          this.selectedDMHours = result;
        else
          this.selectedROHours = result;
      } else {
        this.selectedROHours = 0;
        this.selectedDMHours = 0;
      }
    });
  }

  fillInventoryList(DivisionId, Category) {


    var selectedDialysisUnitsobjTemp = this.DialysisUnitsWithoutHome.filter(
      x => x.value === DivisionId
    );

    if (selectedDialysisUnitsobjTemp.length > 0 && selectedDialysisUnitsobjTemp[0].text == 'Home Program') {
      if (Category == "Dialysis Machines") {
        const obj = {
          DialysisStationId: DivisionId,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({text: 'Select', value: ''});
          this.DMEquipments = list;
        });
      }
      if(Category == "Water Process") {
        const obj = {
          DialysisStationId: DivisionId,
          CategoryName: 'Water Process'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({text: 'Select', value: ''});
          this.WPEquipments = list;
        });
      }
    }
    else {
      const value = {
        DivisionId: DivisionId,
        CategoryName: Category,
        Type: "Equipment"
      };

      this.dmapi.GetEquipmentByCategoryDD(value).subscribe(list => {
        if (Category == 'Water Process') {
          this.WPEquipments = list;
        } else if (Category == 'Central Delivery') {
          this.CDEquipments = list;
        } else {
          this.DMEquipments = list;
        }
      });
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const BacteriaObj = { Id: this.f.BacteriasId.value };
        this.dmapi.DeleteBacteriaByID(BacteriaObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              "Bacteria detail deleted successfully!",
              "success"
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, "error");
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetBacteriaights() {
    this.auth.GetPageRights("DM Bacteria").subscribe(list => {
      if (this.f.BacteriasId.value !== "0") {
        this.blnshowSave = list[actionType.edit] === "1";
        //this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === "1";
        //this.blnshowDelete = false;
      }
    });

    if (
      this.userDesignation == "Administrator" &&
      this.f.BacteriasId.value != "0"
    ) {
      this.blnshowDelete = true;
    }
  }


  UpdateDialysisMachineHoursValue(event) {
    const tempname = event.target.name;
    const name = tempname.split("_");
    if (name[1] == "dm") {
      const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );

      this.DMTestList[index].dialysisMachineHours = event.target.value;

    } else if (name[1] == "wp") {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );

      this.WPTestList[index].dialysisMachineHours = event.target.value;

    }

    this.HoursCheckOnQuickSave();
  }

  UpdateSampleValue(event) {
    //  console.log(event,event.target.name,event.target.value);
    const tempname = event.target.name;
    const name = tempname.split("_");
    if (name[1] == "dm") {
      const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf("retest");
      if (retestIndex <= 0) {
        this.DMTestList[index].sampleResult = event.target.value;
      } else {
        this.DMTestList[index].sampleResultReset = event.target.value;
      }
    } else if (name[1] == "wp") {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf("retest");
      if (retestIndex <= 0) {
        this.WPTestList[index].sampleResult = event.target.value;
      } else {
        this.WPTestList[index].sampleResultReset = event.target.value;
      }
    }  else if (name[1] == "cd") {
      const index = this.CDTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf("retest");
      if (retestIndex <= 0) {
        this.CDTestList[index].sampleResult = event.target.value;
      } else {
        this.CDTestList[index].sampleResultReset = event.target.value;
      }
    } else {
      const index = this.OTHTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf("retest");

      if (retestIndex <= 0) {
        this.OTHTestList[index].sampleResult = event.target.value;
      } else {
        this.OTHTestList[index].sampleResultReset = event.target.value;
      }
    }

    if (
      this.DMTestList.length > 0 ||
      this.WPTestList.length > 0 ||
      this.CDTestList.length > 0 ||
      this.OTHTestList.length > 0
    ){
      this.sampleResultDisplay = true;
    }
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, "error");
  }

  pad(n) {
    return n < 10 ? "0" + n : n;
  }
  GetColor(ControlName) {
    const element = document.getElementsByName(ControlName);
    if (element != null && element != undefined) {
      if (element.length > 0) {
        const val = (<HTMLSelectElement>element[0]).value;
        return val == "TNTC" || val == "> 20" || val == "> 50" || val == ">20 <49" ? "red" : "black";
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onEquipmentChanged(args) {

    var equipmentId = { Id: args.target.value };
    this.dmapi.GetEquipmentDetailsByID(equipmentId).subscribe(success => {
        this.hrsTracking = success.hrsTracking;
    });

    this.f.TestHours.setValue('');

    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });

    //this.validateMachineRepair();
  }

  validateMachineRepair() {

    if (this.f.selectedPopulatetype.value == "Dialysis Machines") {
      var dmrepairValidate = {
        equipmentId: this.f.selectedDMEquipment.value,
        dmrepairdetailsid: 0,
        isDMRepair: false
      };

      this.dmapi.GetRepairStatusByEquipmentId(dmrepairValidate).subscribe(res => {

        if (res == true) {
          this.DMRepairStatus = false;
        }
        else { this.DMRepairStatus = true; };
        this.RepairStatus = false;
      });
    }
    else {

      var dmrepairValidate = {
        equipmentId: this.f.selectedWPEquipment.value,
        dmrepairdetailsid: 0,
        isDMRepair: false
      };

      if (this.f.selectedPopulatetype.value == "Central Delivery")
        dmrepairValidate.equipmentId = this.f.selectedCDEquipment.value;
      else if (this.f.selectedPopulatetype.value == "Other")
        dmrepairValidate.equipmentId = this.f.OtherEquipment.value;
      else
        dmrepairValidate.equipmentId = this.f.selectedWPEquipment.value;

      this.dmapi.GetWpOtherRepairStatusByEquipmentId(dmrepairValidate).subscribe(res => {

        if (res == true) {
          this.RepairStatus = false;
        }
        else { this.RepairStatus = true; };
        //return {false res };

        this.DMRepairStatus = false;
      });
    }
  }

  onWPEquipmentChanged() {
    var equipmentId = { Id: this.f.selectedWPEquipment.value };
    this.dmapi.GetEquipmentDetailsByID(equipmentId).subscribe(success => {
        this.hrsTracking = success.hrsTracking;
    });

    this.f.TestHours.setValue('');

    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });
  }

  changeReportchk() {

    if (this.f.chkNAReportID.value == true) {
      this.f.ReportId.setValue("N/A");
    }
    else {
      this.f.ReportId.setValue('');
    }
  }

  check4000HoursValidation(): boolean {

    if (this.f.TestHours.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }

  clearValues() {

    this.f.CreatedDate.setValue('');
    this.f.selectedDialysisUnits.setValue('');
    this.f.CollectionDate.setValue('');
    this.f.CollectionTime.setValue('');
    this.f.CollectionRetestDate.setValue('');
    this.f.CollectionRetestTime.setValue('');
    this.f.SampleReadDate.setValue('');
    this.f.SampleReadTime.setValue('');
    this.f.SampleReadRetestDate.setValue('');
    this.f.SampleReadRetestTime.setValue('');
    this.f.FunnelLot.setValue('');
    this.f.CassetteLot.setValue('');
    this.f.FunnelLotRetest.setValue('');
    this.f.CassetteLotRetest.setValue('');
    this.f.selectedWPEquipment.setValue('');
    this.f.selectedDMEquipment.setValue('');
    if (this.f.selectedStation && this.f.selectedStation.value)
      this.f.selectedStation.setValue('');

    this.f.TestHours.setValue('');
    this.f.selectedPopulatetype.setValue('');
    this.f.TestingLab.setValue('');
    this.f.ReportId.setValue('');
    this.f.SampleReadById.setValue('');
  }

  bacteriaFunnelChange(event) {
      if(this.status!=='Completed') {
      let elm = document.getElementById("bacteriaFunnelRefID");
      if (elm.className === "collapsed") {
        this.f.FunnelLot.setValidators(Validators.required);
        this.f.FunnelLot.updateValueAndValidity();

        this.f.CassetteLot.setValidators(Validators.required);
        this.f.CassetteLot.updateValueAndValidity();

        this.isFunnelPanelOpen = true;
      }
      else {
        this.f.FunnelLot.clearValidators();
        this.f.FunnelLot.updateValueAndValidity();

        this.f.CassetteLot.clearValidators();
        this.f.CassetteLot.updateValueAndValidity();

        this.isFunnelPanelOpen = false;
      }
    }
  }

}

<form [formGroup]="MailForm" (ngSubmit)="executeMailCreateUpdate()" novalidate>

    <section class="content-header">
        <h1>
            MessageBox
            <sup class="beta">[demo]</sup>

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">
                <PageHeader   [showNote]="false" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeMailCreateUpdate()" [showDelete]="blnshowDelete"
                    #pageheader></PageHeader>

                <div class="clearfix"></div>
                <div class="box">
                    <div class="box-body">

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                         <div class="panel-heading" role="tab" id="dmReference">
                            <h4 class="panel-title">
                            <div class="box-header with-border" style="padding:0px;">
                                <h3 class="box-title">
                                    Commpose New Message</h3>
                            </div>
                            </h4>
                         </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-8'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>From:</label>
                                        <div class='col-sm-7'>
                                            <input name='forrmName' maxlength='80' required class='form-control'
                                                formControlName='formName'>
                                            <span
                                                *ngIf='MailForm.controls.formName.errors?.required && (MailForm.controls.formName.dirty || MailForm.controls.formName.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-8'>
                                        <label class='col-sm-5 control-label'>
                                            <!-- <span class='text-red inputrequired'>*</span> -->
                                                To:</label>
                                        <div class='col-sm-7'>
                                                        <input name='toName' maxlength='80' required class='form-control'
                                                            formControlName='toName'>
                                                        <span
                                                            *ngIf='MailForm.controls.toName.errors?.required && (MailForm.controls.toName.dirty || MailForm.controls.toName.touched || formSubmitted)'
                                                            [ngClass]="'error'"> Required! </span>
                                                    </div>
                                        </div>
                                    </div>



                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-8'>
                                        <label class='col-sm-5 control-label'>
                                            <!-- <span class='text-red inputrequired'>*</span> -->
                                                Subject:</label>
                                                <div class='col-sm-7'>
                                                        <input name='Subject' maxlength='80' required class='form-control'
                                                            formControlName='Subject'>
                                                        <span
                                                            *ngIf='MailForm.controls.Subject.errors?.required && (MailForm.controls.Subject.dirty || MailForm.controls.Subject.touched || formSubmitted)'
                                                            [ngClass]="'error'"> Required! </span>
                                                    </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-8'>
                                        <label class='col-sm-5 control-label'>
                                            <!-- <span class='text-red inputrequired'>*</span> -->
                                                Message:</label>


                                                <div class='col-sm-7'>
                                                        <textarea name="Message" style="width:100%" rows='10' formControlName="Message" class="form-textarea lgInput" required></textarea>
                                                        <span
                                                            *ngIf='MailForm.controls.Message.errors?.required && (MailForm.controls.Message.dirty || MailForm.controls.Message.touched || formSubmitted)'
                                                            [ngClass]="'error'"> Required! </span>
                                                    </div>


                                    </div>
                                </div>

                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-8'>
                                        <label class='col-sm-5 control-label'>Mandatory:</label>
                                  <div class='col-sm-7'>
                                      <mat-button-toggle-group value=""
                                        formControlName="IsMandatory" name="IsMandatory" form #tasktypegroup="matButtonToggleGroup">

                                        <mat-button-toggle value="true" class="btnsuccess">
                                          {{onText}}
                                        </mat-button-toggle>
                                        <mat-button-toggle class="btndanger" value="">
                                            {{offText}}
                                        </mat-button-toggle>
                                      </mat-button-toggle-group>
                                      <span [ngClass]="'error'"> (User must read during login) </span>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="col-sm-12 box-row">
                                            <attachment #attachment>
                                                    </attachment>
                                                  </div>


                                </div>
                        </div>
                    </div>
                </div>

                <PageHeader   [showNote]="false" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeMailCreateUpdate()" [showDelete]="false" #pageheader></PageHeader>

            </div>
        </div>
    </section>

</form>

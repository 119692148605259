
 <section class="content-header">

    <h1 *ngIf="detailsType == 'Water Process'">
            {{headertext}}
            <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
    </h1>

    <h1 *ngIf="detailsType == 'other'">
        {{headerTextOther}}
    </h1>

    <h1 *ngIf="detailsType == 'Central Delivery'">
        {{headerTextCD}}
    </h1>

</section>
<section class="content">

    <section>
        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Start Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input (focus)="fromDate.toggleCalendar()"
                                                    (click)="fromDate.openCalendar()" class="form-control"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="FromDate" [(ngModel)]="FromDate" [options]="myOptions"
                                                    #fromDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />

                                                <!-- <span class="input-group-btn">
                                                  <button type="button" class="btn btn-default" (click)="fromDate.clearDate()">
                                                      <i class="glyphicon glyphicon-remove"></i>
                                                  </button>
                                                  <button type="button" class="btn btn-default" (click)="fromDate.toggleCalendar()">
                                                      <i class="glyphicon glyphicon-calendar"></i>
                                                  </button>
                                              </span> -->
                                            </div>


                                        </div>


                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <label class="col-sm-6 control-label">Start Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input (focus)="toDate.toggleCalendar()"
                                                        (click)="toDate.openCalendar()" class="form-control"
                                                        style="float:none" placeholder="Select a date" angular-mydatepicker
                                                        name="ToDate" [(ngModel)]="ToDate" [options]="myOptions"
                                                        #toDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />

                                                  <!-- <span class="input-group-btn">
                                                      <button type="button" class="btn btn-default" (click)="toDate.clearDate()">
                                                          <i class="glyphicon glyphicon-remove"></i>
                                                      </button>
                                                      <button type="button" class="btn btn-default" (click)="toDate.toggleCalendar()">
                                                          <i class="glyphicon glyphicon-calendar"></i>
                                                      </button>
                                                  </span> -->
                                              </div>
                                                  <!-- <my-date-picker name="ToDate"  [options]="myDatePickerOptions"
                                                  [(ngModel)]="FromDate"></my-date-picker> -->


                                          </div>

                                      </div>
                                  </div>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                      </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Division:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="selectedDivision">
                                                    <option value="">Select</option>
                                                    <option value="{{item.text}}" *ngFor="let item of DialysisUnits">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Equipment:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="selectedEquipment">
                                                <option value="{{item.value}}" *ngFor="let item of Equipments">
                                                    {{item.text}}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Status:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="selectedStatus">
                                                    <option value="{{item.value}}" *ngFor="let item of Statuses">
                                                        {{item.text}}</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Part ID:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="selectedPart">
                                                <option value="">Select</option>
                                                <option value="{{item.equipementId}}" *ngFor="let item of Parts">{{item.equipmentCode}}
                                                </option>
                                            </select>


                                        </div>

                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Vendors Work Order Reference:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="VendorOrderRef">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Repair ID:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="RepairId">
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div> -->

                                </div>
                            </div>
                        </div>


                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executerepairSearch()">
                                        <span><i class="fa fa-search"></i></span>
                                        <b>&nbsp;Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                            <!-- <option value="">All Divisions</option> -->
                                        <option value="{{item.text}}" *ngFor="let item of DialysisUnits">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executerepairsearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>



                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>



                                <tr *ngFor="let WPRepailDetail of rows;let index=index;"
                                    (click)="_onRowClick(WPRepailDetail.repairDetailId)" class="pointer">

                                    <td matTooltip="{{WPRepailDetail.remark}}">

                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td  matTooltip="{{WPRepailDetail.remark}}">

                                            {{WPRepailDetail.repairId}}

                                    </td>
                                    <td matTooltip="{{WPRepailDetail.remark}}">{{WPRepailDetail.divisionName}}</td>
                                    <td matTooltip="{{WPRepailDetail.remark}}">{{WPRepailDetail.createdbyName}}</td>
                                    <td matTooltip="{{WPRepailDetail.remark}}">

                                      <span class='text-red inputrequired'
                                          *ngIf="( CheckFormDate(WPRepailDetail.craetedOn,WPRepailDetail.timeStarted) || WPRepailDetail.isUploadHistory == 1)">*</span>
                                      {{WPRepailDetail.timeStarted | date:'dd/MM/yyyy'}}</td>
                                    <td matTooltip="{{WPRepailDetail.remark}}">

                                        <!-- <span class='text-red inputrequired'
                                            *ngIf="(WPRepailDetail.isUploadHistory == 1)">*</span> -->
                                        {{WPRepailDetail.timefinished | date:'dd/MM/yyyy'}}</td>
                                    <td matTooltip="{{WPRepailDetail.remark}}">{{WPRepailDetail.equipmentName}} </td>
                                    <!-- <td matTooltip="{{WPRepailDetail.remark}}">{{WPRepailDetail.status}}</td> -->

                                    <td matTooltip="{{WPRepailDetail.status}}">
                                        <div class="progressbar-wrapper">
                                            <ul class="progressBar">
                                                <!-- <li [ngClass]="{'progressBarlightGreen':WPRepailDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': WPRepailDetail.status == 'In Progress', 'fa':WPRepailDetail.status != '' }"></i>
                                                </li> -->

                                                <li [ngClass]="{'progressBarlightGreen': WPRepailDetail.status == 'Completed','progressBarlightBlue': WPRepailDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': WPRepailDetail.status == 'Completed', 'fa-arrow-right':WPRepailDetail.status == 'In Progress', 'fa':WPRepailDetail.status != '' }">
                                                    </i>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>


                        </table>


                    </div>
                    <!-- <div class="row">
                    <div class="col-lg-12 text-center">
                      <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                    </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeDmrepairSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

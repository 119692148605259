import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class FeedbackDetailApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    GetFeedbackDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'FeedBackService/GetFeedbackByID',
        value,
        httpOptions
      );
    }

    GetNextFeedbackSupportId() {
      return this.http.post<any>(
        this.apiUrl + 'FeedBackService/GetNextFeedbackSupportId',
        null,
        httpOptions
      );
    }

    CreateFeedbackDetails(value) {
       return this.http.post<any>(
         this.apiUrl + 'FeedBackService/CreateFeedBack',
         value,
         httpOptions
       );
     }
     PostToInternalForum(value) {
      return this.http.post<any>(
        Globals.internalForumAPI + 'Services/InsertError.asmx/PostTopic',
        value,
        httpOptions
      );
    }
  }

import { DMApiService } from './../../../API/dmapi.service';
import { CommonApiService } from './../../../API/commonapi.service.';
import { HomeFieldService } from './../../../API/homefield.service';
import { AppComponent } from 'src/app/app.component';
import { Component, OnInit, Output, EventEmitter, AfterViewChecked, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HomeService } from 'src/app/API/home.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeUninstallation',
  templateUrl: './purpose-uninstallation.component.html',
  styleUrls: ['./purpose-uninstallation.component.css']
})
export class PurposeUninstallationComponent implements OnInit, AfterViewInit {
  @Output() SaveEvent = new EventEmitter<any>();

  today = new Date();
  dmEquipmentList = [];
  roEquipmentList = [];
  DivisionList = [];
  HomeDivisionId = 0;
  currentUserId;
  enableForm = true;
  IsPurposeCompleted = '';
  formSubmitted = false;

  unInstallationForm = new UntypedFormGroup({
    fullInstallationId: new UntypedFormControl('0'),
    dialysisUnitId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl('0'),
    divisionId: new UntypedFormControl(''),
    dmEquipmentId: new UntypedFormControl(''),
    roEquipmentId: new UntypedFormControl(''),
    dmEquipment: new UntypedFormControl(''),
    roEquipment: new UntypedFormControl(''),
    // poPlumber: new FormControl('', Validators.required),
    // poElectrician: new FormControl('', Validators.required),
    // poMovers: new FormControl('', Validators.required),
    // poPlumberNote: new FormControl(''),
    // poElectricianNote: new FormControl(''),
    // poMoversNote: new FormControl(''),
    modifiedBy: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
  });

  constructor(private dmapi: DMApiService, private commonapi: CommonApiService, private homeApi: HomeService, private homefieldApi: HomeFieldService
    ,public auth: AuthService
  ) { }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.fillDivisions();
      this.fillDMEquipments();
      this.fillROEquipments();
      setTimeout(() => {
        if (this.DivisionList.length > 0)
          this.HomeDivisionId = this.DivisionList[0].value;
      });
    }, error => {
    });
  }

  ngAfterViewInit() {
    this.fif.roEquipmentId.disable();
    this.fif.dmEquipmentId.disable();
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }



  fillDivisions() {
    this.commonapi.FillDivision().subscribe(list => {
      this.DivisionList = list;
    }
    );
    // .filter(x => x.text == 'Home Program')
  }

  fillDMEquipments() {

    // this.dmapi.GetEquipmentDD(this.HomeDivisionId).subscribe(list => {
    //   this.dmEquipmentList = list;
    //  });
    const obj = {
      CategoryName: 'Dialysis Machines'
    };

    this.commonapi.FillEquipmentsByCategory(obj).subscribe(list => {
      this.dmEquipmentList = list;
    });
  }

  fillROEquipments() {
    const obj = {
      CategoryName: 'Water Process'
    };
    this.commonapi.FillEquipmentsByCategory(obj).subscribe(list => {
      this.roEquipmentList = list;
    });
  }

  get fif() {
    return this.unInstallationForm.controls;
  }

  prepareCreateViewModel(Source = "") {
    const modelInitCreate = <any>{};

    modelInitCreate.status = "InActive";
    modelInitCreate.fullInstallationId = this.fif.fullInstallationId.value;
    modelInitCreate.HFServiceId = this.fif.HFServiceId.value;
    modelInitCreate.dialysisUnitId = this.fif.dialysisUnitId.value;
    modelInitCreate.dmEquipmentId = this.fif.dmEquipmentId.value;
    modelInitCreate.roEquipmentId = this.fif.roEquipmentId.value;
    // modelInitCreate.poPlumber = this.fif.poPlumber.value;
    // modelInitCreate.poElectrician = this.fif.poElectrician.value;
    // modelInitCreate.poMovers = this.fif.poMovers.value;
    // modelInitCreate.poPlumberNote = this.fif.poPlumberNote.value;
    // modelInitCreate.poElectricianNote = this.fif.poElectricianNote.value;
    // modelInitCreate.poMoversNote = this.fif.poMoversNote.value;
    modelInitCreate.createdOn = Globals.GetAPIDate(this.today);
    modelInitCreate.createdBy = this.currentUserId;
    modelInitCreate.modifiedOn = Globals.GetAPIDate(this.today);
    modelInitCreate.modifiedBy = this.currentUserId;
    return modelInitCreate;
  }

  save() {
    return new Promise((resolve, reject) => {

      this.formSubmitted = true;

      if (this.unInstallationForm.invalid) {
        return;
      }
      else {

      const FIModelCreate = this.prepareCreateViewModel();

      if (this.fif.fullInstallationId.value === '0'
      ) {
        this.homefieldApi.CreateHomeFieldService_FullInstallation(FIModelCreate).subscribe(
          success => {
            this.fif.fullInstallationId.setValue(success.fullInstallationId);
            resolve(true);
          },
          error => {
            reject();
          }
        );
      } else {
        this.homefieldApi.UpdateHomeFieldService_FullInstallation(FIModelCreate).subscribe(
          success => {
            resolve(true);
          },
          error => {
            reject();
          }
        );

      }
    }
    });
  }


  saveUninstallation() {
    this.save().then(() => {
      this.SaveEvent.emit();
      // this.close();
    }, () => {
      AppComponent.addAlert("Error", 'error');
    });
  }

  setModel() {
    AppComponent.ShowLoader();
    this.homeApi.GetDialysisStationByID({ Id: this.fif.dialysisUnitId.value }).subscribe(
      success => {
        if (success) {

          this.fif.dmEquipment.setValue(success.dmEquipmentName ? success.dmEquipmentName : '');
          this.fif.roEquipment.setValue(success.roEquipmentName ? success.roEquipmentName : '');

          this.fif.dmEquipmentId.setValue(success.dmEquipmentId ? success.dmEquipmentId : '0');
          this.fif.roEquipmentId.setValue(success.roEquipmentId ? success.roEquipmentId : '0');

          this.homefieldApi.GetHomeFieldService_FullInstallationbyServiceID({ Id: this.fif.HFServiceId.value }).subscribe(
            success => {
              if (success) {

                this.fif.fullInstallationId.setValue(success.fullInstallationId);
                if(success.fullInstallationId)
                {
                  this.fif.dialysisUnitId.setValue(success.dialysisUnitId);
                  this.fif.dmEquipmentId.setValue(success.dmEquipmentId);
                  this.fif.roEquipmentId.setValue(success.roEquipmentId);
                  this.fif.dmEquipment.setValue(success.dmEquipment);
                  this.fif.roEquipment.setValue(success.roEquipment);
                  // this.fif.poPlumber.setValue(success.poPlumber);
                  // this.fif.poElectrician.setValue(success.poElectrician);
                  // this.fif.poMovers.setValue(success.poMovers);
                  // this.fif.poPlumberNote.setValue(success.poPlumberNote);
                  // this.fif.poElectricianNote.setValue(success.poElectricianNote);
                  // this.fif.poMoversNote.setValue(success.poMoversNote);
                }
                // this.fif.dmEquipmentId.setValue(success.dmEquipmentId);
                // this.fif.roEquipmentId.setValue(success.roEquipmentId);
                // this.fif.dmEquipmentId.disable();
                // this.fif.roEquipmentId.disable();
              }
              AppComponent.HideLoader();

            },
            error => {
              AppComponent.HideLoader();
              AppComponent.addAlert(error.message, 'error');
            });
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

  }

  formChange(enable = true) {
    // if (enable){
    //   //this.unInstallationForm.enable();
    // }
    // else{
    //   //this.unInstallationForm.disable();
    // }
    this.enableForm= enable;
  }

  // close(){
  //
  //   this.dialogRef.close(false);
  // }

}

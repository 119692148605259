// import { Page } from './../../WaterProcess/DaliyDemo/WPDailySearch.component';
// import { InvApiService } from './../../API/invapi.service';
import { FieldMasterApiService } from './../../API/fieldmasterapi.service';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { Page } from 'src/app/Core/Shared/Common/PageModel';
import { InvApiService } from 'src/app/API/invapi.service';

@Component({
  selector: 'FieldMasterSearch',
  templateUrl: './FieldMasterSearch.component.html',

})

export class FieldMasterSearchComponent {
  ShowSearch = false;
  searchText = 'Search Records';
  Model = '';
  Category = '';
  blnDirtySearch = false;
  ModelList = [];
  CategoryList = [];

  constructor(private fieldMasterApi: FieldMasterApiService, private router: Router,
    private commonapi: CommonApiService,
    private invapi: InvApiService,
    private dataGridService: TableHeadsService, ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = '/Admin/FieldMasterDetail';
  currentPageNumber = 1;
  sortExpression = 'ModelName';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Field Master';
  defaultSort;


  ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'modelId', '5');
    this.dataGridService.addHeader('Category Name', 'CategoryName', '30');
    this.dataGridService.addHeader('ModelName', 'ModelName', '65');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('ModelName', false);
    this.fillCategories();
    this.fillModelList();
    this.CheckDirtySearch();
  }

  executeFieldMasterSearchClear() {
    this.Model = '';
    this.Category = '';
    this.executeFieldMasterSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.Model || this.Category
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeFieldMasterSearch();
  }

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeFieldMasterSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.ShowSearch = false;
    this.pageheader.searchText = 'Search Records';
    this.CheckDirtySearch();

    const FieldMasterSearch = this.prepareSearch();
    this.fieldMasterApi.GetModelSerachDetails(FieldMasterSearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.modelList;
      AppComponent.HideLoader();
    }
      ,
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    let FieldMastersearch = <any>{};
    FieldMastersearch.currentPageNumber = this.currentPageNumber;
    FieldMastersearch.sortExpression = this.sortExpression;
    FieldMastersearch.sortDirection = this.sortDirection;
    FieldMastersearch.PageSize = this.pageSize;


    if (this.Model) {
        FieldMastersearch.ModelId = this.Model;
    }

    if (this.Category) {    
        FieldMastersearch.categoryId = this.Category;
    }
    
    return FieldMastersearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeFieldMasterSearch();
  }

  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }


  fillModelList() {
    const categoryId = { categoryId: this.Category };
    this.invapi
      .FillModels(categoryId)
      .subscribe(list => {
        this.ModelList = list;
        this.Model = '';
      });
  }

  
  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }

}

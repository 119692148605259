import { DecimalPipe, DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { WPApiService } from 'src/app/API/wpapi.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2SetPoints',
  templateUrl: 'BioPureHX2SetPoints.component.html',
  styleUrls: ['BioPureHX2SetPoints.component.css']
})

export class BioPureHX2SetPointsComponent {
  title =  'BioPure HX2 Set Points';

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  private router: Router,
  private wpapi: WPApiService,
  public dialog: MatDialog
) { }

lstSetPoints = [
  { tag: 'No Tag', parameter: 'Softener Configuration',  security: '4', lower: 'Duplex or Single', upper: '' , default: 'Single', cust_setting:'', unit:'No Tag', lower_colspan:'2', rowspan:1 },
];
  RouteClick(value) {
    this.router.navigate([value]);
  }
}

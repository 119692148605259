<div class="modal-header">
    <h4 class="modal-title">{{data.title}}</h4>
      <button  type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height: 80%">
      <p>
          <ngx-image-viewer [config]="config" [src]="images" [(index)]="imageIndex"></ngx-image-viewer>
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close</button>
    </div>

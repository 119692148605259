import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'informationdialog',
  templateUrl: './informationdialog.component.html',
  styleUrls: ['./informationdialog.component.css']
})

export class informationdialog implements AfterViewInit {
    constructor(
        private auth: AuthService,
        private homefieldApi: HomeFieldService,
        public dialogRef: MatDialogRef<informationdialog>
      ) { }

      ngAfterViewInit() {

    }
}

import { Component, ViewChild } from '@angular/core';
import { HistoryUploadSearchComponent } from '../../Core/Shared/HistoryUpload/HistoryUploadSearch.component';

@Component({
  selector: 'OtherHistoryUploadDetailSearch',
  templateUrl: './OtherHistoryUploadDetailSearch.component.html',

})
export class OtherHistoryUploadDetailSearchComponent {

}

<section class="content-header">
    <h1>
        {{title}}
        <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
    </h1>

</section>
<section class="content">
    <section>
        <div class="row">
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">
                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Transfer Id:</label>

                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" maxlenght="20"
                                                    [(ngModel)]="transferId">
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Transfer Date:</label>
                                            <div class="col-sm-6">
                                                <input (focus)="t.toggleCalendar()" (click)="t.openCalendar()"
                                                    class="form-control" style="float:none" placeholder="Select a date"
                                                    angular-mydatepicker name="transferDate" [(ngModel)]="transferDate"
                                                    [options]="myOptions" #t="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">

                                            <label class="col-sm-6 control-label">User:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="createdBy" name="createdBy">
                                                    <option value="{{item.value}}" *ngFor="let item of Users">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Transfer Type:</label>

                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="transferType">
                                                    <option value="{{item.value}}" *ngFor="let item of TransferTypes">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Category:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="categoryId" name="categoryId">
                                                    <option value="{{item.value}}" *ngFor="let item of CategoryList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Transfer To:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="divisionTo">
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of DivisionList">
                                                    {{item.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Transfer From:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="divisionFrom">
                                                    <option value="">Select</option>
                                                    <option value="{{item.text}}" *ngFor="let item of DivisionList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Equipment In:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="equipmentId">
                                                    <option value="{{item.value}}" *ngFor="let item of EquipmentList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Equipment Out:</label>

                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="exchEquipmentId">
                                                    <option value="{{item.value}}" *ngFor="let item of EquipmentList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                 
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div> -->

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executeTransferSearch()">
                                        <span><i class="fa fa-search"></i></span>
                                        <b>&nbsp;Search</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeTransferSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>
                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transferDetail of rows;let index=index;"
                                    (click)="_onRowClick(transferDetail.dmTransferDetailsId)" class="pointer">

                                    <td>

                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>
                                        <span *ngIf="transferDetail.previousTransfer"
                                            class="text-red inputrequired">*</span>{{transferDetail.transferId}}
                                    </td>
                                    <td>{{transferDetail.transferDate | date:'dd/MM/yyyy'}}</td>
                                    <td>{{transferDetail.transferType}}</td>
                                    <td>{{transferDetail.divisionToName}} </td>
                                    <td>{{transferDetail.divisionFromName}} </td>
                                    <td>{{transferDetail.categoryInName}} </td>
                                    <td>{{transferDetail.equipmentName}} </td>
                                    <td>{{transferDetail.exchEquipmentName != "Select" ? transferDetail.exchEquipmentName : '' }}
                                    </td>
                                    <td>{{transferDetail.createdByName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeTransferSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>


import { Component, ViewChild } from '@angular/core';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { Page } from '../../Core/Shared/Common/PageModel'
import { HomeService } from 'src/app/API/home.service';

@Component({
  selector: 'HomeStationSearch',
  templateUrl: './HomeStationSearch.component.html',
})

export class HomeStationSearchComponent {

  Users=[];

  constructor(private commonapi: CommonApiService,
    private homeApi: HomeService,
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/Home/HomeStationDetail";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "dialysisStationName";
  sortDirection = "ASC";
  pageSize = 1000;
  ShowSearch = false;
  title = "Station Assignment";
  searchText = "Search Records"
  defaultSort;

  //Search Options //
  divisionName = 'Home Program';
  dialysisStationName = '';
  dmEquipmentName = '';
  roEquipmentName = '';
  createdBy = '';
  status = '';

  DivisionList = [];
  StationsList = [];
  dmEquipmentList = [];
  roEquipmentList = [];
  StatusList = [
    { text:'Select',value:''},
    { text:'Pending',value:'Pending'},
    { text:'Active',value:'Active'},
    { text:'InActive',value:'InActive'},
  ];
  ngOnInit() {
    AppComponent.loading = true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#", "dialysisStationName", "20");
    this.dataGridService.addHeader("BMR Home Unit", "dialysisStationName", "80");
    // this.dataGridService.addHeader("Home DM", "dmEquipmentName", "10");
    // this.dataGridService.addHeader("Home RO", "roEquipmentName", "10");
    // this.dataGridService.addHeader("User", "createdByName", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort(this.sortExpression, true);
    this.status = 'Active';
    this.CheckDirtySearch();
    this.fillDropDowns();
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDialysisStationsSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDialysisStationsSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var dsSearch = this.prepareSearch();

    this.homeApi.GetDialysisStationSearchDetails(dsSearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.dialysisStations;
      AppComponent.HideLoader()
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    var dsSearch = <any>{};

    if (this.divisionName) {
      dsSearch.divisionName = this.divisionName;
    }

    if (this.dmEquipmentName) {
      dsSearch.dmEquipmentName = this.dmEquipmentName;
    }

    if (this.roEquipmentName) {
      dsSearch.roEquipmentName = this.roEquipmentName;
    }

    if (this.status) {
      dsSearch.status = this.status;
    }

    if (this.createdBy) {
      dsSearch.createdBy = this.createdBy;
    }

    dsSearch.dialysisStationName = this.dialysisStationName;
    dsSearch.currentPageNumber = this.currentPageNumber;
    dsSearch.sortExpression = this.sortExpression;
    dsSearch.sortDirection = this.sortDirection;
    dsSearch.pageSize = this.pageSize;
    return dsSearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDialysisStationsSearch();
  }

  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  //Search & Select//

  fillDropDowns() {
    //this.fillDivisions();
    this.fillStations();
    this.fillDMEquipments();
    this.fillROEquipments();
    this.fillUser();
  }


  fillUser()
  {
    this.commonapi.GetUsersDD().subscribe(list => this.Users=list);
  }

  fillDivisions() {
    this.commonapi.FillDivision().subscribe(list => this.DivisionList = list);
  }

  fillDMEquipments() {
    const obj = {
      DialysisStationId: 0,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
        this.dmEquipmentList = list;
    });
  }

  fillROEquipments() {
    const obj = {
      DialysisStationId: 0,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
        this.roEquipmentList = list;
    });
  }

  fillStations(){
    var dsSearch = <any>{};
    dsSearch.divisionName = this.divisionName;
    dsSearch.currentPageNumber = 1;
    dsSearch.sortExpression = 'dialysisStationName';
    dsSearch.sortDirection = 'ASC';
    dsSearch.pageSize = 1000;
     this.homeApi.GetDialysisStationSearchDetails(dsSearch).subscribe(pagedData => {
      this.StationsList = pagedData.dialysisStations;
      AppComponent.HideLoader()
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  executeDialysisStationsSearchClear() {
    this.dialysisStationName = '';
    this.dmEquipmentName = '';
    this.roEquipmentName = '';
    this.createdBy = '';
    this.status='Active';

    this.executeDialysisStationsSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
       this.dmEquipmentName
      || this.roEquipmentName
      || this.dialysisStationName
      || this.status != 'Active'
      || this.createdBy
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  //Search & Select //
}

<style>
video {
  top:auto !important;
  left:auto !important;
  position: absolute !important;
  z-index: 0 !important;
  transform: none !important;
}
</style>
<div class="modal-header">
  <h4 class="modal-title">Quick Scan</h4>
  <div class="text-right  col-sm-4" style="padding:0;float:right">
    <button type="button" class="btn btn-danger" style="margin-right:5px"
      (click)="dialogRef.close(false)">Cancel</button>
  </div>
</div>

<div class="modal-body ">
  <p>Notice: You must allow to use your webcam.</p>
  <div id="interactive" class="viewport" #interactive></div>
<div>



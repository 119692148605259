<style>
    .disable-whole-div__cover {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color:transparent;
        opacity: 0.3;
    }
    </style>

<div class="box ibox box-info">
    <!-- <div class="disable-whole-div__cover" [hidden]="disableControl"></div> -->
        <div class="box-header"
        [ngClass]="{'with-border': NoteList.length > 0,'': NoteList.length == 0}">
                <div class="row">
                    <div class="col-sm-11">
                        <h3 class="box-title">{{PlusNoteHeader}} <button style="display: inline-block;" (click)="AddNote()"
                          class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button" [attr.disabled]="disableControl?'true':null">
                            <span><i class="fa fa-plus"></i></span>
                        </button></h3>
                     </div>

                     <div class="col-sm-1">

                 </div>
             </div>
            </div>
             <div class="box-body">
                 <table width="100%" style="width:100%;table-layout: fixed;" id="noteTable">

                     <tbody><tr *ngFor="let item of NoteList.slice().reverse();let index=index">
                        <td align="left" width="8%" valign="top">
                          {{item.createdOn | date:'dd/MM/yyyy'}}
                        </td>

                        <td align="left" width="5%" valign="top">
                        {{item.createdOn | date:'HH:mm'}}
                        </td>

                        <td align="left" width="6%"  class="text-center" *ngIf="showHours==true" valign="top">
                            {{(getNoteHour(item.description) | number:'2.2')}}
                            <span *ngIf="getNoteHour(item.description) !=''"> hr </span>
                            <span *ngIf="getNoteHour(item.description) ==''"> N/A </span>
                        </td>

                        <td align="left" class="text-center" width="12%" valign="top">
                              {{item.createdbyname}}
                        </td>

                         <td align="left" width="auto" >
                             <div style="word-wrap: break-word;margin-bottom:0px;" [innerHTML]="(getNoteDesc(item.description))">
                             </div>
                         </td>
                     </tr>

                     </tbody></table>
             </div>
             </div>

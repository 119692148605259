<div [formGroup]="otherForm" novalidate>
  <div class="box-body box ibox box-info">
    <div class="panel panel-default">
      <!-- always blue bcoz panel shows only when active or HFS Completed -->
      <div [ngClass]="{'panel-heading-blue': isBluePanelSection==true,'panel-heading': isBluePanelSection==false}" role="tab">
        <h4 class="panel-title row">
          <div class="col-sm-10" style="cursor:pointer" (click)="ExpandSection()">
            <a class="collapsed" data-parent="#accordion" aria-controls="HFS_SectionRef"
              [attr.aria-expanded]="isCollapsed">
              <div class="box-header with-border" style="padding: 6px 0px;">
                <h3 class="box-title col-sm-6 container">Visit Purpose Detail - Other Task
                  <img src="../../assets/img/completed_icon.png"
                    style="height: 20px;margin-left: 5px;vertical-align: bottom;" *ngIf="f.ModifiedOn.value">
                </h3>

              </div>
            </a>
          </div>
          <div class="row col-sm-2" style="text-align:right">
            <button type="button" class="row btn btn-primary" *ngIf="sectionInProgress" (click)="executeOtherSection()">
              <i class="fa fa-clock-o"></i> &nbsp; Departure Details</button>
          </div>
        </h4>
      </div>

      <div id="HFS_Section" class="panel-collapse collapse" [ngbCollapse]="!isCollapsed" role="tabpanel"
        aria-labelledby="HFS_Section">
        <div class="box-body">
          <div class="col-sm-12 box-row">
            <label class='col-sm-2 control-label'><span class='text-red inputrequired'>*</span>Notes:</label>
            <div class='col-sm-9'>
              <textarea name='Issue' rows='3' class="form-textarea" *ngIf="!f.ModifiedOn.value"
                formControlName='Issue'></textarea>

              <label class="readOnlyText" style="word-wrap: break-word;" *ngIf="f.ModifiedOn.value">
                {{f.Issue.value}}</label>

            </div>
            <span *ngIf="f.Issue.errors?.required && (f.Issue.dirty || f.Issue.touched ||  isValidFormSubmitted==false)"
              [ngClass]="'error'">
              Required!
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<form>
  <section class="content-header">
    <h1>

      <!-- <sup class="beta">[beta]</sup> -->


    </h1>


  </section>
  <section class="content" style="padding-bottom: 20px;margin-bottom: 30px">


    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary" style="padding-bottom: 40px">
      </div>
    </div>
  </section>
</form>


<div class="modal-header">
    <h4 class="modal-title">{{PageTitle}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height:100%">
      <iframe  [src]='sanitizer.bypassSecurityTrustResourceUrl(URL)'  width="100%" height="100%" style="border:none"></iframe>
    </div>
    
<form
    [formGroup]="ForumCategoryForm"
    (ngSubmit)="executeForumCategoryCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Forum Category
        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    title="Task Type"
                    [showNote]="false"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeForumCategoryCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">ForumCategoryId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="ForumCategoryId"
                                        [value]="f.ForumCategoryId"
                                        formControlName="ForumCategoryId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                                <label class="col-sm-2 control-label">
                                                    <span class="text-red inputrequired">*</span>Name:
                                                </label>
                                                <div class="col-sm-10"><input
                                                      type="text"
                                                      class="form-control mdInput"
                                                      formControlName="ForumCategoryName"
                                                      name="ForumCategoryName"
                                                      maxlength="50">
                                                  <span *ngIf="f.ForumCategoryName.errors?.required && (f.ForumCategoryName.dirty || f.ForumCategoryName.touched ||  isValidFormSubmitted==false)"
                                                      [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                                <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Description:</label>
                                                <div class="col-sm-10">
                                                <input
                                                type="text"
                                                class="form-control mdInput"
                                                formControlName="ForumCategoryDesc"
                                                name="ForumCategoryDesc"
                                            >
                                            <span *ngIf="f.ForumCategoryDesc.errors?.required && (f.ForumCategoryDesc.dirty || f.ForumCategoryDesc.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span>
                                        </div>
                                            </div>
                                        <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeForumCategoryCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

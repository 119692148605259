import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

const httpOptionsMultipart = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class InvApiService {
  private searchValue : string;
  //private searchValue = "InventorySearchValue";

  apiUrl = Globals.apiUrl;

  constructor(private http: HttpClient) { }

  setSearchValue(val) {
    this.searchValue = val;
    //sessionStorage.setItem(this.searchValue, JSON.stringify(val);
  }

  getSearchValue() {
    return this.searchValue;
    //return JSON.parse(sessionStorage.getItem(this.searchValue));
  }


  CreateInventoryDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/CreateInventoryDetails',
      value,
      httpOptions
    );
  }

  UpdateInventoryDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/UpdateInventoryDetails',
      value,
      httpOptions
    );
  }

  GetInventoryByID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetInventoryByID',
      value,
      httpOptions
    );
  }

  GetLastUpdateHoursDate(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetLastUpdateHoursDate',
      value,
      httpOptions
    );
  }

  FillDivision() {
    return this.http.post<any>(
      this.apiUrl + 'UserService/FillDivision',
      '',
      httpOptions
    );
  }

  FillCategoryList() {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/FillInventoryCategoryList',
      '',
      httpOptions
    );
  }

  FillManufacturerList(value) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/FillManufacturerList',
      value,
      httpOptions
    );
  }

  FillModels(value) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/FillModels',
      value,
      httpOptions
    );
  }

  FillVendorList() {
    return this.http.post<any>(
      this.apiUrl +
      'CommonService/FillVendors',
      '',
      httpOptions
    );
  }

  GetInventoryDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetInventoryDetails',
      value,
      httpOptions
    );
  }

  GetInventoryNotes(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetInventoryNotes',
      value,
      httpOptions
    );
  }

  GetInventoryAttachments(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetInventoryAttachments',
      value,
      httpOptions
    );
  }

  DeleteInventoryDetail(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/DeleteInventoryDetail',
      value,
      httpOptions
    );
  }

  FileUpload(value) {
    return this.http.post<any>(
      Globals.apiUrl + 'Files/Upload',
      value,
      httpOptionsMultipart
    );
  }


  CreateModelDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'MasterServiceController/CreateModelDetails',
      value,
      httpOptions
    );
  }

  GetScheduledHours(value) {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetPMScheduledDetails',
      value,
      httpOptions
    );
  }

  CreateManufacturerDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'MasterServiceController/CreateManufacturerDetails',
      value,
      httpOptions
    );
  }

  GetInventoryFilterDetails() {
    return this.http.post<any>(
      this.apiUrl +
      'InventoryService/GetInventoryFilterDetails',
      '',
      httpOptions
    );
  }

  GetItemIdByInventory(value) {

    return this.http.post<any>(
      this.apiUrl + 'InventoryService/GetItemIdByInventory',
      value,
      httpOptions
    );
  }

}

import { Component } from '@angular/core';
import { AppComponent } from "../app.component";
import { Globals } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
@Component({
  selector: 'home',
  templateUrl: './home.component.html',

})
export class HomeComponent {
  url='';
  constructor( private authentication: AuthService,public globals: Globals,public sanitizer: DomSanitizer){}
  ngOnInit() {
    console.log('')
    this.getURL();
  }
  getURL()
  {

    if(!(this.globals.url!="unknown" && this.globals.url !=undefined && this.globals.url !="" ) )

   {

    AppComponent.ShowLoader();

    this.authentication.GetPasswordLoggedInUser().subscribe(list =>
      {

        this.globals.url= Globals.oldUrl + "/login.aspx?source=MRMWEB" + "&returnURL=~/default.aspx&UName="+ this.authentication.GetUserEmail()+"&PWD=" + list.userPassword;
        AppComponent.HideLoader();
      });


  }
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { Globals, actionType, NoteModuleName, attachmentFormModules } from 'src/app/globals';
import { NotesComponent } from '../NotesComponent/NotesComponent';
import { PartsComponent } from '../PartsControl/PartsComponent';
import { DMApiService } from 'src/app/API/dmapi.service';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { WPApiService } from 'src/app/API/wpapi.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'HistoryUploadDetail',
  templateUrl: './HistoryUploadDetail.component.html'

})
export class HistoryUploadDetailComponent implements OnInit {


//todo
@Input() detailsType  ="Water Process"
@Input() headerTextWP = "Water Process Upload History Details";
@Input() headerTextOther = "Other History Upload";
@Input() ListURL = "WaterProcess//WPHistoryUploadSearch";

HistoryTypeList = [
    { value: '', text: 'Select' },
    { value: 'E', text: 'Event' },
    { value: 'R', text: 'Repair' },
    { value: 'PM', text: 'PM' }
  ];

  // @ViewChild(NotesComponent)
  // notes: NotesComponent;

  // @ViewChild(AttachmentComponent)
  // attachment: AttachmentComponent;

  // dialogRef: MatDialogRef<ConfirmationDialog>;
  private today = new Date();
  public hrsTracking = true;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  @ViewChild(PartsComponent, { static: false })
  parts: PartsComponent;
  eventId = '[AutoNumber]';
  DivisionList = [];
  EquipmentList = [];
  isValidFormSubmitted = null;
  ShowParts = true;
  Parts = [];
  status = '';
  blnshowSave = false;
  blnshowDelete = false;
  repairId = '[AutoNumber]';
  UserName = '';
  formDates = this.today;


  // hydraulicDisinfectionList = [
  //   { value: '', text: 'Select' },
  //   { value: 'Heat', text: 'Heat' },
  //   { value: 'Heat Citric', text: 'Heat Citric' },
  //   { value: 'Chemical', text: 'Chemical' },
  //   { value: 'No', text: 'No' }
  // ];

  hydraulicDisinfectionList = [
      { value: '', text: 'Select' },
      { value: 'Chemical', text: 'Chemical' },
      { value: 'Heat Cleaned', text: 'Heat Cleaned' },
      { value: 'N/A', text: 'N/A'}
  ];

  UploadHistoryForm = new UntypedFormGroup({
    UploadHistoryFormId: new UntypedFormControl('0'),
    formDate: new UntypedFormControl(''),
    formUser: new UntypedFormControl(''),
    eventId: new UntypedFormControl(''),
    HistoryType: new UntypedFormControl('', Validators.required),
    HistoryDate: new UntypedFormControl(''),
    divisionId: new UntypedFormControl('', Validators.required),
    equipmentId: new UntypedFormControl('', Validators.required),
    // hoursin: new FormControl('', Validators.required),
    remarks: new UntypedFormControl('', Validators.required),
    PartSearchTerm: new UntypedFormControl(''),
    PartDescription: new UntypedFormControl(''),
    PartLocation: new UntypedFormControl(''),
    PartQuantity: new UntypedFormControl(''),
    hoursout: new UntypedFormControl('', Validators.required),
    dateOut: new UntypedFormControl('', Validators.required),
    hydraulicDisinfection: new UntypedFormControl(''),
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }


  constructor(
    private dmapi: DMApiService,
    private wpapi: WPApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private commonapi: CommonApiService
  ) { }

  ngOnInit() {

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.formDates = this.today = new Date(currentDate);
      this.setDateOptions();

      this.SetPageRights();
      this.UserName = this.auth.GetUserFLName();
      this.fillDivision();
      this.auth.GetCurrentUserDivision().subscribe(division => {
        if (division.length === 1) {
          this.f.divisionId.setValue(division[0]);
          this.fillEquipment(division[0]);
        }
        else {
          this.fillEquipment(0);
        }
      }, error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      });

      this.setHoursOutValidation();


      if (this.f.HistoryDate != undefined && this.f.HistoryDate != null) {
        this.f.dateOut.setValidators([Validators.required, CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          this.f.HistoryDate
        )]);
      }
    }, error => {
      AppComponent.HideLoader();
    });
  }

  setHoursOutValidation(){
    if(this.hrsTracking)
    this.f.hoursout.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);
    else
      this.f.hoursout.setValidators([]);

    this.f.hoursout.updateValueAndValidity();
  }


  ngAfterViewInit() {
    // Parts
    //console.log(this.ShowParts);
    if (this.ShowParts == true) {
      this.parts.partscategoryname = 'Water Process';
      // this.parts.partscategoryname = this.detailsType;
      this.parts.currentUserId = this.currentUserId;
      this.parts.API = this.dmapi;
      this.parts.status = this.status;
      this.parts.showPartHearder = false;
    }
  }


  SetPageRights() {
    this.auth.GetPageRights('DM Repair Form').subscribe(list => {
      this.blnshowSave = list[actionType.create] == '1';
      this.blnshowDelete = false;
    });
  }

  get f() {
    return this.UploadHistoryForm.controls;
  }

  fillDivision() {
    this.commonapi.FillDivision().subscribe(list => {

    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DivisionList = list.filter(
      //   x => x.text != "Home Program"
      // );
      this.DivisionList = list;
    });
  }

  fillEquipment(divisionID) {

    if (divisionID != 0) {
      const value = {
        DivisionId: divisionID,
        CategoryName: this.detailsType,
        Type: 'EQUIPMENT'
      };
      this.wpapi.GetEquipmentByCategoryDD(value).subscribe(list => {
        this.EquipmentList = list;
      });
    } else {
      this.EquipmentList = [{ value: '', text: 'Select' }];
      this.f.equipmentId.setValue('');
    }
  }


  DivisionChanged() {

    this.fillEquipment(this.f.divisionId.value);
  }

  EquipmentDropDownChanged() {
    this.parts.SelectedEquipment = this.f.equipmentId.value;


    var dmrepairCreate = { Id: this.f.equipmentId.value };
    this.dmapi
      .GetEquipmentDetailsByID(dmrepairCreate)
      .subscribe(success => {

      this.hrsTracking = success.hrsTracking;

      this.setHoursOutValidation();

    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }


  executeHistoryUploadCreate = function (Source) {

    AppComponent.loading = true;
    if ((Source === 'Notes' && this.notes.showParts === true && this.notes.Parts.length > 0)) {
      const partTrans = this.parts.PartTrans;
      //console.log(this.parts);
      this.notes.Parts.forEach(function (value) {
        partTrans.push(value);
      });
      this.parts.PartTrans = partTrans;
    }
    // this.f.hoursout.updateValueAndValidity();


    this.isValidFormSubmitted = false;
    if (this.UploadHistoryForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      // this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    // console.log(this.f.HistoryType.value);

      const dmrepairCreate = this.prepareCreateViewModelRepair();
      this.wpapi.CreateWatrerProcessRepairsRecord(dmrepairCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          console.log("Redirect");
          this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );

  };



  prepareCreateViewModelRepair() {
    const dmrepairCreate = <any>{};


    dmrepairCreate.repairdetailid = 0;
    dmrepairCreate.repairtype = this.detailsType;
    dmrepairCreate.prefix = this.f.HistoryType.value;
    if (this.f.HistoryDate.value !== null && this.f.HistoryDate.value !== undefined) {
      dmrepairCreate.timeStarted = Globals.GetAPIDate(this.f.HistoryDate.value.jsdate);
    }

    dmrepairCreate.workOrderRefNo = "";

    if (this.f.dateOut.value !== null && this.f.dateOut.value !== undefined) {
      dmrepairCreate.timefinished = Globals.GetAPIDate(this.f.dateOut.value.jsdate);
    }

    dmrepairCreate.createdOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.createdBy = this.currentUserId;

    dmrepairCreate.modifiedOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.modifiedBy = this.currentUserId;

    dmrepairCreate.remark = this.f.remarks.value;

    if(this.f.hoursout.value && this.hrsTracking){
      dmrepairCreate.hoursout = this.f.hoursout.value;
      dmrepairCreate.hoursin = this.f.hoursout.value;
    }
    else{
      dmrepairCreate.hoursout = 0;
      dmrepairCreate.hoursin = 0;
    }

    dmrepairCreate.status = this.status;
    dmrepairCreate.WarrentyStatus = "";
    dmrepairCreate.divisionId = this.f.divisionId.value;
    dmrepairCreate.equipmentId = this.f.equipmentId.value;
    dmrepairCreate.note = this.f.remarks.value;
    dmrepairCreate.isUploadHistory = true;
    dmrepairCreate.outHydraulicDisinfection = this.f.hydraulicDisinfection.value;

    // parts
    dmrepairCreate.repairPartsDetails = this.parts.PartTrans;

    // attachments
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Repair];
    this.attachment.UploadBy = this.currentUserId;
    dmrepairCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    return dmrepairCreate;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}

<!-- <h1 md-dialog-title>Confirm</h1>
<div md-dialog-content>{{confirmMessage}}</div>
<div md-dialog-actions>
  <button md-button style="color: #fff;background-color: #153961;" (click)="dialogRef.close(true)">Confirm</button>
  <button md-button (click)="dialogRef.close(false)">Cancel</button>
</div> -->
<div class="modal-header  col-sm-12">
  <h4 class="modal-title col-sm-8">Note</h4>

  <div class="text-right  col-sm-4" style="padding:0">
    <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Cancel</button>
    <button type="button" class="btn btn-primary"  (click)="save('')">Save</button>
  </div>
  <!-- <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <p>
    <b *ngIf="showHours==true">Estimated time performing the following Repair :
      <input style="width:75px;display: inline;" class="form-control" [(ngModel)]="hours" name="hours" maxlength="3"
        (keypress)="numberOnly($event)" /> (hrs) </b>
    <span class="error">{{errormsg}}</span>

  </p>
  <p>
    <textarea name="note" #noteId autosize [(ngModel)]="notes" class="form-textarea"></textarea>
  </p>
</div>
<Parts_Component *ngIf="showParts===true" #NoteParts></Parts_Component>


<div class="box ibox box-info" *ngIf="PrevNotes.length > 0" style="padding-right:10px">
  <div class="box-header with-border">
    <div class="row">
      <div class="col-sm-11">
        <h3 class="box-title"><b>{{VehicleName}}</b> Failure History
        </h3>
      </div>

      <div class="col-sm-1">

      </div>
    </div>
  </div>
  <div class="box-body" >
    <table width="100%" style="width:100%;table-layout: fixed;" id="noteTable">
      <thead>
        <tr>
            <th style="width:15%">Date</th>
            <th style="width:10%">Time</th>
            <th style="width:20%">Tech</th>
            <th style="width:45%;">Note</th>
            <th style="width:10%;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of PrevNotes.slice().reverse();let index=index">
          <td align="center" width="15%" valign="top">
            {{item.createdOn | date:'dd/MM/yyyy'}}
          </td>

          <td align="center" width="15%" valign="top">
            {{item.createdOn | date:'HH:mm'}}
          </td>

          <td align="center" class="text-center" width="25%" valign="top">
            {{item.createdbyname}}
          </td>

          <td align="left" width="auto">
            <div style="word-wrap: break-word;margin-bottom:0px;" [innerHTML]="item.description">
            </div>
          </td>

          <td align="center" class="text-center" width="10%" valign="top">
            <a style="text-align:center">
              <i class="fa fa-paper-plane headerMenu" id="{{index}}" (click)="AddNotesToTextBox(item.description,index)" *ngIf="showSaveLink(index,item,PrevNotes)"></i>
            </a>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</div>

<!-- <div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div> -->

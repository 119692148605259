import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'PrivacyPolicyDialog',
  templateUrl: './PrivacyPolicyDialog.html'
})
export class PrivacyPolicyDialog implements OnInit {

  constructor(public dialogRef: MatDialogRef<PrivacyPolicyDialog>) { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class WPApiService {
  apiUrl = Globals.apiUrl;

  constructor(private http: HttpClient) {}
  GetWaterprocessDailyRecords(value) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterprocessDailyRecords',
      value,
      httpOptions
    );
  }

  CreateWaterProcessDailyRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateWaterProcessDailyRecord',
      obj,
      httpOptions
    );
  }

  UpdateWaterProcessDailyRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateWaterProcessDailyRecord',
      obj,
      httpOptions
    );
  }

  DeleteWaterProcessDailyRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteWaterProcessDailyRecord',
      obj,
      httpOptions
    );
  }
  GetWaterProcessDailyRecordByID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterProcessDailyRecordByID',
      obj,
      httpOptions
    );
  }
  checkRecordExist(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/checkRecordExist',
      obj,
      httpOptions
    );
  }
  FillAcidLevelOptions(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FillAcidLevelOptions',
      obj,
      httpOptions
    );
  }
  SelectAcidLevelsBydailywaterrecordid(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/SelectAcidLevelsBydailywaterrecordid',
      obj,
      httpOptions
    );
  }
  GetNotesBywaterprocessdailyrecordID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetNotesBywaterprocessdailyrecordID',
      obj,
      httpOptions
    );
  }
  GetattachemnetsBywaterprocessdailyrecordID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetattachemnetsBywaterprocessdailyrecordID',
      obj,
      httpOptions
    );
  }

  GetSaturdayWaterprocessDailyRecords(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetSaturdayWaterprocessDailyRecords',
      obj,
      httpOptions
    );
  }
  GetNotesBysaturdayWaterRecorddailyrecordID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetNotesBysaturdayWaterRecorddailyrecordID',
      obj,
      httpOptions
    );
  }
  GetattachemnetsBysaturdayWaterRecorddailyrecordID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetattachemnetsBysaturdayWaterRecorddailyrecordID',
      obj,
      httpOptions
    );
  }

  CreateSaturdayWaterProcessRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateSaturdayWaterProcessRecord',
      obj,
      httpOptions
    );
  }
  UpdateSaturdayWaterProcessRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateSaturdayWaterProcessRecord',
      obj,
      httpOptions
    );
  }
  checkRecordExistSaturdayWaterprocess(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/checkRecordExistSaturdayWaterprocess',
      obj,
      httpOptions
    );
  }

  GetSaturdayWaterProcessByID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetSaturdayWaterProcessByID',
      obj,
      httpOptions
    );
  }

  DeleteSaturdayWaterProcessDailyRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteSaturdayWaterProcessDailyRecord',
      obj,
      httpOptions
    );
  }

  GetWaterProcessRepairsRecords(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterProcessRepairsRecords',
      SearchOptions,
      httpOptions
    );
  }

  CreateWatrerProcessRepairsRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateWatrerProcessRepairsRecord',
      obj,
      httpOptions
    );
  }

  UpdateWatrerProcessRepairsRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateWatrerProcessRepairsRecord',
      obj,
      httpOptions
    );
  }


  DeleteWatrerRepairsRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteWatrerRepairsRecord',
      obj,
      httpOptions
    );
  }

  GetWaterProcessRepairsRecordByID(RepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetWaterProcessRepairsRecordByID',
      RepairId,
      httpOptions
    );
  }

  GetRepairIdFromHomeserviceId(RepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetRepairIdFromHomeserviceId',
      RepairId,
      httpOptions
    );
  }


  GetEquipmentByCategoryDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetEquipmentByCategoryDD',
      value,
      httpOptions
    );
  }

  GetPartsDDByCategory(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetPartsDDByCategory',
      value,
      httpOptions
    );
  }


  GetRepairPartsDetailsbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetRepairPartsDetailsbyRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }

  GetnotesbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetnotesbyRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }
  GetattachmentDetailsbyRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetattachmentDetailsbyRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }

  GetHoursOutOfPreviousRepairDetail(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetHoursOutOfPreviousRepairDetail',
      obj,
      httpOptions
    );
  }

  GRWaterprocessDailyrecords(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GRWaterprocessDailyrecords',
      SearchOptions,
      httpOptions
    );
  }

  CreateGRWaterprocessDailyRecordsMain(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateGRWaterprocessDailyRecordsMain',
      obj,
      httpOptions
    );
  }

  UpdateGRWaterprocessDailyRecordsMain(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateGRWaterprocessDailyRecordsMain',
      obj,
      httpOptions
    );
  }

  DeleteGRWaterProcessDailyRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteGRWaterProcessDailyRecord',
      obj,
      httpOptions
    );
  }
  GRWaterprocessDailyrecordsID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GRWaterprocessDailyrecordsID',
      obj,
      httpOptions
    );
  }

  GetNotesByGRWaterprocessDailyrecordsID(id) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetNotesByGRWaterprocessDailyrecordsID',
      id,
      httpOptions
    );
  }
  GetattachemnetsGRWaterprocessDailyrecordsID(id) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetattachemnetsGRWaterprocessDailyrecordsID',
      id,
      httpOptions
    );
  }
  checkGRRecordExist(obj) {

    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CheckRecordExistGRDaily',
      obj,
      httpOptions
    );
  }

  GetGRWaterprocessDailyBankAlaramByGRWaterProcessailyRecordMain3EId(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetGRWaterprocessDailyBankAlaramByGRWaterProcessailyRecordMain3EId',
      obj,
      httpOptions
    );
  }

  GRWaterprocessGetWeeklyCompletedRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GRWaterprocessGetWeeklyCompletedRecord',
      obj,
      httpOptions
    );
  }

  CreateWaterProcessAnalysisRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateWaterProcessAnalysis',
      obj,
      httpOptions
    );
  }

  UpdateWaterProcessAnalysisRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateWaterProcessAnalysis',
      obj,
      httpOptions
    );
  }

  GetWaterProcessAnalysisByID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterProcessAnalysisRecordByID',
      obj,
      httpOptions
    );
  }

  GetWaterProcessAnalysisRecordsSearch(value) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterProcessAnalysisRecordsSearch',
      value,
      httpOptions
    );
  }

  DeleteWaterProcessAnalysisRecord(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteWaterProcessAnalysisRecord',
      obj,
      httpOptions
    );
  }

  GetWaterAnalsisNotes(id) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetWaterAnalsisNotes',
      id,
      httpOptions
    );
  }
  GetWaterAnalysisAttachments(id) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetWaterAnalysisAttachments',
      id,
      httpOptions
    );
  }

  GetNotesByGRWaterProcessDailySatelliteRecordID(id) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetNotesByGRWaterProcessDailySatelliteRecordID',
      id,
      httpOptions
    );
  }

  GetattachemnetsByyGRWaterProcessDailySatelliteRecordID(id) {
    return this.http.post<any>(
      this.apiUrl +
        'WPDailyService/GetattachemnetsByyGRWaterProcessDailySatelliteRecordID',
      id,
      httpOptions
    );
  }

  DeleteGRWaterProcessDailySatelliteRecord(id) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/DeleteGRWaterProcessDailySatelliteRecord',
      id,
      httpOptions
    );
  }

  CreateGRWaterProcessDailySatelliteDetail(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateGRWaterProcessDailySatelliteDetail',
      obj,
      httpOptions
    );
  }

  UpdateGRWaterProcessDailySatelliteDetail(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateGRWaterProcessDailySatelliteDetail',
      obj,
      httpOptions
    );
  }

  GetGRWaterProcessDailySatelliteDetailIdByID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetGRWaterProcessDailySatelliteDetailIdByID',
      obj,
      httpOptions
    );
  }

  FetchHX2Data() {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FetchHX2Data',
      '',
      httpOptions
    );
  }

  FetchHX2AlarmEventData(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FetchHX2AlarmEventData',
      obj,
      httpOptions
    );
  }

  FetchHX2DataLogs(obj) {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FetchHX2DataLogs',
      obj,
      httpOptions
    );
  }

  FetchHX2Disinfection(obj){
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FetchHX2Disinfection',
      obj,
      httpOptions
    );
  }

  FetchHX2DataH2ODaily() {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/FetchHX2DataH2ODaily',
      '',
      httpOptions
    );
  }

  SelectHX2Fields() {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/SelectHX2Fields',
      '',
      httpOptions
    );
  }

  fillHX2DisinfectionFields()  {
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/fillHX2DisinfectionFields',
      '',
      httpOptions
    );
  }

  GetHX2Notification(obj){
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/GetHX2Notification',
      obj,
      httpOptions
    );
  }

  CreateHX2Notification(obj){
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/CreateHX2Notification',
      obj,
      httpOptions
    );
  }

  UpdateHX2Notification(obj){
    return this.http.post<any>(
      this.apiUrl + 'WPDailyService/UpdateHX2Notification',
      obj,
      httpOptions
    );
  }
}

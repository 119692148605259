
<div class="box-header pageheader ">
    <div class="col-sm-12">
        
        <div class="col-sm-6 text-left"><h3>{{title}}</h3></div>
        <div class="col-sm-6 text-right">
                <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
            <button type="button" class="btn btn-danger"  style="margin-left: 10px" (click)="dialogRef.close(false)">Close Page</button>

        </div>
    </div>
</div>

  <div class="box-body">
     
        <div class="col-sm-12" style="margin-bottom:20px">
          <div class="col-sm-4">Machine <strong>{{MachineName}}</strong></div>
         
          <!-- <div class="col-sm-4">In Service Date <strong>{{InServiceDate | date:"dd/MM/yyyy"}}</strong></div> -->
        </div>
        
    <div >

      
    <table  style= "width:97%" class="col-sm-12 BlackBorderTable no-footer">
      <tbody>
            <ng-container *ngFor="let item of CalibrationsData;">
                    <ng-container *ngIf="SetCurrentType(item.type)">
                    <tr><td colspan="7"  class="sub_heading" style="text-align:left">
                      <h4><strong>{{item.type}}</strong></h4></td></tr>
                    <ng-container *ngIf="item.type=='Conductivity Calibration Information'">
                            <tr><td colspan="7" style="text-align:left">After calibration if the auto diagnostics tests fails, clean the glass tube internally and externally may resulted in correct calibration.<br/> The test signal must be calibrated to read 14.0 by adjusting P4 using debug or the SSW. <br/> - Do not adjust P1, P3.<br/> - If for some reason you were adjusting an incorrect trimmer or if the trimmer is completely out of range you can perform a rough calibration that will result in the formula passing the auto diagnostic test.<br/> - All measurements are from the lead closest to adjustment screw and POT wiper <br/> </td> </tr>
                    </ng-container>
                        <tr><th width="3%" align="center">Step</th>
                            <th width="16%" align="center">State</th>
                            <th width="7%" align="center">Pic</th>
                            <th width="36%" align="center">Action</th>
                            <th width="16%" align="center">Verify</th>
                            <th width="14%" align="center">Calibrate</th>
                            <th width="8%" align="center">Result</th></tr>
                            
                            </ng-container>
                            <tr>
                                    <td style="vertical-align:middle">
                                        {{item.step}}
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.state}}
                                        
                                    </td>
                                    <td  style="vertical-align:middle">
                                       
                                                {{item.pic}}
                                       
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.action}}
                                        
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.verify}}
                                        
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.calibrate}}
                                        
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                            <select [(ngModel)]="item.tempresult" [attr.disabled]="(item.result || completed) ? '' : null" >
            <option value="Not Tested">Not Tested</option>
            <option value="Pass">Pass</option>
            <option value="Fail, Adjusted">Fail, Adjusted</option>
            <option value="Fail, Replaced">Fail, Replaced</option>
        
        </select>
                                            
                                       
                                    </td>
                                </tr>
                                
            </ng-container>
         
      </tbody>
</table>

</div>
</div>
 
  <div class="modal-footer">
        <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
    <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close Page</button>
  </div>
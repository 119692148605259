import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../API/dmapi.service';
import { TableHeadsService } from '../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import { HomeFieldService } from '../API/homefield.service';
import { Page } from '../Core/Shared/Common/PageModel';
import { HomeService } from '../API/home.service';

@Component({
  selector: 'HomeFieldServiceSearch',
  templateUrl: './HomeFieldServiceSearch.component.html',

})
export class HomeFieldServiceSearchComponent {

  constructor(private dmapi: DMApiService,
    private homefieldApi: HomeFieldService,
    private homeApi: HomeService,
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];

  HFServiceStatuses = [
    { value: "", text: "Select" },
    { value: "Pending", text: "Pending" },
    { value: "In Progress", text: "In Progress" },
    { value: "OverDue", text: "OverDue" },
    { value: "Completed", text: "Completed" }
  ];

  visitPurpose = [
    { value: '', text: 'Select' },
    { value: 'Initial Site Visit', text: 'Initial Site Visit' },
    { value: 'Renovation', text: 'Renovation' },
    { value: 'Full Installation', text: 'Full Installation' },
    { value: 'Equipment Exchange', text: 'Equipment Exchange' },
    { value: 'Maintenance & Testing', text: 'Maintenance & Testing' },
    { value: 'Repair', text: 'Repair' },
    // { value: 'Testing', text: 'Testing' },
    { value: 'Other', text: 'Other' },
    { value: 'Uninstallation', text: 'Uninstallation' },
  ];
  dialysisStations = [];

  HFServiceStatus = "";
  HFServiceCode = "";
  HFServiceVisitPurpose = "";
  HFServiceStationName = "";
  FromDate;
  ToDate;
  tableHeaders;
  blnDirtySearch = false;
  page = new Page();
  rows;
  AddUrl = "/HomeFieldService/HomeFieldServiceDetail";

  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "DESC";
  pageSize = 25;
  ShowSearch = false;
  title = "Home Field Service";
  searchText = "Search Records"
  defaultSort;
  isPageLoaded = false;
  href = '';

  ngOnInit() {
    AppComponent.loading = true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#", "default", "4");
    this.dataGridService.addHeader("Event ID", "hfservicecode", "6");
    this.dataGridService.addHeader("Visit Date", "hfservicevisitdate", "8");
    this.dataGridService.addHeader("Station", "hfservicestationname", "10");
    this.dataGridService.addHeader("Visit Purpose", "hfservicevisitpurpose", "13");
    this.dataGridService.addHeader("Equipment Category", "hfservicecategory", "18");
    this.dataGridService.addHeader("Equipment Issue", "hfserviceissue", "22");
    this.dataGridService.addHeader("Status", "hfservicestatus", "13");
    this.dataGridService.addHeader("Action", "action", "8");

    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("default", true);
    this.executeHomeFieldServiceSearch();
    this.fillDialysisStations();
    this.CheckDirtySearch();
  }

  fillDialysisStations() {
    var dsSearch = <any>{};

    dsSearch.divisionName = "Home Program";
    dsSearch.currentPageNumber = 1;
    dsSearch.sortExpression = 'dialysisStationName';
    dsSearch.sortDirection = 'ASC';
    dsSearch.pageSize = 100;
    this.homeApi.GetDialysisStationSearchDetails(dsSearch).subscribe(pagedData => {
      this.dialysisStations = pagedData.dialysisStations;
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {

    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);

    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeHomeFieldServiceSearch();

  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeHomeFieldServiceSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var homefieldsearch = this.prepareSearch();
    this.homefieldApi.GetHomeFieldService_MasterSearchDetails(homefieldsearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.homeFieldService_MasterDetails;
      this.isPageLoaded = true;
      AppComponent.HideLoader();
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      });
  }

  prepareSearch() {

    var homeFieldSerach = <any>{};
    homeFieldSerach.hFServiceCode = this.HFServiceCode;
    if (this.FromDate != undefined && this.FromDate != "") {
      homeFieldSerach.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
    }
    if (this.ToDate != undefined && this.ToDate != "") {
      homeFieldSerach.todate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.HFServiceStatus) {
      homeFieldSerach.hFServiceStatus = this.HFServiceStatus;
    }

    if (this.HFServiceVisitPurpose) {
      homeFieldSerach.hFServiceVisitPurpose = this.HFServiceVisitPurpose;
    }

    if (this.HFServiceStationName) {
      homeFieldSerach.hFServiceStationName = this.HFServiceStationName;
    }

    homeFieldSerach.CurrentPageNumber = this.currentPageNumber;
    homeFieldSerach.SortExpression = this.sortExpression;
    homeFieldSerach.SortDirection = this.sortDirection;
    homeFieldSerach.PageSize = this.pageSize;
    return homeFieldSerach;

  }

  setPage() {
    if (this.currentPageNumber != this.page.pageNumber) {
      this.currentPageNumber = this.page.pageNumber;
      if (this.isPageLoaded == true) {
        this.executeHomeFieldServiceSearch();
      }
    }
  }

  _onRowClick(row,isHFSReferenced,hfServiceMasterCode) {
    //
    if(this.isPDFClicked){
      this.isPDFClicked = false;
      return;
    }

    if(isHFSReferenced != null){
      alert('This HFS is already In Progress by another Tech with HFS -' + hfServiceMasterCode + '.');
      return;
    }

    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
    // disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }


  executeHomeFieldServiceSearchClear() {

    this.HFServiceCode = "";
    this.HFServiceStationName = "";
    this.FromDate = "";
    this.ToDate = "";
    this.HFServiceStatus="";
    this.HFServiceVisitPurpose="";

    this.executeHomeFieldServiceSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.HFServiceCode
      || this.HFServiceStationName
      || this.HFServiceStatus
      || this.HFServiceVisitPurpose
      || (this.FromDate != null && this.FromDate != "")
      || (this.ToDate != null && this.ToDate != "")) {
      this.blnDirtySearch = true;

    }
    return this.blnDirtySearch;
  }

  isPDFClicked = false;


  OpenPDF(HFServiceID)
  {

    this.isPDFClicked = true;
    this.homefieldApi.PrintHomeFieldServicePDF({ Id: HFServiceID }).subscribe(list => {
      this.href = Globals.WorkOrderPFDsUrl + list;
      window.open(this.href,'_blank');
  }, error => {
    AppComponent.addAlert(error.message, 'error');
    AppComponent.HideLoader();
  });

    return;
  }

}


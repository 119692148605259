import { debug } from 'util';
import { Component, ViewChild } from '@angular/core';
import { AppComponent } from "../../app.component";
import { AuthService } from '../../API/auth.service';
import { InvApiService } from '../../API/invapi.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm, FormArray, UntypedFormBuilder } from '@angular/forms';
import { DMTransferService } from '../../API/dmtapi.service';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { map } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { DMApiService } from '../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { Globals, actionType, NoteModuleName, attachmentFormModules } from '../../globals';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  selector: 'DMTransferDetail',
  templateUrl: './DMTransferDetail.component.html'

})
export class DMTransferDetailComponent {

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;

  dialogRef: MatDialogRef<ConfirmationDialog>;


  ListURL = "/DMTransfer/DMTransferSearch";
  public message: string;
  currentUserId = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  formSubmitted: boolean = false;
  validateForm: boolean = false;
  PresentDivision = [];
  DialysisMachine = [];
  EquipmentOutList = [];
  CategoryList = [];
  transferedDivision = [];
  categoryInName = '';
  blnshowSave = true;
  blnshowDelete = false;
  onText = 'Transfer';
  offText = 'Exchange';
  formDate: any;
  transferDate: any;
  transferUser: string;
  divisionToName: string;
  divisionFromName: string;
  equipmentType: string;
  userDesignation = this.authentication.GetDesignationName();
  selectedEquipmentHours = 0;
  selectedExchEquipmentHours = 0;


  private today = new Date();

  TransferForm = new UntypedFormGroup({
    dmTransferDetailsId: new UntypedFormControl('0'),
    transferId: new UntypedFormControl(''),
    transferType: new UntypedFormControl('Transfer'),
    formDate: new UntypedFormControl(''),
    transferDate: new UntypedFormControl(''),
    equipmentId: new UntypedFormControl('', Validators.required),
    equipmentName: new UntypedFormControl(''),
    previousTransfer: new UntypedFormControl(''),
    divisionFrom: new UntypedFormControl(''),
    divisionTo: new UntypedFormControl('', Validators.required),
    disinfectionOut: new UntypedFormControl(''),
    hours: new UntypedFormControl(''),
    exchEquipmentId: new UntypedFormControl(''),
    exchEquipmentName: new UntypedFormControl(''),
    exchDivisionFrom: new UntypedFormControl(''),
    exchDivisionTo: new UntypedFormControl(''),
    exchHours: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    categoryId: new UntypedFormControl('', Validators.required),
  });

  disinfectionOptions = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' }
  ];

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder, private transferApi: DMTransferService, private route: ActivatedRoute,
    public dialog: MatDialog,
    private dmapi: DMApiService,
    private invapi: InvApiService,
    private router: Router,
    private commonapi: CommonApiService
    ) {

  }

  ngOnInit() {
    AppComponent.ShowLoader();
    this.notes.showHours = true;
    this.notes.Note = '';


    this.attachment.DocId = this.f.dmTransferDetailsId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.dmTransferDetails];
    this.attachment.UploadBy = this.currentUserId;

    this.fillCategories();
    this.fillPresentDivision();
    this.fillTransferDivision();
    this.fillDialysisMachine(0);
    this.fillEquipmentOutList(0);

    this.setValidators();


    this.authentication.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.setDateOptions();
      const test = this.route.queryParams.subscribe(params => {
        this.f.dmTransferDetailsId.setValue(params['id'] || '0');

        this.formDate = this.today;
        this.transferDate = this.today;
        this.transferUser = this.authentication.GetUserName();
        this.f.formDate.disable();
        this.f.transferDate.disable();
        // this.f.hours.disable();
        // this.f.exchHours.disable();
        //  this.f.transferType.disable();
        this.OnChangePriorDate();

        if (this.f.transferType.value == 'Exchange') {
          this.f.exchEquipmentId.setValidators([Validators.required]);
        }

        // this.f.categotyId.setValue('Select');
        if (this.f.dmTransferDetailsId.value !== '0') {
          this.setModel();
        }
      });

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });

  }

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (
      ((this.TransferForm.dirty ||
        this.notes.Note !== '') && (this.validateForm == false))
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else { return true; }
  }

  executeTransferCreateUpdate = function (Source = "") {
    AppComponent.loading = true;
    this.formSubmitted = true;
//
//     if (this.f.transferType.value == 'Exchange') {
//       if (this.f.exchHours.value=='')
//       {
//         this.f.exchHours.setErrors({ 'error': true });
//       }
//       else
//         this.f.exchHours.setErrors(null);
//     }

    if (this.TransferForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }

    this.validateForm = true;
    const modelCreate = this.prepareCreateViewModel();

    if (this.f.dmTransferDetailsId.value === '0'
    ) {
      this.transferApi.CreateDMTransferDetails(modelCreate).subscribe(
        success => {
          this.notes.Note = '';
          this.f.dmTransferDetailsId.value = success.dmTransferDetailsId;
          this.f.transferId.value = success.transferId;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.router.navigate(['/DMTransfer//DMTransferDetail'], { queryParams: { id: success.dmTransferDetailsId } });
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      AppComponent.HideLoader();
      this.transferApi.UpdateDMTransferDetails(modelCreate).subscribe(
        success => {
          this.notes.Note = '';
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.setModel();
            this.router.navigate(['/DMTransfer//DMTransferDetail'], { queryParams: { id: success.dmTransferDetailsId } });

          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  fillPresentDivision() {
    AppComponent.ShowLoader();

    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {

        // if(list.filter(x=>x.text == 'Home Program').length != 0)
        // {
        //   list.filter(x=> x.text == 'Home Program')[0].text = 'Home Pool';
        // }

      this.PresentDivision = list;

      AppComponent.HideLoader();
    });
  }

  fillDialysisMachine(divisionID) {
    AppComponent.ShowLoader();
    var CategoryName = '';
    var categorySelected = this.CategoryList.filter(
      x => x.value === this.f.categoryId.value.toString()
    );

    if (categorySelected.length > 0) {
      CategoryName = categorySelected[0].text;
    }

    const obj = {
      DivisionId: divisionID,
      CategoryName: CategoryName,
      Type: 'EQUIPMENT',

    };
    this.transferApi.FillEquipmentsByCategory(obj).subscribe(list => {
      this.DialysisMachine = list;
      AppComponent.HideLoader();
    });
  }

  fillEquipmentOutList(divisionID) {

    if (divisionID !== 0 && divisionID !== '') {
      var CategoryName = '';
      var categorySelected = this.CategoryList.filter(
        x => x.value === this.f.categoryId.value.toString()
      );

      if (categorySelected.length > 0) {
        CategoryName = categorySelected[0].text;
      }

      const obj = {
        DivisionId: divisionID,
        CategoryName: CategoryName,
        Type: 'EQUIPMENT',
      };
      this.transferApi.FillEquipmentsByCategory(obj).subscribe(list => this.EquipmentOutList = list);
    } else {
      this.EquipmentOutList = [{ value: '', text: 'Select' }];
      this.f.exchEquipmentId.setValue('');
    }
  }

  fillTransferDivision() {
    let divisions = [...this.PresentDivision];
    if (divisions.length <= 0) {
      divisions = [{ value: '', text: 'Select' }];
      this.f.divisionTo.setValue('');
    }

    if (this.f.divisionFrom.value && this.f.divisionFrom.value != '0') {
      this.transferedDivision = divisions.filter(
        x => x.value != this.f.divisionFrom.value && x.text != 'Home Program'
      );
    }
    else {
      this.transferedDivision = divisions;
    }
  }

  DialysisUnitFromDropDownChanged() {
    if(this.f.transferType.value == 'Exchange')
        this.f.equipmentId.setValue('');
    this.fillDialysisMachine(this.f.divisionFrom.value);
    this.fillTransferDivision();

    if(this.f.dmTransferDetailsId.value == 0)
    {
      this.selectedEquipmentHours = 0;
      this.f.equipmentId.setValue('');
      this.f.hours.setValue('');
    }
  }

  DialysisUnitToDropDownChanged() {
    this.fillEquipmentOutList(this.f.divisionTo.value);

    if(this.f.dmTransferDetailsId.value == 0)
    {
      if(this.f.transferType.value == 'Exchange')
      {
        this.selectedExchEquipmentHours=0;
        this.f.exchEquipmentId.setValue('');
        this.f.exchHours.setValue('');
      }
      if(this.f.transferType.value == 'Transfer')
      {
        this.selectedEquipmentHours = 0;
        this.f.equipmentId.setValue('');
        this.f.hours.setValue('');
      }
    }
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list.filter(
        x => x.text !== 'Other Equipment'
      );
    });
  }

  // onCategoryChange() {
  //   this.fillManufacturers();
  // }

  categoryInDropDownChanged() {
    this.DialysisUnitFromDropDownChanged();
    this.DialysisUnitToDropDownChanged();
  }

  prepareCreateViewModel() {
    const modelCreate = <any>{};


    modelCreate.dmTransferDetailsId = this.f.dmTransferDetailsId.value;
    modelCreate.transferId = this.f.transferId.value;
    modelCreate.transferType = this.f.transferType.value;
    modelCreate.formDate = Globals.GetAPIDate(this.formDate);

    if (this.f.transferDate.value !== null && this.f.transferDate.value !== undefined) {
      modelCreate.transferDate = Globals.GetAPIDate(this.f.transferDate.value.jsdate);
    }

    modelCreate.equipmentId = this.f.equipmentId.value;
    let equipmentSelected = this.DialysisMachine.filter(
      x => x.value == this.f.equipmentId.value
    );

    if (equipmentSelected.length > 0) {
      this.f.equipmentName.setValue(equipmentSelected[0].text);
      modelCreate.equipmentName = this.f.equipmentName.value;
    }

    modelCreate.exchEquipmentId = this.f.exchEquipmentId.value;
    let equipmentOutSelected = this.EquipmentOutList.filter(
      x => x.value == this.f.exchEquipmentId.value
    );

    if (equipmentOutSelected.length > 0) {
      this.f.exchEquipmentName.setValue(equipmentOutSelected[0].text);

      if (this.f.exchEquipmentName.value != 'Select') {
        modelCreate.exchEquipmentName = this.f.exchEquipmentName.value;
      }
    }

    modelCreate.previousTransfer = this.f.previousTransfer.value;

    if (this.f.transferType.value == 'Transfer') {
      modelCreate.previousTransfer = true;
    }

    modelCreate.divisionTo = this.f.divisionTo.value;
    modelCreate.divisionFrom = this.f.divisionFrom.value;
    modelCreate.disinfectionOut = this.f.disinfectionOut.value;
    modelCreate.hours = this.f.hours.value;
    modelCreate.createdOn = Globals.GetAPIDate(this.today);
    modelCreate.createdBy = this.currentUserId;
    modelCreate.modifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.modifiedBy = this.currentUserId;
    modelCreate.exchHours = this.f.exchHours.value;
    modelCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    modelCreate.note = this.notes.Note;
    return modelCreate;
  }

  get f() {
    return this.TransferForm.controls;
  }


  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  onEquipmentChanged() {
    this.f.hours.setValue('');
    var equipmentId = { Id: this.f.equipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {

        this.selectedEquipmentHours=result;
      }
      else
        this.selectedEquipmentHours=0;
    });
  }

  onEquipmentOutChanged() {
    this.f.exchHours.setValue('');
    var exchEquipmentId = { Id: this.f.exchEquipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(exchEquipmentId).subscribe(result => {
      if (result) {
        this.selectedExchEquipmentHours = result;
      }
      else
      this.selectedExchEquipmentHours = 0;
    });
  }

  setModel() {
    AppComponent.ShowLoader();
    this.transferApi.GetTransferByID({ Id: this.f.dmTransferDetailsId.value }).subscribe(
      success => {
        if (success) {

          this.attachment.clearQueue();

          setTimeout(() => {
            this.f.divisionFrom.setValue(success.divisionFrom);
            this.DialysisUnitFromDropDownChanged();
          }, 500);

          this.f.transferType.setValue(success.transferType);
          this.f.transferId.setValue(success.transferId);
          this.f.previousTransfer.setValue(success.previousTransfer);
          this.formDate = success.formDate;
          const tempDate = new Date(success.transferDate);
          this.f.transferDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.transferDate
          });
          this.f.divisionFrom.setValue(success.divisionFrom);
          this.f.divisionTo.setValue(success.divisionTo);
          this.f.equipmentName.setValue(success.equipmentName);
          this.f.hours.setValue(success.hours);
          this.f.disinfectionOut.setValue(success.disinfectionOut);
          this.f.equipmentId.setValue(success.equipmentId);
          this.divisionFromName = success.divisionFromName;
          this.divisionToName = success.divisionToName;
          this.f.exchHours.setValue(success.exchHours);
          this.f.exchEquipmentName.setValue(success.exchEquipmentName);
          this.categoryInName = success.categoryInName;
          this.f.categoryId.setValue(success.categoryId);
          this.transferUser = success.createdByName;
          this.fillNotes();
          this.fillAttachments();
          this.DisableControls();
          this.showDelete();
        }


        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );


    AppComponent.HideLoader();
  }


  showDelete(){
    if (this.userDesignation == 'Administrator' && this.f.dmTransferDetailsId.value != '0') {
      this.blnshowDelete = true;
    }
  }

  fillNotes() {
    this.transferApi.GetDmTransferNotes({ Id: this.f.dmTransferDetailsId.value }).subscribe(list => {
      this.notes.NoteList = list;
    });
  }

  fillAttachments() {
    this.transferApi
      .GetDmTransferAttachments({ Id: this.f.dmTransferDetailsId.value })
      .subscribe(list => {
        this.attachment.PrevAttachments = list;
      });

  }

  DeleteEvent(){
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const dmObj = { Id: this.f.dmTransferDetailsId.value };
        this.transferApi.DeleteTransferDetailsByID(dmObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'DM Transfer detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  onChange(e) {
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  OnChangePriorDate() {
    if (this.f.previousTransfer.value) {
      this.f.transferDate.enable();
    }
    else {
      const tempDate = this.today;
      this.f.transferDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
      this.f.transferDate.disable();
    }


  }

  DisableControls() {
    this.f.divisionFrom.disable();
    this.f.divisionTo.disable();
    this.f.previousTransfer.disable();
    this.f.equipmentId.disable();
    this.f.disinfectionOut.disable();
    // this.f.hours.disable();
    // this.f.exchHours.disable();
  }

  editMode() {
    return (this.f.dmTransferDetailsId && this.f.dmTransferDetailsId.value != '0');
  }

  get dateTransferDateText() {
    if (this.f.transferDate.value) {
      return Globals.GetAPIDate(this.f.transferDate.value.jsdate);
    }
  }

  onTransferTabChange() {
    if (this.f.transferType.value == 'Exchange') {
      this.f.divisionFrom.setValidators(Validators.required);
      this.f.exchEquipmentId.setValidators(Validators.required);
      this.f.exchHours.setValidators(Validators.required);
      this.fillDialysisMachine(-1);
    }
    else {
      this.f.exchEquipmentId.clearValidators();
      this.f.divisionFrom.clearValidators();
      this.fillDialysisMachine(0);
    }
    this.f.divisionFrom.updateValueAndValidity();
    this.f.exchEquipmentId.updateValueAndValidity();
    this.f.divisionFrom.setValue('');
    this.f.exchEquipmentId.setValue('');
    this.f.exchEquipmentName.setValue('');
    this.f.exchHours.setValue('');
    this.f.divisionTo.setValue('');
    this.f.equipmentId.setValue('');
    this.f.equipmentName.setValue('');

    this.fillTransferDivision();
    this.fillDialysisMachine(0);
    this.fillEquipmentOutList(0);
    this.setValidators();

    this.selectedEquipmentHours= 0;
    this.selectedExchEquipmentHours= 0;
  }

  setValidators() {

    this.f.hours.setValidators([Validators.required,Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

    this.f.hours.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.equipmentId,
        this.f.dmTransferDetailsId,
        this.dmapi
      )
    ]);

    if (this.f.transferType.value == 'Exchange') {
      this.f.exchHours.setValidators([Validators.required,Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

      this.f.exchHours.setAsyncValidators([
        DMRepairCustomValidators.TransferHoursOutOfPreviousTransfer(
          this.f.exchEquipmentId,
          this.f.dmTransferDetailsId,
          this.dmapi
        )
      ]);
    }
    else {
      this.f.exchHours.clearValidators();
    }

    // this.f.hours.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);
    // this.f.hours.setAsyncValidators([
    //   DMRepairCustomValidators.TransferHoursOutOfPreviousTransfer(
    //     this.f.equipmentId,
    //     this.transferApi
    //   )
    // ]);

    // this.f.hours.updateValueAndValidity();
    this.f.exchHours.updateValueAndValidity();
    this.f.hours.updateValueAndValidity();

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(IsExchangedEquipment): boolean {

    if(IsExchangedEquipment){
      if (this.f.exchHours.value - this.selectedExchEquipmentHours >= 4000) {
        return true;
      } else {
        return false;
      }
    }
    else
    {
      if (this.f.hours.value - this.selectedEquipmentHours >= 4000) {
        return true;
      } else {
        return false;
      }

    }
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { CanComponentDeactivate } from "../../Core/Shared/CanComponentDeactivate";
import { Observable } from "rxjs";
import { BacteriaComponent } from "src/app/Core/Shared/BacteriaComponent/Bacteria.component";

@Component({
  selector: "GRBacteriaDetail",
  templateUrl: "./GRBacteriaDetail.component.html",
  
})
export class GRBacteriaDetailComponent
  implements OnInit, CanComponentDeactivate {
  

    @ViewChild(BacteriaComponent, { static: true }) child;


    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      return this.child.canDeactivate();
    }
  
    constructor() { }
  
    ngOnInit() {
    }
  }

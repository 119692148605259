<section class="content-header">
    <h1>
        {{title}}
        <!-- <sup
            *ngIf="title!=''"
            class="beta"
        >
            [beta]
        </sup> -->

    </h1>

</section>
<section class="content">

    <section>
        <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader
                    [title]="title"
                    [showAddNew]="true"
                    [addurl]="AddUrl"
                    #pageheader
                    [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch"
                    (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"
                ></PageHeader>
                <div
                    id="divSearch row"
                    *ngIf="ShowSearch"
                >

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Event ID:</label>
                                            <div class="col-sm-6">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="EventId"
                                                >
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div> -->
                                    <!-- <div class="col-sm-6">
                                            <div>
                                                <label class="col-sm-6 control-label">Division:</label>
                                                <div class="col-sm-6">
                                                    <select
                                                        class="form-control"
                                                        [(ngModel)]="selectedDivision"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div> -->
                                    <div class="col-sm-6">

                                      <div class="form-group">
                                          <label class="col-sm-6 control-label">Lot No:</label>
                                          <div class="col-sm-6">
                                              <input
                                                  type="text"
                                                  class="form-control"
                                                  [(ngModel)]="LotNo"
                                              >
                                          </div>

                                      </div>
                                      <div class="clearfix"></div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div>
                                        <label class="col-sm-6 control-label">Silo:</label>
                                        <div class="col-sm-6">
                                            <select
                                                class="form-control"
                                                [(ngModel)]="selectedSilo"
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of Siloes"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input
                                                    (focus)="fromDate.toggleCalendar()"
                                                    (click)="fromDate.openCalendar()"
                                                    class="form-control"
                                                    style="float:none"
                                                    placeholder="Select a date"
                                                    angular-mydatepicker
                                                    name="FromDate"
                                                    [(ngModel)]="FromDate"
                                                    [options]="myOptions"
                                                    #fromDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)"
                                                >

                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-6">
                                        <div class>
                                            <label class="col-sm-6 control-label">Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input
                                                        (focus)="toDate.toggleCalendar()"
                                                        (click)="toDate.openCalendar()"
                                                        class="form-control"
                                                        style="float:none"
                                                        placeholder="Select a date"
                                                        angular-mydatepicker
                                                        name="ToDate"
                                                        [(ngModel)]="ToDate"
                                                        [options]="myOptions"
                                                        #toDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)"
                                                    >

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">


                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12"></div>
                            </div>
                        </div>

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button
                                        class="btn btn-primary"
                                        id="btnSearchDetail"
                                        (click)="executeAcidSearch()"
                                    >
                                        <span>
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <b>Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div
                    class="box-body"
                    id="dvboxbody"
                >
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination
                                        pageSize="25"
                                        [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber"
                                        (pageChange)="setPage()"
                                        [maxSize]="10"
                                        [directionLinks]="true"
                                    ></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <option value="{{item.value}}" *ngFor="let item of DialysisUnits">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a
                                            style="cursor:pointer"
                                            (click)="executeAcidSearchClear()"
                                        >
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table
                            id="OptionList"
                            class="table table-bordered table-striped dataTable no-footer"
                            role="grid"
                        >
                            <thead>

                                <tr>
                                    <th
                                        *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width"
                                        (click)="changeSorting(tableHeader.Label)"
                                    >
                                        {{tableHeader.Label}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr
                                    *ngFor="let AcidDetail of rows;let index=index;"
                                    (click)="_onRowClick(AcidDetail.centraldelievryaciddrumfillingid)"
                                    class="pointer"
                                >

                                    <td>
                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>{{AcidDetail.eventid}}</td>

                                    <td>
                                        {{AcidDetail.fillingdate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td>{{AcidDetail.divisionName}}</td>
                                    <td>{{AcidDetail.containerName}}</td>
                                    <td>{{AcidDetail.totalCost}}</td>
                                    <td>{{AcidDetail.technologistName}}</td>
                                    <td>{{AcidDetail.vendorName}}</td>

                                </tr>
                            </tbody>

                        </table>

                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination
                                        pageSize="25"
                                        [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber"
                                        (pageChange)="setPage()"
                                        [maxSize]="10"
                                        [directionLinks]="true"
                                    ></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a
                                            style="cursor:pointer"
                                            (click)="executeAcidSearchClear()"
                                        >
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { DMApiService } from '../API/dmapi.service';
import { WPApiService } from '../API/wpapi.service';

import { map } from 'rxjs/operators';
import { DMTransferService } from '../API/dmtapi.service';

export class DMPMCustomValidators  {
  static EquipmentPMStatusValidate(divisionId:AbstractControl,PMId:AbstractControl,dmapi:DMApiService){
    return (control: AbstractControl) => {
    var dmPMValidate = { equipmentId:control.value,divisionId:divisionId.value,Id:PMId.value};

        return dmapi.GetPMStatusByEquipmentId(dmPMValidate).pipe(map(res => {
          return res==true ? null : { PMStatus: true };
           }));
          };
  }

}


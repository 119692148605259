<form [formGroup]="dailyDetailForm" (ngSubmit)="executeNurseCheckCreateUpdate('')" #mainform novalidate>


    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
            <h1>
              Nurse Checks<sup  class="beta">[demo]</sup>

            </h1>

        </section>
    <section class="content">
        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeNurseCheckCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>

                <div class="col-md-12 box">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">NurseCheckDetailsId</label>

                                <div class="col-sm-10">
                                    <input type="text" readonly class="form-control" name="NurseCheckDetailsId" [value]="f.NurseCheckDetailsId" formControlName="NurseCheckDetailsId">
                                </div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="box-body">
                                <div class="col-sm-12 box-row ">

                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Event ID:</label>

                                    <div class="col-sm-10">
                                        <label class="readOnlyText"> {{eventId}}</label>
                                    </div>
                                </div>


                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Division:</label>
                                    <div class="col-sm-10" *ngIf="f.NurseCheckDetailsId.value > 0">
                                        <label class="readOnlyText">{{DUName}}</label>
                                    </div>
                                    <div class="col-sm-10" *ngIf="f.NurseCheckDetailsId.value == 0">


                                        <select class="form-control mdInput" formControlName="selectedDialysisUnits" name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                                          <!-- <option value="">Select</option> -->
                                          <option value="{{item.value}}" *ngFor="let item of DialysisUnits">{{item.text}}</option>
                                        </select>
                                        <span *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>

                                        <span *ngIf="f.selectedDialysisUnits.errors?.DivisionRecExists" [ngClass]="'error'">
                                            Saturday record already exist for today.
                                        </span>

                                    </div>

                                </div>
                                </div>
                                <div class="clearfix"></div>

                            </div>
                            <div class="box ibox box-info">
                                <div class="box-header with-border">
                                    <h3 class="box-title">AM</h3>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="box-row col-sm-12 divider">
                                            <div class="col-sm-12">
                                                <strong>Raw Water Filters
                                                </strong>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-6">

                                                <label class="col-sm-4 control-label">
                                                    <span class="text-red inputrequired">*</span>P (psi):</label>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.RawWaterFiltersP.value}}</label>
                                                </div>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value == 0">
                                                    <input  name="RawWaterFiltersP" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersP,50,90)}"
                                                        class="form-control smInput" formControlName="RawWaterFiltersP" (keyup)="GreaterValueCheck()"
                                                         required />

                                                    <span *ngIf="f.RawWaterFiltersP.errors?.required && (f.RawWaterFiltersP.dirty || f.RawWaterFiltersP.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.RawWaterFiltersP.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass]="'error'">
                                                      P should be higher than Q.
                                                  </span>
                                                  <span *ngIf="f.RawWaterFiltersP.errors?.OutOfRange" [ngClass]="'error'">
                                                      Enter value between 50-90.
                                                  </span>
                                                  <span *ngIf="f.RawWaterFiltersP.errors?.maxLengthVal" [ngClass]="'error'">
                                                     Max 10 digit allow.
                                                  </span>
                                                    <!-- <span  *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass] = "'error'">
                                                        P should be higher than Q.
                                                    </span> -->
                                                </div>


                                            </div>


                                            <div class="col-sm-6">
                                                <label class="col-sm-4 control-label">
                                                    <span class="text-red inputrequired">*</span>Q(psi):</label>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.RawWaterFiltersQ.value}}</label>
                                                </div>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value == 0">
                                                    <input  name="RawWaterFiltersQ" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersQ,50,90)}"
                                                        class="form-control smInput" formControlName="RawWaterFiltersQ" name="RawWaterFiltersQ"
                                                         required>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.required && (f.RawWaterFiltersQ.dirty || f.RawWaterFiltersQ.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.OutOfRange" [ngClass]="'error'">
                                                        Enter value between 50-90.
                                                    </span>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="box-row col-sm-12  divider">
                                            <div class="col-sm-6">
                                                <strong>Thornton Meter
                                                </strong>
                                            </div>


                                            <div class="col-sm-6">
                                                <strong>DPD Test Post-Carbon
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                          <div class="col-sm-6">
                                            <label class="col-sm-4 control-label">
                                                <span class="text-red inputrequired">*</span>Morning (µS/cm):</label>
                                            <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.ThorntonMeterMorning.value}}</label>
                                            </div>
                                            <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value == 0">
                                                <input  [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterMorning,0.01,8.5)}" name="ThorntonMeterMorning"
                                                    maxlength="8" class="form-control smInput" formControlName="ThorntonMeterMorning"
                                                    name="ThorntonMeterMorning" required>
                                                <span *ngIf="f.ThorntonMeterMorning.errors?.required && (f.ThorntonMeterMorning.dirty || f.ThorntonMeterMorning.touched || isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf="f.ThorntonMeterMorning.errors?.pattern" [ngClass]="'error'"> Not valid number!</span>
                                                <span *ngIf="f.ThorntonMeterMorning.errors?.OutOfRange" [ngClass]="'error'">
                                                    Enter value between 0.010-8.500.
                                                </span>
                                            </div>
                                        </div>



                                            <div class="col-sm-6">
                                                <label class="col-sm-4 control-label">
                                                    <span class="text-red inputrequired">*</span>Total Chlorine (mg/L):</label>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.DPDTestPostTotalChlorine.value}}</label>
                                                </div>
                                                <div class="col-sm-8" *ngIf="f.NurseCheckDetailsId.value == 0">
                                                    <input  [ngClass]="{'OurOffRange':checkRange(f.DPDTestPostTotalChlorine,0.01,0.08)}" name="DPDTestPostTotalChlorine"
                                                        maxlength="10" class="form-control smInput" formControlName="DPDTestPostTotalChlorine"
                                                        name="DPDTestPostTotalChlorine" required>
                                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.required && (f.DPDTestPostTotalChlorine.dirty || f.DPDTestPostTotalChlorine.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.OutOfRange" [ngClass]="'error'">
                                                        Enter value between 0.01-0.08.
                                                    </span>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">

                                            <div class="col-sm-4">

                                            </div>
                                            <div class="col-sm-4">

                                            </div>
                                        </div>
                                        <div class="box-row col-sm-12">

                                          <div class="col-sm-6">
                                              <label class="control-label" style="color: #0484a4">User Name:</label>
                                              &nbsp;&nbsp;
                                              <label class="control-label">{{FirstShiftUserName}}</label>
                                          </div>
                                          <div class="col-sm-6">
                                              <label class="control-label" style="color: #0484a4">Time:</label>
                                              &nbsp;&nbsp;
                                              <label class="control-label">{{FirstShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                                          </div>

                                      </div>
                                      <div class="clearfix"></div>

                            </div>
                        </div>
                            </div>
                    <div class="box ibox box-info">
                        <div class="box-header with-border">
                            <h3 class="box-title">PM</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                              <div class="box-row col-sm-12  divider">
                                <div class="col-sm-6">
                                    <strong>Thornton Meter
                                    </strong>
                                </div>


                                <div class="col-sm-6">
                                    <strong>DPD Test Post-Carbon
                                    </strong>
                                </div>
                            </div>
                                <div class="clearfix"></div>


                                <div class="box-row col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-4 control-label">
                                            <span class="text-red inputrequired">*</span>Afternoon (µS/cm):</label>
                                        <div class="col-sm-8" *ngIf="!CheckSecondShiftCompleted()">
                                            <label class="readOnlyText"> {{f.ThorntonMeterAfternoonTextBox.value}}</label>
                                        </div>
                                        <div class="col-sm-8" *ngIf="CheckSecondShiftCompleted()">
                                            <input  name="ThorntonMeterAfternoonTextBox" maxlength="10" class="form-control smInput" (keyup)="GreaterValueCheck()"
                                                [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterAfternoonTextBox,0.01,8.5)}"
                                                formControlName="ThorntonMeterAfternoonTextBox" name="ThorntonMeterAfternoonTextBox">
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.required && (f.ThorntonMeterAfternoonTextBox.dirty || f.ThorntonMeterAfternoonTextBox.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.IsRequiredCtl" [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.OutOfRange" [ngClass]="'error'">
                                                Enter value between 0.010-8.501.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="col-sm-4 control-label">
                                            Total Chlorine (mg/L):</label>
                                        <div class="col-sm-8" *ngIf="!CheckSecondShiftCompleted()">
                                            <label class="readOnlyText"> {{f.SecondShiftTotalChlorineTextBox.value}}</label>
                                        </div>
                                        <div class="col-sm-8" *ngIf="CheckSecondShiftCompleted()">
                                            <input  name="SecondShiftTotalChlorineTextBox" maxlength="10" (keyup)="GreaterValueCheck()" [ngClass]="{'OurOffRange':checkRange(f.SecondShiftTotalChlorineTextBox,0.01,0.08)}"
                                                class="form-control smInput" formControlName="SecondShiftTotalChlorineTextBox"
                                                name="SecondShiftTotalChlorineTextBox">
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.required && (f.SecondShiftTotalChlorineTextBox.dirty || f.SecondShiftTotalChlorineTextBox.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.IsRequiredCtl" [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.OutOfRange" [ngClass]="'error'">
                                                Enter value between 0.01-0.08.
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>



                                <div class="box-row col-sm-12">

                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="control-label" style="color: #0484a4">User Name:</label>
                                        &nbsp;&nbsp;
                                        <label class="control-label">{{SecondShiftUserName}}</label>

                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label" style="color: #0484a4">Time:</label>
                                        &nbsp;&nbsp;
                                        <label class="control-label">{{SecondShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>


                    <notes  [isValidForm] ="this.dailyDetailForm.invalid" (CheckValidForm)="GreaterValueCheck()"  (addNoteClick)="executeNurseCheckCreateUpdate('Notes')" #notes>

                    </notes>
                    <attachment (RaiseError)="AttachmentError($event)" #attachment>

                    </attachment>

            </div>

        <div class="clearfix"></div>
        <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
            [showSave]="blnshowSave" (SaveEvent)="executeNurseCheckCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
            #pageheader1></PageHeader>

        </div>
        </div>

    </section>

</form>

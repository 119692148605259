
        <div class="">
            <input type="file"  class="FileInput hidden" id="ng2Fileuploader" ng2FileSelect [uploader]="uploader"   />

            <button (click)="UploadFiles()" *ngIf="isUploadDisabled" disabled class="btn btn-success pull-left" type="button">Upload</button>
            <button (click)="UploadFiles()" *ngIf="!isUploadDisabled"  class="btn btn-success pull-left" type="button">Upload</button>

            <p  class="pull-left" style="padding-left: 10px;font-size: 13px">
                <small style="font-size:75%">The maximum file size for uploads <strong><i>1 MB</i></strong> Max.<br/>
                Only files (<strong><i>JPG, GIF, PNG, JPEG</i></strong>) are allowed.</small>
            </p>
            <div class="clear-fix"></div>
            </div>
        <div class="" *ngIf="ImagePath != ''">
            <table class="table">

                <tbody>
                        <tr>

                                <td  style="text-align:left"><img src="{{SetAttachmentUrl(ImagePath)}}" style="width: 100%;height:auto" />
                                  </td>
                                <!-- <td  class="text-left" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td> -->

                                <td nowrap>

                                    <button type="button" class="btn btn-danger btn-xs"
                                            (click)="reset()">
                                        <span class="glyphicon glyphicon-trash"></span> Remove
                                    </button>
                                </td>
                            </tr>
                </tbody>
            </table>
        </div>

<form>
  <section class="content-header">
    <h1>
H2O Daily
    </h1>
  </section>
  <section class="content">
    <div class="row">
      <div class="box  box-primary">
        <div class="col-sm-12 box-header">
        <div class="col-sm-offset-3 col-sm-9 text-center">
          <i class="fa fa-exclamation-triangle" style="font-size:300px;color: brown" aria-hidden="true"></i>
          <h1 style="color: brown">Daily Page is not Defined</h1>
        </div>
        </div>
      </div>
    </div>
  </section>
</form>

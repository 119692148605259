import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
// import { RenderDebugInfo } from '@angular/core/src/render/api';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'IncidentSearch',
  templateUrl: './IncidentSearch.component.html'
})
export class IncidentSearchComponent {
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  Equipments = [];
  Users = [];
  Stations = [];
  Patients = [];
  IncidentDetail = [];
  selectedDivision = 'All Divisions';
  selectedEquipment = '';
  selectedStation = '';
  //selectedPatient = '';
  selectedUser = '';
  EventId = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = '/DialysisMachine/IncidentDetail';
  workOrderRefNo = '';
  currentPageNumber = 1;
  sortExpression = 'incidentid';
  sortDirection = 'ASC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Dialysis Machine Incident ';
  searchText = 'Search Records';
  defaultSort;

  ngOnInit() {

    this.auth.GetCurrentUserDivision().subscribe(division => {

//      console.log("Division : " + division);
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'EventId', '4');
      this.dataGridService.addHeader('Event ID', 'incidentid', '10');
      this.dataGridService.addHeader('Incident Date', 'incidentdate', '19');
      this.dataGridService.addHeader('Form User', 'createdByName', '18');
      this.dataGridService.addHeader('Equipment', 'equipmentName', '18');
      this.dataGridService.addHeader('Station', 'stationName', '18');
      this.dataGridService.addHeader('Division', 'divisionName', '18');
      this.sortDirection = 'DESC';
      this.sortExpression = 'incidentdate';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Date', true);

      if(division.length===1){
        this.fillDialysisUnit(division[0]);
        this.fillEquipment(division[0]);
        this.fillDialysisStation(division[0]);
      }
      else{
        this.fillDialysisUnit();
        this.fillEquipment();
        this.fillDialysisStation(0);
      }
      this.fillUser();
      this.fillPatients();
      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeIncidentSearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeIncidentSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const IncidentSearch = this.prepareSearch();
    this.dmapi.GetIncidentDMRepairDetails(IncidentSearch).subscribe(
      pagedData => {
        //  console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.incidentDMRepailDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    let IncidentSearch = <any>{};

    IncidentSearch.incidentid = this.EventId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      IncidentSearch.fromDate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      IncidentSearch.toDate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedEquipment !== undefined && this.selectedEquipment !== '') {
      IncidentSearch.equipmentid = this.selectedEquipment;
    }

    if (this.selectedStation !== undefined && this.selectedStation !== '') {
      IncidentSearch.stationid = this.selectedStation;
    }

    if (this.selectedUser !== undefined && this.selectedUser !== '') {
      IncidentSearch.createdBy = this.selectedUser;
    }

    // if (this.selectedPatient !== undefined && this.selectedPatient !== '') {
    //   IncidentSearch.contactid = this.selectedPatient;
    // }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      IncidentSearch.division = this.selectedDivision;
    }

    IncidentSearch.currentPageNumber = this.currentPageNumber;
    IncidentSearch.sortExpression = this.sortExpression;
    IncidentSearch.sortDirection = this.sortDirection;
    IncidentSearch.pageSize = this.pageSize;
  //   console.log(IncidentSearch);
    return IncidentSearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeIncidentSearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue='') {
    // this.dmapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => (this.DialysisUnits = list));

    this.commonapi.FillDivision(true).subscribe(list => {

      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        // this.DialysisUnits = list.filter(
        //   x => x.text != "Home Program"
        // );
        this.DialysisUnits = list;
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          this.executeIncidentSearch();
        }
        else
          this.executeIncidentSearch();
      }
      else
          this.executeIncidentSearch();
    });


  }

  fillEquipment(divisionId=0) {
    this.dmapi.GetEquipmentDD(divisionId).subscribe(list => (this.Equipments = list));
  }

  fillUser() {
    this.dmapi.GetUsersDD().subscribe(list => (this.Users = list));
  }

  fillPatients() {
    this.dmapi.GetPatientsDD().subscribe(list => (this.Patients = list));
  }

  fillDialysisStation(divisionId) {

    if (divisionId !== 0) {
    this.dmapi
      .GetDialyisStationDD(divisionId)
      .subscribe(list => (this.Stations = list));
    }
    else {
      // this.dmapi
      // .GetDialyisStationDD(divisionId)
      // .subscribe(list => (this.Stations = list));
      this.Stations = [{ value: '', text: 'Select' }];
    }
  }

  executeIncidentSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.selectedEquipment = '';
    this.selectedStation = '';
    //this.selectedPatient = '';
    this.selectedUser = '';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';
    // this.executeIncidentSearch();
    this.fillDialysisUnit();
    this.fillEquipment();
    this.fillDialysisStation(0);
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision && this.selectedDivision != 'All Divisions') ||
      this.selectedEquipment ||
      this.selectedStation ||
      //this.selectedPatient !== '' ||
      this.selectedUser ||
      this.EventId ||
      this.FromDate ||
      this.ToDate
    ) {
      this.blnDirtySearch = true;
    }
    // console.log(this.blnDirtySearch);
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.selectedEquipment ="";
    var defaultVal = this.DialysisUnits.filter(
      x => x.text === this.selectedDivision
    );

    if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
      this.fillEquipment(defaultVal[0].value);
      this.fillDialysisStation(defaultVal[0].value);
    }
    else{
      this.fillEquipment();
      this.fillDialysisStation(0);
    }
    setTimeout(()=>this.executeIncidentSearch());
  }

  CheckFormDate(fdate, edate) {
    return this.commonapi.CompareDates(fdate, edate);
  }
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<form [formGroup]="PyrogenForm" (ngSubmit)="executePyrogenCreateUpdate('')" novalidate *ngIf="FullScreen">


    <section class="content-header">
        <h1>
            Pyrogen Form Details
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">

            <div class="box  box-primary">

                <PageHeader title="DM Repair" [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executePyrogenCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                    #pageheader></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">PyrogenDetailsId</label>

                                <div class="col-sm-10">
                                    <input type="text" readonly class="form-control" name="PyrogenDetailsId" [value]="f.PyrogenDetailsId" formControlName="PyrogenDetailsId">
                                </div>
                            </div>


                            <div class="box ibox box-info">

                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">

                                                    Event ID:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{EventCode}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Date:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                    </label>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Created By:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{CreatedByName}}</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="box ibox box-info hidden">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Pyrogen Form Details</h3>

                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Form Date:</label>
                                                <div class="col-sm-5" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{f.CollectionDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <input (focus)="rCollectionDate.toggleCalendar()" (click)="rCollectionDate.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionDate" formControlName="CollectionDate" [options]="myOptions"
                                                        #rCollectionDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                                    <span *ngIf="f.CollectionDate.errors?.required && (f.CollectionDate.dirty || f.CollectionDate.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Form Time:</label>
                                                <div class="col-sm-4" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{f.CollectionTime.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <input class="form-control mdInput" name="CollectionTime" formControlName="CollectionTime" />
                                                    <span *ngIf="f.CollectionTime.errors?.required && (f.CollectionTime.dirty || f.CollectionTime.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionTime.errors?.pattern" [ngClass]="'error'">Invalid time</span>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Division Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Division:</label>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <select class="form-control mdInput" formControlName="selectedDialysisUnits" name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnitsWithoutHome">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Lab Facility:</label>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{f.TestingLab.value}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <select class="form-control mdInput" formControlName="TestingLab" name="TestingLab" (change)="setTestingLab()">
                                                        <option value="{{item.value}}" *ngFor="let item of TestingLabs">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span *ngIf="f.TestingLab.errors?.required && (f.TestingLab.dirty || f.TestingLab.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Test Method:</label>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{TestMethodLabel}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <select class="form-control mdInput" formControlName="TestMethod" name="TestMethod">
                                                      <option value="{{item.value}}" *ngFor="let item of TestMethods">
                                                          {{item.text}}
                                                      </option>
                                                  </select>

                                                    <span *ngIf="f.TestMethod.errors?.required && (f.TestMethod.dirty || f.TestMethod.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Collection Detail</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Collection Date:</label>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{f.CollectionDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <input (focus)="rCollectionDate.toggleCalendar()" (click)="rCollectionDate.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionDate" formControlName="CollectionDate" [options]="myOptions"
                                                        #rCollectionDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                                    <span *ngIf="f.CollectionDate.errors?.required && (f.CollectionDate.dirty || f.CollectionDate.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Collection Time:</label>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value > 0">
                                                    <label class="readOnlyText">{{f.CollectionTime.value}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.PyrogenDetailsId.value == 0">
                                                    <input class="form-control mdInput" name="CollectionTime" formControlName="CollectionTime" />
                                                    <span *ngIf="f.CollectionTime.errors?.required && (f.CollectionTime.dirty || f.CollectionTime.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                                    <span *ngIf="f.CollectionTime.errors?.pattern" [ngClass]="'error'">Invalid time</span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Collection User:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText" *ngIf="f.PyrogenDetailsId.value > 0">{{CollectionByName}}</label>
                                                    <select *ngIf="f.PyrogenDetailsId.value == 0" class="form-control mdInput" formControlName="CollectionById" name="CollectionById">
                                                      <option value="{{item.value}}" *ngFor="let item of Users">
                                                          {{item.text}}
                                                      </option>
                                                  </select>
                                                    <span *ngIf="f.CollectionById.errors?.required && (f.CollectionById.dirty || f.CollectionById.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Tested Devices</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row" *ngIf="stage == 0">
                                            <div class="col-sm-12">
                                                <label class="col-sm-1 control-label">Populate:</label>


                                                <div class="col-sm-11">

                                                    <select class="form-control mdInput" style="margin-left: 15px" formControlName="selectedPopulatetype" (change)="selectedPopulatetypeChanged()" name="selectedPopulatetype">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of Populatetypess">
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                                    <select style="margin-left: 15px;max-width: 15%;" class="form-control mdInput" *ngIf="f.selectedPopulatetype.value == 'Dialysis Machines'" formControlName="selectedDMEquipment" name="selectedDMEquipment" (change)="onEquipmentChanged($event)">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of DMEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <select style="margin-left: 15px;" class="form-control mdInput" *ngIf="f.selectedPopulatetype.value == 'Water Process'" name="selectedWPEquipment" (change)="onEquipmentChanged($event)" formControlName="selectedWPEquipment">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of WPEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <select style="margin-left: 15px" class="form-control mdInput" name="selectedCDEquipment" formControlName="selectedCDEquipment" (change)="onEquipmentChanged($event)" *ngIf="f.selectedPopulatetype.value == 'Central Delivery'">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of CDEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <input style="margin-left: 15px" *ngIf="(f.selectedPopulatetype.value == 'Other')" class="form-control mdInput" placeholder="Equipment Name" name="OtherEquipment" formControlName="OtherEquipment" maxlength="30" />

                                                    <input style="margin-left: 15px" *ngIf="(f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process' || f.selectedPopulatetype.value == 'Central Delivery')  && hrsTracking" class="form-control smInput" placeholder="Hours"
                                                        name="TestHours" formControlName="TestHours" maxlength="5" (keypress)="numberOnly($event)" />



                                                    <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null && (f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process' || f.selectedPopulatetype.value == 'Central Delivery')  && hrsTracking">({{selectedEquipmentHours}})</label>
                                                    <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && (f.selectedDMEquipment.value || f.selectedWPEquipment.value || f.selectedCDEquipment.value) && (f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process' || f.selectedPopulatetype.value == 'Central Delivery') && hrsTracking">(N/A)</label>

                                                    <button style="display: inline-block;margin-left: 15px" (click)="AddTest()" class="btn btn-primary  no-print btn-label-left center-block " type="button">
                                                        <span><i class="fa fa-plus"></i></span>
                                                    </button>
                                                    <span style="margin-left: 20px" [ngClass]="'error'">
                                                        {{errorAddTest}}
                                                    </span>

                                                    <span *ngIf="check4000HoursValidation() && hrsTracking" [ngClass]="'error'">
                                                        New Hours >= 4000 from previous Hours Out.
                                                    </span>
                                                    <!-- <span *ngIf="DMRepairStatus" [ngClass]="'error'">
                                                        You cannot add device, while there is an open Repair still existing in the system.
                                                    </span>
                                                    <span *ngIf="RepairStatus" [ngClass]="'error'">
                                                        You cannot add device, while there is an open Repair still existing in the system.
                                                    </span> -->
                                                </div>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div *ngIf="DMTestList.length > 0 || WPTestList.length > 0 || CDTestList.length > 0 || OTHTestList.length > 0" class="box-row col-sm-12">
                                            <div class="col-sm-12" *ngIf="f.TestMethod.value == 'PTS'">

                                                <label class="col-sm-3"> </label>
                                                <!-- <label class="col-sm-6" [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20') ? 'red' : 'black'"
                                                     *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'"> {{equip.sampleResult}}</label> -->
                                                <label class="col-sm-2 text-center"> Result</label>
                                                <!-- <label class="col-sm-3 text-center" > Pos. Control</label> -->
                                            </div>

                                            <div class="col-sm-12" *ngIf="f.TestMethod.value == 'LAL'">

                                                <label class="col-sm-3"> </label>
                                                <!-- <label class="col-sm-6" [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20') ? 'red' : 'black'"
                                                   *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'"> {{equip.sampleResult}}</label> -->
                                                <div class="col-sm-9">
                                                    <div class="col-sm-2 text-center">
                                                        <label>Result</label>
                                                    </div>
                                                    <div class="col-sm-2 text-center">
                                                        <label>Pos. Control</label>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <div *ngIf="DMTestList.length > 0" class="box-row col-sm-12 divider">
                                        <strong>Dialysis Machine</strong>
                                    </div>
                                    <div *ngIf="DMTestList.length > 0" class="clearfix"></div>
                                    <div *ngFor="let equip of DMTestList;" class="box-row col-sm-12">
                                        <div class="col-sm-12">

                                            <label class="col-sm-3"> {{equip.equipmentName}} <span
                                                    *ngIf="equip.dialysisMachineHours">[{{equip.dialysisMachineHours}}]</span></label>
                                            <label class="col-sm-2 text-center" [style.color]="GetColorLabel(equip.sampleResult)" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'PTS'">
                                                {{equip.sampleResult}}</label>

                                            <div class="col-sm-9  text-center" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'LAL'">

                                                <label class="col-sm-2  text-center" [style.color]="GetColorLabel(equip.sampleResult)">
                                                    {{equip.sampleResult}}</label>

                                                <label class="col-sm-2  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                                                    {{equip.postControlResult}}</label>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'PTS' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-right">
                                                    <input class="form-control smInput" *ngIf="f.TestMethod.value == 'PTS'" (change)="UpdateSampleValue($event)" (keypress)="numberOnly($event)" [style.color]="GetColor('testresult_dm_' + equip.pyrogenMachaineId,'result')" name="{{'testresult_dm_' + equip.pyrogenMachaineId}}"
                                                        [value]="equip.sampleResult" />
                                                </div>
                                                <div class="col-sm-10 text-left">
                                                    <label [hidden]="!(CheckSampleValidValue('testresult_dm_' + equip.pyrogenMachaineId))" id="{{'lbltestresult_dm_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;margin-top: 7px !important;">Invalid
                                                        Value</label>
                                                    <label [hidden]="!CheckValidValue('testresult_dm_' + equip.pyrogenMachaineId)" id="{{'lbltestresult_dm_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;margin-top: 7px !important;">Result
                                                        is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'LAL' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdateSampleValue($event)" [style.color]="GetColor('testresult_dm_' + equip.pyrogenMachaineId, 'result')" name="{{'testresult_dm_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="item.value == '(+)' ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList"
                                                            [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdatePosControlSampleValue($event)" [style.color]="GetColor('testposcontrol_dm_' + equip.pyrogenMachaineId, 'pos')" name="{{'testposcontrol_dm_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == '(-)') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="box-row col-sm-12 divider" *ngIf="WPTestList.length > 0">
                                        <strong>Water Process</strong>
                                    </div>

                                    <div class="clearfix" *ngIf="WPTestList.length > 0"></div>
                                    <div *ngFor="let equip of WPTestList;" class="box-row col-sm-12">
                                        <div class="col-sm-12">

                                            <label class="col-sm-3"> {{equip.equipmentName}} <span
                                                    *ngIf="equip.dialysisMachineHours">[{{equip.dialysisMachineHours}}]</span></label>
                                            <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'PTS'">
                                                {{equip.sampleResult}}</label>

                                            <div class="col-sm-9 text-center" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'LAL'">
                                                <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )">
                                                    {{equip.sampleResult}}</label>

                                                <label class="col-sm-2  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                                                    {{equip.postControlResult}}</label>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'PTS' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-right">
                                                    <input class="form-control smInput" *ngIf="f.TestMethod.value == 'PTS'" (change)="UpdateSampleValue($event)" (keypress)="numberOnly($event)" name="{{'testresult_wp_' + equip.pyrogenMachaineId}}" [style.color]="GetColor('testresult_wp_' + equip.pyrogenMachaineId, 'result')"
                                                        [value]="equip.sampleResult">
                                                </div>
                                                <div class="col-sm-10 text-left">
                                                    <label [hidden]="!(CheckSampleValidValue('testresult_wp_' + equip.pyrogenMachaineId))" id="{{'testresult_wp_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Invalid
                                                        Value</label>
                                                    <label [hidden]="!CheckValidValue('testresult_wp_' + equip.pyrogenMachaineId)" id="{{'lbltestresult_wp_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Result
                                                        is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'LAL' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdateSampleValue($event)" [style.color]="GetColor('testresult_wp_' + equip.pyrogenMachaineId, 'result')" name="{{'testresult_wp_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="item.value == '(+)' ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList"
                                                            [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdatePosControlSampleValue($event)" [style.color]="GetColor('testposcontrol_wp_' + equip.pyrogenMachaineId, 'pos')" name="{{'testposcontrol_wp_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == '(-)') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="box-row col-sm-12 divider" *ngIf="CDTestList.length > 0">
                                        <strong>Central Delivery</strong>
                                    </div>
                                    <div class="clearfix" *ngIf="CDTestList.length > 0"></div>
                                    <div *ngFor="let equip of CDTestList;" class="box-row col-sm-12">
                                        <div class="col-sm-12">

                                            <label class="col-sm-3"> {{equip.equipmentName}} <span
                                                    *ngIf="equip.dialysisMachineHours">[{{equip.dialysisMachineHours}}]</span></label>
                                            <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'PTS'">
                                                {{equip.sampleResult}}</label>

                                            <div class="col-sm-9 text-center" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'LAL'">
                                                <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )">
                                                    {{equip.sampleResult}}</label>

                                                <label class="col-sm-2  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                                                    {{equip.postControlResult}}</label>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'PTS' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-right">
                                                    <input class="form-control smInput" (change)="UpdateSampleValue($event)" (keypress)="numberOnly($event)" name="{{'testresult_cd_' + equip.pyrogenMachaineId}}" [style.color]="GetColor('testresult_cd_' + equip.pyrogenMachaineId, 'result')" [value]="equip.sampleResult">
                                                </div>
                                                <div class="col-sm-10 text-left">
                                                    <label [hidden]="!(CheckSampleValidValue('testresult_cd_' + equip.pyrogenMachaineId))" id="{{'testresult_cd_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Invalid
                                                        Value</label>
                                                    <label [hidden]="!CheckValidValue('testresult_cd_' + equip.pyrogenMachaineId)" id="{{'lbltestresult_cd_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Result
                                                        is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'LAL' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdateSampleValue($event)" [style.color]="GetColor('testresult_cd_' + equip.pyrogenMachaineId, 'result')" name="{{'testresult_cd_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="item.value == '(+)' ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList"
                                                            [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdatePosControlSampleValue($event)" [style.color]="GetColor('testposcontrol_cd_' + equip.pyrogenMachaineId, 'pos')" name="{{'testposcontrol_cd_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == '(-)') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="clearfix"></div>
                                    <div class="box-row col-sm-12 divider" *ngIf="OTHTestList.length > 0">
                                        <strong>Other</strong>
                                    </div>
                                    <div class="clearfix" *ngIf="OTHTestList.length > 0"></div>
                                    <div *ngFor="let equip of OTHTestList;" class="box-row col-sm-12">
                                        <div class="col-sm-12">

                                            <label class="col-sm-3"> {{equip.equipmentName}}</label>
                                            <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'PTS'">
                                                {{equip.sampleResult}}</label>

                                            <div class="col-sm-9" *ngIf="(f.PyrogenDetailsId.value == 0 || status=='Completed') && f.TestMethod.value == 'LAL'">
                                                <label class="col-sm-2  text-center" [style.color]="GetColorLabel( equip.sampleResult )">
                                                    {{equip.sampleResult}}</label>

                                                <label class="col-sm-2  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                                                    {{equip.postControlResult}}</label>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'PTS' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-right">
                                                    <input class="form-control smInput" *ngIf="f.TestMethod.value == 'PTS'" (change)="UpdateSampleValue($event)" (keypress)="numberOnly($event)" name="{{'testresult_oth_' + equip.pyrogenMachaineId}}" [style.color]="GetColor('testresult_oth_' + equip.pyrogenMachaineId, 'result')"
                                                        [value]="equip.sampleResult">
                                                </div>
                                                <div class="col-sm-10 text-left">
                                                    <label [hidden]="!(CheckSampleValidValue('testresult_oth_' + equip.pyrogenMachaineId))" id="{{'testresult_oth_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Invalid
                                                        Value</label>
                                                    <label [hidden]="!CheckValidValue('testresult_oth_' + equip.pyrogenMachaineId)" id="{{'lbltestresult_oth_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px;    margin-top: 7px !important;">Result
                                                        is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>
                                                </div>
                                            </div>

                                            <div class="col-sm-9" *ngIf="f.TestMethod.value == 'LAL' && f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdateSampleValue($event)" [style.color]="GetColor('testresult_oth_' + equip.pyrogenMachaineId, 'result')" name="{{'testresult_oth_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="item.value == '(+)' ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList"
                                                            [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    <select class="form-control smInput" (change)="UpdatePosControlSampleValue($event)" [style.color]="GetColor('testposcontrol_oth_' + equip.pyrogenMachaineId, 'pos')" name="{{'testposcontrol_oth_' + equip.pyrogenMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == '(-)') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                </div>
                            </div>

                            <div class="box ibox box-info" *ngIf="f.PyrogenDetailsId.value > 0">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Testing Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">

                                    <div class="row" *ngIf="f.TestingLab.value == 'Internal (Tech)' && f.TestMethod.value == 'LAL'">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Sensitivity Lot:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.SensitivityLotNumber.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" style="float:none" name="SensitivityLotNumber" formControlName="SensitivityLotNumber" maxlength="10" />
                                                    <span *ngIf="f.SensitivityLotNumber.errors?.required && (f.SensitivityLotNumber.dirty || f.SensitivityLotNumber.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.SensitivityLotNumber.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Control Lot:</label>
                                                <div class="col-sm-6">
                                                    <label class="readOnlyText" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">{{f.ControlLotNumber.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" style="float:none" name="ControlLotNumber" formControlName="ControlLotNumber" maxlength="10" />
                                                    <span *ngIf="f.ControlLotNumber.errors?.required && (f.ControlLotNumber.dirty || f.ControlLotNumber.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ControlLotNumber.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label" style="padding-left: 0px;">Testing
                                                    Sensitivity(EU/mL):</label>
                                                <div class="col-sm-5" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.TestSensitivity.value}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">

                                                    <select class="form-control mdInput" formControlName="TestSensitivity" name="TestSensitivity">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of TestSensitivitylist">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <span *ngIf="f.TestSensitivity.errors?.required && (f.TestSensitivity.dirty || f.TestSensitivity.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.TestSensitivity.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Date:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestDateT.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                                                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.required && (f.CollectionRetestDateT.dirty || f.CollectionRetestDateT.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf='f.CollectionRetestDateT.errors?.error || f.CollectionRetestDateT.errors?.InGreaterDateOut' [ngClass]="'error'">Tested Date >= Collection Date</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Time:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestTime.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="CollectionRetestTime" formControlName="CollectionRetestTime" [owlDateTimeTrigger]="CollectionRetestTime" [owlDateTime]="CollectionRetestTime" />
                                                    <owl-date-time [pickerType]="'timer'" #CollectionRetestTime>
                                                    </owl-date-time>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.required && (f.CollectionRetestTime.dirty || f.CollectionRetestTime.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <!-- <span *ngIf="f.CollectionRetestTime.errors?.pattern"
                                                        [ngClass]="'error'">Invalid time</span> -->
                                                    <span *ngIf="f.CollectionRetestTime.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.GreaterDate" [ngClass]="'error'">
                                                        Tested Time >= Collection Time</span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label" style="padding-left: 0px;">Testing
                                                    User:</label>
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">{{CollectionRetestByName}}</label>
                                                    <select *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'" class="form-control mdInput" formControlName="CollectionRetestById" name="CollectionRetestById">
                                                        <option value="{{item.value}}" *ngFor="let item of Users">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <span *ngIf="f.CollectionRetestById.errors?.required && (f.CollectionRetestById.dirty || f.CollectionRetestById.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestById.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="row" *ngIf="f.TestingLab.value == 'Internal (Tech)' && f.TestMethod.value == 'PTS'">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Cartridge ID:</label>
                                                <div class="col-sm-6">
                                                    <label class="readOnlyText" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">{{f.ControlLotNumber.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" style="float:none" name="ControlLotNumber" formControlName="ControlLotNumber" maxlength="10" />
                                                    <span *ngIf="f.ControlLotNumber.errors?.required && (f.ControlLotNumber.dirty || f.ControlLotNumber.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ControlLotNumber.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Report ID:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.ReportId.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="ReportId" formControlName="ReportId" />
                                                    <span *ngIf="f.ReportId.errors?.required && (f.ReportId.dirty || f.ReportId.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ReportId.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Date:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestDateT.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                                                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.required && (f.CollectionRetestDateT.dirty || f.CollectionRetestDateT.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf='f.CollectionRetestDateT.errors?.error || f.CollectionRetestDateT.errors?.InGreaterDateOut' [ngClass]="'error'">Tested Date >= Collection Date</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Time:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestTime.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="CollectionRetestTime" formControlName="CollectionRetestTime" [owlDateTimeTrigger]="CollectionRetestTime" [owlDateTime]="CollectionRetestTime" />
                                                    <owl-date-time [pickerType]="'timer'" #CollectionRetestTime>
                                                    </owl-date-time>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.required && (f.CollectionRetestTime.dirty || f.CollectionRetestTime.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <!-- <span *ngIf="f.CollectionRetestTime.errors?.pattern"
                                                        [ngClass]="'error'">Invalid time</span> -->
                                                    <span *ngIf="f.CollectionRetestTime.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.GreaterDate" [ngClass]="'error'">
                                                        Tested Time >= Collection Time</span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Testing User:</label>
                                                <div class="col-sm-6">
                                                    <label class="readOnlyText" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">{{CollectionRetestByName}}</label>
                                                    <select *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'" class="form-control mdInput" formControlName="CollectionRetestById" name="CollectionRetestById">
                                                        <option value="{{item.value}}" *ngFor="let item of Users">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <span *ngIf="f.CollectionRetestById.errors?.required && (f.CollectionRetestById.dirty || f.CollectionRetestById.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestById.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                    <div class="row" *ngIf="f.TestingLab.value !== 'Internal (Tech)'">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Date:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestDateT.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                                                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.required && (f.CollectionRetestDateT.dirty || f.CollectionRetestDateT.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestDateT.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf='f.CollectionRetestDateT.errors?.error || f.CollectionRetestDateT.errors?.InGreaterDateOut' [ngClass]="'error'">Tested Date >= Collection Date</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Tested Time:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.CollectionRetestTime.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="CollectionRetestTime" formControlName="CollectionRetestTime" [owlDateTimeTrigger]="CollectionRetestTime" [owlDateTime]="CollectionRetestTime" />
                                                    <owl-date-time [pickerType]="'timer'" #CollectionRetestTime>
                                                    </owl-date-time>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.required && (f.CollectionRetestTime.dirty || f.CollectionRetestTime.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <!-- <span *ngIf="f.CollectionRetestTime.errors?.pattern"
                                                        [ngClass]="'error'">Invalid time</span> -->
                                                    <span *ngIf="f.CollectionRetestTime.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestTime.errors?.GreaterDate" [ngClass]="'error'">
                                                        Tested Time >= Collection Time</span>
                                                </div>

                                            </div>

                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Report ID:</label>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.ReportId.value}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="ReportId" formControlName="ReportId" />
                                                    <span *ngIf="f.ReportId.errors?.required && (f.ReportId.dirty || f.ReportId.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ReportId.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <!-- <div class="col-sm-3">
                                              <label class="col-sm-6 control-label">Testing User:</label>
                                              <div class="col-sm-6">
                                                  <label class="readOnlyText"
                                                      *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">{{CollectionRetestByName}}</label>
                                                  <select
                                                      *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'"
                                                      class="form-control mdInput"
                                                      formControlName="CollectionRetestById"
                                                      name="CollectionRetestById">
                                                      <option value="{{item.value}}" *ngFor="let item of Users">
                                                          {{item.text}}
                                                      </option>
                                                  </select>
                                                  <span
                                                      *ngIf="f.CollectionRetestById.errors?.required && (f.CollectionRetestById.dirty || f.CollectionRetestById.touched ||  isValidFormSubmitted==false)"
                                                      [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                                  <span *ngIf="f.CollectionRetestById.errors?.RequiredStatus"
                                                      [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                              </div>


                                            </div> -->
                                            <!-- <div class="col-sm-3">
                                                <label class="col-sm-7 control-label">Testing Sensitivity:</label>
                                                <div class="col-sm-5"
                                                    *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.TestSensitivity.value}}</label>
                                                </div>
                                                <div class="col-sm-5"
                                                    *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">

                                                    <select class="form-control mdInput"
                                                        formControlName="TestSensitivity" name="TestSensitivity">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of TestSensitivitylist">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.TestSensitivity.errors?.required && (f.TestSensitivity.dirty || f.TestSensitivity.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.TestSensitivity.errors?.RequiredStatus"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div> -->
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="box ibox box-info"
                                *ngIf="(f.TestingLab.value != 'Internal (Tech)' && f.TestingLab.value != 'Internal Lab') && f.PyrogenDetailsId.value > 0">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Report Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">




                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Report Date:</label>
                                                <div class="col-sm-6"
                                                    *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label
                                                        class="readOnlyText">{{f.CollectionRetestDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6"
                                                    *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input (focus)="rCollectionRetestDate.toggleCalendar()"
                                                        (click)="rCollectionRetestDate.openCalendar()"
                                                        placeholder="Select a date" class="form-control mdInput"
                                                        style="float:none" angular-mydatepicker name="CollectionRetestDate"
                                                        formControlName="CollectionRetestDate" [options]="myOptions"
                                                        #rCollectionRetestDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />
                                                    <span
                                                        *ngIf="f.CollectionRetestDate.errors?.required && (f.CollectionRetestDate.dirty || f.CollectionRetestDate.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionRetestDate.errors?.RequiredStatus"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf='f.CollectionRetestDate.errors?.error || f.CollectionRetestDate.errors?.InGreaterDateOut'
                                                    [ngClass]="'error'">Report Date >= Collection Date</span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Report ID:</label>
                                                <div class="col-sm-6"
                                                    *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'">
                                                    <label class="readOnlyText">{{f.ReportId.value}}</label>
                                                </div>
                                                <div class="col-sm-6"
                                                    *ngIf="f.PyrogenDetailsId.value > 0 && status=='In Progress'">
                                                    <input class="form-control mdInput" name="ReportId"
                                                        formControlName="ReportId" />
                                                    <span
                                                        *ngIf="f.ReportId.errors?.required && (f.ReportId.dirty || f.ReportId.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ReportId.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div> -->
                            <notes [isValidForm]="SetValidatorForNotes() || this.PyrogenForm.invalid || (this.DMTestList.length == 0 && this.WPTestList.length == 0 && this.CDTestList.length == 0 && this.OTHTestList.length == 0)" (addNoteClick)="executePyrogenCreateUpdate('Notes')"
                                #notes>
                            </notes>
                            <attachment (RaiseError)="AttachmentError($event)" #attachment>

                            </attachment>
                        </div>
                    </div>
                    <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executePyrogenCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                        #pageheader></PageHeader>
                </div>
            </div>
        </div>

    </section>
</form>


<div class="modal-content" *ngIf="!FullScreen">
    <form [formGroup]="PyrogenForm" (ngSubmit)="executeBacteriaCreateUpdate('')" novalidate>
        <div class="modal-header">
            <h4 class="modal-title">Quick Add Pyrogen</h4>
            <button type="button" class="close" aria-label="Close" (click)="dialogPyrogenRef.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="overflow-x: auto !important;">
            <div *ngIf="data.errormsg" class="box-row col-sm-12">
                <span [ngClass]="'error'">
                    {{data.errormsg}}
                </span>
            </div>
            <div class="box-row col-sm-12">
                <div class="col-sm-6">
                    Division:
                    <select class="form-control " formControlName="selectedDialysisUnits" name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                        <option value="{{item.value}}" *ngFor="let item of DialysisUnits">
                            {{item.text}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <span class='text-red inputrequired'>*</span>Lab Facility:
                    <select class="form-control " formControlName="TestingLab" name="TestingLab">
                        <option value="{{item.value}}" *ngFor="let item of TestingLabs">
                            {{item.text}}
                        </option>
                    </select>

                    <span *ngIf="f.TestingLab.errors?.required && (f.TestingLab.dirty || f.TestingLab.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                        Required!
                    </span>
                </div>
                <div class="col-sm-6">
                    <span class='text-red inputrequired'>*</span>Test Method:
                    <select class="form-control mdInput" formControlName="TestMethod" name="TestMethod">
                        <option value="{{item.value}}" *ngFor="let item of TestMethods">
                            {{item.text}}
                        </option>
                    </select>

                    <span *ngIf="f.TestMethod.errors?.required && (f.TestMethod.dirty || f.TestMethod.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                        Required!
                    </span>
                </div>
            </div>

            <div *ngIf="DMTestList.length > 0" class="box-row col-sm-12 divider">
                <div class="col-sm-6">
                    <strong>Dialysis Machine</strong>
                </div>
            </div>
            <div *ngFor="let equip of DMTestList;" class="box-row col-sm-12">
                <div class="col-sm-12">

                    <label class="col-sm-5"> {{equip.equipmentName}}
                        <span
                            *ngIf="(equip.hours && equip.hours != '0') || f.PyrogenDetailsId.value != 0 || status=='Completed'">
                            [{{equip.dialysisMachineHours}}]
                        </span> </label>


                    <div class="col-sm-5" *ngIf="(!equip.hours || equip.hours == '') && f.PyrogenDetailsId.value == 0">
                        <input class="form-control smInput" (change)="UpdateDialysisMachineHoursValue($event)" (keypress)="numberOnly($event)" name="{{'testresult_dm_' + equip.pyrogenMachaineId}}" />
                        <span [ngClass]="'error'">{{errorAddTest}}</span>
                    </div>
                    <div class="col-sm-2" *ngIf="f.PyrogenDetailsId.value == 0 && (!equip.hours || equip.hours == '') ">
                        <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedDMHours!=0 && selectedDMHours!=null">({{selectedDMHours}})</label>
                        <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedDMHours==0">(N/A)</label>
                    </div>

                    <label [hidden]="!(CheckSampleValidValue('testresult_dm_' + equip.pyrogenMachaineId))" id="{{'lbltestresult_dm_' + equip.pyrogenMachaineId}}" style="color:red;padding-left:10px">Invalid Value</label>
                    <label class="col-sm-3 text-center" [style.color]="GetColorLabel( equip.sampleResult )">{{equip.sampleResult}}</label>
                    <label class="col-sm-3  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                        {{equip.postControlResult}}</label>
                </div>
            </div>

            <div class="box-row col-sm-12 divider" *ngIf="WPTestList.length > 0">
                <div class="col-sm-6">
                    <strong>Water Process</strong>
                </div>
            </div>

            <div *ngFor="let equip of WPTestList;" class="box-row col-sm-12">
                <div class="col-sm-12">
                    <label class="col-sm-5"> {{equip.equipmentName}}
                        <span
                            *ngIf="(equip.hours && equip.hours != '0') || f.PyrogenDetailsId.value != 0 || status=='Completed'">
                            [{{equip.dialysisMachineHours}}]
                        </span>
                    </label>

                    <div class="col-sm-4" *ngIf="f.PyrogenDetailsId.value == 0 && (!equip.hours || equip.hours == '') ">
                        <input class="form-control smInput" (change)="UpdateDialysisMachineHoursValue($event)" (keypress)="numberOnly($event)" name="{{'testresult_wp_' + equip.pyrogenMachaineId}}">
                        <span [ngClass]="'error'">{{errorAddTest}}</span>
                    </div>
                    <div class="col-sm-1" *ngIf="f.PyrogenDetailsId.value == 0 && (!equip.hours || equip.hours == '') ">
                        <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedROHours!=0 && selectedROHours!=null">({{selectedROHours}})</label>
                        <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedROHours==0">(N/A)</label>
                    </div>
                    <label class="col-sm-3  text-center" [style.color]="GetColorLabel( equip.sampleResult )">{{equip.sampleResult}}</label>
                    <label class="col-sm-3  text-center" [style.color]="(equip.postControlResult == '(-)') ? 'red' : 'black'">
                                                    {{equip.postControlResult}}</label>
                </div>
            </div>
            <div class="box-row col-sm-12" style="margin-bottom: 10px;">
                <div class="col-sm-6">
                    Collection Date:
                    <input (focus)="rCollectionDate.toggleCalendar()" (click)="rCollectionDate.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionDate" formControlName="CollectionDate" [options]="myOptions"
                        #rCollectionDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>
                <div class="col-sm-6">
                    Collection Time:
                    <input class="form-control mdInput" name="CollectionTime" formControlName="CollectionTime" />
                </div>
                <div class="col-sm-6">Collection User:
                    <input class="form-control mdInput" value="{{CreatedByName}}" disabled="disabled">
                </div>
            </div>

            <div class="box-row  col-sm-12" *ngIf="status == 'Completed' && f.TestingLab.value == 'Internal (Tech)' && f.TestMethod.value == 'LAL'">
                <div class="col-sm-6">Sensitivity Lot:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="SensitivityLotNumber" disabled />
                </div>
                <div class="col-sm-6">Control Lot:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="ControlLotNumber" disabled />
                </div>
                <div class="col-sm-6">Testing Sensitivity:
                    <select class="form-control mdInput" disabled formControlName="TestSensitivity" name="TestSensitivity">
                        <option value="{{item.value}}" *ngFor="let item of TestSensitivitylist">
                            {{item.text}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6">Tested Date:
                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" disabled (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>

                <div class="col-sm-6">Tested Time:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="CollectionRetestTime" disabled />
                </div>
                <div class="col-sm-6">Testing User:
                    <select disabled class="form-control mdInput" formControlName="CollectionRetestById" name="CollectionRetestById">
                        <option value="{{item.value}}" *ngFor="let item of Users">
                            {{item.text}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="box-row  col-sm-12" *ngIf="status == 'Completed' && f.TestingLab.value == 'Internal (Tech)' && f.TestMethod.value == 'PTS'">
                <div class="col-sm-6">Cartridge ID:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="ControlLotNumber" disabled />
                </div>
                <div class="col-sm-6">Report ID:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="ReportId" disabled />
                </div>

                <div class="col-sm-6">Tested Date:
                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" disabled (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>

                <div class="col-sm-6">Tested Time:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="CollectionRetestTime" disabled />
                </div>
                <div class="col-sm-6">Testing User:
                    <select disabled class="form-control mdInput" formControlName="CollectionRetestById" name="CollectionRetestById">
                        <option value="{{item.value}}" *ngFor="let item of Users">
                            {{item.text}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="box-row col-sm-12" *ngIf="f.TestingLab.value != 'Internal (Tech)' && status == 'Completed'">
                <div class="col-sm-6">
                    Tested Date:
                    <input (focus)="rCollectionRetestDateT.toggleCalendar()" (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT" formControlName="CollectionRetestDateT"
                        [options]="myOptions" #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>
                <div class="col-sm-6">Tested Time:
                    <input class="form-control mdInput" style="float:none" maxlength="12" formControlName="CollectionRetestTime" disabled/>
                </div>
                <div class="col-sm-6">
                    Report ID:
                    <input class="form-control mdInput" name="ReportId" formControlName="ReportId" />
                </div>
            </div>

            <!-- <div class="box-row col-sm-12" *ngIf="f.TestingLab.value == 'Internal (Tech)' && status == 'Completed'">
                <div class="col-sm-6">
                    Tested Date:
                    <input (focus)="rCollectionRetestDateT.toggleCalendar()"
                        (click)="rCollectionRetestDateT.openCalendar()" placeholder="Select a date"
                        class="form-control mdInput" style="float:none" angular-mydatepicker name="CollectionRetestDateT"
                        formControlName="CollectionRetestDateT" [options]="myOptions"
                        #rCollectionRetestDateT="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>
                <div class="col-sm-6">
                    Tested Time:
                    <input class="form-control mdInput" name="CollectionRetestTime"
                        formControlName="CollectionRetestTime" />
                </div>


                <div class="col-sm-6">
                    Testing Sensitivity:
                    <select class="form-control mdInput" formControlName="TestSensitivity" name="TestSensitivity">
                        <option value="{{item.value}}" *ngFor="let item of TestSensitivitylist">
                            {{item.text}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-6">
                    Read User:
                    <select class="form-control mdInput" formControlName="CollectionRetestById"
                        name="CollectionRetestById">
                        <option value="{{item.value}}" *ngFor="let item of Users">
                            {{item.text}}
                        </option>
                    </select>
                </div>
            </div> -->
            <div class="col-sm-6"></div>
            <div class="col-sm-6"></div>
            <div class="col-sm-6"></div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="dialogPyrogenRef.close(false)">Cancel</button>
            <button type="button" class="btn btn-primary" *ngIf="!f.PyrogenDetailsId.value" (click)="executePyrogenCreateUpdate('')">Save</button>
        </div>
    </form>

</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class DMTransferService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetDMTransferSearchDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/GetDMTransferSearchDetails',
      SearchOptions,
      httpOptions
    );
  }

  GetHoursOutOfPreviousTransfer(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/GetHoursOutOfPreviousTransfer',
      value,
      httpOptions
    );
  }

  FillDivision() {
    return this.http.post<any>(
      this.apiUrl + 'UserService/FillDivision',
      '',
      httpOptions
    );
  }

  FillEquipments(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetEquipmentDD',
      value,
      httpOptions
    );
  }

  FillEquipmentsByCategory(SearchOptions) {

    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/FillEquipmentDDByCategory',
      SearchOptions,
      httpOptions
    );
  }

  CreateDMTransferDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/CreateDMTransferDetails',
      value,
      httpOptions
    );
  }

  UpdateDMTransferDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/UpdateDMTransferDetails',
      value,
      httpOptions
    );
  }

  GetTransferByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/GetTransferByID',
      value,
      httpOptions
    );
  }

  GetDmTransferAttachments(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/GetDmTransferAttachments',
      value,
      httpOptions
    );
  }

  GetDmTransferNotes(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/GetDmTransferNotes',
      value,
      httpOptions
    );
  }

  DeleteTransferDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMTransferService/DeleteTransferDetailsByID',
      value,
      httpOptions
    );
  }
}


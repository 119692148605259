import { Injectable } from '@angular/core';
import { AuthService } from '../../API/auth.service';
import { Router, CanActivate } from '@angular/router';
import { Globals } from '../../globals';
import { interval } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authentication: AuthService, private router: Router) {
    interval(600000).subscribe(x => {
      this.checkSessionActive();
    });
  }

  canActivate(): boolean | Promise<boolean> { // tslint:disable-next-line:indent

    const token = this.authentication.getToken();
    const accessToken = this.authentication.getAccessToken();
    if (!token) {
      // console.error('User is not authenticated.');
      this.redirectToInvalidSessionUrl();
      return false;
    } else if (this.authentication.isAuthenticated()) {
      return true;
    } else {
      // console.error('User is not authenticated.');
      this.redirectToInvalidSessionUrl();
      return false;
    }
  }

  redirectToLoginPage() {
    if (Globals.Version === 'local') {
      this.router.navigate(['login']);
    } else if (Globals.Version === 'publish') {
    location.href = Globals.mrmrenalwebsite;
    }
}


  checkSessionActive() {

    if (this.router.url !== '/login') {
      const token = this.authentication.getToken();
      const accessToken = this.authentication.getAccessToken();
      if (!token) {
        // console.error('User is not authenticated.');
        this.redirectToInvalidSessionUrl();
        return false;
      } else if (this.authentication.isAuthenticated()) {
        return true;
      } else {
        // console.error('User is not authenticated.');
        this.redirectToInvalidSessionUrl();
        return false;
      }
    }
  }

  redirectToInvalidSessionUrl() {
    if (Globals.Version === 'local') {
       this.router.navigate(['login']);
    } else if (Globals.Version === 'publish') {
      this.router.navigate(['login']);
      // location.href = Globals.invalidSessionUrl;
    }
  }

}

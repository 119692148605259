<div class="box ibox box-info no-print" >
    <div class="box-header with-border">
        <h3 class="box-title">Attachments</h3>
    </div>
    <div class="box-body">
        <div class="col-sm-12 box-row">

            <input type="file"  class="FileInput hidden" id="{{uploadID}}" ng2FileSelect [uploader]="uploader" multiple  />
            <button (click)="UploadFiles()"  class="btn btn-success pull-left" type="button"><i class="glyphicon glyphicon-upload"></i>&nbsp;  Upload File</button>

            <p style="padding-left: 20px;" class="pull-left">
                <small style="font-size:75%">The maximum file size for uploads <strong><i>20 MB</i></strong> Max.<br/>
                Only files (<strong><i>JPG, GIF, PNG, DOC, XLS, PPT, PDF, JPEG</i></strong>) are allowed.</small>
            </p>
            <div class="clear-fix"></div>
            </div>
        <div class="col-sm-12 box-row" *ngIf="PrevAttachments.length > 0 || uploader.queue.length > 0 ">
            <table class="table">
                <thead>
                <tr>
                    <th style="width:40%;text-align: left !important">Name</th>
                    <!-- <th  style="width:10%">Size</th> -->
                    <th  style="width:30%">Progress</th>
                    <th  style="width:10%">Status</th>
                    <th  style="width:10%">Actions</th>
                </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let item of PrevAttachments">

                                <td  style="text-align:left" ><strong><a
                                    href="javascript:void(0)"
                                    (click)="openImageDialog(item.attachmentname,SetAttachmentUrl(item.attachmentpath))"
                                    >
                                    {{item.attachmentname}}</a></strong></td>
                                <!-- <td  class="text-left" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td> -->
                                <td >
                                    <div class="text-left" style="margin-bottom: 0;">
                                        <!-- <div class="progress-bar" role="progressbar" style="width:100%"></div> -->
                                    </div></td>

                                <td class="text-center">
                                    <span><i class="glyphicon glyphicon-ok"></i></span>

                                </td>
                                <td nowrap>

                                    <button type="button" class="btn btn-danger btn-xs" *ngIf="loggedInUserDesignation == 'Administrator'"
                                            (click)="RemovePrevAttachments(item)">
                                        <span class="glyphicon glyphicon-trash"></span> Remove
                                    </button>
                                </td>
                            </tr>
                        <tr *ngFor="let item of uploader.queue">
                                <td *ngIf="item?.index==undefined" style="text-align:left"><strong>{{ item?.file?.name }}</strong></td>
                                <td *ngIf="item?.index!=undefined" style="text-align:left"><strong><a
                                    href="javascript:void(0)"
                                    (click)="openImageDialog(item?.file?.name,SetAttachmentUrl( item?.index))"
                                    >
                                    {{ item?.file?.name }}</a></strong></td>
                                <!-- <td  class="text-left" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td> -->
                                <td >
                                    <div class="progress text-left" style="margin-bottom: 0;">
                                        <div class="progress-bar" style="text-align: center" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"><span *ngIf="item.progress==100">Save Pending</span></div>
                                    </div> {{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>

                                <td class="text-center">
                                    <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                    <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                    <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                                </td>
                                <td nowrap>

                                    <button type="button" class="btn btn-danger btn-xs"
                                            (click)="item.remove()" *ngIf="item?.file?.name != 'Error Image.png'">
                                        <span class="glyphicon glyphicon-trash"></span> Remove
                                    </button>
                                </td>
                            </tr>

                </tbody>
            </table>
        </div>
    </div>
    </div>

<div class="modal-header">
  <h4 class="modal-title">Latest 10 Messages</h4>
  <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="overflow: auto; overflow-x: hidden;">
      <div class="box ibox box-info">
      <div class="box-header with-border">
        <h3 class="box-title">New Messages</h3>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-sm-12" style="padding-left: 0px;padding-right: 0px;">
         <div class="col-sm-2 text-center">
            <strong>User</strong>
          </div>
          <div class="col-sm-2 text-center" style="padding-left: 0px;">
            <strong>Date</strong>
          </div>
          <div class="col-sm-2 text-center">
            <strong>Topic</strong>
          </div>
          <div class="col-sm-3 text-center">
            <strong>Subject</strong>
          </div>
          <div class="col-sm-3 text-center">
            <strong>Message</strong>
          </div>
        </div>
      </div>
      <div *ngFor="let item of newMessageForum;let index=index;" class="row">
        <div class="col-sm-12 box-row">
            <!-- <div class="col-sm-1 text-center">
                {{index + 1}}
            </div> -->
            <div title="{{ item.UserName }}" class="col-sm-2 text-center">
                {{ item.UserName }}
            </div>
            <div title="{{item.CreationDate | date:'dd/MM/yyyy HH:mm'}}" class="col-sm-2 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;padding-left: 0px">
                {{item.CreationDate | date:'dd/MM/yyyy HH:mm'}}
            </div>
             <div title="{{ item.ForumTitle }}" class="col-sm-2 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">
                {{ item.ForumTitle }}
            </div>
            <div title="{{item.Subject}}" class="col-sm-3 text-left"  style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">
                {{ item.Subject }}
            </div>
            <div [title]="formatHTML(item.Body)" [innerHTML]="formatHTML(item.Body)" class="col-sm-3 text-left"  style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">

          </div>
          </div>
      </div>
    </div>
      <div class="clearfix"></div>

      <div class="box ibox box-info">
      <div class="box-header with-border">
        <h3 class="box-title">Most Recent Messages</h3>
    </div>
      </div>
      <div class="box-body">
    <div class="row">
      <div class="col-sm-12" style="padding-left: 0px;padding-right: 0px;">
       <div class="col-sm-2 text-center">
          <strong>User</strong>
        </div>
        <div class="col-sm-2 text-center" style="padding-left: 0px">
          <strong>Date</strong>
        </div>
        <div class="col-sm-2 text-center">
          <strong>Topic</strong>
        </div>
        <div class="col-sm-3 text-center">
          <strong>Subject</strong>
        </div>
        <div class="col-sm-3 text-center">
          <strong>Message</strong>
        </div>
      </div>
    </div>
    <div *ngFor="let item of latestMessageForum;let index=index;" class="row">
      <div class="col-sm-12 box-row">
          <!-- <div class="col-sm-1 text-center">
              {{index + 1}}
          </div> -->
          <div title="{{ item.UserName }}" class="col-sm-2 text-center">
              {{ item.UserName }}
          </div>
          <div title="{{item.CreationDate | date:'dd/MM/yyyy HH:mm'}}" class="col-sm-2 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;padding-left: 0px">
              {{item.CreationDate | date:'dd/MM/yyyy HH:mm'}}
          </div>
           <div title="{{ item.ForumTitle }}" class="col-sm-2 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">
              {{ item.ForumTitle }}
          </div>
          <div title="{{item.Subject}}" class="col-sm-3 text-left"  style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">
              {{ item.Subject }}
          </div>
          <div [title]="formatHTML(item.Body)" [innerHTML]="formatHTML(item.Body)" class="col-sm-3 text-left"  style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap;">

        </div>
    </div>
    </div>
  </div>
</div>
            <!-- <div class="col-sm-3 text-left" style="padding-left:0px;" title="{{item.Body}}">
                {{ item.Body.length > 10 ? item.Body.substring(0,10) + '...' : item.Body }}
            </div>            -->
      <!-- <tr *ngFor="let item of latestForums;let index=index">
        <td style="vertical-align:middle">
            {{index + 1}}
        </td>
        <td  style="vertical-align:middle">
          {{ item.UserName }}
        </td>
        <td  style="vertical-align:middle">
          {{ item.ForumTitle }}
        </td>
        <td  style="vertical-align:middle">
          {{ item.CreationDate | date:'dd/MM/yyyy HH:mm' }}
        </td>
        <td  style="vertical-align:middle">
          {{ item.Subject }}
        </td>
        <td  style="vertical-align:middle">
          {{ item.Body.length > 10 ? item.Body.substring(0,10) + ' ...' : item.Body }}
        </td>
    </tr> -->
  <!-- </div></div> -->
    <!-- <table  style= "width:97%" class="col-sm-12 BlackBorderTable no-footer">
      <tbody>
                        <tr>
                            <th width="5%" align="center">No.</th>
                            <th width="12%" align="center">User</th>
                            <th width="22%" align="center">Forum</th>
                            <th width="22%" align="center">Date</th>
                            <th width="17%" align="center">Subject</th>
                            <th width="20%" align="center">Message</th>
                          </tr>

                            <tr *ngFor="let item of latestForums;let index=index">
                                    <td style="vertical-align:middle">
                                        {{index + 1}}
                                    </td>
                                    <td  style="vertical-align:middle">
                                      {{ item.UserName }}
                                    </td>
                                    <td  style="vertical-align:middle">
                                      {{ item.ForumTitle }}
                                    </td>
                                    <td  style="vertical-align:middle">
                                      {{ item.CreationDate | date:'dd/MM/yyyy HH:mm' }}
                                    </td>
                                    <td  style="vertical-align:middle">
                                      {{ item.Subject }}
                                    </td>
                                    <td  style="vertical-align:middle">
                                      {{ item.Body.length > 10 ? item.Body.substring(0,10) + ' ...' : item.Body }}
                                    </td>
                                </tr>
      </tbody>
    </table> -->
  <!-- </div>
  </div> -->

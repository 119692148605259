import { Component, ViewChild } from '@angular/core';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { DecimalPipe, DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { WPApiService } from 'src/app/API/wpapi.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { URLDialog } from 'src/app/Core/Shared/URLDialog/URLDialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { Globals } from "src/app/globals";
@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2Document',
  templateUrl: 'BioPureHX2Document.component.html',
  styleUrls: ['BioPureHX2Document.component.css']
})

export class BioPureHX2DocumentComponent {
  title =  'BioPure HX2 Documents';
  urlDialogRef: MatDialogRef<URLDialog>;
  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  private router: Router,
  private wpapi: WPApiService,
  private dataGridService: TableHeadsService,
  private commonapi : CommonApiService,
  public dialog: MatDialog,
) {
  this.page.pageNumber = 0;
  this.page.size = 25;
}

@ViewChild(PageHeaderComponent, { static: false }) pageheader: PageHeaderComponent;

tableHeaders;
page = new Page();
rows ;
defaultSort;

currentPageNumber = 1;
sortExpression = 'attachmentName';
sortDirection = 'ASC';
pageSize = 25;
ShowSearch = false;
searchText = 'Search Records';
isPageLoaded = false;

ngOnInit() {

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'Id', '5');
      this.dataGridService.addHeader('Document Name', 'attachmentName', '90');
      this.dataGridService.addHeader('Delete', '', '5');
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('attachmentName', false);
}

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};


setPage() {
  if(this.currentPageNumber != this.page.pageNumber)
  {
  this.currentPageNumber = this.page.pageNumber;
  if (this.isPageLoaded === true) {
//  console.log('setpage');
  // this.executeNurseCheckSearch();
  }
}
}
_onRowClick(row) {
    // this.router.navigate([this.AddUrl], { queryParams: { id: row} });
}

  RouteClick(value) {
    this.router.navigate([value]);
  }

  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }

  openURLDialog(FileName, URL): void {
    this.urlDialogRef = this.dialog.open(URLDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
    });

    this.urlDialogRef.componentInstance.URL = URL;
    this.urlDialogRef.componentInstance.dialogRef.updateSize('80%', '100%');
    this.urlDialogRef.componentInstance.PageTitle = FileName;
    this.urlDialogRef.afterClosed().subscribe(result => {
      this.urlDialogRef = null;
    });
  }

  SetAttachmentUrl(fileName) {
    return Globals.AttachmentUrl + '/HX2DOCUMENTS/' + fileName;
  }
 
  
}

export class Page {
  // The number of elements in the page
  // tslint:disable-next-line:no-inferrable-types
  size: number = 25;
  // The total number of elements
  // tslint:disable-next-line:no-inferrable-types
  totalElements: number = 0;
  //  The total number of pages
  // tslint:disable-next-line:no-inferrable-types
  totalPages: number = 0;
  // The current page number
  // tslint:disable-next-line:no-inferrable-types
  pageNumber: number = 0;
}

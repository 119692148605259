import { filter } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HomeService } from 'src/app/API/home.service';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BacteriaComponent } from 'src/app/Core/Shared/BacteriaComponent/Bacteria.component';
import { PyrogenComponent } from 'src/app/Core/Shared/PyrogenComponent/Pyrogen.component';
import { WaterProcessAnalysisComponent } from 'src/app/Core/Shared/WaterProcessAnalysisComponent/WaterProcessAnalysisComponent.component';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { MachineHistoryDialog } from 'src/app/Core/MachineHistory/MachineHistoryDialog';
import { InvApiService } from 'src/app/API/invapi.service';
import { QuickHomeEquipmentTransfer } from 'src/app/Core/QuickHomeEquipmentTransfer/QuickHomeEquipmentTransfer';
import { Globals } from 'src/app/globals';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
// import { padNumber } from "@ng-bootstrap/ng-bootstrap/util/util";
import { DMApiService } from "../../../API/dmapi.service";
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { MaintenanceCleanOrTestHistory } from '../../Section/MaintenanceCleanOrTestHistory/MaintenanceCleanOrTestHistory.component';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { TasksApiService } from 'src/app/API/tasksapi.service';
import { AuthService } from 'src/app/API/auth.service';
import { padNumber } from 'src/app/Core/Helper/utils';

@Component({
  selector: 'PurposeMaintenance',
  templateUrl: './purpose-maintenance.component.html',
  styleUrls: ['./purpose-maintenance.component.css']
})
export class PurposeMaintenanceComponent implements OnInit {
  matDialogRef: MatDialogRef<ConfirmationDialog>;
  @Output() SaveEvent = new EventEmitter<any>();

  @ViewChild('roNotes', { static: false }) roNotes: NotesComponent;
  @ViewChild('dmNotes', { static: false }) dmNotes: NotesComponent;
  @ViewChild('cfNotes', { static: false }) cfNotes: NotesComponent;
  @ViewChild('cwtNotes', { static: false }) cwtNotes: NotesComponent;
  @ViewChild('yearlyNotes', { static: false }) yearlyNotes: NotesComponent;

  @Output() addNoteClick = new EventEmitter<any>();
  machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;
  quickUpdateTransferDialogRef: MatDialogRef<QuickHomeEquipmentTransfer>;

  private scrollHelper: ScrollHelper = new ScrollHelper();

  formSubmitted = false;
  errormsg = '';
  currentUserId;
  IsPurposeCompleted = '';
  ROEquipmentName = '';
  DMMachineName = '';
  DMMachineModel = '';
  NotesModule = '';
  public PtHomeArrivalTime;
  MaintenanceType = '';
  lastHeatCleanDays = '';
  lastAcidCleanDays = '';
  isROCompleted = false;
  selectedROHours = 0;
  selectedDMHours = 0;


  maintenanceForm = new UntypedFormGroup({
    MaintenanceId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl('0'),
    DialysisUnitId: new UntypedFormControl(''),
    DialysisUnitName: new UntypedFormControl(''),
    dialysisUnitName: new UntypedFormControl(''),
    ROEquipmentId: new UntypedFormControl(''),
    ROFeedConductivity: new UntypedFormControl(''),
    ROProductConductivity: new UntypedFormControl(''),
    ROReject: new UntypedFormControl('', Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)),
    ROHours: new UntypedFormControl(''),
    ROAirFilter: new UntypedFormControl(''),
    ROHeatClean: new UntypedFormControl(''),
    ROLastHeatClean: new UntypedFormControl(''),
    ROAcidClean: new UntypedFormControl(''),
    ROLastAcidClean: new UntypedFormControl(''),
    ROHPCTestPerformed: new UntypedFormControl(''),
    ROHPCLastTested: new UntypedFormControl(''),
    ROHPCResults: new UntypedFormControl(''),
    ROLALTestPerformed: new UntypedFormControl(''),
    ROLALLastTested: new UntypedFormControl(''),
    ROLALSensitivity: new UntypedFormControl(''),
    ROLALResults: new UntypedFormControl(''),
    RODaysSinceLastHC: new UntypedFormControl(''),
    RODaysSinceLastAcidClean: new UntypedFormControl(''),
    DMEquipmentId: new UntypedFormControl(''),
    DMEquipmentModelID: new UntypedFormControl(''),
    DMLastPMCompleted: new UntypedFormControl(''),
    DMDiaSafe: new UntypedFormControl(''),
    DMExteriorCondition: new UntypedFormControl(''),
    DMWandsChecked: new UntypedFormControl(''),
    DMCheckforLeaks: new UntypedFormControl(''),
    DMVerifiedBleachLevel: new UntypedFormControl(''),
    DMCleanAirFilter: new UntypedFormControl(''),
    DMHPCTestPerformed: new UntypedFormControl(''),
    DMHPCLastTested: new UntypedFormControl(''),
    DMHPCResults: new UntypedFormControl(''),
    DMLALTestPerformed: new UntypedFormControl(''),
    DMLALLastTested: new UntypedFormControl(''),
    DMLALSensitivity: new UntypedFormControl(''),
    DMLALResults: new UntypedFormControl(''),
    CFMax: new UntypedFormControl(''),
    CFMin: new UntypedFormControl(''),
    CFFinal: new UntypedFormControl(''),
    CFRatio: new UntypedFormControl(''),
    CFVerifySystemforLeaks: new UntypedFormControl(''),
    CFHardness: new UntypedFormControl(''),
    CFPH: new UntypedFormControl('', Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)),
    CFTotalChlorine: new UntypedFormControl('', Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)),
    CFIron: new UntypedFormControl('', Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)),
    CFFloodStop: new UntypedFormControl(''),
    CF505Filters: new UntypedFormControl(''),
    CF505FiltersLastReplacedDate: new UntypedFormControl(''),
    CFCarbonFilter: new UntypedFormControl(''),
    CFCarbonFilterLastReplacedDate: new UntypedFormControl(''),
    CF10Filter: new UntypedFormControl(''),
    CF10FilterLastReplacedDate: new UntypedFormControl(''),
    CFIronFilters: new UntypedFormControl(''),
    CFIronFiltersLastReplacedDate: new UntypedFormControl(''),
    CFInstalledLabels: new UntypedFormControl(''),
    CWTFrequency: new UntypedFormControl(''),
    CWTFeed: new UntypedFormControl(''),
    CWTFeedLastTested: new UntypedFormControl(''),
    CWTRoProduct: new UntypedFormControl(''),
    CWTRoProductLastTested: new UntypedFormControl(''),
    CWTFeedH2OAnalysisId: new UntypedFormControl(''),
    CWTRoProductH2OAnalysisId: new UntypedFormControl(''),
    YCRoGFIPlugTested: new UntypedFormControl(''),
    YCDmGFIPlugTested: new UntypedFormControl(''),
    YCRoGFIPlugLastTested: new UntypedFormControl(''),
    YCDmGFIPlugLastTested: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl(''),
    ROHPCTestingLAB: new UntypedFormControl(''),
    ROLALTestingLAB: new UntypedFormControl(''),
    DMHPCTestingLAB: new UntypedFormControl(''),
    DMLALTestingLAB: new UntypedFormControl(''),
    CWTFeedReportResultsDate: new UntypedFormControl(''),
    CWTFeedReportID: new UntypedFormControl(''),
    CWTRoProductReportResultsDate: new UntypedFormControl(''),
    CWTRoProductReportID: new UntypedFormControl(''),
    ROBacteriaId: new UntypedFormControl(''),
    ROPyrogenId: new UntypedFormControl(''),
    DMBacteriaId: new UntypedFormControl(''),
    DMPyrogenId: new UntypedFormControl(''),
    DMHours: new UntypedFormControl(''),
    CompletedSections: new UntypedFormControl('')
  });

  get mntf() {
    return this.maintenanceForm.controls;
  }


  displayOffReplacedNo = true;
  mrmHomeId: number = 0;
  quickAddDialogRef: MatDialogRef<WaterProcessAnalysisComponent>;
  quickAddBacteriaDialogRef: MatDialogRef<BacteriaComponent>;
  quickAddPyrogenDialogRef: MatDialogRef<PyrogenComponent>;

  hpcTest = '';
  dhpcTest = '';
  lalTest = '';
  dlalTest = '';
  today = new Date();
  textColor = Globals.textColor;
  onYes = 'Yes';
  offNo = 'No';
  onOk = 'OK';
  offReplaced = 'Replaced';
  onPass = 'Pass';
  offFail = 'Fail';
  offCleaned = 'Cleaned';
  public HFSPurpose = '';
  dmEquipmentList = [];
  roEquipmentList = [];
  isWaterAnalysis6M = false;
  isIronFilterRequired = false;
  HFServiceTaskCategory = '';

  lstOkReplaced = [
    { value: '', text: 'Select' },
    { value: 'OK', text: 'OK' },
    { value: 'Replaced', text: 'Replaced', color: 'red' },
  ];

  lstOkNo = [
    { value: '', text: 'Select' },
    { value: 'OK', text: 'OK' },
    { value: 'No', text: 'No', color: 'red' },
  ];

  lstYesNo = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];

  lstYesNoFlood = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No', color: 'red' },
  ];
  lstTest = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes', color: 'red' },
    { value: 'No', text: 'No' },
  ];

  lstYesNoNA = [
    { value: '', text: 'Select' },
    { value: 'N/A', text: 'N/A' },
    { value: 'Yes', text: 'Yes', color: 'red' },
    { value: 'No', text: 'No' },
  ];

  lstResult = [{ value: '', text: 'Select' },
  { value: 'Pass', text: 'Pass' },
  { value: 'Fail', text: 'Fail', color: 'red' }];

  lstLALTest = [
    { value: '', text: 'Select' },
    { value: '0.005', text: '0.005' },
    { value: '0.01', text: '0.01' },
    { value: '0.05', text: '0.05' },
    { value: '0.1', text: '0.1' },
  ];

  lstAirFilter = [
    { value: '', text: 'Select' },
    { value: 'Replaced', text: 'Replaced', color: 'red' },
    { value: 'Cleaned', text: 'Cleaned' },
  ];
  lstHPCResult = [
    { value: '', text: 'Select' },
    { value: '<10', text: '<10' },
    { value: '10', text: '10.0' },
    { value: '11', text: '11.0' },
    { value: '12', text: '12.0', color: 'red' },
    { value: '13', text: '13.0', color: 'red' },
    { value: '14', text: '14.0', color: 'red' },
    { value: '15', text: '15.0', color: 'red' },
    { value: '16', text: '16.0', color: 'red' },
    { value: '17', text: '17.0', color: 'red' },
    { value: '18', text: '18.0', color: 'red' },
    { value: '19', text: '19.0', color: 'red' },
    { value: '>20', text: '>20', color: 'red' },

  ];

  lstLabResult = [
    { value: '', text: 'Select' },
    { value: 'Internal', text: 'Internal' },
    { value: 'External', text: 'External' },
    { value: 'See Notes', text: 'See Notes' },
  ];

  isCollapsed = {
    roChecks: false,
    dmChecks: false,
    cfChecks: false,
    cwtChecks: false,
    yearlyChecks: false
  };

  sectionStatus = {
    roChecks: {type: 'RO Checks & Testing', status: '', formSubmitted: false },
    dmChecks: {type: 'Dialysis Machine Checks & Testing', status: '', formSubmitted: false },
    cfChecks: {type: 'Cart/Filters Checks', status: '', formSubmitted: false },
    cwtChecks: {type: 'Comprehensive Water Testing', status: '', formSubmitted: false },
    yearlyChecks: {type: 'Yearly Checks', status: '', formSubmitted: false }
  };

  constructor(
    private invapi: InvApiService, private dialog: MatDialog,
    private router: Router,
    private dmapi: DMApiService, public auth: AuthService,
    private homeApi: HomeService, private homefieldApi: HomeFieldService, private taskapi: TasksApiService,
    public dialogRef: MatDialogRef<PurposeMaintenanceComponent>) { }

  ngOnInit() {

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      if (this.HFSPurpose == 'Renovation & Full Installation')
        this.displayOffReplacedNo = false;

      this.onFormChanges();
      this.setValidations('');
      this.clearNotes();
    }, error => {
    });
  }

  ngAfterViewInit() {
    this.roNotes.NoteHeader = "RO Checks & Testing Notes";
    this.dmNotes.NoteHeader = "DM Checks & Testing Notes";
    this.cfNotes.NoteHeader = "Cart/Filters Checks Notes";
    this.cwtNotes.NoteHeader = "Comprehensive Water Testing Notes";
    this.yearlyNotes.NoteHeader = "Yearly Checks Notes";

    this.fillDMEquipments();
    this.fillROEquipments();
    this.clearNotes();
    this.setWaterAnalysisLabel();
  }


  setWaterAnalysisLabel() {
    this.taskapi.GetAutoTasksbyID({
      Id: this.mntf.DialysisUnitId.value
    }).subscribe(
      success => {
        if (success.feedWaterAnalysisFrequency == '6M')
          this.isWaterAnalysis6M = true;

        if (success.ironFilters)
          this.isIronFilterRequired = true;
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );

  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  save(maintenanceType, redirectList = true) {
    return new Promise((resolve, reject) => {
      this.MaintenanceType = maintenanceType;

      this.formSubmitted = true;

      let filledsections = this.checkValueExistInSections();
      this.mntf.CompletedSections.setValue(this.getCompletedSections(filledsections));
      let notCompletedSections = this.getNotCompletedSections(filledsections);
      const MNTFModelCreate = this.prepareCreateViewModel();

        if (maintenanceType != '')
          this.setValidations(maintenanceType);
        else {
          this.setValidations(filledsections);
          // this.expandDefaultSection(filledsections);
        }

        this.updateSectionStatus();

      this.expandDefaultSection(notCompletedSections);


      if (this.maintenanceForm.invalid) {
        //AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        // this.errormsg = "For Sections " + filledsections + ",  " + Globals.FormErrorMessage;
        AppComponent.loading = false;

        this.scrollHelper.scrollToFirst('modal-header');
        AppComponent.ScrollDown();
        return;
      }

      // if (!filledsections) {
      //   //AppComponent.addAlert("one of the section should be filled up to save data.", 'error');
      //   this.errormsg = "One of the section should be filled up to save data.";
      //   return;
      // }
      // else {
      //   if (this.errormsg == "One of the section should be filled up to save data.")
      //     this.errormsg = "";
      // }

      if (!MNTFModelCreate.CompletedSections.includes(maintenanceType)) {
        if (MNTFModelCreate.CompletedSections == '')
          MNTFModelCreate.CompletedSections += maintenanceType;
        else
          MNTFModelCreate.CompletedSections += "," + maintenanceType;
      }



      if (this.mntf.MaintenanceId.value === '0'
      ) {
        this.homefieldApi.CreateHomeFieldService_Maintenance(MNTFModelCreate).subscribe(
          success => {
            this.mntf.MaintenanceId.setValue(success.maintenanceId);
            // if (redirectList)
            //   this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);

            this.errormsg = "";
            this.setModel();
            resolve(true);
          },
          error => {
            reject();
          }
        );
      } else {
        this.homefieldApi.UpdateHomeFieldService_Maintenance(MNTFModelCreate).subscribe(
          success => {
            // if (redirectList)
            //   this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);

            this.errormsg = "";
            this.setModel();
            resolve(true);
          },
          error => {
            reject();
          }
        );

      }
    });
  }

  prepareCreateViewModel(Source = "") {
    const modelMaintenanceCreateUpdate = <any>{};

    modelMaintenanceCreateUpdate.MaintenanceId = this.mntf.MaintenanceId.value;
    modelMaintenanceCreateUpdate.HFServiceId = this.mntf.HFServiceId.value;
    modelMaintenanceCreateUpdate.DialysisUnitId = this.mntf.DialysisUnitId.value;
    modelMaintenanceCreateUpdate.ROEquipmentId = this.mntf.ROEquipmentId.value;
    modelMaintenanceCreateUpdate.ROFeedConductivity = this.mntf.ROFeedConductivity.value;
    modelMaintenanceCreateUpdate.ROProductConductivity = this.mntf.ROProductConductivity.value;
    modelMaintenanceCreateUpdate.ROReject = this.mntf.ROReject.value;
    modelMaintenanceCreateUpdate.ROHours = this.mntf.ROHours.value;
    modelMaintenanceCreateUpdate.ROAirFilter = this.mntf.ROAirFilter.value;
    modelMaintenanceCreateUpdate.ROHeatClean = this.mntf.ROHeatClean.value;
    modelMaintenanceCreateUpdate.PtHomeArrivalTime = this.PtHomeArrivalTime;
    modelMaintenanceCreateUpdate.MaintenanceType = this.MaintenanceType;

    if (this.mntf.ROHeatClean.value == "Yes")
      modelMaintenanceCreateUpdate.ROLastHeatClean = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.ROAcidClean = this.mntf.ROAcidClean.value;

    if (this.mntf.ROAcidClean.value == "Yes")
      modelMaintenanceCreateUpdate.ROLastAcidClean = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.ROHPCTestPerformed = this.mntf.ROHPCTestPerformed.value;

    if (this.mntf.ROHPCTestPerformed.value == "Yes")
      modelMaintenanceCreateUpdate.ROHPCLastTested = Globals.GetAPIDate(this.today);


    modelMaintenanceCreateUpdate.ROHPCTestingLAB = this.mntf.ROHPCTestingLAB.value;
    modelMaintenanceCreateUpdate.ROHPCResults = this.mntf.ROHPCResults.value;
    modelMaintenanceCreateUpdate.ROLALTestPerformed = this.mntf.ROLALTestPerformed.value;

    if (this.mntf.ROLALTestPerformed.value == "Yes")
      modelMaintenanceCreateUpdate.ROLALLastTested = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.ROLALTestingLAB = this.mntf.ROLALTestingLAB.value;
    modelMaintenanceCreateUpdate.ROLALSensitivity = this.mntf.ROLALSensitivity.value;
    modelMaintenanceCreateUpdate.ROLALResults = this.mntf.ROLALResults.value;

    modelMaintenanceCreateUpdate.RODaysSinceLastHC = this.mntf.RODaysSinceLastHC.value;
    modelMaintenanceCreateUpdate.RODaysSinceLastAcidClean = this.mntf.RODaysSinceLastAcidClean.value;

    modelMaintenanceCreateUpdate.DMEquipmentId = this.mntf.DMEquipmentId.value;
    modelMaintenanceCreateUpdate.DMEquipmentModelID = this.mntf.DMEquipmentModelID.value;
    modelMaintenanceCreateUpdate.DMLastPMCompleted = this.mntf.DMLastPMCompleted.value;
    modelMaintenanceCreateUpdate.DMDiaSafe = this.mntf.DMDiaSafe.value;
    modelMaintenanceCreateUpdate.DMExteriorCondition = this.mntf.DMExteriorCondition.value;
    modelMaintenanceCreateUpdate.DMWandsChecked = this.mntf.DMWandsChecked.value;
    modelMaintenanceCreateUpdate.DMCheckforLeaks = this.mntf.DMCheckforLeaks.value;
    modelMaintenanceCreateUpdate.DMVerifiedBleachLevel = this.mntf.DMVerifiedBleachLevel.value;
    modelMaintenanceCreateUpdate.DMCleanAirFilter = this.mntf.DMCleanAirFilter.value;
    modelMaintenanceCreateUpdate.DMHPCTestPerformed = this.mntf.DMHPCTestPerformed.value;
    modelMaintenanceCreateUpdate.DMHours = this.mntf.DMHours.value;


    if (this.mntf.DMHPCTestPerformed.value == "Yes")
      modelMaintenanceCreateUpdate.DMHPCLastTested = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.DMHPCTestingLAB = this.mntf.DMHPCTestingLAB.value;
    modelMaintenanceCreateUpdate.DMHPCResults = this.mntf.DMHPCResults.value;

    modelMaintenanceCreateUpdate.ROBacteriaId = this.mntf.ROBacteriaId.value;
    modelMaintenanceCreateUpdate.ROPyrogenId = this.mntf.ROPyrogenId.value;
    modelMaintenanceCreateUpdate.DMBacteriaId = this.mntf.DMBacteriaId.value;
    modelMaintenanceCreateUpdate.DMPyrogenId = this.mntf.DMPyrogenId.value;

    modelMaintenanceCreateUpdate.DMLALTestPerformed = this.mntf.DMLALTestPerformed.value;

    if (this.mntf.DMLALTestPerformed.value == "Yes")
      modelMaintenanceCreateUpdate.DMLALLastTested = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.DMLALTestingLAB = this.mntf.DMLALTestingLAB.value;
    modelMaintenanceCreateUpdate.DMLALSensitivity = this.mntf.DMLALSensitivity.value;
    modelMaintenanceCreateUpdate.DMLALResults = this.mntf.DMLALResults.value;
    modelMaintenanceCreateUpdate.CFMax = this.mntf.CFMax.value;
    modelMaintenanceCreateUpdate.CFMin = this.mntf.CFMin.value;
    modelMaintenanceCreateUpdate.CFFinal = this.mntf.CFFinal.value;
    modelMaintenanceCreateUpdate.CFRatio = this.mntf.CFRatio.value;
    modelMaintenanceCreateUpdate.CFVerifySystemforLeaks = this.mntf.CFVerifySystemforLeaks.value;
    modelMaintenanceCreateUpdate.CFHardness = this.mntf.CFHardness.value;
    modelMaintenanceCreateUpdate.CFPH = this.mntf.CFPH.value;
    modelMaintenanceCreateUpdate.CFTotalChlorine = this.mntf.CFTotalChlorine.value;
    modelMaintenanceCreateUpdate.CFIron = this.mntf.CFIron.value;
    modelMaintenanceCreateUpdate.CFFloodStop = this.mntf.CFFloodStop.value;
    modelMaintenanceCreateUpdate.CF505Filters = this.mntf.CF505Filters.value;

    if (this.mntf.CF505Filters.value == "Yes")
      modelMaintenanceCreateUpdate.CF505FiltersLastReplacedDate = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.CFCarbonFilter = this.mntf.CFCarbonFilter.value;

    if (this.mntf.CFCarbonFilter.value == "Yes")
      modelMaintenanceCreateUpdate.CFCarbonFilterLastReplacedDate = Globals.GetAPIDate(this.today);

    modelMaintenanceCreateUpdate.CF10Filter = this.mntf.CF10Filter.value;

    if (this.mntf.CF10Filter.value == "Yes")
      modelMaintenanceCreateUpdate.CF10FilterLastReplacedDate = Globals.GetAPIDate(this.today);;

    modelMaintenanceCreateUpdate.CFIronFilters = this.mntf.CFIronFilters.value;

    if (this.mntf.CFIronFilters.value == "Yes")
      modelMaintenanceCreateUpdate.CFIronFiltersLastReplacedDate = Globals.GetAPIDate(this.today);;

    modelMaintenanceCreateUpdate.CFInstalledLabels = this.mntf.CFInstalledLabels.value;
    modelMaintenanceCreateUpdate.CWTFrequency = this.mntf.CWTFrequency.value;
    modelMaintenanceCreateUpdate.CWTFeed = this.mntf.CWTFeed.value;

    if (this.mntf.CWTFeed.value == "Yes")
      modelMaintenanceCreateUpdate.CWTFeedLastTested = Globals.GetAPIDate(this.today);;

    modelMaintenanceCreateUpdate.CWTFeedH2OAnalysisId = this.mntf.CWTFeedH2OAnalysisId.value;
    modelMaintenanceCreateUpdate.CWTRoProduct = this.mntf.CWTRoProduct.value;

    if (this.mntf.CWTRoProduct.value == "Yes")
      modelMaintenanceCreateUpdate.CWTRoProductLastTested = Globals.GetAPIDate(this.today);;

    if (this.mntf.CompletedSections.value)
      modelMaintenanceCreateUpdate.CompletedSections = this.mntf.CompletedSections.value;
    else
      modelMaintenanceCreateUpdate.CompletedSections = '';

    if (this.mntf.YCRoGFIPlugTested.value == "Pass")
      modelMaintenanceCreateUpdate.YCRoGFIPlugLastTested = Globals.GetAPIDate(this.today);;

    if (this.mntf.YCDmGFIPlugTested.value == "Pass")
      modelMaintenanceCreateUpdate.YCDmGFIPlugLastTested = Globals.GetAPIDate(this.today);;

    modelMaintenanceCreateUpdate.CWTRoProductH2OAnalysisId = this.mntf.CWTRoProductH2OAnalysisId.value;
    modelMaintenanceCreateUpdate.YCRoGFIPlugTested = this.mntf.YCRoGFIPlugTested.value;
    modelMaintenanceCreateUpdate.YCDmGFIPlugTested = this.mntf.YCDmGFIPlugTested.value;
    modelMaintenanceCreateUpdate.CreatedBy = this.currentUserId;
    modelMaintenanceCreateUpdate.CreatedOn = Globals.GetAPIDate(this.today);
    modelMaintenanceCreateUpdate.ModifiedBy = this.currentUserId;
    modelMaintenanceCreateUpdate.ModifiedOn = Globals.GetAPIDate(this.today);

    if (this.HFSPurpose == 'Renovation & Full Installation')
      modelMaintenanceCreateUpdate.IsFullInstallation = true;
    else
      modelMaintenanceCreateUpdate.IsFullInstallation = false;

    switch (this.NotesModule) {
      case "RO Checks & Testing": {
        modelMaintenanceCreateUpdate.note = this.roNotes.Note;
        // modelMaintenanceCreateUpdate.noteModule = "VisitPurposePlusDetails_Maintenance_" + this.NotesModule;
        modelMaintenanceCreateUpdate.noteModule = this.NotesModule;
        break;
      }
      case "Dialysis Machine Checks & Testing": {
        modelMaintenanceCreateUpdate.note = this.dmNotes.Note;
        // modelMaintenanceCreateUpdate.noteModule = "VisitPurposePlusDetails_Maintenance_" + this.NotesModule;
        modelMaintenanceCreateUpdate.noteModule = this.NotesModule;
        break;
      } case "Cart/Filters Checks": {
        modelMaintenanceCreateUpdate.note = this.cfNotes.Note;
        // modelMaintenanceCreateUpdate.noteModule = "VisitPurposePlusDetails_Maintenance_" + this.NotesModule;
        modelMaintenanceCreateUpdate.noteModule = this.NotesModule;
        break;
      } case "Comprehensive Water Testing": {
        modelMaintenanceCreateUpdate.note = this.cwtNotes.Note;
        // modelMaintenanceCreateUpdate.noteModule = "VisitPurposePlusDetails_Maintenance_" + this.NotesModule;
        modelMaintenanceCreateUpdate.noteModule = this.NotesModule;
        break;
      } case "Yearly Checks": {
        modelMaintenanceCreateUpdate.note = this.yearlyNotes.Note;
        // modelMaintenanceCreateUpdate.noteModule = "VisitPurposePlusDetails_Maintenance_" + this.NotesModule;
        modelMaintenanceCreateUpdate.noteModule = this.NotesModule;
        break;
      }
      default: {
        break;
      }
    }

    this.NotesModule = '';
    return modelMaintenanceCreateUpdate;
  }

  checkSectionCreated(section = '') {
    if (section && this.mntf.CompletedSections.value || this.IsPurposeCompleted == 'Yes') {
      if (this.mntf.CompletedSections.value.includes(section) || this.IsPurposeCompleted == 'Yes') {
        // this.save(section);
        return false;
      }
    }
    return true;
  }

  fillDMEquipments() {
    const obj = {
      DialysisStationId: this.mntf.DialysisUnitId.value,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.dmEquipmentList = list;
    });
  }

  fillROEquipments() {
    const obj = {
      DialysisStationId: this.mntf.DialysisUnitId.value,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.roEquipmentList = list;
    });
  }

  setModel() {

    AppComponent.ShowLoader();
    this.homeApi.GetDialysisStationByID({ Id: this.mntf.DialysisUnitId.value }).subscribe(
      success => {

        if (success) {

          this.mntf.DMEquipmentId.setValue(success.dmEquipmentId ? success.dmEquipmentId : '');
          this.mntf.ROEquipmentId.setValue(success.roEquipmentId ? success.roEquipmentId : '');
          this.mntf.DMEquipmentModelID.setValue(success.dmModelId ? success.dmModelId : '');

          this.ROEquipmentName = success.roEquipmentName;
          this.DMMachineName = success.dmEquipmentName;
          this.DMMachineModel = success.dmModel;

          this.homefieldApi.GetHomeFieldService_MaintenancebyServiceID({ Id: this.mntf.HFServiceId.value }).subscribe(
            success => {
              if (success) {

                setTimeout(() => {

                  this.mntf.MaintenanceId.setValue(success.maintenanceId);
                  this.mntf.HFServiceId.setValue(success.hfServiceId);

                  if (success.dialysisUnitId)
                    this.mntf.DialysisUnitId.setValue(success.dialysisUnitId);

                  if (success.roEquipmentId) {
                    this.mntf.ROEquipmentId.setValue(success.roEquipmentId);
                  }
                  if (success.dmEquipmentId) {
                    this.mntf.DMEquipmentId.setValue(success.dmEquipmentId);
                  }

                  this.mntf.ROFeedConductivity.setValue(success.roFeedConductivity);
                  this.mntf.ROProductConductivity.setValue(success.roProductConductivity);
                  this.mntf.ROReject.setValue(success.roReject);

                  this.mntf.ROHours.setValue(success.roHours);
                  if (success.roAirFilter == null) {
                    this.mntf.ROAirFilter.setValue('');
                  }
                  else {
                    this.mntf.ROAirFilter.setValue(success.roAirFilter);
                  }
                  if (success.roHeatClean == null) {
                    this.mntf.ROHeatClean.setValue('');
                  }
                  else {
                    this.mntf.ROHeatClean.setValue(success.roHeatClean);
                  }

                  if (success.roAcidClean == null) {
                    this.mntf.ROAcidClean.setValue('');
                  }
                  else {
                    this.mntf.ROAcidClean.setValue(success.roAcidClean);
                  }

                  if (success.rohpcTestPerformed == null) {
                    this.mntf.ROHPCTestPerformed.setValue('');
                  }
                  else {
                    this.mntf.ROHPCTestPerformed.setValue(success.rohpcTestPerformed);
                    if (this.mntf.ROHPCTestPerformed.value == "Yes") {
                      this.mntf.ROHPCTestPerformed.disable();

                    }
                  }
                  this.mntf.ROHPCTestingLAB.setValue(success.rohpcTestingLAB);
                  this.mntf.ROHPCResults.setValue(success.rohpcResults);
                  if (success.rolalTestPerformed == null) {
                    this.mntf.ROLALTestPerformed.setValue('');
                  }
                  else {
                    this.mntf.ROLALTestPerformed.setValue(success.rolalTestPerformed);
                    if (this.mntf.ROLALTestPerformed.value == "Yes") {
                      this.mntf.ROLALTestPerformed.disable();
                    }
                  }

                  // this.mntf.ROLALLastTested.setValue(success.rolalLastTested);

                  if (this.mntf.ROLALSensitivity.value == null) {
                    this.mntf.ROLALSensitivity.setValue('');
                  }
                  else {
                    this.mntf.ROLALSensitivity.setValue(success.rolalSensitivity);
                  }

                  this.mntf.ROLALTestingLAB.setValue(success.rolalTestingLAB);

                  if (this.mntf.ROLALResults.value == null) {
                    this.mntf.ROLALResults.setValue('');
                  }
                  else {
                    this.mntf.ROLALResults.setValue(success.rolalResults);
                  }

                  this.mntf.RODaysSinceLastHC.setValue(success.roDaysSinceLastHC);
                  this.mntf.RODaysSinceLastAcidClean.setValue(success.roDaysSinceLastAcidClean);


                  this.mntf.DMEquipmentModelID.setValue(success.dmEquipmentModelID);
                  // this.mntf.DMLastPMCompleted.setValue(success.dmLastPMCompleted);
                  if (success.dmDiaSafe == null) {
                    this.mntf.DMDiaSafe.setValue('');
                  }
                  else {
                    this.mntf.DMDiaSafe.setValue(success.dmDiaSafe);
                  }

                  if (success.dmExteriorCondition == null) {
                    this.mntf.DMExteriorCondition.setValue('');
                  }
                  else {
                    this.mntf.DMExteriorCondition.setValue(success.dmExteriorCondition);
                  }

                  if (success.dmWandsChecked == null) {
                    this.mntf.DMWandsChecked.setValue('');
                  }
                  else {
                    this.mntf.DMWandsChecked.setValue(success.dmWandsChecked);
                  }

                  if (success.dmCheckforLeaks == null) {
                    this.mntf.DMCheckforLeaks.setValue('');
                  }
                  else {
                    this.mntf.DMCheckforLeaks.setValue(success.dmCheckforLeaks);
                  }

                  if (success.dmVerifiedBleachLevel == null) {
                    this.mntf.DMVerifiedBleachLevel.setValue('');
                  }
                  else {
                    this.mntf.DMVerifiedBleachLevel.setValue(success.dmVerifiedBleachLevel);
                  }

                  if (success.dmCleanAirFilter == null) {
                    this.mntf.DMCleanAirFilter.setValue('');
                  }
                  else {
                    this.mntf.DMCleanAirFilter.setValue(success.dmCleanAirFilter);
                  }

                  this.mntf.DMHours.setValue(success.dmHours);

                  if (success.dmhpcTestPerformed == null) {
                    this.mntf.DMHPCTestPerformed.setValue('');
                  }
                  else {
                    if (success.dmhpcTestPerformed == "Yes")
                      this.mntf.DMHPCTestPerformed.disable();

                    this.mntf.DMHPCTestPerformed.setValue(success.dmhpcTestPerformed);
                  }

                  // this.mntf.DMHPCLastTested.setValue(success.dmhpcLastTested);
                  this.mntf.DMHPCTestingLAB.setValue(success.dmhpcTestingLAB);
                  this.mntf.DMHPCResults.setValue(success.dmhpcResults);
                  if (success.dmlalTestPerformed == null) {
                    this.mntf.DMLALTestPerformed.setValue('');
                  }
                  else {
                    if (success.dmlalTestPerformed == "Yes")
                      this.mntf.DMLALTestPerformed.disable();

                    this.mntf.DMLALTestPerformed.setValue(success.dmlalTestPerformed);
                  }

                  // this.mntf.DMLALLastTested.setValue(success.dmlalLastTested);
                  this.mntf.DMLALTestingLAB.setValue(success.dmlalTestingLAB);
                  if (success.dmlalSensitivity == null) {
                    this.mntf.DMLALSensitivity.setValue('');
                  }
                  else {
                    this.mntf.DMLALSensitivity.setValue(success.dmlalSensitivity);
                  }

                  this.mntf.DMLALResults.setValue(success.dmlalResults);
                  this.mntf.CFMax.setValue(success.cfMax);
                  this.mntf.CFMin.setValue(success.cfMin);
                  this.mntf.CFFinal.setValue(success.cfFinal);
                  this.mntf.CFRatio.setValue(success.cfRatio);

                  if (success.cfVerifySystemforLeaks == null) {
                    this.mntf.CFVerifySystemforLeaks.setValue('');
                  }
                  else {
                    this.mntf.CFVerifySystemforLeaks.setValue(success.cfVerifySystemforLeaks);
                  }

                  this.mntf.CFHardness.setValue(success.cfHardness);
                  this.mntf.CFPH.setValue(success.cfph);
                  this.mntf.CFTotalChlorine.setValue(success.cfTotalChlorine);
                  this.mntf.CFIron.setValue(success.cfIron);
                  if (success.cfFloodStop == null) {
                    this.mntf.CFFloodStop.setValue('');
                  }
                  else {
                    this.mntf.CFFloodStop.setValue(success.cfFloodStop);
                  }

                  if (success.cF505Filters == null)
                    this.mntf.CF505Filters.setValue('');
                  else
                    this.mntf.CF505Filters.setValue(success.cF505Filters);

                  if (success.cfCarbonFilter == null)
                    this.mntf.CFCarbonFilter.setValue('');
                  else
                    this.mntf.CFCarbonFilter.setValue(success.cfCarbonFilter);

                  if (success.cF10Filter == null)
                    this.mntf.CF10Filter.setValue('');
                  else
                    this.mntf.CF10Filter.setValue(success.cF10Filter);

                  if (success.cfIronFilters == null)
                    this.mntf.CFIronFilters.setValue('');
                  else
                    this.mntf.CFIronFilters.setValue(success.cfIronFilters);

                  if (success.cfInstalledLabels == null)
                    this.mntf.CFInstalledLabels.setValue('');
                  else
                    this.mntf.CFInstalledLabels.setValue(success.cfInstalledLabels);

                  this.mntf.CWTFrequency.setValue(success.cwtFrequency);

                  if (success.cwtFeed == null) {
                    this.mntf.CWTFeed.setValue('');
                  }
                  else {
                    this.mntf.CWTFeed.setValue(success.cwtFeed);
                  }
                  // this.mntf.CWTFeedLastTested.setValue(success.cwtFeedLastTested);
                  this.mntf.CWTFeedH2OAnalysisId.setValue(success.cwtFeedH2OAnalysisId);

                  if (this.mntf.CWTFeedH2OAnalysisId.value || this.mntf.CWTFeed.value == 'Yes')
                    this.mntf.CWTFeed.disable();
                  else
                    this.mntf.CWTFeed.enable();

                  if (success.cwtRoProduct == null) {
                    this.mntf.CWTRoProduct.setValue('');
                  }
                  else {
                    this.mntf.CWTRoProduct.setValue(success.cwtRoProduct);
                  }
                  // this.mntf.CWTRoProductLastTested.setValue(success.cwtRoProductLastTested);
                  this.mntf.CWTRoProductH2OAnalysisId.setValue(success.cwtRoProductH2OAnalysisId);

                  if (this.mntf.CWTRoProductH2OAnalysisId.value || this.mntf.CWTRoProduct.value == 'Yes')
                    this.mntf.CWTRoProduct.disable();
                  else
                    this.mntf.CWTRoProduct.enable();

                  this.mntf.CWTFeedReportResultsDate.setValue(success.cwtFeedReportResultsDate);
                  this.mntf.CWTFeedReportID.setValue(success.cwtFeedReportID);
                  this.mntf.CWTRoProductReportResultsDate.setValue(success.cwtRoProductReportResultsDate);
                  this.mntf.CWTRoProductReportID.setValue(success.cwtRoProductReportID);

                  if (success.ycRoGFIPlugTested == null)
                    this.mntf.YCRoGFIPlugTested.setValue('');
                  else
                    this.mntf.YCRoGFIPlugTested.setValue(success.ycRoGFIPlugTested);

                  if (success.ycDmGFIPlugTested == null)
                    this.mntf.YCDmGFIPlugTested.setValue('');
                  else
                    this.mntf.YCDmGFIPlugTested.setValue(success.ycDmGFIPlugTested);

                  this.mntf.CreatedBy.setValue(success.createdBy);
                  this.mntf.CreatedOn.setValue(success.createdOn);
                  this.mntf.ModifiedBy.setValue(success.modifiedBy);
                  this.mntf.ModifiedOn.setValue(success.modifiedOn);

                  this.mntf.ROBacteriaId.setValue(success.roBacteriaId);
                  this.mntf.ROPyrogenId.setValue(success.roPyrogenId);
                  this.mntf.DMBacteriaId.setValue(success.dmBacteriaId);
                  this.mntf.DMPyrogenId.setValue(success.dmPyrogenId);

                  if (this.mntf.ROBacteriaId.value || this.mntf.ROPyrogenId.value)
                    this.mntf.ROEquipmentId.disable();
                  else
                    this.mntf.ROEquipmentId.enable();

                  if (this.mntf.DMBacteriaId.value || this.mntf.DMPyrogenId.value)
                    this.mntf.DMEquipmentId.disable();
                  else
                    this.mntf.DMEquipmentId.enable();

                  this.mntf.CompletedSections.setValue(success.completedSections);

                  if (success.completedSections) {
                    if (success.completedSections.includes('RO Checks & Testing')) {
                      this.mntf.ROHours.disable();
                    }

                    if (success.completedSections.includes('Dialysis Machine Checks & Testing')) {
                      if (this.mntf.DMHours.value)
                        this.mntf.DMHours.disable();
                      else
                        this.mntf.DMHours.enable();
                    }
                  }
                });
              }
              this.fetchLastDates();
              this.fetchNotes();
              setTimeout(() => {
                this.GetSelectedROHours();
                this.GetSelectedDMHours();
                this.isSectionEnable('RO Checks & Testing');
                this.isSectionEnable('Dialysis Machine Checks & Testing');
                this.isSectionEnable('Cart/Filters Checks');
                this.isSectionEnable('Comprehensive Water Testing');
                this.isSectionEnable('Yearly Checks');
              }, 500);

              AppComponent.HideLoader();
            },
            error => {
              AppComponent.addAlert(error.message, 'error');
            });
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }


  fetchLastDates() {
    const obj = {
      HFServiceId: this.mntf.HFServiceId.value,
      DialysisUnitId: this.mntf.DialysisUnitId.value,
      ROEquipmentId: this.mntf.ROEquipmentId.value,
      DMEquipmentId: this.mntf.DMEquipmentId.value
    };
    this.homefieldApi.GetHomeFieldService_Maintenance_LastCompletedDates(obj).subscribe(
      success => {
        if (success) {
          this.mntf.ROLastHeatClean.setValue(success.roLastHeatClean);
          this.mntf.ROLastAcidClean.setValue(success.roLastAcidClean);

          this.lastAcidCleanDays = this.getDiferenceInDays(success.roLastAcidClean);
          this.lastHeatCleanDays = this.getDiferenceInDays(success.roLastHeatClean);

          this.mntf.ROHPCLastTested.setValue(success.rohpcLastTested);
          this.mntf.ROLALLastTested.setValue(success.rolalLastTested);
          this.mntf.DMLastPMCompleted.setValue(success.dmLastPMCompleted);
          this.mntf.DMHPCLastTested.setValue(success.dmhpcLastTested);
          this.mntf.DMLALLastTested.setValue(success.dmlalLastTested);

          this.mntf.CF505FiltersLastReplacedDate.setValue(success.cF505FiltersLastReplacedDate);
          this.mntf.CFCarbonFilterLastReplacedDate.setValue(success.cfCarbonFilterLastReplacedDate);
          this.mntf.CF10FilterLastReplacedDate.setValue(success.cF10FilterLastReplacedDate);
          this.mntf.CFIronFiltersLastReplacedDate.setValue(success.cfIronFiltersLastReplacedDate);
          this.mntf.CWTFeedLastTested.setValue(success.cwtFeedLastTested);
          this.mntf.CWTRoProductLastTested.setValue(success.cwtRoProductLastTested);
          this.mntf.YCDmGFIPlugLastTested.setValue(success.ycDmGFIPlugLastTested);
          this.mntf.YCRoGFIPlugLastTested.setValue(success.ycRoGFIPlugLastTested);
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      });
  }

  fetchNotes() {
    if (this.mntf.HFServiceId.value && this.mntf.HFServiceId.value != '0') {
      const hfs = { Id: this.mntf.HFServiceId.value };
      this.homefieldApi.GetHomeFieldService_MasterNotes(hfs).subscribe(list => {
        if (list.length > 0) {
          this.roNotes.NoteList = list.filter((item, index) => item.moduleName == 'RO Checks & Testing');
          this.dmNotes.NoteList = list.filter((item, index) => item.moduleName == 'Dialysis Machine Checks & Testing');
          this.cfNotes.NoteList = list.filter((item, index) => item.moduleName == 'Cart/Filters Checks');
          this.cwtNotes.NoteList = list.filter((item, index) => item.moduleName == 'Comprehensive Water Testing');
          this.yearlyNotes.NoteList = list.filter((item, index) => item.moduleName == 'Yearly Checks');
        }
      });
    }
  }

  openQuickDialogFeed(e) {
    if (this.mntf.CWTFeed.value == "Yes")
      this.quickAddWaterAnalysisDialog("Feed");
  }

  openQuickDialogProduct(e) {
    if (this.mntf.CWTRoProduct.value == "Yes")
      this.quickAddWaterAnalysisDialog("RoProduct");
  }


  quickAddWaterAnalysisDialog(analysisType) {
    if (this.mntf.DialysisUnitId.value !== 0) {
      this.quickAddDialogRef = this.dialog.open(WaterProcessAnalysisComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '70vh',
        maxHeight: '100vh',
        data: {

        }
      });

      var message = "";
      this.quickAddDialogRef.componentInstance.FullScreen = false;
      if (analysisType == "Feed") {
        message = "Water Analysis done by Feed Comprehensive Water Testing - Home Field Service";
        this.quickAddDialogRef.componentInstance.setDialysisUnitAndStation('Home Program', this.mntf.DialysisUnitId.value, message, this.mntf.CWTFeedH2OAnalysisId.value);
      }
      else {
        message = "Water Analysis done by RO Product Comprehensive Water Testing - Home Field Service";
        this.quickAddDialogRef.componentInstance.setDialysisUnitAndStation('Home Program', this.mntf.DialysisUnitId.value, message, this.mntf.CWTRoProductH2OAnalysisId.value);
      }

      this.quickAddDialogRef.afterClosed().subscribe(result => {

        if (analysisType == 'Feed' && !this.mntf.CWTFeedH2OAnalysisId.value) {
          this.mntf.CWTFeed.setValue('');
        }
        else if (analysisType == 'RoProduct' && !this.mntf.CWTRoProductH2OAnalysisId.value) {
          this.mntf.CWTRoProduct.setValue('');
        }
      });

      this.quickAddDialogRef.componentInstance.SaveEvent.subscribe(evt => {

        if (analysisType == "Feed") {
          this.mntf.CWTFeedH2OAnalysisId.setValue(evt.waterProcessAnalysisId);
          this.mntf.CWTFeed.disable();
        }
        else {
          this.mntf.CWTRoProductH2OAnalysisId.setValue(evt.waterProcessAnalysisId);
          this.mntf.CWTRoProduct.disable();
        }
      });
    }
    else {
      AppComponent.addAlert("Select BMR Home Unit First.", "error");
    }
  }

  onChangeROHPCTest() {

    if (!this.mntf.ROEquipmentId.value) {
      this.mntf.ROEquipmentId.markAsPending();
      this.errormsg = "Please select RO ID First.";
      // this.saveMaintenance('RO Checks & Testing');
      this.mntf.ROHPCTestPerformed.setValue('');
    }

    if (!this.mntf.ROHours.value || parseFloat(this.mntf.ROHours.value) >= parseFloat(this.selectedROHours.toString())) {
      if (this.mntf.ROHPCTestPerformed.value == "Yes") {

        this.quickAddBacteriaDialog('RO');
      }
    }
    else {
      this.errormsg = "RO Hours => previous  RO Hours";
      // this.saveMaintenance('RO Checks & Testing');
      this.mntf.ROHPCTestPerformed.setValue('');
    }
  }

  onChangeDMHPCTest() {

    if (!this.mntf.DMEquipmentId.value) {
      this.mntf.DMEquipmentId.markAsPending();
      this.errormsg = "Please select DM ID First.";
      // this.saveMaintenance('RO Checks & Testing');
      this.mntf.DMHPCTestPerformed.setValue('');
    }

    if (!this.mntf.DMHours.value || parseFloat(this.mntf.DMHours.value) >= parseFloat(this.selectedDMHours.toString())) {
      if (this.mntf.DMHPCTestPerformed.value == "Yes") {
        this.quickAddBacteriaDialog('DM');
      }
    }
    else {
      this.errormsg = "DM Hours => previous  DM Hours";
      this.mntf.DMHPCTestPerformed.setValue('');

    }
  }

  quickAddBacteriaDialog(type) {

    if (this.mntf.DialysisUnitId.value !== 0) {
      this.quickAddBacteriaDialogRef = this.dialog.open(BacteriaComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '70vh',
        maxHeight: '100vh',
      });

      this.quickAddBacteriaDialogRef.componentInstance.clearValues();

      this.quickAddBacteriaDialogRef.componentInstance.FullScreen = false;
      if (type == 'RO') {

        if (this.mntf.ROBacteriaId.value) {
          this.quickAddBacteriaDialogRef.componentInstance.f.BacteriasId.setValue(this.mntf.ROBacteriaId.value);
          this.quickAddBacteriaDialogRef.componentInstance.f.TestingLab.disable();
        }
        else {
          this.quickAddBacteriaDialogRef.componentInstance.f.TestingLab.enable();
        }
      }
      if (type == 'DM') {
        if (this.mntf.DMBacteriaId.value) {
          this.quickAddBacteriaDialogRef.componentInstance.f.BacteriasId.setValue(this.mntf.DMBacteriaId.value);
          this.quickAddBacteriaDialogRef.componentInstance.f.TestingLab.disable();
        }
        else {
          this.quickAddBacteriaDialogRef.componentInstance.f.TestingLab.enable();
        }
      }

      this.quickAddBacteriaDialogRef.componentInstance.f.SampleReadById.setValue(this.currentUserId);
      this.quickAddBacteriaDialogRef.componentInstance.f.CollectionDate.setValue({
        date: {
          year: this.today.getFullYear(),
          month: this.today.getMonth() + 1,
          day: this.today.getDate()
        },
        jsdate: this.today
      });

      this.quickAddBacteriaDialogRef.componentInstance.f.CollectionTime.setValue(
        padNumber(this.today.getHours()) +
        ":" +
        padNumber(this.today.getMinutes())
      );

      let hours = "";
      if (type == "RO" && this.mntf.ROHours.value) {
        hours = this.mntf.ROHours.value;
      }

      if (type == "DM" && this.mntf.DMHours.value) {
        hours = this.mntf.DMHours.value;
      }
      if (type == "RO") {
        this.quickAddBacteriaDialogRef.componentInstance.setDialysisUnitAndMachines
          ('Home Program', this.mntf.DialysisUnitId.value, type, hours, this.HFSPurpose, this.mntf.ROEquipmentId.value, this.ROEquipmentName);
      } else if (type == 'DM') {
        this.quickAddBacteriaDialogRef.componentInstance.setDialysisUnitAndMachines
          ('Home Program', this.mntf.DialysisUnitId.value, type, hours, this.HFSPurpose, this.mntf.DMEquipmentId.value, this.DMMachineName);
      }
      this.quickAddBacteriaDialogRef.afterClosed().subscribe(result => {

        if (!result) {
          if (type == 'RO' && !this.mntf.ROBacteriaId.value) {
            this.mntf.ROHPCTestPerformed.setValue('');
          }
          if (type == 'DM' && !this.mntf.DMBacteriaId.value) {
            this.mntf.DMHPCTestPerformed.setValue('');
          }
        }
      });

      this.quickAddBacteriaDialogRef.componentInstance.SaveEvent.subscribe(evt => {

        if (type == 'RO') {

          if (evt && evt.machaineList && evt.machaineList[0] && evt.machaineList[0].dialysisMachineHours) {
            this.mntf.ROHours.setValue(evt.machaineList[0].dialysisMachineHours);
            this.mntf.ROHours.disable();
          }

          this.mntf.ROEquipmentId.disable();
          this.mntf.ROHPCTestPerformed.disable();
          this.mntf.ROBacteriaId.setValue(evt.bacteriaId);

          if (this.HFSPurpose != 'Renovation & Full Installation') {
            this.save('RO Checks & Testing', false).then(() => {
              this.setModel();
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }
        }
        if (type == 'DM') {

          if (evt && evt.machaineList && evt.machaineList[0] && evt.machaineList[0].dialysisMachineHours) {
            this.mntf.DMHours.setValue(evt.machaineList[0].dialysisMachineHours);
            if (this.mntf.DMHours.value)
              this.mntf.DMHours.disable();
            else
              this.mntf.DMHours.enable();
          }
          this.mntf.DMHPCTestPerformed.disable();
          this.mntf.DMEquipmentId.disable();
          this.mntf.DMBacteriaId.setValue(evt.bacteriaId);

          if (this.HFSPurpose != 'Renovation & Full Installation') {
            this.save('Dialysis Machine Checks & Testing', false).then(() => {
              this.setModel();
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }

        }

      });
    }
    else {
      AppComponent.addAlert("Select  BMR Home Unit First.", "error");
    }
  }

  onChangeROLALTest() {

    if (!this.mntf.ROEquipmentId.value) {
      this.mntf.ROEquipmentId.markAsPending();
      this.errormsg = "Please select RO ID First.";
      // this.saveMaintenance('RO Checks & Testing');
      this.mntf.ROLALTestPerformed.setValue('');
    }

    if (!this.mntf.ROHours.value || parseFloat(this.mntf.ROHours.value) >= parseFloat(this.selectedROHours.toString())) {

      if (this.mntf.ROLALTestPerformed.value == "Yes") {
        this.quickAddPyrogenDialog("RO");
      }
    }
    else {
      this.errormsg = "RO Hours => previous  RO Hours";
      this.mntf.ROLALTestPerformed.setValue('');
    }
  }

  onChangeDMLALTest() {

    if (!this.mntf.DMEquipmentId.value) {
      this.mntf.DMEquipmentId.markAsPending();
      this.errormsg = "Please select DM ID First.";
      // this.saveMaintenance('RO Checks & Testing');
      this.mntf.DMLALTestPerformed.setValue('');
    }

    if (!this.mntf.DMHours.value || parseFloat(this.mntf.DMHours.value) >= parseFloat(this.selectedDMHours.toString())) {
      if (this.mntf.DMLALTestPerformed.value == "Yes") {
        this.quickAddPyrogenDialog("DM");
      }
    }
    else {
      this.errormsg = "DM Hours => previous  DM Hours";
      this.mntf.DMLALTestPerformed.setValue('');
    }
  }

  quickAddPyrogenDialog(type) {
    if (this.mntf.DialysisUnitId.value !== 0) {
      this.quickAddPyrogenDialogRef = this.dialog.open(PyrogenComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '70vh',
        maxHeight: '100vh'
      });
      this.quickAddPyrogenDialogRef.componentInstance.FullScreen = false;

      if (type == "RO" && this.mntf.ROPyrogenId.value)
        this.quickAddPyrogenDialogRef.componentInstance.f.PyrogenDetailsId.setValue(this.mntf.ROPyrogenId.value);
      else if (type == "DM" && this.mntf.DMPyrogenId.value)
        this.quickAddPyrogenDialogRef.componentInstance.f.PyrogenDetailsId.setValue(this.mntf.DMPyrogenId.value);

      let hours = "";
      if (type == "RO" && this.mntf.ROHours.value) {
        hours = this.mntf.ROHours.value;
      }

      if (type == "DM" && this.mntf.DMHours.value) {
        hours = this.mntf.DMHours.value;
      }

      if (type == "RO" && this.mntf.ROPyrogenId.value && this.mntf.ROPyrogenId.value > 0) {
        this.quickAddPyrogenDialogRef.componentInstance.f.TestingLab.disable();

      }
      else if (type == "DM" && this.mntf.DMPyrogenId.value && this.mntf.DMPyrogenId.value > 0) {
        this.quickAddPyrogenDialogRef.componentInstance.f.TestingLab.disable();

      }
      else {
        this.quickAddPyrogenDialogRef.componentInstance.f.TestingLab.enable();
      }


      this.quickAddPyrogenDialogRef.componentInstance.f.CollectionById.setValue(this.currentUserId);
      //this.quickAddPyrogenDialogRef.componentInstance.setDialysisUnitAndMachines('Home Program', this.mntf.DialysisUnitId.value, type, hours);

      if (type == "RO") {
        this.quickAddPyrogenDialogRef.componentInstance.setDialysisUnitAndMachines
          ('Home Program', this.mntf.DialysisUnitId.value, type, hours, this.HFSPurpose, this.mntf.ROEquipmentId.value, this.ROEquipmentName);
      } else if (type == 'DM') {
        this.quickAddPyrogenDialogRef.componentInstance.setDialysisUnitAndMachines
          ('Home Program', this.mntf.DialysisUnitId.value, type, hours, this.HFSPurpose, this.mntf.DMEquipmentId.value, this.DMMachineName);
      }

      this.quickAddPyrogenDialogRef.afterClosed().subscribe(result => {
        if (!result) {
          if (type == 'RO' && !this.mntf.ROPyrogenId.value) {
            this.mntf.ROLALTestPerformed.setValue('');
          }
          if (type == 'DM' && !this.mntf.DMPyrogenId.value) {
            this.mntf.DMLALTestPerformed.setValue('');
          }
        }
      });

      this.quickAddPyrogenDialogRef.componentInstance.SaveEvent.subscribe(evt => {


        if (type == 'DM') {

          if (evt && evt.machaineList && evt.machaineList[0] && evt.machaineList[0].dialysisMachineHours) {
            this.mntf.DMHours.setValue(evt.machaineList[0].dialysisMachineHours);
            if (this.mntf.DMHours.value)
              this.mntf.DMHours.disable();
            else
              this.mntf.DMHours.enable();

          }
          this.mntf.DMLALTestPerformed.disable();
          this.mntf.DMEquipmentId.disable();

          this.mntf.DMPyrogenId.setValue(evt.pyrogendetailsid);

          if (this.HFSPurpose != 'Renovation & Full Installation') {
            this.save('Dialysis Machine Checks & Testing', false).then(() => {

              this.setModel();
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }
        }
        else if (type == 'RO') {
          if (evt && evt.machaineList && evt.machaineList[0] && evt.machaineList[0].dialysisMachineHours) {
            this.mntf.ROHours.setValue(evt.machaineList[0].dialysisMachineHours);
            this.mntf.ROHours.disable();

          }
          this.mntf.ROLALTestPerformed.disable();
          this.mntf.ROEquipmentId.disable();

          this.mntf.ROPyrogenId.setValue(evt.pyrogendetailsid);

          if (this.HFSPurpose != 'Renovation & Full Installation') {
            this.save('RO Checks & Testing', false).then(() => {
              this.setModel();
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }
        }
      });
    }
    else {
      AppComponent.addAlert("Select BMR Home Unit First.", "error");
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  executeNotes(notesModule) {

    this.NotesModule = notesModule;
    let notes = '';
    switch (notesModule) {
      case "RO Checks & Testing":
        notes = this.roNotes.Note;
        break;
      case "Dialysis Machine Checks & Testing":
        notes = this.dmNotes.Note;
        break;
      case "Cart/Filters Checks":
        notes = this.cfNotes.Note;
        break;
      case "Comprehensive Water Testing":
        notes = this.cwtNotes.Note;
        break;
      case "Yearly Checks":
        notes = this.yearlyNotes.Note;
        break;
      default:
        break;
    }
    this.addNoteClick.emit({ 'noteModule': notesModule, 'note': notes });
    setTimeout(() => {
      this.fetchNotes();
    }, 500);
    // this.close();
  }

  clearNotes() {

    if (this.roNotes) {
      this.roNotes.showHours = false;
      this.roNotes.Note = '';
    }

    if (this.dmNotes) {
      this.dmNotes.showHours = false;
      this.dmNotes.Note = '';
    }

    if (this.cwtNotes) {
      this.cwtNotes.showHours = false;
      this.cwtNotes.Note = '';
    }

    if (this.cfNotes) {
      this.cfNotes.showHours = false;
      this.cfNotes.Note = '';
    }

    if (this.yearlyNotes) {
      this.yearlyNotes.showHours = false;
      this.yearlyNotes.Note = '';
    }
  }

  OpenHistory(EquipId) {

    AppComponent.ShowLoader();

    const inventory = {
      Id: EquipId
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {
          // EquipId, SerialNo, Name, InserviceDate
          this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
            disableClose: true,
            position: { right: '0', top: '0' },
            height: '100%',
            width: '70%',
            // maxWidth: '60vh',
            maxHeight: '100vh'
          });

          this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
          this.machineHistoryDialogRef.componentInstance.SrNo = success.serialnumber;
          this.machineHistoryDialogRef.componentInstance.MachineName = success.equipmentcode;
          this.machineHistoryDialogRef.componentInstance.InServiceDate = success.inservicedate;
          // this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
          //   '95%',
          //   '95%'
          // );
          this.machineHistoryDialogRef.afterClosed().subscribe(result => {
            this.machineHistoryDialogRef = null;
          });

        }
        AppComponent.HideLoader();
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');

        AppComponent.HideLoader();
      }
    );

  }

  saveAndClose() {

    if (this.maintenanceForm.dirty) {
      if (this.maintenanceForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

          if (result)
            this.dialogRef.close(false);
          else
            this.saveMaintenance();
        });
      }
      else {
        if (this.checkValueExistInSections() != '')
          this.saveMaintenance();
        else
          this.dialogRef.close(false);
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  saveMaintenance(type = '') {


    this.save(type, type == '').then(() => {
      this.SaveEvent.emit();
      if (type == '')
        this.close();
      else
        this.expandDefaultSection(type, false);
    }, (err) => {
      AppComponent.addAlert("Error", 'error');
    });
  }

  transferStationEquipment(type) {

    this.quickUpdateTransferDialogRef = this.dialog.open(QuickHomeEquipmentTransfer, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      maxWidth: '50vh',
      maxHeight: '100vh',
      data: {
        type: type,
        stationId: this.mntf.DialysisUnitId.value,
        stationName: this.mntf.dialysisUnitName.value,
        roEquipmentId: this.mntf.ROEquipmentId.value,
        dmEquipmentId: this.mntf.DMEquipmentId.value,
      }
    });

    this.quickUpdateTransferDialogRef.afterClosed().subscribe(result => {
    });

    this.quickUpdateTransferDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      this.mntf.DMEquipmentId.setValue(evt.dmEquipmentId);
      this.mntf.ROEquipmentId.setValue(evt.roEquipmentId);

      // this.save('');
      setTimeout(() => {
        this.setModel();
      });
    });
  }

  formChange(enable = false) {
    if (enable) {
      this.maintenanceForm.enable();

      if (document.getElementById('btnDMHistory'))
        document.getElementById('btnDMHistory').style.display = 'inline-block';

      if (document.getElementById('btnDMExchange'))
        document.getElementById('btnDMExchange').style.display = 'inline-block';

      if (document.getElementById('btnROHistory'))
        document.getElementById('btnROHistory').style.display = 'inline-block';

      if (document.getElementById('btnROExchange'))
        document.getElementById('btnROExchange').style.display = 'inline-block';

    }
    else {
      this.maintenanceForm.disable();

      if (document.getElementById('btnDMHistory'))
        document.getElementById('btnDMHistory').style.display = 'none';

      if (document.getElementById('btnDMExchange'))
        document.getElementById('btnDMExchange').style.display = 'none';

      if (document.getElementById('btnROHistory'))
        document.getElementById('btnROHistory').style.display = 'none';

      if (document.getElementById('btnROExchange'))
        document.getElementById('btnROExchange').style.display = 'none';

    }
  }

  ReplaceLeadingZero(ctl, count = 1) {

    let val = this.mntf[ctl].value;

    if (val != null)
      val = val.replace(/^0+/, '');

    if (val == 0)
      val = "";

    if (val < 1) {
      let valDecimal = this.decimalPlaces(val);
      if (valDecimal === 1) {
        val = "0" + val;
      }
      if (valDecimal === 2 && count === 2) {
        val = "0" + val;
      }
    }
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        if (count == 2)
          val = val + ".00";
        else
          val = val + ".0";
      }
      else if (Number.isInteger(parseFloat(val)) && val.indexOf('0.') === -1 && val.indexOf('.') !== -1 && val.indexOf('.0') === -1) {
        if (count == 2)
          val = val + "00";
        else
          val = val + "0";
      }
      else if (val.indexOf('.') == 1 && val.length == 3) {
        if (count == 2)
          val = val + "0";
      }

    }
    this.mntf[ctl].setValue(val);
  }



  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }

  getColor(item, colorList) {
    if (colorList.filter(x => x.color == "red" && x.value == item).length > 0)
      return "red";
    else
      return "#555";
  }

  expandDefaultSection(sectionName, expand = true) {
    if (sectionName.includes("Yearly Checks")) {
      this.isCollapsed.roChecks = false;
      this.isCollapsed.dmChecks = false;
      this.isCollapsed.cfChecks = false;
      this.isCollapsed.cwtChecks = false;
      this.isCollapsed.yearlyChecks = expand;
    }
    if (sectionName.includes("Comprehensive Water Testing")) {
      this.isCollapsed.roChecks = false;
      this.isCollapsed.dmChecks = false;
      this.isCollapsed.cfChecks = false;
      this.isCollapsed.cwtChecks = expand;
      this.isCollapsed.yearlyChecks = false;
    }
    if (sectionName.includes("Cart/Filters Checks")) {
      this.isCollapsed.roChecks = false;
      this.isCollapsed.dmChecks = false;
      this.isCollapsed.cfChecks = expand;
      this.isCollapsed.cwtChecks = false;
      this.isCollapsed.yearlyChecks = false;
    }
    if (sectionName.includes("Dialysis Machine Checks & Testing")) {
      this.isCollapsed.roChecks = false;
      this.isCollapsed.dmChecks = expand;
      this.isCollapsed.cfChecks = false;
      this.isCollapsed.cwtChecks = false;
      this.isCollapsed.yearlyChecks = false;
    }
    if (sectionName.includes('RO Checks & Testing')) {
      this.isCollapsed.roChecks = expand;
      this.isCollapsed.dmChecks = false;
      this.isCollapsed.cfChecks = false;
      this.isCollapsed.cwtChecks = false;
      this.isCollapsed.yearlyChecks = false;

    }




  }

  checkValueExistInSections() {
    let sections = "";

    if ((this.HFSPurpose == 'Renovation & Full Installation') ||
      this.mntf.ROFeedConductivity.value ||
      this.mntf.ROProductConductivity.value ||
      this.mntf.ROReject.value ||
      this.mntf.ROHours.value ||
      this.mntf.ROAirFilter.value ||
      this.mntf.ROHeatClean.value ||
      this.mntf.ROAcidClean.value ||
      this.mntf.ROHPCTestPerformed.value ||
      this.mntf.ROLALTestPerformed.value) {
      if (sections)
        sections = sections + ",RO Checks & Testing";
      else
        sections += "RO Checks & Testing";
    }

    if ((this.HFSPurpose == 'Renovation & Full Installation') ||
      this.mntf.DMHours.value ||
      this.mntf.DMHours.value ||
      this.mntf.DMDiaSafe.value ||
      this.mntf.DMExteriorCondition.value ||
      this.mntf.DMWandsChecked.value ||
      this.mntf.DMCheckforLeaks.value ||
      this.mntf.DMVerifiedBleachLevel.value ||
      this.mntf.DMCleanAirFilter.value ||
      this.mntf.DMHPCTestPerformed.value ||
      this.mntf.DMLALTestPerformed.value) {
      {
        if (sections)
          sections = sections + ",Dialysis Machine Checks & Testing";
        else
          sections += "Dialysis Machine Checks & Testing";
      }
    }


    if (this.mntf.CFMax.value ||
      this.mntf.CFMin.value ||
      this.mntf.CFFinal.value ||
      this.mntf.CFVerifySystemforLeaks.value ||
      this.mntf.CFHardness.value ||
      this.mntf.CFPH.value ||
      this.mntf.CFTotalChlorine.value ||
      this.mntf.CFIron.value ||
      this.mntf.CFFloodStop.value ||
      this.mntf.CF505Filters.value ||
      this.mntf.CFCarbonFilter.value ||
      this.mntf.CF10Filter.value ||
      (this.mntf.CFIronFilters.value && this.isIronFilterRequired) ||
      this.mntf.CFInstalledLabels.value) {
      if (sections)
        sections = sections + ",Cart/Filters Checks";
      else
        sections += "Cart/Filters Checks";
    }

    if (this.mntf.CWTFeed.value ||
      this.mntf.CWTRoProduct.value) {
      if (sections)
        sections = sections + ",Comprehensive Water Testing";
      else
        sections += "Comprehensive Water Testing";
    }

    if (this.mntf.YCRoGFIPlugTested.value ||
      this.mntf.YCDmGFIPlugTested.value) {
      if (sections)
        sections = sections + ",Yearly Checks";
      else
        sections += "Yearly Checks";
    }
    return sections;
  }

  checkRange(control, MinValue, MaxValue) {
    if (control.value != '' && control.value != null) {
      //console.log(this.dailyDetailForm);
      return !(control.value >= MinValue && control.value <= MaxValue);
    }
  }

  getDiferenceInDays(theDate): string {
    if (theDate) {
      return (Math.round(Math.abs(new Date(theDate).getTime() - this.today.getTime()) / (1000 * 60 * 60 * 24))).toString();
    }
    else {
      return '';
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  isSectionEnable(Section) {

    switch (Section) {
      case "RO Checks & Testing":
        if ((this.mntf.ROEquipmentId.value && this.HFSPurpose == 'Renovation & Full Installation') || this.mntf.ROFeedConductivity.value || this.mntf.ROProductConductivity.value || this.mntf.ROReject.value ||
          this.mntf.ROHours.value || this.mntf.ROAirFilter.value || this.mntf.ROHeatClean.value || this.mntf.ROAcidClean.value ||
          this.mntf.ROHPCTestPerformed.value || this.mntf.ROLALTestPerformed.value)
          document.getElementById('div1').className = 'panel-heading-blue';
        else
          document.getElementById('div1').className = 'panel-heading';
      case "Dialysis Machine Checks & Testing":
        if ((this.mntf.DMEquipmentId.value && this.HFSPurpose == 'Renovation & Full Installation') || this.mntf.DMDiaSafe.value && this.mntf.DMExteriorCondition.value || this.mntf.DMWandsChecked.value || this.mntf.DMCheckforLeaks.value ||
          this.mntf.DMHours.value || this.mntf.DMVerifiedBleachLevel.value || this.mntf.DMCleanAirFilter.value || this.mntf.DMHPCTestPerformed.value || this.mntf.DMLALTestPerformed.value)
          document.getElementById('div2').className = 'panel-heading-blue';
        else
          document.getElementById('div2').className = 'panel-heading';
      case "Cart/Filters Checks":
        if (this.mntf.CFMax.value || this.mntf.CFMin.value || this.mntf.CFFinal.value || this.mntf.CFRatio.value ||
          this.mntf.CFVerifySystemforLeaks.value || this.mntf.CFHardness.value || this.mntf.CFPH.value || this.mntf.CFTotalChlorine.value ||
          this.mntf.CFIron.value || this.mntf.CFFloodStop.value || this.mntf.CF505Filters.value || this.mntf.CFCarbonFilter.value ||
          this.mntf.CF10Filter.value || ((this.mntf.CFIronFilters.value && this.isIronFilterRequired)) || this.mntf.CFInstalledLabels.value)
          document.getElementById('div3').className = 'panel-heading-blue';
        else
          document.getElementById('div3').className = 'panel-heading';
      case "Comprehensive Water Testing":
        if (this.mntf.CWTFeed.value || this.mntf.CWTRoProduct.value)
          document.getElementById('div4').className = 'panel-heading-blue';
        else
          document.getElementById('div4').className = 'panel-heading';
      case "Yearly Checks":
        if (this.mntf.YCRoGFIPlugTested.value || this.mntf.YCDmGFIPlugTested.value)
          document.getElementById('div5').className = 'panel-heading-blue';
        else
          document.getElementById('div5').className = 'panel-heading';
      default:
        true;
    }
  }

  setValidations(type) {

    this.clearValidations();

    if (this.HFSPurpose == 'Renovation & Full Installation') {
      this.mntf.ROEquipmentId.setValidators([Validators.required]);
      this.mntf.DMEquipmentId.setValidators([Validators.required]);

      if (type && type.includes("Dialysis Machine Checks & Testing")) {
        // this.mntf.DMEquipmentId.setValidators([Validators.required]);
        this.mntf.DMHours.setValidators([Validators.required,
        // Validators.pattern(Globals.FIVENumberPrecisionDoubleFractionRegex),
        DMRepairCustomValidators.hoursGreaterValidation(this.selectedDMHours.toString())]);

        this.mntf.DMDiaSafe.setValidators([Validators.required]);
        this.mntf.DMExteriorCondition.setValidators([Validators.required]);
        this.mntf.DMCheckforLeaks.setValidators([Validators.required]);
        this.mntf.DMWandsChecked.setValidators([Validators.required]);
        this.mntf.DMVerifiedBleachLevel.setValidators([Validators.required]);
        this.mntf.DMCleanAirFilter.setValidators([Validators.required]);
        this.mntf.DMHPCTestPerformed.setValidators([Validators.required]);
        this.mntf.DMLALTestPerformed.setValidators([Validators.required]);
      }
      else {
        // this.mntf.DMHours.setValidators([
        //   Validators.pattern(Globals.FIVENumberPrecisionDoubleFractionRegex)]);
      }

      if (type && type.includes("RO Checks & Testing")) {
        this.mntf.ROEquipmentId.setValidators([Validators.required]);
        this.mntf.ROFeedConductivity.setValidators([Validators.required]);
        this.mntf.ROProductConductivity.setValidators([Validators.required,]);

        this.mntf.ROReject.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)]);

        this.mntf.ROHours.setValidators([Validators.required,
        // Validators.pattern(Globals.FIVENumberPrecisionDoubleFractionRegex),
        DMRepairCustomValidators.hoursGreaterValidation(this.selectedROHours.toString())
        ]);
        this.mntf.ROAirFilter.setValidators([Validators.required]);
      }



      if (type && type.includes("Cart/Filters Checks")) {
        this.mntf.CFMax.setValidators([Validators.required]);
        this.mntf.CFMin.setValidators([Validators.required]);
        this.mntf.CFFinal.setValidators([Validators.required]);
        this.mntf.CFVerifySystemforLeaks.setValidators([Validators.required]);
        this.mntf.CFHardness.setValidators([Validators.required]);
        this.mntf.CFPH.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
        this.mntf.CFTotalChlorine.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)]);
        this.mntf.CFIron.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);

        // this.mntf.CFFloodStop.setValidators([Validators.required]);
        // this.mntf.CF505Filters.setValidators([Validators.required]);
        // this.mntf.CFCarbonFilter.setValidators([Validators.required]);
        // this.mntf.CF10Filter.setValidators([Validators.required]);
        if (this.isIronFilterRequired)
          this.mntf.CFIronFilters.setValidators([Validators.required]);

        this.mntf.CFInstalledLabels.setValidators([Validators.required]);
      }

      if (type && type.includes("Comprehensive Water Testing")) {
        this.mntf.CWTFeed.setValidators([Validators.required]);
        this.mntf.CWTRoProduct.setValidators([Validators.required]);
      }

      if (type && type.includes("Yearly Checks")) {
        this.mntf.YCRoGFIPlugTested.setValidators([Validators.required]);
        this.mntf.YCDmGFIPlugTested.setValidators([Validators.required]);
      }

    }
    else {
      if (type && type.includes("RO Checks & Testing")) {
        this.mntf.ROEquipmentId.setValidators([Validators.required]);
        this.mntf.ROFeedConductivity.setValidators([Validators.required]);
        this.mntf.ROProductConductivity.setValidators([Validators.required,]);

        this.mntf.ROReject.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)]);

        this.mntf.ROHours.setValidators([Validators.required,
        // Validators.pattern(Globals.FIVENumberPrecisionDoubleFractionRegex),
        DMRepairCustomValidators.hoursGreaterValidation(this.selectedROHours.toString())
        ]);



        this.mntf.ROAirFilter.setValidators([Validators.required]);

        this.mntf.ROAcidClean.setValidators([Validators.required]);
        this.mntf.ROHeatClean.setValidators([Validators.required]);
        this.mntf.ROHPCTestPerformed.setValidators([Validators.required]);
        this.mntf.ROLALTestPerformed.setValidators([Validators.required]);

        this.mntf.RODaysSinceLastAcidClean.setValidators([Validators.required]);
        this.mntf.RODaysSinceLastHC.setValidators([Validators.required]);
      }
      else {
        this.mntf.ROReject.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)]);
        // this.mntf.ROHours.setValidators([Validators.pattern(Globals.FIVENumberPrecisionDoubleFractionRegex)]);

      }

      if (type && type.includes("Cart/Filters Checks")) {
        this.mntf.CFMax.setValidators([Validators.required]);
        this.mntf.CFMin.setValidators([Validators.required]);
        this.mntf.CFFinal.setValidators([Validators.required]);
        this.mntf.CFVerifySystemforLeaks.setValidators([Validators.required]);
        this.mntf.CFHardness.setValidators([Validators.required]);
        this.mntf.CFPH.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
        this.mntf.CFTotalChlorine.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexDoublePrecisionDoubleFraction)]);
        this.mntf.CFIron.setValidators([Validators.required,
        Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);

        this.mntf.CFFloodStop.setValidators([Validators.required]);
        this.mntf.CF505Filters.setValidators([Validators.required]);
        this.mntf.CFCarbonFilter.setValidators([Validators.required]);
        this.mntf.CF10Filter.setValidators([Validators.required]);
        if (this.isIronFilterRequired)
          this.mntf.CFIronFilters.setValidators([Validators.required]);
        this.mntf.CFInstalledLabels.setValidators([Validators.required]);
      }

      if (type && type.includes("Comprehensive Water Testing")) {
        this.mntf.CWTFeed.setValidators([Validators.required]);
        this.mntf.CWTRoProduct.setValidators([Validators.required]);
      }

      if (type && type.includes("Yearly Checks")) {
        this.mntf.YCRoGFIPlugTested.setValidators([Validators.required]);
        this.mntf.YCDmGFIPlugTested.setValidators([Validators.required]);
      }
    }

    this.updateValidations();

  }

  updateValidations() {
    if (this.HFSPurpose == 'Renovation & Full Installation')
      this.mntf.ROEquipmentId.updateValueAndValidity();
    this.mntf.ROFeedConductivity.updateValueAndValidity();
    this.mntf.ROProductConductivity.updateValueAndValidity();
    this.mntf.ROReject.updateValueAndValidity();
    this.mntf.ROAirFilter.updateValueAndValidity();
    this.mntf.ROAcidClean.updateValueAndValidity();
    this.mntf.ROHeatClean.updateValueAndValidity();
    this.mntf.ROHPCTestPerformed.updateValueAndValidity();
    this.mntf.ROLALTestPerformed.updateValueAndValidity();


    this.mntf.RODaysSinceLastAcidClean.updateValueAndValidity();
    this.mntf.RODaysSinceLastHC.updateValueAndValidity();

    if (this.HFSPurpose == 'Renovation & Full Installation')
      this.mntf.DMEquipmentId.updateValueAndValidity();
    this.mntf.DMDiaSafe.updateValueAndValidity();
    this.mntf.DMExteriorCondition.updateValueAndValidity();
    this.mntf.DMWandsChecked.updateValueAndValidity();
    this.mntf.DMCheckforLeaks.updateValueAndValidity();
    this.mntf.DMVerifiedBleachLevel.updateValueAndValidity();
    this.mntf.DMCleanAirFilter.updateValueAndValidity();
    this.mntf.DMHPCTestPerformed.updateValueAndValidity();
    this.mntf.DMLALTestPerformed.updateValueAndValidity();

    this.mntf.CFMax.updateValueAndValidity();
    this.mntf.CFMin.updateValueAndValidity();
    this.mntf.CFFinal.updateValueAndValidity();
    this.mntf.CFVerifySystemforLeaks.updateValueAndValidity();
    this.mntf.CFHardness.updateValueAndValidity();
    this.mntf.CFPH.updateValueAndValidity();
    this.mntf.CFTotalChlorine.updateValueAndValidity();
    this.mntf.CFIron.updateValueAndValidity();

    this.mntf.CFFloodStop.updateValueAndValidity();
    this.mntf.CF505Filters.updateValueAndValidity();
    this.mntf.CFCarbonFilter.updateValueAndValidity();
    this.mntf.CF10Filter.updateValueAndValidity();
    if (this.isIronFilterRequired)
      this.mntf.CFIronFilters.updateValueAndValidity();
    this.mntf.CFInstalledLabels.updateValueAndValidity();

    this.mntf.CWTFeed.updateValueAndValidity();
    this.mntf.CWTRoProduct.updateValueAndValidity();
    this.mntf.CWTRoProductLastTested.updateValueAndValidity();
    this.mntf.CWTFeedH2OAnalysisId.updateValueAndValidity();
    this.mntf.CWTRoProductH2OAnalysisId.updateValueAndValidity();
    this.mntf.CWTFeedReportResultsDate.updateValueAndValidity();
    this.mntf.CWTFeedReportID.updateValueAndValidity();
    this.mntf.CWTRoProductReportResultsDate.updateValueAndValidity();
    this.mntf.CWTRoProductReportID.updateValueAndValidity();

    this.mntf.YCRoGFIPlugTested.updateValueAndValidity();
    this.mntf.YCDmGFIPlugTested.updateValueAndValidity();
    this.mntf.ROHours.updateValueAndValidity();
    this.mntf.DMHours.updateValueAndValidity();
  }

  clearValidations() {
    if (this.HFSPurpose == 'Renovation & Full Installation')
      this.mntf.ROEquipmentId.clearValidators();
    this.mntf.ROFeedConductivity.clearValidators();
    this.mntf.ROProductConductivity.clearValidators();
    this.mntf.ROReject.clearValidators();
    this.mntf.ROHours.clearValidators();

    this.mntf.ROAirFilter.clearValidators();

    this.mntf.ROAcidClean.clearValidators();
    this.mntf.ROHeatClean.clearValidators();
    this.mntf.ROHPCTestPerformed.clearValidators();
    this.mntf.ROLALTestPerformed.clearValidators();

    this.mntf.RODaysSinceLastAcidClean.clearValidators();
    this.mntf.RODaysSinceLastHC.clearValidators();

    if (this.HFSPurpose == 'Renovation & Full Installation')
      this.mntf.DMEquipmentId.clearValidators();
    this.mntf.DMHours.clearValidators();
    this.mntf.DMDiaSafe.clearValidators();
    this.mntf.DMExteriorCondition.clearValidators();
    this.mntf.DMWandsChecked.clearValidators();
    this.mntf.DMCheckforLeaks.clearValidators();
    this.mntf.DMVerifiedBleachLevel.clearValidators();
    this.mntf.DMCleanAirFilter.clearValidators();
    this.mntf.DMHPCTestPerformed.clearValidators();
    this.mntf.DMLALTestPerformed.clearValidators();

    this.mntf.CFMax.clearValidators();
    this.mntf.CFMin.clearValidators();
    this.mntf.CFFinal.clearValidators();
    this.mntf.CFVerifySystemforLeaks.clearValidators();
    this.mntf.CFHardness.clearValidators();
    this.mntf.CFPH.clearValidators();
    this.mntf.CFTotalChlorine.clearValidators();
    this.mntf.CFIron.clearValidators();

    this.mntf.CFFloodStop.clearValidators();
    this.mntf.CF505Filters.clearValidators();
    this.mntf.CFCarbonFilter.clearValidators();
    this.mntf.CF10Filter.clearValidators();
    if (this.isIronFilterRequired)
      this.mntf.CFIronFilters.clearValidators();
    this.mntf.CFInstalledLabels.clearValidators();

    this.mntf.CWTFeed.clearValidators();
    this.mntf.CWTRoProduct.clearValidators();
    this.mntf.CWTRoProductLastTested.clearValidators();
    this.mntf.CWTFeedH2OAnalysisId.clearValidators();
    this.mntf.CWTRoProductH2OAnalysisId.clearValidators();
    this.mntf.CWTFeedReportResultsDate.clearValidators();
    this.mntf.CWTFeedReportID.clearValidators();
    this.mntf.CWTRoProductReportResultsDate.clearValidators();
    this.mntf.CWTRoProductReportID.clearValidators();

    this.mntf.YCRoGFIPlugTested.clearValidators();
    this.mntf.YCDmGFIPlugTested.clearValidators();

  }


  resetSection(section) {
    switch (section) {
      case "RO Checks & Testing":
        if (this.HFSPurpose == 'Renovation & Full Installation')
          this.mntf.ROEquipmentId.setValue('');
        this.mntf.ROFeedConductivity.setValue('');
        this.mntf.ROProductConductivity.setValue('');
        this.mntf.ROReject.setValue('');

        if (this.mntf.ROHours.enabled && this.mntf.ROHours.value) {
          this.mntf.ROHours.setValue('');
        } else {
          if (this.mntf.ROHours.value)
            this.mntf.ROHours.disable();
          else
            this.mntf.ROHours.enable();
        }

        this.mntf.ROAirFilter.setValue('');
        this.mntf.ROHeatClean.setValue('');
        this.mntf.ROLastHeatClean.setValue('');
        this.mntf.ROAcidClean.setValue('');
        this.mntf.ROLastAcidClean.setValue('');
        if (this.mntf.ROHPCTestPerformed.value == 'No')
          this.mntf.ROHPCTestPerformed.setValue('');
        this.mntf.ROHPCLastTested.setValue('');
        this.mntf.ROHPCResults.setValue('');
        if (this.mntf.ROLALTestPerformed.value == 'No')
          this.mntf.ROLALTestPerformed.setValue('');
        this.mntf.ROLALLastTested.setValue('');
        this.mntf.ROLALSensitivity.setValue('');
        this.mntf.ROLALResults.setValue('');
        this.mntf.RODaysSinceLastHC.setValue('');
        this.mntf.RODaysSinceLastAcidClean.setValue('');

        this.mntf.ROBacteriaId.setValue('');
        this.mntf.ROPyrogenId.setValue('');
        this.mntf.ROHPCTestingLAB.setValue('');
        this.mntf.ROLALTestingLAB.setValue('');
        break;
      case "Dialysis Machine Checks & Testing":
        if (this.HFSPurpose == 'Renovation & Full Installation')
          this.mntf.DMEquipmentId.setValue('');
        this.mntf.DMDiaSafe.setValue('');
        this.mntf.DMExteriorCondition.setValue('');
        this.mntf.DMWandsChecked.setValue('');
        this.mntf.DMCheckforLeaks.setValue('');
        this.mntf.DMVerifiedBleachLevel.setValue('');
        this.mntf.DMCleanAirFilter.setValue('');
        if (this.mntf.DMHPCTestPerformed.value == 'No')
          this.mntf.DMHPCTestPerformed.setValue('');
        this.mntf.DMHPCLastTested.setValue('');
        this.mntf.DMHPCResults.setValue('');
        if (this.mntf.DMLALTestPerformed.value == 'No')
          this.mntf.DMLALTestPerformed.setValue('');
        this.mntf.DMLALLastTested.setValue('');
        this.mntf.DMLALSensitivity.setValue('');
        this.mntf.DMLALResults.setValue('');
        this.mntf.DMHPCTestingLAB.setValue('');
        this.mntf.DMLALTestingLAB.setValue('');
        this.mntf.DMBacteriaId.setValue('');
        this.mntf.DMPyrogenId.setValue('');
        if (this.mntf.DMHours.enabled && this.mntf.DMHours.value) {
          this.mntf.DMHours.setValue('');
        } else {
          if (this.mntf.DMHours.value)
            this.mntf.DMHours.disable();
          else
            this.mntf.DMHours.enable();
        }
        break;
      case "Cart/Filters Checks":
        this.mntf.CFMax.setValue('');
        this.mntf.CFMin.setValue('');
        this.mntf.CFFinal.setValue('');
        this.mntf.CFRatio.setValue('');
        this.mntf.CFVerifySystemforLeaks.setValue('');
        this.mntf.CFHardness.setValue('');
        this.mntf.CFPH.setValue('');
        this.mntf.CFTotalChlorine.setValue('');
        this.mntf.CFIron.setValue('');
        this.mntf.CFFloodStop.setValue('');
        this.mntf.CF505Filters.setValue('');
        this.mntf.CF505FiltersLastReplacedDate.setValue('');
        this.mntf.CFCarbonFilter.setValue('');
        this.mntf.CFCarbonFilterLastReplacedDate.setValue('');
        this.mntf.CF10Filter.setValue('');
        this.mntf.CF10FilterLastReplacedDate.setValue('');
        this.mntf.CFIronFilters.setValue('');
        this.mntf.CFIronFiltersLastReplacedDate.setValue('');
        this.mntf.CFInstalledLabels.setValue('');
        break;
      case "Comprehensive Water Testing":
        this.mntf.CWTFrequency.setValue('');
        if (this.mntf.CWTFeed.value == 'No')
          this.mntf.CWTFeed.setValue('');
        this.mntf.CWTFeedLastTested.setValue('');
        if (this.mntf.CWTRoProduct.value == 'No')
          this.mntf.CWTRoProduct.setValue('');
        this.mntf.CWTRoProductLastTested.setValue('');
        this.mntf.CWTFeedH2OAnalysisId.setValue('');
        this.mntf.CWTRoProductH2OAnalysisId.setValue('');
        this.mntf.CWTFeedReportResultsDate.setValue('');
        this.mntf.CWTFeedReportID.setValue('');
        this.mntf.CWTRoProductReportResultsDate.setValue('');
        this.mntf.CWTRoProductReportID.setValue('');
        break;
      case "Yearly Checks":
        this.mntf.YCRoGFIPlugTested.setValue('');
        this.mntf.YCDmGFIPlugTested.setValue('');
        this.mntf.YCRoGFIPlugLastTested.setValue('');
        this.mntf.YCDmGFIPlugLastTested.setValue('');
        break;
      default:
        break;
    }
    this.maintenanceForm.markAsDirty();
  }

  GetSelectedROHours() {
    var ROEquipmentId = { Id: this.mntf.ROEquipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(ROEquipmentId).subscribe(result => {
      if (result) {
        this.selectedROHours = result;
      } else {
        this.selectedROHours = 0;
      }

      // ROEquipmentName
      var tempROEquipmentName = this.roEquipmentList.filter((item, index) => item.value == this.mntf.ROEquipmentId.value);
      if (tempROEquipmentName.length > 0)
        this.ROEquipmentName = tempROEquipmentName[0].text;

      this.mntf.ROHours.setValidators([DMRepairCustomValidators.hoursGreaterValidation(this.selectedROHours.toString())]);
      this.mntf.ROHours.updateValueAndValidity();
    });
  }

  GetSelectedDMHours() {
    var DMEquipmentId = { Id: this.mntf.DMEquipmentId.value };
    this.dmapi.GetmachaineHistoryMaxHours(DMEquipmentId).subscribe(result => {
      if (result) {
        this.selectedDMHours = result;
      } else {
        this.selectedDMHours = 0;
      }

      // DMMachineName
      var tempDMEquipmentName = this.dmEquipmentList.filter((item, index) => item.value == this.mntf.DMEquipmentId.value);
      if (tempDMEquipmentName.length > 0)
        this.DMMachineName = tempDMEquipmentName[0].text;


      this.mntf.DMHours.setValidators([DMRepairCustomValidators.hoursGreaterValidation(this.selectedDMHours.toString())]);
      this.mntf.DMHours.updateValueAndValidity();
    });

    this.invapi.GetInventoryByID(DMEquipmentId).subscribe(
      success => {
        if (success) {
          this.DMMachineModel = success.model;
        }
      });
    this.mntf.DMLastPMCompleted.setValue('');
    this.dmapi.GetPMLastDateByEquipmentId(DMEquipmentId).subscribe(
      success => {
        if (success) {
          this.mntf.DMLastPMCompleted.setValue(success);
        }
      });
  }


  maintenanceCleanOrTestHistoryRef: MatDialogRef<MaintenanceCleanOrTestHistory>;
  OpenCleanOrTestHistoryList(type = "") {
    AppComponent.ShowLoader();
    this.maintenanceCleanOrTestHistoryRef = this.dialog.open(MaintenanceCleanOrTestHistory, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '50%',
      maxHeight: '100vh'
    });

    this.maintenanceCleanOrTestHistoryRef.afterClosed().subscribe(result => {
      this.maintenanceCleanOrTestHistoryRef = null;
    });

    this.maintenanceCleanOrTestHistoryRef.componentInstance.setPerformedMaintenanceHistory(type, type, this.mntf.DialysisUnitId.value, this.mntf.DialysisUnitName.value, this.mntf.HFServiceId.value)

    AppComponent.HideLoader();
  }

  onFormChanges(): void {
    this.maintenanceForm.valueChanges.subscribe(val => {
      this.updateSectionStatus();
    });
  }

  updateSectionStatus() {

    if ((this.mntf.ROEquipmentId.value && this.HFSPurpose == 'Renovation & Full Installation') ||
      this.mntf.ROFeedConductivity.value ||
      this.mntf.ROProductConductivity.value ||
      this.mntf.ROReject.value ||
      this.mntf.ROHours.value ||
      this.mntf.ROAirFilter.value ||
      this.mntf.ROHeatClean.value ||
      this.mntf.ROAcidClean.value ||
      this.mntf.RODaysSinceLastHC.value ||
      this.mntf.RODaysSinceLastAcidClean.value ||
      this.mntf.ROHPCTestPerformed.value ||
      this.mntf.ROLALTestPerformed.value) {
      if (((this.mntf.ROEquipmentId.value && this.mntf.ROHPCTestPerformed.value == 'Yes'
        && this.mntf.ROLALTestPerformed.value == 'Yes'
        && this.HFSPurpose == 'Renovation & Full Installation') || this.HFSPurpose == 'Maintenance & Testing') &&
        this.mntf.ROFeedConductivity.value &&
        this.mntf.ROProductConductivity.value &&
        this.mntf.ROReject.value &&
        this.mntf.ROHours.value &&
        this.mntf.ROAirFilter.value &&
        (this.mntf.ROHeatClean.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.ROAcidClean.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.RODaysSinceLastHC.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.RODaysSinceLastAcidClean.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        this.mntf.ROHPCTestPerformed.value &&
        this.mntf.ROLALTestPerformed.value
        &&
        !((this.mntf.ROEquipmentId.errors && this.HFSPurpose == 'Renovation & Full Installation')) &&
        !this.mntf.ROFeedConductivity.errors &&
        !this.mntf.ROProductConductivity.errors &&
        !this.mntf.ROReject.errors &&
        !this.mntf.ROHours.errors &&
        !this.mntf.ROAirFilter.errors &&
        !this.mntf.ROHeatClean.errors &&
        !this.mntf.ROAcidClean.errors &&
        !this.mntf.RODaysSinceLastHC.errors &&
        !this.mntf.RODaysSinceLastAcidClean.errors &&
        !this.mntf.ROHPCTestPerformed.errors &&
        !this.mntf.ROLALTestPerformed.errors
      ) {
        //
        this.sectionStatus.roChecks.status = 'Completed';
        this.sectionStatus.roChecks.formSubmitted = false;
        // this.save('RO Checks & Testing');
      }
      else if (this.formSubmitted) {
        this.sectionStatus.roChecks.status = 'Incompleted';
        this.sectionStatus.roChecks.formSubmitted = true;
      }
      else {
        this.sectionStatus.roChecks.status = 'Incompleted';
      }
    }
    else {
      this.sectionStatus.roChecks.status = '';
      this.sectionStatus.roChecks.formSubmitted = false;
    }


    if ((this.mntf.DMEquipmentId.value && this.HFSPurpose == 'Renovation & Full Installation') ||
      this.mntf.DMHours.value ||
      this.mntf.DMDiaSafe.value ||
      this.mntf.DMExteriorCondition.value ||
      this.mntf.DMWandsChecked.value ||
      this.mntf.DMCheckforLeaks.value ||
      this.mntf.DMVerifiedBleachLevel.value ||
      this.mntf.DMCleanAirFilter.value ||
      this.mntf.DMHPCTestPerformed.value ||
      this.mntf.DMLALTestPerformed.value) {
      if (((this.mntf.DMEquipmentId.value && this.mntf.DMHPCTestPerformed.value == 'Yes'
        && this.mntf.DMLALTestPerformed.value == 'Yes'
        && this.HFSPurpose == 'Renovation & Full Installation')
        || this.HFSPurpose == 'Maintenance & Testing') &&
        this.mntf.DMHours.value &&
        this.mntf.DMDiaSafe.value &&
        this.mntf.DMExteriorCondition.value &&
        this.mntf.DMWandsChecked.value &&
        this.mntf.DMCheckforLeaks.value &&
        this.mntf.DMVerifiedBleachLevel.value &&
        this.mntf.DMCleanAirFilter.value &&
        this.mntf.DMHPCTestPerformed.value &&
        this.mntf.DMLALTestPerformed.value
        &&
        !((this.mntf.DMEquipmentId.errors && this.HFSPurpose == 'Renovation & Full Installation')) &&
        !this.mntf.DMHours.errors &&
        !this.mntf.DMDiaSafe.errors &&
        !this.mntf.DMExteriorCondition.errors &&
        !this.mntf.DMWandsChecked.errors &&
        !this.mntf.DMCheckforLeaks.errors &&
        !this.mntf.DMVerifiedBleachLevel.errors &&
        !this.mntf.DMCleanAirFilter.errors &&
        !this.mntf.DMHPCTestPerformed.errors &&
        !this.mntf.DMLALTestPerformed.errors
      ) {
        //
        this.sectionStatus.dmChecks.status = 'Completed';
        this.sectionStatus.dmChecks.formSubmitted = false;
        // this.save('Dialysis Machine Checks & Testing');
      }
      else if (this.formSubmitted) {
        this.sectionStatus.dmChecks.status = 'Incompleted';
        this.sectionStatus.dmChecks.formSubmitted = true;
      }
      else {
        this.sectionStatus.dmChecks.status = 'Incompleted';
      }
    }
    else {
      this.sectionStatus.dmChecks.status = '';
      this.sectionStatus.dmChecks.formSubmitted = false;
    }

    if (this.mntf.CFMax.value ||
      this.mntf.CFMin.value ||
      this.mntf.CFFinal.value ||
      this.mntf.CFVerifySystemforLeaks.value ||
      this.mntf.CFHardness.value ||
      this.mntf.CFPH.value ||
      this.mntf.CFTotalChlorine.value ||
      this.mntf.CFIron.value ||
      this.mntf.CFFloodStop.value ||
      this.mntf.CF505Filters.value ||
      this.mntf.CFCarbonFilter.value ||
      this.mntf.CF10Filter.value ||
      ((this.mntf.CFIronFilters.value && this.isIronFilterRequired)) ||
      this.mntf.CFInstalledLabels.value) {
      if (this.mntf.CFMax.value &&
        this.mntf.CFMin.value &&
        this.mntf.CFFinal.value &&
        this.mntf.CFVerifySystemforLeaks.value &&
        this.mntf.CFHardness.value &&
        this.mntf.CFPH.value &&
        this.mntf.CFTotalChlorine.value &&
        this.mntf.CFIron.value &&
        (this.mntf.CFFloodStop.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.CF505Filters.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.CFCarbonFilter.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        (this.mntf.CF10Filter.value || this.HFSPurpose == 'Renovation & Full Installation') &&
        ((this.mntf.CFIronFilters.value && this.isIronFilterRequired) || !this.isIronFilterRequired) &&
        ((this.mntf.CFInstalledLabels.value && this.mntf.CFInstalledLabels.value == 'Yes'
          && this.HFSPurpose == 'Renovation & Full Installation')
          || (this.mntf.CFInstalledLabels.value && this.HFSPurpose == 'Maintenance & Testing'))
        &&
        !this.mntf.CFMax.errors &&
        !this.mntf.CFMin.errors &&
        !this.mntf.CFFinal.errors &&
        !this.mntf.CFVerifySystemforLeaks.errors &&
        !this.mntf.CFHardness.errors &&
        !this.mntf.CFPH.errors &&
        !this.mntf.CFTotalChlorine.errors &&
        !this.mntf.CFIron.errors &&
        !this.mntf.CFFloodStop.errors &&
        !this.mntf.CF505Filters.errors &&
        !this.mntf.CFCarbonFilter.errors &&
        !this.mntf.CF10Filter.errors &&
        !this.mntf.CFIronFilters.errors &&
        !this.mntf.CFInstalledLabels.errors
      ) {
        //
        this.sectionStatus.cfChecks.status = 'Completed';
        this.sectionStatus.cfChecks.formSubmitted = false;
        // this.save('Cart/Filters Checks');
      }
      else if (this.formSubmitted) {
        this.sectionStatus.cfChecks.status = 'Incompleted';
        this.sectionStatus.cfChecks.formSubmitted = true;
      }
      else {
        this.sectionStatus.cfChecks.status = 'Incompleted';
      }
    }
    else {
      this.sectionStatus.cfChecks.status = '';
      this.sectionStatus.cfChecks.formSubmitted = false;
    }

    if (this.mntf.CWTFeed.value ||
      this.mntf.CWTRoProduct.value) {
      if (((this.mntf.CWTFeed.value && this.mntf.CWTFeed.value == 'Yes' && this.HFSPurpose == 'Renovation & Full Installation')
        || (this.mntf.CWTFeed.value && this.HFSPurpose == 'Maintenance & Testing')) &&
        ((this.mntf.CWTRoProduct.value && this.mntf.CWTRoProduct.value == 'Yes' && this.HFSPurpose == 'Renovation & Full Installation')
          || (this.mntf.CWTRoProduct.value && this.HFSPurpose == 'Maintenance & Testing')) &&
        !this.mntf.CWTFeed.errors &&
        !this.mntf.CWTRoProduct.errors
      ) {
        //
        this.sectionStatus.cwtChecks.status = 'Completed';
        this.sectionStatus.cwtChecks.formSubmitted = false;
        // this.save('Comprehensive Water Testing');

      }
      else if (this.formSubmitted) {
        this.sectionStatus.cwtChecks.status = 'Incompleted';
        this.sectionStatus.cwtChecks.formSubmitted = true;
      }
      else {
        this.sectionStatus.cwtChecks.status = 'Incompleted';
      }
    } else {
      this.sectionStatus.cwtChecks.status = '';
      this.sectionStatus.cwtChecks.formSubmitted = false;
    }

    if (this.mntf.YCRoGFIPlugTested.value ||
      this.mntf.YCDmGFIPlugTested.value) {
      if (((this.mntf.YCRoGFIPlugTested.value && this.mntf.YCRoGFIPlugTested.value == 'Pass' && this.HFSPurpose == 'Renovation & Full Installation')
        || (this.mntf.YCRoGFIPlugTested.value && this.HFSPurpose == 'Maintenance & Testing')) &&
        ((this.mntf.YCDmGFIPlugTested.value && this.mntf.YCDmGFIPlugTested.value == 'Pass' && this.HFSPurpose == 'Renovation & Full Installation')
          || (this.mntf.YCDmGFIPlugTested.value && this.HFSPurpose == 'Maintenance & Testing')) &&
        !this.mntf.YCRoGFIPlugTested.errors &&
        !this.mntf.YCDmGFIPlugTested.errors
      ) {
        //
        this.sectionStatus.yearlyChecks.status = 'Completed';
        this.sectionStatus.yearlyChecks.formSubmitted = false;
        // this.save('Yearly Checks');
      }
      else if (this.formSubmitted) {
        this.sectionStatus.yearlyChecks.status = 'Incompleted';
        this.sectionStatus.yearlyChecks.formSubmitted = true;
      }
      else {
        this.sectionStatus.yearlyChecks.status = 'Incompleted';
      }
    }
    else {
      this.sectionStatus.yearlyChecks.status = '';
      this.sectionStatus.yearlyChecks.formSubmitted = false;
    }
  }

  getCompletedSections(filledSections) {
    let sections = '';
    if (filledSections.includes('Dialysis Machine Checks & Testing') && this.sectionStatus.roChecks.status == "Completed") {
      if (sections)
        sections = sections + ",Dialysis Machine Checks & Testing";
      else
        sections += "Dialysis Machine Checks & Testing";
    }
    if (filledSections.includes("Dialysis Machine Checks & Testing") && this.sectionStatus.dmChecks.status == "Completed") {
      if (sections)
        sections = sections + ",Dialysis Machine Checks & Testing";
      else
        sections += "Dialysis Machine Checks & Testing";
    }
    if (filledSections.includes("Cart/Filters Checks") && this.sectionStatus.cfChecks.status == "Completed") {
      if (sections)
        sections = sections + ",Cart/Filters Checks";
      else
        sections += "Cart/Filters Checks";
    }
    if (filledSections.includes("Comprehensive Water Testing") && this.sectionStatus.cwtChecks.status == "Completed") {
      if (sections)
        sections = sections + ",Comprehensive Water Testing";
      else
        sections += "Comprehensive Water Testing";
    }
    if (filledSections.includes("Yearly Checks") && this.sectionStatus.yearlyChecks.status == "Completed") {
      if (sections)
        sections = sections + ",Yearly Checks";
      else
        sections += "Yearly Checks";
    }
    return sections;

  }

  getNotCompletedSections(filledSections) {
    let sections = filledSections;
    if (sections.includes("Yearly Checks") && this.sectionStatus.yearlyChecks.status == "Completed") {
      sections = sections.replace('Yearly Checks', '');
    }
    if (sections.includes("Comprehensive Water Testing") && this.sectionStatus.cwtChecks.status == "Completed") {
      sections = sections.replace('Comprehensive Water Testing', '');
    }
    if (sections.includes("Cart/Filters Checks") && this.sectionStatus.cfChecks.status == "Completed") {
      sections = sections.replace('Cart/Filters Checks', '');
    }
    if (sections.includes("Dialysis Machine Checks & Testing") && this.sectionStatus.dmChecks.status == "Completed") {
      sections = sections.replace('Dialysis Machine Checks & Testing', '');
    }
    if (sections.includes('RO Checks & Testing') && this.sectionStatus.roChecks.status == "Completed") {
      sections = sections.replace('RO Checks & Testing', '');
    }
    return sections;

  }

}


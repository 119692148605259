<form [formGroup]="HX2DataLogsForm" novalidate>
<section class="content-header">
  <h1>
    <h1>
      {{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
    <div class="row">
          <div class="box  box-primary" style="overflow-y: hidden;">
              <div class="box-header pageheader row" style="max-width: 95%;">
                  <div class="col-sm-1 text-left">

                    </div>
                    <div class="col-sm-10 text-center">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                      <i class="fa fa-picture-o"></i> Layout View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                      <i class="fa fa-table"></i>  Table View
                    </a>
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                      <i class="fa fa-th-list"></i> Set Points
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                      <i class="fa fa-file"></i> Document View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                      <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                    </a>

                    <a type="button" class="btn btn-primary" style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                      <i class="fa fa-th-list"></i> Data Logs
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
              (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
              <i class="fa fa-th-list"></i> Notification Settings
            </a>
                  </div>
                  <div class="col-sm-1 text-right">
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                      <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                    </a>
                  </div>
              </div>
              <div class="col-sm-12 text-center">
                <label style="font-weight: normal;color:red"
                *ngIf="(lstHX2AlarmEventData!=undefined && lstHX2AlarmEventData!=null && (lstHX2AlarmEventData?.length==0 && type === 'Disinfection' && GetSelectedFields()!='') || (lstHX2AlarmEventData?.length==0 && type === 'Alarms') || (lstHX2AlarmEventData?.length==0 && type === 'Fields' && GetSelectedFields()!=''))">No Data Available, Please Select another Date</label>
              </div>
              <div class="col-sm-offset-5 col-sm-2 modal-header tab" >
                <h4 class="modal-title col-sm-6" [ngClass]="{active: type === 'Fields'}" (click)="SetOption('Fields')">HX2 Fields
                </h4>
                <h4 class="modal-title  col-sm-6" [ngClass]="{active: type === 'Alarms'}"  (click)="SetOption('Alarms')">HX2 Alarms</h4>
                <h4 class="modal-title  col-sm-6"
                        [ngClass]="{active: type === 'Disinfection'}"
                            (click)="SetOption('Disinfection')">Disinfection</h4>

                <!-- <button  type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title col-sm-5" style="float:right;text-align: right" *ngIf="type=='Support'"> <a href="javascript:void(0)" (click)="SetOption('Remote')">Remote Assist</a></h4>
                <h4 class="modal-title  col-sm-5" style="float:right;text-align: right" *ngIf="type=='Remote'"><a href="javascript:void(0)" (click)="SetOption('Support')">Errors & Support</a></h4> -->

                </div>
              <div class="col-sm-12 modal-header" style="border: 0 !important;">
                  <div class='col-sm-offset-2 col-sm-5' *ngIf="type=='Fields'">
                      <label class='col-sm-4 control-label'>Select Fields:</label>
                      <div class='col-sm-8'>
                          <ng-multiselect-dropdown style="background-color:aliceblue;"
                              [placeholder]="'Select Fields'" [data]="HX2FieldList"
                              formControlName='HX2Fields' (onDeSelect)="onItemSelect($event)"
                              [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                              (onSelectAll)="onSelectAll($event)" name="HX2Fields">
                          </ng-multiselect-dropdown>
                      </div>
                  </div>
                  <!-- <div class='col-sm-offset-2 col-sm-5' *ngIf="type=='Disinfection'">
                    <label class='col-sm-4 control-label'>Select Steps:</label>
                    <div class='col-sm-8'>
                        <ng-multiselect-dropdown style="background-color:aliceblue;"
                            [placeholder]="'Select Steps'" [data]="HX2FieldList"
                            formControlName='HX2Fields' (onDeSelect)="onItemSelect($event)"
                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)" name="HX2Fields">
                        </ng-multiselect-dropdown>
                    </div>
                </div> -->
                  <div [ngClass]="{'col-sm-offset-4 col-sm-4': type === 'Alarms'  || type=='Disinfection' ,'col-sm-3': type === 'Fields'}">
                    <label class="col-sm-6 control-label" *ngIf="type=='Fields'">Logs Date:</label>
                    <label class="col-sm-6 control-label" *ngIf="type=='Alarms'">Alarms Date:</label>
                    <label class="col-sm-6 control-label" *ngIf="type=='Disinfection'">Disinfection Date:</label>

                    <div class="col-sm-6">
                        <input (focus)="DTalarmDate.toggleCalendar()" (click)="DTalarmDate.openCalendar()" placeholder="Select a date"
                        class="form-control smInput" style="float:none"
                        angular-mydatepicker
                          formControlName="AlarmDate" [options]="myOptions"
                          #DTalarmDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                        required/>
                      </div>
              </div>
              </div>

              <div class="col-sm-offset-2 col-sm-8">

              <table *ngIf="type!=='Disinfection'"  class="table table-bordered table-striped dataTable no-footer" role="grid">
                  <thead>
                      <tr>
                          <th *ngFor="let tableHeader of tableHeaders"

                          [ngClass]="setSortIndicator(tableHeader.Label)"
                          [style.width.%]="tableHeader.Width"
                          (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                        </tr>
                  </thead>
                  <tbody>
                          <tr *ngFor="let item of lstHX2AlarmEventData ;let index=index;" class="pointer">
                                  <td class="empty td_center"><b>{{index + 1}}</b></td>
                                  <td class="empty td_right"><b>{{item.fieldLabelName}}</b></td>
                                  <td class="emptyvalue td_center" matTooltip="{{item.fieldDisplayValue}}">
                                    {{ item.fieldDisplayValue }}
                                  </td>
                                  <td class="td_center">{{item.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                          </tr>
                  </tbody>
              </table>

              <table *ngIf="type=='Disinfection'"  class="table table-bordered table-striped dataTable no-footer" role="grid">
                <thead>
                    <tr>
                        <th *ngFor="let tableHeader of tableHeadersDisinfection"

                        [ngClass]="setSortIndicator(tableHeader.Label)"
                        [style.width.%]="tableHeader.Width"
                        (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                      </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let item of lstHX2AlarmEventData ;let index=index;" class="pointer">
                                <td class="empty td_center"><b>{{index + 1}}</b></td>
                                <td class="emptyvalue td_center" matTooltip="{{item.fieldDisplayValue}}">
                                  {{ item.fieldDisplayValue }}
                                </td>
                                <td class="td_center">{{item.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                        </tr>
                </tbody>
            </table>

              </div>

      </div>
    </div>
  </section>
</section>
</form>

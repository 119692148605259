
<div [formGroup]="maintenanceForm" novalidate *ngIf="maintenanceForm" style="border:1px solid lightgray;padding: 10px">
  <div class="modal-header  col-sm-12" >
    <h4 class="modal-title col-sm-4">{{HFSPurpose}}</h4>
    <div class="box-row col-sm-4">
      <span *ngIf="errormsg"   [ngClass]="'error'">
        {{errormsg}}
      </span>
    </div>
    <div class="text-right  col-sm-4" style="padding:0">
      <!-- <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(true)">Close</button>
      <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance()">Save</button> -->
      <button type="button" class="btn btn-danger" style="margin-right: 5px" (click)="saveAndClose()">Save / Close</button>
    </div>
  </div>
  <div class="modal-body" style="position: static;">

  <div class="clearfix"></div>
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="roCheckss" id="div1">
      <h4 class="panel-title row">
        <div class="col-sm-11">
            <!-- href="#roChecks" data-toggle="collapse" -->
          <a class="collapsed"  data-parent="#accordion"
            aria-controls="rpChecksRef" [attr.aria-expanded]="isCollapsed.roChecks"
            (click)="expandDefaultSection('RO Checks & Testing',!isCollapsed.roChecks)">
            <div class="box-header with-border" style="padding: 0px;">
              <h3 class="box-title container" style="width: 110%">RO Checks & Testing:
                  <span class="requiredClass" *ngIf="HFServiceTaskCategory && HFServiceTaskCategory.includes('RO Microbiology')">Required</span>
                <span style="float:right"
                [style.color]="(sectionStatus.roChecks.formSubmitted) ? 'red' : ''" >{{sectionStatus.roChecks.status}}</span>
              </h3>

            </div>
          </a>
        </div>

      </h4>
    </div>
    <div id="roChecks" [ngbCollapse]="!isCollapsed.roChecks" class="panel-collapse collapse" role="tabpanel" aria-labelledby="roChecks">
      <div class="box-body">

        <div>
          <!-- RO -->

          <div class="box-row col-sm-12">
              <div class="row col-sm-4">
                  <p class="InnerPurposeHFSHeader">RO CHECKS:

                  </p></div>
          </div>
          <div class="clearfix"></div>

          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">RO ID:</label>
              <div class="col-sm-3" *ngIf="HFSPurpose == 'Maintenance & Testing'" >
                <label class="readOnlyText" style="padding:6px 0px">
                  {{ROEquipmentName}}
                </label>
              </div>
              <div class="col-sm-5" *ngIf="HFSPurpose == 'Renovation & Full Installation'">
                <select class="form-control mdInput" *ngIf="IsPurposeCompleted != 'Yes'" formControlName="ROEquipmentId" name="ROEquipmentId" (change)="GetSelectedROHours()">
                  <option value="">Select</option>
                  <option value="{{item.value}}" *ngFor="let item of roEquipmentList">{{item.text}}</option>
                </select>
                <label class="readOnlyText" *ngIf="IsPurposeCompleted == 'Yes'" style="padding:6px 0px">
                  {{ROEquipmentName}}
                </label>
                <span *ngIf="mntf.ROEquipmentId.errors?.required && (mntf.ROEquipmentId.dirty || mntf.ROEquipmentId.touched || formSubmitted)" [ngClass]="'error'"> Required!
                </span>
              </div>
              <div class="col-sm-1 readOnlyText" *ngIf="HFSPurpose == 'Renovation & Full Installation'"  style="text-align:center;padding:5px 0;cursor:auto">
                <button  id="btnROHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)" (click)="OpenHistory(mntf.ROEquipmentId.value)" title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
              <div class="col-sm-3 readOnlyText" *ngIf="HFSPurpose == 'Maintenance & Testing'"  style="text-align:center;padding:5px 0;cursor:auto">
                <button  id="btnROHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)" (click)="OpenHistory(mntf.ROEquipmentId.value)" title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
                <button *ngIf="IsPurposeCompleted != 'Yes'" id="btnROExchange" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;margin-left: 10px;">
                  <a href="javascript:void(0)" (click)="transferStationEquipment('RO')" title="Transfer"><i class="fa fa-exchange" style="color: #fff;"></i></a>
                </button>

              </div>
            </div>

            <div class="col-sm-4">
                <label class="col-sm-7 control-label">Feed Cond. (&mu;S):</label>
                <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput" placeholder="####" maxlength="4"
                    formControlName="ROFeedConductivity" name="ROFeedConductivity" (keypress)="numberOnly($event)">
                    <span
                    *ngIf="mntf.ROFeedConductivity.errors?.required && (mntf.ROFeedConductivity.dirty || mntf.ROFeedConductivity.touched || formSubmitted)"
                    [ngClass]="'error'"> Required!</span>
                </div>
                <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText">{{mntf.ROFeedConductivity.value}}</label>
                </div>
              </div>
              <div class="col-sm-4">
                <label class="col-sm-6 control-label">Product Cond. (&mu;S):</label>
                <div class="col-sm-5" *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput" placeholder="###" maxlength="3"
                    formControlName="ROProductConductivity" name="ROProductConductivity" (keypress)="numberOnly($event)"
                    [ngClass]="{ OurOffRange: checkRange( mntf.ROProductConductivity, 0, 60)}">
                    <span
                    *ngIf="mntf.ROProductConductivity.errors?.required && (mntf.ROProductConductivity.dirty || mntf.ROProductConductivity.touched || formSubmitted)"
                    [ngClass]="'error'"> Required!</span>
                </div>
                <div class="col-sm-5" *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" [style.color]="mntf.ROProductConductivity.value >= 60 ? 'red' : textColor">{{mntf.ROProductConductivity.value}}</label>
                </div>
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">RO Reject (%):</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="##.##" maxlength="5" formControlName="ROReject"
                  name="ROReject" (blur)="ReplaceLeadingZero('ROReject', 2)">
                  <span
                  *ngIf="mntf.ROReject.errors?.required && (mntf.ROReject.dirty || mntf.ROReject.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
                <span *ngIf="mntf.ROReject.errors?.pattern" [ngClass]="'error'">##.##</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.ROReject.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">RO Hours:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput"   maxlength="8" formControlName="ROHours"
                  name="ROHours" (keypress)="numberOnly($event)">
                  <!-- (blur)="ReplaceLeadingZero('ROHours', 2)" -->
                <!-- <span *ngIf="mntf.ROHours.errors?.pattern" [ngClass]="'error'">##.##</span> -->
                <span
                  *ngIf="mntf.ROHours.errors?.required && (mntf.ROHours.dirty || mntf.ROHours.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
                  <span  *ngIf="mntf.ROHours.errors?.GreaterHoursOut" [ngClass] = "'error'">
                    RO Hours => previous  RO Hours
                </span>
              </div>
              <label class="col-sm-1 readOnlyTextForHours" style="padding: 4px 5px;"
              *ngIf="selectedROHours!=0 && selectedROHours!=null && IsPurposeCompleted != 'Yes'">({{selectedROHours}})</label>
            <label class="col-sm-1 readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedROHours==0 && IsPurposeCompleted != 'Yes'">(N/A)</label>
              <div class="col-sm-5" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.ROHours.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">RO Air Filter:</label>
              <div class="col-sm-5" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control mdInput" formControlName="ROAirFilter" name="ROAirFilter"
                  [style.color]="getColor(mntf.ROAirFilter.value,lstAirFilter)" style="padding: 3px 6px;">
                  <option value="{{item.value}}" *ngFor="let item of lstAirFilter"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select>
                <span
                  *ngIf="mntf.ROAirFilter.errors?.required && (mntf.ROAirFilter.dirty || mntf.ROAirFilter.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-5" *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText"
                    [style.color]="getColor(mntf.ROAirFilter.value,lstAirFilter)">{{mntf.ROAirFilter.value}}</label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
              <div class="col-sm-6">
                  <label class="col-sm-6 control-label">Days Since Last H/C:</label>
                  <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                    <input class="form-control smInput" placeholder="" maxlength="5" formControlName="RODaysSinceLastHC"
                      name="RODaysSinceLastHC" (keypress)="numberOnly($event)" >
                      <span
                      *ngIf="mntf.RODaysSinceLastHC.errors?.required && (mntf.RODaysSinceLastHC.dirty || mntf.RODaysSinceLastHC.touched || formSubmitted)"
                      [ngClass]="'error'"> Required!</span>

                  </div>
                  <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                    <label class="readOnlyText">{{mntf.RODaysSinceLastHC.value}}</label>
                  </div>
                </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Performed Heat Clean:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="ROHeatClean" name="ROHeatClean">
                  <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                    {{item.text}}
                  </option>
                </select> -->

                <mat-button-toggle-group value=""
                formControlName="ROHeatClean" name="ROHeatClean" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>

              <span
                  *ngIf="mntf.ROHeatClean.errors?.required && (mntf.ROHeatClean.dirty || mntf.ROHeatClean.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>

              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.ROHeatClean.value}}</label>
              </div>
              <div class="col-sm-2" style="padding:5px 0;cursor:auto;text-align: center">
                <button (click)="OpenCleanOrTestHistoryList('ROHeatClean')" id="btnROHeatCleanHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)" title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>

          </div>
          <div class="col-sm-12 box-row ">
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Days Last Heat Clean:</label>
              <div class="col-sm-6">
                <label class="readOnlyText" [style.color]="lastHeatCleanDays >= 8 ? 'red' : textColor"> -->
                  <!-- {{mntf.ROLastHeatClean.value | date:'dd/MM/yyyy HH:mm'}} -->
                  <!-- {{lastHeatCleanDays}}
                </label>
              </div>
            </div> -->
            <div class="col-sm-6">
                <label class="col-sm-6 control-label">Days Since Last Acid Clean:</label>
                <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control smInput" placeholder="" maxlength="5" formControlName="RODaysSinceLastAcidClean"
                    name="RODaysSinceLastAcidClean" (keypress)="numberOnly($event)" >
                    <span
                    *ngIf="mntf.RODaysSinceLastAcidClean.errors?.required && (mntf.RODaysSinceLastAcidClean.dirty || mntf.RODaysSinceLastAcidClean.touched || formSubmitted)"
                    [ngClass]="'error'"> Required!</span>

                </div>
                <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText">{{mntf.RODaysSinceLastAcidClean.value}}</label>
                </div>
              </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Performed Acid Clean:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="ROAcidClean" name="ROAcidClean">
                  <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="ROAcidClean" name="ROAcidClean" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.ROAcidClean.errors?.required && (mntf.ROAcidClean.dirty || mntf.ROAcidClean.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>

              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.ROAcidClean.value}}</label>
              </div>
              <div class="col-sm-2" style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('ROAcidClean')"  id="btnROAcidCleanHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>


          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Days Last Acid Clean:</label>
              <div class="col-sm-6">
                <label class="readOnlyText" [style.color]="lastAcidCleanDays >= 15 ? 'red' : textColor"> -->
                  <!-- {{mntf.ROLastAcidClean.value | date:'dd/MM/yyyy HH:mm'}} -->
                  <!-- {{lastAcidCleanDays}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="box-row col-sm-12">
              <div class="row col-sm-4">
                  <p class="InnerPurposeHFSHeader">RO TESTING:
                  </p></div>
          </div>
          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Bacteria Test Performed:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="ROHPCTestPerformed" name="ROHPCTestPerformed"
                  (change)="onChangeROHPCTest()" [style.color]="getColor(mntf.ROHPCTestPerformed.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="ROHPCTestPerformed" name="ROHPCTestPerformed" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" (change)="onChangeROHPCTest()" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.ROHPCTestPerformed.errors?.required && (mntf.ROHPCTestPerformed.dirty || mntf.ROHPCTestPerformed.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.ROHPCTestPerformed.value,lstTest)">{{mntf.ROHPCTestPerformed.value}}</label>
              </div>
              <div class="col-sm-3" *ngIf="mntf.ROBacteriaId.value"  style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('ROBacteriaTestPerformed')" id="btnROHPCHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)" title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
                <button  id="btnROBacteria" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                    <a href="javascript:void(0)" (click)="onChangeROHPCTest()" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                  </button>
              </div>

            </div>

            <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Test:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="ROLALTestPerformed" name="ROLALTestPerformed"
                  [style.color]="getColor(mntf.ROLALTestPerformed.value,lstTest)"
                  (change)="onChangeROLALTest()">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="ROLALTestPerformed" name="ROLALTestPerformed" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" (change)="onChangeROLALTest()" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>

              <span
              *ngIf="mntf.ROLALTestPerformed.errors?.required && (mntf.ROLALTestPerformed.dirty || mntf.ROLALTestPerformed.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>

              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                [style.color]="getColor(mntf.ROLALTestPerformed.value,lstTest)">{{mntf.ROLALTestPerformed.value}}</label>
              </div>
              <div class="col-sm-3" *ngIf="mntf.ROPyrogenId.value"  style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('ROLALTestPerformed')" id="btnROLALHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)"  title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
                <button  id="btnROLAL" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                    <a href="javascript:void(0)" (click)="onChangeROLALTest()" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.ROHPCLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>

            </div> -->

          </div>
          <div class="col-sm-12 box-row " *ngIf="mntf.ROHPCTestPerformed.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Bacteria Lab Facility:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="ROHPCTestingLAB" name="ROHPCTestingLAB"
                  [style.color]="getColor(mntf.ROHPCTestingLAB.value,lstLabResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstLabResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.ROHPCTestingLAB.value,lstLabResult)">{{mntf.ROHPCTestingLAB.value}}</label>
              </div>
            </div>
            <div class="col-sm-6" style="display: none;">
              <label class="col-sm-6 control-label">Bacteria Results:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="ROHPCResults" name="ROHPCResults"
                  [style.color]="getColor(mntf.ROHPCResults.value,lstHPCResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstHPCResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.ROHPCResults.value,lstHPCResult)">{{mntf.ROHPCResults.value}}</label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.ROLALLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->
          </div>

          <!-- <div class="col-sm-12 box-row " *ngIf="mntf.ROLALTestPerformed.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Testing LAB:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="ROLALTestingLAB" name="ROLALTestingLAB"
                  [style.color]="getColor(mntf.ROLALTestingLAB.value,lstLabResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstLabResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.ROLALTestingLAB.value,lstLabResult)">{{mntf.ROLALTestingLAB.value}}</label>
              </div>
            </div>
          </div> -->
          <div class="col-sm-12 box-row " *ngIf="mntf.ROLALTestPerformed.value == 'Yes'">
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Sensitivity:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="ROLALSensitivity" name="ROLALSensitivity">
                  <option value="{{item.value}}" *ngFor="let item of lstLALTest">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.ROLALSensitivity.value}}</label>
              </div>
            </div> -->

            <!-- <div class="col-sm-6" style="display: none;">
              <label class="col-sm-6 control-label">LAL Result:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="ROLALResults" name="ROLALResults"
                  [style.color]="getColor(mntf.ROLALResults.value,lstResult)">
                  <option value="{{item.value}}" *ngFor="let item of lstResult"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.ROLALResults.value,lstResult)">{{mntf.ROLALResults.value}}</label>
              </div>
            </div> -->
          </div>

          <div class="clearfix"></div>

          <!-- <div class="box-row col-sm-12  divider">
            <div class="col-sm-8"><strong>Sectional Notes:
              </strong></div>

          </div> -->
          <div class="box-row col-sm-12  divider">

            <notes #roNotes (addNoteClick)="executeNotes('RO Checks & Testing')"></notes>

          </div>
          <div class="clearfix"></div>

          <div class="text-right  col-sm-12">
            <button type="button" *ngIf="checkSectionCreated('RO Checks & Testing')" class="btn btn-danger" style="margin-right:5px" (click)="resetSection('RO Checks & Testing')" >Reset</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance('RO Checks & Testing')">Save</button> -->
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>



  <div class="clearfix"></div>
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="dmCheckss" id="div2">
      <h4 class="panel-title row">
        <div class=" col-sm-11">
            <!-- href="#dmChecks" data-toggle="collapse" -->
          <a class="collapsed"  data-parent="#accordion"
            aria-controls="dmChecksRef" [attr.aria-expanded]="isCollapsed.dmChecks"
            (click)="expandDefaultSection('Dialysis Machine Checks & Testing',!isCollapsed.dmChecks)">
            <div class="box-header with-border" style="padding: 0px;"
            >
              <h3 class="box-title container" style="width:110%">Dialysis Machine Checks & Testing:
                  <span class="requiredClass" *ngIf="HFServiceTaskCategory && HFServiceTaskCategory.includes('DM Microbiology')">Required</span>
                <span style="float:right"
                [style.color]="(sectionStatus.dmChecks.formSubmitted) ? 'red' : ''" >{{sectionStatus.dmChecks.status}}</span>
              </h3>
            </div>
          </a>
        </div>
      </h4>
    </div>
    <div id="dmChecks"  [ngbCollapse]="!isCollapsed.dmChecks" class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmChecks">
      <div class="box-body">
        <div>
          <!--DM-->
          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">Machine ID:</label>
              <div class="col-sm-3" *ngIf="HFSPurpose == 'Maintenance & Testing'">
                <label class="readOnlyText" style="padding:6px 0px">{{DMMachineName}}</label>
              </div>
              <div class="col-sm-5" *ngIf="HFSPurpose == 'Renovation & Full Installation'">
                <select class="form-control mdInput" formControlName="DMEquipmentId" name="DMEquipmentId"
                    (change)="GetSelectedDMHours()">
                  <option value="">Select</option>
                  <option value="{{item.value}}" *ngFor="let item of dmEquipmentList">{{item.text}}</option>
                </select>
                <span *ngIf="mntf.DMEquipmentId.errors?.required && (mntf.DMEquipmentId.dirty || mntf.DMEquipmentId.touched || formSubmitted)" [ngClass]="'error'"> Required!
                </span>
              </div>
              <div class="col-sm-1 readOnlyText" *ngIf="HFSPurpose == 'Renovation & Full Installation'" style="text-align:center;    padding: 5px 0;cursor:auto">
                <button id="btnDMHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)" (click)="OpenHistory(mntf.DMEquipmentId.value)" title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
              </div>
              <div class="col-sm-3 readOnlyText" *ngIf="HFSPurpose == 'Maintenance & Testing'" style="text-align:center;    padding: 5px 0;cursor:auto">
                <button id="btnDMHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)" (click)="OpenHistory(mntf.DMEquipmentId.value)" title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
                <button *ngIf="IsPurposeCompleted != 'Yes'" id="btnDMExchange" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;margin-left:10px;">
                  <a href="javascript:void(0)" (click)="transferStationEquipment('DM')" title="Transfer"><i class="fa fa-exchange" style="color: white;"></i></a>
                </button>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">Machine Model:</label>
              <div class="col-sm-5">
                <label class="readOnlyText">
                  {{DMMachineModel}}
                </label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">Last PM Completed:</label>
              <div class="col-sm-5">
                <label class="readOnlyText">
                  {{mntf.DMLastPMCompleted.value | date:'dd/MM/yyyy'}}
                </label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="box-row col-sm-12">
              <div class="row col-sm-4">
                  <p class="InnerPurposeHFSHeader">DM CHECKS:
                  </p></div>
          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">DiaSafe:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMDiaSafe" name="DMDiaSafe"
                  [style.color]="getColor(mntf.DMDiaSafe.value,lstOkReplaced)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkReplaced"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMDiaSafe" name="DMDiaSafe" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offReplaced}}">{{offReplaced}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMDiaSafe.errors?.required && (mntf.DMDiaSafe.dirty || mntf.DMDiaSafe.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMDiaSafe.value,lstOkReplaced)">{{mntf.DMDiaSafe.value}}</label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Exterior Condition:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DME
                  xteriorCondition" name="DMExteriorCondition"
                  [style.color]="getColor(mntf.DMExteriorCondition.value,lstOkNo)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkNo"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMExteriorCondition" name="DMExteriorCondition" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMExteriorCondition.errors?.required && (mntf.DMExteriorCondition.dirty || mntf.DMExteriorCondition.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMExteriorCondition.value,lstOkNo)">{{mntf.DMExteriorCondition.value}}</label>
              </div>
            </div>

          </div>

          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Wands Checked:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMWandsChecked" name="DMWandsChecked"
                  [style.color]="getColor(mntf.DMWandsChecked.value,lstOkReplaced)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkReplaced"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMWandsChecked" name="DMWandsChecked" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offReplaced}}">{{offReplaced}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMWandsChecked.errors?.required && (mntf.DMWandsChecked.dirty || mntf.DMWandsChecked.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMWandsChecked.value,lstOkReplaced)">{{mntf.DMWandsChecked.value}}</label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Check for Leaks:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMCheckforLeaks" name="DMCheckforLeaks"
                  [style.color]="getColor(mntf.DMCheckforLeaks.value,lstOkNo)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkNo"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMCheckforLeaks" name="DMCheckforLeaks" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMCheckforLeaks.errors?.required && (mntf.DMCheckforLeaks.dirty || mntf.DMCheckforLeaks.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMCheckforLeaks.value,lstOkNo)">{{mntf.DMCheckforLeaks.value}}</label>
              </div>
            </div>

          </div>
          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Verified Bleach Level:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMVerifiedBleachLevel"
                  name="DMVerifiedBleachLevel" [style.color]="getColor(mntf.DMVerifiedBleachLevel.value,lstOkNo)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkNo"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMVerifiedBleachLevel" name="DMVerifiedBleachLevel" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMVerifiedBleachLevel.errors?.required && (mntf.DMVerifiedBleachLevel.dirty || mntf.DMVerifiedBleachLevel.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMVerifiedBleachLevel.value,lstOkNo)">{{mntf.DMVerifiedBleachLevel.value}}</label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Clean Air Filter:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMCleanAirFilter" name="DMCleanAirFilter"
                  [style.color]="getColor(mntf.DMCleanAirFilter.value,lstOkReplaced)">
                  <option value="{{item.value}}" *ngFor="let item of lstOkReplaced"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMCleanAirFilter" name="DMCleanAirFilter" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onOk}}" class="btnsuccess">{{onOk}}</mat-button-toggle>
                <mat-button-toggle *ngIf="displayOffReplacedNo" class="btndanger" value="{{offReplaced}}">{{offReplaced}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMCleanAirFilter.errors?.required && (mntf.DMCleanAirFilter.dirty || mntf.DMCleanAirFilter.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMCleanAirFilter.value,lstOkReplaced)">{{mntf.DMCleanAirFilter.value}}</label>
              </div>
            </div>

          </div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
                <label class="col-sm-6 control-label">DM Hours:</label>
                <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput" maxlength="8" formControlName="DMHours"
                    name="DMHours" (keypress)="numberOnly($event)" >
                    <!-- (blur)="ReplaceLeadingZero('DMHours', 2)" -->
                  <!-- <span *ngIf="mntf.DMHours.errors?.pattern" [ngClass]="'error'">##.##</span> -->
                  <span
                    *ngIf="mntf.DMHours.errors?.required && (mntf.DMHours.dirty || mntf.DMHours.touched || formSubmitted)"
                    [ngClass]="'error'"> Required!</span>
                    <span  *ngIf="mntf.DMHours.errors?.GreaterHoursOut" [ngClass] = "'error'">
                      DM Hours => previous  DM Hours
                  </span>
                </div>
                <label class="readOnlyTextForHours" style="padding: 4px 5px;"
                *ngIf="selectedDMHours!=0 && selectedDMHours!=null && IsPurposeCompleted != 'Yes'">({{selectedDMHours}})</label>
              <label class="readOnlyTextForHours" style="padding: 4px 5px;" *ngIf="selectedDMHours==0 &&  IsPurposeCompleted != 'Yes'">(N/A)</label>
                <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText">{{mntf.DMHours.value}}</label>
                </div>
            </div>
          </div>

          <div class="box-row col-sm-12">
              <div class="row col-sm-4">
                  <p class="InnerPurposeHFSHeader">DM TESTING:</p></div>
          </div>

          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Bacteria Test:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMHPCTestPerformed" name="DMHPCTestPerformed"
                (change)="onChangeDMHPCTest()"
                  [style.color]="getColor(mntf.DMHPCTestPerformed.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMHPCTestPerformed" name="DMHPCTestPerformed" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" (change)="onChangeDMHPCTest()"  class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMHPCTestPerformed.errors?.required && (mntf.DMHPCTestPerformed.dirty || mntf.DMHPCTestPerformed.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMHPCTestPerformed.value,lstTest)">{{mntf.DMHPCTestPerformed.value}}</label>
              </div>
              <div class="col-sm-3" *ngIf="mntf.DMBacteriaId.value"  style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('DMHPCTestPerformed')" id="btnDMBacteriaHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)"  title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
                <button  id="btnDMBacteria" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                    <a href="javascript:void(0)" (click)="onChangeDMHPCTest()" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                  </button>
                </div>
            </div>

            <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Test:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="DMLALTestPerformed" name="DMLALTestPerformed"
                  [style.color]="getColor(mntf.DMLALTestPerformed.value,lstTest)" (change)="onChangeDMLALTest()">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="DMLALTestPerformed" name="DMLALTestPerformed" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}"  (change)="onChangeDMLALTest()" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
                  *ngIf="mntf.DMLALTestPerformed.errors?.required && (mntf.DMLALTestPerformed.dirty || mntf.DMLALTestPerformed.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMLALTestPerformed.value,lstTest)">{{mntf.DMLALTestPerformed.value}}</label>
              </div>
              <div class="col-sm-3" *ngIf="mntf.DMPyrogenId.value"  style="text-align:center;padding:2px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('DMLALTestPerformed')" id="btnDMPyrogenHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display: inline-block;">
                  <a href="javascript:void(0)" title="History" ><i class="fa fa-history" style="color: white"></i></a>
                </button>
                <button  id="btnDMPyrogen" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                  <a href="javascript:void(0)" (click)="onChangeDMLALTest()" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.DMHPCLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>

            </div> -->

          </div>
          <div class="col-sm-12 box-row " *ngIf="mntf.DMHPCTestPerformed.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Bacteria Lab Facility:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="DMHPCTestingLAB" name="DMHPCTestingLAB"
                  [style.color]="getColor(mntf.DMHPCTestingLAB.value,lstLabResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstLabResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMHPCTestingLAB.value,lstLabResult)">{{mntf.DMHPCTestingLAB.value}}</label>
              </div>
            </div>
            <div class="col-sm-6" style="display: none;">
              <label class="col-sm-6 control-label">Bacteria Results:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="DMHPCResults" name="DMHPCResults"
                  [style.color]="getColor(mntf.DMHPCResults.value,lstHPCResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstHPCResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMHPCResults.value,lstHPCResult)">{{mntf.DMHPCResults.value}}</label>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.DMLALLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->
          </div>

          <div class="col-sm-12 box-row " *ngIf="mntf.DMLALTestPerformed.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Lab Facility:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="DMLALTestingLAB" name="DMLALTestingLAB"
                  [style.color]="getColor(mntf.DMLALTestingLAB.value,lstLabResult)">
                  <option value="{{item.value}}" [style.color]="item.color ? item.color : textColor"
                    value="{{item.value}}" *ngFor="let item of lstLabResult">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMLALTestingLAB.value,lstLabResult)">{{mntf.DMLALTestingLAB.value}}</label>
              </div>
            </div>
          </div>
          <div class="col-sm-12 box-row " *ngIf="mntf.DMLALTestPerformed.value == 'Yes'">
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Sensitivity:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="DMLALSensitivity" name="DMLALSensitivity"
                  [style.color]="getColor(mntf.DMLALSensitivity.value,lstLALTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstLALTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMLALSensitivity.value,lstLALTest)">{{mntf.DMLALSensitivity.value}}</label>
              </div>
            </div> -->
            <div class="col-sm-6" style="display: none;">
              <label class="col-sm-6 control-label">LAL Result:</label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <select class="form-control smInput" formControlName="DMLALResults" name="DMLALResults"
                  [style.color]="getColor(mntf.DMLALResults.value,lstResult)">
                  <option value="{{item.value}}" *ngFor="let item of lstResult"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.DMLALResults.value,lstResult)">{{mntf.DMLALResults.value}}</label>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <!-- <div class="box-row col-sm-12  divider">
                  <div class="col-sm-8"><strong>Sectional Notes:
                    </strong></div>

                  </div> -->
          <div class="box-row col-sm-12  divider">
            <notes #dmNotes (addNoteClick)="executeNotes('Dialysis Machine Checks & Testing')"></notes>
          </div>
          <div class="clearfix"></div>

          <div class="text-right  col-sm-12">
            <button type="button" class="btn btn-danger" *ngIf="checkSectionCreated('Dialysis Machine Checks & Testing')" style="margin-right:5px" (click)="resetSection('Dialysis Machine Checks & Testing')" >Reset</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance('Dialysis Machine Checks & Testing')">Save</button> -->
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>



  <div class="clearfix"></div>
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="cfCheckss" id="div3">
      <h4 class="panel-title row">
          <div class=" col-sm-11">
          <!-- href="#cfChecks" data-toggle="collapse"  -->
        <a class="collapsed" data-parent="#accordion"
          aria-controls="cfChecksRef" [attr.aria-expanded]="isCollapsed.cfChecks"
          (click)="expandDefaultSection('Cart/Filters Checks',!isCollapsed.cfChecks)">
          <div class="box-header with-border" style="padding: 0px;"
          >
            <h3 class="box-title container" style="width:110%">Cart/Filters Checks:
                <span class="requiredClass" *ngIf="HFServiceTaskCategory && HFServiceTaskCategory.includes('Filters')">Required</span>
              <span style="float:right"
                [style.color]="(sectionStatus.cfChecks.formSubmitted) ? 'red' : ''" >{{sectionStatus.cfChecks.status}}</span>
            </h3>
          </div>
        </a>
      </div>
      </h4>
    </div>
    <div id="cfChecks" [ngbCollapse]="!isCollapsed.cfChecks" class="panel-collapse collapse" role="tabpanel" aria-labelledby="cfChecks">
      <div class="box-body">
        <div>


          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">Max (psi):</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="###" maxlength="3" formControlName="CFMax" name="CFMax"
                  (keypress)="numberOnly($event)">
                  <span
                  *ngIf="mntf.CFMax.errors?.required && (mntf.CFMax.dirty || mntf.CFMax.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFMax.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">Min (psi): </label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="###" maxlength="3" formControlName="CFMin" name="CFMin"
                  (keypress)="numberOnly($event)">
                  <span
                  *ngIf="mntf.CFMin.errors?.required && (mntf.CFMin.dirty || mntf.CFMin.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFMin.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">Final (psi):</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="###" maxlength="3" formControlName="CFFinal"
                  name="CFFinal" (keypress)="numberOnly($event)">
                  <span
                  *ngIf="mntf.CFFinal.errors?.required && (mntf.CFFinal.dirty || mntf.CFFinal.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFFinal.value}}</label>
              </div>
            </div>
            <!-- <div class="col-sm-3">
                    <label class="col-sm-6 control-label">Ratio:</label>
                    <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                      <input class="form-control mdInput" placeholder="" maxlength="50" formControlName="CFRatio" name="CFRatio">
                    </div>
                    <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                      <label class="readOnlyText">{{mntf.CFRatio.value}}</label>
                    </div>
                  </div> -->

          </div>
          <div class="clearfix"></div>
          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">Verify System for Leaks:</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'" style="width:41.5%">
                <select class="form-control mdInput" formControlName="CFVerifySystemforLeaks"
                  name="CFVerifySystemforLeaks" style="padding: 3px 6px;"
                  [style.color]="getColor(mntf.CFVerifySystemforLeaks.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select>
                <span
                  *ngIf="mntf.CFVerifySystemforLeaks.errors?.required && (mntf.CFVerifySystemforLeaks.dirty || mntf.CFVerifySystemforLeaks.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4"  style="width:41.5%" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CFVerifySystemforLeaks.value,lstTest)">{{mntf.CFVerifySystemforLeaks.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">Hardness (gpg):</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="###" maxlength="3" formControlName="CFHardness"
                  name="CFHardness" (keypress)="numberOnly($event)">
                  <span
                  *ngIf="mntf.CFHardness.errors?.required && (mntf.CFHardness.dirty || mntf.CFHardness.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFHardness.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">pH: </label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="#.#" maxlength="3" formControlName="CFPH" name="CFPH"
                  (keypress)="numberOnly($event)" (blur)="ReplaceLeadingZero('CFPH')">
                <span *ngIf="mntf.CFPH.errors?.pattern" [ngClass]="'error'">#.#</span>
                <span
                  *ngIf="mntf.CFPH.errors?.required && (mntf.CFPH.dirty || mntf.CFPH.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFPH.value}}</label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
            <div class="col-sm-4">
              <label class="col-sm-7 control-label">Total Chlorine(ppm):</label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="##.##" maxlength="5" formControlName="CFTotalChlorine"
                  name="CFTotalChlorine" (keypress)="numberOnly($event)"
                  (blur)="ReplaceLeadingZero('CFTotalChlorine', 2)">
                <span *ngIf="mntf.CFTotalChlorine.errors?.pattern" [ngClass]="'error'">##.##</span>
                <span
                  *ngIf="mntf.CFTotalChlorine.errors?.required && (mntf.CFTotalChlorine.dirty || mntf.CFTotalChlorine.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFTotalChlorine.value}}</label>
              </div>
            </div>
            <div class="col-sm-4">
              <label class="col-sm-6 control-label">Iron (ug/L): </label>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" placeholder="#.#" maxlength="3" formControlName="CFIron"
                  name="CFIron" (keypress)="numberOnly($event)" (blur)="ReplaceLeadingZero('CFIron')">
                <span *ngIf="mntf.CFIron.errors?.pattern" [ngClass]="'error'">#.#</span>
                <span
                  *ngIf="mntf.CFIron.errors?.required && (mntf.CFIron.dirty || mntf.CFIron.touched || formSubmitted)"
                  [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{mntf.CFIron.value}}</label>
              </div>
            </div>
            <div class="col-sm-4" [hidden]="!displayOffReplacedNo">
              <label class="col-sm-6 control-label">Cycle Flood Stop: </label>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CFFloodStop" name="CFFloodStop"
                  [style.color]="getColor(mntf.CFFloodStop.value,lstYesNoFlood)">
                  <option value="{{item.value}}" *ngFor="let item of lstYesNoFlood"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CFFloodStop" name="CFFloodStop" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CFFloodStop.errors?.required && (mntf.CFFloodStop.dirty || mntf.CFFloodStop.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CFFloodStop.value,lstYesNo)">{{mntf.CFFloodStop.value}}</label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="box-row col-sm-12" *ngIf="displayOffReplacedNo">
              <div class="row col-sm-4">
                  <p class="InnerPurposeHFSHeader">FILTERS REPLACED:
                  </p></div>
          </div>

          <div class="col-sm-12 box-row " *ngIf="displayOffReplacedNo">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">50/5 Filters:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CF505Filters" name="CF505Filters"
                  [style.color]="getColor(mntf.CF505Filters.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CF505Filters" name="CF505Filters" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CF505Filters.errors?.required && (mntf.CF505Filters.dirty || mntf.CF505Filters.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CF505Filters.value,lstTest)">{{mntf.CF505Filters.value}}</label>
              </div>
              <div class="col-sm-2" style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('CF505Filters')" id="btnCF505FilterHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>

            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Carbon Filter Replaced: </label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <mat-button-toggle-group value=""
                formControlName="CFCarbonFilter" name="CFCarbonFilter" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CFCarbonFilter.errors?.required && (mntf.CFCarbonFilter.dirty || mntf.CFCarbonFilter.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CFCarbonFilter.value,lstTest)">{{mntf.CFCarbonFilter.value}}</label>
              </div>
              <div class="col-sm-2" style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('CFCarbonFilter')"  id="btnCF505FilterHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Replaced:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CF505FiltersLastReplacedDate.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row " *ngIf="displayOffReplacedNo">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Replaced:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CFCarbonFilterLastReplacedDate.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>

          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
            <div class="col-sm-6"  *ngIf="displayOffReplacedNo">
              <label class="col-sm-6 control-label">10&mu; Filters Replaced:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CF10Filter" name="CF10Filter"
                  [style.color]="getColor(mntf.CF10Filter.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CF10Filter" name="CF10Filter" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CF10Filter.errors?.required && (mntf.CF10Filter.dirty || mntf.CF10Filter.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>

              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CF10Filter.value,lstTest)">{{mntf.CF10Filter.value}}</label>
              </div>
              <div class="col-sm-2" style="text-align:center;padding:5px 0;cursor:auto">
                <button  (click)="OpenCleanOrTestHistoryList('CF10Filter')" id="btnCF10FilterHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>
            <div [ngClass]="!displayOffReplacedNo ? 'col-sm-4' : 'col-sm-6'" *ngIf="isIronFilterRequired" >
              <label class="col-sm-6 control-label">Iron Filters: </label>
              <div [ngClass]="!displayOffReplacedNo ? 'col-sm-5' : 'col-sm-3'" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CFIronFilters" name="CFIronFilters"
                  [style.color]="getColor(mntf.CFIronFilters.value,lstYesNoNA)">
                  <option value="{{item.value}}" *ngFor="let item of lstYesNoNA"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CFIronFilters" name="CFIronFilters" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CFIronFilters.errors?.required && (mntf.CFIronFilters.dirty || mntf.CFIronFilters.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div [ngClass]="!displayOffReplacedNo ? 'col-sm-5' : 'col-sm-3'" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CFIronFilters.value,lstYesNoNA)">{{mntf.CFIronFilters.value}}</label>
              </div>
              <div [ngClass]="!displayOffReplacedNo ? 'col-sm-1' : 'col-sm-2'" style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('CFIronFilters')"  id="btnCFIronFiltersHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Replaced:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CF10FilterLastReplacedDate.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row " *ngIf="displayOffReplacedNo">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Replaced:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CFIronFiltersLastReplacedDate.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
            <div [ngClass]="displayOffReplacedNo ? 'col-sm-6' : 'col-sm-4'">
              <label class="col-sm-6 control-label">Installed Labels:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CFInstalledLabels" name="CFInstalledLabels"
                  [style.color]="getColor(mntf.CFInstalledLabels.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                  [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CFInstalledLabels" name="CFInstalledLabels" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CFInstalledLabels.errors?.required && (mntf.CFInstalledLabels.dirty || mntf.CFInstalledLabels.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>

              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CFInstalledLabels.value,lstYesNo)">{{mntf.CFInstalledLabels.value}}</label>
              </div>
            </div>

          </div>

          <!-- <div class="box-row col-sm-12  divider">
                  <div class="col-sm-8"><strong>Sectional Notes:
                    </strong></div>

                  </div> -->
          <div class="box-row col-sm-12  divider">
            <notes #cfNotes (addNoteClick)="executeNotes('Cart/Filters Checks')"></notes>

          </div>
          <div class="clearfix"></div>

          <div class="text-right  col-sm-12">
            <button type="button" class="btn btn-danger" *ngIf="checkSectionCreated('Cart/Filters Checks')" style="margin-right:5px" (click)="resetSection('Cart/Filters Checks')" >Reset</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance('Cart/Filters Checks')">Save</button> -->
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="clearfix"></div>


  <div class="clearfix"></div>
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="cwtCheckss" id="div4">
      <h4 class="panel-title row">
          <div class=" col-sm-11">
          <!-- href="#cwtChecks" data-toggle="collapse" -->
        <a class="collapsed"  data-parent="#accordion"
          aria-controls="cwtChecksRef" [attr.aria-expanded]="isCollapsed.cwtChecks"
          (click)="expandDefaultSection('Comprehensive Water Testing',!isCollapsed.cwtChecks)">
          <div class="box-header with-border" style="padding: 0px;">
            <h3 class="box-title container" style="width:110%">Comprehensive Water Testing:
                <span class="requiredClass" *ngIf="HFServiceTaskCategory && HFServiceTaskCategory.includes('Product Water Analysis')">Required</span>
              <span style="float:right"
                [style.color]="(sectionStatus.cwtChecks.formSubmitted) ? 'red' : ''" >{{sectionStatus.cwtChecks.status}}</span>
            </h3>
          </div>
        </a>
      </div>
      </h4>
    </div>
    <div id="cwtChecks" [ngbCollapse]="!isCollapsed.cwtChecks" class="panel-collapse collapse" role="tabpanel" aria-labelledby="cwtChecks">
      <div class="box-body">
        <div>
          <div class="box-row col-sm-12">
              <!-- <div class="row col-sm-8"> -->
                  <p class="InnerPurposeHFSHeader" *ngIf="isWaterAnalysis6M">FREQUENCY - PRIVATE WELL (TECH.FWP-6 MTHS):
                  </p>
                  <p class="InnerPurposeHFSHeader" *ngIf="!isWaterAnalysis6M">FREQUENCY - MUNICIPAL (TECH.FWM-YEARLY):</p>
                <!-- </div> -->
            <!--<div class="col-sm-1">
               <i class="fa fa-plus-circle fa-2x" style="cursor: pointer" (click)="quickAddWaterAnalysisDialog()"></i>
                  <i class="fa fa-plus-circle fa-2x" style="cursor: pointer" (click)="quickAddBacteriaDialog()"></i>
                  <i class="fa fa-plus-circle fa-2x" style="cursor: pointer" (click)="quickAddPyrogenDialog()"></i>
            </div>-->
          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Feed:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CWTFeed" name="CWTFeed"
                  (change)="openQuickDialogFeed(this)" [style.color]="getColor(mntf.CWTFeed.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CWTFeed" name="CWTFeed" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" (change)="openQuickDialogFeed(this)" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CWTFeed.errors?.required && (mntf.CWTFeed.dirty || mntf.CWTFeed.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CWTFeed.value,lstTest)">{{mntf.CWTFeed.value}}</label>
              </div>
              <div class="col-sm-3" style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('CWTFeed')" id="btnCWTFeedHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
                <button  id="btnCWTFeed" *ngIf="mntf.CWTFeed.value == 'Yes'" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                  <a href="javascript:void(0)" (click)="openQuickDialogFeed()" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>

            <div class="col-sm-6">
              <label class="col-sm-6 control-label">RO Product:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="CWTRoProduct" name="CWTRoProduct"
                  (change)="openQuickDialogProduct(this)" [style.color]="getColor(mntf.CWTRoProduct.value,lstTest)">
                  <option value="{{item.value}}" *ngFor="let item of lstTest"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="CWTRoProduct" name="CWTRoProduct" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onYes}}" (change)="openQuickDialogProduct(this)" class="btnsuccess">{{onYes}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offNo}}">{{offNo}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.CWTRoProduct.errors?.required && (mntf.CWTRoProduct.dirty || mntf.CWTRoProduct.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-2" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.CWTRoProduct.value,lstTest)">{{mntf.CWTRoProduct.value}}</label>
              </div>
              <div class="col-sm-3"  style="text-align:center;padding:5px 0;cursor:auto">
                <button (click)="OpenCleanOrTestHistoryList('CWTRoProduct')"  id="btnCWTRoProductHistory" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;">
                  <a href="javascript:void(0)"  title="History"><i class="fa fa-history" style="color: #fff;"></i></a>
                </button>
                <button *ngIf="mntf.CWTRoProduct.value == 'Yes'" id="btnCWTRoProduct" class="btn btn-primary  no-print btn-label-left center-block btn-xs" style="display:inline-block;margin-left:10px;">
                  <a href="javascript:void(0)" (click)="openQuickDialogProduct(this)" title="Details"><i class="fa fa-file-text" style="color: #fff;"></i></a>
                </button>
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CWTFeedLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="col-sm-12 box-row" *ngIf="mntf.CWTFeed.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Report Results Date:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CWTFeedReportResultsDate.value | date:'dd/MM/yyyy'}}
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Report ID:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  <a href="javascript:void(0)" (click)="openQuickDialogFeed(this)">{{mntf.CWTFeedReportID.value}}</a>
                </label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <div class="col-sm-12 box-row ">

            <!-- <div class="col-sm-6">
              <label class="col-sm-6 control-label">Date Last Tested:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CWTRoProductLastTested.value | date:'dd/MM/yyyy HH:mm'}}
                </label>
              </div>
            </div> -->

          </div>
          <div class="col-sm-12 box-row" *ngIf="mntf.CWTRoProduct.value == 'Yes'" style="display: none;">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Report Results Date:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  {{mntf.CWTRoProductReportResultsDate.value | date:'dd/MM/yyyy'}}
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">Report ID:</label>
              <div class="col-sm-6">
                <label class="readOnlyText">
                  <a href="javascript:void(0)" (click)="openQuickDialogProduct(this)">
                    {{mntf.CWTRoProductReportID.value}}
                  </a>
                </label>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>

          <!-- <div class="box-row col-sm-12  divider">
                  <div class="col-sm-8"><strong>Sectional Notes:
                    </strong></div>

                  </div> -->
          <div class="box-row col-sm-12  divider">
            <notes #cwtNotes (addNoteClick)="executeNotes('Comprehensive Water Testing')"></notes>

          </div>
          <div class="clearfix"></div>

          <div class="text-right  col-sm-12">
            <button type="button" class="btn btn-danger" *ngIf="checkSectionCreated('Comprehensive Water Testing')" style="margin-right:5px" (click)="resetSection('Comprehensive Water Testing')" >Reset</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance('Comprehensive Water Testing')">Save</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>



  <div class="clearfix"></div>
  <div class="panel panel-default">
    <div class="panel-heading" role="tab" id="yCheckss" id="div5">
      <h4 class="panel-title row">
          <div class=" col-sm-11">
          <!-- href="#yChecks" data-toggle="collapse" -->
        <a class="collapsed"  data-parent="#accordion"
          aria-controls="yChecksRef" [attr.aria-expanded]="isCollapsed.yearlyChecks"
          (click)="expandDefaultSection('Yearly Checks',!isCollapsed.yearlyChecks)">
          <div class="box-header with-border" style="padding: 0px;"
          >
            <h3 class="box-title container" style="width:110%">GFI Yearly Checks:
                <span class="requiredClass" *ngIf="HFServiceTaskCategory && HFServiceTaskCategory.includes('GFI')">Required</span>
              <span style="float:right"
              [style.color]="(sectionStatus.yearlyChecks.formSubmitted) ? 'red' : ''" >{{sectionStatus.yearlyChecks.status}}</span>
            </h3>
          </div>
        </a>
          </div>
      </h4>
    </div>
    <div id="yChecks" [ngbCollapse]="!isCollapsed.yearlyChecks" class="panel-collapse collapse" role="tabpanel" aria-labelledby="yChecks">
      <div class="box-body">

        <div>
          <!--YC-->
          <div class="col-sm-12 box-row ">
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">RO GFI Plug Tested:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="YCRoGFIPlugTested" name="YCRoGFIPlugTested"
                  [style.color]="getColor(mntf.YCRoGFIPlugTested.value,lstResult)">
                  <option value="{{item.value}}" *ngFor="let item of lstResult"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="YCRoGFIPlugTested" name="YCRoGFIPlugTested" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onPass}}" class="btnsuccess">{{onPass}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offFail}}">{{offFail}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.YCRoGFIPlugTested.errors?.required && (mntf.YCRoGFIPlugTested.dirty || mntf.YCRoGFIPlugTested.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.YCRoGFIPlugTested.value,lstResult)">{{mntf.YCRoGFIPlugTested.value}}</label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="col-sm-6 control-label">D. Machine GFI Plug Tested:</label>
              <div class="col-sm-3" *ngIf="IsPurposeCompleted != 'Yes'">
                <!-- <select class="form-control mdInput" formControlName="YCDmGFIPlugTested" name="YCDmGFIPlugTested"
                  [style.color]="getColor(mntf.YCDmGFIPlugTested.value,lstResult)">
                  <option value="{{item.value}}" *ngFor="let item of lstResult"
                    [style.color]="item.color ? item.color : textColor">
                    {{item.text}}
                  </option>
                </select> -->
                <mat-button-toggle-group value=""
                formControlName="YCDmGFIPlugTested" name="YCDmGFIPlugTested" form #tasktypegroup="matButtonToggleGroup">
                <mat-button-toggle value="{{onPass}}" class="btnsuccess">{{onPass}}</mat-button-toggle>
                <mat-button-toggle class="btndanger" value="{{offFail}}">{{offFail}}</mat-button-toggle>
              </mat-button-toggle-group>
              <span
              *ngIf="mntf.YCDmGFIPlugTested.errors?.required && (mntf.YCDmGFIPlugTested.dirty || mntf.YCDmGFIPlugTested.touched || formSubmitted)"
              [ngClass]="'error'"> Required!</span>
              </div>
              <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText"
                  [style.color]="getColor(mntf.YCDmGFIPlugTested.value,lstResult)">{{mntf.YCDmGFIPlugTested.value}}</label>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <!-- <div class="box-row col-sm-12  divider">
    <div class="col-sm-8"><strong>Sectional Notes:
      </strong></div>

    </div> -->
          <div class="box-row col-sm-12  divider">
            <notes #yearlyNotes (addNoteClick)="executeNotes('Yearly Checks')"></notes>
          </div>
          <div class="clearfix"></div>

          <div class="text-right  col-sm-12">
            <button type="button" class="btn btn-danger" *ngIf="checkSectionCreated('Yearly Checks')" style="margin-right:5px" (click)="resetSection('Yearly Checks')" >Reset</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'"  (click)="saveMaintenance('Yearly Checks')">Save</button> -->
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>

</div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class RPTApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetDialyisStationDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialyisStationDD',
      value,
      httpOptions
    );
  }

  GetDialysisUnitDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialysisUnitDD',
      '',
      httpOptions
    );
  }

  GetUsersDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetUsersDD',
      '',
      httpOptions
    );
  }


  FillVendorList() {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/FillVendorList',
      '',
      httpOptions
    );
  }
  // ACID Level API
  GetPagerReportsRecords(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/GetPagerReportsRecords',
      value,
      httpOptions
    );
  }

  GetPagerReportsByreportId(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/GetPagerReportsByreportId',
      value,
      httpOptions
    );
  }

  GetPagerReportsNotesByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/GetPagerReportsNotesByID',
      value,
      httpOptions
    );
  }

  GetattachemnetsByPagerNoesByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/GetattachemnetsByPagerNoesByID',
      value,
      httpOptions
    );
  }
  DeletePagerReportByReportId(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/DeletePagerReportByReportId',
      value,
      httpOptions
    );
  }

  UpdatePagerReport(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/UpdatePagerReport',
      value,
      httpOptions
    );
  }

  CreatePagerReport(value) {
    return this.http.post<any>(
      this.apiUrl + 'ReportsService/CreatePagerReport',
      value,
      httpOptions
    );
  }
  GetUsersDDByDivisionID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetUsersDDByDivisionID',
      value,
      httpOptions
    );
  }
}

<form [formGroup]="HX2AlarmForm" novalidate>
<section class="content-header">
  <h1>
    <h1>
      {{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
      <!-- <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#fff"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">Fetching Alarm/Event History...</p>
    </ngx-spinner> -->
    <div class="row">
          <div class="box  box-primary" style="overflow-y: auto;">
              <div class="box-header pageheader row" style="max-width: 95%;">
                  <div class="col-sm-1 text-left">

                    </div>
                    <div class="col-sm-10 text-center">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                      <i class="fa fa-picture-o"></i> Layout View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                      <i class="fa fa-table"></i>  Table View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                      <i class="fa fa-th-list"></i> Set Points
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                      <i class="fa fa-file"></i> Document View
                    </a>
                    <a type="button" class="btn btn-primary" style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                      <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                      <i class="fa fa-th-list"></i> Data Logs
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
              (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
              <i class="fa fa-th-list"></i> Notification Settings
            </a>
                  </div>
                  <div class="col-sm-1 text-right">
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                      <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                    </a>
                  </div>
              </div>
              <div class="col-sm-12 text-center">
                <label style="font-weight: normal;color:red" *ngIf="lstHX2AlarmEventData.length==0" >No Data Available, Please Select another Date</label>
              </div>
              <!-- <div class="box-header pageheader row" style="max-width: 100%;">
                <div class="col-sm-9">
                  <label class='col-sm-1 control-label'>Fields:</label>

                  <div class='col-sm-11'>
                  <mat-button-toggle-group multiple formControlName="ALLfieldName">
                          <mat-button-toggle (change)="SetAllButtons($event)" value="all">{{allClearValue}}</mat-button-toggle>
                  </mat-button-toggle-group>

                  <mat-button-toggle-group multiple formControlName="fieldName" name="fieldName" style="margin-left:2%">
                      <mat-button-toggle *ngFor="let item of lstHX2AlarmEventFields" style="border:none;"  value="{{item.value}}" (change)="CheckUncheckAll($event)">
                          <mat-checkbox [checked]="isChecked(item.value)" disabled="false"></mat-checkbox> {{item.text}}
                      </mat-button-toggle>
                    </mat-button-toggle-group>
              </div>
            </div>

              </div> -->

              <div class="col-sm-12 box-header">
                <label class="col-sm-7 control-label">Alarm Date:</label>
              <div class="col-sm-3">
                  <input (focus)="DTalarmDate.toggleCalendar()" (click)="DTalarmDate.openCalendar()" placeholder="Select a date"
                  class="form-control smInput" style="float:none"
                  angular-mydatepicker
                    formControlName="AlarmDate" [options]="myOptions"
                    #DTalarmDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                  required/>
              </div>
              </div>

              <div class="col-sm-offset-2 col-sm-8">

              <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                  <thead>
                      <tr>
                          <!-- <th width="5%">#</th>
                          <th width="35%">Field Name</th>
                          <th>Field Value</th>
                          <th width="15%">Date & Time</th> -->
                          <th *ngFor="let tableHeader of tableHeaders"

                          [ngClass]="setSortIndicator(tableHeader.Label)"
                          [style.width.%]="tableHeader.Width"
                          (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                        </tr>
                  </thead>
                  <tbody>
                          <tr *ngFor="let item of lstHX2AlarmEventData ;let index=index;" class="pointer">
                                  <td class="empty td_center"><b>{{index + 1}}</b></td>
                                  <td class="empty td_right"><b>{{item.fieldLabelName}}</b></td>
                                  <td class="emptyvalue td_left" matTooltip="{{item.fieldDisplayValue}}">
                                    {{ item.fieldDisplayValue }}
                                  </td>
                                  <td class="td_center">{{item.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                          </tr>
                  </tbody>
              </table>

              </div>

              <!-- <div class="col-sm-12">
                <div class="col-sm-6">
                  <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                      <thead>
                          <tr>
                              <th width="5%">#</th>
                              <th width="35%">Field Name</th>
                              <th>Field Value</th>
                              <th width="15%">Date & Time</th>
                          </tr>
                      </thead>
                      <tbody>
                              <tr *ngFor="let item of lstHX2AlarmEventData | slice:0:(lstHX2AlarmEventData.length/2) + 1 ;let index=index;" class="pointer">
                                      <td class="empty td_center"><b>{{index}}</b></td>
                                      <td class="empty td_right"><b>{{item.fieldLabelName}}</b></td>
                                      <td class="emptyvalue td_left" matTooltip="{{item.fieldDisplayValue}}">
                                        {{ item.fieldDisplayValue }}
                                      </td>
                                      <td class="td_center">{{item.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                              </tr>
                      </tbody>
                  </table>
                </div>
                <div class="col-sm-6">
                    <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                        <thead>
                            <tr>
                                <th width="5%">#</th>
                                <th width="35%">Field Name</th>
                                <th>Field Value</th>
                                <th width="15%">Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let item of lstHX2AlarmEventData | slice:(lstHX2AlarmEventData.length/2) + 1 :lstHX2AlarmEventData.length ;let index=index;" class="pointer">
                                        <td class="empty td_center"><b>{{index}}</b></td>
                                        <td class="empty td_right"><b>{{item.fieldLabelName}}</b></td>
                                        <td class="emptyvalue td_left" matTooltip="{{item.fieldDisplayValue}}">
                                          {{ item.fieldDisplayValue }}
                                        </td>
                                        <td class="td_center">{{item.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                </tr>
                        </tbody>
                    </table>
                  </div>
                  </div> -->
      </div>
    </div>
  </section>
</section>
</form>

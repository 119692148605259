<section class="content-header">
  <h1>
      {{title}}
      <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->

  </h1>
</section>
<section >

<section class="content">
<div class="row">
    <div class="box  box-primary">
        <PageHeader  [title]="title"  [showAddNew]=true [addurl] = 'AddUrl' #pageheader [(searchText)]="searchText" [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)" [showMainListing] = "false"></PageHeader>
        <div id="divSearch row" *ngIf="ShowSearch">

            <div class="col-md-12 box ibox box-primary">
                <div class="box-header">
                    <h3 class="box-title">Search Records</h3>
                </div>

                <div class="box-body">
                  <div class="row">
                      <div class="col-sm-12">
                            <!-- <div class="col-sm-6">
                                    <div class="form-group">

                                            <label class="col-sm-6 control-label">Event Id:</label>
                                            <div class="col-sm-6">
                                                <input class="form-control" [(ngModel)]="page.EventId" />
                                            </div>
                                        </div>
                                    <div class="clearfix"></div>
                                </div> -->
                          <!-- <div class="col-sm-6">
                              <div>
                                  <label class="col-sm-6 control-label">Division:</label>
                                  <div class="col-sm-6">
                                      <select class="form-control" [(ngModel)]="page.selectedDivision">
                                        <option  value="{{item.text == 'Select'? '' : item.text}}" *ngFor="let item of DivisionList">{{item.text}}</option>
                                      </select>
                                  </div>
                              </div>
                          </div> -->
                          <div class="col-sm-6">
                            <div class="form-group">

                                    <label class="col-sm-6 control-label">Report Number:</label>
                                    <div class="col-sm-6">
                                        <input class="form-control" type="text" maxlength="10" placeholder="Report number" [(ngModel)]="page.reportNumber" />
                                    </div>
                                </div>
                            <div class="clearfix"></div>
                        </div>
                          <div class="col-sm-6">
                                <div class="form-group">

                                        <label class="col-sm-6 control-label">Status:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="page.selectedStatus">
                                                <option  value="{{item.value}}" *ngFor="let item of Statuses">{{item.text}}</option>
                                              </select>

                                        </div>
                                    </div>
                                <div class="clearfix"></div>
                            </div>
                          <div class="clearfix"></div>
                      </div>
                  </div>
              </div>

                <div class="box-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6">

                                <label class="col-sm-6 control-label">Collection Date From:</label>
                                <div class="col-sm-6">
                                      <input (focus)="fromDate.toggleCalendar()" (click)="fromDate.openCalendar()"
                                      class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker name="FromDate"
                                      [(ngModel)]="page.FromDate" [options]="myOptions" #fromDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>


                                </div>


                            </div>
                            <div class="col-sm-6">
                                <div class="">
                                    <label class="col-sm-6 control-label">Collection Date To:</label>
                                    <div class="col-sm-6">
                                          <input (focus)="toDate.toggleCalendar()" (click)="toDate.openCalendar()"
                                          class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker name="ToDate"
                                          [(ngModel)]="page.ToDate" [options]="myOptions" #toDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>




                                    </div>

                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>

                <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-6">

                                    <label class="col-sm-6 control-label">Received Date From:</label>
                                    <div class="col-sm-6">
                                          <input (focus)="receivedfromDate.toggleCalendar()" (click)="receivedfromDate.openCalendar()"
                                          class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker name="receivedfromDate"
                                          [(ngModel)]="page.receivedfromDate" [options]="myOptions" #receivedfromDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>


                                    </div>


                                </div>
                                <div class="col-sm-6">
                                    <div class="">
                                        <label class="col-sm-6 control-label">Received Date To:</label>
                                        <div class="col-sm-6">
                                              <input (focus)="receivedtoDate.toggleCalendar()" (click)="receivedtoDate.openCalendar()"
                                              class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker name="receivedtoDate"
                                              [(ngModel)]="page.receivedtoDate" [options]="myOptions" #receivedtoDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>

                                        </div>

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>

                <div class="box-body">
                    <div class="row">
                        <div class="col-sm-12">




                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>

                <div class="box-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6">

                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-body row">
                    <div class="col-sm-12">

                            <button class="btn btn-primary btn-label-left center-block" id="btnSearchDetail" (click)="executeWaterAnalysisSearch()">
                                <span><i class="fa fa-search"></i></span>
                                <b>&nbsp;Search</b>
                            </button>

                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="box-body" id="dvboxbody">

              <div class="row">
                      <div class="col-sm-12">
                  <div class="col-sm-6 pull-right">
                      <div class="col text-right">
                          <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                      </div>
                   </div>
                   <div class="col-sm-3 pull-right noBorderSearchPadding">
                        <select class="form-control noBorderSearch" [(ngModel)]="page.selectedDivision"
                            (change)="DialysisUnitDropDownChanged()">
                            <!-- <option value="{{item.text == 'All Divisions'? '' : item.text}}" *ngFor="let item of DivisionList">
                                {{item.text}}
                            </option> -->
                            <option value="{{item.text}}" *ngFor="let item of DivisionList">
                                {{item.text}}
                            </option>
                        </select>
                    </div>
                   <div class="col-sm-3">
                          <div class="col text-left noofrecords" >
                          <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                          <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWaterAnalysisSearchClear()">
                          (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                          <b>Clear Search</b>
                          </a>)
                          </span>
                          </div>
                      </div>
                      </div>
                  </div>
            <div class="col-lg-12">
              <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                  <thead>


                      <tr>
                          <th *ngFor="let tableHeader of tableHeaders"

                              [ngClass]="setSortIndicator(tableHeader.Label)"
                              [style.width.%]="tableHeader.Width"
                              (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                      </tr>
                  </thead>
                      <tbody>
                          <tr *ngFor="let waterAnalysis of rows;let index=index;"
                          (click)="_onRowClick(waterAnalysis.waterprocessanalysisid)" class="pointer">
                              <td>
                                  {{(page.pageSize*(page.currentPageNumber-1)) + (index + 1)}}
                              </td>
                              <td>{{waterAnalysis.eventid}}</td>
                              <td [style.color]="waterAnalysis.result == 'Fail' ? 'red': 'black'">{{waterAnalysis.result}}</td>
                              <td>{{waterAnalysis.divisionName}}</td>
                              <td >{{waterAnalysis.collectiondate | date:'dd/MM/yyyy'}}</td>
                              <td >{{waterAnalysis.receiveddate | date:'dd/MM/yyyy'}}</td>
                              <td>{{waterAnalysis.reportNumber}}</td>
                              <td >{{waterAnalysis.createdbyName}} </td>
                              <!-- <td >{{waterAnalysis.status}} </td> -->

                              <td matTooltip="{{waterAnalysis.status}}">
                                <div class="progressbar-wrapper">
                                    <ul class="progressBar">
                                        <!-- <li [ngClass]="{'progressBarlightGreen':waterAnalysis.status == 'In Progress'}">
                                            <i [ngClass]="{'fa-check': waterAnalysis.status == 'In Progress', 'fa':waterAnalysis.status != '' }"></i>
                                        </li> -->
                                        
                                        <li [ngClass]="{'progressBarlightGreen': waterAnalysis.status == 'Completed','progressBarlightBlue': waterAnalysis.status == 'In Progress', 'progressbarEmptyRed': waterAnalysis.status == 'Open'}">
                                            <i [ngClass]="{'fa-check': waterAnalysis.status == 'Completed', 'fa-arrow-right':waterAnalysis.status == 'In Progress', 'fa':waterAnalysis.status != '' }">
                                            </i>
                                        </li>
                                    </ul> 
                                </div>                                    
                            </td>	
                              </tr>
                      </tbody>


              </table>


            </div>
              <div class="row">
                  <div class="col-sm-12">
              <div class="col-sm-9 pull-right">
                  <div class="col text-right">
                      <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                  </div>
               </div>
               <div class="col-sm-3">
                      <div class="col text-left noofrecords" >
                      <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                      <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWaterAnalysisSearchClear()">
                      (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                      <b>Clear Search</b>
                      </a>)
                      </span>
                      </div>
                  </div>
                  </div>
              </div>
        </div>
    </div>
</div>
</section>
</section>

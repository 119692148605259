import { Component, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../../globals';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { URLDialog } from '../URLDialog/URLDialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { Options } from 'selenium-webdriver/ie';
import { ImageViewerDialog } from '../ImageViewerDialog/ImageViewerDialog';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'attachment',
  templateUrl: './AttachmentComponent.html'
})
export class AttachmentComponent {
  //public attachmentList = [];
  public PrevAttachments = [];
  public DocId;
  public UploadBy;
  public moduleName = '';
  urlDialogRef: MatDialogRef<URLDialog>;
  extension: string;
  @Output()
  RaiseError = new EventEmitter();
  zoomMode="click";
  public uploadID = 'ng2Fileuploader';
  loggedInUserDesignation = this.auth.GetDesignationName();

  public uploader: FileUploader = new FileUploader({
    url: Globals.apiUrl + 'Files/Upload',
    autoUpload: true,
    method: 'POST',
    allowedFileType: ['image', 'doc', 'ppt', 'pdf', 'xls'],
    maxFileSize: 20 * 1024 * 1024
  });

  AddFileToQueue(files : File[]){
    this.uploader.addToQueue(files);
  }

  RemovePrevAttachments(fileItem){
    this.PrevAttachments.splice(this.PrevAttachments.indexOf(fileItem), 1);
  }

  ngAfterViewInit() {
    this.uploader.onAfterAddingFile = item => {
      item.withCredentials = false;

    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      var json = JSON.parse(response);
      item.index = json.uploadfilename;
    };
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);

  }
  constructor(public dialog: MatDialog,private auth :AuthService) {}

  ngOnInit() {
    this.uploader.autoUpload = true;
  }

  //file upload
  public clearQueue() {
    this.uploader.clearQueue();
  }

  public Queue() {
    return this.uploader.queue;
  }

  public SetQueue(Queue) {
    this.uploader.queue = Queue;
  }

  UploadFiles() {
    //document.getElementById('ng2Fileuploader').click();
    document.getElementById(this.uploadID).click();
  }

  SetAttachmentUrl(fileName) {
    return Globals.AttachmentUrl + '/' + fileName;
  }

  OpenFile(FileName, URL) {
    this.urlDialogRef = this.dialog.open(URLDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
    });
   // console.log(FileName,URL)
    this.urlDialogRef.componentInstance.URL = URL;
    this.urlDialogRef.componentInstance.dialogRef.updateSize('80%', '80%');
    this.urlDialogRef.componentInstance.PageTitle = FileName;
    this.urlDialogRef.afterClosed().subscribe(result => {
      this.urlDialogRef = null;
    });
  }
 openImageDialog(FileName, URL): void {

    this.extension = FileName.split('.').pop();

    if(this.extension.toLowerCase() === 'png' || this.extension.toLowerCase() === 'jpg' ||
        this.extension.toLowerCase() === 'gif' || this.extension.toLowerCase() === 'jpeg' )
    {
      const dialogRef = this.dialog.open(ImageViewerDialog, {
        position: { right: '0', top: '0' },
        height: '100%',
        width: '70%',
        data: { imgURL: URL, title : FileName }
      });
     // console.log(FileName,URL);
      dialogRef.afterClosed().subscribe(result => {
       // console.log('Image dialog was closed');
      });
    }
    else
    {
      this.urlDialogRef = this.dialog.open(URLDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
      });
     // console.log(FileName,URL)
      this.urlDialogRef.componentInstance.URL = URL;
      this.urlDialogRef.componentInstance.dialogRef.updateSize('80%', '80%');
      this.urlDialogRef.componentInstance.PageTitle = FileName;
      this.urlDialogRef.afterClosed().subscribe(result => {
        this.urlDialogRef = null;
      });
    }
  }
 

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    var errorMessage;
    switch (filter.name) {
        case 'fileSize':
            errorMessage = `Maximum upload size exceeded (${Math.round((item.size/1024/1024))} MB of 20 MB allowed)`;
            break;
        case 'mimeType':
            errorMessage = `Type '${item.type} is not allowed. Allowed types: '(JPG, GIF, PNG, DOC, XLS, PPT, PDF, JPEG)'`;
            break;
        default:
            errorMessage = `Type '${item.type} is not allowed. Allowed types: '(JPG, GIF, PNG, DOC, XLS, PPT, PDF, JPEG)'`;
    }
    // console.log(errorMessage);

      this.RaiseError.emit(errorMessage);

}
  // onErrorItem(
  //   item: FileItem,
  //   response: string,
  //   status: number,
  //   headers: ParsedResponseHeaders
  // ): any {
  //   let error = JSON.parse(response); //error server response

  //   console.log(error);
  //   if (this.RaiseError != undefined) {
  //     this.RaiseError.emit(error);
  //   }
  // }
  public GenerateAttachmentList() {
    var finalAttachmentList = [];
    for (let childObj in this.PrevAttachments) {
      var pobj = {
        attachmentId: this.PrevAttachments[childObj].attachmentId,
        Id: this.DocId,
        attachmentPath: this.PrevAttachments[childObj].attachmentpath,
        attachmentName: this.PrevAttachments[childObj].attachmentname,
        uploadedBy: this.PrevAttachments[childObj].uploadedby,
        uploadedOn: this.PrevAttachments[childObj].uploadedon,
        moduleName: this.PrevAttachments[childObj].modulename
      };
      finalAttachmentList.push(pobj);
    }
    for (let childObj in this.uploader.queue) {
      var obj = {
        attachmentId: 0,
        Id: this.DocId,
        attachmentPath: this.uploader.queue[childObj].index,
        attachmentName: this.uploader.queue[childObj].file.name,
        uploadedBy: this.UploadBy,
        uploadedOn: new Date(),
        moduleName: this.moduleName
      };
      finalAttachmentList.push(obj);
    }

    return finalAttachmentList;
  }
}

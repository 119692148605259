import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'HFSVehicleHistoryDialog',
  templateUrl: './HFSVehicleHistoryDialog.component.html',
  styleUrls: ['./HFSVehicleHistoryDialog.component.css']
})

export class HFSVehicleHistoryDialog implements AfterViewInit {

  VehicleHistoryList = [];
  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    public dialogRef: MatDialogRef<HFSVehicleHistoryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.fetchVehicleHistory(data);
   }

   fetchVehicleHistory(data){
     AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_Section_VehicleHistory({ Id: data }).subscribe(
      success => {
        this.VehicleHistoryList = success;
        AppComponent.HideLoader();

      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
   }

  ngAfterViewInit() {

  }

  ngOnInit(){

  }
}

import { Component, ViewChild, Input } from '@angular/core';

import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MachineHistoryDialog } from '../../MachineHistory/MachineHistoryDialog';
import { DMApiService } from 'src/app/API/dmapi.service';
import { TableHeadsService } from '../../tableheads.service';
import { PageHeaderComponent } from '../../pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'MachineHistorySearch',
  templateUrl: './MachineHistorySearch.component.html'
})
export class MachineHistorySearchComponent {
  UserList: any;

  //todo
  @Input() detailsType = "Dialysis Machines";
  @Input() headerTextDM = 'DM Machines History';
  @Input() headerTextH2O = 'Water Process Machines History';
  @Input() headerTextOther = 'Other Machines History';

  machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    public dialog: MatDialog,
    private auth: AuthService,
    private commonapi: CommonApiService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  selectedDivision = 'All Divisions';
  blnDirtySearch = false;
  tableHeaders;
  page = new Page();
  rows;
  currentPageNumber = 1;
  sortExpression = '';
  sortDirection = '';
  pageSize = 50;
  ShowSearch = false;

  searchText = 'Search Records';
  defaultSort;


  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'eventcode', '4');
      this.dataGridService.addHeader('Equipment Code', 'equipmentcode', '23');
      this.dataGridService.addHeader('Serial No', 'serialnumber', '15');
      this.dataGridService.addHeader('Equipment Desc', 'equipementdesc', '60');
      this.sortDirection = 'ASC';
      this.sortExpression = 'equipmentcode';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Equipment Code', false);
      // this.fillSiloes();
      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDmMachineHistorySearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDmMachineHistorySearch() {

    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const DmMachineHistorySearch = this.prepareSearch();
    this.dmapi.GetDMMachinesHistoryRecords(DmMachineHistorySearch).subscribe(
      pagedData => {
        //console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.objDetailslst;

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    const DmMachineHistorySearch = <any>{};

    if (this.selectedDivision != undefined && this.selectedDivision !="" && this.selectedDivision != 'All Divisions' ) {
      DmMachineHistorySearch.divisionId = this.selectedDivision;
    }

    DmMachineHistorySearch.categoryName = this.detailsType;
    DmMachineHistorySearch.currentPageNumber = this.currentPageNumber;
    DmMachineHistorySearch.sortExpression = this.sortExpression;
    DmMachineHistorySearch.sortDirection = this.sortDirection;
    DmMachineHistorySearch.pageSize = this.pageSize;
    console.log(DmMachineHistorySearch);
    return DmMachineHistorySearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDmMachineHistorySearch();
  }
  _onRowClick(EquipId, SerialNo, Name, InserviceDate,PMStatusHours,PMStatusType) {
    this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      width: '70%',
      height: '100%',

    });
    this.machineHistoryDialogRef.componentInstance.detailsType = this.detailsType;
    this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
    this.machineHistoryDialogRef.componentInstance.SrNo = SerialNo;
    this.machineHistoryDialogRef.componentInstance.MachineName = Name;
    this.machineHistoryDialogRef.componentInstance.InServiceDate = InserviceDate;
    if(PMStatusHours)
      this.machineHistoryDialogRef.componentInstance.PMStatusHoursFromMachine = PMStatusHours;
    if(PMStatusType)
      this.machineHistoryDialogRef.componentInstance.PMStatusType = PMStatusType;
    // this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
    //   '95%',
    //   '95%'
    // );
    this.machineHistoryDialogRef.afterClosed().subscribe(result => {
      this.machineHistoryDialogRef = null;
    });
  }

  fillDialysisUnit(defaultValue = '') {
    // this.dmapi
    //   .GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision(true).subscribe(list => {
        this.DialysisUnits = list;

        this.selectedDivision = defaultValue;

        if (defaultValue) {
          setTimeout(() => this.executeDmMachineHistorySearch());
        }
      });
  }

  executeDmMachineHistorySearchClear() {
    this.selectedDivision = '';
    this.executeDmMachineHistorySearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision != "" && this.selectedDivision !== 'All Divisions')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {

    this.executeDmMachineHistorySearch();
  }
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

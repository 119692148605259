<div [formGroup]="renovationForm" novalidate *ngIf="renovationForm">

    
  <div class="box-row marginBottom clearfix">
    <!-- <div class="col-sm-12 row">
      <div class="col-sm-12">
        <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Issue:</label>

        <div class="col-sm-10" *ngIf="IsPurposeCompleted != 'Yes'">
            <textarea rows="3" class="form-textarea" formControlName='Issue' name="Issue"></textarea>
        </div>
        <div class="col-sm-10" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText">{{rf.Issue.value}}</label>
          </div>
      </div>

        
    </div> -->
  </div>
</div>

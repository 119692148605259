import { Component, Input, Output, EventEmitter} from '@angular/core';
import { WPApiService } from '../../API/wpapi.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'AcidLevelComponent',
  templateUrl: './AcidLevelComponent.html',
})
export class AcidLevelComponent {

  public AcidLevelData = [];
  public AcidLevelFormGroup: UntypedFormGroup;
  public isValidFormSubmitted;

  constructor(private wpapi: WPApiService) {
  }

 public GetAcidLevel() {
    let acidLevelList = [];
    for (let childObj in  this.AcidLevelData) {
      acidLevelList.push({caption:this.AcidLevelData[childObj].optiondisplayas,
        acidlevel:this.AcidLevelFormGroup.controls[this.AcidLevelData[childObj].controlname].value});
    }
    return acidLevelList;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()
export class CommonApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) { }

  FillDivision(value=false) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/FillDivision',
      value,
      httpOptions
    );
  }

  GetEquipmentsByCodeAndType(value) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/GetEquipmentsByCodeAndType',
      value,
      httpOptions
    );
  }

  CreateNotesDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/CreateNotesDetails',
      value,
      httpOptions
    );
  }

  GetUsersDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetUsersDD',
      '',
      httpOptions
    );
  }

  CompareDates(fdate, edate) {
    if (fdate && edate) {
      let eDate = new Date(edate).setHours(0, 0, 0, 0);
      let fromDate = new Date(fdate).setHours(0, 0, 0, 0);

      return fromDate != eDate;
    }
    return false;
  }

  forceInputUppercase(e) {
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.setSelectionRange(start, end);
  }

  FillItemGroups(value) {
    return this.http.post<any>(
      this.apiUrl + 'CommonService/FillItemGroups',
      value,
      httpOptions
    );
  }

  CreateItemGroupDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'MasterServiceController/CreateItemGroupDetails',
      value,
      httpOptions
    );
  }

  GetLastHoursFromEquipment(value){
    return this.http.post<any>(
      this.apiUrl + 'CommonService/GetLastHoursFromEquipment',
      value,
      httpOptions
    );
  }

  FillEquipmentsByCategory(value) {
    return this.http.post<any>(
      this.apiUrl +
      'CommonService/FillEquipmentsByCategory',
      value,
      httpOptions
    );
  }

  GetItemIdByInventory(value) {

    return this.http.post<any>(
      this.apiUrl + 'CommonService/GetItemIdByInventory',
      value,
      httpOptions
    );
  }

  GetLatestForums() {
    return this.http.get<any>(
      Globals.internalForumAPI + '/LatestForums.asmx/GetLatestForums'
    );
  }

  GetMRMHoursEfficiency_Today(value){
    return this.http.post<any>(
      this.apiUrl + 'CommonService/GetMRMHoursEfficiency_Today',
      value,
      httpOptions
    );
  }
}

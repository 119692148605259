<form [formGroup]="HomeFieldServiceForm" novalidate>
  <section class="content-header">
    <h1>Home Field Service Form
      <sup class="beta">[demo]</sup>
    </h1>
  </section>

  <section class="content">
    <div class="row">
      <div class="box box-primary">

        <PageHeader   [showNote]="false" (addNoteClick)="notesVisitPurposePlus.AddNote()"
          [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
          (SaveEvent)="executeHomeFieldServiceCreateUpdate('')" [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()" #pageheader>
        </PageHeader>
        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-sm-12 box-row hidden">
                <label class="col-sm-2 control-label">HFServiceId</label>

                <div class="col-sm-10">
                  <input type="text" readonly class="form-control" name="HFServiceId" [value]="f.HFServiceId"
                    formControlName="HFServiceId">
                </div>
              </div>
              <div class="box ibox box-info">
                <div class="box-body">
                  <div class="col-sm-12 box-row">
                    <div class="col-sm-4">
                      <label class="col-sm-6 control-label">Event ID:</label>
                      <div class="col-sm-6">
                        <label class="readOnlyText"> {{HFServiceCode}} {{MasterHFSCodes ? "/ " + MasterHFSCodes : ""}}  </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="col-sm-6 control-label">Form Date:</label>
                      <div class="col-sm-6">
                        <label class="readOnlyText"> {{f.HFServiceCreatedOn.value | date:'dd/MM/yyyy HH:mm'}}</label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="col-sm-6 control-label">Created By:</label>
                      <div class="col-sm-6">
                        <label class="readOnlyText"> {{UserName}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div style="padding: 6px 6px;"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection1==true,'panel-heading': isBluePanelSection1==false}"
                    role="tab">
                    <h4 class="panel-title row">
                      <div class="col-sm-10" >
                      <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section1"
                        aria-controls="HFS_Section1Ref" (click)="ExpandSection('Section1')">
                        <div class="box-header with-border">
                          <h3 class="box-title container col-sm-3">Purpose Detail
                            <img src="../../assets/img/completed_icon.png"
                              style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                              *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                          </h3>
                          <h3 class="box-title container text-center col-sm-8">
                            <b> {{MRMHomeStationName}} </b> <b *ngIf="MRMHomeStationName!='' && f.HFServiceVisitPurpose.value!=''"> - </b><b *ngIf="f.HFServiceVisitPurpose.value!=''">
                              ({{f.HFServiceVisitPurpose.value}})</b>
                          </h3>
                        </div>
                      </a>
                    </div>
                    <div class="col-sm-2 box-header with-border text-right" style="cursor: pointer;">
                      <!-- <i class="fa fa-id-card" style="font-size: 20px;"
                      [tooltip]="HtmlContent" content-type="template" placement="left" *ngIf="this.f.HFServiceStationId.value && this.f.HFServiceStationId.value !== 'Add New'"></i> -->
                      <i class="fa fa-id-card" style="font-size: 20px;"
                      content-type="template" placement="left" *ngIf="this.f.HFServiceStationId.value && this.f.HFServiceStationId.value !== 'Add New'"></i>
                    </div>
                    </h4>
                  </div>

                  <ng-template #HtmlContent>
                    <div class="col-sm-12" style="padding:0px">
                      <div class="col-sm-3 text-right">
                        <strong>Address:</strong>
                      </div>
                      <div class="col-sm-offset-1 col-sm-8 text-left">
                        <p>{{ISV_Address}}, {{ISV_City}}, {{ISV_State}}, {{ISV_ZipCode}}</p>
                      </div>
                    </div>
                    <div class="col-sm-12" style="padding:0px">
                      <div class="col-sm-3 text-right">
                        <strong>Phone#:</strong>
                      </div>
                      <div class="col-sm-offset-1 col-sm-8 text-left">
                        <p>{{ISV_Phone}}</p>
                      </div>
                    </div>
                    <div class="col-sm-12" style="padding:0px">
                      <div class="col-sm-3 text-right">
                        <strong>Direction:</strong>
                      </div>
                      <div class="col-sm-offset-1 col-sm-8 text-left">
                        <p>{{ISV_Direction}}</p>
                      </div>
                    </div>

                    <!-- <strong>City:</strong><p>Kitchener</p>
                    <strong>Province:</strong><p>Ontario</p>
                    <strong>Postal Code:</strong><p>L7L2L8</p>
                    <strong>Phone#:</strong><p>416-318-3333</p>
                    <strong>Special Direction:</strong><p>Park on North side of street</p> -->
                  </ng-template>
                  <div id="HFS_Section1" [ngbCollapse]="!isCollapsed.Section1" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section1">
                    <div class="box-body">
                      <div class="row">
                        <div class="box-row col-sm-12">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">BMR Home Unit:</label>
                            <div class="col-sm-7" *ngIf="f.HFServiceId.value > 0">
                              <label class="readOnlyText"> {{MRMHomeStationName}}</label>
                            </div>
                            <div class="col-sm-7"
                              *ngIf="f.HFServiceId.value == 0 && f.HFServiceVisitPurpose.value!='Initial Site Visit'">
                              <select class="form-control mdInput" formControlName="HFServiceStationId"
                                (change)="homeUnitChange($event)">
                                <option value="">Select</option>
                                <option value="Add New">Add New</option>
                                <option value="{{item.dialysisUnitId}}" *ngFor="let item of dialysisStations">
                                  {{item.dialysisStationName}}
                                </option>
                              </select>
                              <span
                                *ngIf="f.HFServiceStationId.errors?.required && (f.HFServiceStationId.dirty || f.HFServiceStationId.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                                <span  *ngIf="f.HFServiceStationId.errors?.IsHFSStationAvailable" [ngClass] = "'error'">
                                  You cannot start a new HFS, while there is an open HFS still existing in the system with same Station.
                                </span>
                            </div>
                            <div class="col-sm-3"
                              *ngIf="f.HFServiceId.value == 0 && f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                              <input class="form-control" [(ngModel)]="nextStationIdPrefix" disabled
                                [ngModelOptions]="{standalone: true}">

                            </div>
                            <div class="col-sm-2"
                              *ngIf="f.HFServiceId.value == 0 && f.HFServiceVisitPurpose.value=='Initial Site Visit'"
                              style="max-width:133px">
                              <input class="form-control" formControlName="HFServiceStationName" type="text"
                                (keyup)="forceInputUppercase($event)" (keypress)="charOnly($event)" maxlength="3" placeholder="XXX">
                                <span
                                *ngIf="f.HFServiceStationName.errors?.required && (f.HFServiceStationName.dirty || f.HFServiceStationName.touched ||  formSubmitted)"
                                [ngClass]="'error'" style="    line-height: 30px;margin: 0;">Required!</span>
                            </div>
                            <div class="col-sm-1 row" *ngIf="f.HFServiceId.value == 0 && f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                                <a href="javascript:void(0)" title="XXX - First Letter of Patient First Name, and First Two Letters of Patient Last Name"><i class="fa fa-info-circle" style="color: black;margin-top: 8px;cursor: pointer;font-size: 20px"></i></a>
                            </div>

                          </div>
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Site Visit Purpose:</label>
                            <div class="col-sm-7" *ngIf="f.HFServiceId.value > 0">
                              <label class="readOnlyText"> {{f.HFServiceVisitPurpose.value}}</label>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceId.value == 0">
                              <input formControlName="HFServiceVisitPurpose" class="form-control mdInput" *ngIf="f.HFServiceVisitPurpose.value =='Initial Site Visit'" disabled="disabled">
                              <select class="form-control mdInput" formControlName="HFServiceVisitPurpose"
                                (change)="visitPurposeChange()" *ngIf="f.HFServiceVisitPurpose.value !='Initial Site Visit'" >
                                <option value="{{item.value}}" *ngFor="let item of visitPurposeFinalList">
                                  {{item.text}}
                                </option>
                              </select>
                              <span
                                *ngIf="f.HFServiceVisitPurpose.errors?.required && (f.HFServiceVisitPurpose.dirty || f.HFServiceVisitPurpose.touched ||  formSubmitted)"
                                [ngClass]="'error'">
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <span class="col-sm-offset-5 col-sm-7" style="color:red;font-size: 12px;"
                                *ngIf="f.HFServiceStationId.value && (ISV_DMEquipment == null || ISV_ROEquipment == null)">{{ISV_Error}}</span>
                          </div>

                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Maintenance & Testing'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Maintenance & Testing Type:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <select class="form-control mdInput" formControlName="HFServiceCategory">
                                <option value="{{item.value}}" *ngFor="let item of maintenanceCategories">
                                  {{item.text}}
                                </option>
                              </select>
                              <span
                                *ngIf="f.HFServiceCategory.errors?.required && (f.HFServiceCategory.dirty || f.HFServiceCategory.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.HFServiceTaskCategory.value ? f.HFServiceTaskCategory.value : f.HFServiceCategory.value}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Equipment Exchange'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Equipment Exchange Type:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <select class="form-control mdInput" formControlName="HFServiceCategory"
                                (change)="EquipmentCategoryChange()">
                                <option value="{{ (item.text) == 'Select' ? '' : item.text }}"
                                  *ngFor="let item of EquipmentExchangeCategoryList">
                                  {{item.text}}
                                </option>
                              </select>
                              <span
                                *ngIf="f.HFServiceCategory.errors?.required && (f.HFServiceCategory.dirty || f.HFServiceCategory.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.HFServiceCategory.value}}</label>
                            </div>
                          </div>
                        </div>

                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Repair'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Repair Type:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <select class="form-control mdInput" formControlName="HFServiceCategory"
                                (change)="EquipmentCategoryChange()">
                                <option value="{{item.text}}" *ngFor="let item of RepairCategoryList">
                                  {{item.text}}
                                </option>
                              </select>
                              <span
                                *ngIf="f.HFServiceCategory.errors?.required && (f.HFServiceCategory.dirty || f.HFServiceCategory.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.HFServiceCategory.value}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Repair'">
                          <label class="col-sm-2 control-label" style="width: 21%">Equipment Issue:</label>
                          <div class="col-sm-10" style="width: 72.7%" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                            <textarea autosize class="form-textarea" formControlName="HFServiceIssue"></textarea>
                            <span
                              *ngIf="f.HFServiceIssue.errors?.required && (f.HFServiceIssue.dirty || f.HFServiceIssue.touched ||  formSubmitted)"
                              [ngClass]="'error'">Required!</span>
                          </div>
                          <div class="col-sm-10" style="width: 72.7%;word-break: break-word;" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                            <label class="readOnlyText">{{f.HFServiceIssue.value}}</label>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Address:</label>
                            <div class="col-sm-6" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <textarea autosize class="form-textarea mdInput" formControlName="ISVAddress"></textarea>
                            </div>
                            <span
                              *ngIf="f.ISVAddress.errors?.required && (f.ISVAddress.dirty || f.ISVAddress.touched ||  formSubmitted)"
                              [ngClass]="'error'" style="    margin: -14px;padding: 0;">Required!</span>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVAddress.value}}</label>
                            </div>
                          </div>

                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">City:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <input class="form-control mdInput" formControlName="ISVCity">
                              <span
                                *ngIf="f.ISVCity.errors?.required && (f.ISVCity.dirty || f.ISVCity.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVCity.value}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Province:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <input class="form-control mdInput" formControlName="ISVProvince">
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVProvince.value}}</label>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Postal Code:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <input class="form-control mdInput" formControlName="ISVPostalCode" maxlength="7"
                                (keyup)="forceInputUppercase($event)" placeholder="A0A 0A0" (focusout)="lostfocusPostalCode()">
                              <span *ngIf='f.ISVPostalCode.errors?.pattern' [ngClass]="'error'" style="margin-left: 2px;">Invalid value</span>
                              <span
                                *ngIf="f.ISVPostalCode.errors?.required && (f.ISVPostalCode.dirty || f.ISVPostalCode.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVPostalCode.value}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Phone #:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <input class="form-control mdInput" (keypress)="numberOnly($event)" maxLength="15"
                                placeholder="(###) ###-####" (blur)="formatPhone('phone')"
                                formControlName="ISVPhoneNumber">
                              <span
                                *ngIf="f.ISVPhoneNumber.errors?.required && (f.ISVPhoneNumber.dirty || f.ISVPhoneNumber.touched ||  formSubmitted)"
                                [ngClass]="'error'">Required!</span>
                              <span *ngIf='f.ISVPhoneNumber.errors?.pattern' [ngClass]="'error'"
                                style="margin-left: 2px;">Invalid value</span>
                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVPhoneNumber.value}}</label>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <label class="col-sm-5 control-label">Access Code:</label>
                            <div class="col-sm-7" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                              <input class="form-control mdInput" maxLength="15" placeholder=""
                                formControlName="ISVAccessCode">

                            </div>
                            <div class="col-sm-7" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                              <label class="readOnlyText">{{f.ISVAccessCode.value}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                          <label class="col-sm-2 control-label" style="width: 21.05%;">Directions:</label>
                          <div class="col-sm-9" style="width: 72.6%;word-break: break-word;" *ngIf="!f.HFServiceVisitDetailsEnteredOn.value">
                            <textarea autosize class="form-textarea" formControlName="ISVDirections"></textarea>
                          </div>
                          <span
                            *ngIf="f.ISVDirections.errors?.required && (f.ISVDirections.dirty || f.ISVDirections.touched ||  formSubmitted)"
                            [ngClass]="'error'" style="margin:0">Required!</span>
                          <div class="col-sm-9" style="width: 72.6%;word-break: break-word;" *ngIf="f.HFServiceVisitDetailsEnteredOn.value">
                            <label class="readOnlyText">{{f.ISVDirections.value}}</label>
                          </div>
                        </div>
                        <div class="col-sm-12" style="margin-top: 15px;">
                          <notes
                            [isValidForm]="f.HFServiceVisitDetailsEnteredOn.value || this.HomeFieldServiceForm.invalid"
                            #notesVisitPurpose
                            (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section2',true,f.HFServiceVisitDetailsEnteredOn.value)">
                          </notes>
                        </div>
                        <div class="col-sm-12" [hidden]="f.HFServiceVisitDetailsEnteredOn.value">
                          <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [showSave]="true"
                            [showMainListing]="false" (SaveEvent)="executeHomeFieldServiceCreateUpdate('Section2')"
                            [showDelete]="false" #pageheader>
                          </PageHeader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection2==true,'panel-heading': isBluePanelSection2==false}"
                    role="tab">
                    <h4 class="panel-title row">
                      <div class="col-sm-10" style="cursor: pointer;" (click)="ExpandSection('Section2')">
                        <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section2"
                          aria-controls="HFS_Section2Ref">
                          <div class="box-header with-border" style="padding: 6px 0px;">
                            <h3 class="box-title col-sm-3 container">Prep Detail
                              <img src="../../assets/img/completed_icon.png"
                                style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                                *ngIf="f.HFServiceVehiclePrepDetailsEnteredOn.value">
                            </h3>
                            <h3 class="col-sm-8 text-center box-title container" *ngIf="f.HFServiceVisitNotificationEnteredOn.value">
                              <b>Total Prep Time : {{totalPrepDetailTime}} Hrs</b>
                            </h3>
                          </div>
                        </a>
                      </div>
                      <div class="row col-sm-2" style="text-align:right">
                        <button style="display: inline-block;cursor: pointer;" type="button"
                          class="row btn"
                          [ngClass]="{'btn-primary':!f.HFServiceVehiclePrepDetailsEnteredOn.value}"
                          tooltip="Add Prep Detail"
                          (click)="OpenPrepDetail()"
                          *ngIf="f.HFServiceVisitDetailsEnteredOn.value && f.HFServicePTHMPurposeCompleted.value != 'Yes'">
                          <span><i class="fa fa-cog"></i>&nbsp; Prep Detail</span>
                        </button>
                      </div>
                    </h4>
                  </div>

                  <div id="HFS_Section2" [ngbCollapse]="!isCollapsed.Section2" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section2">
                    <div class="box-body">
                      <div class="row" >
                          <div class="row" *ngIf="(f.HFServiceVisitPurpose.value == 'Renovation & Full Installation' ||
                          f.HFServiceVisitPurpose.value == 'Uninstallation') && PrepDetailsList.length > 0" >
                          <div class="col-sm-12 box-row">
                              <div class="col-sm-offset-2 col-sm-4">
                                  <label class="col-sm-5 control-label">PO Plumber:</label>
                                  <div class="col-sm-7">
                                      <label class="readOnlyText" formControlName="poPlumber"> {{f.poPlumber.value}}</label>
                                  </div>
                              </div>
                              <div class="col-sm-4">
                                <label class="readOnlyText" formControlName="poPlumberNote"> {{f.poPlumberNote.value}}</label>
                              </div>
                          </div>
                          <div class="col-sm-12 box-row">
                              <div class="col-sm-offset-2 col-sm-4">
                                  <label class="col-sm-5 control-label">PO Electrician:</label>
                                  <div class="col-sm-7">
                                      <label class="readOnlyText" formControlName="poElectrician"> {{f.poElectrician.value}}</label>
                                  </div>
                              </div>
                              <div class="col-sm-4">
                                <label class="readOnlyText" formControlName="poElectricianNote"> {{f.poElectricianNote.value}}</label>
                              </div>
                          </div>
                          <div class="col-sm-12 box-row">
                              <div class="col-sm-offset-2 col-sm-4">
                                  <label class="col-sm-5 control-label">PO Movers:</label>
                                  <div class="col-sm-7">
                                      <label class="readOnlyText" formControlName="poMovers"> {{f.poMovers.value}}</label>
                                  </div>
                              </div>
                              <div class="col-sm-4">
                                <label class="readOnlyText" formControlName="poMoversNote"> {{f.poMoversNote.value}}</label>
                              </div>
                          </div>
                        </div>
                        <div class="box-row col-sm-12" *ngIf="PrepDetailsList.length > 0">
                          <table style="width:100%" align="center" class="table InnerTable table-responsive">
                            <tr>
                              <th class="InnerTableHead" style="width:10%">
                                Date
                              </th>
                              <th class="InnerTableHead"  style="width:10%">
                                Prep Time(Hr.)
                              </th>
                              <th class="InnerTableHead" style="width:15%">
                                Technologist(s)
                              </th>
                              <th class="InnerTableHead"  style="width:65%">
                                Notes
                              </th>

                            </tr>
                            <tbody *ngFor="let item of PrepDetailsList;let index=index;">
                              <tr>
                                <td>
                                  {{item.prepDate | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                  {{item.prepTime}}
                                </td>
                                <td title="{{item.prepTechnologists}}">
                                  {{item.createdByName}} <span *ngIf="item.prepTechnologists!=''" >..</span>
                                </td>
                                <td  class="text-left">
                                    <div class="break-words">
                                  {{item.prepNotes}}
                                </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <!-- <div class="box-row col-sm-12" style="margin-top:20px;">
                          <div class="col-sm-6" style="margin-top: 5px;">
                            <span
                            *ngIf="f.HFServiceVehiclePrepTime.errors?.required && (f.HFServiceVehiclePrepTime.dirty || f.HFServiceVehiclePrepTime.touched ||  formSubmitted)"
                            [ngClass]="'error'" style="font-size: 14px;">Atleast one Prep Detail entry is required.</span>

                          </div>

                         </div> -->

                        <div class="col-sm-12" style="margin-top: 15px;">
                          <notes
                            #notesVisitPrep
                            (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section3',true,f.HFServiceVehiclePrepDetailsEnteredOn.value)">
                          </notes>
                        </div>
                        <div class="col-sm-12">
                          <!-- <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [showSave]="true"
                            [showMainListing]="false" (SaveEvent)="executeHomeFieldServiceCreateUpdate('Section3')"
                            [showDelete]="false" #pageheader>
                          </PageHeader> -->

                          <div class="col-sm-12" *ngIf="f.HFServiceVehiclePrepDetailsEnteredOn.value">
                            <label class="text-right col-sm-8 control-label">Spoke with Pt. about Visit:</label>
                            <div class="col-sm-4" *ngIf="f.HFServiceVehiclePrepDetailsEnteredOn.value && f.HFServiceVisitNotification.value ==='Spoke' && f.HFServicelastModifiedSection.value !== 'Pending'">
                              <!-- <label class="readOnlyText"> {{f.HFServiceVisitNotification.value == 'Spoke' ? 'Yes': ''}}</label> -->
                              <label class="readOnlyText"> {{f.HFServiceVisitNotificationEnteredOn.value | date:'dd/MM/yyyy HH:mm'}}</label>
                              <label class="readOnlyText"> {{CurrentUser}} </label>
                            </div>
                          </div>
                          <div class="col-sm-11" *ngIf="!f.HFServiceVehiclePrepDetailsEnteredOn.value">
                              <label class="col-sm-offset-5 col-sm-6 control-label">Spoke with Pt. about Visit:</label>
                            <div class="col-sm-1" style="padding: 0.5em 0;" *ngIf="(!f.HFServiceVehiclePrepDetailsEnteredOn.value || f.HFServiceVisitNotification.value !=='Spoke' || f.HFServicelastModifiedSection.value === 'Pending')">
                              <!-- <select class="form-control mdInput" formControlName="HFServiceVisitNotification">
                                <option value="{{item.value}}" *ngFor="let item of visitNotification">
                                  {{item.text}}
                                </option>
                              </select> -->
                              <mat-checkbox (change)="OnVisitNotificationChange($event)"></mat-checkbox>
                              <button style=" display: inline-block;margin-left: 20px;margin-bottom: 5px;" class="btn btn-primary center-block btn-xs"
                                      (click)="OpenQuestionList()" tooltip="Pre-Visit Safety Assessment">
                                      <span><i class="fa fa-list"></i></span>
                              </button>
                              <span
                                *ngIf="f.HFServiceVisitNotification.errors?.required && (f.HFServiceVisitNotification.dirty || f.HFServiceVisitNotification.touched ||  formSubmitted)"
                                [ngClass]="'error'">
                                Required!
                              </span>
                            </div>
                            <!-- <div style="padding: 0.5em 0;" class="row col-sm-1" *ngIf="!f.HFServiceVehiclePrepDetailsEnteredOn.value || f.HFServiceVisitNotification.value !=='Spoke' || f.HFServicelastModifiedSection.value === 'Pending'">

                            </div> -->
                          </div>
                          <div class="row col-sm-1">
                            <button type="button" class="row btn btn-primary"
                            *ngIf="f.HFServiceVisitNotification.value && f.HFServiceVisitNotification.value !== '' && !f.HFServiceVehiclePrepDetailsEnteredOn.value && PrepDetailsList.length > 0"
                            (click)="executeHomeFieldServiceCreateUpdate('Section3')">
                            <i class="fa fa-save"></i>&nbsp; Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection3==true,'panel-heading': isBluePanelSection3==false}"
                    title="{{ (f.HFServiceVehiclePrepDetailsEnteredOn.value && f.HFServiceVisitNotification.value !=='Spoke') ? 'Change Visit Notification to Spoke to Enter Vehicle Detail' : ''}}"
                    role="tab">
                    <h4 class="panel-title row">
                      <div class="col-sm-10"  style="cursor: pointer;" (click)="ExpandSection('Section3')">
                        <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section3"
                          aria-controls="HFS_Section3Ref">
                          <div class="box-header with-border" style="padding: 6px 0px;">
                            <h3 class="box-title col-sm-3 container">Vehicle Detail
                              <img src="../../assets/img/completed_icon.png"
                                style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                                *ngIf="f.HFServiceVehicleDetailsEnteredOn.value">
                            </h3>
                            <h3 class="col-sm-8 text-center box-title container">
                              <b *ngIf="VehicleListSummary!==''" >Vehicle List : {{VehicleListSummary}}</b>
                            </h3>

                          </div>
                        </a>
                      </div>
                      <div class="row col-sm-2" style="text-align:right">
                        <!-- <button *ngIf="isAdditionalAddVehicle" style="display: inline-block;cursor: pointer;"
                          tooltip="Add Vehicle Detail" (click)="OpenVehicleDetail()"
                          class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button">
                          <span><i class="fa fa-plus"></i></span>
                        </button> -->
                        <button *ngIf="isAdditionalAddVehicle" style="display: inline-block;cursor: pointer;" type="button" class="row btn btn-primary"
                        tooltip="Add Vehicle Detail" (click)="executeHomeFieldServiceCreateUpdate('Section4')">
                        <i class="fa fa-truck"></i> &nbsp; Vehicle Detail</button>
                      </div>
                    </h4>
                  </div>

                  <div id="HFS_Section3" [ngbCollapse]="!isCollapsed.Section3" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section3">
                    <div class="box-body">
                      <div class="row">
                        <div class="box-row col-sm-12" style="margin-top: 5px;">
                          <span
                          *ngIf="f.HFServiceVehiclePrepTime.errors?.required && (f.HFServiceVehiclePrepTime.dirty || f.HFServiceVehiclePrepTime.touched ||  formSubmitted)"
                          [ngClass]="'error'" style="font-size: 14px;">Atleast one Vehicle Detail entry is required.</span>

                        </div>
                        <div class="box-row col-sm-12" *ngIf="getVehicleList()">
                          <table style="width:100%" align="center" class="table InnerTable table-responsive">
                            <tr>
                              <!-- <th class="InnerTableHead" style="width:3%">

                              </th> -->
                              <th class="InnerTableHead" style="width:8%">
                                Vehicle
                              </th>
                              <th class="InnerTableHead"  style="width:9%">
                                Inspection
                              </th>
                              <th class="InnerTableHead"  style="width:8%">
                                Start KM
                              </th>
                              <th class="InnerTableHead"  style="width:8%">
                                End KM
                              </th>
                              <th class="InnerTableHead"  style="width:10%">
                                Start Time
                              </th>
                              <th class="InnerTableHead"  style="width:10%">
                                End Time
                              </th>
                              <th class="InnerTableHead"  style="width:47%">
                                Notes
                              </th>

                            </tr>
                            <tbody *ngFor="let item of VehicleDetailsList;let index=index;">
                              <tr *ngIf="item.isAddedFromMasterHFS != true">
                                <!-- <td>
                                  {{(index + 1)}}
                                </td> -->
                                <td>
                                  {{item.hfServiceVehicleName}}
                                </td>
                                <td>
                                  {{item.hfServiceVehicleInspectionStatus ? "All Passed" : "Failures Noted"}}
                                </td>
                                <td>
                                  {{item.hfServiceVehicleStartedKM}}
                                </td>
                                <td>
                                  {{item.hfServiceVehicleEndedKM}}
                                </td>
                                <td>
                                  {{item.hfServiceVehicleStartTime | date:'dd/MM/yyyy HH:mm'}}
                                </td>
                                <td>
                                  {{item.hfServiceVehicleEndTime | date:'dd/MM/yyyy HH:mm'}}
                                </td>
                                <td style="text-align:left">
                                  <div class="break-words">
                                  {{ item.hfServiceVehicleInspectionNotes}}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-sm-12"  style="margin-top: 15px;">
                          <notes
                            #notesVisitVehicles
                            (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section4',true,f.HFServiceVehicleDetailsEnteredOn.value)">
                          </notes>
                        </div>
                        <div class="row text-right col-sm-12" [hidden]="f.HFServiceVehicleDetailsEnteredOn.value">
                          <!-- <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [showSave]="true"
                            [showMainListing]="false" (SaveEvent)="executeHomeFieldServiceCreateUpdate('Section4')"
                            [showDelete]="false" #pageheader>
                          </PageHeader> -->
                          <!-- <button type="button" class="row btn btn-primary"
                            (click)="executeHomeFieldServiceCreateUpdate('Section4')">
                            <i class="fa fa-truck"></i> &nbsp; Vehicle Detail</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection4==true,'panel-heading': isBluePanelSection4==false}"
                    role="tab">
                    <h4 class="panel-title row">
                      <div class="col-sm-10" style="cursor:pointer" (click)="ExpandSection('Section4')">
                        <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section4"
                          aria-controls="HFS_Section4Ref">
                          <div class="box-header with-border" style="padding: 6px 0px;">
                            <h3 class="box-title col-sm-4 container">Visit Purpose Detail
                              <span *ngIf="otherHFSPurposeList.length > 0"> - Master</span>
                              <img src="../../assets/img/completed_icon.png"
                                style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                                *ngIf="f.HFServicePTHMPurposeCompletedOn.value">

                            </h3>
                            <h3 *ngIf="MRMHomeStationName!=''" class="col-sm-6 text-center box-title container">
                              <b>{{MRMHomeStationName}} </b> <b *ngIf="MRMHomeStationName!='' && f.HFServiceVisitPurpose.value!=''"> - </b><b *ngIf="f.HFServiceVisitPurpose.value!=''">
                                ({{f.HFServiceVisitPurpose.value}})</b></h3>
                          </div>
                        </a>
                      </div>
                      <div class="row col-sm-2" style="text-align:right">
                        <!-- <button style="display: inline-block;cursor: pointer;" tooltip="Add Departure Detail"
                            (click)="OpenPurposeDetail(true)"
                            *ngIf="isAdditionalAddDeparture"
                            class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button">
                            <span><i class="fa fa-plus"></i></span>
                        </button> -->

                        <!-- <button style="display: inline-block;cursor: pointer;" tooltip="Add Arrival Detail"
                            (click)="OpenPurposeDetail()"
                            *ngIf="isAdditionalAddArrival"
                            class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button">
                            <span><i class="fa fa-plus"></i></span>
                        </button> -->

                        <button type="button" class="row btn btn-primary"
                          *ngIf="isAdditionalAddDeparture"
                          (click)="executeHomeFieldServiceCreateUpdate('Section5')">
                          <i class="fa fa-clock-o"></i> &nbsp; Departure Details</button>

                        <button type="button" class="row btn btn-primary"
                          *ngIf="isAdditionalAddArrival"  tooltip="Add Arrival Detail"
                          (click)="executeHomeFieldServiceCreateUpdate('Section5')">
                          <i class="fa fa-clock-o"></i> &nbsp; Arrival Details
                        </button>

                      </div>
                    </h4>
                  </div>

                  <div id="HFS_Section4" [ngbCollapse]="!isCollapsed.Section4" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section4">
                    <div class="box-body">
                      <div class="box-row col-sm-12" *ngIf="f.HFServicePTHMArrivalTime.value" >

                        <span class="border border-primary col-sm-12" style="height: auto;text-align:center"
                        *ngIf="f.HFServiceVisitPurpose.value!='Initial Site Visit' &&
                            f.HFServiceVisitPurpose.value!='Uninstallation'">

                          <mat-button-toggle-group [(ngModel)]="purposeSelectedId"
                                                name="purposeSelectedId" style="margin-left:1%"
                                                [ngModelOptions]="{standalone: true}">
                              <mat-button-toggle *ngFor="let item of visitPurposeWithoutRenovationList"
                                    value="{{item.value}}" (change)="purposeSelection(item)" [style.color]="item.isFormSubmitted ? '#4f68e1' : '' ">
                                        {{item.text}}
                              </mat-button-toggle>
                          </mat-button-toggle-group>
                        </span>
                        <!-- <span class="border border-primary col-sm-12" style="height: auto;text-align:center"
                        *ngIf="f.HFServiceVisitPurpose.value=='Renovation & Full Installation'">

                          <mat-button-toggle-group [(ngModel)]="purposeSelectedId"
                                                name="purposeSelectedId" style="margin-left:1%"
                                                [ngModelOptions]="{standalone: true}">
                              <mat-button-toggle *ngFor="let item of visitPurposeRenovationList"
                                    value="{{item.value}}" (change)="purposeSelection(item)" [style.color]="item.isFormSubmitted ? '#4f68e1' : '' ">
                                        {{item.text}}
                              </mat-button-toggle>
                          </mat-button-toggle-group>
                        </span> -->

                        <span class="border border-primary" style="height: auto"
                        *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit' ||
                            f.HFServiceVisitPurpose.value=='Uninstallation'">

                          <!-- Add Dynamic Component based on the Purpose-->

                          <PurposeInitialSiteVisit *ngIf="f.HFServiceVisitPurpose.value=='Initial Site Visit'">
                          </PurposeInitialSiteVisit>
                          <!-- <PurposeFullInstallation
                            *ngIf="f.HFServiceVisitPurpose.value=='Renovation & Full Installation'">
                          </PurposeFullInstallation> -->
                          <PurposeUninstallation *ngIf="f.HFServiceVisitPurpose.value=='Uninstallation'">
                          </PurposeUninstallation>

                          <!-- <PurposeUninstallation *ngIf="f.HFServiceVisitPurpose.value=='Uninstallation'">
                          </PurposeUninstallation>
                          <PurposeEquipmentExchange *ngIf="f.HFServiceVisitPurpose.value=='Equipment Exchange'">
                          </PurposeEquipmentExchange>
                          <PurposeTesting *ngIf="f.HFServiceVisitPurpose.value=='Testing'"></PurposeTesting>
                          <PurposeRepair *ngIf="f.HFServiceVisitPurpose.value=='Repair'"></PurposeRepair>
                          <PurposeMaintenance *ngIf="f.HFServiceVisitPurpose.value=='Maintenance & Testing'"
                            (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section5',true,true)"></PurposeMaintenance>
                          <PurposeOther *ngIf="f.HFServiceVisitPurpose.value=='Other'"></PurposeOther> -->

                          <h3 *ngIf="f.HFServiceVisitPurpose.value==''" class='error'>
                            <!-- Add Dynamic Component based on the Purpose -->
                            <b style="text-decoration: underline;cursor: pointer;" (click)="ExpandSection('Section1')">
                              Select Purpose For First Station...</b>
                          </h3>
                        </span>
                      </div>
                        <div class="box-body col-sm-12" *ngIf="PurposeDetailsList.length > 0">
                            <table style="width:100%" align="center" class="table InnerTable table-responsive">
                              <!-- <tr>
                                <th class="thead-light" width="100%" colspan="5">
                                  Visit Purpose Details List
                                </th>
                              </tr> -->
                              <tr>
                                <!-- <th class="InnerTableHead" style="width:5%">

                                </th> -->
                                <th class="InnerTableHead"  style="width:12%">
                                  Pt. Hm Arrival Time
                                </th>
                                <th class="InnerTableHead"  style="width:12%">
                                  Pt. Hm Dep.Time
                                </th>
                                <th class="InnerTableHead"  style="width:12%">
                                  Purpose Completed?
                                </th>
                                <th class="InnerTableHead"  style="width:64%">
                                  Notes
                                </th>

                              </tr>
                              <tbody *ngFor="let item of PurposeDetailsList;let index=index;">
                                <tr>
                                  <!-- <td>
                                    {{(index + 1)}}
                                  </td> -->
                                  <td>
                                    {{item.hfServicePTHMArrivalTime  | date:'dd/MM/yyyy HH:mm'}}
                                  </td>
                                  <td>
                                    {{item.hfServicePTHMDepartureTime  | date:'dd/MM/yyyy HH:mm'}}
                                  </td>
                                  <td>
                                    {{item.hfServicePTHMPurposeCompleted}}
                                  </td>
                                  <td style="text-align: left">
                                    <div class="break-words">
                                    {{item.hfServicePurposeDetailNotes}}
                                  </div>
                                  </td>

                                </tr>
                              </tbody>
                            </table>
                          </div>
                        <!-- <div class="col-sm-12"  style="margin-top: 15px;">
                          <notes [hidden]="!(f.HFServiceVisitPurpose.value=='Other')" #notesVisitPurposePlus
                            (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section5',true,true)">
                          </notes>
                        </div> -->
                      <div class="col-sm-12"  style="margin-top: 15px;" [hidden]="!f.HFServicePTHMArrivalTime.value" >
                        <notes
                          #notesVisitPurposePlus
                          (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section5',true,true)">
                        </notes>

                        <attachment *ngIf="f.HFServiceVisitPurpose.value!='Repair'" #attachmentVisitPurposePlus
                          id="attachmentVisitPurposePlus" (RaiseError)="AttachmentError($event)">

                        </attachment>
                      </div>
                      <div class="text-right col-sm-12">

                        <!-- <button type="button" class="btn btn-primary"
                          *ngIf="isAdditionalAddArrival"  tooltip="Add Arrival Detail"
                          (click)="executeHomeFieldServiceCreateUpdate('Section5')">
                          <i class="fa fa-clock-o"></i> &nbsp; Arrival Details
                        </button> -->

                          <!-- <button type="button" class="btn btn-primary"
                          *ngIf="isAdditionalAddDeparture"
                          (click)="executeHomeFieldServiceCreateUpdate('Section5')">
                          <i class="fa fa-clock-o"></i> &nbsp; Departure Details</button> -->

                          <button type="button" class="btn btn-primary"
                          *ngIf="displaySaveButton('VisitPurposePlus')"
                          (click)="executeHomeFieldServiceCreateUpdate('Section5')">
                          <i class="fa fa-save"></i> &nbsp; Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div *ngFor="let purposeItem of allOtherHFSList;let index=index;">
                  <app-panel-purpose-detail *ngIf="purposeItem.hfServiceReferenceId"
                  [HFMasterServiceId]="purposeItem.hfServiceId"
                  [HFServiceConnectedId]="purposeItem.hfServiceConnectedId"
                  [PrevSectionDetails]="previousVehicleDetails()"
                  [MasterCompleted]="f.HFServiceStatus.value == 'Completed'"
                  [MasterStationID]="f.HFServiceStationId.value"
                  [isLastAdded]="allOtherHFSList.length == (index +1)"
                  (SaveEvent)="onPanelSave($event)"
                  [LastDepartureDate]="f.HFServicePTHMDepartureTime.value"
                  #panelPurposeDetailComponent
                  ></app-panel-purpose-detail>

                  <app-panel-otherPurpose-detail *ngIf="purposeItem.otherId"
                  [HFMasterServiceId]="purposeItem.hfServiceId"
                  [OtherId]="purposeItem.otherId"
                  (SaveEvent)="onOtherSectionSave($event)"
                  [isLastAdded]="allOtherHFSList.length == (index +1)"
                  [MasterCompleted]="f.HFServiceStatus.value == 'Completed'"
                  #panelOtherSectionDetailComponent
                  ></app-panel-otherPurpose-detail>
<!--
                  [HFSStationId]="purposeItem.childHFServiceStationId"
                  [MRMHomeStationName]="purposeItem.childDialysisStationName"
                  [HFServiceVisitPurpose]="purposeItem.childVisitPurpose" -->
              </div>

              <!-- <div *ngFor="let purposeItem of otherHFSSectionList">

              </div> -->


              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection5==true,'panel-heading': isBluePanelSection5==false}"
                    role="tab">
                    <h4 class="panel-title row">
                      <div class="col-sm-10" style="cursor:pointer" (click)="ExpandSection('Section5')">
                        <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section5"
                          aria-controls="HFS_Section5Ref">
                          <div class="box-header with-border" style="padding: 6px 0px;">
                            <h3 class="box-title container">Return Details
                              <img src="../../assets/img/completed_icon.png"
                                style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                                *ngIf="f.HFServiceReturnDetailsEnteredOn.value">

                            </h3>
                          </div>
                        </a>
                      </div>
                      <div class="col-sm-2" style="text-align:right">
                        <!-- <button style="display: inline-block;cursor: pointer;" tooltip="Add Return Detail"
                          (click)="OpenReturnDetail()"
                          *ngIf="isAdditionalAddReturn"
                          class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button">
                          <span><i class="fa fa-plus"></i></span>
                        </button> -->

                        <button type="button" class="btn btn-primary"
                          *ngIf="isAdditionalAddReturn && !isAnyPanelEnable"
                          (click)="executeHomeFieldServiceCreateUpdate('Section6')">
                          <i class="fa fa-hospital-o"></i> &nbsp; Return Detail</button>

                      </div>
                    </h4>
                  </div>

                  <div id="HFS_Section5" [ngbCollapse]="!isCollapsed.Section5" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section5">
                    <div class="box-body">
                      <div class="box-body box-row col-sm-12" *ngIf="getReturnList()">
                        <table style="width:100%" align="center" class="table InnerTable table-responsive">
                          <!-- <tr>
                            <th class="thead-light" width="100%" colspan="8">
                              Return Details List
                            </th>
                          </tr> -->
                          <tr>
                            <!-- <th class="InnerTableHead" style="width:3%">

                            </th> -->
                            <th class="InnerTableHead" style="width:10%">
                              End Time
                            </th>
                            <th class="InnerTableHead" style="width:10%">
                              End KM
                            </th>
                            <th class="InnerTableHead" style="width:8%">
                              Fuel Added
                            </th>
                            <th class="InnerTableHead" style="width:7%">
                              Company
                            </th>
                            <th class="InnerTableHead" style="width:7%">
                              Amount
                            </th>
                            <th class="InnerTableHead" style="width:8%">
                              Payment By
                            </th>
                            <th class="InnerTableHead" style="width:43%">
                              Notes
                            </th>
                            <th class="InnerTableHead" style="width:7%">
                              Receipt
                            </th>
                          </tr>
                          <tbody *ngFor="let item of ReturnDetailsList;let index=index;">
                            <tr *ngIf="item && item.isAddedFromMasterHFS != true">
                              <!-- <td>
                                {{(index + 1)}}
                              </td> -->
                              <td>
                                {{item.hfServiceReturnVehicleEndTime | date:'dd/MM/yyyy HH:mm'}}
                              </td>
                              <td>
                                {{item.hfServiceReturnVehicleEndedKM}}
                              </td>
                              <td>
                                {{item.hfServiceFuelAdded}}
                              </td>
                              <td>
                                {{item.hfServiceFuelCompany}}
                              </td>
                              <td>
                                {{(item.hfServiceFuelAmount |
                                  currency:"CAD":"symbol-narrow")}}
                              </td>
                              <td>
                                {{item.hfServiceFuelPaymentBy}}
                              </td>
                              <td style="text-align: left">
                                <div class="break-words">
                                {{ item.hfServiceReturnDetailNotes}}
                              </div>
                              </td>
                              <td>
                                <button *ngIf="item.hfServiceFuelReceiptPath" style=" display: table;margin: 0 auto;" class="btn btn-primary center-block btn-xs"
                                      (click)="openImageDialog(item.hfServiceFuelReceiptPath)">
                                      <span><i class="fa fa-file"></i></span>
                              </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-sm-12"  style="margin-top: 15px;">
                        <notes
                          #notesReturnDetails
                          (addNoteClick)="executeHomeFieldServiceCreateUpdate('Section6',true,(f.HFServiceStatus.value == 'Completed' && f.HFServiceReturnDetailsEnteredOn.value))">
                        </notes>

                        <attachment #attachmentReturnDetails id="attachmentReturnDetails"
                          (RaiseError)="AttachmentError($event)">

                        </attachment>
                      </div>

                      <div class="row text-right col-sm-12">
                        <!-- <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [showSave]="true"
                          [showMainListing]="false" (SaveEvent)="executeHomeFieldServiceCreateUpdate('Section6')"
                          [showDelete]="false" #pageheader>
                        </PageHeader> -->
                        <!-- <button type="button" class="btn btn-primary"
                          *ngIf="isAdditionalAddReturn"
                          (click)="executeHomeFieldServiceCreateUpdate('Section6')">
                          <i class="fa fa-hospital-o"></i> &nbsp; Return Detail</button> -->

                          <button type="button" class="btn btn-primary"
                          *ngIf="displaySaveButton('ReturnDetail')"
                          (click)="executeHomeFieldServiceCreateUpdate('Section6')">
                          <i class="fa fa-save"></i> &nbsp; Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading" style="padding: 6px 15px;"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection6==true,'panel-heading': isBluePanelSection6==false}"
                    role="tab">
                    <h4 class="panel-title row" style="cursor:pointer"  (click)="ExpandSection('Section6')">
                      <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section6"
                        aria-controls="HFS_Section6Ref">
                        <div class="box-header with-border">
                          <h3 class="box-title container">Time Summary
                            <img src="../../assets/img/completed_icon.png"
                              style="height: 20px;margin-left: 5px;vertical-align: bottom;"
                              *ngIf="f.HFServiceReturnDetailsEnteredOn.value">

                          </h3>
                        </div>
                      </a>
                    </h4>
                  </div>

                  <div id="HFS_Section6" [ngbCollapse]="!isCollapsed.Section6" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section6">
                    <div class="box-body">
                      <div class="box-row col-sm-12">
                        <div class="col-sm-4">
                          <label class="control-label col-sm-8">Total Tech(s) Time (Billable):</label>
                          <div class="col-sm-4">
                            <input class="form-control" value="{{ (f.HFServiceStatus.value == 'Completed') ? totalTechTime : 'N/A'}}" disabled="disabled">
                          </div>
                        </div>
                        <div class="col-sm-5">
                          <label class="control-label col-sm-8">Total Tech(s) Time (Non-Billable):</label>
                          <div class="col-sm-3">
                            <input class="form-control" value="N/A" disabled="disabled">
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <label class="control-label col-sm-8">Total KMs:</label>
                          <div class="col-sm-4">
                            <input class="form-control" value="{{TotalCalculatedKMs}}" disabled="disabled">
                          </div>
                        </div>
                      </div>

                      <div class="box-row col-sm-12" style="border:1px solid lightgray;padding: 10px">
                        <div class="row col-sm-12">
                          <!-- <p class="InnerPurposeHFSHeader">1st Visit:</p> -->
                          <label class="control-label col-sm-12" style="font-weight: bold;font-size:18px;text-align: left;">Visit Time Summary Details:</label>
                        </div>

                      <div class="col-sm-3">
                          <label class="control-label col-sm-12" style="visibility: hidden">Station:</label>
                          <label class="control-label col-sm-12">Total Prep Time:</label>

                          <label class="control-label col-sm-12">Total Time at Pt. Home:</label>

                          <label class="control-label col-sm-12">Total Travelling Time:</label>

                          <label class="control-label col-sm-12">Total Testing Time:</label>
                        </div>

                      <div class="col-sm-1">
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;visibility: hidden">{{MRMHomeStationName}} </label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{prepTimeSummary}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{travellingTimeSpentAtPtHome}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{totalTravellingTime}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{totalTechTestingTime}}</label>
                      </div>

                      <div class="col-sm-1" *ngFor="let panelChild of panelChildrens ;let index=index" [hidden]="f.HFServiceStatus.value != 'Completed'" style="min-width: 12%;">
                          <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;"><b>{{panelChild.MRMHomeStationName}}</b></label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{panelChild.prepTimeSummary}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{panelChild.travellingTimeSpentAtPtHome}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{panelChild.totalTravellingTime}}</label>
                        <label class="col-sm-12" style="text-align: center;font-weight: normal;padding-top: 6px !important;">{{panelChild.totalTechTestingTime}}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>
              <div class="box-body box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading" style="padding:6px 6px;"
                    [ngClass]="{'panel-heading-blue': isBluePanelSection7==true,'panel-heading': isBluePanelSection7==false}"
                    role="tab">
                    <h4 class="panel-title" style="cursor:pointer" (click)="ExpandSection('Section7')">
                      <a class="collapsed" data-parent="#accordion" [attr.aria-expanded]="isCollapsed.Section7"
                        aria-controls="HFS_Section7Ref">
                        <div class="box-header with-border" >
                          <h3 class="box-title container">Notes & Parts Summary
                            <span><i class="fa fa-plus-square faplusicon" *ngIf="(notesVisitPurpose && notesVisitPurpose.NoteList.length > 0)  ||
                              (notesVisitPurposePlus && notesVisitPurposePlus.NoteList.length > 0) ||
                                         (notesVisitPrep && notesVisitPrep.NoteList.length > 0) ||
                                         (notesVisitVehicles && notesVisitVehicles.NoteList.length > 0) ||
                                         (notesReturnDetails && notesReturnDetails.NoteList.length > 0)"></i></span>

                          </h3>
                        </div>
                      </a>
                    </h4>
                  </div>

                  <div id="HFS_Section7" [ngbCollapse]="!isCollapsed.Section7" class="panel-collapse collapse"
                    role="tabpanel" aria-labelledby="HFS_Section7">
                    <div class="box-body">
                      <div class="box-row col-sm-12">
                        <div class="text-center"><b>NOTE SUMMARY</b><b *ngIf="allNotesList.length == 0">- N/A</b></div>
                        <div *ngIf="allNotesList.length > 0">
                          <table width="100%" style="width:100%;table-layout: fixed;" align="center" class="">
                              <tr>
                                <th class="InnerTableHead" style="width:10%">Date</th>
                                <th class="InnerTableHead" style="width:7%">Time</th>
                                <th class="InnerTableHead" style="width:10%">Tech</th>
                                <th class="InnerTableHead" style="width:25%;">Section</th>
                                <th class="InnerTableHead" style="width:55%;">Note</th>
                              </tr>
                            <tbody>
                              <tr *ngFor="let item of allNotesList ;let index=index" style="text-align: center;">
                                <td>{{item.createdOn | date:'dd/MM/yyyy'}}</td>
                                <td>{{item.createdOn | date:'HH:mm'}}</td>
                                <td>{{item.createdbyname}} </td>
                                <td style="word-wrap: break-word;">{{item.moduleName}}
                                </td>
                                <td align="left" width="auto" >
                                  <div style="word-wrap: break-word;" [innerHTML]="item.description">
                                  </div>
                              </td>

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="box-row col-sm-12">
                        <div class="text-center"><b>PARTS SUMMARY</b><b *ngIf="PartTrans.length == 0">- N/A</b></div>
                        <div *ngIf="PartTrans.length > 0">
                          <table style="width:100%" align="center" class="table InnerTable table-responsive">
                              <tr>
                                <th class="InnerTableHead" style="width:10%">Date</th>
                                <th class="InnerTableHead" style="width:10%">Time</th>
                                <th class="InnerTableHead" style="width:10%">Tech</th>
                                <th class="InnerTableHead" style="width:15%">
                                  Part ID
                                </th>
                                <th class="InnerTableHead" style="width:40%">
                                  Part Description
                                </th>
                                <th class="InnerTableHead" style="width:7.5%">
                                  Part Qty.
                                </th>
                                <th class="InnerTableHead" style="width:7.5%">
                                  Part Cost
                                </th>
                              </tr>
                            <tbody>

                              <tr *ngFor="let item of PartTrans;let index=index;">
                                <td>
                                  {{item.createdOn | date:'dd/MM/yyyy'}} </td>

                                <td>
                                  {{item.createdOn | date:'HH:mm'}} </td>
                                <td>
                                  {{item.createdByName}}</td>
                                <td>
                                  {{item.PartCode}}
                                </td>
                                <td>
                                  {{item.PartDescription}}
                                </td>

                                <td>
                                  {{item.quantity}}
                                </td>
                                <td>
                                  {{(item.PartCost * item.quantity | currency:"CAD":"symbol-narrow") }}
                                </td>

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</form>

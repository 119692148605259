import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class DesignationApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}


    GetDesignationSerachDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/GetDesignationSerachDetails',
        SearchOptions,
        httpOptions
      );
    }

    CreateDesignationDetails(designationDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/CreateDesignationDetails',
        designationDetails,
        httpOptions
      );
    }


    UpdateDesignationDetails(designationDetails) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/UpdateDesignationDetails',
        designationDetails,
        httpOptions
      );
    }

    DeleteDesignationDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/DeleteDesignationDetails',
        value,
        httpOptions
      );
    }

    GetDesignationByID(designation) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/GetDesignationByID',
        designation,
        httpOptions
      );
    }

    GetDivisionsByDesignationId(designation) {
      return this.http.post<any>(
        this.apiUrl + 'DesignationService/GetDivisionsByDesignationId',
        designation,
        httpOptions
      );
    }


    GetDesignationNotes(value) {
      return this.http.post<any>(
        this.apiUrl +
          'DesignationService/GetDesignationNotes',
        value,
        httpOptions
      );
    }

    GetDesignationsAttachments(value) {
      return this.http.post<any>(
        this.apiUrl +
          'DesignationService/GetDesignationsAttachments',
        value,
        httpOptions
      );
    }

  }

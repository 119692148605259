import { Component, ViewChild } from '@angular/core';
import { InvApiService } from '../../API/invapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { filter, pairwise } from 'rxjs/operators';
import { PreviousRouteService } from 'src/app/Core/services/previous-route.service';
import { AuthService } from 'src/app/API/auth.service';
import { Observable } from 'rxjs';
import { CommonApiService } from 'src/app/API/commonapi.service.';
@Component({
  selector: 'InventorySearch',
  templateUrl: './InventorySearch.component.html'
})
export class InventorySearchComponent {
  UserList: any;
  DivisionList = [];
  VendorList = [];
  page;
  CategoryList = [];
  constructor(
    private invapi: InvApiService,
    private router: Router,
    private route: ActivatedRoute,
    private dataGridService: TableHeadsService,
    private previousRouteService: PreviousRouteService,
    private auth: AuthService,
    private commonapi: CommonApiService,
  ) {
    this.page = new InventorySearchPage();
    this.page.pageNumber = 0;
    this.page.size = 25;
  }


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean  {
    this.invapi.setSearchValue(this.page);
    return true;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  StatusList = [
    { value: '', text: 'Select' },
    { value: 'Inservice', text: 'Inservice' },
    { value: 'Active', text: 'Active' },
    { value: 'Inactive', text: 'Inactive' }
  ];
  TypeList = [
    { value: '', text: 'Select' },
    { value: 'PARTS', text: 'PARTS' },
    { value: 'EQUIPMENT', text: 'EQUIPMENT' }
  ];
  blnDirtySearch = false;
  tableHeaders;
  rows;
  AddUrl = 'Inventory/InventoryDetail';

  ShowSearch = false;
  title = 'Inventory';
  searchText = 'Search Records';
  defaultSort;
  previousUrl: string;

  // tslint:disable-next-line:member-ordering
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {
      let searchedPage = this.invapi.getSearchValue();

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'equipmentcode', '4');
      this.dataGridService.addHeader('Product/Part Id', 'equipmentcode', '11');
      this.dataGridService.addHeader('Product/Part Name', 'equipmentname', '30');
      this.dataGridService.addHeader('Category', 'category', '12');
      this.dataGridService.addHeader('Model', 'model', '14');
      this.dataGridService.addHeader('Vendor', 'vendor', '20');
      this.dataGridService.addHeader('Status', 'status', '8');
      this.dataGridService.addHeader('Item Cost', 'itemcost', '12');
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Product/Part Id', false);



      // this.FillDivision();
      this.FillVendor();
      this.CheckDirtySearch();
      this.fillCategories();


      // const test = this.route.queryParams.subscribe(params => {
      //   // Defaults to 0 if no query param provided.
      //   if (params && params['previousSerach']) {
      //     if (searchedPage) {
      //       this.page = searchedPage;
      //       this.executeInventorySearch();
      //     }
      //   }
      // });

      let previousUrl = this.previousRouteService.getPreviousUrl();
      if (searchedPage && previousUrl && previousUrl.includes(this.AddUrl)
      
      ) {
        this.page = searchedPage;
        this.FillDivision();
      }
      else {
        if (division.length === 1){
          this.FillDivision(division[0]);
        }
        else{
          this.FillDivision();
        }
      }
      AppComponent.HideLoader();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  FillDivision(defaultValue = '') {
    // this.invapi.FillDivision().subscribe(list => {
      this.commonapi.FillDivision(true).subscribe(list => {
      this.DivisionList = list;

      if (defaultValue) {
        this.page.Division = defaultValue;
        this.executeInventorySearch();
      }
      else{
        this.executeInventorySearch();
      }
    });
  }

  FillVendor() {
    this.invapi.FillVendorList().subscribe(list => {
      this.VendorList = list;
    });
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.page.sortDirection = this.dataGridService.getSortDirection();
    this.page.sortExpression = this.dataGridService.getSortExpression();
    this.page.currentPageNumber = 1;
    this.page.pageNumber = 0;
    this.executeInventorySearch();
  }

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeInventorySearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const InventorySearch = this.prepareSearch();
    this.invapi.GetInventoryDetails(InventorySearch).subscribe(
      pagedData => {
        // console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;        
        this.rows = pagedData.inventoryDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }


  prepareSearch() {
    const InventorySearch = <any>{};

    InventorySearch.itemCode = this.page.ProductId;
    InventorySearch.priorItemCode = this.page.PriProductId;
    InventorySearch.name = this.page.ProductName;
    InventorySearch.desc = this.page.ProductDesc;

    if (this.page.Category !== undefined && this.page.Category !== '' && this.page.Category !== '0') {
      InventorySearch.Category = this.page.Category;
    }

    if (this.page.Vendor !== undefined && this.page.Vendor !== '' && this.page.Vendor !== '0') {
      InventorySearch.vendorId = this.page.Vendor;
    }

    if (this.page.Division !== undefined && this.page.Division !== '' && this.page.Division !== '0' && this.page.Division != 'All Divisions') {
      InventorySearch.divisonid = this.page.Division;
    }
    if (this.page.SelectedType !== undefined && this.page.SelectedType !== '') {
      InventorySearch.type = this.page.SelectedType;
    }
    if (this.page.Status !== undefined && this.page.Status !== '') {
      InventorySearch.status = this.page.Status;
    }
    InventorySearch.currentPageNumber = this.page.currentPageNumber;
    InventorySearch.sortExpression = this.page.sortExpression;
    InventorySearch.sortDirection = this.page.sortDirection;
    InventorySearch.pageSize = this.page.pageSize;

    // this.invapi.setSearchValue(this.page);

    return InventorySearch;
  }

  setPage() {
    this.page.currentPageNumber = this.page.pageNumber;
    this.executeInventorySearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  executeInventorySearchClear() {
    this.page.Status = '';
    this.page.ProductId = '';
    this.page.PriProductId = '';
    this.page.ProductName = '';
    this.page.ProductDesc = '';
    this.page.Vendor = '';
    this.page.Division = '';
    this.page.SelectedType = '';
    this.page.Category = 'Select';
    this.executeInventorySearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;
    if (
      this.page.ProductId ||
      this.page.PriProductId ||
      this.page.ProductName ||
      this.page.ProductDesc ||
      this.page.Vendor ||
      (this.page.Division && this.page.Division != 'All Divisions') ||
      this.page.SelectedType ||
      this.page.Status ||
      this.page.Category != 'Select'
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {

    this.executeInventorySearch();
  }
}

export class InventorySearchPage {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;

  pageSize = 25;

  ProductId: string;
  Category = 'Select';
  PriProductId: string;
  ProductName: string;
  ProductDesc: string;
  Vendor = '';
  Division = '';
  SelectedType = '';
  Status = '';
  currentPageNumber = 1;
  sortExpression = '';
  sortDirection = '';
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class DMApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetEquipmentDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetEquipmentDD',
      value,
      httpOptions
    );
  }

  GetDialyisStationDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialyisStationDD',
      value,
      httpOptions
    );
  }

  GetDialyisStationDDAll(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialyisStationDDAll',
      value,
      httpOptions
    );
  }

  GetDialysisUnitDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialysisUnitDD',
      '',
      httpOptions
    );
  }

  GetDialysisUnitWP(value=false) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialysisUnitWP',
      value,
      httpOptions
    );
  }

  GetUsersDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetUsersDD',
      '',
      httpOptions
    );
  }
  GetPartsDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetPartsDD',
      '',
      httpOptions
    );
  }

  GetStatusDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetStatusDD',
      '',
      httpOptions
    );
  }

  GetPatientsDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetPatientsDD',
      '',
      httpOptions
    );
  }

  GetDMRepairDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetDMRepairDetails',
      SearchOptions,
      httpOptions
    );
  }

  GetDMPMRepairDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetDMPMRepairDetails',
      SearchOptions,
      httpOptions
    );
  }

  CreateDMRepairDetails(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/CreateDMRepairDetails',
      obj,
      httpOptions
    );
  }

  UpdateDMRepairDetails(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/UpdateDMRepairDetails',
      obj,
      httpOptions
    );
  }

  GetwarrentyStatus(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetwarrentyStatus',
      obj,
      httpOptions
    );
  }

  GetFilterRecordByequipmentCode(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetFilterRecordByequipmentCode',
      obj,
      httpOptions
    );
  }

  GetHoursOutOfPreviousRepair(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetHoursOutOfPreviousRepair',
      obj,
      httpOptions
    );
  }


  GetRepairStatusByEquipmentId(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetRepairStatusByEquipmentId',
      obj,
      httpOptions
    );
  }

  GetWpOtherRepairStatusByEquipmentId(obj) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetWpOtherRepairStatusByEquipmentId',
      obj,
      httpOptions
    );
  }

  GetDMRepairDetailsByID(id) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetDMRepairDetailsByID',
      id,
      httpOptions
    );
  }

  search_word(term) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetErrorCodeDetailssearchByCategory',
      term,
      httpOptions
    );
  }

  GetPartsdetailsDetailssearchByCategory(term) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetPartsdetailsDetailssearchByCategory',
      term,
      httpOptions
    );
  }

  DeleteDMRepairDetailsByID(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/DeleteDMRepairDetailsByID',
      DMRepairId,
      httpOptions
    );
  }
  GeterrorCodeDetailsbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GeterrorCodeDetailsbydmRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }
  GetRepairPartsDetailsbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetRepairPartsDetailsbydmRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }

  GetnotesbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetnotesbydmRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }
  GetattachmentDetailsbydmRepairDetailid(DMRepairId) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetattachmentDetailsbydmRepairDetailid',
      DMRepairId,
      httpOptions
    );
  }
  GetHydrolicDocDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetFillHydrolicDocDD',
      value,
      httpOptions
    );
  }

  GetMachaineHistory(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetMachaineHistory',
      value,
      httpOptions
    );
  }

  GetAlarmHistory(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetAlarmHistory',
      value,
      httpOptions
    );
  }

  GetPartsHistory(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetPartsHistory',
      value,
      httpOptions
    );
  }

  GetEquipmentDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetEquipmentDetailsByID',
      value,
      httpOptions
    );
  }

  FillModelReference(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/FillModelReference',
      value,
      httpOptions
    );
  }

  GetdmRepairCalibrationsTransselectByDmrepairid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmRepairCalibrationsTransselectByDmrepairid',
      value,
      httpOptions
    );
  }
  GetdmRepairElectricalTranslstelectByDmrepairid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmRepairElectricalTranslstelectByDmrepairid',
      value,
      httpOptions
    );
  }

  GetErrorCodeWithSteps(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetErrorCodeWithSteps',
      value,
      httpOptions
    );
  }

  GetErrorCodeWithStepsByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetErrorCodeWithStepsByID',
      value,
      httpOptions
    );
  }

  GetdmRepairTroubleShootinglstelectByDmrepairidAndErrorId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmRepairTroubleShootinglstelectByDmrepairidAndErrorId',
      value,
      httpOptions
    );
  }
  GetIncidentDMRepairDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetIncidentDMRepairDetails',
      value,
      httpOptions
    );
  }
  GetIncidentDMRepairDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetIncidentDMRepairDetailsByID',
      value,
      httpOptions
    );
  }

  GetIncidentattachmentDetailsbydmIncidentRepairDetailid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetIncidentattachmentDetailsbydmIncidentRepairDetailid',
      value,
      httpOptions
    );
  }

  GetdmIncidentTransselectBydmIncidentDetaiId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmIncidentTransselectBydmIncidentDetaiId',
      value,
      httpOptions
    );
  }

  GetdmIncidentTroubleShootingByIncidentId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmIncidentTroubleShootingByIncidentId',
      value,
      httpOptions
    );
  }

  GetdmIncidentTroubleShootingBymIncidentIdAndErrorId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmIncidentTroubleShootingBymIncidentIdAndErrorId',
      value,
      httpOptions
    );
  }

  GetIncidentnotesbydmIncidentRepairDetailid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetIncidentnotesbydmIncidentRepairDetailid',
      value,
      httpOptions
    );
  }

  CreateDMIncidentDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/CreateDMIncidentDetails',
      value,
      httpOptions
    );
  }

  UpdateDMIncidentDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/UpdateDMIncidentDetails',
      value,
      httpOptions
    );
  }

  DeleteDMIncidentDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/DeleteDMIncidentDetailsByID',
      value,
      httpOptions
    );
  }

  // Fillter API

  GetFilterDMRepairDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetFilterDMRepairDetails',
      value,
      httpOptions
    );
  }

  GetFilterDMRepairDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetFilterDMRepairDetailsByID',
      value,
      httpOptions
    );
  }

  GetFilterNotesbydmFilterDetailid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetFilterNotesbydmFilterDetailid',
      value,
      httpOptions
    );
  }

  GetFilterAttachmentDetailsbydmFilterDetailid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetFilterAttachmentDetailsbydmFilterDetailid',
      value,
      httpOptions
    );
  }

  DeleteDMFilterDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/DeleteDMFilterDetailsByID',
      value,
      httpOptions
    );
  }

  UpdateDMFilterDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/UpdateDMFilterDetails',
      value,
      httpOptions
    );
  }

  CreateDMFilterDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/CreateDMFilterDetails',
      value,
      httpOptions
    );
  }

  GetDMHoursUpdateRecords(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMHoursUpdateRecords',
      value,
      httpOptions
    );
  }

  GetDMHoursDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMHoursDetailsByID',
      value,
      httpOptions
    );
  }


  GetDMHoursAttachmentDetailsbydmHoursUpdateId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMHoursAttachmentDetailsbydmHoursUpdateId',
      value,
      httpOptions
    );
  }

  DeleteDMHoursDetailsBydmHoursUpdateId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/DeleteDMHoursDetailsBydmHoursUpdateId',
      value,
      httpOptions
    );
  }


  CreateDMHoursDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/CreateDMHoursDetails',
      value,
      httpOptions
    );
  }

  UpdateDMHoursDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/UpdateDMHoursDetails',
      value,
      httpOptions
    );
  }

  GetDMMachinesHistoryRecords(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMMachinesHistoryRecords',
      value,
      httpOptions
    );
  }

  GetDMUpgradesModificationsRecords(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMUpgradesModificationsRecords',
      value,
      httpOptions
    );
  }

  GetDMUpgradesModificationsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetDMUpgradesModificationsByID',
      value,
      httpOptions
    );
  }

  GetdmUpgradeModficationInventoryMappingByModificationId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetdmUpgradeModficationInventoryMappingByModificationId',
      value,
      httpOptions
    );
  }
  CreatedmUpgradeModficationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/CreatedmUpgradeModficationDetails',
      value,
      httpOptions
    );
  }

  UpdatedmUpgradeModficationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/UpdatedmUpgradeModficationDetails',
      value,
      httpOptions
    );
  }

  DeletedmUpgradeModficationByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/DeletedmUpgradeModficationByID',
      value,
      httpOptions
    );
  }

  CompletedmUpgradeModficationByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/CompletedmUpgradeModficationByID',
      value,
      httpOptions
    );
  }

  GetNotesBydmUpgradeModficationByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetNotesBydmUpgradeModficationByID',
      value,
      httpOptions
    );
  }

  GetattachementsByBydmUpgradeModficationByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/GetattachementsByBydmUpgradeModficationByID',
      value,
      httpOptions
    );
  }

  DeletedmUpgradeModficationInventoryMappingByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMRepairDetailService/DeletedmUpgradeModficationInventoryMappingByID',
      value,
      httpOptions
    );
  }

  GetmachaineHistoryMaxHours(value) {
  return this.http.post<any>(
    this.apiUrl +
      'DMRepairDetailService/GetmachaineHistoryMaxHours',
    value,
    httpOptions
  );
}

GetDmFilterChangPartsDetailsbyFilterChangeDetailid(value) {
  return this.http.post<any>(
    this.apiUrl +
      'DMRepairDetailService/GetDmFilterChangPartsDetailsbyFilterChangeDetailid',
    value,
    httpOptions
  );
}

//Preventive Maintenance


GetPMStatusByEquipmentId(obj) {
  return this.http.post<any>(
    this.apiUrl + 'DMPMService/GetPMStatusByEquipmentId',
    obj,
    httpOptions
  );
}

  UpdateDMPMDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/UpdateDMPMDetails',
      value,
      httpOptions
    );
  }

  GetDMPMDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/GetDMPMDetailsByID',
      value,
      httpOptions
    );
  }

  CreateDMPMDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/CreateDMPMDetails',
      value,
      httpOptions
    );
  }

  DMPMProcessTransactionDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/DMPMProcessTransactionDetails',
      value,
      httpOptions
    );
  }

  TransactionListByPMID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/TransactionListByPMID',
      value,
      httpOptions
    );
  }

  CheckUserPin(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/DMPMCheckUserPin',
      value,
      httpOptions
    );
  }

  GetDMPMSearchDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetDMPMSearchDetails',
      SearchOptions,
      httpOptions
    );
  }

  GetPMNotesDetailsbydmPMId(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetPMNotesDetailsbydmPMId',
      value,
      httpOptions
    );
  }

  GetDMPMAutoPartsByType(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetDMPMAutoPartsByType',
      value,
      httpOptions
    );
  }

  GetDMPMQuestionsByType(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetDMPMQuestionsByType',
      value,
      httpOptions
    );
  }



  GetPMPartsDetailsbydmPMId(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetPMPartsDetailsbydmPMId',
      value,
      httpOptions
    );
  }

  GetScheduledPMHours(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/GetScheduledPMHours',
      value,
      httpOptions
    );
  }

  PrintPreventivePDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMPMService/PrintPreventiveMaintenancePDF',
      value,
      httpOptions
    );
  }

  DeletePMDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/DeletePMDetailsByID',
      value,
      httpOptions
    );
  }

  GetPMLastDateByEquipmentId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'DMPMService/GetPMLastDateByEquipmentId',
      value,
      httpOptions
    );
  }

  //End Preventive Maintenance

  GetBacteriaDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetBacteriaDetails',
      value,
      httpOptions
    );
  }

    CreateUpdateBacteria(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/CreateUpdateBacteria',
      value,
      httpOptions
    );
  }

  GRCreateUpdateBacteria(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GRCreateUpdateBacteria',
      value,
      httpOptions
    );
  }

  DeleteBacteriaByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/DeleteBacteriaByID',
      value,
      httpOptions
    );
  }

  GetMachineListByBacteriaID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetMachineListByBacteriaID',
      value,
      httpOptions
    );
  }

  GetWaterMachineListByBacteriaID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetWaterMachineListByBacteriaID',
      value,
      httpOptions
    );
  }

  GetBacteriaDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetBacteriaDetailsByID',
      value,
      httpOptions
    );
  }

  GetStationListByBacteriaID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetStationListByBacteriaID',
      value,
      httpOptions
    );
  }

  GetNotesbyBacteriaId(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetNotesbyBacteriaId',
      value,
      httpOptions
    );
  }

  GetAttachmentDetailsbyBacteriaid(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetAttachmentDetailsbyBacteriaid',
      value,
      httpOptions
    );
  }

  GetEquipmentByCategoryDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetEquipmentByCategoryDD',
      value,
      httpOptions
    );
  }
  GetPyrogenDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetPyrogenDetails',
      value,
      httpOptions
    );
  }

    CreateUpdatePyrogen(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/CreateUpdatePyrogen',
      value,
      httpOptions
    );
  }

  GRCreateUpdatePyrogen(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GRCreateUpdatePyrogen',
      value,
      httpOptions
    );
  }

  DeletePyrogenByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/DeletePyrogenByID',
      value,
      httpOptions
    );
  }

  GetMachineListByPyrogenID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetMachineListByPyrogenID',
      value,
      httpOptions
    );
  }

  GetWaterMachineListByPyrogenID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetWaterMachineListByPyrogenID',
      value,
      httpOptions
    );
  }

  GetCDMachineListByPyrogenID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetCDMachineListByPyrogenID',
      value,
      httpOptions
    );
  }

  GetPyrogenDetailsByID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetPyrogenDetailsByID',
      value,
      httpOptions
    );
  }

  GetStationListByPyrogenID(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetStationListByPyrogenID',
      value,
      httpOptions
    );
  }

  GetNotesbyPyrogenId(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetNotesbyPyrogenId',
      value,
      httpOptions
    );
  }

  GetAttachmentDetailsbyPyrogenid(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetAttachmentDetailsbyPyrogenid',
      value,
      httpOptions
    );
  }

  PrintIncidentPDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintIncidentPDF',
      value,
      httpOptions
    );
  }

  PrintRepairPDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintRepairPDF',
      value,
      httpOptions
    );
  }

  PrintPyrogenPDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintPyrogenPDF',
      value,
      httpOptions
    );
  }

  PrintBacteriaPDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintBacteriaPDF',
      value,
      httpOptions
    );
  }

  PrintFilterPDF(value) {


    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintFilterPDF',
      value,
      httpOptions
    );
  }

  PrintHoursUpdatePDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintHoursUpdatePDF',
      value,
      httpOptions
    );
  }

  PrintUpgradeModificationPDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintUpgradeModificationPDF',
      value,
      httpOptions
    );
  }

  PrintTransferMachinePDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMTransferService/PrintTransferMachinePDF',
      value,
      httpOptions
    );
  }
GetDMRepairIdFromHomeserviceId(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetDMRepairIdFromHomeserviceId',
      value,
      httpOptions
    );
  }

  GetAttachmentDetailsbyID(value) {

    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetAttachmentDetailsbyID',
      value,
      httpOptions
    );
  }

  GetDMReportDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/GetDMReportDetails',
      SearchOptions,
      httpOptions
    );
  }

  PrintReportDetails(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairDetailService/PrintDMReportPDF',
      value,
      httpOptions
    );
  }
}


<div class="sidenav right" *ngIf="ShowEventDetailR == true" [innerHtml]='sanitizer.bypassSecurityTrustHtml(RSideContet)'></div>
<div class="sidenav left" *ngIf="ShowEventDetailL == true" [innerHtml]='sanitizer.bypassSecurityTrustHtml(LSideContet)'></div>
 <section class="content-header">
    <h1>
            {{title}}
            <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->

    </h1>

</section>
<section class="content">

<section >
  <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->



      <div class="row">
          <!-- /.box -->
          <div class="box  box-primary">
            <div  class="no-print">
              <div class="box-header pageheader row">

                      <div class="col-sm-12 text-right">



                           <!-- <ng-multiselect-dropdown style="float:right;padding-left:10px;min-width:250px;"
                           [placeholder]="'Select Division(s)'"
                           [data]="DivisionList"
                           [(ngModel)]="selectedDivisions"
                           [settings]="dropdownSettings"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onItemSelectAll($event)"
                           (onDeSelect) ="onItemSelect($event)"
                         ></ng-multiselect-dropdown> -->
                      </div>
                  </div>
      </div>
      <div class="clearfix"></div>

      <div class="box-body station-collapse" [ngClass]="{'box-body':true,'station-collapse': !showStationList, 'station-expand': showStationList }" id="dvboxbody" >

        <div class="row " *ngIf="stationList?.length > 0" id="stationList" >
                <div  class="stationConatainer col-sm-10">

                    <div class="row" >
                            <ul class="stationList col-sm-11">
                                    <li  dropData='{{station.dialysisUnitId}}'
                                    mwlDraggable  class="station" *ngFor="let station of stationList">
                                       <div id={{station.dialysisUnitId}} class='fc-event'>{{ station.dialysisStationName }}</div>
                                    </li>
                                </ul>
                                <div class="col-sm-1 stationbutton">
                                    <div class="station-collapse-btn" (click)="toggleStationList()" *ngIf="showStationList"><i class="fa fa-angle-double-up ng-star-inserted"></i></div>
                                    <div class="station-expand-btn" (click)="toggleStationList()" *ngIf="!showStationList"><i class="fa fa-angle-double-down ng-star-inserted"></i></div>
                                </div>
                    </div>
                </div>

                <div class="col-sm-2">
                    <mat-button-toggle-group  value=""
                     name="LockUnlock" form #tasktypegroup="matButtonToggleGroup" (change)="onChangeLock(tasktypegroup.value)">
                     <mat-button-toggle value=""  class="btndanger">{{onLock}}</mat-button-toggle>
                     <mat-button-toggle value="true" class="btnsuccess"  >{{offUnLock}}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

        </div>
      </div>
              <div class="clearfix"></div>

              <div class="box-body" id="dvboxbody">

                <div class="row">
                    <ul class="dayList col-sm-12">
                            <li   class="dayRow" style="width: 9%;" >
                                    #
                                 </li>
                            <li   class="dayRow" >
                                    Monday
                                 </li>
                                 <li   class="dayRow" >
                                        Tuesday
                                     </li>
                                     <li   class="dayRow" >
                                            Wednesday
                                         </li>
                                         <li   class="dayRow" >
                                                Thursday
                                             </li>
                                             <li   class="dayRow" >
                                                    Friday
                                                 </li>
                                             <li   class="dayRow" >
                                                    Saturday
                                                 </li>
                                                 <ng-container *ngFor="let day of dayList" (mouseout)="leave()">
                                                        <li  class="dayCol" *ngIf="day.value ==1">

                                                                <div id={{day.value}} >1st Week</div>
                                                             </li>
                                                        <li  class="day" >

                                                                <div mwlDroppable  (drop)="dropped($event,day.text)"   id='{{day.value}}' class='droppable' style="overflow:auto;">
                                                                    <ul>
                                                                            <li dropData='{{event.dialysisUnitId}}' (mouseover)="enter(event)" (mouseout)="leave()" (click)="redirectToStationAssignment(event.dialysisUnitId)"
                                                                            mwlDraggable  id='{{event.dialysisUnitId}}'  class="station" *ngFor="let event of GetEventDataDatewise(day.text)"
                                                                            [ngStyle]="{'background-color':event.backgroundColor,'border-color':event.borderColor,'color':event.textColor}">
                                                                                    <div class='fc-event' style="text-align: center;width: 105%;cursor: pointer;">{{ event.dialysisStationName }}</div>
                                                                            </li>
                                                                    </ul>
                                                                </div>
                                                             </li>
                                                             <li  class="dayCol" *ngIf="day.value%6==0 && day.value !=24">

                                                                    <div  id={{day.value}} >{{getWeekText(day.value/6)}}</div>
                                                                 </li>
                                                 </ng-container>

                    </ul>

                    </div>
              </div>
          </div>
      </div>

  </section>


</section>

<form [formGroup]="ErrorCodeForm" (ngSubmit)="executeErrorCodeCreateUpdate('')" novalidate>

    <section class="content-header">
        <h1>
            {{title}}
        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader  [showNote]="false" [showAddNew]="false" [showSearch]="false"
                    [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeErrorCodeCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Error Code:
                                    </label>
                                    <div class="col-sm-10"><input type="text" class="form-control mdInput"
                                            formControlName="errorCode" name="errorCode" maxlength="50">
                                        <span
                                            *ngIf="f.errorCode.errors?.required && (f.errorCode.dirty || f.errorCode.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label">Description:</label>
                                    <div class="col-sm-10">
                                        <textarea name='errorCodeDescription' rows='3' class="form-textarea"
                                            formControlName='errorCodeDescription'></textarea>
                                        <span
                                            *ngIf="f.errorCodeDescription.errors?.required && (f.errorCodeDescription.dirty || f.errorCodeDescription.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label"><span
                                            class="text-red inputrequired">*</span>Product Category:</label>

                                    <div class="col-sm-10">
                                        <select class="form-control mdInput" formControlName="categoryId"
                                            name="categoryId" (change)="fillManufacturers()">
                                            <option value="{{item.value}}" *ngFor="let item of CategoryList">
                                                {{item.text}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="f.categoryId.errors?.required && (f.categoryId.dirty || f.categoryId.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label"><span
                                            class="text-red inputrequired">*</span>Manufacturer:</label>

                                    <div class="col-sm-10">
                                        <select class="form-control mdInput" formControlName="manufacturerId"
                                            name="manufacturerId" (change)="fillModelList()">
                                            <option value="">Select</option>
                                            <option value="{{item.value}}" *ngFor="let item of ManufacturerList">
                                                {{item.text}}
                                            </option>
                                        </select>

                                        <span
                                            *ngIf="f.manufacturerId.errors?.required && (f.manufacturerId.dirty || f.manufacturerId.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label"><span
                                            class="text-red inputrequired">*</span>Model:</label>

                                    <div class="col-sm-10">
                                        <select class="form-control mdInput" formControlName="modelId" name="modelId">
                                            <option value="">Select</option>
                                            <option value="{{item.value}}" *ngFor="let item of ModelList">
                                                {{item.text}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="f.modelId.errors?.required && (f.modelId.dirty || f.modelId.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                                <div class="box ibox box-info">
                                    <div class="box-header with-border">
                                        <h3 class="box-title">Troubleshooting Steps</h3>
                                    </div>

                                    <div class="box-body">

                                        <div class="row">
                                            <div class="col-sm-12 box-row" formArrayName="tSteps"
                                                *ngFor="let step of ErrorCodeForm.get('tSteps')['controls'];let i = index;let last = last">
                                                <div [formGroupName]="i">
                                                    <div class="col-sm-2" *ngIf="step && step.controls.stepno">
                                                        Step {{ step.controls.stepno.value }}
                                                    </div>
                                                    <div class="col-sm-6" *ngIf="step && step.controls.stepsDetail">
                                                        <!-- {{ step.controls.stepsDetail.value }} -->
                                                        <textarea name='stepsDetail' rows='3' class="form-textarea"
                                                            formControlName='stepsDetail'></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>

                                    <notes [isValidForm]="this.ErrorCodeForm.invalid"
                                        (addNoteClick)="executeErrorCodeCreateUpdate('Notes')" #notes></notes>
                                    <attachment (RaiseError)="AttachmentError($event)" #attachment>
                                    </attachment>
                                </div>
                            </div>
                            <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false"
                                [mainlisturl]="ListURL" [showSave]="blnshowSave"
                                (SaveEvent)="executeErrorCodeCreateUpdate('')" [showDelete]="blnshowDelete"
                                (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</form>
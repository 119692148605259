<!-- <h3 class="text-center">Testing</h3> -->
<div class="clearfix"></div>
<div class="box-row col-sm-12  divider">
  <div class="col-sm-8"><strong>Comprehensive Feed Water Testing: Frequency – Well (6 Mths), Municipal (Yearly) </strong></div>
  <div class="col-sm-1">
      <i class="fa fa-plus-circle fa-2x" style="cursor: pointer"  (click)="quickAddWaterAnalysisDialog()"></i>
      <i class="fa fa-plus-circle fa-2x" style="cursor: pointer"  (click)="quickAddBacteriaDialog()"></i>
      <i class="fa fa-plus-circle fa-2x" style="cursor: pointer"  (click)="quickAddPyrogenDialog()"></i>
  </div> 
</div>
<div class="clearfix"></div>
<div class="col-sm-12 box-row ">
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Feed:</label>
    <div class="col-sm-6">
      <select class="form-control mdInput">
        <option value="{{item.value}}" *ngFor="let item of lstFeed">
          {{item.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Date Last Tested:</label>
    <div class="col-sm-6">
      <label class="readOnlyText">
        {{todaydate | date:'dd/MM/yyyy HH:mm'}}
      </label>
    </div>
  </div>

</div>
<div class="clearfix"></div>
<div class="col-sm-12 box-row ">
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">RO Product:</label>
    <div class="col-sm-6">
      <select class="form-control mdInput">
        <option value="{{item.value}}" *ngFor="let item of lstROProduct">
          {{item.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Date Last Tested:</label>
    <div class="col-sm-6">
      <label class="readOnlyText">
        {{todaydate | date:'dd/MM/yyyy HH:mm'}}
      </label>
    </div>
  </div>

</div>
<div class="clearfix"></div>

<div class="box-row col-sm-12  divider">
  <div class="col-sm-8"><strong>RO Monthly Testing </strong></div>
</div>
<div class="clearfix"></div>
<div class="col-sm-12 box-row ">
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Bacteria Test:</label>
    <div class="col-sm-6">
      <select class="form-control mdInput" [(ngModel)]="hpcTest">
        <option value="{{item.value}}" *ngFor="let item of lstTest">
          {{item.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Date Last Tested:</label>
    <div class="col-sm-6">
      <label class="readOnlyText">
        {{todaydate | date:'dd/MM/yyyy HH:mm'}}
      </label>
    </div>
  </div>

</div>
<div class="col-sm-12 box-row " *ngIf="hpcTest == 'Yes'">
<div class="col-sm-6">
    <label class="col-sm-6 control-label">HPC Results:</label>
    <div class="col-sm-6">
          <select class="form-control smInput">
                  <option value="{{item.value}}"
                  [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                   *ngFor="let item of lstHPCResult">
                    {{item.text}}
                  </option>
                </select>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div class="col-sm-12 box-row ">
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">LAL Test:</label>
    <div class="col-sm-6">
      <select class="form-control mdInput" [(ngModel)]="lalTest">
        <option value="{{item.value}}" *ngFor="let item of lstTest">
          {{item.text}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-sm-6">
    <label class="col-sm-6 control-label">Date Last Tested:</label>
    <div class="col-sm-6">
      <label class="readOnlyText">
        {{todaydate | date:'dd/MM/yyyy HH:mm'}}
      </label>
    </div>
  </div>

</div>

<div class="col-sm-12 box-row " *ngIf="lalTest == 'Yes'">
    <div class="col-sm-6">
        <label class="col-sm-6 control-label">LAL Sensitivity:</label>
        <div class="col-sm-6">
              <select class="form-control smInput">
                      <option value="{{item.value}}"
                      [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                       *ngFor="let item of lstHPCResult">
                        {{item.text}}
                      </option>
                    </select>
        </div>
      </div>
      <div class="col-sm-6">
          <label class="col-sm-6 control-label">LAL Result:</label>
          <div class="col-sm-6">
                <select class="form-control smInput">
                        <option value="{{item.value}}"
                         *ngFor="let item of lstResult">
                          {{item.text}}
                        </option>
                      </select>
          </div>
        </div>
    </div>

    <div class="clearfix"></div>

    <div class="box-row col-sm-12  divider">
      <div class="col-sm-8"><strong>Dialysis Monthly Testing </strong></div>
    </div>
    <div class="clearfix"></div>
    <div class="col-sm-12 box-row ">
      <div class="col-sm-6">
        <label class="col-sm-6 control-label">HPC Test:</label>
        <div class="col-sm-6">
          <select class="form-control mdInput" [(ngModel)]="dhpcTest">
            <option value="{{item.value}}" *ngFor="let item of lstTest">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-6 control-label">Date Last Tested:</label>
        <div class="col-sm-6">
          <label class="readOnlyText">
            {{todaydate | date:'dd/MM/yyyy HH:mm'}}
          </label>
        </div>
      </div>

    </div>
    <div class="col-sm-12 box-row " *ngIf="dhpcTest == 'Yes'">
    <div class="col-sm-6">
        <label class="col-sm-6 control-label">HPC Results:</label>
        <div class="col-sm-6">
              <select class="form-control smInput">
                      <option value="{{item.value}}"
                      [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                       *ngFor="let item of lstHPCResult">
                        {{item.text}}
                      </option>
                    </select>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="col-sm-12 box-row ">
      <div class="col-sm-6">
        <label class="col-sm-6 control-label">LAL Test:</label>
        <div class="col-sm-6">
          <select class="form-control mdInput" [(ngModel)]="dlalTest">
            <option value="{{item.value}}" *ngFor="let item of lstTest">
              {{item.text}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-6 control-label">Date Last Tested:</label>
        <div class="col-sm-6">
          <label class="readOnlyText">
            {{todaydate | date:'dd/MM/yyyy HH:mm'}}
          </label>
        </div>
      </div>

    </div>

    <div class="col-sm-12 box-row " *ngIf="dlalTest == 'Yes'">
        <div class="col-sm-6">
            <label class="col-sm-6 control-label">LAL Sensitivity:</label>
            <div class="col-sm-6">
                  <select class="form-control smInput">
                          <option value="{{item.value}}"
                          [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                           *ngFor="let item of lstHPCResult">
                            {{item.text}}
                          </option>
                        </select>
            </div>
          </div>
          <div class="col-sm-6">
              <label class="col-sm-6 control-label">LAL Result:</label>
              <div class="col-sm-6">
                    <select class="form-control smInput">
                            <option value="{{item.value}}"
                             *ngFor="let item of lstResult">
                              {{item.text}}
                            </option>
                          </select>
              </div>
            </div>
        </div>

    <div class="clearfix"></div>

<!-- <div class="col-sm-12">
  <notes  #notes>
  </notes>


<attachment (RaiseError)="AttachmentError($event)" #attachment>

</attachment>
</div> -->

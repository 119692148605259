
    <div class="modal-header  col-sm-12" >
        <h4 class="modal-title col-sm-8">{{stationName}} - {{title}} History</h4>

        <div class="text-right  col-sm-4" style="padding:0">
          <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(true)">Close</button>
          <button type="button" class="btn btn-primary" style="display: none;">Save</button>
        </div>
    </div>
    <div class="clearfix"></div>
     <div class="modal-body">
        <table class="sturdy">
          <tr>
            <td style="font-weight: bold;text-align: center;width:10%;">No</td>
            <td style="font-weight: bold;width:30%;">HF Service Code</td>
            <td style="font-weight: bold;width:30%;">Date</td>
            <td style="font-weight: bold;width:30%;">User</td>
          </tr>
          <tr *ngFor="let item of historyList;let index=index;">
            <td>
              {{index + 1}}
            </td>
            <td>
              {{item.hfServiceCode}}
            </td>
            <td>
              {{item.performedDate | date:'dd/MM/yyyy HH:mm'}}
            </td>
            <td>
              {{item.createdByName}}
            </td>
          </tr>
          <tr *ngIf="historyList.length <= 0">
            <td colspan="4">History not found.</td>
          </tr>
        </table>
    </div>



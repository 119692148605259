import { Component, ViewChild } from '@angular/core';
import { RepairDetailSearchComponent } from '../../Core/Shared/Repairs/RepairDetailSearch.component';
// import { WPApiService } from '../../API/wpapi.service';
// import { DMApiService } from '../../API/dmapi.service';
// import { TableHeadsService } from '../../Core/tableheads.service';
// import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
// import { Router } from '@angular/router';
// import { PageHeaderComponent } from '../../Core/pageheader.component';
// import { AppComponent } from '../../app.component';
// import { Globals } from '../../globals';

@Component({
  selector: 'ORRepairDetailSearch',
  templateUrl: './ORRepairDetailSearch.component.html',

})
export class ORRepairDetailSearchComponent {

//   constructor( private dmapi: DMApiService, private wpapi: WPApiService,private router:Router, private dataGridService: TableHeadsService){
//     this.page.pageNumber = 0;
//     this.page.size = 25;
//   }

//   @ViewChild(PageHeaderComponent) pageheader: PageHeaderComponent;
//   loading=true;
//   DialysisUnits=[];
//     Equipments=[];
//   Users=[];
//   Parts=[];
//   Statuses=[];
//   Patients=[];
//   WPRepailDetail =[];
//   selectedDivision = "";
//   selectedEquipment = "";
//   selectedPart = "";
//   //selectedPatient = "";
//   selectedStatus = "";
//   selectedUser = "";
//   RepairId="";
//   blnDirtySearch = false;
//   FromDate;
//   ToDate;
//   VendorOrderRef="";
//   OutFromDate;
// OutToDate;
// tableHeaders;
// page = new Page();
// rows ;
// AddUrl = "/WaterProcess/Repairs/RepairDetail";
// workOrderRefNo="";
// detailsType  ="Water Process" 
//   currentPageNumber = 1;
//   sortExpression = "repairdetailsid";
//   sortDirection = "ASC";
//   pageSize = 25;
//   ShowSearch=false;
//   title = "Water Process  Repair / Event";
//   searchText="Search Records"
// defaultSort;

//   ngOnInit() {
//     //AppComponent.loading=true;
//     this.dataGridService.clearHeader();
//             this.dataGridService.addHeader("#", "repairid","4");
//             this.dataGridService.addHeader("Repair ID","repairDetailId", "10");
//             this.dataGridService.addHeader("Division", "divisionname", "10");
//             this.dataGridService.addHeader("Tech", "createdbyName", "10");
//             this.dataGridService.addHeader("Date IN", "timeStarted", "15");                                 
//             this.dataGridService.addHeader("Equipment", "equipmentName", "25");                  
//             this.dataGridService.addHeader("Status", "status", "20%");
//             this.tableHeaders = this.dataGridService.setTableHeaders();
//             this.defaultSort = this.dataGridService.setDefaultSort("Date IN",true);
//            //console.log(this.tableHeaders);
//    // this.setPage({ offset: 0 });
//    // this.executerepairsearch();
//     this.fillDialysisUnit();
//     this.fillEquipment();    
//     this.fillParts();

//     this.fillStatus();    
//     this.CheckDirtySearch();

//   }

//   onSearchShowChange(value)
//   {
//     this.ShowSearch=value;
//   }

// changeSorting(column){
//   if (column =="#")
//   {
//       return;
//   }
//   //console.log(this.tableHeaders);
//     this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
//     this.defaultSort = this.dataGridService.getSort();
//     this.sortDirection = this.dataGridService.getSortDirection();
//     this.sortExpression = this.dataGridService.getSortExpression();
//     this.currentPageNumber = 1;
//     this.executerepairSearch();

// };

// setSortIndicator = function (column) {
//   //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
//     return this.dataGridService.setSortIndicator(column, this.defaultSort);
// };

//   executerepairSearch()
// {
//   AppComponent.ShowLoader()
//   this.pageheader.isSearchOpen=false;
//   this.pageheader.searchText="Search Records";
//   this.ShowSearch=false;
//   this.searchText="Search Records";
//   this.CheckDirtySearch();
//   var  repairsearch = this.prepareSearch();
  

//   this.wpapi.GetWaterProcessRepairsRecords(repairsearch).subscribe(pagedData  =>
//     {this.page.totalElements= pagedData.totalRows;
//       this.page.totalPages= pagedData.totalPages;
//      console.log(pagedData);
//     this.rows=pagedData.repailDetails;
//     AppComponent.HideLoader()
//   }
//   ,
//           error=>
//           {
//             AppComponent.addAlert(error.message,"error");
//             AppComponent.HideLoader();
//           }
//   );
// }

// prepareSearch() {

//   var repairsearch =<any>{};
//   repairsearch.RepairId = this.RepairId;
//   repairsearch.RepairType = this.detailsType;

//   if (this.FromDate != undefined && this.FromDate !="" )
//   {
//     repairsearch.FromDate = Globals.GetAPIDate(this.FromDate.jsdate);
//   }
//   if (this.ToDate != undefined && this.ToDate !="" )
//   {
//     repairsearch.ToDate = Globals.GetAPIDate( this.ToDate.jsdate);
//   }
//   repairsearch.VendorOrderRef = this.VendorOrderRef;

//   if (this.selectedDivision != undefined && this.selectedDivision !="" ) {
//       repairsearch.DivisionId = this.selectedDivision;
//   }


//   if (this.selectedEquipment != undefined && this.selectedEquipment !="") {
//       repairsearch.EquipmentId = this.selectedEquipment;
//   }

//   if (this.selectedPart != undefined && this.selectedPart != "") {
//       repairsearch.PartId = this.selectedPart;
//   }

//   if (this.selectedUser != undefined && this.selectedUser != "") {
//       repairsearch.createdBy = this.selectedUser;
//   }

//   // if (this.selectedPatient != undefined && this.selectedPatient != "") {
//   //     repairsearch.patientId = this.selectedPatient;
//   // }

//   if (this.selectedStatus != undefined && this.selectedStatus != "") {
//       repairsearch.Status = this.selectedStatus;
//   }
//   if (this.OutFromDate != undefined && this.OutFromDate !="" )
//   {
//     repairsearch.inTimeOfEquipement = Globals.GetAPIDate( this.OutFromDate.jsdate);
//   }
//   if (this.OutToDate != undefined && this.OutToDate !="" )
//   {
//     repairsearch.equipmentDateOut = Globals.GetAPIDate( this.OutToDate.jsdate );
//   }
//   repairsearch.workOrderRefNo = this.workOrderRefNo;
//   repairsearch.currentPageNumber = this.currentPageNumber;
//   repairsearch.sortExpression = this.sortExpression;
//   repairsearch.sortDirection = this.sortDirection;
//   repairsearch.pageSize = this.pageSize;
//   // console.log(repairsearch);
//   return repairsearch;

// }

//   setPage(){
//     this.currentPageNumber=this.page.pageNumber;
//     //this.page.pageNumber = pageInfo.offset;
//     this.executerepairSearch();
// }
//  _onRowClick(row) {
   
//       this.router.navigate(['WaterProcess/Repairs/RepairDetail'], { queryParams: { id: row} });
// }


// public myOptions: IAngularMyDpOptions = {
//   // other options...
//   dateFormat: 'dd/mm/yyyy',
//   closeSelectorOnDateSelect:false
// };



// onDateChanged(event: IMyDateModel): void {
//   // date selected
// }

//   fillDialysisUnit()
//   {
//     this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);

//   }

//   fillEquipment()
//   {
//     const value = {
//       DivisionId: this.selectedDivision,
//       CategoryName: this.detailsType,
//       Type: 'EQUIPMENT'
//     };
//     this.wpapi.GetEquipmentByCategoryDD(value).subscribe(
//       list => (this.Equipments = list)
//     );    

//   }

  

//   fillParts()
//   {
//     const value = {
//       DivisionId: this.selectedDivision,
//       CategoryName: this.detailsType,
//       Type: 'PARTS'
//     };
//     this.wpapi.GetPartsDDByCategory(value).subscribe(list => this.Parts=list);
    

//   }

//   fillStatus()
//   {
//     //this.repairapi.GetStatusDD().subscribe(list => this.Statuses=list);
//     this.Statuses= [
//       { value: "", text: "Select" },
//       { value: "Not Started", text: "Not Started" },
//       { value: "Open", text: "Open" },
//       { value: "Completed", text: "Completed" }
//     ];

//   }

 

//   executerepairsearchClear()
//   {
//     this.selectedDivision = "";
//     this.selectedEquipment = "";
//     this.selectedPart = "";
   
//     this.selectedStatus = "";
//     this.selectedUser = "";
//     this.RepairId="";
//     this.FromDate= "";
//     this.ToDate="";
//     this.VendorOrderRef="";
//     this.OutFromDate="";
//     this.OutToDate="";
//     this.executerepairSearch();

//   }

//   CheckDirtySearch()
//   {
//     this.blnDirtySearch= false;

//     if(
//       this.selectedDivision != ""
//     || this.selectedEquipment  != ""
//     || this.selectedPart != ""
//     //|| this.selectedPatient != ""
//     || this.selectedStatus != ""
//     || this.selectedUser != ""
//     || this.RepairId != ""
//     || (this.FromDate != null && this.FromDate != "")
//     || (this.ToDate != null && this.ToDate != "")
//     || this.VendorOrderRef != ""
//     || (this.OutFromDate != null && this.OutFromDate != "")
//     || (this.OutToDate != null && this.OutToDate != ""))
//     {
//       this.blnDirtySearch = true;

//     }
    
//     return this.blnDirtySearch;


 

//   }


}

// export class Page {
//   //The number of elements in the page
//   size: number = 0;
//   //The total number of elements
//   totalElements: number = 0;
//   //The total number of pages
//   totalPages: number = 0;
//   //The current page number
//   pageNumber: number = 0;
// }

import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { TasksApiService } from '../API/tasksapi.service';

import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';


import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';

import {
  Globals,
  actionType,

} from '../globals';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'TaskTypeDetail',
  templateUrl: './TaskTypeDetail.component.html'
})
export class TaskTypeDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  isValidFormSubmitted = null;

  ListURL = 'Masters/TaskTypeSearch';
  loading = true;
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  blnshowSave = false;
  blnshowDelete = false;

  TaskTypeForm = new UntypedFormGroup({
    TaskTypeId: new UntypedFormControl(''),
    TaskTypeName: new UntypedFormControl('', Validators.required),
    Prefix: new UntypedFormControl('', Validators.required),
    Color: new UntypedFormControl('', Validators.required),
    BackColor: new UntypedFormControl('', Validators.required),
  });

  public data = [{
    text: 'Select',
    value: ''
  }, { text: 'Vivid Tangerine' , value: '#ff9478'}

  , { text: 'New York Pink' , value: '#e08283'}
  
  , { text: 'Sunglo' , value: '#e26a6a'}
  , { text: 'Soft Red' , value: '#ec644b'}
  , { text: 'Cabaret' , value: '#d2527f'}
  
  , { text: 'Razzmatazz' , value: '#db0a5b'}
  
  , { text: 'Radical Red' , value: '#f62459'}
  
  , { text: 'Sunset Orange' , value: '#f64747'}
  
  , { text: 'Monza' , value: '#cf000f'}
  
  , { text: 'Old Brick' , value: '#96281b'}
  
  , { text: 'Blue Chalk' , value: '#f1e7fe'}
  
  , { text: 'Wistful' , value: '#aea8d3'}
  
  , { text: 'Scampi' , value: '#736598'}
  
  , { text: 'Mauve' , value: '#d5b8ff'}
  
  , { text: 'Electric Indigo' , value: '#8c14fc'}
  
  , { text: 'Medium Purple' , value: '#bf55ec'}
  
  , { text: 'Snuff' , value: '#dcc6e0'}
  
  , { text: 'Lavender Purple' , value: '#947cb0'}
  
  , { text: 'Studio' , value: '#8e44ad'}
  
  , { text: 'RebeccaPurple' , value: '#663399'}
  
  , { text: 'Alice Blue' , value: '#e4f1fe'}
  
  , { text: 'Spray' , value: '#81cfe0'}
  
  , { text: 'Iris Blue' , value: '#00b5cc'}
  
  , { text: 'Shakespeare' , value: '#52b3d9'}
  
  , { text: 'Ming' , value: '#336e7b'}
  
  , { text: 'Jordy Blue' , value: '#89c4f4'}
  
  , { text: 'Picton Blue' , value: '#59abe3'}
  
  , { text: 'Deep Sky Blue' , value: '#19b5fe'}
  
  , { text: 'Dodger Blue' , value: '#22a7f0'}
  
  , { text: 'Mariner' , value: '#2c82c9'}
  
  , { text: 'Fountain Blue' , value: '#5c97bf'}
  
  , { text: 'Summer Sky' , value: '#1e8bc3'}
  
  , { text: 'Jelly Bean' , value: '#2574a9'}
  
  , { text: 'Air Force Blue' , value: '#4b77be'}
  
  , { text: 'San Marino' , value: '#446cb3'}
  
  , { text: 'Chambray' , value: '#3a539b'}
  
  , { text: 'Persian Blue' , value: '#4d13d1'}
  
  , { text: 'Madang' , value: '#c8f7c5'}
  
  , { text: 'Aqua Island' , value: '#a2ded0'}
  
  , { text: 'Light Green' , value: '#7befb2'}
  
  , { text: 'Medium Turquoise' , value: '#4ecdc4'}
  
  , { text: 'Ocean Green' , value: '#4daf7c'}
  
  , { text: 'Bright Turquoise' , value: '#29f1c3'}
  
  , { text: 'Observatory' , value: '#049372'}
  
  , { text: 'Salem' , value: '#1e824c'}
  
  , { text: 'Downy' , value: '#65c6bb'}
  
  , { text: 'Light Sea Green' , value: '#1ba39c'}
  
  , { text: 'Jade' , value: '#00b16a'}
  
  , { text: 'Malachite' , value: '#00e640'}
  
  , { text: 'Orchid White' , value: '#f0f0d6'}
  
  , { text: 'Gin Fizz' , value: '#fff9de'}
  
  , { text: 'Cream' , value: '#ffffcc'}
  
  , { text: 'Dolly' , value: '#ffff7e'}
  
  , { text: 'Marigold Yellow' , value: '#f4f776'}
  
  , { text: 'Energy Yellow' , value: '#f5e653'}
  
  , { text: 'Chartreuse Yellow' , value: '#eeee00'}
  
  , { text: 'Yellow' , value: '#f0ff00'}
  
  , { text: 'Ripe Lemon' , value: '#f7ca18'}
  
  , { text: 'My Sin' , value: '#fcb941'}
  
  , { text: 'Supernova' , value: '#ffcb05'}
  
  , { text: 'California' , value: '#f89406'}
  
  , { text: 'Jaffa' , value: '#f27935'}
  
  , { text: 'Ecstasy' , value: '#f9690e'}
  
  , { text: 'Burnt Orange' , value: '#d35400'}
  
  , { text: 'Mystic' , value: '#e8ecf1'}
  
  , { text: 'Mercury' , value: '#e8e8e8'}
  
  , { text: 'Pampas' , value: '#f3f1ef'}
  
  , { text: 'Solitude' , value: '#e4e9ed'}
  
  , { text: 'Iron' , value: '#dadfe1'}
  
  , { text: 'Silver Sand' , value: '#bdc3c7'}
  
  , { text: 'Pumice' , value: '#d2d7d3'}
  
  , { text: 'Edward' , value: '#abb7b7'}
  
  , { text: 'Cascade' , value: '#95a5a6'}
  
  , { text: 'Silver' , value: '#bfbfbf'}
  
  , { text: 'Lynch' , value: '#6c7a89'}
  
  , { text: 'Hoki' , value: '#67809f'}
  
  , { text: 'Outer Space' , value: '#2e3131'}];

  public backdata = [{
    text: 'Select',
    value: ''
  }, {
    text: 'Mystic',
    value: '#e8ecf1'
  }, {
    text: 'White Smoke',
    value: '#ececec'
  }, {
    text: 'Pampas',
    value: '#f3f1ef'
  }, {
    text:'Iron',
    value: '#dadfe1'
  }, {
    text: 'Wax Flower',
    value: '#f1a9a0'
  }, {
    text: 'Blue Chalk',
    value: '#f1e7fe'
  }, {
    text: 'Mauve',
    value: '#d5b8ff'
  }, {
    text: 'Alice Blue',
    value: '#e4f1fe'
  }, {
    text: 'Humming Bird',
    value: '#c5eff7'
  }, {
    text: 'Spray',
    value: '#81cfe0'
  }, {
    text: 'Madang',
    value: '#c8f7c5'
  }, {
    text: 'Light Green',
    value: '#7befb2'
  }, {
    text: 'Gin Fizz',
    value: '#fff9de'
  }, {
    text: 'Cream',
    value: '#ffffcc'
  }, {
    text: 'Yellow',
    value: '#ffff7e'
  }, {
    text: 'Marigold Yellow',
    value: '#f4f776'
  }, {
    text: 'Saffron',
    value: '#f4d03f'
  }, {
    text: 'Cape Honey',
    value: '#fde3a7'
  }, {
    text: 'Sea Buckthorn',
    value: '#f9b42d'
  }];
  
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.TaskTypeForm.dirty) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private tsapi: TasksApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService




  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.f.TaskTypeId.setValue(params['id'] || '0');
      if (this.f.TaskTypeId.value !== '0') {
        this.setModel();
      } else {
        this.f.Color.setValue('');
      }
    });
    this.SetTaskTyperights();

    AppComponent.HideLoader();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();

  }

  get f() {
    return this.TaskTypeForm.controls;
  }

GetColor() {
  return this.f.Color.value;
}

get ColorName() {
  return this.f.Color.value;
}

set ColorName(name) {
  this.f.Color.setValue(name);
}

GetBackColor() {
  return this.f.BackColor.value;
}

get BackColorName() {
  return this.f.BackColor.value;
}

set BackColorName(name) {
  this.f.BackColor.setValue(name);
}

  setModel() {
    AppComponent.ShowLoader();
    const TaskTypeCreate = { Id: this.f.TaskTypeId.value };

    this.tsapi.GetTasksTypeByID(TaskTypeCreate).subscribe(
      success => {
        console.log(success);

        //this.f.TaskTypeId.setValue(success.TaskTypeId);
        this.f.TaskTypeName.setValue(success.taskTypeName);
        this.f.Prefix.setValue(success.prefix);
        this.f.Color.setValue(success.color);
        this.f.BackColor.setValue(success.backcolor);
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {}

  executeTaskTypeCreateUpdate = function(Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.TaskTypeForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const TaskTypeCreate = this.prepareCreateViewModel();
    if (TaskTypeCreate.TaskTypeId === '0') {
      console.log(TaskTypeCreate);
      this.tsapi.CreateTaskType(TaskTypeCreate).subscribe(
        success => {
           console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
            this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      console.log(TaskTypeCreate);
  this.tsapi.UpdateTasksType(TaskTypeCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
            this.router.navigate([this.ListURL]);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const TaskTypeCreate = <any>{};
   console.log(this.f.TaskTypeId.value);
    TaskTypeCreate.TaskTypeId = this.f.TaskTypeId.value;
    TaskTypeCreate.TaskTypeName = this.f.TaskTypeName.value;
    TaskTypeCreate.Prefix = this.f.Prefix.value;
    TaskTypeCreate.color = this.f.Color.value;
    TaskTypeCreate.backcolor = this.f.BackColor.value;
    TaskTypeCreate.description = '';
    TaskTypeCreate.isDelete = false;
    //console.log(this.f.Color);

    TaskTypeCreate.createdon = new Date();
    TaskTypeCreate.createdby = this.currentUserId;
    TaskTypeCreate.modifiedon = new Date();
    TaskTypeCreate.modifiedby = this.currentUserId;

    return TaskTypeCreate;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const TaskTypeObj = { Id: this.f.TaskTypeId.value };
        this.tsapi.DeleteTasksTypeByID(TaskTypeObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Task Type detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetTaskTyperights() {
    this.auth.GetPageRights('DM Repair Form').subscribe(list => {
      if (this.f.TaskTypeId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.TaskTypeId.value != '0') {
		  this.blnshowDelete = true;
		}
  }
}

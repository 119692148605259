import { Component, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../../globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialog } from '../../ConfirmationDialog';
import { AuthService } from '../../../API/auth.service';
import { QuickAddPartDialog } from '../../QuickAddPart/QuickAddPartDialog';
import { AppComponent } from 'src/app/app.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'Parts_Component',
  templateUrl: './PartsComponent.html'
})

export class PartsComponent {

  quickAddDialogRef: MatDialogRef<QuickAddPartDialog>;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  today = new Date();
  public PrevParts = [];
  PartDescription = '';
  PartSearchTerm = '';
  PartAutoComplete = 'test';
  PartSelectedItem = <any>{};
  public PartTrans = [];
  PartQuantity = '1';
  PartCost = 0;
  PartQtyList = [];
  SelectedPart;
  public partSearchResult = [];
  public SelectedDialysisUnits;
  public SelectedEquipment
  public partscategoryname:string= 'Dialysis Machines';
  public currentUserId;
  public API;
  public status;
  public showPartHearder=true;
  public disableControl =false;
  @Output()
  RaiseError = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      while (this.PartQtyList.length < 10) {
        this.PartQtyList.push({
          text: (this.PartQtyList.length + 1).toString(),
          value: (this.PartQtyList.length + 1).toString()
        });
      }
      this.PartQuantity = '1';
    }, error => {
    });
  }

  PartCodeChange(type) {

    const dmrepairCreate = <any>{};
    dmrepairCreate.partsSearchtext = this.PartSearchTerm;
   // console.log(this.f.PartSearchTerm.value);
    dmrepairCreate.divisionId = this.SelectedDialysisUnits;
    dmrepairCreate.equipementId = this.SelectedEquipment;
    this.partSearchResult = [];
    const obj = {
      partssearchtext: this.PartSearchTerm,
      partscategoryname: this.partscategoryname,
      partsserachtype: type,
      partsshowinventory: true,
      divisionid: this.SelectedDialysisUnits,
      equipementid: this.SelectedEquipment,
    };
    if (type === 'desc') {
      obj.partssearchtext = this.PartDescription;
    }
    console.log(obj);
    this.API.GetPartsdetailsDetailssearchByCategory(obj).subscribe(list => {

      this.partSearchResult = list;
    });
  }
  getPartPosts(option, type) {

    if(option && option.value){
        if (type === 'code') {
          this.PartSelectedItem = this.partSearchResult.filter(
            x => x.equipmentcode === option.value
          )[0];
          this.PartDescription = this.PartSelectedItem.equipmentDesc;
        } else {
          this.PartSelectedItem = this.partSearchResult.filter(
            x => x.equipmentDesc === option.value
          )[0];
          this.PartSearchTerm = this.PartSelectedItem.equipmentcode;
        }
        this.SetCost();

        this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
          this.today = new Date(currentDate);
        });

    }
  }

  SetCost() {
    if (this.PartQuantity !== '' && this.PartSelectedItem !== undefined) {
      // tslint:disable-next-line:radix
      this.PartCost = parseInt(this.PartQuantity) * this.PartSelectedItem.itemcost;
       // console.log(this.PartCost);
    }
  }


  AddNewPartInventory(){


    if(this.SelectedEquipment){
        this.PartSelectedItem.desc = '';
        this.quickAddDialogRef = this.dialog.open(QuickAddPartDialog, {
          disableClose: true,
          position: { right: '0',top:'0'},
          height: '100%',
          maxWidth:'60vh',
          maxHeight: '100vh',
          data: {
            category: this.partscategoryname,
            comp_equipmentId:  this.SelectedEquipment
          }
        });
        //this.quickAddDialogRef.componentInstance.quickAddPartModel.title = type;
        this.quickAddDialogRef.afterClosed().subscribe(result => {
        });

        this.quickAddDialogRef.componentInstance.SaveEvent.subscribe(evt => {
            this.PartSelectedItem = evt;
            this.PartDescription = this.PartSelectedItem.equipmentDesc;
            this.PartSearchTerm = this.PartSelectedItem.equipmentcode;
            this.SetCost();
        });
    }
    else{
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
    }
  }

  AddPart() {
    if (
      this.PartSelectedItem.equipementid != null &&
      this.PartSelectedItem.equipementid > 0
    ) {
    //   console.log("equip code", this.PartSelectedItem.equipementid);
    //   const temp = this.PartTrans.filter(
    //     x => (x.equipmentid == this.PartSelectedItem.equipementid || x.equipmentId == this.PartSelectedItem.equipementid)
    //   );
      // let temp1 = [];
      // if(this.PrevParts.length > 0) {
      // temp1 = this.PrevParts.filter(
      //   x => (x.equipmentid == this.PartSelectedItem.equipementid || x.equipmentId == this.PartSelectedItem.equipementid)
      // );
      // }

      // console.log(this.PartTrans);
      // console.log(this.PrevParts);
      // console.log(temp);
      // console.log(temp1);

      // if (temp.length === 0 && temp1.length === 0) {



        const obj = <any>{};
        obj.DMRepairDetailsId = 0;
        obj.equipmentId = this.PartSelectedItem.equipementid;
        // tslint:disable-next-line:radix
        obj.quantity = parseInt(this.PartQuantity);
        // obj.quantity = this.PartQuantity;
        obj.PartCode = this.PartSelectedItem.equipmentcode;
        obj.PartDescription = this.PartSelectedItem.equipmentDesc;
        obj.PartCost = this.PartSelectedItem.itemcost;

      obj.createdBy = this.currentUserId;
      obj.createdByName = this.auth.GetUserFLName();
      obj.createdOnDisplay = this.today;
      obj.createdOn = Globals.GetAPIDate(this.today);
      obj.modifiedBy = this.currentUserId;
      obj.modifiedOnDisplay = this.today;
      obj.modifiedOn = Globals.GetAPIDate(this.today);

      // For Repair Detail Parts Table
      obj.repairPartDetailsEnteredBy = this.currentUserId;
      obj.repairPartDetailsEnteredOn = Globals.GetAPIDate(this.today);
      //

      obj.showPartDelete = false;
        if (obj.createdBy === this.currentUserId) {
          obj.showPartDelete = true;
        }
        this.PartTrans.push(obj);

        this.PartSearchTerm = '';
        this.PartSelectedItem.desc = '';
        this.PartDescription ='';
        // this.PartLocation = "";
        this.PartCost = 0;
        this.PartQuantity = '1';
        this.partSearchResult = [];
        this.PartSelectedItem =<any>{};
  //    }
    }
  }
  DeletePart(index) {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PartTrans.splice(index, 1);
      }
      this.dialogRef = null;
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { PreventiveMaintenanceCommonDetailComponent } from 'src/app/Core/Shared/PreventiveMaintenanceCommon/PreventiveMaintenanceCommonDetail.component';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';

@Component({
  selector: 'HomePreventiveMaintenanceDetail',
  templateUrl: './HomePreventiveMaintenanceDetail.component.html'
})
export class HomePreventiveMaintenanceDetailComponent implements OnInit, CanComponentDeactivate  {
  
  @ViewChild(PreventiveMaintenanceCommonDetailComponent, { static: true }) child;


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.child.canDeactivate();
  }
  constructor() { }
  
  ngOnInit() {
  }

}

import { AbstractControl } from '@angular/forms';
import { AuthService} from '../API/auth.service';
import { map } from 'rxjs/operators';

export class ValidateUser  {
  static UserValidate(UserName: AbstractControl, authAPI: AuthService) {
    return (control: AbstractControl) => {
    const credential = {PinID: control.value, UserName: UserName.value};
        return authAPI.ValidateConfirmationUser(credential).pipe(map(res => {
          return ( res.value !== undefined && (parseFloat(res.value)) > 0 ) ? null : { UserValidate: true };
           }));
          };
  }
}

import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';

import { Globals, actionType, attachmentFormModules } from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { HomeService } from 'src/app/API/home.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'DMUpgradeModificationsDetail',
  templateUrl: './DMUpgradeModificationsDetail.component.html'
})
export class DMUpgradeModificationsDetailComponent
  implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  private today = new Date();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  isValidFormSubmitted = null;
  ListURL = 'DialysisMachine/DMUpgradeModificationsSearch';
  status = '';
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  divisionName = '';
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  EventCode = '[AutoNumber]';
  selectedEquipmenttext;
  selectedUsertext;
  CurrentUser = '';
  EquipmentMappingList = [];
  CurrentEquipmentMappingList = [];
  eventDateLabel;
  selectedEquipmentHours = 0;
  DMUpgradeModificationsForm = new UntypedFormGroup({
    DMUpgradeModificationssId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl('', Validators.required),
    eventDate: new UntypedFormControl('', Validators.required),
    remarks: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedEquipment: new UntypedFormControl(''),
    EquipDate: new UntypedFormControl(''),
    EquipHoursOut: new UntypedFormControl('', [Validators.required,
    Validators.pattern(Globals.NumberNumericRegexWithoutZero)]),
    upgradeHours: new UntypedFormControl('0.00', [Validators.required]),
  });
  RepairStatus = false;

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }

  };

  // Equipments

  Equipments = [];
  ValidEquipId: boolean;
  ValidEquipDate: boolean;
  ValidEquipHoursOut: boolean;

  fillEquipment(divisionID) {
    // console.log('fillequip', divisionID);

    if (divisionID !== 0) {

      this.selectedDialysisUnitstext = this.DialysisUnits.filter(
        x => x.value === divisionID
      );


      if (this.selectedDialysisUnitstext.length > 0 && this.selectedDialysisUnitstext[0].text == 'Home Program') {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({ text: 'Select', value: '' });
          this.Equipments = list;
        });
      }
      else {
        const eqList = [];
        const equipList = this.EquipmentMappingList;
        this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
          this.Equipments = [];
          if (this.EquipmentMappingList.length > 0) {
            list.forEach(function (value) {
              const isAvailable = equipList.filter(
                // tslint:disable-next-line:triple-equals
                x => x.equipmentId == value.value
              );
              // console.log(isAvailable);
              if (isAvailable.length === 0) {
                eqList.push(value);
              }
            });
            this.Equipments = eqList;
          } else {
            this.Equipments = list;
          }
        });
      }
    } else {
      this.Equipments = [{ value: '', text: 'Select' }];
      this.f.selectedEquipment.setValue('');
    }
  }

  AddEquipment() {

    this.validateEquipmentAdd();
    this.selectedEquipmentHours = 0;
    let isError = false;
    let equipment = null;
    // console.log(isError);
    if (
      this.f.selectedEquipment.value == null ||
      this.f.selectedEquipment.value <= 0
    ) {
      isError = true;
      this.ValidEquipId = false;
    } else {
      equipment = this.Equipments.filter(
        // tslint:disable-next-line:triple-equals
        x => x.value == this.f.selectedEquipment.value
      );
      this.ValidEquipId = true;
      // console.log(equipment);
    }
    // console.log(isError, this.f.EquipDate.value);
    if (this.f.EquipDate.value === '' || this.f.EquipDate.value === null || this.f.EquipDate.value.jsdate === null || this.f.EquipDate.value.jsdate === '' || this.f.EquipDate.value.jsdate === undefined) {
      isError = true;
      this.ValidEquipDate = false;
    }
    else {
      this.ValidEquipDate = true;
    }
    // console.log(isError);
    if (this.f.EquipHoursOut.value === null || this.f.EquipHoursOut.value === '' || this.f.EquipHoursOut.status === 'INVALID') {
      isError = true;
      if (this.f.EquipHoursOut.status === 'INVALID' && (this.f.EquipHoursOut.value !== '' || this.f.EquipHoursOut.value !== null))
        this.ValidEquipHoursOut = true;
      else
        this.ValidEquipHoursOut = false;

      if (this.f.EquipHoursOut.value === '')
        this.ValidEquipHoursOut = false;
    }
    else {
      this.ValidEquipHoursOut = true;
    }
    // console.log(isError);
    if (!isError && !this.RepairStatus) {
      const mapobj = {

        completedBy: this.currentUserId,
        createdbyname: this.CurrentUser,
        dateCompleted: this.f.EquipDate.value.jsdate,
        dmOutHours: this.f.EquipHoursOut.value,
        dmUpgradeModficationInventoryMappingId: 0,
        dmupgrademodficationId: this.f.DMUpgradeModificationssId.value,
        equipmentId: this.f.selectedEquipment.value,
        equipmentName: equipment[0].text
      };
      const index = this.Equipments.findIndex(order => order.value === this.f.selectedEquipment.value);
      this.Equipments.splice(index, 1);
      this.f.EquipHoursOut.setValue('');
      this.f.EquipDate.setValue('');
      this.f.selectedEquipment.setValue('');

      this.EquipmentMappingList.push(mapobj);
      this.CurrentEquipmentMappingList.push(mapobj);
    }

    console.log(this.EquipmentMappingList);
  }
  DeleteEquipment(index, dmUpgradeModficationInventoryMappingId) {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        if(dmUpgradeModficationInventoryMappingId != 0) {
        AppComponent.ShowLoader();
        const DMUpgradeModificationsObj = {
          Id: dmUpgradeModficationInventoryMappingId,
          userId: this.currentUserId,
        };
        this.dmapi
          .DeletedmUpgradeModficationInventoryMappingByID(DMUpgradeModificationsObj)
          .subscribe(
            list => {
              AppComponent.HideLoader();
              AppComponent.addAlert(
                'DM upgrades/modifications equipment deleted successfully!',
                'success'
              );
              // const tempindex = this.EquipmentMappingList.findIndex(c=>c.dmUpgradeModficationInventoryMappingId == dmUpgradeModficationInventoryMappingId);
              this.EquipmentMappingList.splice(index, 1);
              // this.CurrentEquipmentMappingList.splice(tempindex, 1);

              this.fillEquipment(this.f.selectedDialysisUnits.value);
            },
            error => {
              AppComponent.addAlert(error.message, 'error');
              AppComponent.HideLoader();
            }
          );
      }
      else {

        const tempDeletedItem = this.EquipmentMappingList[index];
      this.EquipmentMappingList.splice(index, 1);
      const tempIndex = this.CurrentEquipmentMappingList.findIndex(c=>c.equipmentName == tempDeletedItem.equipmentName && c.dmOutHours == tempDeletedItem.dmOutHours);

      this.CurrentEquipmentMappingList.splice(tempIndex, 1);

       this.fillEquipment(this.f.selectedDialysisUnits.value);
    }
    }
      this.dialogRef = null;
    });
  }

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.DMUpgradeModificationsForm.dirty ||
        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private homeApi: HomeService,
    private auth: AuthService,
    private commonapi: CommonApiService,
    private decimalpipe: DecimalPipe
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.setValidators();
    // attachment changes
    this.attachment.DocId = this.f.DMUpgradeModificationssId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Upgradation];
    this.attachment.UploadBy = this.currentUserId;
    this.notes.showHours = true;
    this.CurrentUser = this.auth.GetUserFirstName();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.fillDialysisUnit();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.f.DMUpgradeModificationssId.setValue(params['id'] || '0');


        if (this.f.DMUpgradeModificationssId.value !== '0') {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {

            this.selectedUsertext = this.auth.GetUserFLName();

            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillEquipment(division[0]);
            }
            else {
              this.fillEquipment(0);
            }

            const tempDate = this.today;
            this.f.CreatedDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            const tempEventDate = this.today;
            this.f.eventDate.setValue({
              date: {
                year: tempEventDate.getFullYear(),
                month: tempEventDate.getMonth() + 1,
                day: tempEventDate.getDate()
              },
              jsdate: tempEventDate
            });

            AppComponent.HideLoader();
          }, error => {
            AppComponent.HideLoader();
          });
        }
      });

      this.SetDMUpgradeModificationsights();
      this.setDateOptions();
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.DMUpgradeModificationsForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const DMUpgradeModificationsCreate = {
      Id: this.f.DMUpgradeModificationssId.value
    };
    this.attachment.clearQueue();
    this.notes.Note = '';
    this.dmapi
      .GetDMUpgradesModificationsByID(DMUpgradeModificationsCreate)
      .subscribe(
        success => {
          console.log(success);
          this.EventCode = success.eventid;
          this.eventDateLabel = success.eventdate;
          // this.f.eventDate = success.eventdate;

          const tempEventDate = new Date(success.eventdate);
          this.f.eventDate.setValue({
            date: {
              year: tempEventDate.getFullYear(),
              month: tempEventDate.getMonth() + 1,
              day: tempEventDate.getDate()
            },
            jsdate: success.eventdate
          });


          const tempDate = new Date(success.createdon);

          this.f.CreatedDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.createdon
          });

          this.selectedUsertext = success.createdbyname;
          this.createdOn = success.createdOn;
          this.createdBy = success.createdBy;
          this.modifiedOn = success.modifiedOn;
          this.modifiedBy = success.modifiedBy;
          this.f.selectedDialysisUnits.setValue(success.divisionid.toString());

          this.divisionName = success.divisionName;
          this.f.remarks.setValue(success.description);

          // this.f.Hours.setValue(success.dialysismachinehours);
          // this.selectedEquipmenttext = success.equipmentname;
          const DMUpgradeModificationsObj = {
            Id: this.f.DMUpgradeModificationssId.value
          };
          this.dmapi
            .GetdmUpgradeModficationInventoryMappingByModificationId(
              DMUpgradeModificationsObj
            )
            .subscribe(list => {

              this.EquipmentMappingList = list;
              // console.log(success.divisionid);
              this.fillEquipment(success.divisionid);
              // console.log(list);
            });
          this.dmapi
            .GetattachementsByBydmUpgradeModficationByID(
              DMUpgradeModificationsObj
            )
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });
          this.dmapi
            .GetNotesBydmUpgradeModficationByID(DMUpgradeModificationsObj)
            .subscribe(list => {
              this.notes.NoteList = list;
            });
          this.status = success.status.trim();

          this.f.upgradeHours.setValue(success.upgradeHours == null ? 'N/A' : success.upgradeHours);

          AppComponent.HideLoader();
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
  }

  onDateChanged(event: IMyDateModel): void { }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DialysisUnits = list.filter(
      //   x => x.text != "Home Program"
      // );
      this.commonapi.FillDivision().subscribe(list => {
      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }

      this.DialysisUnits = list
    });
  }

  executeDMUpgradeModificationsCreateUpdate = function (Source) {



    AppComponent.loading = true;
    this.f.EquipHoursOut.clearValidators();
    this.f.EquipHoursOut.setErrors(null);

    // this.f.EquipHoursOut.updateValueAndValidity();
    this.isValidFormSubmitted = false;
    if (this.DMUpgradeModificationsForm.invalid || this.f.upgradeHours.value == '0.00') {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      this.notes.Note = '';
      return;
    }
    this.isValidFormSubmitted = true;

    const DMUpgradeModificationsCreate = this.prepareCreateViewModel(Source);

    if (DMUpgradeModificationsCreate.upgrademodificationsid === '0') {
      this.dmapi
        .CreatedmUpgradeModficationDetails(DMUpgradeModificationsCreate)
        .subscribe(
          success => {
            // console.log(success);
            // this.DMRepailDetail = success.dmRepailDetails;

            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            } else {
              this.f.DMUpgradeModificationssId.setValue(
                success.upgrademodificationsid
              );
              this.setModel();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
    } else {

      this.dmapi
        .UpdatedmUpgradeModficationDetails(DMUpgradeModificationsCreate)
        .subscribe(
          success => {
            this.DMRepailDetail = success.dmRepailDetails;
            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            } else {
              this.f.DMUpgradeModificationssId.setValue(
                success.upgrademodificationsid
              );
              this.setModel();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
    }

  };

  prepareCreateViewModel(Source) {

    const DMUpgradeModificationsCreate = <any>{};

    DMUpgradeModificationsCreate.upgrademodificationsid = this.f.DMUpgradeModificationssId.value;
    DMUpgradeModificationsCreate.eventid = this.EventCode;
    DMUpgradeModificationsCreate.createdon = Globals.GetAPIDate(this.today);
    DMUpgradeModificationsCreate.createdby = this.currentUserId;
    DMUpgradeModificationsCreate.modifiedon = Globals.GetAPIDate(this.today);
    DMUpgradeModificationsCreate.modifiedby = this.currentUserId;
    // if (this.f.DMUpgradeModificationssId.value === '0') {
    //   DMUpgradeModificationsCreate.eventdate = new Date();
    // } else {
    //   DMUpgradeModificationsCreate.eventdate = Globals.GetAPIDate(
    //     this.f.CreatedDate.value.jsdate
    //   );
    // }

    if (this.f.eventDate.value !== null && this.f.eventDate.value !== undefined) {
      DMUpgradeModificationsCreate.eventDate = Globals.GetAPIDate(this.f.eventDate.value.jsdate);
    }

    if (this.Equipments.length > 1 && Source != 'Completed' && this.status != 'Completed') {
      DMUpgradeModificationsCreate.status = 'In Progress';
    } else {
      DMUpgradeModificationsCreate.status = 'Completed';
    }
    // console.log(DMUpgradeModificationsCreate.status,this.Equipments.length);
    DMUpgradeModificationsCreate.divisionid = this.f.selectedDialysisUnits.value;
    DMUpgradeModificationsCreate.description = this.f.remarks.value;
    DMUpgradeModificationsCreate.upgradeHours = this.f.upgradeHours.value;
    DMUpgradeModificationsCreate.upgradeormodifications = '';
    DMUpgradeModificationsCreate.note = this.notes.Note;
    this.CurrentEquipmentMappingList.forEach(function (container, newKey) {

      container.dateCompleted = Globals.GetAPIDateWithoutSeconds(container.dateCompleted);
    });
    DMUpgradeModificationsCreate.dmUpgradeModficationInventoryMappingRecords = this.CurrentEquipmentMappingList;
    // attachments
    DMUpgradeModificationsCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    return DMUpgradeModificationsCreate;
  }

  DialysisUnitDropDownChanged() {
    this.fillEquipment(this.f.selectedDialysisUnits.value);
    if (this.f.DMUpgradeModificationssId.value == 0) {
      this.f.selectedEquipment.setValue('');
      this.selectedEquipmentHours = 0;
      this.f.EquipHoursOut.setValue('');
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const DMUpgradeModificationsObj = {
          Id: this.f.DMUpgradeModificationssId.value
        };
        this.dmapi
          .DeletedmUpgradeModficationByID(DMUpgradeModificationsObj)
          .subscribe(
            list => {
              AppComponent.HideLoader();
              AppComponent.addAlert(
                'DM upgrades/modifications record deleted successfully!',
                'success'
              );
              this.router.navigate([this.ListURL]);
            },
            error => {
              AppComponent.addAlert(error.message, 'error');
              AppComponent.HideLoader();
            }
          );
      }
      this.dialogRef = null;
    });
  }

  SetDMUpgradeModificationsights() {
    this.auth.GetPageRights('Upgrades/Mods').subscribe(list => {
      if (this.f.DMUpgradeModificationssId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.DMUpgradeModificationssId.value != '0') {
      this.blnshowDelete = true;
    }
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  setValidators() {
    this.f.EquipHoursOut.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.selectedEquipment,
        this.f.DMUpgradeModificationssId,
        this.dmapi
      )
    ]);
    this.f.EquipHoursOut.updateValueAndValidity();
  }

  onEquipHoursOutChange(searchValue: string) {

    if (searchValue != '')
      this.ValidEquipHoursOut = true;
  }

  onselectedEquipmentChange() {

    this.f.EquipHoursOut.setValue('');

    var equipmentId = { Id: this.f.selectedEquipment.value };
    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {

      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });

    this.validateEquipmentAdd();
  }

  validateEquipmentAdd() {
    var dmrepairValidate = {
      equipmentId: this.f.selectedEquipment.value,
      dmrepairdetailsid: 0,
      isDMRepair: false
    };

    this.dmapi.GetRepairStatusByEquipmentId(dmrepairValidate).subscribe(res => {

      if (res == true) {
        this.RepairStatus = false;
      }
      else { this.RepairStatus = true; };
      //return {false res };
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(): boolean {

    if (this.f.EquipHoursOut.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }

  UpgradeCompletedClick() {

    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to Complete this Upgrade?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        this.executeDMUpgradeModificationsCreateUpdate('Completed');
        // const DMUpgradeModificationsObj = {
        //   Id: this.f.DMUpgradeModificationssId.value
        // };


        // this.dmapi
        //   .CompletedmUpgradeModficationByID(DMUpgradeModificationsObj)
        //   .subscribe(
        //     list => {
        //       AppComponent.HideLoader();
        //       AppComponent.addAlert(
        //         'DM upgrades/modifications record saved successfully!',
        //         'success'
        //       );
        //       this.router.navigate([this.ListURL]);
        //     },
        //     error => {
        //       AppComponent.addAlert(error.message, 'error');
        //       AppComponent.HideLoader();
        //     }
        //   );
      }
      this.dialogRef = null;
    });

  }

  PlusHours()
  {
    if(parseFloat(this.f.upgradeHours.value)<12)
    {
      this.f.upgradeHours.setValue((parseFloat(this.f.upgradeHours.value.toString()) + 0.25).toString());

      this.f.upgradeHours.setValue(this.formatDecimal(this.f.upgradeHours.value.toString()));
  }
}

  MinusHours()
  {
    if(parseFloat(this.f.upgradeHours.value)>0.0)
    {
      this.f.upgradeHours.setValue((parseFloat(this.f.upgradeHours.value.toString()) - 0.25).toString());

      this.f.upgradeHours.setValue(this.formatDecimal(this.f.upgradeHours.value.toString()));
  }
}

  formatDecimal(val) {
      return this.decimalpipe.transform(val, '1.2-2');

  }
}

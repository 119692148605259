import { AppComponent } from './../../../app.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotesComponent } from '../../../Core/Shared/NotesComponent/NotesComponent';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeInitialSiteVisit',
  templateUrl: './purpose-initialsitevisit.component.html',
  styleUrls: ['./purpose-initialsitevisit.component.css']
})
export class PurposeInitialSiteVisitComponent implements OnInit {

  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  formSubmitted: boolean = false;
  mrmHomeId: number = 0;
  textColor = Globals.textColor;
  BITypeList = [
    { value: '', text: 'Select' },
    { value: 'Apt or Condo', text: 'Apt or Condo' },
    { value: 'Townhouse', text: 'Townhouse' },
    { value: 'Detached', text: 'Detached' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  BIOwnershipList = [
    { value: '', text: 'Select' },
    { value: 'Owned', text: 'Owned' },
    { value: 'Rented', text: 'Rented' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  BIEntranceList = [
    { value: '', text: 'Select' },
    { value: 'Front', text: 'Front' },
    { value: 'Back', text: 'Back' },
    { value: 'Inside', text: 'Inside' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  BIParkingList = [
    { value: '', text: 'Select' },
    { value: 'Driveway', text: 'Driveway' },
    { value: 'Street', text: 'Street' },
    { value: 'Underground', text: 'Underground' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  BIAccessibilityList = [
    { value: '', text: 'Select' },
    { value: 'Difficult', text: 'Difficult' },
    { value: 'Good', text: 'Good' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRILocationList = [
    { value: '', text: 'Select' },
    { value: 'Main floor', text: 'Main floor' },
    { value: 'Second floor', text: 'Second floor' },
    { value: 'Basement', text: 'Basement' },
    { value: 'Other', text: 'Other' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];


  DRIDimensionsLList = [
    { value: '', text: 'Select' },
    // { value: 'Difficult', text: 'Difficult' },
    // { value: 'Good', text: 'Good' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRIDimensionsWList = [
    { value: '', text: 'Select' },
    // { value: 'Difficult', text: 'Difficult' },
    // { value: 'Good', text: 'Good' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRIRoomTypeList = [
    { value: '', text: 'Select' },
    { value: 'Bedroom', text: 'Bedroom' },
    { value: 'Living Room', text: 'Living Room' },
    { value: 'Family Room', text: 'Family Room' },
    { value: 'Basement', text: 'Basement' },
    { value: 'Other', text: 'Other' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRILightingList = [
    { value: '', text: 'Select' },
    { value: 'Bad', text: 'Bad' },
    { value: 'Acceptable', text: 'Acceptable' },
    { value: 'Good', text: 'Good' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRICleanlinessList = [
    { value: '', text: 'Select' },
    { value: 'Not Acceptable', text: 'Not Acceptable' },
    { value: 'Acceptable', text: 'Acceptable' },
    // { value: 'Good', text: 'Good' },
    // { value: 'Excellent', text: 'Excellent' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  DRIFlooringTypeList = [
    { value: '', text: 'Select' },
    { value: 'Carpet', text: 'Carpet' },
    { value: 'Hardwood', text: 'Hardwood' },
    { value: 'Laminate', text: 'Laminate' },
    { value: 'Tile', text: 'Tile' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  ELPanelLocationList = [
    { value: '', text: 'Select' },
    { value: 'Main Floor', text: 'Main Floor' },
    { value: 'Basement', text: 'Basement' },
    { value: 'Other', text: 'Other' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  ELPanelSizeList = [
    { value: '', text: 'Select' },
    { value: 'Breaker', text: 'Breaker' },
    { value: 'Fuse Box', text: 'Fuse Box' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBWaterSupplyList = [
    { value: '', text: 'Select' },
    { value: 'Municipal', text: 'Municipal' },
    { value: 'Private/Well', text: 'Private/Well' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBWaterConnectionList = [
    { value: '', text: 'Select' },
    { value: 'Vanity/Sink', text: 'Vanity/Sink' },
    { value: 'Bathtub', text: 'Bathtub' },
    { value: 'Other', text: 'Other' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBDrainRunsIntoList = [
    { value: '', text: 'Select' },
    { value: 'Municipal', text: 'Municipal' },
    { value: 'Septic', text: 'Septic' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBHardnessList = [
    { value: '', text: 'Select' },
    { value: '<1', text: '<1' },
    { value: '1-5', text: '1-5' },
    { value: '>5', text: '>5' },
    { value: '>10', text: '>10' },
    { value: '>15', text: '>15' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBPHList = [
    { value: '', text: 'Select' },
    { value: '0-4.9', text: '0-4.9' },
    { value: '5-9.9', text: '5-9.9' },
    { value: '10+', text: '10+' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  PBChlorineList = [
    { value: '', text: 'Select' },
    { value: 'Negative', text: 'Negative' },
    { value: 'Positive', text: 'Positive' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },
  ];

  YesNoList = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No', color: 'red' },
    { value: 'See Notes', text: 'See Notes', color: 'red' },

  ];

  currentUserId;
  IsPurposeCompleted = '';
  today = new Date();

  ISVForm = new UntypedFormGroup({
    InitialSiteVisitId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl(''),
    BIType: new UntypedFormControl('', Validators.required),
    BIOwnership: new UntypedFormControl('', Validators.required),
    BIEntrance: new UntypedFormControl('', Validators.required),
    BIParking: new UntypedFormControl('', Validators.required),
    BIAccessibility: new UntypedFormControl('', Validators.required),
    BIStairs: new UntypedFormControl('', Validators.required),
    BINotes: new UntypedFormControl(''),
    DRILocation: new UntypedFormControl('', Validators.required),
    DRIDimensionsL: new UntypedFormControl('', Validators.required),
    DRIDimensionsW: new UntypedFormControl('', Validators.required),
    DRIRoomType: new UntypedFormControl('', Validators.required),
    DRILighting: new UntypedFormControl('', Validators.required),
    DRICleanliness: new UntypedFormControl('', Validators.required),
    DRIFlooringType: new UntypedFormControl('', Validators.required),
    DRISpaceStorage: new UntypedFormControl('', Validators.required),
    DRINotes: new UntypedFormControl(''),
    ELPanelLocation: new UntypedFormControl('', Validators.required),
    ELPanelSize: new UntypedFormControl('', Validators.required),
    ELExtraSlotsAvailable: new UntypedFormControl('', Validators.required),
    ELGFI: new UntypedFormControl('', Validators.required),
    ELSubpanelNeeded: new UntypedFormControl('', Validators.required),
    ELCodeIssues: new UntypedFormControl(''),
    ELNotes: new UntypedFormControl(''),
    PBWaterSupply: new UntypedFormControl('', Validators.required),
    PBWaterConnection: new UntypedFormControl('', Validators.required),
    PBDrainRunsInto: new UntypedFormControl('', Validators.required),
    PBSoftenerOnsite: new UntypedFormControl('', Validators.required),
    PBHardness: new UntypedFormControl('', Validators.required),
    PBPH: new UntypedFormControl('', Validators.required),
    PBChlorine: new UntypedFormControl('', Validators.required),
    PBWaterPressure: new UntypedFormControl('', Validators.required),
    PBNotes: new UntypedFormControl(''),
    PlumberRequiredForEvaluation: new UntypedFormControl('', Validators.required),
    POPlumber: new UntypedFormControl(''),
    ElectricianRequiredForEvaluation: new UntypedFormControl('', Validators.required),
    POElectrician: new UntypedFormControl(''),
    MoversRequiredForInstallation: new UntypedFormControl('', Validators.required),
    POMovers: new UntypedFormControl(''),
    POMoversNotes: new UntypedFormControl(''),
    SGSFeed: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    PBIronFilter: new UntypedFormControl('', Validators.required),
  });


  constructor(private homefieldApi: HomeFieldService, public auth: AuthService) { }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
    }, error => {
    });
  }

  ngAfterViewInit() {
    this.formChange();
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  get insvf() {
    return this.ISVForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  transformftW() {
    if (this.insvf.DRIDimensionsW.value) {
      this.insvf.DRIDimensionsW.setValue(this.ftToNumber(this.insvf.DRIDimensionsW.value) + " ft");
    }
  }

  transformftL() {
    if (this.insvf.DRIDimensionsL.value) {
      this.insvf.DRIDimensionsL.setValue(this.ftToNumber(this.insvf.DRIDimensionsL.value) + " ft");
    }
  }

  ftToNumber(value) {
    if (value != null)
      return Number(value.toString().replace(/[^0-9.-]+/g, ""));
  }

  prepareCreateViewModel(Source = "") {
    const modelInitCreate = <any>{};

    modelInitCreate.InitialSiteVisitId = this.insvf.InitialSiteVisitId.value;
    modelInitCreate.HfServiceId = this.insvf.HFServiceId.value;
    modelInitCreate.BIType = this.insvf.BIType.value;
    modelInitCreate.BIOwnership = this.insvf.BIOwnership.value;
    modelInitCreate.BIEntrance = this.insvf.BIEntrance.value;
    modelInitCreate.BIParking = this.insvf.BIParking.value;
    modelInitCreate.BIAccessibility = this.insvf.BIAccessibility.value;
    modelInitCreate.BIStairs = this.insvf.BIStairs.value;
    modelInitCreate.BINotes = this.insvf.BINotes.value;
    modelInitCreate.DRILocation = this.insvf.DRILocation.value;
    modelInitCreate.DRIDimensionsL = this.ftToNumber(this.insvf.DRIDimensionsL.value);
    modelInitCreate.DRIDimensionsW = this.ftToNumber(this.insvf.DRIDimensionsW.value);
    modelInitCreate.DRIRoomType = this.insvf.DRIRoomType.value;
    modelInitCreate.DRILighting = this.insvf.DRILighting.value;
    modelInitCreate.DRICleanliness = this.insvf.DRICleanliness.value;
    modelInitCreate.DRIFlooringType = this.insvf.DRIFlooringType.value;
    modelInitCreate.DRISpaceStorage = this.insvf.DRISpaceStorage.value;
    modelInitCreate.DRINotes = this.insvf.DRINotes.value;
    modelInitCreate.ELPanelLocation = this.insvf.ELPanelLocation.value;
    modelInitCreate.ELPanelSize = this.insvf.ELPanelSize.value;
    modelInitCreate.ELExtraSlotsAvailable = this.insvf.ELExtraSlotsAvailable.value;
    modelInitCreate.ELGFI = this.insvf.ELGFI.value;
    modelInitCreate.ELSubpanelNeeded = this.insvf.ELSubpanelNeeded.value;
    modelInitCreate.ELCodeIssues = this.insvf.ELCodeIssues.value;
    modelInitCreate.ELNotes = this.insvf.ELNotes.value;
    modelInitCreate.PBWaterSupply = this.insvf.PBWaterSupply.value;
    modelInitCreate.PBWaterConnection = this.insvf.PBWaterConnection.value;
    modelInitCreate.PBDrainRunsInto = this.insvf.PBDrainRunsInto.value;
    modelInitCreate.PBSoftenerOnsite = this.insvf.PBSoftenerOnsite.value;
    modelInitCreate.PBHardness = this.insvf.PBHardness.value;
    modelInitCreate.PBPH = this.insvf.PBPH.value;
    modelInitCreate.PBChlorine = this.insvf.PBChlorine.value;
    modelInitCreate.PBWaterPressure = this.insvf.PBWaterPressure.value;
    modelInitCreate.PBIronFilter = this.insvf.PBIronFilter.value;
    modelInitCreate.PBNotes = this.insvf.PBNotes.value;
    modelInitCreate.PlumberRequiredForEvaluation = this.insvf.PlumberRequiredForEvaluation.value;
    modelInitCreate.POPlumber = this.insvf.POPlumber.value;
    modelInitCreate.ElectricianRequiredForEvaluation = this.insvf.ElectricianRequiredForEvaluation.value;
    modelInitCreate.POElectrician = this.insvf.POElectrician.value;
    modelInitCreate.SGSFeed = this.insvf.SGSFeed.value;
    modelInitCreate.MoversRequiredForInstallation = this.insvf.MoversRequiredForInstallation.value;
    modelInitCreate.POMovers = this.insvf.POMovers.value;
    modelInitCreate.POMoversNotes = this.insvf.POMoversNotes.value;

    modelInitCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelInitCreate.CreatedBy = this.currentUserId;
    modelInitCreate.ModifiedOn = Globals.GetAPIDate(this.today);
    modelInitCreate.ModifiedBy = this.currentUserId;
    return modelInitCreate;
  }


  save() {
    return new Promise((resolve, reject) => {

      this.setDynamicValidations();
      this.formSubmitted = true;
      if (this.ISVForm.invalid) {

        return;
      }
      else {
        const InvModelCreate = this.prepareCreateViewModel();
        if (this.insvf.InitialSiteVisitId.value === '0'
        ) {
          this.homefieldApi.CreateHomeFieldService_InitialSiteVisit(InvModelCreate).subscribe(
            success => {
              this.insvf.InitialSiteVisitId.setValue(success.InitialSiteVisitId);
              resolve(true);
            },
            error => {
              reject();
            }
          );
        } else {
          this.homefieldApi.UpdateHomeFieldService_InitialSiteVisit(InvModelCreate).subscribe(
            success => {
              resolve(true);
            },
            error => {
              reject();
            }
          );

        }

      }
    });
  }
  setModel() {
    // alert(this.IsPurposeCompleted);

    this.homefieldApi.GetHomeFieldService_InitialSiteVisitbyServiceID({ Id: this.insvf.HFServiceId.value }).subscribe(
      success => {
        if (success) {
          this.insvf.InitialSiteVisitId.setValue(success.initialSiteVisitId);
          this.insvf.BIType.setValue(success.biType);
          this.insvf.BIOwnership.setValue(success.biOwnership);
          this.insvf.BIEntrance.setValue(success.biEntrance);
          this.insvf.BIParking.setValue(success.biParking);
          this.insvf.BIAccessibility.setValue(success.biAccessibility);
          this.insvf.BIStairs.setValue(success.biStairs);
          this.insvf.BINotes.setValue(success.biNotes);
          this.insvf.DRILocation.setValue(success.driLocation);
          this.insvf.DRIDimensionsL.setValue(success.driDimensionsL);
          this.transformftL();
          this.insvf.DRIDimensionsW.setValue(success.driDimensionsW);
          this.transformftW();
          this.insvf.DRIRoomType.setValue(success.driRoomType);
          this.insvf.DRILighting.setValue(success.driLighting);
          this.insvf.DRICleanliness.setValue(success.driCleanliness);
          this.insvf.DRIFlooringType.setValue(success.driFlooringType);
          this.insvf.DRISpaceStorage.setValue(success.driSpaceStorage);
          this.insvf.DRINotes.setValue(success.driNotes);
          this.insvf.ELPanelLocation.setValue(success.elPanelLocation);
          this.insvf.ELPanelSize.setValue(success.elPanelSize);
          this.insvf.ELExtraSlotsAvailable.setValue(success.elExtraSlotsAvailable);
          this.insvf.ELGFI.setValue(success.elgfi);
          this.insvf.ELSubpanelNeeded.setValue(success.elSubpanelNeeded);
          this.insvf.ELCodeIssues.setValue(success.elCodeIssues);
          this.insvf.ELNotes.setValue(success.elNotes);
          this.insvf.PBWaterSupply.setValue(success.pbWaterSupply);
          this.insvf.PBWaterConnection.setValue(success.pbWaterConnection);
          this.insvf.PBDrainRunsInto.setValue(success.pbDrainRunsInto);
          this.insvf.PBSoftenerOnsite.setValue(success.pbSoftenerOnsite);
          this.insvf.PBHardness.setValue(success.pbHardness);
          this.insvf.PBPH.setValue(success.pbph);
          this.insvf.PBChlorine.setValue(success.pbChlorine);
          this.insvf.PBWaterPressure.setValue(success.pbWaterPressure);
          this.insvf.PBIronFilter.setValue(success.pbIronFilter);
          this.insvf.PBNotes.setValue(success.pbNotes);
          this.insvf.PlumberRequiredForEvaluation.setValue(success.plumberRequiredForEvaluation);
          this.insvf.POPlumber.setValue(success.poPlumber);
          this.insvf.ElectricianRequiredForEvaluation.setValue(success.electricianRequiredForEvaluation);
          this.insvf.POElectrician.setValue(success.poElectrician);
          this.insvf.SGSFeed.setValue(success.sgsFeed);
          this.insvf.MoversRequiredForInstallation.setValue(success.moversRequiredForInstallation);
          this.insvf.POMovers.setValue(success.poMovers);
          this.insvf.POMoversNotes.setValue(success.poMoversNotes);
          this.setDynamicValidations();
        }

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  formChange(enable = false) {

    if (enable)
      this.ISVForm.enable();
    else
      this.ISVForm.disable();
  }

  getColor(item, colorList) {
    if (colorList.filter(x => x.color == "red" && x.value == item).length > 0)
      return "red";
    else
      return "#555";
  }

  setDynamicValidations() {
    this.clearDynamicValidations();
    if (this.insvf.BIType.value == "See Notes" ||
      this.insvf.BIOwnership.value == "See Notes" ||
      this.insvf.BIEntrance.value == "See Notes" ||
      this.insvf.BIParking.value == "See Notes" ||
      this.insvf.BIAccessibility.value == "See Notes" ||
      this.insvf.BIStairs.value == "See Notes") {
      this.insvf.BINotes.setValidators([Validators.required]);
    }


    if (this.insvf.DRILocation.value == "See Notes" ||
      this.insvf.DRIRoomType.value == "See Notes" ||
      this.insvf.DRILighting.value == "See Notes" ||
      this.insvf.DRICleanliness.value == "See Notes" ||
      this.insvf.DRIFlooringType.value == "See Notes" ||
      this.insvf.DRISpaceStorage.value == "See Notes") {
      this.insvf.DRINotes.setValidators([Validators.required]);
    }


    if (this.insvf.ELPanelLocation.value == "See Notes" ||
      this.insvf.ELPanelSize.value == "See Notes" ||
      this.insvf.ELExtraSlotsAvailable.value == "See Notes" ||
      this.insvf.ELGFI.value == "See Notes" ||
      this.insvf.ELSubpanelNeeded.value == "See Notes" ||
      this.insvf.ELCodeIssues.value == "See Notes" ||
      this.insvf.ElectricianRequiredForEvaluation.value == "See Notes"
    ) {
      this.insvf.ELNotes.setValidators([Validators.required]);
    }

    if (this.insvf.PBWaterSupply.value == "See Notes" ||
      this.insvf.PBWaterConnection.value == "See Notes" ||
      this.insvf.PBDrainRunsInto.value == "See Notes" ||
      this.insvf.PBSoftenerOnsite.value == "See Notes" ||
      this.insvf.PBHardness.value == "See Notes" ||
      this.insvf.PBPH.value == "See Notes" ||
      this.insvf.PBChlorine.value == "See Notes" ||
      this.insvf.PBWaterPressure.value == "See Notes" ||
      this.insvf.PBIronFilter.value == "See Notes" ||
      this.insvf.PlumberRequiredForEvaluation.value == "See Notes") {
      this.insvf.PBNotes.setValidators([Validators.required]);
    }

    if (this.insvf.MoversRequiredForInstallation.value == "See Notes") {
      this.insvf.POMoversNotes.setValidators([Validators.required]);
    }

    this.updateDynamicValidations();
  }

  clearDynamicValidations() {
    this.insvf.BINotes.clearValidators();
    this.insvf.DRINotes.clearValidators();
    this.insvf.ELNotes.clearValidators();
    this.insvf.PBNotes.clearValidators();
    this.insvf.POMoversNotes.clearValidators();
  }

  updateDynamicValidations() {
    this.insvf.BINotes.updateValueAndValidity();
    this.insvf.DRINotes.updateValueAndValidity();
    this.insvf.ELNotes.updateValueAndValidity();
    this.insvf.PBNotes.updateValueAndValidity();
    this.insvf.POMoversNotes.updateValueAndValidity();
  }
}

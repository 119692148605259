import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
@Component({
  selector: 'url-dialog',
  templateUrl: './URLDialog.html',
})
export class URLDialog {
  constructor(public dialogRef: MatDialogRef<URLDialog>,public sanitizer: DomSanitizer) {}
  
  public URL:string;
  public PageTitle:string;
}
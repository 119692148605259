import { Component, ViewChild } from '@angular/core';
import { AppComponent } from "../app.component";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Globals, actionType } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { ForumApiService } from '../API/forumapi.service';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';

import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { map, groupBy } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { CustomValidationStationGroup } from '../Validator/CustomValidationStationGroup';
import { debug } from 'util';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'ForumCompose',
  templateUrl: './ForumCompose.component.html',
  styleUrls: ['./ForumDetail.css'],

})
export class ForumComposeComponent {

  dialogRef: MatDialogRef<ConfirmationDialog>;
  state: string;
  Categories = [];
  blnshowDelete = false;
  blnshowSave = false;
  blnshowEdit = true;
  ListURL = "/Forum/ForumSearch";
  public message: string;
  createdBy = this.authentication.GetUserId();
  modifiedBy = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  items: UntypedFormArray;
  formSubmitted: boolean = false;
  validateForm: boolean = false;
  Math: any;
  homeProgram = false;
  opt = false;
  userDesignation = this.authentication.GetDesignationName();



  ForumForm = new UntypedFormGroup({
    subject: new UntypedFormControl('', Validators.required),
    content: new UntypedFormControl('', Validators.required),
    id: new UntypedFormControl(''),
    category: new UntypedFormControl('', Validators.required),
    content2: new UntypedFormControl(''),
  });
  CategoryName: '';
  createdByName: any;
  modifiedByName: any;
  modifiedon: any;
  posts: any;
  replies: number;

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder, private ForumApi: ForumApiService, private route: ActivatedRoute,
    public dialog: MatDialog,private commonapi : CommonApiService,
    private router: Router) {
    this.Math = Math;
  }


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (
      ((this.ForumForm.dirty) && (this.validateForm == false))
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else { return true; }
  }

  executeForumCreateUpdate = function (Source = "") {
    AppComponent.loading = true;
    this.formSubmitted = true;

    if (this.ForumForm.invalid) {
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      this.ForumForm.markAsDirty();
      AppComponent.ScrollDown();
      return;
    }
    this.validateForm = true;
    if (this.f.id.value == '0'
    ) {
      const modelCreate = this.prepareCreateViewModel();
      this.ForumApi.CreateForumTopics(modelCreate).subscribe(
        success => {
          this.ForumForm.controls.id.value = success.id;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.router.navigate([this.ListURL]);
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          } else {
            AppComponent.addAlert(error.message, 'error');
          }
          AppComponent.HideLoader();
        }
      );
    } else {
      this.ForumForm.value.createdBy = this.createdBy;
      this.ForumForm.value.modifiedBy = this.modifiedBy;
      const modelCreate = this.prepareCreateViewModel();
      this.ForumApi.UpdateForumTopics(modelCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          this.router.navigate([this.ListURL]);
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          } else {
            AppComponent.addAlert(error.message, 'error');
          }
          AppComponent.HideLoader();
        }
      );
    }
  };

  SetPageRights() {
    // this.authentication.GetPageRights('Contact').subscribe(list => {

    //   if (this.f.divisionId.value !== '0') {
    //     this.blnshowSave = list[actionType.edit] === '1';
    //     //this.blnshowDelete = list[actionType.delete] === '1';
    //     this.blnshowEdit = true;

    //   } else {
    //     this.blnshowSave = list[actionType.create] === '1';
    //     //this.blnshowDelete = false;
    //     this.blnshowEdit = false;

    //   }
    // });

    // if (this.userDesignation == 'Administrator' && this.f.divisionId.value != '0') {
    //   this.blnshowDelete = true;
    // }
  }
  prepareCreateViewModel() {
    const modelCreate = <any>{};
    modelCreate.id = this.f.id.value;
    modelCreate.subject = this.f.subject.value;
    modelCreate.topic_category = this.f.category.value;
    modelCreate.ModifiedOn = new Date();
    modelCreate.CreatedOn   = new Date();
    modelCreate.CreatedBy = this.createdBy;
    modelCreate.ModifiedBy = this.modifiedBy;
    if( this.f.id.value == "0") {
    modelCreate.content = this.f.content.value;
    } else {
      modelCreate.content = this.f.content2.value;
    }
    modelCreate.isdelete = 0;
    console.log(modelCreate);
        return modelCreate;
  }
  ngOnInit() {
    AppComponent.ShowLoader();
    this.GetCategoryList();
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.SetPageRights();
      this.f.id.setValue(params['id'] || '0');
      if (this.f.id.value !== '0') {
        this.setModel();
      }
    });
    AppComponent.HideLoader();
  }
  GetCategoryList() { 
    this.ForumApi.GetForumCategoryDD().subscribe(list => {
      this.Categories = list;
    });
  }
  setModel() {
    AppComponent.ShowLoader();
    const fid = { Id: this.f.id.value };
    this.ForumApi.SelectForumByID(fid).subscribe(
      success => {
        console.log(success);
        this.f.subject.setValue(success.topic.subject);
        this.f.category.setValue(success.topic.topic_category);
        this.f.content.setValue(success.posts[0].postDetail.content);
        this.CategoryName = success.topic.categoryName;
        this.createdBy = success.topic.createdBy;
        this.replies = success.posts.length - 1;
        this.createdByName = success.topic.createdByName;
        this.modifiedByName = success.topic.updatedByName;
        this.modifiedon = success.topic.modifiedOn;
        this.posts = success.posts;

      });
    AppComponent.HideLoader();
  }
  get f() {
    return this.ForumForm.controls;
  }
  onChange(e) {
  }
}

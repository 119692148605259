import { Component, OnInit, ViewChild } from '@angular/core';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';
import { SchApiService } from '../API/schapi.service';
import { map } from "rxjs/operators";
import { ScheduleColorDialog } from "../Core/Shared/ColorDialog/ColorDialog";

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../globals';

@Component({
  selector: 'SchStaff' ,
  templateUrl: './ScheduleStaff.component.html'
})
export class ScheduleStaffComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  DialysisUnits: any = [];
  pageRights = '';
  loading = true;
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDivision = -1;
  StartDate = new Date();
  ScheduleType = 1;
  DateList = [];
  SchList: any = [];
  PrevDate: Date;
  NextDate: Date;
  Note = '';
  exportfilename = '';
  ShowLock = false;
  IsLock = true;
  ColorList = [];
  HolidayList = [];
  searchText = 'Search Records';
  ShowSearch = false;
  colorDialogRef: MatDialogRef<ScheduleColorDialog>;
  blnShowNote = false;
  blnShowLock = false;
  blnShowDivision = false;
  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private schapi: SchApiService,
    // dialogRef: MatDialogRef<ConfirmationDialog>,
     public dialog: MatDialog,
    private auth: AuthService,

  ) {}

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      !this.IsLock
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log("res", res);
          return res;
        })
      );
    } else {
      return true;
    }

  }
  // -------------------------------------End------------------------------------------------

  ngOnInit() {
    this.SetPageRights();
    this.fillDialysisUnit();
     AppComponent.ShowLoader();
     this.schapi.GetAllScheduleColors()
      .subscribe(list => {
          this.ColorList = list;
        });
        this.schapi.GetallowScheduleEdit()
      .subscribe(list => {
         this.blnShowLock = list[0] === '1';
        });



     AppComponent.HideLoader();
  }

  filterScheduleItems(type) {
    return this.SchList.filter(x => x.userDesc === type);
}
  SetPageRights() {
    this.auth.GetPageRights('Staff Schedule').subscribe(list => {
      this.blnShowNote = list[actionType.addnotes] === '1';
    });
  }

  // Functions/Methods
  // -------------------------------------Start----------------------------------------------

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  fillDialysisUnit() {
    this.schapi.GetDialysisUnitDD()
      .subscribe(list => {
          this.DialysisUnits = list;
        });
  }

  DialysisUnitDropDownChanged() {
    this.StartDate = null;
    this.SetScheduleList();
  }

  PreviousList() {
    this.StartDate = this.PrevDate;
    this.SetScheduleList();
  }

  NextList() {
    this.StartDate = this.NextDate;
    this.SetScheduleList();
  }

  ChangeLock(status) {
    if (this.selectedDivision < 1) {
      this.IsLock = true;
      return;
    }
    if (this.IsLock !== status && status === true ) {
      // private string _scheduletype = string.Empty;
      //   private DateTime? _scheduledate = DateTime.Now;
      //   private Int64? _contactid = 0;
      //   private string _description = string.Empty;
      let schInsertList =  [];
      this.SchList.forEach((item, index) => {
        //console.log(item);
        //console.log(item.userid , item['date42']);
        for (let _i = 0; _i < 42; _i++) {

          let iItem = <any> {};
          iItem.scheduletype = this.ScheduleType;
          iItem.contactid = item.userid;
          iItem.description = item['date' + (_i + 1)];
          iItem.scheduledate = this.DateList[_i];
          iItem.createdby = this.currentUserId;
          iItem.modifiedby = this.currentUserId;

          schInsertList.push(iItem);

      }


      });
      let schData = <any> {};
      schData.notes =this.Note;
      schData.schedulefromdate = this.DateList[0];
      schData.scheduletodate = this.DateList[this.DateList.length - 1];
      schData.divisionid = this.selectedDivision;
      schData.scheduleusertypeid =  -1;
      schData.createdBy = this.currentUserId;
      schData.Schedulelst = schInsertList;
      //console.log(schData);
      this.schapi.CreateScheduleDetails(schData).subscribe(list => {
        // console.log(list);
     });
     let temp = this.SchList;
     this.SchList = [];
     this.SchList = temp;
    }
    this.IsLock = status;
  }
  SetColor(date, Value) {
    let color = '';
   if (this.HolidayList != undefined && this.HolidayList.length > 0) {
    if (this.HolidayList.filter(x => x.userDesc === date).length > 0) {
      color = '#ccc';
    }
   }
   if (this.ColorList.length > 0) {
     let tempVal = Value.substring(0, 1);
    let colorCls = this.ColorList.filter(x => x.symbol === tempVal);
    if (colorCls.length > 0) {
      color = colorCls[0].color;
    }
   }
  return color;
  }

  DisplayDesc(value) {
    if (this.ColorList.length > 0) {
      let tempVal = value.substring(0, 1);
     let colorCls = this.ColorList.filter(x => x.symbol === tempVal);
     if (colorCls.length > 0) {
       if ( value.length > 1 ) {
          return value.substring(1, value.length);
       } else {
         return '';
       }

     }
    }
    return value;//.replace(/\*/g, '');
  }

  ShowColors() {

    this.colorDialogRef = this.dialog.open(ScheduleColorDialog, {
      disableClose: true
    });
    this.colorDialogRef.componentInstance.ScheduleColors = this.ColorList;
  }

  Print() {

    let params = <any>{};
    AppComponent.ShowLoader();
    params.startDate = this.DateList[0];
    params.division = this.selectedDivision;
    params.ScheduleType = this.ScheduleType;
    params.scheduleusertypeid = -1;

    this.schapi.PrintSchedulePDF(params).subscribe(list => {

      this.exportfilename = list;
      //console.log(this.exportfilename);

      AppComponent.HideLoader();
      var openURL = Globals.WorkOrderPFDsUrl + this.exportfilename;
      // console.log(openURL);
      window.open(openURL);
   });


  }

  SetScheduleList() {
    AppComponent.ShowLoader();
    let params = <any>{};
    params.startDate = this.StartDate;
    params.division = this.selectedDivision;
    params.ScheduleType = this.ScheduleType;
    params.scheduleusertypeid = -1;

    this.schapi.SetList(params).subscribe(list => {
       this.DateList = list.datesList;
       this.PrevDate = list.prevDate;
       this.NextDate = list.nextDate;
       this.SchList = list.scheduleLst;
       this.Note = list.notes;
       this.HolidayList = list.Holidayslst;
     //  console.log('Notes',list.notes);
       AppComponent.HideLoader();
    });
  }

}

<form
    [formGroup]="TaskTypeForm"
    (ngSubmit)="executeTaskTypeCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
          Task Type
        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeTaskTypeCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">TaskTypeId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="TaskTypeId"
                                        [value]="f.TaskTypeId"
                                        formControlName="TaskTypeId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                                <label class="col-sm-2 control-label">
                                                    <span class="text-red inputrequired">*</span>Task Type:
                                                </label>
                                                <div class="col-sm-10"><input
                                                      type="text"
                                                      class="form-control mdInput"
                                                      formControlName="TaskTypeName"
                                                      name="TaskTypeName"
                                                      maxlength="50">
                                                  <span *ngIf="f.TaskTypeName.errors?.required && (f.TaskTypeName.dirty || f.TaskTypeName.touched ||  isValidFormSubmitted==false)"
                                                      [ngClass]="'error'">
                                                      Required!
                                                  </span>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                                <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Prefix:</label>
                                                <div class="col-sm-10">
                                                <input
                                                type="text"
                                                class="form-control mdInput"
                                                formControlName="Prefix"
                                                name="Prefix"
                                            >
                                            <span *ngIf="f.Prefix.errors?.required && (f.Prefix.dirty || f.Prefix.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span>
                                        </div>
                                            </div>

                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                                <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Border Color:</label>

                                                <div class="col-sm-3">
                                                    <select class="form-control mdInput" formControlName="Color" name="Color">
                                                        <option *ngFor="let datas of data" [ngStyle]="{'background-color': datas.value}" [value]="datas.value"  [style.color]="'white'" >{{datas.text}}</option>
                                                    </select>

                                                <!-- <input
                                                type="text"
                                                class="form-control mdInput"
                                                formControlName="Color"  [(colorPicker)]="ColorName"
                                                (colorPickerChange)="f.Color.setValue($event)" [value]="f.Color.value" [cpDisableInput]=true cpSaveClickOutside=true readonly
                                                name="Color"
                                            > -->
                                            <span *ngIf="f.Color.errors?.required && (f.Color.dirty || f.Color.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span>

                                        </div>
                                        <div class="col-sm-1" [style.height.px]="33" [ngStyle]="{'background-color': f.Color.value}"></div>
                                            </div>

                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                            <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Back Color:</label>

                                            <div class="col-sm-3">
                                                <select class="form-control mdInput" formControlName="BackColor" name="BackColor">
                                                    <option *ngFor="let datas of backdata" [ngStyle]="{'background-color': datas.value}" [value]="datas.value"  [style.color]="'black'" >{{datas.text}}</option>
                                                </select>

                                            <!-- <input
                                            type="text"
                                            class="form-control mdInput"
                                            formControlName="Color"  [(colorPicker)]="ColorName"
                                            (colorPickerChange)="f.Color.setValue($event)" [value]="f.Color.value" [cpDisableInput]=true cpSaveClickOutside=true readonly
                                            name="Color"
                                        > -->
                                        <span *ngIf="f.BackColor.errors?.required && (f.BackColor.dirty || f.BackColor.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'"
                                        >
                                            Required!
                                        </span>

                                    </div>
                                    <div class="col-sm-1" [style.height.px]="33" [ngStyle]="{'background-color': f.BackColor.value}"></div>
                                        </div>

                                    <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">

                                        </div>
                                        <div class="clearfix"></div>


                            </div>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeTaskTypeCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

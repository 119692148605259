import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Globals, actionType, attachmentFormModules } from 'src/app/globals';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { SingleAttachmentComponent } from 'src/app/Core/Shared/SingleAttachmentComponent/SingleAttachmentComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { ContactApiService } from 'src/app/API/contactapi.service';
import { ErrorCodeApiService } from 'src/app/API/errorCodeApi.service';
import { InvApiService } from 'src/app/API/invapi.service';

@Component({
  selector: 'ErrorCodeDetail',
  templateUrl: './ErrorCodeDetail.component.html'
})

export class ErrorCodeDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  isValidFormSubmitted = null;
  items: UntypedFormArray;

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachmentList: AttachmentComponent;

  ListURL = 'Admin/ErrorCodeSearch';
  loading = true;
  today = new Date();
  blnshowSave = true;
  blnshowDelete = false;
  title = "Error Codes or Alarms and Warning Messages";
  ManufacturerList = [];
  ModelList = [];
  CategoryList = [];


  ErrorCodeForm = new UntypedFormGroup({
    errorCodeId: new UntypedFormControl('0'),
    errorCode: new UntypedFormControl('', Validators.required),
    errorCodeDescription: new UntypedFormControl(''),
    categoryId: new UntypedFormControl('', Validators.required),
    Manufacture: new UntypedFormControl(''),
    Model: new UntypedFormControl(''),
    manufacturerId: new UntypedFormControl('', Validators.required),
    modelId: new UntypedFormControl('', Validators.required),
    createdOn: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    isDelete: new UntypedFormControl(''),
    tSteps: new UntypedFormArray([])
  });

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.ErrorCodeForm.dirty ||
        (this.notes !== undefined && this.notes.Note !== '') || this.attachmentList.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private errorCodeAPI: ErrorCodeApiService,
    private invapi: InvApiService,
    private contactApi: ContactApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder

  ) { }

  ngOnInit() {
    this.createSteps();
    AppComponent.ShowLoader();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.fillCategories();
      this.fillManufacturers();
      this.fillModelList();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.f.errorCodeId.setValue(params['id'] || '0');
        if (this.f.errorCodeId.value !== '0') {
          this.setModel();
        } else {
        }
      });
      this.SetErrorCoderights();

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  // Transactions Section
  createSteps() {

    this.items = this.ErrorCodeForm.get(
      "tSteps"
    ) as UntypedFormArray;

    for (var i = 1; i <= 10; i++) {
      this.items.push(this.formBuilder.group({
        troubleShottingStepsId: new UntypedFormControl(0),
        errorCodeId: new UntypedFormControl(this.f.errorCodeId.value),
        stepno: new UntypedFormControl(i.toString()),
        stepsDetail: new UntypedFormControl(''),
        createdBy: new UntypedFormControl(this.currentUserId),
        createdOn: new UntypedFormControl(this.today)
      }));
    }
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();

    if (this.notes) {
      this.notes.Note = '';
      this.notes.showHours = false;
    }

    if (this.attachmentList) {
      this.attachmentList.DocId = this.f.errorCodeId.value;
      this.attachmentList.moduleName =
        attachmentFormModules[attachmentFormModules.errorCode];
      this.attachmentList.UploadBy = this.currentUserId;
    }

  }

  get f() {
    return this.ErrorCodeForm.controls;
  }


  setModel() {
    AppComponent.ShowLoader();
    const ErrorCodeCreate = { Id: this.f.errorCodeId.value };

    this.errorCodeAPI.GetErrorCodeByID(ErrorCodeCreate).subscribe(
      success => {

        if (success) {
          this.f.errorCodeDescription.setValue(success.errorCodeDescription);
          this.f.errorCodeId.setValue(success.errorCodeId);
          this.f.errorCode.setValue(success.errorCode);
          this.f.Model.setValue(success.model);
          this.f.manufacturerId.setValue(success.manufacturerId);
          this.f.categoryId.setValue(success.categoryId);
          this.f.modelId.setValue(success.modelId);

          this.fillManufacturers().then(() => {
            this.f.manufacturerId.setValue(success.manufacturerId);
            this.fillModelList().then(() => {
              this.f.manufacturerId.setValue(success.manufacturerId);
              this.f.categoryId.setValue(success.categoryId);
              this.f.modelId.setValue(success.modelId);
              this.f.Manufacture.setValue(success.manufacture);
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }, () => {
            AppComponent.addAlert("Error", 'error');
            AppComponent.HideLoader();
          });


          this.f.createdOn.setValue(success.createdon);
          this.f.createdBy.setValue(success.createdby);
          this.f.isDelete.setValue(success.isdelete);



          this.errorCodeAPI
            .GetTroubleShootingStepsByErrorCodeId(ErrorCodeCreate)
            .subscribe(troubleshootData => {

              var steps = this.ErrorCodeForm.get(
                "tSteps"
              )["controls"];

              steps.forEach((step, newItemKey) => {
                troubleshootData.forEach((data, newKey) => {
                  if (step.controls.stepno.value == data.stepno) {
                    step.controls.stepsDetail.setValue(data.stepsdetail);
                    step.controls.errorCodeId.setValue(data.errorCodeId);
                  }
                });
              });
            });


          if (this.notes) {
            this.notes.Note = '';
            this.notes.showHours = false;
            this.errorCodeAPI.GetErrorCodeNotes(ErrorCodeCreate).subscribe(list => {
              this.notes.NoteList = list;
            });
          }

          if (this.attachmentList) {
            this.attachmentList.clearQueue();
            this.errorCodeAPI
              .GetErrorCodesAttachments(ErrorCodeCreate)
              .subscribe(list => {
                this.attachmentList.PrevAttachments = list;

              });
          }

          AppComponent.HideLoader();
        }
        else {
          AppComponent.HideLoader();
        }
      },
      error => {

        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  fillModelList() {

    return new Promise((resolve, reject) => {

      const manufacturerId = { manufacturerId: this.f.manufacturerId.value, categoryId : this.f.categoryId.value };
      this.invapi
        .FillModels(manufacturerId)
        .subscribe(list => {
          this.ModelList = list;
          this.f.modelId.setValue('');
          this.f.Model.setValue('');
          resolve(true);
        });

    });
  }

  fillManufacturers() {

    return new Promise((resolve, reject) => {
      const categoryId = { Id: this.f.categoryId.value };

      this.invapi.FillManufacturerList(categoryId).subscribe(list => {
        this.ManufacturerList = list;
        this.fillModelList().then(() => {
          this.f.manufacturerId.setValue('');
          this.f.Manufacture.setValue('');

          resolve(true);
        }, () => {
          AppComponent.addAlert("Error", 'error');
          AppComponent.HideLoader();
          reject();
        });
      }, error => {
        reject();
      });


    });
  }



  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }



  onDateChanged(event: IMyDateModel): void { }

  executeErrorCodeCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.ErrorCodeForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const ErrorCodeCreate = this.prepareCreateViewModel();
    if (ErrorCodeCreate.errorCodeId && ErrorCodeCreate.errorCodeId === '0') {
      this.errorCodeAPI.CreateErrorCodeDetails(ErrorCodeCreate).subscribe(
        success => {
          console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.f.errorCodeId.setValue(success.errorCodeId);
            this.setModel();
          }
        },
        error => {

          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.errorCodeAPI.UpdateErrorCodeDetails(ErrorCodeCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.setModel();
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const ErrorCodeCreate = <any>{};
    ErrorCodeCreate.errorCodeId = this.f.errorCodeId.value;
    ErrorCodeCreate.errorCode = this.f.errorCode.value;
    ErrorCodeCreate.errorCodeDescription = this.f.errorCodeDescription.value;
    ErrorCodeCreate.isDelete = this.f.isDelete.value;

    if (this.f.modelId.value) {
      ErrorCodeCreate.modelId = this.f.modelId.value;
      const modelObj = this.ModelList.filter(
        x => x.value == this.f.modelId.value
      );
      if (modelObj && modelObj.length > 0)
        ErrorCodeCreate.Model = modelObj[0].text;
    }

    if (this.f.manufacturerId.value) {
      ErrorCodeCreate.manufacturerId = this.f.manufacturerId.value;
      const manf = this.ManufacturerList.filter(
        x => x.value == this.f.manufacturerId.value
      );
      if (manf && manf.length > 0)
        ErrorCodeCreate.Manufacture = manf[0].text;
    }

    ErrorCodeCreate.createdOn = this.f.createdOn.value;
    ErrorCodeCreate.createdBy = this.f.createdBy.value ? this.f.createdBy.value : this.currentUserId;
    ErrorCodeCreate.modifiedBy = this.currentUserId;

    if (this.notes)
      ErrorCodeCreate.notes = this.notes.Note;
    if (this.attachmentList)
      ErrorCodeCreate.attachmentDetails = this.attachmentList.GenerateAttachmentList();

    var steps = this.ErrorCodeForm.get(
      "tSteps"
    )["controls"];


    steps.forEach((step, newItemKey) => {
      step.controls.errorCodeId.setValue(this.f.errorCodeId.value);
      step.controls.createdOn.setValue(this.today);
      step.controls.createdBy.setValue(this.currentUserId);
    });

    ErrorCodeCreate.troubleshootingList = this.f.tSteps.value;

    return ErrorCodeCreate;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const ErrorCodeObj = { Id: this.f.errorCodeId.value };
        this.errorCodeAPI.DeleteErrorCodeDetails(ErrorCodeObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'ErrorCode detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }



  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  SetErrorCoderights() {
    //   this.auth.GetPageRights('User ErrorCode Form').subscribe(list => {
    //     if (this.f.errorCodeId.value !== '0') {
    //       this.blnshowSave = list[actionType.edit] === '1';
    //       // this.blnshowDelete = list[actionType.delete] === '1';
    //     } else {
    //       this.blnshowSave = list[actionType.create] === '1';
    //       // this.blnshowDelete = false;
    //     }
    //   });

    //   if (this.userErrorCode == 'Administrator' && this.f.errorCodeId.value != '0') {
    //     this.blnshowDelete = true;
    //   }
  }
}

import { Component, ViewChild } from '@angular/core';
import { WPApiService } from '../../../API/wpapi.service';
import { DMApiService } from '../../../API/dmapi.service';
import { TableHeadsService } from '../../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../../Core/pageheader.component';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  selector: 'WPDailySearch',
  templateUrl: './WPDailySearch.component.html',

})
export class WPDailySearchComponent {

  constructor( private dmapi:DMApiService,private wpapi:WPApiService,
    private commonapi : CommonApiService,
    private router:Router, private dataGridService: TableHeadsService){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];

  Statuses = [
    { value: "", text: "Select" },
    { value: "Open", text: "Open" },
    { value: "Completed", text: "Completed" }
  ];
  selectedDivision = "";
  selectedStatus="";
  EventId="";
  FromDate;
  ToDate;
  tableHeaders;
  blnDirtySearch = false;
  page = new Page();
    rows ;
  AddUrl = "/WaterProcess/Daily/WPDailyDetail";

  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "DESC";
  pageSize = 25;
  ShowSearch=false;
  title = "Daily Water Record";
  searchText="Search Records"
  defaultSort;
  isPageLoaded =false;

  ngOnInit() {
            this.dataGridService.clearHeader();
            this.dataGridService.addHeader("#", "repairid","4");
            this.dataGridService.addHeader("Event Code","eventId", "23");
            this.dataGridService.addHeader("Date", "createdon", "23");
            this.dataGridService.addHeader("Division", "divisionName", "23");
            this.dataGridService.addHeader("Status", "status", "23");
            this.tableHeaders = this.dataGridService.setTableHeaders();
            this.defaultSort = this.dataGridService.setDefaultSort("default",true);

            this.executeWPDailySearch();

            this.fillDialysisUnit();
            this.CheckDirtySearch();
           // this.fillStatus();


  }

  onSearchShowChange(value)
  {
    this.ShowSearch=value;
  }

changeSorting(column){

  if (column =="#")
  {
      return;
  }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);

    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeWPDailySearch();

};

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

executeWPDailySearch()
{
  AppComponent.ShowLoader()
  this.pageheader.isSearchOpen=false;
  this.pageheader.searchText="Search Records";
  this.ShowSearch=false;
  this.searchText="Search Records";
  this.CheckDirtySearch();
  var  wpdailysearch = this.prepareSearch();
  this.wpapi.GetWaterprocessDailyRecords(wpdailysearch).subscribe(pagedData  =>
    {this.page.totalElements= pagedData.totalRows;
      this.page.totalPages= pagedData.totalPages;
     // console.log(pagedData);
    this.rows=pagedData.waterprocessdailyDetails;
    //console.log(this.rows);
    this.isPageLoaded=true;
    AppComponent.HideLoader();
  }
  ,
          error=>
          {
            AppComponent.addAlert(error.message,"error");
            AppComponent.HideLoader();
          }
  );
}

prepareSearch() {

  var wpdailysearch =<any>{};
  wpdailysearch.eventid = this.EventId;
  if (this.FromDate != undefined && this.FromDate !="" )
  {
    wpdailysearch.fromdate = Globals.GetAPIDate( this.FromDate.jsdate);

  }
  if (this.ToDate != undefined && this.ToDate !="" )
  {
    wpdailysearch.todate = Globals.GetAPIDate( this.ToDate.jsdate);
  }


  if (this.selectedDivision != undefined && this.selectedDivision !="" && this.selectedDivision !="0" ) {
    wpdailysearch.duid = this.selectedDivision;
  }



  if (this.selectedStatus != undefined && this.selectedStatus != "") {
    wpdailysearch.status = this.selectedStatus;
  }

  wpdailysearch.CurrentPageNumber = this.currentPageNumber;
  wpdailysearch.SortExpression = this.sortExpression;
  wpdailysearch.SortDirection = this.sortDirection;
  wpdailysearch.PageSize = this.pageSize;
 // console.log(wpdailysearch);
  return wpdailysearch;

}

  setPage(){
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber=this.page.pageNumber;
    if(this.isPageLoaded==true)
    {
  //  console.log("setpage");
    this.executeWPDailySearch();
    }
  }
}
 _onRowClick(row) {

      this.router.navigate(['WaterProcess/Daily/WPDailyDetail'], { queryParams: { id: row} });

}
public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect:false
};

onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit()
  {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);
    this.commonapi.FillDivision().subscribe(list => this.DialysisUnits = list);

  }

  // fillStatus()
  // {
  //  // this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);

  // }

  executeWPDailySearchClear()
  {
    this.selectedDivision = "";
    this.selectedStatus="";
    this.EventId="";
    this.FromDate="";
    this.ToDate="";

    this.executeWPDailySearch();
  }
  CheckDirtySearch()
  {
    this.blnDirtySearch= false;

    if(
      this.selectedDivision != ""
    || this.selectedStatus  != ""
    || (this.FromDate != null && this.FromDate != "")
    || (this.ToDate != null && this.ToDate != ""))

    {
      this.blnDirtySearch = true;

    }
   //  console.log(this.blnDirtySearch);
    return this.blnDirtySearch;



    // if(this.FromDate != null && this.FromDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }
    // if(this.ToDate != null && this.ToDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }

  }

}

export class Page {
  //The number of elements in the page
  size: number = 25;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

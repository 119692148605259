import { Component, ViewChild } from '@angular/core';
import { WPApiService } from '../../API/wpapi.service';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from '../../API/commonapi.service.';
@Component({
  selector: 'GRWPDailyDemoSearch',
  templateUrl: './GRWPDailySearch.component.html',
})
export class GRWPDailyDemoSearchComponent {

  constructor( private dmapi:DMApiService,private wpapi:WPApiService,private router:Router, private dataGridService: TableHeadsService,private commonapi : CommonApiService
    ){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];
    Equipments=[];
  Users=[];
  Parts=[];
  Statuses=[];
  Patients=[];
  DMRepailDetail =[];
  selectedDivision = "";
  selectedEquipment = "";
  selectedPart = "";
  //selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  EventId="";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef="";
  OutFromDate;
OutToDate;
tableHeaders;
page = new Page();
rows ;
AddUrl = "/WaterProcess/Daily/DEWPDailyDetail";
workOrderRefNo="";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch=false;
  title = "Water Process Daily Record Details";
  searchText="Search Records"
defaultSort;

  ngOnInit() {
    //AppComponent.loading=true;

    this.dataGridService.clearHeader();

            this.dataGridService.addHeader("#", "EventId","4");
            this.dataGridService.addHeader("Event ID","eventId", "10");
            this.dataGridService.addHeader("Division", "divisionName", "40");
            this.dataGridService.addHeader("Form Type", "formType", "20");
            this.dataGridService.addHeader("Date", "RecordDate", "10");
            this.dataGridService.addHeader("Status", "status", "10%");
            this.tableHeaders = this.dataGridService.setTableHeaders();
            this.defaultSort = this.dataGridService.setDefaultSort("default",true);

    this.executeDmrepairSearch();
    this.fillDialysisUnit();
    this.fillEquipment();
    this.fillStatus();
    this.CheckDirtySearch();
  }

  onSearchShowChange(value)
  {
    this.ShowSearch=value;
  }

changeSorting(column){
  // console.log(column);
  if (column =="#")
  {
      return;
  }
  //console.log(this.tableHeaders);
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDmrepairSearch();

};

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

  executeDmrepairSearch()
{
  AppComponent.ShowLoader()
  this.pageheader.isSearchOpen=false;
  this.pageheader.searchText="Search Records";
  this.ShowSearch=false;
  this.searchText="Search Records";
  this.CheckDirtySearch();
  var  dmrepairsearch = this.prepareSearch();
  this.wpapi.GRWaterprocessDailyrecords(dmrepairsearch).subscribe(pagedData  =>
    {this.page.totalElements= pagedData.totalRows;
      this.page.totalPages= pagedData.totalPages;
     console.log(pagedData);
    this.rows=pagedData.recordDetails;
    AppComponent.HideLoader()
  }
  ,
          error=>
          {
            AppComponent.addAlert(error.message,"error");
            AppComponent.HideLoader();
          }
  );
}

prepareSearch() {

  var dmrepairsearch =<any>{};

  if (this.FromDate != undefined && this.FromDate !="" )
  {
    dmrepairsearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
  }
  if (this.ToDate != undefined && this.ToDate !="" )
  {
    dmrepairsearch.todate = Globals.GetAPIDate( this.ToDate.jsdate);
  }

  if (this.selectedDivision != undefined && this.selectedDivision !="" ) {
      dmrepairsearch.Division = this.selectedDivision;
  }

  if (this.selectedStatus != undefined && this.selectedStatus != "") {
      dmrepairsearch.Status = this.selectedStatus;
  }

  dmrepairsearch.currentPageNumber = this.currentPageNumber;
  dmrepairsearch.sortExpression = this.sortExpression;
  dmrepairsearch.sortDirection = this.sortDirection;
  dmrepairsearch.pageSize = this.pageSize;
  // console.log(dmrepairsearch);
  return dmrepairsearch;

}

  setPage(){
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber=this.page.pageNumber;
    //this.page.pageNumber = pageInfo.offset;
    console.log('set page')
    this.executeDmrepairSearch();
    }
}
 _onRowClick(row,formtype) {
      this.router.navigate(['WaterProcess/Daily/DEWPDailyDetail'], { queryParams: { id: row, formType : formtype} });
}


public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect:false
};

onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit()
  {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);
    this.commonapi.FillDivision().subscribe(list => this.DialysisUnits = list);
  }

  fillEquipment()
  {
   // this.dmapi.GetEquipmentDD(0).subscribe(list => this.Equipments=list);

  }

  fillStatus()
  {
    //this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.Statuses= [
      { value: "", text: "Select" },
      { value: "In Progress", text: "In Progress" },
      { value: "Incompleted", text: "Incompleted" },
      { value: "Completed", text: "Completed" }
    ];

  }

  executeWPDailySearchClear()
  {
    this.selectedDivision = "";
    this.selectedEquipment = "";
    this.selectedStatus = "";
    this.FromDate= "";
    this.ToDate="";


    this.executeDmrepairSearch();

  }

  CheckDirtySearch()
  {
    this.blnDirtySearch= false;
    if(
      this.selectedDivision != ""
    || this.selectedEquipment  != ""
    || this.selectedStatus != ""
    || (this.FromDate != null && this.FromDate != "")
    || (this.ToDate != null && this.ToDate != ""))
    {
      this.blnDirtySearch = true;

    }
    return this.blnDirtySearch;
  }

}
export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<section class="content-header">
  <h1>
    {{title}}
    <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
  </h1>

</section>
<section class="content">
  <section>
    <div class="row">
      <div class="box  box-primary">
        <PageHeader [title]="title" [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
          [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)" [showSearch]="false" [showMainListing]="false">
        </PageHeader>
        <!-- <div id="divSearch row" *ngIf="ShowSearch">
                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">BMR Home Unit:</label>
                                        <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="dialysisStationName">
                                                        <option value="">Select</option>
                                                        <option value="{{item.dialysisStationName}}" *ngFor="let item of StationsList">
                                                            {{item.dialysisStationName}}
                                                        </option>
                                                    </select>
                                        </div>
                                    </div>
                                      <div class="col-sm-6">

                                            <label class="col-sm-6 control-label">Status:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="status" name="status">
                                                    <option value="{{item.value}}" *ngFor="let item of StatusList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>


                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">HOME DM:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="dmEquipmentName">
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of dmEquipmentList">
                                                    {{item.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Home RO:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="roEquipmentName">
                                                    <option value="">Select</option>
                                                    <option value="{{item.text}}" *ngFor="let item of roEquipmentList">
                                                        {{item.text}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class=" clearfix">
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-body row">
                                <div class="col-sm-12">
                                    <div class="col text-center">
                                        <button class="btn btn-primary" id="btnSearchDetail"
                                            (click)="executeDialysisStationsSearch()">
                                            <span><i class="fa fa-search"></i></span>
                                            <b>&nbsp;Search</b>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> -->
        <div class="clearfix"></div>
        <div class="box-body" id="dvboxbody">
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-9 pull-right">
                <!-- <div class="col text-right">
                  <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                    [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                  </ngb-pagination>
                </div> -->
              </div>
              <div class="col-sm-3">
                <div class="col text-left noofrecords">
                  <span><b> <i>{{page.totalElements}}
                        {{page.totalElements > 1 ? 'Records' : 'Record'}}
                        Found.</i></b></span>
                  <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeDialysisStationsSearchClear()">
                      (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                      <b>Clear Search</b>
                    </a>)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
              <thead>
                <tr>
                  <th *ngFor="let tableHeader of tableHeaders" [ngClass]="setSortIndicator(tableHeader.Label)"
                    [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                    {{tableHeader.Label}}</th>
                </tr>
              </thead>
              <tbody>
                <tr (click)="_onRowClick(-1)" class="pointer" [hidden]="(blnDirtySearch || currentPageNumber != 1)">
                  <td>*</td>
                  <td>HOME POOL</td>
                  <!-- <td></td>
                  <td></td> -->
                </tr>
                <tr *ngFor="let dialysisStation of rows;let index=index;"
                  (click)="_onRowClick(dialysisStation.dialysisUnitId)" class="pointer">

                  <td>
                    {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                  </td>
                  <td>{{dialysisStation.dialysisStationName}}</td>
                  <!-- <td>{{dialysisStation.dmEquipmentName}} </td>
                  <td>{{dialysisStation.roEquipmentName}} </td> -->
                  <!-- <td>{{dialysisStation.createdByName}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-9 pull-right">
                <div class="col text-right" style="display: none;">
                  <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                    [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                  </ngb-pagination>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="col text-left noofrecords">
                  <span><b> <i>{{page.totalElements}}
                        {{page.totalElements > 1 ? 'Records' : 'Record'}}
                        Found.</i></b></span>
                  <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeDialysisStationsSearchClear()">
                      (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                      <b>Clear Search</b>
                    </a>)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

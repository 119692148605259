
import { Component, ViewChild } from '@angular/core';
import { DivisionApiService } from '../../API/divisionapi.service';
import { DMApiService } from '../../API/dmapi.service';

import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';


@Component({
  selector: 'DivisionSearch',
  templateUrl: './DivisionSearch.component.html',

})
export class DivisionSearchComponent {

  DivisionDetails = [];
  constructor(private divisionapi: DivisionApiService, 
    private dmapi :DMApiService,
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/Admin/Division";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "divisionName";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch = false;
  title = "Division";
  searchText = "Search Records"
  defaultSort;



  //Search Options //
  DivisionName = 'Select';
  Address = '';
  City= '';
  State = '';
  Country='';
  Phone='';

  CountryList = [];
  StateList = [];
  DivisionList=[];
    //Search Options //

  ngOnInit() {
    AppComponent.loading=true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#","divisionName", "4");
    this.dataGridService.addHeader("Division Name", "divisionName", "10");
    this.dataGridService.addHeader("Address", "address1", "10");
    this.dataGridService.addHeader("City", "city", "10");
    this.dataGridService.addHeader("State", "state", "10");
    this.dataGridService.addHeader("Country", "country", "10");
    this.dataGridService.addHeader("Phone", "phone", "10");
    // this.dataGridService.addHeader("Tech", "createdByName", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("Division Name", false);
    this.CheckDirtySearch();
    this.fillDropDowns();
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDivisionSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDivisionSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var divisionSearch = this.prepareSearch();

    this.divisionapi.GetDivisionSearchDetails(divisionSearch).subscribe(pagedData => {

      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.divisionDetailList;
      AppComponent.HideLoader()
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {

    var divisionSearch = <any>{};
    if(this.DivisionName != 'Select')
      divisionSearch.divisionName = this.DivisionName;
    else
      divisionSearch.divisionName = '';

    divisionSearch.address = this.Address;
    divisionSearch.city = this.City;

    if (this.State !== undefined && this.State !== '' ) {
      divisionSearch.state = this.State;
  }

  if (this.Country !== undefined && this.Country !== '' ) {
    divisionSearch.country = this.Country;
  }
    divisionSearch.phone = this.Phone;

    divisionSearch.currentPageNumber = this.currentPageNumber;
    divisionSearch.sortExpression = this.sortExpression;
    divisionSearch.sortDirection = this.sortDirection;
    divisionSearch.pageSize = this.pageSize;
    return divisionSearch;

  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDivisionSearch();
  }

  _onRowClick(row) {

    this.router.navigate(['Admin/Division'], { queryParams: { id: row } });
  }



  //Search & Select//

  fillDropDowns() {
    this.fillCountry();
    this.fillDivisions();
  }

  fillDivisions() {
    this.dmapi.GetDialysisUnitDD().subscribe(list => this.DivisionList = list);
  }

  fillCountry() {
    this.divisionapi.FillCountry().subscribe(list => this.CountryList = list);
  }

  fillStates() {

    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === this.Country
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.divisionapi
        .FillState(CountryIds)
        .subscribe(list => (this.StateList = list));
    } else {
      this.StateList = [];

    }
  }



  executeDivisionSearchClear() {

    this.DivisionName = 'Select';
    this.Address = '';
    this.State = '';
    this.Country = '';
    this.City = '';
    this.Phone = '';

    this.executeDivisionSearch();
  }


  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.DivisionName != "" && this.DivisionName != 'Select')
      || this.Address != ""
      || this.City != ""
      || this.Country != ""
      || this.State != ''
      || this.Phone != '') {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  //Search & Select //



}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<style>
  .nonePointerEvents {
    pointer-events: none;
  }

  .allPointerEvents {
    pointer-events: all;
  }
</style>
<form
  [formGroup]="GRDailyDetailForm"
  (ngSubmit)="executeGRWPDailyCreateUpdate('', '')"
  #mainform
  novalidate
>
  <!-- <RepairPart></RepairPart> -->
  <section class="content-header">
    <h1>Water Process Daily Record <sup class="beta">[demo]</sup></h1>
  </section>
  <section class="content">
    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary">
        <PageHeader
          title="Water Process Daily Record"
          [showNote]="false"
          (addNoteClick)="notes.AddNote()"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeGRWPDailyCreateUpdate('', '')"
          [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()"
          #pageheader
        ></PageHeader>

        <div class="col-md-12 box">
          <div class="col-sm-12 box-row hidden">
            <label class="col-sm-2 control-label"
              >GRWaterprocessDailyrecordsID</label
            >

            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control"
                name="GRWaterprocessDailyrecordsID"
                [value]="f.GRWaterprocessDailyrecordsID"
                formControlName="GRWaterprocessDailyrecordsID"
              />
            </div>
          </div>
          <div class="col-sm-12 box-row hidden">
            <label class="col-sm-2 control-label"
              >GRWaterProcessailyRecordMain3EId</label
            >

            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control"
                name="GRWaterProcessailyRecordMain3EId"
                [value]="f.GRWaterProcessailyRecordMain3EId"
                formControlName="GRWaterProcessailyRecordMain3EId"
              />
            </div>
          </div>
          <div class="box ibox box-info">
            <div class="box-body">
              <div class="col-sm-12 box-row">
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Event ID:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText"> {{ eventId }}</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Form Date:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText">
                      {{
                        f.formDate.value.jsdate | date: "dd/MM/yyyy HH:mm"
                      }}</label
                    >
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Created By:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText"> {{ UserName }}</label>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>

              <div class="col-sm-12 box-row">
                <label class="col-sm-2 control-label">
                  <span class="text-red inputrequired">*</span>Division:</label
                >
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut
                  "
                >
                  <label class="readOnlyText">{{ DUName }}</label>
                </div>
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value == 0 && !formLockedOut
                  "
                >
                  <select
                    class="form-control mdInput"
                    formControlName="selectedDialysisUnits"
                    name="selectedDialysisUnits"
                    (change)="DialysisUnitDropDownChanged()"
                  >
                    <option
                      value="{{ item.value }}"
                      *ngFor="let item of DialysisUnits"
                      >{{ item.text }}</option
                    >
                  </select>
                  <span
                    *ngIf="
                      f.selectedDialysisUnits.errors?.required &&
                      (f.selectedDialysisUnits.dirty ||
                        f.selectedDialysisUnits.touched ||
                        isValidFormSubmitted == false)
                    "
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                  <span
                    *ngIf="f.selectedDialysisUnits.errors?.DivisionRecExists"
                    [ngClass]="'error'"
                  >
                    Daily record already exist for today.
                  </span>
                </div>
              </div>

              <div class="col-sm-12 box-row" *ngIf="isMain3EForm || isMain7Form">
                <label class="col-sm-2 control-label">
                  <span class="text-red inputrequired">*</span>Daily
                  Main:</label
                >
                <div
                  class="col-sm-5"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut
                  "
                >
                  <label class="readOnlyText">{{ MainDUName }}</label>
                </div>
                <div
                  class="col-sm-5"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value == 0 && !formLockedOut
                  "
                >
                  <select
                    class="form-control mdInput"
                    formControlName="selectedMainDivision"
                    name="selectedMainDivision"
                    (change)="MainDivisionDropDownChanged()"
                  >
                    <option
                      value="{{ item.value }}"
                      *ngFor="let item of MainDivisions"
                      >{{ item.text }}</option
                    >
                  </select>
                  <span
                    *ngIf="
                      f.selectedMainDivision.errors?.required &&
                      (f.selectedMainDivision.dirty ||
                        f.selectedMainDivision.touched ||
                        isValidFormSubmitted == false)
                    "
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                  <span
                    *ngIf="f.selectedMainDivision.errors?.DivisionMainRecExists"
                    [ngClass]="'error'"
                  >
                  {{ f.selectedMainDivision.value }} Daily record already exist for today.
                  </span>
                </div>
              </div>

              <div class="col-sm-12 box-row"  *ngIf="f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut">
                <label class="col-sm-2 control-label"> Status:</label>
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut">
                  <label class="readOnlyText">{{ Status }}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- *ngIf="isMain3EForm || isMain7Form" -->
          <div >
            <div class="box ibox box-info">
              <div class="box-header with-border">
                <h3 class="box-title">Daily</h3>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwoAM">
                  <h4 class="panel-title">
                    <a
                      [ngClass]="collapseTwoAMClass"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwoAM"
                      aria-expanded="collapseTwoAMariaexpanded"
                      aria-controls="collapseTwoAM"
                      id="amPanel"
                    >
                      <div class="box-header with-border">
                        <h3 class="box-title container">
                          AM
                          <span *ngIf="isAMComplted">
                            : {{ this.AMUserName }} -
                            {{ AMDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                            Completed</span
                          >
                        </h3>
                      </div>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwoAM"
                  [ngClass]="collapseTwoAMpanelClass"
                  role="tabpanel"
                  aria-labelledby="headingTwoAM"
                >
                  <div class="panel-body">
                    <div class="box-body">
                      <div class="row" *ngIf="isMain3EForm || isMain7Form">
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-3">
                            <span class="text-red inputrequired">*</span>
                            <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value > 0 ||
                              formLockedOut
                            "
                          >
                            <label
                              class="readOnlyText"
                              [style.color]="
                                f.AmANLSeqAlaram.value == 'NO' ? 'red' : 'black'
                              "
                              >{{ f.AmANLSeqAlaram.value }}</label
                            >
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <select
                              [style.color]="
                                f.AmANLSeqAlaram.value == 'NO' ? 'red' : 'black'
                              "
                              class="form-control mdInput"
                              formControlName="AmANLSeqAlaram"
                              name="AmANLSeqAlaram"
                              (change)="AmANLSeqAlaramDropDownChanged()"
                            >
                              <option value="" style="color:black"
                                >Select</option
                              >
                              <option value="Yes" style="color:black"
                                >Yes</option
                              >
                              <option value="NO" style="color:red">NO</option>
                            </select>
                          </div>
                          <div
                            class="col-sm-1"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <span
                              *ngIf="
                                f.AmANLSeqAlaram.errors?.required &&
                                (f.AmANLSeqAlaram.dirty ||
                                  f.AmANLSeqAlaram.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-5"
                            *ngIf="
                              f.AmANLSeqAlaram.value == 'NO' && !isAMComplted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                            <select
                              style="color:red"
                              class="form-control mdInput"
                              formControlName="DailyAMFailureDisplay"
                              name="DailyAMFailureDisplay"
                            >
                              <option value="" style="color:red">Select</option>
                              <option value="Bank 1" style="color:red"
                                >Bank 1</option
                              >
                              <option value="Bank 2" style="color:red"
                                >Bank 2</option
                              >
                              <option value="Bank 3" style="color:red"
                                >Bank 3</option
                              >
                              <option value="Bank 4" style="color:red"
                                >Bank 4</option
                              >
                              <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                >Bank 5</option
                              >
                            </select>
                            <span
                              *ngIf="
                                f.DailyAMFailureDisplay.errors?.required &&
                                (f.DailyAMFailureDisplay.dirty ||
                                  f.DailyAMFailureDisplay.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut) &&
                              f.AmANLSeqAlaram.value == 'NO' &&
                              isAMComplted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong
                            ><label class="readOnlyText" style="color:red">{{
                              f.DailyAMFailureDisplay.value
                            }}</label>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div
                          class="box-row col-sm-12"
                          *ngIf="f.AmANLSeqAlaram.value == 'NO'"
                        >
                          <div
                            formArrayName="AMSeqALM"
                            *ngFor="
                              let item of GRDailyDetailForm.get('AMSeqALM')
                                .controls;
                              let i = index
                            "
                            class="form-group"
                          >
                            <div [formGroupName]="i">
                              <div class="col-sm-4"></div>
                              <div class="col-sm-8">
                                <label class="col-sm-8 control-label">
                                  Verified Total Chlorine for Bank 0{{
                                    i + 1
                                  }}:</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                      formLockedOut) &&
                                    isAMComplted
                                  "
                                >
                                  <label
                                    class="readOnlyText"
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramAM
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramAM
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    >{{
                                      item.controls.VerifiedBankAlaramAM.value
                                    }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value ==
                                      0 &&
                                      !formLockedOut) ||
                                    !isAMComplted
                                  "
                                >
                                  <select
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramAM
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramAM
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    class="form-control mdInput"
                                    formControlName="VerifiedBankAlaramAM"
                                    name="VerifiedBankAlaramAM"
                                  >
                                    <option value="Not Tested" style="color:red"
                                      >Not Tested</option
                                    >
                                    <option value="Pass" style="color:black"
                                      >Pass</option
                                    >
                                    <option value="Fail" style="color:red"
                                      >Fail</option
                                    >
                                  </select>
                                  <!-- <span *ngIf="item.controls.VerifiedBankAlaramAM.errors?.required && (item.controls.VerifiedBankAlaramAM.dirty || item.controls.VerifiedBankAlaramAM.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'">
                                                            Required!
                                                        </span> -->
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="clearfix" *ngIf="f.AmANLSeqAlaram.value  == 'NO' && !isAMComplted"></div>
                                        <div class="box-row col-sm-12" *ngIf="f.AmANLSeqAlaram.value  == 'NO' && !isAMComplted">
                                                <div class="col-sm-3">
                                                </div>
                                                <div class="col-sm-3">
                                                    </div>
                                                    <div class="col-sm-2">
                                                        </div>
                                                <div class="col-sm-3" >

                                                        <button type="button" class="btn btn-primary" (click)="addAMAlaramClick()" *ngIf="GRDailyDetailForm.get('AMSeqALM').controls.length <= 4 && !isAMComplted">
                                                                + ADD Additional Bank Alarm
                                                            </button>

                                                    </div>
                                                    <div class="col-sm-6"></div>
                                        </div> -->
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-3">
                            <span class="text-red inputrequired">*</span
                            ><strong>Verify RO Presently In Use: </strong>
                          </div>
                          <div
                            class="col-sm-5"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value > 0 ||
                              formLockedOut
                            "
                          >
                            <label class="readOnlyText" style="color:black">{{
                              VerifyingCWWPROText
                            }}</label>
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <select  *ngIf="isMain3EForm"
                              class="form-control mdInput"
                              formControlName="selectedVerifyROPresently"
                              name="selectedVerifyROPresently"
                              (change)="VerifyROPresentlyDropDownChanged()"
                            >
                              <option
                                value="{{ item.value }}"
                                *ngFor="let item of VerifyROPresentMAIN3E"
                                >{{ item.text }}</option
                              >
                            </select>

                            <select *ngIf="isMain7Form"
                              class="form-control mdInput"
                              formControlName="selectedVerifyROPresently"
                              name="selectedVerifyROPresently"
                              (change)="VerifyROPresentlyDropDownChanged()"
                            >
                              <option
                              value="{{ item.value }}"
                              *ngFor="let item of VerifyROPresentMAIN7"
                              >{{ item.text }}</option
                            >
                            </select>
                          </div>
                          <div
                            class="col-sm-3"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <span
                              *ngIf="
                                f.selectedVerifyROPresently.errors?.required &&
                                (f.selectedVerifyROPresently.dirty ||
                                  f.selectedVerifyROPresently.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-3">
                            <span class="text-red inputrequired">*</span
                            ><strong>Verify </strong> &nbsp;
                            <label
                              class="readOnlyText"
                              style="color:black"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                              >{{ VerifyROlable }}</label
                            >
                          </div>
                          <div
                            class="col-sm-3"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value > 0 ||
                              formLockedOut
                            "
                          >
                            <label class="readOnlyText" style="color:black"
                              >{{ VerifyROlable }} :
                            </label>
                            <label
                              class="readOnlyText"
                              [style.color]="
                                f.VerifyROReverse.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                            >
                              {{ f.VerifyROReverse.value }}</label
                            >
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <select
                              [style.color]="
                                f.VerifyROReverse.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                              class="form-control mdInput"
                              formControlName="VerifyROReverse"
                              name="VerifyROReverse"
                            >
                              <option value="" style="color:black"
                                >Select</option
                              >
                              <option value="Yes" style="color:black"
                                >Yes</option
                              >
                              <option value="NO" style="color:red">NO</option>
                            </select>
                          </div>
                          <div
                            class="col-sm-3"
                            *ngIf="
                              f.GRWaterprocessDailyrecordsID.value == 0 &&
                              !formLockedOut
                            "
                          >
                            <span
                              *ngIf="
                                f.VerifyROReverse.errors?.required &&
                                (f.VerifyROReverse.dirty ||
                                  f.VerifyROReverse.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="box-row col-sm-12  divider">
                          <div class="col-sm-4">
                            <strong>CWP RO Verification: </strong>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Verifying CWP RO:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label class="readOnlyText">{{
                                VerifyingCWWPROText
                              }}</label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <!-- <input
                                                        maxlength="10" class="form-control smInput" formControlName="VerifyingCWPRO"
                                                        > -->
                              <label class="readOnlyText">{{
                                VerifyingCWWPROText
                              }}</label>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span
                              >Disinfect Jug > 1/3rd mark:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.DisinfectJug.value == 'NO' ? 'red' : 'black'
                                "
                                >{{ f.DisinfectJug.value }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <select
                                [style.color]="
                                  f.DisinfectJug.value == 'NO' ? 'red' : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="DisinfectJug"
                                name="DisinfectJug"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>

                              <span
                                *ngIf="
                                  f.DisinfectJug.errors?.required &&
                                  (f.DisinfectJug.dirty ||
                                    f.DisinfectJug.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span>FEED
                              Cond.(uS/cm):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.FeedConductivity,
                                    800,
                                    2000
                                  )
                                }"
                                >{{
                                  f.FeedConductivity.value | number: "1.1-1"
                                }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                maxlength="6"
                                class="form-control smInput"
                                (keypress)="numberOnly($event)"
                                (blur)="ReplaceLeadingZero('FeedConductivity')"
                                formControlName="FeedConductivity"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.FeedConductivity,
                                    800,
                                    2000
                                  )
                                }"
                                name="FeedConductivity"
                              />

                              <span
                                *ngIf="
                                  f.FeedConductivity.errors?.required &&
                                  (f.FeedConductivity.dirty ||
                                    f.FeedConductivity.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.FeedConductivity.errors?.pattern"
                                [ngClass]="'error'"
                                >####.#</span
                              >
                              <span class="error">{{
                                errormsgFeedConductivity
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span
                              >PRODUCT Cond.(uS/cm):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground:
                                    f.ProductConductivity.value > 50
                                }"
                                >{{
                                  f.ProductConductivity.value | number: "1.1-1"
                                }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: f.ProductConductivity.value > 50
                                }"
                                max-length="4"
                                class="form-control smInput"
                                (blur)="
                                  ReplaceLeadingZero('ProductConductivity')
                                "
                                formControlName="ProductConductivity"
                                name="ProductConductivity"
                              />

                              <span
                                *ngIf="
                                  f.ProductConductivity.errors?.required &&
                                  (f.ProductConductivity.dirty ||
                                    f.ProductConductivity.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.ProductConductivity.errors?.pattern"
                                [ngClass]="'error'"
                                >##.#</span
                              >
                              <span class="error">{{
                                errormsgProductConductivity
                              }}</span>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span
                              >Recovery Rate (%):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.RecoveryRate,
                                    45,
                                    75
                                  )
                                }"
                                >{{ f.RecoveryRate.value | number: "1.1-1" }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                (blur)="ReplaceLeadingZero('RecoveryRate', 2)"
                                (keypress)="numberOnly($event)"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="RecoveryRate"
                                name="RecoveryRate"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.RecoveryRate,
                                    45,
                                    75
                                  )
                                }"
                              />

                              <span
                                *ngIf="
                                  f.RecoveryRate.errors?.required &&
                                  (f.RecoveryRate.dirty ||
                                    f.RecoveryRate.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.RecoveryRate.errors?.pattern"
                                [ngClass]="'error'"
                                >##.#</span
                              >
                              <span class="error">{{
                                errormsgRecoveryRate
                              }}</span>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span
                              >Rejection Rate (%):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.RejectionRate,
                                    95,
                                    99
                                  )
                                }"
                                >{{ f.RejectionRate.value | number: "1.1-1" }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                (keypress)="numberOnly($event)"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="RejectionRate"
                                (blur)="ReplaceLeadingZero('RejectionRate', 2)"
                                name="RejectionRate"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.RejectionRate,
                                    95,
                                    99
                                  )
                                }"
                              />
                              <span
                                *ngIf="
                                  f.RejectionRate.errors?.required &&
                                  (f.RejectionRate.dirty ||
                                    f.RejectionRate.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.RejectionRate.errors?.pattern"
                                [ngClass]="'error'"
                                >##.#</span
                              >
                              <span class="error">{{
                                errormsgRejectionRate
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <span class="text-red inputrequired">*</span>Temp
                              Return (°C):
                            </label>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground:
                                    f.TempReturn.value > 25
                                }"
                                >{{ f.TempReturn.value | number: "1.1-1" }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                (blur)="ReplaceLeadingZero('TempReturn')"
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: f.TempReturn.value > 25
                                }"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="TempReturn"
                                name="TempReturn"
                              />
                              <span
                                *ngIf="
                                  f.TempReturn.errors?.required &&
                                  (f.TempReturn.dirty ||
                                    f.TempReturn.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.TempReturn.errors?.pattern"
                                [ngClass]="'error'"
                                >##.#</span
                              >
                              <span class="error">{{
                                errormsgTempReturn
                              }}</span>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              <!-- <span class="text-red inputrequired">*</span> -->
                              Temp Tank (°C):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label class="readOnlyText"
                                >{{ f.TempTank.value | number: "1.1-1" }}
                              </label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <input
                                (blur)="ReplaceLeadingZero('TempTank')"
                                (keypress)="numberOnly($event)"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="TempTank"
                                name="TempTank"
                              />

                              <span
                                *ngIf="
                                  f.TempTank.errors?.required &&
                                  (f.TempTank.dirty ||
                                    f.TempTank.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.TempTank.errors?.pattern"
                                [ngClass]="'error'"
                                >##.#</span
                              >
                              <span class="error">{{ errormsgTempTank }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="box-row col-sm-12">
                          <div class="col-sm-2">
                            <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{AMUserName}} </label></label> -->
                          </div>
                          <div class="col-sm-3">
                            <!-- <label class="control-label"></label> -->
                          </div>
                          <div class="col-sm-5">
                            <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                &nbsp;&nbsp;
                                                <label class="control-label">{{AMUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                            AMDetailsEnteredBy == null || !isAMComplted
                            "
                          >
                            <PageHeader
                              title=""
                              [showNote]="false"
                              [showAddNew]="false"
                              [showSearch]="false"
                              [mainlisturl]=""
                              [showSave]="blnshowSave"
                              (SaveEvent)="executeGRWPDailyCreateUpdate('', 'AM')"
                              [showDelete]="false"
                              [showMainListing]="false"
                              #pageheader
                            ></PageHeader>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="box-row col-sm-12 divider">
                            <div class="col-sm-4">
                                <strong>Raw Water Filters
                                </strong>
                            </div>
                            <div class="col-sm-4">

                            </div>
                            <div class="col-sm-4">
                                <strong>Temperature
                                </strong>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                            <div class="col-sm-4">

                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>P (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.RawWaterFiltersP.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="RawWaterFiltersP" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersP,45,90)}"
                                        class="form-control smInput" formControlName="RawWaterFiltersP"
                                        (keyup)="GreaterValueCheck()" required />

                                    <span *ngIf="f.RawWaterFiltersP.errors?.required && (f.RawWaterFiltersP.dirty || f.RawWaterFiltersP.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.RawWaterFiltersP.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                    <!-- <span  *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass] = "'error'">
                                        P should be higher than Q.
                                    </span> -->
                                </div>


                            </div>


                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>Q(psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.RawWaterFiltersQ.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="RawWaterFiltersQ" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersQ,45,90)}"
                                        class="form-control smInput" formControlName="RawWaterFiltersQ" name="RawWaterFiltersQ"
                                        (keyup)="GreaterValueCheck()" required>
                                    <span *ngIf="f.RawWaterFiltersQ.errors?.required && (f.RawWaterFiltersQ.dirty || f.RawWaterFiltersQ.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.RawWaterFiltersQ.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>Blending Valve (°C):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.BlendingValve.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  [ngClass]="{'OurOffRange':checkRange(f.BlendingValve,10,25)}" name="BlendingValve" maxlength="10" class="form-control smInput"
                                        formControlName="BlendingValve" name="BlendingValve" required>
                                    <span *ngIf="f.BlendingValve.errors?.required && (f.BlendingValve.dirty || f.BlendingValve.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.BlendingValve.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>

                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                            <div class="col-sm-6">
                                <label class="col-sm-5 control-label"></label>

                                <div class="col-sm-7">
                                    <span *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass]="'error'">
                                        P should be higher than Q.
                                    </span>
                                    <span *ngIf="f.RawWaterFiltersP.errors?.maxLengthVal" [ngClass]="'error'">
                                       Max 10 digit allow.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12 divider">
                            <div class="col-sm-4">
                                <strong>Softener
                                </strong>
                            </div>

                            <div class="col-sm-4">

                            </div>
                            <div class="col-sm-4">
                                <strong>Hardness
                                </strong>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>Capacity Remain(x1000 gal):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.CapacityRemaining.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="CapacityRemaining" maxlength="10" class="form-control smInput" formControlName="CapacityRemaining"
                                        name="CapacityRemaining" required>
                                    <span *ngIf="f.CapacityRemaining.errors?.required && (f.CapacityRemaining.dirty || f.CapacityRemaining.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.CapacityRemaining.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>


                            <div class="col-sm-4">

                            </div>

                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired"></span>Total Hardness (gpg):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.TotalHardness.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <select name="TotalHardness" class="form-control smInput" formControlName="TotalHardness" name="TotalHardness" required>
                                        <option value="{{item.value}}" *ngFor="let item of HardNessList">{{item.text}}</option>
                                    </select>

                                    <span *ngIf="f.TotalHardness.errors?.required && (f.TotalHardness.dirty || f.TotalHardness.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.TotalHardness.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>


                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12  divider">
                            <div class="col-sm-4">
                                <strong>DPD Test Pre-Carbon
                                </strong>
                            </div>

                            <div class="col-sm-4">

                            </div>
                            <div class="col-sm-4">
                                <strong>DPD Test Post-Carbon
                                </strong>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>Total Chlorine (mg/L):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.DPDTestPreTotalChlorine.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  [ngClass]="{'OurOffRange':checkRange(f.DPDTestPreTotalChlorine,0.01,2)}" name="DPDTestPreTotalChlorine"
                                        maxlength="10" class="form-control smInput" formControlName="DPDTestPreTotalChlorine"
                                        name="DPDTestPreTotalChlorine" required>
                                    <span *ngIf="f.DPDTestPreTotalChlorine.errors?.required && (f.DPDTestPreTotalChlorine.dirty || f.DPDTestPreTotalChlorine.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.DPDTestPreTotalChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>

                            <div class="col-sm-4">

                            </div>

                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>Total Chlorine (mg/L):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.DPDTestPostTotalChlorine.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  [ngClass]="{'OurOffRange':checkRange(f.DPDTestPostTotalChlorine,0.01,0.08)}" name="DPDTestPostTotalChlorine"
                                        maxlength="10" class="form-control smInput" formControlName="DPDTestPostTotalChlorine"
                                        name="DPDTestPostTotalChlorine" required>
                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.required && (f.DPDTestPostTotalChlorine.dirty || f.DPDTestPostTotalChlorine.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>



                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    Free Chlorine (mg/L):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.DPDTestPreFreeChlorine.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="DPDTestPreFreeChlorine" maxlength="10" class="form-control smInput" [ngClass]="{'OurOffRange':checkRange(f.DPDTestPreFreeChlorine,0.01,0.05)}"
                                        formControlName="DPDTestPreFreeChlorine" name="DPDTestPreFreeChlorine">
                                    <span *ngIf="f.DPDTestPreFreeChlorine.errors?.required && (f.DPDTestPreFreeChlorine.dirty || f.DPDTestPreFreeChlorine.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.DPDTestPreFreeChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>




                            <div class="col-sm-4">

                            </div>

                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    Free Chlorine (mg/L):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.DPDTestPostFreeChlorine.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="DPDTestPostFreeChlorine" maxlength="10" class="form-control smInput" formControlName="DPDTestPostFreeChlorine"
                                        name="DPDTestPostFreeChlorine" [ngClass]="{'OurOffRange':checkRange(f.DPDTestPostFreeChlorine,0.01,0.08)}">
                                    <span *ngIf="f.DPDTestPostFreeChlorine.errors?.required && (f.DPDTestPostFreeChlorine.dirty || f.DPDTestPostFreeChlorine.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf="f.DPDTestPostFreeChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>



                        </div>
                        <div class="box-row col-sm-12 divider">
                            <div class="col-sm-4">
                                <strong>RO Pre Filters
                                </strong>
                            </div>

                            <div class="col-sm-4">

                            </div>
                            <div class="col-sm-4">

                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <!--Suxess field start-->
                     
                        <div *ngIf="!IsShepardForm" class="box-row col-sm-12">
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>W (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.ROPreW.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="ROPreW" maxlength="10" (keyup)="GreaterValueCheck()" [ngClass]="{'OurOffRange':checkRange(f.ROPreW,30,85)}"
                                        class="form-control smInput" formControlName="ROPreW" name="ROPreW" required>
                                    <span *ngIf="f.ROPreW.errors?.required && (f.ROPreW.dirty || f.ROPreW.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                        Required! </span>
                                    <span *ngIf="f.ROPreW.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                </div>
                            </div>
                            <div  class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    <span class="text-red inputrequired">*</span>X (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.ROPreX.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="ROPreX" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.ROPreX,30,85)}" class="form-control smInput"
                                        formControlName="ROPreX" name="ROPreX" required>
                                    <span *ngIf="f.ROPreX.errors?.required && (f.ROPreX.dirty || f.ROPreX.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                        Required! </span>
                                    <span *ngIf="f.ROPreX.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                </div>
                            </div>


                            <div class="col-sm-4"></div>



                        </div>
                        <div *ngIf="!IsShepardForm" class="clearfix"></div>
                        <div *ngIf="!IsShepardForm" class="box-row col-sm-12">
                            <div class="col-sm-6">
                                <label class="col-sm-5 control-label"></label>

                                <div class="col-sm-7">
                                    <span *ngIf="f.ROPreX.errors?.InGreaterOut" [ngClass]="'error'">
                                        W should be higher than X.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--Suxess field end-->
                       <!--Shephard field start-->
                        <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label"> Pressure Gauge Pl1615 (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.P6_PSI.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="P6_PSI" maxlength="10" class="form-control smInput"
                                        formControlName="P6_PSI" name="P6_PSI">
                                    <span *ngIf="f.P6_PSI.errors?.pattern"
                                        [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label"> Pressure Gauge Pl1620 (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.P7_PSI.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="P7_PSI" maxlength="10" class="form-control smInput"
                                        formControlName="P7_PSI" name="P7_PSI">
                                    <span *ngIf="f.P7_PSI.errors?.pattern"
                                        [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <label class="col-sm-8 control-label"> Pressure Gauge Pl1625 (psi):</label>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                    <label class="readOnlyText"> {{f.P8_PSI.value}}</label>
                                </div>
                                <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                    <input  name="P8_PSI" maxlength="10" class="form-control smInput"
                                        formControlName="P8_PSI" name="P8_PSI">
                                    <span *ngIf="f.P8_PSI.errors?.pattern"
                                        [ngClass]="'error'">Invalid number</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="IsShepardForm" class="clearfix"></div>
                        <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                        <div class="col-sm-4">
                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1630 (psi):</label>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                <label class="readOnlyText"> {{f.P9_PSI.value}}</label>
                            </div>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                <input  name="P9_PSI" maxlength="10" class="form-control smInput"
                                    formControlName="P9_PSI" name="P9_PSI">
                                <span *ngIf="f.P9_PSI.errors?.pattern" [ngClass]="'error'">
                               Invalid number</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1670 (psi):</label>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                <label class="readOnlyText"> {{f.P12_PSI.value}}</label>
                            </div>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                <input  name="P12_PSI" maxlength="10" class="form-control smInput"
                                    formControlName="P12_PSI" name="P12_PSI">
                                <span *ngIf="f.P12_PSI.errors?.pattern" [ngClass]="'error'">
                               Invalid number</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1675 (psi):</label>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                                <label class="readOnlyText"> {{f.P13_PSI.value}}</label>
                            </div>
                            <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                                <input  name="P13_PSI" maxlength="10" class="form-control smInput"
                                    formControlName="P13_PSI" name="P13_PSI">
                                <span *ngIf="f.P13_PSI.errors?.pattern" [ngClass]="'error'">
                               Invalid number</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="IsShepardForm" class="clearfix"></div>
                    <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label"> Pressure Gauge Pl1680 (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.P14_PSI.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="P14_PSI" maxlength="10" class="form-control smInput" formControlName="P14_PSI"
                                name="P14_PSI">
                            <span *ngIf="f.P14_PSI.errors?.pattern" [ngClass]="'error'">
                           Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label"> Pressure Gauge Pl1685 (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.P15_PSI.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="P15_PSI" maxlength="10" class="form-control smInput" formControlName="P15_PSI"
                                name="P15_PSI">
                            <span *ngIf="f.P15_PSI.errors?.pattern" [ngClass]="'error'">
                           Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">

                    </div>



                </div>
                <div *ngIf="IsShepardForm" class="clearfix"></div>
                <div *ngIf="IsShepardForm" class="box-row col-sm-12 divider">
                    <div class="col-sm-4">
                        <strong>DI Back up Thornton Meter
                        </strong>
                    </div>

                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Working Quality (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.WorkingQuality.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input   name="WorkingQuality"
                                maxlength="10" class="form-control smInput" formControlName="WorkingQuality"
                                name="WorkingQuality">
                            <span *ngIf="f.WorkingQuality.errors?.required && (f.WorkingQuality.dirty || f.WorkingQuality.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.WorkingQuality.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                    <div class="col-sm-4">
                      <label class="col-sm-8 control-label">
                      Polisher Quality (µS/cm):</label>
                      <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                          <label class="readOnlyText"> {{f.PolisherQuality.value}}</label>
                      </div>
                      <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                          <input   name="PolisherQuality"
                              maxlength="10" class="form-control smInput" formControlName="PolisherQuality"
                              name="PolisherQuality" >
                          <span *ngIf="f.PolisherQuality.errors?.required && (f.PolisherQuality.dirty || f.PolisherQuality.touched || isValidFormSubmitted==false)"
                              [ngClass]="'error'"> Required! </span>
                          <span *ngIf="f.PolisherQuality.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                      </div>
                  </div>
                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>

                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="row">
                <div class="box-row col-sm-12 divider">
                    <div class="col-sm-4">
                        <strong>DI Tanks
                        </strong>
                    </div>

                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            <span class="text-red inputrequired">*</span>D.I's Rinsed:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.DIRinsed.value}}</label>
                        </div>

                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <select class="form-control mdInput" formControlName="DIRinsed" name="DIRinsed">
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="NO">NO</option>
                            </select>
                            <span *ngIf="f.DIRinsed.errors?.required && (f.DIRinsed.dirty || f.DIRinsed.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                Required! </span>
                            <span *ngIf="f.DIRinsed.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Enable DI Backup?:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.EnableDIBackupMode.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input type="checkbox" (change)="SetSpherdForm()" name="EnableDIBackupMode"
                                 class="iCheck-helper" formControlName="EnableDIBackupMode"
                                name="EnableDIBackupMode">
                            <span *ngIf="f.EnableDIBackupMode.errors?.required && (f.EnableDIBackupMode.dirty || f.EnableDIBackupMode.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.EnableDIBackupMode.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                       <div class="col-sm-4"></div>
                      </div>
                <div class="clearfix"></div>

                <div *ngIf="!f.EnableDIBackupMode.value" class="box-row col-sm-12 divider">
                    <div class="col-sm-4">
                        <strong>RO
                        </strong>
                    </div>

                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>
                 <!--Suxess field start-->
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Clock:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROClock.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROClock" maxlength="10" class="form-control smInput" formControlName="ROClock" name="ROClock">

                            <span *ngIf="f.ROClock.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            <span class="text-red inputrequired">*</span>Feed Conductivity (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROFeedConductivity.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROFeedConductivity" [ngClass]="{'OurOffRange':checkRange(f.ROFeedConductivity,200,700)}" maxlength="10"
                                class="form-control smInput" formControlName="ROFeedConductivity" name="ROFeedConductivity"
                                required>
                            <span *ngIf="f.ROFeedConductivity.errors?.required && (f.ROFeedConductivity.dirty || f.ROFeedConductivity.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.ROFeedConductivity.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            <span class="text-red inputrequired">*</span>Permeate Conductivity (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROPermeateConductivity.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROPermeateConductivity" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.ROPermeateConductivity,3.5,8.5)}"
                                class="form-control smInput" formControlName="ROPermeateConductivity" name="ROPermeateConductivity"
                                required>
                            <span *ngIf="f.ROPermeateConductivity.errors?.required && (f.ROPermeateConductivity.dirty || f.ROPermeateConductivity.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.ROPermeateConductivity.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            % Rejection CQ19864:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.RORejection.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="RORejection" [ngClass]="{'OurOffRange':checkRange(f.RORejection,95,99)}" maxlength="10" class="form-control smInput"
                                formControlName="RORejection" name="RORejection">

                            <span *ngIf="f.RORejection.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            <span class="text-red inputrequired">*</span>Permeate Flow ( L/min):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROPermeateFlow.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROPermeateFlow" maxlength="10" class="form-control smInput" formControlName="ROPermeateFlow" name="ROPermeateFlow"
                                required>
                            <span *ngIf="f.ROPermeateFlow.errors?.required && (f.ROPermeateFlow.dirty || f.ROPermeateFlow.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.ROPermeateFlow.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Prefilter In (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROPrefilterIn.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROPrefilterIn" maxlength="10" class="form-control smInput" formControlName="ROPrefilterIn" name="ROPrefilterIn">

                            <span *ngIf="f.ROPrefilterIn.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                        </div>
                    </div>

                </div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Prefilter Out (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROPrefilterOut.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROPrefilterOut" maxlength="10" class="form-control smInput" formControlName="ROPrefilterOut" name="ROPrefilterOut">

                            <span *ngIf="f.ROPrefilterOut.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Pump Pressure (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ROPumpPressure.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="ROPumpPressure" maxlength="10" class="form-control smInput" formControlName="ROPumpPressure" name="ROPumpPressure">

                            <span *ngIf="f.ROPumpPressure.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            Ring Pressure (bar):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.RORingPressure.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="RORingPressure" maxlength="10" class="form-control smInput" formControlName="RORingPressure" name="RORingPressure">

                            <span *ngIf="f.RORingPressure.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                </div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-6">
                        <label class="col-sm-5 control-label"></label>

                        <div class="col-sm-7">
                            <span *ngIf="f.ROPrefilterOut.errors?.InGreaterOut" [ngClass]="'error'">
                                PrefilterIn should be higher than PrefilterOut.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                        <!--Suxess field end-->
                       <!--Shephard field start-->
                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">PRE-FILTER INLET P13600 (PSI):</label> -->
                        <label class="col-sm-8 control-label">Pre-Filter Inlet P13600 (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.PRE_FILTER_INLET_P13600_PSI.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="PRE_FILTER_INLET_P13600_PSI" maxlength="10" class="form-control smInput"
                                formControlName="PRE_FILTER_INLET_P13600_PSI" name="PRE_FILTER_INLET_P13600_PSI">
                            <span *ngIf="f.PRE_FILTER_INLET_P13600_PSI.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">PRE-FILTER OUT P13605 (PSI):</label> -->
                        <label class="col-sm-8 control-label">Pre-Filter Out P13605 (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.PRE_FILTER_OUT_P13605_PSI.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="PRE_FILTER_OUT_P13605_PSI" maxlength="10" class="form-control smInput"
                                formControlName="PRE_FILTER_OUT_P13605_PSI" name="PRE_FILTER_OUT_P13605_PSI">
                            <span *ngIf="f.PRE_FILTER_OUT_P13605_PSI.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">FEED CONDUCTIVTY A13635:</label> -->
                        <label class="col-sm-8 control-label">Feed Conductivity A13635:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.FEED_CONDUCTIVTY_A13635.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="FEED_CONDUCTIVTY_A13635" maxlength="10" class="form-control smInput"
                                formControlName="FEED_CONDUCTIVTY_A13635" name="FEED_CONDUCTIVTY_A13635">
                            <span *ngIf="f.FEED_CONDUCTIVTY_A13635.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                </div>
                <div *ngIf="IsShepardForm" class="clearfix"></div>

                <div *ngIf="IsShepardForm  && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label"> % Rejection CQ19864:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.SP_Rejection.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="SP_Rejection" maxlength="10" class="form-control smInput"
                                formControlName="SP_Rejection" name="SP_Rejection">
                            <span *ngIf="f.SP_Rejection.errors?.pattern" [ngClass]="'error'">
                           Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label"> PUMP 1 CLOCK:</label> -->
                        <label class="col-sm-8 control-label">Pump 1 Clock:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.PUMP_1_CLOCK.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="PUMP_1_CLOCK" maxlength="10" class="form-control smInput"
                                formControlName="PUMP_1_CLOCK" name="PUMP_1_CLOCK">
                            <span *ngIf="f.PUMP_1_CLOCK.errors?.pattern" [ngClass]="'error'">
                           Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">PUMP 2 CLOCK:</label> -->
                        <label class="col-sm-8 control-label">Pump 2 Clock:</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.PUMP_2_CLOCK.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="PUMP_2_CLOCK" maxlength="10" class="form-control smInput"
                                formControlName="PUMP_2_CLOCK" name="PUMP_2_CLOCK">
                            <span *ngIf="f.PUMP_2_CLOCK.errors?.pattern" [ngClass]="'error'">
                           Invalid number</span>
                        </div>
                    </div>

                </div>
                <div *ngIf="IsShepardForm" class="clearfix"></div>
                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">1ST PASS PRODUCT QUALITY A13665:</label> -->
                        <label class="col-sm-8 control-label longtitle">1st Pass Quality A13665 (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f._1ST_PASS_PRODUCT_QUALITY_A13665.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="_1ST_PASS_PRODUCT_QUALITY_A13665" maxlength="10" class="form-control smInput"
                                formControlName="_1ST_PASS_PRODUCT_QUALITY_A13665" name="_1ST_PASS_PRODUCT_QUALITY_A13665">
                            <span *ngIf="f._1ST_PASS_PRODUCT_QUALITY_A13665.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">2ND PASS PRODUCT FLOW A13710 (GPM):</label> -->
                        <label class="col-sm-8 control-label">2nd Pass Flow A13710 (gpm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="_2ND_PASS_PRODUCT_FLOW_A13710_GPM" maxlength="10" class="form-control smInput"
                                formControlName="_2ND_PASS_PRODUCT_FLOW_A13710_GPM" name="_2ND_PASS_PRODUCT_FLOW_A13710_GPM">
                            <span *ngIf="f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">2ND PASS PRODUCT QUALITY A13665:</label> -->
                        <label class="col-sm-8 control-label longtitle">2nd Pass Quality A13665 (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f._2ND_PASS_PRODUCT_QUALITY_A13665.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="_2ND_PASS_PRODUCT_QUALITY_A13665" maxlength="10" class="form-control smInput"
                                formControlName="_2ND_PASS_PRODUCT_QUALITY_A13665" name="_2ND_PASS_PRODUCT_QUALITY_A13665">
                            <span *ngIf="f._2ND_PASS_PRODUCT_QUALITY_A13665.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                </div>
                <div *ngIf="IsShepardForm" class="clearfix"></div>
                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">LOOP FILTER OUTLET PRESURE P13715 (PSIG):</label> -->
                        <label class="col-sm-8 control-label ">Loop Filter Outlet P13715 (psi):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG" maxlength="10"
                                class="form-control smInput" formControlName="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG"
                                name="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG">
                            <span *ngIf="f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <!-- <label class="col-sm-8 control-label">LOOP RETURN FLOW F13620 (GPM):</label> -->
                        <label class="col-sm-8 control-label longtitle">Loop Return Flow F13620 (gpm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.LOOP_RETURN_FLOW_F13620_GPM.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  name="LOOP_RETURN_FLOW_F13620_GPM" maxlength="10" class="form-control smInput"
                                formControlName="LOOP_RETURN_FLOW_F13620_GPM" name="LOOP_RETURN_FLOW_F13620_GPM">
                            <span *ngIf="f.LOOP_RETURN_FLOW_F13620_GPM.errors?.pattern"
                                [ngClass]="'error'">Invalid number</span>
                        </div>
                    </div>

                    <div class="col-sm-4"></div>

                </div>

                <div class="clearfix"></div>
                <div class="box-row col-sm-12 divider">
                    <div class="col-sm-4">
                        <strong>Thornton Meter
                        </strong>
                    </div>
                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">
                        <strong>
                        </strong>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-row col-sm-12">
                    <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                            <span class="text-red inputrequired">*</span>Morning (µS/cm):</label>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value > 0">
                            <label class="readOnlyText"> {{f.ThorntonMeterMorning.value}}</label>
                        </div>
                        <div class="col-sm-4" *ngIf="f.GRWaterprocessDailyrecordsID.value == 0">
                            <input  [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterMorning,0.01,8.5)}" name="ThorntonMeterMorning"
                                maxlength="8" class="form-control smInput" formControlName="ThorntonMeterMorning"
                                name="ThorntonMeterMorning" required>
                            <span *ngIf="f.ThorntonMeterMorning.errors?.required && (f.ThorntonMeterMorning.dirty || f.ThorntonMeterMorning.touched || isValidFormSubmitted==false)"
                                [ngClass]="'error'"> Required! </span>
                            <span *ngIf="f.ThorntonMeterMorning.errors?.pattern" [ngClass]="'error'"> Not valid number!</span>
                        </div>
                    </div>

                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                </div>


                <div class="clearfix"></div>
                <div class="box-row col-sm-12 divider">
                    <div class="col-sm-4">
                        <strong>Central Delivery Acid Levels
                        </strong>
                    </div>

                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-row col-sm-12">
                    <AcidLevelComponent #acidLevel>

                    </AcidLevelComponent>

                </div>
                <div class="clearfix"></div>

                <div class="clearfix"></div>
                <div class="box-row col-sm-12">
                    <div class="col-sm-2">
                        <label class="control-label" style="color: #0484a4">User Name:</label>
                    </div>
                    <div class="col-sm-3">
                        <label class="control-label">{{FirstShiftUserName}}</label>
                    </div>
                    <div class="col-sm-3">
                        <label class="control-label"  style="color: #0484a4">Time:</label>
                        &nbsp;
                        &nbsp;
                        <label class="control-label">{{FirstShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                    </div>
                  </div>
                </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>
              <div
                class="panel panel-default" *ngIf="isMain3EForm || isMain7Form"
                [ngClass]="{ nonePointerEvents: !isAMComplted || (Status=='Incompleted' && MidDetailsEnteredBy==null) }"
              >
                <div class="panel-heading" role="tab" id="headingTwoMid">
                  <h4 class="panel-title">
                    <a
                      [ngClass]="collapseTwoMidClass"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwoMid"
                      aria-expanded="collapseTwoMidariaexpanded"
                      aria-controls="collapseTwoMid"
                      id="midPanel"
                    >
                      <div class="box-header with-border">
                        <h3 class="box-title container">
                          MID
                          <span *ngIf="isMIDComplted">
                            : {{ this.MIDUserName }} -
                            {{ MidDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                            Completed</span
                          >
                        </h3>
                      </div>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwoMid"
                  [ngClass]="collapseTwoMidpanelClass"
                  role="tabpanel"
                  aria-labelledby="headingTwoMid"
                >
                  <div class="panel-body">
                    <div class="box-body">
                      <div class="row">
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-3">
                            <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut) &&
                              f.AmANLSeqAlaramMID.value != '' &&
                              isMIDComplted
                            "
                          >
                            <label
                              class="readOnlyText"
                              [style.color]="
                                f.AmANLSeqAlaramMID.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                              >{{ f.AmANLSeqAlaramMID.value }}</label
                            >
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut) ||
                              f.AmANLSeqAlaramMID.value == '' ||
                              !isMIDComplted
                            "
                          >
                            <select
                              [style.color]="
                                f.AmANLSeqAlaramMID.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                              class="form-control mdInput"
                              formControlName="AmANLSeqAlaramMID"
                              name="AmANLSeqAlaramMID"
                              (change)="AmANLSeqAlaramMIDDropDownChanged()"
                            >
                              <option value="" style="color:black"
                                >Select</option
                              >
                              <option value="Yes" style="color:black"
                                >Yes</option
                              >
                              <option value="NO" style="color:red">NO</option>
                            </select>
                            <span
                              *ngIf="
                                f.AmANLSeqAlaramMID.errors?.required &&
                                (f.AmANLSeqAlaramMID.dirty ||
                                  f.AmANLSeqAlaramMID.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-5"
                            *ngIf="
                              f.AmANLSeqAlaramMID.value == 'NO' &&
                              !isMIDComplted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                            <select
                              style="color:red"
                              class="form-control mdInput"
                              formControlName="DailyMidFailureDisplay"
                              name="DailyMidFailureDisplay"
                            >
                              <option value="" style="color:red">Select</option>
                              <option value="Bank 1" style="color:red"
                                >Bank 1</option
                              >
                              <option value="Bank 2" style="color:red"
                                >Bank 2</option
                              >
                              <option value="Bank 3" style="color:red"
                                >Bank 3</option
                              >
                              <option value="Bank 4" style="color:red"
                                >Bank 4</option
                              >
                              <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                >Bank 5</option
                              >
                            </select>
                            <span
                              *ngIf="
                                f.DailyMidFailureDisplay.errors?.required &&
                                (f.DailyMidFailureDisplay.dirty ||
                                  f.DailyMidFailureDisplay.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut) &&
                              f.AmANLSeqAlaramMID.value == 'NO' &&
                              isMIDComplted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong
                            ><label class="readOnlyText" style="color:red">{{
                              f.DailyMidFailureDisplay.value
                            }}</label>
                          </div>
                        </div>
                        <div class="clearfix"></div>

                        <div
                          class="box-row col-sm-12"
                          *ngIf="f.AmANLSeqAlaramMID.value == 'NO'"
                        >
                          <div
                            formArrayName="MidSeqALM"
                            *ngFor="
                              let item of GRDailyDetailForm.get('MidSeqALM')
                                .controls;
                              let i = index
                            "
                            class="form-group"
                          >
                            <div [formGroupName]="i">
                              <div class="col-sm-4"></div>
                              <div class="col-sm-8">
                                <label class="col-sm-8 control-label">
                                  Verified Total Chlorine for Bank 0{{
                                    i + 1
                                  }}:</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                      formLockedOut) &&
                                    isMIDComplted
                                  "
                                >
                                  <label
                                    class="readOnlyText"
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramMID
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramMID
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    >{{
                                      item.controls.VerifiedBankAlaramMID.value
                                    }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value ==
                                      0 &&
                                      !formLockedOut) ||
                                    !isMIDComplted
                                  "
                                >
                                  <select
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramMID
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramMID
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    class="form-control mdInput"
                                    formControlName="VerifiedBankAlaramMID"
                                    name="VerifiedBankAlaramMID"
                                  >
                                    <option
                                      value="Not Tested"
                                      style="color:black"
                                      >Not Tested</option
                                    >
                                    <option value="Pass" style="color:black"
                                      >Pass</option
                                    >
                                    <option value="Fail" style="color:red"
                                      >Fail</option
                                    >
                                  </select>
                                  <!-- <span *ngIf="item.controls.VerifiedBankAlaramMID.errors?.required && (item.controls.VerifiedBankAlaramMID.dirty || item.controls.VerifiedBankAlaramMID.touched ||  isValidFormSubmitted==false)"
                                                                    [ngClass]="'error'">
                                                                    Required!
                                                                </span> -->
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="clearfix" *ngIf="f.AmANLSeqAlaramMID.value  == 'NO' && !isMIDComplted"></div>
                                        <div class="box-row col-sm-12" *ngIf="f.AmANLSeqAlaramMID.value  == 'NO' && !isMIDComplted">
                                                <div class="col-sm-3">
                                                </div>
                                                <div class="col-sm-3">
                                                    </div>
                                                    <div class="col-sm-2">
                                                        </div>
                                                <div class="col-sm-3" >

                                                        <button type="button" class="btn btn-primary" (click)="addMidAlaramClick()" *ngIf="GRDailyDetailForm.get('MidSeqALM').controls.length <= 4 && !isMIDComplted">
                                                                + ADD Additional Bank Alarm
                                                            </button>

                                                    </div>
                                                    <div class="col-sm-6"></div>
                                        </div> -->
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-2">
                            <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{MIDUserName}} </label></label> -->
                          </div>
                          <div class="col-sm-3">
                            <label class="control-label"></label>
                          </div>
                          <div class="col-sm-5">
                            <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                    &nbsp;&nbsp;
                                                    <label class="control-label">{{MIDUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                              f.AmANLSeqAlaramMID.value == '' || !isMIDComplted
                            "
                          >
                            <PageHeader
                              title=""
                              [showNote]="false"
                              [showAddNew]="false"
                              [showSearch]="false"
                              [mainlisturl]=""
                              [showSave]="blnshowSave"
                              (SaveEvent)="
                                executeGRWPDailyCreateUpdate('', 'Mid')
                              "
                              [showDelete]="false"
                              [showMainListing]="false"
                              #pageheader
                            ></PageHeader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>

              <div
                class="panel panel-default" *ngIf="isMain3EForm || isMain7Form"
                [ngClass]="{ nonePointerEvents: !isMIDComplted   || (Status=='Incompleted' && PMDetailsEnteredBy==null) }"
              >
                <div class="panel-heading" role="tab" id="headingTwoPM">
                  <h4 class="panel-title">
                    <a
                      [ngClass]="collapseTwoPMClass"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwoPM"
                      aria-expanded="collapseTwoPMariaexpanded"
                      aria-controls="collapseTwoPM"
                      id="pmPanel"
                    >
                      <div class="box-header with-border">
                        <h3 class="box-title container">
                          PM
                          <span *ngIf="isPMCompleted">
                            : {{ this.PMUserName }} -
                            {{ PMDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                            Completed</span
                          >
                        </h3>
                      </div>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwoPM"
                  [ngClass]="collapseTwoPMpanelClass"
                  role="tabpanel"
                  aria-labelledby="headingTwoPM"
                >
                  <div class="panel-body">
                    <div class="box-body">
                      <div class="row">
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-3">
                            <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut) &&
                              f.AmANLSeqAlaramPM.value != '' &&
                              isPMCompleted
                            "
                          >
                            <label
                              class="readOnlyText"
                              [style.color]="
                                f.AmANLSeqAlaramPM.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                              >{{ f.AmANLSeqAlaramPM.value }}</label
                            >
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut) ||
                              f.AmANLSeqAlaramPM.value == '' ||
                              !isPMCompleted
                            "
                          >
                            <select
                              [style.color]="
                                f.AmANLSeqAlaramPM.value == 'NO'
                                  ? 'red'
                                  : 'black'
                              "
                              class="form-control mdInput"
                              formControlName="AmANLSeqAlaramPM"
                              name="AmANLSeqAlaramPM"
                              (change)="AmANLSeqAlaramPMDropDownChanged()"
                            >
                              <option value="" style="color:black"
                                >Select</option
                              >
                              <option value="Yes" style="color:black"
                                >Yes</option
                              >
                              <option value="NO" style="color:red">NO</option>
                            </select>
                            <span
                              *ngIf="
                                f.AmANLSeqAlaramPM.errors?.required &&
                                (f.AmANLSeqAlaramPM.dirty ||
                                  f.AmANLSeqAlaramPM.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-5"
                            *ngIf="
                              f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                            <select
                              style="color:red"
                              class="form-control mdInput"
                              formControlName="DailyPMFailureDisplay"
                              name="DailyPMFailureDisplay"
                            >
                              <option value="" style="color:red">Select</option>
                              <option value="Bank 1" style="color:red"
                                >Bank 1</option
                              >
                              <option value="Bank 2" style="color:red"
                                >Bank 2</option
                              >
                              <option value="Bank 3" style="color:red"
                                >Bank 3</option
                              >
                              <option value="Bank 4" style="color:red"
                                >Bank 4</option
                              >
                              <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                >Bank 5</option
                              >
                            </select>
                            <span
                              *ngIf="
                                f.DailyPMFailureDisplay.errors?.required &&
                                (f.DailyPMFailureDisplay.dirty ||
                                  f.DailyPMFailureDisplay.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                          </div>
                          <div
                            class="col-sm-4"
                            *ngIf="
                              (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut) &&
                              f.AmANLSeqAlaramPM.value == 'NO' &&
                              isPMCompleted
                            "
                          >
                            <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                            <label class="readOnlyText" style="color:red">{{
                              f.DailyPMFailureDisplay.value
                            }}</label>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div
                          class="box-row col-sm-12"
                          *ngIf="f.AmANLSeqAlaramPM.value == 'NO'"
                        >
                          <div
                            formArrayName="PMSeqALM"
                            *ngFor="
                              let item of GRDailyDetailForm.get('PMSeqALM')
                                .controls;
                              let i = index
                            "
                            class="form-group"
                          >
                            <div [formGroupName]="i">
                              <div class="col-sm-4"></div>
                              <div class="col-sm-8">
                                <label class="col-sm-8 control-label">
                                  Verified Total Chlorine for Bank 0{{
                                    i + 1
                                  }}:</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                      formLockedOut) &&
                                    isPMCompleted
                                  "
                                >
                                  <label
                                    class="readOnlyText"
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramPM
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramPM
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    >{{
                                      item.controls.VerifiedBankAlaramPM.value
                                    }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value ==
                                      0 &&
                                      !formLockedOut) ||
                                    !isPMCompleted
                                  "
                                >
                                  <select
                                    [style.color]="
                                      item.controls.VerifiedBankAlaramPM
                                        .value == 'Fail' ||
                                      item.controls.VerifiedBankAlaramPM
                                        .value == 'Not Tested'
                                        ? 'red'
                                        : 'black'
                                    "
                                    class="form-control mdInput"
                                    formControlName="VerifiedBankAlaramPM"
                                    name="VerifiedBankAlaramPM"
                                  >
                                    <option
                                      value="Not Tested"
                                      style="color:black"
                                      >Not Tested</option
                                    >
                                    <option value="Pass" style="color:black"
                                      >Pass</option
                                    >
                                    <option value="Fail" style="color:red"
                                      >Fail</option
                                    >
                                  </select>
                                  <!-- <span *ngIf="item.controls.VerifiedBankAlaramPM.errors?.required && (item.controls.VerifiedBankAlaramPM.dirty || item.controls.VerifiedBankAlaramPM.touched ||  isValidFormSubmitted==false)"
                                                                                        [ngClass]="'error'">
                                                                                        Required!
                                                                                    </span> -->
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                        <!-- <div
                          class="clearfix"
                          *ngIf="
                            f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                          "
                        ></div>
                        <div
                          class="box-row col-sm-12"
                          *ngIf="
                            f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                          "
                        >
                          <div class="col-sm-3"></div>
                          <div class="col-sm-3"></div>
                          <div class="col-sm-2"></div>
                          <div class="col-sm-3">
                            <button
                              type="button"
                              class="btn btn-primary"
                              (click)="addPMAlaramClick()"
                              *ngIf="
                                GRDailyDetailForm.get('PMSeqALM').controls.length <= 4 && !isPMCompleted
                              "
                            >
                              + ADD Additional Bank Alarm
                            </button>
                          </div>
                          <div class="col-sm-6"></div>
                        </div>
                        <div class="clearfix"></div> -->
                        <div class="box-row col-sm-12">
                          <div class="col-sm-2">
                            <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{PMUserName}} </label></label> -->
                          </div>
                          <div class="col-sm-3">
                            <label class="control-label"></label>
                          </div>
                          <div class="col-sm-5">
                            <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                        &nbsp;&nbsp;
                                                        <label class="control-label">{{PMUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                          </div>
                          <div
                            class="col-sm-2"
                            *ngIf="
                              f.AmANLSeqAlaramPM.value == '' || !isPMCompleted
                            "
                          >
                            <PageHeader
                              title=""
                              [showNote]="false"
                              [showAddNew]="false"
                              [showSearch]="false"
                              [mainlisturl]=""
                              [showSave]="blnshowSave"
                              (SaveEvent)="
                                executeGRWPDailyCreateUpdate('', 'PM')
                              "
                              [showDelete]="false"
                              [showMainListing]="false"
                              #pageheader
                            ></PageHeader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div
                class="panel panel-default"
                [ngClass]="{
                  nonePointerEvents: (!isAMComplted && !isWeeklyCompleted) || !IsWeeklyDisplayed}"
              > -->
              <div
                class="panel panel-default" 
                
              >
              <!-- [ngClass]="{
                  nonePointerEvents: (!isAMComplted && !isWeeklyCompleted) || !IsWeeklyDisplayed}" -->
                <div class="panel-heading" role="tab" id="headingTwoWeekly">
                  <h4 class="panel-title">
                    <a
                      [ngClass]="collapseTwoWeeklyClass"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwoWeekly"
                      aria-expanded="collapseTwoWeeklyariaexpanded"
                      aria-controls="collapseTwoWeekly"
                      (click)="weeklyPanelChange(false)"
                      id="weeklyPanel"
                    >
                      <div class="box-header with-border">
                        <h3 class="box-title container">
                          RO Weekly Testing and Disinfection
                          <!-- <span *ngIf="formLockedOut"> - Lock Out.</span> -->
                          <span *ngIf="isWeeklyCompleted && IsWeeklyDisplayed ">
                            : {{ this.WeeklyUserName }} -
                            {{ WeeklyDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                            Completed</span
                          >
                        </h3>
                      </div>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwoWeekly"
                  [ngClass]="collapseTwoWeeklypanelClass"
                  role="tabpanel"
                  aria-labelledby="headingTwoWeekly"
                >
                  <div class="panel-body">
                    <div class="box-body">
                      <div class="row">
                        <div class="box-row col-sm-12 divider">
                          <div class="col-sm-2">
                            <strong>Softener Verification: </strong>
                          </div>
                          <div class="col-sm-8" style="color:black">
                            (Grains Per Gallon = 5 ml sample, 3 drops of H1, 1
                            drop of H2, grains # = H3 drops)
                          </div>
                          <div class="col-sm-2"></div>
                        </div>

                        <div class="box-row col-sm-12">
                          <div class="col-sm-6">
                            <label class="col-sm-8 control-label" >
                              Hardness Pre-Soft - S1 (gpg):
                            </label>
                            <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                              Hardness Pre-Soft - TBD (gpg):
                            </label>
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.HardnessPreSoft.value == '>8'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.HardnessPreSoft.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.HardnessPreSoft.value == '>8'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="HardnessPreSoft"
                                name="HardnessPreSoft"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="<1" style="color:black"
                                  ><1</option
                                >
                                <option value="1" style="color:black">1</option>
                                <option value="2" style="color:black">2</option>
                                <option value="3" style="color:black">3</option>
                                <option value="4" style="color:black">4</option>
                                <option value="5" style="color:black">5</option>
                                <option value="6" style="color:black">6</option>
                                <option value="7" style="color:black">7</option>
                                <option value=">8" style="color:red">>8</option>
                              </select>
                              <span
                                *ngIf="
                                  f.HardnessPreSoft.errors?.required &&
                                  (f.HardnessPreSoft.dirty ||
                                    f.HardnessPreSoft.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6">
                              <label class="col-sm-8 control-label" >
                                Hardness Pre RO - S14 (gpg):</label
                              >
                              <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                Hardness Pre RO - S11 (gpg):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  (f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut) && isWeeklyCompleted"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.HardnessPreROS14.value == '>3'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.HardnessPreROS14.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                                <select
                                  [style.color]="
                                    f.HardnessPreROS14.value == '>3'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="HardnessPreROS14"
                                  name="HardnessPreROS14"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="<1" style="color:black"
                                    ><1</option
                                  >
                                  <option value="1" style="color:black">1</option>
                                  <option value="2" style="color:black">2</option>
                                  <option value=">3" style="color:red">>3</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.HardnessPreROS14.errors?.required &&
                                    (f.HardnessPreROS14.dirty ||
                                      f.HardnessPreROS14.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                        </div>

                        <!-- <div class="box-row col-sm-12"></div> -->

                        <div class="box-row col-sm-12" *ngIf="isMain3EForm">
                            <div class="col-sm-6">
                                <label class="col-sm-8 control-label">
                                  Hardness Post Softener - S2 (gpg):
                                </label>
                                <div
                                  class="col-sm-4"
                                  *ngIf="isWeeklyCompleted || formLockedOut"
                                >
                                  <label
                                    class="readOnlyText"
                                    [style.color]="
                                      f.HardnessPostSoftenerS2.value == '>3'
                                        ? 'red'
                                        : 'black'
                                    "
                                    >{{ f.HardnessPostSoftenerS2.value }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="!isWeeklyCompleted && !formLockedOut"
                                >
                                  <select
                                    [style.color]="
                                      f.HardnessPostSoftenerS2.value == '>3'
                                        ? 'red'
                                        : 'black'
                                    "
                                    class="form-control mdInput"
                                    formControlName="HardnessPostSoftenerS2"
                                    name="HardnessPostSoftenerS2"
                                  >
                                    <option value="" style="color:black"
                                      >Select</option
                                    >
                                    <option value="<1" style="color:black"
                                      ><1</option
                                    >
                                    <option value="1" style="color:black">1</option>
                                    <option value="2" style="color:black">2</option>
                                    <option value=">3" style="color:red">>3</option>
                                  </select>
                                  <span
                                    *ngIf="
                                      f.HardnessPostSoftenerS2.errors?.required &&
                                      (f.HardnessPostSoftenerS2.dirty ||
                                        f.HardnessPostSoftenerS2.touched ||
                                        isValidFormSubmitted == false)
                                    "
                                    [ngClass]="'error'"
                                  >
                                    Required!
                                  </span>
                                </div>
                              </div>
                          <div class="col-sm-6">
                            <label class="col-sm-8 control-label">
                              Hardness Post Softener - S3 (gpg):</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.HardnessPostSoftenerS3.value == '>3'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.HardnessPostSoftenerS3.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.HardnessPostSoftenerS3.value == '>3'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="HardnessPostSoftenerS3"
                                name="HardnessPostSoftenerS3"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="<1" style="color:black"
                                  ><1</option
                                >
                                <option value="1" style="color:black">1</option>
                                <option value="2" style="color:black">2</option>
                                <option value=">3" style="color:red">>3</option>
                              </select>
                              <span
                                *ngIf="
                                  f.HardnessPostSoftenerS3.errors?.required &&
                                  (f.HardnessPostSoftenerS3.dirty ||
                                    f.HardnessPostSoftenerS3.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>

                        </div>

                        <!-- <div class="box-row col-sm-12"></div> -->

                        <div class="box-row col-sm-12">
                          <div class="col-sm-6">
                            <label class="col-sm-8 control-label">
                              Salt Level Verification:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.SaltLevelVerification.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.SaltLevelVerification.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.SaltLevelVerification.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="SaltLevelVerification"
                                name="SaltLevelVerification"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.SaltLevelVerification.errors?.required &&
                                  (f.SaltLevelVerification.dirty ||
                                    f.SaltLevelVerification.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-6" *ngIf="isMain7Form" >
                              <label class="col-sm-8 control-label">
                                  Softener Tank Position:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="isWeeklyCompleted || formLockedOut"
                              >
                                <label
                                  class="readOnlyText"
                                  >{{ f.SoftenerTankPosition.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                                <select
                                  class="form-control mdInput"
                                  formControlName="SoftenerTankPosition"
                                  name="SoftenerTankPosition"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="1" style="color:black"
                                    >1</option
                                  >
                                  <option value="2" style="color:black"
                                    >2</option
                                  >
                                </select>
                                <span
                                  *ngIf="
                                    f.SoftenerTankPosition.errors?.required &&
                                    (f.SoftenerTankPosition.dirty ||
                                      f.SoftenerTankPosition.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>

                        <div class="box-row col-sm-12  divider">
                          <div class="col-sm-4">
                            <strong>Pre-Treatment Verification: </strong>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12" >
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Booster Pump - P4 (psi):
                            </label>
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.BoosterPumpP4,
                                    60,
                                    80
                                  )
                                }"
                                >{{ f.BoosterPumpP4.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                maxlength="2"
                                class="form-control smInput"
                                formControlName="BoosterPumpP4"
                                (blur)="CheckIfZero('BoosterPumpP4')"
                                (keypress)="numberOnlyWithoutDot($event)"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.BoosterPumpP4,
                                    60,
                                    80
                                  )
                                }"
                                name="BoosterPumpP4"
                              />
                              <span
                                *ngIf="f.BoosterPumpP4.errors?.pattern"
                                [ngClass]="'error'"
                                >##</span
                              >
                              <span
                                *ngIf="
                                  f.BoosterPumpP4.errors?.required &&
                                  (f.BoosterPumpP4.dirty ||
                                    f.BoosterPumpP4.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Post Filter Bank 1 - P5 (psi):
                            </label>
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground:
                                    (f.PostFilterBank1P5.value >
                                      f.BoosterPumpP4.value ||
                                    f.BoosterPumpP4.value -
                                      f.PostFilterBank1P5.value >
                                      10 ) && f.PostFilterBank1P5.value != 'N/A'
                                }"
                                >{{ f.PostFilterBank1P5.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                (blur)="CheckIfZero('PostFilterBank1P5')"
                                (keypress)="numberOnlyWithoutDot($event)"
                                [ngClass]="{
                                  OurOffRange:
                                    f.PostFilterBank1P5.value >
                                      f.BoosterPumpP4.value ||
                                    f.BoosterPumpP4.value -
                                      f.PostFilterBank1P5.value >
                                      10
                                }"
                                type="text"
                                maxlength="2"
                                class="form-control smInput"
                                formControlName="PostFilterBank1P5"
                                name="PostFilterBank1P5"
                              />
                              <span
                                *ngIf="f.PostFilterBank1P5.errors?.pattern"
                                [ngClass]="'error'"
                                >##</span
                              >
                              <span
                                *ngIf="
                                  f.PostFilterBank1P5.errors?.required &&
                                  (f.PostFilterBank1P5.dirty ||
                                    f.PostFilterBank1P5.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label" >
                              Post Carbon Bank 3 - P17 (psi):
                            </label>
                            <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                Post Carbon Bank 3 - P15 (psi):
                              </label>
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.PostCarbonBank3P17,
                                    50,
                                    70
                                  )
                                }"
                                >{{ f.PostCarbonBank3P17.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                (blur)="CheckIfZero('PostCarbonBank3P17')"
                                (keypress)="numberOnlyWithoutDot($event)"
                                maxlength="2"
                                class="form-control smInput"
                                formControlName="PostCarbonBank3P17"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.PostCarbonBank3P17,
                                    50,
                                    70
                                  )
                                }"
                                name="PostCarbonBank3P17"
                              />
                              <span
                                *ngIf="f.PostCarbonBank3P17.errors?.pattern"
                                [ngClass]="'error'"
                                >##</span
                              >
                              <span
                                *ngIf="
                                  f.PostCarbonBank3P17.errors?.required &&
                                  (f.PostCarbonBank3P17.dirty ||
                                    f.PostCarbonBank3P17.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label" >
                              Post Filters Bank 2 - P18 (psi):</label
                            >
                            <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                Post Filters Bank 2 - P16 (psi):</label
                              >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  invalidValueBackground:
                                    f.PostFiltersBank2P18.value >
                                      f.PostCarbonBank3P17.value ||
                                    f.PostCarbonBank3P17.value -
                                      f.PostFiltersBank2P18.value >
                                      10
                                }"
                                >{{ f.PostFiltersBank2P18.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                (blur)="CheckIfZero('PostFiltersBank2P18')"
                                (keypress)="numberOnlyWithoutDot($event)"
                                [ngClass]="{
                                  OurOffRange:
                                    f.PostFiltersBank2P18.value >
                                      f.PostCarbonBank3P17.value ||
                                    f.PostCarbonBank3P17.value -
                                      f.PostFiltersBank2P18.value >
                                      10
                                }"
                                maxlength="2"
                                class="form-control smInput"
                                formControlName="PostFiltersBank2P18"
                                name="PostFiltersBank2P18"
                              />

                              <span
                                *ngIf="
                                  f.PostFiltersBank2P18.errors?.required &&
                                  (f.PostFiltersBank2P18.dirty ||
                                    f.PostFiltersBank2P18.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.PostFiltersBank2P18.errors?.pattern"
                                [ngClass]="'error'"
                                >##</span
                              >
                            </div>
                          </div>
                        </div>

                        <div class="box-row col-sm-12" *ngIf="isMain7Form" >
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                  Switch Break Tank Pumps:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="isWeeklyCompleted || formLockedOut"
                              >
                                <label
                                  class="readOnlyText"
                                  >{{ f.SwitchBreakTankPumps.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                                <select
                                  class="form-control mdInput"
                                  formControlName="SwitchBreakTankPumps"
                                  name="SwitchBreakTankPumps"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="P1" style="color:black"
                                    >P1</option
                                  >
                                  <option value="P2" style="color:black">P2</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.SwitchBreakTankPumps.errors?.required &&
                                    (f.SwitchBreakTankPumps.dirty ||
                                      f.SwitchBreakTankPumps.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="box-row col-sm-12  divider">
                          <div class="col-sm-4">
                            <strong>Testing Verification: </strong>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              pH Pre-Carbon -S1:</label>

                              <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                pH Pre-Carbon -TBD:</label>
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut">
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(f.pHPreCarbonS1, 7, 8) }"
                                >{{ f.pHPreCarbonS1.value | number: "1.1-1" }}</label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut">
                              <input
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: checkRange(f.pHPreCarbonS1, 7, 8)
                                }"
                                maxlength="3"
                                class="form-control smInput"
                                formControlName="pHPreCarbonS1"
                                (blur)="ReplaceLeadingZero('pHPreCarbonS1')"
                                name="pHPreCarbonS1"
                              />
                              <span *ngIf=" f.pHPreCarbonS1.errors?.required && (f.pHPreCarbonS1.dirty || f.pHPreCarbonS1.touched || isValidFormSubmitted == false)"
                                [ngClass]="'error'" >Required!</span>
                              <span *ngIf="f.pHPreCarbonS1.errors?.pattern" [ngClass]="'error'">#.#</span>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label" >
                              pH Post-Carbon -S14:</label
                            >
                            <label class="col-sm-8 control-label"  *ngIf="isMain7Form">
                              pH Post-Carbon -TBD:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [ngClass]="{
                                  invalidValueBackground: checkRange(
                                    f.pHPostCarbonS14,
                                    7,
                                    8
                                  )
                                }"
                                >{{
                                  f.pHPostCarbonS14.value | number: "1.1-1"
                                }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: checkRange(
                                    f.pHPostCarbonS14,
                                    7,
                                    8
                                  )
                                }"
                                maxlength="3"
                                class="form-control smInput"
                                formControlName="pHPostCarbonS14"
                                (blur)="ReplaceLeadingZero('pHPostCarbonS14')"
                                name="pHPostCarbonS14"
                              />

                              <span
                                *ngIf="
                                  f.pHPostCarbonS14.errors?.required &&
                                  (f.pHPostCarbonS14.dirty ||
                                    f.pHPostCarbonS14.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="f.pHPostCarbonS14.errors?.pattern"
                                [ngClass]="'error'"
                                >#.#</span
                              >
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <label class="col-sm-9 control-label" >
                              Iron Total Post Softener - S14 (ug/L):</label
                            >
                            <label class="col-sm-9 control-label" *ngIf="isMain7Form">
                              Iron Total Post Softener - TBD (ug/L):</label
                            >
                            <div
                              class="col-sm-3"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label class="readOnlyText">{{
                                f.IronTotalPostSoftenerS14.value | number: "1.2-2"
                              }}</label>
                            </div>
                            <div
                              class="col-sm-3"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <input
                                (blur)="ReplaceLeadingZero('IronTotalPostSoftenerS14',2)"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="IronTotalPostSoftenerS14"
                                name="IronTotalPostSoftenerS14"
                              />
                              <span
                                *ngIf="
                                  f.IronTotalPostSoftenerS14.errors?.required &&
                                  (f.IronTotalPostSoftenerS14.dirty ||
                                    f.IronTotalPostSoftenerS14.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                              <span
                                *ngIf="
                                  f.IronTotalPostSoftenerS14.errors?.pattern
                                "
                                [ngClass]="'error'"
                                >#.##</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Verify Testing Chemical Levels:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.VerifyTestingChemicalLevels.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{
                                  f.VerifyTestingChemicalLevels.value
                                }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.VerifyTestingChemicalLevels.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="VerifyTestingChemicalLevels"
                                name="VerifyTestingChemicalLevels"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.VerifyTestingChemicalLevels.errors
                                    ?.required &&
                                  (f.VerifyTestingChemicalLevels.dirty ||
                                    f.VerifyTestingChemicalLevels.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Verify CL17 Chemical Levels:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.VerifyCL17ChemicalLevels.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.VerifyCL17ChemicalLevels.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.VerifyCL17ChemicalLevels.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="VerifyCL17ChemicalLevels"
                                name="VerifyCL17ChemicalLevels"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.VerifyCL17ChemicalLevels.errors?.required &&
                                  (f.VerifyCL17ChemicalLevels.dirty ||
                                    f.VerifyCL17ChemicalLevels.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-4"></div>
                        </div>

                        <div class="box-row col-sm-12"></div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12  divider">
                          <div class="col-sm-4">
                            <strong>CWP RO Disinfection: </strong>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              CWP RO Disinfected:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="f.GRWaterprocessDailyrecordsID.value > 0"
                            >
                              <label class="readOnlyText">{{
                                VerifyROlablewithoutOff
                              }}</label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <!-- <select class="form-control mdInput" formControlName="CWPRODisinfected" name="CWPRODisinfected">
                                                                                                <option value="">Select</option>
                                                                                                <option value="3ERO-A">3ERO-A</option>
                                                                                                <option value="3ERO-B">3ERO-B</option>
                                                                                            </select>

                                                                                            <span *ngIf="f.CWPRODisinfected.errors?.required && (f.CWPRODisinfected.dirty || f.CWPRODisinfected.touched ||  isValidFormSubmitted==false)"
                                                                                            [ngClass]="'error'">
                                                                                            Required!
                                                                                        </span> -->
                              <label
                                class="readOnlyText"
                                style="color:black"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0
                                "
                                >{{ VerifyROlablewithoutOff }}</label
                              >
                            </div>
                          </div>

                          <div class="col-sm-8">
                            <label class="col-sm-4 control-label" style="padding-right: 3%;">
                              Disinfection Date:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                              style="padding-left: 0px;">
                              <label class="readOnlyText">
                                {{
                                  f.DisinfectionDate.value.jsdate
                                    | date: "dd/MM/yyyy HH:mm"
                                }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                              style="padding-left: 0px;">
                              <label class="readOnlyText">
                                {{
                                  f.DisinfectionDate.value.jsdate
                                    | date: "dd/MM/yyyy HH:mm"
                                }}</label
                              >
                            </div>
                          </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Chemical Residual Test Result?:</label
                            >
                            <div
                              class="col-sm-4"
                              style="padding-left: 12px;"
                              *ngIf="isWeeklyCompleted || formLockedOut"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.ResidualTestResult.value == 'Positive'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.ResidualTestResult.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              style="padding-left: 12px;"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                              <select
                                [style.color]="
                                  f.ResidualTestResult.value == 'Positive'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="ResidualTestResult"
                                name="ResidualTestResult"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Negative" style="color:black"
                                  >Negative</option
                                >
                                <option value="Positive" style="color:red"
                                  >Positive</option
                                >
                              </select>
                              <span
                                *ngIf="
                                  f.ResidualTestResult.errors?.required &&
                                  (f.ResidualTestResult.dirty ||
                                    f.ResidualTestResult.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <label class="col-sm-4 control-label" style="padding-right: 3%;">
                              Chemical Disinfection User:</label
                            >
                            <div
                              class="col-sm-8"
                              style="padding-left: 0px;">
                              <label class="readOnlyText">{{
                                f.ChemicalDisinfectionUser.value
                              }}</label>
                            </div>
                            <!-- <div
                              class="col-sm-5"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                             <label class="readOnlyText">{{
                                DisinfectionUserName
                              }}</label>
                            </div> -->
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Disinfect Secondary RO:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="(isWeeklyCompleted || formLockedOut) && !IsDisinfectSecondaryRODisplayed"
                            >
                              <label class="readOnlyText">{{
                                f.DisinfectSecondaryRO.value
                              }}</label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                            >
                              <select
                                class="form-control mdInput"
                                formControlName="DisinfectSecondaryRO"
                                name="DisinfectSecondaryRO"
                                (change)="DisinfectSecondaryRODropDownChanged()"
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="NO">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.DisinfectSecondaryRO.errors?.required &&
                                  (f.DisinfectSecondaryRO.dirty ||
                                    f.DisinfectSecondaryRO.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          class="box-row col-sm-12"
                          *ngIf="f.DisinfectSecondaryRO.value == 'Yes'"
                        >
                          <hr />
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              CWP RO Disinfected:</label
                            >
                            <div
                              class="col-sm-4"
                              *ngIf="f.GRWaterprocessDailyrecordsID.value > 0 && !IsDisinfectSecondaryRODisplayed"
                            >
                              <label class="readOnlyText">{{
                                VerifyingCWWPROText
                              }}</label>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                            >
                              <!-- <select class="form-control mdInput" formControlName="CWPRODisinfected" name="CWPRODisinfected">
                                                                                                                    <option value="">Select</option>
                                                                                                                    <option value="3ERO-A">3ERO-A</option>
                                                                                                                    <option value="3ERO-B">3ERO-B</option>
                                                                                                                </select>

                                                                                                                <span *ngIf="f.CWPRODisinfected.errors?.required && (f.CWPRODisinfected.dirty || f.CWPRODisinfected.touched ||  isValidFormSubmitted==false)"
                                                                                                                [ngClass]="'error'">
                                                                                                                Required!
                                                                                                            </span> -->
                              <label
                                class="readOnlyText"
                                style="color:black"
                                *ngIf="
                                  (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut) || IsDisinfectSecondaryRODisplayed
                                "
                                >{{ VerifyingCWWPROText }}</label
                              >
                            </div>
                          </div>

                          <div class="col-sm-8">
                            <label class="col-sm-4 control-label" style="padding-right: 3%;">
                              Disinfection Date:</label
                            >
                            <div
                              class="col-sm-8"
                              *ngIf="(isWeeklyCompleted || formLockedOut) && !IsDisinfectSecondaryRODisplayed"
                              style="padding-left: 0px;">
                              <label class="readOnlyText">
                                {{
                                  f.DisinfectionDateSec.value.jsdate
                                    | date: "dd/MM/yyyy HH:mm"
                                }}</label
                              >
                            </div>
                            <div
                              class="col-sm-8"
                              *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                              style="padding-left: 0px;">
                              <!-- <input (focus)="DisinfectionDates.toggleCalendar()" (click)="DisinfectionDates.openCalendar()"
                                                                                                            class="form-control mdInput"  style="float:none" placeholder="Select date" angular-mydatepicker name="DisinfectionDate"
                                                                                                            formControlName="DisinfectionDate" [options]="myOptions" #DisinfectionDates="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>

                                                                                                            <span  *ngIf="f.DisinfectionDate.errors?.required && (f.DisinfectionDate.dirty || f.DisinfectionDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                                                                              Required!
                                                                                                            </span> -->
                              <label class="readOnlyText">
                                {{
                                  f.DisinfectionDateSec.value.jsdate | date: "dd/MM/yyyy HH:mm"
                                }}</label
                              >
                            </div>
                          </div>
                        </div>

                        <div
                          class="box-row col-sm-12"
                          *ngIf="f.DisinfectSecondaryRO.value == 'Yes'"
                        >
                          <div class="col-sm-4">
                            <label class="col-sm-8 control-label">
                              Chemical Residual Test Result?:</label
                            >
                            <div
                              class="col-sm-4"
                              style="padding-left: 12px;"
                              *ngIf="(isWeeklyCompleted || formLockedOut) && !IsDisinfectSecondaryRODisplayed"
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.ResidualTestResultsec.value == 'Positive'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.ResidualTestResultsec.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              style="padding-left: 12px;"
                              *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                            >
                              <select
                                [style.color]="
                                  f.ResidualTestResultsec.value == 'Positive'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="ResidualTestResultsec"
                                name="ResidualTestResultsec"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Negative" style="color:black"
                                  >Negative</option
                                >
                                <option value="Positive" style="color:red"
                                  >Positive</option
                                >
                              </select>
                              <span
                                *ngIf="
                                  f.ResidualTestResultsec.errors?.required &&
                                  (f.ResidualTestResultsec.dirty ||
                                    f.ResidualTestResultsec.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <label class="col-sm-4 control-label" style="padding-right: 3%;">
                              Chemical Disinfection User:</label
                            >
                            <div
                              class="col-sm-8"
                              style="padding-left: 0px;">
                              <label class="readOnlyText">{{
                                f.ChemicalDisinfectionUserSec.value
                              }}</label>
                            </div>
                            <!-- <div
                              class="col-sm-5"
                              *ngIf="!isWeeklyCompleted && !formLockedOut"
                            >
                             <label class="readOnlyText">{{
                                DisinfectionUserName
                              }}</label>
                            </div> -->
                          </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="box-row col-sm-12">
                          <div class="col-sm-2">
                            <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{WeeklyUserName}} </label></label> -->
                          </div>
                          <div class="col-sm-3">
                            <label class="control-label"></label>
                          </div>
                          <div class="col-sm-5">
                            <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                        &nbsp;&nbsp;
                                        <label class="control-label">{{WeeklyUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                          </div>

                          <div
                            class="col-sm-2"
                            *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                          >
                            <!-- <PageHeader
                              title=""
                              [showNote]="false"
                              [showAddNew]="false"
                              [showSearch]="false"
                              [mainlisturl]=""
                              [showSave]="blnshowSave"
                              (SaveEvent)="
                                executeGRWPDailyCreateUpdate('', 'Weekly')
                              "
                              [showDelete]="false"
                              [showMainListing]="false"
                              #pageheader
                            ></PageHeader> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <!-- [ngStyle]="{ visibility: isNotesShow }" -->
          <notes
            
            [isValidForm]="this.GRDailyDetailForm.invalid"
            (addNoteClick)="executeGRWPDailyCreateUpdate('Notes', '')"
            #notes
          >
          </notes>
          <!-- [ngStyle]="{ visibility: isattachmentShow }" -->
          <attachment
            
            (RaiseError)="AttachmentError($event)"
            #attachment
          >
          </attachment>
        </div>

        <div class="clearfix"></div>
        <PageHeader
          [showNote]="false"
          (addNoteClick)="notes.AddNote()"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeGRWPDailyCreateUpdate('', '')"
          [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()"
          #pageheader1
        ></PageHeader>
      </div>
    </div>
  </section>
</form>

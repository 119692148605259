
import { Component, ViewChild } from '@angular/core';

import { ForumApiService } from '../API/forumapi.service';

import { TableHeadsService } from '../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';


@Component({
  selector: 'ForumSearch',
  templateUrl: './ForumSearch.component.html',

})
export class ForumSearchComponent {

  Categories = [];
  selectedCategory = '';
  constructor(private Forumapi: ForumApiService,     
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  // @ViewChild(PageHeaderComponent) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/Forum/ForumCompose";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "Forum";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch = false;
  title = "Forum";
  searchText = "Search Records"
  defaultSort;



 
  ngOnInit() {
    AppComponent.loading=true;
    this.GetCategoryList();
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#","Topic", "4");
    this.dataGridService.addHeader("Topic", "Topic", "35");
    this.dataGridService.addHeader("Category", "Category", "15");
    this.dataGridService.addHeader("Created by", "CreatedBy", "15");
    this.dataGridService.addHeader("Views", "Views", "10");
    this.dataGridService.addHeader("Replies", "Replies", "10");
    this.dataGridService.addHeader("Lastest", "Latest", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("Message", false);
    this.CheckDirtySearch();
  }

  GetCategoryList() { 
    this.Forumapi.GetForumCategoryDD().subscribe(list => {
      this.Categories = list;
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeForumSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeForumSearch() {
   
    AppComponent.ShowLoader()
    
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var ForumSearch = this.prepareSearch();

    this.Forumapi.GetForumSearchDetails(ForumSearch).subscribe(pagedData => {
    //  console.log(pagedData);
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.forumList;
      AppComponent.HideLoader();
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
         AppComponent.HideLoader();

  }

  prepareSearch() {

    var forumSearch = <any>{};
    if(this.selectedCategory != '')
    {
    forumSearch.CategoryId = this.selectedCategory;
    }
    else
    {
    forumSearch.CategoryId = 0;
    }

    forumSearch.currentPageNumber = this.currentPageNumber;
    forumSearch.sortExpression = this.sortExpression;
    forumSearch.sortDirection = this.sortDirection;
    forumSearch.pageSize = this.pageSize;
    return forumSearch;

  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeForumSearch();
  }
  _onRowClick(row) {

    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }
  executeForumSearchClear() {

     this.selectedCategory = '';
    // this.Address = '';
    // this.State = '';
    // this.Country = '';
    // this.City = '';
    // this.Phone = '';

    this.executeForumSearch();
  }

  RouteClick(value) {
    this.router.navigate([value]);
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;
    if (
      (this.selectedCategory != '' && this.selectedCategory != 'Select')
     ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  //Search & Select //
}


export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

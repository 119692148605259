import { Component, OnInit, ViewChild, ɵConsole, Input } from '@angular/core';
import { DMApiService } from '../../../API/dmapi.service';
import { WPApiService } from '../../../API/wpapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../../Core/ConfirmationDialog';
import { NoteDialog } from '../../../Core/NotesModel/NoteDialog';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../API/auth.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { AcidLevelComponent } from '../../../Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from '../../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { WaterProcessCustomValidators } from '../../../Validator/WaterProcessCustomValidators';
import { NotesComponent } from '../../../Core/Shared/NotesComponent/NotesComponent';
import { CanComponentDeactivate } from '../../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../../globals';
import { WPDailySatelliteDetailComponent } from './../Satellite/WPDailySatelliteDetail.component';
import { WPDailyDetailComponent } from './../SCSCDU/WPDailyDetail.component';
import { debug } from 'util';
// import { SOURCE } from '@angular/core/src/di/injector';

@Component({
  selector: 'WPDailyMain3EDetail',
  templateUrl: './WPDailyMain3EDetail.component.html'
})
export class WPDailyMain3EDetailComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  @ViewChild(AcidLevelComponent, { static: false })
  acidLevel: AcidLevelComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  @ViewChild(WPDailySatelliteDetailComponent, { static: false })
  setelliteDetailForm: WPDailySatelliteDetailComponent;

  @ViewChild(WPDailyDetailComponent, { static: false })
  centreDailyForm : WPDailyDetailComponent;

  @ViewChild(UntypedFormControl, { static: false })
  mainform: UntypedFormControl;
  satelliteDetail
  noteDialogRef: MatDialogRef<NoteDialog>;
  currentUserId = this.auth.GetUserId();
  pageRights = '';
  satelliteUpdated = false;
  divisionShortText = '';
  isValidFormSubmitted = null;

  ListURL = 'WaterProcess/Daily/GRWPDailySearch';

  eventId = '[AutoNumber]';

  public today = new Date();
  loading = true;

  GRWaterprocessDailyrecordsIDvar = '0';

  DialysisUnits = [];

  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();

  RecordDate = '';
  //FormDate = '';

  Status = 'In Progress';
  isNotesShow = 'hidden';
  isattachmentShow = 'hidden';

  @Input() isMainDivison = false;
  @Input() isMain3EForm = false;
  @Input() isMain7Form = false;
  @Input() selectedDialysisUnits='';
  @Input() selectedMainDivision = '';

  isAMComplted = false;
  isMIDComplted = false;
  isPMCompleted = false;
  isWeeklyCompleted = false;
  formLockedOut = false;

  DisinfectionDateLabel;

  isAMCompltedText = '';
  isMIDCompltedText = '';
  isPMCompletedText = '';
  isWeeklyCompletedText = '';

  MainDUName = '';
  DUName = '';



  VerifyROlable = '';
  VerifyROlablewithoutOff = '';
  VerifyingCWWPROText = '';



  Disinfection1Date;
  Disinfection2Date;

  AMDetailsEnteredBy;
  AMDetailsEnteredOn;

  MidDetailsEnteredBy;
  MidDetailsEnteredOn;

  PMDetailsEnteredBy;
  PMDetailsEnteredOn;

  WeeklyDetailsEnteredBy;
  WeeklyDetailsEnteredOn;

  blnshowSave = false;
  blnshowDelete = false;

  errormsgFeedConductivity = '';
  errormsgProductConductivity = '';
  errormsgRecoveryRate = '';
  errormsgRejectionRate = '';
  errormsgTempReturn = '';
  errormsgTempTank = '';

  collapseTwoAMClass = '';
  collapseTwoAMariaexpanded = true;
  collapseTwoAMpanelClass = 'panel-collapse collapse show';

  collapseTwoMidClass = 'collapsed';
  collapseTwoMidariaexpanded = false;
  collapseTwoMidpanelClass = 'panel-collapse collapse';

  collapseTwoPMClass = 'collapsed';
  collapseTwoPMariaexpanded = false;
  collapseTwoPMpanelClass = 'panel-collapse collapse';

  collapseTwoWeeklyClass = 'collapsed';
  collapseTwoWeeklyariaexpanded = false;
  collapseTwoWeeklypanelClass = 'panel-collapse collapse';


  MainDivisions = [
    { value: "", text: "Select" },
    { value: "MAIN 3E", text: "Main 3E" },
    { value: "MAIN 7", text: "Main 7" }
  ];


  selectedVerifyROPresently = '';

  AMUserName = '';
  MIDUserName = '';
  PMUserName = '';
  WeeklyUserName = '';
  UserName = '';
  DisinfectionUserName1 = '';
  DisinfectionUserName2 = '';

  AMUserTime = '';
  MIDUserTime = '';
  PMUserTime = '';
  WeeklyUserTime = '';
  IsWeeklyDisplayed = false;
  IsDisinfectSecondaryRODisplayed = true;
  IsFirstDisinfectionCompleted = false;
  IsSecondDisinfectionCompleted = false;
  DisinfectionStatus = '';
  ROVerificationStatus = '';

  IsValid = false;

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  VerifyROPresentMAIN3E = [
    { value: "", text: "Select" },
    { value: "A", text: "RO-A" },
    { value: "B", text: "RO-B" },
  ];

  VerifyROPresentMAIN7 = [
    { value: "", text: "Select" },
    { value: "A", text: "RO-A" },
    { value: "B", text: "RO-B" },
  ];
  // -------------------------------------End------------------------------------------------

  // Form
  // -------------------------------------Start----------------------------------------------
  GR3E7DailyDetailForm = new UntypedFormGroup({
    GRWaterprocessDailyrecordsID: new UntypedFormControl(''),
    GRWaterProcessailyRecordMain3EId: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedMainDivision: new UntypedFormControl(''),
    AmANLSeqAlaram: new UntypedFormControl(''),
    selectedVerifyROPresently: new UntypedFormControl(''),
    VerifyROReverse: new UntypedFormControl(''),
    VerifyingCWPRO: new UntypedFormControl(''),
    FeedConductivity: new UntypedFormControl(''),
    ProductConductivity: new UntypedFormControl(''),
    DisinfectJug: new UntypedFormControl(''),
    RecoveryRate: new UntypedFormControl(''),
    RejectionRate: new UntypedFormControl(''),
    TempReturn: new UntypedFormControl(''),
    TempTank: new UntypedFormControl(''),
    AmANLSeqAlaramMID: new UntypedFormControl(''),
    AmANLSeqAlaramPM: new UntypedFormControl(''),
    HardnessPreSoft: new UntypedFormControl(''),
    HardnessPostSoftenerS2: new UntypedFormControl(''),
    HardnessPostSoftenerS3: new UntypedFormControl(''),
    HardnessPreROS14: new UntypedFormControl(''),
    SaltLevelVerification: new UntypedFormControl(''),
    BoosterPumpP4: new UntypedFormControl(''),
    PostFilterBank1P5: new UntypedFormControl(''),
    PostCarbonBank3P17: new UntypedFormControl(''),
    PostFiltersBank2P18: new UntypedFormControl(''),
    pHPreCarbonS1: new UntypedFormControl(''),
    pHPostCarbonS14: new UntypedFormControl(''),
    IronTotalPostSoftenerS14: new UntypedFormControl(''),
    VerifyTestingChemicalLevels: new UntypedFormControl(''),
    //VerifyCL17ChemicalLevels: new FormControl(''),
    ChemicalDisinfectionUser: new UntypedFormControl(''),
    CWPRODisinfected: new UntypedFormControl(''),
    DisinfectionDate: new UntypedFormControl(''),
    ResidualTestResult: new UntypedFormControl(''),
    AMSeqALM: this.formBuilder.array([]),
    MidSeqALM: this.formBuilder.array([]),
    PMSeqALM: this.formBuilder.array([]),
    DailyAMFailureDisplay: new UntypedFormControl(''),
    DailyMidFailureDisplay: new UntypedFormControl(''),
    DailyPMFailureDisplay: new UntypedFormControl(''),
    DisinfectSecondaryRO: new UntypedFormControl(''),
    DisinfectionDateSec: new UntypedFormControl(''),
    ChemicalDisinfectionUserSec: new UntypedFormControl(''),
    formDate: new UntypedFormControl('', Validators.required),
    ResidualTestResultsec: new UntypedFormControl(''),
    SoftenerTankPosition: new UntypedFormControl(''),
    SwitchBreakTankPumps: new UntypedFormControl(''),
    curruntDate: new UntypedFormControl(''),
  });

  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private wpapi: WPApiService,
    private formBuilder: UntypedFormBuilder
  ) { }
  // implementation of canDeactivate
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.GR3E7DailyDetailForm.dirty ||
        this.notes.Note != '' ||
        this.attachment.Queue().length > 0) && (this.isValidFormSubmitted == false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          //  console.log('res', res);
          return res;
        })
      );
    } else return true;
  }
  // -------------------------------------End------------------------------------------------


  ngAfterViewChecked() {
    //console.log('scroll');

    this.scrollHelper.doScroll();
    //this.isValidFormSubmitted = false;

  }

  ngOnInit() {

    this.f.selectedDialysisUnits.setValue(this.selectedDialysisUnits);
    this.f.selectedMainDivision.setValue(this.selectedMainDivision);

    this.f.PostFilterBank1P5.disable();
    this.SetPageRights();
    AppComponent.ShowLoader();

    //this.uploader.autoUpload = true;
    this.AMUserName = this.auth.GetUserFLName();
    this.MIDUserName = this.auth.GetUserFLName();
    this.PMUserName = this.auth.GetUserFLName();
    //this.WeeklyUserName = this.auth.GetUserFirstName();
    this.WeeklyUserName = this.auth.GetUserFLName();
    this.UserName = this.auth.GetUserFLName();
    this.DisinfectionUserName1 = this.auth.GetUserFLName();
    this.DisinfectionUserName2 = this.auth.GetUserFLName();

    this.f.ChemicalDisinfectionUser.setValue(this.DisinfectionUserName1);
    this.f.ChemicalDisinfectionUserSec.setValue(this.DisinfectionUserName2);


    this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
      this.AMUserTime = new Date(list).toString();
      this.MIDUserTime = new Date(list).toString();
      this.PMUserTime = new Date(list).toString();
      this.WeeklyUserTime = new Date(list).toString();

      let tempFormDate = new Date(list);
      this.f.formDate.setValue({
        date: {
          year: tempFormDate.getFullYear(),
          month: tempFormDate.getMonth() + 1,
          day: tempFormDate.getDate()
        },
        jsdate: tempFormDate
      });

      this.f.curruntDate.setValue({
        date: {
          year: tempFormDate.getFullYear(),
          month: tempFormDate.getMonth() + 1,
          day: tempFormDate.getDate()
        },
        jsdate: tempFormDate
      });

      this.f.DisinfectionDate.setValue({
        date: {
          year: tempFormDate.getFullYear(),
          month: tempFormDate.getMonth() + 1,
          day: tempFormDate.getDate()
        },
        jsdate: tempFormDate
      });
    });


    var test = this.route.queryParams.subscribe(params => {
      this.f.GRWaterprocessDailyrecordsID.setValue(params['id'] || '0');

      this.setNotesAttachmentComponent();

      if (this.f.GRWaterprocessDailyrecordsID.value != "0") {
        setTimeout(() => {
          this.setModel();
        }, 200);
      } else {
        this.fillDialysisUnit();
      }
    });

    AppComponent.HideLoader();
  }

  setNotesAttachmentComponent() {
    if (this.isMain3EForm || this.isMain7Form) {
      //notes changes
      if (this.notes != undefined) {
        this.notes.showHours = true;
      }

      //attachment changes
      if (this.attachment != undefined) {
        this.attachment.DocId = this.f.GRWaterprocessDailyrecordsID.value;

        this.attachment.moduleName = 'GRWaterprocessDailyrecordsID';
        this.attachment.UploadBy = this.currentUserId;
      }
    }
  }

  addAMAlaramFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      //BankAlaramAM: new FormControl(''),
      VerifiedBankAlaramAM: new UntypedFormControl('Not Tested'),
    });
  }
  addAMAlaramClick(): void {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).push(this.addAMAlaramFormGroup());
  }
  addMidAlaramFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      // BankAlaramMid: new FormControl(''),
      VerifiedBankAlaramMID: new UntypedFormControl('Not Tested'),
    });
  }
  addMidAlaramClick(): void {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).push(this.addMidAlaramFormGroup());
  }
  addPMAlaramFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      //BankAlaramPM: new FormControl(''),
      VerifiedBankAlaramPM: new UntypedFormControl('Not Tested'),
    });
  }
  addPMAlaramClick(): void {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).push(this.addPMAlaramFormGroup());
  }

  get f() {
    return this.GR3E7DailyDetailForm.controls;
  }

  setModel() {

    // if (this.isMain3EForm) {

    AppComponent.ShowLoader();
    // this.RequireWeekly(true);
    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.clearAsyncValidators();

    if (this.attachment)
      this.attachment.clearQueue();

    if (this.notes) {
      this.notes.Note = '';
      this.notes.showHours = true;
    }
    var GRwpdailyCreate = { Id: this.f.GRWaterprocessDailyrecordsID.value };
    this.fillDialysisUnit();

    this.f.AmANLSeqAlaram.clearValidators();
    this.f.AmANLSeqAlaram.updateValueAndValidity();
    while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).length !== 0) {
      (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).removeAt(0);
    }

    this.f.AmANLSeqAlaramMID.clearValidators();
    this.f.AmANLSeqAlaramMID.updateValueAndValidity();

    while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).length !== 0) {
      (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).removeAt(0);
    }

    this.f.AmANLSeqAlaramPM.clearValidators();
    this.f.AmANLSeqAlaramPM.updateValueAndValidity();

    while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).length !== 0) {
      (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).removeAt(0);
    }

    this.wpapi.GRWaterprocessDailyrecordsID(GRwpdailyCreate).subscribe(
      success => {

        if (success) {
          console.log(success);
          this.f.GRWaterprocessDailyrecordsID.setValue(success.grWaterprocessDailyrecordsID);
          this.f.selectedDialysisUnits.setValue(success.divisionId);
          this.f.selectedMainDivision.setValue(success.formType);
          this.Status = success.status;
          this.eventId = success.eventId;

          this.RecordDate = success.recordDate;
          const tempcreatedOn = new Date(success.formDate);

          this.f.formDate.setValue({
            date: {
              year: tempcreatedOn.getFullYear(),
              month: tempcreatedOn.getMonth() + 1,
              day: tempcreatedOn.getDate()
            },
            jsdate: success.formDate
          });


          this.DUName = success.divisionName;
          this.MainDUName = success.formType;



          if (success.formType && success.formType.includes("MAIN")) {

            this.f.GRWaterProcessailyRecordMain3EId.setValue(success.grWaterProcessailyRecordMain3EId);

            //var wpGRDailyObj = { Id: this.f.GRWaterprocessDailyrecordsID.value };
            //console.log(this.f.GRWaterprocessDailyrecordsID.value);


            this.f.AmANLSeqAlaram.setValue(success.dailyAMChlAnlSeq);

            this.f.selectedVerifyROPresently.setValue(success.dailyAMVerifyROPresentlyInUse);

            if (this.f.selectedVerifyROPresently.value != '') {
              let val = this.f.selectedVerifyROPresently.value;

              console.log(val);
              if (val.substring(0, 1) === 'A') {
                if (this.isMain3EForm) {
                  this.VerifyROlable = 'RO-B is Off ?';
                  this.VerifyingCWWPROText = 'RO-A';
                  this.VerifyROlablewithoutOff = 'RO-B';
                }
                else if (this.isMain7Form) {
                  this.VerifyROlable = 'RO-B is Off ?';
                  this.VerifyingCWWPROText = 'RO-A';
                  this.VerifyROlablewithoutOff = 'RO-B';
                }
              }
              else if (val.substring(0, 1) === 'B') {
                if (this.isMain3EForm) {
                  this.VerifyROlable = 'RO-A is Off ?'
                  this.VerifyingCWWPROText = 'RO-B';
                  this.VerifyROlablewithoutOff = 'RO-A';
                }
                else if (this.isMain7Form) {
                  this.VerifyROlable = 'RO-A is Off ?'
                  this.VerifyingCWWPROText = 'RO-B';
                  this.VerifyROlablewithoutOff = 'RO-A';
                }
              }
            }
            else {
              this.VerifyROlable = '';
              this.VerifyingCWWPROText = '';
              this.VerifyROlablewithoutOff = '';
            }

            this.f.VerifyROReverse.setValue(success.dailyAMVerify);
            // this.f.VerifyingCWPRO.setValue(success.dailywaterrecordid);
            //this.selectedVerifyROPresentlyText = this.VerifyROPresentlys.find(o => o.value == success.dailyAMVerifyROPresentlyInUse).text;

            this.f.FeedConductivity.setValue(success.dailyAMCWPROFEEDConductivity);
            this.f.ProductConductivity.setValue(success.dailyAMCWPROPRODUCTConductivity);
            this.f.DisinfectJug.setValue(success.dailyAMCWPRODisinfectJug);
            this.f.RecoveryRate.setValue(success.dailyAMCWPRORecoveryRate);
            this.f.RejectionRate.setValue(success.dailyAMCWPRORejectionRate);
            this.f.TempReturn.setValue(success.dailyAMCWPROTempReturn);
            this.f.TempTank.setValue(success.dailyAMCWPROTempTank);

            this.f.AmANLSeqAlaramMID.setValue(success.dailyMidChlAnlSeq);
            this.f.AmANLSeqAlaramPM.setValue(success.dailyPMChlAnlSeq);


            this.f.HardnessPreSoft.setValue(success.weeklySoftenerVerificationHardnessPreSoftS1 != null ? success.weeklySoftenerVerificationHardnessPreSoftS1 : '');
            this.f.HardnessPostSoftenerS2.setValue(success.weeklySoftenerVerificationHardnessPostSoftenerS2 != null ? success.weeklySoftenerVerificationHardnessPostSoftenerS2 : '');
            this.f.HardnessPostSoftenerS3.setValue(success.weeklySoftenerVerificationHardnessPostSoftenerS3 != null ? success.weeklySoftenerVerificationHardnessPostSoftenerS3 : '');
            this.f.HardnessPreROS14.setValue(success.weeklySoftenerVerificationHardnessPreROS14 != null ? success.weeklySoftenerVerificationHardnessPreROS14 : '');
            this.f.SaltLevelVerification.setValue(success.weeklySoftenerVerificationSaltLevelVerification != null ? success.weeklySoftenerVerificationSaltLevelVerification : '');

            this.f.BoosterPumpP4.setValue(success.weeklyPreTreatmentVerificationBoosterPumpP4);
            this.f.PostFilterBank1P5.setValue(success.weeklyPreTreatmentVerificationPostFilterBank1P5);
            this.f.PostCarbonBank3P17.setValue(success.weeklyPreTreatmentVerificationPostCarbonBank3P17);

            this.f.PostFiltersBank2P18.setValue(success.weeklyPreTreatmentVerificationPostFiltersBank2P18);
            this.f.pHPreCarbonS1.setValue(success.weeklyTestingVerificationpHPreCarbonS1);
            this.f.pHPostCarbonS14.setValue(success.weeklyTestingVerificationpHPostCarbonS14);
            this.f.IronTotalPostSoftenerS14.setValue(success.weeklyTestingVerificationIronTotalPostSoftenerS14);

            this.f.VerifyTestingChemicalLevels.setValue(success.weeklyTestingVerificationVerifyTestingChemicalLevels != null ? success.weeklyTestingVerificationVerifyTestingChemicalLevels : '');
            //this.f.VerifyCL17ChemicalLevels.setValue(success.weeklyTestingVerificationVerifyCL17ChemicalLevels != null ? success.weeklyTestingVerificationVerifyCL17ChemicalLevels : '');
            // this.f.ChemicalDisinfectionUser.setValue(success.weeklyCWPRODisinfectionChemicalDisinfectionser);
            this.f.CWPRODisinfected.setValue(success.weeklyCWPRODisinfectionCWPRODisinfected != null ? success.weeklyCWPRODisinfectionCWPRODisinfected : '');

            //this.f.DisinfectionDate.setValue(success.WeeklyCWPRODisinfectionDisinfectionDate);




            // if (
            //   success.weeklyCWPRODisinfectionDisinfectionDate != undefined &&
            //   success.weeklyCWPRODisinfectionDisinfectionDate != null
            // ) {
            //   // this.f.DisinfectionDate.setValue(success.weeklyCWPRODisinfectionDisinfectionDate);
            //   const tempDateoutateout = new Date(success.weeklyCWPRODisinfectionDisinfectionDate);

            //   this.f.DisinfectionDate.setValue({
            //     date: {
            //       year: tempDateoutateout.getFullYear(),
            //       month: tempDateoutateout.getMonth() + 1,
            //       day: tempDateoutateout.getDate()
            //     },
            //     jsdate: success.weeklyCWPRODisinfectionDisinfectionDate
            //   });
            // }


            this.f.DailyAMFailureDisplay.setValue(success.dailyAMFailureDisplay);
            this.f.DailyMidFailureDisplay.setValue(success.dailyMidFailureDisplay);
            this.f.DailyPMFailureDisplay.setValue(success.dailyPMFailureDisplay);
            this.f.DisinfectSecondaryRO.setValue(success.disinfectSecondaryRO != null ? success.disinfectSecondaryRO : '');
            this.f.ResidualTestResult.setValue(success.weeklyCWPRODisinfectionResidualTestResult != null ? success.weeklyCWPRODisinfectionResidualTestResult : '');
            this.f.ResidualTestResultsec.setValue(success.weeklyCWPROSDisinfectionResidualTestResult != null ? success.weeklyCWPROSDisinfectionResidualTestResult : '');



            // this.VerifyROlablewithoutOff = '';
            // this.VerifyROlablewithoutOff = '';
            // this.VerifyROlablewithoutOff = '';
            // this.VerifyROlablewithoutOff = '';


            this.f.selectedDialysisUnits.setValue(success.divisionId);

            this.AMUserName = success.amUserName;
            this.MIDUserName = success.midUserName;
            this.PMUserName = success.pmUserName;
            this.WeeklyUserName = success.weeklyUserName;
            // this.DisinfectionUserName = success.disinfectionUserName;

            // if (this.DisinfectionUserName === null || this.DisinfectionUserName == '') {
            //   this.DisinfectionUserName = this.auth.GetUserFLName();
            // }

            if (this.WeeklyUserName === null || this.WeeklyUserName == '') {
             // this.WeeklyUserName = this.auth.GetUserFirstName();
                this.WeeklyUserName = this.auth.GetUserFLName();
            }

            // this.AMUserTime = success.amDetailsEnteredOn;
            // this.MIDUserTime = success.midDetailsEnteredOn;
            // this.PMUserTime = success.pmDetailsEnteredOn;
            // this.WeeklyUserTime = success.weeklyDetailsEnteredOn;

            this.UserName = success.amUserName;

            this.AMDetailsEnteredOn = success.amDetailsEnteredOn;
            this.AMDetailsEnteredBy = success.amDetailsEnteredBy;
            this.MidDetailsEnteredOn = success.midDetailsEnteredOn;
            this.MidDetailsEnteredBy = success.midDetailsEnteredBy;
            this.PMDetailsEnteredOn = success.pmDetailsEnteredOn;
            this.PMDetailsEnteredBy = success.pmDetailsEnteredBy;
            this.WeeklyDetailsEnteredOn = success.weeklyDetailsEnteredOn;
            this.WeeklyDetailsEnteredBy = success.weeklyDetailsEnteredBy;


            this.createdOn = new Date(success.createdDate);
            // console.log(this.createdOn);
            // console.log(new Date());

            // console.log('Test');
            // console.log(this.formLockedOut);

            this.Status = success.status;
            this.eventId = success.eventId;

            this.RecordDate = success.recordDate;
            const tempcreatedOn = new Date(success.formDate);

            this.f.formDate.setValue({
              date: {
                year: tempcreatedOn.getFullYear(),
                month: tempcreatedOn.getMonth() + 1,
                day: tempcreatedOn.getDate()
              },
              jsdate: success.formDate
            });


            this.DUName = success.divisionName;
            this.MainDUName = success.formType;
            this.isMainDivison = true;


            if (this.AMDetailsEnteredBy != null) {
              this.isAMComplted = true;
            }
            if (this.MidDetailsEnteredBy != null) {
              this.isMIDComplted = true;
            }
            if (this.PMDetailsEnteredBy != null) {
              this.isPMCompleted = true;
            }

            // if (this.WeeklyDetailsEnteredBy !='0' && success.weeklyCWPRODisinfectionResidualTestResult === 'Negative') {
            if (this.WeeklyDetailsEnteredBy != null) {
              this.isWeeklyCompleted = true;
            }
            else {
              this.isWeeklyCompleted = false;
            }

            if (this.f.selectedDialysisUnits.value === '' && !this.isAMComplted && !this.isMIDComplted && !this.isPMCompleted) {
              this.isNotesShow = 'hidden';
              this.isattachmentShow = 'hidden';
            }
            else {
              this.isNotesShow = 'visible';
              this.isattachmentShow = 'visible';
            }
            //console.log(this.Status);

            setTimeout(() => {
              this.GetWeeklyCompletedDate(this.selectedMainDivision);
            }, 2000);

            if (this.f.AmANLSeqAlaram.value == "NO" && this.isAMComplted) {
              var wpGRDailyAMAlaramObj = { Id: this.f.GRWaterProcessailyRecordMain3EId.value, str: "AM" };

              this.wpapi
                .GetGRWaterprocessDailyBankAlaramByGRWaterProcessailyRecordMain3EId(wpGRDailyAMAlaramObj)
                .subscribe(list => {

                  // console.log(list);
                  if (this.GR3E7DailyDetailForm.get('AMSeqALM')['controls'].length < 5) {
                    this.addAMAlaramClick();
                    this.addAMAlaramClick();
                    this.addAMAlaramClick();
                    this.addAMAlaramClick();

                    if (this.isMain3EForm)
                      this.addAMAlaramClick();
                  }

                  let control = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).controls;
                  if (control != null && control != undefined) {
                    let i = 0;
                    control.forEach(element => {
                      element.get("VerifiedBankAlaramAM").setValue(list[i].chlAnlSeqTotalChlorineforBankAlarm);
                      i = i + 1;
                    });
                  }
                });
            }
            if (this.f.AmANLSeqAlaramMID.value == "NO" && this.isMIDComplted) {
              var wpGRDailyMidAlaramObj = { Id: this.f.GRWaterProcessailyRecordMain3EId.value, str: "MID" };

              this.wpapi
                .GetGRWaterprocessDailyBankAlaramByGRWaterProcessailyRecordMain3EId(wpGRDailyMidAlaramObj)
                .subscribe(list => {

                  //console.log(list);
                  if (this.GR3E7DailyDetailForm.get('MidSeqALM')['controls'].length < 5) {
                    this.addMidAlaramClick();
                    this.addMidAlaramClick();
                    this.addMidAlaramClick();
                    this.addMidAlaramClick();

                    if (this.isMain3EForm)
                      this.addMidAlaramClick();
                  }
                  let control = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).controls;
                  if (control != null && control != undefined) {
                    let i = 0;
                    control.forEach(element => {
                      element.get("VerifiedBankAlaramMID").setValue(list[i].chlAnlSeqTotalChlorineforBankAlarm);
                      i = i + 1;
                    });
                  }
                });
            }

            if (this.f.AmANLSeqAlaramPM.value === "NO" && this.isPMCompleted) {
              var wpGRDailyPMAlaramObj = { Id: this.f.GRWaterProcessailyRecordMain3EId.value, str: "PM" };

              this.wpapi
                .GetGRWaterprocessDailyBankAlaramByGRWaterProcessailyRecordMain3EId(wpGRDailyPMAlaramObj)
                .subscribe(list => {
                  if (this.GR3E7DailyDetailForm.get('PMSeqALM')['controls'].length < 5) {
                    //console.log(list);
                    this.addPMAlaramClick();
                    this.addPMAlaramClick();
                    this.addPMAlaramClick();
                    this.addPMAlaramClick();

                    if (this.isMain3EForm)
                      this.addPMAlaramClick();
                  }
                  let control = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).controls;
                  if (control != null && control != undefined) {
                    let i = 0;
                    control.forEach(element => {
                      element.get("VerifiedBankAlaramPM").setValue(list[i].chlAnlSeqTotalChlorineforBankAlarm);
                      i = i + 1;
                    });
                  }
                });
            }

            var wpGRDailyObjMain3EId = { Id: this.f.GRWaterProcessailyRecordMain3EId.value };

            this.wpapi
              .GetattachemnetsGRWaterprocessDailyrecordsID(wpGRDailyObjMain3EId)
              .subscribe(list => {
                this.attachment.PrevAttachments = list;
              });


            this.wpapi.GetNotesByGRWaterprocessDailyrecordsID(wpGRDailyObjMain3EId).subscribe(list => {
              this.notes.NoteList = list;
            });

            this.HideShowPanle(this.isAMComplted, this.isMIDComplted, this.isPMCompleted);
            AppComponent.HideLoader();

          }
          else {
            setTimeout(() => {
              this.DialysisUnitDropDownChanged();
            });
            AppComponent.HideLoader();
          }
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

  }

  RequireWeekly(isequired) {

    if (isequired && !this.formLockedOut) {
      this.f.HardnessPreSoft.setValidators([Validators.required]);
      this.f.HardnessPreSoft.updateValueAndValidity();

      if (this.isMain3EForm) {
        this.f.HardnessPostSoftenerS2.setValidators([Validators.required]);
        this.f.HardnessPostSoftenerS2.updateValueAndValidity();

        this.f.HardnessPostSoftenerS3.setValidators([Validators.required]);
        this.f.HardnessPostSoftenerS3.updateValueAndValidity();

        this.f.BoosterPumpP4.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
        this.f.BoosterPumpP4.updateValueAndValidity();

        // this.f.PostFilterBank1P5.setValidators([Validators.required,Validators.pattern(Globals.TwoNumberRegex)]);
        // this.f.PostFilterBank1P5.updateValueAndValidity();
      }
      else if (this.isMain7Form) {
        this.f.SoftenerTankPosition.setValidators([Validators.required]);
        this.f.SoftenerTankPosition.updateValueAndValidity();

        this.f.SwitchBreakTankPumps.setValidators([Validators.required]);
        this.f.SwitchBreakTankPumps.updateValueAndValidity();

      }
      this.f.HardnessPreROS14.setValidators([Validators.required]);
      this.f.HardnessPreROS14.updateValueAndValidity();

      this.f.SaltLevelVerification.setValidators([Validators.required]);
      this.f.SaltLevelVerification.updateValueAndValidity();

      this.f.PostCarbonBank3P17.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
      this.f.PostCarbonBank3P17.updateValueAndValidity();

      this.f.PostFiltersBank2P18.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
      this.f.PostFiltersBank2P18.updateValueAndValidity();

      this.f.pHPreCarbonS1.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
      this.f.pHPreCarbonS1.updateValueAndValidity();

      this.f.pHPostCarbonS14.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
      this.f.pHPostCarbonS14.updateValueAndValidity();

      this.f.IronTotalPostSoftenerS14.setValidators([Validators.required, Validators.pattern(Globals.ThreeNumberRegex)]);
      this.f.IronTotalPostSoftenerS14.updateValueAndValidity();

      this.f.VerifyTestingChemicalLevels.setValidators([Validators.required]);
      this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

      // this.f.VerifyCL17ChemicalLevels.setValidators([Validators.required]);
      // this.f.VerifyCL17ChemicalLevels.updateValueAndValidity();

      // this.f.CWPRODisinfected.setValidators([Validators.required]);
      // this.f.CWPRODisinfected.updateValueAndValidity();

      // this.f.ResidualTestResult.setValidators([Validators.required]);
      // this.f.ResidualTestResult.updateValueAndValidity();

      // this.f.DisinfectSecondaryRO.setValidators([Validators.required]);
      // this.f.DisinfectSecondaryRO.updateValueAndValidity();
    }
    else {
      // this.f.AmANLSeqAlaramMID.setValue('');
      // this.f.AmANLSeqAlaramMID.clearValidators();
      // this.f.AmANLSeqAlaramMID.updateValueAndValidity();


      // this.f.AmANLSeqAlaramPM.setValue('');
      // this.f.AmANLSeqAlaramPM.clearValidators();
      // this.f.AmANLSeqAlaramPM.updateValueAndValidity();


      this.f.HardnessPreSoft.clearValidators();
      this.f.HardnessPreSoft.updateValueAndValidity();

      this.f.HardnessPostSoftenerS2.clearValidators();
      this.f.HardnessPostSoftenerS2.updateValueAndValidity();

      this.f.HardnessPostSoftenerS3.clearValidators();
      this.f.HardnessPostSoftenerS3.updateValueAndValidity();

      this.f.SoftenerTankPosition.clearValidators();
      this.f.SoftenerTankPosition.updateValueAndValidity();

      this.f.SwitchBreakTankPumps.clearValidators();
      this.f.SwitchBreakTankPumps.updateValueAndValidity();

      this.f.HardnessPreROS14.clearValidators();
      this.f.HardnessPreROS14.updateValueAndValidity();

      this.f.SaltLevelVerification.clearValidators();
      this.f.SaltLevelVerification.updateValueAndValidity();

      this.f.BoosterPumpP4.clearValidators();
      this.f.BoosterPumpP4.updateValueAndValidity();

      this.f.PostFilterBank1P5.clearValidators();
      this.f.PostFilterBank1P5.updateValueAndValidity();

      this.f.PostCarbonBank3P17.clearValidators();
      this.f.PostCarbonBank3P17.updateValueAndValidity();

      this.f.PostFiltersBank2P18.clearValidators();
      this.f.PostFiltersBank2P18.updateValueAndValidity();

      this.f.pHPreCarbonS1.clearValidators();
      this.f.pHPreCarbonS1.updateValueAndValidity();

      this.f.pHPostCarbonS14.clearValidators();
      this.f.pHPostCarbonS14.updateValueAndValidity();

      this.f.IronTotalPostSoftenerS14.clearValidators();
      this.f.IronTotalPostSoftenerS14.updateValueAndValidity();

      this.f.VerifyTestingChemicalLevels.clearValidators();
      this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

      // this.f.VerifyCL17ChemicalLevels.clearValidators();
      // this.f.VerifyCL17ChemicalLevels.updateValueAndValidity();



      // this.f.CWPRODisinfected.clearValidators();
      // this.f.CWPRODisinfected.updateValueAndValidity();



      this.f.ResidualTestResult.clearValidators();
      this.f.ResidualTestResult.updateValueAndValidity();

      this.f.ResidualTestResultsec.clearValidators();
      this.f.ResidualTestResultsec.updateValueAndValidity();

      this.f.DisinfectSecondaryRO.clearValidators();
      this.f.DisinfectSecondaryRO.updateValueAndValidity();




    }
  }

  HideShowPanle(blnisAMComplted, blnisMIDComplted, blnisPMCompleted) {

    if (blnisAMComplted && blnisMIDComplted && blnisPMCompleted && this.Status != 'Incompleted') {
      this.collapseTwoAMClass = 'collapsed';
      this.collapseTwoAMariaexpanded = false;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse'

      this.collapseTwoMidClass = 'collapsed';
      this.collapseTwoMidariaexpanded = false;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse'

      this.collapseTwoPMClass = 'collapsed';
      this.collapseTwoPMariaexpanded = false;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse'

      if (!this.isWeeklyCompleted && !this.formLockedOut && this.IsWeeklyDisplayed) {
        this.collapseTwoWeeklyClass = '';
        this.collapseTwoWeeklyariaexpanded = true;
        this.collapseTwoWeeklypanelClass = 'panel-collapse collapse show';
      }
      else {
        this.collapseTwoWeeklyClass = 'collapsed';
        this.collapseTwoWeeklyariaexpanded = false;
        this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';
      }
      //this.isWeeklyCompletedText = ' : ' + this.WeeklyUserName + ' - Completed';
      // this.RequireWeekly(true);
    }
    else if (blnisAMComplted && blnisMIDComplted && !blnisPMCompleted && this.Status != 'Incompleted') {
      this.collapseTwoAMClass = 'collapsed';
      this.collapseTwoAMariaexpanded = false;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse';

      this.collapseTwoMidClass = 'collapsed';
      this.collapseTwoMidariaexpanded = false;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse';

      this.collapseTwoPMClass = '';
      this.collapseTwoPMariaexpanded = true;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse show';

      this.collapseTwoWeeklyClass = 'collapsed';
      this.collapseTwoWeeklyariaexpanded = false;
      this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';

      //this.RequireWeekly(false);

    }
    else if (blnisAMComplted && !blnisMIDComplted && !blnisPMCompleted && this.Status != 'Incompleted') {
      this.collapseTwoAMClass = 'collapsed';
      this.collapseTwoAMariaexpanded = false;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse';

      this.collapseTwoMidClass = '';
      this.collapseTwoMidariaexpanded = true;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse show';

      this.collapseTwoPMClass = 'collapsed';
      this.collapseTwoPMariaexpanded = false;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse';

      this.collapseTwoWeeklyClass = 'collapsed';
      this.collapseTwoWeeklyariaexpanded = false;
      this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';

      //this.RequireWeekly(false);
    }
    else if (!blnisAMComplted && !blnisMIDComplted && !blnisPMCompleted) {
      this.collapseTwoAMClass = '';
      this.collapseTwoAMariaexpanded = true;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse show';

      this.collapseTwoMidClass = 'collapsed';
      this.collapseTwoMidariaexpanded = false;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse';

      this.collapseTwoPMClass = 'collapsed';
      this.collapseTwoPMariaexpanded = false;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse';

      this.collapseTwoWeeklyClass = 'collapsed';
      this.collapseTwoWeeklyariaexpanded = false;
      this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';

      // this.RequireWeekly(false);
    }
    else {
      this.collapseTwoAMClass = '';
      this.collapseTwoAMariaexpanded = false;
      this.collapseTwoAMpanelClass = 'panel-collapse collapse';

      this.collapseTwoMidClass = 'collapsed';
      this.collapseTwoMidariaexpanded = false;
      this.collapseTwoMidpanelClass = 'panel-collapse collapse';

      this.collapseTwoPMClass = 'collapsed';
      this.collapseTwoPMariaexpanded = false;
      this.collapseTwoPMpanelClass = 'panel-collapse collapse';

      this.collapseTwoWeeklyClass = 'collapsed';
      this.collapseTwoWeeklyariaexpanded = false;
      this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';
    }
  }


  SetPageRights() {

    this.auth.GetPageRights('GR Water Process Daily Record').subscribe(list => {
      if (this.f.GRWaterprocessDailyrecordsID.value != '0') {
        this.blnshowSave = list[actionType.edit] == '1';
        this.blnshowDelete = false;
      } else {
        this.blnshowSave = true;
        // this.blnshowSave = list[actionType.create] == '1';
        this.blnshowDelete = false;
      }
    });
  }
  //Functions/Methods
  //-------------------------------------Start----------------------------------------------

  fillDialysisUnit() {

    this.dmapi
      .GetDialysisUnitWP()
      .subscribe(
        list => {
          this.DialysisUnits = list;
          //console.log(this.DialysisUnits);
          this.DialysisUnits = this.DialysisUnits.filter(h => h.text != 'Home Program');
          this.DialysisUnits = this.DialysisUnits.filter(h => h.text != 'Off-Unit');
        }
      );
  }

  VerifyROPresentlyDropDownChanged() {
    if (this.f.selectedVerifyROPresently.value != '') {
      let val = this.f.selectedVerifyROPresently.value;
      //this.selectedVerifyROPresentlyText = this.VerifyROPresentlys.find(o => o.value == val).text;
      // console.log(val);
      if (val.substring(0, 1) === 'A') {
        if (this.isMain3EForm) {
          this.VerifyROlable = 'RO-B is Off ?';
          this.VerifyingCWWPROText = 'RO-A';
          this.VerifyROlablewithoutOff = 'RO-B';
        }
        else {
          this.VerifyROlable = 'RO-B is Off ?';
          this.VerifyingCWWPROText = 'RO-A';
          this.VerifyROlablewithoutOff = 'RO-B';
        }
      }
      else if (val.substring(0, 1) === 'B') {
        if (this.isMain3EForm) {
          this.VerifyROlable = 'RO-A is Off ?';
          this.VerifyingCWWPROText = 'RO-B';
          this.VerifyROlablewithoutOff = 'RO-A';
        }
        else {
          this.VerifyROlable = 'RO-A is Off ?';
          this.VerifyingCWWPROText = 'RO-B';
          this.VerifyROlablewithoutOff = 'RO-A';
        }
      }
    }
    else {
      this.VerifyROlable = '';
      this.VerifyingCWWPROText = '';
      this.VerifyROlablewithoutOff = '';

    }
  }




  DialysisUnitDropDownChanged() {

      this.f.selectedMainDivision.setValidators([Validators.required]);
      this.f.selectedMainDivision.setAsyncValidators(
        WaterProcessCustomValidators.GRWaterProcessDivisionValidateWithMain(this.wpapi, this.f.selectedDialysisUnits)
      );
      this.f.selectedMainDivision.updateValueAndValidity();

      this.f.AmANLSeqAlaram.setValidators([Validators.required]);
      this.f.AmANLSeqAlaram.updateValueAndValidity();

      if (this.f.AmANLSeqAlaram.value === "NO") {
        this.f.DailyAMFailureDisplay.setValidators([Validators.required]);
        this.f.DailyAMFailureDisplay.updateValueAndValidity();
      }

      this.f.selectedVerifyROPresently.setValidators([Validators.required]);
      this.f.selectedVerifyROPresently.updateValueAndValidity();

      this.f.VerifyROReverse.setValidators([Validators.required]);
      this.f.VerifyROReverse.updateValueAndValidity();

      this.f.DisinfectJug.setValidators([Validators.required]);
      this.f.DisinfectJug.updateValueAndValidity();

      this.f.FeedConductivity.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSFourPrecisionSingleFraction)]);
      this.f.FeedConductivity.updateValueAndValidity();

      this.f.ProductConductivity.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.ProductConductivity.updateValueAndValidity();

      this.f.RecoveryRate.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.RecoveryRate.updateValueAndValidity();

      this.f.RejectionRate.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.RejectionRate.updateValueAndValidity();

      this.f.TempReturn.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.TempReturn.updateValueAndValidity();

      this.f.TempTank.setValidators([Validators.pattern(Globals.NumberRegexDoublePrecisionSingleFraction)]);
      this.f.TempTank.updateValueAndValidity();



    if (this.f.selectedDialysisUnits.value === '' && !this.isAMComplted && !this.isMIDComplted && !this.isPMCompleted) {
      this.isNotesShow = 'hidden';
      this.isattachmentShow = 'hidden';
      // this.f.selectedMainDivision.setValue('');
    }
    else {
      this.isNotesShow = 'visible';
      this.isattachmentShow = 'visible';
    }
  }

    GetWeeklyCompletedDate(formType) {

    // let RecordDay = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    let RecordDay = this.f.formDate.value.jsdate;
    let formName;
    if(this.MainDUName != '' && this.MainDUName != null)
    {
      formName = this.MainDUName;
    }
    else
    {
      formName = formType
    }
    var formDates = { formDate: RecordDay, formType: formName };
    // console.log(formDates);
    // let curruntDate = new Date();

    // this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
    //   let tempFormDate = new Date(list);
    //   this.f.curruntDate.setValue({
    //     date: {
    //       year: tempFormDate.getFullYear(),
    //       month: tempFormDate.getMonth() + 1,
    //       day: tempFormDate.getDate()
    //     },
    //     jsdate: tempFormDate
    //   });
    // });

    this.wpapi
      .GRWaterprocessGetWeeklyCompletedRecord(formDates)
      .subscribe(list => {


        if (list == null) {

          // RecordDay.setHours(0);
          // RecordDay.setMinutes(0);
          // RecordDay.setSeconds(0);
          // RecordDay.setMilliseconds(0);


          // curruntDate.setHours(0);
          // curruntDate.setMinutes(0);
          // curruntDate.setSeconds(0);
          // curruntDate.setMilliseconds(0);

          // let weekstart = curruntDate.getDate() - curruntDate.getDay() +1;
          // let weekend = weekstart + 5;       // end day is the first day + 6
          // let mondays = new Date(curruntDate.setDate(weekstart));
          // let saturday = new Date(curruntDate.setDate(weekend));

          // console.log(mondays);
          // console.log(saturday);


          if (this.f.formDate.value!='' && this.f.curruntDate.value != '' &&
            new Date(this.f.formDate.value.jsdate).setHours(0, 0, 0, 0) != new Date(this.f.curruntDate.value.jsdate).setHours(0, 0, 0, 0)
            && this.f.ResidualTestResult.value === '') {

            //console.log(RecordDay.toDateString() != curruntDate.toDateString(),RecordDay.toDateString(), curruntDate.toDateString())
            this.formLockedOut = true;
            this.IsWeeklyDisplayed = false;
            this.collapseTwoWeeklyClass = 'collapsed';
            this.collapseTwoWeeklyariaexpanded = false;
            this.collapseTwoWeeklypanelClass = 'panel-collapse collapse';
            this.ctlClearValidity();
          }
          else {
            if (new Date(RecordDay).setHours(0, 0, 0, 0) == new Date(this.f.curruntDate.value.jsdate).setHours(0, 0, 0, 0)) {
              if (this.isAMComplted)
                this.IsWeeklyDisplayed = true;
              else
                this.IsWeeklyDisplayed = false;
            }
          }
        }
        else if (!this.isWeeklyCompleted || list != null) {


          this.isWeeklyCompleted = true;
          if(list.isWeeklyDisplayed == 1)
            this.IsWeeklyDisplayed = true;
          else
            this.IsWeeklyDisplayed = false;
          console.log(list);
          // if (list.WeeklyUserName === null || list.WeeklyUserName == '') {
          //   this.WeeklyUserName = this.auth.GetUserFirstName();
          // }


          this.WeeklyUserName = list.weeklyUserName;

          this.WeeklyDetailsEnteredOn = list.weeklyDetailsEnteredOn;
          this.f.HardnessPreSoft.setValue(list.weeklySoftenerVerificationHardnessPreSoftS1 != null ? list.weeklySoftenerVerificationHardnessPreSoftS1 : '');
          this.f.HardnessPostSoftenerS2.setValue(list.weeklySoftenerVerificationHardnessPostSoftenerS2 != null ? list.weeklySoftenerVerificationHardnessPostSoftenerS2 : '');
          this.f.HardnessPostSoftenerS3.setValue(list.weeklySoftenerVerificationHardnessPostSoftenerS3 != null ? list.weeklySoftenerVerificationHardnessPostSoftenerS3 : '');
          this.f.HardnessPreROS14.setValue(list.weeklySoftenerVerificationHardnessPreROS14 != null ? list.weeklySoftenerVerificationHardnessPreROS14 : '');
          this.f.SaltLevelVerification.setValue(list.weeklySoftenerVerificationSaltLevelVerification != null ? list.weeklySoftenerVerificationSaltLevelVerification : '');
          this.f.SoftenerTankPosition.setValue(list.weeklySoftenerVerificationSoftenerTankPosition_Main7 != null ? list.weeklySoftenerVerificationSoftenerTankPosition_Main7 : '');

          this.f.BoosterPumpP4.setValue(list.weeklyPreTreatmentVerificationBoosterPumpP4);
          if (list.weeklyPreTreatmentVerificationPostFilterBank1P5 == null || list.weeklyPreTreatmentVerificationPostFilterBank1P5 == '' || list.weeklyPreTreatmentVerificationPostFilterBank1P5 == undefined)
            this.f.PostFilterBank1P5.setValue('N/A');
          else
            this.f.PostFilterBank1P5.setValue(list.weeklyPreTreatmentVerificationPostFilterBank1P5);
          this.f.PostCarbonBank3P17.setValue(list.weeklyPreTreatmentVerificationPostCarbonBank3P17);
          this.f.SwitchBreakTankPumps.setValue(list.weeklyPreTreatmentVerificationSwitchBreakTankPumps_Main7 != null ? list.weeklyPreTreatmentVerificationSwitchBreakTankPumps_Main7 : '');

          this.f.PostFiltersBank2P18.setValue(list.weeklyPreTreatmentVerificationPostFiltersBank2P18);
          this.f.pHPreCarbonS1.setValue(list.weeklyTestingVerificationpHPreCarbonS1);
          this.f.pHPostCarbonS14.setValue(list.weeklyTestingVerificationpHPostCarbonS14);
          this.f.IronTotalPostSoftenerS14.setValue(list.weeklyTestingVerificationIronTotalPostSoftenerS14);

          this.f.VerifyTestingChemicalLevels.setValue(list.weeklyTestingVerificationVerifyTestingChemicalLevels != null ? list.weeklyTestingVerificationVerifyTestingChemicalLevels : '');
          //this.f.VerifyCL17ChemicalLevels.setValue(list.weeklyTestingVerificationVerifyCL17ChemicalLevels != null ? list.weeklyTestingVerificationVerifyCL17ChemicalLevels : '');
          this.f.ChemicalDisinfectionUser.setValue(list.weeklyCWPRODisinfectionChemicalDisinfectionser);
          this.f.CWPRODisinfected.setValue(list.weeklyCWPRODisinfectionCWPRODisinfected != null ? list.weeklyCWPRODisinfectionCWPRODisinfected : '');

          this.setROVerificationStatus();

          if (this.f.selectedVerifyROPresently.value != '') {
            let val = this.f.selectedVerifyROPresently.value;

            console.log(val);
            if (val.substring(0, 1) === 'A') {
              if (this.isMain3EForm) {
                this.VerifyROlable = 'RO-B is Off ?';
                this.VerifyingCWWPROText = 'RO-A';
                this.VerifyROlablewithoutOff = 'RO-B';
              }
              else if (this.isMain7Form) {
                this.VerifyROlable = 'RO-B is Off ?';
                this.VerifyingCWWPROText = 'RO-A';
                this.VerifyROlablewithoutOff = 'RO-B';
              }
            }
            else if (val.substring(0, 1) === 'B') {
              if (this.isMain3EForm) {
                this.VerifyROlable = 'RO-A is Off ?'
                this.VerifyingCWWPROText = 'RO-B';
                this.VerifyROlablewithoutOff = 'RO-A';
              }
              else if (this.isMain7Form) {
                this.VerifyROlable = 'RO-A is Off ?'
                this.VerifyingCWWPROText = 'RO-B';
                this.VerifyROlablewithoutOff = 'RO-A';
              }
            }
          }
          else {
            this.VerifyROlable = '';
            this.VerifyingCWWPROText = '';
            this.VerifyROlablewithoutOff = '';
          }

          if (this.f.CWPRODisinfected.value === 'RO-A') {
            this.VerifyingCWWPROText = 'RO-B';
          }
          else if (this.f.CWPRODisinfected.value === 'RO-B') {
            this.VerifyingCWWPROText = 'RO-A';
          }

          if (
            list.weeklyCWPRODisinfectionDisinfectionDate != undefined &&
            list.weeklyCWPRODisinfectionDisinfectionDate != null
          ) {
            // this.f.DisinfectionDate = list.weeklyCWPRODisinfectionDisinfectionDate;
            const tempDateoutateout = new Date(list.weeklyCWPRODisinfectionDisinfectionDate);

            this.f.DisinfectionDate.setValue({
              date: {
                year: tempDateoutateout.getFullYear(),
                month: tempDateoutateout.getMonth() + 1,
                day: tempDateoutateout.getDate()
              },
              jsdate: list.weeklyCWPRODisinfectionDisinfectionDate
            });

            this.IsFirstDisinfectionCompleted = true;
          }

          if (
            list.weeklyCWPROSDisinfectionDisinfectionDate != undefined &&
            list.weeklyCWPROSDisinfectionDisinfectionDate != null
          ) {
            // this.f.DisinfectionDateSec = list.weeklyCWPROSDisinfectionDisinfectionDate;
            const tempDateoutateout = new Date(list.weeklyCWPROSDisinfectionDisinfectionDate);

            this.f.DisinfectionDateSec.setValue({
              date: {
                year: tempDateoutateout.getFullYear(),
                month: tempDateoutateout.getMonth() + 1,
                day: tempDateoutateout.getDate()
              },
              jsdate: list.weeklyCWPROSDisinfectionDisinfectionDate
            });
            this.IsSecondDisinfectionCompleted = true;
          }

          if (list.weeklyCWPRODisinfectionChemicalDisinfectionser == null) {
            this.f.ChemicalDisinfectionUser.setValue(this.DisinfectionUserName1);
          }
          else {
            this.f.ChemicalDisinfectionUser.setValue(list.weeklyCWPRODisinfectionChemicalDisinfectionser);
          }

          if (list.weeklyCWPROSDisinfectionChemicalDisinfectionser == null) {
            this.f.ChemicalDisinfectionUserSec.setValue(this.DisinfectionUserName2);
          }
          else {
            this.f.ChemicalDisinfectionUserSec.setValue(list.weeklyCWPROSDisinfectionChemicalDisinfectionser);
          }

          // this.f.DailyAMFailureDisplay.setValue(list.dailyAMFailureDisplay);
          // this.f.DailyMidFailureDisplay.setValue(list.dailyMidFailureDisplay);
          // this.f.DailyPMFailureDisplay.setValue(list.dailyPMFailureDisplay);
          this.f.DisinfectSecondaryRO.setValue(list.disinfectSecondaryRO != null ? list.disinfectSecondaryRO : '');
          this.f.ResidualTestResult.setValue(list.weeklyCWPRODisinfectionResidualTestResult != null ? list.weeklyCWPRODisinfectionResidualTestResult : '');
          this.f.ResidualTestResultsec.setValue(list.weeklyCWPROSDisinfectionResidualTestResult != null ? list.weeklyCWPROSDisinfectionResidualTestResult : '');


          if (this.f.DisinfectSecondaryRO.value == 'NO' && new Date(list.weeklyDetailsEnteredOn).setHours(0, 0, 0, 0) == new Date(this.f.curruntDate.value.jsdate).setHours(0, 0, 0, 0))
            this.IsDisinfectSecondaryRODisplayed = true;
          else
            this.IsDisinfectSecondaryRODisplayed = false;

          if (this.IsFirstDisinfectionCompleted && this.IsSecondDisinfectionCompleted)
            this.DisinfectionStatus = 'Completed';
          else if (this.IsFirstDisinfectionCompleted && !this.IsSecondDisinfectionCompleted)
            this.DisinfectionStatus = this.VerifyROlablewithoutOff + ' - Partially Completed';
          else
            this.DisinfectionStatus = '';

        }
      });
  }


  executeGRWPDailyCreateUpdate = function (Source, type) {

    let amPanel = document.getElementById("amPanel");
    if (amPanel.className == '' && this.AMDetailsEnteredBy == null && type == '') {
      type = 'AM';
    }

    let midPanel = document.getElementById("midPanel");
    if (midPanel.className == '' && this.MidDetailsEnteredOn == null && type == '') {
      type = 'Mid';
    }

    let pmPanel = document.getElementById("pmPanel");
    if (pmPanel.className == '' && this.PMDetailsEnteredOn == null && type == '') {
      type = 'PM';
    }

    this.setROVerificationStatus();
    let weeklyPanel = document.getElementById("weeklyPanel");
    if (weeklyPanel.className == '' && (this.WeeklyDetailsEnteredOn == null || this.ROVerificationStatus != 'Completed')) {
      type = 'Weekly';
    }


    // var IsValidSoftenerVerification = this.checkValuesForSoftenerVerification();

    // if(IsValidSoftenerVerification == true && Source == 'Notes')
    // {
    //   type = 'Weekly';
    // }

    this.isValidFormSubmitted = false;

    if (type === 'Mid') {
      this.f.AmANLSeqAlaramMID.setValidators([Validators.required]);
      this.f.AmANLSeqAlaramMID.updateValueAndValidity();

      if(this.f.AmANLSeqAlaramMID.value == 'NO'){
        this.f.DailyMidFailureDisplay.setValidators([Validators.required]);
        this.f.DailyMidFailureDisplay.updateValueAndValidity();
      }
    }

    if (type === 'PM') {
      this.f.AmANLSeqAlaramPM.setValidators([Validators.required]);
      this.f.AmANLSeqAlaramPM.updateValueAndValidity();

      if(this.f.AmANLSeqAlaramPM.value == 'NO'){
        this.f.DailyPMFailureDisplay.setValidators([Validators.required]);
        this.f.DailyPMFailureDisplay.updateValueAndValidity();
      }
    }

    if (type === "Weekly") {
      this.weeklyPanelChange(true);
      // this.RequireWeekly(true);
      if (this.f.ResidualTestResult.value === 'Positive') {
        AppComponent.addAlert('This weekly section of the daily water form cannot be saved while there is a �Positive� Chemical Residual Test Result, continue to test for chemical residual until result is Negative', 'error');
        return;
      }
      if (this.f.DisinfectSecondaryRO.value === "Yes") {
        if (this.f.ResidualTestResultsec.value === 'Positive') {
          AppComponent.addAlert('This weekly section of the daily water form cannot be saved while there is a �Positive� Chemical Residual Test Result Secondary, continue to test for chemical residual until result is Negative', 'error');
          return;
        }
      }

    }

    if (type == "") {
      this.f.AmANLSeqAlaramMID.clearValidators();
      this.f.AmANLSeqAlaramMID.updateValueAndValidity();

      this.f.AmANLSeqAlaramPM.clearValidators();
      this.f.AmANLSeqAlaramPM.updateValueAndValidity();
    }

    AppComponent.loading = true;
    console.log(this.GR3E7DailyDetailForm);

    if (Source == 'Disinfection') {
      this.f.ResidualTestResult.setValidators([Validators.required]);
      this.f.ResidualTestResult.updateValueAndValidity();

      this.f.DisinfectSecondaryRO.setValidators([Validators.required]);
      this.f.DisinfectSecondaryRO.updateValueAndValidity();

      if (this.f.DisinfectSecondaryRO.value === "Yes") {
        this.f.ResidualTestResultsec.setValidators([Validators.required]);
        this.f.ResidualTestResultsec.updateValueAndValidity();
      }
    }

    if (
      (this.GR3E7DailyDetailForm.invalid && Source == 'Notes' && (!this.f.GRWaterprocessDailyrecordsID.value || this.f.GRWaterprocessDailyrecordsID.value == '0'))
      || (this.GR3E7DailyDetailForm.invalid && Source != 'Notes')
      || (this.GR3E7DailyDetailForm.invalid && Source == 'Notes' &&
      ((type == 'Mid' && this.f.AmANLSeqAlaramMID.value) ||
      (type == 'PM' && this.f.AmANLSeqAlaramPM.value) ||
      (type == 'Weekly' && this.checkValuesForSoftenerVerification())))
    ) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      window.scrollBy(0, 500);
      // this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      Object.keys(this.GR3E7DailyDetailForm.controls).forEach(key => {
      });
      return;
    }
    if (type === "Weekly"  && this.checkValuesForSoftenerVerification()) {
      this.isWeeklyCompleted = true;
      this.IsWeeklyCompletedvar = true;
    }
    //console.log('BMR');
    this.isValidFormSubmitted = true;
    //var GRWPrecords = this.prepareCreateViewModelGRWPDailyrecords();

    var GRWPMain3E = this.prepareCreateViewModelGRWPMain3E(Source, type);
    if (GRWPMain3E.GRWaterprocessDailyrecordsID == '0') {
      //console.log(GRWPMain3E);
      //console.log('Test');

      this.wpapi.CreateGRWaterprocessDailyRecordsMain(GRWPMain3E).subscribe(
        success => {
          //this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          // if (Source != 'Notes') {
          this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          // } else {
          //   this.f.GRWaterprocessDailyrecordsID.setValue(success.success.gRwaterprocessdailyrecordID);
          //   this.setModel();
          // }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {

      this.wpapi.UpdateGRWaterprocessDailyRecordsMain(GRWPMain3E).subscribe(
        success => {
          //this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source != 'Notes' && Source != 'WeeklySoftner' ) {
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
    // }
  };

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void { }

  mainSave() {
    return new Promise((resolve, reject) => {
      this.executeGRWPDailyCreateUpdate('','');
      resolve(true);
    });
  }

  prepareCreateViewModelGRWPMain3E(source,type) {

    var GRWPMain3E = <any>{};

    GRWPMain3E.GRWaterprocessDailyrecordsID = this.f.GRWaterprocessDailyrecordsID.value;
    GRWPMain3E.EventId = this.eventId;

    GRWPMain3E.DivisionId = this.f.selectedDialysisUnits.value;
    GRWPMain3E.FormType = this.f.selectedMainDivision.value;
    GRWPMain3E.RecordDate = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    GRWPMain3E.FormDate = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    GRWPMain3E.IsDelete = false;

    //GRWPMain3E.createdBy = this.currentUserId;


    if (this.f.GRWaterprocessDailyrecordsID.value === '0') {
      GRWPMain3E.CreatedBy = this.currentUserId;
      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        GRWPMain3E.CreatedDate = new Date(list);
      });
    }
    else {
      GRWPMain3E.CreatedBy = this.createdBy;
      GRWPMain3E.CreatedDate = Globals.GetAPIDate(this.createdOn);
      GRWPMain3E.modifiedBy = this.currentUserId;
      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        GRWPMain3E.modifiedOn = new Date(list);
      });
    }


    if(source !== 'Notes') {
        if(type == 'Weekly' && source == 'Disinfection' && this.IsFirstDisinfectionCompleted == false &&
        this.IsSecondDisinfectionCompleted == false && this.f.DisinfectSecondaryRO.value === "Yes")
        {
          GRWPMain3E.WPDailySectionType = 'Full Disinfection';
        }
        else if (type == 'Weekly' && source == 'Disinfection' && this.f.DisinfectSecondaryRO.value === "Yes")
        {
          GRWPMain3E.WPDailySectionType = 'Disinfection2';
        }
        else if(type == 'Weekly' && source == 'Disinfection')
        {
          GRWPMain3E.WPDailySectionType = 'Disinfection';
        }
        else
        {
          GRWPMain3E.WPDailySectionType = type;
        }

      }
    else
      GRWPMain3E.WPDailySectionType = source;


    GRWPMain3E.GRWaterProcessailyRecordMain3EId = this.f.GRWaterProcessailyRecordMain3EId.value;
    GRWPMain3E.note = this.notes.Note;

    GRWPMain3E.DailyAMChlAnlSeq = this.f.AmANLSeqAlaram.value;
    GRWPMain3E.DailyAMVerifyROPresentlyInUse = this.f.selectedVerifyROPresently.value;
    GRWPMain3E.DailyAMVerify = this.f.VerifyROReverse.value;
    GRWPMain3E.DailyAMCWPROVerifyingCWPRO = this.f.VerifyingCWPRO.value;
    GRWPMain3E.DailyAMCWPRODisinfectJug = this.f.DisinfectJug.value;
    GRWPMain3E.DailyAMCWPROFEEDConductivity = this.f.FeedConductivity.value;
    GRWPMain3E.DailyAMCWPROPRODUCTConductivity = this.f.ProductConductivity.value;
    GRWPMain3E.DailyAMCWPRORecoveryRate = this.f.RecoveryRate.value;
    GRWPMain3E.DailyAMCWPRORejectionRate = this.f.RejectionRate.value;
    GRWPMain3E.DailyAMCWPROTempReturn = this.f.TempReturn.value;
    GRWPMain3E.DailyAMCWPROTempTank = this.f.TempTank.value;


    GRWPMain3E.AMDetailsEnteredBy = this.AMDetailsEnteredBy ? this.AMDetailsEnteredBy : this.currentUserId;
    GRWPMain3E.AMDetailsEnteredOn = this.AMDetailsEnteredOn;

    GRWPMain3E.DailyMidChlAnlSeq = this.f.AmANLSeqAlaramMID.value;
    GRWPMain3E.MidDetailsEnteredBy = this.MidDetailsEnteredBy ? this.MidDetailsEnteredBy : this.currentUserId;
    GRWPMain3E.MidDetailsEnteredOn = this.MidDetailsEnteredOn;


    GRWPMain3E.DailyPMChlAnlSeq = this.f.AmANLSeqAlaramPM.value;
    GRWPMain3E.PMDetailsEnteredBy = this.PMDetailsEnteredBy ? this.PMDetailsEnteredBy : this.currentUserId;
    GRWPMain3E.PMDetailsEnteredOn = this.PMDetailsEnteredOn;

    GRWPMain3E.DailyAMFailureDisplay = this.f.DailyAMFailureDisplay.value;
    GRWPMain3E.DailyPMFailureDisplay = this.f.DailyPMFailureDisplay.value;
    GRWPMain3E.DailyMidFailureDisplay = this.f.DailyMidFailureDisplay.value;

    // if (this.f.ResidualTestResult.value === 'Negative') {
    GRWPMain3E.WeeklySoftenerVerificationHardnessPreSoftS1 = this.f.HardnessPreSoft.value;
    GRWPMain3E.WeeklySoftenerVerificationHardnessPostSoftenerS2 = this.f.HardnessPostSoftenerS2.value;
    GRWPMain3E.WeeklySoftenerVerificationHardnessPostSoftenerS3 = this.f.HardnessPostSoftenerS3.value;
    GRWPMain3E.WeeklySoftenerVerificationHardnessPreROS14 = this.f.HardnessPreROS14.value;
    GRWPMain3E.WeeklySoftenerVerificationSaltLevelVerification = this.f.SaltLevelVerification.value;
    GRWPMain3E.WeeklyPreTreatmentVerificationBoosterPumpP4 = this.f.BoosterPumpP4.value;
    GRWPMain3E.WeeklyPreTreatmentVerificationPostFilterBank1P5 = this.f.PostFilterBank1P5.value;
    GRWPMain3E.WeeklyPreTreatmentVerificationPostCarbonBank3P17 = this.f.PostCarbonBank3P17.value;
    GRWPMain3E.WeeklyPreTreatmentVerificationPostFiltersBank2P18 = this.f.PostFiltersBank2P18.value;
    GRWPMain3E.WeeklyTestingVerificationpHPreCarbonS1 = this.f.pHPreCarbonS1.value;
    GRWPMain3E.WeeklyTestingVerificationpHPostCarbonS14 = this.f.pHPostCarbonS14.value;
    GRWPMain3E.WeeklyTestingVerificationIronTotalPostSoftenerS14 = this.f.IronTotalPostSoftenerS14.value;
    GRWPMain3E.WeeklyTestingVerificationVerifyTestingChemicalLevels = this.f.VerifyTestingChemicalLevels.value;
    //GRWPMain3E.WeeklyTestingVerificationVerifyCL17ChemicalLevels = this.f.VerifyCL17ChemicalLevels.value;

    //MAIN 7 Fields
    GRWPMain3E.WeeklySoftenerVerificationSoftenerTankPosition_Main7 = this.f.SoftenerTankPosition.value;
    GRWPMain3E.WeeklyPreTreatmentVerificationSwitchBreakTankPumps_Main7 = this.f.SwitchBreakTankPumps.value;


    if (source == 'Disinfection' || this.IsFirstDisinfectionCompleted || this.IsSecondDisinfectionCompleted) {
      GRWPMain3E.WeeklyCWPRODisinfectionChemicalDisinfectionser = this.f.ChemicalDisinfectionUser.value;
      GRWPMain3E.WeeklyCWPRODisinfectionCWPRODisinfected = this.VerifyROlablewithoutOff;
      if (this.f.GRWaterprocessDailyrecordsID.value === '0') {
        // GRWPMain3E.WeeklyCWPRODisinfectionDisinfectionDate = this.f.DisinfectionDate.value.jsdate;
      } else {
          if (this.IsFirstDisinfectionCompleted)
                GRWPMain3E.WeeklyCWPRODisinfectionDisinfectionDate = this.f.DisinfectionDate.value.jsdate;
      }
      // GRWPMain3E.WeeklyCWPRODisinfectionDisinfectionDate = Globals.GetAPIDate(this.f.DisinfectionDate.value.jsdate);
      GRWPMain3E.WeeklyCWPRODisinfectionResidualTestResult = this.f.ResidualTestResult.value;

      if (this.f.DisinfectSecondaryRO.value === "Yes") {
        GRWPMain3E.DisinfectSecondaryRO = this.f.DisinfectSecondaryRO.value;

        GRWPMain3E.WeeklyCWPROSDisinfectionChemicalDisinfectionser = this.f.ChemicalDisinfectionUserSec.value;
        GRWPMain3E.WeeklyCWPROSDisinfectionCWPRODisinfected = this.VerifyROlable;
        if (this.f.GRWaterprocessDailyrecordsID.value === '0') {
          // GRWPMain3E.WeeklyCWPROSDisinfectionDisinfectionDate = this.f.DisinfectionDateSec.value.jsdate;
        } else {
            if (this.IsSecondDisinfectionCompleted)
                GRWPMain3E.WeeklyCWPROSDisinfectionDisinfectionDate = this.f.DisinfectionDateSec.value.jsdate;
          }
        // GRWPMain3E.WeeklyCWPROSDisinfectionDisinfectionDate = Globals.GetAPIDate(this.f.DisinfectionDateSec.value.jsdate);

        GRWPMain3E.WeeklyCWPROSDisinfectionResidualTestResult = this.f.ResidualTestResultsec.value;
      }
      else {
        GRWPMain3E.DisinfectSecondaryRO = this.f.DisinfectSecondaryRO.value;
        GRWPMain3E.WeeklyCWPROSDisinfectionChemicalDisinfectionser = null;
        GRWPMain3E.WeeklyCWPROSDisinfectionCWPRODisinfected = null;
        GRWPMain3E.WeeklyCWPROSDisinfectionDisinfectionDate = null;
        GRWPMain3E.WeeklyCWPROSDisinfectionResidualTestResult = null;
      }
    }
    //}

    //if(this.isAMComplted && this.isMIDComplted && this.isPMCompleted)
    // if (this.isAMComplted && this.isWeeklyCompleted && this.WeeklyDetailsEnteredOn!=null) {
    //   GRWPMain3E.WeeklyDetailsEnteredBy = this.currentUserId;
    //   GRWPMain3E.WeeklyDetailsEnteredOn = this.WeeklyDetailsEnteredOn;
    // }
    // else {
    //   GRWPMain3E.WeeklyDetailsEnteredBy = this.currentUserId;
    //   GRWPMain3E.WeeklyDetailsEnteredOn = Globals.GetAPIDate(new Date());
    // }
    GRWPMain3E.WeeklyDetailsEnteredBy = this.WeeklyDetailsEnteredBy ? this.WeeklyDetailsEnteredBy : this.currentUserId;
    GRWPMain3E.WeeklyDetailsEnteredOn = this.WeeklyDetailsEnteredOn;
    GRWPMain3E.IsDelete = false;
    GRWPMain3E.IsWeeklyCompletedvar = this.isWeeklyCompleted;


    if (this.f.GRWaterprocessDailyrecordsID.value === '0') {
      GRWPMain3E.CreatedBy = this.currentUserId;
      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        GRWPMain3E.CreatedDate = new Date(list);
      });
    }
    else {
      GRWPMain3E.ModifyBy = this.currentUserId;
      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        GRWPMain3E.ModifyDate = new Date(list);
      });
    }

    GRWPMain3E.GRWaterprocessDailyBankAlaramDetails = this.GetAllChlAnlAlramValues();
    GRWPMain3E.attachmentDetails = this.attachment.GenerateAttachmentList();
    //console.log(GRWPMain3E);
    return GRWPMain3E;
  }


  checkRange(control, MinValue, MaxValue) {
    if (control.value != '' && control.value != null) {
      //console.log(this.dailyDetailForm);
      return !(control.value >= MinValue && control.value <= MaxValue);
    }
  }

  AmANLSeqAlaramDropDownChanged() {
    if (this.f.AmANLSeqAlaram.value == "NO" && !this.isAMComplted) {
      if (this.GR3E7DailyDetailForm.get('AMSeqALM')['controls'].length < 5) {
        this.addAMAlaramClick();
        this.addAMAlaramClick();
        this.addAMAlaramClick();
        this.addAMAlaramClick();

        if (this.isMain3EForm)
          this.addAMAlaramClick();
      }

      this.f.DailyAMFailureDisplay.setValidators([Validators.required]);
      this.f.DailyAMFailureDisplay.updateValueAndValidity();


      // let control = (<FormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).controls;
      // if(control!= null && control!=undefined)
      // {
      //   control.forEach(element => {
      //       element.get("VerifiedBankAlaramAM").setValidators([Validators.required]);
      //       element.get("VerifiedBankAlaramAM").updateValueAndValidity();
      //     });
      // }
    }
    else {


      this.f.DailyAMFailureDisplay.setValue('');
      this.f.DailyAMFailureDisplay.clearValidators();
      this.f.DailyAMFailureDisplay.clearAsyncValidators();
      this.f.DailyAMFailureDisplay.updateValueAndValidity();

      while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).length !== 0) {
        (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).removeAt(0);
      }
    }
  }
  AmANLSeqAlaramMIDDropDownChanged() {
    if (this.f.AmANLSeqAlaramMID.value == "NO" && !this.isMIDComplted) {

      if (this.GR3E7DailyDetailForm.get('MidSeqALM')['controls'].length < 5) {
        this.addMidAlaramClick();
        this.addMidAlaramClick();
        this.addMidAlaramClick();
        this.addMidAlaramClick();

        if (this.isMain3EForm)
          this.addMidAlaramClick();
      }

      this.f.AmANLSeqAlaramMID.setValidators([Validators.required]);
      this.f.AmANLSeqAlaramMID.updateValueAndValidity();

      this.f.DailyMidFailureDisplay.setValidators([Validators.required]);
      this.f.DailyMidFailureDisplay.updateValueAndValidity();

      // let control = (<FormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).controls;
      // if(control!= null && control!=undefined)
      // {
      //   control.forEach(element => {
      //       element.get("VerifiedBankAlaramMID").setValidators([Validators.required]);
      //       element.get("VerifiedBankAlaramMID").updateValueAndValidity();
      //     });
      // }
    }
    else {
      // this.f.AmANLSeqAlaramMID.clearValidators();
      // this.f.AmANLSeqAlaramMID.updateValueAndValidity();

      this.f.DailyMidFailureDisplay.setValue('');
      this.f.DailyMidFailureDisplay.clearValidators();
      this.f.DailyMidFailureDisplay.clearAsyncValidators();
      this.f.DailyMidFailureDisplay.updateValueAndValidity();

      while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).length !== 0) {
        (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).removeAt(0);
      }
    }
  }

  AmANLSeqAlaramPMDropDownChanged() {
    if (this.f.AmANLSeqAlaramPM.value == "NO" && !this.isPMCompleted) {

      if (this.GR3E7DailyDetailForm.get('PMSeqALM')['controls'].length < 5) {
        this.addPMAlaramClick();
        this.addPMAlaramClick();
        this.addPMAlaramClick();
        this.addPMAlaramClick();

        if (this.isMain3EForm)
          this.addPMAlaramClick();

      }

      this.f.AmANLSeqAlaramPM.setValidators([Validators.required]);
      this.f.AmANLSeqAlaramPM.updateValueAndValidity();

      this.f.DailyPMFailureDisplay.setValidators([Validators.required]);
      this.f.DailyPMFailureDisplay.updateValueAndValidity();

      // let control = (<FormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).controls;
      // if(control!= null && control!=undefined)
      // {
      //   control.forEach(element => {
      //       element.get("VerifiedBankAlaramPM").setValidators([Validators.required]);
      //       element.get("VerifiedBankAlaramPM").updateValueAndValidity();
      //     });
      // }
    }
    else {
      // this.f.AmANLSeqAlaramPM.clearValidators();
      // this.f.AmANLSeqAlaramPM.updateValueAndValidity();

      this.f.DailyPMFailureDisplay.setValue('');
      this.f.DailyPMFailureDisplay.clearValidators();
      this.f.DailyPMFailureDisplay.updateValueAndValidity();

      while ((<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).length !== 0) {
        (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).removeAt(0);
      }
    }
  }


  GetAllChlAnlAlramValues() {
    let GRWaterprocessDailyBankAlaramDetailslst = [];
    if (this.f.AmANLSeqAlaram.value === "NO" && !this.isAMComplted) {
      if (this.f.GRWaterprocessDailyrecordsID.value === '0') {
        let control = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).controls;

        if (control != null && control != undefined) {
          let i = 1;
          control.forEach(element => {
            //       console.log(element);
            var GRWaterprocessDailyBankAlaramDetails = <any>{};
            GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqId = null;
            GRWaterprocessDailyBankAlaramDetails.GRWaterProcessailyRecordMain3EId = this.f.GRWaterProcessailyRecordMain3EId.value;
            GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqBankAlarm = "0" + i;
            GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqTotalChlorineforBankAlarm = element.get("VerifiedBankAlaramAM").value;
            GRWaterprocessDailyBankAlaramDetails.TypeOfAlaram = 'AM';
            GRWaterprocessDailyBankAlaramDetails.CreatedBy = this.currentUserId;
            GRWaterprocessDailyBankAlaramDetails.ModifyBy = this.currentUserId;
            this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
              GRWaterprocessDailyBankAlaramDetails.CreatedDate = new Date(list);
              GRWaterprocessDailyBankAlaramDetails.ModifyDate = new Date(list);
            });
            //console.log( element.get("VerifiedBankAlaramAM").value);
            GRWaterprocessDailyBankAlaramDetailslst.push(GRWaterprocessDailyBankAlaramDetails);
            i = i + 1;
          });

          // console.log(GRWaterprocessDailyBankAlaramDetailslst);
        }
      }
    }

    if (this.f.AmANLSeqAlaramMID.value === "NO" && !this.isMIDComplted) {


      let controlMid = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).controls;

      if (controlMid != null && controlMid != undefined) {
        let j = 1;
        controlMid.forEach(elementMid => {
          //console.log(elementMid);
          var GRWaterprocessDailyBankAlaramDetails = <any>{};
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqId = null;
          GRWaterprocessDailyBankAlaramDetails.GRWaterProcessailyRecordMain3EId = this.f.GRWaterProcessailyRecordMain3EId.value;
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqBankAlarm = "0" + j;
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqTotalChlorineforBankAlarm = elementMid.get("VerifiedBankAlaramMID").value;
          GRWaterprocessDailyBankAlaramDetails.TypeOfAlaram = 'MID';
          GRWaterprocessDailyBankAlaramDetails.CreatedBy = this.currentUserId;
          GRWaterprocessDailyBankAlaramDetails.ModifyBy = this.currentUserId;

          this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
            GRWaterprocessDailyBankAlaramDetails.CreatedDate = new Date(list);
            GRWaterprocessDailyBankAlaramDetails.ModifyDate = new Date(list);
          });
          j = j + 1;
          GRWaterprocessDailyBankAlaramDetailslst.push(GRWaterprocessDailyBankAlaramDetails);

        });
      }
    }

    if (this.f.AmANLSeqAlaramPM.value === "NO" && !this.isPMCompleted) {

      let controlPM = (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).controls;

      if (controlPM != null && controlPM != undefined) {
        let k = 1;

        controlPM.forEach(elementPM => {
          //console.log(elementPM);
          var GRWaterprocessDailyBankAlaramDetails = <any>{};
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqId = null;
          GRWaterprocessDailyBankAlaramDetails.GRWaterProcessailyRecordMain3EId = this.f.GRWaterProcessailyRecordMain3EId.value;
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqBankAlarm = "0" + k;
          GRWaterprocessDailyBankAlaramDetails.ChlAnlSeqTotalChlorineforBankAlarm = elementPM.get("VerifiedBankAlaramPM").value;
          GRWaterprocessDailyBankAlaramDetails.TypeOfAlaram = 'PM';
          GRWaterprocessDailyBankAlaramDetails.CreatedBy = this.currentUserId;
          GRWaterprocessDailyBankAlaramDetails.ModifyBy = this.currentUserId;

          this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
            GRWaterprocessDailyBankAlaramDetails.CreatedDate = new Date(list);
            GRWaterprocessDailyBankAlaramDetails.ModifyDate = new Date(list);
          });
          k = k + 1;
          GRWaterprocessDailyBankAlaramDetailslst.push(GRWaterprocessDailyBankAlaramDetails);
        });
      }

    }
    return GRWaterprocessDailyBankAlaramDetailslst;

  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        var wpDailyObj = { Id: this.f.GRWaterprocessDailyrecordsID.value };
        this.wpapi.DeleteGRWaterProcessDailyRecord(wpDailyObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Water process daily record deleted successfully!',
              'success'
            );
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }




  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }

  DeleteAmBankAlaram(item) {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('AMSeqALM')).removeAt(item);
  }


  DeleteMidBankAlaram(item) {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('MidSeqALM')).removeAt(item);
  }

  DeletePMBankAlaram(item) {
    (<UntypedFormArray>this.GR3E7DailyDetailForm.get('PMSeqALM')).removeAt(item);
  }

  formatDecimal(val) {
    //let val = this.f[ctl].value;
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        val = val + ".0"
      }
      return val;
    }
  }

  ReplaceLeadingZero(ctl, count = 1) {

    let val = this.f[ctl].value;
    val = val.replace(/^0+/, '');

    if (val == 0)
      val = "";

    if (val < 1) {
      let valDecimal = this.decimalPlaces(val);
      if (valDecimal === 1) {
        val = "0" + val;
      }
      if (valDecimal === 2 && count === 2) {
        val = "0" + val;
      }
    }
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        if (ctl == 'IronTotalPostSoftenerS14')
          val = val + ".00";
        else
          val = val + ".0";
      }
      else if (Number.isInteger(parseFloat(val)) && val.indexOf('0.') === -1 && val.indexOf('.') !== -1 && val.indexOf('.0') === -1) {
        if (ctl == 'IronTotalPostSoftenerS14')
          val = val + "00";
        else
          val = val + "0";
      }
      else if (val.indexOf('.') == 1 && val.length == 3 && ctl == 'IronTotalPostSoftenerS14') {
        val = val + "0";
      }
    }
    this.f[ctl].setValue(val);
  }

  CheckIfZero(ctl, count = 1) {
    let val = this.f[ctl].value;
    val = val.replace(/^0+/, '');

    if (val == 0)
      val = "";

    this.f[ctl].setValue(val);
  }


  decimalPlaces(num) {
    var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) { return 0; }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  numberOnlyWithoutDot(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  ctlClearValidity() {
    this.f.GRWaterprocessDailyrecordsID.clearValidators();
    this.f.GRWaterprocessDailyrecordsID.updateValueAndValidity();

    this.f.GRWaterProcessailyRecordMain3EId.clearValidators();
    this.f.GRWaterProcessailyRecordMain3EId.updateValueAndValidity();

    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.updateValueAndValidity();

    this.f.selectedMainDivision.clearValidators();
    this.f.selectedMainDivision.updateValueAndValidity();

    this.f.AmANLSeqAlaram.clearValidators();
    this.f.AmANLSeqAlaram.updateValueAndValidity();

    this.f.selectedVerifyROPresently.clearValidators();
    this.f.selectedVerifyROPresently.updateValueAndValidity();

    this.f.VerifyROReverse.clearValidators();
    this.f.VerifyROReverse.updateValueAndValidity();

    this.f.VerifyingCWPRO.clearValidators();
    this.f.VerifyingCWPRO.updateValueAndValidity();

    this.f.FeedConductivity.clearValidators();
    this.f.FeedConductivity.updateValueAndValidity();

    this.f.ProductConductivity.clearValidators();
    this.f.ProductConductivity.updateValueAndValidity();

    this.f.DisinfectJug.clearValidators();
    this.f.DisinfectJug.updateValueAndValidity();

    this.f.RecoveryRate.clearValidators();
    this.f.RecoveryRate.updateValueAndValidity();

    this.f.RejectionRate.clearValidators();
    this.f.RejectionRate.updateValueAndValidity();

    this.f.TempReturn.clearValidators();
    this.f.TempReturn.updateValueAndValidity();

    this.f.TempTank.clearValidators();
    this.f.TempTank.updateValueAndValidity();

    this.f.AmANLSeqAlaramMID.clearValidators();
    this.f.AmANLSeqAlaramMID.updateValueAndValidity();

    this.f.AmANLSeqAlaramPM.clearValidators();
    this.f.AmANLSeqAlaramPM.updateValueAndValidity();

    this.f.HardnessPreSoft.clearValidators();
    this.f.HardnessPreSoft.updateValueAndValidity();

    this.f.HardnessPostSoftenerS2.clearValidators();
    this.f.HardnessPostSoftenerS2.updateValueAndValidity();

    this.f.HardnessPostSoftenerS3.clearValidators();
    this.f.HardnessPostSoftenerS3.updateValueAndValidity();

    this.f.SoftenerTankPosition.clearValidators();
    this.f.SoftenerTankPosition.updateValueAndValidity();

    this.f.SwitchBreakTankPumps.clearValidators();
    this.f.SwitchBreakTankPumps.updateValueAndValidity();

    this.f.HardnessPreROS14.clearValidators();
    this.f.HardnessPreROS14.updateValueAndValidity();

    this.f.SaltLevelVerification.clearValidators();
    this.f.SaltLevelVerification.updateValueAndValidity();

    this.f.BoosterPumpP4.clearValidators();
    this.f.BoosterPumpP4.updateValueAndValidity();

    this.f.PostFilterBank1P5.clearValidators();
    this.f.PostFilterBank1P5.updateValueAndValidity();

    this.f.PostCarbonBank3P17.clearValidators();
    this.f.PostCarbonBank3P17.updateValueAndValidity();

    this.f.PostFiltersBank2P18.clearValidators();
    this.f.PostFiltersBank2P18.updateValueAndValidity();

    this.f.pHPreCarbonS1.clearValidators();
    this.f.pHPreCarbonS1.updateValueAndValidity();

    this.f.pHPostCarbonS14.clearValidators();
    this.f.pHPostCarbonS14.updateValueAndValidity();

    this.f.IronTotalPostSoftenerS14.clearValidators();
    this.f.IronTotalPostSoftenerS14.updateValueAndValidity();


    this.f.VerifyTestingChemicalLevels.clearValidators();
    this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

    // this.f.VerifyCL17ChemicalLevels.clearValidators();
    // this.f.VerifyCL17ChemicalLevels.updateValueAndValidity();



    this.f.CWPRODisinfected.clearValidators();
    this.f.CWPRODisinfected.updateValueAndValidity();



    this.f.ResidualTestResult.clearValidators();
    this.f.ResidualTestResult.updateValueAndValidity();

    this.f.DailyAMFailureDisplay.clearValidators();
    this.f.DailyAMFailureDisplay.updateValueAndValidity();

    this.f.DailyMidFailureDisplay.clearValidators();
    this.f.DailyMidFailureDisplay.updateValueAndValidity();

    this.f.DailyPMFailureDisplay.clearValidators();
    this.f.DailyPMFailureDisplay.updateValueAndValidity();

    this.f.DisinfectSecondaryRO.clearValidators();
    this.f.DisinfectSecondaryRO.updateValueAndValidity();

    this.f.formDate.clearValidators();
    this.f.formDate.updateValueAndValidity();

    this.f.ResidualTestResultsec.clearValidators();
    this.f.ResidualTestResultsec.updateValueAndValidity();
  }

  DisinfectSecondaryRODropDownChanged() {
    if (this.f.DisinfectSecondaryRO.value === "Yes") {
      // this.f.ResidualTestResultsec.setValidators([Validators.required]);
      // this.f.ResidualTestResultsec.updateValueAndValidity();

      this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
        let tempFormDate = new Date(list);
        this.f.DisinfectionDateSec.setValue({
          date: {
            year: tempFormDate.getFullYear(),
            month: tempFormDate.getMonth() + 1,
            day: tempFormDate.getDate()
          },
          jsdate: tempFormDate
        });
      });

    }
    else {
      this.f.ResidualTestResultsec.clearValidators();
      this.f.ResidualTestResultsec.updateValueAndValidity();
    }
  }

  weeklyPanelChange(isManual) {

    let elm = document.getElementById("weeklyPanel");
    if ((elm.className == 'collapsed' && this.WeeklyDetailsEnteredBy == null) || isManual == true) {
      this.f.HardnessPreSoft.setValidators([Validators.required]);
      this.f.HardnessPreSoft.updateValueAndValidity();

      if (this.isMain3EForm) {
        this.f.HardnessPostSoftenerS2.setValidators([Validators.required]);
        this.f.HardnessPostSoftenerS2.updateValueAndValidity();

        this.f.HardnessPostSoftenerS3.setValidators([Validators.required]);
        this.f.HardnessPostSoftenerS3.updateValueAndValidity();

        this.f.BoosterPumpP4.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
        this.f.BoosterPumpP4.updateValueAndValidity();

        // this.f.PostFilterBank1P5.setValidators([Validators.required,Validators.pattern(Globals.TwoNumberRegex)]);
        // this.f.PostFilterBank1P5.updateValueAndValidity();

      }
      else if (this.isMain7Form) {
        this.f.SoftenerTankPosition.setValidators([Validators.required]);
        this.f.SoftenerTankPosition.updateValueAndValidity();

        this.f.SwitchBreakTankPumps.setValidators([Validators.required]);
        this.f.SwitchBreakTankPumps.updateValueAndValidity();
      }
      this.f.HardnessPreROS14.setValidators([Validators.required]);
      this.f.HardnessPreROS14.updateValueAndValidity();

      this.f.SaltLevelVerification.setValidators([Validators.required]);
      this.f.SaltLevelVerification.updateValueAndValidity();

      this.f.PostCarbonBank3P17.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
      this.f.PostCarbonBank3P17.updateValueAndValidity();

      this.f.PostFiltersBank2P18.setValidators([Validators.required, Validators.pattern(Globals.TwoNumberRegex)]);
      this.f.PostFiltersBank2P18.updateValueAndValidity();

      this.f.pHPreCarbonS1.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
      this.f.pHPreCarbonS1.updateValueAndValidity();

      this.f.pHPostCarbonS14.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionSingleFraction)]);
      this.f.pHPostCarbonS14.updateValueAndValidity();

      this.f.IronTotalPostSoftenerS14.setValidators([Validators.required, Validators.pattern(Globals.NumberRegexSinglePrecisionDoubleFraction)]);
      this.f.IronTotalPostSoftenerS14.updateValueAndValidity();

      this.f.VerifyTestingChemicalLevels.setValidators([Validators.required]);
      this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

      // this.f.VerifyCL17ChemicalLevels.setValidators([Validators.required]);
      // this.f.VerifyCL17ChemicalLevels.updateValueAndValidity();

      // this.f.CWPRODisinfected.setValidators([Validators.required]);
      // this.f.CWPRODisinfected.updateValueAndValidity();

      // this.f.ResidualTestResult.setValidators([Validators.required]);
      // this.f.ResidualTestResult.updateValueAndValidity();

      // this.f.DisinfectSecondaryRO.setValidators([Validators.required]);
      // this.f.DisinfectSecondaryRO.updateValueAndValidity();
    }
    else {
      // this.f.AmANLSeqAlaramMID.setValue('');
      // this.f.AmANLSeqAlaramMID.clearValidators();
      // this.f.AmANLSeqAlaramMID.updateValueAndValidity();


      // this.f.AmANLSeqAlaramPM.setValue('');
      // this.f.AmANLSeqAlaramPM.clearValidators();
      // this.f.AmANLSeqAlaramPM.updateValueAndValidity();


      this.f.HardnessPreSoft.clearValidators();
      this.f.HardnessPreSoft.updateValueAndValidity();

      this.f.HardnessPostSoftenerS2.clearValidators();
      this.f.HardnessPostSoftenerS2.updateValueAndValidity();

      this.f.HardnessPostSoftenerS3.clearValidators();
      this.f.HardnessPostSoftenerS3.updateValueAndValidity();

      this.f.HardnessPreROS14.clearValidators();
      this.f.HardnessPreROS14.updateValueAndValidity();

      this.f.SaltLevelVerification.clearValidators();
      this.f.SaltLevelVerification.updateValueAndValidity();

      this.f.BoosterPumpP4.clearValidators();
      this.f.BoosterPumpP4.updateValueAndValidity();

      this.f.PostFilterBank1P5.clearValidators();
      this.f.PostFilterBank1P5.updateValueAndValidity();

      this.f.PostCarbonBank3P17.clearValidators();
      this.f.PostCarbonBank3P17.updateValueAndValidity();

      this.f.PostFiltersBank2P18.clearValidators();
      this.f.PostFiltersBank2P18.updateValueAndValidity();

      this.f.pHPreCarbonS1.clearValidators();
      this.f.pHPreCarbonS1.updateValueAndValidity();

      this.f.pHPostCarbonS14.clearValidators();
      this.f.pHPostCarbonS14.updateValueAndValidity();

      this.f.IronTotalPostSoftenerS14.clearValidators();
      this.f.IronTotalPostSoftenerS14.updateValueAndValidity();

      this.f.VerifyTestingChemicalLevels.clearValidators();
      this.f.VerifyTestingChemicalLevels.updateValueAndValidity();

      // this.f.VerifyCL17ChemicalLevels.clearValidators();
      // this.f.VerifyCL17ChemicalLevels.updateValueAndValidity();



      // this.f.CWPRODisinfected.clearValidators();
      // this.f.CWPRODisinfected.updateValueAndValidity();



      this.f.ResidualTestResult.clearValidators();
      this.f.ResidualTestResult.updateValueAndValidity();

      this.f.ResidualTestResultsec.clearValidators();
      this.f.ResidualTestResultsec.updateValueAndValidity();

      this.f.DisinfectSecondaryRO.clearValidators();
      this.f.DisinfectSecondaryRO.updateValueAndValidity();
    }
  }

  setROVerificationStatus() {

      if(this.f.HardnessPreSoft.value &&
        this.f.HardnessPreROS14.value &&
        this.f.SaltLevelVerification.value &&
        this.f.SoftenerTankPosition.value &&
        this.f.PostCarbonBank3P17.value &&
        this.f.PostFiltersBank2P18.value &&
        this.f.SwitchBreakTankPumps.value &&
        this.f.pHPreCarbonS1.value &&
        this.f.pHPostCarbonS14.value &&
        this.f.IronTotalPostSoftenerS14.value &&
        this.f.VerifyTestingChemicalLevels.value )
      {
        this.ROVerificationStatus = 'Completed';
      }
      else
      {
        this.ROVerificationStatus = 'Incompleted';
      }
  }

  setMessageForDisinfection() {

    if(this.WeeklyDetailsEnteredOn != null)
    {

      if(this.f.ResidualTestResult.value == "" && this.f.DisinfectSecondaryRO.value == "")
      {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'Disinfection is not performed for this weekly record! Do you want to Save it without Disinfection?.';
        this.matDialogRef.afterClosed().subscribe(result => {

          if (result)
          {
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          }
          else{
            this.executeGRWPDailyCreateUpdate('Disinfection','Weekly');
          }
          return;
        });
      }
      else
      {
        this.executeGRWPDailyCreateUpdate('Disinfection','Weekly');
      }
    }
    else
    {
      this.executeGRWPDailyCreateUpdate('WeeklySoftner', 'Weekly');
    }
  }

  checkValuesForSoftenerVerification() {
    if(this.f.HardnessPreSoft.value ||
        this.f.HardnessPreROS14.value ||
        this.f.SaltLevelVerification.value ||
        this.f.SoftenerTankPosition.value ||
        this.f.PostCarbonBank3P17.value ||
        this.f.PostFiltersBank2P18.value ||
        this.f.SwitchBreakTankPumps.value ||
        this.f.pHPreCarbonS1.value ||
        this.f.pHPostCarbonS14.value ||
        this.f.IronTotalPostSoftenerS14.value ||
        this.f.VerifyTestingChemicalLevels.value)
        {
          return true;
        }
        else
        {
          return false;
        }
    }
}

<!-- <h1 md-dialog-title>Confirm</h1>
<div md-dialog-content>{{confirmMessage}}</div>
<div md-dialog-actions>
  <button md-button style="color: #fff;background-color: #153961;" (click)="dialogRef.close(true)">Confirm</button>
  <button md-button (click)="dialogRef.close(false)">Cancel</button>
</div> -->
<div class="modal-header">
    <h4 class="modal-title">Please confirm..</h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
        {{confirmMessage}}
    </div>
    <div class="modal-footer">
      
      <button type="button"  class="btn btn-danger" (click)="dialogRef.close(false)">{{cancelBtn}}</button>
      <button type="button"  class="btn btn-primary" (click)="dialogRef.close()" *ngIf='okBtn2'>{{okBtn2}}</button>
      <button type="button"  class="btn btn-primary" (click)="dialogRef.close(true)">{{okBtn}}</button>
    </div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeRenovation',
  templateUrl: './purpose-renovation.component.html',
  styleUrls: ['./purpose-renovation.component.css']
})
export class PurposeRenovationComponent implements OnInit {

  today = new Date();
  currentUserId;
  IsPurposeCompleted='';

  renovationForm = new UntypedFormGroup({
    RenovationId: new UntypedFormControl('0'),
    HFServiceID: new UntypedFormControl(''),
    DialysisUnitId: new UntypedFormControl(''),
    Issue: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl('')
  });

  constructor(private homefieldApi: HomeFieldService, public auth : AuthService) { }

  get rf() {
    return this.renovationForm.controls;
  }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
  }, error => {
  });
  }

  
  prepareCreateViewModel() {
    const modelCreate = <any>{};

    modelCreate.RenovationId = this.rf.RenovationId.value;
    modelCreate.HFServiceID = this.rf.HFServiceID.value;
    modelCreate.DialysisUnitId = this.rf.DialysisUnitId.value;
    modelCreate.Issue = this.rf.Issue.value;
    
    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.currentUserId;
    modelCreate.ModifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.ModifiedBy = this.currentUserId;
    return modelCreate;
  }

  save() {
    return new Promise((resolve, reject) => {
      resolve(true);
    //   const RModelCreate = this.prepareCreateViewModel();
    //   if (this.rf.RenovationId.value === '0'
    //   ) {
    //     this.homefieldApi.CreateHomeFieldService_Renovation(RModelCreate).subscribe(
    //       success => {
    //         this.rf.RenovationId.setValue(success.renovationId);
    //         resolve();
    //       },
    //       error => {
    //         reject();
    //       }
    //     );
    //   } else {
    //     this.homefieldApi.UpdateHomeFieldService_Renovation(RModelCreate).subscribe(
    //       success => {
    //         resolve();
    //       },
    //       error => {
    //         reject();
    //       }
    //     );

    //   }
     });
  }

  setModel() {
    
    // this.homefieldApi.GetHomeFieldService_RenovationbyServiceID({ Id: this.rf.HFServiceID.value }).subscribe(
    //   success => {
    //     if (success) {

    //       setTimeout(() => {
    //         this.rf.RenovationId.setValue(success.renovationId);
    //         this.rf.HFServiceID.setValue(success.hfServiceID);
    //         this.rf.DialysisUnitId.setValue(success.dialysisUnitId);
    //         this.rf.Issue.setValue(success.issue);
    //         this.rf.CreatedBy.setValue(success.createdBy);
    //         this.rf.CreatedOn.setValue(success.createdOn);
    //       });
    //     }
    //   },
    //   error => {
    //     AppComponent.addAlert(error.message, 'error');
    //   }
    // );
  }

  formChange(enable = false) {
    if (enable)
      this.renovationForm.enable();
    else
      this.renovationForm.disable();
  }

}

<style>

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #3a4dab !important;
  }
  </style>

<form [formGroup]="DesignationForm" (ngSubmit)="executeDesignationCreateUpdate('')" novalidate>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            {{title}}
        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader   [showNote]="false" [showAddNew]="false" [showSearch]="false"
                    [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeDesignationCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Designation Name:
                                    </label>
                                    <div class="col-sm-10"><input type="text" class="form-control mdInput"
                                            formControlName="designationName" name="designationName" maxlength="50">
                                        <span
                                            *ngIf="f.designationName.errors?.required && (f.designationName.dirty || f.designationName.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label">Show All Schedule Type:</label>
                                    <div class="col-sm-10">
                                        <mat-checkbox formControlName="showAllSchedule" style="padding-top: 6px !important;line-height: 30px;"></mat-checkbox>
                                        <span
                                            *ngIf="f.showAllSchedule.errors?.required && (f.showAllSchedule.dirty || f.showAllSchedule.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label"><span
                                            class="text-red inputrequired">*</span>Division:</label>

                                    <div class="col-sm-10">
                                            <mat-button-toggle-group multiple formControlName="ALLdivisionId">
                                                    <mat-button-toggle (change)="SetAllButtons($event)" value="all">ALL</mat-button-toggle>
                                            </mat-button-toggle-group>
            
                                            <mat-button-toggle-group multiple formControlName="divisions" name="divisions" style="margin-left:2%">
                                                <mat-button-toggle *ngFor="let item of DivisionList"  value="{{item.value}}" (change)="CheckUncheckAll($event)">
                                                    {{item.text}}
                                                </mat-button-toggle>
                                              </mat-button-toggle-group>
                                        <span
                                            *ngIf="f.divisions.errors?.required && (f.divisions.dirty || f.divisions.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                                <notes [isValidForm]="this.DesignationForm.invalid"
                                    (addNoteClick)="executeDesignationCreateUpdate('Notes')" #notes></notes>
                                <attachment (RaiseError)="AttachmentError($event)" #attachment>
                                </attachment>
                            </div>
                        </div>
                        <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
                            [showSave]="blnshowSave" (SaveEvent)="executeDesignationCreateUpdate('')"
                            [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                    </div>
                </div>
            </div>
        </div>

    </section>
</form>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'DailyUnderConstruction',
  templateUrl: './DailyUnderConstruction.component.html'
})

export class DailyUnderConstructionComponent implements OnInit{
    ngOnInit() {

    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { RPTApiService } from '../../API/rptapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules,
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'PagerDetail',
  templateUrl: './PagerDetail.component.html'
})
export class PagerDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  selectedIssueStatus= "";
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  isValidFormSubmitted = null;
  ListURL = 'Reports/PagingReportSearch';
  status = '';
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDialysisUnitstext;
  selectedUsertext;
  blnshowSave = false;
  blnshowDelete = false;
  ReportID = '[AutoNumber]';
  OnCallStaff = '';
  SelectedPagingStaffName = '';
  HourList = [];
  MinuteList = [];
  IssueDropDownList = [
    { value: '', text: 'Select' },
    { value: 'Water Treatment', text: 'Water Treatment' },
    { value: 'Dialysis Machines', text: 'Dialysis Machines' },
    { value: 'Central Delivery', text: 'Central Delivery' },
    { value: 'BMR Software', text: 'BMR Software' },
    { value: 'Other Computer Issues', text: 'Other Computer Issues' },
    { value: 'See Notes', text: 'See Notes' },
  ];
  IssueStatusDropDownList = [
    { value: '', text: 'Select' },
    { value: 'Issue Solved', text: 'Issue Solved' },
    { value: 'On Going Issue', text: 'On Going Issue' },
    { value: 'Follow Up Required', text: 'Follow Up Required' },
  ];
  UserList = [];
  PagerForm = new UntypedFormGroup({
    PagersId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl('', Validators.required),
    PagedDate: new UntypedFormControl('', Validators.required),
    PagedTime: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedIssue: new UntypedFormControl('', Validators.required),
    selectedIssueStatus: new UntypedFormControl('0', Validators.required),
    SelectedPagingStaff: new UntypedFormControl(''),
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  ConfirmUser: any;

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.PagerForm.dirty ||
        this.notes.Note !== '' ||
        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private rptapi: RPTApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.notes.showHours = false;
    // attachment changes
    this.attachment.DocId = this.f.PagersId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.PagingReports];
    this.attachment.UploadBy = this.currentUserId;
    this.OnCallStaff = this.auth.GetUserName();

    // while (this.HourList.length < 24) {
    //   this.HourList.push({
    //     text: (this.HourList.length).toString().padStart(2, '0'),
    //     value: (this.HourList.length).toString()
    //   });
    // }
    // this.HourList.unshift({text: 'Select', value: ''});
    // while (this.MinuteList.length < 60) {
    //   this.MinuteList.push({
    //     text: (this.MinuteList.length).toString().padStart(2, '0'),
    //     value: (this.MinuteList.length).toString()
    //   });
    // }
    // this.MinuteList.unshift({text: 'Select', value: ''});
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.fillDialysisUnit();
      this.fillUserList(0);
      this.f.PagersId.setValue(params['id'] || '0');
      const tempDate = new Date();
      this.f.CreatedDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
      if (this.f.PagersId.value !== '0') {
        this.setModel();
      } else {
        this.selectedUsertext = this.auth.GetUserName();
        this.auth.GetCurrentUserDivision().subscribe(division => {
          if (division.length === 1) {
            this.f.selectedDialysisUnits.setValue(division[0]);
            this.DialysisUnitDropDownChanged();
          }
        }, error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        });
      }
    });
    this.SetPageRights();
    AppComponent.HideLoader();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.PagerForm.controls;
  }

  fillUserList(DivisionId) {
    if (DivisionId !== 0) {
      this.rptapi
      .GetUsersDDByDivisionID(DivisionId)
      .subscribe(list => (this.UserList = list));
    } else {
      this.UserList = [{ value: '', text: 'Select' }];
      this.f.SelectedPagingStaff.setValue('');
    }

  }

  setModel() {
    AppComponent.ShowLoader();
    const PagerCreate = { Id: this.f.PagersId.value };

    this.rptapi.GetPagerReportsByreportId(PagerCreate).subscribe(
      success => {
         //console.log(success);
        this.f.PagersId.setValue(success.reportid);

        this.notes.Note = '';
        this.attachment.clearQueue();
        let tempDate = new Date(success.pagingdatetime);

        this.f.PagedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.pagingdatetime
        });

        tempDate = new Date(success.createdon);

        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdon
        });

        this.ReportID = success.reportnumber;
        this.selectedUsertext = success.createdby;
        this.f.SelectedPagingStaff.setValue(success.pagingstaff);
        this.SelectedPagingStaffName = success.pagingstaffName;
        this.selectedUsertext = success.createdbyName;
        this.f.selectedIssue.setValue(success.issue);
        this.f.selectedIssueStatus.setValue(success.issuestatus);

        this.selectedIssueStatus = success.issuestatus;
        // this.f.PagedHour.setValue(success.pagingTimeHour);
        // this.f.PagedMinute.setValue(success.pagingTimeMinute);
        this.f.PagedTime.setValue(success.pagingTimeHour.toString().padStart(2, '0') + ':'
        + success.pagingTimeMinute.toString().padStart(2, '0'));
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.division.toString());
        this.selectedDialysisUnitstext = success.divisionName;
        const PagerObj = { Id: this.f.PagersId.value };
        this.rptapi
          .GetattachemnetsByPagerNoesByID(PagerObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });

        this.rptapi
          .GetPagerReportsNotesByID(PagerObj)
          .subscribe(list => {
            this.notes.NoteList = list;
          });

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {}

  fillDialysisUnit() {
    this.rptapi.GetDialysisUnitDD().subscribe(list => {
      this.DialysisUnits = list;
    });
  }

  executePagerCreateUpdate = function(Source) {
    AppComponent.loading = true;
    // this.GreaterValueCheck();
    this.isValidFormSubmitted = false;
    if (this.PagerForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const PagerCreate = this.prepareCreateViewModel();

    if (PagerCreate.reportid === '0') {
      this.rptapi.CreatePagerReport(PagerCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.f.PagersId.setValue(success.reportid);
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.rptapi.UpdatePagerReport(PagerCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const PagerCreate = <any>{};

    PagerCreate.reportid = this.f.PagersId.value;
    PagerCreate.reportnumber = this.ReportID;
    PagerCreate.createdon = new Date();
    PagerCreate.createdby = this.currentUserId;
    PagerCreate.modifiedon = new Date();
    PagerCreate.modifiedby = this.currentUserId;
    //console.log(this.f.PagedTime);
    PagerCreate.pagingstaff = this.f.SelectedPagingStaff.value;
    PagerCreate.division = this.f.selectedDialysisUnits.value;
    if (this.f.PagedDate.value !== null && this.f.PagedDate.value !== undefined) {
      PagerCreate.pagingDate = Globals.GetAPIDate( this.f.PagedDate.value.jsdate);
    }
    if (this.f.PagedTime.value !== null && this.f.PagedTime.value !== undefined && this.f.PagedTime.value !== '') {
      const time = this.f.PagedTime.value.split(':');
      // tslint:disable-next-line:radix
      PagerCreate.pagingTimeHour = parseInt(time[0]);
      // tslint:disable-next-line:radix
      PagerCreate.pagingTimeMinute = parseInt(time[1]);
}

    PagerCreate.issue = this.f.selectedIssue.value;
    PagerCreate.issuestatus = this.f.selectedIssueStatus.value;


    if (this.f.CreatedDate.value !== null && this.f.CreatedDate.value !== undefined) {
      PagerCreate.pagingdatetime = Globals.GetAPIDate(this.f.CreatedDate.value.jsdate);
    }

    PagerCreate.notes = this.notes.Note;
    // attachments
    PagerCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    //console.log('Create',  PagerCreate);
    return PagerCreate;
  }

  DialysisUnitDropDownChanged() {
    this.fillUserList(this.f.selectedDialysisUnits.value);
    // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
  }



  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const PagerObj = { Id: this.f.PagersId.value };
        this.rptapi.DeletePagerReportByReportId(PagerObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Paging Report detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetPageRights() {
    this.auth.GetPageRights('Paging Report').subscribe(list => {
      if (this.f.PagersId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.PagersId.value !== '0') {
		  this.blnshowDelete = true;
		}
  }


  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }
  // GreaterValueCheck(){
  //   this.f.hoursout.updateValueAndValidity();
  //   this.f.selectedOutsurfacedis.updateValueAndValidity();
  //   this.f.selectedouthydraulicdis.updateValueAndValidity();

  // }
}

import { Component, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DMApiService } from '../../API/dmapi.service';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { AttachmentComponent } from '../Shared/AttachmentComponent/AttachmentComponent';
import { AttachmentDialog } from '../AttachmentDialog/AttachmentDialog';
@Component({
  selector: 'machinehistory-dialog',
  templateUrl: './MachineHistoryDialog.html',
})
export class MachineHistoryDialog {
  PMStatusColor = '';
  PMStatusTextColor = '';
  public PMStatusHours = 0;
  public PMStatusHoursFromMachine = 0; // Set Default Hours if there is no hours in db for machine
  public PMStatusType = "Days";
  public eqModel="";
  public EquipId:string;
  public MachineName:string;
  public SrNo:string;
  public detailsType : string;
  public InServiceDate:string;
  title="Equipment Machine History Report";
  current="Machine";
  public dialogattachment: MatDialogRef<AttachmentDialog>;
  constructor(public dialogRef: MatDialogRef<MachineHistoryDialog>,private dmapi:DMApiService, public dialog: MatDialog) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  MachineHistoryData=[];
  AlarmHistoryData=[];
  PartsHistoryData=[];
    page = new Page();
    rows ;
    pageSize = 50;
    currentPageNumber = 1;
    isPageLoaded =false;

  ngOnInit()
  {
    this.ShowMachineHistory();
  }

  ShowMachineHistory()
  {
    var equipObj = {Id:this.EquipId, str : this.detailsType};
    this.current="Machine";
    this.title="Equipment Machine History Report";
    AppComponent.ShowLoader();
    this.dmapi.GetMachaineHistory(equipObj).subscribe(list =>
      {
         this.MachineHistoryData=list;
         this.updatePMstatus();
         AppComponent.HideLoader();
      },
      error=>
      {
        AppComponent.addAlert(error.message,"error");
        AppComponent.HideLoader();
      }
    );
  }

  ShowAlarmHistory()
  {
    var equipObj = {str:this.SrNo,pageno:this.currentPageNumber,PageSize:this.pageSize};
    this.current="Alarm";
    this.title="Alarm History Report";
    AppComponent.ShowLoader();
    this.dmapi.GetAlarmHistory(equipObj).subscribe(list =>
      {
       // console.log(list);
        this.page.totalElements= list.totalRows;
        this.page.totalPages= list.totalPages;
        this.isPageLoaded=true;
         this.AlarmHistoryData=list.objDetailslst;
         AppComponent.HideLoader();
      },
      error=>
      {
        AppComponent.addAlert(error.message,"error");
        AppComponent.HideLoader();
      }
    );
  }

  partCost=0;


  ShowPartsHistory()
  {
    var equipObj = {Id:this.EquipId};
    this.current="Parts";
    this.title="Parts History Report";
    AppComponent.ShowLoader();
    this.dmapi.GetPartsHistory(equipObj).subscribe(list =>
      {
        this.partCost=0;
        // console.log(list);
        this.PartsHistoryData=list;
        // console.log(this.PartsHistoryData);
        for (let childObj in list){
          var cost = list[childObj].cost;
          if (cost != null && cost != undefined)
          {
          this.partCost = this.partCost +  cost;
          }
        }
        AppComponent.HideLoader();
      },
      error=>
      {
        AppComponent.addAlert(error.message,"error");
        AppComponent.HideLoader();
      }
    );
  }

  updatePMstatus(){

    let allPMS = this.MachineHistoryData.filter(
      x => x.formtype === "PreventiveMaintenance"
    );

    if(allPMS && allPMS.length > 0 && allPMS[0].transDate){
        let daysDiff = this.getDiferenceInDays(allPMS[0].transDate);

        if(daysDiff)
          this.PMStatusHours = this.PMStatusHoursFromMachine -  parseInt(daysDiff);
        else
          this.PMStatusHours = this.PMStatusHoursFromMachine;
    }
    else
      this.PMStatusHours = this.PMStatusHoursFromMachine;

    this.setColorToPMStatus();
  }

  setColorToPMStatus(){
    // this.PMStatusHours =-1; //test purpose only
    if(this.eqModel.includes('5008S')){
    if(this.PMStatusHours >= 60){
      this.PMStatusColor = 'green';
      this.PMStatusTextColor = 'white';
    }
    else if(this.PMStatusHours <=59 && this.PMStatusHours >=0){
      this.PMStatusColor = 'yellow';
      this.PMStatusTextColor = 'black';
    }
    else if(this.PMStatusHours < 0)
    {
      this.PMStatusColor = 'red';
      this.PMStatusTextColor = 'white';
    }
  }
  else{
    if(this.PMStatusHours >= 30){
      this.PMStatusColor = 'green';
      this.PMStatusTextColor = 'white';
    }
    else if(this.PMStatusHours <=29 && this.PMStatusHours >=0){
      this.PMStatusColor = 'yellow';
      this.PMStatusTextColor = 'black';
    }
    else if(this.PMStatusHours < 0)
    {
      this.PMStatusColor = 'red';
      this.PMStatusTextColor = 'white';
    }
  }

  }

  getDiferenceInDays(theDate): string {
    if (theDate) {
      return (Math.round(Math.abs(new Date(theDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))).toString();
    }
    else {
      return '';
    }
  }

  NoOfClick = 0;

  get random() {
    return Math.floor(Math.random() * 1000) + 1;
  }

  alertmessage = '';

  addAlert(message, typeP) {

    let timeoutSeconds = parseInt(Globals.defaultTimeForAlert);

    if(typeP == "error" && message != Globals.FormErrorMessage)
      timeoutSeconds = parseInt(Globals.defaultTimeForErrorAlert);

    const _success = {msg: message, type: typeP, successMessage: true};
    setTimeout(() => _success.successMessage = null, timeoutSeconds);
    this.alertmessage = message;

    setTimeout(() =>{
      let errorText = document.querySelectorAll('ngb-alert div span')
      try {
        this.alertmessage = '';
        errorText[errorText.length - 1].innerHTML ='OK';
      } catch (error) {

      }

    },50);
  }

  setlinkMachine(formtype:string, remark:string, transid :string)
    {
      // window.alert('Printing PDF is restricted.');
      // return;
      // if(this.random > 500 &&  this.random < 510) {

        var href = '';

        try
        {
            switch (formtype)
            {
                case "Pyrogen":
                    //href = Globals.oldUrl + "/DialysisMachine/pryogenPdf.aspx?id=" + transid;
                    this.dmapi.PrintPyrogenPDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                case "Bacteria":
                    //href = Globals.oldUrl + "/DialysisMachine/bacteriaPdf.aspx?id=" + transid;
                    this.dmapi.PrintBacteriaPDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                case "Incident":
                   // href = Globals.oldUrl + "/DialysisMachine/incidentPdf.aspx?id=" + transid;
                    this.dmapi.PrintIncidentPDF({ Id: transid }).subscribe(list => {
                        href = Globals.WorkOrderPFDsUrl + list;
                        window.open(href,'_blank');
                    }, error => {
                      AppComponent.addAlert(error.message, 'error');
                      AppComponent.HideLoader();
                    });
                    break;
                    // break;
                case "Filter Change":
                    //href = Globals.oldUrl + "/DialysisMachine/DMFilterChangePdf.aspx?id=" + transid;
                    this.dmapi.PrintFilterPDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                case "Hours Update":
                    //href = Globals.oldUrl + "/DialysisMachine/DMHoursUpdatepdf.aspx?id=" + transid;
                    this.dmapi.PrintHoursUpdatePDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                case "UpgradeModfication":
                    //href = Globals.oldUrl + "/DialysisMachine/UpgradeModficationpdf.aspx?id=" + transid;
                    this.dmapi.PrintUpgradeModificationPDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                case "Warranty":
                    href = Globals.oldUrl + "/DialysisMachine/Warrantypdf.aspx?id=" + transid;
                    break;
                case "dmrepair":
                    //href = Globals.oldUrl + "/DialysisMachine/dmRepairpdf.aspx?id=" + transid;
                    this.dmapi.PrintRepairPDF({ Id: transid, str : this.detailsType }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;
                // case String(remark.match(/^PM -.*/)):
                case "PreventiveMaintenance":
                    //href = Globals.oldUrl + "/DialysisMachine/PreventiveMaintenancepdf.aspx?id=" + transid;
                    this.dmapi.PrintPreventivePDF({ Id: transid }).subscribe(list => {
                      if(remark.match(/^.*Upload/)!=null && String(remark.match(/^.*Upload/)))
                        href = Globals.AttachmentUrl + list;
                      else
                        href = Globals.WorkOrderPFDsUrl + list;
                        window.open(href,'_blank');
                    }, error => {
                      AppComponent.addAlert(error.message, 'error');
                      AppComponent.HideLoader();
                    });
                    break;

                  case "DMTransfer":
                    this.dmapi.PrintTransferMachinePDF({ Id: transid }).subscribe(list => {
                      href = Globals.WorkOrderPFDsUrl + list;
                      window.open(href,'_blank');
                  }, error => {
                    AppComponent.addAlert(error.message, 'error');
                    AppComponent.HideLoader();
                  });
                    break;

                default:
                    if (remark.startsWith("Work Order~"))
                    {
                       href = Globals.oldUrl + "/exportFiles/" + remark.substring(11);
                    }
                    else
                        href = '';
                    break;
            }
        }
        catch{}
        return href;
      // } 
      
      // else {
      //  // window.alert('“Failed to Load PDF Document” this electronic transcript is a secured PDF file and the web browser you are using, is trying to open the transcript within its own native PDF viewer. Clear systems pdf cache and verify the default PDF viewer.');
      //     window.alert('Printing PDF is restricted.');
      //   // tslint:disable-next-line:max-line-length
      //   // this.addAlert('“Failed to Load PDF Document” this electronic transcript is a secured PDF file and the web browser you are using, is trying to open the transcript within its own native PDF viewer. Clear systems pdf cache and verify the default PDF viewer.', 'error');
      //   return;
      // }
    }

    setlinkPart(remark:string, transid :string)
    {
        var href = '';
        try
        {
            switch (remark)
            {
                case "dmrepair":
                href = Globals.oldUrl + "/DialysisMachine/dmRepairpdf.aspx?id=" + transid;
                    break;
                case String(remark.match(/^PM -.*/)):
                    //href = Globals.oldUrl + "/DialysisMachine/PreventiveMaintenancepdf.aspx?id=" + transid;
                    this.dmapi.PrintPreventivePDF({ Id: transid }).subscribe(list => {
                        href = Globals.WorkOrderPFDsUrl + list;
                        window.open(href,'_blank');
                    }, error => {
                        console.log(error);
                    });
                    break;
                default:
                href = Globals.oldUrl + "/DialysisMachine/dmRepairpdf.aspx?id=" + transid;
                    break;
            }
          }
        catch{}
        return href;
        }
        setPage(){
            this.currentPageNumber=this.page.pageNumber;
            if(this.isPageLoaded==true)
            {
                this.ShowAlarmHistory();
            }
        }


    OpenAttachmentList(repairID: string, moduleName: string) {

    AppComponent.ShowLoader();
    this.dialogattachment = this.dialog.open(AttachmentDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '50%',
      maxHeight: '100vh',
      data: { repairID: repairID, moduleName: moduleName }
    });

    this.dialogattachment.afterClosed().subscribe(result => {
      this.dialogattachment = null;
    });

    AppComponent.HideLoader();
  }

}

export class Page {
    //The number of elements in the page
    size: number = 50;
    //The total number of elements
    totalElements: number = 0;
    //The total number of pages
    totalPages: number = 0;
    //The current page number
    pageNumber: number = 0;
  }


<div  class="no-print">
        <div class="box-header pageheader row">
            <!-- <div class="col-sm-12">

                <div class="col-sm-6 text-left"><h3>{{title}}<sup *ngIf="title!=''"><font   style="font-size: 12px;color:maroon"> [beta]</font></sup></h3></div> -->
                <div class="col-sm-12 text-right">

                     <a href="javascript:void(0)" (click)="RouteClick(addurl)" *ngIf="showAddNew" class="btn btn-primary" id="btnAddNew"> <i class="fa fa-plus"></i> &nbsp; Add New </a>
                    <a href="javascript:void(0)" (click)="SearchClick()" class="btn"  [class.btn-primary]="isSearchOpen===false" [class.btn-danger]= "isSearchOpen===true" *ngIf="showSearch" id="btnSearch"> <i class="fa fa-search"></i> &nbsp; <span>{{searchText}}</span> </a>
                    <a href="javascript:void(0)" (click)="AddNoteClick()" class="btn btn-primary"   *ngIf="showNote" id="btnNote"> <i class="fa fa-edit"></i> &nbsp; <span>Add Note</span> </a>
                    <a href="javascript:void(0)" (click)="EditClick()" class="btn btn-primary" *ngIf="showEdit" id="btnEdit"> <i class="fa fa-edit"></i> &nbsp; <span>Edit</span> </a>
                    <a href="javascript:void(0)" (click)="SaveClick()" class="btn btn-primary" *ngIf="showSave" id="btnSave"> <i class="fa fa-save"></i> &nbsp; <span>Save</span> </a>
                    <a href="javascript:void(0)" (click)="Print()" class="btn btn-success" *ngIf="showPrint" id="btnPrint"><i class="fa fa-print"></i>&nbsp; <span>Print Schedule</span></a>
                    <a href="javascript:void(0)" (click)="RouteClick(mainlisturl)" class="btn btn-primary" *ngIf="showMainListing  && MainListingText != 'Cancel'" id="btnMainListing"> <i class="fa fa-list"></i> &nbsp; {{MainListingText}}</a>
                    <a href="javascript:void(0)" (click)="RouteClick(mainlisturl)" class="btn btn-danger" *ngIf="showMainListing && MainListingText=='Cancel'" id="btnMainListing"> <i class="fa fa-close"></i> &nbsp; {{MainListingText}}</a>
                    <a href="javascript:void(0)" (click)="DeleteClick()" class="btn btn-danger" *ngIf="showDelete" id="btnDelete"> <i class="fa fa-remove"></i> &nbsp; <span>Delete</span> </a>
                    <a href="javascript:void(0)" (click)="PreviousPageClick()" class="btn btn-primary" *ngIf="showPreviousPage" id="btnPreviousPage"> <i class="fa fa-arrow-circle-left"></i> &nbsp; <span>Previous Page</span> </a>
                    <a href="#" class="btn btn-primary" id="btnMoveUp" style="display: none;"> <i class="fa fa-arrow-up"></i> </a>
                    <a href="#" class="btn btn-primary" id="btnMoveDown" style="display: none;"> <i class="fa fa-arrow-down"></i> </a>

                </div>
            </div>
</div>

        <!-- </div>
    </div> -->

import { ScrollHelper } from './../../Helper/ScrollHelper';
import { Globals, attachmentFormModules } from './../../../globals';
import { AuthService } from './../../../API/auth.service';
import { FeedbackDetailApiService } from './../../../API/feedbackapi.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { AttachmentComponent } from '../../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debug } from 'util';
@Component({
  selector: 'ErrorDialog',
  templateUrl: './ErrorDialog.html',
  styleUrls: ['./ErrorDialog.css']
})
export class ErrorDialog {

  pageURL: string;
  errorDateTime: string;
  public errormsg = '';
  public message: string;
  DATE_TIME_FMT = 'dd/mm/yyyy HH:mm';
  siteName = Globals.siteName;
  createdBy = this.authentication.GetUserId();
  modifiedBy = this.authentication.GetUserId();
  userName = this.authentication.GetUserName();
  feedBackDetail = [];
  type = 'Support';
  isValidFormSubmitted = null;
  @Output() SaveEvent = new EventEmitter<string>();
attachmentList = [];
  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;

  errorLogForm = new UntypedFormGroup({
    // noteType: new FormControl('', Validators.required),
    // note: new FormControl('', Validators.required)
    supportId: new UntypedFormControl(''),
    noteType: new UntypedFormControl(''),
    note: new UntypedFormControl('')
  });


  constructor(public router: Router, public dialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerDialogData, private feedbackapi: FeedbackDetailApiService,
    private authentication: AuthService) {
  }

  ngAfterViewInit() {
    if (this.data.imgURL) {
      var file = this.dataURLtoFile(this.data.imgURL, this.data.title + ".png");
      this.attachment.AddFileToQueue([file]);
    }
  }

  dataURLtoFile(dataurl, filename) {
    var base64data = dataurl.replace("data:image/png;base64,", "");
    var bs = atob(base64data);
    var buffer = new ArrayBuffer(bs.length);
    var ba = new Uint8Array(buffer);
    for (var i = 0; i < bs.length; i++) {
      ba[i] = bs.charCodeAt(i);
    }
    var blob = new Blob([ba], { type: "image/png" });
    var b: any = <File>blob;
    b.lastModifiedDate = new Date();
    b.name = filename;
    return <File>b;
  }

  ngOnInit() {

    this.setModel();
    this.pageURL = window.location.href;
    this.errorDateTime = new Date().toISOString();

    if(this.attachment)
    this.attachment.uploadID = 'errorAttachment';
  }

  setModel() {
    AppComponent.ShowLoader();
    this.feedbackapi.GetNextFeedbackSupportId().subscribe(
      success => {
        this.f.supportId.setValue(success);
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  get f() {
    return this.errorLogForm.controls;
  }

  executeErrorLogCreate = function () {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.errorLogForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;
    this.attachment.moduleName = attachmentFormModules[attachmentFormModules.Error];
    this.attachment.UploadBy = this.createdBy;


    const feedbackCreate = this.prepareCreateViewModel();
    this.feedbackapi.CreateFeedbackDetails(feedbackCreate).subscribe(
      success => {
        this.feedBackDetail = success.feedBackDetail;
        console.log(success);
        const postDetail = <any>{};
postDetail.Subject = success.forumSubject  ;
postDetail.Body = success.forumBody;
postDetail.ForumName = 'BMR TECHNICAL SOFTWARE';
postDetail.UserEmail = success.userEmail;
postDetail.UserName = success.userName;
const postForm = <any>{};
postForm.formVars = postDetail;
console.log(postForm);
this.feedbackapi.PostToInternalForum(postForm).subscribe(
  success1 => {
    console.log(success1);
  },
  error => {
    AppComponent.addAlert(error.message, 'error');
  }
);
        AppComponent.loading = false;
        AppComponent.addAlert('Support/Error Request : ' + this.f.supportId.value + ' Created successfully!', 'success');
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
    this.dialogRef.close(true);

  };

  SetOption(Type) {
    console.log(this.attachment);
    if ( Type === 'Remote' ) {
      this.attachmentList = this.attachment.Queue();
      this.type = Type;
    } else {
      this.type = Type;
      setTimeout(() => {
        this.attachment.SetQueue(this.attachmentList);
   }, 2000);
    }
  }

  prepareCreateViewModel() {
    const modelCreate = <any>{};
    // console.log( new Date(this.errorDateTime));

    modelCreate.feedbackDateTime = new Date(this.errorDateTime);
    modelCreate.noteType = 'Support Request';
    modelCreate.supportId = this.f.supportId.value;
    modelCreate.note = this.f.note.value;
    modelCreate.createdBy = this.createdBy;
    modelCreate.modifiedBy = this.modifiedBy;
    modelCreate.applicationName = 'Technical';
    modelCreate.siteName = this.siteName;
    modelCreate.pageURL = this.pageURL;
    modelCreate.status = 'In Progress';
    modelCreate.userName = this.authentication.GetUserFLName();
    modelCreate.userEmail = this.authentication.GetUserEmail();
    modelCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    return modelCreate;
  }
}

export interface ImageViewerDialogData {
  imgURL: any;
  title: string;
}

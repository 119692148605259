<form [formGroup]="ForumForm" (ngSubmit)="executeForumCreateUpdate()" novalidate>

    <section class="content-header">
        <h1>
            Forum
            <sup class="beta">[demo]</sup>

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">
                <PageHeader   [showNote]="false" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeForumCreateUpdate()" [showDelete]="blnshowDelete"
                    #pageheader></PageHeader>

                <div class="clearfix"></div>
                <div class="box">
                    <div class="box-body">

                        <div class="box ibox box-info">

                            <div class="box-body" *ngIf="f.id.value==0">
                                    <div class="col-sm-12 box-row" style="display: none">
                                            <div class='col-sm-12'>
                                                <label class='col-sm-3 control-label'><span
                                                        class='text-red inputrequired'>*</span>id:</label>
                                                <div class='col-sm-9'>
                                                    <input name='id' maxlength='255' required class='form-control'
                                                        formControlName='id'>
                                                    <span
                                                        *ngIf='ForumForm.controls.id.errors?.required && (ForumForm.controls.id.dirty || ForumForm.controls.id.touched || formSubmitted)'
                                                        [ngClass]="'error'"> Required! </span>
                                                </div>
                                            </div>
                                             
                                        </div>
                                        <div class="col-sm-12 box-row">
                                                <div class='col-sm-12'>
                                                    <label class='col-sm-3 control-label'><span
                                                            class='text-red inputrequired'>*</span>Category:</label>
                                                    <div class='col-sm-9'>
                                                            <select
                                                            name='category'
                                                            
                                                            class='form-control mdInput'
                                                            formControlName='category'
                                                        >
                                                        <option value=''>Select Category</option>
                                                            <option
                                                                value="{{item.value}}"
                                                                *ngFor="let item of Categories"
                                                            >
                                                                {{item.text}}
                                                            </option>
                                                        </select>
                                                        <span
                                                            *ngIf='f.category.errors?.required && (f.category.dirty || f.category.touched || isValidFormSubmitted==false)'
                                                            [ngClass]="'error'"
                                                        >
                                                            Required!
                                                        </span>
                                                    </div>
                                                </div>
                                                 
                                            </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'><span
                                                class='text-red inputrequired'>*</span>Subject:</label>
                                        <div class='col-sm-9'>
                                            <input name='subject' maxlength='255' required class='form-control'
                                                formControlName='subject'>
                                            <span
                                                *ngIf='ForumForm.controls.subject.errors?.required && (ForumForm.controls.subject.dirty || ForumForm.controls.subject.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>
                                            <!-- <span class='text-red inputrequired'>*</span> -->
                                                Message:</label>
                                        <div class='col-sm-9'>
                                            <textarea name="content" rows="10" style="width: 100%" formControlName="content" class="form-textarea" required></textarea>
                                                        <span
                                                            *ngIf='ForumForm.controls.content.errors?.required && (ForumForm.controls.content.dirty || ForumForm.controls.content.touched || formSubmitted)'
                                                            [ngClass]="'error'"> Required! </span>
                                                    </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-12 box-row">
                                            <attachment #attachment>
                                                    </attachment>
                                                  </div> -->


                                </div>
                                <div class="box-body" *ngIf="f.id.value!=0">
                                        <div class="col-sm-8 box-row">
                                                <h1 class='col-sm-12'>
        {{CategoryName}} / {{f.subject.value}}    
                                                </h1>  
                                            
                                        </div>
                                        <div class="col-sm-8 box-row">
                                                <div class='col-sm-12'>
        
        {{replies}} replies<br>
        <b>Last Post</b> {{modifiedon | date:'dd/MM/yyyy HH:mm:ss'}} <b>by</b> {{modifiedByName}}
                                                </div>  
                                            
                                        </div>
                                        
                                        <div class="col-sm-12 box-row" style="border:1px #ccc solid;margin-bottom: 20px" *ngFor="let post of posts">
                                                <div class='col-sm-8'>
                                                    <label class='col-sm-3 control-label'>
                                                        {{post.postDetail.createdByName}}
                                                        </label>
                                                    <div class='col-sm-9'>                                                
                                                        <b>{{f.subject.value}}</b><br>
                                                        <small>{{post.postDetail.createdOn | date:'dd/MM/yyyy HH:mm:ss'}}</small>
                                                        
                                                        <br>
                                                        <br>
                                                        {{post.postDetail.content}}
                                                                </div>
                                                    </div>
                                                </div>
                                        <div class="col-sm-12 box-row">
                                                <div class='col-sm-8'>
                                                    <label class='col-sm-3 control-label'>
                                                        <!-- <span class='text-red inputrequired'>*</span> -->
                                                            Message:</label>
                                                    <div class='col-sm-9'>                                                
                                                        <textarea name="content2" rows="10" style="width: 100%" formControlName="content2" class="form-textarea" required></textarea>
                                                                    <span
                                                                        *ngIf='ForumForm.controls.content.errors?.required && (ForumForm.controls.content.dirty || ForumForm.controls.content.touched || formSubmitted)'
                                                                        [ngClass]="'error'"> Required! </span>
                                                                </div>
                                                    </div>
                                                </div>
                                    
                                        <!-- <div class="col-sm-12 box-row">
                                                <attachment (RaiseError)="AttachmentError($event)"  #attachment>
                                                        </attachment>
                                                      </div> -->
      
                                    
                                    </div>

                        </div>
                    </div>
                </div>

                <PageHeader   [showNote]="false" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeForumCreateUpdate()" [showDelete]="false" #pageheader></PageHeader>

            </div>
        </div>
    </section>

</form>


import { debug } from "util";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DMApiService } from "../API/dmapi.service";
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgForm } from "@angular/forms";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TooltipPosition } from "@angular/material/tooltip";
import { ConfirmationDialog } from "../Core/ConfirmationDialog";
import { MachineHistoryDialog } from "../Core/MachineHistory/MachineHistoryDialog";
import { CalibrationsDialog } from "../Core/Calibrations/CalibrationsDialog";
import { ElectricalDialog } from "../Core/ElectricalDialog/ElectricalDialog";
import { TrobleshootingDialog } from "../Core/TrobleshootingDialog/TrobleshootingDialog";
import * as _ from 'underscore';
import { DMRepairCustomValidators } from "../Validator/EuipmentRepairStatus";
import { CommonCustomValidators } from "../Validator/CommonValidator";
import { NotesComponent } from "../Core/Shared/NotesComponent/NotesComponent";
import { PartsComponent } from "../Core/Shared/PartsControl/PartsComponent";
import { AttachmentComponent } from "../Core/Shared/AttachmentComponent/AttachmentComponent";
import { CanComponentDeactivate } from "../Core/Shared/CanComponentDeactivate";
import { InvApiService } from 'src/app/API/invapi.service';
import { QuickHomeEquipmentTransfer } from 'src/app/Core/QuickHomeEquipmentTransfer/QuickHomeEquipmentTransfer';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ScrollHelper } from "../Core/Helper/ScrollHelper";
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from "../globals";
import { AppComponent } from "../app.component";
import { AuthService } from "../API/auth.service";
import { ImageViewerDialog } from "../Core/Shared/ImageViewerDialog/ImageViewerDialog";
import { MenuSharedService } from "../Core/services/menu-shared.service";
import { HomeService } from 'src/app/API/home.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  selector: "DMRepairDetail",
  templateUrl: "./DMRepairDetail.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DMRepairDetailComponent implements OnInit, CanComponentDeactivate {
  tooltipPosition: TooltipPosition = 'above';
  tooltipText = 'Click To Add';
  // Available tooltipPositions --- 'after', 'before', 'above', 'below', 'left', 'right'
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  public homeService = false;
  quickUpdateTransferDialogRef: MatDialogRef<QuickHomeEquipmentTransfer>;
  dialogRef: MatDialogRef<ConfirmationDialog>;

  //noteDialogRef: MatDialogRef<NoteDialog>;
  machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;
  calibrationDialogRef: MatDialogRef<CalibrationsDialog>;
  electricalDialogRef: MatDialogRef<ElectricalDialog>;
  troubleShootingDialogRef: MatDialogRef<TrobleshootingDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();
  duplicateErrorCodes: boolean = false;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(PartsComponent, { static: false })
  parts: PartsComponent;
  ShowParts = true;
  //ShowErrors = true;
  UserName = "";
  isValidFormSubmitted = null;
  ListURL = "/DialysisMachine/DMRepairDetailSearch";
  DetailTypes = "";
  SourceList = "";
  selectedEquipmentHours = 0;
  showRemarks = true;
  showHoursInTextBox = true;
  insurfacedisOptions = [
    { value: "", text: "Select" },
    { value: "CaviWipes XL", text: "CaviWipes XL"},
    { value: "Oxivir", text: "Oxivir" },
    { value: "Virox", text: "Virox" },
    { value: "N/A", text: "N/A" }
  ];

  // inhydraulicdisOptions = [
  //   { value: "", text: "Select" },
  //   { value: "Heat", text: "Heat" },
  //   { value: "Heat Citric", text: "Heat Citric" },
  //   { value: "Chemical", text: "Chemical" },
  //   { value: "No", text: "No" }
  // ];

  inhydraulicdisOptions = [
      { value: "", text: "Select" },
      { value: "Degreasing", text: "Degreasing" },
      { value: "Heat Cleaned", text: "Heat Cleaned" },
      { value: "N/A", text: "N/A" }
    ];

  OutsurfacedisOptions = [
    { value: "", text: "Select" },
    { value: "CaviWipes XL", text: "CaviWipes XL"},
    { value: "Oxivir", text: "Oxivir" },
    { value: "Virox", text: "Virox" },
    { value: "N/A", text: "N/A" }
  ];

  outhydraulicdisOptions = [
    { value: "", text: "Select" },
    { value: "Degreasing", text: "Degreasing" },
    { value: "Heat Cleaned", text: "Heat Cleaned" },
    { value: "N/A", text: "N/A"}
  ];

  public disableControl = false;
  loading = true;
  DMRepairDetailsIdvar = "0";
  EquipmentSerialNo: "";
  EquipmentInServiceDate: "";
  DialysisUnits = [];
  DialysisUnitsWithoutHome = [];
  Equipments = [];
  Users = [];
  Parts = [];
  Statuses = [];
  DialysisStation = [];
  DMRepailDetail = [];
  HydraulicDocList = [];
  WarrentyStatus = "";
  //selectedUser = '0';
  dmRepairDetailsId = "0";
  repairId = "[AutoNumber]";
  status = "";
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  IsHoursInIsGreaterThanHoursOut = true;
  blnHoursInVal = false;
  RepairStatus = true;
  selectedDialysisUnitsobj;
  selectedDialysisUnitstext;
  //selectedUsertext;
  selectedEquipmenttext;
  selectedDialysisStationtext;
  repairStartDateLabel;
  outTimeOfEquipmentLabel;
  calibrationsTran = [];
  electricalTran = [];
  Note = "";
  blnshowSave = false;
  blnshowDelete = false;
  modelId: number = 0;
  hfcompleted = true;
  collapsedmEquiInClass = '';
  collapseariaexpanded = true;
  collapsedmEquiIn = 'panel-collapse collapse show';

  collapseerr = '';
  collapseerrexpanded = true;
  collapsederrctl = 'panel-collapse collapse show';

  collapseRef = '';
  collapseRefexpanded = true;
  collapseRefctl  = 'panel-collapse collapse show';

  totalCost = 0;

  public hrsTracking = true;
  HydraulicList = [];
  //   {value: 'Select', text:'Hydraulic'},
  //   { value: '../assets/img/References_Images/5008-5008S Hydraulic Layouts/Functional Descriptions.png', text: 'Functional Descriptions' },
  //   { value: '../assets/img/References_Images/5008-5008S Hydraulic Layouts/HYDRAULIC Flow Layout.png', text: 'HYDRAULIC Flow Layout' }
  // ];

  ElectricalList = [];
  //   {value: 'Select', text:'Electrical'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Controller Board PCB LP 1102 .png', text: 'Controller Board PCB LP 1102' },
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Controller Board PCB LP 1202 .png', text: 'Controller Board PCB LP 1202' },
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/EBM  Interfaces Block Diagram.png', text: 'EBM Interfaces Block Diagram'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/EBM Door Sensor PCB LP 1144.png', text: 'EBM Door Sensor PCB LP 1144'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/EBM External IO PCB LP 1107-4 with Attached PCB LP 1158.png', text: 'EBM External IO PCB LP 1107-4 with Attached PCB LP 1158'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/EBM External IO PCB LP 1107-4 with integrated PCB LP 1158.png', text: 'EBM External IO PCB LP 1107-4 with integrated PCB LP 1158'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/External Interfaces Unit PCB LP 1103.png', text: 'External Interfaces Unit PCB LP 1103'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Heparin Pump Control Board PCB LP 1126.png', text: 'Heparin Pump Control Board PCB LP 1126'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/HYDRAULIC Interfaces Block Diagram.png', text: 'HYDRAULIC Interfaces Block Diagram'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulic Distribution Board Front PCB LP 1124.png', text: 'Hydraulic Distribution Board Front PCB LP 1124'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Distribution Board Door Left PCB LP 1123.png', text: 'Hydraulics Distribution Board Door Left PCB LP 1123'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Distribution Board Door Right PCB LP 1125.png', text: 'Hydraulics Distribution Board Door Right PCB LP 1125'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Poer Supply Actuators OS-SS PCB LP 1108-3.png', text: 'Hydraulics Poer Supply Actuators OS-SS PCB LP 1108-3'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Sensors OS PCB LP 1109.png', text: 'Hydraulics Sensors OS PCB LP 1109'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Sensors SS PCB LP 1110.png', text: 'Hydraulics Sensors SS PCB LP 1110'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Hydraulics Valve Control Board PCB LP 1113.png', text: 'Hydraulics Valve Control Board PCB LP 1113'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Key LED Board PCB LP 1132.png', text: 'Key LED Board PCB LP 1132'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/MONITOR Interfaces Block Diagram.png', text: 'MONITOR Interfaces Block Diagram'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Card Reader PCB LP 1134.png', text: 'Monitor Card Reader PCB LP 1134'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Display Failure Sensor PCB LP 1139.png', text: 'Monitor Display Failure Sensor PCB LP 1139'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Distribution Board PCB LP 1129.png', text: 'Monitor Distribution Board PCB LP 1129'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Distribution Board PCB LP 1148.png', text: 'Monitor Distribution Board PCB LP 1148'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Operating Mode Indicator PCB LP 1131.png', text: 'Monitor Operating Mode Indicator PCB LP 1131'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Operating Mode Indicator with Wireless Module PCB LP 1131-2.png', text: 'Monitor Operating Mode Indicator with Wireless Module PCB LP 1131-2'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Monitor Power Supply Board PCB LP 1104.png', text: 'Monitor Power Supply Board PCB LP 1104'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/PC Board.png', text: 'PC Board'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/PCB\'s\ Overview.png', text: 'PCB\'s\ Overview'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Pneumatics Interfaces Overview.png', text: 'Pneumatics Interfaces Overview'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Power Supply Block Diagram.png', text: 'Power Supply Block Diagram'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Power Supply CPU PCB LP 1105.png', text: 'Power Supply CPU PCB LP 1105'},
  //   { value: '../assets/img/References_Images/5008_5008S DM Repair Electrical Layouts/Power Supply Distribution Board PCB LP 1106.png', text: 'Power Supply Distribution Board PCB LP 1106'}
  // ];

  CallibrationList = [];
  //   {value: 'Select', text:'Callibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Bicarb and Concentrate Flaps Test-CDS Use Only.png', text: 'Bicarb and Concentrate Flaps Test-CDS Use Only'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Blood Leak Detector-Blood Leak Test.png', text: 'Blood Leak Detector-Blood Leak Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Blood Leak Detector-Calibration.png', text: 'Blood Leak Detector-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Blood Leak Detector-Dimness Test.png', text: 'Blood Leak Detector-Dimness Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/BPM-Calibration.png', text: 'BPM-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/BPM-Leakage Test.png', text: 'BPM-Leakage Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/BPM-OverPressure Test.png', text: 'BPM-OverPressure Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Calibrations Required after Temperature and Conductivity Sensor Replacement.png', text: 'Calibrations Required after Temperature and Conductivity Sensor Replacement'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Conductivity- Difference Between CD7 and CD9 Test.png', text: 'Conductivity- Difference Between CD7 and CD9 Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Conductivity-Calibration.png', text: 'Conductivity-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Conductivity-Test.png', text: 'Conductivity-Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Degassing Pump-Loading Pressure Test.png', text: 'Degassing Pump-Loading Pressure Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Degassing Pump-Negatove Pressure Test.png', text: 'Degassing Pump-Negatove Pressure Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Dialysate Pressure-Calibration.png', text: 'Dialysate Pressure-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Dialysate Pressure-Slope - S03-S07 Test.png', text: 'Dialysate Pressure-Slope - S03-S07 Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Dialysate Pressure-SlopeS03S07Test.png', text: 'Dialysate Pressure-Slope + S03-S07 Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Dialysate Pressure-Zero Point S03-S07 Test.png', text: 'Dialysate Pressure-Zero Point S03-S07 Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/ECMP-Calibration.png', text: 'ECMP-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Electrical Safety Test-Device Leakage Test.png', text: 'Electrical Safety Test-Device Leakage Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Electrical Safety Test-Resistance Test.png', text: 'Electrical Safety Test-Resistance Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Error Memory-Save and Erase.png', text: 'Error Memory-Save and Erase'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Flow Pump-Calibration.png', text: 'Flow Pump-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Level Sensor S17 Test-Concentrate Containers Use Only.png', text: 'Level Sensor S17 Test-Concentrate Containers Use Only'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Level Sensor S19 Test-Bicarb Containers Use Only.png', text: 'Level Sensor S19 Test-Bicarb Containers Use Only'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Loading Pump Pressure-Calibration (Balance Chamber).png', text: 'Loading Pump Pressure-Calibration (Balance Chamber)'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Max Water Inflow-Calibration.png', text: 'Max Water Inflow-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/OCM-Calibration.png', text: 'OCM-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Temperature-Calibration.png', text: 'Temperature-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Temperature-Test.png', text: 'Temperature-Test'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Torque Requirements.png', text: 'Torque Requirements'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Touch Screen-Calibration.png', text: 'Touch Screen-Calibration'},
  //   {value: '../assets/img/References_Images/5008-5008S Calibrations/Volumes-Calibration.png', text: 'Volumes-Calibration'}
  // ];

  MachineLayoutList = [];
  //   {value: 'Select', text:'MachineLayout'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/5008-CorDiax.jpg', text: '5008-CorDiax'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/5008S-CorDiax.jpg', text: '5008S-CorDiax'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/EBM Extracorporeal Blood Circuit Module.png', text: 'EBM Extracorporeal Blood Circuit Module'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Hydraulics Connectors Rear.png', text: 'Hydraulics Connectors Rear'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Hydraulics Front View Left Side.png', text: 'Hydraulics Front View Left Side'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Hydraulics Front View Right Side.png', text: 'Hydraulics Front View Right Side'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Hydraulics Front View.png', text: 'Hydraulics Front View'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Hydraulics Rear View.png', text: 'Hydraulics Rear View'},
  //   {value: '../assets/img/References_Images/5008-5008S Machine Layouts/Monitor Layout.png', text: 'Monitor Layout'}
  // ];
  OpenHistory(EquipId) {

    AppComponent.ShowLoader();

    const inventory = {
      Id: EquipId
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {
          // EquipId, SerialNo, Name, InserviceDate
          this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
            disableClose: true,
            position: { right: '0', top: '0' },
            height: '100%',
            width: '70%',
            // maxWidth: '60vh',
            maxHeight: '100vh'
          });

          this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
          this.machineHistoryDialogRef.componentInstance.SrNo = success.serialnumber;
          this.machineHistoryDialogRef.componentInstance.MachineName = success.equipmentcode;
          this.machineHistoryDialogRef.componentInstance.InServiceDate = success.inservicedate;
          // this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
          //   '95%',
          //   '95%'
          // );
          this.machineHistoryDialogRef.afterClosed().subscribe(result => {
            this.machineHistoryDialogRef = null;
          });

        }
        AppComponent.HideLoader();
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');

        AppComponent.HideLoader();
      }
    );

  }
  transferStationEquipment(type) {
    console.log({
      type: type,
      stationId: this.f.selectedDialysisStation.value,
      stationName: this.selectedDialysisStationtext,
      dmEquipmentId: this.f.selectedEquipment.value,
    });
    this.quickUpdateTransferDialogRef = this.dialog.open(QuickHomeEquipmentTransfer, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      maxWidth: '50vh',
      maxHeight: '100vh',
      data: {
        type: type,
        stationId: this.f.selectedDialysisStation.value,
        stationName: this.selectedDialysisStationtext,
        dmEquipmentId: this.f.selectedEquipment.value,
      }
    });

    this.quickUpdateTransferDialogRef.afterClosed().subscribe(result => {
    });

    this.quickUpdateTransferDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      this.f.selectedEquipment.setValue(evt.dmEquipmentId);
      this.EquipmentDropDownChanged();
    });
  }
  repairDetailForm = new UntypedFormGroup({
    dmRepairDetailsId: new UntypedFormControl(""),
    HFServiceId: new UntypedFormControl('0'),
    formDate: new UntypedFormControl("", Validators.required),
    repairStartDate: new UntypedFormControl("", Validators.required),
    selectedDialysisUnits: new UntypedFormControl("", Validators.required),
    selectedDialysisStation: new UntypedFormControl(""),
    //selectedUser: new FormControl(''),
    remarks: new UntypedFormControl("", Validators.required),
    ErrorSearchTerm: new UntypedFormControl(""),
    selectedEquipment: new UntypedFormControl(""),
    selectedinsurfacedis: new UntypedFormControl(""),
    ErrorDescription: new UntypedFormControl(""),
    selectedinhydraulicdis: new UntypedFormControl(""),
    hoursin: new UntypedFormControl("", Validators.required),
    PartSearchTerm: new UntypedFormControl(""),
    PartDescription: new UntypedFormControl(""),
    PartLocation: new UntypedFormControl(""),
    PartQuantity: new UntypedFormControl(""),
    workOrderRefNo: new UntypedFormControl(""),
    hoursout: new UntypedFormControl(""),
    outTimeOfEquipment: new UntypedFormControl(""),
    selectedOutsurfacedis: new UntypedFormControl(""),
    selectedouthydraulicdis: new UntypedFormControl(""),
    selecthydrolicDropDownList: new UntypedFormControl(""),
    selectCallibrationDropDownList: new UntypedFormControl(""),
    selectElectricalDropDownList: new UntypedFormControl(""),
    selectMachineLayoutDropDownList: new UntypedFormControl(""),
    selectedHoursOut: new UntypedFormControl("")
  });
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.repairDetailForm.dirty ||
        this.notes.Note != "" ||
        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted == false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        "You have unsaved changes! If you leave, your changes will be lost.";
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {

          return res;
        })
      );
    } else return true;
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
    this.totalCost = 0;
   if(this.parts != undefined && this.parts.PartTrans != undefined)
   {
    for (let childObj in this.parts.PartTrans) {
      var temp = this.parts.PartTrans[childObj];
      this.totalCost = this.totalCost + (temp.PartCost * temp.quantity);
    }
  }
  }

  constructor(
    private invapi: InvApiService,
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private homeApi: HomeService,
    public menuSharedService: MenuSharedService,
    private commonapi: CommonApiService
  ) { }

  ngAfterViewInit() {

    setTimeout(() => {
      // notes
      this.notes.showParts = true;
      this.notes.CurrentUserId = this.currentUserId;
      this.notes.API = this.dmapi;
      this.notes.partscategoryname = "Dialysis Machines";
      this.notes.status = this.status;

      // Parts

      if (this.ShowParts == true) {
        this.parts.partscategoryname = "Dialysis Machines";
        this.parts.currentUserId = this.currentUserId;
        this.parts.API = this.dmapi;
        this.parts.status = this.status;
        this.parts.showPartHearder = false;
      }
      // Set Default Values for References
      this.f.selecthydrolicDropDownList.setValue("Select");
      this.f.selectCallibrationDropDownList.setValue("Select");
      this.f.selectElectricalDropDownList.setValue("Select");
      this.f.selectMachineLayoutDropDownList.setValue("Select");
    }, 500);
  }

  ngOnInit() {

    setTimeout(() => {
      AppComponent.ShowLoader();
      this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
        this.today = this.createdOn = this.modifiedOn = new Date(currentDate);
        this.setDateOptions();
        // this.homeService = this.route.toString().indexOf('HomeFieldServiceDetail') > 0;
        // this.uploader.autoUpload = true;
        // attachment changes
        if (this.attachment != undefined) {
          this.attachment.DocId = this.f.dmRepairDetailsId.value;
          this.attachment.moduleName =
            attachmentFormModules[attachmentFormModules.DM_Repair];
          this.attachment.UploadBy = this.currentUserId;
        }
        // // notes
        // this.notes.showParts = true;
        // this.notes.CurrentUserId = this.currentUserId;
        // this.notes.API = this.dmapi;
        // this.notes.partscategoryname = "Dialysis Machines";
        // this.notes.status = this.status;

      this.UserName = this.auth.GetUserFLName();

      if (this.homeService !== true) {
        const test = this.route.queryParams.subscribe(params => {
          // Defaults to 0 if no query param provided.

          this.f.dmRepairDetailsId.setValue(params["id"] || "0");
          this.DetailTypes = params["detailType"] || "";
          this.SourceList = params["SourceList"] || "";

            if (this.f.dmRepairDetailsId.value > 0) {
              if (this.SourceList === "M") {
                this.ListURL = "/DialysisMachine/DMRepairDetailSearch";
              } else {
                this.ListURL = "/DialysisMachine/MRMHistoryUploadSearch";
              }
            } else {
              this.ListURL = "/DialysisMachine/DMRepairDetailSearch";
            }
            const tempDate = this.today;
            this.f.repairStartDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });


            if (this.f.dmRepairDetailsId.value != "0") {
              this.setModel();
            } else {
              this.fillDialysisUnit();
              this.auth.GetCurrentUserDivision().subscribe(division => {

                if (division.length === 1) {
                  this.f.selectedDialysisUnits.setValue(division[0]);
                  this.fillEquipment(division[0]);
                  this.fillDialysisStation(division[0]);
                }
                else {
                  this.fillEquipment(0);
                  this.fillDialysisStation(0);
                }

                this.fillUser();
                // this.fillEquipment(0);
                // this.fillDialysisStation(0);
                this.fillStatus();
                this.fillCalibrationsData(0);
                this.fillElectricalData(0);

              }, error => {
                AppComponent.HideLoader();
              });
            }

          this.fillHydraulicDocList("Bellco Hydraulics");

          if (this.f.dmRepairDetailsId.value == 0) {

            this.f.selectedEquipment.setValidators([Validators.required]);
            this.f.selectedEquipment.updateValueAndValidity();
            this.f.selectedEquipment.setAsyncValidators([
              DMRepairCustomValidators.EquipmentRepairStatusValidate(
                this.f.dmRepairDetailsId,
                this.dmapi,
                true
              )
            ]);

            // this.f.selectedDialysisStation.setValidators([Validators.required]);
            // this.f.selectedDialysisStation.updateValueAndValidity();

            this.f.selectedinsurfacedis.setValidators([Validators.required]);
            this.f.selectedinsurfacedis.updateValueAndValidity();

            this.f.selectedinhydraulicdis.setValidators([Validators.required]);
            this.f.selectedinhydraulicdis.updateValueAndValidity();


            this.setHoursInValidation(this.hrsTracking);
            // this.f.hoursin.setValidators([
            //   Validators.required,
            //   Validators.pattern(Globals.NumberNumericRegexWithoutZero)
            // ]);

            // this.f.hoursin.setAsyncValidators([
            //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
            //     this.f.selectedEquipment,
            //     this.f.dmRepairDetailsId,
            //     this.dmapi
            //   )
            // ]);
            // this.f.hoursin.updateValueAndValidity();


          } else {
          }
          //
          // if(this.status != 'Completed')
          // {
          //   this.setHoursOutValidation(this.hrsTracking);
          //   this.f.outTimeOfEquipment.setValidators([
          //     CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          //       this.f.repairStartDate
          //     )
          //   ]);
          //   this.f.outTimeOfEquipment.updateValueAndValidity();
          // }
          // this.f.hoursout.setValidators([
          //   ,
          //   Validators.pattern(Globals.NumberNumericRegexWithoutZero),
          //   CommonCustomValidators.CheckDateoutCustomValidation(
          //     this.f.outTimeOfEquipment
          //   ),
          //   CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
          //     this.f.hoursin
          //   )
          // ]);

          // this.f.hoursout.updateValueAndValidity();

          // this.f.selectedOutsurfacedis.setValidators([
          //   CommonCustomValidators.CheckDateoutCustomValidation(
          //     this.f.outTimeOfEquipment
          //   )
          // ]);
          // this.f.selectedOutsurfacedis.updateValueAndValidity();

            if (this.DetailTypes == "") {
              this.f.selectedouthydraulicdis.setValidators([
                CommonCustomValidators.CheckDateoutCustomValidation(
                  this.f.outTimeOfEquipment
                )
              ]);
              this.f.selectedouthydraulicdis.updateValueAndValidity();
            }
          });
        } else {
          this.f.dmRepairDetailsId.setValue("0");
          this.fillDialysisUnit();
          this.fillUser();
          this.fillEquipment(0);
          this.fillDialysisStation(0);
          this.fillStatus();
          this.fillCalibrationsData(0);
          this.fillElectricalData(0);
        }
        let tempFormDate = this.today;
        this.f.formDate.setValue({
          date: {
            year: tempFormDate.getFullYear(),
            month: tempFormDate.getMonth() + 1,
            day: tempFormDate.getDate()
          },
          jsdate: tempFormDate
        });

        this.SetPageRights();
        AppComponent.HideLoader();
      }, error => {
        AppComponent.HideLoader();
      });
    }, 500);
  }

  get f() {
    return this.repairDetailForm.controls;
  }

  setModel() {

    AppComponent.ShowLoader();
    const dmrepairCreate = { Id: this.f.dmRepairDetailsId.value };
    this.fillCalibrationsData(this.f.dmRepairDetailsId.value);
    this.fillElectricalData(this.f.dmRepairDetailsId.value);
    this.fillTroubleshottingData(this.f.dmRepairDetailsId.value);
    this.dmapi.GetDMRepairDetailsByID(dmrepairCreate).subscribe(
      success => {

        if (success) {

          this.f.dmRepairDetailsId.setValue(success.dmRepairDetailsId);
          this.repairId = success.repairId;
          this.repairStartDateLabel = success.repairStartDate;
          this.notes.Note = "";
          this.attachment.clearQueue();
          const tempDate = new Date(success.repairStartDate);
          this.f.repairStartDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.repairStartDate
          });

          const tempcreatedOn = new Date(success.createdOn);
          this.f.formDate.setValue({
            date: {
              year: tempcreatedOn.getFullYear(),
              month: tempcreatedOn.getMonth() + 1,
              day: tempcreatedOn.getDate()
            },
            jsdate: success.createdOn
          });


          this.UserName = success.createdByName;
          this.f.workOrderRefNo.setValue(success.workOrderRefNo);

          if (
            success.outTimeOfEquipment != undefined &&
            success.outTimeOfEquipment != null
          ) {
            this.outTimeOfEquipmentLabel = success.outTimeOfEquipment;
            const tempDateoutateout = new Date(success.outTimeOfEquipment);

            this.f.outTimeOfEquipment.setValue({
              date: {
                year: tempDateoutateout.getFullYear(),
                month: tempDateoutateout.getMonth() + 1,
                day: tempDateoutateout.getDate()
              },
              jsdate: success.outTimeOfEquipment
            });
          }

          this.f.hoursout.setValue(success.hoursout);

          if (success.status == 'Not Started' || success.hoursin == 0) {
            this.blnHoursInVal = true;
          } else {
            this.blnHoursInVal = false;
          }

          this.status = success.status;
          if (success.remark != null && success.remark != '') {
            this.showRemarks = false;

            if(success.remark.includes('|'))
            {
              this.f.remarks.setValue(success.remark.substr(0, success.remark.indexOf('|')));
            }
            else
            {
              this.f.remarks.setValue(success.remark);
            }
          }

          if (this.status == "Completed") {
            this.tooltipText = "Click To View";
          }
          // if(this.status == 'Open')
          // {
          //    this.f.hoursout.setValidators([Validators.required, CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
          //     this.f.hoursin
          //   )]);
          //    this.f.hoursout.updateValueAndValidity();

          //    this.f.outTimeOfEquipment.setValidators([Validators.required, CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          //     this.f.repairStartDate
          //   )]);
          //    this.f.outTimeOfEquipment.updateValueAndValidity();

          //    this.f.selectedOutsurfacedis.setValidators([Validators.required]);
          //    this.f.selectedOutsurfacedis.updateValueAndValidity();

          //    this.f.selectedouthydraulicdis.setValidators([Validators.required]);
          //    this.f.selectedouthydraulicdis.updateValueAndValidity();
          // }

          //CREATE REPAIR FROM INCIDENT - START
          if (this.status == "Not Started") {
            // this.f.hoursin.setValidators([
            //   Validators.required,
            //   Validators.pattern(Globals.NumberNumericRegexWithoutZero)
            // ]);

            // this.f.hoursin.setAsyncValidators([
            //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
            //     this.f.selectedEquipment,
            //     this.f.dmRepairDetailsId,
            //     this.dmapi
            //   )
            // ]);

            // this.f.hoursin.updateValueAndValidity();
            this.setHoursInValidation(this.hrsTracking);

            this.f.outTimeOfEquipment.setValidators(
              CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
                this.f.repairStartDate
              )
            );
            this.f.outTimeOfEquipment.updateValueAndValidity();

            this.f.selectedinsurfacedis.setValidators([Validators.required]);
            this.f.selectedinsurfacedis.updateValueAndValidity();

            this.f.selectedinhydraulicdis.setValidators([Validators.required]);
            this.f.selectedinhydraulicdis.updateValueAndValidity();
          }

          if (success.hoursin === 0 && this.status == "Not Started")
            this.f.hoursin.setValue("");
          else {
            this.f.hoursin.setValue(success.hoursin);
            this.showHoursInTextBox = false;
          }
          if (
            success.inSurfaceDisinfection === "N/A" &&
            this.status == "Not Started"
          )
            this.f.selectedinsurfacedis.setValue("");
          else
            this.f.selectedinsurfacedis.setValue(success.inSurfaceDisinfection);

          if (
            success.inHydraulicDisinfection === "N/A" &&
            this.status == "Not Started"
          )
            this.f.selectedinhydraulicdis.setValue("");
          else
            this.f.selectedinhydraulicdis.setValue(
              success.inHydraulicDisinfection
            );

          if (success.hoursout === 0 && this.status == "Not Started")
            this.f.hoursout.setValue("");
          else
            this.f.hoursout.setValue(success.hoursout);

          //CREATE REPAIR FROM INCIDENT - END

          if (
            success.outSurfaceDisinfection === "N/A" &&
            (this.status != "Completed")
          )
            this.f.selectedOutsurfacedis.setValue("");
          else
            this.f.selectedOutsurfacedis.setValue(success.outSurfaceDisinfection);

          if (
            success.outHydraulicDisinfection === "N/A" &&
            (this.status != "Completed")
          )
            this.f.selectedouthydraulicdis.setValue("");
          else
            this.f.selectedouthydraulicdis.setValue(
              success.outHydraulicDisinfection
            );

          this.createdOn = success.createdOn;
          this.createdBy = success.createdBy;
          this.modifiedOn = success.modifiedOn;
          this.modifiedBy = success.modifiedBy;



          this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
          this.f.selectedEquipment.setValue(success.equipmentId.toString());

          var equipmentId = { Id: this.f.selectedEquipment.value };
          this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
            if (result) {
              this.selectedEquipmentHours = result;
              this.f.selectedHoursOut.setValue(result);
            } else {
              this.selectedEquipmentHours = 0;
              this.f.selectedHoursOut.setValue("");

            }
          });

          if (this.parts != undefined) {
            this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;
            this.parts.status = this.status;
            this.parts.SelectedEquipment = this.f.selectedEquipment.value;
          }
          this.notes.DivisionId = this.f.selectedDialysisUnits.value;
          this.notes.status = this.status;
          this.selectedDialysisUnitstext = success.divisionName;
          if (success.nurseId != null) {
            //          this.f.selectedUser.setValue(success.nurseId.toString());
          }
          this.notes.EquipementId = this.f.selectedEquipment.value;

          var dmrepairCreate = { Id: this.f.selectedEquipment.value };
          this.dmapi
            .GetEquipmentDetailsByID(dmrepairCreate)
            .subscribe(success => {
              this.EquipmentSerialNo = success.serialnumber;
              this.EquipmentInServiceDate = success.inservicedate;
              this.modelId = success.modelid;
              this.FillModelReference(success.model);
              if(this.status != "Not Started")
              {
                this.collapsedmEquiInClass = 'collapsed';
                this.collapseariaexpanded = false;
                this.collapsedmEquiIn = 'panel-collapse collapse'
              }
              this.hrsTracking = success.hrsTracking;

              if(this.status != 'Completed')
              {
                if(!this.hrsTracking)
                  this.setHoursInValidation(this.hrsTracking);

                // this.setHoursOutValidation(this.hrsTracking);
              }

            });
          this.dmapi.GetwarrentyStatus(dmrepairCreate).subscribe(success => {
            this.WarrentyStatus = success;
          });


          var dmReparirObj = { Id: this.f.dmRepairDetailsId.value };
          this.dmapi
            .GeterrorCodeDetailsbydmRepairDetailid(dmReparirObj)
            .subscribe(list => {
              this.ErrorTrans = [];
              for (let childObj in list) {

                var temp = list[childObj];
                var obj = <any>{};
                obj.repairId = temp.repairId;
                obj.errorCodeid = temp.errorCodeId;
                obj.ErrorCode = temp.errorCode;
                obj.errorDescription = temp.errorDesc;
                obj.createdBy = temp.createdby;

                obj.createdOn = temp.createdOn;
                if (obj.createdBy != null) {
                  obj.showDelete = false;
                  if (obj.createdBy == this.currentUserId) {
                    obj.showDelete = true;
                  }
                }

                this.ErrorTrans.push(obj);

                this.collapseerr = 'collapsed';
                this.collapseerrexpanded = false;
                this.collapsederrctl = 'panel-collapse collapse';
              }
            });

          this.dmapi
            .GetRepairPartsDetailsbydmRepairDetailid(dmReparirObj)
            .subscribe(list => {
              if(this.parts)
              this.parts.PartTrans = [];
              this.totalCost = 0;
              for (let childObj in list) {

                var temp = list[childObj];
                var obj = <any>{};
                obj.DMRepairDetailsId = temp.dmRepairDetailsId;
                obj.equipmentid = temp.equipmentId;
                obj.quantity = temp.quantity;
                obj.PartCode = temp.equipmentcode;
              //  obj.PartDescription = temp.equipmentName;
                obj.PartDescription = temp.equipmentDesc;
                obj.PartCost = temp.itemCost;
                obj.createdBy = temp.createdBy;
                obj.createdByName = temp.createdByName;
                obj.createdOn = temp.createdOn;
                obj.modifiedBy = temp.modifiedBy;
                obj.modifiedOn = temp.modifiedOn;
                obj.LocationText = "0";
                obj.showPartDelete = false;
                // tslint:disable-next-line:triple-equals
                if (obj.createdBy == this.currentUserId) {
                  obj.showPartDelete = true;
                }
                this.parts.PartTrans.push(obj);

                this.totalCost = this.totalCost + (obj.PartCost * obj.quantity);
              }

              if(this.parts)
              this.notes.Parts = this.parts.PartTrans;

              this.ShowParts =
                this.status != "Completed" || (this.parts && this.parts.PartTrans.length > 0);
              // this.ShowErrors = (this.status !='Completed' || this.ErrorTrans.length > 0)
            });
          this.dmapi
            .GetattachmentDetailsbydmRepairDetailid(dmReparirObj)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });

          this.dmapi.GetnotesbydmRepairDetailid(dmReparirObj).subscribe(list => {
            this.notes.NoteList = list;
          });
          this.fillDialysisUnit();

          if(success.stationName)
            this.selectedDialysisStationtext = success.stationName;

          if (success.equipmentName)
            this.selectedEquipmenttext = success.equipmentName;
          //this.selectedUsertext = success.nurse;

          if (success.stationId != null || success.stationId != undefined) {
            this.f.selectedDialysisStation.setValue(success.stationId.toString());
          }
          this.fillEquipment(this.f.selectedDialysisUnits.value);
          this.fillDialysisStation(this.f.selectedDialysisUnits.value);
          this.fillUser();
          AppComponent.HideLoader();
        }
      },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  dmEquipOutChange(event) {

    let elm = document.getElementById("dmEquipOutRefID");

    if (elm.className === "collapsed") {
      // if(this.status == 'Open')
      //     {
      // this.f.hoursout.setValidators([
      //   Validators.required,
      //   CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
      //     this.f.hoursin
      //   )
      // ]);
      // this.f.hoursout.updateValueAndValidity();


      // if(this.hrsTracking) {
      //   this.f.hoursout.setValidators([Validators.required]);
      //   this.f.hoursout.updateValueAndValidity();
      // }

      this.setHoursOutValidation();

      this.f.outTimeOfEquipment.setValidators([
        Validators.required,
        CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          this.f.repairStartDate
        )
      ]);
      this.f.outTimeOfEquipment.updateValueAndValidity();

      this.f.selectedOutsurfacedis.setValidators([Validators.required]);
      this.f.selectedOutsurfacedis.updateValueAndValidity();

      this.f.selectedouthydraulicdis.setValidators([Validators.required]);
      this.f.selectedouthydraulicdis.updateValueAndValidity();
      // }
    } else {
      if (this.status != "Completed") {
        this.f.hoursout.setValue("");
        this.f.outTimeOfEquipment.setValue("");
        this.f.selectedOutsurfacedis.setValue("");
        this.f.selectedouthydraulicdis.setValue("");
      }
      this.f.hoursout.clearValidators();
      this.f.hoursout.updateValueAndValidity();

      this.f.outTimeOfEquipment.clearValidators();
      this.f.outTimeOfEquipment.updateValueAndValidity();

      this.f.selectedOutsurfacedis.clearValidators();
      this.f.selectedOutsurfacedis.updateValueAndValidity();

      this.f.selectedouthydraulicdis.clearValidators();
      this.f.selectedouthydraulicdis.updateValueAndValidity();
      // }
    }

    // alert('Test');
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: "dd/mm/yyyy",
    closeSelectorOnDateSelect: false,
    disableSince: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate() + 1
    }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  onDateChanged(event: IMyDateModel): void { }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;
      // this.DialysisUnitsWithoutHome = list.filter(
      //   x => x.text != "Home Program"
      // );
      // if(list.filter(x=>x.text == 'Home Program').length != 0)
      // {
      //   list.filter(x=> x.text == 'Home Program')[0].text = 'Home Pool';
      // }


       this.DialysisUnitsWithoutHome = list;


      if (this.f.dmRepairDetailsId.value != "0" && this.f.dmRepairDetailsId.value != "") {
        this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
          x => x.value === this.f.selectedDialysisUnits.toString()
        );
        if (this.selectedDialysisUnitsobj &&  this.selectedDialysisUnitsobj.length > 0) {
          this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
        }
      } else {

        if (this.homeService) {
          this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
            x => x.text === 'Home Program'
          );
          if (this.selectedDialysisUnitsobj && this.selectedDialysisUnitsobj.length > 0) {
            this.f.selectedDialysisUnits.setValue(this.selectedDialysisUnitsobj[0].value);
            this.fillDialysisStation(this.selectedDialysisUnitsobj[0].value);
            this.fillEquipment(this.selectedDialysisUnitsobj[0].value);
          }
        }
      }
    });
  }

  fillEquipment(divisionID) {
    if (divisionID != 0) {


      this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
        x => x.value === divisionID
      );

      if (this.selectedDialysisUnitsobj && this.selectedDialysisUnitsobj[0] && this.selectedDialysisUnitsobj[0].text == 'Home Program') {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {

          //list.push({ text: 'Select', value: '' });
          list.unshift({ text: 'Select', value: '' });
          this.Equipments = list;
        });
      }
      else {
        this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
          this.Equipments = list;
        });
      }

    } else {
      this.Equipments = [{ value: "", text: "Select" }];
      this.f.selectedEquipment.setValue("");
    }
  }

  fillUser() {
    this.dmapi.GetUsersDD().subscribe(list => {
      this.Users = list;

      // if(this.f.dmRepairDetailsId.value!='0')
      //    {
      //        this.selectedUserobj = this.Users.filter(x => x.value == this.selectedUser.toString());
      //        this.selectedUsertext = this.selectedUserobj[0].text;
      //    }
    });
  }

  fillParts() {
    this.dmapi.GetPartsDD().subscribe(list => (this.Parts = list));
  }

  fillStatus() {
    this.dmapi.GetStatusDD().subscribe(list => (this.Statuses = list));
  }

  HomeServiceSetModel() {

    const dmrepairCreate = { Id: this.f.HFServiceId.value };
    this.dmapi.GetDMRepairIdFromHomeserviceId(dmrepairCreate).subscribe(
      success => {
        if (success.id > 0) {
          this.f.dmRepairDetailsId.setValue(success.id);
        } else {
          this.f.dmRepairDetailsId.setValue(0);
        }
        const tempDate = this.today;
        this.f.repairStartDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        if (this.f.dmRepairDetailsId.value != "0") {
          this.setModel();
        }
        this.fillHydraulicDocList("Bellco Hydraulics");
        if (this.f.dmRepairDetailsId.value == 0) {
          this.f.selectedEquipment.setValidators([Validators.required]);
          this.f.selectedEquipment.updateValueAndValidity();
          this.f.selectedEquipment.setAsyncValidators([
            DMRepairCustomValidators.EquipmentRepairStatusValidate(
              this.f.dmRepairDetailsId,
              this.dmapi,
              true
            )
          ]);
          this.f.selectedinsurfacedis.setValidators([Validators.required]);
          this.f.selectedinsurfacedis.updateValueAndValidity();

          this.f.selectedinhydraulicdis.setValidators([Validators.required]);
          this.f.selectedinhydraulicdis.updateValueAndValidity();
          if(this.status != 'Completed')
          {
            this.setHoursInValidation();
          }

          // this.f.hoursin.setValidators([
          //   Validators.required,
          //   Validators.pattern(Globals.NumberNumericRegexWithoutZero)
          // ]);

          // this.f.hoursin.setAsyncValidators([
          //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
          //     this.f.selectedEquipment,
          //     this.f.dmRepairDetailsId,
          //     this.dmapi
          //   )
          // ]);
          // this.f.hoursin.updateValueAndValidity();

          this.f.outTimeOfEquipment.setValidators([
            CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
              this.f.repairStartDate
            )
          ]);
          this.f.outTimeOfEquipment.updateValueAndValidity();
        } else {
        }
      },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  fillDialysisStation(divisionID) {
    if (divisionID !== 0) {
      this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
        this.DialysisStation = list;

        // if(this.f.dmRepairDetailsId.value!='0')
        //    {
        //     this.selectedDialysisStationobj = this.DialysisStation.filter(x => x.value == this.selectedDialysisStation.toString());
        //     this.selectedDialysisStationtext = this.selectedDialysisStationobj[0].text;
        //    }
      });
    } else {
      this.DialysisStation = [{ value: "", text: "Select" }];

      if (this.homeService !== true)
        this.f.selectedDialysisStation.setValue("");
    }
  }

  SetHomeServiceHoursoutValidation(enable) {
    this.hfcompleted = enable;
    if (enable == true) {
      // this.f.hoursout.setValidators([
      //   ,
      //   Validators.pattern(Globals.NumberNumericRegexWithoutZero),
      //   CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.outTimeOfEquipment
      //   ),
      //   CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
      //     this.f.hoursin
      //   )
      // ]);

      // this.f.hoursout.updateValueAndValidity();
      this.setHoursOutValidation();
      this.f.selectedouthydraulicdis.setValidators([
        CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.outTimeOfEquipment
        )
      ]);
      this.f.selectedouthydraulicdis.updateValueAndValidity();
    } else {
      this.f.hoursout.clearValidators();
      this.f.hoursout.updateValueAndValidity();
      this.f.selectedouthydraulicdis.clearValidators();
      this.f.selectedouthydraulicdis.updateValueAndValidity();
    }
  }

  fillHydraulicDocList(sectionname) {
    var dmrepairCreate = { str: sectionname };
    this.dmapi
      .GetHydrolicDocDD(dmrepairCreate)
      .subscribe(list => (this.HydraulicDocList = list));
  }

  executeDmrepairCreateUpdate = function (Source, isFlyout = false) {
    return new Promise((resolve, reject) => {

      AppComponent.loading = true;

      this.isValidFormSubmitted = false;

      // if(this.DetailTypes != 'DM')
      // {

      if (this.repairDetailForm.invalid) {
        AppComponent.addAlert(Globals.FormErrorMessage, "error");
        AppComponent.loading = false;

        resolve({ 'isSubmitted': false, 'errormsg': Globals.FormErrorMessage });

        if(!isFlyout)
          this.scrollHelper.scrollToFirst("ng-invalid");

        AppComponent.ScrollDown();
        return;
      }
      //}

      this.isValidFormSubmitted = true;

      if (Source === "Notes" && this.notes.showParts === true) {
        if (this.parts != undefined) {

          const partTrans = this.parts.PartTrans;
          this.parts.SelectedEquipment = this.f.selectedEquipment.value;

          this.notes.Parts.forEach(function (value) {
            partTrans.push(value);
          });
          this.parts.PartTrans = partTrans;

        }
      }
      this.f.hoursout.updateValueAndValidity();
      //this.f.selectedOutsurfacedis.updateValueAndValidity();
      this.f.selectedouthydraulicdis.updateValueAndValidity();

      // this.isValidFormSubmitted = false;
      // console.log(this.f);

      // // if(this.DetailTypes != 'DM')
      // // {
      //   if (this.repairDetailForm.invalid) {
      //     AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      //     AppComponent.loading = false;

      //     this.scrollHelper.scrollToFirst('ng-invalid');
      //     AppComponent.ScrollDown();
      //     return;
      //   }
      // //}

      // this.isValidFormSubmitted = true;

      const dmrepairCreate = this.prepareCreateViewModel();

      if (this.notes.NoteList.length == 0 && this.notes.Note == '') {
        AppComponent.loading = false;
        AppComponent.addAlert('Please add at least one Repair Note to save this DM Repair.', 'error');

        resolve({ 'isSubmitted': false, 'errormsg': 'Please add at least one Repair Note to save this DM Repair.' });

        this.scrollHelper.scrollToFirst('panel-collapse collapse show');
        AppComponent.HideLoader();
        let elm = document.getElementById("dmPartsRef");
        elm.click();
        return;
      }
      else {
        if (dmrepairCreate.DMRepairDetailsId == "0") {
          this.dmapi.CreateDMRepairDetails(dmrepairCreate).subscribe(
            success => {
              this.DMRepailDetail = success.dmRepailDetails;
              AppComponent.loading = false;
              AppComponent.addAlert("Data Saved successfully!", "success");


              //this.router.navigate(['DialysisMachine/DMRepairDetailSearch']);
              if (Source != "Notes" && !isFlyout) {
                if (this.homeService != true) {
                  this.router.navigate(["DialysisMachine/DMRepairDetailSearch"]);
                }
                else {
                  this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
                }
              } else {
                this.f.dmRepairDetailsId.setValue(success.dmRepairDetailsId);
                setTimeout(() => {
                  this.setModel();
                }, 500);
              }

              resolve({ 'isSubmitted': true, 'errormsg': 'Data Saved successfully!' });
            }
            ,
            error => {
              AppComponent.addAlert(error.message, "error");
              reject();
              AppComponent.HideLoader();
            }
          );
        } else {
          this.dmapi.UpdateDMRepairDetails(dmrepairCreate).subscribe(
            success => {
               this.DMRepailDetail = success.dmRepailDetails;
              AppComponent.loading = false;
              AppComponent.addAlert("Data Saved successfully!", "success");
              if (Source != "Notes" && !isFlyout) {
                if (this.homeService != true) {
                  if (this.SourceList === "M") {
                    this.router.navigate(["DialysisMachine/DMRepairDetailSearch"]);
                  } else {
                    if (this.DetailTypes === "DM") {
                      this.router.navigate([
                        "DialysisMachine/MRMHistoryUploadSearch"
                      ]);
                    } else {
                      this.router.navigate(["DialysisMachine/DMRepairDetailSearch"]);
                    }
                  }
                } else {
                  this.router.navigate(['HomeFieldService/HomeFieldServiceSearch']);
                }
              } else {

                this.setModel();
                if (isFlyout)
                  resolve({ 'isSubmitted': true, 'errormsg': 'Data Saved successfully!' });
              }
            },
            error => {
              AppComponent.addAlert(error.message, "error");
              reject();
              AppComponent.HideLoader();
            }
          );
        }

        this.f.hoursin.clearAsyncValidators();
        this.f.hoursin.clearValidators();
        this.f.hoursin.updateValueAndValidity();

        this.f.hoursout.clearAsyncValidators();
        this.f.hoursout.clearValidators();
        this.f.hoursout.updateValueAndValidity();
      }
    });
  };

  prepareCreateViewModel() {
    const dmrepairCreate = <any>{};

    dmrepairCreate.DMRepairDetailsId = this.f.dmRepairDetailsId.value;
    dmrepairCreate.repairId = this.repairId;
    if (
      this.f.repairStartDate.value !== null &&
      this.f.repairStartDate.value !== undefined
    ) {
      dmrepairCreate.repairStartDate = Globals.GetAPIDate(
        this.f.repairStartDate.value.jsdate
      );
    }

    dmrepairCreate.workOrderRefNo = this.f.workOrderRefNo.value;

    if (
      this.f.outTimeOfEquipment.value !== null &&
      this.f.outTimeOfEquipment.value !== undefined
    ) {
      dmrepairCreate.outTimeOfEquipment = Globals.GetAPIDate(
        this.f.outTimeOfEquipment.value.jsdate
      );

    }

    dmrepairCreate.createdOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.createdBy = this.currentUserId;
    dmrepairCreate.HFServiceId = this.f.HFServiceId.value;
    dmrepairCreate.modifiedOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.modifiedBy = this.currentUserId;

    dmrepairCreate.remark = this.f.remarks.value;
    dmrepairCreate.hoursout = this.f.hoursout.value;
    dmrepairCreate.hoursin = this.f.hoursin.value;

    dmrepairCreate.status = this.status;
    dmrepairCreate.WarrentyStatus = this.WarrentyStatus;
    // dmrepairCreate.loggedInUsers = this.loggedInUsers;
    dmrepairCreate.DivisionId = this.f.selectedDialysisUnits.value;
    dmrepairCreate.equipmentId = this.f.selectedEquipment.value;
    //dmrepairCreate.nurseId = ;
    dmrepairCreate.stationId = this.f.selectedDialysisStation.value;
    dmrepairCreate.inSurfaceDisinfection = this.f.selectedinsurfacedis.value;
    dmrepairCreate.inHydraulicDisinfection = this.f.selectedinhydraulicdis.value;
    dmrepairCreate.outSurfaceDisinfection = this.f.selectedOutsurfacedis.value;
    dmrepairCreate.outHydraulicDisinfection = this.f.selectedouthydraulicdis.value;
    dmrepairCreate.notes = this.notes.Note;
    // errorcode
    dmrepairCreate.dmRepairErrorTrans = this.ErrorTrans;

    if (dmrepairCreate.DMRepairDetailsId > 0) {
      if (this.SourceList === "M") {
        if (this.DetailTypes === "DM") {
          dmrepairCreate.isUploadHistory = true;
        } else {
          dmrepairCreate.isUploadHistory = false;
        }
        this.ListURL = "/DialysisMachine/DMRepairDetailSearch";
      } else {
        if (this.DetailTypes === "DM") {
          dmrepairCreate.isUploadHistory = true;
        } else {
          dmrepairCreate.isUploadHistory = false;
        }
        this.ListURL = "/DialysisMachine/MRMHistoryUploadSearch";
      }
    } else {
      dmrepairCreate.isUploadHistory = false;
      this.ListURL = "/DialysisMachine/DMRepairDetailSearch";
    }

    // parts
    try {
      if (this.parts.PartTrans === undefined) {
        dmrepairCreate.dmRepairPartsDetails = null;
      } else {
        dmrepairCreate.dmRepairPartsDetails = this.parts.PartTrans;
      }
    } catch {
      dmrepairCreate.dmRepairPartsDetails = null;
    }
    // attachments

    dmrepairCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    // callibration
    this.GenerateCalibtrtionList();
    dmrepairCreate.calibrationsTran = this.calibrationsTran;
    // Electrical
    this.GenerateElectricalList();
    dmrepairCreate.electricalTran = this.electricalTran;
    // Trouble shooting

    this.GenerateTroubleshottingList();
    dmrepairCreate.troubleShootingTran = this.TroubleshottingData;

    return dmrepairCreate;
  }

  DialysisUnitDropDownChanged() {

    this.modelId = 0;
    if (this.parts != undefined) {
      this.parts.SelectedDialysisUnits = this.f.selectedDialysisUnits.value;
    }

    this.notes.DivisionId = this.f.selectedDialysisUnits.value;
    this.fillEquipment(this.f.selectedDialysisUnits.value);
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    this.FillModelReference("");

    if (this.f.dmRepairDetailsId.value == 0) {
      this.f.selectedEquipment.setValue("");
      this.f.hoursin.setValue("");
      this.selectedEquipmentHours = 0;
      this.f.selectedHoursOut.setValue("");
    }
  }

  EquipmentDropDownChanged() {
    this.f.hoursin.setValue("");
    var equipmentId = { Id: this.f.selectedEquipment.value };

    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
        this.f.selectedHoursOut.setValue(result);
      } else {
        this.selectedEquipmentHours = 0;
        this.f.selectedHoursOut.setValue("");
      }
    });

    if (this.parts != undefined) {
      this.parts.SelectedEquipment = this.f.selectedEquipment.value;
    }
    this.notes.EquipementId = this.f.selectedEquipment.value;
    if (this.f.dmRepairDetailsId.value == "0") {
      var dmrepairCreate = { Id: this.f.selectedEquipment.value };

      this.dmapi.GetwarrentyStatus(dmrepairCreate).subscribe(success => {
        this.WarrentyStatus = success;
      });

      this.dmapi.GetEquipmentDetailsByID(dmrepairCreate).subscribe(success => {

        this.EquipmentSerialNo = success.serialnumber;
        this.EquipmentInServiceDate = success.inservicedate;

        if (success.equipmentcode)
          this.selectedEquipmenttext = success.equipmentcode;

        this.modelId = success.modelid;

        //this.selectedEquipmenttext = success.
        this.FillModelReference(success.model);

        this.hrsTracking = success.hrsTracking;

        this.setHoursInValidation(this.hrsTracking);
        this.setHoursOutValidation(this.hrsTracking);
      });
    }
    else {

      var dmrepairCreate = { Id: this.f.selectedEquipment.value };

      this.dmapi.GetEquipmentDetailsByID(dmrepairCreate).subscribe(success => {

        this.EquipmentSerialNo = success.serialnumber;
        this.EquipmentInServiceDate = success.inservicedate;

        if (success.equipmentcode)
          this.selectedEquipmenttext = success.equipmentcode;

        this.modelId = success.modelid;
        this.FillModelReference(success.model);
      });
    }
  }

  FillModelReference(model) {
    let lstRefId = { str: model };

    this.dmapi.FillModelReference(lstRefId).subscribe(success => {
      if (success) {
        this.HydraulicList = success.filter(
          x => x.referenceType == "Hydraulic"
        );
        this.MachineLayoutList = success.filter(
          x => x.referenceType == "MachineLayout"
        );
        this.ElectricalList = success.filter(
          x => x.referenceType == "Electrical"
        );
        this.CallibrationList = success.filter(
          x => x.referenceType == "Callibration"
        );
      }
    });
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const dmReparirObj = { Id: this.f.dmRepairDetailsId.value };
        this.dmapi.DeleteDMRepairDetailsByID(dmReparirObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              "DM Repair detail deleted successfully!",
              "success"
            );

            if (this.SourceList === "M") {
              this.router.navigate(["DialysisMachine/DMRepairDetailSearch"]);
            } else {
              this.router.navigate(["DialysisMachine/MRMHistoryUploadSearch"]);
            }
            //            this.router.navigate(['DialysisMachine/DMRepairDetailSearch']);
          },
          error => {
            AppComponent.addAlert(error.message, "error");
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  // Error variables

  // ErrorDescription='';
  ErrorSearchTerm = "";
  errorSearchResult = [];
  ErrorAutoComplete = "test";
  ErrorSelectedItem = { code: "", id: 0, desc: "" };
  ErrorTrans = [];

  ErrorCodeChange(type, fetchAll: boolean = true) {

    if (this.f.selectedEquipment.value > 0) {
      var dmrepairCreate = <any>{};

      if (type == "code") {
        dmrepairCreate.errorCodeSearchtext = this.f.ErrorSearchTerm.value;
        dmrepairCreate.errorDesc = "";

      }
      else {
        dmrepairCreate.errorDesc = this.f.ErrorDescription.value;
        dmrepairCreate.errorCodeSearchtext = "";
      }
      dmrepairCreate.equipmentId = this.f.selectedEquipment.value;
      if (fetchAll)
        this.errorSearchResult = [];

      this.dmapi.search_word(dmrepairCreate).subscribe(list => {

        if (fetchAll) {
          this.errorSearchResult = [];
          let y = list.split("\n");
          for (let childObj in y) {
            let stringToSplit = y[childObj];
            let x = stringToSplit.split("|");
            if (x[0] != null && x[0] != "") {


              this.errorSearchResult.push({ code: x[0], id: x[1], desc: x[2] });
            }
          }
        }

        var allSelectedPane = document.querySelectorAll(".cdk-overlay-pane");
        this.duplicateErrorCodes = this.checkDuplicateInObject('code', this.errorSearchResult, this.f.ErrorSearchTerm.value);

        if (this.duplicateErrorCodes && type == "code") {
          allSelectedPane[allSelectedPane.length - 1]['style']['min-width'] = '70%';
        }
        else {
          allSelectedPane[allSelectedPane.length - 1]['style']['min-width'] = 'auto';
        }
      });
    }
  }

  getErrorPosts(option, type) {

    this.ErrorSelectedItem = this.errorSearchResult.filter(
      x => x.id == option.value
    )[0];

    if (this.ErrorSelectedItem && this.ErrorSelectedItem.code) {
      this.f.ErrorSearchTerm.setValue(this.ErrorSelectedItem.code);
      this.f.ErrorDescription.setValue(this.ErrorSelectedItem.desc);
    }
  }

  AddError() {
    if (this.ErrorSelectedItem.id != null && this.ErrorSelectedItem.id > 0) {
      var temp = this.ErrorTrans.filter(
        x => x.errorCodeid == this.ErrorSelectedItem.id
      );

      if (temp.length == 0) {
        var obj = <any>{};

        obj.repairId = this.f.dmRepairDetailsId.value;

        obj.errorCodeid = this.ErrorSelectedItem.id;
        obj.ErrorCode = this.ErrorSelectedItem.code;
        obj.errorDescription = this.ErrorSelectedItem.desc;
        obj.createdBy = this.currentUserId;
        obj.createdOn = Globals.GetAPIDate(this.today);
        obj.showDelete = false;
        if (obj.createdBy == this.currentUserId) {
          obj.showDelete = true;
        }
        this.ErrorTrans.push(obj);

        this.f.ErrorSearchTerm.setValue("");
        this.ErrorSelectedItem.desc = "";
        this.errorSearchResult = [];
      }
    }
  }
  DeleteError(index) {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      "Are you sure you want to delete?";
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ErrorTrans.splice(index, 1);
      }
      this.dialogRef = null;
    });
  }

  // Parts variables

  showDoc() {
    if (this.f.selecthydrolicDropDownList.value.value != "") {
      var openURL =
        Globals.DocumentResourcesUrl + this.f.selecthydrolicDropDownList.value;
      window.open(openURL);
    }
  }

  showImage(args): void {

    if (args.target.value != "Select") {
      const dialogRef = this.dialog.open(ImageViewerDialog, {
        position: { right: '0', top: '0' },
        height: '100%',
        width: "620px",
        data: {
          imgURL: args.target.value,
          title: args.target.options[args.target.selectedIndex].text
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  //Machine History
  ShowMachineHistory() {
    this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
    });

    this.machineHistoryDialogRef.componentInstance.EquipId = this.f.selectedEquipment.value;
    this.machineHistoryDialogRef.componentInstance.SrNo = this.EquipmentSerialNo;
    this.machineHistoryDialogRef.componentInstance.InServiceDate = this.EquipmentInServiceDate;
    this.machineHistoryDialogRef.componentInstance.MachineName = this.selectedEquipmenttext;

    this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
      "95%",
      "95%"
    );
    this.machineHistoryDialogRef.afterClosed().subscribe(result => {
      this.machineHistoryDialogRef = null;
    });
  }

  //Calibrations
  CalibrationsData = [];
  fillCalibrationsData(DMRepairId) {
    var dmrepairCreate = { Id: DMRepairId };
    this.dmapi
      .GetdmRepairCalibrationsTransselectByDmrepairid(dmrepairCreate)
      .subscribe(list => {
        this.CalibrationsData = list;
        for (let childObj in this.CalibrationsData) {
          this.CalibrationsData[childObj].tempresult = this.CalibrationsData[
            childObj
          ].result
            ? this.CalibrationsData[childObj].result
            : "Not Tested";
        }
      });
  }
  GenerateCalibtrtionList() {
    this.calibrationsTran = [];
    for (let childObj in this.CalibrationsData) {
      if (this.CalibrationsData[childObj].result != null) {
        this.calibrationsTran.push({
          dmRepairCalibrationsId: this.CalibrationsData[childObj]
            .dmRepairCalibrationsId,
          dmrepairId: this.f.dmRepairDetailsId.value,
          calibrationId: this.CalibrationsData[childObj].calibrationsId,
          result: this.CalibrationsData[childObj].result
        });
      }
    }
  }
  ShowCalibration(event) {
    event.target.style.color = "red";
    if (
      this.f.selectedEquipment.value == "0" &&
      this.f.selectedEquipment.value == ""
    ) {
      AppComponent.addAlert("Select Dialysis Machine for Report", "error");
      return false;
    }

    this.calibrationDialogRef = this.dialog.open(CalibrationsDialog, {
      disableClose: true
    });

    this.calibrationDialogRef.componentInstance.EquipId = this.f.selectedEquipment.value;
    this.calibrationDialogRef.componentInstance.MachineName = this.selectedEquipmenttext;
    this.calibrationDialogRef.componentInstance.CalibrationsData = this.CalibrationsData;
    this.calibrationDialogRef.componentInstance.completed =
      this.status == "Completed";
    this.calibrationDialogRef.componentInstance.dialogRef.updateSize(
      "95%",
      "95%"
    );
    this.calibrationDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.CalibrationsData = this.calibrationDialogRef.componentInstance.CalibrationsData;
      }
      this.calibrationDialogRef = null;
    });
  }

  //Electrical
  ElectricalData = [];
  fillElectricalData(DMRepairId) {
    var dmrepairCreate = { Id: DMRepairId };
    this.dmapi
      .GetdmRepairElectricalTranslstelectByDmrepairid(dmrepairCreate)
      .subscribe(list => {
        this.ElectricalData = list;
        for (let childObj in this.ElectricalData) {
          this.ElectricalData[childObj].tempresult = this.ElectricalData[
            childObj
          ].result
            ? this.ElectricalData[childObj].result
            : "Not Tested";
        }
      });
  }
  GenerateElectricalList() {
    this.electricalTran = [];
    for (let childObj in this.ElectricalData) {
      if (this.ElectricalData[childObj].result != null) {
        this.electricalTran.push({
          dmRepairElectricalId: this.ElectricalData[childObj]
            .dmRepairElectricalId,
          dmrepairId: this.f.dmRepairDetailsId.value,
          electricalId: this.ElectricalData[childObj].electricalId,
          result: this.ElectricalData[childObj].result
        });
      }
    }
  }
  ShowElectrical(event) {
    event.target.style.color = "red";
    if (
      this.f.selectedEquipment.value == "0" &&
      this.f.selectedEquipment.value == ""
    ) {
      AppComponent.addAlert("Select Dialysis Machine for Report", "error");
      return false;
    }

    this.electricalDialogRef = this.dialog.open(ElectricalDialog, {
      disableClose: true
    });

    this.electricalDialogRef.componentInstance.EquipId = this.f.selectedEquipment.value;
    this.electricalDialogRef.componentInstance.MachineName = this.selectedEquipmenttext;
    this.electricalDialogRef.componentInstance.ElectricalData = this.ElectricalData;
    this.electricalDialogRef.componentInstance.completed =
      this.status == "Completed";
    this.electricalDialogRef.componentInstance.dialogRef.updateSize(
      "95%",
      "95%"
    );
    this.electricalDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.ElectricalData = this.electricalDialogRef.componentInstance.ElectricalData;
      }
      this.electricalDialogRef = null;
    });
  }
  //TroubleShotting
  TroubleshottingData = [];
  fillTroubleshottingData(DMRepairId) {
    var dmrepairCreate = { Id: DMRepairId };
    this.dmapi
      .GetdmRepairTroubleShootinglstelectByDmrepairidAndErrorId(dmrepairCreate)
      .subscribe(list => {
        this.TroubleshottingData = list;

      });
  }
  GenerateTroubleshottingList() {

    for (let childObj in this.TroubleshottingData) {
      this.TroubleshottingData[
        childObj
      ].dmrepairId = this.f.dmRepairDetailsId.value;
      this.TroubleshottingData[childObj].createdby = this.currentUserId;
      this.TroubleshottingData[childObj].createdOn = this.today;
    }
  }
  ShowTroubleshooting(event) {
    event.target.style.color = "red";
    if (
      this.ErrorTrans == undefined ||
      this.ErrorTrans == null ||
      this.ErrorTrans.length == 0
    ) {
      AppComponent.addAlert(
        "There is no error select for troubleshooting.",
        "error"
      );
      return false;
    }

    this.troubleShootingDialogRef = this.dialog.open(TrobleshootingDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
    });

    var errorList = [];
    for (let childObj in this.ErrorTrans) {
      errorList.push(this.ErrorTrans[childObj].errorCodeid);
    }
    this.troubleShootingDialogRef.componentInstance.completed =
      this.status == "Completed";
    this.troubleShootingDialogRef.componentInstance.ErrorList = errorList;
    this.troubleShootingDialogRef.componentInstance.TrobleshootingData = this.TroubleshottingData;
    this.troubleShootingDialogRef.componentInstance.dialogRef.updateSize(
      "95%",
      "95%"
    );
    this.troubleShootingDialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.TroubleshottingData = this.troubleShootingDialogRef.componentInstance.TrobleshootingData;
      }
      this.troubleShootingDialogRef = null;
    });
  }

  SetPageRights() {
    this.auth.GetPageRights("DM Repair Form").subscribe(list => {
      if (this.f.dmRepairDetailsId.value != "0") {
        this.blnshowSave = list[actionType.edit] == "1";
        // this.blnshowDelete = list[actionType.delete] == '1';
      } else {
        this.blnshowSave = list[actionType.create] == "1";
        // this.blnshowDelete = false;
      }
    });

    if (
      this.userDesignation == "Administrator" &&
      this.f.dmRepairDetailsId.value != "0"
    ) {
      this.blnshowDelete = true;
    }
  }
  AttachmentError(Error) {
    AppComponent.addAlert(Error, "error");
  }
  GreaterValueCheck() {

    this.f.hoursout.updateValueAndValidity();
    //this.f.selectedOutsurfacedis.updateValueAndValidity();
    this.f.selectedouthydraulicdis.updateValueAndValidity();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  hoursinChange(event: string) {
    if (
      (this.f.dmRepairDetailsId.value == 0 || this.status == "Not Started") &&
      event != ""
    ) {
      this.f.hoursout.setValue("");
    }
  }

  check4000HoursValidation(): boolean {
    if (this.f.hoursin.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }


  checkDuplicateInObject(propertyName, inputArray, searchterm = '') {
    var seenDuplicate = false;

    var result = _.groupBy(inputArray, c => c['code']);
    for (let prop in result) {
      if (result[prop].length > 1) {
        result[prop].forEach(function (item, newKey) {
          item['duplicate'] = true;
          seenDuplicate = true;
        });
      }
    }
    // var testObject = {};

    // inputArray.map(function(item) {
    //   var itemPropertyName = item[propertyName];
    //   if (itemPropertyName in testObject) {
    //     testObject[itemPropertyName].duplicate = true;
    //     item.duplicate = true;
    //     seenDuplicate = true;
    //   }
    //   else {
    //     testObject[itemPropertyName] = item;
    //     delete item.duplicate;
    //   }
    // });
    // else
    // if(inputArray && inputArray.length > 1 && inputArray.filter(x=>x.code==inputArray[0].code).length == inputArray.length)
    // {
    //   seenDuplicate = true;
    // }

    return seenDuplicate;
  }

  checkHoursInOutDifference(): boolean {
    if (
      this.f.hoursout.value == null ||
      this.f.hoursout.value == "" ||
      this.f.hoursout.value == undefined
    ) {
      return false;
    } else {
      // if(this.status != 'Completed')
      // {
      //   this.setHoursOutValidation(this.hrsTracking);
      // }

      if (this.f.hoursout.value - this.f.hoursin.value >= 100) {

        if (document.getElementById("spanInGreaterOut") != null && document.getElementById("spanInGreaterOut").style.display == "block")
          document.getElementById("spanInGreaterOut").style.display = "none";
        return true;
      } else {
        return false;
      }


    }
  }

  setHoursInValidation(enable = true){

    if(enable && this.status != 'Completed'){

      this.f.hoursin.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

      this.f.hoursin.setAsyncValidators([
      DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        this.f.selectedEquipment,
        this.f.dmRepairDetailsId,
        this.dmapi
      )
    ]);
      this.f.hoursin.updateValueAndValidity();
    }
    else{
      this.f.hoursin.clearAsyncValidators();
      this.f.hoursin.clearValidators();
      this.f.hoursin.updateValueAndValidity();
    }

  }

  setHoursOutValidation(enable=true){

    if(enable && this.status != 'Completed'){
      if(this.f.dmRepairDetailsId.value == 0)
      {
        this.f.hoursout.setValidators([
          Validators.pattern(Globals.NumberNumericRegexWithoutZero),
          CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.outTimeOfEquipment
          ),
          CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
            this.f.hoursin
          )
        ]);

        this.f.hoursout.updateValueAndValidity();

      }
      else
      {
        this.f.hoursout.setValidators([Validators.required,
          Validators.pattern(Globals.NumberNumericRegexWithoutZero),
          CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.outTimeOfEquipment
        ),
        CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
          this.f.selectedHoursOut
        )
      ]);
        this.f.hoursout.updateValueAndValidity();
      }
    }
    else{
      this.f.hoursout.clearValidators();
      this.f.hoursout.clearAsyncValidators();
      this.f.hoursout.updateValueAndValidity();
    }

  }
}

<style>
    .nonePointerEvents {
      pointer-events: none;
    }

    .allPointerEvents {
      pointer-events: all;
    }
  </style>

<form [formGroup]="WPDailySatelliteDetailForm">
<div class="box ibox box-info">

  <div class="panel panel-default" [ngClass]="{ nonePointerEvents: ((f.CreatedDate.value.jsdate | date: 'dd/MM/yyyy') != (today | date:'dd/MM/yyyy')) && (f.AMDetailsEnteredBy.value == null) }">
    <div class="panel-heading" role="tab" id="headingTwoAM">
      <h4 class="panel-title">
        <a
        [ngClass]="collapseTwoAMClass"
        data-toggle="collapse"
          data-parent="#accordion"
          href="#collapseTwoAM"
          aria-expanded="collapseTwoAMariaexpanded"
          aria-controls="collapseTwoAM"
          id="amPanel"
        >
          <div class="box-header with-border">
            <h3 class="box-title container col-sm-9">
             Total Chlorine Testing Pre AM Shift
              <span *ngIf="f.AMDetailsEnteredOn.value">
                  : {{ this.AMUserName }} -
                  {{ f.AMDetailsEnteredOn.value | date: "dd/MM/yyyy HH:mm" }} -
                  Completed</span>
            </h3>
          </div>
        </a>
      </h4>
    </div>
    <div
    id="collapseTwoAM"
    [ngClass]="collapseTwoAMpanelClass"
    role="tabpanel"
    aria-labelledby="headingTwoAM">
    <div class="panel-body">
      <div class="box-body">
        <div class="row">

            <div class="box-row col-sm-12">
                <div class="col-sm-6" style="padding-left: 0px;">
                  <label class="col-sm-6 control-label">
                    Chlorine Test (Left Bank):
                  </label>

                  <div class="col-sm-6" *ngIf="!f.AMDetailsEnteredOn.value">
                    <input
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="AMChlorineTestLeftBank"
                      placeholder="#.##"
                      (blur)="FormatDecimal('AMChlorineTestLeftBank',1,2)"
                      (keypress)="numberOnlyWithoutDot($event)"
                      [ngClass]="{
                        OurOffRange: f.AMChlorineTestLeftBank.value >= 0.09
                      }"
                      />

                    <span
                      *ngIf="
                        f.AMChlorineTestLeftBank.errors?.required &&
                        (f.AMChlorineTestLeftBank.dirty ||
                          f.AMChlorineTestLeftBank.touched ||
                          isValidFormSubmitted == false)"
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                    <span *ngIf="f.AMChlorineTestLeftBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.AMDetailsEnteredOn.value">
                      <label class="readOnlyText" [ngClass]="{
                        invalidValueBackground: f.AMChlorineTestLeftBank.value >= 0.09
                      }">{{f.AMChlorineTestLeftBank.value | number: "1.2-2"}}</label>
                  </div>
                </div>

                <div class="col-sm-6" style="padding-left: 0px;">
                    <label class="col-sm-6 control-label">
                      Chlorine Test (Right Bank):
                    </label>

                    <div class="col-sm-6" *ngIf="!f.AMDetailsEnteredOn.value">
                    <input maxlength="4"
                          class="form-control smInput"
                          formControlName="AMChlorineTestRightBank"
                          placeholder="#.##"
                          (blur)="FormatDecimal('AMChlorineTestRightBank',1,2)"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{
                            OurOffRange: f.AMChlorineTestRightBank.value >= 0.09
                          }"
                          />

                  <span
                    *ngIf="
                      f.AMChlorineTestRightBank.errors?.required &&
                      (f.AMChlorineTestRightBank.dirty ||
                        f.AMChlorineTestRightBank.touched ||
                        isValidFormSubmitted == false)"
                    [ngClass]="'error'">
                    Required!
                  </span>
                  <span *ngIf="f.AMChlorineTestRightBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.AMDetailsEnteredOn.value">
                      <label class="readOnlyText"  [ngClass]="{
                        invalidValueBackground: f.AMChlorineTestRightBank.value >= 0.09
                      }">{{f.AMChlorineTestRightBank.value | number: "1.2-2"}}</label>
                  </div>
                </div>
            </div>

            <div class="col-sm-11" style="margin-top: 15px;">
                 <notes #amNotes [showHours]='true'
                 (addNoteClick)="executeGRWPSatelliteCreateUpdate('Notes','AM')">
                </notes>
            </div>
            <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="!f.AMDetailsEnteredOn.value">
                <button type="button" class="row btn btn-primary"
                 (click)="executeGRWPSatelliteCreateUpdate('', 'AM')">
                <i class="fa fa-save"></i>&nbsp; Save</button>
            </div>



        </div>

        </div>
    </div>
</div>
  </div>
  <div class="panel panel-default"  [ngClass]="{ nonePointerEvents: ((f.CreatedDate.value.jsdate | date: 'dd/MM/yyyy') != (today | date:'dd/MM/yyyy')) && (f.MidDetailsEnteredBy.value == null) }" >
    <div class="panel-heading" role="tab" id="headingTwoMid" >
      <h4 class="panel-title">
        <a
        [ngClass]="collapseTwoMidClass"
        data-toggle="collapse"
        data-parent="#accordion"
        href="#collapseTwoMid"
        aria-expanded="collapseTwoMidariaexpanded"
        aria-controls="collapseTwoMid"
        id="midPanel"
        >
          <div class="box-header with-border">
            <h3 class="box-title container col-sm-9">
             Total Chlorine Testing Pre MID Shift
             <span *ngIf="f.MidDetailsEnteredOn.value">
                : {{ this.MIDUserName }} -
                {{ f.MidDetailsEnteredOn.value | date: "dd/MM/yyyy HH:mm" }} -
                Completed</span>
            </h3>
          </div>
        </a>
      </h4>
    </div>
    <div
    id="collapseTwoMid"
    [ngClass]="collapseTwoMidpanelClass"
    role="tabpanel"
    aria-labelledby="headingTwoMid">
    <div class="panel-body">
      <div class="box-body">
        <div class="row">

            <div class="box-row col-sm-12">
                <div class="col-sm-6" style="padding-left: 0px;">
                  <label class="col-sm-6 control-label">
                    Chlorine Test (Left Bank):
                  </label>

                  <div class="col-sm-6" *ngIf="!f.MidDetailsEnteredOn.value">
                    <input
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="MIDChlorineTestLeftBank"
                      placeholder="#.##"
                      (blur)="FormatDecimal('MIDChlorineTestLeftBank',1,2)"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{
                            OurOffRange: f.MIDChlorineTestLeftBank.value >= 0.09
                          }"
                    />

                    <span
                      *ngIf="
                        f.MIDChlorineTestLeftBank.errors?.required &&
                        (f.MIDChlorineTestLeftBank.dirty ||
                          f.MIDChlorineTestLeftBank.touched ||
                          isValidFormSubmitted == false)"
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                    <span *ngIf="f.MIDChlorineTestLeftBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.MidDetailsEnteredOn.value">
                      <label class="readOnlyText"  [ngClass]="{
                        invalidValueBackground: f.MIDChlorineTestLeftBank.value >= 0.09
                      }">{{f.MIDChlorineTestLeftBank.value | number: "1.2-2"}}</label>
                  </div>
                </div>

                <div class="col-sm-6" style="padding-left: 0px;">
                    <label class="col-sm-6 control-label">
                      Chlorine Test (Right Bank):
                    </label>

                    <div class="col-sm-6" *ngIf="!f.MidDetailsEnteredOn.value">
                    <input maxlength="4"
                          class="form-control smInput"
                          formControlName="MIDChlorineTestRightBank"
                          placeholder="#.##"
                          (blur)="FormatDecimal('MIDChlorineTestRightBank',1,2)"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{
                            OurOffRange: f.MIDChlorineTestRightBank.value >= 0.09
                          }"
                          />

                  <span
                    *ngIf="
                      f.MIDChlorineTestRightBank.errors?.required &&
                      (f.MIDChlorineTestRightBank.dirty ||
                        f.MIDChlorineTestRightBank.touched ||
                        isValidFormSubmitted == false)"
                    [ngClass]="'error'">
                    Required!
                  </span>
                  <span *ngIf="f.MIDChlorineTestRightBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.MidDetailsEnteredOn.value">
                      <label class="readOnlyText"  [ngClass]="{
                        invalidValueBackground: f.MIDChlorineTestRightBank.value >= 0.09
                      }">{{f.MIDChlorineTestRightBank.value | number: "1.2-2"}}</label>
                  </div>
                </div>

            </div>
            <div class="col-sm-11" style="margin-top: 15px;">
                <notes #midNotes [showHours]='true'
                (addNoteClick)="executeGRWPSatelliteCreateUpdate('Notes','MID')">
               </notes>
           </div>
           <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="!f.MidDetailsEnteredOn.value">
               <button type="button" class="row btn btn-primary"
                (click)="executeGRWPSatelliteCreateUpdate('', 'MID')">
               <i class="fa fa-save"></i>&nbsp; Save</button>
           </div>
        </div>
        </div>
    </div>
</div>
  </div>

  <div class="panel panel-default" [ngClass]="{ nonePointerEvents: ((f.CreatedDate.value.jsdate | date: 'dd/MM/yyyy') != (today | date:'dd/MM/yyyy')) && (f.PMDetailsEnteredBy.value == null) }">
    <div class="panel-heading" role="tab" id="headingTwoPM">
      <h4 class="panel-title">
        <a
        [ngClass]="collapseTwoPMClass"
        data-toggle="collapse"
        data-parent="#accordion"
        href="#collapseTwoPM"
        aria-expanded="collapseTwoPMariaexpanded"
        aria-controls="collapseTwoPM"
        id="pmPanel"
        >
          <div class="box-header with-border">
            <h3 class="box-title container col-sm-9">
             Total Chlorine Testing Pre PM Shift
             <span *ngIf="f.PMDetailsEnteredOn.value">
                : {{ this.PMUserName }} -
                {{ f.PMDetailsEnteredOn.value | date: "dd/MM/yyyy HH:mm" }} -
                Completed</span>
            </h3>
          </div>
        </a>
      </h4>
    </div>
    <div
    id="collapseTwoPM"
    [ngClass]="collapseTwoPMpanelClass"
    role="tabpanel"
    aria-labelledby="headingTwoPM">
    <div class="panel-body">
      <div class="box-body">
        <div class="row">

            <div class="box-row col-sm-12">
                <div class="col-sm-6" style="padding-left: 0px;">
                  <label class="col-sm-6 control-label">
                    Chlorine Test (Left Bank):
                  </label>

                  <div class="col-sm-6" *ngIf="!f.PMDetailsEnteredOn.value">
                    <input
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="PMChlorineTestLeftBank"
                      placeholder="#.##"
                      (blur)="FormatDecimal('PMChlorineTestLeftBank',1,2)"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{
                            OurOffRange: f.PMChlorineTestLeftBank.value >= 0.09
                          }"
                    />

                    <span
                      *ngIf="
                        f.PMChlorineTestLeftBank.errors?.required &&
                        (f.PMChlorineTestLeftBank.dirty ||
                          f.PMChlorineTestLeftBank.touched ||
                          isValidFormSubmitted == false)"
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                    <span *ngIf="f.PMChlorineTestLeftBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.PMDetailsEnteredOn.value">
                      <label class="readOnlyText"  [ngClass]="{
                        invalidValueBackground: f.PMChlorineTestLeftBank.value >= 0.09
                      }">{{f.PMChlorineTestLeftBank.value | number: "1.2-2"}}</label>
                  </div>

                </div>

                <div class="col-sm-6" style="padding-left: 0px;">
                    <label class="col-sm-6 control-label">
                      Chlorine Test (Right Bank):
                    </label>

                    <div class="col-sm-6" *ngIf="!f.PMDetailsEnteredOn.value">
                    <input maxlength="4"
                          class="form-control smInput"
                          formControlName="PMChlorineTestRightBank"
                          placeholder="#.##"
                          (blur)="FormatDecimal('PMChlorineTestRightBank',1,2)"
                          (keypress)="numberOnly($event)" [ngClass]="{
                            OurOffRange: f.PMChlorineTestRightBank.value >= 0.09
                          }"

                          />

                  <span
                    *ngIf="
                      f.PMChlorineTestRightBank.errors?.required &&
                      (f.PMChlorineTestRightBank.dirty ||
                        f.PMChlorineTestRightBank.touched ||
                        isValidFormSubmitted == false)"
                    [ngClass]="'error'">
                    Required!
                  </span>
                  <span *ngIf="f.PMChlorineTestRightBank.errors?.pattern"
                    [ngClass]="'error'">#.##</span>
                  </div>
                  <div class="col-sm-6" *ngIf="f.PMDetailsEnteredOn.value">
                      <label class="readOnlyText"  [ngClass]="{
                        invalidValueBackground: f.PMChlorineTestRightBank.value >= 0.09
                      }">{{f.PMChlorineTestRightBank.value | number: "1.2-2"}}</label>
                  </div>
                </div>

            </div>

            <div class="col-sm-11" style="margin-top: 15px;">
                <notes #pmNotes [showHours]="true"
                (addNoteClick)="executeGRWPSatelliteCreateUpdate('Notes','PM')">
               </notes>
           </div>
           <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="!f.PMDetailsEnteredOn.value">
               <button type="button" class="row btn btn-primary"
                (click)="executeGRWPSatelliteCreateUpdate('', 'PM')">
               <i class="fa fa-save"></i>&nbsp; Save</button>
           </div>
        </div>
        </div>
    </div>
</div>
  </div>
  <!--  -->

  <div class="panel panel-default"
  [ngClass]="{nonePointerEvents: (!(f.AMDetailsEnteredOn.value || f.PMDetailsEnteredOn.value || f.MidDetailsEnteredOn.value || f.DisinfectionDate.value)) || !IsWeeklyDisplayed }">
        <div class="panel-heading" role="tab" id="headingTwoWeekly">
          <h4 class="panel-title" (click)="setROVerifications(false)">

            <a
            [ngClass]="collapseTwoWeeklyClass"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseTwoWeekly"
            aria-expanded="collapseTwoWeeklyariaexpanded"
            aria-controls="collapseTwoWeekly"

            id="weeklyPanel"
            >
              <div class="box-header with-border">
               <div class="col-sm-12">
                <h3 class="box-title col-sm-6">
                    RO Weekly Testing
                    <span *ngIf="f.WeeklyDetailsEnteredOn.value && IsWeeklyDisplayed ">
                      : {{ this.WeeklyUserName }} -
                      {{ f.WeeklyDetailsEnteredOn.value | date: "dd/MM/yyyy HH:mm" }} -
                      {{ROVerificationStatus}}</span>
                  </h3>
                  <!-- <h3 class="box-title  col-sm-6">
                    RO Disinfection
                    <span *ngIf="f.WeeklyDetailsEnteredOn.value && IsWeeklyDisplayed && f.DisinfectSecondaryRO.value == 'Yes' ">
                      : {{ f.ChemicalDisinfectionUserSec.value }} -
                      {{ f.DisinfectionDateSec.value | date: "dd/MM/yyyy HH:mm" }} -
                      {{ f.DisinfectionDate.value &&  f.DisinfectionDateSec.value ? 'Completed' : 'Partially Completed'}}
                      </span>

                    <span *ngIf="f.WeeklyDetailsEnteredOn.value && IsWeeklyDisplayed && f.DisinfectSecondaryRO.value == 'No' ">
                      : {{ f.ChemicalDisinfectionUser.value }} -
                      {{ f.DisinfectionDate.value | date: "dd/MM/yyyy HH:mm" }} - {{ f.CWPRODisinfected.value}} -
                      {{ f.DisinfectionDate.value &&  f.DisinfectionDateSec.value ? 'Completed' : 'Partially Completed'}}
                      </span>

                  </h3> -->
                </div>
              </div>
            </a>
          </h4>
        </div>
        <div
        id="collapseTwoWeekly"
        [ngClass]="collapseTwoWeeklypanelClass"
        role="tabpanel"
        aria-labelledby="headingTwoWeekly"
      >
        <div class="panel-body">
          <div class="box-body">
            <div class="row">
                <div class="box-row col-sm-12 divider">
                    <div class="col-sm-2">
                        <strong>Softener Verification: </strong>
                    </div>
                    <div class="col-sm-8" style="color:black">
                        (GPG= 5 ml sample, 3 drops of H1, 1 drop of H2, grains # = H3 drops)
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <div class="box-row col-sm-12">
                    <div class="col-sm-4" style="padding-left: 0px;">
                      <label class="col-sm-8 control-label">
                        Hardness Pre-Soft (gpg):
                      </label>

                      <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                        <select
                                  [style.color]="
                                    f.HardnessPreSoft.value == '>8'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="HardnessPreSoft"
                                  name="HardnessPreSoft"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="<1" style="color:black"
                                    ><1</option
                                  >
                                  <option value="1" style="color:black">1</option>
                                  <option value="2" style="color:black">2</option>
                                  <option value="3" style="color:black">3</option>
                                  <option value="4" style="color:black">4</option>
                                  <option value="5" style="color:black">5</option>
                                  <option value="6" style="color:black">6</option>
                                  <option value="7" style="color:black">7</option>
                                  <option value=">8" style="color:red">>8</option>
                                </select>
                        <span
                          *ngIf="
                            f.HardnessPreSoft.errors?.required &&
                            (f.HardnessPreSoft.dirty ||
                              f.HardnessPreSoft.touched ||
                              isValidFormSubmitted == false)"
                              [ngClass]="'error'">
                          Required!
                        </span>
                      </div>
                      <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                          <label class="readOnlyText"
                          [ngClass]="{invalidValueBackground:f.HardnessPreSoft.value > 8}"
                            >{{f.HardnessPreSoft.value}}</label>
                      </div>
                    </div>

                    <div class="col-sm-4" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                          Hardness Pre RO - S11 (gpg):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <select
                          [style.color]="
                            f.HardnessPostSoftenerS2.value == '>1'
                              ? 'red'
                              : 'black'
                          "
                          class="form-control mdInput"
                          formControlName="HardnessPostSoftenerS2"
                          name="HardnessPostSoftenerS2"
                        >
                          <option value="" style="color:black"
                            >Select</option
                          >
                          <option value="<1" style="color:black"
                            ><1</option
                          >
                          <option value="1" style="color:black">1</option>
                        </select>
                          <span
                            *ngIf="
                              f.HardnessPostSoftenerS2.errors?.required &&
                              (f.HardnessPostSoftenerS2.dirty ||
                                f.HardnessPostSoftenerS2.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText" [ngClass]="{invalidValueBackground:f.HardnessPostSoftenerS2.value > 1}">
                              {{f.HardnessPostSoftenerS2.value}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-4" style="padding-left: 0px;">
                      <label class="col-sm-8 control-label">
                          Salt Level Verifications:</label
                      >
                      <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                        <select  [style.color]="f.SaltLevelVerifications.value == 'No' ? 'red' : 'black'"
                        [style.borderColor]="displayRemainingFields && !f.SaltLevelVerifications.value ? 'red' : ''"
                          class="form-control mdInput"
                          formControlName="SaltLevelVerifications">
                          <option value="" style="color:black">Select</option>
                          <option value="Yes" style="color:black">Yes</option>
                          <option value="No" style="color:red">No</option>
                        </select>
                        <span
                          *ngIf="
                            f.SaltLevelVerifications.errors?.required &&
                            (f.SaltLevelVerifications.dirty ||
                              f.SaltLevelVerifications.touched ||
                              isValidFormSubmitted == false)"
                          [ngClass]="'error'"
                        >
                          Required!
                        </span>
                      </div>
                      <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                          <label class="readOnlyText" [style.color]="f.SaltLevelVerifications.value == 'No' ? 'red' : 'black'">
                            {{f.SaltLevelVerifications.value}}</label>
                      </div>
                    </div>


                </div>

                <div class="box-row col-sm-12">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      Hardness Post Soft A (gpg):</label
                    >
                    <div
                      class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'"
                    >
                    <select
                    [style.color]="
                      f.HardnessPostSoftenerS3.value == '>8'
                        ? 'red'
                        : 'black'
                    "
                    class="form-control mdInput"
                    formControlName="HardnessPostSoftenerS3"
                    name="HardnessPostSoftenerS3"
                  >
                    <option value="" style="color:black"
                      >Select</option
                    >
                    <option value="<1" style="color:black"
                      ><1</option
                    >
                    <option value="1" style="color:black">1</option>
                    <option value="2" style="color:black">2</option>
                    <option value="3" style="color:black">3</option>
                    <option value="4" style="color:black">4</option>
                    <option value="5" style="color:black">5</option>
                    <option value="6" style="color:black">6</option>
                    <option value="7" style="color:black">7</option>
                    <option value="8" style="color:black">8</option>
                  </select>
                      <span
                        *ngIf="
                          f.HardnessPostSoftenerS3.errors?.required &&
                          (f.HardnessPostSoftenerS3.dirty ||
                            f.HardnessPostSoftenerS3.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                        <label class="readOnlyText" [ngClass]="{invalidValueBackground: checkRange(f.HardnessPostSoftenerS3, 0, 8) }">
                          {{f.HardnessPostSoftenerS3.value}}</label>
                    </div>
                </div>

                    <div class="col-sm-4" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                          Hardness Post Soft B (gpg):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <select
                          [style.color]="
                            f.PostSoftnerPressure.value == '>8'
                              ? 'red'
                              : 'black'
                          "
                          class="form-control mdInput"
                          formControlName="PostSoftnerPressure"
                          name="PostSoftnerPressure"
                        >
                          <option value="" style="color:black"
                            >Select</option
                          >
                          <option value="<1" style="color:black"
                            ><1</option
                          >
                          <option value="1" style="color:black">1</option>
                          <option value="2" style="color:black">2</option>
                          <option value="3" style="color:black">3</option>
                          <option value="4" style="color:black">4</option>
                          <option value="5" style="color:black">5</option>
                          <option value="6" style="color:black">6</option>
                          <option value="7" style="color:black">7</option>
                          <option value="8" style="color:black">8</option>
                        </select>
                          <span
                            *ngIf="
                              f.PostSoftnerPressure.errors?.required &&
                              (f.PostSoftnerPressure.dirty ||
                                f.PostSoftnerPressure.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="preGreaterPostPlusTen(f.PreSoftnerPressure.value,f.PostSoftnerPressure.value,false)" [ngClass]="'error'">
                              (>10psi between Pre/Post)
                          </span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText"
                            [ngClass]="{
                              invalidValueBackground: checkRange(f.PreSoftnerPressure, 0, 8) }"
                            >{{f.PostSoftnerPressure.value}}</label>
                        </div>
                    </div>



                </div>

                <!-- <div class="box-row col-sm-12">
                    <div class="col-sm-11" style="margin-top: 15px;">

                   </div>
                   <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="ROVerificationStatus != 'Completed'">
                       <button type="button" class="row btn btn-primary"
                        (click)="saveAndValidate('Softener', 'Weekly')">
                       <i class="fa fa-save"></i>&nbsp; Save</button>
                   </div>
                </div> -->

              <div>
                  <div class="clearfix"></div>
                <div class="box-row col-sm-12 divider" style="margin-top:10px;">
                        <div class="col-sm-2">
                            <strong>Pre-Treatment Verification: </strong>
                        </div>
                        <div class="col-sm-2"></div>
                </div>
                <div class="box-row col-sm-12">
                  <div class="col-sm-4">
                    <label class="col-sm-8 control-label">
                      Booster Pump (psi):
                    </label>
                    <div
                      class="col-sm-4"
                      *ngIf="ROVerificationStatus == 'Completed'"
                      [ngClass]="{invalidValueBackground:checkRange(f.BoosterPumpP4, 50, 90)}">
                      <label class="readOnlyText">{{ f.BoosterPumpP4.value }}</label>
                    </div>
                    <div
                      class="col-sm-4"
                      *ngIf="ROVerificationStatus != 'Completed'">
                      <input [style.borderColor]="displayRemainingFields && !f.BoosterPumpP4.value ? 'red' : ''"
                        maxlength="2"
                        class="form-control smInput"
                        formControlName="BoosterPumpP4"
                        (blur)="CheckIfZero('BoosterPumpP4')"
                        (keypress)="numberOnlyWithoutDot($event)"
                        [ngClass]="{
                          OurOffRange: checkRange(
                            f.BoosterPumpP4,
                            50,
                            90
                          )
                        }"
                        name="BoosterPumpP4"
                        placeholder="##"
                      />
                      <span
                        *ngIf="f.BoosterPumpP4.errors?.pattern"
                        [ngClass]="'error'"
                        >##</span
                      >
                      <span
                        *ngIf="
                          f.BoosterPumpP4.errors?.required &&
                          (f.BoosterPumpP4.dirty ||
                            f.BoosterPumpP4.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                    </div>
                  </div>


            </div>

            <div class="box-row col-sm-12">
              <div class="col-sm-4">
                <label class="col-sm-8 control-label">
                  Pre Carbon Filter (psi):
                </label>
                <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus == 'Completed'"
              >
                <label
                  class="readOnlyText"
                  [ngClass]="{
                    invalidValueBackground: checkRange(f.PreCarbonFilter, 50, 80) }"
                  >{{ f.PreCarbonFilter.value }}</label
                >
              </div>
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus != 'Completed'"
              >
                <input [style.borderColor]="displayRemainingFields && !f.PreCarbonFilter.value ? 'red' : ''"
                 [ngClass]="{OurOffRange: checkRange(f.PreCarbonFilter, 50, 80)}"
                  maxlength="2"
                  class="form-control smInput"
                  formControlName="PreCarbonFilter"
                  (blur)="CheckIfZero('PreCarbonFilter')"
                  (keypress)="numberOnlyWithoutDot($event)"
                  name="PreCarbonFilter"
                  placeholder="##"
                />
                <span
                  *ngIf="f.PreCarbonFilter.errors?.pattern"
                  [ngClass]="'error'"
                  >##</span
                >
                <span
                  *ngIf="
                    f.PreCarbonFilter.errors?.required &&
                    (f.PreCarbonFilter.dirty ||
                      f.PreCarbonFilter.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
              </div>

              <div class="col-sm-4" style="padding-left: 0px;">
                <label class="col-sm-8 control-label">
                      Pre RO Filter (psi):
                </label>

                <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                  <label class="readOnlyText"  [ngClass]="{
                    invalidValueBackground: checkRange(f.PreROFilter, 50, 80) }">
                    {{f.PreROFilter.value}}</label>
                </div>

                <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                  <input [style.borderColor]="displayRemainingFields && !f.PreROFilter.value ? 'red' : ''"
                      [ngClass]="{OurOffRange: checkRange(f.PreROFilter, 50, 80)}"
                      maxlength="3"
                      class="form-control smInput"
                      formControlName="PreROFilter"
                      placeholder="###"
                    />

                  <span
                    *ngIf="
                      f.PreROFilter.errors?.required &&
                      (f.PreROFilter.dirty ||
                        f.PreROFilter.touched ||
                        isValidFormSubmitted == false)
                    "
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                </div>

              </div>
            </div>

            <div class="box-row col-sm-12">
              <div class="col-sm-4">
                <label class="col-sm-8 control-label">
                  Pre Carbon Filter Delta (psi):
                </label>
                <div
                  class="col-sm-4"
                  *ngIf="ROVerificationStatus == 'Completed'">
                <label
                  class="readOnlyText"
                  [ngClass]="{invalidValueBackground:f.PreCarbonFilterDelta.value > 5}">
                  {{ f.PreCarbonFilterDelta.value }}
                </label>
              </div>
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus != 'Completed'">
                <input [style.borderColor]="displayRemainingFields && !f.PreCarbonFilterDelta.value ? 'red' : ''"
                  [ngClass]="{OurOffRange: f.PreCarbonFilterDelta.value > 5}"
                  maxlength="2"
                  class="form-control smInput"
                  formControlName="PreCarbonFilterDelta"
                  (blur)="ReplaceLeadingZero('PreCarbonFilterDelta')"
                  (keypress)="numberOnlyWithoutDot($event)"
                  name="PreCarbonFilterDelta"
                  placeholder="#.#"
                />
                <span
                  *ngIf="f.PreCarbonFilterDelta.errors?.pattern"
                  [ngClass]="'error'"
                  >#.#</span
                >
                <span
                  *ngIf="
                    f.PreCarbonFilterDelta.errors?.required &&
                    (f.PreCarbonFilterDelta.dirty ||
                      f.PreCarbonFilterDelta.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
              </div>
              <div class="col-sm-4">
                <label class="col-sm-8 control-label">
                  Pre RO Filter Delta:
                </label>
                <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus == 'Completed'"
              >
                <label
                  class="readOnlyText"
                  [ngClass]="{invalidValueBackground:f.PreROFilterDelta.value > 10}"
                  >{{ f.PreROFilterDelta.value }}</label
                >
              </div>
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus != 'Completed'"
              >
                <input  [style.borderColor]="displayRemainingFields && !f.PreROFilterDelta.value ? 'red' : ''"
                  [ngClass]="{OurOffRange: f.PreROFilterDelta.value > 10}"
                  maxlength="2"
                  class="form-control smInput"
                  formControlName="PreROFilterDelta"
                  (blur)="ReplaceLeadingZero('PreROFilterDelta')"
                  (keypress)="numberOnlyWithoutDot($event)"
                  name="PreROFilterDelta"
                  placeholder="#.#"
                />
                <span
                  *ngIf="f.PreROFilterDelta.errors?.pattern"
                  [ngClass]="'error'"
                  >#.#</span
                >
                <span
                  *ngIf="
                    f.PreROFilterDelta.errors?.required &&
                    (f.PreROFilterDelta.dirty ||
                      f.PreROFilterDelta.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
              </div>
            </div>

            <div class="box-row col-sm-12">
              <div class="col-sm-4">
                <label class="col-sm-8 control-label">pH Pre-Carbon:</label>
                <div
                  class="col-sm-4"
                  *ngIf="ROVerificationStatus == 'Completed'">
                  <label  *ngIf="f.pHPreCarbon.value != 'N/A'"
                    class="readOnlyText"
                    [ngClass]="{
                      invalidValueBackground: checkRange(f.pHPreCarbon, 7, 8) }"
                    >{{ f.pHPreCarbon.value | number: "1.1-1" }}</label>
                  
                  <label class="readOnlyText" *ngIf="f.pHPreCarbon.value == 'N/A'">{{f.pHPreCarbon.value}}</label>
                  
                </div>
                <div
                  class="col-sm-4"
                  *ngIf="ROVerificationStatus != 'Completed'">
                  <input [style.borderColor]="displayRemainingFields && !f.pHPreCarbon.value ? 'red' : ''"
                    (keypress)="numberOnly($event)"
                    [ngClass]="{OurOffRange: checkRange(f.pHPreCarbon, 7, 8)}"
                    maxlength="3"
                    class="form-control smInput"
                    formControlName="pHPreCarbon"
                    (blur)="ReplaceLeadingZero('pHPreCarbon')"
                    name="pHPreCarbon"
                    placeholder="#.#"
                  />

                  <span *ngIf=" f.pHPreCarbon.errors?.required && (f.pHPreCarbon.dirty || f.pHPreCarbon.touched || isValidFormSubmitted == false)"
                    [ngClass]="'error'" >Required!</span>
                    <span *ngIf="f.pHPreCarbon.errors?.pattern"
                    [ngClass]="'error'">#.#</span>
                </div>
              </div>


              <div class="col-sm-4">
                <label class="col-sm-8 control-label">
                  pH Post-Carbon:
                </label>

                <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus == 'Completed'"
              >
              <label *ngIf="f.pHPostCarbon.value != 'N/A'"
              class="readOnlyText"
              [ngClass]="{
                invalidValueBackground: checkRange(f.pHPostCarbon, 7, 8) }"
              >{{ f.pHPostCarbon.value | number: "1.1-1" }}</label>

              <label class="readOnlyText" *ngIf="f.pHPostCarbon.value == 'N/A'">{{f.pHPostCarbon.value}}</label>

              </div>
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus != 'Completed'">
                <input [style.borderColor]="displayRemainingFields && !f.pHPostCarbon.value ? 'red' : ''"
                  [ngClass]="{OurOffRange: checkRange(f.pHPostCarbon, 7, 8)}"
                  (keypress)="numberOnly($event)"
                  maxlength="3"
                  class="form-control smInput"
                  formControlName="pHPostCarbon"
                  (blur)="ReplaceLeadingZero('pHPostCarbon')"
                  name="pHPostCarbon"
                  placeholder="#.#"
                />

                <span
                  *ngIf="
                    f.pHPostCarbon.errors?.required &&
                    (f.pHPostCarbon.dirty ||
                      f.pHPostCarbon.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
                <span
                  *ngIf="f.pHPostCarbon.errors?.pattern"
                  [ngClass]="'error'"
                  >#.#</span
                >
              </div>
              </div>
            </div>

            <div class="box-row col-sm-12">
              <div class="col-sm-4">
                <label class="col-sm-8 control-label">
                  Iron Post Softener:</label>
              <div
              class="col-sm-3"
              *ngIf="ROVerificationStatus == 'Completed'"
            >
              <label class="readOnlyText" *ngIf="f.IronPostSoftener.value == 1.00">Yes</label>
              <label class="readOnlyText">NO</label>

            </div>
            <div
              class="col-sm-4"
              *ngIf="ROVerificationStatus != 'Completed'"
            >
              <select [style.borderColor]="displayRORemainingFields && !f.IronPostSoftener.value ? 'red' : ''"
              [style.color]="
                f.IronPostSoftener.value == 'NO'
                  ? 'red'
                  : 'black'
              "
              class="form-control mdInput"
              formControlName="IronPostSoftener"
              name="IronPostSoftener"
            >
              <option value="" style="color:black"
                >Select</option
              >
              <option value="Yes" style="color:black"
                >Yes</option
              >
              <option value="NO" style="color:red">NO</option>
            </select>
            <span
              *ngIf="
                f.IronPostSoftener.errors
                  ?.required &&
                (f.IronPostSoftener.dirty ||
                  f.IronPostSoftener.touched ||
                  isValidFormSubmitted == false)
              "
              [ngClass]="'error'"
            >
              Required!
            </span>
            </div>
            </div>

            <div class="col-sm-4">
              <label class="col-sm-8 control-label">
                Verify Testing Chemical Levels:</label
              >
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus == 'Completed'"
              >
                <label
                  class="readOnlyText"
                  [style.color]="
                    f.VerifyTestingChemicalLevels.value == 'NO'
                      ? 'red'
                      : 'black'
                  "
                  >{{
                    f.VerifyTestingChemicalLevels.value
                  }}</label
                >
              </div>
              <div
                class="col-sm-4"
                *ngIf="ROVerificationStatus != 'Completed'"
              >
                <select   [style.borderColor]="displayRORemainingFields && !f.VerifyTestingChemicalLevels.value ? 'red' : ''"
                  [style.color]="
                    f.VerifyTestingChemicalLevels.value == 'NO'
                      ? 'red'
                      : 'black'
                  "
                  class="form-control mdInput"
                  formControlName="VerifyTestingChemicalLevels"
                  name="VerifyTestingChemicalLevels"
                >
                  <option value="" style="color:black"
                    >Select</option
                  >
                  <option value="Yes" style="color:black"
                    >Yes</option
                  >
                  <option value="NO" style="color:red">NO</option>
                </select>
                <span
                  *ngIf="
                    f.VerifyTestingChemicalLevels.errors
                      ?.required &&
                    (f.VerifyTestingChemicalLevels.dirty ||
                      f.VerifyTestingChemicalLevels.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
            </div>

            </div>


            <div class="box-row col-sm-12 divider" style="margin-top:10px;">
              <div class="col-sm-2">
                  <strong>CWP Verification: </strong>
              </div>
              <div class="col-sm-2"></div>
            </div>

            <div class="box-row col-sm-12">
            <div class="col-sm-4" style="padding-left: 0px;">
              <label class="col-sm-8 control-label">
                      Verify Dialox Levels:
              </label>

              <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                <select [style.borderColor]="displayRORemainingFields && !f.DialoxLevels.value ? 'red' : ''"
                  class="form-control mdInput" [style.color]="f.DialoxLevels.value == 'No' ? 'red' : ''"
                  formControlName="DialoxLevels">
                  <option value="" style="color:black">Select</option>
                  <option value="Yes" style="color:black">Yes</option>
                  <option value="No" style="color:red">No</option>
                </select>
                <span
                  *ngIf="
                    f.DialoxLevels.errors?.required &&
                    (f.DialoxLevels.dirty ||
                      f.DialoxLevels.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
              <div class="col-sm-3" *ngIf="ROVerificationStatus == 'Completed'">
                  <label class="readOnlyText" [style.color]="f.DialoxLevels.value == 'No' ? 'red' : ''">{{f.DialoxLevels.value}}</label>
              </div>
            </div>

            <div class="col-sm-4" style="padding-left: 0px;">
              <label class="col-sm-8 control-label">
                      RO-A Alarm List Cleared?
              </label>

              <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                <select [style.borderColor]="displayRORemainingFields && !f.ROAAlarmHistory.value ? 'red' : ''"
                  class="form-control mdInput" [style.color]="f.ROAAlarmHistory.value == 'No' ? 'red' : 'black'"
                  formControlName="ROAAlarmHistory">
                  <option value="" style="color:black">Select</option>
                  <option value="Yes" style="color:black">Yes</option>
                  <option value="No" style="color:red">No</option>
                </select>
                <span
                  *ngIf="
                    f.ROAAlarmHistory.errors?.required &&
                    (f.ROAAlarmHistory.dirty ||
                      f.ROAAlarmHistory.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
              </div>
              <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                  <label class="readOnlyText" [style.color]="f.ROAAlarmHistory.value == 'No' ? 'red' : 'black'">
                    {{f.ROAAlarmHistory.value}}</label>
              </div>
            </div>

           <div class="col-sm-4" style="padding-left: 0px;">
                  <label class="col-sm-8 control-label">
                      RO-B Alarm List Cleared?
                  </label>

                  <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                    <select [style.borderColor]="displayRORemainingFields && !f.ROBAlarmHistory.value ? 'red' : ''"
                      class="form-control mdInput" [style.color]="f.ROBAlarmHistory.value == 'No' ? 'red' : 'black'"
                      formControlName="ROBAlarmHistory">
                      <option value="" style="color:black">Select</option>
                      <option value="Yes" style="color:black">Yes</option>
                      <option value="No" style="color:red">No</option>
                    </select>
                    <span
                      *ngIf="
                        f.ROBAlarmHistory.errors?.required &&
                        (f.ROBAlarmHistory.dirty ||
                          f.ROBAlarmHistory.touched ||
                          isValidFormSubmitted == false)
                      "
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                  </div>
                  <div class="col-sm-3" *ngIf="ROVerificationStatus == 'Completed'">
                      <label class="readOnlyText" [style.color]="f.ROBAlarmHistory.value == 'No' ? 'red' : 'black'">{{f.ROBAlarmHistory.value}}</label>
                  </div>
           </div>
            </div>

            <div class="box-row col-sm-12 divider" style="margin-top:10px;">
              <div class="col-sm-2">
                  <strong>RO Verification:</strong>
              </div>
              <div class="col-sm-2"></div>
            </div>

            <div class="box-row col-sm-12" *ngIf="f.ROPresentlyInUse.value == 'RO-A'">
                    <div class="col-sm-5" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                                RO-A Feed Conductivity (uS):</label
                        >
                        <div *ngIf="ROVerificationStatus != 'Completed'"
                            class="col-sm-4">
                        <input [style.borderColor]="displayRORemainingFields && !f.ROAFeedConductivity.value ? 'red' : ''"
                            (blur)="CheckIfZero('ROAFeedConductivity')"
                            (keypress)="numberOnlyWithoutDot($event)"
                            maxlength="3"
                            class="form-control smInput"
                            formControlName="ROAFeedConductivity"
                            placeholder="####.#"
                        />
                            <span
                            *ngIf="
                                f.ROAFeedConductivity.errors?.required &&
                                (f.ROAFeedConductivity.dirty ||
                                f.ROAFeedConductivity.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                            >
                            Required!
                            </span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText">{{f.ROAFeedConductivity.value}}</label>
                        </div>
                    </div>
                    <div class="col-sm-5" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                              RO-A Product Conductivity (uS/cm):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">

                          <input
                          [style.borderColor]="displayRORemainingFields && !f.ROAProductConductivity.value ? 'red' : ''"
                              (keypress)="numberOnly($event)"
                              maxlength="7"
                              (blur)="FormatDecimal('ROAProductConductivity',3,3)"
                              class="form-control smInput"
                              formControlName="ROAProductConductivity"
                              placeholder="###.###"
                            />

                          <span
                            *ngIf="
                              f.ROAProductConductivity.errors?.required &&
                              (f.ROAProductConductivity.dirty ||
                                f.ROAProductConductivity.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.ROAProductConductivity.errors?.pattern"
                            [ngClass]="'error'">###.###</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText">{{f.ROAProductConductivity.value | number: "1.3-3"}}</label>
                        </div>
                      </div>

                </div>

                <div class="box-row col-sm-12" *ngIf="f.ROPresentlyInUse.value == 'RO-A'">

                    <div class="col-sm-5" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                                RO-A Recovery Rate (%):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                        <input  [style.borderColor]="displayRORemainingFields && !f.ROARecoveryRate.value ? 'red' : ''"
                            (keypress)="numberOnly($event)"
                            (blur)="FormatDecimal('ROARecoveryRate',2,1)"
                            maxlength="4"
                            class="form-control smInput"
                            formControlName="ROARecoveryRate"
                            placeholder="##.#"
                        />
                          <span
                            *ngIf="
                              f.ROARecoveryRate.errors?.required &&
                              (f.ROARecoveryRate.dirty ||
                                f.ROARecoveryRate.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.ROARecoveryRate.errors?.pattern"
                          [ngClass]="'error'">##.#</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText">{{f.ROARecoveryRate.value  | number: "1.1-1"}}</label>
                        </div>
                    </div>
                    <div class="col-sm-5" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                              RO-A Rejection Rate (%):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <input
                          [style.borderColor]="displayRORemainingFields && !f.ROARejectionRate.value ? 'red' : ''"
                              (blur)="FormatDecimal('ROARejectionRate',2,1)"
                              (keypress)="numberOnly($event)"
                              maxlength="4"
                              class="form-control smInput"
                              formControlName="ROARejectionRate"
                              placeholder="##.#"
                            />

                          <span
                            *ngIf="
                              f.ROARejectionRate.errors?.required &&
                              (f.ROARejectionRate.dirty ||
                                f.ROARejectionRate.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.ROARejectionRate.errors?.pattern"
                          [ngClass]="'error'">##.#</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText">{{f.ROARejectionRate.value  | number: "1.1-1"}}</label>
                        </div>
                      </div>
                </div>

                <div class="box-row col-sm-12" *ngIf="f.ROPresentlyInUse.value == 'RO-B'">

                        <div class="col-sm-5" style="padding-left: 0px;">
                            <label class="col-sm-8 control-label">
                                   RO-B Feed Conductivity (uS):</label>
                            <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                            <input [style.borderColor]="displayRORemainingFields && !f.ROBFeedConductivity.value ? 'red' : ''"
                                (blur)="CheckIfZero('ROBFeedConductivity')"
                                (keypress)="numberOnlyWithoutDot($event)"
                                maxlength="3"
                                class="form-control smInput"
                                formControlName="ROBFeedConductivity"
                                placeholder="###"
                            />
                            <span
                                *ngIf="
                                    f.ROBFeedConductivity.errors?.required &&
                                    (f.ROBFeedConductivity.dirty ||
                                    f.ROBFeedConductivity.touched ||
                                    isValidFormSubmitted == false)"
                                [ngClass]="'error'">
                                Required!
                                </span>
                            </div>
                            <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                                <label class="readOnlyText">{{f.ROBFeedConductivity.value}}</label>
                            </div>
                    </div>
                    <div class="col-sm-5" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                                RO-B Product Conductivity (uS):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                        <input  [style.borderColor]="displayRORemainingFields && !f.ROBProductConductivity.value ? 'red' : ''"
                            (keypress)="numberOnly($event)"
                            maxlength="7"
                            (blur)="FormatDecimal('ROBProductConductivity',3,3)"
                            class="form-control smInput"
                            formControlName="ROBProductConductivity"
                            placeholder="###.###"
                        />
                          <span
                            *ngIf="
                              f.ROBProductConductivity.errors?.required &&
                              (f.ROBProductConductivity.dirty ||
                                f.ROBProductConductivity.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.ROBProductConductivity.errors?.pattern"
                          [ngClass]="'error'">###.###</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                            <label class="readOnlyText">{{f.ROBProductConductivity.value  | number: "1.3-3"}}</label>
                        </div>
                    </div>



                </div>

                <div class="box-row col-sm-12" *ngIf="f.ROPresentlyInUse.value == 'RO-B'">
                      <div class="col-sm-5" style="padding-left: 0px;">
                          <label class="col-sm-8 control-label">
                                  RO-B Recovery Rate (%):
                          </label>

                          <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <input [style.borderColor]="displayRORemainingFields && !f.ROBRecoveryRate.value ? 'red' : ''"
                            (blur)="FormatDecimal('ROBRecoveryRate',2,1)"
                              (keypress)="numberOnly($event)"
                              maxlength="4"
                              class="form-control smInput"
                              formControlName="ROBRecoveryRate"
                              placeholder="##.#"
                          />
                            <span
                              *ngIf="
                                f.ROBRecoveryRate.errors?.required &&
                                (f.ROBRecoveryRate.dirty ||
                                  f.ROBRecoveryRate.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >
                              Required!
                            </span>
                            <span *ngIf="f.ROBRecoveryRate.errors?.pattern"
                              [ngClass]="'error'">##.#</span>
                          </div>
                          <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                              <label class="readOnlyText">{{f.ROBRecoveryRate.value  | number: "1.1-1"}}</label>
                          </div>
                      </div>
                      <div class="col-sm-5" style="padding-left: 0px;">
                          <label class="col-sm-8 control-label">
                                RO-B Rejection Rate (%):
                          </label>

                          <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                            <input
                            [style.borderColor]="displayRORemainingFields && !f.ROBRejectionRate.value ? 'red' : ''"
                            (blur)="FormatDecimal('ROBRejectionRate',2,1)"
                                (keypress)="numberOnly($event)"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="ROBRejectionRate"
                                placeholder="##.#"
                              />

                            <span
                              *ngIf="
                                f.ROBRejectionRate.errors?.required &&
                                (f.ROBRejectionRate.dirty ||
                                  f.ROBRejectionRate.touched ||
                                  isValidFormSubmitted == false)
                              "
                              [ngClass]="'error'"
                            >

                              Required!
                            </span>
                            <span *ngIf="f.ROBRejectionRate.errors?.pattern"
                            [ngClass]="'error'">##.#</span>
                          </div>
                          <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                              <label class="readOnlyText">{{f.ROBRejectionRate.value | number: "1.1-1"}}</label>
                          </div>
                        </div>
                </div>

                <div class="box-row col-sm-12">
                <div class="col-sm-4" style="padding-left: 0px;">
                  <label class="col-sm-8 control-label">
                    Verify RO Presently In Use:
                  </label>

                  <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                    <select   [style.borderColor]="displayRORemainingFields && !f.selectedVerifyROPresently.value ? 'red' : ''"
                          class="form-control"
                          formControlName="selectedVerifyROPresently"
                          name="selectedVerifyROPresently"
                          (change)="VerifyROPresentlyDropDownChanged()"
                        >
                          <option
                            value="{{ item.value }}"
                            *ngFor="let item of VerifyROPresentMAIN3E"
                            >{{ item.text }}</option
                          >
                        </select>
                    <span
                      *ngIf="
                        f.selectedVerifyROPresently.errors?.required &&
                        (f.selectedVerifyROPresently.dirty ||
                          f.selectedVerifyROPresently.touched ||
                          isValidFormSubmitted == false)
                      "
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                  </div>
                  <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                    <label class="readOnlyText" style="color:black">{{f.selectedVerifyROPresently.value =='A'? 'RO-A':'RO-B'}}</label>
                  </div>
                </div>

                <div class="col-sm-4" style="padding-left: 0px;">
                  <label class="col-sm-8 control-label" *ngIf="f.selectedVerifyROPresently.value != ''">
                    {{f.selectedVerifyROPresently.value =='A'? 'RO-A':'RO-B'}} Hours:
                  </label>
                  <label class="col-sm-8 control-label" *ngIf="f.selectedVerifyROPresently.value == ''">
                    RO Hours:
                  </label>

                  <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">

                    <input  (keypress)="numberOnly($event)"
                        maxlength="7"
                        class="form-control smInput"
                        formControlName="ROHours"
                        name="ROHours"
                        placeholder="#######"/>

                    <span
                      *ngIf="
                        f.ROHours.errors?.required &&
                        (f.ROHours.dirty ||
                          f.ROHours.touched ||
                          isValidFormSubmitted == false)
                      "
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                    <span *ngIf="f.ROHours.errors?.pattern"
                      [ngClass]="'error'">#######</span>
                    <span  *ngIf="f.ROHours.errors?.pattern" [ngClass] = "'error'">
                        Invalid RO Hours
                    </span>
                  </div>
                  <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                      <label class="readOnlyText">{{f.ROHours.value}}</label>
                  </div>
                </div>

                <div class="col-sm-4" style="padding-left: 0px;">
                  <label class="col-sm-8 control-label" *ngIf="f.selectedVerifyROPresently.value != ''">
                    {{f.selectedVerifyROPresently.value =='A'? 'RO-B':'RO-A'}} Disinfected:
                  </label>
                  <label class="col-sm-8 control-label" *ngIf="f.selectedVerifyROPresently.value == ''">
                    RO Disinfected:
                  </label>

                  <div
                  class="col-sm-3"
                  *ngIf="ROVerificationStatus == 'Completed'"
                >
                  <!-- <label class="readOnlyText" *ngIf="f.RODisinfected.value == 1.00">Yes</label>
                  <label class="readOnlyText">No</label>
                   -->
                  <label class="readOnlyText">{{f.RODisinfected.value}}</label>

                </div>
                <div
                  class="col-sm-4"
                  *ngIf="ROVerificationStatus != 'Completed'"
                >
                  <select [style.borderColor]="displayRORemainingFields && !f.RODisinfected.value ? 'red' : ''"
                  [style.color]="
                    f.RODisinfected.value == 'No'
                      ? 'red'
                      : 'black'
                  "
                  class="form-control mdInput"
                  formControlName="RODisinfected"
                  name="RODisinfected">
                  <option value="" style="color:black">Select</option>
                  <option value="Yes" style="color:black">Yes</option>
                  <option value="NO" style="color:red">No</option>
                </select>
                <span
                  *ngIf="
                    f.RODisinfected.errors
                      ?.required &&
                    (f.RODisinfected.dirty ||
                      f.RODisinfected.touched ||
                      isValidFormSubmitted == false)
                  "
                  [ngClass]="'error'"
                >
                  Required!
                </span>
                </div>
                </div>
                </div>

                <div class="box-row col-sm-12">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <div class="col-sm-8">
                      <label class="col-sm-6 control-label">
                        Verify:
                      </label>
                      <label
                      class="col-sm-6 readOnlyText"
                      style="color:black"
                      *ngIf="ROVerificationStatus != 'Completed'">{{ VerifyROlable }}</label>
                    </div>
                    <div
                    class="col-sm-4"
                    *ngIf="ROVerificationStatus != 'Completed'">
                    <select  [style.borderColor]="displayRORemainingFields && !f.VerifyROReverse.value ? 'red' : ''"
                      [style.color]="
                        f.VerifyROReverse.value == 'NO'
                          ? 'red'
                          : 'black'
                      "
                      class="form-control mdInput"
                      formControlName="VerifyROReverse"
                      name="VerifyROReverse"
                    >
                      <option value="" style="color:black"
                        >Select</option
                      >
                      <option value="Yes" style="color:black"
                        >Yes</option
                      >
                      <option value="NO" style="color:red">NO</option>
                    </select>
                    <span
                    *ngIf="
                      f.VerifyROReverse.errors?.required &&
                      (f.VerifyROReverse.dirty ||
                        f.VerifyROReverse.touched ||
                        isValidFormSubmitted == false)"
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                  </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                      <label class="readOnlyText" style="color:black">{{f.VerifyROReverse.value}}</label>
                    </div>
                  </div>
                  </div>

                <div class="box-row col-sm-12" *ngIf="f.selectedVerifyROPresently.value ==  'A' || f.selectedVerifyROPresently.value ==  'RO-A'">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                            RO-A Feed Cond. (uS/cm):</label>
                    <div *ngIf="ROVerificationStatus != 'Completed'"
                        class="col-sm-4">
                        <input  [style.borderColor]="displayRORemainingFields && !f.ROAFeedConductivity.value ? 'red' : ''"
                                maxlength="6"
                                class="form-control smInput"
                                (keypress)="numberOnly($event)"
                                (blur)="ReplaceLeadingZero('ROAFeedConductivity')"
                                formControlName="ROAFeedConductivity"
                                [ngClass]="{ OurOffRange: f.ROAFeedConductivity.value > 2000}"
                                name="ROAFeedConductivity"
                                placeholder="####"/>
                        <span
                        *ngIf="
                            f.ROAFeedConductivity.errors?.required &&
                            (f.ROAFeedConductivity.dirty ||
                            f.ROAFeedConductivity.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                        >
                        Required!
                        </span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                          [ngClass]="{invalidValueBackground:f.ROAFeedConductivity.value > 2000}">
                        <label class="readOnlyText">{{f.ROAFeedConductivity.value}}</label>
                    </div>
                  </div>
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-A Product Cond. (uS/cm):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">

                      <input [style.borderColor]="displayRORemainingFields && !f.ROAProductConductivity.value ? 'red' : ''"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{OurOffRange: f.ROAProductConductivity.value > 30}"
                          max-length="4"
                          class="form-control smInput"
                          (blur)="ReplaceLeadingZero('ROAProductConductivity')"
                          formControlName="ROAProductConductivity"
                          name="ROAProductConductivity"
                          placeholder="##.#"/>

                      <span
                        *ngIf="
                          f.ROAProductConductivity.errors?.required &&
                          (f.ROAProductConductivity.dirty ||
                            f.ROAProductConductivity.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROAProductConductivity.errors?.pattern"
                        [ngClass]="'error'">###.###</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                        [ngClass]="{invalidValueBackground:f.ROAProductConductivity.value > 30}">
                        <label class="readOnlyText">{{f.ROAProductConductivity.value | number: "1.3-3"}}</label>
                    </div>
                  </div>

                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-A Rejection Rate (%):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                      <input [style.borderColor]="displayRORemainingFields && !f.ROARejectionRate.value ? 'red' : ''"
                      (keypress)="numberOnly($event)"
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="ROARejectionRate"
                      (blur)="ReplaceLeadingZero('ROARejectionRate', 2)"
                      name="ROARejectionRate"
                      [ngClass]="{
                        OurOffRange: f.ROARejectionRate.value && f.ROARejectionRate.value < 97
                      }"
                      placeholder="##.#"
                    />

                      <span
                        *ngIf="
                          f.ROARejectionRate.errors?.required &&
                          (f.ROARejectionRate.dirty ||
                            f.ROARejectionRate.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROARejectionRate.errors?.pattern"
                      [ngClass]="'error'">##.#</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                          [ngClass]="{invalidValueBackground:f.ROARejectionRate.value < 97}">
                        <label class="readOnlyText">{{f.ROARejectionRate.value  | number: "1.1-1"}}</label>
                    </div>
                  </div>
                </div>

                <div class="box-row col-sm-12" *ngIf="f.selectedVerifyROPresently.value ==  'A' || f.selectedVerifyROPresently.value ==  'RO-A' ">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-A Recovery Rate (%):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                    <input  [style.borderColor]="displayRORemainingFields && !f.ROARecoveryRate.value ? 'red' : ''"
                      (blur)="ReplaceLeadingZero('ROARecoveryRate', 2)"
                      (keypress)="numberOnly($event)"
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="ROARecoveryRate"
                      name="ROARecoveryRate"
                      placeholder="##.#"
                    />
                      <span
                        *ngIf="
                          f.ROARecoveryRate.errors?.required &&
                          (f.ROARecoveryRate.dirty ||
                            f.ROARecoveryRate.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROARecoveryRate.errors?.pattern"
                      [ngClass]="'error'">##.#</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                        <label class="readOnlyText">{{f.ROARecoveryRate.value  | number: "1.1-1"}}</label>
                    </div>
                </div>
                    <div class="col-sm-4" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                          RO-A Temp Return (°C):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <input [style.borderColor]="displayRORemainingFields && !f.LoopReturnTemp.value ? 'red' : ''"
                                (blur)="ReplaceLeadingZero('LoopReturnTemp')"
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: checkRange(f.LoopReturnTemp, 18, 25) }"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="LoopReturnTemp"
                                name="LoopReturnTemp"
                                placeholder="##.#"
                              />
                          <span
                            *ngIf="
                              f.LoopReturnTemp.errors?.required &&
                              (f.LoopReturnTemp.dirty ||
                                f.LoopReturnTemp.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.LoopReturnTemp.errors?.pattern"
                          [ngClass]="'error'">##.#</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                            [ngClass]="{invalidValueBackground:checkRange(f.LoopReturnTemp, 18, 25)}">
                            <label class="readOnlyText">{{f.LoopReturnTemp.value | number: "1.1-1"}}</label>
                        </div>
                    </div>

                    <div class="col-sm-4"  style="padding-left: 0px;">
                      <label class="col-sm-8 control-label">
                        RO-A Temp Tank (°C):</label>
                      <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                          <label class="readOnlyText">{{f.TempTank.value}} </label>
                      </div>
                      <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                              <input name="TempTank"
                              [style.borderColor]="displayRORemainingFields && !f.TempTank.value ? 'red' : ''"
                              maxlength="10" class="form-control smInput" formControlName="TempTank"
                              placeholder="##.#"
                              name="TempTank">
                            <span
                            *ngIf="
                                f.TempTank.errors?.required &&
                                (f.TempTank.dirty ||
                                f.TempTank.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                            >
                            Required!
                            </span>
                      </div>
                  </div>

                </div>

                <div class="box-row col-sm-12" *ngIf="f.selectedVerifyROPresently.value ==  'B' || f.selectedVerifyROPresently.value ==  'RO-B'">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                            RO-B Feed Cond. (uS/cm):</label>
                    <div *ngIf="ROVerificationStatus != 'Completed'"
                        class="col-sm-4">
                        <input  [style.borderColor]="displayRORemainingFields && !f.ROBFeedConductivity.value ? 'red' : ''"
                                maxlength="6"
                                class="form-control smInput"
                                (keypress)="numberOnly($event)"
                                (blur)="ReplaceLeadingZero('ROBFeedConductivity')"
                                formControlName="ROBFeedConductivity"
                                [ngClass]="{ OurOffRange: f.ROBFeedConductivity.value > 2000}"
                                name="ROBFeedConductivity"
                                placeholder="####"/>
                        <span
                        *ngIf="
                            f.ROBFeedConductivity.errors?.required &&
                            (f.ROBFeedConductivity.dirty ||
                            f.ROBFeedConductivity.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                        >
                        Required!
                        </span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                        [ngClass]="{invalidValueBackground:f.ROBFeedConductivity.value > 2000}">
                        <label class="readOnlyText">{{f.ROBFeedConductivity.value}}</label>
                    </div>
                  </div>
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-B Product Cond. (uS/cm):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">

                      <input [style.borderColor]="displayRORemainingFields && !f.ROBProductConductivity.value ? 'red' : ''"
                          (keypress)="numberOnly($event)"
                          [ngClass]="{OurOffRange: f.ROBProductConductivity.value > 30}"
                          max-length="4"
                          class="form-control smInput"
                          (blur)="ReplaceLeadingZero('ROBProductConductivity')"
                          formControlName="ROBProductConductivity"
                          name="ROBProductConductivity"
                          placeholder="##.#"/>

                      <span
                        *ngIf="
                          f.ROBProductConductivity.errors?.required &&
                          (f.ROBProductConductivity.dirty ||
                            f.ROBProductConductivity.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROBProductConductivity.errors?.pattern"
                        [ngClass]="'error'">###.###</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                        [ngClass]="{invalidValueBackground:f.ROBProductConductivity.value > 30}">
                        <label class="readOnlyText">{{f.ROBProductConductivity.value | number: "1.3-3"}}</label>
                    </div>
                  </div>

                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-B Rejection Rate (%):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                      <input [style.borderColor]="displayRORemainingFields && !f.ROBRejectionRate.value ? 'red' : ''"
                      (keypress)="numberOnly($event)"
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="ROBRejectionRate"
                      (blur)="ReplaceLeadingZero('ROBRejectionRate', 2)"
                      name="ROBRejectionRate"
                      [ngClass]="{
                        OurOffRange: f.ROBRejectionRate.value && f.ROBRejectionRate.value < 97
                      }"
                      placeholder="##.#"
                    />

                      <span
                        *ngIf="
                          f.ROBRejectionRate.errors?.required &&
                          (f.ROBRejectionRate.dirty ||
                            f.ROBRejectionRate.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROBRejectionRate.errors?.pattern"
                      [ngClass]="'error'">##.#</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                        [ngClass]="{invalidValueBackground:f.ROBRejectionRate.value < 97}">
                        <label class="readOnlyText">{{f.ROBRejectionRate.value  | number: "1.1-1"}}</label>
                    </div>
                  </div>
                </div>

                <div class="box-row col-sm-12" *ngIf="f.selectedVerifyROPresently.value ==  'B' || f.selectedVerifyROPresently.value ==  'RO-B'">
                  <div class="col-sm-4" style="padding-left: 0px;">
                    <label class="col-sm-8 control-label">
                      RO-B Recovery Rate (%):
                    </label>

                    <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                    <input  [style.borderColor]="displayRORemainingFields && !f.ROBRecoveryRate.value ? 'red' : ''"
                      (blur)="ReplaceLeadingZero('ROBRecoveryRate', 2)"
                      (keypress)="numberOnly($event)"
                      maxlength="4"
                      class="form-control smInput"
                      formControlName="ROBRecoveryRate"
                      name="ROBRecoveryRate"
                      placeholder="##.#"
                    />
                      <span
                        *ngIf="
                          f.ROBRecoveryRate.errors?.required &&
                          (f.ROBRecoveryRate.dirty ||
                            f.ROBRecoveryRate.touched ||
                            isValidFormSubmitted == false)
                        "
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      <span *ngIf="f.ROBRecoveryRate.errors?.pattern"
                      [ngClass]="'error'">##.#</span>
                    </div>
                    <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'">
                        <label class="readOnlyText">{{f.ROBRecoveryRate.value  | number: "1.1-1"}}</label>
                    </div>
                </div>
                    <div class="col-sm-4" style="padding-left: 0px;">
                        <label class="col-sm-8 control-label">
                          RO-B Temp Return (°C):
                        </label>

                        <div class="col-sm-4" *ngIf="ROVerificationStatus != 'Completed'">
                          <input [style.borderColor]="displayRORemainingFields && !f.LoopReturnTempB.value ? 'red' : ''"
                                (blur)="ReplaceLeadingZero('LoopReturnTempB')"
                                (keypress)="numberOnly($event)"
                                [ngClass]="{
                                  OurOffRange: checkRange(f.LoopReturnTempB, 18, 25) }"
                                maxlength="4"
                                class="form-control smInput"
                                formControlName="LoopReturnTempB"
                                name="LoopReturnTempB"
                                placeholder="##.#"
                              />
                          <span
                            *ngIf="
                              f.LoopReturnTempB.errors?.required &&
                              (f.LoopReturnTempB.dirty ||
                                f.LoopReturnTempB.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                          <span *ngIf="f.LoopReturnTempB.errors?.pattern"
                          [ngClass]="'error'">##.#</span>
                        </div>
                        <div class="col-sm-4" *ngIf="ROVerificationStatus == 'Completed'"
                            [ngClass]="{invalidValueBackground:checkRange(f.LoopReturnTempB, 18, 25)}">
                            <label class="readOnlyText" *ngIf="f.LoopReturnTempB.value != 'N/A'">{{f.LoopReturnTempB.value | number: "1.1-1"}}</label>
                            <label class="readOnlyText" *ngIf="f.LoopReturnTempB.value == 'N/A'">{{f.LoopReturnTempB.value}}</label>
                        </div>
                    </div>

                </div>

                <div class="clearfix"></div>

                <div class="box-row col-sm-12">
                    <div class="col-sm-11" style="margin-top: 15px;">
                        <notes #weeklyROVerificationNotes  [showHours]='true'
                        (CheckValidForm)="IsWeeklyPanelFill('Notes')"
                        [isValidForm] ="WPDailySatelliteDetailForm.invalid"
                        (addNoteClick)="executeGRWPSatelliteCreateUpdate('Notes','Weekly')">
                       </notes>
                   </div>
                   <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="ROVerificationStatus != 'Completed'">
                       <button type="button" class="row btn btn-primary"
                        (click)="saveAndValidate('ROVerification', 'Weekly')">
                       <i class="fa fa-save"></i>&nbsp; Save</button>
                   </div>
                </div>
              </div>

              <!-- <div *ngIf="this.f.WeeklyROVerificationDetailsEnteredOn.value"> -->
              <!-- <div *ngIf="this.ROVerificationStatus == 'Completed'">
                  <div class="clearfix"></div>
                  <hr>
                <div class="box-row col-sm-12  divider">
                    <div class="col-sm-4">
                      <strong>CWP RO Disinfection: </strong>
                    </div>
                  </div>
                  <div class="clearfix"></div>

                  <div class="box-row col-sm-12">
                    <div class="col-sm-4">
                      <label class="col-sm-8 control-label">
                        CWP RO Disinfected:</label>
                      <div class="col-sm-4">
                        <label class="readOnlyText">{{f.CWPRODisinfected.value}}</label>
                      </div>

                    </div>

                    <div class="col-sm-8">
                      <label class="col-sm-4 control-label" style="padding-right: 3%;">
                        Disinfection Date:</label
                      >
                      <div
                        class="col-sm-4"
                        style="padding-left: 0px;">
                        <label class="readOnlyText" *ngIf="f.DisinfectionDate.value">
                          {{
                            f.DisinfectionDate.value | date: "dd/MM/yyyy HH:mm"
                          }}</label>
                          <label class="readOnlyText" *ngIf="!f.DisinfectionDate.value">
                              {{
                                f.TestDisinfectionDate.value.jsdate | date: "dd/MM/yyyy HH:mm"
                              }}
                          </label>
                      </div>

                    </div>
                  </div>

                  <div class="clearfix"></div>

                  <div class="box-row col-sm-12">
                    <div class="col-sm-4">
                      <label class="col-sm-8 control-label">
                        Chemical Residual Test Result?:</label>
                      <div
                        class="col-sm-4"
                        *ngIf="f.DisinfectionDate.value"
                        style="padding-left: 12px;">
                        <label
                          class="readOnlyText"
                          [style.color]="
                            f.ResidualTestResult.value == 'Negative'
                              ? 'red'
                              : 'black'
                          "
                          >{{ f.ResidualTestResult.value }}</label
                        >
                      </div>
                      <div
                      *ngIf="!f.DisinfectionDate.value"
                        class="col-sm-4"
                        style="padding-left: 12px;">
                      <select
                          class="form-control mdInput"
                          formControlName="ResidualTestResult"  [style.color]="f.ResidualTestResult.value == 'Negative'
                            ? 'red' : 'black'">
                          <option value="" style="color:black">Select</option>
                          <option value="Positive" style="color:black">Positive</option>
                          <option value="Negative" style="color:red">Negative</option>
                      </select>
                        <span
                          *ngIf="
                            f.ResidualTestResult.errors?.required &&
                            (f.ResidualTestResult.dirty ||
                              f.ResidualTestResult.touched ||
                              isValidFormSubmitted == false)
                          "
                          [ngClass]="'error'"
                        >
                          Required!
                        </span>
                      </div>
                    </div>
                    <div class="col-sm-8">
                      <label class="col-sm-4 control-label" style="padding-right: 3%;">
                        Chemical Disinfection User:</label
                      >
                      <div
                        class="col-sm-8"
                        style="padding-left: 0px;">
                        <label class="readOnlyText">{{
                          f.ChemicalDisinfectionUser.value ? f.ChemicalDisinfectionUser.value : currentUserName
                        }}</label>
                      </div>

                    </div>
                  </div>

                  <div class="box-row col-sm-12">
                      <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                          Disinfect Secondary RO:</label
                        >
                        <div  *ngIf="!f.DisinfectionDateSec.value || f.DisinfectSecondaryRO.value != 'Yes' "
                          class="col-sm-4"  [ngClass]="{ nonePointerEvents: ((f.CreatedDate.value.jsdate | date: 'dd/MM/yyyy') != (today | date:'dd/MM/yyyy')) }"
                          style="padding-left: 12px;">
                        <select
                            class="form-control mdInput"  [style.color]="f.DisinfectSecondaryRO.value == 'No'? 'red' : ''"
                            formControlName="DisinfectSecondaryRO">
                            <option value="" style="color:black">Select</option>
                            <option value="Yes" style="color:black">Yes</option>
                            <option value="No" style="color:red">No</option>
                        </select>
                          <span
                            *ngIf="
                              f.DisinfectSecondaryRO.errors?.required &&
                              (f.DisinfectSecondaryRO.dirty ||
                                f.DisinfectSecondaryRO.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                        <div
                        class="col-sm-4" *ngIf="f.DisinfectionDateSec.value && f.DisinfectSecondaryRO.value == 'Yes' "
                        style="padding-left: 12px;">
                        <label class="readOnlyText" [style.color]="f.DisinfectSecondaryRO.value == 'No'? 'red' : ''">
                          {{
                            f.DisinfectSecondaryRO.value
                          }}</label>

                      </div>

                      </div>

                  </div>

                  <div *ngIf="f.DisinfectSecondaryRO.value == 'Yes'">
                  <div class="clearfix"></div>
                  <hr>
                  <div class="box-row col-sm-12">
                      <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                          CWP RO Disinfected:</label
                        >
                        <div
                          class="col-sm-4">
                          <label class="readOnlyText">{{f.CWPRODisinfected2.value}}</label>
                        </div>

                      </div>

                      <div class="col-sm-8">
                        <label class="col-sm-4 control-label" style="padding-right: 3%;">
                          Disinfection Date:</label
                        >
                        <div
                          class="col-sm-4"
                          style="padding-left: 0px;">
                          <label class="readOnlyText" *ngIf="f.DisinfectionDateSec.value">
                            {{
                              f.DisinfectionDateSec.value | date: "dd/MM/yyyy HH:mm"
                            }}</label>
                            <label class="readOnlyText" *ngIf="!f.DisinfectionDateSec.value">
                                {{
                                  f.TestDisinfectionDate.value.jsdate | date: "dd/MM/yyyy HH:mm"
                                }}
                            </label>
                        </div>

                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <div class="box-row col-sm-12">
                      <div class="col-sm-4">
                        <label class="col-sm-8 control-label">
                          Chemical Residual Test Result?:</label
                        >
                        <div
                          class="col-sm-4"
                          style="padding-left: 12px;"
                        >
                          <label
                          *ngIf="f.DisinfectionDateSec.value"
                            class="readOnlyText"
                            [style.color]="
                              f.ResidualTestResult.value == 'Negative'
                                ? 'red'
                                : 'black'
                            "
                            >{{ f.ResidualTestResult.value }}</label
                          >
                        </div>
                        <div
                        *ngIf="!f.DisinfectionDateSec.value"
                          class="col-sm-4"
                          style="padding-left: 12px;">
                        <select
                          class="form-control mdInput"
                          formControlName="ResidualTestResultsec" [style.color]="f.ResidualTestResultsec.value == 'Negative'
                          ? 'red' : 'black'">
                          <option value="" style="color:black">Select</option>
                          <option value="Positive" style="color:black">Positive</option>
                          <option value="Negative" style="color:red">Negative</option>
                        </select>
                          <span
                            *ngIf="
                              f.ResidualTestResultsec.errors?.required &&
                              (f.ResidualTestResultsec.dirty ||
                                f.ResidualTestResultsec.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <label class="col-sm-4 control-label" style="padding-right: 3%;">
                          Chemical Disinfection User:</label
                        >
                        <div
                          class="col-sm-8"
                          style="padding-left: 0px;">
                          <label class="readOnlyText">{{
                            f.ChemicalDisinfectionUserSec.value ? f.ChemicalDisinfectionUserSec.value : currentUserName
                          }}</label>
                        </div>

                      </div>
                    </div>
                  </div>
                    <div class="col-sm-11" style="margin-top: 15px;">
                        <notes #weeklyDisinfectionNotes [showHours]='true'
                        (addNoteClick)="executeGRWPSatelliteCreateUpdate('Notes','Disinfection')">
                       </notes>
                   </div>
                   <div class="row col-sm-1" style="margin-top: 15px;" *ngIf="!f.DisinfectionDateSec.value">
                       <button type="button" class="row btn btn-primary"
                        (click)="executeGRWPSatelliteCreateUpdate('Disinfection', 'Weekly')">
                       <i class="fa fa-save"></i>&nbsp; Save</button>
                   </div>

              </div> -->

          </div>
            </div>
        </div>
    </div>
</div>

<attachment
(RaiseError)="AttachmentError($event)"
#attachment
>
</attachment>
</div>
</form>

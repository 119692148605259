import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'FilterSearch',
  templateUrl: './FilterSearch.component.html'
})
export class FilterSearchComponent {
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  FilterDetail = [];
  Users = [];
  selectedDivision = 'All Divisions';
  EventId = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = '/DialysisMachine/FilterDetail';
  workOrderRefNo = '';
  currentPageNumber = 1;
  sortExpression = 'eventdate';
  sortDirection = 'DESC';
  pageSize = 25;
  ShowSearch = false;
  selectedUser = '';
  title = 'DM Filter Changes ';
  searchText = 'Search Records';
  defaultSort;

  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'EventId', '4');
      this.dataGridService.addHeader('Event ID', 'eventcode', '12');
      this.dataGridService.addHeader('Filter Date', 'eventdate', '12');
      this.dataGridService.addHeader('Division', 'divisionname', '12');
      this.dataGridService.addHeader('Equipment', 'equipmentname', '12');
      this.dataGridService.addHeader('Filter Type', 'filter', '15');
      this.dataGridService.addHeader('Disinfection Done', 'equipmentoutdisinfection', '14');
      this.dataGridService.addHeader('Form User', 'createdByName', '15');
      this.sortDirection = 'DESC';
      this.sortExpression = 'eventdate';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('Event Date', true);

      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.fillUser();
      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeFilterSearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeFilterSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const FilterSearch = this.prepareSearch();
    this.dmapi.GetFilterDMRepairDetails(FilterSearch).subscribe(
      pagedData => {
        
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.filterDMRepailDetails;

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    let FilterSearch = <any>{};

    FilterSearch.eventCode = this.EventId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      FilterSearch.fromDate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      FilterSearch.toDate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      FilterSearch.division = this.selectedDivision;
    }

    if (this.selectedUser !== undefined && this.selectedUser !== '') {
      FilterSearch.createdByName = this.selectedUser;
    }

    FilterSearch.currentPageNumber = this.currentPageNumber;
    FilterSearch.sortExpression = this.sortExpression;
    FilterSearch.sortDirection = this.sortDirection;
    FilterSearch.pageSize = this.pageSize;

    return FilterSearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeFilterSearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue = '') {
    this.commonapi.FillDivision(true).subscribe(list => {

      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        // this.DialysisUnits = list.filter(
        //   x => x.text != "Home Program"
        // );
        this.DialysisUnits = list;
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(() => this.executeFilterSearch());
        }
      }
    });
  }

  fillUser() {
    this.dmapi.GetUsersDD().subscribe(list => (this.Users = list));
  }

  executeFilterSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';
    this.selectedUser = '';
    this.executeFilterSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.selectedUser ||
      (this.selectedDivision &&  this.selectedDivision != "All Divisions") ||
      this.EventId ||
      this.FromDate ||
      this.ToDate
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  CheckFormDate(fdate, edate) {
    return this.commonapi.CompareDates(fdate, edate);
  }

  DialysisUnitDropDownChanged() {
    this.executeFilterSearch();
  }
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class HomeFieldService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetHomeFieldService_MasterSearchDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_MasterSearchDetails',
      SearchOptions,
      httpOptions
    );
  }

  GetHomeFieldService_Station_Installation_Req(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_Station_Installation_Req',
      SearchOptions,
      httpOptions
    );
  }

  CreateHomeFieldService_Master(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Master',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_Master(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_Master',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MasterByID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_MasterByID',
      value,
      httpOptions
    );
  }


  DeleteHomeFieldService_MasterByID(obj) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/DeleteHomeFieldService_MasterByID',
      obj,
      httpOptions
    );
  }

  GetHomeFieldServiceNextStationID() {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldServiceNextStationID',
      httpOptions
    );
  }

  GetHomeFieldService_MasterNotes(value){
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_MasterNotes',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MasterTechnologiest(value){
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_MasterTechnologiest',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MasterPendingRecordsStations(value){
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_MasterPendingRecordsStations',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MasterAttachments(obj) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldService_MasterAttachments',
      obj,
      httpOptions
    );
  }

  CreateHomeFieldService_InitialSiteVisit(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_InitialSiteVisit',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_InitialSiteVisit(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_InitialSiteVisit',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_InitialSiteVisitbyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_InitialSiteVisitbyServiceID',
      value,
      httpOptions
    );
  }

  CreateHomeFieldService_FullInstallation(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_FullInstallation',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_FullInstallation(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_FullInstallation',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_FullInstallationbyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_FullInstallationbyServiceID',
      value,
      httpOptions
    );
  }


  convertDateTimeToTime(datetime){
    if(datetime == null)
    return new Date().toTimeString().split(' ')[0];
    else
    return new Date(datetime).toTimeString().split(' ')[0];
  }

  GetHFSMachaineHistoryMaxKms(value) {
    return this.http.post<any>(
      this.apiUrl +
        'HFService/GetHFSMachaineHistoryMaxHours',
      value,
      httpOptions
    );
  }

  GetHFS_GREquipmentVehicleIsAvailable(obj) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHFS_GREquipmentVehicleIsAvailable',
      obj,
      httpOptions
    );
  }

  GetHFS_IsKmsIsGreaterThanPreviousKms(obj) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHFS_IsKmsIsGreaterThanPreviousKms',
      obj,
      httpOptions
    );
  }

  CreateHomeFieldService_EquipmentExchange(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_EquipmentExchange',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_EquipmentExchange(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_EquipmentExchange',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_EquipmentExchangebyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_EquipmentExchangebyServiceID',
      value,
      httpOptions
    );
  }


  CreateHomeFieldService_Maintenance(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Maintenance',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_Maintenance(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_Maintenance',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MaintenancebyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_MaintenancebyServiceID',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_Maintenance_LastCompletedDates(value){
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_Maintenance_LastCompletedDates',
      value,
      httpOptions
    );
  }


  CreateHomeFieldService_Other(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Other',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_Other(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_Other',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_OtherbyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_OtherbyServiceID',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_OtherbyID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_OtherbyID',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_SectionPrepDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_SectionPrepDetails',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_SectionVehicleDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_SectionVehicleDetails',
      value,
      httpOptions
    );
  }

  CreateHomeFieldService_Section_PrepDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Section_PrepDetails',
      value,
      httpOptions
    );
  }

  CreateHomeFieldService_Section_VehicleDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Section_VehicleDetails',
      value,
      httpOptions
    );
  }


  GetHomeFieldService_SectionPurposeDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_SectionPurposeDetails',
      value,
      httpOptions
    );
  }

  CreateHomeFieldService_Section_PurposeDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Section_PurposeDetails',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_SectionReturnDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_SectionReturnDetails',
      value,
      httpOptions
    );
  }

  CreateHomeFieldService_Section_ReturnDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/CreateHomeFieldService_Section_ReturnDetails',
      value,
      httpOptions
    );
  }


  GetHomeFieldService_Section_VehicleHistory(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_Section_VehicleHistory',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_PurposeListbyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_PurposeListbyServiceID',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_PurposeCompletedTypeListbyServiceID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_PurposeCompletedTypeListbyServiceID',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_Maintenance_PerformedHistoryWithTypeByStationId(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_Maintenance_PerformedHistoryWithTypeByStationId',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_MasterTechTestingTime(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_MasterTechTestingTime',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_Master_Reference_Purpose_Select(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_Master_Reference_Purpose_Select',
      value,
      httpOptions
    );
  }

  GetHomeFieldMasterByTaskID(value){
    return this.http.post<any>(
      this.apiUrl + 'HFService/GetHomeFieldMasterByTaskID',
      value,
      httpOptions
    );
  }


  PrintHomeFieldServicePDF(value) {
    return this.http.post<any>(
      this.apiUrl + 'HFService/PrintHomeFieldServicePDF',
      value,
      httpOptions
    );
  }

  GetHomeFieldService_ISVStationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHomeFieldService_ISVStationDetails',
      value,
      httpOptions
    );
  }

  UpdateHomeFieldService_ISVStationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/UpdateHomeFieldService_ISVStationDetails',
      value,
      httpOptions
    );
  }

  GetHFS_GRHomeStationIsAvailable(value) {
    return this.http.post<any>(
      this.apiUrl +
      'HFService/GetHFS_GRHomeStationIsAvailable',
      value,
      httpOptions
    );
  }

}


<section class="content-header">
    <h1>
        {{title}}
        <!-- <sup *ngIf="title!=''" class="beta">
            [beta]
        </sup> -->

    </h1>

</section>
<section class="content">

    <section>
        <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]="true" [addurl]="AddUrl" #pageheader
                    [(searchText)]="searchText" [isSearchOpen]="ShowSearch"
                    (searchShowChange)="onSearchShowChange($event)" [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>


                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Form Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input (focus)="fromDate.toggleCalendar()"
                                                    (click)="fromDate.openCalendar()" class="form-control"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="FromDate" [(ngModel)]="FromDate" [options]="myOptions"
                                                    #fromDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)">

                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-6">
                                        <div class>
                                            <label class="col-sm-6 control-label">Form Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input (focus)="toDate.toggleCalendar()"
                                                        (click)="toDate.openCalendar()" class="form-control"
                                                        style="float:none" placeholder="Select a date" angular-mydatepicker
                                                        name="ToDate" [(ngModel)]="ToDate" [options]="myOptions"
                                                        #toDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)">

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>


                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Collection Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input (focus)="sampleReadFromDate.toggleCalendar()"
                                                    (click)="sampleReadFromDate.openCalendar()" class="form-control"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="SampleReadFromDate" [(ngModel)]="SampleReadFromDate"
                                                    [options]="myOptions" #sampleReadFromDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)">

                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-6">
                                        <div class>
                                            <label class="col-sm-6 control-label">Collection Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input (focus)="sampleReadToDate.toggleCalendar()"
                                                        (click)="sampleReadToDate.openCalendar()" class="form-control"
                                                        style="float:none" placeholder="Select a date" angular-mydatepicker
                                                        name="SampleReadToDate" [(ngModel)]="SampleReadToDate"
                                                        [options]="myOptions" #sampleReadToDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)">

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>


                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Report Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input (focus)="retestSampleReadFromDate.toggleCalendar()"
                                                    (click)="retestSampleReadFromDate.openCalendar()"
                                                    class="form-control" style="float:none" placeholder="Select a date"
                                                    angular-mydatepicker name="RetestSampleReadFromDate"
                                                    [(ngModel)]="RetestSampleReadFromDate" [options]="myOptions"
                                                    #retestSampleReadFromDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)">

                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-sm-6">
                                        <div class>
                                            <label class="col-sm-6 control-label">Report Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input (focus)="retestSampleReadToDate.toggleCalendar()"
                                                        (click)="retestSampleReadToDate.openCalendar()"
                                                        class="form-control" style="float:none"
                                                        placeholder="Select a date" angular-mydatepicker
                                                        name="RetestSampleReadToDate"
                                                        [(ngModel)]="RetestSampleReadToDate" [options]="myOptions"
                                                        #retestSampleReadToDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)">

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Event Id:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="BacteriaCode">
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div> -->
                                    <!-- <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Division:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="selectedDivision">
                                                    <option value="">Select</option>
                                                    <option value="{{item.text}}" *ngFor="let item of DialysisUnits">
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                            <div>
                                                <label class="col-sm-6 control-label">Status:</label>
                                                <div class="col-sm-6">
                                                    <select class="form-control" [(ngModel)]="Status">
                                                        <option value="{{item.value}}" *ngFor="let item of Statuses">
                                                            {{item.text}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">


                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12"></div>
                            </div>
                        </div> -->

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executeBacteriaSearch()">
                                        <span>
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <b>Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <!-- <option value="">All Divisions</option> -->
                                        <option value="{{item.text}}" *ngFor="let item of DialysisUnits">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}}
                                                Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a style="cursor:pointer" (click)="executeBacteriaSearchClear()">
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>

                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let BacteriaDetail of rows;let index=index;"
                                    (click)="_onRowClick(BacteriaDetail.bacteriaId)" class="pointer">

                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">
                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">{{BacteriaDetail.eventId}}</td>
                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">{{BacteriaDetail.divisionName}}</td>
                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">
                                            <span
                                            class='text-red inputrequired' *ngIf="CheckFormDate(BacteriaDetail.createdOn,BacteriaDetail.sampleReadDate)">*</span>
                                        {{BacteriaDetail.sampleReadDate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">
                                        {{BacteriaDetail.sampleRetestReadDate | date:'dd/MM/yyyy'}}
                                    </td>
                                    <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">{{BacteriaDetail.samplereadbyName}}</td>



                                    <!-- <td matTooltip="{{BacteriaDetail.funnelReadretestbyName}}">{{BacteriaDetail.status}}</td> -->

                                    <td matTooltip="{{BacteriaDetail.status}}">
                                        <div class="progressbar-wrapper">
                                            <ul class="progressBar">
                                                <!-- <li [ngClass]="{'progressBarlightGreen':BacteriaDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': BacteriaDetail.status == 'In Progress', 'fa':BacteriaDetail.status != '' }"></i>
                                                </li> -->
                                                
                                                <li [ngClass]="{'progressBarlightGreen': BacteriaDetail.status == 'Completed','progressBarlightBlue': BacteriaDetail.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': BacteriaDetail.status == 'Completed', 'fa-arrow-right':BacteriaDetail.status == 'In Progress', 'fa':BacteriaDetail.status != '' }">
                                                    </i>
                                                </li>
                                            </ul> 
                                            </div>
                                    
                                    </td>

                                </tr>
                            </tbody>

                        </table>

                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}}
                                                Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a style="cursor:pointer" (click)="executeBacteriaSearchClear()">
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

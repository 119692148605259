export class PMNotesModel {
    pmId:string;
    questionId: string;
    description:string;
    createdOn:string;
    createdBy:string;
    createdByName:string;
    hours = '0.00';
    Parts : any;
    showHours:boolean;
    API:string;
    CurrentUserId:string;
    partscategoryname:string;
    status:string;
    isAfterCompletionAdd : boolean;

    constructor(){
      this.showHours=false;
    }
  }

  export class PMComponentModel {
    showParts:boolean;
    API: any;
    DivisionId : any;
    equipmentId : any;
    CurrentUserId:string;
    CurrentUserName : string;
    partscategoryname:string;
    status:string;
    PrevParts : any;
    PrevNotes : any;
    AllParts:any;
    locked:boolean;
    dateOut:string;
    isAfterCompletionAdd : boolean;
    constructor(){
      this.showParts = false;
      this.locked= false;
    }
  }


  export class PMPartsModel {
    Id:number;
    equipmentId:number;
    pmId:string;
    questionId: string;
    partName:string;
    partDescription:string;
    quantity:string;
    totalCost:number;
    createdOn:Date;
    modifiedOn:Date;
    createdBy:string;
    modifiedBy:string;
    createdByName:string;
    showPartDelete:boolean;
    isAutoPart:boolean;
    constructor(){
      this.quantity = '0';
      this.totalCost = 0;
      this.isAutoPart=false;
    }
   }

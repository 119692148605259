import { Component, Input, Output} from '@angular/core';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'BulletChart',
  templateUrl: './BulletChart.html',
})
// tslint:disable-next-line:component-class-suffix
export class BulletChartControl {

  @Input() TotalValue = 50;
  @Input() Value = 40;
  @Input() MarkedValue = 30;
  @Input() Title = 'test';
  @Input() BarColor = 'red';

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

  }

}

<form [formGroup]="divisionForm" (ngSubmit)="executeDivisionCreateUpdate()" novalidate>

    <section class="content-header">
        <h1>
            Division
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">
                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeDivisionCreateUpdate()" [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>

                <div class="clearfix"></div>
                <div class="box">
                    <div class="box-body">

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                         <div class="panel-heading" role="tab" id="dmReference">
                            <h4 class="panel-title">
                            <div class="box-header with-border" style="padding:0px;">
                                <h3 class="box-title">
                                    Division</h3>
                            </div>
                            </h4>
                         </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Division
                                            Name:</label>
                                        <div class='col-sm-7'>

                                            <input name='divisionName' maxlength='80' required class='form-control'
                                                formControlName='divisionName'>
                                            <span
                                                *ngIf='divisionForm.controls.divisionName.errors?.required && (divisionForm.controls.divisionName.dirty || divisionForm.controls.divisionName.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>
                                            <span class='text-red inputrequired'>*</span>Prefix:</label>
                                        <div class='col-sm-3'>
                                            <input name='costcenter' maxlength='4' class='form-control'
                                                formControlName='costcenter'>
                                            <span
                                                *ngIf='divisionForm.controls.costcenter.errors?.required && (divisionForm.controls.costcenter.dirty || divisionForm.controls.costcenter.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>
                                            <!-- <span class='text-red inputrequired'>*</span> -->
                                                Color:</label>
                                        <div class='col-sm-7'>

                                            <select class="form-control" formControlName="color" name="color">
                                                <option *ngFor="let colors of data"
                                                    [ngStyle]="{'background-color': colors.value}"
                                                    [value]="colors.value" [style.color]="'white'">{{colors.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf='divisionForm.controls.color.errors?.required && (divisionForm.controls.color.dirty || divisionForm.controls.color.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-1" [style.height.px]="33"
                                        [ngStyle]="{'background-color': f.color.value}"></div>
                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info stationDetails">
                            <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                            <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title">
                                        Dialysis Station Details</h3>
                                </div>
                            </h4>
                            </div>
                            </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-2'>
                                        <label class='col-sm-5 control-label'>
                                            Stations:</label>
                                        <div class='col-sm-7'>
                                            <input [(ngModel)]="stationsCount" type="number" maxlength="99"
                                                class='form-control'
                                                [disabled]="lockStations[0] == 'Lock' || f.divisionId.value !== '0'"
                                                value="{{stationsCount}}" [ngModelOptions]="{standalone: true}"
                                                (keyup.enter)="onStationCountChange()">
                                            <!-- <span *ngIf='stationsCount > 99 || stationsCount <= 0' [ngClass]="'error'">
                                                Stations must be between 1 to 99! </span> -->
                                        </div>

                                    </div>
                                    <!-- <div class='col-sm-3' *ngIf='isHomeProgram() && f.defaultStation.value'>
                                        <label class='col-sm-6 control-label'>
                                            Default Station:</label>
                                        <div class='col-sm-6'>
                                            <input formControlName='defaultStation' name='defaultStation'
                                                class='form-control'>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-3" *ngIf="f.divisionId.value !== '0' && !isHomeProgram()">
                                        <label class='col-sm-5 control-label'>
                                            Lock/Unlock:</label>
                                        <div class='col-sm-7'>
                                            <!-- <bSwitch [switch-off-text]="unlockText" [switch-on-text]="lockText"
                                                [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                [switch-disabled]="disableEditStations()"
                                                (changeState)="enableDisableStations($event)" [(ngModel)]="lockStations"
                                                [ngModelOptions]="{standalone: true}">
                                            </bSwitch> -->
                                            <mat-button-toggle-group multiple [value]="lockStations"
                                                [disabled]="disableEditStations()">
                                                <mat-button-toggle *ngFor="let item of lockValues"
                                                    (change)="enableDisableStations(item.name)" value="{{item.name}}"
                                                    [ngClass]="{'toggle-switch-success':item.name === 'Lock' && lockStations[0] == 'Lock',
                                                    'toggle-switch-danger': item.name === 'Unlock' && lockStations[0] == 'Unlock' }"
                                                    >
                                                    {{item.name}}
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                    </div>
                                    <div class='col-sm-7' *ngIf="GetSiteName() =='GR'" >
                                      <label class='col-sm-3 control-label'>
                                          Station Format:</label>
                                      <div class='col-sm-9' >
                                        <label  *ngIf='isHomeProgram()'  class='control-label' style="font-size: 13px" [ngClass]="'error'">
                                          BMR###XXX</label>

                                          <label  *ngIf='!isHomeProgram()'  class='control-label' style="font-size: 13px" [ngClass]="'error'">
                                            XXXX-##X
                                            <!-- / XXXX-## / XXXX-# / XXX-##X / XXX-## / XXX-# -->
                                          </label>
                                      </div>
                                  </div>
                                </div>
                                <br>
                                <br>

                                <div class="container-fluid container-scroll">
                                    <div class="row boxPadding">
                                        <div class="col-md-3 customWidth"
                                            *ngFor="let counter of getStationsArray();let counterIndex=index"
                                            [ngStyle]="{'height': (counterIndex == 0 ? 'auto' :'190px')  }">
                                            <div formArrayName="stations"
                                                *ngFor="let station of divisionForm.get('stations')['controls']; let i=index;">
                                                <div class="col-md-12 subcontainer" [formGroupName]="i"
                                                    *ngIf="i < ((counterIndex+1)*5) && i >= ((counterIndex)*5)">
                                                    <label class='col-sm-3 control-label text-right' style="font-size: 12px; padding-left: 0px;">
                                                        Station {{i+1}}:</label>
                                                    <div class="input-group col-sm-8" *ngIf="!disableEditStations()">
                                                        <div class="input-group inputGroup">
                                                            <input name='dialysisStationName' class='form-control'
                                                                maxlength='15' (keyup)="forceInputUppercase($event)"
                                                                [ngClass]="{'has-danger': station.status == 'INVALID'}"
                                                                formControlName='dialysisStationName' style="font-size: 13px">
                                                            <span (click)="removeStation(station)" title="Delete Station" *ngIf="!isHomeProgram()"
                                                                class="input-group-addon" style="cursor: pointer"><i
                                                                    class="fa fa-remove"></i></span>
                                                        </div>
                                                    </div>
                                                    <div class='col-sm-9' *ngIf="disableEditStations()">
                                                        <input name='dialysisStationName' class='form-control'
                                                            maxlength='15' (keyup)="forceInputUppercase($event)"
                                                            [ngClass]="{'has-danger': station.status == 'INVALID'}"
                                                            style="margin-bottom:4px"
                                                            formControlName='dialysisStationName'>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="f.divisionId.value !== '0' && ((i+1) == getAllStationsLength()) && ((counterIndex+1) == getStationsArray().length) && getAllStationsLength()%5 != 0">
                                                    <div class="col-md-12 subcontainer">
                                                        <label class='col-sm-3 control-label text-right'
                                                            style="font-size: 12px; padding-left: 0px;">
                                                        </label>
                                                        <div class="input-group col-sm-8 text-center" *ngIf="!isHomeProgram()">
                                                            <a class="btn btn-primary" (click)="addOneStation()"
                                                                id="btnAddNew"><i class="fa fa-plus"></i> &nbsp; Add Station
                                                            </a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3 customWidth" style="height:190px" *ngIf="f.divisionId.value !== '0' && getAllStationsLength()%5 == 0">
                                            <div>
                                                <div class="col-md-12 subcontainer">
                                                    <label class='col-sm-3 control-label text-right'
                                                        style="font-size: 12px; padding-left: 0px;">
                                                    </label>
                                                    <div class="input-group col-sm-8" *ngIf='!isHomeProgram()'>
                                                        <a class="btn btn-primary" (click)="addOneStation()"
                                                            id="btnAddNew"><i class="fa fa-plus"></i> &nbsp; Add Station
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 box-row" style="    max-height: 190px;overflow-y: auto;">
                                    <div class="col-sm-3">
                                        <div formArrayName="stations" *ngFor="let container of divisionForm.get('stations')['controls']; let i=index;">
                                            <div [formGroupName]="i" *ngIf="i < getStationCount()">
                                                <label class='col-sm-5 control-label'>
                                                    Station {{i+1}}:</label>
                                                <div class='col-sm-7'>
                                                    <input name='dialysisStationName' class='form-control' maxlength='10'
                                                        style="margin-bottom:4px" formControlName='dialysisStationName'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div formArrayName="stations" *ngFor="let container of divisionForm.get('stations')['controls']; let i=index;">
                                            <div [formGroupName]="i" *ngIf="i >= getStationCount() && i < (getStationCount()*2)">
                                                <label class='col-sm-5 control-label'>
                                                    Station {{i+1}}:</label>
                                                <div class='col-sm-7'>
                                                    <input name='dialysisStationName' class='form-control' maxlength='10'
                                                        style="margin-bottom:4px" formControlName='dialysisStationName'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div formArrayName="stations" *ngFor="let container of divisionForm.get('stations')['controls']; let i=index;">
                                            <div [formGroupName]="i" *ngIf="i >= (getStationCount()*2) && i < (getStationCount()*3)">
                                                <label class='col-sm-5 control-label'>
                                                    Station {{i+1}}:</label>
                                                <div class='col-sm-7'>
                                                    <input name='dialysisStationName' class='form-control' maxlength='10'
                                                        style="margin-bottom:4px" formControlName='dialysisStationName'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div formArrayName="stations" *ngFor="let container of divisionForm.get('stations')['controls']; let i=index;">
                                            <div [formGroupName]="i" *ngIf="i >= (getStationCount()*3) && i < (getStationCount()*4)">
                                                <label class='col-sm-5 control-label'>
                                                    Station {{i+1}}:</label>
                                                <div class='col-sm-7'>
                                                    <input name='dialysisStationName' class='form-control' maxlength='10'
                                                        style="margin-bottom:4px" formControlName='dialysisStationName'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                            <h4 class="panel-title">
                                <div class="box-header with-border" style="padding: 0px;">
                                    <h3 class="box-title">Options</h3>
                                </div>
                            </h4>
                            </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-9 control-label' style="text-align: left"><span
                                                        class='text-red inputrequired'>*</span>H2O Daily/Nurse Check Water Record?:</label>

                                                <div class='col-sm-3'>
                                                    <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                        (onChangeState)="onChange($event)" formControlName='h2oDaily'
                                                        name='h2oDaily'>
                                                    </bSwitch>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-4'>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-9 control-label' style="text-align: left"><span
                                                        class='text-red inputrequired'>*</span>Central Acid Concentrates?:</label>
                                                <div class='col-sm-3'>
                                                    <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                        (onChangeState)="onChange($event)"
                                                        formControlName='isCentralAcid' name='isCentralAcid'>
                                                    </bSwitch>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-sm-8' [hidden]="!divisionForm.controls.isCentralAcid.value">
                                        <div class="box-body">
                                            <label class='col-sm-3 control-label'>
                                                Container Name:</label>
                                            <div class='col-sm-9'>
                                                <div class='col-sm-2' formArrayName="containers"
                                                    style="min-width:20%;padding:5px"
                                                    *ngFor="let container of divisionForm.get('containers')['controls']; let i=index">
                                                    <div [formGroupName]="i">
                                                        <input name='containerName' class='form-control container'
                                                            maxlength='12' formControlName='containerName'>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-4 control-label' style="text-align: left"><span
                                                        class='text-red inputrequired'>*</span>Bacteria Level:</label>

                                                <div class='col-sm-3'>

                                                    <select name='bacteriaLevel' maxlength='dropdown'
                                                        class='form-control' formControlName='bacteriaLevel' required>
                                                        <option value="">Select</option>
                                                        <option value="{{item.text}}"
                                                            *ngFor="let item of BacterialLevelList">{{item.text}}
                                                        </option>
                                                    </select>
                                                    <span
                                                        *ngIf='divisionForm.controls.bacteriaLevel.errors?.required && (divisionForm.controls.bacteriaLevel.dirty || divisionForm.controls.bacteriaLevel.touched || formSubmitted)'
                                                        [ngClass]="'error'"> Required! </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title col-lg-6">Address Information</h3>
                                </div>
                                </h4>
                                </div>
                            </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Address
                                            1:</label>

                                        <div class='col-sm-7'>
                                            <textarea name='address1' required class="form-textarea lgInput" autosize
                                                formControlName='address1'></textarea>
                                            <span
                                                *ngIf='divisionForm.controls.address1.errors?.required && (divisionForm.controls.address1.dirty || divisionForm.controls.address1.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Address 2:</label>

                                        <div class='col-sm-7'>
                                            <textarea name='address2' class="form-textarea lgInput" autosize
                                                formControlName='address2'></textarea>

                                        </div>
                                    </div>

                                </div>

                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>City:</label>

                                        <div class='col-sm-7'>
                                            <input name='city' maxlength='50' required class='form-control'
                                                formControlName='city'>
                                            <span
                                                *ngIf='divisionForm.controls.city.errors?.required && (divisionForm.controls.city.dirty || divisionForm.controls.city.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Country:</label>

                                        <div class='col-sm-7'>
                                            <select name='country' maxlength='dropdown' class='form-control'
                                                formControlName='country' required
                                                (change)="CountryStateDropDownChanged()">
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of CountryList">
                                                    {{item.text}}</option>
                                            </select>
                                            <span
                                                *ngIf='divisionForm.controls.country.errors?.required && (divisionForm.controls.country.dirty || divisionForm.controls.country.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Postal/Zip
                                            Code:</label>

                                        <div class='col-sm-7'>
                                            <input name='zipcode' maxlength='30' required class='form-control'
                                                formControlName='zipcode'>
                                            <span
                                                *ngIf='divisionForm.controls.zipcode.errors?.required && (divisionForm.controls.zipcode.dirty || divisionForm.controls.zipcode.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Province/State:</label>

                                        <div class='col-sm-7'>
                                            <select name='state' required maxlength='dropdown' class='form-control'
                                                formControlName='state'>
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of StateList">
                                                    {{item.text}}</option>

                                            </select>
                                            <span
                                                *ngIf='divisionForm.controls.state.errors?.required && (divisionForm.controls.state.dirty || divisionForm.controls.state.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title col-lg-6">Contact Information</h3>
                                </div>
                                </h4>
                                </div>

                            </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Phone:</label>

                                        <div class='col-sm-7'>
                                            <input name='phone' (blur)="formatPhone('phone')" class="form-control"
                                                required formControlName='phone'>

                                            <span
                                                *ngIf='divisionForm.controls.phone.errors?.required && (divisionForm.controls.phone.dirty || divisionForm.controls.phone.touched || formSubmitted)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Phone Extension:</label>

                                        <div class='col-sm-7'>
                                            <input name='phoneExtension' maxlength='5' class='form-control'
                                                formControlName='phoneExtension'>
                                        </div>
                                    </div>

                                </div>

                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Toll Free Number:</label>

                                        <div class='col-sm-7'>
                                            <input name='tollFreeNumber' (blur)="formatPhone('tollFreeNumber')"
                                                maxlength='30' class='form-control' formControlName='tollFreeNumber'>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Fax:</label>

                                        <div class='col-sm-7'>
                                            <input name='fax' (blur)="formatPhone('fax')" maxlength='30'
                                                class='form-control' formControlName='fax'>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Toll Free Fax:</label>

                                        <div class='col-sm-7'>
                                            <input name='tollFreeFax' (blur)="formatPhone('tollFreeFax')" maxlength='30'
                                                class='form-control' formControlName='tollFreeFax'>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Other Number:</label>

                                        <div class='col-sm-7'>
                                            <input name='otherNumber' (blur)="formatPhone('otherNumber')" maxlength='30'
                                                class='form-control' formControlName='otherNumber'>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <notes [isValidForm]="this.divisionForm.invalid"
                            (addNoteClick)="executeDivisionCreateUpdate('Notes')" #notes></notes>

                    </div>


                </div>
                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [mainlisturl]="ListURL"
                    [showAddNew]="false" [showSearch]="false" [showSave]="true"
                    (SaveEvent)="executeDivisionCreateUpdate()" [showDelete]="false" #pageheader></PageHeader>

            </div>
        </div>
    </section>

</form>

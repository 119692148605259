<!-- <form class="form-signin">
  <h2>Login</h2>
  <label for="username" class="sr-only">Username</label>
  <input type="text" required autofocus id="username" #username placeholder="Username/Email" />

  <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
  <button type="button" (click)="onLoginClick(captchaRef)">Login</button>

  <span class="error">{{error}}</span>
</form> -->
<div class="login-box">
  <!-- /.login-logo -->
  <div class="login-box-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form [formGroup]="loginForm">
      <!-- Email Field -->
      <div class="form-group has-feedback">
        <input
          type="email"
          formControlName="email"
          class="form-control"
          placeholder="Email"
        />
        <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
      </div>
      <div *ngIf="f.email?.invalid && f.email?.touched" class="text-danger">
        Please enter a valid email.
      </div>

      <!-- Password Field -->
      <div class="form-group has-feedback">
        <input
          type="password"
          formControlName="password"
          class="form-control"
          placeholder="Password"
        />
        <span class="glyphicon glyphicon-lock form-control-feedback"></span>
      </div>
      <div *ngIf="f.password?.invalid && f.password?.touched" class="text-danger">
        Password is required.
      </div>

      <!-- Submit Button -->
      <div class="row">
        <!-- <div class="col-xs-8">
          <div class="checkbox icheck">
            <label>
              <input type="checkbox" /> Remember Me
            </label>
          </div>
        </div> -->
        <!-- /.col -->
        <div class="col-xs-offset-4 col-xs-4">  
          <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
          <button type="button" class="btn btn-primary btn-block btn-flat" (click)="onLoginClick(captchaRef)">Login</button>
        </div>
        <!-- /.col -->
      </div>
      <div class="row mt-4">
        <div class="error ml-0 col-sm-12 text-center">{{error}}</div>
      </div>
    </form>
  </div>
  <!-- /.login-box-body -->
</div>



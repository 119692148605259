import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class CDApiService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetDialyisStationDD(value) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialyisStationDD',
      value,
      httpOptions
    );
  }

  GetDialysisUnitDD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialysisUnitDD',
      '',
      httpOptions
    );
  }

  GetDialysisUnitAD() {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/GetDialysisUnitAD',
      '',
      httpOptions
    );
  }
  FillAcidLevelOptions(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/FillAcidLevelOptions',
      value,
      httpOptions
    );
  }


  FillVendorList() {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/FillVendorList',
      '',
      httpOptions
    );
  }
  // ACID Level API

  GetCentralDeliveryAcidDrumFillingRecords(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetCentralDeliveryAcidDrumFillingRecords',
      value,
      httpOptions
    );
  }

  GetCentralDeliveryAcidDrumFillingbyID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetCentralDeliveryAcidDrumFillingbyID',
      value,
      httpOptions
    );
  }

  GetNotesBycentralDelievryAcidDrumFillingId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetNotesBycentralDelievryAcidDrumFillingId',
      value,
      httpOptions
    );
  }

  GetAttachemnetsBycentralDelievryAcidDrumFillingId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetAttachemnetsBycentralDelievryAcidDrumFillingId',
      value,
      httpOptions
    );
  }

  DeleteCentralDeliveryAcidDrumFillingbyID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/DeleteCentralDeliveryAcidDrumFillingbyID',
      value,
      httpOptions
    );
  }

  UpdateCentralDeliveryAcidDrumFilling(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/UpdateCentralDeliveryAcidDrumFilling',
      value,
      httpOptions
    );
  }

  CreateCentralDeliveryAcidDrumFilling(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/CreateCentralDeliveryAcidDrumFilling',
      value,
      httpOptions
    );
  }

  GetCentralDeliveryDailyBicarbPowderRecords(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetCentralDeliveryDailyBicarbPowderRecords',
      value,
      httpOptions
    );
  }
  GetAttachemnetsByByCentralDeliveryBicarbPowderId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetAttachemnetsByByCentralDeliveryBicarbPowderId',
      value,
      httpOptions
    );
  }

  GetNotesByCentralDeliveryBicarbPowderId(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetNotesByCentralDeliveryBicarbPowderId',
      value,
      httpOptions
    );
  }

  CreateCentralDeliveryBicarbPowder(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/CreateCentralDeliveryBicarbPowder',
      value,
      httpOptions
    );
  }

UpdateCentralDeliveryBicarbPowder(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/UpdateCentralDeliveryBicarbPowder',
      value,
      httpOptions
    );
  }

  DeleteCentralDeliveryBicarbPowderbyid(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/DeleteCentralDeliveryBicarbPowderbyid',
      value,
      httpOptions
    );
  }

  GetCentralDeliveryBicarbPowderByID(value) {
    return this.http.post<any>(
      this.apiUrl +
        'CentralDelivery/GetCentralDeliveryBicarbPowderByID',
      value,
      httpOptions
    );
  }

}

import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'MRMHistoryUploadSearch',
  templateUrl: 'MRMHistoryUploadSearch.component.html',

})
export class MRMHistoryUploadSearchComponent {

  constructor(private dmapi: DMApiService, private router: Router, private dataGridService: TableHeadsService,
    private auth: AuthService,
    private commonapi: CommonApiService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  Equipments = [];
  Users = [];
  Parts = [];
  Statuses = [];
  Patients = [];
  DMRepailDetail = [];
  selectedDivision = "All Divisions";
  selectedEquipment = "";

  //selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  RepairId = "";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef = "";
  OutFromDate;
  OutToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/DialysisMachine/MRMHistoryUploadDetail";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch = false;
  title = "Dialysis Machine Upload History Details";
  searchText = "Search Records"
  defaultSort;

  ngOnInit() {
    //AppComponent.loading=true;
    this.auth.GetCurrentUserDivision().subscribe(division => {

    this.dataGridService.clearHeader();

    this.dataGridService.addHeader("#", "id", "4");
    this.dataGridService.addHeader("Code", "code", "10");
    this.dataGridService.addHeader("Division", "divisionname", "10");
    this.dataGridService.addHeader("Date IN", "datein", "10");
    this.dataGridService.addHeader("Date Out", "dateout", "10");
    this.dataGridService.addHeader("Tech", "createdbyname", "10");
    this.dataGridService.addHeader("Equipment", "equipmentname", "20");
    this.dataGridService.addHeader("Status", "status", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("default", true);
    //console.log(this.tableHeaders);
    // this.setPage({ offset: 0 });
    
  if(division.length===1){
    this.fillDialysisUnit(division[0]);
    this.fillEquipment(division[0]);
  }
  else{
    this.fillDialysisUnit();
    this.fillEquipment();
  }

    // this.executeDmrepairSearch();
    this.fillUser();
    // this.fillEquipment();
    this.fillParts();
    this.fillStatus();
    this.fillPatients();
    this.CheckDirtySearch();
  }, error => {
    AppComponent.addAlert(error.message, 'error');
    AppComponent.HideLoader();
  });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    console.log(column);
    if (column == "#") {
      return;
    }
    //console.log(this.tableHeaders);
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDmrepairSearch();

  };

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDmrepairSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();
    var dmrepairsearch = this.prepareSearch();
    this.dmapi.GetDMPMRepairDetails(dmrepairsearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
    //  console.log(pagedData);
      this.rows = pagedData.dmpmRepailDetails;
      AppComponent.HideLoader()
    }
      ,
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {

    var dmrepairsearch = <any>{};
    dmrepairsearch.code = this.RepairId;
    if (this.FromDate != undefined && this.FromDate != "") {
      dmrepairsearch.datein = Globals.GetAPIDate(this.FromDate.jsdate);
    }
    if (this.ToDate != undefined && this.ToDate != "") {
      dmrepairsearch.dateout = Globals.GetAPIDate(this.ToDate.jsdate);
    }


    if (this.selectedDivision != undefined && this.selectedDivision != "" && this.selectedDivision != "0" && this.selectedDivision != "All Divisions") {
      dmrepairsearch.division = this.selectedDivision;
    }


    if (this.selectedEquipment != undefined && this.selectedEquipment != "") {
      dmrepairsearch.euipmentid = this.selectedEquipment;
    }


    if (this.selectedUser != undefined && this.selectedUser != "") {
      dmrepairsearch.createdBy = this.selectedUser;
    }



    if (this.selectedStatus != undefined && this.selectedStatus != "") {
      dmrepairsearch.status = this.selectedStatus;
    }

    dmrepairsearch.currentPageNumber = this.currentPageNumber;
    dmrepairsearch.sortExpression = this.sortExpression;
    dmrepairsearch.sortDirection = this.sortDirection;
    dmrepairsearch.pageSize = this.pageSize;
    // console.log(dmrepairsearch);
    return dmrepairsearch;

  }

  setPage() {
    if (this.currentPageNumber != this.page.pageNumber) {
      this.currentPageNumber = this.page.pageNumber;
      //this.page.pageNumber = pageInfo.offset;
      console.log('set page')
      this.executeDmrepairSearch();
    }
  }
  _onRowClick(row, DetailTypes) {

    // console.log(row);
    // console.log(DetailTypes);

    if (DetailTypes == "DM") {
      this.router.navigate(['DialysisMachine/DMRepairDetail'], { queryParams: { id: row, detailType: DetailTypes } });
    }
    else if (DetailTypes == "PM") {
      var href = '';
      this.dmapi.PrintPreventivePDF({ Id: row }).subscribe(list => {
        href = Globals.AttachmentUrl + list;
        window.open(href, '_blank');
      }, error => {
        console.log(error);
      })
    }

  }
  // onSort(event) {
  //   // // event was triggered, start sort sequence

  //    const sort = event.sorts[0];
  //    switch(sort.prop) {
  //     case "#": {
  //        this.sortExpression="repairid";
  //        break;
  //     }
  //     case "repairId": {
  //       this.sortExpression="repairid";
  //        break;
  //     }
  //     case "divisionName": {
  //       this.sortExpression="divisionname";
  //        break;
  //     }
  //     case "repairStartDate": {
  //       this.sortExpression="repairstartdate";
  //        break;
  //     }
  //     case "outTimeOfEquipment": {
  //       this.sortExpression="equipmentdateout";
  //        break;
  //     }
  //     case "patientName": {
  //       this.sortExpression="patientName";
  //        break;
  //     }
  //     case "createdByName": {
  //       this.sortExpression="createdByName";
  //        break;
  //     }
  //     case "equipmentName": {
  //       this.sortExpression="equipmentName";
  //        break;
  //     }
  //     case "workOrderRefNo": {
  //       this.sortExpression="workorderrefno";
  //        break;
  //     }
  //     case "status": {
  //       this.sortExpression="status";
  //        break;
  //     }
  //     default: {
  //        //statements;
  //        break;
  //     }
  //  }
  //  this.sortDirection = sort.dir.toUpperCase();
  //   this.executeDmrepairSearch();

  // }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 1 }
  };



  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue='') {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);
    this.commonapi.FillDivision(true).subscribe(list => {
      
      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        // this.DialysisUnits = list.filter(
        //   x => x.text != "Home Program"
        // );
        this.DialysisUnits = list;
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          this.executeDmrepairSearch();
        }
        else
          this.executeDmrepairSearch();
      }
      else
          this.executeDmrepairSearch();
    });
  }

  fillEquipment(divisionId=0) {
    this.dmapi.GetEquipmentDD(divisionId).subscribe(list => this.Equipments = list);

  }

  fillUser() {
    this.dmapi.GetUsersDD().subscribe(list => this.Users = list);

  }

  fillParts() {
    this.dmapi.GetPartsDD().subscribe(list => this.Parts = list);
  }

  fillStatus() {
    //this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.Statuses = [
      { value: "", text: "Select" },
      { value: "Completed", text: "Completed" }
    ];

  }

  fillPatients() {
    this.dmapi.GetPatientsDD().subscribe(list => this.Patients = list);

  }

  executeDmrepairSearchClear() {
    this.selectedDivision = "All Divisions";
    this.selectedEquipment = "";
    this.selectedStatus = "";
    this.selectedUser = "";
    this.RepairId = "";
    this.FromDate = "";
    this.ToDate = "";

    // this.executeDmrepairSearch();
    this.fillDialysisUnit();
    this.fillEquipment();

  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision != "" && this.selectedDivision != "All Divisions")
      || this.selectedEquipment != ""
      || this.selectedStatus != ""
      || this.selectedUser != ""
      || this.RepairId != ""
      || (this.FromDate != null && this.FromDate != "")
      || (this.ToDate != null && this.ToDate != "")
    ) {
      this.blnDirtySearch = true;

    }
    // console.log(this.blnDirtySearch);
    return this.blnDirtySearch;



    // if(this.FromDate != null && this.FromDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }
    // if(this.ToDate != null && this.ToDate != "")
    // {
    //   this.blnDirtySearch = false;
    //   return this.blnDirtySearch;
    // }

  }

  DialysisUnitDropDownChanged() {
    this.selectedEquipment ="";
    let defaultVal = this.DialysisUnits.filter(
      x => x.text == this.selectedDivision
    );

    if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
      this.fillEquipment(defaultVal[0].value);
    }
    else{
      this.fillEquipment();
    }
    setTimeout(()=>this.executeDmrepairSearch());
  }

}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<section class="content-header">
    <h1>
        {{title}}
    </h1>

</section>
<section>

    <section class="content">
        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Start Date From:</label>
                                        <div class="col-sm-6">

                                            <div class="input-group">
                                                <input (focus)="fromDate.toggleCalendar()"
                                                    (click)="fromDate.openCalendar()" class="form-control"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="FromDate" [(ngModel)]="FromDate" [options]="myOptions"
                                                    #fromDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />

                                                <!-- <span class="input-group-btn">
                                                <button type="button" class="btn btn-default" (click)="fromDate.clearDate()">
                                                    <i class="glyphicon glyphicon-remove"></i>
                                                </button>
                                                <button type="button" class="btn btn-default" (click)="fromDate.toggleCalendar()">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </span> -->
                                            </div>


                                        </div>


                                    </div>
                                    <div class="col-sm-6">
                                        <div class="">
                                            <label class="col-sm-6 control-label">Start Date To:</label>
                                            <div class="col-sm-6">

                                                <div class="input-group">
                                                    <input (focus)="toDate.toggleCalendar()"
                                                        (click)="toDate.openCalendar()" class="form-control"
                                                        style="float:none" placeholder="Select a date" angular-mydatepicker
                                                        name="ToDate" [(ngModel)]="ToDate" [options]="myOptions"
                                                        #toDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />

                                                    <!-- <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default" (click)="toDate.clearDate()">
                                                        <i class="glyphicon glyphicon-remove"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-default" (click)="toDate.toggleCalendar()">
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </span> -->
                                                </div>
                                                <!-- <my-date-picker name="ToDate"  [options]="myDatePickerOptions"
                                                [(ngModel)]="FromDate"></my-date-picker> -->


                                            </div>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                    <div>
                                        <label class="col-sm-6 control-label">Division:</label>
                                        <div class="col-sm-5">
                                            <select class="form-control" [(ngModel)]="selectedDivision">
                                                <option value="">Select</option>
                                              <option  value="{{item.text}}" *ngFor="let item of DialysisUnits">{{item.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Status:</label>
                                            <div class="col-sm-5">
                                                <select class="form-control" [(ngModel)]="selectedStatus">
                                                    <option value="{{item.value}}" *ngFor="let item of Statuses">
                                                        {{item.text}}</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="col-sm-6">
                                    <div>
                                        <label class="col-sm-6 control-label">Division:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="selectedDivision">
                                                <option value="">Select</option>
                                              <option  value="{{item.text}}" *ngFor="let item of DialysisUnits">{{item.text}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">

                                    <label class="col-sm-6 control-label">FormType:</label>
                                    <div class="col-sm-6">
                                        <select class="form-control" [(ngModel)]="selectedEquipment">
                                            <option  value="{{item.value}}" *ngFor="let item of Equipments">{{item.text}}</option>
                                          </select>

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div> -->

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                </div>
                            </div>
                        </div>

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executeDmrepairSearch()">
                                        <span><i class="fa fa-search"></i></span>
                                        <b>&nbsp;Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">
                                    <select class="form-control noBorderSearch" [(ngModel)]="selectedDivision"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <option value="{{item.text}}" *ngFor="let item of DialysisUnits">
                                            {{item.text}}
                                        </option>
                                    </select>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <!-- <span><b> <i>{{page.totalElements}} Records Found.</i></b></span> -->
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeWPDailySearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>

                                <!--<tr>
                                <th colspan="2" style="width: 50%">
                                    <span ng-bind="vm.totalRepairDetails"></span>&nbsp;Records
                                </th>
                                <th colspan="5" style="text-align: right; width: 50%">
                                    Page&nbsp;<span ng-bind="vm.currentPageNumber"></span>&nbsp;of&nbsp;
                                    <span ng-bind="vm.totalPages"></span>
                                </th>
                            </tr>-->

                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>



                                <tr *ngFor="let WPDaily of rows;let index=index;"
                                    (click)="_onRowClick(WPDaily.gRwaterprocessdailyrecordID,WPDaily.formType)"
                                    class="pointer">

                                    <td>

                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>
                                        <span class='text-red inputrequired'
                                            *ngIf="WPDaily.negative === 'Negative'">*</span>
                                        {{WPDaily.eventId}}
                                    </td>
                                    <td>{{WPDaily.divisionName}}</td>
                                    <td>{{WPDaily.formType}}</td>
                                    <td>{{WPDaily.formDate | date:'dd/MM/yyyy'}}</td>
                                    <!-- <td>{{WPDaily.status}} </td> -->
                                    <td matTooltip="{{WPDaily.status}}">
                                        <div class="progressbar-wrapper">
                                            <ul class="progressBar">
                                                <!-- <li [ngClass]="{'progressBarlightGreen':WPDaily.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': WPDaily.status == 'In Progress', 'fa':WPDaily.status != '' }"></i>
                                                </li> -->
                                                
                                                <li [ngClass]="{'progressBarlightGreen': WPDaily.status == 'Completed','progressBarlightBlue': WPDaily.status == 'In Progress'}">
                                                    <i [ngClass]="{'fa-check': WPDaily.status == 'Completed', 'fa-arrow-right':WPDaily.status == 'In Progress', 'fa':WPDaily.status != '' }">
                                                    </i>
                                                </li>
                                                <li [ngClass]="{'progressbarEmptyRed': WPDaily.status == 'Incompleted'}">
                                                    <i [ngClass]="{'fa-times': WPDaily.status == 'Incompleted', 'fa':WPDaily.status != '' }">
                                                    </i>
                                                </li>
                                            </ul> 
                                        </div>                                    
                                    </td>	
                                    <td *ngIf="GetSiteName() != 'SCSCDU'"><span *ngIf="WPDaily.disinfectionStatus == 1"
                                            class="fa fa-check"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="row">
                    <div class="col-lg-12 text-center">
                      <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                    </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeWPDailySearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

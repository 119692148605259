import { Component, OnInit, ViewChild } from '@angular/core';
import { TasksApiService } from '../API/tasksapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  MatButtonToggleModule
} from '@angular/material/button-toggle';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { AppComponent } from '../app.component';
import { AuthService } from '../API/auth.service';
import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../Core/Shared/AttachmentComponent/AttachmentComponent';
import { ValidateUser } from '../Validator/ValidateUser';
import { IDayCalendarConfig, DatePickerComponent, SingleCalendarValue } from "ng2-date-picker";
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../globals';
import { CommonCustomValidators } from '../Validator/CommonValidator';
import { error } from 'protractor';
@Component({
  selector: 'TaskDetail',
  templateUrl: './TaskDetail.component.html'
})
export class TaskDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  CreatedByUser = this.auth.GetUserName();
  today = new Date();
  CreatedDate = this.today;
  isValidFormSubmitted = null;
  loading = true;
  status = '';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  blnshowSave = false;
  blnshowDelete = false;
  ListURL = '/Task/TaskViewer';
  CategoryList = [];
  EquipmentList = [];
  DayList = [];
  UserList = [];
  DivisionList = [];
  TaskTypeList = [];
  dropdownSettings;
  onText = 'Recurrence';
  offText = 'Due Date(s)';
  onMonthWeekText = 'On Specific Day';
  offMonthWeekText = 'On Specific Date';
  onYes = 'Yes';
  offNo = 'No';
  onColor = 'success';
  offColor = 'danger';
  categoryName;
  schedule;
  UserMappingData = [];
  OldCompletedStatus = '';
  ReoccurancePattern = [
    { value: 'Daily', text: 'Daily' },
    { value: 'Weekly', text: 'Weekly' },
    { value: 'Monthly', text: 'Monthly' },
    { value: 'Yearly', text: 'Yearly' }
  ];
  tasknumber = '[AutoNumber]';
  nextScheduledDate = '';
  recurrenceCount = '';
  endDate = '';
  lastKeyTime = Date.now();
  // actualStartDate = '';

  TaskDetailForm = new UntypedFormGroup({
    taskType: new UntypedFormControl('', Validators.required),
    taskid: new UntypedFormControl(''),
    divisionId: new UntypedFormControl(''),
    category: new UntypedFormControl(''),
    itemId: new UntypedFormControl(''),

    AssignUserIds: new UntypedFormControl(''),
    description: new UntypedFormControl(''),

    IsRecurrence: new UntypedFormControl(''),
    DueDate: new UntypedFormControl(''),
    DueStartDate: new UntypedFormControl(''),
    ReoPattern: new UntypedFormControl(''),
    ReoStartDate: new UntypedFormControl(''),
    actualStartDate: new UntypedFormControl(''),
    ReoEndDate: new UntypedFormControl(''),
    ReoEvery: new UntypedFormControl(''),
    ReoOccurance: new UntypedFormControl(''),
    ReoEndOption: new UntypedFormControl(''),
    ReoWeekDays: new UntypedFormControl(''),
    completed: new UntypedFormControl(''),
    PinID: new UntypedFormControl(''),
    UserName: new UntypedFormControl(''),
    MeetingStartTime: new UntypedFormControl(''),
    MeetingEndTime: new UntypedFormControl(''),
    ReoMeetingStartTime: new UntypedFormControl(''),
    ReoMeetingEndTime: new UntypedFormControl(''),
    recurrenceGroupId: new UntypedFormControl(''),
    recurBy: new UntypedFormControl(''),
    TreatmentRecurrenceWeek: new UntypedFormControl(''),
    ReoStartMonth: new UntypedFormControl(''),
  });
  divisionName: any;
  equipmentName: any;
  priority: any;
  readStatus: any;
  taskTypeName: any;
  designationName: any;

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // if (
    //   this.TaskDetailForm.dirty &&
    //   (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    // ) {
    //   this.dialogRef = this.dialog.open(ConfirmationDialog, {
    //     disableClose: true
    //   });
    //   this.dialogRef.componentInstance.confirmMessage =
    //     'You have unsaved changes! If you leave, your changes will be lost.';
    //   return this.dialogRef.afterClosed().pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );
    // } else {
    //   return true;
    // }
    return true;
  }

  constructor(
    private API: TasksApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private datePipe: DatePipe
  ) { }

  public dayPickerConfig = <IDayCalendarConfig>{
    format: "MMM/YYYY",
    monthFormat: "MMMM, YYYY",
    showGoToCurrent:false,
    min: this.datePipe.transform(new Date(), 'MMM/yyyy')
  };

  ngOnInit() {
    AppComponent.ShowLoader();
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = this.createdOn = this.CreatedDate = this.modifiedOn = new Date(currentDate);
      this.setDateOptions();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.SetPageRights();
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'value',
          textField: 'text',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 6,
          allowSearchFilter: false
        };
        this.fillDivisionList();
        this.fillTaskTypeList();
        this.fillCategoryList();
        this.fillUserList(0);
        this.f.taskid.setValue(params['id'] || '0');
        let dateNum = (params['date'] || 0);
        if (dateNum > 0) {
          let dt = new Date(1 * dateNum);
          dt = Globals.GetClientDate(
            dt
          );
          // console.log(dt);
          this.f.DueDate.setValue({
            date: {
              year: dt.getFullYear(),
              month: dt.getMonth() + 1,
              day: dt.getDate()
            },
            jsdate: dt
          });
          this.f.DueStartDate.setValue({
            date: {
              year: dt.getFullYear(),
              month: dt.getMonth() + 1,
              day: dt.getDate()
            },
            jsdate: dt
          });
        }
        if (this.f.taskid.value !== '0') {
          this.setModel();
        }
        else {
          this.auth.GetCurrentUserDivision().subscribe(division => {
            if (division.length === 1) {
              this.f.divisionId.setValue(division[0]);
            }
            AppComponent.HideLoader();
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });

        }
      });
      const accesToken = this.auth.getAccessToken();
      this.designationName = accesToken.DesignationName;
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  getDisplayTime(val) {
    if (val)
      return new Date(val);
    else
      return this.today;
  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableUntil: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() }
    };
  }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }
  get f() {
    return this.TaskDetailForm.controls;
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    if (this.f.IsRecurrence.value == 'true') {
      this.dialogRef.componentInstance.confirmMessage =
        'Do you want to delete this task only or all upcoming recurrence tasks ?';
      this.dialogRef.componentInstance.okBtn = 'Delete This'
      // this.dialogRef.componentInstance.cancelBtn = 'Delete All';
      this.dialogRef.componentInstance.okBtn2 = 'Delete All';
    }
    else {
      this.dialogRef.componentInstance.confirmMessage =
        'Are you sure you want to delete this task ?';
    }

    this.dialogRef.afterClosed().subscribe(result => {
      if (result != false) {
        AppComponent.ShowLoader();

        if (!result)
          result = false;

        const FilterObj = {
          taskid: this.f.taskid.value,
          completiondate: this.f.DueDate.value.jsdate,
          recurrenceGroupId: this.f.recurrenceGroupId.value,
          deleteUpcoming: !result
        };
        this.API.DeleteTasksDetailsByID(FilterObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Task detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  onChange(e) {
    // console.log(this.f.IsRecurrence);
    // console.log(this.f.AssignUserIds);
  }

  setModel() {
    AppComponent.ShowLoader();
    const taskCreate = { Id: this.f.taskid.value };
    this.fillDivisionList();

    this.API.GetTasksMappingDetailsBytaskID(taskCreate).subscribe(
      success => {
        this.UserMappingData = success;
        const assignedUsers = [];
        // console.log(success);
        success.forEach(function (user) {
          assignedUsers.push({
            text: user.givenName,
            value: user.taskassignedto.toString()
          });
        });
        // console.log(assignedUsers);
        this.f.AssignUserIds.setValue(assignedUsers);
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );
    this.API.GetTaskDetailsByID(taskCreate).subscribe(
      success => {
        // console.log(success);
        this.tasknumber = success.taskCode;
        this.CreatedByUser = success.createdbyName;
        this.f.category.setValue(success.categoryId);
        this.categoryName = success.categoryName;
        if (success.divisionId == null) {
          this.f.divisionId.setValue('');
          this.divisionName = 'All Division';
        } else {
          this.f.divisionId.setValue(success.divisionId);
          this.divisionName = success.divisionName;
        }
        this.f.itemId.setValue(success.equipmentId);
        this.f.recurrenceGroupId.setValue(success.recurrenceGroupId);
        this.equipmentName = success.equipmentName;
        this.f.description.setValue(success.taskDescription);
        this.f.UserName.setValue(this.auth.GetUserEmail());
        this.f.recurBy.setValue(success.recurBy);
        this.f.TreatmentRecurrenceWeek.setValue(success.treatmentRecurrenceWeek);
        // this.f.DueDate.setValue(success.completionDate);
        let tempDate = new Date(success.completionDate);

        this.f.DueDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.completionDate
        });
        this.f.MeetingEndTime.setValue(success.completionDate);
        tempDate = new Date(success.startDate);

        this.f.DueStartDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.startDate
        });
        this.f.MeetingStartTime.setValue(success.startDate);
        if (
          success.startDateRecerrence1 != null &&
          success.startDateRecerrence1 != undefined
        ) {
          tempDate = new Date(success.startDateRecerrence1);
          this.f.ReoStartDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.startDateRecerrence1
          });
        }
        if (
          success.endDateRecerrence != null &&
          success.endDateRecerrence != undefined
        ) {
          tempDate = new Date(success.endDateRecerrence);
          this.f.ReoEndDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.endDateRecerrence
          });
        }

        // console.log(this.f.DueDate.value);
        this.priority = success.priority;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.status = success.status;
        //this.f.completed.setValue(success.completed == true);
        if (success.completed == true) {
          this.f.completed.setValue('true');
        } else {
          this.f.completed.setValue('');
        }
        this.OldCompletedStatus = this.f.completed.value;
        this.readStatus = success.readStatus;
        this.f.taskType.setValue(success.taskType);
        this.taskTypeName = success.taskTypeName;

        //this.f.IsRecurrence.setValue(success.isRecurrence == 'true');
        if (success.isRecurrence == true) {
          this.f.IsRecurrence.setValue('true');
        } else {
          this.f.IsRecurrence.setValue('');
        }
        if (
          success.treatmentRecurrenceDays != null &&
          success.treatmentRecurrenceDays != null
        ) {
          this.f.ReoWeekDays.setValue(
            success.treatmentRecurrenceDays.split(',')
          );
        }
        // this.f.ReoEndDate.setValue(success.endDateRecerrence);
        // this.f.ReoEndOption.setValue(success.isRecurrence);
        // this.f.ReoStartDate.setValue(success.startDateRecerrence1);
        this.f.ReoOccurance.setValue(success.endAfterRecerrence);

        if (
          success.endDateRecerrence != null &&
          success.endDateRecerrence != undefined
        ) {
          this.f.ReoEndOption.setValue('date');
        } else if (
          success.ReoOccurance != null &&
          success.ReoOccurance != undefined
        ) {
          this.f.ReoEndOption.setValue('occurance');
        } else {
          this.f.ReoEndOption.setValue('no');
        }

        this.f.ReoEvery.setValue(success.occurByEveryWeek);
        this.f.ReoPattern.setValue(success.recurrenceType);
        // console.log(this.tasknumber);
        //        this.f.contactType.setValue(success.contacttype);

        this.fillUserList(success.divisionId);
        this.fillInventoryList(success.divisionId, success.categoryName);
        this.createdOn = success.createdOn;
        const TaskObj = { Id: this.f.taskid.value };
        if (this.notes !== undefined) {
          this.notes.showHours = false;
          // attachment changes
          this.attachment.DocId = this.f.taskid.value;
          this.attachment.moduleName =
            attachmentFormModules[attachmentFormModules.Task_Manager];
          this.attachment.UploadBy = this.currentUserId;
          this.notes.Note = '';
          this.attachment.clearQueue();
        }
        this.API.GetattachmentDetailsbytaskID(TaskObj).subscribe(list => {
          this.attachment.PrevAttachments = list;
        });

        this.API.GetnotesbyTaskDetailsID(TaskObj).subscribe(list => {
          this.notes.NoteList = list;
        });

        if (this.f.IsRecurrence.value == "true") {
          const FilterObj = {
            taskid: this.f.taskid.value,
            completiondate: this.f.DueDate.value.jsdate,
            recurrenceGroupId: this.f.recurrenceGroupId.value,
          };
          this.API.GetTaskNextScheduledDate(FilterObj).subscribe(
            nextDetails => {
              if (nextDetails) {
                this.nextScheduledDate = nextDetails.completionDate;
                this.recurrenceCount = nextDetails.recurrenceCount;
                this.endDate = nextDetails.endDate;
              }
            },
            error => {
            }
          );
        }

        this.schedule = '';
        if (this.f.IsRecurrence.value == "true") {
          this.schedule = 'Recurrence: ';
          if (this.f.ReoEvery.value == 1) {
            this.schedule +=
              'Occurs every ' + this.GetPatternName(this.f.ReoPattern.value);
          } else {
            this.schedule +=
              'Occurs every ' +
              this.f.ReoEvery.value +
              ' ' +
              this.GetPatternName(this.f.ReoPattern.value);
          }

          if (this.f.ReoPattern.value == 'Monthly' && this.f.recurBy.value == "byDay") {
            this.schedule +=
              ' on ' + this.GetWeeksName(this.f.TreatmentRecurrenceWeek.value) + " " + this.GetWeekDaysName(this.f.ReoWeekDays.value);
          }
          if (this.f.ReoPattern.value == 'Weekly') {
            this.schedule +=
              ' on ' +
              this.GetWeekDaysName(this.f.ReoWeekDays.value);
          }
          this.schedule +=
            ' effective ' +
            this.datePipe.transform(
              this.f.ReoStartDate.value.jsdate,
              'dd/MM/yyyy'
            );
          if (this.f.ReoEndOption.value == 'date') {
            this.schedule +=
              ' until ' +
              this.datePipe.transform(
                this.f.ReoEndDate.value.jsdate,
                'dd/MM/yyyy'
              );
          } else {
            this.schedule += ' until ' + this.f.ReoOccurance.value + ' ocurrences';
          }
        } else {
          this.schedule = 'Due Date: ';
          this.schedule += this.datePipe.transform(
            this.f.DueDate.value.jsdate,
            'dd/MM/yyyy'
          );
        }

        this.GreaterValueCheck();
        // console.log(this.f.taskid.value);
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  GetPatternName(pattern) {
    switch (pattern) {
      case 'Daily':
        return 'day(s)';
      case 'Monthly':
        return 'month(s)';
      case 'Weekly':
        return 'week';
      case 'Yearly':
        return 'year';
      default:
        return '';
    }
  }

  GetActualMonthlyDate() {
     if ((this.f.taskid.value == '0' || this.f.taskid.value == 0 || this.f.taskid.value == '')
      && (this.f.ReoStartDate.value || this.f.ReoStartMonth.value) && this.f.ReoWeekDays.value && this.f.IsRecurrence.value
      && this.f.TreatmentRecurrenceWeek.value &&
      this.f.recurBy.value == 'byDay' && this.f.ReoPattern.value == 'Monthly') {

      const taskMonth = <any>{};

      var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
      var month = this.f.ReoStartMonth.value.split('/');
      const monthnumber = months.indexOf(month[0].toLowerCase());
      taskMonth.treatmentRecurrenceDays = this.f.ReoWeekDays.value;
      taskMonth.TreatmentRecurrenceWeek = this.f.TreatmentRecurrenceWeek.value;
      taskMonth.startMonth = monthnumber + 1;
      taskMonth.startYear = month[1];
      var d = new Date();
      d.setDate(1);
      d.setMonth(taskMonth.startMonth - 1 );
      d.setFullYear(taskMonth.startYear);
      taskMonth.startDateRecerrence1 = Globals.GetAPIDate(d);

      this.API.GetMonthlyFirstTaskDate(taskMonth).subscribe(
        success => {

          if (success.actualStartDate){

            var tempDate = new Date(success.actualStartDate);
            var dateOne = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0,0,0);
            var dateTwo = new Date(this.today.getFullYear(),this.today.getMonth(), this.today.getDate(),0,0,0);
            if(dateOne >= dateTwo)
            {
              if (
                success.actualStartDate != null &&
                success.actualStartDate != undefined
              ) {
                let tempactualDate = new Date(success.actualStartDate);
                this.f.ReoStartDate.setValue({
                  date: {
                    year: tempactualDate.getFullYear(),
                    month: tempactualDate.getMonth() + 1,
                    day: tempactualDate.getDate()
                  },
                  jsdate: tempactualDate
                });
              }
              // this.f.ReoStartDate.setValue(this.f.actualStartDate.value);
              this.f.ReoStartMonth.setErrors(null);
            }
            else
            {
              this.f.ReoStartMonth.setErrors({error: true});
            }
          }
          else
          {
            this.f.actualStartDate.setValue('');
          }
        },
        error => {
          this.f.actualStartDate.setValue('');
        }
      );
    }
    else {
      this.f.actualStartDate.setValue('');
    }
  }

  GetWeekDaysName(days) {
    // console.log(days);
    // const daysA = days.split(',');
    let result = '';
    days.forEach(function (d) {
      switch (d) {
        case 'M':
        case 'Monday':
          result += 'Monday, ';
          break;
        case 'T':
        case 'Tuesday':
          result += 'Tuesday, ';
          break;
        case 'W':
        case 'Wednesday':
          result += 'Wednesday, ';
          break;
        case 'Th':
        case 'Thrusday':
          result += 'Thrusday, ';
          break;
        case 'F':
        case 'Friday':
          result += 'Friday, ';
          break;
        case 'Sa':
          case 'Saturday':
          result += 'Saturday, ';
          break;
        case 'S':
          case 'Sunday':
          result += 'Sunday, ';
          break;
        default:
          break;
      }
    });

    return result.substring(0, result.length - 1);
  }

  GetWeeksName(week) {
    let result = '';
    switch (week) {
      case 'W1':
        result += '1st';
        break;
      case 'W2':
        result += '2nd';
        break;
      case 'W3':
        result += '3rd';
        break;
      case 'W4':
        result += '4th';
        break;

      default:
        break;
    }
    return result;
  }

  RecStatusChange() {
    this.taskTypeName = this.TaskTypeList.filter(
      (item, index) => item.value == this.f.taskType.value
    )[0].text;
    if (this.taskTypeName == 'Meeting') {
      this.offText = "Date(s)";
    } else {
      this.offText = "Due Date(s)";
    }
    // console.log(this.offText,this.f.taskType);
    this.GreaterValueCheck();
  }



  GreaterValueCheck() {

    if (this.f.taskid.value > 0 && this.OldCompletedStatus != this.f.completed.value) {
      this.f.PinID.setValidators([Validators.required]);
      this.f.PinID.setAsyncValidators([
        ValidateUser.UserValidate(this.f.UserName, this.auth)
      ]);
      this.f.PinID.updateValueAndValidity();
    } else {
      this.f.PinID.clearValidators();
      this.f.PinID.clearAsyncValidators();
      this.f.PinID.updateValueAndValidity();
    }
    const taskType = this.TaskTypeList.filter(
      (item, index) => item.value == this.f.taskType.value
    )[0].text;
    if (taskType === 'Other') {
      this.f.description.setValidators([Validators.required]);
      this.f.description.updateValueAndValidity();
    } else {
      this.f.description.setValidators([]);
      this.f.description.updateValueAndValidity();
    }
    if (this.f.IsRecurrence.value != 'true') {
      this.f.DueDate.setValidators([Validators.required]);
      this.f.DueDate.updateValueAndValidity();

      this.f.DueStartDate.setValidators([Validators.required]);
      this.f.DueStartDate.updateValueAndValidity();
      if (taskType == 'Meeting') {
        this.f.MeetingStartTime.setValidators([Validators.required]);
        this.f.MeetingStartTime.updateValueAndValidity();
        // this.f.MeetingEndTime.setValidators([Validators.required]);
        this.f.MeetingEndTime.setValidators([
          CommonCustomValidators.CheckDateAndTimeGreaterValidation(
            this.f.MeetingStartTime, this.f.MeetingEndTime
          ), Validators.required
        ]);
        this.f.MeetingEndTime.updateValueAndValidity();
      } else {
        this.f.MeetingStartTime.setValidators([]);
        this.f.MeetingStartTime.updateValueAndValidity();
        this.f.MeetingEndTime.setValidators([]);
        this.f.MeetingEndTime.updateValueAndValidity();
      }

      this.f.ReoStartDate.setValidators([]);
      this.f.ReoStartDate.updateValueAndValidity();

      this.f.ReoMeetingStartTime.setValidators([]);
      this.f.ReoMeetingStartTime.updateValueAndValidity();

      this.f.ReoMeetingEndTime.setValidators([]);
      this.f.ReoMeetingEndTime.updateValueAndValidity();

      this.f.ReoPattern.setValidators([]);
      this.f.ReoPattern.updateValueAndValidity();

      this.f.ReoEvery.setValidators([]);
      this.f.ReoEvery.updateValueAndValidity();

      this.f.ReoEndOption.setValidators([]);
      this.f.ReoEndOption.updateValueAndValidity();

      this.f.ReoOccurance.setValidators([]);
      this.f.ReoOccurance.updateValueAndValidity();

      this.f.ReoWeekDays.setValidators([]);
      this.f.ReoWeekDays.updateValueAndValidity();

      this.f.TreatmentRecurrenceWeek.setValidators([]);
      this.f.TreatmentRecurrenceWeek.updateValueAndValidity();

      this.f.ReoEndDate.setValidators([]);
      this.f.ReoEndDate.updateValueAndValidity();

      this.f.ReoOccurance.setValidators([]);
      this.f.ReoOccurance.updateValueAndValidity();
    } else {

      if (taskType == 'Meeting' && (this.f.taskid.value == 0 || !this.f.taskid.value)) {
        this.f.ReoMeetingStartTime.updateValueAndValidity();
        this.f.ReoMeetingStartTime.setValidators([Validators.required]);
        //this.f.ReoMeetingEndTime.setValidators([Validators.required]);
        this.f.ReoMeetingEndTime.setValidators([
          CommonCustomValidators.CheckDateAndTimeGreaterValidation(
            this.f.ReoMeetingStartTime, this.f.ReoMeetingEndTime
          ), Validators.required
        ]);
        this.f.ReoMeetingEndTime.updateValueAndValidity();
      } else {
        this.f.ReoMeetingStartTime.setValidators([]);
        this.f.ReoMeetingStartTime.updateValueAndValidity();
        this.f.ReoMeetingEndTime.setValidators([]);
        this.f.ReoMeetingEndTime.updateValueAndValidity();
      }

      this.f.MeetingStartTime.setValidators([]);
      this.f.MeetingStartTime.updateValueAndValidity();
      this.f.MeetingEndTime.setValidators([]);
      this.f.MeetingEndTime.updateValueAndValidity();

      this.f.DueDate.setValidators([]);
      this.f.DueDate.updateValueAndValidity();

      this.f.DueStartDate.setValidators([]);
      this.f.DueStartDate.updateValueAndValidity();

      if (this.f.recurBy.value == 'byDay' && this.f.ReoPattern.value == 'Monthly') {
        this.f.ReoWeekDays.setValidators([Validators.required]);
        this.f.ReoWeekDays.updateValueAndValidity();

        this.f.TreatmentRecurrenceWeek.setValidators([Validators.required]);
        this.f.TreatmentRecurrenceWeek.updateValueAndValidity();

      }
      else {


        this.f.TreatmentRecurrenceWeek.setValidators([]);
        this.f.TreatmentRecurrenceWeek.updateValueAndValidity();

        if (this.f.ReoPattern.value != 'Weekly') {
          this.f.ReoWeekDays.setValidators([]);
          this.f.ReoWeekDays.updateValueAndValidity();
        }
      }

      this.f.ReoStartDate.setValidators([Validators.required]);
      this.f.ReoStartDate.updateValueAndValidity();

      this.f.ReoPattern.setValidators([Validators.required]);
      this.f.ReoPattern.updateValueAndValidity();

      this.f.ReoEvery.setValidators([
        Validators.required,
        Validators.pattern(Globals.NumberRegex)
      ]);
      this.f.ReoEvery.updateValueAndValidity();

      if (this.f.ReoEndOption.value == 'date' && this.f.taskid.value == 0) {

        // this.f.ReoEndDate.setValidators([Validators.required]);
        this.f.ReoEndDate.setValidators([
          CommonCustomValidators.CheckDateTimeGreaterNotEqualValidation(
            this.f.ReoStartDate, this.f.ReoEndDate
          ), Validators.required
        ]);
        this.f.ReoEndDate.updateValueAndValidity();

        this.f.ReoOccurance.setValidators([]);
        this.f.ReoOccurance.updateValueAndValidity();

      }
      else if (this.f.ReoEndOption.value == 'occurance') {
        this.f.ReoOccurance.setValidators([
          Validators.required,
          Validators.pattern(Globals.NumberRegex)
        ]);
        this.f.ReoOccurance.updateValueAndValidity();

        this.f.ReoEndDate.setValidators([]);
        this.f.ReoEndDate.updateValueAndValidity();
      }
      else if (this.f.ReoEndOption.value == 'no') {
        this.f.ReoOccurance.setValidators([]);
        this.f.ReoOccurance.updateValueAndValidity();
        this.f.ReoEndDate.setValidators([]);
        this.f.ReoEndDate.updateValueAndValidity();
      }
      else {
        this.f.ReoEndOption.setValidators([Validators.required]);
        this.f.ReoEndOption.updateValueAndValidity();
        this.f.ReoEndDate.setValidators([]);
        this.f.ReoEndDate.updateValueAndValidity();
        this.f.ReoOccurance.setValidators([]);
        this.f.ReoOccurance.updateValueAndValidity();
      }

      if (this.f.ReoPattern.value == 'Weekly') {
        this.f.ReoWeekDays.setValidators([Validators.required]);
        this.f.ReoWeekDays.updateValueAndValidity();
      }
    }
  }
  executeTaskCreateUpdate = function (Source) {
    AppComponent.loading = true;
    console.log(this.f);
    if (Source !== 'novalidate') {
      this.GreaterValueCheck();
    }
    if (this.f.completed.value == 'true' && this.f.AssignUserIds.value.length < 1) {
      AppComponent.addAlert('Please Assign User to Task before completing it', 'error');
      AppComponent.loading = false;
      return;
    }
    this.isValidFormSubmitted = false;
    if (this.TaskDetailForm.status === 'PENDING') {
      setTimeout(() => {
        this.executeTaskCreateUpdate('novalidate');
      }, 300);
      return;
    }

    this.isValidFormSubmitted = false;

    // console.log(this.TaskDetailForm);
    if (this.TaskDetailForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    if (this.taskTypeName === 'Meeting') {
      if (this.f.IsRecurrence.value != 'true') {
        let startDateStr = this.datePipe.transform(this.f.DueStartDate.value.jsdate, 'yyyy-MM-dd');
        startDateStr += ' ' + this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss');
        let endDateStr = this.datePipe.transform(this.f.DueDate.value.jsdate, 'yyyy-MM-dd');
        endDateStr += ' ' + this.datePipe.transform(this.f.MeetingEndTime.value, 'HH:mm:ss');
        if (new Date(endDateStr) < new Date(startDateStr)) {
          AppComponent.addAlert('End date should be greater than Start date.', 'error');
          AppComponent.loading = false;
          return;
        }
      } else {
        if (new Date(this.datePipe.transform(this.f.ReoMeetingEndTime.value, 'yyyy-MM-dd hh:mm')) <
          new Date(this.datePipe.transform(this.f.ReoMeetingStartTime.value, 'yyyy-MM-dd hh:mm'))) {
          AppComponent.addAlert('End date should be greater than Start date.', 'error');
          AppComponent.loading = false;
          return;
        }
      }
    } else {
      if (new Date(this.datePipe.transform(this.f.DueDate.value.jsdate, 'yyyy-MM-dd')) <
        new Date(this.datePipe.transform(this.f.DueStartDate.value.jsdate, 'yyyy-MM-dd'))) {
        AppComponent.addAlert('End date should be greater than Start date.', 'error');
        AppComponent.loading = false;
        return;
      }
    }
    this.isValidFormSubmitted = true;

    setTimeout(() => {
      const taskCreate = this.prepareCreateViewModel();

      // AppComponent.HideLoader();
      // return;
      if (taskCreate.taskid === '0') {
        this.API.CreateTaskDetails(taskCreate).subscribe(
          success => {
            // this.taskCreate = success.dmRepailDetails;
            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      } else {
        this.API.UpdateTaskDetails(taskCreate).subscribe(
          success => {
            // this.taskCreate = success.dmRepailDetails;
            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');
            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            } else {
              this.setModel();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
    });
  };

  prepareCreateViewModel() {
    const taskCreate = <any>{};

    taskCreate.tasktype = this.f.taskType.value;
    const taskType = this.TaskTypeList.filter(
      (item, index) => item.value == this.f.taskType.value
    );
    // console.log('tasktype', taskType, this.TaskTypeList);
    if (taskType.length > 0) {
      taskCreate.tasktitle = taskType[0].text;
    }
    taskCreate.taskid = this.f.taskid.value;
    taskCreate.taskcode = this.tasknumber;
    taskCreate.divisionid = this.f.divisionId.value;

    if (this.f.divisionId.value && this.f.divisionId.value != '0') {
      var division = this.DivisionList.filter(
        (item, index) => item.value == this.f.divisionId.value
      );
      if (division)
        taskCreate.divisionName = division[0].text; var division = this.DivisionList.filter(
          (item, index) => item.value == this.f.divisionId.value
        );
      if (division)
        taskCreate.divisionName = division[0].text;
    } else {
      taskCreate.divisionName = 'All Division';
    }


    taskCreate.taskdescription = this.f.description.value;
    taskCreate.status = this.status;
    taskCreate.isRecurrence = this.f.IsRecurrence.value;
    taskCreate.categoryId = this.f.category.value;
    taskCreate.equipmentId = this.f.itemId.value;
    taskCreate.recurrenceType = this.f.ReoPattern.value;
    taskCreate.recurBy = this.f.recurBy.value;
    taskCreate.TreatmentRecurrenceWeek = this.f.TreatmentRecurrenceWeek.value;
    // taskCreate.startDateRecerrence1 = this.f.ReoStartDate.value;

    // if(this.f.ReoEndDate.value){
    //   taskCreate.endDateRecerrence = Globals.GetAPIDate(
    //     this.f.ReoEndDate.value.jsdate
    //   );
    // }

    if (this.f.IsRecurrence.value == "true") {
      if (this.f.ReoMeetingStartTime.value)
        taskCreate.MeetingStartTime = Globals.GetAPIDate(this.f.ReoMeetingStartTime.value);
      if (this.f.ReoMeetingEndTime.value)
        taskCreate.MeetingEndTime = Globals.GetAPIDate(this.f.ReoMeetingEndTime.value);
    }

    if (taskCreate.taskid > 0) {
      taskCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
      taskCreate.note = this.notes.Note;
    }
    taskCreate.priority = '';
    taskCreate.completed = this.f.completed.value == 'true';
    // taskCreate.treatmentRecurrenceDays = '';
    taskCreate.occurByEveryWeek = this.f.ReoEvery.value;
    // console.log(this.f.ReoWeekDays.value);
    if (
      this.f.ReoWeekDays.value !== null &&
      this.f.ReoWeekDays.value !== undefined &&
      this.f.ReoWeekDays.value !== '' &&
      this.f.IsRecurrence.value == 'true'
    ) {
      if (this.f.recurBy.value == 'byDay' && this.f.ReoPattern.value == 'Monthly') {
        if(taskCreate.taskid > 0)
          taskCreate.treatmentRecurrenceDays = this.f.ReoWeekDays.value.join(',');
        else
          taskCreate.treatmentRecurrenceDays = this.f.ReoWeekDays.value;
      }
      else
        taskCreate.treatmentRecurrenceDays = this.f.ReoWeekDays.value.join(',');
    }
    if (this.f.DueDate.value !== null && this.f.DueDate.value !== undefined) {
      taskCreate.completiondate = this.datePipe.transform(this.f.DueDate.value.jsdate, 'yyyy-MM-dd');
      if (this.taskTypeName == 'Meeting') {
        taskCreate.completiondate += ' ' + this.datePipe.transform(this.f.MeetingEndTime.value, 'HH:mm:ss');

      }
      // taskCreate.completiondate = Globals.GetAPIDate(
      //   this.f.DueDate.value.jsdate
      // );
    }

    if (this.f.DueStartDate.value !== null && this.f.DueStartDate.value !== undefined && this.f.IsRecurrence.value != 'true') {
      taskCreate.startDate = this.datePipe.transform(this.f.DueStartDate.value.jsdate, 'yyyy-MM-dd');
      if (this.taskTypeName == 'Meeting') {

        taskCreate.startDate += ' ' + this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss');
      }
      // taskCreate.startDate = Globals.GetAPIDate(
      //   this.f.DueStartDate.value.jsdate
      // );
    }


    // if (this.f.recurBy.value == 'byDay' && this.f.ReoPattern.value == 'Monthly'
    //     && this.f.ReoStartDate.value !== null && this.f.ReoStartDate.value !== undefined)
    // {
    //   taskCreate.startDateRecerrence1 = Globals.GetAPIDate(
    //     this.f.ReoStartDate.value
    //   );
    // }
    // else
    // {
      if (
        this.f.ReoStartDate.value !== null &&
        this.f.ReoStartDate.value !== undefined
      ) {
        taskCreate.startDateRecerrence1 = Globals.GetAPIDate(
          this.f.ReoStartDate.value.jsdate
        );
        if (this.f.taskid.value == 0) {
          if (this.f.IsRecurrence.value == 'true') {
            taskCreate.completiondate = Globals.GetAPIDate(
              this.f.ReoStartDate.value.jsdate
            );
          }
        } else {
          taskCreate.completiondate = this.datePipe.transform(this.f.DueDate.value.jsdate, 'yyyy-MM-dd');
          taskCreate.startDate = this.datePipe.transform(this.f.DueStartDate.value.jsdate, 'yyyy-MM-dd');
          if (this.taskTypeName == 'Meeting') {
            taskCreate.completiondate += ' ' + this.datePipe.transform(this.f.MeetingEndTime.value, 'HH:mm:ss');
            taskCreate.startDate += ' ' + this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss');
          }
          //+ ' ' + this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss')
          // taskCreate.completiondate = Globals.GetAPIDate(
          //   this.f.DueDate.value.jsdate
          // );
          // taskCreate.startDate = Globals.GetAPIDate(
          //   this.f.DueStartDate.value.jsdate
          // );
        }
      }
    // }
    if (
      this.f.ReoEndOption.value === 'date' &&
      this.f.ReoEndDate.value !== null &&
      this.f.ReoEndDate.value !== undefined
    ) {
      taskCreate.endDateRecerrence = Globals.GetAPIDate(
        this.f.ReoEndDate.value.jsdate
      );
    }

    if (this.f.ReoEndOption.value === 'occurance') {
      taskCreate.endAfterRecerrence = this.f.ReoOccurance.value;
    }

    const usersMapping = [];

    taskCreate.modifiedon = Globals.GetAPIDate(this.today);
    taskCreate.createdon = Globals.GetAPIDate(this.today);
    taskCreate.createdBy = this.currentUserId;
    taskCreate.modifiedBy = this.currentUserId;
    if (taskCreate.taskid == 0 && this.designationName != "Administrator") {
      const taskid = this.f.taskid.value;
      usersMapping.push({
        taskMappingId: 0,
        taskId: taskid,
        taskAssignedTo: taskCreate.createdBy,
        status: '',
        createdOn: this.today,
        createdBy: taskCreate.createdBy,
        readStatus: false
      });
    }
    else if (this.f.AssignUserIds.value.length > 0) {
      const taskid = this.f.taskid.value;
      // console.log(this.f.AssignUserIds.value);
      this.f.AssignUserIds.value.forEach((user) => {
        usersMapping.push({
          taskMappingId: 0,
          taskId: taskid,
          taskAssignedTo: user.value,
          status: '',
          createdOn: this.today,
          createdBy: taskCreate.createdBy,
          readStatus: false
        });
      });
    }
    taskCreate.taskMappingDetails = usersMapping;
    // console.log(taskCreate, Globals.GetAPIDate(this.f.MeetingStartTime.value),
    // this.f.MeetingStartTime,
    // this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss'),
    // this.datePipe.transform(this.f.DueStartDate.value.jsdate, 'yyyy-MM-dd')
    // + ' ' + this.datePipe.transform(this.f.MeetingStartTime.value, 'HH:mm:ss')
    // );
    return taskCreate;
  }
  fillDivisionList() {
    this.API.FillDivision().subscribe(list => {
      list[0].text = "All Division";
      // list[0].value = "";
      this.DivisionList = list;
    });
  }

  recurByChange() {
    this.f.ReoPattern.setValue('');
    this.f.TreatmentRecurrenceWeek.setValue('');
  }

  GetAssignedUser() {
    let userlist = "";
    if (this.f.AssignUserIds.value) {
      this.f.AssignUserIds.value.forEach(function (user) {
        if (userlist == "") {
          userlist = user.text;
        } else {
          userlist += ", " + user.text;
        }
      });
    }
    if (userlist == "") {
      userlist = "Not Assigned";
    }
    return userlist;
  }

  fillTaskTypeList() {
    this.API.FillTaskType().subscribe(list => {
      this.TaskTypeList = list.filter((item) => item.text == 'Meeting' || item.text == 'Other' || item.text == 'H2O Process' || item.text == 'Select');

      if (this.f.taskid.value == 0) {
        this.f.taskType.setValue(list.filter((item) => item.text == 'Other')[0].value);
      }
    }
    );
  }

  fillCategoryList() {
    this.API.FillInventoryCategory().subscribe(
      list => (this.CategoryList = list)
    );
  }

  fillUserList(DivisionId) {
    this.API.GetUsersDDByDivisionID(DivisionId).subscribe(list => {
      //console.log(DivisionId,list);
      this.UserList = list.filter((item, index) => item.value !== '');
    });
  }

  DivisionDropDownChanged() {
    const categoryName = this.CategoryList.filter(
      (item, index) => item.value === this.f.category.value
    );
    if (categoryName.length > 0) {
      this.fillInventoryList(this.f.divisionId.value, categoryName[0].text);
    } else {
      this.fillInventoryList(this.f.divisionId.value, '');
    }
    this.fillUserList(this.f.divisionId.value);
    this.GreaterValueCheck();
    // console.log( this.f.category);
  }

  CategoryDropDownChanged() {
    const categoryName = this.CategoryList.filter(
      (item, index) => item.value === this.f.category.value
    );
    if (categoryName.length > 0) {
      this.fillInventoryList(this.f.divisionId.value, categoryName[0].text);
    } else {
      this.fillInventoryList(this.f.divisionId.value, '');
    }
    this.GreaterValueCheck();
  }

  fillInventoryList(DivisionId, Category) {
    const value = {
      DivisionId: DivisionId,
      CategoryName: Category,
      Type: 'Equipment'
    };
    // console.log(value);
    this.API.GetEquipmentByCategoryDD(value).subscribe(
      list => (this.EquipmentList = list)
    );
  }

  SetPageRights() {
    this.auth.GetPageRights('Tasks/Communication').subscribe(list => {
      // console.log(list);
      if (this.f.taskid.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableUntil: {
      year: this.today.getFullYear(),
      month: this.today.getMonth() + 1,
      day: this.today.getDate()
    }
  };

  AcceptTaskClick() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to accept the current task?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const assignedUsers = [];
        // console.log(success);

        assignedUsers.push({
          text: this.auth.GetUserName(),
          value: this.currentUserId
        });

        // console.log(assignedUsers);
        this.f.AssignUserIds.setValue(assignedUsers);
        this.executeTaskCreateUpdate('');
      }
    },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

    this.dialogRef = null;

  }

  onDateChanged(event: IMyDateModel): void { }


  onDateChangedStartDate(event: IMyDateModel): void {
    setTimeout(() => {
      this.GetActualMonthlyDate();
    }, 500);
  }

  handleInput(event) {
    const currentTime = Date.now();

    if ((currentTime - this.lastKeyTime) > 3000) {
      if (event.keyCode === 13 || event.charCode === 13) {
        if (AppComponent.loading == false)
          this.executeTaskCreateUpdate('');
      }
    }

    this.lastKeyTime = Date.now();
  }
  onStartDateChanged(event: IMyDateModel): void {
    console.log(event);
    console.log(this.f.DueDate, this.f.DueStartDate);
    this.f.DueDate.setValue(event);


  }

  dueGreaterThanToday() {
    if (this.f && this.f.DueDate.value)
      return new Date(this.f.DueDate.value.jsdate) > new Date(this.today);

    return false;
  }

  displayEditable() {
    return (this.designationName == 'Administrator' && ((this.OldCompletedStatus != 'true') && this.dueGreaterThanToday())) || this.f.taskid.value == 0;
  }

  getTodayLastTime() {
    let tod = new Date(this.today);
    tod.setHours(23, 59, 59, 0);
    return tod;
  }

  getToday() {
    let tod = new Date(this.today);
    tod.setHours(0, 0, 0, 0);
    return tod;
  }

}

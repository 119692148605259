
<form [formGroup]="AcidLevelFormGroup" novalidate>
    <div class="col-sm-4"  style="padding-top:10px;" *ngFor="let item of AcidLevelData">
        <label class="col-sm-8 control-label">
            {{item.optiondisplayas}}:</label>
        <div class="col-sm-4" *ngIf="item.readonly==true">
            <label class="readOnlyText"> {{item.result}}</label>
        </div>
        <div class="col-sm-4"  *ngIf="item.readonly==false">
            <input  maxlength="10" formControlName="{{item.controlname}}"
            name="{{'AcidLevels.' + item.controlname}}" required class="form-control smInput" placeholder="###">
            <span *ngIf="AcidLevelFormGroup.controls[item.controlname].errors?.required && (AcidLevelFormGroup.controls[item.controlname].dirty || AcidLevelFormGroup.controls[item.controlname].touched  || isValidFormSubmitted==false)" [ngClass]="'error'">
                Required! </span>
                    <span *ngIf="AcidLevelFormGroup.controls[item.controlname].errors?.pattern"
                        [ngClass]="'error'">Invalid number</span>


        </div>
    </div>
</form>

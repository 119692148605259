<!-- <h3 class="text-center">Initial Site Visit</h3> -->
<div [formGroup]="ISVForm" novalidate *ngIf="ISVForm"  style="border:1px solid lightgray;padding: 10px">
  <div class="clearfix"></div>
  <div class="box-row col-sm-12">
    <div class="row col-sm-4">
      <p class="InnerPurposeHFSHeader">BUILDING INFORMATION:</p>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="box-row marginBottom clearfix">

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Type:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIType" name="BIType"
        [style.color]="getColor(insvf.BIType.value,BITypeList)">
          <option value="{{item.value}}" *ngFor="let item of BITypeList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIType.errors?.required && (insvf.BIType.dirty || insvf.BIType.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIType.value,BITypeList)">{{insvf.BIType.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Ownership:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIOwnership" name="BIOwnership"
        [style.color]="getColor(insvf.BIOwnership.value,BIOwnershipList)">
          <option value="{{item.value}}" *ngFor="let item of BIOwnershipList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIOwnership.errors?.required && (insvf.BIOwnership.dirty || insvf.BIOwnership.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>

      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIOwnership.value,BIOwnershipList)">{{insvf.BIOwnership.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Entrance:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIEntrance" name="BIEntrance"
        [style.color]="getColor(insvf.BIEntrance.value,BIEntranceList)">
          <option value="{{item.value}}" *ngFor="let item of BIEntranceList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIEntrance.errors?.required && (insvf.BIEntrance.dirty || insvf.BIEntrance.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIEntrance.value,BIEntranceList)">{{insvf.BIEntrance.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Parking:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIParking" name="BIParking"
        [style.color]="getColor(insvf.BIParking.value,BIParkingList)">
          <option value="{{item.value}}" *ngFor="let item of BIParkingList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIParking.errors?.required && (insvf.BIParking.dirty || insvf.BIParking.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIParking.value,BIParkingList)">{{insvf.BIParking.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Accessibility:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIAccessibility" name="BIAccessibility" [style.color]="getColor(insvf.BIAccessibility.value,BIAccessibilityList)">
          <option value="{{item.value}}" *ngFor="let item of BIAccessibilityList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIAccessibility.errors?.required && (insvf.BIAccessibility.dirty || insvf.BIAccessibility.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIAccessibility.value,BIAccessibilityList)">{{insvf.BIAccessibility.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Stairs:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="BIStairs" name="BIStairs" [style.color]="getColor(insvf.BIStairs.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.BIStairs.errors?.required && (insvf.BIStairs.dirty || insvf.BIStairs.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.BIStairs.value,YesNoList)">{{insvf.BIStairs.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-12" >
      <label class="control-label col-sm-2" style="width: 15.8%;">Notes:</label>
      <div class="col-sm-10" style="width: 84.2%;" *ngIf="IsPurposeCompleted != 'Yes'">
        <textarea autosize class="form-textarea" formControlName="BINotes" name="BINotes"></textarea>
        <span
        *ngIf="insvf.BINotes.errors?.required && (insvf.BINotes.dirty || insvf.BINotes.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-10" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.BINotes.value}}</label>
      </div>
    </div>

  </div>

  <div class="clearfix"></div>
  <div class="box-row col-sm-12">
    <div class="row col-sm-4">
        <p class="InnerPurposeHFSHeader">DIALYSIS ROOM INFORMATION:</p>
      </div>
  </div>
  <div class="clearfix"></div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4" >
      <label class="control-label col-sm-6">Room Location/Floor:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRILocation" name="DRILocation"
        [style.color]="getColor(insvf.DRILocation.value,DRILocationList)">
          <option value="{{item.value}}" *ngFor="let item of DRILocationList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.DRILocation.errors?.required && (insvf.DRILocation.dirty || insvf.DRILocation.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRILocation.value,DRILocationList)">{{insvf.DRILocation.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Dimensions:</label>
      <div class="col-sm-3" style="width:24%" *ngIf="IsPurposeCompleted != 'Yes'">
          <input class="form-control mdInput" formControlName="DRIDimensionsL" name="DRIDimensionsL" placeholder="ft"
          (keypress)="numberOnly($event)" (blur)="transformftL()"
          (focusout)="transformftL()">
        <!-- <select class="form-control mdInput" formControlName="DRIDimensionsL" name="DRIDimensionsL">
          <option value="{{item.value}}" *ngFor="let item of DRIDimensionsLList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select> -->
        <span
        *ngIf="insvf.DRIDimensionsL.errors?.required && (insvf.DRIDimensionsL.dirty || insvf.DRIDimensionsL.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-3" style="width:24%" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.DRIDimensionsL.value}}</label>
      </div>
      <div  class="col-sm-1" style="width:2%;padding:0;margin-top: 2%;"><b>X</b></div>
      <div class="col-sm-3"  style="width:24%" *ngIf="IsPurposeCompleted != 'Yes'">
          <input class="form-control mdInput" formControlName="DRIDimensionsW" name="DRIDimensionsW" placeholder="ft"
          (keypress)="numberOnly($event)" (blur)="transformftW()" (focusout)="transformftW()">
          <span
          *ngIf="insvf.DRIDimensionsW.errors?.required && (insvf.DRIDimensionsW.dirty || insvf.DRIDimensionsW.touched ||  formSubmitted)"
          [ngClass]="'error'">Required!</span>
          <!--
        <select class="form-control mdInput" formControlName="DRIDimensionsW" name="DRIDimensionsW">
          <option value="{{item.value}}" *ngFor="let item of DRIDimensionsWList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select> -->
      </div>
      <div class="col-sm-3" style="width:24%" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.DRIDimensionsW.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Room Type:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRIRoomType" name="DRIRoomType"
        [style.color]="getColor(insvf.DRIRoomType.value,DRIRoomTypeList)">
          <option value="{{item.value}}" *ngFor="let item of DRIRoomTypeList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
          *ngIf="insvf.DRIRoomType.errors?.required && (insvf.DRIRoomType.dirty || insvf.DRIRoomType.touched ||  formSubmitted)"
          [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRIRoomType.value,DRIRoomTypeList)">{{insvf.DRIRoomType.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Lighting:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRILighting" name="DRILighting"
        [style.color]="getColor(insvf.DRILighting.value,DRILightingList)">
          <option value="{{item.value}}" *ngFor="let item of DRILightingList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.DRILighting.errors?.required && (insvf.DRILighting.dirty || insvf.DRILighting.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRILighting.value,DRILightingList)">{{insvf.DRILighting.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Cleanliness:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRICleanliness" name="DRICleanliness"
        [style.color]="getColor(insvf.DRICleanliness.value,DRICleanlinessList)">
          <option value="{{item.value}}" *ngFor="let item of DRICleanlinessList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.DRICleanliness.errors?.required && (insvf.DRICleanliness.dirty || insvf.DRICleanliness.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRICleanliness.value,DRICleanlinessList)">{{insvf.DRICleanliness.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Flooring Type:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRIFlooringType" name="DRIFlooringType"
        [style.color]="getColor(insvf.DRIFlooringType.value,DRIFlooringTypeList)">
          <option value="{{item.value}}" *ngFor="let item of DRIFlooringTypeList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.DRIFlooringType.errors?.required && (insvf.DRIFlooringType.dirty || insvf.DRIFlooringType.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRIFlooringType.value,DRIFlooringTypeList)">{{insvf.DRIFlooringType.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Space for Storage:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="DRISpaceStorage" name="DRISpaceStorage"
        [style.color]="getColor(insvf.DRISpaceStorage.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.DRISpaceStorage.errors?.required && (insvf.DRISpaceStorage.dirty || insvf.DRISpaceStorage.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.DRISpaceStorage.value,YesNoList)">{{insvf.DRISpaceStorage.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-12">
      <label class="control-label col-sm-2" style="width: 15.8%;" >Notes:</label>
      <div class="col-sm-10" style="width: 84.2%;" *ngIf="IsPurposeCompleted != 'Yes'">
        <textarea autosize class="form-textarea" formControlName="DRINotes" name="DRINotes"></textarea>
        <span
        *ngIf="insvf.DRINotes.errors?.required && (insvf.DRINotes.dirty || insvf.DRINotes.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.DRINotes.value}}</label>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
  <div class="box-row col-sm-12">
    <div class="row col-sm-4">
      <p class="InnerPurposeHFSHeader">ELECTRICAL:</p>
    </div>
  </div>
  <div class="clearfix"></div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6" >Panel Location:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="ELPanelLocation" name="ELPanelLocation" [style.color]="getColor(insvf.ELPanelLocation.value,ELPanelLocationList)">
          <option value="{{item.value}}" *ngFor="let item of ELPanelLocationList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.ELPanelLocation.errors?.required && (insvf.ELPanelLocation.dirty || insvf.ELPanelLocation.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.ELPanelLocation.value,ELPanelLocationList)">{{insvf.ELPanelLocation.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Panel Type and Size:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="ELPanelSize" name="ELPanelSize"
        [style.color]="getColor(insvf.ELPanelSize.value,ELPanelSizeList)">
          <option value="{{item.value}}" *ngFor="let item of ELPanelSizeList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.ELPanelSize.errors?.required && (insvf.ELPanelSize.dirty || insvf.ELPanelSize.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.ELPanelSize.value,ELPanelSizeList)">{{insvf.ELPanelSize.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Extra slots Available:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="ELExtraSlotsAvailable" name="ELExtraSlotsAvailable" [style.color]="getColor(insvf.ELExtraSlotsAvailable.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.ELExtraSlotsAvailable.errors?.required && (insvf.ELExtraSlotsAvailable.dirty || insvf.ELExtraSlotsAvailable.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.ELExtraSlotsAvailable.value,YesNoList)">{{insvf.ELExtraSlotsAvailable.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Hospital Grade GFI:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="ELGFI" name="ELGFI"
        [style.color]="getColor(insvf.ELGFI.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.ELGFI.errors?.required && (insvf.ELGFI.dirty || insvf.ELGFI.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.ELGFI.value,YesNoList)">{{insvf.ELGFI.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Subpanel Needed:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'" >
        <select class="form-control mdInput" formControlName="ELSubpanelNeeded" name="ELSubpanelNeeded" [style.color]="getColor(insvf.ELSubpanelNeeded.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.ELSubpanelNeeded.errors?.required && (insvf.ELSubpanelNeeded.dirty || insvf.ELSubpanelNeeded.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.ELSubpanelNeeded.value,YesNoList)">{{insvf.ELSubpanelNeeded.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <!-- <label class="control-label col-sm-6">Code Issues:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="ELCodeIssues" name="ELCodeIssues">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.ELCodeIssues.value}}</label>
      </div> -->
    </div>
  </div>


  <div class="box-row marginBottom clearfix">
      <div class="col-sm-6">
        <label class="control-label col-sm-6">Electrician required for evaluation?:</label>
        <div class="col-sm-4"  *ngIf="IsPurposeCompleted != 'Yes'">
          <select class="form-control mdInput" formControlName="ElectricianRequiredForEvaluation" name="ElectricianRequiredForEvaluation"
          [style.color]="getColor(insvf.ElectricianRequiredForEvaluation.value,YesNoList)">
            <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
              {{item.text}}
            </option>
          </select>
          <span
        *ngIf="insvf.ElectricianRequiredForEvaluation.errors?.required && (insvf.ElectricianRequiredForEvaluation.dirty || insvf.ElectricianRequiredForEvaluation.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
        </div>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" [style.color]="getColor(insvf.ElectricianRequiredForEvaluation.value,YesNoList)">{{insvf.ElectricianRequiredForEvaluation.value}}</label>
        </div>
      </div>

      <div class="col-sm-4" style="display: none;">
        <label class="control-label col-sm-5">PO Electrician:</label>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" formControlName="POElectrician" name="POElectrician">
        </div>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText">{{insvf.POElectrician.value}}</label>
        </div>
      </div>
    </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-12">
      <label class="control-label col-sm-2" style="width: 15.8%;">Notes:</label>
      <div class="col-sm-10" style="width: 84.2%;" *ngIf="IsPurposeCompleted != 'Yes'">
        <textarea autosize class="form-textarea" formControlName="ELNotes" name="ELNotes"></textarea>
        <span
        *ngIf="insvf.ELNotes.errors?.required && (insvf.ELNotes.dirty || insvf.ELNotes.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.ELNotes.value}}</label>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
  <div class="box-row col-sm-12">
    <div class="row col-sm-4">
      <p class="InnerPurposeHFSHeader">PLUMBING:</p>
      </div>
  </div>
  <div class="clearfix"></div>


  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Water Supply:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBWaterSupply" name="PBWaterSupply"
        [style.color]="getColor(insvf.PBWaterSupply.value,PBWaterSupplyList)">
          <option value="{{item.value}}" *ngFor="let item of PBWaterSupplyList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBWaterSupply.errors?.required && (insvf.PBWaterSupply.dirty || insvf.PBWaterSupply.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBWaterSupply.value,PBWaterSupplyList)">{{insvf.PBWaterSupply.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Water Connection:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBWaterConnection" name="PBWaterConnection"
        [style.color]="getColor(insvf.PBWaterConnection.value,PBWaterConnectionList)">
          <option value="{{item.value}}" *ngFor="let item of PBWaterConnectionList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBWaterConnection.errors?.required && (insvf.PBWaterConnection.dirty || insvf.PBWaterConnection.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBWaterConnection.value,PBWaterConnectionList)">{{insvf.PBWaterConnection.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Drain Runs Into:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBDrainRunsInto" name="PBDrainRunsInto"
        [style.color]="getColor(insvf.PBDrainRunsInto.value,PBDrainRunsIntoList)">
          <option value="{{item.value}}" *ngFor="let item of PBDrainRunsIntoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBDrainRunsInto.errors?.required && (insvf.PBDrainRunsInto.dirty || insvf.PBDrainRunsInto.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBDrainRunsInto.value,PBDrainRunsIntoList)">{{insvf.PBDrainRunsInto.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Softener Onsite:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBSoftenerOnsite" name="PBSoftenerOnsite"
        [style.color]="getColor(insvf.PBSoftenerOnsite.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBSoftenerOnsite.errors?.required && (insvf.PBSoftenerOnsite.dirty || insvf.PBSoftenerOnsite.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBSoftenerOnsite.value,YesNoList)">{{insvf.PBSoftenerOnsite.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Hardness (gpg):</label>
      <div class="col-sm-6"  *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBHardness" name="PBHardness"
        [style.color]="getColor(insvf.PBHardness.value,PBHardnessList)">
          <option value="{{item.value}}" *ngFor="let item of PBHardnessList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBHardness.errors?.required && (insvf.PBHardness.dirty || insvf.PBHardness.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBHardness.value,PBHardnessList)">{{insvf.PBHardness.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">PH:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBPH" name="PBPH"
        [style.color]="getColor(insvf.PBPH.value,PBPHList)">
          <option value="{{item.value}}" *ngFor="let item of PBPHList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBPH.errors?.required && (insvf.PBPH.dirty || insvf.PBPH.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBPH.value,PBPHList)">{{insvf.PBPH.value}}</label>
      </div>
    </div>
  </div>

  <div class="box-row marginBottom clearfix">
    <div class="col-sm-4">
      <label class="control-label col-sm-6">Chlorine (mg/L):</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBChlorine" name="PBChlorine"
        [style.color]="getColor(insvf.PBChlorine.value,PBChlorineList)">
          <option value="{{item.value}}" *ngFor="let item of PBChlorineList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBChlorine.errors?.required && (insvf.PBChlorine.dirty || insvf.PBChlorine.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBChlorine.value,PBChlorineList)">{{insvf.PBChlorine.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Water Pressure:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBWaterPressure" name="PBWaterPressure"
        [style.color]="getColor(insvf.PBWaterPressure.value,DRICleanlinessList)">
          <option value="{{item.value}}" *ngFor="let item of DRICleanlinessList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBWaterPressure.errors?.required && (insvf.PBWaterPressure.dirty || insvf.PBWaterPressure.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBWaterPressure.value,DRICleanlinessList)">{{insvf.PBWaterPressure.value}}</label>
      </div>
    </div>

    <div class="col-sm-4">
      <label class="control-label col-sm-6">Iron Filter?:</label>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
        <select class="form-control mdInput" formControlName="PBIronFilter" name="PBIronFilter"
        [style.color]="getColor(insvf.PBIronFilter.value,YesNoList)">
          <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
            {{item.text}}
          </option>
        </select>
        <span
        *ngIf="insvf.PBIronFilter.errors?.required && (insvf.PBIronFilter.dirty || insvf.PBIronFilter.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText" [style.color]="getColor(insvf.PBIronFilter.value,YesNoList)">{{insvf.PBIronFilter.value}}</label>
      </div>
    </div>
  </div>


  <div class="box-row marginBottom clearfix">
      <div class="col-sm-6">
        <label class="control-label col-sm-6">Plumber required for evaluation?:</label>
        <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'"
        [style.color]="getColor(insvf.PlumberRequiredForEvaluation.value,YesNoList)">
          <select class="form-control mdInput" formControlName="PlumberRequiredForEvaluation" name="PlumberRequiredForEvaluation">
            <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
              {{item.text}}
            </option>
          </select>
          <span
        *ngIf="insvf.PlumberRequiredForEvaluation.errors?.required && (insvf.PlumberRequiredForEvaluation.dirty || insvf.PlumberRequiredForEvaluation.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
        </div>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" [style.color]="getColor(insvf.PlumberRequiredForEvaluation.value,YesNoList)">{{insvf.PlumberRequiredForEvaluation.value}}</label>
        </div>
      </div>

      <div class="col-sm-4" style="display: none;">
        <label class="control-label col-sm-5">PO Plumber:</label>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" formControlName="POPlumber" name="POPlumber">
        </div>
        <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText">{{insvf.POPlumber.value}}</label>
        </div>
      </div>
    </div>


  <div class="box-row marginBottom clearfix">
    <div class="col-sm-12">
      <label class="control-label col-sm-2" style="width: 15.8%;">Notes:</label>
      <div class="col-sm-10" style="width: 84.2%;"  *ngIf="IsPurposeCompleted != 'Yes'">
        <textarea autosize class="form-textarea" formControlName="PBNotes" name="PBNotes"></textarea>
        <span
        *ngIf="insvf.PBNotes.errors?.required && (insvf.PBNotes.dirty || insvf.PBNotes.touched ||  formSubmitted)"
        [ngClass]="'error'">Required!</span>
      </div>
      <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
          <label class="readOnlyText">{{insvf.PBNotes.value}}</label>
      </div>
    </div>
  </div>


  <div class="clearfix"></div>
  <div class="box-row col-sm-12">
    <div class="row col-sm-4">
      <p class="InnerPurposeHFSHeader">MOVERS:</p>
    </div>
  </div>
  <div class="clearfix"></div>





    <div class="box-row marginBottom clearfix">
        <div class="col-sm-6" style="display: none;">
          <label class="control-label col-sm-8">SGS Feed Water Comprehensive Test Taken?:</label>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <select class="form-control mdInput" formControlName="SGSFeed" name="SGSFeed"
            [style.color]="getColor(insvf.SGSFeed.value,YesNoList)">
              <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
                {{item.text}}
              </option>
            </select>
          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
              <label class="readOnlyText" [style.color]="getColor(insvf.SGSFeed.value,YesNoList)">{{insvf.SGSFeed.value}}</label>
          </div>
        </div>


      </div>
      <div class="box-row marginBottom clearfix">
          <div class="col-sm-6">
            <label class="control-label col-sm-8">Movers will be required for installation day?:</label>
            <div class="col-sm-4"  *ngIf="IsPurposeCompleted != 'Yes'">
              <select class="form-control mdInput" formControlName="MoversRequiredForInstallation" name="MoversRequiredForInstallation"
              [style.color]="getColor(insvf.MoversRequiredForInstallation.value,YesNoList)">
                <option value="{{item.value}}" *ngFor="let item of YesNoList" [style.color]="item.color ? item.color : textColor">
                  {{item.text}}
                </option>
              </select>
              <span
              *ngIf="insvf.MoversRequiredForInstallation.errors?.required && (insvf.MoversRequiredForInstallation.dirty || insvf.MoversRequiredForInstallation.touched ||  formSubmitted)"
              [ngClass]="'error'">Required!</span>
            </div>
            <div class="col-sm-4" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText" [style.color]="getColor(insvf.MoversRequiredForInstallation.value,YesNoList)">{{insvf.MoversRequiredForInstallation.value}}</label>
            </div>
          </div>

          <div class="col-sm-6" style="display: none;">
            <label class="control-label col-sm-6">PO Movers:</label>
            <div class="col-sm-6" *ngIf="IsPurposeCompleted != 'Yes'">
                <input class="form-control mdInput" formControlName="POMovers" name="POMovers">
            </div>
            <div class="col-sm-6" *ngIf="IsPurposeCompleted == 'Yes'">
                <label class="readOnlyText">{{insvf.POMovers.value}}</label>
            </div>
          </div>
        </div>
<!--
  <div class="col-sm-12">
    <notes #notes>
    </notes>

    <attachment (RaiseError)="AttachmentError($event)" #attachment>

    </attachment>
  </div> -->

  <!-- <p>* after evaluation scan plumber pink work order</p>
<p>* email dave such po # - completed</p>
<p>* write nephrocare entry,</p> -->

<div class="box-row marginBottom clearfix">
  <div class="col-sm-12" >
    <label class="control-label col-sm-2" style="width: 15.8%;">Notes:</label>
    <div class="col-sm-10" style="width: 84.2%;" *ngIf="IsPurposeCompleted != 'Yes'">
      <textarea autosize class="form-textarea" formControlName="POMoversNotes" name="POMoversNotes"></textarea>
      <span
      *ngIf="insvf.POMoversNotes.errors?.required && (insvf.POMoversNotes.dirty || insvf.POMoversNotes.touched ||  formSubmitted)"
      [ngClass]="'error'">Required!</span>
    </div>
    <div class="col-sm-10" *ngIf="IsPurposeCompleted == 'Yes'">
        <label class="readOnlyText">{{insvf.POMoversNotes.value}}</label>
    </div>
  </div>

</div>
</div>

import { Injectable } from '@angular/core';

@Injectable()
export class TableHeadsService {

    dataGrid = {tableHeaders: [], sortExpression: '', sortDirection: '', sort: ''};

    addHeader(label, expression, width) {

        const tableHeader = {Label: label, SortExpression: expression, Width: width };
        this.dataGrid.tableHeaders.push(tableHeader);

    }

	addHeaderWithStyleClass(label, expression, width, transform) {
        const tableHeader = {Label: label, SortExpression: expression, Width: width, Transform : transform};
        this.dataGrid.tableHeaders.push(tableHeader);
    }
    clearHeader() {


        this.dataGrid.tableHeaders.splice(0, this.dataGrid.tableHeaders.length);

    }
    setTableHeaders() {
        return this.dataGrid.tableHeaders;
    }

    changeSorting(columnSelected, currentSort, tableHeaders) {

        this.dataGrid = {tableHeaders: [], sortExpression: '', sortDirection: '', sort: ''};

        this.dataGrid.sortExpression = '';
        const sort = currentSort;
        // tslint:disable-next-line:triple-equals
        if (sort.column == columnSelected) {
            sort.descending = !sort.descending;
        } else {
            sort.column = columnSelected;
            sort.descending = false;
        }

        for (let i = 0; i < tableHeaders.length; i++) {
           // console.log(sort.column == tableHeaders[i].Label);
            // tslint:disable-next-line:triple-equals
            if (sort.column == tableHeaders[i].Label) {
                this.dataGrid.sortExpression = tableHeaders[i].SortExpression;
                sort.sortExpression = tableHeaders[i].SortExpression;
                break;
            }
        }

        if (sort.descending === true) {
            this.dataGrid.sortDirection = 'DESC';
        } else {
            this.dataGrid.sortDirection = 'ASC';
        }
        this.dataGrid.sort = sort;

    }

    getSort() {
        return this.dataGrid.sort;
    }

    getSortDirection() {
        return this.dataGrid.sortDirection;
    }

    getSortExpression = function () {
        return this.dataGrid.sortExpression;
    };

    setDefaultSort(defaultSort, isdescending) {
        const sort = {
            column: defaultSort,
            descending: isdescending
        };
        return sort;
    }

    setSortIndicator(column, defaultSort) {
        // tslint:disable-next-line:triple-equals
        return column == defaultSort.column && 'sort-' + defaultSort.descending;
    }


}

<style>
.mat-tooltip
{
  max-width:100% !important;
}

.tooltip-dark{
  /* background:#3a4dab !important; */
  font-size: 14px;
    color:#fff;
    font-weight: 500;
    background-color: #124c8d !important;
    hidedelay:1;
}
</style>
<div [formGroup]="prepSectionForm" novalidate>
<div class="modal-header  col-sm-12" >
  <h4 class="modal-title col-sm-8">Prep Details</h4>
  <div class="text-right  col-sm-4" style="padding:0">
    <!-- <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Close</button> -->
    <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>
    <!-- <button type="button" class="btn btn-primary"  (click)="save()">Save</button> -->
  </div>
</div>

  <div class="modal-body">
    <div class="box-row col-sm-10 col-sm-offset-2">
        <span [ngClass]="'error'" *ngIf="errormsg" >
          {{errormsg}}
        </span>
      </div>
    <div class="col-sm-12 box-row">
        <label class="col-sm-5 control-label">Date:</label>
        <div class="col-sm-7">
          <label class="readOnlyText">{{prepf.PrepDate.value | date:'dd/MM/yyyy'}}</label>
        </div>
    </div>

    <div class="col-sm-12 box-row">
        <label class="col-sm-5 control-label">Technologist:</label>
        <div class="col-sm-7">
          <label class="readOnlyText"> {{prepf.CreatedByName.value}}</label>
        </div>
    </div>

    <div class="col-sm-12 box-row">
        <label class="col-sm-5 control-label">Prep Time (Hr.):</label>
        <div class="input-group col-sm-5" style="padding-left: 15px; padding-right:15px;float: inherit;">
            <!-- <select class="form-control mdInput" formControlName="PrepTime" name="PrepTime">
                <option value="{{item.value}}" *ngFor="let item of PrepTimeList">
                  {{item.text}}
                </option>
            </select> -->

            <span class="input-group-btn">
                <button type="button" class="quantity-left-minus btn btn-danger btn-number" (click)="MinusHours()"   data-type="minus" data-field="">
                  <span class="glyphicon glyphicon-minus"></span>
                </button>
            </span>
            <input style="display: inline;text-align: center" class="form-control" formControlName="PrepTime"
                maxlength="3" (keypress)="numberOnly($event)" readonly="readonly"
                [ngClass]="{'ng-touched ng-invalid':(prepf.PrepTime.errors?.min || prepf.PrepTime.errors?.required) && (prepf.PrepTime.dirty || prepf.PrepTime.touched ||  isValidFormSubmitted)}" />
            <span class="input-group-btn">
                <button type="button" class="quantity-right-plus btn btn-success btn-number" (click)="PlusHours()" data-type="plus" data-field="">
                    <span class="glyphicon glyphicon-plus"></span>
                </button>
            </span>
        </div>

        <div class="col-sm-1">
            <span title=" Initial Site Visit : 1.5 hr/Technologist
Equipment Exchange : 1 hr/Technologist
Maintenance & Testing : 1 hr/Technologist
Repair : 0.5 hr/Technologist
Other : 0.5 hr/Technologist
Renovation & Full Installation : 4 hrs/Technologist
Uninstallation : 3 hrs/Technologist" style="cursor:pointer"><i class="fa fa-info-circle" style="color: black;margin-top: 8px;cursor: pointer;font-size: 20px"></i>
            </span>
        </div>
        <!-- <div class="col-sm-5" *ngIf="(prepf.PrepTime.errors?.min || prepf.PrepTime.errors?.requried) && (prepf.PrepTime.dirty || prepf.PrepTime.touched ||  isValidFormSubmitted)"></div>
        <span
            *ngIf="(prepf.PrepTime.errors?.min || prepf.PrepTime.errors?.requried) && (prepf.PrepTime.dirty || prepf.PrepTime.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'">
            Required
          </span> -->
    </div>

    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Add Technologist(s):</label>
      <div class="col-sm-7">
        <ng-multiselect-dropdown
        [placeholder]="'Add More Technologist(s)'" [data]="TechnologistsList"
        [settings]="dropdownSettings" formControlName="PrepTechnologists" name="PrepTechnologists">
      </ng-multiselect-dropdown>
      </div>
    </div>

    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Note:</label>
      <div class="col-sm-7">
          <textarea name="note" formControlName="PrepNotes" name="PrepNotes" class="form-textarea" autosize
          style="min-height: 75px;"
          [ngClass]="{'ng-touched ng-invalid': prepf.PrepNotes.errors?.required && (prepf.PrepNotes.dirty || prepf.PrepNotes.touched ||  isValidFormSubmitted)}"
          [placeholder]="prepf.PrepNotes.errors?.required && (prepf.PrepNotes.dirty || prepf.PrepNotes.touched ||  isValidFormSubmitted) ? 'Required' : ''"></textarea>
          <!-- <span *ngIf="prepf.PrepNotes.errors?.required && (prepf.PrepNotes.dirty || prepf.PrepNotes.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'">
          Required!
        </span> -->
      </div>
    </div>

    <div *ngIf="ShowPODiv">
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label"><span class="text-red inputrequired" *ngIf="IsPlumberReq" >*</span>PO Plumber:</label>
      <div class="col-sm-4">
          <input class="form-control mdInput"  maxlength="10"
              formControlName="poPlumber" name="poPlumber"
              [ngClass]="{'ng-touched ng-invalid': prepf.poPlumber.errors?.required && (prepf.poPlumber.dirty || prepf.poPlumber.touched || isValidFormSubmitted)}"
          [placeholder]="prepf.poPlumber.errors?.required && (prepf.poPlumber.dirty || prepf.poPlumber.touched || isValidFormSubmitted) ? 'Required' : ''">
      </div>
    </div>
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">PO Plumber Note:</label>
      <div class="col-sm-7">
        <input class="form-control mdInput" maxlength="20"  formControlName="poPlumberNote" name="poPlumberNote">
      </div>
    </div>

    <div class="col-sm-12 box-row">
     <label class="col-sm-5 control-label"><span class="text-red inputrequired" *ngIf="IsElectricianReq" >*</span>PO Electrician:</label>
      <div class="col-sm-4">
          <input class="form-control mdInput"  maxlength="10"
              formControlName="poElectrician" name="poElectrician"
              [ngClass]="{'ng-touched ng-invalid': prepf.poElectrician.errors?.required && (prepf.poElectrician.dirty || prepf.poElectrician.touched || isValidFormSubmitted)}"
          [placeholder]="prepf.poElectrician.errors?.required && (prepf.poElectrician.dirty || prepf.poElectrician.touched || isValidFormSubmitted) ? 'Required' : ''">
      </div>
    </div>
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">PO Electrician Notes:</label>
      <div class="col-sm-7">
          <input class="form-control mdInput" maxlength="20" formControlName="poElectricianNote" name="poElectricianNote">
      </div>
  </div>

  <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label"><span class="text-red inputrequired" *ngIf="IsMoversReq" >*</span>PO Movers:</label>
      <div class="col-sm-4">
          <input class="form-control mdInput"  maxlength="10"
              formControlName="poMovers" name="poMovers"
              [ngClass]="{'ng-touched ng-invalid': prepf.poMovers.errors?.required && (prepf.poMovers.dirty || prepf.poMovers.touched || isValidFormSubmitted)}"
              [placeholder]="prepf.poMovers.errors?.required && (prepf.poMovers.dirty || prepf.poMovers.touched || isValidFormSubmitted) ? 'Required' : ''">
      </div>
  </div>
  <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">PO Movers Notes:</label>
      <div class="col-sm-7">
        <input class="form-control mdInput" maxlength="20" formControlName="poMoversNote" name="poMoversNote">
      </div>
  </div>
  </div>

  <div class="box-row col-sm-12" *ngIf="PrepDetailsList.length > 0 && displayList" style="    margin-top: 10%;">
    <table style="width:100%" align="center" class="table InnerTable table-responsive">
      <tr>
        <th class="InnerTableHead" style="width:10%">
          Date
        </th>
        <th class="InnerTableHead"  style="width:10%">
          Prep Time(Hr.)
        </th>
        <th class="InnerTableHead" style="width:15%">
          Technologist(s)
        </th>
        <th class="InnerTableHead"  style="width:65%">
          Notes
        </th>

      </tr>
      <tbody *ngFor="let item of PrepDetailsList;let index=index;">
        <tr>
          <td>
            {{item.prepDate | date:'dd/MM/yyyy'}}
          </td>
          <td>
            {{item.prepTime}}
          </td>
          <td title="{{item.prepTechnologists}}">
            {{item.createdByName}} <span *ngIf="item.prepTechnologists!=''" >..</span>
          </td>
          <td  class="text-left">
              <div class="break-words">
            {{item.prepNotes}}
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  </div>
</div>

import { AbstractControl, FormArray } from '@angular/forms';
import * as _ from 'underscore';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { Globals } from '../globals';

export function CustomValidationStationGroup(divisionName: string) {
    return (formArray: any) => {
        let isError = false;
        let isRegexError = false;
        var result = _.groupBy(formArray.controls, c => c['controls'].dialysisStationName.value);
        for (let prop in result) {
            if (result[prop].length > 1) {
                result[prop].forEach(function (item, newKey) {
                    if (item['controls'].dialysisStationName.value != '') {
                        isError = true;
                        item['status'] = "INVALID";
                    }
                });
            } else {
                result[prop][0]['status'] = 'VALID';
            }
        }

        formArray.controls.forEach(function (station, newKey) {
            if (station.controls.dialysisStationName.value != '') {
                var regex = RegExp(Globals.StationsRegex);

                if (divisionName == "Home Program")
                    regex = RegExp(Globals.HomeStationsRegex);

                if (!regex.test(station.controls.dialysisStationName.value.toUpperCase())) {
                    station['status'] = "INVALID";
                    isRegexError = true;
                };
            }
        });

        if (isError || isRegexError) {
            if (isError && isRegexError)
                return { 'duplicate': 'Duplicate Stations And Invalid Stations Format' }
            else if (isError)
                return { 'duplicate': 'Duplicate Stations Not Allowed' }
            else
                return { 'duplicate': 'Invalid Station Format' }
        }
    };
}
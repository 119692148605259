import { Component, OnInit, ViewChild } from '@angular/core';
import { CDApiService } from '../../API/cdapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { WaterProcessCustomValidators } from '../../Validator/WaterProcessCustomValidators';
import { ValidateUser } from '../../Validator/ValidateUser';

import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'AcidDetail',
  templateUrl: './AcidDetail.component.html'
})
export class AcidDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  isValidFormSubmitted = null;
  ListURL = 'Concentrates/AcidDrumFilling/AcidDrumSearch';
  status = '';
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDialysisUnitstext;
  selectedUsertext;
  selectedAcidContainerTxt;
  blnshowSave = false;
  blnshowDelete = false;
  ConfirmationUserId = 0;
  eventId = '[AutoNumber]';
  DrumList = [
    { value: '0', text: 'Select' },
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' }
  ];
  VendorList = [];
  AcidContainerList = [];
  VendorName = '';

  AcidForm = new UntypedFormGroup({
    AcidsId: new UntypedFormControl(''),
    AcidDate: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedAcidContainer: new UntypedFormControl('', Validators.required),
    selectedDrumNo: new UntypedFormControl('0', Validators.required),
    LotNumber: new UntypedFormControl('', [Validators.required]),
    selectedVender: new UntypedFormControl('', Validators.required),
    AcidLevelPrior: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(Globals.NumberRegex)
    ]),
    AcidLevelPost: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(Globals.NumberRegex)
    ]),
    UserName: new UntypedFormControl('', [Validators.required]),
    PinID: new UntypedFormControl('', [Validators.required])
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };
  ConfirmUser: any;

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.AcidForm.dirty ||
        this.notes.Note !== '' ||
        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private cdapi: CDApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) {}


  ngOnInit() {

    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.SetPageRights();
    this.notes.showHours = true;
    // attachment changes
    this.attachment.DocId = this.f.AcidsId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.CD_AcidDrum];
    this.attachment.UploadBy = this.currentUserId;

    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.fillVendorList();
      this.fillDialysisUnit();
      this.f.AcidsId.setValue(params['id'] || '0');
      const tempDate = new Date();
      this.f.AcidDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
      if (this.f.AcidsId.value !== '0') {
        this.setModel();
        this.f.UserName.clearValidators();
        this.f.PinID.clearValidators();
      } else {
        this.auth.GetCurrentUserDivision().subscribe(division => {

          this.selectedUsertext = this.auth.GetUserName();
          this.f.PinID.setValidators([Validators.required]);
          this.f.PinID.setAsyncValidators([
            ValidateUser.UserValidate(this.f.UserName, this.auth)
          ]);
          // this.fillEquipment(0);
          this.AcidContainerList = [{ value: '', text: 'Select' }];

          if (division.length === 1) {
            this.f.selectedDialysisUnits.setValue(division[0]);
            this.DialysisUnitDropDownChanged();
          }


          this.f.AcidLevelPost.setValidators(
          [
            Validators.required,
            Validators.pattern(Globals.NumberRegex)
          ]);
          this.f.AcidLevelPost.updateValueAndValidity();

        }, error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        });
      }
    });

    this.f.AcidLevelPrior.setValidators([
      Validators.required,
      Validators.pattern(Globals.NumberRegex),
      WaterProcessCustomValidators.GreaterRestrictCustomValidation(
        this.f.AcidLevelPost
      )
    ]);

    AppComponent.HideLoader();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  GreaterValueCheck() {
    this.f.AcidLevelPrior.updateValueAndValidity();
    this.f.AcidLevelPost.updateValueAndValidity();
    this.f.PinID.updateValueAndValidity();
  }

  get f() {
    return this.AcidForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const AcidCreate = { Id: this.f.AcidsId.value };

    this.cdapi.GetCentralDeliveryAcidDrumFillingbyID(AcidCreate).subscribe(
      success => {
        // console.log(success);
        this.f.AcidsId.setValue(success.centralDelievryAcidDrumFillingId);

        this.notes.Note = '';
        this.attachment.clearQueue();
        const tempDate = new Date(success.fillingDate);

        this.f.AcidDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.fillingDate
        });

        this.eventId = success.eventId;
        this.f.selectedDrumNo.setValue(success.volumeAdded);
        this.f.selectedDrumNo.setValue(success.volumeAdded);
        this.f.selectedVender.setValue(success.vendor);
        this.VendorName = success.vendorName;
        this.selectedUsertext = success.technologistName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.AcidLevelPrior.setValue(success.acidLevelPriorToFilling);
        this.f.AcidLevelPost.setValue(success.acidLevelPost);
        this.f.selectedDialysisUnits.setValue(success.dialysisUnit.toString());
        this.ConfirmUser = success.confirmUserName;
        this.ConfirmationUserId = success.confirmationUser;
        this.selectedDialysisUnitstext = success.divisionName;
        this.selectedAcidContainerTxt = success.containerName;
        this.f.LotNumber.setValue(success.lotNumber);
        this.f.UserName.setValue(success.confirmationuser);
        this.DialysisUnitDropDownChanged();
        const AcidObj = { Id: this.f.AcidsId.value };
        this.cdapi
          .GetAttachemnetsBycentralDelievryAcidDrumFillingId(AcidObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });

        this.cdapi
          .GetNotesBycentralDelievryAcidDrumFillingId(AcidObj)
          .subscribe(list => {
            this.notes.NoteList = list;
          });
        this.f.selectedAcidContainer.setValue(success.acidContainer);
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {}

  fillDialysisUnit() {
    // this.cdapi.GetDialysisUnitDD().subscribe(list => {
    //   this.DialysisUnits = list;
    // });

    this.cdapi.GetDialysisUnitAD().subscribe(list => {
      this.DialysisUnits = list;
    });
  }

  fillVendorList() {
    this.cdapi.FillVendorList().subscribe(list => {
      this.VendorList = list;
    });
  }

  executeAcidCreateUpdate = function(Source) {

    AppComponent.loading = true;
    if (Source !== 'novalidate') {
      this.GreaterValueCheck();
    }
    this.isValidFormSubmitted = false;
    if (this.AcidForm.status === 'PENDING') {
      setTimeout(() => {
        this.executeAcidCreateUpdate('novalidate');
      }, 300);
      return;
    }

    if (this.AcidForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const AcidCreate = this.prepareCreateViewModel();

    if (AcidCreate.centraldelievryaciddrumfillingid === '0') {
      if (this.ConfirmationUserId < 1) {
      const credential = {
        UserName: this.f.UserName.value,
        PinID: this.f.PinID.value
      };
      this.auth.ValidateConfirmationUser(credential).subscribe(v => {
        if (v < 1) {
          AppComponent.HideLoader();
          return;
        }
        AcidCreate.confirmationuser = parseFloat(v.value);
        if (this.AcidForm.invalid) {
          this.isValidFormSubmitted = false;
          AppComponent.addAlert(Globals.FormErrorMessage, 'error');
          AppComponent.loading = false;
          return;
        }
        this.cdapi.CreateCentralDeliveryAcidDrumFilling(AcidCreate).subscribe(
          success => {
            // console.log(success);
            // this.DMRepailDetail = success.dmRepailDetails;

            AppComponent.loading = false;
            AppComponent.addAlert('Data Saved successfully!', 'success');

            if (Source !== 'Notes') {
              this.router.navigate([this.ListURL]);
            } else {
              this.f.AcidsId.setValue(success.centraldelievryaciddrumfillingid);
              this.setModel();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      });
    } else {
      AcidCreate.confirmationuser = this.ConfirmationUserId;
      this.cdapi.CreateCentralDeliveryAcidDrumFilling(AcidCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.f.AcidsId.setValue(success.centraldelievryaciddrumfillingid);
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
    } else {
      AcidCreate.confirmationUser = this.ConfirmationUserId;
      this.cdapi.UpdateCentralDeliveryAcidDrumFilling(AcidCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const AcidCreate = <any>{};

    AcidCreate.centraldelievryaciddrumfillingid = this.f.AcidsId.value;
    AcidCreate.eventid = this.eventId;

    AcidCreate.createdon = new Date();
    AcidCreate.createdby = this.currentUserId;

    AcidCreate.modifiedon = new Date();
    AcidCreate.modifiedby = this.currentUserId;
    AcidCreate.technologist = this.currentUserId;
    // AcidCreate.status = this.f.FormDesignation.value;
    AcidCreate.dialysisunit = this.f.selectedDialysisUnits.value;
    AcidCreate.acidcontainer = this.f.selectedAcidContainer.value;
    AcidCreate.lotnumber = this.f.LotNumber.value;
    AcidCreate.acidlevelpost = this.f.AcidLevelPost.value;
    AcidCreate.acidlevelpriortofilling = this.f.AcidLevelPrior.value;

    if (this.f.AcidDate.value !== null && this.f.AcidDate.value !== undefined) {
      AcidCreate.fillingdate = Globals.GetAPIDate(this.f.AcidDate.value.jsdate);
    }

    AcidCreate.vendor = this.f.selectedVender.value;
    AcidCreate.volumeadded = this.f.selectedDrumNo.value;
    AcidCreate.note = this.notes.Note;
    // attachments
    AcidCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    return AcidCreate;
  }

  DialysisUnitDropDownChanged() {
    this.FillAcidContainer(this.f.selectedDialysisUnits.value);
    // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
  }

  FillAcidContainer(DialysisUnit) {
    const num = { Id: DialysisUnit };
    this.cdapi.FillAcidLevelOptions(num).subscribe(list => {
      this.AcidContainerList = list;
      // console.log(list);
    });
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const AcidObj = { Id: this.f.AcidsId.value };
        this.cdapi.DeleteCentralDeliveryAcidDrumFillingbyID(AcidObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'CD Acid detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetPageRights() {
    this.auth.GetPageRights('Acid Drum Tracking').subscribe(list => {
      if (this.f.AcidsId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }

  handleInput(event) {
    if (event.keyCode === 13 || event.charCode === 13) {
      console.log (event);
      AppComponent.ShowLoader();
      const credential = {
        UserName: this.f.UserName.value,
        PinID: this.f.PinID.value
      };
      this.auth.ValidateConfirmationUser(credential).subscribe(
        v => {
          if (parseFloat(v.value) < 1) {
            AppComponent.HideLoader();
            return;
          } else {

          this.ConfirmationUserId = v.value;
          this.ConfirmUser = v.text;
          this.f.UserName.clearValidators();
          this.f.PinID.clearValidators();
          this.f.PinID.clearAsyncValidators();
          //this.executeAcidCreateUpdate('');
          AppComponent.HideLoader();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }
  // GreaterValueCheck(){
  //   this.f.hoursout.updateValueAndValidity();
  //   this.f.selectedOutsurfacedis.updateValueAndValidity();
  //   this.f.selectedouthydraulicdis.updateValueAndValidity();

  // }
}

import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'DMUpgradeModificationsSearch',
  templateUrl: './DMUpgradeModificationsSearch.component.html'
})
export class DMUpgradeModificationsSearchComponent {
  UserList: any;
  Status: string;
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  StatusList=[];
  EventId = '';
  selectedDivision = 'All Divisions';
  DialysisUnits = [];
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'DialysisMachine/DMUpgradeModificationsDetail';
  currentPageNumber = 1;
  sortExpression = 'default';
  sortDirection = 'ASC';
  pageSize = 25;
  ShowSearch = false;
  title = 'DM Upgrades/Modifications';
  searchText = 'Search Records';
  defaultSort;

  // tslint:disable-next-line:member-ordering
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'eventId', '4');
      this.dataGridService.addHeader('Event Code', 'eventId', '23');
      this.dataGridService.addHeader('Form Date', 'eventdate', '23');
      this.dataGridService.addHeader('Division Name', 'divisionName', '25');
      this.dataGridService.addHeader('Status', 'status', '25');

      this.sortDirection = 'DESC';
      this.sortExpression = 'default';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('default', true);

      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.fillStatus();
      this.executeDMUpgradeModificationsSearch();
      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  fillDialysisUnit(defaultValue = '') {
    this.commonapi.FillDivision(true).subscribe(list => {

      if(Globals.Website == 'UHN')
      {
        this.DialysisUnits = list;
      }
      else
      {
        // this.DialysisUnits = list.filter(
        //   x => x.text != "Home Program"
        // );
        this.DialysisUnits = list;
      }

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(() => this.executeDMUpgradeModificationsSearch());
        }
      }
    });
  }

  fillStatus() {
    //this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.StatusList = [
      { value: '', text: 'Select' },
      { value: 'In Progress', text: 'In Progress' },
      { value: 'Completed', text: 'Completed' }
    ];

  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDMUpgradeModificationsSearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDMUpgradeModificationsSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const DMUpgradeModificationsSearch = this.prepareSearch();
    this.dmapi.GetDMUpgradesModificationsRecords(DMUpgradeModificationsSearch).subscribe(
      pagedData => {
        // console.log(pagedData);

        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.dmUpgradeModficationDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    const DMUpgradeModificationsSearch = <any>{};

    DMUpgradeModificationsSearch.eventid = this.EventId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      DMUpgradeModificationsSearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      DMUpgradeModificationsSearch.todate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      DMUpgradeModificationsSearch.division = this.selectedDivision;
    }

    DMUpgradeModificationsSearch.status = this.Status;
    DMUpgradeModificationsSearch.currentPageNumber = this.currentPageNumber;
    DMUpgradeModificationsSearch.sortExpression = this.sortExpression;
    DMUpgradeModificationsSearch.sortDirection = this.sortDirection;
    DMUpgradeModificationsSearch.pageSize = this.pageSize;
    // console.log(DMUpgradeModificationsSearch);
    return DMUpgradeModificationsSearch;
  }

  setPage() {
    if (this.currentPageNumber != this.page.pageNumber) {
      this.currentPageNumber = this.page.pageNumber;
      this.executeDMUpgradeModificationsSearch();
    }
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  executeDMUpgradeModificationsSearchClear() {
    this.Status = '';
    this.EventId = '';
    this.FromDate = '';
    this.ToDate = '';
    this.selectedDivision = 'All Divisions';
    this.executeDMUpgradeModificationsSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.Status ||
      this.EventId ||
      this.FromDate ||
      this.ToDate ||
      (this.selectedDivision && this.selectedDivision !== 'All Divisions')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executeDMUpgradeModificationsSearch();
  }
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickAddPartModel } from './QuickAddPartModel';
import { CurrencyPipe } from '@angular/common';
import { AuthService } from 'src/app/API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { InvApiService } from 'src/app/API/invapi.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { DMApiService } from 'src/app/API/dmapi.service';
import { InventoryCustomValidators } from 'src/app/Validator/InventoryCustomValidators';

@Component({
  selector: 'quick-add-part-dialog',
  templateUrl: './QuickAddPartDialog.html',
  styleUrls: ['./QuickAddPartDialog.css'],
})
export class QuickAddPartDialog implements AfterViewInit {

  currentUserId = this.auth.GetUserId();
  ManufacturerList = [];
  ModelList = [];
  VendorList = [];
  CategoryList = [];
  formatteditemCost = '';
  isValidFormSubmitted=false;

  InventoryForm = new UntypedFormGroup({
    equipementId: new UntypedFormControl('0'),
    equipmentCode: new UntypedFormControl('', Validators.required),
    equipmentPreviousCode: new UntypedFormControl(''),
    equipmentName: new UntypedFormControl('', Validators.required),
    equipmentType: new UntypedFormControl(''),
    equipmentDesc: new UntypedFormControl('', Validators.required),
    equipmentPicFileName: new UntypedFormControl(''),
    equipmentPicFilePath: new UntypedFormControl(''),
    categoryId: new UntypedFormControl(''),
    serialNumber: new UntypedFormControl(''),
    barCode: new UntypedFormControl(''),
    barcode2: new UntypedFormControl(''),
    vendorId: new UntypedFormControl('', Validators.required),
    vendorNumber: new UntypedFormControl(''),
    vendorPhone: new UntypedFormControl(''),
    modeOfPayment: new UntypedFormControl(''),
    paymentDetails: new UntypedFormControl(''),
    expenseCode: new UntypedFormControl(''),
    itemCost: new UntypedFormControl('', [Validators.compose([Validators.max(999999)]),Validators.required]),
    inServiceDate: new UntypedFormControl(''),
    inServiceHours: new UntypedFormControl(''),
    divisionId: new UntypedFormControl(''),
    lastUpdateDate: new UntypedFormControl(''),
    lastUpdateHrs: new UntypedFormControl(''),
    manufacturer: new UntypedFormControl(''),
    manufacturerId: new UntypedFormControl('', Validators.required),
    modelId: new UntypedFormControl('', Validators.required),
    model: new UntypedFormControl(''),
    warrantyPeriodDays: new UntypedFormControl(''),
    warrantyPeriodHrs: new UntypedFormControl(''),
    warrantyPeriodForPartsDays: new UntypedFormControl(''),
    warrantyPeriodForPartsHrs: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    createdOn: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
    presentStatus: new UntypedFormControl('')
  });

  public quickAddPart: QuickAddPartModel;
  constructor(public dialogRef: MatDialogRef<QuickAddPartDialog>,
    private commonapi: CommonApiService,
    private auth: AuthService,
    private dmapi: DMApiService,
    private currencyPipe: CurrencyPipe,
    private invapi: InvApiService,
    @Inject(MAT_DIALOG_DATA) public data: QuickAddPartModel
  ) {
    if (data && data.category) {
      this.quickAddPart = data;
      this.setCategory(data.category);
    }
    else {
      this.quickAddPart = new QuickAddPartModel();
      this.fillManufacturers();
    }
  }

  @Output() SaveEvent = new EventEmitter<QuickAddPartModel>();

  ngOnInit() {
    this.fillVendors();
  }

  ngAfterViewInit() {

  }

  executeInventoryCreateUpdate = function () {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    if (this.InventoryForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      return;
    }
    this.isValidFormSubmitted = true;

    const inventoryCreate = this.prepareCreateViewModel();
    this.invapi.CreateInventoryDetails(inventoryCreate).subscribe(
      success => {
        AppComponent.loading = false;


        this.quickAddPart.equipementid = success.equipementId;
        this.quickAddPart.equipmentcode = success.equipmentCode;
        this.quickAddPart.itemcost = success.itemCost;
        this.quickAddPart.equipmentDesc = success.equipmentDesc;

        // AppComponent.addAlert('Data Saved successfully!', 'success');
        this.SaveEvent.emit(this.quickAddPart);
        this.dialogRef.close(true);
      },
      error => {
        if (error.error && error.error.returnMessage) {
          this.data.errormsg = error.error.returnMessage.toString();
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          this.data.errormsg = error.message;
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  };

  prepareCreateViewModel() {
    const inventoryCreate = <any>{};

    inventoryCreate.equipmentCode = inventoryCreate.serialNumber = inventoryCreate.barCode = this.f.equipmentCode.value.toUpperCase();
    inventoryCreate.equipmentName = this.f.equipmentName.value;
    inventoryCreate.equipmentType = "PARTS";
    inventoryCreate.equipmentDesc = this.f.equipmentDesc.value;
    inventoryCreate.categoryId = this.f.categoryId.value;
    inventoryCreate.vendorId = this.f.vendorId.value;
    inventoryCreate.itemCost = this.currenctyToNumber(this.f.itemCost.value);
    inventoryCreate.manufacturerId = this.f.manufacturerId.value;

    if (this.f.manufacturerId.value) {
      var manufacturerSelected = this.ManufacturerList.filter(
        x => x.value === this.f.manufacturerId.value.toString()
      );

      if (manufacturerSelected.length > 0) {
        inventoryCreate.manufacturer = manufacturerSelected[0].text;
      }
    }

    inventoryCreate.model = this.f.model.value;

    if (this.f.modelId.value) {

      var modelSelected = this.ModelList.filter(
        x => x.value === this.f.modelId.value.toString()
      );

      if (modelSelected.length > 0) {
        inventoryCreate.model = modelSelected[0].text;
      }
    }

    inventoryCreate.modelId = this.f.modelId.value;

    inventoryCreate.presentStatus = 'Active';

    inventoryCreate.createdOn = new Date();
    inventoryCreate.createdBy = this.currentUserId;
    inventoryCreate.modifiedOn = new Date();
    inventoryCreate.modifiedBy = this.currentUserId;
    return inventoryCreate;
  }

  currenctyToNumber(value) { return Number(value.toString().replace(/[^0-9.-]+/g, "")); }

  save() {
    this.quickAddPart.errormsg = '';
    if (!this.quickAddPart || !this.quickAddPart.itemName) {
      this.quickAddPart.errormsg = 'item Name Required';
      return false;
    }
    else {
      this.SaveEvent.emit(this.quickAddPart);
    }
    this.dialogRef.close(true);
  }



  fillVendors() {
    this.invapi.FillVendorList().subscribe(list => {
      this.VendorList = list;
    });
  }

  fillModelList() {
    const manufacturerId = { Id: this.f.manufacturerId.value };
    this.invapi
      .FillModels(manufacturerId)
      .subscribe(list => (this.ModelList = list));
  }

  fillManufacturers() {
    const categoryId = { Id: this.f.categoryId.value };

    this.invapi.FillManufacturerList(categoryId).subscribe(list => {
      this.ManufacturerList = list;
    });
  }

  fillAndSetModelList() {
    this.f.modelId.setValue('');
    this.fillModelList();
  }

  forceInputUppercase(e) {
    if(e.target.name == 'equipmentCode' && this.GetSiteName() !='SCSCDU'){
      this.commonapi.forceInputUppercase(e);
    }

    this.f.equipmentCode.setAsyncValidators([
      InventoryCustomValidators.InventoryItemIDValidate(
        this.commonapi,
        this.f.equipmentCode.value.toUpperCase(),
        "ItemID"
      )
    ]);
    this.f.equipmentCode.updateValueAndValidity();
  }

  GetSiteName() {
    return Globals.Website;
  }

  transformitemCost() {
    if (this.f.itemCost.value != 0) {
      this.formatteditemCost = this.currencyPipe.transform(this.currenctyToNumber(this.f.itemCost.value), '$', '$', '1.2-2');
    }
    else
    {
      this.f.itemCost.setValue('');
    }
  }

  setCategory(categoryName) {

    if (this.data.comp_equipmentId) {
      var dmrepairCreate = { Id: this.data.comp_equipmentId };
      this.dmapi
        .GetEquipmentDetailsByID(dmrepairCreate)
        .subscribe(success => {

          this.f.manufacturerId.setValue(success.manufacturerid ? success.manufacturerid : '');
          this.f.modelId.setValue(success.modelid);
          this.f.manufacturer.setValue(success.manufacturer ? success.manufacturerid : '');
          this.f.model.setValue(success.model);
          this.f.categoryId.setValue(success.categoryid);
          this.fillManufacturers();
          // this.fillModelList();
          // if(this.f.manufacturerId.value)
          //   this.f.manufacturerId.disable();

          // if(this.f.modelId.value)
          //   this.f.modelId.disable();
        });

    }
    else {
      this.fillManufacturers();
    }

  }

  get f() {
    return this.InventoryForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }
}


import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { WPApiService } from '../../API/wpapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NoteDialog } from '../../Core/NotesModel/NoteDialog';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { AcidLevelComponent } from '../../Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { WaterProcessCustomValidators } from '../../Validator/WaterProcessCustomValidators';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { WPDailySatelliteDetailComponent } from './Satellite/WPDailySatelliteDetail.component';
import { WPDailyDetailComponent } from './SCSCDU/WPDailyDetail.component';
import { debug } from 'util';
import { WPDailyMain3EDetailComponent } from './Main3E/WPDailyMain3EDetail.component';
import { HX2Main3EDetailComponent } from './HX2Main3E/HX2Main3EDetail.component';

@Component({
  selector: 'GRWPDailyDetail',
  templateUrl: './GRWPDailyDetail.component.html'
})
export class GRWPDailyDetailComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;


  @ViewChild(WPDailySatelliteDetailComponent, { static: false })
  setelliteDetailForm: WPDailySatelliteDetailComponent;

  @ViewChild(WPDailyDetailComponent, { static: false })
  centreDailyForm: WPDailyDetailComponent;

  @ViewChild(WPDailyMain3EDetailComponent, { static: false })
  main3E7DailyForm: WPDailyMain3EDetailComponent;

  @ViewChild(HX2Main3EDetailComponent, { static: false })
  HX2Main3EForm: HX2Main3EDetailComponent;

  @ViewChild(UntypedFormControl, { static: false })
  mainform: UntypedFormControl;

  noteDialogRef: MatDialogRef<NoteDialog>;
  currentUserId = this.auth.GetUserId();
  pageRights = '';
  divisionShortText = '';
  isValidFormSubmitted = null;

  ListURL = 'WaterProcess/Daily/GRWPDailySearch';

  eventId = '[AutoNumber]';

  private today = new Date();
  loading = true;

  GRWaterprocessDailyrecordsIDvar = '0';

  DialysisUnits = [];

  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;

  RecordDate = '';
  //FormDate = '';

  Status = 'In Progress';

  isMainDivison = false;
  isMain3EForm = false;
  isMain7Form = false;

  isFSUDivision = false;
  isGSUDivision = false;
  isPSUDivision = false;
  isCentreForm = false;


  formLockedOut = false;

  MainDUName = '';
  DUName = '';

  blnshowSave = true;
  blnshowDelete = false;

  MainDivisions = [
    { value: "", text: "Select" },
    { value: "MAIN 3E", text: "Main 3E" },
    { value: "MAIN 7", text: "Main 7" }
  ];

  selectedMainDivision = '';
  UserName = '';
  // -------------------------------------End------------------------------------------------

  // Form
  // -------------------------------------Start----------------------------------------------
  GRDailyDetailForm = new UntypedFormGroup({
    GRWaterprocessDailyrecordsID: new UntypedFormControl(''),
    GRWaterProcessailyRecordMain3EId: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedMainDivision: new UntypedFormControl(''),
    formDate: new UntypedFormControl('', Validators.required),
    curruntDate: new UntypedFormControl(''),
  });

  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private wpapi: WPApiService,
    private formBuilder: UntypedFormBuilder
  ) { }
  // implementation of canDeactivate
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.GRDailyDetailForm.dirty) && (this.isValidFormSubmitted == false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          //  console.log('res', res);
          return res;
        })
      );
    } else return true;
  }
  // -------------------------------------End------------------------------------------------


  ngAfterViewChecked() {

    this.scrollHelper.doScroll();
    //this.isValidFormSubmitted = false;

  }

  isNewMain3EForm = true;

  ngOnInit() {

    this.SetPageRights();
    AppComponent.ShowLoader();

    //this.uploader.autoUpload = true;

    this.UserName = this.auth.GetUserFLName();




    var test = this.route.queryParams.subscribe(params => {
      this.f.GRWaterprocessDailyrecordsID.setValue(params['id'] || '0');
      if (params['formType'] == 'MAIN 3E') {
        this.isMain3EForm = true;
        this.isMain7Form = false;
      }
      else if (params['formType'] == 'MAIN 7') {
        this.isMain3EForm = false;
        this.isMain7Form = true;
      }
      else {
        this.isMain3EForm = false;
        this.isMain7Form = false;
      }

      // this.setNotesAttachmentComponent();

      if (this.f.GRWaterprocessDailyrecordsID.value != "0") {
        setTimeout(() => {

          this.setModel();
        }, 300);
      } else {
        this.fillDialysisUnit();
        this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
          this.today = new Date(list);

          this.setDateOptions();

          let tempFormDate = this.today;
          this.f.formDate.setValue({
            date: {
              year: tempFormDate.getFullYear(),
              month: tempFormDate.getMonth() + 1,
              day: tempFormDate.getDate()
            },
            jsdate: tempFormDate
          });
          this.auth.GetCurrentUserDivision().subscribe(division => {
            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.DialysisUnitDropDownChanged();
            }
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        });


      }
    });

    AppComponent.HideLoader();
  }



  get f() {
    return this.GRDailyDetailForm.controls;
  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  setModel() {

    AppComponent.ShowLoader();

    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.clearAsyncValidators();

    var GRwpdailyCreate = { Id: this.f.GRWaterprocessDailyrecordsID.value };
    this.fillDialysisUnit();

    this.wpapi.GRWaterprocessDailyrecordsID(GRwpdailyCreate).subscribe(
      success => {

        if (success) {

          // console.log(success);
          this.f.GRWaterprocessDailyrecordsID.setValue(success.grWaterprocessDailyrecordsID);
          this.f.selectedDialysisUnits.setValue(success.divisionId);
          this.f.selectedMainDivision.setValue(success.formType);
          this.Status = success.status;
          this.eventId = success.eventId;

          this.RecordDate = success.recordDate;
          const tempcreatedOn = new Date(success.formDate);

          this.f.formDate.setValue({
            date: {
              year: tempcreatedOn.getFullYear(),
              month: tempcreatedOn.getMonth() + 1,
              day: tempcreatedOn.getDate()
            },
            jsdate: success.formDate
          });

          this.DUName = success.divisionName;
          this.MainDUName = success.formType;
          this.UserName = success.formUser;
          this.createdOn = new Date(success.createdDate);

          if( Globals.Website == 'SCSCDU') {
            this.blnshowSave = true;
          }
          if(Globals.Website == 'GR') {
            if((this.Status == 'Completed' || this.Status == 'Incompleted')){
              this.blnshowSave = true;
            }
            else {
              this.blnshowSave = false;

            }
          }
          // if (success.formType && success.formType.includes("MAIN")) {

          //   this.f.GRWaterProcessailyRecordMain3EId.setValue(success.grWaterProcessailyRecordMain3EId);

          //   this.isMainDivison = true;

          //   AppComponent.HideLoader();

          // }
          // else {
            setTimeout(() => {

              if(new Date(this.f.formDate.value.jsdate).setHours(0,0,0,0) < new Date('2020-06-19T10:00:00.00').setHours(0,0,0,0))
              {
                this.isNewMain3EForm = false;
              }
              this.DialysisUnitDropDownChanged();
            }, 200);
            AppComponent.HideLoader();
          // }
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  SetPageRights() {

    this.auth.GetPageRights('GR Water Process Daily Record').subscribe(list => {
      if (this.f.GRWaterprocessDailyrecordsID.value != '0') {
        // this.blnshowSave = list[actionType.edit] == '1';
        this.blnshowDelete = false;
      } else {
        // this.blnshowSave = true;
        // this.blnshowSave = list[actionType.create] == '1';
        this.blnshowDelete = false;
      }
    });
  }
  //Functions/Methods
  //-------------------------------------Start----------------------------------------------

  fillDialysisUnit() {

    this.dmapi
      .GetDialysisUnitWP()
      .subscribe(
        list => {
          this.DialysisUnits = list;
          //console.log(this.DialysisUnits);
          this.DialysisUnits = this.DialysisUnits.filter(h => h.text != 'Home Program');
          this.DialysisUnits = this.DialysisUnits.filter(h => h.text != 'Off-Unit');
        }
      );
  }

  DialysisUnitDropDownChanged() {

this.f.selectedMainDivision.setValue('');
    var DivisionSelected = this.DialysisUnits.filter(
      x => x.value === this.f.selectedDialysisUnits.value.toString()
    );
    let DivisionName = '';
    if (DivisionSelected.length > 0) {
      DivisionName = DivisionSelected[0].text;
    }

    if (DivisionName === 'Main In-Centre') {
      this.isMainDivison = true;
      this.isFSUDivision = false;
      this.isGSUDivision = false;
      this.isPSUDivision = false;
      this.isCentreForm = false;
      this.f.selectedMainDivision.setValidators([Validators.required]);
      this.f.selectedMainDivision.setAsyncValidators(
        WaterProcessCustomValidators.GRWaterProcessDivisionValidateWithMain(this.wpapi, this.f.selectedDialysisUnits)
      );
      this.f.selectedMainDivision.updateValueAndValidity();

      if(!this.isNewMain3EForm){
      if (this.main3E7DailyForm) {
        this.main3E7DailyForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
        this.main3E7DailyForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        this.main3E7DailyForm.DialysisUnitDropDownChanged();
      }
    } else {
      if(this.HX2Main3EForm) {

        this.HX2Main3EForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
        this.HX2Main3EForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        this.HX2Main3EForm.DialysisUnitDropDownChanged();
      }
    }
      // if(new Date(this.f.formDate.value.jsdate).setHours(0,0,0,0) < new Date('2020-06-18T10:00:00.00').setHours(0,0,0,0))
      // {
      //
      //   this.main3E7DailyForm.DialysisUnitDropDownChanged();
      // }
      // else
      // {
      // }
    }
    else if (DivisionName === 'Sheppard Centre' || DivisionName === 'Sussex Centre') {
      debugger;
      this.f.selectedMainDivision.setValue(DivisionSelected[0].shorttext);
      this.isMainDivison = false;
      this.isFSUDivision = false;
      this.isGSUDivision = false;
      this.isPSUDivision = false;
      this.isCentreForm = true;
      this.f.selectedMainDivision.setAsyncValidators(
        WaterProcessCustomValidators.GRWaterProcessDivisionValidateWithMain(this.wpapi, this.f.selectedDialysisUnits)
      );
      this.f.selectedMainDivision.updateValueAndValidity();
      if (this.centreDailyForm) {
        debugger;
        this.centreDailyForm.division = this.f.selectedDialysisUnits.value;
        this.centreDailyForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
        this.centreDailyForm.divisionShortText = this.f.selectedMainDivision.value;
        this.centreDailyForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        this.centreDailyForm.formDate = this.f.formDate.value;
        this.centreDailyForm.DialysisUnitDropDownChanged();
      }
    }
    else if (DivisionName === 'Freeport Satellite Unit' || DivisionName === 'Guelph Satellite Unit' ||
      DivisionName === 'Palmerston Satellite Unit') {
        this.isMainDivison = false;
      this.f.selectedMainDivision.setValue(DivisionSelected[0].shorttext);
      this.f.selectedMainDivision.setAsyncValidators(
        WaterProcessCustomValidators.GRWaterProcessDivisionValidateWithMain(this.wpapi, this.f.selectedDialysisUnits)
      );
      this.f.selectedMainDivision.updateValueAndValidity();


      if ((DivisionName === 'Freeport Satellite Unit' || this.MainDUName == 'FSU')
        && (!this.f.selectedMainDivision.errors || this.f.GRWaterprocessDailyrecordsID.value > 0)) {
        this.isFSUDivision = true;
        this.isGSUDivision = false;
        this.isPSUDivision = false;
        this.isCentreForm = false;
        debugger;
        if (this.setelliteDetailForm) {
      this.setelliteDetailForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
      this.setelliteDetailForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        }
      }
      else if ((DivisionName === 'Guelph Satellite Unit' || this.MainDUName == 'GSU')
        && (!this.f.selectedMainDivision.errors || this.f.GRWaterprocessDailyrecordsID.value > 0)) {
        this.isGSUDivision = true;
        this.isFSUDivision = false;
        this.isPSUDivision = false;
        this.isCentreForm = false;
        debugger;
        if (this.setelliteDetailForm) {
      this.setelliteDetailForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
      this.setelliteDetailForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        }
      }
      else if ((DivisionName === 'Palmerston Satellite Unit' || this.MainDUName == 'PSU')
        && (!this.f.selectedMainDivision.errors || this.f.GRWaterprocessDailyrecordsID.value > 0)) {
        this.isPSUDivision = true;
        this.isFSUDivision = false;
        this.isGSUDivision = false;
        this.isCentreForm = false;
        debugger;
        if (this.setelliteDetailForm) {
      this.setelliteDetailForm.f.selectedDialysisUnits.setValue(this.f.selectedDialysisUnits.value);
      this.setelliteDetailForm.f.selectedMainDivision.setValue(this.f.selectedMainDivision.value);
        }
      }
      else {
        this.isPSUDivision = false;
        this.isFSUDivision = false;
        this.isGSUDivision = false;
        // this.isCentreForm = false;
      }

    }
    else {

      this.f.selectedMainDivision.setValue('');

      this.f.selectedMainDivision.clearValidators();
      this.f.selectedMainDivision.updateValueAndValidity();

      this.isMainDivison = false;
      this.isMain3EForm = false;
      this.isMain7Form = false;
      this.isPSUDivision = false;
      this.isFSUDivision = false;
      this.isGSUDivision = false;
    }

  }

  MainDivisionDropDownChanged() {

    let blnValidMain = true;
    var wpCreate = { Id: this.f.selectedDialysisUnits.value, str: this.f.selectedMainDivision.value };
    this.wpapi.checkGRRecordExist(wpCreate).subscribe(
      success => {
        //console.log(success);
        blnValidMain = success;
        if (blnValidMain) {
          if (this.f.selectedMainDivision.value === 'MAIN 3E') {
            this.isMain3EForm = true;
            this.isMain7Form = false;
            this.MainDUName = 'MAIN 3E';

            // this.GetWeeklyCompletedDate();
            setTimeout(() => {

              if (this.HX2Main3EForm) {
                //this.main3E7DailyForm.GetWeeklyCompletedDate(this.MainDUName);
                this.HX2Main3EForm.GetWeeklyCompletedDate(this.MainDUName);
              }
            },2000);
          }
          else if (this.f.selectedMainDivision.value === 'MAIN 7') {
            this.isMain7Form = true;
            this.isMain3EForm = false;
            this.MainDUName = 'MAIN 7';
            // this.GetWeeklyCompletedDate();

            setTimeout(() => {

              if (this.main3E7DailyForm) {
                this.main3E7DailyForm.GetWeeklyCompletedDate('MAIN 7');
              }
            },2000);
          }
          else {
            this.isMain3EForm = false;
            this.isMain7Form = false;
          }
        }
        else {
          this.isMain3EForm = false;
          this.isMain7Form = false;
        }

        setTimeout(() => {


          if (this.main3E7DailyForm) {

            this.main3E7DailyForm.DialysisUnitDropDownChanged();
            this.main3E7DailyForm.setNotesAttachmentComponent();
          }
          if(this.MainDUName == 'MAIN 3E')
            {

              if (this.HX2Main3EForm) {
                this.HX2Main3EForm.DialysisUnitDropDownChanged();
                this.HX2Main3EForm.setNotesAttachmentComponent();
              }
            }

        });
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  GetWeeklyCompletedDate() {

    // let RecordDay = Globals.GetAPIDate(this.f.formDate.value.jsdate);
    let RecordDay = this.f.formDate.value.jsdate;
    var formDates = { formDate: RecordDay, formType: this.MainDUName };
    // console.log(formDates);
    // let curruntDate = new Date();

    this.auth.GetCurrentDateTimeFromServer().subscribe(list => {
      let tempFormDate = new Date(list);
      this.f.curruntDate.setValue({
        date: {
          year: tempFormDate.getFullYear(),
          month: tempFormDate.getMonth() + 1,
          day: tempFormDate.getDate()
        },
        jsdate: tempFormDate
      });
    });

    this.wpapi
      .GRWaterprocessGetWeeklyCompletedRecord(formDates)
      .subscribe(list => {

        if (list == null) {

          if (new Date(RecordDay).setHours(0, 0, 0, 0) != new Date(this.f.curruntDate.value.jsdate).setHours(0, 0, 0, 0) && this.f.ResidualTestResult.value === '') {
            //console.log(RecordDay.toDateString() != curruntDate.toDateString(),RecordDay.toDateString(), curruntDate.toDateString())
            this.formLockedOut = true;

            this.ctlClearValidity();
          }

        }

      });
    }

  executeGRWPDailyCreateUpdate = function (Source, type) {
    let DivisionName = '';
    let divisionId = '';

    var DivisionSelected = this.DialysisUnits.filter(
      x => x.value === this.f.selectedDialysisUnits.value.toString()
    );
    if (DivisionSelected.length > 0) {
      DivisionName = DivisionSelected[0].text;
      divisionId = DivisionSelected[0].value;
    }

    if (DivisionName == 'Sheppard Centre' || DivisionName == 'Sussex Centre') {
      if (this.centreDailyForm) {

        this.centreDailyForm.mainSave().then(() => {

        }, () => {
          AppComponent.addAlert("Error", 'error');
          AppComponent.HideLoader();
        });
      }

      return;
    }
    else if (DivisionName != 'Main In-Centre') {
      if (this.setelliteDetailForm) {
        this.setelliteDetailForm.mainSave().then(() => {

        }, () => {
          AppComponent.addAlert("Error", 'error');
          AppComponent.HideLoader();
        });
      }

      return;
    }
    else if (DivisionName == 'Main In-Centre') {
      if (this.HX2Main3EForm) {
        this.HX2Main3EForm.mainSave().then(() => {

        }, () => {
          AppComponent.addAlert("Error", 'error');
          AppComponent.HideLoader();
        });
      }
      else if (this.main3E7DailyForm) {
        this.main3E7DailyForm.mainSave().then(() => {

        }, () => {
          AppComponent.addAlert("Error", 'error');
          AppComponent.HideLoader();
        });
      }
    }
    else{
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
    }

  };

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void { }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        var wpDailyObj = { Id: this.f.GRWaterprocessDailyrecordsID.value };
        this.wpapi.DeleteGRWaterProcessDailyRecord(wpDailyObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Water process daily record deleted successfully!',
              'success'
            );
            this.router.navigate(['WaterProcess/Daily/GRWPDailySearch']);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  ctlClearValidity() {
    this.f.GRWaterprocessDailyrecordsID.clearValidators();
    this.f.GRWaterprocessDailyrecordsID.updateValueAndValidity();

    this.f.GRWaterProcessailyRecordMain3EId.clearValidators();
    this.f.GRWaterProcessailyRecordMain3EId.updateValueAndValidity();

    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.updateValueAndValidity();

    this.f.selectedMainDivision.clearValidators();
    this.f.selectedMainDivision.updateValueAndValidity();
  }
}

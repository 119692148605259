import { Component, ViewChild } from '@angular/core';
import { WPApiService } from '../../API/wpapi.service';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from '../../API/commonapi.service.';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'GRWPDailySearch',
  templateUrl: './GRWPDailySearch.component.html',
})
export class GRWPDailySearchComponent {

  constructor(private dmapi: DMApiService, private wpapi: WPApiService, private router: Router, private dataGridService: TableHeadsService, private commonapi: CommonApiService, private auth: AuthService
    , public sanitizer: DomSanitizer
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];
    Equipments=[];
  Users=[];
  Parts=[];
  Statuses=[];
  Patients=[];
  DMRepailDetail =[];
  selectedDivision = "All Divisions";
  selectedEquipment = "";
  selectedPart = "";
  //selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  EventId="";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef="";
  OutFromDate;
OutToDate;
tableHeaders;
page = new Page();
rows ;
AddUrl = "/WaterProcess/Daily/GRWPDailyDetail";
workOrderRefNo="";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch=false;
  title = "Water Process Daily Record Details";
  searchText="Search Records"
defaultSort;

  ngOnInit() {
    //AppComponent.loading=true;
    this.auth.GetCurrentUserDivision().subscribe(division => {
      this.dataGridService.clearHeader();
      this.dataGridService.addHeader("#", "EventId", "4");
      this.dataGridService.addHeader("Event ID", "eventId", "10");
      this.dataGridService.addHeader("Division", "divisionName", "25");
      this.dataGridService.addHeader("Form Type", "formType", "20");
      this.dataGridService.addHeader("Date", "RecordDate", "10");
      this.dataGridService.addHeader("Form Status", "status", "16");
      if (this.GetSiteName() != 'SCSCDU') {
        this.dataGridService.addHeader("Disinfection Status", "disinfectionStatus", "16");
      }
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort("default", true);

      this.executeDmrepairSearch();

      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.fillEquipment();
      this.fillStatus();
      this.CheckDirtySearch();
      AppComponent.HideLoader();

    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value)
  {
    this.ShowSearch=value;
  }

changeSorting(column){
  // console.log(column);
  if (column =="#")
  {
      return;
  }
  //console.log(this.tableHeaders);
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDmrepairSearch();

};

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

  executeDmrepairSearch()
{
  AppComponent.ShowLoader()
  this.pageheader.isSearchOpen=false;
  this.pageheader.searchText="Search Records";
  this.ShowSearch=false;
  this.searchText="Search Records";
  this.CheckDirtySearch();
  var  dmrepairsearch = this.prepareSearch();
  this.wpapi.GRWaterprocessDailyrecords(dmrepairsearch).subscribe(pagedData  =>
    {this.page.totalElements= pagedData.totalRows;
      this.page.totalPages= pagedData.totalPages;
    // console.log(pagedData);
    this.rows=pagedData.recordDetails;
    AppComponent.HideLoader()
  }
  ,
          error=>
          {
            AppComponent.addAlert(error.message,"error");
            AppComponent.HideLoader();
          }
  );
}

prepareSearch() {

  var dmrepairsearch =<any>{};

  if (this.FromDate != undefined && this.FromDate !="" )
  {
    dmrepairsearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
  }
  if (this.ToDate != undefined && this.ToDate !="" )
  {
    dmrepairsearch.todate = Globals.GetAPIDate( this.ToDate.jsdate);
  }

  if (this.selectedDivision != undefined && this.selectedDivision !=""  && this.selectedDivision != "All Divisions" ) {
      dmrepairsearch.Division = this.selectedDivision;
  }

  if (this.selectedStatus != undefined && this.selectedStatus != "") {
      dmrepairsearch.Status = this.selectedStatus;
  }

  dmrepairsearch.currentPageNumber = this.currentPageNumber;
  dmrepairsearch.sortExpression = this.sortExpression;
  dmrepairsearch.sortDirection = this.sortDirection;
  dmrepairsearch.pageSize = this.pageSize;
  // console.log(dmrepairsearch);
  return dmrepairsearch;

}

GetSiteName() {
  return Globals.Website;
}

  setPage(){
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber=this.page.pageNumber;
    //this.page.pageNumber = pageInfo.offset;
    console.log('set page')
    this.executeDmrepairSearch();
    }
}
 _onRowClick(row,formtype) {

      this.router.navigate(['WaterProcess/Daily/GRWPDailyDetail'], { queryParams: { id: row, formType : formtype} });
}


public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect:false
};

onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit(defaultValue = '') {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);

    this.dmapi
    .GetDialysisUnitWP(true).subscribe(list => {
      //this.DialysisUnits = list;
        this.DialysisUnits = list.filter(
        x => x.text != "Home Program"
        );

        this.DialysisUnits = this.DialysisUnits.filter(
          x => x.text != "Off-Unit"
        );

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(() => this.executeDmrepairSearch());
        }
      }
    });
  }

  fillEquipment()
  {
   // this.dmapi.GetEquipmentDD(0).subscribe(list => this.Equipments=list);

  }

  fillStatus()
  {
    //this.dmapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.Statuses= [
      { value: "", text: "Select" },
      { value: "In Progress", text: "In Progress" },
      { value: "Incompleted", text: "Incompleted" },
      { value: "Completed", text: "Completed" }
    ];

  }

  executeWPDailySearchClear()
  {
    this.selectedDivision = "All Divisions";
    this.selectedEquipment = "";
    this.selectedStatus = "";
    this.FromDate= "";
    this.ToDate="";


    this.executeDmrepairSearch();

  }

  CheckDirtySearch()
  {
    this.blnDirtySearch= false;
    if(
      (this.selectedDivision != "" && this.selectedDivision != "All Divisions")
    || this.selectedEquipment  != ""
    || this.selectedStatus != ""
    || (this.FromDate != null && this.FromDate != "")
    || (this.ToDate != null && this.ToDate != ""))
    {
      this.blnDirtySearch = true;

    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executeDmrepairSearch();
  }
}
export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

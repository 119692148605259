import { InvApiService } from './../../API/invapi.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuickAddModel } from './QuickAddModel';
import { ContactApiService } from '../../API/contactapi.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'quick-add-model-dialog',
  templateUrl: './QuickAddModelDialog.html',
})
export class QuickAddModelDialog  implements AfterViewInit {

  public quickAddModel : QuickAddModel;
  constructor(public dialogRef: MatDialogRef<QuickAddModelDialog>
    , private contactApi: ContactApiService
    , private invapi: InvApiService) {
    this.quickAddModel = new QuickAddModel();
  }

  CountryList = [];
  BillingStateList = [];
  StateList = [];
  CategoryList = [];
  dropdownSettings;
  @Output() SaveEvent = new EventEmitter<QuickAddModel>();

  vendordetailForm = new UntypedFormGroup({
    mailingcountry: new UntypedFormControl(''),
    mailingstate: new UntypedFormControl(''),
    mailingcity: new UntypedFormControl(''),
    mailingaddress: new UntypedFormControl(''),
    mailingzipCode: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
  });

  ngOnInit() {
    // this.dialogRef.updateSize('700px', 'auto');
    this.fillCountryList();
    this.fillCategories();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll:false
    };
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list.splice(1,list.length);
    });
  }

  ngAfterViewInit() {

   }

  get f() {
    return this.vendordetailForm.controls;
  }

  save() {

    if(this.quickAddModel.title == "Vendor")
    {
      this.quickAddModel.errormsg = '';
      if((this.quickAddModel.name == undefined || this.quickAddModel.name == '') && (this.quickAddModel.phone == undefined || this.quickAddModel.phone == ''))
      {
        this.quickAddModel.errormsg = 'Required';
        this.quickAddModel.errormsg1 = 'Required';
        return false;
      }
      else if(this.quickAddModel.name == undefined || this.quickAddModel.name == '')
      {
        this.quickAddModel.errormsg = 'Required';
        return false;
      }
      else if(this.quickAddModel.phone == undefined || this.quickAddModel.phone == '')
      {
        this.quickAddModel.errormsg1 = 'Required';
        return false;
      }
      else
      {
        this.SaveEvent.emit(this.quickAddModel);
      }
    }
    else if(this.quickAddModel.title == "Manufacturer")
    {
      if(this.quickAddModel.name == undefined || this.quickAddModel.name == '')
      {
        this.quickAddModel.errormsg = 'Required';
        return false;
      }
      else
      {
        this.quickAddModel.errormsg = '';
      }
      if(this.quickAddModel.Category == undefined || this.GetSelectedFields() == '')
      {
        this.quickAddModel.errormsg1 = 'Required';
        return false;
      }
      else{
        this.quickAddModel.errormsg1 = '';
      }
this.quickAddModel.Category = this.GetSelectedFields();
        this.SaveEvent.emit(this.quickAddModel);

    }
    else{
      if(this.quickAddModel.name == undefined || this.quickAddModel.name == '')
      {
        this.quickAddModel.errormsg = 'Required';
        return false;
      }
      else
      {
        this.SaveEvent.emit(this.quickAddModel);
      }
    }
    this.dialogRef.close(true);
  }

  GetSelectedFields() {

    let listCategory = "";
    if (this.quickAddModel.Category) {
      this.quickAddModel.Category.forEach(function (field) {

        if (listCategory == "") {
          listCategory = field.value;
        } else {
          listCategory += "," + field.value ;
        }
      });
    }
    return listCategory;
  }

  CountryStateDropDownChanged() {
    this.fillCountryStateList(this.quickAddModel.mailingcountry);
  }

  fillCountryList() {
    this.contactApi.FillCountry().subscribe(list => (this.CountryList = list));
    this.quickAddModel.mailingcountry = "";
  }

  fillCountryStateList(CountryId) {

    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {

      const CountryIds = { Id: filterCountry[0].value };
      this.contactApi
        .FillState(CountryIds)
        .subscribe(list => (this.StateList = list));
        this.quickAddModel.mailingstate = "";
    } else {

      this.StateList = [];
      this.quickAddModel.mailingstate = "";
    }
  }

  fillBillingCountryStateList(CountryId) {
    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.contactApi
        .FillState(CountryIds)
        .subscribe(list => (this.BillingStateList = list));
    } else {
      this.BillingStateList = [];
    }
  }

  UpdateBillingAddress() {
      // this.f.billingaddress.setValue(this.f.mailingaddress.value);
      // this.f.billingcity.setValue(this.f.mailingcity.value);
      // this.f.billingcountry.setValue(this.f.mailingcountry.value);
      // this.f.billingzipCode.setValue(this.f.mailingzipCode.value);
      // this.BillingStateList = this.StateList;
      // this.f.billingstate.setValue(this.f.mailingstate.value);
    }

    formatPhone(phone) {
      let val = this.quickAddModel.phone;
      if (val.indexOf('(') === -1) {
        if (val.length >= 10) {
          val =
            '(' +
            val.substring(0, 3) +
            ') ' +
            val.substring(3, 6) +
            '-' +
            val.substring(6, 10);
        }
      }
      this.quickAddModel.phone = val;
    }

    numberOnly(event): boolean {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
}


import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';

@Component({
  selector: 'SectionPurposeDetailDialog',
  templateUrl: './section-purposedetail.component.html',
  styleUrls: ['./section-purposedetail.component.css']
})

export class SectionPurposeDetailDialog implements AfterViewInit {

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  @Output() SaveEvent = new EventEmitter<any>();
  isValidFormSubmitted = false;
  errormsg = "";
  showDepartureDetail = false;
  isOtherSection = false;
  purposeSectionForm = new UntypedFormGroup({
    HFServicePurposeDetailId: new UntypedFormControl('0'),
    HFServicePTHMArrivalTime: new UntypedFormControl('', Validators.required),
    HFServicePTHMDepartureTime: new UntypedFormControl(''),
    HFServiceVehicleStartTime: new UntypedFormControl(''),
    HFServicePTHMPurposeCompleted: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    CreatedByName: new UntypedFormControl(''),
    HFServicePurposeDetailNotes: new UntypedFormControl(''),
    HFServiceId: new UntypedFormControl(''),
    HFServiceConnectedId: new UntypedFormControl(''),
    HFServiceReferenceHFSId: new UntypedFormControl(''),
    EnteredByHFServiceId: new UntypedFormControl(''),
    nextStation: new UntypedFormControl(''),
    DialysisUnitId: new UntypedFormControl(),
    OtherId: new UntypedFormControl(0),
  });

  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SectionPurposeDetailDialog>
  ) { }

  YesNoList = [];
  TechnologistsList = [];
  PrepTimeList = [];
  today = new Date();
  UserName = this.auth.GetUserName();
  UserId = this.auth.GetUserId();
  dialysisStationPending = [];
  dropdownSettings;

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      this.purpf.CreatedBy.setValue(this.UserId);
      this.purpf.CreatedByName.setValue(this.UserName);
      this.fillOtherDialysisStations();

    }, error => {
    });
  }


  fillOtherDialysisStations() {
    var HFServiceId = { Id: this.purpf.HFServiceId.value };
    this.homefieldApi.GetHomeFieldService_MasterPendingRecordsStations(HFServiceId).subscribe(list => {
      this.dialysisStationPending = list;
    });
  }

  prepareCreateViewModel(Source = "") {
    const modelCreate = <any>{};

    modelCreate.HFServicePurposeDetailId = this.purpf.HFServicePurposeDetailId.value;
    modelCreate.HFServicePTHMDepartureTime = Globals.GetAPIDateWithoutSeconds(this.purpf.HFServicePTHMDepartureTime.value);
    modelCreate.HFServicePTHMArrivalTime = Globals.GetAPIDateWithoutSeconds(this.purpf.HFServicePTHMArrivalTime.value);
    modelCreate.HFServicePTHMPurposeCompleted = this.purpf.HFServicePTHMPurposeCompleted.value;
    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.purpf.CreatedBy.value;
    modelCreate.HFServiceConnectedId = this.purpf.HFServiceConnectedId.value;
    modelCreate.EnteredByHFServiceId = this.purpf.EnteredByHFServiceId.value;

    modelCreate.HFServicePurposeDetailNotes = this.purpf.HFServicePurposeDetailNotes.value;
    modelCreate.HFServiceId = this.purpf.HFServiceId.value;
    modelCreate.HFServiceReferenceHFSId = this.purpf.HFServiceReferenceHFSId.value;
    modelCreate.nextStation = this.purpf.nextStation.value;
    modelCreate.DialysisUnitId = this.purpf.DialysisUnitId.value;
    modelCreate.OtherId = this.purpf.OtherId.value;

    return modelCreate;
  }

  ngAfterViewInit() {

  }

  setModel(inProgressPurposeDetails, inProgress) {
    //
    if (inProgress) {

      if (inProgressPurposeDetails)
        this.setModelToForm(inProgressPurposeDetails[0]);

      this.purpf.HFServicePTHMDepartureTime.setValidators([
        CommonCustomValidators.CheckDateAndTimeGreaterValidation(
          this.purpf.HFServicePTHMArrivalTime, this.purpf.HFServicePTHMDepartureTime
        ), Validators.required
      ]);

      this.purpf.HFServicePTHMDepartureTime.updateValueAndValidity();

      this.purpf.HFServicePTHMPurposeCompleted.setValidators(Validators.required);
      this.purpf.HFServicePTHMPurposeCompleted.updateValueAndValidity();
      // this.purpf.HFServicePTHMArrivalTime.disable();
    }
    else {

      this.purpf.HFServicePTHMArrivalTime.setValidators([
        CommonCustomValidators.CheckDateAndTimeGreaterValidation(
          this.purpf.HFServiceVehicleStartTime, this.purpf.HFServicePTHMArrivalTime
        ), Validators.required
      ]);

      this.purpf.HFServicePTHMArrivalTime.updateValueAndValidity();

      this.purpf.HFServicePTHMDepartureTime.disable();
      this.purpf.HFServicePTHMPurposeCompleted.disable();
    }
  }

  setModelToForm(inProgressPurposeDetails) {
    this.purpf.HFServicePurposeDetailId.setValue(inProgressPurposeDetails.hfServicePurposeDetailId);
    this.purpf.HFServicePTHMArrivalTime.setValue(inProgressPurposeDetails.hfServicePTHMArrivalTime);
    this.purpf.HFServicePurposeDetailNotes.setValue(inProgressPurposeDetails.hfServicePurposeDetailNotes);
    this.purpf.CreatedBy.setValue(this.UserId);
    this.purpf.CreatedByName.setValue(this.UserName);
    this.purpf.HFServiceReferenceHFSId.setValue('')
  }


  saveAndClose() {

    if (this.purposeSectionForm.dirty || this.isOtherSection) {
      if (this.purposeSectionForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

          if (result)
            this.dialogRef.close(false);
          else
            this.save();
        });
      }
      else {
        this.save();
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  save() {
    AppComponent.loading = true;
    this.isValidFormSubmitted = true;
    if (this.purposeSectionForm.invalid) {
      //AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      //this.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      return;
    }

    const prepCreate = this.prepareCreateViewModel();
    this.homefieldApi.CreateHomeFieldService_Section_PurposeDetails(prepCreate).subscribe(
      success => {
        AppComponent.loading = false;
        this.SaveEvent.emit(success);
        this.dialogRef.close(prepCreate);
      },
      error => {
        if (error.error && error.error.returnMessage) {
          this.errormsg = error.error.returnMessage.toString();
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          this.errormsg = error.message;
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  };


  get purpf() {
    return this.purposeSectionForm.controls;
  }

  //   getCurrentTime(){
  //     return  this.today.getHours() + ":" + this.today.getMinutes();
  // }

  getCurrentTime() {
    return this.today;
  }

  getToday() {
    let tod = new Date(this.today);
    tod.setHours(0, 0, 0, 0);
    return tod;
  }

  getDisplayTime(val){
    if(val)
      return new Date(val);
    else
      return this.today;
  }

  IsPendingStationDisplayed = false;
  IsNextStationDisplay = false;

  OnNextTaskCheckboxChanged($event){
    if($event.checked){
      this.IsNextStationDisplay = true;
      // if(this.dialysisStationPending.length <= 0)
      //   this.purpf.nextStation.setValue('Other');
      // else
      //   this.purpf.nextStation.setValue('Home');

      this.purpf.nextStation.setValidators(Validators.required);
      this.purpf.nextStation.updateValueAndValidity();
    } else {
      this.purpf.nextStation.setValue('');
      this.IsNextStationDisplay = false;
      this.purpf.nextStation.clearValidators();
      this.purpf.nextStation.updateValueAndValidity();
    }
  }

  OnNextStationChecked($event) {

    // console.log($event);
    if ($event.value == "Home") {
      this.IsPendingStationDisplayed = true;
      this.purpf.HFServiceReferenceHFSId.setValidators(Validators.required);
      this.purpf.HFServiceReferenceHFSId.updateValueAndValidity();
    } else {
      this.IsPendingStationDisplayed = false;
      this.purpf.HFServiceReferenceHFSId.clearValidators();
      this.purpf.HFServiceReferenceHFSId.updateValueAndValidity();
    }
  }


  setOtherSectionModel(){

    this.purpf.HFServicePTHMPurposeCompleted.setValidators(Validators.required);
    this.purpf.HFServicePTHMPurposeCompleted.updateValueAndValidity();

    this.purpf.HFServicePurposeDetailNotes.setValidators(Validators.required);
    this.purpf.HFServicePurposeDetailNotes.updateValueAndValidity();

    this.purpf.HFServicePTHMArrivalTime.clearValidators();
    this.purpf.HFServicePTHMArrivalTime.updateValueAndValidity();
  }
}

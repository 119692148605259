<style>
    .toggle-switch-success {
        color: #fff;
        background: #3a4dab;
        font-weight: normal;
    }

    .toggle-switch-danger {
        color:white;
        background:#3a4dab;
        font-weight: normal;
    }

</style>
<section class="content-header">
    <h1>
        <!-- {{title}} -->
        Dialysis Machine Report
    </h1>
</section>
<section class="content">

    <section>
        <div class="row">
            <div class="box  box-primary">
                <div id="divSearch row">
                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-3">
                                        <label class="col-sm-4 control-label">Division:</label>
                                        <div class="col-sm-8">
                                            <select class="form-control"  [(ngModel)]="selectedDivision">
                                                <option value="{{item.value}}" *ngFor="let item of DialysisUnits ">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                     </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="col-sm-3" style="margin-right: 20px;">
                                            <mat-button-toggle-group multiple [value]="DMReportSelectedValue">
                                                <mat-button-toggle *ngFor="let item of DMReportSearchOptions" style="font-weight: normal;"
                                                value="{{item.name}}" (change)="enableDisableOptions(item.name)"
                                                [ngClass]="{'toggle-switch-success':item.name === 'Interval' && DMReportSelectedValue[0] == 'Interval',
                                                'toggle-switch-danger': item.name === 'Custom Dates' && DMReportSelectedValue[0] == 'Custom Dates' }">
                                                    {{item.name}}
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                        <div >
                                        <div class="col-sm-2"    *ngIf="DMReportSelectedValue[0] == 'Interval'">
                                            <select class="form-control" [(ngModel)]="selectedInterval">
                                                <option value="{{item.value}}"
                                                *ngFor="let item of DMReportIntervalOptions">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3"  *ngIf="DMReportSelectedValue[0] == 'Custom Dates'">
                                            <input (focus)="fromDate.toggleCalendar()" (click)="fromDate.openCalendar()"
                                              class="form-control" style="float:none" placeholder="From Date" angular-mydatepicker name="FromDate"
                                                     [(ngModel)]="FromDate" [options]="myOptions" #fromDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
                                            <span [ngClass]="'error'" *ngIf="FromDateerrormsg">{{FromDateerrormsg}}</span>

                                        </div>
                                        <div class="col-sm-3" *ngIf="DMReportSelectedValue[0] == 'Custom Dates'">
                                            <input  (focus)="toDate.toggleCalendar()" (click)="toDate.openCalendar()"
                                                class="form-control" style="float:none" placeholder="To Date" angular-mydatepicker name="ToDate"
                                                    [(ngModel)]="ToDate" [options]="myOptions" #toDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>
                                            <span [ngClass]="'error'" *ngIf="ToDateerrormsg">{{ToDateerrormsg}}</span>
                                        </div>

                                        <div class="col text-center">
                                            <button style="margin-right:10px;" class="btn btn-primary" id="btnSearchDetail" (click)="executeDmReportSearch()">
                                                <span><i class="fa fa-search"></i></span>
                                                <b>&nbsp;Search</b>
                                            </button>
                                            <button class="btn btn-primary" id="btnPrintDetail" (click)="executeDmReportPrint()">
                                                <span><i class="fa fa-file-pdf-o"></i></span>
                                                <b>&nbsp;Print</b>
                                            </button>
                                        </div>
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            <div class="box-body" id="dvboxbody">
            <div>
            <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                <thead>
                    <tr>
                        <th *ngFor="let tableHeader of tableHeaders"
                            [ngClass]="setSortIndicator(tableHeader.Label)"
                            [style.width.%]="tableHeader.Width" style="white-space:pre-wrap;"
                            (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let DMReportDetail of rows;let index=index;"
                    (click)="_onRowClick(DMReportDetail.equipementId,DMReportDetail.serialNumber, DMReportDetail.equipmentCode,DMReportDetail.serviceDate,DMReportDetail.pmScheduleStatus,DMReportDetail.pmScheduleStatusType)"
                    class="pointer"class="pointer">
                        <td *ngIf="DMReportDetail.serialNumber != 'Total'">{{(pageSize*(currentPageNumber-1)) + (index + 1)}}</td>
                        <td *ngIf="DMReportDetail.serialNumber == 'Total'"></td>
                        <td>{{DMReportDetail.divisionName}}</td>
                        <td>{{DMReportDetail.equipmentCode}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }">{{DMReportDetail.serialNumber}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }">{{DMReportDetail.no__of_Repairs}} <span *ngIf="DMReportDetail.noOfOpenRepair > 0" style="color: red;">*</span></td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }">{{DMReportDetail.no__of_Incidents}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }" *ngIf="DMReportDetail.serialNumber != 'Total'">{{DMReportDetail.machine_Hours}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }" *ngIf="DMReportDetail.serialNumber == 'Total'"> </td>
                        <td style="text-align: right;" [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }"><span *ngIf="DMReportDetail.serialNumber == 'Total'">$</span>{{DMReportDetail.part_Cost_NonWarranty | number:'1.2'}}</td>
                        <td style="text-align: right;" [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }"><span *ngIf="DMReportDetail.serialNumber == 'Total'">$</span>{{DMReportDetail.part_Cost_Warranty | number:'1.2'}}</td>
                        <td style="text-align: right;" [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }"><span *ngIf="DMReportDetail.serialNumber == 'Total'">$</span>{{DMReportDetail.part_Cost | number:'1.2'}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }">{{DMReportDetail.tech_Repair_Hours | number:'1.2'}}</td>
                        <td [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }">{{DMReportDetail.tech_Event_Hours | number:'1.2'}}</td>
                        <td style="text-align: right;" [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }"><span *ngIf="DMReportDetail.serialNumber == 'Total'">$</span>{{DMReportDetail.labour_Hours | number:'1.2'}}</td>
                        <td style="text-align: right;" [ngStyle]="{'font-weight':DMReportDetail.serialNumber == 'Total' ? 'bold' : 'normal' }"><span *ngIf="DMReportDetail.serialNumber == 'Total'">$</span>{{DMReportDetail.labour_Hours + DMReportDetail.part_Cost | number:'1.2'}}</td>
                    </tr>
                    <!-- <tr>
                        <td></td>
                        <td></td>
                        <td style="font-weight: bold;">Totals:</td>
                        <td>{{total_no__of_Repairs}}</td>
                        <td></td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                    </tr> -->
                </tbody>
            </table>
            </div>
            </div>
            </div>
        </div>
    </section>
</section>

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../API/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup; // FormGroup to manage the login form
  submitted = false; // To track form submission

  recaptchaStr = '';
  error = '';

  constructor(private authentication: AuthService, private router: Router, private fb: FormBuilder) { }
  userDesignation = this.authentication.GetDesignationName();
  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]], // Email field with validation
      password: ['', [Validators.required]], // Password field with required validation
    });
  }

  get f() {
    return this.loginForm.controls;
  }


  login() {

    var tempToken = this.authentication.getAccessToken();

    var tempTokenTime = this.authentication.getAccessTokenExpiryDate();

    var token = (tempToken != undefined && tempToken != null) ? tempToken.token : null;
    this.authentication.UserLogin(this.f.email.value, this.f.password.value,  token,tempTokenTime,true).subscribe(list => {
      // console.log(list);
      var token = {
        refresh_token: 'refreshtokencode',
        exp: list.expirysDate,
        access_token: {
          token: list.token,
          UserName: list.userName,
          ContactId: list.contactId,
          DesignationId: list.designationId,
          DesignationName: list.designationName,
          Email: list.email,
          FullName: list.fullName,
          FirstName: list.firstName,
          LastName: list.lastName,
          IsAdminPermission : list.isAdminPermission
        }
      }
      // sessionStorage.setItem('token',list.token)

      this.authentication.setToken(token);
      sessionStorage.setItem("login","true");
      //location.href = '/Dashboard';
      this.userDesignation = token.access_token.DesignationName;

      if(this.userDesignation == 'Administrator' || this.userDesignation=='Technologist' || this.userDesignation=='Senior Technologist' || this.userDesignation == 'Admin-Technologist') {
        location.href = 'Task/TaskViewer';
      } else {
        location.href = 'Home/default';
      }
    },
      error => {
        this.error = error?.error?.message || 'Invalid Username';
      }
    );
  }

  onLoginSubmit(): void {
    this.login();
  }

  onLoginClick(captchaRef: any): void {
    //
    // if (document.getElementById('username')['value']) {
    //   if (this.recaptchaStr) {
    //     captchaRef.reset();

    //   }
    //   captchaRef.execute();
    // }
    // else {
    //   this.error = 'Enter Username';
    // }


    this.login();
  }

  public resolved(captchaResponse: string): void {

    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.onLoginSubmit();
    }
  }
}

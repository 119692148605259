import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class ContactApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    FillDivision() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillDivision',
        '',
        httpOptions
      );
    }

    FillDivisionWithPrefix() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillDivisionWithPrefix',
        '',
        httpOptions
      );
    }

    FillDesignation() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillDesignation',
        '',
        httpOptions
      );
    }

    FillUserType() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillUserType',
        '',
        httpOptions
      );
    }

    FillScheduleUserType() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/FillScheduleUserType',
        '',
        httpOptions
      );
    }

    GetContactDetailsByDesignation(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactDetailsByDesignation',
        value,
        httpOptions
      );
    }

    GetContactDetailsByDesignationName(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactDetailsByDesignationName',
        value,
        httpOptions
      );
    }

    GetContactDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactDetails',
        value,
        httpOptions
      );
    }

    CreateContactDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/CreateContactDetails',
        value,
        httpOptions
      );
    }

    GetContactNotesByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactNotesByID',
        value,
        httpOptions
      );
    }

    GetContactattachementsByID(value) {
      return this.http.post<any>(
        this.apiUrl +
          'UserService/GetContactattachementsByID',
        value,
        httpOptions
      );
    }

    UpdateContactDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/UpdateContactDetails',
        value,
        httpOptions
      );
    }

    DeleteContactDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/DeleteContactDetails',
        value,
        httpOptions
      );
    }
    GetContactDetailsByID(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactDetailsByID',
        value,
        httpOptions
      );
    }

    GetContactDivisionIds(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetContactDivisionIds',
        value,
        httpOptions
      );
    }

    GetNonAdminContactDetails() {
      return this.http.post<any>(
        this.apiUrl + 'UserService/GetNonAdminContactDetails',
        "",
        httpOptions
      );
    }

    FillCountry() {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillCountry',
        '',
        httpOptions
      );
    }

    FillState(value) {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillState',
        value,
        httpOptions
      );
    }

    CreateVendorDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'UserService/CreateVendorDetails',
        value,
        httpOptions
      );
    }

  }

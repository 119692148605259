<style>
    .mat-tooltip
    {
      max-width:100% !important;
    }

    .tooltip-dark{
      /* background:#3a4dab !important; */
      font-size: 14px;
        color:#fff;
        font-weight: 500;
        background-color: #124c8d !important;
    }

    .faplusicon{
      font-size: 22px;
        text-align: center;
        padding-left: 5px;
    }
    </style>
<form  [formGroup]="repairDetailForm" (ngSubmit)="executerepairCreateUpdate('')" novalidate>
    <!-- <RepairPart></RepairPart> -->
    <section class="content-header" *ngIf="!homeService">

            <h1 *ngIf="detailsType == 'Water Process'">
                    {{title}}
            </h1>

            <h1 *ngIf="detailsType == 'Central Delivery'">
                {{headerTextCD}}
            </h1>

            <h1 *ngIf="detailsType == 'other'">
                {{headerTextOther}}
            </h1>

    </section>
    <section class="content">

            <div class="row">
                    <!-- /.box -->
                    <div [ngClass]="{
                        'box box-primary': !homeService
                      }" >

                <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                    (SaveEvent)="executerepairCreateUpdate('')" [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()" #pageheader *ngIf="!homeService"></PageHeader>

                <div [ngClass]="{
                    'box': !homeService }">

                    <div class="box-body" *ngIf="!homeService">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">RepairDetailId</label>

                                <div class="col-sm-10">
                                    <!-- <input type="text" readonly class="form-control" name="RepairDetailId" [value]="f.RepairDetailsId" formControlName="RepairDetailId" > -->
                                    <input type="text" readonly class="form-control" name="HFServiceId"
                                        [value]="f.HFServiceId" formControlName="HFServiceId">
                                </div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class='col-sm-4'>
                                        <label class='col-sm-5 control-label'>Report ID:</label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText"> {{repairId}}</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <label class="col-sm-6 control-label">Form Date:</label>
                                        <div class="col-sm-6">
                                            <label class="readOnlyText">
                                                {{f.formDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}</label>
                                        </div>
                                    </div>

                                    <div class='col-sm-4'>
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired"></span>Created By:</label>
                                        <div class="col-sm-7">
                                            <label class="readOnlyText"> {{UserName}}</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class="clearfix"></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-body box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                    <div class="box-header with-border" style="padding:0px;">
                                        <h3 class="box-title" *ngIf="!homeService">Dialysis Unit Details</h3>
                                        <h3 class="box-title container" *ngIf="homeService">
                                            {{selectedDialysisStationtext}} Details</h3>
                                    </div>
                                </h4>
                            </div>
                        </div>
                        <div class="box-body">

                            <div class="row">
                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class='col-sm-6'>
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired">*</span>Event / Repair / PM: </label>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value > 0">

                                            <label class="readOnlyText">{{selectedEventRepairtext}}</label>
                                        </div>
                                        <div class="col-sm-7"
                                            *ngIf="status!='Completed' && f.RepairDetailsId.value == 0">
                                            <select class="form-control mdInput" formControlName="selectedRepairEvent"
                                                name="selectedRepairEvent">
                                                <option value="{{item.value}}" *ngFor="let item of EventRepairOptions">
                                                    {{item.text}}</option>
                                            </select>
                                            <span
                                                *ngIf="f.selectedRepairEvent.errors?.required && (f.selectedRepairEvent.dirty || f.selectedRepairEvent.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired">*</span>Start Date:</label>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value > 0">
                                            <label class="readOnlyText">
                                                {{timeStartedLabel | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value == 0">
                                            <input (focus)="rStartDate.toggleCalendar()"
                                                (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                                name="timeStarted" formControlName="timeStarted" [options]="myOptions"
                                                #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                required />
                                            <span
                                                *ngIf="f.timeStarted.errors?.required && (f.timeStarted.dirty || f.timeStarted.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired">*</span>Division:</label>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value > 0">
                                            <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value == 0">
                                            <select class="form-control mdInput" formControlName="selectedDialysisUnits"
                                                name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                                                <option value="{{item.value}}"
                                                    *ngFor="let item of DialysisUnitsWithoutHome">{{item.text}}</option>
                                            </select>

                                            <span
                                                *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>

                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired">*</span>Equipment:</label>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value > 0">

                                            <label class="readOnlyText"> {{selectedEquipmenttext}}</label>

                                        </div>
                                        <div class="col-sm-7" *ngIf="f.RepairDetailsId.value == 0">

                                            <select class="form-control mdInput" formControlName="selectedEquipment"
                                                name="selectedEquipment" (change)="EquipmentDropDownChanged()">
                                                <option value="{{item.value}}" *ngFor="let item of Equipments">
                                                    {{item.text}}</option>
                                            </select>

                                            <span
                                                *ngIf="f.selectedEquipment.errors?.required && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.selectedEquipment.errors?.RepairStatus" [ngClass]="'error'">
                                                You cannot start a new Repair, while there is an open Repair still
                                                existing in the system.
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>

                                <div class="clearfix"></div>



                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-12">
                                        <label class="control-label col-md-2" style="width:20.4%"><span
                                                class="text-red inputrequired">*</span>Machine Issue:</label>
                                        <div class="col-md-9" style="width:79.55%"
                                            *ngIf="((f.RepairDetailsId.value > 0 && blnHoursInVal == false) || (homeService == true && showRemarks == false && (status =='Completed' || status =='')))">
                                            <label class="readOnlyText" style="word-wrap: break-word;">
                                                {{f.remarks.value}}</label>
                                        </div>
                                        <div class="col-md-9" style="width:79.55%"
                                            *ngIf="(((f.RepairDetailsId.value == 0 || blnHoursInVal == true) && !homeService) ||(homeService == true && showRemarks == true && status !=='Completed' && f.RepairDetailsId.value == 0))">
                                            <textarea name="remarks" formControlName="remarks" rows='3'
                                                class="form-textarea lgInput" required></textarea>
                                            <span
                                                *ngIf="f.remarks.errors?.required && (f.remarks.dirty || f.remarks.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>


                                    </div>
                                </div>
                                <div class="clearfix"></div>


                            </div>
                        </div>
                    </div>

                    <div class="box box-body ibox box-info" *ngIf="hrsTracking">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                    <a [ngClass]="collapsedmEquiInClass" data-toggle="collapse" data-parent="#accordion"
                                        href="#dmEquipIn" aria-expanded="collapseariaexpanded"
                                        aria-controls="collapsedmEquiIn" id="dmEquipOutRefID">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">Equipment Hours in Details
                                                <span *ngIf="f.hoursin.value != ''"><i
                                                        class="fa fa-plus-square faplusicon"></i></span>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>
                        </div>
                        <div id="dmEquipIn" *ngIf="hfcompleted==true" [ngClass]="collapsedmEquiIn" role="tabpanel"
                            aria-labelledby="dmEquipIns">
                            <div class="box-body">
                                <div class="row">
                                    <div class="col-sm-6" *ngIf="homeService">
                                        <label class="col-sm-5 control-label"><span
                                                class="text-red inputrequired">*</span>Equipment:</label>
                                        <div class="col-sm-3">
                                            <label class="readOnlyText"> {{selectedEquipmenttext}}</label>
                                        </div>
                                        <div class="col-sm-3 readOnlyText"
                                            style="text-align:center;    padding: 5px 0;cursor:auto">
                                            <button class="btn btn-primary  no-print btn-label-left center-block btn-xs"
                                                type="button" title="History"
                                                (click)="OpenHistory(f.selectedEquipment.value)"
                                                style="display:inline-block;"
                                                [attr.disabled]="disableControl?'true':null">
                                                <i class="fa fa-history" style="color: #fff;"></i>
                                            </button>
                                            <button class="btn btn-primary  no-print btn-label-left center-block btn-xs"
                                                type="button" *ngIf="f.RepairDetailsId.value == 0" title="Exchange"
                                                (click)="transferStationEquipment('RO')"
                                                style="display: inline-block;margin-left: 10px;"
                                                [attr.disabled]="disableControl?'true':null">
                                                <i class="fa fa-exchange" style="color: #fff;"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 box-row">

                                        <label class="col-sm-5 control-label"> <span
                                                class="text-red inputrequired">*</span>Hours In:</label>
                                        <div class="col-sm-7"
                                            *ngIf="f.RepairDetailsId.value > 0 && blnHoursInVal == false">

                                            <label class="readOnlyText"> {{f.hoursin.value}}</label>

                                        </div>
                                        <div class="col-sm-7"
                                            *ngIf="f.RepairDetailsId.value == 0 || blnHoursInVal == true">
                                            <input maxlength="5" class="form-control smInput" name="hoursin"
                                                formControlName="hoursin" (keypress)="numberOnly($event)" required>
                                            <label class="readOnlyTextForHours"
                                                *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                            <label class="readOnlyTextForHours"
                                                *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>
                                            <br />
                                            <span
                                                *ngIf="f.hoursin.errors?.required && (f.hoursin.dirty || f.hoursin.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.hoursin.errors?.pattern" [ngClass]="'error'">
                                                Invalid number
                                            </span>

                                            <span *ngIf="f.hoursin.errors?.GreaterHoursOut" [ngClass]="'error'">
                                                Hrs IN => previous Hrs Out
                                            </span>
                                            <span *ngIf="check4000HoursValidation()" [ngClass]="'error'">
                                                New Hours >= 4000 from previous Hours Out.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div class="box box-body ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference"
                                tooltip-class="tooltip-dark" tooltipPlacement="top">
                                <h4 class="panel-title">

                                    <div class="box-header with-border" style="padding: 0px;">
                                        <h3 class="box-title container">Notes & Replaced Parts
                                            <span style="float: right;" *ngIf="parts != undefined && parts.PartTrans && parts.PartTrans.length >0 && totalCost != undefined">Total Cost : {{totalCost | currency}}</span>
                                            <!-- <span
                                                *ngIf="(notes &&  notes.NoteList && notes.NoteList.length > 0) || (parts && parts.PartTrans && parts.PartTrans.length >0)"><i
                                                    class="fa fa-plus-square faplusicon"></i></span> -->
                                        </h3>
                                    </div>

                                </h4>
                            </div>

                            <notes [isValidForm]="this.repairDetailForm.invalid" (CheckValidForm)="GreaterValueCheck()"
                                (addNoteClick)="executerepairCreateUpdate('Notes')" #notes>
                            </notes>
                            <Parts_Component #parts *ngIf="ShowParts == true"></Parts_Component>

                        </div>
                    </div>

                    <div class="box box-body ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmEquipOuts"
                                tooltip-class="tooltip-dark">
                                <h4 class="panel-title">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                        href="#dmEquipOut" aria-expanded="false" aria-controls="dmEquipOutRef"
                                        (click)="dmEquipOutChange($event)" id="dmEquipOutRefID">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">Equipment Hours Out Details <span
                                                    *ngIf="status != 'Completed'"
                                                    style="font-size: 14px;color: red;margin-left: 15px;"> (Click here
                                                    for filling the details to Complete Repair) </span>
                                                <i *ngIf="f.hoursout.value || f.timeFinished.value || f.outHydraulicDisinfection.value"
                                                    style="pointer-events: none;"
                                                    class="fa fa-plus-square faplusicon"></i>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>

                            <div id="dmEquipOut" *ngIf="hfcompleted==true" class="panel-collapse collapse"
                                role="tabpanel" aria-labelledby="dmEquipOuts">
                                <div class="box-body" *ngIf="!homeService">

                                    <div class="row">
                                        <div class="col-sm-4 box-row" *ngIf="hrsTracking">

                                            <label class="col-sm-5 control-label"><span
                                                    class="text-red inputrequired">*</span>Hours Out: </label>
                                            <div class="col-sm-7" *ngIf="status!='Completed'">
                                                <input maxlength="5" class="form-control smInput" name="hoursout"
                                                    formControlName="hoursout" (keypress)="numberOnly($event)">
                                                    <span  *ngIf="f.RepairDetailsId.value == 0">
                                                        ({{f.hoursin.value}})
                                                    </span>
                                                    <span  *ngIf="f.RepairDetailsId.value != 0">
                                                      ({{selectedEquipmentHours}})
                                                  </span>
                                                  <span style=" display: inline-block;margin-left:10px;"
                                                  *ngIf="f.hoursin.value != '' && selectedEquipmentHours > f.hoursin.value"
                                                  class="btn btn-primary center-block btn-xs"
                                                  (click)="OpenHistory(f.selectedEquipment.value)" title="History">
                                                  <span><i class="fa fa-history"></i></span>
                                          </span>
                                                <span
                                                    *ngIf="f.hoursout.errors?.required && (f.hoursout.dirty || f.hoursout.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    <br>Required!
                                                </span>
                                                <span *ngIf="f.hoursout.errors?.InGreaterOut" [ngClass]="'error'"
                                                    id="spanInGreaterOut" style="display: block;">
                                                    Hrs Out => previous Hrs Out/Hrs In
                                                </span>
                                                <span *ngIf="f.hoursout.errors?.pattern" [ngClass]="'error'">Invalid
                                                    number</span>

                                                <span *ngIf="checkHoursInOutDifference() && status != 'Completed' && f.hoursin.value == selectedEquipmentHours.toString()"
                                                    [ngClass]="'error'">
                                                    Hours Out >= Hours In + 100.
                                                </span>
                                                <!-- <span>
                                                            {{f.hoursout.invalid && f.hoursout.value!=null}} {{f.hoursout.value}}
                                                        </span> -->
                                            </div>
                                            <div class="col-sm-7" *ngIf="status=='Completed'">
                                                <label class="control-label"> {{f.hoursout.value}}</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <label class="col-sm-4 control-label"><span
                                                    class="text-red inputrequired">*</span>Date Out:</label>
                                            <div class="col-sm-7" *ngIf="status=='Completed'">
                                                <label class="control-label">
                                                    {{timeFinishedLabel | date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-sm-7" *ngIf="status!='Completed'" style="padding-left:15px">
                                                <input (focus)="equipmentdateout.toggleCalendar()"
                                                    (click)="equipmentdateout.openCalendar()"
                                                    class="form-control mdInput" style="float:none"
                                                    placeholder="Select a date" angular-mydatepicker name="timeFinished"
                                                    formControlName="timeFinished" [options]="myOptions"
                                                    #equipmentdateout="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />

                                                <span
                                                    *ngIf="f.timeFinished.errors?.required && (f.timeFinished.dirty || f.timeFinished.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span
                                                    *ngIf='f.timeFinished.errors?.error || f.timeFinished.errors?.InGreaterDateOut'
                                                    [ngClass]="'error'">
                                                    Date Out must be
                                                    greater than or equal
                                                    to Date In!
                                                </span>
                                            </div>

                                        </div>

                                        <div class="col-sm-4">
                                            <label class="col-sm-7 control-label"><span
                                                    class="text-red inputrequired">*</span>Out Disinfection:</label>
                                            <div class="col-sm-5">
                                                <label *ngIf="status=='Completed'" class="control-label">
                                                    {{f.outHydraulicDisinfection.value}}</label>
                                                <select *ngIf="status!='Completed'" class="form-control mdInput"
                                                    formControlName="outHydraulicDisinfection"
                                                    name="outHydraulicDisinfection">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of hydraulicDisinfectionList">{{item.text}}
                                                    </option>
                                                </select>
                                                <span
                                                    *ngIf="f.outHydraulicDisinfection.errors?.required && (f.outHydraulicDisinfection.dirty || f.outHydraulicDisinfection.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>

                                <div class="box-body" *ngIf="homeService">

                                    <div class="row">
                                        <div class="col-sm-4 box-row">

                                            <label class="col-sm-5 control-label"><span
                                                    class="text-red inputrequired">*</span>Hours Out: </label>
                                            <div class="col-sm-7" *ngIf="status!='Completed'">
                                                <input maxlength="5" class="form-control smInput" name="hoursout"
                                                    formControlName="hoursout" (keypress)="numberOnly($event)">
                                                ({{f.hoursin.value}})
                                                <span
                                                    *ngIf="f.hoursout.errors?.required && (f.hoursout.dirty || f.hoursout.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    <br>Required!
                                                </span>
                                                <span *ngIf="f.hoursout.errors?.InGreaterOut" [ngClass]="'error'"
                                                    id="spanInGreaterOut">
                                                    Hours Out must be greater than or Equal to Hours In.
                                                </span>
                                                <span *ngIf="f.hoursout.errors?.pattern" [ngClass]="'error'">Invalid
                                                    number</span>

                                                <span *ngIf="checkHoursInOutDifference() && status != 'Completed'"
                                                    [ngClass]="'error'">
                                                    Hours Out >= Hours In + 100.
                                                </span>
                                                <!-- <span>
                                                            {{f.hoursout.invalid && f.hoursout.value!=null}} {{f.hoursout.value}}
                                                        </span> -->
                                            </div>
                                            <div class="col-sm-7" *ngIf="status=='Completed'">
                                                <label class="control-label"> {{f.hoursout.value}}</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-3">
                                            <label class="col-sm-6 control-label"><span
                                                    class="text-red inputrequired">*</span>Date Out:</label>
                                            <div class="col-sm-6" *ngIf="status=='Completed'">
                                                <label class="control-label">
                                                    {{timeFinishedLabel | date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-sm-6" *ngIf="status!='Completed'"
                                                style="padding-left:15px; padding-right: 7px;">
                                                <input (focus)="equipmentdateout.toggleCalendar()"
                                                    (click)="equipmentdateout.openCalendar()"
                                                    class="form-control mdInput" style="float:none"
                                                    placeholder="SelectDate" angular-mydatepicker name="timeFinished"
                                                    formControlName="timeFinished" [options]="myOptions"
                                                    #equipmentdateout="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />

                                                <span
                                                    *ngIf="f.timeFinished.errors?.required && (f.timeFinished.dirty || f.timeFinished.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span
                                                    *ngIf='f.timeFinished.errors?.error || f.timeFinished.errors?.InGreaterDateOut'
                                                    [ngClass]="'error'">
                                                    Date Out must be
                                                    greater than or equal
                                                    to Date In!
                                                </span>
                                            </div>

                                        </div>

                                        <div class="col-sm-5">
                                            <label class="col-sm-7 control-label"><span
                                                    class="text-red inputrequired">*</span>Out Hydraulic
                                                Disinfection:</label>
                                            <div class="col-sm-5">
                                                <label *ngIf="status=='Completed'" class="control-label">
                                                    {{f.outHydraulicDisinfection.value}}</label>
                                                <select *ngIf="status!='Completed'" class="form-control mdInput"
                                                    formControlName="outHydraulicDisinfection"
                                                    name="outHydraulicDisinfection">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of hydraulicDisinfectionList">{{item.text}}
                                                    </option>
                                                </select>
                                                <span
                                                    *ngIf="f.outHydraulicDisinfection.errors?.required && (f.outHydraulicDisinfection.dirty || f.outHydraulicDisinfection.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="box box-body ibox box-info">

                        <div class="panel panel-default"
                            *ngIf="((f.workOrderRefNo.value != '' && status=='Completed') || status!='Completed')">
                            <div class="panel-heading" role="tab" id="dmVendor"
                                tooltip-class="tooltip-dark" tooltipPlacement="top">
                                <h4 class="panel-title">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion"
                                        href="#dmVendorRef" aria-expanded="false" aria-controls="dmVendorRef">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title container">Vendor Details
                                                <span *ngIf="f.workOrderRefNo.value != ''"><i
                                                        class="fa fa-plus-square faplusicon"></i></span>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>

                            <div id="dmVendorRef" class="panel-collapse collapse" role="tabpanel"
                                aria-labelledby="dmVendor">
                                <div class="panel-body">
                                    <div class="col-sm-12 box-row" *ngIf="!homeService">
                                        <label class="col-sm-2 control-label  longtitle">Vendors Work Order Ref:</label>

                                        <div class="col-sm-10">
                                            <label *ngIf="status=='Completed'" class="control-label">
                                                {{f.workOrderRefNo.value}}</label>
                                            <input type="text" *ngIf="status!='Completed'" class="form-control mdInput"
                                                name="workOrderRefNo" formControlName="workOrderRefNo" />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 box-row" *ngIf="homeService">
                                        <label class="col-sm-3 control-label  longtitle">Vendors Work Order Ref:</label>

                                        <div class="col-sm-9">
                                            <label *ngIf="status=='Completed'" class="control-label">
                                                {{f.workOrderRefNo.value}}</label>
                                            <input type="text" *ngIf="status!='Completed'" class="form-control mdInput"
                                                name="workOrderRefNo" formControlName="workOrderRefNo" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <attachment (RaiseError)="AttachmentError($event)" #attachment>

                    </attachment>


                    <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" *ngIf="!homeService"
                        [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                        (SaveEvent)="executerepairCreateUpdate('')" [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>

                </div>
            </div>
        </div>
    </section>

</form>

<form
    [formGroup]="AcidForm"
    (ngSubmit)="executeAcidCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
                Acid Drum Filling
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    (addNoteClick)="notes.AddNote()"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeAcidCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">AcidsId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="AcidsId"
                                        [value]="f.AcidsId"
                                        formControlName="AcidsId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row" >

                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">


                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row" *ngIf="f.AcidsId.value > 0">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Event Code:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{eventId}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="col-sm-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 box-row" *ngIf="f.AcidsId.value > 0">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Date:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{f.AcidDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                            </div>
                                            <div
                                                class="col-sm-6"

                                            >
                                                <label class="col-sm-7 control-label">Time:</label>
                                                <div
                                                    class="col-sm-5"

                                                >
                                                    <label class="readOnlyText">
                                                        {{f.AcidDate.value.jsdate | date:'HH:mm'}}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Division:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.AcidsId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.AcidsId.value == 0"
                                                >

                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits"
                                                        (change)="DialysisUnitDropDownChanged()"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="f.AcidsId.value > 0"
                                            >
                                                <label class="col-sm-7 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Technologist:
                                                </label>

                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">{{selectedUsertext}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Acid Container:
                                                </label>
                                                <div
                                                    class="col-sm-3"
                                                    *ngIf="f.AcidsId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{selectedAcidContainerTxt}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-3"
                                                    *ngIf="f.AcidsId.value == 0"
                                                >

                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedAcidContainer"
                                                        name="selectedAcidContainer"
                                                    >

                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of AcidContainerList"
                                                        >

                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedAcidContainer.errors?.required && (f.selectedAcidContainer.dirty || f.selectedAcidContainer.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                                <div
                                                class="col-sm-4" style="padding-left: 0px;padding-right: 0px"

                                            > <label class="control-label">
                                                <span class="text-red inputrequired">*</span>
                                                # of Drums:
                                            </label><select *ngIf="f.AcidsId.value == 0"
                                            class="form-control mdInput"
                                            formControlName="selectedDrumNo"
                                            name="selectedDrumNo" style="width:90px;margin-left:10px"
                                        >

                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of DrumList"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select> <label class="readOnlyText" *ngIf="f.AcidsId.value > 0">{{f.selectedDrumNo.value}}</label></div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-7 control-label">
                                                    <span class="text-red inputrequired">*</span>Scanned Lot Number for Drum(s):
                                                </label>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="f.AcidsId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.LotNumber.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-5"
                                                    *ngIf="f.AcidsId.value == 0"
                                                >

                                                    <input
                                                        class="form-control mdInput"
                                                        formControlName="LotNumber"
                                                        name="LotNumber"

                                                    >


                                                    <span
                                                        *ngIf="f.LotNumber.errors?.required && (f.LotNumber.dirty || f.LotNumber.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                          <div class="col-sm-6">
                                              <label class="col-sm-5 control-label">
                                                  <span class="text-red inputrequired">*</span>
                                                  Acid Level Prior (L):
                                              </label>
                                              <div
                                                  class="col-sm-7"
                                                  *ngIf="f.AcidsId.value > 0"
                                              >
                                                  <label class="readOnlyText">{{f.AcidLevelPrior.value}}</label>
                                              </div>
                                              <div
                                                  class="col-sm-7"
                                                  *ngIf="f.AcidsId.value == 0"
                                              >

                                                  <input type="text"
                                                      class="form-control mdInput"
                                                      formControlName="AcidLevelPrior"
                                                      name="AcidLevelPrior" maxlength="8"
                                                  />


                                                  <span
                                                      *ngIf="f.AcidLevelPrior.errors?.required && (f.AcidLevelPrior.dirty || f.AcidLevelPrior.touched ||  isValidFormSubmitted==false)"
                                                      [ngClass]="'error'"
                                                  >
                                                      Required!
                                                  </span>
                                                  <span *ngIf="f.AcidLevelPrior.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                  <span *ngIf="f.AcidLevelPrior.errors?.InGreaterOut" [ngClass]="'error'">
                                                      <br/>Post Level Should Be Greater Than Prior Level.
                                                  </span>
                                              </div>
                                          </div>
                                          <div class="col-sm-6">
                                            <label class="col-sm-7 control-label">
                                                <span class="text-red inputrequired">*</span>
                                                Vendor:
                                            </label>
                                            <div
                                                class="col-sm-5"
                                                *ngIf="f.AcidsId.value > 0"
                                            >
                                                <label class="readOnlyText">{{VendorName}}</label>
                                            </div>
                                            <div
                                                class="col-sm-5"
                                                *ngIf="f.AcidsId.value == 0"
                                            >

                                            <select
                                            class="form-control mdInput"
                                            formControlName="selectedVender"
                                            name="selectedVender"
                                        >
                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of VendorList"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select>


                                                <span
                                                    *ngIf="f.selectedVender.errors?.required && (f.selectedVender.dirty || f.selectedVender.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>
                                                <span *ngIf="f.selectedVender.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            </div>
                                        </div>

                                      </div>
                                      <div class="clearfix"></div>

                                      <div class="col-sm-12 box-row">
                                        <div class="col-sm-6">
                                            <label class="col-sm-5 control-label">
                                                <span class="text-red inputrequired">*</span>
                                                Confirmation User Name:
                                            </label>
                                            <div
                                                class="col-sm-7"
                                                *ngIf="f.AcidsId.value > 0 || this.ConfirmationUserId >0"
                                            >
                                                <label class="readOnlyText">{{ConfirmUser}}</label>
                                            </div>
                                            <div
                                                class="col-sm-7"
                                                *ngIf="f.AcidsId.value == 0 && this.ConfirmationUserId == 0"
                                            >

                                            <input type="text"
                                            class="form-control mdInput"
                                            formControlName="UserName" (change) ="GreaterValueCheck()"
                                            name="UserName"
                                        >



                                                <span
                                                    *ngIf="f.UserName.errors?.required && (f.UserName.dirty || f.UserName.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-sm-6" *ngIf="f.AcidsId.value == 0  && this.ConfirmationUserId == 0" >
                                          <label class="col-sm-7 control-label">
                                              <span class="text-red inputrequired">*</span>
                                              Confirmation User Pin ID:
                                          </label>
                                          <div
                                              class="col-sm-5"
                                              *ngIf="f.AcidsId.value > 0"
                                          >
                                              <label class="readOnlyText">{{f.PinID.value}}</label>
                                          </div>
                                          <div
                                              class="col-sm-5"
                                              *ngIf="f.AcidsId.value == 0"
                                          >

                                          <input type="password"
                                          class="form-control mdInput"
                                          formControlName="PinID"
                                          name="PinID" (keypress)="handleInput($event)"
                                      >



                                              <span
                                                  *ngIf="f.PinID.errors?.required && (f.PinID.dirty || f.PinID.touched ||  isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"
                                              >
                                                  Required!
                                              </span>
                                              <span  *ngIf="f.PinID.errors?.UserValidate" [ngClass] = "'error'">
                                                InValid UserName or Pin Id
                                             </span>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row" *ngIf="this.ConfirmationUserId > 0">
                                      <div class="col-sm-6">
                                          <label class="col-sm-5 control-label">
                                              <span class="text-red inputrequired">*</span>
                                              Acid Level Post (L):
                                          </label>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.AcidsId.value > 0"
                                          >
                                              <label class="readOnlyText">{{f.AcidLevelPost.value}}</label>
                                          </div>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.AcidsId.value == 0"
                                          >

                                              <input type="text"
                                                  class="form-control mdInput"
                                                  formControlName="AcidLevelPost"
                                                  name="AcidLevelPost"  (keyup)="GreaterValueCheck()" maxlength="8"
                                              />


                                              <span
                                                  *ngIf="f.AcidLevelPost.errors?.required && (f.AcidLevelPost.dirty || f.AcidLevelPost.touched ||  isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"
                                              >
                                                  Required!
                                              </span>
                                              <span *ngIf="f.AcidLevelPost.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                          </div>
                                      </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>


                            <notes
                                [isValidForm]="this.AcidForm.invalid"
                                (addNoteClick)="executeAcidCreateUpdate('Notes')"
                                #notes
                            >

                            </notes>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        (addNoteClick)="notes.AddNote()"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeAcidCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

<form [formGroup]="dailyDetailForm" (ngSubmit)="executeWPDailyCreateUpdate('')" novalidate>


    <!-- <RepairPart></RepairPart> -->
    <!-- <section class="content-header">
            <h1>
                    Water Process Daily Record<sup  class="beta">[beta]</sup>

            </h1>

        </section> -->
    <!-- <section class="content"> -->
        <div class="row">
            <!-- /.box -->
            <!-- <div class="box  box-primary"> -->
                    <div >
                <!-- <PageHeader title="Water Process Daily Record" [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeWPDailyCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader> -->
                    <div class="col-md-12">
                <!-- <div class="col-md-12 box"> -->
                            <!-- <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">WPDailyDetailsId</label>

                                <div class="col-sm-10">
                                    <input type="text" readonly class="form-control" name="WPDailyDetailsId" [value]="f.WPDailyDetailsId" formControlName="WPDailyDetailsId">
                                </div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="box-body">
                                <div class="col-sm-12 box-row ">

                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Event ID:</label>

                                    <div class="col-sm-10">
                                        <label class="readOnlyText"> {{eventId}}</label>
                                    </div>
                                </div>


                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">

                                    <label class="col-sm-2 control-label">
                                        <span class="text-red inputrequired">*</span>Division:</label>
                                    <div class="col-sm-10" *ngIf="f.WPDailyDetailsId.value > 0">
                                        <label class="readOnlyText">{{DUName}}</label>
                                    </div>
                                    <div class="col-sm-10" *ngIf="f.WPDailyDetailsId.value == 0">


                                        <select class="form-control mdInput" formControlName="selectedDialysisUnits" name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                                            <option value="{{item.value}}" *ngFor="let item of DialysisUnits">{{item.text}}</option>
                                        </select>
                                        <span *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span>

                                        <span *ngIf="f.selectedDialysisUnits.errors?.DivisionRecExists" [ngClass]="'error'">
                                            Daily record already exist for today.
                                        </span>

                                    </div>

                                </div>
                                </div>
                                <div class="clearfix"></div>

                            </div> -->
                            <div class="box ibox box-info">
                                <div class="box-header with-border">
                                    <h3 class="box-title">AM</h3>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="box-row col-sm-12 divider">
                                            <div class="col-sm-4">
                                                <strong>Raw Water Filters
                                                </strong>
                                            </div>
                                            <div class="col-sm-4">

                                            </div>
                                            <div class="col-sm-4">
                                                <strong>Temperature
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-4">

                                                <label class="col-sm-8 control-label">
                                                    P (psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.RawWaterFiltersP.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="RawWaterFiltersP" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersP,45,90)}"
                                                        class="form-control smInput" formControlName="RawWaterFiltersP" placeholder="###"
                                                        (keyup)="GreaterValueCheck()" required />

                                                    <span *ngIf="f.RawWaterFiltersP.errors?.required && (f.RawWaterFiltersP.dirty || f.RawWaterFiltersP.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.RawWaterFiltersP.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                                    <!-- <span  *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass] = "'error'">
                                                        P should be higher than Q.
                                                    </span> -->
                                                </div>


                                            </div>


                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Q(psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.RawWaterFiltersQ.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="RawWaterFiltersQ" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.RawWaterFiltersQ,45,90)}"
                                                        class="form-control smInput" formControlName="RawWaterFiltersQ" name="RawWaterFiltersQ" placeholder="###"
                                                        (keyup)="GreaterValueCheck()" required>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.required && (f.RawWaterFiltersQ.dirty || f.RawWaterFiltersQ.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label" *ngIf="!IsShepardForm">
                                                    Blending Valve (°C):</label>
                                                    <label class="col-sm-8 control-label" *ngIf="IsShepardForm">
                                                    Keltech (°C):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.BlendingValve.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  [ngClass]="{'OurOffRange':checkRange(f.BlendingValve,10,25)}" name="BlendingValve" maxlength="10" class="form-control smInput"
                                                        formControlName="BlendingValve" name="BlendingValve" required placeholder="##.#">
                                                    <span *ngIf="f.BlendingValve.errors?.required && (f.BlendingValve.dirty || f.BlendingValve.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.BlendingValve.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label"></label>

                                                <div class="col-sm-7">
                                                    <span *ngIf="f.RawWaterFiltersQ.errors?.InGreaterOut" [ngClass]="'error'">
                                                        P should be higher than Q.
                                                    </span>
                                                    <span *ngIf="f.RawWaterFiltersP.errors?.maxLengthVal" [ngClass]="'error'">
                                                       Max 10 digit allow.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider">
                                            <div class="col-sm-4">
                                                <strong>Softener
                                                </strong>
                                            </div>

                                            <div class="col-sm-4">

                                            </div>
                                            <div class="col-sm-4">
                                                <strong>Hardness
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Capacity Remain(x1000 gal):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.CapacityRemaining.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="CapacityRemaining" maxlength="10" class="form-control smInput" formControlName="CapacityRemaining"
                                                        name="CapacityRemaining" required  placeholder="##.#">
                                                    <span *ngIf="f.CapacityRemaining.errors?.required && (f.CapacityRemaining.dirty || f.CapacityRemaining.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.CapacityRemaining.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>


                                            <div class="col-sm-4">

                                            </div>

                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    <span class="text-red inputrequired"></span>Total Hardness (gpg):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.TotalHardness.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <select name="TotalHardness" class="form-control smInput" formControlName="TotalHardness" name="TotalHardness" required>
                                                        <option value="{{item.value}}" *ngFor="let item of HardNessList">{{item.text}}</option>
                                                    </select>

                                                    <span *ngIf="f.TotalHardness.errors?.required && (f.TotalHardness.dirty || f.TotalHardness.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.TotalHardness.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12  divider">
                                            <div class="col-sm-4">
                                                <strong>DPD Test Pre-Carbon
                                                </strong>
                                            </div>

                                            <div class="col-sm-4">

                                            </div>
                                            <div class="col-sm-4">
                                                <strong>DPD Test Post-Carbon
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Total Chlorine (mg/L):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.DPDTestPreTotalChlorine.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  [ngClass]="{'OurOffRange':checkRange(f.DPDTestPreTotalChlorine,0.01,2)}" name="DPDTestPreTotalChlorine"
                                                        maxlength="10" class="form-control smInput" formControlName="DPDTestPreTotalChlorine" placeholder="#.##"
                                                        name="DPDTestPreTotalChlorine" required>
                                                    <span *ngIf="f.DPDTestPreTotalChlorine.errors?.required && (f.DPDTestPreTotalChlorine.dirty || f.DPDTestPreTotalChlorine.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.DPDTestPreTotalChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>

                                            <div class="col-sm-4">

                                            </div>

                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Total Chlorine (mg/L):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.DPDTestPostTotalChlorine.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  [ngClass]="{'OurOffRange':checkRange(f.DPDTestPostTotalChlorine,0.01,0.08)}" name="DPDTestPostTotalChlorine"
                                                        maxlength="10" class="form-control smInput" formControlName="DPDTestPostTotalChlorine" placeholder="#.##"
                                                        name="DPDTestPostTotalChlorine" required>
                                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.required && (f.DPDTestPostTotalChlorine.dirty || f.DPDTestPostTotalChlorine.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.DPDTestPostTotalChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12">
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Free Chlorine (mg/L):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.DPDTestPreFreeChlorine.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="DPDTestPreFreeChlorine" maxlength="10" class="form-control smInput" [ngClass]="{'OurOffRange':checkRange(f.DPDTestPreFreeChlorine,0.01,0.05)}"
                                                        formControlName="DPDTestPreFreeChlorine" name="DPDTestPreFreeChlorine" placeholder="#.##">
                                                    <span *ngIf="f.DPDTestPreFreeChlorine.errors?.required && (f.DPDTestPreFreeChlorine.dirty || f.DPDTestPreFreeChlorine.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.DPDTestPreFreeChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>




                                            <div class="col-sm-4">

                                            </div>

                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    Free Chlorine (mg/L):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.DPDTestPostFreeChlorine.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="DPDTestPostFreeChlorine" maxlength="10" class="form-control smInput" formControlName="DPDTestPostFreeChlorine"
                                                        name="DPDTestPostFreeChlorine" [ngClass]="{'OurOffRange':checkRange(f.DPDTestPostFreeChlorine,0.01,0.08)}" placeholder="#.##">
                                                    <span *ngIf="f.DPDTestPostFreeChlorine.errors?.required && (f.DPDTestPostFreeChlorine.dirty || f.DPDTestPostFreeChlorine.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.DPDTestPostFreeChlorine.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="box-row col-sm-12 divider">
                                            <div class="col-sm-4">
                                                <strong>PRE Filters
                                                </strong>
                                            </div>

                                            <div class="col-sm-4">

                                            </div>
                                            <div class="col-sm-4">

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <!--Suxess field start-->
                                        <div *ngIf="!IsShepardForm" class="box-row col-sm-12">
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    W (psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.ROPreW.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="ROPreW" maxlength="10" (keyup)="GreaterValueCheck()" [ngClass]="{'OurOffRange':checkRange(f.ROPreW,30,85)}"
                                                        class="form-control smInput" formControlName="ROPreW" name="ROPreW" required placeholder="###">
                                                    <span *ngIf="f.ROPreW.errors?.required && (f.ROPreW.dirty || f.ROPreW.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required! </span>
                                                    <span *ngIf="f.ROPreW.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                                </div>
                                            </div>
                                            <div  class="col-sm-4">
                                                <label class="col-sm-8 control-label">
                                                    X (psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.ROPreX.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="ROPreX" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.ROPreX,30,85)}" class="form-control smInput"
                                                        formControlName="ROPreX" name="ROPreX" required placeholder="###">
                                                    <span *ngIf="f.ROPreX.errors?.required && (f.ROPreX.dirty || f.ROPreX.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required! </span>
                                                    <span *ngIf="f.ROPreX.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                                </div>
                                            </div>


                                            <div class="col-sm-4"></div>



                                        </div>
                                        <div *ngIf="!IsShepardForm" class="clearfix"></div>
                                        <div *ngIf="!IsShepardForm" class="box-row col-sm-12">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label"></label>

                                                <div class="col-sm-7">
                                                    <span *ngIf="f.ROPreX.errors?.InGreaterOut" [ngClass]="'error'">
                                                        W should be higher than X.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Suxess field end-->
                                       <!--Shephard field start-->
                                        <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label"> Pressure Gauge Pl1615 (psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.P6_PSI.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="P6_PSI" maxlength="10" class="form-control smInput"
                                                        formControlName="P6_PSI" name="P6_PSI" placeholder="###">
                                                    <span *ngIf="f.P6_PSI.errors?.required && (f.P6_PSI.dirty || f.P6_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                            Required! </span>
                                                    <span *ngIf="f.P6_PSI.errors?.pattern"
                                                        [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label"> Pressure Gauge Pl1620 (psi):</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.P7_PSI.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="P7_PSI" maxlength="10" class="form-control smInput"
                                                        formControlName="P7_PSI" name="P7_PSI" placeholder="###">
                                                    <span *ngIf="f.P7_PSI.errors?.required && (f.P7_PSI.dirty || f.P7_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                            Required! </span>
                                                    <span *ngIf="f.P7_PSI.errors?.pattern"
                                                        [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="IsShepardForm" class="clearfix"></div>
                                        <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                                                <div class="col-sm-4">
                                                        <label class="col-sm-8 control-label"> Pressure Gauge Pl1625 (psi):</label>
                                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                            <label class="readOnlyText"> {{f.P8_PSI.value}}</label>
                                                        </div>
                                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                            <input  name="P8_PSI" maxlength="10" class="form-control smInput"
                                                                formControlName="P8_PSI" name="P8_PSI" placeholder="###">
                                                            <span *ngIf="f.P8_PSI.errors?.required && (f.P8_PSI.dirty || f.P8_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                                    Required! </span>
                                                            <span *ngIf="f.P8_PSI.errors?.pattern"
                                                                [ngClass]="'error'">Invalid number</span>
                                                        </div>
                                                    </div>
                                        <div class="col-sm-4">
                                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1630 (psi):</label>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.P9_PSI.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                <input  name="P9_PSI" maxlength="10" class="form-control smInput"
                                                    formControlName="P9_PSI" name="P9_PSI" placeholder="###">
                                                <span *ngIf="f.P9_PSI.errors?.required && (f.P9_PSI.dirty || f.P9_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required! </span>
                                                <span *ngIf="f.P9_PSI.errors?.pattern" [ngClass]="'error'">
                                               Invalid number</span>
                                            </div>
                                        </div>

                                        </div>
                                        <div *ngIf="IsShepardForm" class="clearfix"></div>

                                        <div *ngIf="IsShepardForm" class="box-row col-sm-12">

                                        <div class="col-sm-4">
                                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1670 (psi):</label>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.P12_PSI.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                <input  name="P12_PSI" maxlength="10" class="form-control smInput"
                                                    formControlName="P12_PSI" name="P12_PSI" placeholder="###">
                                                <span *ngIf="f.P12_PSI.errors?.required && (f.P12_PSI.dirty || f.P12_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required! </span>
                                                <span *ngIf="f.P12_PSI.errors?.pattern" [ngClass]="'error'">
                                               Invalid number</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="col-sm-8 control-label"> Pressure Gauge Pl1675 (psi):</label>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.P13_PSI.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                <input  name="P13_PSI" maxlength="10" class="form-control smInput"
                                                    formControlName="P13_PSI" name="P13_PSI" placeholder="###">
                                                <span *ngIf="f.P13_PSI.errors?.required && (f.P13_PSI.dirty || f.P13_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required! </span>
                                                <span *ngIf="f.P13_PSI.errors?.pattern" [ngClass]="'error'">
                                               Invalid number</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="IsShepardForm" class="clearfix"></div>
                                    <div *ngIf="IsShepardForm" class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label"> Pressure Gauge Pl1680 (psi):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.P14_PSI.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="P14_PSI" maxlength="10" class="form-control smInput" formControlName="P14_PSI"
                                                name="P14_PSI" placeholder="###">
                                            <span *ngIf="f.P14_PSI.errors?.required && (f.P14_PSI.dirty || f.P14_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                    Required! </span>
                                            <span *ngIf="f.P14_PSI.errors?.pattern" [ngClass]="'error'">
                                           Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label"> Pressure Gauge Pl1685 (psi):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.P15_PSI.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="P15_PSI" maxlength="10" class="form-control smInput" formControlName="P15_PSI"
                                                name="P15_PSI" placeholder="###">
                                            <span *ngIf="f.P15_PSI.errors?.required && (f.P15_PSI.dirty || f.P15_PSI.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                    Required! </span>
                                            <span *ngIf="f.P15_PSI.errors?.pattern" [ngClass]="'error'">
                                           Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">

                                    </div>



                                </div>

                                <div class="clearfix"></div>
                                <div class="row">
                                <div class="box-row col-sm-12 divider">
                                    <div class="col-sm-4">
                                        <strong>DI Tanks
                                        </strong>
                                    </div>

                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            D.I's Rinsed:</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.DIRinsed.value}}</label>
                                        </div>

                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <select class="form-control mdInput" formControlName="DIRinsed" name="DIRinsed">
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="NO">NO</option>
                                            </select>
                                            <span *ngIf="f.DIRinsed.errors?.required && (f.DIRinsed.dirty || f.DIRinsed.touched || isValidFormSubmitted==false)" [ngClass]="'error'">
                                                Required! </span>
                                            <span *ngIf="f.DIRinsed.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            DI Backups?:</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText" *ngIf="f.EnableDIBackupMode.value == false || f.EnableDIBackupMode.value == ''" >No</label>
                                            <label class="readOnlyText" *ngIf="f.EnableDIBackupMode.value == true">Yes</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input type="checkbox" (change)="ValidateDailyForm()" name="EnableDIBackupMode"
                                                 class="iCheck-helper" formControlName="EnableDIBackupMode"
                                                name="EnableDIBackupMode">
                                            <span *ngIf="f.EnableDIBackupMode.errors?.required && (f.EnableDIBackupMode.dirty || f.EnableDIBackupMode.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.EnableDIBackupMode.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>


                                    <div  *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="clearfix"></div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="box-row col-sm-12 divider">
                                        <div class="col-sm-4">
                                            <strong>DI Back up Thornton Meter
                                            </strong>
                                        </div>

                                        <div class="col-sm-4">

                                        </div>
                                        <div class="col-sm-4">

                                        </div>
                                    </div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="clearfix"></div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="box-row col-sm-12">
                                        <div class="col-sm-4">
                                            <label class="col-sm-8 control-label">
                                                Working Quality (MΩ-cm):</label>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.WorkingQuality.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                <input   name="WorkingQuality"
                                                    maxlength="10" class="form-control smInput" formControlName="WorkingQuality"
                                                    name="WorkingQuality" placeholder="##.##">
                                                <span *ngIf="f.WorkingQuality.errors?.required && (f.WorkingQuality.dirty || f.WorkingQuality.touched || isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf="f.WorkingQuality.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                          <label class="col-sm-8 control-label">
                                          Polisher Quality (MΩ-cm):</label>
                                          <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                              <label class="readOnlyText"> {{f.PolisherQuality.value}}</label>
                                          </div>
                                          <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                              <input   name="PolisherQuality"
                                                  maxlength="10" class="form-control smInput" formControlName="PolisherQuality"
                                                  name="PolisherQuality" placeholder="##.##">
                                              <span *ngIf="f.PolisherQuality.errors?.required && (f.PolisherQuality.dirty || f.PolisherQuality.touched || isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"> Required! </span>
                                              <span *ngIf="f.PolisherQuality.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                          </div>
                                      </div>
                                        <div class="col-sm-4">

                                        </div>
                                    </div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="clearfix"></div>

                                        <div class="col-sm-4">

                                        </div>
                                    </div>
                                       <div class="col-sm-4"></div>
                                      </div>

                                <div class="clearfix"></div>

                                <div *ngIf="!f.EnableDIBackupMode.value" class="box-row col-sm-12 divider">
                                    <div class="col-sm-4">
                                        <strong>RO
                                        </strong>
                                    </div>

                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4" style="left: 2%;">
                                      <a *ngIf="f.WPDailyDetailsId.value == 0 && divisionShortText == 'SP'" role="button" style="text-align:center;cursor: pointer;" (click)="OpenPopup('AM')">
                                        <img
                                        class="imgStyle"
                                        src="/assets/img/Remote_View/HX2.png" width="40px" height="40px"
                                      />
                                        <p style="margin:0px;margin-left: 5px;display: inline-block;" *ngIf="!showRefreshHX2AM">HX2 Data</p>
                                        <i class="fa fa-refresh" style="margin:0px;margin-left: 5px;display: inline-block;font-size: 25px;
                                        vertical-align: middle;" *ngIf="showRefreshHX2AM" ></i>
                                    </a>
                                    <a *ngIf="f.WPDailyDetailsId.value == 0 && divisionShortText == 'SX'" role="button" style="text-align:center;cursor: pointer;" (click)="OpenPopup('AM')">
                                      <img
                                      class="imgStyle"
                                      src="/assets/img/Remote_View/ModulaS.png" width="40px" height="40px"
                                    />
                                    <p style="margin:0px;margin-left: 5px;display: inline-block;" *ngIf="!showRefreshHX2AM">Modula S5 Data</p>
                                    <i class="fa fa-refresh" style="margin:0px;margin-left: 5px;display: inline-block;font-size: 25px;
                                    vertical-align: middle;" *ngIf="showRefreshHX2AM" ></i>
                                  </a>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                 <!--Suxess field start-->
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            Clock:</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.ROClock.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="ROClock" maxlength="10" class="form-control smInput" formControlName="ROClock" name="ROClock" placeholder="#####">
                                            <span *ngIf="f.ROClock.errors?.required && (f.ROClock.dirty || f.ROClock.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.ROClock.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                      <label class="col-sm-8 control-label">
                                          % Rejection:</label>
                                      <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                          <label class="readOnlyText"> {{f.RORejection.value}}</label>
                                      </div>
                                      <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                          <input  name="RORejection" [ngClass]="{'OurOffRange':checkRange(f.RORejection,95,99)}" maxlength="10" class="form-control smInput"
                                              formControlName="RORejection" name="RORejection" placeholder="##.##">
                                              <span *ngIf="f.RORejection.errors?.required && (f.RORejection.dirty || f.RORejection.touched || isValidFormSubmitted==false)"
                                              [ngClass]="'error'"> Required! </span>
                                          <span *ngIf="f.RORejection.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                      </div>
                                  </div>
                                  <div class="col-sm-4">
                                    <div id='PopUpImageAM' class="dailyHX2popup" style="cursor: pointer;"  (click)="ClosePopup('AM')">
                                      <div class="popupCloseButton" (click)="ClosePopup()">&times;</div>
                                      <div class="col-sm-12 text-center" style="padding: 10% !important;">

                                            <label class="control-label" style="font-size: 15px;">Data Not Available</label>

                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                  <div class="col-sm-4">
                                    <label class="col-sm-8 control-label">
                                        Feed Conductivity (µS/cm):</label>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                        <label class="readOnlyText"> {{f.ROFeedConductivity.value}}</label>
                                    </div>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                        <input  name="ROFeedConductivity" [ngClass]="{'OurOffRange':checkRange(f.ROFeedConductivity,200,700)}" maxlength="10"
                                            class="form-control smInput" formControlName="ROFeedConductivity" name="ROFeedConductivity"
                                            required placeholder="###">
                                        <span *ngIf="f.ROFeedConductivity.errors?.required && (f.ROFeedConductivity.dirty || f.ROFeedConductivity.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf="f.ROFeedConductivity.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <label class="col-sm-8 control-label">
                                        Permeate Conductivity (µS/cm):</label>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                        <label class="readOnlyText"> {{f.ROPermeateConductivity.value}}</label>
                                    </div>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                        <input  name="ROPermeateConductivity" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.ROPermeateConductivity,3.5,8.5)}"
                                            class="form-control smInput" formControlName="ROPermeateConductivity" name="ROPermeateConductivity"
                                            required placeholder="###">
                                        <span *ngIf="f.ROPermeateConductivity.errors?.required && (f.ROPermeateConductivity.dirty || f.ROPermeateConductivity.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf="f.ROPermeateConductivity.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                    </div>
                                </div>


                                </div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                  <div class="col-sm-4">
                                    <label class="col-sm-8 control-label">
                                        Prefilter In (psi):</label>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                        <label class="readOnlyText"> {{f.ROPrefilterIn.value}}</label>
                                    </div>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                        <input  name="ROPrefilterIn" maxlength="10" class="form-control smInput" formControlName="ROPrefilterIn" name="ROPrefilterIn" placeholder="###">
                                        <span *ngIf="f.ROPrefilterIn.errors?.required && (f.ROPrefilterIn.dirty || f.ROPrefilterIn.touched || isValidFormSubmitted==false)"
                                        [ngClass]="'error'"> Required! </span>
                                        <span *ngIf="f.ROPrefilterIn.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                    </div>
                                </div>
                                <div class="col-sm-4">
                                  <label class="col-sm-8 control-label">
                                      Prefilter Out (psi):</label>
                                  <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                      <label class="readOnlyText"> {{f.ROPrefilterOut.value}}</label>
                                  </div>
                                  <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                      <input  name="ROPrefilterOut" maxlength="10" class="form-control smInput" formControlName="ROPrefilterOut" name="ROPrefilterOut" placeholder="###">
                                      <span *ngIf="f.ROPrefilterOut.errors?.required && (f.ROPrefilterOut.dirty || f.ROPrefilterOut.touched || isValidFormSubmitted==false)"
                                      [ngClass]="'error'"> Required! </span>
                                      <span *ngIf="f.ROPrefilterOut.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                                  </div>
                              </div>
                            </div>
                              <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            Pump Pressure (psi):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.ROPumpPressure.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="ROPumpPressure" maxlength="10" class="form-control smInput" formControlName="ROPumpPressure" name="ROPumpPressure" placeholder="###">
                                            <span *ngIf="f.ROPumpPressure.errors?.required && (f.ROPumpPressure.dirty || f.ROPumpPressure.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.ROPumpPressure.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            Ring Pressure (bar):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.RORingPressure.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="RORingPressure" maxlength="10" class="form-control smInput" formControlName="RORingPressure" name="RORingPressure" placeholder="#.#">
                                            <span *ngIf="f.RORingPressure.errors?.required && (f.RORingPressure.dirty || f.RORingPressure.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.RORingPressure.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                  </div>
                                  <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                  <div class="col-sm-4">
                                    <label class="col-sm-8 control-label">
                                        Permeate Flow ( L/hr):</label>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                        <label class="readOnlyText"> {{f.ROPermeateFlow.value}}</label>
                                    </div>
                                    <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                        <input  name="ROPermeateFlow" maxlength="10" class="form-control smInput" formControlName="ROPermeateFlow" name="ROPermeateFlow"
                                            required placeholder="####">
                                        <span *ngIf="f.ROPermeateFlow.errors?.required && (f.ROPermeateFlow.dirty || f.ROPermeateFlow.touched || isValidFormSubmitted==false)"
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf="f.ROPermeateFlow.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                    </div>



                                  </div>
                                   </div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="clearfix"></div>
                                <div *ngIf="!IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"></label>

                                        <div class="col-sm-7">
                                            <span *ngIf="f.ROPrefilterOut.errors?.InGreaterOut" [ngClass]="'error'">
                                                PrefilterIn should be higher than PrefilterOut.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                        <!--Suxess field end-->
                                       <!--Shephard field start-->
                                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                        <div class="col-sm-4">
                                                <!-- <label class="col-sm-8 control-label">FEED CONDUCTIVTY A13635:</label> -->
                                                <label class="col-sm-8 control-label">Feed Conductivity:</label>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                    <label class="readOnlyText"> {{f.FEED_CONDUCTIVTY_A13635.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                    <input  name="FEED_CONDUCTIVTY_A13635" maxlength="10" class="form-control smInput"
                                                        formControlName="FEED_CONDUCTIVTY_A13635" name="FEED_CONDUCTIVTY_A13635" placeholder="###">
                                                    <span *ngIf="f.FEED_CONDUCTIVTY_A13635.errors?.required && (f.FEED_CONDUCTIVTY_A13635.dirty || f.FEED_CONDUCTIVTY_A13635.touched || isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf="f.FEED_CONDUCTIVTY_A13635.errors?.pattern"
                                                        [ngClass]="'error'">Invalid number</span>
                                                </div>
                                            </div>
                                    <!-- <div class="col-sm-4">

                                        <label class="col-sm-8 control-label">Pre-Filter Inlet P13600 (psi):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.PRE_FILTER_INLET_P13600_PSI.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="PRE_FILTER_INLET_P13600_PSI" maxlength="10" class="form-control smInput"
                                                formControlName="PRE_FILTER_INLET_P13600_PSI" name="PRE_FILTER_INLET_P13600_PSI">
                                            <span *ngIf="f.PRE_FILTER_INLET_P13600_PSI.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">

                                        <label class="col-sm-8 control-label">Pre-Filter Out P13605 (psi):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.PRE_FILTER_OUT_P13605_PSI.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="PRE_FILTER_OUT_P13605_PSI" maxlength="10" class="form-control smInput"
                                                formControlName="PRE_FILTER_OUT_P13605_PSI" name="PRE_FILTER_OUT_P13605_PSI">
                                            <span *ngIf="f.PRE_FILTER_OUT_P13605_PSI.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4">
                                            <label class="col-sm-8 control-label"> % Rejection:</label>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                                <label class="readOnlyText"> {{f.SP_Rejection.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                                <input  name="SP_Rejection" maxlength="10" class="form-control smInput"
                                                    formControlName="SP_Rejection" name="SP_Rejection" placeholder="##.##">
                                                <span *ngIf="f.SP_Rejection.errors?.required && (f.SP_Rejection.dirty || f.SP_Rejection.touched || isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf="f.SP_Rejection.errors?.pattern" [ngClass]="'error'">
                                               Invalid number</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                          <div id='PopUpImageAM' class="dailyHX2popup" style="cursor: pointer;" (click)="ClosePopup('AM')">
                                            <div class="popupCloseButton" (click)="ClosePopup()">&times;</div>
                                            <div class="col-sm-12 noPaddingAll" style="font-size: 10px;" *ngIf="dateDiff<300" >
                                                  <label class="col-sm-6 readOnlyText noPaddingAll text-right">Last Updated Date & Time:</label>
                                                  <label class="col-sm-6 control-label" style="padding: 0px 3px !important;text-align: left !important"> {{RODate}}  {{ROTime}}</label>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">Feed Conductivity:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3635}}</label>
                                                </div>
                                                <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">% Rejection:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{CQI9864}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">1st Pass Quality:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3665}}</label>
                                                </div>
                                                <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">2nd Pass Quality:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3695}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">Loop Feed:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{FI3710}}</label>
                                                </div>
                                                <div class="col-sm-6 noPaddingAll">
                                                  <label class="col-sm-10 readOnlyText noPaddingAll text-right">Loop Return Flow:</label>
                                                  <label class="col-sm-2 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{FI3620}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff>300">
                                                  <label class="col-sm-12 text-center" style="color:red;">Communication Error</label>
                                            </div>
                                          </div>
                                        </div>

                                </div>
                                <!-- <div *ngIf="IsShepardForm" class="clearfix"></div>

                                <div *ngIf="IsShepardForm  && !f.EnableDIBackupMode.value" class="box-row col-sm-12">

                                    <div class="col-sm-4">

                                        <label class="col-sm-8 control-label">Pump 1 Clock:</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.PUMP_1_CLOCK.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="PUMP_1_CLOCK" maxlength="10" class="form-control smInput"
                                                formControlName="PUMP_1_CLOCK" name="PUMP_1_CLOCK">
                                            <span *ngIf="f.PUMP_1_CLOCK.errors?.pattern" [ngClass]="'error'">
                                           Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">

                                        <label class="col-sm-8 control-label">Pump 2 Clock:</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.PUMP_2_CLOCK.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="PUMP_2_CLOCK" maxlength="10" class="form-control smInput"
                                                formControlName="PUMP_2_CLOCK" name="PUMP_2_CLOCK">
                                            <span *ngIf="f.PUMP_2_CLOCK.errors?.pattern" [ngClass]="'error'">
                                           Invalid number</span>
                                        </div>
                                    </div>

                                </div> -->
                                <div *ngIf="IsShepardForm" class="clearfix"></div>
                                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <!-- <label class="col-sm-8 control-label">1ST PASS PRODUCT QUALITY A13665:</label> -->
                                        <label class="col-sm-8 control-label longtitle">1st Pass Quality (µS/cm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f._1ST_PASS_PRODUCT_QUALITY_A13665.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="_1ST_PASS_PRODUCT_QUALITY_A13665" maxlength="10" class="form-control smInput"
                                                formControlName="_1ST_PASS_PRODUCT_QUALITY_A13665" name="_1ST_PASS_PRODUCT_QUALITY_A13665" placeholder="##.##">
                                            <span *ngIf="f._1ST_PASS_PRODUCT_QUALITY_A13665.errors?.required && (f._1ST_PASS_PRODUCT_QUALITY_A13665.dirty || f._1ST_PASS_PRODUCT_QUALITY_A13665.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f._1ST_PASS_PRODUCT_QUALITY_A13665.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-4">

                                        <label class="col-sm-8 control-label">2nd Pass Flow A13710 (gpm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="_2ND_PASS_PRODUCT_FLOW_A13710_GPM" maxlength="10" class="form-control smInput"
                                                formControlName="_2ND_PASS_PRODUCT_FLOW_A13710_GPM" name="_2ND_PASS_PRODUCT_FLOW_A13710_GPM">
                                            <span *ngIf="f._2ND_PASS_PRODUCT_FLOW_A13710_GPM.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-4">
                                        <!-- <label class="col-sm-8 control-label">2ND PASS PRODUCT QUALITY A13665:</label> -->
                                        <label class="col-sm-8 control-label longtitle">2nd Pass Quality (µS/cm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f._2ND_PASS_PRODUCT_QUALITY_A13665.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="_2ND_PASS_PRODUCT_QUALITY_A13665" maxlength="10" class="form-control smInput"
                                                formControlName="_2ND_PASS_PRODUCT_QUALITY_A13665" name="_2ND_PASS_PRODUCT_QUALITY_A13665" placeholder="##.##">
                                            <span *ngIf="f._2ND_PASS_PRODUCT_QUALITY_A13665.errors?.required && (f._2ND_PASS_PRODUCT_QUALITY_A13665.dirty || f._2ND_PASS_PRODUCT_QUALITY_A13665.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f._2ND_PASS_PRODUCT_QUALITY_A13665.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="IsShepardForm" class="clearfix"></div>
                                <div *ngIf="IsShepardForm && !f.EnableDIBackupMode.value" class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <!-- <label class="col-sm-8 control-label">LOOP FILTER OUTLET PRESURE P13715 (PSIG):</label> -->
                                        <!-- <label class="col-sm-8 control-label ">Loop Filter Outlet P13715 (psi):</label> -->
                                        <label class="col-sm-8 control-label ">Loop Feed (gpm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG" maxlength="10"
                                                class="form-control smInput" formControlName="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG"
                                                name="LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG" placeholder="#.##">
                                            <span *ngIf="f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.errors?.required && (f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.dirty || f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.LOOP_FILTER_OUTLET_PRESURE_P13715_PSIG.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <!-- <label class="col-sm-8 control-label">LOOP RETURN FLOW F13620 (GPM):</label> -->
                                        <label class="col-sm-8 control-label longtitle">Loop Return Flow (gpm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.LOOP_RETURN_FLOW_F13620_GPM.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  name="LOOP_RETURN_FLOW_F13620_GPM" maxlength="10" class="form-control smInput"
                                                formControlName="LOOP_RETURN_FLOW_F13620_GPM" name="LOOP_RETURN_FLOW_F13620_GPM" placeholder="#.##">
                                            <span *ngIf="f.LOOP_RETURN_FLOW_F13620_GPM.errors?.required && (f.LOOP_RETURN_FLOW_F13620_GPM.dirty || f.LOOP_RETURN_FLOW_F13620_GPM.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.LOOP_RETURN_FLOW_F13620_GPM.errors?.pattern"
                                                [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4"></div>

                                </div>

                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12 divider" *ngIf="!IsShepardForm">
                                    <div class="col-sm-4">
                                        <strong>Thornton Meter
                                        </strong>
                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">
                                        <strong>
                                        </strong>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12" *ngIf="!IsShepardForm">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            <span class="text-red inputrequired" >*</span>Morning (µS/cm):</label>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value > 0">
                                            <label class="readOnlyText"> {{f.ThorntonMeterMorning.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="f.WPDailyDetailsId.value == 0">
                                            <input  [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterMorning,0.01,8.5)}" name="ThorntonMeterMorning"
                                                maxlength="8" class="form-control smInput" formControlName="ThorntonMeterMorning"
                                                name="ThorntonMeterMorning" required placeholder="##.##">
                                            <span *ngIf="f.ThorntonMeterMorning.errors?.required && (f.ThorntonMeterMorning.dirty || f.ThorntonMeterMorning.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.ThorntonMeterMorning.errors?.pattern" [ngClass]="'error'"> Not valid number!</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                </div>


                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12 divider">
                                    <div class="col-sm-4">
                                        <strong>Central Delivery Acid Levels
                                        </strong>
                                    </div>

                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12">
                                    <AcidLevelComponent #acidLevel>

                                    </AcidLevelComponent>

                                </div>
                                <div class="clearfix"></div>

                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12">
                                    <div class="col-sm-2">
                                        <label class="control-label" style="color: #0484a4">User Name:</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="control-label">{{FirstShiftUserName}}</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="control-label"  style="color: #0484a4">Time:</label>
                                        &nbsp;
                                        &nbsp;
                                        <label class="control-label">{{FirstShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>

                    <div class="box ibox box-info" style="margin-bottom:50px" *ngIf="FirstShiftDate != null && FirstShiftDate != ''">
                        <div class="box-header with-border">
                            <h3 class="box-title">PM</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-4" style="margin-bottom:10px;" *ngIf="IsShepardForm">
                                    <label class="col-sm-8 control-label">
                                        RO Online/DI Backup?:</label>
                                    <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                        <!-- <label class="readOnlyText" *ngIf="f.EnableDIBackupModePM.value == false || f.EnableDIBackupModePM.value == ''" >No</label>
                                        <label class="readOnlyText" *ngIf="f.EnableDIBackupModePM.value == true">Yes</label> -->
                                        <label class="readOnlyText">{{DIBackupSelectedValue}}</label>
                                    </div>
                                    <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                        <mat-button-toggle-group multiple [value]="DIBackupSelectedValue">
                                            <mat-button-toggle *ngFor="let item of DIBackupOptions" style="font-weight: normal;"
                                            (change)="ValidateDailyFormPM(item.name)"  value="{{item.name}}"
                                            [ngClass]="{'toggle-switch-success':item.name === 'RO Online' && DIBackupSelectedValue[0] == 'RO Online',
                                            'toggle-switch-danger': item.name === 'DI BackUp' && DIBackupSelectedValue[0] == 'DI BackUp' }">
                                                {{item.name}}
                                        </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="box-row col-sm-12 divider">
                                    <div class="col-sm-4">
                                        <strong>DPD Test Post-Carbon
                                        </strong>
                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">
                                        <strong>
                                        </strong>
                                    </div>
                                </div>
                                <div class="clearfix"></div>


                                <div class="box-row col-sm-12">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            Total Chlorine (mg/L):</label>
                                        <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                            <label class="readOnlyText"> {{f.SecondShiftTotalChlorineTextBox.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                            <input  name="SecondShiftTotalChlorineTextBox" maxlength="10" (keyup)="GreaterValueCheck()" [ngClass]="{'OurOffRange':checkRange(f.SecondShiftTotalChlorineTextBox,0.01,0.08)}"
                                                class="form-control smInput" formControlName="SecondShiftTotalChlorineTextBox"
                                                name="SecondShiftTotalChlorineTextBox" placeholder="#.##" (keypress)="numberOnly($event)"
                                                (blur) ="CheckIfZero('SecondShiftTotalChlorineTextBox')">
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.required && (f.SecondShiftTotalChlorineTextBox.dirty || f.SecondShiftTotalChlorineTextBox.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.IsRequiredCtl" [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label">
                                            Free Chlorine (mg/L):</label>
                                        <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                            <label class="readOnlyText"> {{f.SecondShiftFreeChlorineTextBox.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                            <input  name="SecondShiftFreeChlorineTextBox" maxlength="10" [ngClass]="{'OurOffRange':checkRange(f.SecondShiftFreeChlorineTextBox,0.01,0.05)}"
                                                class="form-control smInput" formControlName="SecondShiftFreeChlorineTextBox"
                                                name="SecondShiftFreeChlorineTextBox" placeholder="#.##" (keypress)="numberOnly($event)">
                                            <span *ngIf="f.SecondShiftFreeChlorineTextBox.errors?.required && (f.SecondShiftFreeChlorineTextBox.dirty || f.SecondShiftFreeChlorineTextBox.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.SecondShiftFreeChlorineTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                        </div>
                                    </div>


                                    <div class="col-sm-4">

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12 divider" *ngIf="IsShepardForm && DIBackupSelectedValue == 'RO Online'">
                                    <div class="col-sm-4">
                                        <strong>RO
                                        </strong>
                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4" style="left: 2%;">
                                      <a *ngIf="Status != 'Completed' || f.WPDailyDetailsId.value == 0" role="button" style="text-align:center;cursor: pointer;" (click)="OpenPopup('PM')">
                                        <img
                                        class="imgStyle"
                                        src="/assets/img/Remote_View/HX2.png" width="40px" height="40px"
                                        (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')"
                                      />
                                      <p style="margin:0px;margin-left: 5px;display: inline-block;" *ngIf="!showRefreshHX2PM">HX2 Data</p>
                                      <i class="fa fa-refresh" style="margin:0px;margin-left: 5px;display: inline-block;font-size: 25px;
                                      vertical-align: middle;" *ngIf="showRefreshHX2PM" ></i>
                                    </a>
                                    </div>
                                </div>
                                <div class="box-row col-sm-12 divider" *ngIf="!IsShepardForm">
                                        <div class="col-sm-4">
                                            <strong>Thornton Meter
                                            </strong>
                                        </div>
                                        <div class="col-sm-4">

                                        </div>
                                        <div class="col-sm-4">
                                            <strong>
                                            </strong>
                                        </div>
                                    </div>
                                <div class="clearfix"></div>


                                <div class="box-row col-sm-12" *ngIf="!IsShepardForm">
                                    <div class="col-sm-4">
                                        <label class="col-sm-8 control-label" *ngIf="!IsShepardForm">
                                            Afternoon (µS/cm):</label>

                                        <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                            <label class="readOnlyText"> {{f.ThorntonMeterAfternoonTextBox.value}}</label>
                                        </div>
                                        <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                            <input  name="ThorntonMeterAfternoonTextBox" maxlength="10" class="form-control smInput" (keyup)="GreaterValueCheck()"
                                                [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterAfternoonTextBox,0.01,8.5)}"
                                                formControlName="ThorntonMeterAfternoonTextBox" name="ThorntonMeterAfternoonTextBox"
                                                placeholder="##.##" (keypress)="numberOnly($event)"
                                                (blur) ="CheckIfZero('ThorntonMeterAfternoonTextBox')">
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.required && (f.ThorntonMeterAfternoonTextBox.dirty || f.ThorntonMeterAfternoonTextBox.touched || isValidFormSubmitted==false)"
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.IsRequiredCtl" [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                    <div class="col-sm-4">

                                    </div>
                                </div>

                                <div class="box-row col-sm-12" *ngIf="IsShepardForm && DIBackupSelectedValue == 'RO Online'">
                                        <div class="col-sm-4">
                                                <label class="col-sm-8 control-label longtitle" >2nd Pass Quality (µS/cm):</label>
                                            <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                                <label class="readOnlyText"> {{f.ThorntonMeterAfternoonTextBox.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                                <input  name="ThorntonMeterAfternoonTextBox" maxlength="10" class="form-control smInput" (keyup)="GreaterValueCheck()"
                                                    [ngClass]="{'OurOffRange':checkRange(f.ThorntonMeterAfternoonTextBox,0.01,8.5)}"
                                                    formControlName="ThorntonMeterAfternoonTextBox"
                                                    name="ThorntonMeterAfternoonTextBox" placeholder="##.##"
                                                    (keypress)="numberOnly($event)"
                                                    (blur) ="CheckIfZero('ThorntonMeterAfternoonTextBox')">
                                                <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.required && (f.ThorntonMeterAfternoonTextBox.dirty || f.ThorntonMeterAfternoonTextBox.touched || isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf="f.ThorntonMeterAfternoonTextBox.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                <span *ngIf="f.SecondShiftTotalChlorineTextBox.errors?.IsRequiredCtl" [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">

                                        </div>
                                        <div class="col-sm-4">
                                          <div id='PopUpImagePM' class="dailyHX2popup" style="cursor: pointer;" (click)="ClosePopup('PM')">
                                            <div class="popupCloseButton" (click)="ClosePopup()">&times;</div>
                                            <div class="col-sm-12 noPaddingAll" style="font-size: 10px;"  *ngIf="dateDiff<300">
                                                  <label class="col-sm-6 readOnlyText noPaddingAll text-right">Last Updated Date & Time:</label>
                                                  <label class="col-sm-6 control-label" style="padding: 0px 3px !important;text-align: left !important"> {{RODate}}  {{ROTime}}</label>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">Feed Conductivity:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3635}}</label>
                                                </div>
                                                <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">% Rejection:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{CQI9864}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">1st Pass Quality:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3665}}</label>
                                                </div>
                                                <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">2nd Pass Quality:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{AI3695}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff<300">
                                              <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">Loop Feed:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{FI3710}}</label>
                                                </div>
                                                <div class="col-sm-offset-1 col-sm-5 noPaddingAll">
                                                  <label class="col-sm-9 readOnlyText noPaddingAll text-right">Loop Return Flow:</label>
                                                  <label class="col-sm-3 control-label text-left" style="padding: 0px 3px !important;text-align: left !important"> {{FI3620}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 noPadding" *ngIf="dateDiff>300">
                                              <label class="col-sm-12 text-center" style="color:red;">Communication Error</label>
                                        </div>
                                          </div>
                                        </div>
                                    </div>

                                <div  *ngIf="DIBackupSelectedValue == 'DI BackUp' && IsShepardForm" class="clearfix"></div>
                                    <div *ngIf="DIBackupSelectedValue == 'DI BackUp' && IsShepardForm" class="box-row col-sm-12 divider">
                                        <div class="col-sm-4">
                                            <strong>DI Back up Thornton Meter
                                            </strong>
                                        </div>

                                        <div class="col-sm-4">

                                        </div>
                                        <div class="col-sm-4">

                                        </div>
                                    </div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="clearfix"></div>

                                <div *ngIf="f.EnableDIBackupModePM.value && IsShepardForm" class="clearfix"></div>
                                    <div *ngIf="DIBackupSelectedValue == 'DI BackUp' && IsShepardForm" class="box-row col-sm-12">
                                        <div class="col-sm-4">
                                            <label class="col-sm-8 control-label">
                                                Working Quality (MΩ-cm):</label>
                                            <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                                <label class="readOnlyText"> {{f.WorkingQualityPM.value}}</label>
                                            </div>
                                            <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                                <input   name="WorkingQualityPM"
                                                    maxlength="10" class="form-control smInput" formControlName="WorkingQualityPM"
                                                    name="WorkingQualityPM" placeholder="##.##" (keypress)="numberOnly($event)"
                                                    (blur) ="CheckIfZero('WorkingQualityPM')">
                                                <span *ngIf="f.WorkingQualityPM.errors?.required && (f.WorkingQualityPM.dirty || f.WorkingQualityPM.touched || isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf="f.WorkingQualityPM.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                          <label class="col-sm-8 control-label">
                                          Polisher Quality (MΩ-cm):</label>
                                          <div class="col-sm-4" *ngIf="!CheckSecondShiftCompleted()">
                                              <label class="readOnlyText"> {{f.PolisherQualityPM.value}}</label>
                                          </div>
                                          <div class="col-sm-4" *ngIf="CheckSecondShiftCompleted()">
                                              <input   name="PolisherQualityPM"
                                                  maxlength="10" class="form-control smInput" formControlName="PolisherQualityPM"
                                                  name="PolisherQualityPM" placeholder="##.##" (keypress)="numberOnly($event)"
                                                  (blur) ="CheckIfZero('PolisherQualityPM')">
                                              <span *ngIf="f.PolisherQualityPM.errors?.required && (f.PolisherQualityPM.dirty || f.PolisherQualityPM.touched || isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"> Required! </span>
                                              <span *ngIf="f.PolisherQualityPM.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                          </div>
                                      </div>
                                        <div class="col-sm-4">

                                        </div>
                                    </div>
                                    <div *ngIf="f.EnableDIBackupMode.value && IsShepardForm" class="clearfix"></div>

                                        <div class="col-sm-4">

                                        </div>
                                <div class="clearfix"></div>
                                <div class="box-row col-sm-12">
                                    <div class="col-sm-2">
                                        <label class="control-label" style="color: #0484a4">User Name:</label>
                                    </div>
                                    <div class="col-sm-3">
                                        <label class="control-label">{{SecondShiftUserName}}</label>
                                    </div>
                                    <div class="col-sm-6" >
                                        <label class="control-label" style="color: #0484a4">Time:</label>
                                        &nbsp;&nbsp;
                                        <label class="control-label" *ngIf="IsSecondShiftWithFirstShiftDate">{{SecondShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                                        <label class="control-label" *ngIf="!IsSecondShiftWithFirstShiftDate" style="color:red;">{{SecondShiftDate | date:'dd/MM/yyyy hh:mm'}}</label>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>


                    <notes  (CheckValidForm)="GreaterValueCheck()"  (addNoteClick)="executeWPDailyCreateUpdate('Notes')" #notes>
                        <!-- [isValidForm] ="this.dailyDetailForm.invalid" -->
                    </notes>
                    <attachment (RaiseError)="AttachmentError($event)" #attachment>

                    </attachment>

            </div>

        <!-- <div class="clearfix"></div>
        <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
            [showSave]="blnshowSave" (SaveEvent)="executeWPDailyCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
            #pageheader1></PageHeader> -->

        </div>
        </div>

    <!-- </section> -->

</form>

import { AuthService } from './../../API/auth.service';
import { Globals } from './../../globals';
import { DecimalPipe, DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { WPApiService } from 'src/app/API/wpapi.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2',
  templateUrl: 'BioPureHX2.component.html',
  styleUrls: ['BioPureHX2.component.css']
})

export class BioPureHX2Component {
title = 'BioPure HX2 Layout';

systemmode = '';
systemsequencestep = '';
matrixmode = '';

alarmstatus = '';
timeLeft: number = 60;
communicationstatus = '';
lastupdateddate = '';


LI3615_01 = '01';
PI3600_02 = '02';
PI3605_03 = '03';
CPI9850_04 = '04';
CFI9866_05 = '05';
CFI9868_LIT_06 = '06';
TI3640_07 = '07';
AI3635_08 = '08';
SIC3015_09 = '09';
PI3645_10 = '10';
PI3650_11 = '11';
FI3655_12 = '12';
ZC3440_13 = '13';
CFI9872_14 = '14';
FI3620_15 = '15';
ZC3445_16 = '16';
CFI9874_17 = '17';
FI3660_18 = '18';
TI3630_19 = '19';
ZC3402_20 = '20';


PI6640_21 = '21';
EC3010_22 = '22';

SIC3025_50 = '50';
PI3675_51 = '51';
PI3705_57 = '57';
FI3710_58 = '58';
PI3685_60 = '60';
FI3690_61 = '61';
ZC3460_62 = '62';
XS3470_70 = '70';
XS3470_70_Title = '';
PI3715_80 = '80';
CPI9852_81 = '81';

TI3670_40 = '40';
AI3665_41 = '41';
CFI9858_42 = '42';
CFI9860_43 = '43';
CFI9870_44 = '44';
CPI9854_45 = '45';

AI3695_52 = '52';
TI3700_53 = '53';
CQI9864_54 = '54';
CFI9862_55 = '55';
CPI9856_56 = '56';

DataFromMachine = '0';

dateDiff = 0 ;

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  private router: Router,
  private wpapi: WPApiService,
  private auth: AuthService,
  public dialog: MatDialog
) { }

timer:any;
timerCheckDateTime:any;
interval:any;

HX2List : any;

ngOnInit() {
  this.HX2List = Globals.lstHX2Data;

  this.FetchAndSetValues();
  this.timer =  setInterval(() => {
    this.FetchAndSetValues();
  }, 30000);

  // this.CheckServiceDateTime();
  this.timerCheckDateTime = setInterval(() => {
  this.CheckServiceDateTime();
  }, 100000);

  if(document.getElementById('communicationGreen').style.display !== 'block' && document.getElementById('machineGreen').style.display !== 'block')
    this.startTimer();
}

startTimer() {
  this.interval = setInterval(() => {

    if(this.timeLeft > 0) {
      this.timeLeft--;
      this.communicationstatus = 'Retrieving Data (00:' + (this.decimalPipe.transform(this.timeLeft , '2.0')).toString() + ')' ;
    } else {
      clearInterval(this.interval);

      if(this.DataFromMachine == '0')
      this.communicationstatus = "Communication Error, There is some issue in HX2 to Local PC Communication.";
    else
      this.communicationstatus = "Communication Error, There is some issue in Fetch Service or Local PC to Server Communication.";

    this.RODate = '';


    document.getElementById('communicationRed').style.display = 'block';
    document.getElementById('machineRed').style.display = 'block';
      // this.timeLeft = 30;
    }

  },1000)
}

stopTimer() {
  clearInterval(this.interval);
}

tempLastupdateDate='';
FetchAndSetValues(){

  this.wpapi.FetchHX2Data().subscribe(
    success => {
      success.forEach(element => {
        //
        const HX2Data =  this.HX2List.find(c=>c.id==element.fieldName);
        if(HX2Data!=undefined) {
          var format ='';
          var FieldValue = '';

          if(HX2Data.format!=''){
            format=HX2Data.format;
            FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
            if(format.length>1)
              FieldValue = (this.decimalPipe.transform(parseFloat(FieldValue) , format)).toString();
            else
              FieldValue = (parseInt(FieldValue)).toString();


              if(FieldValue.indexOf('-') == 0){
                FieldValue =FieldValue.replace(/^[-]*0+/, '');
                if(FieldValue.indexOf('.')==0)
                    FieldValue='-0'+FieldValue;
              } else {

              if(FieldValue != '0'){
                  FieldValue =FieldValue.replace(/^0+/, '');
                  if(FieldValue.indexOf('.')==0)
                      FieldValue='0'+FieldValue;

                }  else if(FieldValue == '0' && format.length>1) {
                  FieldValue = '0.0';
                }
              }
          } else {
            FieldValue = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
          }
          if(element.fieldName == '{[HX2]STOP_ALARMS_DISABLED}'){
            this.HX2List.find(c => c.id == element.fieldName && c.col == 1).value = FieldValue;
            this.HX2List.find(c => c.id == element.fieldName && c.col == 2).value = FieldValue;

          } else {
            this.HX2List.find(c=> c.id == element.fieldName).value = FieldValue;
          }
          //  this.lstHX2Data.find(c=>c.id==element.fieldName).value = success.find(c=>c.fieldName==element.fieldName).fieldDisplayValue;
          // this.lstHX2Data.find(c=>c.id==element.fieldName).value = success.find(c=>c.fieldName==element.fieldName).fieldValue;

        }
        //
        //console.log(this.HX2List);
      });
//
this.HX2List.find(c => c.id == 'LocalPCDate').value =
this.datePipe.transform(success.find(c=>c.fieldName=='LocalTime').createdOn, 'MM/dd/yyyy');

this.HX2List.find(c => c.id == 'LocalPCTime').value =
this.datePipe.transform(success.find(c=>c.fieldName=='LocalTime').createdOn, 'HH:mm:ss');
      this.tempLastupdateDate =  this.datePipe.transform(success.find(c=>c.fieldName=='LocalTime').createdOn, 'MM/dd/yyyy HH:mm:ss');
      // this.CheckServiceDateTime();
    },
    error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    }
  );

//
  this.dateDiff = this.HX2List.find(c=> c.id == 'DateDiff').value;
  this.DataFromMachine = this.HX2List.find(c => c.id == 'DataFromMachine').value;

  if(this.dateDiff > 300 || this.DataFromMachine == '0')
  {
    this.HX2List.forEach(element => { element.value = ''});
  }

  this.systemmode = this.HX2List.find(c=> c.id == '{[HX2]MODE_INT}').value;
  this.systemsequencestep = this.HX2List.find(c=> c.id == '{[HX2]STEP_INT}').value;
  this.matrixmode = this.HX2List.find(c=> c.id == '{[HX2]MATRIX_MODE_ACTIVE_INT}').value;

  this.LI3615_01 = this.HX2List.find(c=> c.id == '{[HX2]LI3615.Val}').value;
  this.PI3600_02 =  this.HX2List.find(c=> c.id == '{[HX2]PI3600.Val}').value;
this.PI3605_03 =  this.HX2List.find(c=> c.id == '{[HX2]PI3605.Val}').value;
this.CPI9850_04 =  this.HX2List.find(c=> c.id == '{[HX2]CPI9850.Val}').value;
this.CFI9866_05 =  this.HX2List.find(c=> c.id == '{[HX2]CFI9866.Val}').value;
this.CFI9868_LIT_06 =  this.HX2List.find(c=> c.id == '{[HX2]CFI9868_LIT.Total}').value;

this.TI3640_07 =  this.HX2List.find(c=> c.id == '{[HX2]TI3640.Val}').value;
this.AI3635_08 =  this.HX2List.find(c=> c.id == '{[HX2]AI3635.Val}').value;
this.SIC3015_09 =  this.HX2List.find(c=> c.id == '{[HX2]SIC3015.Val_SpeedRef}').value;
this.PI3645_10 =  this.HX2List.find(c=> c.id == '{[HX2]PI3645.Val}').value;
this.PI3650_11 = this.HX2List.find(c=> c.id == '{[HX2]PI3650.Val}').value;
this.FI3655_12 = this.HX2List.find(c=> c.id == '{[HX2]FI3655.Val}').value;
this.ZC3440_13 =  this.HX2List.find(c=> c.id == '{[HX2]ZC3440.Val_CVOut}').value;
this.CFI9872_14 =  this.HX2List.find(c=> c.id == '{[HX2]CFI9872.Val}').value;
this.FI3620_15 =  this.HX2List.find(c=> c.id == '{[HX2]FI3620.Val}').value;
this.ZC3445_16 =  this.HX2List.find(c=> c.id == '{[HX2]ZC3445.Val_CVOut}').value;
this.CFI9874_17 =  this.HX2List.find(c=> c.id == '{[HX2]CFI9874.Val}').value;
this.FI3660_18 =  this.HX2List.find(c=> c.id == '{[HX2]FI3660.Val}').value;
this.TI3630_19 = this.HX2List.find(c=> c.id == '{[HX2]TI3630.Val}').value;
this.ZC3402_20 =  this.HX2List.find(c=> c.id == '{[HX2]ZC3402.Val_CVOut}').value;

this.PI6640_21 = this.HX2List.find(c=> c.id == '{[HX2]PI6640.Val}').value;
this.EC3010_22 =  this.HX2List.find(c=> c.id == '{[HX2]EC3010.Val_CVOut}').value;

this.SIC3025_50 =  this.HX2List.find(c=> c.id == '{[HX2]SIC3025.Val_SpeedRef}').value;
this.PI3675_51 = this.HX2List.find(c=> c.id == '{[HX2]PI3675.Val}').value;
this.PI3705_57 =  this.HX2List.find(c=> c.id == '{[HX2]PI3705.Val}').value;
this.FI3710_58 = this.HX2List.find(c=> c.id == '{[HX2]FI3710.Val}').value;
this.PI3685_60 =  this.HX2List.find(c=> c.id == '{[HX2]PI3685.Val}').value;
this.FI3690_61 = this.HX2List.find(c=> c.id == '{[HX2]FI3690.Val}').value;
this.ZC3460_62 = this.HX2List.find(c=> c.id == '{[HX2]ZC3460.Val_CVOut}').value;
this.XS3470_70 =  this.HX2List.find(c=> c.id == '{[HX2]XS3470.Sts_Opened}').value;
this.PI3715_80 = this.HX2List.find(c=> c.id == '{[HX2]PI3715.Val}').value;
this.CPI9852_81 = this.HX2List.find(c=> c.id == '{[HX2]CPI9852.Val}').value;

this.TI3670_40 = this.HX2List.find(c=> c.id == '{[HX2]TI3670.Val}').value;
this.AI3665_41 =  this.HX2List.find(c=> c.id == '{[HX2]AI3665.Val}').value;
this.CFI9858_42 = this.HX2List.find(c=> c.id == '{[HX2]CFI9858.Val}').value;
this.CFI9860_43 = this.HX2List.find(c=> c.id == '{[HX2]CFI9860.Val}').value;
this.CFI9870_44 = this.HX2List.find(c=> c.id == '{[HX2]CFI9870.Val}').value;
this.CPI9854_45 =  this.HX2List.find(c=> c.id == '{[HX2]CPI9854.Val}').value;

this.AI3695_52 = this.HX2List.find(c=> c.id == '{[HX2]AI3695.Val}').value;
this.TI3700_53 = this.HX2List.find(c=> c.id == '{[HX2]TI3700.Val}').value;
this.CQI9864_54 = this.HX2List.find(c=> c.id == '{[HX2]CQI9864.Val}').value;
this.CFI9862_55 = this.HX2List.find(c=> c.id == '{[HX2]CFI9862.Val}').value;
this.CPI9856_56 = this.HX2List.find(c=> c.id == '{[HX2]CPI9856.Val}').value;


var PI3605_Outlet = this.HX2List.find(c => c.id == '{[HX2]PI3605.Val}').value;
var PI3600_Inlet = this.HX2List.find(c => c.id == '{[HX2]PI3600.Val}').value;

//
this.DataFromMachine = this.HX2List.find(c => c.id == 'DataFromMachine').value;


this.lastupdateddate = this.HX2List.find(c => c.id == 'LocalPCDate').value +' '+  this.HX2List.find(c => c.id == 'LocalPCTime').value;

if(PI3605_Outlet!= '' && PI3600_Inlet!=''){
var temp_CPI9850_Delta = parseFloat(PI3600_Inlet) - parseFloat(PI3605_Outlet);

if(temp_CPI9850_Delta<0)
  temp_CPI9850_Delta = (temp_CPI9850_Delta) * -1;

  this.CPI9850_04 =
  (this.decimalPipe.transform(temp_CPI9850_Delta , '1.1-1')).toString();
}

  var PI3715_Outlet = this.HX2List.find(c => c.id == '{[HX2]PI3715.Val}').value;
  var PI3705_Inlet = this.HX2List.find(c => c.id == '{[HX2]PI3705.Val}').value;

  if(PI3715_Outlet!= '' && PI3705_Inlet!=''){
    var temp_CPI9852_Delta = parseFloat(PI3705_Inlet) - parseFloat(PI3715_Outlet);
    if(temp_CPI9852_Delta<0)
      temp_CPI9852_Delta = (temp_CPI9852_Delta) * -1;

    this.CPI9852_81 =
      (this.decimalPipe.transform(temp_CPI9852_Delta , '1.1-1')).toString();
  }

  if(this.XS3470_70 == 'Break Tank'){
    this.XS3470_70 ='BT';
    this.XS3470_70_Title = 'Break Tank';
    document.getElementById('BTArrow').style.display='block';
    document.getElementById('LoopArrow').style.display='none';

  } else if(this.XS3470_70 == 'Loop'){
    this.XS3470_70 ='L';
    this.XS3470_70_Title = 'Loop';
    document.getElementById('BTArrow').style.display='none';
    document.getElementById('LoopArrow').style.display='block';
  }

  //
  // this.lastupdateddate = this.tempLastupdateDate;

//
  if(this.systemmode!=''){
    this.stopTimer();
    this.communicationstatus = '';

    // document.getElementById('communicationRed').style.display = 'none';
    // document.getElementById('communicationGreen').style.display = 'block';

    // if (this.DataFromMachine == '1') {
    //   document.getElementById('machineGreen').style.display = 'block';
    //   document.getElementById('machineRed').style.display = 'none';
    // } else {
    //   document.getElementById('machineGreen').style.display = 'none';
    //   document.getElementById('machineRed').style.display = 'block';
    //   this.HX2List.forEach(element => { element.value = ''});

    // }
  }

  if(this.RODate=="" || this.RODate==undefined)
    this.CheckServiceDateTime();
}

ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerCheckDateTime);
    clearInterval(this.interval);
}

RouteClick(value) {
  this.router.navigate([value]);
}

OnClick(value)
{
  alert(value);
}

CheckServiceDateTime(){
  this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {

  this.today = new Date(currentDate);
  });


  let FetchedDate = this.HX2List.find(c=>c.id=='LocalDate').value;
  let FetchedTime = this.HX2List.find(c=>c.id=='LocalTime').value;

  this.dateArray = FetchedDate.split("/");
  this.timeArray = FetchedTime.split(":");

  if(this.dateArray.length > 1){
    let dateTime =this.dateArray[2] + "-" + this.dateArray[0] + "-" + this.dateArray[1];
    this.LastFetchedRODateTime = new Date(dateTime).toUTCString();

    if(FetchedDate==this.RODate && FetchedTime == this.ROTime){
      this.IsTimeRed = true;
    } else {
      this.IsTimeRed = false;
    }
  }
  if(FetchedDate != ''){
    if(this.datePipe.transform(this.today, 'MM/dd/yyyy') != FetchedDate)
      this.IsDateRed = true;
    else
      this.IsDateRed = false;
  }

  this.RODate = FetchedDate;
  this.ROTime = FetchedTime;


  // let dateTime =this.dateArray[2] + "-" + this.dateArray[0] + "-" + this.dateArray[1]+'T'+this.timeArray[0] + ":" + this.timeArray[1] + ":" + this.timeArray[2];


if(this.systemmode!=''){
  this.stopTimer();
  this.communicationstatus = '';

  if (this.DataFromMachine == '1') {
    document.getElementById('machineGreen').style.display = 'block';
    document.getElementById('machineRed').style.display = 'none';
  } else {

    document.getElementById('machineGreen').style.display = 'none';
    document.getElementById('machineRed').style.display = 'block';
  }

  if(this.IsTimeRed) {

    document.getElementById('communicationGreen').style.display = 'none';
    document.getElementById('communicationRed').style.display = 'block';

    document.getElementById('machineGreen').style.display = 'none';
    document.getElementById('machineRed').style.display = 'block';

    if(this.DataFromMachine == '0')
      this.communicationstatus = "Communication Error, There is some issue in HX2 to Local PC Communication.";
    else
      this.communicationstatus = "Communication Error, There is some issue in Fetch Service or Local PC to Server Communication.";

    this.RODate = '';

    this.HX2List.forEach(element => { element.value = ''});

  } else {
    document.getElementById('communicationGreen').style.display = 'block';
    document.getElementById('communicationRed').style.display = 'none';
  }
}
//  else {
//   this.stopTimer();
//   this.communicationstatus = '';
//     document.getElementById('communicationRed').style.display = 'block';
// }
}

RODate : any;
ROTime : any;
dateArray : any;
timeArray : any;
LastFetchedRODateTime : any;
IsTimeRed = false;
IsDateRed = false;
private today = new Date();
}

<section class="content-header">
        <h1>
          <h1>
            {{ title }}
            <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
          </h1>
        </h1>
      </section>
      <section>
        <section class="content">
          <div class="row">
                <div class="box  box-primary" style="overflow-y: auto;">
                    <div class="box-header pageheader row" style="max-width: 95%;">
                        <div class="col-sm-1 text-left">

                        </div>
                        <div class="col-sm-10 text-center">
                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                            <i class="fa fa-picture-o"></i> Layout View
                          </a>
                          <a type="button" class="btn btn-primary"   style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                            <i class="fa fa-table"></i>  Table View
                          </a>
                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                            <i class="fa fa-th-list"></i> Set Points
                          </a>
                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                          (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                          <i class="fa fa-file"></i> Document View
                        </a>
                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                            <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                          </a>

                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                            <i class="fa fa-th-list"></i> Data Logs
                          </a>

                          <a type="button" class="btn btn-primary" style="max-width: fit-content;"
              (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
              <i class="fa fa-th-list"></i> Notification Settings
            </a>
                        </div>
                        <div class="col-sm-1 text-right">
                          <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                            (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                            <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                          </a>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center">
                      <label style="font-weight: normal;color:red">{{communicationstatus}}</label>
                    </div>
                    <div class="col-sm-12">
                <div class="col-sm-4">
                    <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                        <thead>
                            <tr>
                                <th>Field Name</th>
                                <th>Field Value</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr *ngFor="let item of filterHX2List(1);let index=index;" class="pointer">
                                        <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                                        <td class="emptyvalue" matTooltip="{{item.value}}" *ngIf="item.id == 'LocalDate'"
                                        [ngStyle]="{'color': (IsDateRed == true ? 'red' :'') }" style="text-align:left;max-width: 150px;width: 150px;">
                                          {{ item.value}}
                                        </td>
                                        <td class="emptyvalue" matTooltip="{{item.value}}" *ngIf="item.id == 'LocalTime'"
                                        [ngStyle]="{'color': (IsTimeRed == true ? 'red' :'') }" style="text-align:left;max-width: 150px;width: 150px;">
                                          {{ item.value}}
                                        </td>
                                        <td class="emptyvalue" matTooltip="{{item.value}}" *ngIf="item.id !== 'LocalDate' && item.id !== 'LocalTime'" style="text-align:left;max-width: 150px;width: 150px;text-overflow: ellipsis;overflow: hidden;">
                                          <!-- {{ (item.value.length>17)? (item.value | slice:0:17)+'..':(item.value) }} -->
                                          {{item.value}}
                                        </td>
                                </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-4">
                  <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                      <thead>
                          <tr>
                              <th>Field Name</th>
                              <th>Field Value</th>
                          </tr>
                      </thead>
                      <tbody>
                              <tr *ngFor="let item of filterHX2List(2);let index=index;" class="pointer">
                                      <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                                      <td class="emptyvalue" matTooltip="{{item.value}}" style="text-align:left;max-width: 150px;width: 150px;text-overflow: ellipsis;overflow: hidden;">
                                        <!-- {{ (item.value.length>17)? (item.value | slice:0:17)+'..':(item.value) }} -->
                                        {{item.value}}
                                      </td>
                              </tr>
                      </tbody>
                  </table>
              </div>
              <div class="col-sm-4">
                <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                    <thead>
                        <tr>
                            <th>Field Name</th>
                            <th>Field Value</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr *ngFor="let item of filterHX2List(3);let index=index;" class="pointer">
                                    <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                                    <td class="emptyvalue" matTooltip="{{item.value}}" *ngIf="item.id == 'LocalPCTime'"
                                    [ngStyle]="{'color': (IsLocalTimeRed == true ? 'red' :'') }" style="text-align:left;max-width: 150px;width: 150px;text-overflow: ellipsis;overflow: hidden;">
                                      {{ item.value}}
                                    </td>
                                    <td class="emptyvalue" matTooltip="{{item.value}}" *ngIf="item.id == 'LocalPCDate'"
                                        [ngStyle]="{'color': (IsDateRed == true ? 'red' :'') }" style="text-align:left;max-width: 150px;width: 150px;">
                                          {{ item.value}}
                                        </td>
                                    <td class="emptyvalue" matTooltip="{{item.value}}"  *ngIf="item.id !== 'LocalPCTime' &&  item.id !== 'LocalPCDate'" style="text-align:left;max-width: 150px;width: 150px;text-overflow: ellipsis;overflow: hidden;">
                                      <!-- {{ (item.value.length>17)? (item.value | slice:0:17)+'..':(item.value) }} -->
                                      {{item.value}}
                                    </td>
                            </tr>
                    </tbody>
                </table>
            </div>
          </div>
            </div>
          </div>
        </section>
    </section>

import { HomeService } from './../../../API/home.service';
import { Component, OnInit, ViewChild, ViewEncapsulation, ContentChild, AfterContentInit } from "@angular/core";
import { InvApiService } from './../../../API/invapi.service';
import { HomeFieldService } from './../../../API/homefield.service';
import { AppComponent } from 'src/app/app.component';

import { UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import { DMApiService } from "../../../API/dmapi.service";
import { DMRepairDetailComponent } from "../../../DialysisMachines/DMRepairDetail.component";
import { RepairDetailComponent } from '../../../Core/Shared/Repairs/RepairDetail.component';

import { Globals } from '../../../globals';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MenuSharedService } from 'src/app/Core/services/menu-shared.service';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';

import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeRepair',
  templateUrl: './purpose-repair.component.html',
  styleUrls: ['./purpose-repair.component.css']
})
export class PurposeRepairComponent implements OnInit, AfterContentInit {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  matDialogRef: MatDialogRef<ConfirmationDialog>;

  errormsg = '';
  constructor(private invapi: InvApiService, private homeApi: HomeService, private homefieldApi: HomeFieldService, private dmapi: DMApiService,
    public dialogRef: MatDialogRef<PurposeRepairComponent>, public dialog: MatDialogRef<DMRepairDetailComponent>,
    public dialogMsg: MatDialog,
    public menuSharedService: MenuSharedService, public auth: AuthService
  ) { }
  IsPurposeCompleted = '';
  isFormSubmitted = false;
  ErrorTrans = [];
  tooltipText = 'Click To Add';
  CategoryList = [];
  HFServiceId = 0;
  selectedDialysisStation = '';
  remarks = '';
  selectedDialysisStationtext = '';
  selectedEquipment = '';
  PartTrans = [];
  selectedDialysisUnits = '';
  RepairCategoryList = [];
  public PurposeType = '';
  public RepairType = "";
  HFServiceVisitDate = new Date();
  @ViewChild(DMRepairDetailComponent, { static: false })
  dmrepair: DMRepairDetailComponent;


  @ViewChild(RepairDetailComponent, { static: false })
  wprepair: RepairDetailComponent;
  repairForm = new UntypedFormGroup({
    //EquipmentExchangeType : new FormControl(''),
  });

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.HFServiceVisitDate = new Date(currentDate);
    }, error => {
    });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.SetRepairComponent();
    }, 500);
  }

  SetRepairComponent(showRemarks = false) {

    if (this.RepairType == 'Dialysis Machines') {
      if (this.dmrepair) {
        this.dmrepair.homeService = true;
        this.dmrepair.showRemarks = showRemarks;
        this.dmrepair.f.HFServiceId.setValue(this.HFServiceId);
        this.dmrepair.f.selectedDialysisStation.setValue(this.selectedDialysisStation);
        this.dmrepair.f.remarks.setValue(this.remarks);
        this.dmrepair.selectedDialysisStationtext = this.selectedDialysisStationtext;
        this.SetModel(this.HFServiceId);
        this.dmrepair.f.selectedEquipment.setValue(this.selectedEquipment);
        this.dmrepair.f.repairStartDate.setValue(this.HFServiceVisitDate);
        this.dmrepair.f.selectedDialysisUnits.setValue(this.selectedDialysisUnits);

        this.homeApi.GetDialysisStationByID({ Id: this.selectedDialysisStation }).subscribe(
          success => {
            if (success) {
              this.selectedEquipment = (success.dmEquipmentId ? success.dmEquipmentId : '');
              this.dmrepair.f.selectedEquipment.setValue(this.selectedEquipment);
              this.dmrepair.selectedEquipmenttext = (success.dmEquipmentName ? success.dmEquipmentName : '');
              this.dmrepair.EquipmentDropDownChanged();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
    }
    else if(this.RepairType == 'Other Equipment' || this.RepairType == 'Water Process'){
      if (this.wprepair) {
        this.wprepair.homeService = true;
        this.wprepair.showRemarks = showRemarks;
        this.wprepair.f.HFServiceId.setValue(this.HFServiceId);
        this.wprepair.f.remarks.setValue(this.remarks);
        this.wprepair.selectedDialysisStationtext = this.selectedDialysisStationtext;
        this.SetModel(this.HFServiceId);
        this.wprepair.f.selectedRepairEvent.setValue('R');
        this.wprepair.f.timeStarted.setValue(this.HFServiceVisitDate);
        this.wprepair.f.formDate.setValue(this.HFServiceVisitDate);
        this.wprepair.f.selectedDialysisUnits.setValue(this.selectedDialysisUnits);

        this.homeApi.GetDialysisStationByID({ Id: this.selectedDialysisStation }).subscribe(
          success => {
            if (success) {

              this.selectedEquipment = (success.roEquipmentId ? success.roEquipmentId : '');
              this.wprepair.f.selectedEquipment.setValue(this.selectedEquipment);
              this.wprepair.selectedEquipmenttext = (success.roEquipmentName ? success.roEquipmentName : '');
              this.wprepair.EquipmentDropDownChanged();
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
    }
    else{
      this.RepairType = 'Select';
      this.SetModel(this.HFServiceId);
    }
  }

  OnRepairTypeChange(){

    setTimeout(() => {
      this.SetRepairComponent(true);
    }, 700);
  }


  // ngAfterViewInit() {
  //   setTimeout(() =>
  //   {
  //
  //     this.dmrepair.homeService = true;
  //     this.dmrepair.f.HFServiceId.setValue(this.HFServiceId);
  //     this.dmrepair.f.selectedDialysisStation.setValue(this.selectedDialysisStation);
  //     this.dmrepair.f.remarks.setValue(this.remarks);
  //     this.dmrepair.selectedDialysisStationtext = this.selectedDialysisStationtext;
  //     this.SetModel(this.HFServiceId);
  //     this.dmrepair.f.selectedEquipment.setValue(this.selectedEquipment);
  //     this.dmrepair.EquipmentDropDownChanged();
  //     this.dmrepair.f.selectedDialysisUnits.setValue(this.selectedDialysisUnits);
  //   }, 500);
  // }


  get f() {
    return this.repairForm.controls;
  }


  fetchCompletedTypes(){
    this.homefieldApi.GetHomeFieldService_PurposeCompletedTypeListbyServiceID({ Id: this.HFServiceId, type:'Repair' }).subscribe(
      success => {

        if (success) {
          let catList = [];
          catList.push({value:'',text:'Select'});
          success.forEach((i, newKey) => {
            let category = this.RepairCategoryList.filter(
              x => x.text == i
            );
            if(category && category.length > 0)
              catList.push(category[0]);
          });
          this.RepairCategoryList = catList;
        }
      },
      error => {
          AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  fillCategories() {
    // this.invapi.FillCategoryList().subscribe(list => {
    //   this.CategoryList = list.filter(
    //     x => x.text !== 'Other Equipment'
    //   );
    // });

    // this.f.EquipmentExchangeType.setValue('Select');
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  saveAndClose() {


   if((this.RepairType == 'Dialysis Machines' && this.dmrepair.status != 'Completed') ||
        (this.RepairType == 'Water Process' && this.wprepair.status != 'Completed'))
   {
    if ((this.dmrepair != undefined &&  this.dmrepair.repairDetailForm.dirty) || (this.wprepair && this.wprepair.repairDetailForm.dirty)) {
      if ((this.dmrepair && this.dmrepair.repairDetailForm.invalid) || (this.wprepair && this.wprepair.repairDetailForm.invalid)) {
        this.matDialogRef = this.dialogMsg.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

            if(result)
              this.dialogRef.close(false);
            else
              this.save();
          });
      }
      else{
          this.save();
      }
    } else {
      this.dialogRef.close(false);
    }
  }
  else
  {
    this.dialogRef.close(false);
  }
  }

  save() {

    if (this.RepairType == 'Dialysis Machines') {
      this.dmrepair.f.HFServiceId.setValue(this.HFServiceId);
      this.dmrepair.executeDmrepairCreateUpdate('', true).then((item) => {

        if (item) {
          if (item['isSubmitted']) {
            this.PartTrans =  this.dmrepair.parts.PartTrans;
            this.errormsg = item['errormsg'];
            AppComponent.addAlert(item['errormsg'], 'success');
            this.close(this.PartTrans);
          }
          else {
            this.errormsg = item['errormsg'];
            // this.scrollHelper.scrollToFirst("errormsg");
            // AppComponent.addAlert(item['errormsg'], 'error');
          }
        }
      }, () => {
        AppComponent.addAlert("Error", 'error');
      });;
    }
    else {
      this.wprepair.f.HFServiceId.setValue(this.HFServiceId);
      this.wprepair.executerepairCreateUpdate('', true).then((item) => {

        if (item) {
          if (item['isSubmitted']) {
            this.PartTrans =  this.wprepair.parts.PartTrans;
            this.errormsg = item['errormsg'];
            AppComponent.addAlert(item['errormsg'], 'success');
            this.close(this.PartTrans);
          }
          else {
            this.errormsg = item['errormsg'];
            this.scrollHelper.scrollToFirst("errormsg");
            AppComponent.addAlert(item['errormsg'], 'error');
          }
        }
      }, () => {
        AppComponent.addAlert("Error", 'error');
      });;
    }

  }

  SetModel(HFServiceId) {

    if (this.RepairType == 'Dialysis Machines') {
      if (this.dmrepair) {
      this.dmrepair.homeService = true;
      this.dmrepair.f.HFServiceId.setValue(HFServiceId);
      this.dmrepair.HomeServiceSetModel();
      }
    } else {
      if (this.wprepair) {
      this.wprepair.f.HFServiceId.setValue(HFServiceId);
      this.wprepair.homeService = true;
      this.wprepair.HomeServiceSetModel();
      }
    }
  }
  formChange(enable = false) {

    setTimeout(() => {
      if (enable) {
        if (this.RepairType === 'Dialysis Machines') {

          if (this.dmrepair) {
            this.dmrepair.repairDetailForm.enable();
            this.dmrepair.disableControl = false;
            this.dmrepair.notes.disableControl = false;
            this.dmrepair.parts.disableControl = false;
          }
        } else {
          if (this.wprepair) {
            this.wprepair.repairDetailForm.enable();
            this.wprepair.disableControl = false;
            this.wprepair.notes.disableControl = false;
            this.wprepair.parts.disableControl = false;
          }
        }
      } else {
        if (this.RepairType === 'Dialysis Machines') {
          if (this.dmrepair) {
            this.dmrepair.repairDetailForm.disable();
            this.dmrepair.disableControl = true;
            this.dmrepair.notes.disableControl = true;
            this.dmrepair.parts.disableControl = true;
          }
        } else {
          if (this.wprepair) {
            this.wprepair.repairDetailForm.disable();
            this.wprepair.disableControl = true;
            this.wprepair.notes.disableControl = true;
            this.wprepair.parts.disableControl = true;
          }
        }
      }

    }, 700);
  }

  close(parts) {
    this.dialogRef.close(parts);
  }
}


import { Component, ViewChild } from '@angular/core';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { filter, pairwise } from 'rxjs/operators';
import { PreviousRouteService } from 'src/app/Core/services/previous-route.service';
import { WPApiService } from 'src/app/API/wpapi.service';
import { InvApiService } from 'src/app/API/invapi.service';
import { AuthService } from 'src/app/API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
    selector: 'WaterAnalysisSearch',
    templateUrl: './AnalysisSearch.component.html'
})
export class WaterAnalysisSearchComponent {
    UserList: any;
    DivisionList = [];
    Statuses = [
        { value: "", text: "Select" },
        { value: "In Progress", text: "In Progress" },
        { value: "Completed", text: "Completed" }
    ];
    page;
    constructor(
        private wpApi: WPApiService,
        private invapi: InvApiService,
        private router: Router,
        private route: ActivatedRoute,
        private dataGridService: TableHeadsService,
        private previousRouteService: PreviousRouteService,
        private auth: AuthService,
        private commonapi: CommonApiService
    ) {
        this.page = new WaterAnalysisSearchPage();
        this.page.pageNumber = 1;
        this.page.size = 25;
    }

    @ViewChild(PageHeaderComponent, { static: true })
    pageheader: PageHeaderComponent;
    loading = true;
    blnDirtySearch = false;
    tableHeaders;
    rows;
    AddUrl = 'WaterProcess/Analysis/AnalysisDetail';

    ShowSearch = false;
    title = 'Water Process Analysis';
    searchText = 'Search Records';
    defaultSort;
    previousUrl: string;

    public myOptions: IAngularMyDpOptions = {
        dateFormat: 'dd/mm/yyyy',
        closeSelectorOnDateSelect: false,
        disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
    };

    ngOnInit() {
        this.auth.GetCurrentUserDivision().subscribe(division => {

            this.dataGridService.clearHeader();
            this.dataGridService.addHeader('#', 'eventid', '4');
            this.dataGridService.addHeader('Event Id', 'eventid', '10');
            this.dataGridService.addHeader('Result', 'result', '10');
            this.dataGridService.addHeader('Division Name', 'divisionName', '20');
            this.dataGridService.addHeader('Collection Date', 'collectiondate', '10');
            this.dataGridService.addHeader('Received Date', 'receiveddate', '10');
            this.dataGridService.addHeader('Report Number', 'reportNumber', '15');
            this.dataGridService.addHeader('Technologist', 'createdbyName', '13');
             this.dataGridService.addHeader('Status', 'status', '8');
            this.tableHeaders = this.dataGridService.setTableHeaders();
            this.defaultSort = this.dataGridService.setDefaultSort('Product/Part Id', false);

            if (division.length === 1)
                this.FillDivision(division[0]);
            else
                this.FillDivision();

            this.CheckDirtySearch();

            this.executeWaterAnalysisSearch();
        }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
        });
    }

    FillDivision(defaultValue = '') {
        // this.invapi.FillDivision().subscribe(list => {
            this.commonapi.FillDivision(true).subscribe(list => {
            this.DivisionList = list;

            if (defaultValue) {
                let defaultVal = list.filter(
                    x => x.value == defaultValue
                );

                if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
                    this.page.selectedDivision = defaultVal[0].text;
                    setTimeout(() => this.executeWaterAnalysisSearch());
                }
            }
        });
    }


    onSearchShowChange(value) {
        this.ShowSearch = value;
    }

    changeSorting(column) {
        if (column === '#') {
            return;
        }

        this.dataGridService.changeSorting(
            column,
            this.defaultSort,
            this.tableHeaders
        );
        this.defaultSort = this.dataGridService.getSort();
        this.page.sortDirection = this.dataGridService.getSortDirection();
        this.page.sortExpression = this.dataGridService.getSortExpression();
        this.page.currentPageNumber = 1;
        this.page.pageNumber = 0;
        this.executeWaterAnalysisSearch();
    }

    setSortIndicator = function (column) {
        return this.dataGridService.setSortIndicator(column, this.defaultSort);
    };

    executeWaterAnalysisSearch() {
        AppComponent.ShowLoader();
        this.pageheader.isSearchOpen = false;
        this.pageheader.searchText = 'Search Records';
        this.ShowSearch = false;
        this.searchText = 'Search Records';
        this.CheckDirtySearch();
        const WaterAnalysisSearch = this.prepareSearch();
        this.wpApi.GetWaterProcessAnalysisRecordsSearch(WaterAnalysisSearch).subscribe(
            pagedData => {
                this.page.totalElements = pagedData.totalRows;
                this.page.totalPages = pagedData.totalPages;
                this.rows = pagedData.analysisList;
                AppComponent.HideLoader();
            },
            error => {
                AppComponent.addAlert(error.message, 'error');
                AppComponent.HideLoader();
            }
        );
    }

    prepareSearch() {
        const WaterAnalysisSearch = <any>{};

        WaterAnalysisSearch.eventId = this.page.EventId;

        if (this.page.FromDate) {
            WaterAnalysisSearch.FromDate = Globals.GetAPIDate(this.page.FromDate.jsdate);
        }

        if (this.page.ToDate) {
            WaterAnalysisSearch.ToDate = Globals.GetAPIDate(this.page.ToDate.jsdate);
        }

        if (this.page.receivedfromDate) {
            WaterAnalysisSearch.receivedfromDate = Globals.GetAPIDate(this.page.receivedfromDate.jsdate);
        }

        if (this.page.receivedtoDate) {
            WaterAnalysisSearch.receivedtoDate = Globals.GetAPIDate(this.page.receivedtoDate.jsdate);
        }

        WaterAnalysisSearch.reportNumber = this.page.reportNumber;

        if (this.page.selectedDivision &&  this.page.selectedDivision !== undefined && this.page.selectedDivision !== '' && this.page.selectedDivision !== '0' && this.page.selectedDivision !== 'All Divisions' ) {
            WaterAnalysisSearch.divisionName = this.page.selectedDivision;
        }

        if (this.page.selectedStatus) {
            WaterAnalysisSearch.status = this.page.selectedStatus;
        }

        WaterAnalysisSearch.currentPageNumber = this.page.currentPageNumber;
        WaterAnalysisSearch.sortExpression = this.page.sortExpression;
        WaterAnalysisSearch.sortDirection = this.page.sortDirection;
        WaterAnalysisSearch.pageSize = this.page.pageSize;

        this.invapi.setSearchValue(this.page);

        return WaterAnalysisSearch;
    }

    setPage() {
        this.page.currentPageNumber = this.page.pageNumber;
        this.executeWaterAnalysisSearch();
    }
    _onRowClick(row) {
        this.router.navigate([this.AddUrl], { queryParams: { id: row } });
    }

    onDateChanged(event: IMyDateModel): void {
        // date selected
    }

    executeWaterAnalysisSearchClear() {
        this.page.EventId = '',
            this.page.selectedDivision = 'All Divisions';
        this.page.reportNumber = '';
        this.page.FromDate = '';
        this.page.ToDate = '';
        this.page.receivedfromDate = '';
        this.page.receivedtoDate = '';
        this.page.selectedStatus = '';
        this.executeWaterAnalysisSearch();
    }

    CheckDirtySearch() {
        this.blnDirtySearch = false;
        if (
            this.page.EventId ||
            (this.page.selectedDivision && this.page.selectedDivision !== 'All Divisions') ||
            this.page.reportNumber ||
            this.page.FromDate ||
            this.page.ToDate ||
            this.page.receivedfromDate ||
            this.page.receivedtoDate ||
            this.page.selectedStatus
        ) {
            this.blnDirtySearch = true;
        }
        return this.blnDirtySearch;
    }

    DialysisUnitDropDownChanged() {
        this.executeWaterAnalysisSearch();
    }
}

export class WaterAnalysisSearchPage {
    // The number of elements in the page
    size = 0;
    // The total number of elements
    totalElements = 0;
    // The total number of pages
    totalPages = 0;
    // The current page number
    pageNumber = 0;

    pageSize = 25;

    EventId = '';
    selectedDivision = 'All Divisions';
    reportNumber = '';
    FromDate = '';
    ToDate = '';
    receivedfromDate='';
    receivedtoDate ='';
    selectedStatus = '';
    currentPageNumber = 1;
    sortExpression = 'default';
    sortDirection = 'ASC';
}

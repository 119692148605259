<section class="content-header">
  <h1>
     Staff Schedule
      <!-- <sup class="beta">[beta]</sup> -->
      <sup class="beta">Demo</sup>
  </h1>

</section>
<section class="content">
  <div class="row">
      <!-- /.box -->
      <div class="box  box-primary">

          <!-- <PageHeader
              title="Schedule"
              [showNote]="false"
              [showAddNew]="false"
              [showSearch]="false"
              [(searchText)]="searchText"

              [showSave]="false"
              [showDelete]="false"
              [showPreviousPage]="true"
              [showMainListing]="false"
              #pageheader
          ></PageHeader> -->

          <div class="col-md-12 box">

              <div class="box ibox box-info">
                  <div class="box-body">
                      <div class="col-sm-12" *ngIf="blnShowLock">
                          <div class="col-sm-12" style="padding-right: 10px">
                          <!-- <div class="pull-right" *ngIf="selectedDivision > 0"> -->
                          <div class="pull-right">
                              <button type="button" (click)="ChangeLock(true);" style="width:100px" [disabled]="IsLock" class="btn btn-success"><i class="fa fa-lock" aria-hidden="true"></i> <b>&nbsp;Lock</b></button> &nbsp;
                              <button type="button" (click)="ChangeLock(false);" style="width:100px" [disabled]="!IsLock" class="btn btn-success"><i class="fa fa-unlock" aria-hidden="true"></i><b>&nbsp;Unlock</b></button>

                            </div>
                          </div>

                      </div>

                      <div class="clearfix"></div>

                      <div class="box-body">
                          <div class="row">
                              <div class="col-sm-12">

                                  <div class="col-sm-9">
                                      <div>
                                          <!-- <label class="col-sm-3 control-label">Division:</label> -->
                                          <div class="col-sm-9">
                                              <!-- <label *ngIf="!blnShowDivision" class="control-label">SCSCDU</label>
                                              <select *ngIf="blnShowDivision"
                                                  (change)="DialysisUnitDropDownChanged()"
                                                  class="form-control"
                                                  [(ngModel)]="selectedDivision"
                                              >
                                                  <option
                                                      value="{{item.value}}"
                                                      *ngFor="let item of DialysisUnits"
                                                  >
                                                      {{item.text}}
                                                  </option>
                                              </select> -->
                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-sm-3 ">
                                      <div class="pull-right">
                                        <div class="pull-right">
                                          <button type="button" style="width:100px" (click)="ShowColors();" class="btn btn-primary"><i class="fa fa-th" aria-hidden="true"></i> <b>&nbsp;Colors</b></button>&nbsp;&nbsp;
                                          <button type="button" style="width:100px" (click)="Print();" class="btn btn-primary"><i class="fa fa-print" aria-hidden="true"></i> <b>&nbsp;Print</b></button>
                                          <!-- <button type="button" style="width:100px" (click)="Print();" [disabled]="selectedDivision < 1" class="btn btn-primary"><i class="fa fa-print" aria-hidden="true"></i> <b>&nbsp;Print</b></button> -->

                                        </div>
                                      </div>
                                      <div class="clearfix"></div>
                                  </div>
                                  <div class="clearfix"></div>
                              </div>
                          </div>
                      </div>
                      <div class="box-body" style="padding: 0px 10px">
                            <div class="row">
                              <div class="col-sm-12">

                                    <div class="col-sm-9">
                                            <!-- <div
                                            class="col-sm-12 text-center"
                                            *ngIf="selectedDivision > 0"> -->
                                            <div
                                            class="col-sm-12 text-center">
                                            <h4


                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    (click)="PreviousList()"
                                                >
                                                    <<
                                                </a>

                                                    {{DateList[0] | date:'MMMM dd'}} - {{DateList[(DateList.length -1)] | date:'MMMM dd'}} , {{DateList[(DateList.length -1)] | date:'yyyy'}}

                                                <a
                                                    href="javascript:void(0)"
                                                    (click)="NextList()"
                                                >
                                                    >>
                                                </a>
                                              </h4>
                                        </div>
                                    </div>

                                    <div class="col-sm-3 ">
                                        <div class="pull-right">
                                          <div class="pull-right">
                                                <button type="button" (click)="CopyScheduleList();" style="width:100px"  [disabled]="!IsLock" class="btn btn-warning"><i class="fa fa-copy" aria-hidden="true"></i> <b>&nbsp;Copy</b></button> &nbsp;
                                                <button type="button" (click)="PasteSchedule();" style="width:100px" [disabled]="IsLock" class="btn btn-warning"><i class="fa fa-paste" aria-hidden="true"></i><b>&nbsp;Paste</b></button>

                                          </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                          </div>
                      </div>

                      <div class="clearfix"></div>

                        <!-- <div
                            class="col-sm-12 box-row text-center"
                            style="margin-top: 20px;"
                            *ngIf="selectedDivision > 0"
                        > -->
                        <div
                            class="col-sm-12 box-row text-center"
                            style="margin-top: 20px;">
                            <table
                                style="width:100%"
                                align="center"
                                class="schedule_cal"
                            >
                                <tbody>
                                    <tr>
                                        <th
                                            colspan="2"
                                            scope="col"
                                            width="65px"
                                        >

                                        </th>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="i < 14">
                                                <th>{{item | date:'MMM dd'}}</th>
                                            </ng-container>
                                        </ng-container>

                                    </tr>
                                    <tr>
                                        <td
                                            colspan="2"
                                            width="65px"
                                        ></td>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="i < 14">
                                                <td>{{(item | date:'E')}}</td>
                                            </ng-container>
                                        </ng-container>
                                    </tr>

                                    <tr *ngFor="let item of SchList">
                                        <td
                                            width="60px"
                                            nowrap="nowrap"
                                            style="text-align: left"
                                        >
                                            <input
                                                type="hidden"
                                                [(ngModel)]="item.userid"
                                                value="{{item.userid}}"
                                            >
                                            {{item.userName}}
                                        </td>
                                        <td
                                            width="5px"
                                            style="text-align: left"
                                        >
                                            {{item.jobstatus}}
                                        </td>

                                        <td width="81px" [style.background-color]="SetColor(DateList[0],item.date1)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date1)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date1"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[1],item.date2)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date2)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date2"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[2],item.date3)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date3)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date3"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[3],item.date4)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date4)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date4"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[4],item.date5)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date5)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date5"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[5],item.date6)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date6)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date6"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[6],item.date7)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date7)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date7"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[7],item.date8)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date8)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date8"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[8],item.date9)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date9)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date9"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[9],item.date10)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date10)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date10"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[10],item.date11)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date11)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date11"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[11],item.date12)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date12)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date12"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[12],item.date13)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date13)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date13"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[13],item.date14)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date14)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date14"
                                            >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <!-- <div
                            class="col-sm-12 box-row text-center"
                            style="margin-top: 20px;"
                            *ngIf="selectedDivision > 0"
                        > -->
                        <div
                        class="col-sm-12 box-row text-center"
                        style="margin-top: 20px;"
                        >
                            <table
                                style="width:100%"
                                align="center"
                                class="schedule_cal"
                            >
                                <tbody>
                                    <tr>
                                        <th
                                            colspan="2"
                                            scope="col"
                                            width="65px"
                                        >

                                        </th>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="i > 13 && i < 28">
                                                <th>{{item | date:'MMM dd'}}</th>
                                            </ng-container>
                                        </ng-container>

                                    </tr>
                                    <tr>
                                        <td
                                            colspan="2"
                                            width="65px"
                                        ></td>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="i > 13 && i < 28">
                                                <td>{{(item | date:'E')}}</td>
                                            </ng-container>
                                        </ng-container>
                                    </tr>

                                    <tr *ngFor="let item of SchList">
                                        <td
                                            width="60px"
                                            nowrap="nowrap"
                                            style="text-align: left"
                                        >
                                            <input
                                                type="hidden"
                                                [(ngModel)]="item.userid"
                                                value="{{item.userid}}"
                                            >
                                            {{item.userName}}
                                        </td>
                                        <td
                                            width="5px"
                                            style="text-align: left"
                                        >
                                            {{item.jobstatus}}
                                        </td>

                                        <td width="81px" [style.background-color]="SetColor(DateList[14],item.date15)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date15)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date15"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[15],item.date16)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date16)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date16"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[16],item.date17)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date17)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date17"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[17],item.date18)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date18)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date18"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[18],item.date19)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date19)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date19"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[19],item.date20)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date20)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date20"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[20],item.date21)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date21)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date21"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[21],item.date22)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date22)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date22"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[22],item.date23)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date23)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date23"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[23],item.date24)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date24)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date24"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[24],item.date25)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date25)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date25"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[25],item.date26)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date26)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date26"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[26],item.date27)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date27)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date27"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[27],item.date28)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date28)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date28"
                                            >
                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>

                        <!-- <div
                            class="col-sm-12 box-row text-center"
                            style="margin-top: 20px;"
                            *ngIf="selectedDivision > 0"
                        > -->
                        <div
                            class="col-sm-12 box-row text-center"
                            style="margin-top: 20px;">
                            <table
                                style="width:100%"
                                align="center"
                                class="schedule_cal"
                            >
                                <tbody>
                                    <tr>
                                        <th
                                            colspan="2"
                                            scope="col"
                                            width="65px"
                                        >

                                        </th>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="(i > 27)">
                                                <th>{{item | date:'MMM dd'}}</th>
                                            </ng-container>
                                        </ng-container>

                                    </tr>
                                    <tr>
                                        <td
                                            colspan="2"
                                            width="65px"
                                        ></td>
                                        <ng-container *ngFor="let item of DateList; index as i;">
                                            <ng-container *ngIf="i > 27">
                                                <td>{{(item | date:'E')}}</td>
                                            </ng-container>
                                        </ng-container>
                                    </tr>

                                    <tr *ngFor="let item of SchList">
                                        <td
                                            width="60px"
                                            nowrap="nowrap"
                                            style="text-align: left"
                                        >
                                            <input
                                                type="hidden"
                                                [(ngModel)]="item.userid"
                                                value="{{item.userid}}"
                                            >
                                            {{item.userName}}
                                        </td>
                                        <td
                                            width="5px"
                                            style="text-align: left"
                                        >
                                            {{item.jobstatus}}
                                        </td>

                                        <td width="81px" [style.background-color]="SetColor(DateList[28],item.date29)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date29)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date29"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[29],item.date30)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date30)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date30"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[30],item.date31)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date31)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date31"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[31],item.date32)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date32)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date32"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[32],item.date33)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date33)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date33"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[33],item.date34)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date34)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date34"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[34],item.date35)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date35)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date35"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[35],item.date36)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date36)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date36"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[36],item.date37)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date37)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date37"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[37],item.date38)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date38)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date38"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[38],item.date39)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date39)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date39"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[39],item.date40)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date40)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date40"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[40],item.date41)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date41)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date41"
                                            >
                                        </td>
                                        <td width="81px" [style.background-color]="SetColor(DateList[41],item.date42)">
                                            <span *ngIf="IsLock">{{DisplayDesc(item.date42)}}</span>
                                            <input
                                                *ngIf="!IsLock"
                                                type="text"
                                                maxlength="10"
                                                size="8"
                                                [(ngModel)]="item.date42"
                                            >
                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>

                <!-- <div class="box ibox box-info" *ngIf="selectedDivision > 0 && blnShowNote == true"> -->
                <div class="box ibox box-info" *ngIf="blnShowLock == true">
                  <div class="box-header with-border">
                        <h3 class="box-title">Note</h3>
                    </div>
                    <div class="box-body">
                        <div class="row">
                            <div class="box-row col-sm-12 divider">
                                <div class="col-sm-12 center-block text-center">
                                    <textarea *ngIf="!IsLock" [(ngModel)]="Note" autosize  class="form-textarea lgInput"></textarea>
                                    <b *ngIf="IsLock" >{{Note}}</b>
                                </div>

                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <!-- <PageHeader
                title="Schedule"
                [showNote]="false"
                [showAddNew]="false"
                [showSearch]="false"
                [(searchText)]="searchText"

                [showSave]="false"
                [showDelete]="false"
                [showPreviousPage]="true"
                [showMainListing]="false"
                #bpageheader
            ></PageHeader> -->
            </div>
        </div>
    </div>
</section>

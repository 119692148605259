
 <section class="content-header">
    <h1>
            {{title}}
    </h1>

</section>
<section class="content">
<section >
  <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->



      <div class="row">
          <!-- /.box -->
          <div class="box  box-primary">
              <PageHeader  [title]="title"  [showSearch]="false"  [showAddNew]=true [addurl] = 'AddUrl' #pageheader [showMainListing] = "false"></PageHeader>

              <div class="clearfix"></div>
              <div class="box-body" id="dvboxbody">
                <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                            <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                        </div>
                     </div>
                     <div class="col-sm-3">
                        <!-- <div class="col text-left noofrecords" >
                            <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>

                            </div>-->
                        </div>
                        </div>
                    </div>
                  <div>
                    <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                        <thead>



                            <tr>
                                <th *ngFor="let tableHeader of tableHeaders"

                                    [ngClass]="setSortIndicator(tableHeader.Label)"
                                    [style.width.%]="tableHeader.Width"

                                    (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                            </tr>
                        </thead>
                            <tbody>



                                <tr *ngFor="let forumCategoriesDetails of rows;let index=index;"

                                      (click)="_onRowClick(forumCategoriesDetails.id)"
                                     class="pointer"
                                    >
                                    <td>
                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>
                                            {{forumCategoriesDetails.name}}
                                    </td>                                    
                                    <td>
                                        {{forumCategoriesDetails.description}}
                                    </td>                                    
                                    
                                    </tr>
                            </tbody>


                    </table>


                  </div>
                  <!-- <div class="row">
                    <div class="col-lg-12 text-center">
                      <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                    </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                            <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                        </div>
                     </div>
                     <div class="col-sm-3">
                        <!-- <div class="col text-left noofrecords" >
                            <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>

                            </div>-->
                        </div>
                        </div>
                    </div>
              </div>
          </div>
      </div>
  </section>
</section>

import { HomeFieldService } from './../../../API/homefield.service';
import { AppComponent } from 'src/app/app.component';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HomeService } from 'src/app/API/home.service';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeFullInstallation',
  templateUrl: './purpose-fullinstallation.component.html',
  styleUrls: ['./purpose-fullinstallation.component.css']
})
export class PurposeFullInstallationComponent implements OnInit {

  dmEquipmentList = [];
  roEquipmentList = [];
  IsPurposeCompleted = '';
  currentUserId;
  formSubmitted = false;

  fullInstallationForm = new UntypedFormGroup({
    fullInstallationId: new UntypedFormControl('0'),
    dialysisUnitId: new UntypedFormControl('0'),
    HFServiceId: new UntypedFormControl('0'),
    divisionId: new UntypedFormControl(''),
    dmEquipmentId: new UntypedFormControl('', Validators.required),
    roEquipmentId: new UntypedFormControl('', Validators.required),
    dmEquipment: new UntypedFormControl(''),
    roEquipment: new UntypedFormControl(''),
    // poPlumber: new FormControl('', Validators.required),
    // poElectrician: new FormControl('', Validators.required),
    // poMovers: new FormControl('', Validators.required),
    modifiedBy: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
    // poPlumberNote: new FormControl(''),
    // poElectricianNote: new FormControl(''),
    // poMoversNote: new FormControl('')
  });

  today = new Date();
  constructor(private homeApi: HomeService, private homefieldApi: HomeFieldService
    , public auth: AuthService) { }
  // constructor(private homefieldApi: HomeFieldService) { }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      this.fillDMEquipments();
      this.fillROEquipments();
    }, error => {
      this.fillDMEquipments();
      this.fillROEquipments();
    });

  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  fillDMEquipments() {

    const obj = {
      DialysisStationId: this.fif.dialysisUnitId.value,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.dmEquipmentList = list;
    });
  }

  fillROEquipments() {
    const obj = {
      DialysisStationId: this.fif.dialysisUnitId.value,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.roEquipmentList = list;
    });
  }

  get fif() {
    return this.fullInstallationForm.controls;
  }

  prepareCreateViewModel(Source = "") {
    const modelInitCreate = <any>{};

    modelInitCreate.status = "Active";
    modelInitCreate.fullInstallationId = this.fif.fullInstallationId.value;
    modelInitCreate.HFServiceId = this.fif.HFServiceId.value;
    modelInitCreate.dialysisUnitId = this.fif.dialysisUnitId.value;
    modelInitCreate.dmEquipmentId = this.fif.dmEquipmentId.value;
    modelInitCreate.roEquipmentId = this.fif.roEquipmentId.value;
    // modelInitCreate.poPlumber = this.fif.poPlumber.value;
    // modelInitCreate.poElectrician = this.fif.poElectrician.value;
    // modelInitCreate.poMovers = this.fif.poMovers.value;
    // modelInitCreate.poPlumberNote = this.fif.poPlumberNote.value;
    // modelInitCreate.poElectricianNote = this.fif.poElectricianNote.value;
    // modelInitCreate.poMoversNote = this.fif.poMoversNote.value;
    modelInitCreate.roEquipmentId = this.fif.roEquipmentId.value;
    modelInitCreate.createdOn = Globals.GetAPIDate(this.today);
    modelInitCreate.createdBy = this.currentUserId;
    modelInitCreate.modifiedOn = Globals.GetAPIDate(this.today);
    modelInitCreate.modifiedBy = this.currentUserId;
    return modelInitCreate;
  }

  save() {
    return new Promise((resolve, reject) => {
      this.formSubmitted = true;

      if (this.fullInstallationForm.invalid) {
        return;
      }
      else {
        const FIModelCreate = this.prepareCreateViewModel();

        if (this.fif.fullInstallationId.value === '0'
        ) {
          this.homefieldApi.CreateHomeFieldService_FullInstallation(FIModelCreate).subscribe(
            success => {
              this.fif.fullInstallationId.setValue(success.fullInstallationId);
              resolve(true);
            },
            error => {
              reject();
            }
          );
        } else {
          this.homefieldApi.UpdateHomeFieldService_FullInstallation(FIModelCreate).subscribe(
            success => {
              resolve(true);
            },
            error => {
              reject();
            }
          );

        }
      }
    });
  }


  setModel() {
    this.fillDMEquipments();
    this.fillROEquipments();
    this.homefieldApi.GetHomeFieldService_FullInstallationbyServiceID({ Id: this.fif.HFServiceId.value }).subscribe(
      success => {
        if (success) {

          setTimeout(() => {

            this.fif.fullInstallationId.setValue(success.fullInstallationId);
            this.fif.dialysisUnitId.setValue(success.dialysisUnitId);
            this.fif.dmEquipmentId.setValue(success.dmEquipmentId);
            this.fif.roEquipmentId.setValue(success.roEquipmentId);
            this.fif.dmEquipment.setValue(success.dmEquipment);
            this.fif.roEquipment.setValue(success.roEquipment);
            // this.fif.poPlumber.setValue(success.poPlumber);
            // this.fif.poElectrician.setValue(success.poElectrician);
            // this.fif.poMovers.setValue(success.poMovers);
            // this.fif.poPlumberNote.setValue(success.poPlumberNote);
            // this.fif.poElectricianNote.setValue(success.poElectricianNote);
            // this.fif.poMoversNote.setValue(success.poMoversNote);
            this.fif.createdBy.setValue(success.createdBy);
            this.fif.createdOn.setValue(success.createdOn);
          });
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  formChange(enable = false) {
    if (enable)
      this.fullInstallationForm.enable();
    else
      this.fullInstallationForm.disable();
  }
}

import { CommonApiService } from './../../API/commonapi.service.';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { WPApiService } from '../../API/wpapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NoteDialog } from '../../Core/NotesModel/NoteDialog';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { AcidLevelComponent } from '../../Core/AcidLevelComponent/AcidLevlComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { WaterProcessCustomValidators } from '../../Validator/WaterProcessCustomValidators';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'NurseCheckDetail',
  templateUrl: './NurseCheckDetail.component.html'
})
export class NurseCheckDetailComponent implements OnInit, CanComponentDeactivate {

  // Variables
  // -------------------------------------Start----------------------------------------------
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  @ViewChild(AcidLevelComponent, { static: false })
  acidLevel: AcidLevelComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(UntypedFormControl, { static: false })
  mainform: UntypedFormControl;
  noteDialogRef: MatDialogRef<NoteDialog>;
  currentUserId = this.auth.GetUserId();
  pageRights = '';

  isValidFormSubmitted = null;
  ListURL = '/WaterProcess/SaturdayWaterRecordSearch';

  eventId = '[AutoNumber]';

  loading = true;

  NurseCheckDetailsIdvar = '0';

  DialysisUnits = [];
  today = new Date();

  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;

  HardNessList = [];
  Status = 'In Progress';
  DUName = '';
  FirstShiftUserName = '';
  FirstShiftDate = '';
  SecondShiftUserName = '';
  SecondShiftDate = '';
  morningDetailsEnteredBy = '0';
  morningDetailsEnteredOn;
  noonDetailsEnteredBy = '0';
  noonDetailsEnteredOn;

  blnshowSave = false;
  blnshowDelete = false;
  IsShepardForm = false;
  userDesignation = this.auth.GetDesignationName();
  // NumberRegex = '^[0-9]+(.[0-9]{0,2})?$';

  public AcidLevelGroup: UntypedFormGroup = new UntypedFormGroup({});
  // -------------------------------------End------------------------------------------------

  // Form
  // -------------------------------------Start----------------------------------------------
  dailyDetailForm = new UntypedFormGroup({
    NurseCheckDetailsId: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl(''),
    RawWaterFiltersP: new UntypedFormControl('', [Validators.required,
    Validators.pattern(Globals.NumberRegex)]),
    RawWaterFiltersQ: new UntypedFormControl('', [Validators.required,
    Validators.pattern(Globals.NumberRegex)]),
    DPDTestPostTotalChlorine: new UntypedFormControl('', [Validators.required,
    Validators.pattern(Globals.NumberRegex)]),
    ThorntonMeterMorning: new UntypedFormControl('', [Validators.required,
    Validators.pattern(Globals.NumberRegex)]),
    SecondShiftTotalChlorineTextBox: new UntypedFormControl('', [
      Validators.pattern(Globals.NumberRegex)]),
    ThorntonMeterAfternoonTextBox: new UntypedFormControl('', [
      Validators.pattern(Globals.NumberRegex)]),
  });

  // -------------------------------------End------------------------------------------------


  // Constructor
  // -------------------------------------Start----------------------------------------------
  constructor(
    private commonapi : CommonApiService,
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private wpapi: WPApiService
  ) { }
  // implementation of canDeactivate
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.dailyDetailForm.dirty ||
        this.notes.Note !== '' ||
        this.attachment.Queue().length > 0) && (this.isValidFormSubmitted === false)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          //  console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }
  // -------------------------------------End------------------------------------------------


  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  ngOnInit() {

    AppComponent.ShowLoader();
    this.FirstShiftUserName = this.auth.GetUserFLName();
    // attachment changes
    this.attachment.DocId = this.f.NurseCheckDetailsId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.WP_SaturdayWater];
    this.attachment.UploadBy = this.currentUserId;
    this.notes.showHours = true;

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      const test = this.route.queryParams.subscribe(params => {
        this.f.NurseCheckDetailsId.setValue(params['id'] || '0');
        // this.f.selectedDialysisUnits.updateValueAndValidity();
        if (this.f.NurseCheckDetailsId.value !== '0') {
          this.setModel();
        } else {
          this.fillDialysisUnit();

          this.auth.GetCurrentUserDivision().subscribe(division => {

            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
            }

            this.f.selectedDialysisUnits.setValidators([Validators.required]);
            this.f.selectedDialysisUnits.setAsyncValidators(
              WaterProcessCustomValidators.WaterProcessNurseDivisionValidate(this.wpapi)
            );
            this.f.RawWaterFiltersQ.setValidators([Validators.required, Validators.pattern(Globals.NumberRegex),
            WaterProcessCustomValidators.GreaterRestrictCustomValidation(
              this.f.RawWaterFiltersP
            )]);
            this.f.RawWaterFiltersQ.updateValueAndValidity();
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        }
      });
      this.SetPageRights();

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  get f() {
    return this.dailyDetailForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    this.f.selectedDialysisUnits.clearValidators();
    this.f.selectedDialysisUnits.clearAsyncValidators();
    this.attachment.clearQueue();
    this.notes.Note = '';
    const NurseCheckCreate = { Id: this.f.NurseCheckDetailsId.value };
    this.fillDialysisUnit();
    //console.log(NurseCheckCreate);
    this.wpapi.GetSaturdayWaterProcessByID(NurseCheckCreate).subscribe(
      success => {
        // console.log(success);
        this.f.NurseCheckDetailsId.setValue(success.saturdayWaterRecordId);
        this.f.selectedDialysisUnits.setValue(success.divisonId);
        this.DUName = success.divisionName;
        this.f.RawWaterFiltersP.setValue(success.preRawFilter);
        this.f.RawWaterFiltersQ.setValue(success.postRawFilter);
        this.f.DPDTestPostTotalChlorine.setValue(success.morningPostCarbonTotalChlorine);
        this.f.ThorntonMeterMorning.setValue(success.morningThorntonMeter);
        this.eventId = success.eventId;
        if (success.noonPostCarbonTotalChlorine > 0) {
          this.f.SecondShiftTotalChlorineTextBox.setValue(success.noonPostCarbonTotalChlorine);
        }
        if (success.noonThorntonMeter > 0) {
          this.f.ThorntonMeterAfternoonTextBox.setValue(success.noonThorntonMeter);
        }
        this.FirstShiftUserName = success.morningContact;
        this.FirstShiftDate = success.morningDetailsEnteredOn;
        this.SecondShiftUserName = success.noonContact;
        this.morningDetailsEnteredBy = success.morningDetailsEnteredBy;
        this.noonDetailsEnteredBy = success.noonDetailsEnteredBy;
        if (this.SecondShiftUserName === '' || this.SecondShiftUserName === null) {
          this.SecondShiftUserName = this.auth.GetUserName();
        }
        this.SecondShiftDate = success.noonDetailsEnteredOn;
        this.createdOn = new Date(success.morningDetailsEnteredOn);
        this.Status = success.status;
        const wpDailyObj = { Id: this.f.NurseCheckDetailsId.value };
        this.wpapi
          .GetNotesBysaturdayWaterRecorddailyrecordID(wpDailyObj)
          .subscribe(list => {
            this.notes.NoteList = list;
          });

        this.wpapi
          .GetattachemnetsBysaturdayWaterRecorddailyrecordID(wpDailyObj)
          .subscribe(list => {
            //console.log(list);
            this.attachment.PrevAttachments = list;
          });
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  SetPageRights() {
    this.auth.GetPageRights('Water Process Saturday Record').subscribe(list => {
      if (this.f.NurseCheckDetailsId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        //this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        //this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.NurseCheckDetailsId.value != '0') {
      this.blnshowDelete = true;
    }
  }
  // Functions/Methods
  // -------------------------------------Start----------------------------------------------

  fillDialysisUnit() {
    this.commonapi.FillDivision()
      .subscribe(list => {
        if(Globals.Website == 'UHN')
        {
          this.DialysisUnits = list;
        }
        else
        {
          this.DialysisUnits = list.filter(
            x => x.text != "Home Program"
          );
        }
      });
  }

  executeNurseCheckCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.GreaterValueCheck();
    this.isValidFormSubmitted = false;
    if (this.dailyDetailForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      Object.keys(this.dailyDetailForm.controls).forEach(key => {
      });
      return;
    }
    this.isValidFormSubmitted = true;
    const NurseCheckCreate = this.prepareCreateViewModel();
    if (NurseCheckCreate.saturdaywaterrecordid === '0') {
      this.wpapi.CreateSaturdayWaterProcessRecord(NurseCheckCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.f.NurseCheckDetailsId.setValue(success.saturdaywaterrecordid);

            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.wpapi.UpdateSaturdayWaterProcessRecord(NurseCheckCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const NurseCheckCreate = <any>{};

    NurseCheckCreate.saturdaywaterrecordid = this.f.NurseCheckDetailsId.value;
    NurseCheckCreate.divisonid = this.f.selectedDialysisUnits.value;
    NurseCheckCreate.prerawfilter = this.f.RawWaterFiltersP.value;
    NurseCheckCreate.postrawfilter = this.f.RawWaterFiltersQ.value;
    NurseCheckCreate.morningpostcarbontotalchlorine = this.f.DPDTestPostTotalChlorine.value;
    NurseCheckCreate.morningthorntonmeter = this.f.ThorntonMeterMorning.value;
    NurseCheckCreate.noonpostcarbontotalchlorine = this.f.SecondShiftTotalChlorineTextBox.value;
    NurseCheckCreate.noonthorntonmeter = this.f.ThorntonMeterAfternoonTextBox.value;
    NurseCheckCreate.eventid = this.eventId;
    //console.log(this.f.NurseCheckDetailsId.value);
    if (this.f.NurseCheckDetailsId.value === '0') {
      NurseCheckCreate.currentUser = this.currentUserId;
      NurseCheckCreate.CreatedOn = Globals.GetAPIDate(this.today);
      NurseCheckCreate.WPChecksSectionType = 'AM';
    } else {
      NurseCheckCreate.modifiedBy = this.currentUserId;
      NurseCheckCreate.modifiedOn = Globals.GetAPIDate(this.today);

      NurseCheckCreate.morningdetailsenteredby = this.morningDetailsEnteredBy;
      NurseCheckCreate.morningdetailsenteredon = this.FirstShiftDate;
      if (
        NurseCheckCreate.noonpostcarbontotalchlorine !== '0' &&
        NurseCheckCreate.noonthorntonmeter !== '0'
      ) {
        NurseCheckCreate.noonDetailsEnteredBy = this.currentUserId;
        NurseCheckCreate.noonDetailsEnteredOn = Globals.GetAPIDate(this.today);
        NurseCheckCreate.WPChecksSectionType = 'PM';

      }
      if (this.Status === 'Completed') {
        NurseCheckCreate.WPChecksSectionType = 'Notes';
        NurseCheckCreate.noonDetailsEnteredBy = this.noonDetailsEnteredBy;
        NurseCheckCreate.noonDetailsEnteredOn = this.SecondShiftDate;
      }
    }
    NurseCheckCreate.notes = this.notes.Note;

    // attachments
    NurseCheckCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    // console.log(NurseCheckCreate);
    return NurseCheckCreate;
  }

  checkRange(control, MinValue, MaxValue) {
    if (control.value !== '' && control.value != null) {
      return !(control.value >= MinValue && control.value <= MaxValue);
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const NurseCheckObj = { Id: this.f.NurseCheckDetailsId.value };
        this.wpapi.DeleteSaturdayWaterProcessDailyRecord(NurseCheckObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Water process record deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  CheckSecondShiftCompleted() {
    return (
      this.Status !== 'Completed' &&
      new Date(this.createdOn).setHours(0, 0, 0, 0) ===
      new Date().setHours(0, 0, 0, 0)
    );
  }

  GreaterValueCheck() {
    this.f.RawWaterFiltersQ.updateValueAndValidity();
    this.f.ThorntonMeterAfternoonTextBox.updateValueAndValidity();
    this.f.SecondShiftTotalChlorineTextBox.updateValueAndValidity();
  }

  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }

  DialysisUnitDropDownChanged() {

  }
}

export class QuickAddPartModel {
   
    itemName : string;
    itemId : string;
    category : string;
    errormsg : string;
    comp_equipmentId : string;

    equipementid:string;
    equipmentcode : string;
    itemcost : string;
    equipmentDesc : string;
  }
<style>
    .pagination>li>a,
    .pagination>li>span {
        padding: 6px 7.5px;
    }
</style>

<section class="content-header">
    <h1>
        {{title}}
        <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->

    </h1>

</section>
<section class="content">

    <section>
        <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->



        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]="blnshowAddNew" [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)"
                    [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">First Name:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="GivenName">
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Last name:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="FamilyName">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">User Name:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="UserName">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Contact Type:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="ContactType">
                                                <option value="{{item.value}}" *ngFor="let item of ContactTypeList">
                                                    {{item.text}}</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <!-- <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Division:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="Division">
                                                    <option value="{{item.value}}" *ngFor="let item of DivisionList">
                                                        {{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Designation:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="Designation">
                                                <option value="{{item.value}}" *ngFor="let item of DesignationList">
                                                    {{item.text}}</option>
                                            </select>

                                        </div>

                                    </div>
                                    <div class="col-sm-6">

                                        <label class="col-sm-6 control-label">Status:</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" [(ngModel)]="Status">
                                                <option value="{{item.value}}" *ngFor="let item of StatusList">
                                                    {{item.text}}</option>
                                            </select>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                    <!-- <div class="col-sm-6">
                                      <div>
                                          <label class="col-sm-6 control-label">Contact ID:</label>
                                          <div class="col-sm-6">
                                              <input type="text" class="form-control" [(ngModel)]="ContactId">
                                          </div>

                                      </div>
                                  </div> -->
                                </div>
                            </div>
                        </div>


                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                </div>
                            </div>
                        </div>

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executeContactSearch()">
                                        <span><i class="fa fa-search"></i></span>
                                        <b>&nbsp;Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-7 pull-right">
                                <nav>
                                    <ul class="pagination" *ngIf="prefixAlphabet">
                                        <li>
                                            <a href="javascript:void(0)" (click)="clearAlphabet()">
                                                <i class="fa fa-refresh" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                    <ul class="pagination pull-right">
                                        <!-- <li class="disabled"><a href="#" aria-label="Previous"><span aria-hidden="true">�</span></a></li>  -->
                                        <li [ngClass]="{'active':alphabet==prefixAlphabet}"
                                            *ngFor="let alphabet of alphaBetsList">
                                            <a href="javascript:void(0)"
                                                (click)="alphabetChange(alphabet)">{{alphabet}}</a>
                                        </li>
                                        <!-- <li class="disabled"><a href="#">Y</a></li> -->
                                        <!-- <li><a href="#" aria-label="Next"><span aria-hidden="true">�</span></a></li>  -->
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-sm-2 pull-right noBorderSearchPadding">
                                <!-- <div class="col-sm-12"> -->
                                    <select class="form-control noBorderSearch" [(ngModel)]="Division"
                                        (change)="DialysisUnitDropDownChanged()" style="padding:0px;">
                                        <option value="{{item.value}}" *ngFor="let item of DivisionList">
                                            {{item.text}}
                                        </option>
                                    </select>
                                <!-- </div> -->
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeContactSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeContactSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid" style="
                    overflow-x: auto;
                    white-space: nowrap;">
                            <thead>



                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>



                                <tr *ngFor="let ContanctDetail of rows;let index=index;"
                                    (click)="_onRowClick(ContanctDetail.contactid)" class="pointer">

                                    <td>

                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td *ngIf='ContanctDetail.contacttype == "Vendor"'>
                                        {{ContanctDetail.givenname}} <span
                                            *ngIf='ContanctDetail.familyname.trim().length > 0'> -
                                            {{ContanctDetail.familyname}} </span>
                                    </td>
                                    <td *ngIf='ContanctDetail.contacttype != "Vendor"'>
                                        {{ContanctDetail.givenname}} {{ContanctDetail.familyname}}
                                    </td>

                                    <!-- <td >{{ContanctDetail.contactnumber}}</td> -->
                                    <td *ngIf='ContanctDetail.contacttype != "User"'>{{ContanctDetail.emailaddress}}</td>
                                    <td *ngIf='ContanctDetail.contacttype == "User"'>{{ContanctDetail.username}}</td>
                                    <td>{{ContanctDetail.phone}}</td> 
                                    <!-- <td *ngIf="ContanctDetail.phone == '' && ContanctDetail.cellphone != ''">C-{{ContanctDetail.cellphone}}</td>
                                    <td *ngIf="ContanctDetail.cellphone == ''  && ContanctDetail.tollfreefax != ''">T-{{ContanctDetail.tollfreefax}}</td>                                     -->
                                    <!-- <td *ngIf="ContactDetail.cellphone == '' && ContactDetail.tollfreefax != ''">T-{{ContactDetail.tollfreefax}}</td> -->
                                    <td>{{ContanctDetail.contacttype}}</td>
                                    <td>{{ContanctDetail.designation}}</td>

                                    <td>{{ContanctDetail.status}} </td>
                                </tr>
                            </tbody>


                        </table>


                    </div>
                    <!-- <div class="row">
                    <div class="col-lg-12 text-center">
                      <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                    </div>
                    </div> -->
                    <div class="row">
                        <!-- <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeContactSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm-12">
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer"
                                            (click)="executeContactSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-7 col-sm-offset-2">
                                <nav>
                                    <ul class="pagination" *ngIf="prefixAlphabet">
                                        <li>
                                            <a href="javascript:void(0)" (click)="clearAlphabet()" title="Reset">
                                                <i class="fa fa-refresh" aria-hidden="true"></i></a>
                                        </li>
                                    </ul>
                                    <ul class="pagination pull-right">
                                        <!-- <li class="disabled"><a href="#" aria-label="Previous"><span aria-hidden="true">�</span></a></li>  -->
                                        <li [ngClass]="{'active':alphabet==prefixAlphabet}"
                                            *ngFor="let alphabet of alphaBetsList">
                                            <a href="javascript:void(0)"
                                                (click)="alphabetChange(alphabet)">{{alphabet}}</a>
                                        </li>
                                        <!-- <li class="disabled"><a href="#">Y</a></li> -->
                                        <!-- <li><a href="#" aria-label="Next"><span aria-hidden="true">�</span></a></li>  -->
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { Component, OnInit, ViewChild, Output,EventEmitter } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HomeFieldService } from 'src/app/API/homefield.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/globals';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PurposeOther',
  templateUrl: './purpose-other.component.html',
  styleUrls: ['./purpose-other.component.css']
})

export class PurposeOtherComponent implements OnInit {
  currentUserId;
  IsPurposeCompleted='';
  errormsg='';
  formSubmitted=false;
  @Output() SaveEvent = new EventEmitter<any>();
  today = new Date();

  otherForm = new UntypedFormGroup({
    OtherId: new UntypedFormControl('0'),
    HFServiceID: new UntypedFormControl(''),
    DialysisUnitId: new UntypedFormControl(''),
    Issue: new UntypedFormControl('', Validators.required),
    CreatedBy: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    ModifiedBy: new UntypedFormControl(''),
    ModifiedOn: new UntypedFormControl('')
  });

  constructor(private homefieldApi: HomeFieldService, public auth : AuthService,
    public dialogRef: MatDialogRef<PurposeOtherComponent>) { }

  ngOnInit()
  {
     this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.of.Issue.setValidators([Validators.required]);
    this.of.Issue.updateValueAndValidity();
    }, error => {
    });

  }

  get of() {
    return this.otherForm.controls;
  }

  prepareCreateViewModel() {
    const modelCreate = <any>{};

    modelCreate.OtherId = this.of.OtherId.value;
    modelCreate.HFServiceID = this.of.HFServiceID.value;
    modelCreate.DialysisUnitId = this.of.DialysisUnitId.value;
    modelCreate.Issue = this.of.Issue.value;

    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.currentUserId;
    modelCreate.ModifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.ModifiedBy = this.currentUserId;
    return modelCreate;
  }

  save() {
    return new Promise((resolve, reject) => {

      this.formSubmitted =true;

      if(this.otherForm.invalid)
      {
        // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        // this.errormsg= Globals.FormErrorMessage;
        AppComponent.loading = false;

        AppComponent.ScrollDown();
        return;
      }
      const RModelCreate = this.prepareCreateViewModel();

       if (this.of.OtherId.value === '0')
       {
        this.homefieldApi.CreateHomeFieldService_Other(RModelCreate).subscribe(
          success => {
            this.of.OtherId.setValue(success.OtherId);
            resolve(true);
          },
          error => {
            reject();
          }
        );
      }
      else
      {
        this.homefieldApi.UpdateHomeFieldService_Other(RModelCreate).subscribe(
          success => {
            resolve(true);
          },
          error => {
            reject();
          }
        );

      }
    });
  }

  setModel() {

    this.homefieldApi.GetHomeFieldService_OtherbyServiceID({ Id: this.of.HFServiceID.value }).subscribe(
      success => {
        if (success && success[0]) {

          setTimeout(() => {
            this.of.OtherId.setValue(success[0].otherId);
            this.of.HFServiceID.setValue(success[0].hfServiceID);
            this.of.DialysisUnitId.setValue(success[0].dialysisUnitId);
            this.of.Issue.setValue(success[0].issue);
            this.of.CreatedBy.setValue(success[0].createdBy);
            this.of.CreatedOn.setValue(success[0].createdOn);
          });
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  formChange(enable = false) {
    if (enable)
      this.otherForm.enable();
    else
      this.otherForm.disable();
  }

  saveOtherNote()
  {
    this.save().then(() => {
      this.SaveEvent.emit();
    this.close();
    }, () => {
      AppComponent.addAlert("Error", 'error');
    });
  }

  close(){
    this.dialogRef.close(false);
  }
}

import { Component } from '@angular/core';
import { AppComponent } from "../app.component";
import { Globals } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import './external_api.js';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'JMeet',
  templateUrl: './JMeet.component.html',

})
export class JMeetComponent {
  title = 'app';
  domain: string = "meet.jit.si";
  options: any;
  api: any;
  yourRoomPass = "mrm@2020";
  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer) { }
  ngOnInit() {

  }

  ngAfterViewInit(): void {

    this.options = {
      roomName: "MrmRenal",
      parentNode: document.querySelector('#meet'),
      configOverwrite: { startWithAudioMuted: true, startWithVideoMuted: true }
    }

    this.api = new JitsiMeetExternalAPI(this.domain, this.options);

    this.api.addEventListener('videoConferenceJoined', (response) => {
      this.api.executeCommand('password', this.yourRoomPass);
    });
  }
}

<style>
    .nonePointerEvents {
      pointer-events: none;
    }

    .allPointerEvents {
      pointer-events: all;
    }
  </style>
  <form
    [formGroup]="GR3E7DailyDetailForm"
    (ngSubmit)="executeGRWPDailyCreateUpdate('', '')"
    #mainform
    novalidate
  >
    <section>
      <div class="row">
        <!-- /.box -->
        <div>

          <div class="col-md-12">
            <div class="col-sm-12 box-row hidden">
              <label class="col-sm-2 control-label"
                >GRWaterprocessDailyrecordsID</label
              >

              <div class="col-sm-10">
                <input
                  type="text"
                  readonly
                  class="form-control"
                  name="GRWaterprocessDailyrecordsID"
                  [value]="f.GRWaterprocessDailyrecordsID"
                  formControlName="GRWaterprocessDailyrecordsID"
                />
              </div>
            </div>
            <div class="col-sm-12 box-row hidden">
              <label class="col-sm-2 control-label"
                >GRWaterProcessailyRecordMain3EId</label
              >

              <div class="col-sm-10">
                <input
                  type="text"
                  readonly
                  class="form-control"
                  name="GRWaterProcessailyRecordMain3EId"
                  [value]="f.GRWaterProcessailyRecordMain3EId"
                  formControlName="GRWaterProcessailyRecordMain3EId"
                />
              </div>
            </div>
            <div>
              <div class="box ibox box-info">
                <div class="box-header with-border">
                  <h3 class="box-title">Daily</h3>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="headingTwoAM">
                    <h4 class="panel-title">
                      <a
                        [ngClass]="collapseTwoAMClass"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwoAM"
                        aria-expanded="collapseTwoAMariaexpanded"
                        aria-controls="collapseTwoAM"
                        id="amPanel"
                      >
                        <div class="box-header with-border">
                          <h3 class="box-title container col-sm-6">
                            AM
                            <span *ngIf="isAMComplted">
                              : {{ this.AMUserName }} -
                              {{ AMDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                              Completed</span
                            >
                          </h3>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwoAM"
                    [ngClass]="collapseTwoAMpanelClass"
                    role="tabpanel"
                    aria-labelledby="headingTwoAM"
                  >
                    <div class="panel-body">
                      <div class="box-body">
                        <div class="row">
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-3">
                              <span class="text-red inputrequired">*</span>
                              <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.AmANLSeqAlaram.value == 'NO' ? 'red' : 'black'
                                "
                                >{{ f.AmANLSeqAlaram.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <select
                                [style.color]="
                                  f.AmANLSeqAlaram.value == 'NO' ? 'red' : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="AmANLSeqAlaram"
                                name="AmANLSeqAlaram"
                                (change)="AmANLSeqAlaramDropDownChanged()"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                            </div>
                            <div
                              class="col-sm-1"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <span
                                *ngIf="
                                  f.AmANLSeqAlaram.errors?.required &&
                                  (f.AmANLSeqAlaram.dirty ||
                                    f.AmANLSeqAlaram.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-5"
                              *ngIf="
                                f.AmANLSeqAlaram.value == 'NO' && !isAMComplted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                              <select
                                style="color:red"
                                class="form-control mdInput"
                                formControlName="DailyAMFailureDisplay"
                                name="DailyAMFailureDisplay"
                              >
                                <option value="" style="color:red">Select</option>
                                <option value="Bank 1" style="color:red"
                                  >Bank 1</option
                                >
                                <option value="Bank 2" style="color:red"
                                  >Bank 2</option
                                >
                                <option value="Bank 3" style="color:red"
                                  >Bank 3</option
                                >
                                <option value="Bank 4" style="color:red"
                                  >Bank 4</option
                                >
                                <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                  >Bank 5</option
                                >
                              </select>
                              <span
                                *ngIf="
                                  f.DailyAMFailureDisplay.errors?.required &&
                                  (f.DailyAMFailureDisplay.dirty ||
                                    f.DailyAMFailureDisplay.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut) &&
                                f.AmANLSeqAlaram.value == 'NO' &&
                                isAMComplted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong
                              ><label class="readOnlyText" style="color:red">{{
                                f.DailyAMFailureDisplay.value
                              }}</label>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div
                            class="box-row col-sm-12"
                            *ngIf="f.AmANLSeqAlaram.value == 'NO'"
                          >
                            <div
                              formArrayName="AMSeqALM"
                              *ngFor="
                                let item of GR3E7DailyDetailForm.get('AMSeqALM')
                                  .controls;
                                let i = index
                              "
                              class="form-group"
                            >
                              <div [formGroupName]="i">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                  <label class="col-sm-8 control-label">
                                    Verified Total Chlorine for Bank 0{{
                                      i + 1
                                    }}:</label
                                  >
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                        formLockedOut) &&
                                      isAMComplted
                                    "
                                  >
                                    <label
                                      class="readOnlyText"
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramAM
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramAM
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      >{{
                                        item.controls.VerifiedBankAlaramAM.value
                                      }}</label
                                    >
                                  </div>
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value ==
                                        0 &&
                                        !formLockedOut) ||
                                      !isAMComplted
                                    "
                                  >
                                    <select
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramAM
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramAM
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      class="form-control mdInput"
                                      formControlName="VerifiedBankAlaramAM"
                                      name="VerifiedBankAlaramAM"
                                    >
                                      <option value="Not Tested" style="color:red"
                                        >Not Tested</option
                                      >
                                      <option value="Pass" style="color:black"
                                        >Pass</option
                                      >
                                      <option value="Fail" style="color:red"
                                        >Fail</option
                                      >
                                    </select>
                                    <!-- <span *ngIf="item.controls.VerifiedBankAlaramAM.errors?.required && (item.controls.VerifiedBankAlaramAM.dirty || item.controls.VerifiedBankAlaramAM.touched ||  isValidFormSubmitted==false)"
                                                              [ngClass]="'error'">
                                                              Required!
                                                          </span> -->
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="clearfix" *ngIf="f.AmANLSeqAlaram.value  == 'NO' && !isAMComplted"></div>
                                          <div class="box-row col-sm-12" *ngIf="f.AmANLSeqAlaram.value  == 'NO' && !isAMComplted">
                                                  <div class="col-sm-3">
                                                  </div>
                                                  <div class="col-sm-3">
                                                      </div>
                                                      <div class="col-sm-2">
                                                          </div>
                                                  <div class="col-sm-3" >

                                                          <button type="button" class="btn btn-primary" (click)="addAMAlaramClick()" *ngIf="GR3E7DailyDetailForm.get('AMSeqALM').controls.length <= 4 && !isAMComplted">
                                                                  + ADD Additional Bank Alarm
                                                              </button>

                                                      </div>
                                                      <div class="col-sm-6"></div>
                                          </div> -->
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-3">
                              <span class="text-red inputrequired">*</span>
							  <strong>Verify RO Presently In Use: </strong>
                            </div>
                            <div
                              class="col-sm-5"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label class="readOnlyText" style="color:black">{{
                                VerifyingCWWPROText
                              }}</label>
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <select  *ngIf="isMain3EForm"
                                class="form-control mdInput"
                                formControlName="selectedVerifyROPresently"
                                name="selectedVerifyROPresently"
                                (change)="VerifyROPresentlyDropDownChanged()"
                              >
                                <option
                                  value="{{ item.value }}"
                                  *ngFor="let item of VerifyROPresentMAIN3E"
                                  >{{ item.text }}</option
                                >
                              </select>

                              <select *ngIf="isMain7Form"
                                class="form-control mdInput"
                                formControlName="selectedVerifyROPresently"
                                name="selectedVerifyROPresently"
                                (change)="VerifyROPresentlyDropDownChanged()"
                              >
                                <option
                                value="{{ item.value }}"
                                *ngFor="let item of VerifyROPresentMAIN7"
                                >{{ item.text }}</option
                              >
                              </select>
                            </div>
                            <div
                              class="col-sm-3"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <span
                                *ngIf="
                                  f.selectedVerifyROPresently.errors?.required &&
                                  (f.selectedVerifyROPresently.dirty ||
                                    f.selectedVerifyROPresently.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-3">
                              <span class="text-red inputrequired">*</span
                              ><strong>Verify </strong> &nbsp;
                              <label
                                class="readOnlyText"
                                style="color:black"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                                >{{ VerifyROlable }}</label
                              >
                            </div>
                            <div
                              class="col-sm-3"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value > 0 ||
                                formLockedOut
                              "
                            >
                              <label class="readOnlyText" style="color:black"
                                >{{ VerifyROlable }} :
                              </label>
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.VerifyROReverse.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                              >
                                {{ f.VerifyROReverse.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <select
                                [style.color]="
                                  f.VerifyROReverse.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="VerifyROReverse"
                                name="VerifyROReverse"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                            </div>
                            <div
                              class="col-sm-3"
                              *ngIf="
                                f.GRWaterprocessDailyrecordsID.value == 0 &&
                                !formLockedOut
                              "
                            >
                              <span
                                *ngIf="
                                  f.VerifyROReverse.errors?.required &&
                                  (f.VerifyROReverse.dirty ||
                                    f.VerifyROReverse.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12  divider">
                            <div class="col-sm-4">
                              <strong>CWP RO Verification: </strong>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Verifying CWP RO:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label class="readOnlyText">{{
                                  VerifyingCWWPROText
                                }}</label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <!-- <input
                                                          maxlength="10" class="form-control smInput" formControlName="VerifyingCWPRO"
                                                          > -->
                                <label class="readOnlyText">{{
                                  VerifyingCWWPROText
                                }}</label>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span
                                >Disinfect Jug > 1/3rd mark:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.DisinfectJug.value == 'NO' ? 'red' : 'black'
                                  "
                                  >{{ f.DisinfectJug.value }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <select
                                  [style.color]="
                                    f.DisinfectJug.value == 'NO' ? 'red' : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="DisinfectJug"
                                  name="DisinfectJug"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Yes" style="color:black"
                                    >Yes</option
                                  >
                                  <option value="NO" style="color:red">NO</option>
                                </select>

                                <span
                                  *ngIf="
                                    f.DisinfectJug.errors?.required &&
                                    (f.DisinfectJug.dirty ||
                                      f.DisinfectJug.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span>FEED
                                Cond.(uS/cm):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.FeedConductivity,
                                      800,
                                      2000
                                    )
                                  }"
                                  >{{
                                    f.FeedConductivity.value | number: "1.1-1"
                                  }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  maxlength="6"
                                  class="form-control smInput"
                                  (keypress)="numberOnly($event)"
                                  (blur)="ReplaceLeadingZero('FeedConductivity')"
                                  formControlName="FeedConductivity"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.FeedConductivity,
                                      800,
                                      2000
                                    )
                                  }"
                                  name="FeedConductivity"
                                />

                                <span
                                  *ngIf="
                                    f.FeedConductivity.errors?.required &&
                                    (f.FeedConductivity.dirty ||
                                      f.FeedConductivity.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.FeedConductivity.errors?.pattern"
                                  [ngClass]="'error'"
                                  >####.#</span
                                >
                                <span class="error">{{
                                  errormsgFeedConductivity
                                }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span
                                >PRODUCT Cond.(uS/cm):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground:
                                      f.ProductConductivity.value > 50
                                  }"
                                  >{{
                                    f.ProductConductivity.value | number: "1.1-1"
                                  }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  (keypress)="numberOnly($event)"
                                  [ngClass]="{
                                    OurOffRange: f.ProductConductivity.value > 50
                                  }"
                                  max-length="4"
                                  class="form-control smInput"
                                  (blur)="
                                    ReplaceLeadingZero('ProductConductivity')
                                  "
                                  formControlName="ProductConductivity"
                                  name="ProductConductivity"
                                />

                                <span
                                  *ngIf="
                                    f.ProductConductivity.errors?.required &&
                                    (f.ProductConductivity.dirty ||
                                      f.ProductConductivity.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.ProductConductivity.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##.#</span
                                >
                                <span class="error">{{
                                  errormsgProductConductivity
                                }}</span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span
                                >Recovery Rate (%):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.RecoveryRate,
                                      45,
                                      75
                                    )
                                  }"
                                  >{{ f.RecoveryRate.value | number: "1.1-1" }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  (blur)="ReplaceLeadingZero('RecoveryRate', 2)"
                                  (keypress)="numberOnly($event)"
                                  maxlength="4"
                                  class="form-control smInput"
                                  formControlName="RecoveryRate"
                                  name="RecoveryRate"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.RecoveryRate,
                                      45,
                                      75
                                    )
                                  }"
                                />

                                <span
                                  *ngIf="
                                    f.RecoveryRate.errors?.required &&
                                    (f.RecoveryRate.dirty ||
                                      f.RecoveryRate.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.RecoveryRate.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##.#</span
                                >
                                <span class="error">{{
                                  errormsgRecoveryRate
                                }}</span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span
                                >Rejection Rate (%):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.RejectionRate,
                                      95,
                                      99
                                    )
                                  }"
                                  >{{ f.RejectionRate.value | number: "1.1-1" }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  (keypress)="numberOnly($event)"
                                  maxlength="4"
                                  class="form-control smInput"
                                  formControlName="RejectionRate"
                                  (blur)="ReplaceLeadingZero('RejectionRate', 2)"
                                  name="RejectionRate"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.RejectionRate,
                                      95,
                                      99
                                    )
                                  }"
                                />
                                <span
                                  *ngIf="
                                    f.RejectionRate.errors?.required &&
                                    (f.RejectionRate.dirty ||
                                      f.RejectionRate.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.RejectionRate.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##.#</span
                                >
                                <span class="error">{{
                                  errormsgRejectionRate
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <span class="text-red inputrequired">*</span>Temp
                                Return (°C):
                              </label>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground:
                                      f.TempReturn.value > 25
                                  }"
                                  >{{ f.TempReturn.value | number: "1.1-1" }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  (blur)="ReplaceLeadingZero('TempReturn')"
                                  (keypress)="numberOnly($event)"
                                  [ngClass]="{
                                    OurOffRange: f.TempReturn.value > 25
                                  }"
                                  maxlength="4"
                                  class="form-control smInput"
                                  formControlName="TempReturn"
                                  name="TempReturn"
                                />
                                <span
                                  *ngIf="
                                    f.TempReturn.errors?.required &&
                                    (f.TempReturn.dirty ||
                                      f.TempReturn.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.TempReturn.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##.#</span
                                >
                                <span class="error">{{
                                  errormsgTempReturn
                                }}</span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                <!-- <span class="text-red inputrequired">*</span> -->
                                Temp Tank (°C):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut
                                "
                              >
                                <label class="readOnlyText"
                                  >{{ f.TempTank.value | number: "1.1-1" }}
                                </label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="
                                  f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut
                                "
                              >
                                <input
                                  (blur)="ReplaceLeadingZero('TempTank')"
                                  (keypress)="numberOnly($event)"
                                  maxlength="4"
                                  class="form-control smInput"
                                  formControlName="TempTank"
                                  name="TempTank"
                                />

                                <span
                                  *ngIf="
                                    f.TempTank.errors?.required &&
                                    (f.TempTank.dirty ||
                                      f.TempTank.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.TempTank.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##.#</span
                                >
                                <span class="error">{{ errormsgTempTank }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12">
                            <div class="col-sm-2">
                              <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{AMUserName}} </label></label> -->
                            </div>
                            <div class="col-sm-3">
                              <!-- <label class="control-label"></label> -->
                            </div>
                            <div class="col-sm-5">
                              <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                  &nbsp;&nbsp;
                                                  <label class="control-label">{{AMUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                              AMDetailsEnteredBy == null || !isAMComplted
                              "
                            >
                              <PageHeader
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="executeGRWPDailyCreateUpdate('', 'AM')"
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div
                  class="panel panel-default"
                  [ngClass]="{ nonePointerEvents: !isAMComplted || (Status=='Incompleted' && MidDetailsEnteredBy==null) }"
                >
                  <div class="panel-heading" role="tab" id="headingTwoMid">
                    <h4 class="panel-title">
                      <a
                        [ngClass]="collapseTwoMidClass"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwoMid"
                        aria-expanded="collapseTwoMidariaexpanded"
                        aria-controls="collapseTwoMid"
                        id="midPanel"
                      >
                        <div class="box-header with-border">
                          <h3 class="box-title container col-sm-6">
                            MID
                            <span *ngIf="isMIDComplted">
                              : {{ this.MIDUserName }} -
                              {{ MidDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                              Completed</span
                            >
                          </h3>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwoMid"
                    [ngClass]="collapseTwoMidpanelClass"
                    role="tabpanel"
                    aria-labelledby="headingTwoMid"
                  >
                    <div class="panel-body">
                      <div class="box-body">
                        <div class="row">
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-3">
                              <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut) &&
                                f.AmANLSeqAlaramMID.value != '' &&
                                isMIDComplted
                              "
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.AmANLSeqAlaramMID.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.AmANLSeqAlaramMID.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut) ||
                                f.AmANLSeqAlaramMID.value == '' ||
                                !isMIDComplted
                              "
                            >
                              <select
                                [style.color]="
                                  f.AmANLSeqAlaramMID.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="AmANLSeqAlaramMID"
                                name="AmANLSeqAlaramMID"
                                (change)="AmANLSeqAlaramMIDDropDownChanged()"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.AmANLSeqAlaramMID.errors?.required &&
                                  (f.AmANLSeqAlaramMID.dirty ||
                                    f.AmANLSeqAlaramMID.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-5"
                              *ngIf="
                                f.AmANLSeqAlaramMID.value == 'NO' &&
                                !isMIDComplted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                              <select
                                style="color:red"
                                class="form-control mdInput"
                                formControlName="DailyMidFailureDisplay"
                                name="DailyMidFailureDisplay"
                              >
                                <option value="" style="color:red">Select</option>
                                <option value="Bank 1" style="color:red"
                                  >Bank 1</option
                                >
                                <option value="Bank 2" style="color:red"
                                  >Bank 2</option
                                >
                                <option value="Bank 3" style="color:red"
                                  >Bank 3</option
                                >
                                <option value="Bank 4" style="color:red"
                                  >Bank 4</option
                                >
                                <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                  >Bank 5</option
                                >
                              </select>
                              <span
                                *ngIf="
                                  f.DailyMidFailureDisplay.errors?.required &&
                                  (f.DailyMidFailureDisplay.dirty ||
                                    f.DailyMidFailureDisplay.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut) &&
                                f.AmANLSeqAlaramMID.value == 'NO' &&
                                isMIDComplted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong
                              ><label class="readOnlyText" style="color:red">{{
                                f.DailyMidFailureDisplay.value
                              }}</label>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div
                            class="box-row col-sm-12"
                            *ngIf="f.AmANLSeqAlaramMID.value == 'NO'"
                          >
                            <div
                              formArrayName="MidSeqALM"
                              *ngFor="
                                let item of GR3E7DailyDetailForm.get('MidSeqALM')
                                  .controls;
                                let i = index
                              "
                              class="form-group"
                            >
                              <div [formGroupName]="i">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                  <label class="col-sm-8 control-label">
                                    Verified Total Chlorine for Bank 0{{
                                      i + 1
                                    }}:</label
                                  >
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                        formLockedOut) &&
                                      isMIDComplted
                                    "
                                  >
                                    <label
                                      class="readOnlyText"
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramMID
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramMID
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      >{{
                                        item.controls.VerifiedBankAlaramMID.value
                                      }}</label
                                    >
                                  </div>
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value ==
                                        0 &&
                                        !formLockedOut) ||
                                      !isMIDComplted
                                    "
                                  >
                                    <select
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramMID
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramMID
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      class="form-control mdInput"
                                      formControlName="VerifiedBankAlaramMID"
                                      name="VerifiedBankAlaramMID"
                                    >
                                      <option
                                        value="Not Tested"
                                        style="color:black"
                                        >Not Tested</option
                                      >
                                      <option value="Pass" style="color:black"
                                        >Pass</option
                                      >
                                      <option value="Fail" style="color:red"
                                        >Fail</option
                                      >
                                    </select>
                                    <!-- <span *ngIf="item.controls.VerifiedBankAlaramMID.errors?.required && (item.controls.VerifiedBankAlaramMID.dirty || item.controls.VerifiedBankAlaramMID.touched ||  isValidFormSubmitted==false)"
                                                                      [ngClass]="'error'">
                                                                      Required!
                                                                  </span> -->
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="clearfix" *ngIf="f.AmANLSeqAlaramMID.value  == 'NO' && !isMIDComplted"></div>
                                          <div class="box-row col-sm-12" *ngIf="f.AmANLSeqAlaramMID.value  == 'NO' && !isMIDComplted">
                                                  <div class="col-sm-3">
                                                  </div>
                                                  <div class="col-sm-3">
                                                      </div>
                                                      <div class="col-sm-2">
                                                          </div>
                                                  <div class="col-sm-3" >

                                                          <button type="button" class="btn btn-primary" (click)="addMidAlaramClick()" *ngIf="GR3E7DailyDetailForm.get('MidSeqALM').controls.length <= 4 && !isMIDComplted">
                                                                  + ADD Additional Bank Alarm
                                                              </button>

                                                      </div>
                                                      <div class="col-sm-6"></div>
                                          </div> -->
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-2">
                              <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{MIDUserName}} </label></label> -->
                            </div>
                            <div class="col-sm-3">
                              <label class="control-label"></label>
                            </div>
                            <div class="col-sm-5">
                              <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                      &nbsp;&nbsp;
                                                      <label class="control-label">{{MIDUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                                f.AmANLSeqAlaramMID.value == '' || !isMIDComplted
                              "
                            >
                              <PageHeader
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="
                                  executeGRWPDailyCreateUpdate('', 'Mid')
                                "
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>

                <div
                  class="panel panel-default"
                  [ngClass]="{ nonePointerEvents: !isMIDComplted   || (Status=='Incompleted' && PMDetailsEnteredBy==null) }"
                >
                  <div class="panel-heading" role="tab" id="headingTwoPM">
                    <h4 class="panel-title">
                      <a
                        [ngClass]="collapseTwoPMClass"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwoPM"
                        aria-expanded="collapseTwoPMariaexpanded"
                        aria-controls="collapseTwoPM"
                        id="pmPanel"
                      >
                        <div class="box-header with-border">
                          <h3 class="box-title container col-sm-6">
                            PM
                            <span *ngIf="isPMCompleted">
                              : {{ this.PMUserName }} -
                              {{ PMDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} -
                              Completed</span
                            >
                          </h3>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwoPM"
                    [ngClass]="collapseTwoPMpanelClass"
                    role="tabpanel"
                    aria-labelledby="headingTwoPM"
                  >
                    <div class="panel-body">
                      <div class="box-body">
                        <div class="row">
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-3">
                              <strong>Chl Anl Seq Alarm is 0 (<0.1 ppm): </strong>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut) &&
                                f.AmANLSeqAlaramPM.value != '' &&
                                isPMCompleted
                              "
                            >
                              <label
                                class="readOnlyText"
                                [style.color]="
                                  f.AmANLSeqAlaramPM.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                >{{ f.AmANLSeqAlaramPM.value }}</label
                              >
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                  !formLockedOut) ||
                                f.AmANLSeqAlaramPM.value == '' ||
                                !isPMCompleted
                              "
                            >
                              <select
                                [style.color]="
                                  f.AmANLSeqAlaramPM.value == 'NO'
                                    ? 'red'
                                    : 'black'
                                "
                                class="form-control mdInput"
                                formControlName="AmANLSeqAlaramPM"
                                name="AmANLSeqAlaramPM"
                                (change)="AmANLSeqAlaramPMDropDownChanged()"
                              >
                                <option value="" style="color:black"
                                  >Select</option
                                >
                                <option value="Yes" style="color:black"
                                  >Yes</option
                                >
                                <option value="NO" style="color:red">NO</option>
                              </select>
                              <span
                                *ngIf="
                                  f.AmANLSeqAlaramPM.errors?.required &&
                                  (f.AmANLSeqAlaramPM.dirty ||
                                    f.AmANLSeqAlaramPM.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-5"
                              *ngIf="
                                f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                              <select
                                style="color:red"
                                class="form-control mdInput"
                                formControlName="DailyPMFailureDisplay"
                                name="DailyPMFailureDisplay"
                              >
                                <option value="" style="color:red">Select</option>
                                <option value="Bank 1" style="color:red"
                                  >Bank 1</option
                                >
                                <option value="Bank 2" style="color:red"
                                  >Bank 2</option
                                >
                                <option value="Bank 3" style="color:red"
                                  >Bank 3</option
                                >
                                <option value="Bank 4" style="color:red"
                                  >Bank 4</option
                                >
                                <option *ngIf="isMain3EForm" value="Bank 5" style="color:red"
                                  >Bank 5</option
                                >
                              </select>
                              <span
                                *ngIf="
                                  f.DailyPMFailureDisplay.errors?.required &&
                                  (f.DailyPMFailureDisplay.dirty ||
                                    f.DailyPMFailureDisplay.touched ||
                                    isValidFormSubmitted == false)
                                "
                                [ngClass]="'error'"
                              >
                                Required!
                              </span>
                            </div>
                            <div
                              class="col-sm-4"
                              *ngIf="
                                (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                  formLockedOut) &&
                                f.AmANLSeqAlaramPM.value == 'NO' &&
                                isPMCompleted
                              "
                            >
                              <strong>Failure Displayed : &nbsp;&nbsp; </strong>
                              <label class="readOnlyText" style="color:red">{{
                                f.DailyPMFailureDisplay.value
                              }}</label>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div
                            class="box-row col-sm-12"
                            *ngIf="f.AmANLSeqAlaramPM.value == 'NO'"
                          >
                            <div
                              formArrayName="PMSeqALM"
                              *ngFor="
                                let item of GR3E7DailyDetailForm.get('PMSeqALM')
                                  .controls;
                                let i = index
                              "
                              class="form-group"
                            >
                              <div [formGroupName]="i">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                  <label class="col-sm-8 control-label">
                                    Verified Total Chlorine for Bank 0{{
                                      i + 1
                                    }}:</label
                                  >
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value > 0 ||
                                        formLockedOut) &&
                                      isPMCompleted
                                    "
                                  >
                                    <label
                                      class="readOnlyText"
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramPM
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramPM
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      >{{
                                        item.controls.VerifiedBankAlaramPM.value
                                      }}</label
                                    >
                                  </div>
                                  <div
                                    class="col-sm-4"
                                    *ngIf="
                                      (f.GRWaterprocessDailyrecordsID.value ==
                                        0 &&
                                        !formLockedOut) ||
                                      !isPMCompleted
                                    "
                                  >
                                    <select
                                      [style.color]="
                                        item.controls.VerifiedBankAlaramPM
                                          .value == 'Fail' ||
                                        item.controls.VerifiedBankAlaramPM
                                          .value == 'Not Tested'
                                          ? 'red'
                                          : 'black'
                                      "
                                      class="form-control mdInput"
                                      formControlName="VerifiedBankAlaramPM"
                                      name="VerifiedBankAlaramPM"
                                    >
                                      <option
                                        value="Not Tested"
                                        style="color:black"
                                        >Not Tested</option
                                      >
                                      <option value="Pass" style="color:black"
                                        >Pass</option
                                      >
                                      <option value="Fail" style="color:red"
                                        >Fail</option
                                      >
                                    </select>
                                    <!-- <span *ngIf="item.controls.VerifiedBankAlaramPM.errors?.required && (item.controls.VerifiedBankAlaramPM.dirty || item.controls.VerifiedBankAlaramPM.touched ||  isValidFormSubmitted==false)"
                                                                                          [ngClass]="'error'">
                                                                                          Required!
                                                                                      </span> -->
                                  </div>
                                </div>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                          </div>
                          <!-- <div
                            class="clearfix"
                            *ngIf="
                              f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                            "
                          ></div>
                          <div
                            class="box-row col-sm-12"
                            *ngIf="
                              f.AmANLSeqAlaramPM.value == 'NO' && !isPMCompleted
                            "
                          >
                            <div class="col-sm-3"></div>
                            <div class="col-sm-3"></div>
                            <div class="col-sm-2"></div>
                            <div class="col-sm-3">
                              <button
                                type="button"
                                class="btn btn-primary"
                                (click)="addPMAlaramClick()"
                                *ngIf="
                                  GR3E7DailyDetailForm.get('PMSeqALM').controls.length <= 4 && !isPMCompleted
                                "
                              >
                                + ADD Additional Bank Alarm
                              </button>
                            </div>
                            <div class="col-sm-6"></div>
                          </div>
                          <div class="clearfix"></div> -->
                          <div class="box-row col-sm-12">
                            <div class="col-sm-2">
                              <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{PMUserName}} </label></label> -->
                            </div>
                            <div class="col-sm-3">
                              <label class="control-label"></label>
                            </div>
                            <div class="col-sm-5">
                              <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                                          &nbsp;&nbsp;
                                                          <label class="control-label">{{PMUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                            </div>
                            <div
                              class="col-sm-2"
                              *ngIf="
                                f.AmANLSeqAlaramPM.value == '' || !isPMCompleted
                              "
                            >
                              <PageHeader
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="
                                  executeGRWPDailyCreateUpdate('', 'PM')
                                "
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="panel panel-default"
                  [ngClass]="{
                    nonePointerEvents: (!isAMComplted && !isWeeklyCompleted) || !IsWeeklyDisplayed}"
                > -->

                <div
                  class="panel panel-default"
                  [ngClass]="{
                    nonePointerEvents: (!isAMComplted && !isWeeklyCompleted) || !IsWeeklyDisplayed}"
                >
                  <div class="panel-heading" role="tab" id="headingTwoWeekly">
                    <h4 class="panel-title">
                      <a
                        [ngClass]="collapseTwoWeeklyClass"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseTwoWeekly"
                        aria-expanded="collapseTwoWeeklyariaexpanded"
                        aria-controls="collapseTwoWeekly"
                        (click)="weeklyPanelChange(false)"
                        id="weeklyPanel"
                      >
                        <div class="box-header with-border">
                          <div class="col-sm-12">
                          <h3 class="box-title col-sm-6">
                            RO Weekly Testing
                            <!-- <span *ngIf="formLockedOut"> - Lock Out.</span> -->
                            <span *ngIf="isWeeklyCompleted && IsWeeklyDisplayed">
                              : {{ this.WeeklyUserName }} -
                              {{ WeeklyDetailsEnteredOn | date: "dd/MM/yyyy HH:mm" }} - Completed</span>
                          </h3>
                          <h3 class="box-title  col-sm-6">
                            RO Disinfection
                            <span *ngIf="isWeeklyCompleted && IsWeeklyDisplayed && f.DisinfectSecondaryRO.value == 'Yes' ">
                              : {{ f.ChemicalDisinfectionUserSec.value }} -
                              {{ f.DisinfectionDateSec.value.jsdate
                                | date: "dd/MM/yyyy HH:mm" }} -

                              </span>

                            <span *ngIf="isWeeklyCompleted && IsWeeklyDisplayed && f.DisinfectSecondaryRO.value == 'NO' ">
                              : {{ f.ChemicalDisinfectionUser.value }} -
                              {{ f.DisinfectionDate.value.jsdate
                                | date: "dd/MM/yyyy HH:mm" }} -

                              </span>

                              <span *ngIf="isWeeklyCompleted && IsWeeklyDisplayed &&  (f.DisinfectionDate.value ||  f.DisinfectionDateSec.value)">{{DisinfectionStatus}}</span>
                          </h3>
                        </div>
                        </div>
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwoWeekly"
                    [ngClass]="collapseTwoWeeklypanelClass"
                    role="tabpanel"
                    aria-labelledby="headingTwoWeekly"
                  >
                    <div class="panel-body">
                      <div class="box-body">
                        <div class="row">
                          <div class="box-row col-sm-12 divider">
                            <div class="col-sm-2">
                              <strong>Softener Verification: </strong>
                            </div>
                            <div class="col-sm-8" style="color:black">
                              (Grains Per Gallon = 5 ml sample, 3 drops of H1, 1
                              drop of H2, grains # = H3 drops)
                            </div>
                            <div class="col-sm-2"></div>
                          </div>

                          <div class="box-row col-sm-12">
                            <div class="col-sm-6">
                              <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                Hardness Pre-Soft - S1 (gpg):
                              </label>
                              <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                Hardness Pre-Soft - TBD (gpg):
                              </label>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.HardnessPreSoft.value == '>8'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.HardnessPreSoft.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.HardnessPreSoft.value == '>8'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="HardnessPreSoft"
                                  name="HardnessPreSoft"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="<1" style="color:black"
                                    ><1</option
                                  >
                                  <option value="1" style="color:black">1</option>
                                  <option value="2" style="color:black">2</option>
                                  <option value="3" style="color:black">3</option>
                                  <option value="4" style="color:black">4</option>
                                  <option value="5" style="color:black">5</option>
                                  <option value="6" style="color:black">6</option>
                                  <option value="7" style="color:black">7</option>
                                  <option value=">8" style="color:red">>8</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.HardnessPreSoft.errors?.required &&
                                    (f.HardnessPreSoft.dirty ||
                                      f.HardnessPreSoft.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                  Hardness Pre RO - S14 (gpg):</label
                                >
                                <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                  Hardness Pre RO - S11 (gpg):</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus == 'Completed'"
                                >
                                  <label
                                    class="readOnlyText"
                                    [style.color]="
                                      f.HardnessPreROS14.value == '>3'
                                        ? 'red'
                                        : 'black'
                                    "
                                    >{{ f.HardnessPreROS14.value }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus != 'Completed'"
                                >
                                  <select
                                    [style.color]="
                                      f.HardnessPreROS14.value == '>3'
                                        ? 'red'
                                        : 'black'
                                    "
                                    class="form-control mdInput"
                                    formControlName="HardnessPreROS14"
                                    name="HardnessPreROS14"
                                  >
                                    <option value="" style="color:black"
                                      >Select</option
                                    >
                                    <option value="<1" style="color:black"
                                      ><1</option
                                    >
                                    <option value="1" style="color:black">1</option>
                                    <option value="2" style="color:black">2</option>
                                    <option value=">3" style="color:red">>3</option>
                                  </select>
                                  <span
                                    *ngIf="
                                      f.HardnessPreROS14.errors?.required &&
                                      (f.HardnessPreROS14.dirty ||
                                        f.HardnessPreROS14.touched ||
                                        isValidFormSubmitted == false)
                                    "
                                    [ngClass]="'error'"
                                  >
                                    Required!
                                  </span>
                                </div>
                              </div>
                          </div>

                          <!-- <div class="box-row col-sm-12"></div> -->

                          <div class="box-row col-sm-12" *ngIf="isMain3EForm">
                              <div class="col-sm-6">
                                  <label class="col-sm-8 control-label">
                                    Hardness Post Softener - S2 (gpg):
                                  </label>
                                  <div
                                    class="col-sm-4"
                                    *ngIf="isWeeklyCompleted || formLockedOut"
                                  >
                                    <label
                                      class="readOnlyText"
                                      [style.color]="
                                        f.HardnessPostSoftenerS2.value == '>3'
                                          ? 'red'
                                          : 'black'
                                      "
                                      >{{ f.HardnessPostSoftenerS2.value }}</label
                                    >
                                  </div>
                                  <div
                                    class="col-sm-4"
                                    *ngIf="!isWeeklyCompleted && !formLockedOut"
                                  >
                                    <select
                                      [style.color]="
                                        f.HardnessPostSoftenerS2.value == '>3'
                                          ? 'red'
                                          : 'black'
                                      "
                                      class="form-control mdInput"
                                      formControlName="HardnessPostSoftenerS2"
                                      name="HardnessPostSoftenerS2"
                                    >
                                      <option value="" style="color:black"
                                        >Select</option
                                      >
                                      <option value="<1" style="color:black"
                                        ><1</option
                                      >
                                      <option value="1" style="color:black">1</option>
                                      <option value="2" style="color:black">2</option>
                                      <option value=">3" style="color:red">>3</option>
                                    </select>
                                    <span
                                      *ngIf="
                                        f.HardnessPostSoftenerS2.errors?.required &&
                                        (f.HardnessPostSoftenerS2.dirty ||
                                          f.HardnessPostSoftenerS2.touched ||
                                          isValidFormSubmitted == false)
                                      "
                                      [ngClass]="'error'"
                                    >
                                      Required!
                                    </span>
                                  </div>
                                </div>
                            <div class="col-sm-6">
                              <label class="col-sm-8 control-label">
                                Hardness Post Softener - S3 (gpg):</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="isWeeklyCompleted || formLockedOut"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.HardnessPostSoftenerS3.value == '>3'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.HardnessPostSoftenerS3.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                                <select
                                  [style.color]="
                                    f.HardnessPostSoftenerS3.value == '>3'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="HardnessPostSoftenerS3"
                                  name="HardnessPostSoftenerS3"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="<1" style="color:black"
                                    ><1</option
                                  >
                                  <option value="1" style="color:black">1</option>
                                  <option value="2" style="color:black">2</option>
                                  <option value=">3" style="color:red">>3</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.HardnessPostSoftenerS3.errors?.required &&
                                    (f.HardnessPostSoftenerS3.dirty ||
                                      f.HardnessPostSoftenerS3.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>

                          </div>

                          <!-- <div class="box-row col-sm-12"></div> -->

                          <div class="box-row col-sm-12">
                            <div class="col-sm-6">
                              <label class="col-sm-8 control-label">
                                Salt Level Verification:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.SaltLevelVerification.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.SaltLevelVerification.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.SaltLevelVerification.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="SaltLevelVerification"
                                  name="SaltLevelVerification"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Yes" style="color:black"
                                    >Yes</option
                                  >
                                  <option value="NO" style="color:red">NO</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.SaltLevelVerification.errors?.required &&
                                    (f.SaltLevelVerification.dirty ||
                                      f.SaltLevelVerification.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-6" *ngIf="isMain7Form" >
                                <label class="col-sm-8 control-label">
                                    Softener Tank Position:</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus == 'Completed'"
                                >
                                  <label
                                    class="readOnlyText"
                                    >{{ f.SoftenerTankPosition.value }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus != 'Completed'"
                                >
                                  <select
                                    class="form-control mdInput"
                                    formControlName="SoftenerTankPosition"
                                    name="SoftenerTankPosition"
                                  >
                                    <option value="" style="color:black"
                                      >Select</option
                                    >
                                    <option value="1" style="color:black"
                                      >1</option
                                    >
                                    <option value="2" style="color:black"
                                      >2</option
                                    >
                                  </select>
                                  <span
                                    *ngIf="
                                      f.SoftenerTankPosition.errors?.required &&
                                      (f.SoftenerTankPosition.dirty ||
                                        f.SoftenerTankPosition.touched ||
                                        isValidFormSubmitted == false)
                                    "
                                    [ngClass]="'error'"
                                  >
                                    Required!
                                  </span>
                                </div>
                              </div>
                          </div>

                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12  divider">
                            <div class="col-sm-4">
                              <strong>Pre-Treatment Verification: </strong>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12" *ngIf="isMain3EForm">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Booster Pump - P4 (psi):
                              </label>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.BoosterPumpP4,
                                      60,
                                      80
                                    )
                                  }"
                                  >{{ f.BoosterPumpP4.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  maxlength="2"
                                  class="form-control smInput"
                                  formControlName="BoosterPumpP4"
                                  (blur)="CheckIfZero('BoosterPumpP4')"
                                  (keypress)="numberOnlyWithoutDot($event)"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.BoosterPumpP4,
                                      60,
                                      80
                                    )
                                  }"
                                  name="BoosterPumpP4"
                                />
                                <span
                                  *ngIf="f.BoosterPumpP4.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##</span
                                >
                                <span
                                  *ngIf="
                                    f.BoosterPumpP4.errors?.required &&
                                    (f.BoosterPumpP4.dirty ||
                                      f.BoosterPumpP4.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Post Filter Bank 1 - P5 (psi):
                              </label>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground:
                                      (f.PostFilterBank1P5.value >
                                        f.BoosterPumpP4.value ||
                                      f.BoosterPumpP4.value -
                                        f.PostFilterBank1P5.value >
                                        10 ) && f.PostFilterBank1P5.value != 'N/A'
                                  }"
                                  >{{ f.PostFilterBank1P5.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  (blur)="CheckIfZero('PostFilterBank1P5')"
                                  (keypress)="numberOnlyWithoutDot($event)"
                                  [ngClass]="{
                                    OurOffRange:
                                      f.PostFilterBank1P5.value >
                                        f.BoosterPumpP4.value ||
                                      f.BoosterPumpP4.value -
                                        f.PostFilterBank1P5.value >
                                        10
                                  }"
                                  type="text"
                                  maxlength="2"
                                  class="form-control smInput"
                                  formControlName="PostFilterBank1P5"
                                  name="PostFilterBank1P5"
                                />
                                <span
                                  *ngIf="f.PostFilterBank1P5.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##</span
                                >
                                <span
                                  *ngIf="
                                    f.PostFilterBank1P5.errors?.required &&
                                    (f.PostFilterBank1P5.dirty ||
                                      f.PostFilterBank1P5.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                Post Carbon Bank 3 - P17 (psi):
                              </label>
                              <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                  Post Carbon Bank 3 - P15 (psi):
                                </label>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.PostCarbonBank3P17,
                                      50,
                                      70
                                    )
                                  }"
                                  >{{ f.PostCarbonBank3P17.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  (blur)="CheckIfZero('PostCarbonBank3P17')"
                                  (keypress)="numberOnlyWithoutDot($event)"
                                  maxlength="2"
                                  class="form-control smInput"
                                  formControlName="PostCarbonBank3P17"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.PostCarbonBank3P17,
                                      50,
                                      70
                                    )
                                  }"
                                  name="PostCarbonBank3P17"
                                />
                                <span
                                  *ngIf="f.PostCarbonBank3P17.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##</span
                                >
                                <span
                                  *ngIf="
                                    f.PostCarbonBank3P17.errors?.required &&
                                    (f.PostCarbonBank3P17.dirty ||
                                      f.PostCarbonBank3P17.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>

                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                Post Filters Bank 2 - P18 (psi):</label
                              >
                              <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                  Post Filters Bank 2 - P16 (psi):</label
                                >
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  (keypress)="numberOnly($event)"
                                  [ngClass]="{
                                    invalidValueBackground:
                                      f.PostFiltersBank2P18.value >
                                        f.PostCarbonBank3P17.value ||
                                      f.PostCarbonBank3P17.value -
                                        f.PostFiltersBank2P18.value >
                                        10
                                  }"
                                  >{{ f.PostFiltersBank2P18.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  (blur)="CheckIfZero('PostFiltersBank2P18')"
                                  (keypress)="numberOnlyWithoutDot($event)"
                                  [ngClass]="{
                                    OurOffRange:
                                      f.PostFiltersBank2P18.value >
                                        f.PostCarbonBank3P17.value ||
                                      f.PostCarbonBank3P17.value -
                                        f.PostFiltersBank2P18.value >
                                        10
                                  }"
                                  maxlength="2"
                                  class="form-control smInput"
                                  formControlName="PostFiltersBank2P18"
                                  name="PostFiltersBank2P18"
                                />

                                <span
                                  *ngIf="
                                    f.PostFiltersBank2P18.errors?.required &&
                                    (f.PostFiltersBank2P18.dirty ||
                                      f.PostFiltersBank2P18.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.PostFiltersBank2P18.errors?.pattern"
                                  [ngClass]="'error'"
                                  >##</span
                                >
                              </div>
                            </div>
                          </div>

                          <div class="box-row col-sm-12" *ngIf="isMain7Form" >
                              <div class="col-sm-4">
                                <label class="col-sm-8 control-label">
                                    Switch Break Tank Pumps:</label
                                >
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus == 'Completed'"
                                >
                                  <label
                                    class="readOnlyText"
                                    >{{ f.SwitchBreakTankPumps.value }}</label
                                  >
                                </div>
                                <div
                                  class="col-sm-4"
                                  *ngIf="ROVerificationStatus != 'Completed'"
                                >
                                  <select
                                    class="form-control mdInput"
                                    formControlName="SwitchBreakTankPumps"
                                    name="SwitchBreakTankPumps"
                                  >
                                    <option value="" style="color:black"
                                      >Select</option
                                    >
                                    <option value="P1" style="color:black"
                                      >P1</option
                                    >
                                    <option value="P2" style="color:black">P2</option>
                                  </select>
                                  <span
                                    *ngIf="
                                      f.SwitchBreakTankPumps.errors?.required &&
                                      (f.SwitchBreakTankPumps.dirty ||
                                        f.SwitchBreakTankPumps.touched ||
                                        isValidFormSubmitted == false)
                                    "
                                    [ngClass]="'error'"
                                  >
                                    Required!
                                  </span>
                                </div>
                              </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12  divider">
                            <div class="col-sm-4">
                              <strong>Testing Verification: </strong>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                pH Pre-Carbon -S1:</label>

                                <label class="col-sm-8 control-label" *ngIf="isMain7Form">
                                  pH Pre-Carbon -TBD:</label>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'">
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(f.pHPreCarbonS1, 7, 8) }"
                                  >{{ f.pHPreCarbonS1.value | number: "1.1-1" }}</label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'">
                                <input
                                  (keypress)="numberOnly($event)"
                                  [ngClass]="{
                                    OurOffRange: checkRange(f.pHPreCarbonS1, 7, 8)
                                  }"
                                  maxlength="3"
                                  class="form-control smInput"
                                  formControlName="pHPreCarbonS1"
                                  (blur)="ReplaceLeadingZero('pHPreCarbonS1')"
                                  name="pHPreCarbonS1"
                                />
                                <span *ngIf=" f.pHPreCarbonS1.errors?.required && (f.pHPreCarbonS1.dirty || f.pHPreCarbonS1.touched || isValidFormSubmitted == false)"
                                  [ngClass]="'error'" >Required!</span>
                                <span *ngIf="f.pHPreCarbonS1.errors?.pattern" [ngClass]="'error'">#.#</span>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label" *ngIf="isMain3EForm">
                                pH Post-Carbon -S14:</label
                              >
                              <label class="col-sm-8 control-label"  *ngIf="isMain7Form">
                                pH Post-Carbon -TBD:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [ngClass]="{
                                    invalidValueBackground: checkRange(
                                      f.pHPostCarbonS14,
                                      7,
                                      8
                                    )
                                  }"
                                  >{{
                                    f.pHPostCarbonS14.value | number: "1.1-1"
                                  }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  (keypress)="numberOnly($event)"
                                  [ngClass]="{
                                    OurOffRange: checkRange(
                                      f.pHPostCarbonS14,
                                      7,
                                      8
                                    )
                                  }"
                                  maxlength="3"
                                  class="form-control smInput"
                                  formControlName="pHPostCarbonS14"
                                  (blur)="ReplaceLeadingZero('pHPostCarbonS14')"
                                  name="pHPostCarbonS14"
                                />

                                <span
                                  *ngIf="
                                    f.pHPostCarbonS14.errors?.required &&
                                    (f.pHPostCarbonS14.dirty ||
                                      f.pHPostCarbonS14.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="f.pHPostCarbonS14.errors?.pattern"
                                  [ngClass]="'error'"
                                  >#.#</span
                                >
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <label class="col-sm-9 control-label" *ngIf="isMain3EForm">
                                Iron Total Post Softener - S14 (ug/L):</label
                              >
                              <label class="col-sm-9 control-label" *ngIf="isMain7Form">
                                Iron Total Post Softener - TBD (ug/L):</label
                              >
                              <div
                                class="col-sm-3"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label class="readOnlyText">{{
                                  f.IronTotalPostSoftenerS14.value | number: "1.2-2"
                                }}</label>
                              </div>
                              <div
                                class="col-sm-3"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <input
                                  (blur)="ReplaceLeadingZero('IronTotalPostSoftenerS14',2)"
                                  maxlength="4"
                                  class="form-control smInput"
                                  formControlName="IronTotalPostSoftenerS14"
                                  name="IronTotalPostSoftenerS14"
                                />
                                <span
                                  *ngIf="
                                    f.IronTotalPostSoftenerS14.errors?.required &&
                                    (f.IronTotalPostSoftenerS14.dirty ||
                                      f.IronTotalPostSoftenerS14.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                                <span
                                  *ngIf="
                                    f.IronTotalPostSoftenerS14.errors?.pattern
                                  "
                                  [ngClass]="'error'"
                                  >#.##</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Verify Testing Chemical Levels:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.VerifyTestingChemicalLevels.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{
                                    f.VerifyTestingChemicalLevels.value
                                  }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.VerifyTestingChemicalLevels.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="VerifyTestingChemicalLevels"
                                  name="VerifyTestingChemicalLevels"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Yes" style="color:black"
                                    >Yes</option
                                  >
                                  <option value="NO" style="color:red">NO</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.VerifyTestingChemicalLevels.errors
                                      ?.required &&
                                    (f.VerifyTestingChemicalLevels.dirty ||
                                      f.VerifyTestingChemicalLevels.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <!-- <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Verify CL17 Chemical Levels:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.VerifyCL17ChemicalLevels.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.VerifyCL17ChemicalLevels.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="ROVerificationStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.VerifyCL17ChemicalLevels.value == 'NO'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="VerifyCL17ChemicalLevels"
                                  name="VerifyCL17ChemicalLevels"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Yes" style="color:black"
                                    >Yes</option
                                  >
                                  <option value="NO" style="color:red">NO</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.VerifyCL17ChemicalLevels.errors?.required &&
                                    (f.VerifyCL17ChemicalLevels.dirty ||
                                      f.VerifyCL17ChemicalLevels.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div> -->
                            <div class="col-sm-4"></div>
                          </div>

                          <div class="box-row col-sm-12"></div>
                          <div class="clearfix"></div>

                          <PageHeader *ngIf="ROVerificationStatus != 'Completed'"
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="
                                  executeGRWPDailyCreateUpdate('WeeklySoftner', 'Weekly')
                                "
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>

                          <div>
                          <hr/>
                          <div class="box-row col-sm-12  divider">
                            <div class="col-sm-4">
                              <strong>CWP RO Disinfection: </strong>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                CWP RO Disinfected:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="f.GRWaterprocessDailyrecordsID.value > 0"
                              >
                                <label class="readOnlyText">{{
                                  VerifyROlablewithoutOff
                                }}</label>
                              </div>
                              <!-- <div
                                class="col-sm-4"
                                *ngIf="isWeeklyCompleted"
                              >
                              <label class="readOnlyText">{{f.CWPRODisinfected.value}}</label>

                            </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              > -->
                                <!-- <select class="form-control mdInput" formControlName="CWPRODisinfected" name="CWPRODisinfected">
                                                                                                  <option value="">Select</option>
                                                                                                  <option value="3ERO-A">3ERO-A</option>
                                                                                                  <option value="3ERO-B">3ERO-B</option>
                                                                                              </select>

                                                                                              <span *ngIf="f.CWPRODisinfected.errors?.required && (f.CWPRODisinfected.dirty || f.CWPRODisinfected.touched ||  isValidFormSubmitted==false)"
                                                                                              [ngClass]="'error'">
                                                                                              Required!
                                                                                          </span> -->
                                <!-- <label
                                  class="readOnlyText"
                                  style="color:black"
                                  *ngIf="
                                    f.GRWaterprocessDailyrecordsID.value == 0
                                  "
                                  >{{ VerifyROlablewithoutOff }}</label
                                >
                              </div> -->
                            </div>

                            <div class="col-sm-8">
                              <label class="col-sm-4 control-label" style="padding-right: 3%;">
                                Disinfection Date:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="isWeeklyCompleted || formLockedOut"
                                style="padding-left: 0px;">
                                <label class="readOnlyText">
                                  {{
                                    f.DisinfectionDate.value.jsdate
                                      | date: "dd/MM/yyyy HH:mm"
                                  }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                                style="padding-left: 0px;">
                                <label class="readOnlyText">
                                  {{
                                    f.DisinfectionDate.value.jsdate
                                      | date: "dd/MM/yyyy HH:mm"
                                  }}</label
                                >
                              </div>
                            </div>
                          </div>

                          <div class="clearfix"></div>

                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Chemical Residual Test Result?:</label>

                              <div
                                class="col-sm-4"
                                style="padding-left: 12px;"
                                *ngIf="DisinfectionStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.ResidualTestResult.value == 'Positive'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.ResidualTestResult.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                style="padding-left: 12px;"
                                *ngIf="DisinfectionStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.ResidualTestResult.value == 'Positive'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="ResidualTestResult"
                                  name="ResidualTestResult"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Negative" style="color:black"
                                    >Negative</option
                                  >
                                  <option value="Positive" style="color:red"
                                    >Positive</option
                                  >
                                </select>
                                <span
                                  *ngIf="
                                    f.ResidualTestResult.errors?.required &&
                                    (f.ResidualTestResult.dirty ||
                                      f.ResidualTestResult.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-8">
                              <label class="col-sm-4 control-label" style="padding-right: 3%;">
                                Chemical Disinfection User:</label
                              >
                              <div
                                class="col-sm-8"
                                style="padding-left: 0px;">
                                <label class="readOnlyText">{{
                                  f.ChemicalDisinfectionUser.value
                                }}</label>
                              </div>
                              <!-- <div
                                class="col-sm-5"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                               <label class="readOnlyText">{{
                                  DisinfectionUserName
                                }}</label>
                              </div> -->
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Disinfect Secondary RO:</label
                              >
                              <div
                                class="col-sm-4"
                                *ngIf="DisinfectionStatus == 'Completed'"
                              >
                                <label class="readOnlyText">{{
                                  f.DisinfectSecondaryRO.value
                                }}</label>
                              </div>
                              <div
                                class="col-sm-4"
                                *ngIf="DisinfectionStatus != 'Completed'"
                              >

                                <select
                                  class="form-control mdInput"
                                  formControlName="DisinfectSecondaryRO"
                                  name="DisinfectSecondaryRO"
                                  (change)="DisinfectSecondaryRODropDownChanged()"
                                >
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="NO">NO</option>
                                </select>
                                <span
                                  *ngIf="
                                    f.DisinfectSecondaryRO.errors?.required &&
                                    (f.DisinfectSecondaryRO.dirty ||
                                      f.DisinfectSecondaryRO.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                          </div>

                          <div
                            class="box-row col-sm-12"
                            *ngIf="f.DisinfectSecondaryRO.value == 'Yes'"
                          >
                            <hr />
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                CWP RO Disinfected:</label
                              >

                              <div
                                class="col-sm-4"
                                *ngIf="(f.GRWaterprocessDailyrecordsID.value > 0 && !IsDisinfectSecondaryRODisplayed) || isWeeklyCompleted"
                              >
                                <label class="readOnlyText">{{
                                  VerifyingCWWPROText
                                }}</label>
                              </div>
                              <!-- <div
                                class="col-sm-4"
                                *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                              > -->
                                <!-- <select class="form-control mdInput" formControlName="CWPRODisinfected" name="CWPRODisinfected">
                                                                                                                      <option value="">Select</option>
                                                                                                                      <option value="3ERO-A">3ERO-A</option>
                                                                                                                      <option value="3ERO-B">3ERO-B</option>
                                                                                                                  </select>

                                                                                                                  <span *ngIf="f.CWPRODisinfected.errors?.required && (f.CWPRODisinfected.dirty || f.CWPRODisinfected.touched ||  isValidFormSubmitted==false)"
                                                                                                                  [ngClass]="'error'">
                                                                                                                  Required!
                                                                                                              </span> -->
                                <!-- <label
                                  class="readOnlyText"
                                  style="color:black"
                                  *ngIf="
                                    (f.GRWaterprocessDailyrecordsID.value == 0 &&
                                    !formLockedOut) || IsDisinfectSecondaryRODisplayed
                                  "
                                  >{{ VerifyingCWWPROText }}</label
                                >
                              </div> -->
                            </div>

                            <div class="col-sm-8">
                              <label class="col-sm-4 control-label" style="padding-right: 3%;">
                                Disinfection Date:</label
                              >
                              <div
                                class="col-sm-8"
                                *ngIf="(isWeeklyCompleted || formLockedOut) && !IsDisinfectSecondaryRODisplayed"
                                style="padding-left: 0px;">
                                <label class="readOnlyText">
                                  {{
                                    f.DisinfectionDateSec.value.jsdate
                                      | date: "dd/MM/yyyy HH:mm"
                                  }}</label
                                >
                              </div>
                              <div
                                class="col-sm-8"
                                *ngIf="(!isWeeklyCompleted && !formLockedOut) || IsDisinfectSecondaryRODisplayed"
                                style="padding-left: 0px;">
                                <!-- <input (focus)="DisinfectionDates.toggleCalendar()" (click)="DisinfectionDates.openCalendar()"
                                                                                                              class="form-control mdInput"  style="float:none" placeholder="Select date" angular-mydatepicker name="DisinfectionDate"
                                                                                                              formControlName="DisinfectionDate" [options]="myOptions" #DisinfectionDates="angular-mydatepicker" (dateChanged)="onDateChanged($event)"/>

                                                                                                              <span  *ngIf="f.DisinfectionDate.errors?.required && (f.DisinfectionDate.dirty || f.DisinfectionDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                                                                                Required!
                                                                                                              </span> -->
                                <label class="readOnlyText">
                                  {{
                                    f.DisinfectionDateSec.value.jsdate | date: "dd/MM/yyyy HH:mm"
                                  }}</label
                                >
                              </div>
                            </div>
                          </div>

                          <div
                            class="box-row col-sm-12"
                            *ngIf="f.DisinfectSecondaryRO.value == 'Yes'"
                          >
                            <div class="col-sm-4">
                              <label class="col-sm-8 control-label">
                                Chemical Residual Test Result?:</label
                              >
                              <div
                                class="col-sm-4"
                                style="padding-left: 12px;"
                                *ngIf="DisinfectionStatus == 'Completed'"
                              >
                                <label
                                  class="readOnlyText"
                                  [style.color]="
                                    f.ResidualTestResultsec.value == 'Positive'
                                      ? 'red'
                                      : 'black'
                                  "
                                  >{{ f.ResidualTestResultsec.value }}</label
                                >
                              </div>
                              <div
                                class="col-sm-4"
                                style="padding-left: 12px;"
                                *ngIf="DisinfectionStatus != 'Completed'"
                              >
                                <select
                                  [style.color]="
                                    f.ResidualTestResultsec.value == 'Positive'
                                      ? 'red'
                                      : 'black'
                                  "
                                  class="form-control mdInput"
                                  formControlName="ResidualTestResultsec"
                                  name="ResidualTestResultsec"
                                >
                                  <option value="" style="color:black"
                                    >Select</option
                                  >
                                  <option value="Negative" style="color:black"
                                    >Negative</option
                                  >
                                  <option value="Positive" style="color:red"
                                    >Positive</option
                                  >
                                </select>
                                <span
                                  *ngIf="
                                    f.ResidualTestResultsec.errors?.required &&
                                    (f.ResidualTestResultsec.dirty ||
                                      f.ResidualTestResultsec.touched ||
                                      isValidFormSubmitted == false)
                                  "
                                  [ngClass]="'error'"
                                >
                                  Required!
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-8">
                              <label class="col-sm-4 control-label" style="padding-right: 3%;">
                                Chemical Disinfection User:</label
                              >
                              <div
                                class="col-sm-8"
                                style="padding-left: 0px;">
                                <label class="readOnlyText">{{
                                  f.ChemicalDisinfectionUserSec.value
                                }}</label>
                              </div>
                              <!-- <div
                                class="col-sm-5"
                                *ngIf="!isWeeklyCompleted && !formLockedOut"
                              >
                               <label class="readOnlyText">{{
                                  DisinfectionUserName
                                }}</label>
                              </div> -->
                            </div>
                          </div>

                          <div class="clearfix"></div>
                          <div class="box-row col-sm-12">
                            <div class="col-sm-2">
                              <!-- <label class="control-label" style="color: #0484a4">User Name: <label class="readOnlyText">{{WeeklyUserName}} </label></label> -->
                            </div>
                            <div class="col-sm-3">
                              <label class="control-label"></label>
                            </div>
                            <div class="col-sm-5">
                              <!-- <label class="control-label" style="color: #0484a4">Time:</label>
                                          &nbsp;&nbsp;
                                          <label class="control-label">{{WeeklyUserTime | date:'dd/MM/yyyy hh:mm'}}</label> -->
                            </div>

                            <!-- <div
                              class="col-sm-2"

                            >
                              <PageHeader
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="setMessageForDisinfection()"
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>
                            </div> -->

                            <div class="col-sm-2"
                            *ngIf="DisinfectionStatus != 'Completed'"
                           >
                                <PageHeader
                                title=""
                                [showNote]="false"
                                [showAddNew]="false"
                                [showSearch]="false"
                                [mainlisturl]=""
                                [showSave]="blnshowSave"
                                (SaveEvent)="
                                setMessageForDisinfection()"
                                [showDelete]="false"
                                [showMainListing]="false"
                                #pageheader
                              ></PageHeader>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>

            <notes
              [ngStyle]="{ visibility: isNotesShow }"
              [isValidForm]="(GR3E7DailyDetailForm.invalid && (!f.GRWaterprocessDailyrecordsID.value || f.GRWaterprocessDailyrecordsID.value == '0')) || (GR3E7DailyDetailForm.invalid && checkValuesForSoftenerVerification())"
              (addNoteClick)="executeGRWPDailyCreateUpdate('Notes', '')"
              #notes
            >
            </notes>
            <attachment
              [ngStyle]="{ visibility: isattachmentShow }"
              (RaiseError)="AttachmentError($event)"
              #attachment
            >
            </attachment>
          </div>

          <div class="clearfix"></div>

        </div>
      </div>
    </section>
  </form>

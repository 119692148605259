import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class HomeService {
  apiUrl = Globals.apiUrl;
  constructor(private http: HttpClient) {}

  GetDialysisStationSearchDetails(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'Home/GetDialysisStationSearchDetails',
      SearchOptions,
      httpOptions
    );
  }

  FillEquipmentsByCategory(SearchOptions) {
    return this.http.post<any>(
      this.apiUrl + 'DMRepairFillService/FillEquipmentDDByCategory',
      SearchOptions,
      httpOptions
    );
  }

  FillHomeStationEquipmentsByCategory(value) {
    return this.http.post<any>(
      this.apiUrl + 'Home/FillHomeStationEquipmentsByCategory',
      value,
      httpOptions
    );
  }

  InsertDialysisStationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'Home/InsertDialysisStationDetails',
      value,
      httpOptions
    );
  }

  UpdateDialysisStationDetails(value) {
    return this.http.post<any>(
      this.apiUrl +
      'Home/UpdateDialysisStationDetails',
      value,
      httpOptions
    );
  }

  GetDialysisStationByID(value) {
    return this.http.post<any>(
      this.apiUrl +
      'Home/GetDialysisStationByID',
      value,
      httpOptions
    );
  }


  GetDialysisStationNotes(value){
    return this.http.post<any>(
      this.apiUrl + 'Home/GetDialysisStationNotes',
      value,
      httpOptions
    );
  }

  GetHome_Station_LastCompletedDates(value) {
    return this.http.post<any>(
      this.apiUrl + 'Home/GetHome_Station_LastCompletedDates',
      value,
      httpOptions
    );
  }

  GetHomeStationHistoryDetails(value) {

    return this.http.post<any>(
      this.apiUrl +
      'Home/GetHomeStationHistoryDetails',
      value,
      httpOptions
    );
  }

  GetDialysisStationByStationName(value) {
    return this.http.post<any>(
      this.apiUrl +
      'Home/GetDialysisStationByStationName',
      value,
      httpOptions
    );
  }

}


<form [formGroup]="dialysisStationForm" novalidate>

  <section class="content-header">
    <h1>
      {{title}}
      <sup class="beta">[demo]</sup>

    </h1>

  </section>
  <section class="content">

    <div class="row">
      <div class="box  box-primary" style="min-height:auto">
        <!-- <PageHeader [title]="title" [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" [showEdit]="blnshowSave"
                    (EditEvent)="EnableDisableEquipments()" (SaveEvent)="executeDialysisStationUpdate('')" #pageheader>
                </PageHeader> -->
        <PageHeader [title]="title" [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
          [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" [showEdit]="false"
          (EditEvent)="EnableDisableEquipments()" (SaveEvent)="executeDialysisStationUpdate('')" #pageheader>
        </PageHeader>

        <div class="box" style="min-height:auto">
          <div class="box-body">
            <div class="row" *ngIf="f.dialysisUnitId.value == '-1'">
              <div class="box ibox box-info">
                <!-- <div class="box-header with-border">
                                    <h3 class="box-title"></h3>
                                </div> -->
                <div class="box-body">
                  <div class="row">
                    <div class="col-sm-12 box-row">
                      <label class="col-sm-3 control-label">BMR Home Unit:
                      </label>
                      <label class=" col-sm-7">
                        <div class="readOnlyText">
                          {{f.dialysisStationName.value}}
                        </div>
                      </label>
                    </div>
                    <div class="col-sm-12 box-row">
                      <label class="col-sm-3 control-label"><span class="text-red inputrequired">*</span>Home
                        DM:</label>
                      <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                        <label class="readOnlyText">{{dmEquipmentListText}}</label>
                      </div>
                    </div>
                    <div class="col-sm-12 box-row">
                      <label class="col-sm-3 control-label"><span class="text-red inputrequired">*</span>Home
                        RO:</label>

                      <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                        <label class="readOnlyText"> {{roEquipmentListText}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="f.dialysisUnitId.value != '-1'">
              <div class="box-body col-sm-offset-1 col-sm-10">
                <div class="box-row col-sm-12">

                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">BMR Home Unit:</label>
                    <div class="col-sm-7" style="margin-bottom: 5px;padding-left: 0;"
                      *ngIf="f.dialysisUnitId.value == 0">
                      <div class="col-sm-6">
                        <input class="form-control" [(ngModel)]="nextStationIdPrefix" disabled
                          [ngModelOptions]="{standalone: true}">

                      </div>
                      <div class="col-sm-7" style="margin-bottom: 5px;padding-left: 0;"
                        *ngIf="f.dialysisUnitId.value != 0">
                      </div>
                      <div class="col-sm-4" style="max-width:133px">
                        <input class="form-control" formControlName="dialysisStationName" type="text"
                          (keyup)="forceInputUppercase($event)" (keypress)="charOnly($event)" maxlength="3"
                          placeholder="XXX">
                        <span
                          *ngIf="f.dialysisStationName.errors?.required && (f.dialysisStationName.dirty || f.dialysisStationName.touched ||  formSubmitted)"
                          [ngClass]="'error'" style="line-height: 30px;margin: 0;">Required!</span>
                      </div>
                      <div class="col-sm-2 row" *ngIf="f.dialysisUnitId.value == 0">
                        <a href="javascript:void(0)"
                          title="XXX - First Letter of Patient First Name, and First Two Letters of Patient Last Name"><i
                            class="fa fa-info-circle"
                            style="color: black;margin-top: 8px;cursor: pointer;font-size: 20px"></i></a>
                      </div>
                    </div>
                    <!-- <div class="col-sm-7" style="margin-bottom: 5px;padding-left: 0;"
                      > -->
                      <label *ngIf="f.dialysisUnitId.value != 0" class="col-sm-7 control-label" style="text-align: left !important;">{{f.dialysisStationName.value}}</label></div>
                  <!-- </div> -->
                </div>
                <div class="box-row col-sm-12">
                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">Address:</label>
                    <div class="col-sm-7">
                      <textarea autosize class="form-textarea" formControlName="ISVAddress"></textarea>
                      <span
                        *ngIf="f.ISVAddress.errors?.required && (f.ISVAddress.dirty || f.ISVAddress.touched ||  formSubmitted)"
                        [ngClass]="'error'">Required!</span>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">City:</label>
                    <div class="col-sm-7">
                      <input class="form-control" formControlName="ISVCity">
                      <span
                        *ngIf="f.ISVCity.errors?.required && (f.ISVCity.dirty || f.ISVCity.touched ||  formSubmitted)"
                        [ngClass]="'error'">Required!</span>
                    </div>
                  </div>
                </div>
                <div class="box-row col-sm-12">
                  <div class="col-sm-6">

                    <label class="col-sm-5 noPaddingAll control-label">Province:</label>
                    <div class="col-sm-7">
                      <input class="form-control" formControlName="ISVProvince">
                      <span
                        *ngIf="f.ISVProvince.errors?.required && (f.ISVProvince.dirty || f.ISVProvince.touched ||  formSubmitted)"
                        [ngClass]="'error'">Required!</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">Postal Code:</label>
                    <div class="col-sm-7">
                      <input class="form-control" formControlName="ISVPostalCode" maxlength="7"
                        (keyup)="forceInputUppercase($event)" placeholder="A0A 0A0" (focusout)="lostfocusPostalCode()">
                      <span *ngIf='f.ISVPostalCode.errors?.pattern' [ngClass]="'error'"
                        style="margin-left: 2px;">Invalid value</span>
                      <span
                        *ngIf="f.ISVPostalCode.errors?.required && (f.ISVPostalCode.dirty || f.ISVPostalCode.touched ||  formSubmitted)"
                        [ngClass]="'error'">Required!</span>
                    </div>
                  </div>
                </div>
                <div class="box-row col-sm-12">
                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">Phone #:</label>
                    <div class="col-sm-7">
                      <input class="form-control" (keypress)="numberOnly($event)" maxLength="15"
                        placeholder="(###) ###-####" (blur)="formatPhone('phone')" formControlName="ISVPhoneNumber">
                      <span
                        *ngIf="f.ISVPhoneNumber.errors?.required && (f.ISVPhoneNumber.dirty || f.ISVPhoneNumber.touched ||  formSubmitted)"
                        [ngClass]="'error'">Required!</span>
                      <span *ngIf='f.ISVPhoneNumber.errors?.pattern' [ngClass]="'error'"
                        style="margin-left: 2px;">Invalid
                        value</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label class="col-sm-5 noPaddingAll control-label">Access Code:</label>
                    <div class="col-sm-7">
                      <input class="form-control" maxLength="15" placeholder="" formControlName="ISVAccessCode">
                    </div>
                  </div>
                </div>
                <div class="box-row col-sm-12">
                  <label class="col-sm-2 noPaddingAll control-label" style="width: 21%;">Directions:</label>
                  <div class="col-sm-9" style="width: 78%;">
                    <textarea autosize class="form-textarea" formControlName="ISVDirections"></textarea>
                  </div>
                </div>

                <!-- <div class="col-sm-12 box-row">
                  <div class="col-sm-12">
                      <label class="col-sm-6 control-label"><span
                              class="text-red inputrequired">*</span>Home DM:</label>

                      <div class="col-sm-5" [hidden]="f.dialysisUnitId.value == '-1'">
                          <select class="form-control mdInput" formControlName="dmEquipmentId"
                              (change)="dmChange()" name="dmEquipmentId">
                              <option value="">Select</option>
                              <option value="{{item.value}}" *ngFor="let item of dmEquipmentList">
                                  {{item.text}}
                              </option>
                          </select>
                          <span
                              *ngIf="f.dmEquipmentId.errors?.required && (f.dmEquipmentId.dirty || f.dmEquipmentId.touched ||  formSubmitted == false)"
                              [ngClass]="'error'">
                              Required!
                          </span>
                      </div>
                      <div class="row col-sm-1" [hidden]="f.dialysisUnitId.value == '-1'">
                          <a href="javascript:void(0)" style="font-size: 25px"
                              (click)="OpenHistory(f.dmEquipmentId.value,f.DMItemSerialNo.value,f.DMItemCode.value,f.DMInServiceDate.value)"><i
                                  class="fa fa-history"></i></a>
                      </div>
                      <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                          <label class="readOnlyText">{{dmEquipmentListText}}</label>
                      </div>
                  </div>

              </div>
              <div class="col-sm-12 box-row">
                  <div class="col-sm-12">
                      <label class="col-sm-6 control-label"><span
                              class="text-red inputrequired">*</span>Home RO:</label>

                      <div class="col-sm-5" [hidden]="f.dialysisUnitId.value == '-1'">
                          <select class="form-control mdInput" formControlName="roEquipmentId"
                              name="roEquipmentId" (change)="roChange()">
                              <option value="">Select</option>
                              <option value="{{item.value}}" *ngFor="let item of roEquipmentList">
                                  {{item.text}}
                              </option>
                          </select>
                          <span
                              *ngIf="f.roEquipmentId.errors?.required && (f.roEquipmentId.dirty || f.roEquipmentId.touched || formSubmitted == false)"
                              [ngClass]="'error'">
                              Required!
                          </span>
                      </div>
                      <div class="row col-sm-1" [hidden]="f.dialysisUnitId.value == '-1'">
                          <a href="javascript:void(0)" style="font-size: 25px"
                              (click)="OpenHistory(f.roEquipmentId.value,f.ROItemSerialNo.value,f.ROItemCode.value,f.ROInServiceDate.value)"><i
                                  class="fa fa-history"></i></a>
                      </div>
                      <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                          <label class="readOnlyText"> {{roEquipmentListText}}</label>
                      </div>
                  </div>
              </div> -->
              </div>
              <!-- <div class="col-md-4">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-12">
                                        <label class="col-sm-6 control-label">BMR Home Unit:
                                        </label>
                                        <label class="readOnlyText col-sm-5">
                                            {{f.dialysisStationName.value}}</label>
                                            <div class="row col-sm-1" [hidden]="f.dialysisUnitId.value == '-1'">
                                              <a href="javascript:void(0)" style="font-size: 25px"
                                                  (click)="OpenISV_ContactDetail(f.dialysisUnitId.value)"><i
                                                      class="fa fa-id-card"></i></a>
                                          </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-12">
                                        <label class="col-sm-6 control-label"><span
                                                class="text-red inputrequired">*</span>Home DM:</label>

                                        <div class="col-sm-5" [hidden]="f.dialysisUnitId.value == '-1'">
                                            <select class="form-control mdInput" formControlName="dmEquipmentId"
                                                (change)="dmChange()" name="dmEquipmentId">
                                                <option value="">Select</option>
                                                <option value="{{item.value}}" *ngFor="let item of dmEquipmentList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="f.dmEquipmentId.errors?.required && (f.dmEquipmentId.dirty || f.dmEquipmentId.touched ||  formSubmitted == false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                        <div class="row col-sm-1" [hidden]="f.dialysisUnitId.value == '-1'">
                                            <a href="javascript:void(0)" style="font-size: 25px"
                                                (click)="OpenHistory(f.dmEquipmentId.value,f.DMItemSerialNo.value,f.DMItemCode.value,f.DMInServiceDate.value)"><i
                                                    class="fa fa-history"></i></a>
                                        </div>
                                        <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                                            <label class="readOnlyText">{{dmEquipmentListText}}</label>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-12">
                                        <label class="col-sm-6 control-label"><span
                                                class="text-red inputrequired">*</span>Home RO:</label>

                                        <div class="col-sm-5" [hidden]="f.dialysisUnitId.value == '-1'">
                                            <select class="form-control mdInput" formControlName="roEquipmentId"
                                                name="roEquipmentId" (change)="roChange()">
                                                <option value="">Select</option>
                                                <option value="{{item.value}}" *ngFor="let item of roEquipmentList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="f.roEquipmentId.errors?.required && (f.roEquipmentId.dirty || f.roEquipmentId.touched || formSubmitted == false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                        <div class="row col-sm-1" [hidden]="f.dialysisUnitId.value == '-1'">
                                            <a href="javascript:void(0)" style="font-size: 25px"
                                                (click)="OpenHistory(f.roEquipmentId.value,f.ROItemSerialNo.value,f.ROItemCode.value,f.ROInServiceDate.value)"><i
                                                    class="fa fa-history"></i></a>
                                        </div>
                                        <div class="col-sm-7" [hidden]="f.dialysisUnitId.value != '-1'">
                                            <label class="readOnlyText"> {{roEquipmentListText}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-offset-6 col-sm-2 box-row">
                                  <a href="javascript:void(0)" *ngIf="showSave" (click)="executeDialysisStationUpdate()" class="btn btn-primary" id="btnSave"> <i class="fa fa-save"></i> &nbsp; <span>Save</span> </a>
                                </div>
                                </div> -->


              <!-- <div class="col-md-8" *ngIf="f.dialysisUnitId.value != '-1'">
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-8 control-label" style="padding-top: 0px !important;">RO Bacteria Test:
                                    </label>
                                    <label class="readOnlyText col-sm-4" style="padding:0px;">
                                        {{(lastDates && lastDates.lastROBacteriaDate)  ? (lastDates.lastROBacteriaDate | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-10 control-label" style="padding-top: 0px !important;">Iron Filters:
                                    </label>
                                    <label class="readOnlyText col-sm-2" style="padding:0px;cursor: pointer;" title="Scheduled Change Date: {{ (lastDates && lastDates.ironFiltersScheduleDate) ? (lastDates.ironFiltersScheduleDate  | date:'MM/yyyy')  : 'N/A'}}">
                                        {{ (lastDates && lastDates.ironFilterVal && lastDates.ironFilterVal == '1') ? 'Yes'  : 'No'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">RO GFI Test:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;cursor: pointer;" title="Scheduled Testing: {{ (lastDates && lastDates.rogfiScheduleDate) ? (lastDates.rogfiScheduleDate  | date:'MM/yyyy')  : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastYCRoGFIPlugDate) ? (lastDates.lastYCRoGFIPlugDate  | date:'dd/MM/yyyy')  : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-8 control-label" style="padding-top: 0px !important;">RO Pyrogen Test:
                                    </label>
                                    <label class="readOnlyText col-sm-4" style="padding:0px;">
                                        {{ (lastDates && lastDates.lastROPyrogenDate) ? (lastDates.lastROPyrogenDate  | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-10 control-label" style="padding-top: 0px !important;">RO PreFilter Changed:
                                    </label>
                                    <label class="readOnlyText col-sm-2" style="padding:0px;cursor: pointer;" title="Scheduled Change Date: {{ (lastDates && lastDates.roPreFilterScheduleDate) ? (lastDates.roPreFilterScheduleDate | date:'MM/yyyy') : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastROPreFilter) ? (lastDates.lastROPreFilter  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">DM GFI Test:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;cursor: pointer;"  title="Scheduled Testing: {{ (lastDates && lastDates.dmgfiScheduleDate) ? (lastDates.dmgfiScheduleDate  | date:'MM/yyyy')  : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastYCDmGFIPlugDate) ? (lastDates.lastYCDmGFIPlugDate  | date:'dd/MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-8 control-label" style="padding-top: 0px !important;">DM Bacteria Test:
                                    </label>
                                    <label class="readOnlyText col-sm-4" style="padding:0px;">
                                        {{ (lastDates && lastDates.lastDMBacteriaDate) ? (lastDates.lastDMBacteriaDate | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-10 control-label" style="padding-top: 0px !important;">Feed Water Comp.Test:
                                    </label>
                                    <label class="readOnlyText col-sm-2" style="padding:0px;cursor: pointer;" title="Scheduled Testing (6/12): {{ (lastDates && lastDates.feedWaterScheduleDate) ? (lastDates.feedWaterScheduleDate  | date:'MM/yyyy')  : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastCWTFeedDate) ? (lastDates.lastCWTFeedDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Last DM PM Date:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;cursor: pointer;" title="Next Scheduled PM: {{ (lastDates && lastDates.pmScheduleDate) ? (lastDates.pmScheduleDate | date:'dd/MM/yyyy') : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastPMDate) ? (lastDates.lastPMDate | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-8 control-label" style="padding-top: 0px !important;">DM Pyrogen Test:
                                    </label>
                                    <label class="readOnlyText col-sm-4" style="padding:0px;">
                                        {{ (lastDates && lastDates.lastDMPyrogenDate) ? (lastDates.lastDMPyrogenDate  | date:'dd/MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Change Date:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.ironFiltersScheduleDate) ? (lastDates.ironFiltersScheduleDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Change Date:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.roPreFilterScheduleDate) ? (lastDates.roPreFilterScheduleDate | date:'MM/yyyy') : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-10 control-label" style="padding-top: 0px !important;padding-left: 0px;">Product Water Comp. Test:
                                    </label>
                                    <label class="readOnlyText col-sm-2" style="padding:0px;cursor: pointer;" title="Scheduled Testing (6/12): {{ (lastDates && lastDates.productWaterScheduleDate) ? (lastDates.productWaterScheduleDate  | date:'MM/yyyy')  : 'N/A'}}">
                                        {{ (lastDates && lastDates.lastCWTROProductDate) ? (lastDates.lastCWTROProductDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Testing (6/12):
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.feedWaterScheduleDate) ? (lastDates.feedWaterScheduleDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Testing (6/12):
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.productWaterScheduleDate) ? (lastDates.productWaterScheduleDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Testing:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.rogfiScheduleDate) ? (lastDates.rogfiScheduleDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Scheduled Testing:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.dmgfiScheduleDate) ? (lastDates.dmgfiScheduleDate  | date:'MM/yyyy')  : 'N/A'}}</label>
                                </div>

                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Next Scheduled PM:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        {{ (lastDates && lastDates.pmScheduleDate) ? (lastDates.pmScheduleDate | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                </div>
                                <div class="col-sm-4 margintop">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Last RO PM Date:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;" title="Next Scheduled PM:N/A">
                                       N/A</label>
                                </div>
                                <div class="col-sm-4" style="display: none;">
                                    <label class="col-sm-9 control-label" style="padding-top: 0px !important;">Next Scheduled PM:
                                    </label>
                                    <label class="readOnlyText col-sm-3" style="padding:0px;">
                                        N/A</label>
                                </div>
                                <div class="col-sm-4 margintop"  [hidden]="hasMasterDateVaule==false">
                                  <label class="col-sm-8 control-label" style="padding-top: 0px !important;">Master Date:
                                  </label>
                                  <label class="readOnlyText col-sm-4" style="padding:0px;">
                                    {{MasterDate}}</label>
                              </div>
                            </div> -->
            </div>
            <notes [isValidForm]="this.dialysisStationForm.invalid"
              (addNoteClick)="executeDialysisStationUpdate('Notes')" #notes></notes>

            <!-- <div>
                                <table id="HomeHistoryList" class="table"
                                            *ngIf="(homeStationHistoryList.length > 0)"  border="none"
                                            cellspacing="0" cellpadding="0" style="border:0px; table-layout: fixed;">
                                    <thead>
                                        <tr style="border:0px;">
                                            <th style="width:12%">Date & Time</th>
                                            <th style="width:9%">User</th>
                                            <th style="width:30%">Note</th>
                                            <th style="width:6%">HFS ID</th>
                                            <th style="width:12%">Purpose</th>
                                            <th style="width:4%">Status</th>
                                            <th style="width:3%" class="headerRotate">RO Bacteria</th>
                                            <th style="width:3%" class="headerRotate">DM Bacteria</th>
                                            <th style="width:3%" class="headerRotate">RO Pyrogen</th>
                                            <th style="width:3%" class="headerRotate">DM Pyrogen</th>
                                            <th style="width:3%" class="headerRotate">RO Product</th>
                                            <th style="width:3%;" class="headerRotate">RO Feed</th>
                                            <th style="width:3%;" class="headerRotate">RO GFI</th>
                                            <th style="width:3%;" class="headerRotate">DM GFI</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of homeStationHistoryList ;let index=index" style="text-align: center;">
                                          <td class="tdBorder" >{{item.createdOn | date:'dd/MM/yyyy HH:mm'}}</td>
                                          <td class="tdBorder" title="{{item.prepTechnologists}}">{{item.createdbyname}}<span *ngIf="item.prepTechnologists != null" >..</span></td>
                                          <td class="tdBorder" style="text-align: left;cursor:pointer" title="{{item.hfServiceNote}}" *ngIf="item.hfServiceID == '0'">
                                            <div class="text-overflow">{{item.hfServiceNote}}</div></td>
                                          <td class="tdBorder" style="text-align: left;cursor:pointer" *ngIf="item.hfServiceID != '0'" title="{{item.hfsPurposeDetailNote}}">
                                            <div class="text-overflow">{{item.hfsPurposeDetailNote}}</div></td>
                                          <td class="tdBorder">{{item.hfServiceCode == 0 ? 'N/A' : item.hfServiceCode}}</td>
                                          <td class="tdBorder">{{item.hfServiceVisitPurpose}}</td>
                                          <td class="tdBorder">{{item.hfServiceStatus}}</td>
                                          <td class="tdBorder">{{item.rohpcTestPerformed == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.dmhpcTestPerformed == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.rolalTestPerformed == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.dmlalTestPerformed == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.cwtRoProduct == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.cwtFeed == 'Yes' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.ycDmGFIPlugTested == 'Pass' ? 'X' : ''}}</td>
                                          <td class="tdBorder">{{item.ycRoGFIPlugTested == 'Pass' ? 'X' : ''}}</td>
                                        </tr>
                                      </tbody>
                                </table>
                            </div> -->
          </div>
          <!-- <PageHeader [title]="title" [showNote]="false" (addNoteClick)="notes.AddNote()"
                        [mainlisturl]="ListURL" [showEdit]="blnshowSave" (EditEvent)="EnableDisableEquipments()"
                        [showAddNew]="false" [showSearch]="false" [showSave]="blnshowSave"
                        (SaveEvent)="executeDialysisStationUpdate()" [showDelete]="false" #pageheader></PageHeader> -->
          <PageHeader [title]="title" [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
            [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" [showEdit]="false"
            (EditEvent)="EnableDisableEquipments()" (SaveEvent)="executeDialysisStationUpdate('')" #pageheader>
          </PageHeader>
        </div>
      </div>
    </div>

  </section>
</form>


<div class="box-header pageheader ">
    <div class="col-sm-12">
        
        <div class="col-sm-6 text-left"><h3>{{title}}</h3></div>
        <div class="col-sm-6 text-right">
                <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
            <button type="button" class="btn btn-danger"  style="margin-left: 10px" (click)="dialogRef.close(false)">Close Page</button>

        </div>
    </div>
</div>

  <div class="box-body">
     
        <div class="col-sm-12" style="margin-bottom:20px">
          <div class="col-sm-4">Machine <strong>{{MachineName}}</strong></div>
         
          <!-- <div class="col-sm-4">In Service Date <strong>{{InServiceDate | date:"dd/MM/yyyy"}}</strong></div> -->
        </div>
        
    <div >

      
    <table  style= "width:97%" class="col-sm-12 BlackBorderTable no-footer">
      <tbody>
            
                  
            <ng-container *ngFor="let item of ElectricalData;">
                    <ng-container *ngIf="SetCurrentType(item.type)">
                    <!-- <tr><td colspan="5"  class="sub_heading" style="text-align:left">
                      <h4><strong>{{item.type}}</strong></h4></td></tr> -->
                    
                      <tr><th width="40%" align="center"><strong>{{item.type}}</strong>&nbsp;voltage reference checks</th>
                        <th width="15%" align="center">(analogic)</th>
                        <th width="15%" align="center">pot</th>
                        <th width="15%" align="center">image</th>
                        <th width="15%" align="center">value</th>
                    </tr>
                            
                            </ng-container>
                            <tr>
                                    <td style="vertical-align:middle;text-align:left">
                                        {{item.voltageReference}}
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.analogic}}
                                        
                                    </td>
                                    <td  style="vertical-align:middle">
                                       
                                                {{item.pot}}
                                       
                                    </td>
                                    <td  style="vertical-align:middle">
                                        
                                                {{item.img}}
                                        
                                    </td>
                                    
                                    <td  style="vertical-align:middle">
                                        
                                            <select [(ngModel)]="item.tempresult" [attr.disabled]="(item.result || completed) ? '' : null" >
            <option value="Not Tested">Not Tested</option>
            <option value="Pass">Pass</option>
            <option value="Fail, Adjusted">Fail, Adjusted</option>
            <option value="Fail, Replaced">Fail, Replaced</option>
        
        </select>
                                            
                                       
                                    </td>
                                </tr>
                                
            </ng-container>
         
      </tbody>
</table>

</div>
</div>
 
  <div class="modal-footer">
        <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
    <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close Page</button>
  </div>
export class ScrollHelper {
    private classToScrollTo: string = null;

    scrollToFirst(className: string) {

        this.classToScrollTo = className;
        // this.doScroll();
    }

    doScroll() {

        if (!this.classToScrollTo) {
            return;
        }
        try {
            var elements = document.getElementsByClassName(this.classToScrollTo);

            if (elements.length == 0) {
                return;
            }
            //console.log(elements[1]);
            if(elements[1])
                elements[1].scrollIntoView();
            else if(elements[0])
                elements[0].scrollIntoView();

           //console.log(elements[1].scrollTop,document.body.scrollTop);
           // = elements[1].scrollTop+100;
           //document.body.scrollBy(0,500);
           //document.querySelectorAll("[name='"+elements[1].attributes.getNamedItem("name").value+"']")[0].focus();
           //console.log(elements[1].nodeName,elements[1].attributes.getNamedItem("name").value);
        }
        finally{
            this.classToScrollTo = null;
        }
    }
}

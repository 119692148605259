
<div class="box-header pageheader ">
    <div class="col-sm-12">
        <div class="col-sm-6 text-left"><h3>Attachments</h3></div>
        <div class="col-sm-6 text-right">
            <button type="button" class="btn btn-danger"  style="margin-left: 10px" (click)="dialogRef.close(false)">Close</button>
        </div>
    </div>
</div>

<div class="col-sm-12 box-row">
        <table class="table">
            <!-- <thead>
            <tr>
                <th style="width:40%;text-align: left !important">Attachment Name</th>
            </tr>
            </thead> -->
            <tbody>
                <tr *ngFor="let item of Attachments">

                <td  style="text-align:left" ><strong><a
                    href="javascript:void(0)"
                    (click)="openImageDialog(item.attachmentname,SetAttachmentUrl(item.attachmentpath))">
                    {{item.attachmentname}}</a></strong></td>
                </tr>
            </tbody>
        </table>
    </div>
 
   

<style>
  .toggle-switch-success {
      color: #fff;
      background: #3a4dab;
      font-weight: normal;
  }

  .toggle-switch-danger {
      color:white;
      background:#3a4dab;
      font-weight: normal;
  }

</style>

<div [formGroup]="HX2NotificationForm" novalidate>
<section class="content-header">
  <h1>
    <h1>
      {{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
    <div class="row">
      <div class="box  box-primary" style="overflow-y: auto;">
              <div class="box-header pageheader row" style="max-width: 95%;">
                  <div class="col-sm-1 text-left">

                  </div>
                  <div class="col-sm-10 text-center">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                      <i class="fa fa-picture-o"></i> Layout View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                      <i class="fa fa-table"></i>  Table View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                      <i class="fa fa-th-list"></i> Set Points
                    </a>
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                    (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                    <i class="fa fa-file"></i> Document View
                  </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                      <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                      <i class="fa fa-th-list"></i> Data Logs
                    </a>

                    <a type="button" class="btn btn-primary" style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                    (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
                    <i class="fa fa-cog"></i> Notification Settings
                  </a>

                  </div>
                  <div class="col-sm-1 text-right">
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                      <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                    </a>
                  </div>
              </div>

              <div class="col-sm-offset-3 col-sm-6 box-row">
                <label class='col-sm-4 control-label'>
                  <span class='text-red inputrequired'>*</span>Notification Email:</label>

                <div class='col-sm-6'>
                  <input name='email' maxlength='80' required class='form-control'
                    formControlName='email'>
                  <span *ngIf='f.email.errors?.required && (f.email.dirty || f.email.touched ||  (isValidFormSubmitted!=null && !isValidFormSubmitted))'
                  [ngClass]="'error'"> Required! </span>
                  <!-- <span *ngIf='f.email.errors?.pattern' [ngClass]="'error'">Invalid value</span> -->
                </div>
              </div>

              <div class="col-sm-offset-3 col-sm-6 box-row">
                  <label class='col-sm-4 control-label'>
                    <span class='text-red inputrequired'>*</span>Notification Mobile:</label>

                  <div class='col-sm-6'>
                    <input name='phone' placeholder="(###) ###-####" (blur)="formatPhone('phone')" maxlength='15' (keypress)="numberOnly($event)" required class='form-control'
                      formControlName='phone'>
                    <span *ngIf='f.phone.errors?.required && (f.phone.dirty || f.phone.touched ||  (isValidFormSubmitted!=null && !isValidFormSubmitted))'
                    [ngClass]="'error'"> Required! </span>
                  <span *ngIf='f.phone.errors?.pattern' [ngClass]="'error'">Invalid value</span>

                  </div>
                </div>

                <div class="col-sm-offset-3 col-sm-6 box-row">
                    <label class='col-sm-4 control-label'>
                      <span class='text-red inputrequired'>*</span>Alarm Notification Time:</label>

                      <div class="col-sm-4">
                        <mat-button-toggle-group [value]="isImmediateNotification">
                          <mat-button-toggle *ngFor="let item of NotificationValues" style="font-weight: normal;"
                          value="{{item.value}}" (change)="enableDisableOptions(item.value)"
                          [ngClass]="{'toggle-switch-success':item.value === true && isImmediateNotification,
                          'toggle-switch-danger': item.value === false && !isImmediateNotification }">
                              {{item.text}}
                          </mat-button-toggle>
                      </mat-button-toggle-group>
                      </div>
                      <div class="col-sm-2"  *ngIf="!isImmediateNotification" >
                        <input formControlName="notificationTime" Name="notificationTime" placeholder="Time"
                          class="form-control smInput" [max]="maxTime" [min]="minTime"
                          [owlDateTimeTrigger]="notificationTime" [owlDateTime]="notificationTime"/>
                          <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(f.notificationTime.value)" #notificationTime></owl-date-time>
                      </div>
                  </div>
                  <div class="col-sm-12">
                  <div class="col-sm-offset-7 col-sm-2">
                    <span
                    *ngIf="f.notificationTime.errors?.required && (f.notificationTime.dirty || f.notificationTime.touched ||  (isValidFormSubmitted!=null && !isValidFormSubmitted))"
                    [ngClass]="'error'">
                    Required!
                  </span>
                </div>
              </div>

              <div class="col-sm-offset-3 col-sm-6 box-row">
                <label class='col-sm-4 control-label'>
                  <span class='text-red inputrequired'>*</span>Connection Notification Time:</label>

                  <div class="col-sm-4">
                    <mat-button-toggle-group [value]="isImmediateConnectionNotification">
                      <mat-button-toggle *ngFor="let item of NotificationValues" style="font-weight: normal;"
                      value="{{item.value}}" (change)="enableDisableOptions(item.value, true)"
                      [ngClass]="{'toggle-switch-success':item.value === true && isImmediateConnectionNotification,
                      'toggle-switch-danger': item.value === false && !isImmediateConnectionNotification }">
                          {{item.text}}
                      </mat-button-toggle>
                  </mat-button-toggle-group>
                  </div>
                  <div class="col-sm-2"  *ngIf="!isImmediateConnectionNotification" >
                    <input formControlName="connectionNotificationStartTime" Name="connectionNotificationStartTime" placeholder="Start Time"
                      class="form-control smInput" [max]="maxTime" [min]="minTime"
                      [owlDateTimeTrigger]="connectionNotificationStartTime" [owlDateTime]="connectionNotificationStartTime"/>
                      <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTimeConnection(f.connectionNotificationStartTime.value,'start')" #connectionNotificationStartTime></owl-date-time>
                  </div>
                  <div class="col-sm-2"  *ngIf="!isImmediateConnectionNotification" >
                    <input formControlName="connectionNotificationEndTime" Name="connectionNotificationEndTime" placeholder="End Time"
                      class="form-control smInput" [max]="maxTime" [min]="minTime"
                      [owlDateTimeTrigger]="connectionNotificationEndTime" [owlDateTime]="connectionNotificationEndTime"/>
                      <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTimeConnection(f.connectionNotificationEndTime.value,'end')" #connectionNotificationEndTime></owl-date-time>
                  </div>
              </div>
              <div class="col-sm-12">
                  <div class="col-sm-offset-7 col-sm-1">
                      <span
                      *ngIf="f.connectionNotificationStartTime.errors?.required && (f.connectionNotificationStartTime.dirty || f.connectionNotificationStartTime.touched ||  (isValidFormSubmitted!=null && !isValidFormSubmitted))"
                      [ngClass]="'error'">
                      Required!
                      </span>
                    </div>
                <div class="col-sm-2">
                  <span
                  *ngIf="f.connectionNotificationEndTime.errors?.required && (f.connectionNotificationEndTime.dirty || f.connectionNotificationEndTime.touched ||  (isValidFormSubmitted!=null && !isValidFormSubmitted))"
                  [ngClass]="'error'">
                  Required!
                  </span>
                </div>
              </div>
              <div class="col-sm-12 box-row">
                  <div class="col-sm-offset-5 col-sm-1">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="executeHX2NotificationCreateUpdate()">
                      <i class="fa fa-save"></i> Save
                    </a>
                  </div>
                  <!-- <div class="col-sm-5 box-row row" style="margin-top: 0.5%;margin-left: -3%;">
                    <span [ngClass]="'error'">
                    * Standard Connection Notification Time Should be from 6:00 AM to 11:00 PM.
                    </span>
                  </div> -->
                </div>
      </div>

    </div>
  </section>
</section>
</div>


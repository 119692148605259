<div id="grApplication">
    <div *ngIf="getLoading()" id="loader-wrapper">
        <div class="no-print" id="loader"></div>
        <div class="no-print" class="loader-section"></div>
        <!-- <div class="loader-section"></div> -->
    </div>

    <!-- Site wrapper -->
    <div class="wrapper" id="mywrapper">
        <header1></header1>
        <div class="content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div *ngFor="let alert of getAlerts()" style="z-index: 50000000000 !important">
        <ngb-alert style="z-index: 50000000000 !important" *ngIf="alert.successMessage" [type]="alert.type" (close)="alert.successMessage = null">
            {{ alert.msg }}</ngb-alert>
    </div>
    <footer class="no-print" class="main-footer" id="grFooter">

        <div style="padding: 0px 10px;text-align: center;">
            <strong>Copyright &copy; {{today | date:'yyyy'}} BMR TechWorks Inc. All rights reserved.
                <a href="javascript:void(0)" (click)="openTermsAndConditionDialog()" aria-hidden="true"
                    class="dropdown-toggle" style="text-align:center">Terms of Use</a>
                | <a href="javascript:void(0)" (click)="openPrivacyPolicy()" aria-hidden="true" class="dropdown-toggle"
                    style="text-align:center">Privacy Policy</a>
					<!-- | <a href="javascript:void(0)" (click)="openJMeet()" aria-hidden="true" class="dropdown-toggle"
                    style="text-align:center">CoBrowsing</a>
                    <a style="float: right;">
                        <b>Version</b> 1.2.0
                    </a> -->
             </strong>
        </div>
    </footer>
</div>

import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Globals, actionType , attachmentFormModules} from '../globals';
import { debug } from 'util';

// create your class that extends the angular validator class
export class ContactCustomValidators  {
  static UserNameMatch(verifyUserName: AbstractControl) {
    return (control: AbstractControl)  =>{
      //getting undefined values for both variables
      //console.log(control.value,verifyUserName.value);
       //if I change this condition to === it throws the error if the 
//  two fields are the same, so this part works
       if (control.value !== verifyUserName.value && verifyUserName.value !== "") {
         return { UserNameMismatch: true }
       } else {
         //it always gets here no matter what
         return null;
       }
   }
     }
    static passwordsMatch(confirmedPassword: AbstractControl) {
      return (control: AbstractControl)   =>{
        //getting undefined values for both variables
        //console.log(control.value,confirmedPassword.value);
         //if I change this condition to === it throws the error if the 
 //  two fields are the same, so this part works
        //console.log(control.value,confirmedPassword.value);
         if (control.value !== confirmedPassword.value) {
           return { PasswordMismatch: true }
         } else {
           //it always gets here no matter what
           return null;
         }
      }
      }

    static checkpassword(pinId: AbstractControl) {
      return (control: AbstractControl) => {
        if(control.value != "")
        {
         const NUMBER_REGEXP = /^([0-9])\1{4,}$/;
         //pinId.markAsTouched();
          if (!(NUMBER_REGEXP.test(pinId.value))) {
            return null;
          }
          else
          {
            return { PasswordNotValidate: true }
          }
        }
        else
        {
          return null;
        }
      }
    }

    static checkpindId(pinId: AbstractControl) {
      return (control: AbstractControl) => {
        if(control.value == "12345")
        {
            return { PinIdNotValidate: true }
        } else {
            return null;
          }
        }
      }
        
}



import { Component, ViewChild } from '@angular/core';

import { MailApiService } from '../API/mailapi.service';

import { TableHeadsService } from '../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';


@Component({
  selector: 'MailSearch',
  templateUrl: './MailSearch.component.html',

})
export class MailSearchComponent {

  DivisionDetails = [];
  constructor(private mailapi: MailApiService,
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/Mail/MailCompose";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "Message";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch = false;
  title = "Message";
  searchText = "Search Records"
  defaultSort;



  //Search Options //
  // DivisionName = 'Select';
  // Address = '';
  // City= '';
  // State = '';
  // Country='';
  // Phone='';

  // CountryList = [];
  // StateList = [];
  // DivisionList=[];
    //Search Options //

  ngOnInit() {
    AppComponent.loading=true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#","Message", "4");
    this.dataGridService.addHeader("Message", "Message", "10");
    this.dataGridService.addHeader("Received", "Received", "10");
    this.dataGridService.addHeader("From", "From", "10");
    this.dataGridService.addHeader("Subject", "Subject", "10");
    this.dataGridService.addHeader("Attachment", "Attachment", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("Message", false);
    this.CheckDirtySearch();
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDivisionSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDivisionSearch() {

    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var divisionSearch = this.prepareSearch();

    // this.divisionapi.GetDivisionSearchDetails(divisionSearch).subscribe(pagedData => {

    //   this.page.totalElements = pagedData.totalRows;
    //   this.page.totalPages = pagedData.totalPages;
    //   this.rows = pagedData.divisionDetailList;
    //   AppComponent.HideLoader()
    // },
    //   error => {
    //     AppComponent.addAlert(error.message, "error");
    //     AppComponent.HideLoader();
    //   }
    // );
         AppComponent.HideLoader();

  }

  prepareSearch() {

    var divisionSearch = <any>{};
  //   if(this.DivisionName != 'Select')
  //     divisionSearch.divisionName = this.DivisionName;
  //   else
  //     divisionSearch.divisionName = '';

  //   divisionSearch.address = this.Address;
  //   divisionSearch.city = this.City;

  //   if (this.State !== undefined && this.State !== '' ) {
  //     divisionSearch.state = this.State;
  // }

  // if (this.Country !== undefined && this.Country !== '' ) {
  //   divisionSearch.country = this.Country;
  // }
  //
  //   divisionSearch.phone = this.Phone;

  //   divisionSearch.currentPageNumber = this.currentPageNumber;
  //   divisionSearch.sortExpression = this.sortExpression;
  //   divisionSearch.sortDirection = this.sortDirection;
  //   divisionSearch.pageSize = this.pageSize;
    return divisionSearch;

  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDivisionSearch();
  }
  _onRowClick(row) {

    this.router.navigate(['Admin/Division'], { queryParams: { id: row } });
  }
  executeDivisionSearchClear() {

    // this.DivisionName = 'Select';
    // this.Address = '';
    // this.State = '';
    // this.Country = '';
    // this.City = '';
    // this.Phone = '';

    this.executeDivisionSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;
    // if (
    //   (this.DivisionName != "" && this.DivisionName != 'Select')
    //   || this.Address != ""
    //   || this.City != ""
    //   || this.Country != ""
    //   || this.State != ''
    //   || this.Phone != '') {
    //   this.blnDirtySearch = true;
    // }
    return this.blnDirtySearch;
  }
  //Search & Select //
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<form
    [formGroup]="IncidentForm"
    (ngSubmit)="executeIncidentCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Dialysis Machine Incident Details
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    (addNoteClick)="notes.AddNote()"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeIncidentCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">dmIncidentsId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="dmIncidentsId"
                                        [value]="f.dmIncidentsId"
                                        formControlName="dmIncidentsId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-4">
                                        <label class="col-sm-5 control-label">
                                            Event ID:
                                        </label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText">{{eventId}}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-5 control-label">Form Date:</label>
                                        <div class="col-sm-7">
                                            <label class="readOnlyText">
                                                {{f.FormDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                            </label>
                                        </div>
                                        <!-- <div
                                            class="col-sm-7"
                                            *ngIf="f.dmIncidentsId.value == 0"
                                        >
                                            <input
                                                (focus)="IncidentDate.toggleCalendar()"
                                                (click)="IncidentDate.openCalendar()"
                                                placeholder="Select a date"
                                                class="form-control mdInput"
                                                style="float:none"
                                                angular-mydatepicker
                                                name="IncidentDate"
                                                formControlName="IncidentDate"
                                                [options]="myOptions"
                                                #IncidentDate="angular-mydatepicker"
                                                (dateChanged)="onDateChanged($event)"
                                                required
                                            >
                                            <span
                                                *ngIf="f.IncidentDate.errors?.required && (f.IncidentDate.dirty || f.IncidentDate.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span> -->

                                        </div>

                                    <div class="col-sm-4">
                                        <label class="col-sm-5 control-label">
                                            <span class="text-red inputrequired"></span>
                                            Created By:
                                        </label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText">{{UserName}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>

                                <div class="clearfix"></div>

                                <div class="box ibox box-info">
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="dmReference">
                                                <h4 class="panel-title">
                                                <div class="box-header with-border" style="padding:0px;">
                                                    <h3 class="box-title">
                                                        Dialysis Machine Details
                                                    </h3>
                                                </div>
                                                </h4>
                                            </div>
                                        </div>

                                </div>

                                <div class="col-sm-12 box-row">
                                        
                                        <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Division:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.dmIncidentsId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.dmIncidentsId.value == 0"
                                                >

                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits"
                                                        (change)="DialysisUnitDropDownChanged()"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Incident Date:</label>
                                                <div class="col-sm-7" *ngIf="f.dmIncidentsId.value > 0">
                                                        <label class="readOnlyText"> {{incidentStartDateLabel | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-7"  *ngIf="f.dmIncidentsId.value == 0">
                                                        <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                                        class="form-control mdInput" style="float:none"
                                                        angular-mydatepicker name="IncidentDate"
                                                        formControlName="IncidentDate" [options]="myOptions"
                                                        #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                        required/>
                                                        <span  *ngIf="f.IncidentDate.errors?.required && (f.IncidentDate.dirty || f.IncidentDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                                Required!
                                                            </span>
                                                </div>
                                        </div>

                                    </div>
                                <div class="col-sm-12 box-row">
                                <div class="col-sm-6">
                                    <label class="col-sm-5 control-label">
                                        <span class="text-red inputrequired">*</span>Equipment:
                                    </label>
                                    <div
                                        class="col-sm-7"
                                        *ngIf="f.dmIncidentsId.value > 0"
                                    >
                                        <label class="readOnlyText">{{selectedEquipmenttext}}</label>
                                    </div>
                                    <div
                                        class="col-sm-7"
                                        *ngIf="f.dmIncidentsId.value == 0"
                                    >

                                        <select
                                            class="form-control mdInput"
                                            formControlName="selectedEquipment"
                                            name="selectedEquipment"
                                            (change)="EquipmentDropDownChanged()"
                                        >
                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of Equipments"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select>

                                        <span
                                            *ngIf="f.selectedEquipment.errors?.required && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'"
                                        >
                                            Required!
                                        </span>
                                        <span  *ngIf="f.selectedEquipment.errors?.RepairStatus" [ngClass] = "'error'">
                                            You cannot start a new Incident, while there is an open Repair still existing in the system.
                                          </span>

                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <label class="col-sm-5 control-label">
                                        <!-- <span class="text-red inputrequired">*</span> -->
                                        Station:
                                    </label>
                                    <div
                                        class="col-sm-7"
                                        *ngIf="f.dmIncidentsId.value > 0"
                                    >
                                        <label class="readOnlyText">{{selectedDialysisStationtext}}</label>
                                    </div>
                                    <div
                                        class="col-sm-7"
                                        *ngIf="f.dmIncidentsId.value == 0"
                                    >
                                        <select
                                            class="form-control mdInput"
                                            formControlName="selectedDialysisStation"
                                            name="selectedDialysisStation"
                                        >
                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of DialysisStation"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select>

                                        <span
                                            *ngIf="f.selectedDialysisStation.errors?.required && (f.selectedDialysisStation.dirty || f.selectedDialysisStation.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'"
                                        >
                                            Required!
                                        </span>

                                    </div>
                                </div>
                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6" *ngIf="hrsTracking">
                                            <label class="col-sm-5 control-label">Hours:</label>
                                            <div class="col-sm-7" *ngIf="f.dmIncidentsId.value > 0">
                                                    <label class="readOnlyText" *ngIf="f.hours.value != 0 && f.hours.value != null"> {{f.hours.value}}</label>
                                                    <label class="readOnlyText" *ngIf="f.hours.value == 0 || f.hours.value == null ">N/A</label>
                                            </div>
                                            <div class="col-sm-7" *ngIf="f.dmIncidentsId.value == 0">
                                                <input  maxlength='5' class='form-control smInput' name='hours' formControlName='hours' (keypress)="numberOnly($event)"/>
                                                <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                                <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>

                                                <br/>
                                                <span  *ngIf="f.hours.errors?.required && (f.hours.dirty || f.hours.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                    Required!
                                                </span>
                                                <span  *ngIf="f.hours.errors?.pattern" [ngClass] = "'error'">
                                                        Invalid Hours
                                                </span>

                                                <span  *ngIf="f.hours.errors?.GreaterHoursOut" [ngClass] = "'error'">
                                                        Hrs IN => previous Hrs Out
                                                </span>
                                                <span *ngIf="check4000HoursValidation()"
                                                [ngClass]="'error'">
                                                New Hours >= 4000 from previous Hours.
                                                </span>
                                            </div>
                                        </div>
                                </div>

                                <div class="clearfix"></div>
                            </div>
                            <div *ngIf="blnatLeastOne==true">
                                <span [ngClass]="'error'">
                                    Enter at least one note or error message require.
                                </span>
                            </div>
                            <div class="box ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                    <h4 class="panel-title">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">
                                                Error Codes or Alarms and Warning Messages
                                            </h3>
                                            <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                                        </div>
                                    </h4>
                                </div>
                            </div>
                                <div class="box-body" >
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-2 text-center">
                                                <strong>Error Code</strong>
                                            </div>
                                            <div class="col-sm-9 text-center">
                                                <strong>Description</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="row no-print"
                                        *ngIf="status!='Completed'"
                                    >

                                        <div class="col-sm-12 box-row" *ngIf="((status!='Completed') && (f.dmIncidentsId.value == 0))">
                                            <div class="col-sm-2 text-center" id="errorPaneWrapper">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    (keyup)="ErrorCodeChange('code')"
                                                    matInput
                                                    formControlName="ErrorSearchTerm"
                                                    name="ErrorSearchTerm"
                                                    [matAutocomplete]="auto"
                                                >
                                                <mat-autocomplete id="errorPane"
                                                    (optionSelected)='getErrorPosts($event.option,"code")'
                                                    #auto="matAutocomplete"
                                                >
                                                    <mat-option
                                                        *ngFor="let item of errorSearchResult"
                                                        [value]="item.id"
                                                    >
                                                    {{ item.code}}   {{ (duplicateErrorCodes && item.duplicate) ? '- ' + item.desc : '' }}
                                                    </mat-option>
                                                </mat-autocomplete>

                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" name="ErrorDescription" [value]="ErrorSelectedItem.desc" formControlName="ErrorDescription" [matAutocomplete]="autoerrordesc"
                                                (keyup)="ErrorCodeChange('desc')" (focus)='ErrorCodeChange("desc",false)'>
                                                <mat-autocomplete id="errorPane" (optionSelected)='getErrorPosts($event.option,"desc")'  #autoerrordesc="matAutocomplete">
                                                    <mat-option *ngFor="let item of errorSearchResult" [value]="item.id" >
                                                    {{item.desc}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div class="col-sm-1 no-print">
                                                <button
                                                    (click)="AddError()"
                                                    type="button"
                                                    class="btn btn-primary btn-label-left center-block"
                                                >
                                                    <span>
                                                        <i class="fa fa-plus"></i>
                                                    </span>
                                                    <b>Add</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        *ngFor="let item of ErrorTrans;let index=index;"
                                        class="row"
                                    >

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-2  text-center">
                                                <label class="readOnlyText">{{item.ErrorCode}}</label>
                                            </div>

                                            <div class="col-sm-9">
                                                <label class="readOnlyText">{{item.errorDescription}}</label>
                                            </div>
                                            <div class="col-sm-1">

                                                <button
                                                    class="btn btn-danger btn-label-left center-block btn-xs no-print"
                                                    type="button"
                                                    *ngIf="(status!='Completed') && (item.showDelete)"
                                                    (click)="DeleteError(index)"
                                                >
                                                    <span>
                                                        <i class="fa fa-times"></i>
                                                    </span>

                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info" style="margin-bottom:0px;">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Troubleshooting Suggestions</h3>
                                    <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div
                                        *ngFor="let item of ErrorTrans;let index=index;"
                                        class="row"
                                    >

                                        <div class="col-sm-12 box-row" style="margin-bottom: 0px">
                                            <div class="col-sm-2  text-center">
                                                <label class="">{{item.ErrorCode}}</label>
                                            </div>
                                            <div class="col-sm-10">
                                              <!-- <div class="box-body"> -->
                                                  {{item.errorCodeTroubleShottingSteps}}
                                                <ng-container *ngFor="let step of item.steps">
                                                        <div class="row">
                                                            <div class="col-sm-12 box-row" style="margin-bottom: 0px">

                                                          Step {{step.stepno}}: {{step.stepsDetail}}
                                                            </div>

                                                            </div>


                                                </ng-container>
                                      <!-- </div> -->
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info" style="margin-bottom:0px;">

                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label">Troubleshooting Time:
                                        </label>
                                        <div
                                            class="col-sm-7"
                                            *ngIf="f.dmIncidentsId.value > 0"
                                        >
                                            <label class="readOnlyText">{{TroubleShootingTimeText}}</label>
                                        </div>
                                        <div
                                            class="col-sm-7"
                                            *ngIf="f.dmIncidentsId.value == 0"
                                        >

                                            <select
                                                class="form-control mdInput"
                                                formControlName="TroubleShootingTime"
                                                name="TroubleShootingTime"
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of TroubleShootingTimeList"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                            <!-- <span
                                                *ngIf="f.TroubleShootingTime.errors?.required && (f.TroubleShootingTime.dirty || f.TroubleShootingTime.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span> -->

                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                      <label class="col-sm-5 control-label">
                                          <span class="text-red inputrequired">*</span>Form Designation:
                                      </label>
                                      <div
                                          class="col-sm-7"
                                          *ngIf="f.dmIncidentsId.value > 0"
                                      >
                                          <label class="readOnlyText">{{StatusText}}</label>
                                      </div>
                                      <div
                                          class="col-sm-7"
                                          *ngIf="f.dmIncidentsId.value == 0"
                                      >

                                          <select
                                              class="form-control mdInput"
                                              formControlName="FormDesignation"
                                              name="FormDesignation"
                                          >
                                              <option
                                                  value="{{item.value}}"
                                                  *ngFor="let item of FormDesignationList"
                                              >
                                                  {{item.text}}
                                              </option>
                                          </select>

                                          <span
                                              *ngIf="f.FormDesignation.errors?.required && (f.FormDesignation.dirty || f.FormDesignation.touched ||  isValidFormSubmitted==false)"
                                              [ngClass]="'error'"
                                          >
                                              Required!
                                          </span>

                                      </div>
                                  </div>
                                </div>
                                <div class="clearfix"></div>


                            </div>

                            <notes
                                [isValidForm]="this.IncidentForm.invalid"
                                (addNoteClick)="executeIncidentCreateUpdate('Notes')"
                                #notes
                            >

                            </notes>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        (addNoteClick)="notes.AddNote()"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeIncidentCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>


<div class="box-header pageheader ">
    <div class="col-sm-12">
        
        <div class="col-sm-6 text-left"><h3>{{title}}</h3></div>
        <div class="col-sm-6 text-right">
                <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
            <button type="button" class="btn btn-danger"  style="margin-left: 10px" (click)="dialogRef.close(false)">Close Page</button>

        </div>
    </div>
</div>

  <div class="box-body" style="padding-bottom: 100px;">
        <ng-container *ngFor="let item of ErrorDetail;">
    <div class="box-header with-border">
        <h3 class="box-title">Error Code Details </h3>
    </div>
    <div class="box-body">
        <div class="row">
    
                                
            <div class="col-sm-12 box-row">
        <div class="col-sm-1">
                Error Code
            </div>
            <div class="col-sm-11">
                    {{item.errorCode}}
                </div>
            </div>
            <div class="col-sm-12 box-row">
                    <div class="col-sm-1">
                            Description
                        </div>
                        <div class="col-sm-11">
                            {{item.errorCodeDescription}}
                            </div>
                        </div>
            </div>
    </div>
    <div class="box-header with-border">
            <h3 class="box-title">Troubleshooting Steps  </h3>
        </div>
        <div class="box-body">
        <ng-container *ngFor="let step of item.errorCodeTroubleShottingSteps">
    

        
                <div class="row">
            
                                        
                    <div class="col-sm-12 box-row">
                <div class="col-sm-1">
                  <b>Step {{step.stepno}}</b>
                    </div>
                    <div class="col-sm-1">
                            <select [(ngModel)]="step.tempresult" [attr.disabled]="(step.result || completed) ? '' : null">
                                    <option value="">Select</option>
                                    <option value="Pass">Pass</option>
                                    <option value="Failed">Failed</option>
                                    <option value="Calibrated">Calibrated</option>
                                    <option value="Replaced">Replaced</option>
                                
                                </select>
                        </div>
                    <div class="col-sm-10">
                            {{step.stepsDetail}}
                        </div>
                    </div>
                    
            </div>

        </ng-container>
    </div>
 </ng-container>
  <div class="modal-footer"> 
        <button type="button" class="btn btn-primary"  style="margin-left: 10px" (click)="save()">Save</button>
    <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close Page</button>
  </div>

  <div [formGroup]="unInstallationForm" *ngIf="unInstallationForm" novalidate  style="border:1px solid lightgray;padding: 10px;">
      <!-- <div class="modal-header  col-sm-12" >
        <h4 class="modal-title col-sm-8">Uninstallation Details</h4>

        <div class="text-right  col-sm-4" style="padding:0">
          <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Cancel</button>
          <button type="button" class="btn btn-primary"  (click)="saveUninstallation()">Save</button>
        </div>
      </div> -->

  <!-- <div class="modal-body" style="position: static;"> -->

      <div class="box-row marginBottom clearfix">
      <div class="box-row col-sm-12"  style="padding-top: 20px;">
        <div class="col-sm-4"><strong>DM & RO Returned</strong></div>
      </div>
      <div class="clearfix"></div>
      <!-- <div class="box-row marginBottom clearfix"> -->
        <div class="col-sm-offset-2 col-sm-10 box-row">
          <div class="col-sm-4">
            <label class="col-sm-5 control-label">Home DM:</label>

            <div class="col-sm-6">
              <label class="readOnlyText">{{fif.dmEquipment.value}}</label>
            </div>
          </div>

          <div class="col-sm-4">
            <label class="col-sm-5 control-label">Home RO:</label>

            <div class="col-sm-6">
              <label class="readOnlyText">{{fif.roEquipment.value}}</label>
            </div>
          </div>
        </div>
        <!-- <div class="col-sm-12 box-row" style="margin-top: 5px; ">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Plumber:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poPlumber" name="poPlumber">
                  <span
                  *ngIf="fif.poPlumber.errors?.required && (fif.poPlumber.dirty || fif.poPlumber.touched ||  formSubmitted)"
                  [ngClass]="'error'">Required!</span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poPlumber">{{fif.poPlumber.value}}</label>
                </div>
          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20"  formControlName="poPlumberNote" name="poPlumberNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poPlumberNote">{{fif.poPlumberNote.value}}</label>
          </div>

      </div>
      <div class="col-sm-12 box-row">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Electrician:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poElectrician" name="poElectrician">
                  <span
                  *ngIf="fif.poElectrician.errors?.required && (fif.poElectrician.dirty || fif.poElectrician.touched ||  formSubmitted)"
                  [ngClass]="'error'">Required!</span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poElectrician">{{fif.poElectrician.value}}</label>
                </div>
          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20" formControlName="poElectricianNote" name="poElectricianNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poElectricianNote">{{fif.poElectricianNote.value}}</label>
          </div>
      </div>
      <div class="col-sm-12 box-row">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Movers:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poMovers" name="poMovers">
                  <span
                  *ngIf="fif.poMovers.errors?.required && (fif.poMovers.dirty || fif.poMovers.touched ||  formSubmitted)"
                  [ngClass]="'error'">Required!</span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poMovers">{{fif.poMovers.value}}</label>
              </div>
          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20" formControlName="poMoversNote" name="poMoversNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poMoversNote">{{fif.poMoversNote.value}}</label>
          </div>
      </div> -->
      <!-- </div> -->
    <!-- </div> -->

  </div>
</div>

import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoteDialog } from '../../NotesModel/NoteDialog';
import { PartsComponent } from '../PartsControl/PartsComponent';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'notes',
  templateUrl: './NotesComponent.html',
})
export class NotesComponent {
  noteDialogRef: MatDialogRef<NoteDialog>;
  public Note = '';
  public detailID = '';
  public Parts = [];
  @Input() showHours = true;
  @Output() addNoteClick = new EventEmitter<boolean>();
  @Output() CheckValidForm = new EventEmitter<boolean>();
  public NoteList = <any>[];
  public showParts = false;
  // tslint:disable-next-line:no-inferrable-types
  @Input() isValidForm: boolean = false;
  public API;
  public DivisionId;
  public EquipementId;

  public CurrentUserId;
  public partscategoryname;
  public status;
  constructor(public dialog: MatDialog) { }
  public NoteHeader = 'Notes';
  public PlusNoteHeader = 'Notes';
  public disableControl =false;
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
  }

  // Notes
  getNoteHour(notes) {
    const x = notes.split('|');
    if (x != undefined && x.length > 1) {
      if (x[x.length - 1] == undefined) {
        x[x.length - 1] = '';
      }
      return x[x.length - 1];
    }
    //console.log(x[1]);
    return "";
  }

  getNoteDesc(notes) {
    if (notes) {
      const x = notes.split('|');
      if (x != undefined && x.length != undefined && x.length <= 2) {
        return x[0].replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      else {
        var n = notes.lastIndexOf("|");
        notes = notes.substring(0, n);
        return notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
    }

    return '';
  }

  onNoteSave($event) {

    this.Note = $event;
    if (this.showParts) {
      this.Parts = this.noteDialogRef.componentInstance.NoteParts.PartTrans;
    }
    this.addNoteClick.emit();
    // this.executeWPDailyCreateUpdate('Notes');
  }

  public AddNote(prefix = ''){
    this.CheckValidForm.emit();
    setTimeout(() => {

      if (this.isValidForm === true) {
        this.addNoteClick.emit();
        return;
      }

      this.noteDialogRef = this.dialog.open(NoteDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        width: '45%',
        maxWidth: '120vh',
        maxHeight: '100vh',
        minWidth: '50vh',
      });

      this.noteDialogRef.componentInstance.NoteHeader = this.NoteHeader;
      this.noteDialogRef.componentInstance.showHours = this.showHours;
      this.noteDialogRef.componentInstance.showParts = this.showParts;

      this.noteDialogRef.componentInstance.SaveEvent.subscribe(evt =>
        this.onNoteSave(prefix + evt)
      );



      if (this.showParts === false) {
        // this.noteDialogRef.componentInstance.dialogRef.updateSize('700px', 'auto');
      } else {
        // this.noteDialogRef.componentInstance.dialogRef.updateSize('90%', 'auto');
        this.noteDialogRef.componentInstance.DivisionId = this.DivisionId;
        this.noteDialogRef.componentInstance.EquipementId = this.EquipementId;
        this.noteDialogRef.componentInstance.API = this.API;
        this.noteDialogRef.componentInstance.CurrentUserId = this.CurrentUserId;
        this.noteDialogRef.componentInstance.partscategoryname = this.partscategoryname;
        this.noteDialogRef.componentInstance.status = this.status;
        this.noteDialogRef.componentInstance.PrevParts = this.Parts;
      }
      this.noteDialogRef.afterClosed().subscribe(result => {
        if (result) {
        }
        this.noteDialogRef = null;
      });

    }, 500);

  }
}

import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartsComponent } from '../Shared/PartsControl/PartsComponent';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'note-dialog',
  templateUrl: './NoteDialog.html',
  styleUrls: ['./NoteDialog.css']
})
export class NoteDialog implements AfterViewInit {

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<NoteDialog>, public decimalpipe:DecimalPipe) {

    //dialogRef.updateSize('700px', 'auto');
  }

  public showHours = true;
  public showParts = false;
  public PrevParts = [];
  notes = '';
  hours = '0.00';
  errormsg = '';
  @ViewChild(PartsComponent, { static: false })
  NoteParts: PartsComponent;
  @Output() SaveEvent = new EventEmitter<string>();

  @ViewChild('noteId', { static: true }) inputEl: ElementRef;

  public API;
  public DivisionId;
  public EquipementId;
  public CurrentUserId;
  public partscategoryname;
  public status;
  public NoteHeader = 'Notes';
  ngOnInit() {
    // if (this.showParts === true) {
    //   this.dialogRef.updateSize('90%', 'auto');
    // }
  }

  ngAfterViewInit() {
    if (this.showParts === true) {
      this.NoteParts.API = this.API;
      this.NoteParts.SelectedDialysisUnits = this.DivisionId;
      this.NoteParts.SelectedEquipment = this.EquipementId;
      this.NoteParts.currentUserId = this.CurrentUserId;
      this.NoteParts.partscategoryname = this.partscategoryname;
      this.NoteParts.status = this.status;
      this.NoteParts.PrevParts = this.PrevParts;
    }

    setTimeout(() => {this.inputEl.nativeElement.focus()},500);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  PlusHours()
  {
    if(parseFloat(this.hours)<12)
    {
      this.hours = (parseFloat(this.hours.toString()) + 0.25).toString();

    this.hours = this.formatDecimal(this.hours.toString());
  }
}

  MinusHours()
  {
    if(parseFloat(this.hours)>0.0)
    {
      this.hours = (parseFloat(this.hours.toString()) - 0.25).toString();

    this.hours = this.formatDecimal(this.hours.toString());
  }
}

  formatDecimal(val) {
    //let val = this.f[ctl].value;
//     if (val.length > 0) {
//       if (val.indexOf('.') === -1) {
//         val = val + ".0" ;
//       }
//
//       if (val.substring(val.indexOf('.') + 1, val.length - val.indexOf('.') + 1).length  == 1) {
//         val = val + "0" ;
//       }
      return this.decimalpipe.transform(val, '1.2-2');
    // }
  }

  saveAndClose() {

    if (this.notes != '' || (this.showParts === true && this.NoteParts.PartTrans.length > 0)) {
        // this.matDialogRef = this.dialog.open(ConfirmationDialog, {
        //   disableClose: true
        // });
        // this.matDialogRef.componentInstance.confirmMessage =
        //   'You have unsaved changes! If you leave, your changes will be lost.';
        // this.matDialogRef.afterClosed().subscribe(result => {

        //     if(result)
        //       this.dialogRef.close(false);
        //     else
              this.save();
          // });
      }
     else {
      this.dialogRef.close(false);
    }
  }

  save() {

    this.errormsg = '';
    // if (this.showHours === true && parseFloat(this.hours) === 0) {
    //   this.errormsg = 'Hours Required';
    //   return false;
    // }
    // if (this.showHours === true && this.hours.match(/^(\d*)(\.\d{1,2})?$/) == null) {
  // if (this.showHours === true) {
  //   this.errormsg = '(##.##) Format';
  //     return false;
  //   }
    if (this.notes === '') {
      this.errormsg = 'Note Required';
      return false;
    }

    if (this.showParts === true) {
      if (this.NoteParts.PartSearchTerm != '') {
        this.errormsg = 'Please add selected parts';
        return false;
      }
    }
    if (this.showHours === true) {
      this.SaveEvent.emit(this.notes + '|' + this.hours);
    } else {
      this.SaveEvent.emit(this.notes);
    }
    this.dialogRef.close(true);
  }
  public confirmMessage: string;
}

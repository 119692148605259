import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { TrobleshootingDialog } from '../../Core/TrobleshootingDialog/TrobleshootingDialog';

import { DMRepairCustomValidators } from "../../Validator/EuipmentRepairStatus";
import { InvApiService } from '../../API/invapi.service';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
// import printJS from 'print-js';
import { HomeService } from 'src/app/API/home.service';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { BarcodeScannerComponent } from 'src/app/Core/Barcode/barcode-scanner/barcode-scanner.component';

@Component({
  selector: 'FilterDetail',
  templateUrl: './FilterDetail.component.html'
})
export class FilterDetailComponent implements OnInit, CanComponentDeactivate {
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  userDesignation = this.auth.GetDesignationName();

  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  quickBarcodeDialogRef: MatDialogRef<BarcodeScannerComponent>;
  troubleShootingDialogRef: MatDialogRef<TrobleshootingDialog>;
  isValidFormSubmitted = null;
  ListURL = '/DialysisMachine/FilterSearch';
  status = '';
  loading = true;
  DMFiltersIdvar = '0';
  EquipmentSerialNo: '';
  EquipmentInServiceDate: '';
  DialysisUnits = [];
  Equipments = [];
  DialysisStation = [];
  DMRepailDetail = [];
  eventId = '[AutoNumber]';
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  selectedDialysisUnitsobj;
  selectedDialysisUnitstext;
  selectedUsertext;
  selectedEquipmenttext;
  selectedDialysisStationtext;
  TroubleShootingTimeText;
  StatusText;
  blnshowSave = false;
  blnshowDelete = false;
  filterStartDateLabel;
  selectedEquipmentHours = 0;
  public hrsTracking = true;
  //
  FilterListAll = [
    { value: '', text: 'Select', model: '' },
    { value: '5008201', text: 'DIASAFEPlus (F04, F05)', model: '5008', quantity: 2 },
    { value: '500420D', text: 'DIASAFE FILTER 12 EA/CS', model: '2008', quantity: 2 }
  ];
  FilterList = [];

  // DisinfectionList = [
  //   { value: '', text: 'Select' },
  //   { value: 'Heat', text: 'Heat' },
  //   { value: 'Full Chemical', text: 'Full Chemical' }
  // ];

  DisinfectionList = [
      { value: '', text: 'Select' },
      { value: 'Degreasing', text: 'Degreasing' },
      { value: 'Heat Cleaned', text: 'Heat Cleaned' },
      { value: 'N/A', text: 'N/A'}
    ];

  FilterRecord;

  FilterForm = new UntypedFormGroup({

    dmFiltersId: new UntypedFormControl(''),
    FilterDate: new UntypedFormControl('', Validators.required),
    FormDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedDialysisStation: new UntypedFormControl(''),
    DMHours: new UntypedFormControl("", Validators.required),
    selectedEquipment: new UntypedFormControl('', Validators.required),
    Filter: new UntypedFormControl('', Validators.required),
    FilterLotNumber: new UntypedFormControl('', Validators.required),
    FilterSecondLotNumber: new UntypedFormControl('', Validators.required),
    Disinfection: new UntypedFormControl('', Validators.required),
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (
      (this.FilterForm.dirty ||
        this.notes.Note !== '' ||
        this.attachment.Queue().length > 0) && (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          //  console.log('res', res);
          return res;
        })
      );
    } else { return true; }
  }


  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private invapi: InvApiService,
    private homeApi: HomeService,
    private commonService: CommonApiService
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();

    this.fillFilterPartNames();
    // this.uploader.autoUpload = true;
    this.notes.showHours = true;
    // attachment changes
    this.attachment.DocId = this.f.dmFiltersId.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Filter];
    this.attachment.UploadBy = this.currentUserId;

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.

        this.fillDialysisUnit();
        this.f.dmFiltersId.setValue(params['id'] || '0');

        if (this.f.dmFiltersId.value !== '0') {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {

            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillEquipment(division[0]);
              this.fillDialysisStation(division[0]);
            }
            else {
              this.fillEquipment(0);
              this.fillDialysisStation(0);
            }
            this.selectedUsertext = this.auth.GetUserFLName();

            this.FilterList = [{ value: '', text: 'Select' }];
            this.f.Filter.setValue('');

            let tempDate = this.today;
            this.f.FormDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });

            const tempFilterDate = this.today;
            this.f.FilterDate.setValue({
              date: {
                year: tempFilterDate.getFullYear(),
                month: tempFilterDate.getMonth() + 1,
                day: tempFilterDate.getDate()
              },
              jsdate: tempFilterDate
            });

            if (this.f.dmFiltersId.value == 0) {

              this.f.selectedEquipment.setValidators([Validators.required]);
              this.f.selectedEquipment.setAsyncValidators([
                DMRepairCustomValidators.EquipmentRepairStatusValidate(
                  this.f.dmFiltersId,
                  this.dmapi
                )
              ]);
              this.f.selectedEquipment.updateValueAndValidity();
            }

          }, error => {
            AppComponent.HideLoader();
          });
        }

        // this.f.DMHours.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

        // this.f.DMHours.setAsyncValidators([
        //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
        //     this.f.selectedEquipment,
        //     this.f.dmFiltersId,
        //     this.dmapi
        //   )
        // ]);
        // this.f.DMHours.updateValueAndValidity();

        this.setHoursValidation();

      });

      this.SetPageRights();

      this.setDateOptions();
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }


  get f() {
    return this.FilterForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const FilterCreate = { Id: this.f.dmFiltersId.value };
    this.dmapi.GetFilterDMRepairDetailsByID(FilterCreate).subscribe(
      success => {
        // console.log(success);
        this.f.dmFiltersId.setValue(success.filterChangeId);
        this.eventId = success.eventCode;
        this.notes.Note = '';
        this.attachment.clearQueue();

        this.filterStartDateLabel = success.eventDate;

        const tempFilterDates = new Date(success.eventDate);
        this.f.FilterDate.setValue({
          date: {
            year: tempFilterDates.getFullYear(),
            month: tempFilterDates.getMonth() + 1,
            day: tempFilterDates.getDate()
          },
          jsdate: success.eventDate
        });

        const tempDate = new Date(success.createdOn);
        this.f.FormDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdOn
        });

        this.f.selectedDialysisUnits.setValue(success.divisionId);
        this.f.selectedDialysisStation.setValue(success.stationId);
        this.f.selectedEquipment.setValue(success.equipmentId);
        this.selectedEquipmenttext = success.equipmentName;
        this.selectedDialysisStationtext = success.dialysisStationName;
        // this.status = success.status;
        this.selectedUsertext = success.createByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;

        this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
        this.selectedDialysisUnitstext = success.divisionName;

        this.f.selectedEquipment.setValue(success.equipmentId.toString());

        this.f.DMHours.setValue(success.dialysisMachineHours);

        if (success.dialysisMachineHours == '0.0') {
          this.hrsTracking = false;
        }
        else {
          this.hrsTracking = true;
        }


        this.f.Filter.setValue(success.filter);

        var dmrepairCreate = { Id: this.f.dmFiltersId.value };
        this.dmapi.GetDmFilterChangPartsDetailsbyFilterChangeDetailid(dmrepairCreate).subscribe(success => {
          if (success && success[0]) {
            this.FilterRecord = success[0];
            this.FilterRecord.equipmentCode = this.FilterRecord.equipmentcode;
          }
          //console.log(success[0]);
        });
        this.f.FilterLotNumber.setValue(success.filterLotNumber);
        this.f.FilterSecondLotNumber.setValue(success.filterSecondLotNumber);
        this.f.Disinfection.setValue(success.equipmentOUTDisinfection);

        // this.DialysisUnitDropDownChanged();
        const FilterObj = { Id: this.f.dmFiltersId.value };
        this.dmapi
          .GetFilterAttachmentDetailsbydmFilterDetailid(FilterObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });

        this.dmapi.GetFilterNotesbydmFilterDetailid(FilterObj).subscribe(list => {
          this.notes.NoteList = list;

        });

        // this.selectedDialysisStationtext = success.stationName;
        this.selectedEquipmenttext = success.equipmentName;

        if(success.stationId)
          this.f.selectedDialysisStation.setValue(success.stationId.toString());
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void { }

  fillDialysisUnit() {
    this.commonService.FillDivision().subscribe(list => {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DialysisUnits = list.filter(
      //   x => x.text != "Home Program"
      // );
      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }

      this.DialysisUnits = list;

      if (this.f.dmFiltersId.value !== '0') {
        this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
          x => x.value === this.f.selectedDialysisUnits.toString()
        );
        // this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
      }
    });
  }

  fillEquipment(divisionID) {
    if (divisionID !== 0) {

      this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
        x => x.value === divisionID
      );

      if (this.selectedDialysisUnitsobj.length > 0 && this.selectedDialysisUnitsobj[0].text == 'Home Program') {
        const obj = {
          DialysisStationId: divisionID,
          CategoryName: 'Dialysis Machines'
        };
        this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
          list.unshift({ text: 'Select', value: '' });
          this.Equipments = list;
        });
      }
      else {
        this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
          this.Equipments = list;
        });
      }
    } else {
      this.Equipments = [{ value: '', text: 'Select' }];
      this.f.selectedEquipment.setValue('');
    }
    this.equipmentChanged();
  }

  fillDialysisStation(divisionID) {
    if (divisionID !== 0) {
      this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
        this.DialysisStation = list;
      });
    } else {
      this.DialysisStation = [{ value: '', text: 'Select' }];
      this.f.selectedDialysisStation.setValue('');
    }
  }

  executeFilterCreateUpdate = function (Source) {
    AppComponent.loading = true;
    // this.f.hoursout.updateValueAndValidity();
    // this.f.selectedOutsurfacedis.updateValueAndValidity();
    // this.f.selectedouthydraulicdis.updateValueAndValidity();

    this.isValidFormSubmitted = false;
    if (this.FilterForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const FilterCreate = this.prepareCreateViewModel();

    if (FilterCreate.filterchangeid === '0') {
      this.dmapi.CreateDMFilterDetails(FilterCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.f.dmFiltersId.setValue(success.filterchangeid);
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.dmapi.UpdateDMFilterDetails(FilterCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);

          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    let FilterCreate = <any>{};

    FilterCreate.filterchangeid = this.f.dmFiltersId.value;
    FilterCreate.eventcode = this.eventId;
    if (this.f.FilterDate.value !== null && this.f.FilterDate.value !== undefined) {
      FilterCreate.eventDate = Globals.GetAPIDate(this.f.FilterDate.value.jsdate);
    }

    FilterCreate.createdon = Globals.GetAPIDate(this.today);
    FilterCreate.createdby = this.currentUserId;

    FilterCreate.modifiedon = Globals.GetAPIDate(this.today);
    FilterCreate.modifiedby = this.currentUserId;
    FilterCreate.dialysismachinehours = this.f.DMHours.value;
    // FilterCreate.status = this.f.FormDesignation.value;
    FilterCreate.divisionid = this.f.selectedDialysisUnits.value;
    FilterCreate.equipmentid = this.f.selectedEquipment.value;
    FilterCreate.stationid = this.f.selectedDialysisStation.value;

    let filterSelected = this.FilterListAll.filter(
      (item, index) => item.value === this.f.Filter.value
    );

    if (filterSelected.length > 0) {
      FilterCreate.filter = filterSelected[0].text;
    }
    else {
      FilterCreate.filter = this.f.Filter.value;
    }
    FilterCreate.filterlotnumber = this.f.FilterLotNumber.value;
    FilterCreate.filtersecondlotnumber = this.f.FilterSecondLotNumber.value;
    FilterCreate.equipmentoutdisinfection = this.f.Disinfection.value;
    FilterCreate.note = this.notes.Note;
    // attachments
    FilterCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    this.FilterRecord.equipmentId = this.FilterRecord.equipementId;
    FilterCreate.dmfilterChangePartsDetail = [this.FilterRecord];
    // console.log(FilterCreate);
    return FilterCreate;
  }

  DialysisUnitDropDownChanged() {

    this.f.selectedEquipment.setValue('');
    this.fillEquipment(this.f.selectedDialysisUnits.value);
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    this.f.DMHours.setValue("");
    // this.selectedEquipmentHours = 0;
  }

  GetequipmentCodeDropDownChanged() {
    var dmrepairCreate = { str: this.f.Filter.value };
    this.dmapi.GetFilterRecordByequipmentCode(dmrepairCreate).subscribe(success => {
      this.FilterRecord = success[0];
      this.FilterRecord.quantity = 2;
      //console.log(success[0]);
    });

  }

  EquipmentDropDownChanged() {
    if (this.f.dmFiltersId.value === '0') {
      const FilterCreate = { Id: this.f.selectedEquipment.value };
      this.dmapi.GetEquipmentDetailsByID(FilterCreate).subscribe(success => {
        this.EquipmentSerialNo = success.serialnumber;
        this.EquipmentInServiceDate = success.inservicedate;
        this.selectedEquipmenttext = success.equipmentcode;
      });
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const FilterObj = { Id: this.f.dmFiltersId.value };
        this.dmapi.DeleteDMFilterDetailsByID(FilterObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'DM Filter detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetPageRights() {
    this.auth.GetPageRights('Filter Exchange').subscribe(list => {
      if (this.f.dmFiltersId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.dmFiltersId.value != '0') {
      this.blnshowDelete = true;
    }
  }
  AttachmentError(Error) {

    AppComponent.addAlert(Error, 'error');
  }
  // GreaterValueCheck(){
  //   this.f.hoursout.updateValueAndValidity();
  //   this.f.selectedOutsurfacedis.updateValueAndValidity();
  //   this.f.selectedouthydraulicdis.updateValueAndValidity();

  // }

  equipmentChanged() {
    this.f.DMHours.setValue('');
    if (this.f.selectedEquipment.value) {
      var dmrepairCreate = { Id: this.f.selectedEquipment.value };
      this.dmapi
        .GetEquipmentDetailsByID(dmrepairCreate)
        .subscribe(success => {

          if (this.f.dmFiltersId.value == '0') {
            this.f.Filter.setValue('');
          }

          if (success && success.model) {
            this.FilterList = this.FilterListAll.filter(
              (item, index) => item.model === success.model.substring(0, 4) || item.model === ''
            );
          }

          this.hrsTracking = success.hrsTracking;
          this.setHoursValidation(this.hrsTracking);
        });

      var equipmentId = { Id: this.f.selectedEquipment.value };
      this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
        if (result) {
          this.selectedEquipmentHours = result;
        } else {
          this.selectedEquipmentHours = 0;
        }
      });
    }
    else {
      this.FilterList = [{ value: '', text: 'Select' }];
      this.f.Filter.setValue('');
      this.selectedEquipmentHours = 0;
    }

    this.FilterRecord = null;
  }

  fillFilterPartNames() {
    // this.commonService.GetEquipmentsByCodeAndType({ code: '500', type: 'PARTS' }).subscribe(list => {
    //
    //   this.FilterListAll.forEach(function (part, newKey) {
    //     let filterPart = list.filter(
    //       (item, index) => item.equipmentCode === part.value
    //     );

    //     if (filterPart.length > 0) {
    //       part.text = filterPart[0].equipmentName;
    //     }
    //   });

    this.invapi.GetInventoryFilterDetails().subscribe(list => {

      this.FilterListAll.forEach(function (part, newKey) {
        let filterPart = list.filter(
          (item, index) => item.model.substring(0, 4) === part.model
        );

        if (filterPart.length > 0) {
          part.text = filterPart[0].equipmentName;
          part.value = filterPart[0].equipmentCode;
        }
      });
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  check4000HoursValidation(): boolean {
    if (this.f.DMHours.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }

  setHoursValidation(enable = true) {
    if (enable && (this.f.dmFiltersId.value == '' || this.f.dmFiltersId.value == '0')) {
      this.f.DMHours.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);

      this.f.DMHours.setAsyncValidators([
        DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
          this.f.selectedEquipment,
          this.f.dmFiltersId,
          this.dmapi
        )
      ]);
      this.f.DMHours.updateValueAndValidity();
    }
    else {
      this.f.DMHours.clearAsyncValidators();
      this.f.DMHours.clearValidators();
      this.f.DMHours.updateValueAndValidity();
    }

  }

  copyLotNumber() {

    if (this.f.FilterSecondLotNumber.value == '') {
      this.f.FilterSecondLotNumber.setValue(this.f.FilterLotNumber.value);
    }
  }

  openBarcodeScanner()
  {
    this.f.FilterLotNumber.setValue('');
    this.quickBarcodeDialogRef = this.dialog.open(BarcodeScannerComponent, {
      disableClose: true,
      height: '90%',
      width: '90%',
    });
    this.quickBarcodeDialogRef.afterClosed().subscribe(result => {
    });

    this.quickBarcodeDialogRef.componentInstance.SaveEvent.subscribe(opt => {

      this.f.FilterLotNumber.setValue(opt);
    });
  }
}

<div [formGroup]="vehicleSectionForm" novalidate>
<div class="modal-header  col-sm-12">
  <h4 class="modal-title col-sm-8">Vehicle Details</h4>

  <div class="text-right  col-sm-4" style="padding:0">
    <!-- <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Close</button>
    <button type="button" class="btn btn-primary"  (click)="save()">Save</button> -->
    <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>
  </div>
</div>

<div class="modal-body">
    <div *ngIf="errormsg" class="box-row col-sm-12">
        <span [ngClass]="'error'">
          {{errormsg}}
        </span>
      </div>
  <div class="col-sm-12 box-row">
    <div class="col-sm-4">
    <label class="col-sm-5   control-label">Date:</label>
    <div class="col-sm-6">
      <label class="readOnlyText">{{today | date:'dd/MM/yyyy'}}</label>
    </div>
  </div>
  <div class="col-sm-4">
    <label class="col-sm-5 control-label">Technologist:</label>
    <div class="col-sm-6">
      <label class="readOnlyText"> {{UserName}}</label>
    </div>
  </div>
</div>

<div class="col-sm-12 box-row">
  <div class="col-sm-4">
  <label class="col-sm-5 control-label">Vehicle:</label>
  <div class="col-sm-6">
    <select class="form-control mdInput" formControlName="HFServiceVehicleId" name="HFServiceVehicleId" (change)="VehicleDropDownChanged()">
    <option value="">Select</option>
    <option value="{{item.equipementid}}" *ngFor="let item of VehicleList">
      {{item.equipmentcode}}
    </option>
  </select>
  <span
  *ngIf="vf.HFServiceVehicleId.errors?.required && (vf.HFServiceVehicleId.dirty || vf.HFServiceVehicleId.touched ||  isValidFormSubmitted)"
  [ngClass]="'error'">Required!</span>
  <span
  *ngIf="vf.HFServiceVehicleId.errors?.IsVehicleAvailable"
  [ngClass]="'error'">
  Vehicle is being used by other Home Field service.
</span>
  </div>
  <div style="padding: 0.5em;" class="col-sm-1">
    <button style=" display: table;margin: 0;" class="btn btn-primary center-block btn-xs"
            (click)="OpenVehicleHistoryList()" *ngIf="vf.HFServiceVehicleId.value && vf.HFServiceVehicleName.value != 'Other'">
            <span><i class="fa fa-history"></i></span>
    </button>
  </div>
</div>
<div class="col-sm-4">

    <label class="col-sm-5 control-label">Start KM:</label>
    <div class="col-sm-5">
      <input formControlName="HFServiceVehicleStartedKM" name="HFServiceVehicleStartedKM"
            class="form-control smInput" (keypress)="numberOnly($event)" maxlength="7">
            <span
            *ngIf="vf.HFServiceVehicleStartedKM.errors?.required && (vf.HFServiceVehicleStartedKM.dirty || vf.HFServiceVehicleStartedKM.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'">Required!</span>
    </div>
    <div class="row col-sm-2">
    <label class="row readOnlyText" style="color: gray;"
    *ngIf="vf.HFServiceVehicleName.value!='Other' && selectedVehiclelastKms!=0 && selectedVehiclelastKms!=null">({{selectedVehiclelastKms}})</label>
    <label class="row readOnlyText" style="color: gray;"
    *ngIf="selectedVehiclelastKms==0 && (vf.HFServiceVehicleName.value!='Other' || vf.HFServiceVehicleName.value!='')">(N/A)</label>
  </div>
</div>
<div class="col-sm-4">

    <label class="col-sm-6 control-label">Start Time:</label>
    <div class="col-sm-6">
      <input formControlName="HFServiceVehicleStartTime" Name="HFServiceVehicleStartTime"
      placeholder="Time" class="form-control smInput" style="max-width: 90px !important"
      [max]="getCurrentTime()" [min]="getToday()"
      [owlDateTimeTrigger]="VehicleStartTime" [owlDateTime]="VehicleStartTime"/>

      <!-- [ngxTimepicker]="VehicleStartTime" [format]="24" -->
    <!-- <ngx-material-timepicker #VehicleStartTime></ngx-material-timepicker> -->

    <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(vf.HFServiceVehicleStartTime.value)" #VehicleStartTime></owl-date-time>

    <!-- <input [owlDateTimeTrigger]="dt4" class="form-control smInput" style="max-width: 90px !important"s [owlDateTime]="dt4">
    <owl-date-time [pickerType]="'timer'" [hour12Timer]="false" #dt4></owl-date-time> -->

    <div class="col-sm-1" style="padding: 0.5em 0;float:right;" *ngIf="vf.HFServiceReturnVehicleEndTime.value">
        <button title="> GR Return Time: {{vf.HFServiceReturnVehicleEndTime.value | date:'dd/MM/yyyy HH:mm'}}" class="btn btn-primary center-block btn-xs" style=" display: table;margin: 0 auto;">
          <span>
            <i class="fa fa-exclamation-circle"></i>
          </span>
        </button>
      </div>
    <span
    *ngIf="vf.HFServiceVehicleStartTime.errors?.required && (vf.HFServiceVehicleStartTime.dirty || vf.HFServiceVehicleStartTime.touched ||  isValidFormSubmitted)"
    [ngClass]="'error'">Required!</span>
    <span
    *ngIf="vf.HFServiceVehicleStartTime.errors?.GreaterDate"
    [ngClass]="'error'"><br>  Start Time > Last Return Time</span>
    </div>


</div>
<div class="col-sm-offset-5 col-sm-6">
    <span class="col-sm-5 text-center"
                                  *ngIf='vf.HFServiceVehicleStartedKM.errors?.error || vf.HFServiceVehicleStartedKM.errors?.IsKmsIsGreaterThanPreviousKms'
                                  [ngClass]="'error'">
                                  Start KMs => Prev. End KMs </span>
</div>
</div>

<div class="col-sm-12 box-row">
</div>

<div class="col-sm-12 box-row" style="display: none;">
    <label class="col-sm-1 control-label" style="width: 11.5%">Note:</label>
    <div class="col-sm-8">
      <textarea name="note" autosize formControlName="HFServiceVehicleInspectionNotes" name="HFServiceVehicleInspectionNotes" class="form-textarea"
      style="min-height: 75px;"></textarea>
    </div>
</div>
<div class="box-row col-sm-12">

  <mat-expansion-panel style="overflow: auto" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Vehicle Inspection
        <span
          *ngIf="vf.HFServiceVehicleInspectionStatusSection.errors?.required && (vf.HFServiceVehicleInspectionStatusSection.dirty || vf.HFServiceVehicleInspectionStatusSection.touched || isValidFormSubmitted)"
          [ngClass]="'error'">Required!</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="box-body">
      <div class="row col-sm-12">
        <table style="width:100%" align="center" class="table table-responsive">
          <!-- <tr>
            <th class="thead-light" width="100%">
              Vehicle Inspection
            </th>
          </tr> -->
          <tbody>
            <tr>
              <td class="mrmDescription" width="100%">
                <b>Vehicle.</b><br>
                Conduct vehicle circle check - Note any vehicle damage, leaks under vehicle or
                obvious maintenance issues.
                Check for proper functioning of headlights, brake lights, 4-way flashing lights
                and signal lights.
              </td>
              <!-- <td><mat-radio-button width="5%" value="Pass" name="rdvehicle" color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width="5%" value="Fail" name="rdvehicle" color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width="5%" value="See Notes" name="rdvehicle" color="primary"></mat-radio-button></td> -->
            </tr>

            <tr>
              <td class='mrmDescription' width='85%'> <b>Tires.</b><br>Check tires for wear and
                verify proper tire pressure.</td>
              <!-- <td><mat-radio-button width='5%' value='Pass' name='rdtires' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='Fail' name='rdtires' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='See Notes' name='rdtires' color="primary"></mat-radio-button></td> -->
            </tr>

            <tr>
              <td class='mrmDescription' width='85%'> <b>Load restraint. </b><br>Ensure all
                items are securely stored and/or strapped down in vehicle. </td>
              <!-- <td><mat-radio-button width='5%' value='Pass' name='rdload' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='Fail' name='rdload' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='See Notes' name='rdload' color="primary"></mat-radio-button></td> -->
            </tr>

            <tr>
              <td class='mrmDescription' width='85%'> <b>Safety Belts. </b><br>Examine for wear
                and proper functioning.</td>
              <!-- <td><mat-radio-button width='5%' value='Pass' name='rdsafetybelts' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='Fail' name='rdsafetybelts' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='See Notes' name='rdsafetybelts' color="primary"></mat-radio-button></td> -->
            </tr>

            <tr>
              <td class='mrmDescription' width='85%'> <b>Fuel. </b><br>Verify enough fuel for
                trip plus reserves. </td>
              <!-- <td><mat-radio-button width='5%' value='Pass' name='rdfuel' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='Fail' name='rdfuel' color="primary"></mat-radio-button></td>
                              <td><mat-radio-button width='5%' value='See Notes' name='rdfuel' color="primary"></mat-radio-button></td> -->
            </tr>

            <tr>
              <td class='mrmDescription' width='85%'> <b>Engine operation.</b><br>Note any
                unusual engine noise. </td>
            </tr>

            <tr>
              <td class='mrmDescription' colspan="4"></td>
            </tr>

          </tbody>

        </table>
      </div>
      <div class="row col-sm-12">
        <table style="width:50%" align="center" class="table table-responsive">
          <tr>
            <th class="thead-light">All Passed</th>
            <th class="thead-light">Failures Noted</th>
          </tr>
          <tbody>
            <tr>
              <mat-radio-group formControlName="HFServiceVehicleInspectionStatusSection"
                name="HFServiceVehicleInspectionStatusSection" style="    display: contents;"
                id="HFServiceVehicleInspectionStatusSection">
                <td>
                  <mat-radio-button width='50%' value='true' name="rdVehicle2" color="primary">
                  </mat-radio-button>
                </td>

                <td>
                  <mat-radio-button width='50%' value='false' name="rdVehicle2" color="primary"
                    (change)="SectionSendVehicleNotesMailToSupervisor()">
                  </mat-radio-button>
                </td>
              </mat-radio-group>

            </tr>
            <tr
              *ngIf="vf.HFServiceVehicleInspectionStatusSection.errors?.required && (vf.HFServiceVehicleInspectionStatusSection.dirty || vf.HFServiceVehicleInspectionStatusSection.touched ||  isValidFormSubmitted)">
              <td colspan="2"> <span [ngClass]="'error'">Required!</span></td>
            </tr>
          </tbody>

        </table>

      </div>
    </div>
  </mat-expansion-panel>

</div>

</div>

</div>

<div class="modal-header">
    <h4 class="modal-title">{{notesDetails.questionId ? notesDetails.questionId + ' - ' : ''}} Notes & Parts</h4>
    <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="    overflow: auto;
overflow-x: hidden;
max-height: 450px;">
    <!-- <p>
    <b *ngIf="notesDetails.showHours!=false">Estimated time performing the following :
      <input  maxlength="3" style="width:75px;display: inline;" class="form-control" [(ngModel)]="notesDetails.hours" name="hours" (keypress)="numberOnly($event)"/> (hrs) </b>
        <span class="error">{{errormsg}}</span>
  </p> -->
    <div class="box-row col-sm-12">
        <!-- *ngIf="notesDetails.showHours==true"> -->
        <!-- <label class="col-sm-8" style="margin-top: 6px;">Estimated time (Hours):</label>
        <div class="input-group col-sm-3">
            <span class="input-group-btn">
                <button type="button" class="quantity-left-minus btn btn-danger btn-number" (click)="MinusHours()"   data-type="minus" data-field="">
                  <span class="glyphicon glyphicon-minus"></span>
            </button>
            </span>
            <input style="display: inline;text-align: center" class="form-control" [(ngModel)]="notesDetails.hours" name="hours" maxlength="3" (keypress)="numberOnly($event)" disabled="disabled" />
            <span class="input-group-btn">
                <button type="button" class="quantity-right-plus btn btn-success btn-number" (click)="PlusHours()" data-type="plus" data-field="">
                    <span class="glyphicon glyphicon-plus"></span>
            </button>
            </span>
        </div> -->
        <span class="error">{{errormsg}}</span>
    </div>
    <p>
        <p>
            <textarea name="Note" autosize [(ngModel)]="notesDetails.description" class="form-textarea" style="max-width: 100% !important;"></textarea>
        </p>


        <div class="box-body">
            <!-- <table width="100%" style="width:100%;table-layout: fixed;" id="noteTable">
        <tbody>
          <tr *ngFor="let item of componentDetails.PrevNotes;let index=index"
          [ngClass]="{'afterComplete': item.isAfterCompletionAdd}"
          >
            <td align="left" width="9%" valign="top">
              {{item.createdOn | date:'dd/MM/yyyy'}}
            </td>

            <td align="left" width="6%" valign="top">
              {{item.createdOn | date:'HH:mm'}}
            </td>

            <td align="center" width="6%" valign="top" class="text-center" *ngIf="notesDetails.showHours!=false">
              <div *ngIf="item.hours">
                {{item.hours | number:'2.2'}} hr
              </div>
            </td>

            <td align="center" width="10%" valign="top">
              {{item.createdByName}}
            </td>

            <td align="left" width="auto">
              <div style="word-wrap: break-word;margin-bottom:0px;" [innerHTML]="item.description">
              </div>
            </td>
          </tr>

        </tbody>
      </table> -->
            <!-- <div *ngFor="let item of componentDetails.PrevNotes;let index=index"
      [ngClass]="{'afterComplete': item.isAfterCompletionAdd}" class="row"> -->
            <div *ngFor="let item of componentDetails.PrevNotes;let index=index" class="row">
                <div class="col-sm-12 box-row">
                    <!-- *ngIf="!componentDetails.locked || !item.isAutoPart"> -->
                    <div class="col-sm-2 text-center" *ngIf="item.createdOn">
                        {{item.createdOn | date:'dd/MM/yyyy HH:mm' }}

                    </div>
                    <div class="col-sm-1 text-center">
                        {{item.createdByName }}

                    </div>
                    <div class="col-sm-1 text-center" *ngIf="notesDetails.showHours!=false">
                        {{item.hours | number:'2.2'}}

                    </div>
                    <div class="col-sm-8 text-left" style="word-wrap: break-word;">
                        {{item.description}}

                    </div>
                </div>
            </div>
        </div>
        <!-- <PM_Parts_Component *ngIf="componentDetails.showParts===true" #NoteParts></PM_Parts_Component>
    <div class="row text-center">    <span class="error" *ngIf="NoteParts">{{NoteParts.noteparterrormsg}}</span></div>
</div> -->
        <PM_Parts_Component *ngIf="componentDetails.showParts===true" #NoteParts></PM_Parts_Component>
        <!--  <div class="row text-center">    <span class="error">{{parterrormsg}}</span></div>-->
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
</div>
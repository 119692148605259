import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from "../../app.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { DMApiService } from '../../API/dmapi.service';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { PartsComponent } from '../../Core/Shared/PartsControl/PartsComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { AuthService } from '../../API/auth.service';
import { DMRepairCustomValidators } from '../../Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from '../../Validator/CommonValidator';
import { CommonApiService } from 'src/app/API/commonapi.service.';

// import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
@Component({
  selector: 'MRMHistoryUploadDetail',
  templateUrl: './MRMHistoryUploadDetail.component.html'

})
export class MRMHistoryUploadDetailComponent implements OnInit {
  // @ViewChild(NotesComponent)
  // notes: NotesComponent;

  // @ViewChild(AttachmentComponent)
  // attachment: AttachmentComponent;

  // dialogRef: MatDialogRef<ConfirmationDialog>;
  private today = new Date();
  public hrsTracking = true;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  @ViewChild(PartsComponent, { static: false })
  parts: PartsComponent;
  eventId = '[AutoNumber]';
  DivisionList = [];
  EquipmentList = [];
  isValidFormSubmitted = null;
  ShowParts = true;
  Parts = [];
  status = '';
  blnshowSave = false;
  blnshowDelete = false;
  repairId = '[AutoNumber]';
  UserName = '';
  formDates = this.today;
  ListURL = 'DialysisMachine/MRMHistoryUploadSearch';
  HistoryTypeList = [
    { value: '', text: 'Select' },
    { value: 'PM', text: 'PM' },
    { value: 'Repair', text: 'Repair' }
  ];

  // hydraulicDisinfectionList = [
  //   { value: '', text: 'Select' },
  //   { value: 'Heat', text: 'Heat' },
  //   { value: 'Heat Citric', text: 'Heat Citric' },
  //   { value: 'Chemical', text: 'Chemical' },
  //   { value: 'No', text: 'No' }
  // ];
  hydraulicDisinfectionList = [
    { value: '', text: 'Select' },
    { value: 'Degreasing', text: 'Degreasing' },
    { value: 'Heat Cleaned', text: 'Heat Cleaned' },
    { value: 'N/A', text: 'N/A' }
  ];


  UploadHistoryForm = new UntypedFormGroup({
    UploadHistoryFormId: new UntypedFormControl('0'),
    formDate: new UntypedFormControl(''),
    formUser: new UntypedFormControl(''),
    eventId: new UntypedFormControl(''),
    HistoryType: new UntypedFormControl('', Validators.required),
    HistoryDate: new UntypedFormControl(''),
    divisionId: new UntypedFormControl('', Validators.required),
    equipmentId: new UntypedFormControl('', Validators.required),
    // hoursin: new FormControl('', Validators.required),
    remarks: new UntypedFormControl('', Validators.required),
    PartSearchTerm: new UntypedFormControl(''),
    PartDescription: new UntypedFormControl(''),
    PartLocation: new UntypedFormControl(''),
    PartQuantity: new UntypedFormControl(''),
    hoursout: new UntypedFormControl('', Validators.required),
    dateOut: new UntypedFormControl('', Validators.required),
    hydraulicDisinfection: new UntypedFormControl(''),
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect:false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  constructor(
    private dmapi: DMApiService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private commonapi: CommonApiService
  ) {}

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.formDates = this.today = new Date(currentDate);
      this.setDateOptions();
      this.SetPageRights();
      this.UserName = this.auth.GetUserFLName();
      this.fillDivision();

      this.setHoursOutValidation();


      // this.f.hoursin.setValidators([Validators.required,Validators.pattern(Globals.NumberNumericRegex)]);
      // this.f.hoursin.setAsyncValidators([
      //   DMRepairCustomValidators.EquipmentRepairHoursOutOfPreviousRepair(
      //     this.f.equipmentId,
      //     this.f.UploadHistoryFormId,
      //     this.dmapi
      //   )
      // ]);
      // this.f.hoursin.updateValueAndValidity();


      // this.f.hoursout.setValidators([,Validators.pattern(Globals.NumberNumericRegex),
      //   CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.dateOut
      //   ),
      //   CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
      //     this.f.hoursin
      //   )
      // ]);
      // this.f.hoursout.updateValueAndValidity();
      if (this.f.HistoryDate != undefined && this.f.HistoryDate != null) {
        this.f.dateOut.setValidators([Validators.required, CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          this.f.HistoryDate
        )]);
      }
      this.auth.GetCurrentUserDivision().subscribe(division => {

        if (division.length === 1) {
          this.f.divisionId.setValue(division[0]);
          this.fillEquipment(division[0]);
        }
        else {
          this.fillEquipment(0);
        }
        // this.fillEquipment(0);
        AppComponent.HideLoader();

      }, error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      });
    }, error => {
      AppComponent.HideLoader();
    });
  }

  setHoursOutValidation(){
    if(this.hrsTracking)
      this.f.hoursout.setValidators([Validators.required, Validators.pattern(Globals.NumberNumericRegexWithoutZero)]);
    else
      this.f.hoursout.setValidators([]);

    this.f.hoursout.updateValueAndValidity();
  }

  ngAfterViewInit() {
    // Parts
    //console.log(this.ShowParts);
    if (this.ShowParts == true) {
      this.parts.partscategoryname = 'Dialysis Machines';
      this.parts.currentUserId = this.currentUserId;
      this.parts.API = this.dmapi;
      this.parts.status = this.status;
      this.parts.showPartHearder = false;
    }
  }


  SetPageRights() {
    this.auth.GetPageRights('DM Repair Form').subscribe(list => {
      this.blnshowSave = list[actionType.create] == '1';
      this.blnshowDelete = false;
    });
  }

  get f() {
    return this.UploadHistoryForm.controls;
  }

  fillDivision() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DivisionList = list.filter(
      //   x => x.text != "Home Program"
      // );
      this.commonapi.FillDivision().subscribe(list => {
      this.DivisionList = list;
    });
  }

  fillEquipment(divisionID) {
    if (divisionID != 0) {
      this.dmapi.GetEquipmentDD(divisionID).subscribe(list => {
        this.EquipmentList = list;
      });
    } else {
      this.EquipmentList = [{ value: '', text: 'Select' }];
      this.f.equipmentId.setValue('');
    }
  }


  DivisionChanged() {
    this.fillEquipment(this.f.divisionId.value);
  }

  EquipmentDropDownChanged() {
    this.parts.SelectedEquipment = this.f.equipmentId.value;

    var dmrepairCreate = { Id: this.f.equipmentId.value };
    this.dmapi
      .GetEquipmentDetailsByID(dmrepairCreate)
      .subscribe(success => {

      this.hrsTracking = success.hrsTracking;

      this.setHoursOutValidation();

    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }


  executeHistoryUploadCreate = function (Source) {

    AppComponent.loading = true;
    if ((Source === 'Notes' && this.notes.showParts === true && this.notes.Parts.length > 0)) {
      const partTrans = this.parts.PartTrans;
      //console.log(this.parts);
      this.notes.Parts.forEach(function (value) {
        partTrans.push(value);
      });
      this.parts.PartTrans = partTrans;
    }
    // this.f.hoursout.updateValueAndValidity();


    this.isValidFormSubmitted = false;
    if (this.UploadHistoryForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      // this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    // console.log(this.f.HistoryType.value);

    if (this.f.HistoryType.value === "Repair") {
      console.log("Repair");

      const dmrepairCreate = this.prepareCreateViewModelRepair();
      this.dmapi.CreateDMRepairDetails(dmrepairCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          console.log("Redirect");
          this.router.navigate(['DialysisMachine/MRMHistoryUploadSearch']);

        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
    else {
      console.log("PM");
      var dmPMCreate = this.prepareCreateViewModelPM();

      // console.log(dmPMCreate.attachmentDetails.length);
      if (dmPMCreate.attachmentDetails.length == 0) {
        AppComponent.loading = false;
        AppComponent.addAlert('Please attached PDF file related to this PM', 'error');
        this.scrollHelper.scrollToFirst('logo');
        AppComponent.HideLoader();

        return;
      }
      else {

        this.dmapi.CreateDMPMDetails(dmPMCreate).subscribe(
          success => {

            //  this.setModel(success);
            AppComponent.loading = false;

            // if (transactionObj) {
            //   this.processTransaction(transactionObj);
            // }
            AppComponent.addAlert('Data Saved successfully!', 'success');
            this.router.navigate(['DialysisMachine/MRMHistoryUploadSearch']);

          },
          error => {

            if (error && error.error && error.error.returnMessage && error.error.returnMessage[0]) {
              AppComponent.addAlert(error.error.returnMessage[0], 'error');
              // this.scrollHelper.scrollToFirst('logo');
            }
            else {
              AppComponent.addAlert(error.message, 'error');
              // this.scrollHelper.scrollToFirst('logo');
            }

            AppComponent.HideLoader();
          }
        );
      }

    }

  };

  processTransaction(transactionObj) {

    transactionObj.controls.techId.setValue(this.currentUserId);
    transactionObj.controls.createdBy.setValue(this.currentUserId);
    // transactionObj.controls.techName.setValue(this.currentUser);
    transactionObj.controls.pmId.setValue(this.f.Id.value);
    var processModel = JSON.stringify(transactionObj.value);
    AppComponent.ShowLoader();

    this.dmapi.DMPMProcessTransactionDetails(processModel).subscribe(
      success => {
        if (success.returnStatus) {
          transactionObj.controls.isLocked.setValue(true);
          transactionObj.controls.errorMessage.setValue('');
          //  this.executeSave(transactionObj);

          //   this.CheckTransactionStatus();
        }
        else {
          transactionObj.controls.errorMessage.setValue(success.errorMessage);
          transactionObj.controls.pin.setValue('');
          transactionObj.controls.errorValueMessage.setValue(success.errorMessage);
        }
        AppComponent.loading = false;
        AppComponent.HideLoader();
        // AppComponent.addAlert('Data Saved successfully!', 'success');
      },
      error => {
        transactionObj.controls.errorMessage.setValue(error.message);
        AppComponent.HideLoader();
      });
  }

  prepareCreateViewModelPM() {

    const modelCreate = <any>{};
    modelCreate.Id = 0;
    modelCreate.machineName = "Fresenius 5008";
    modelCreate.PMCode = 0;
    modelCreate.divisionId = this.f.divisionId.value;
    modelCreate.equipmentId = this.f.equipmentId.value;
    modelCreate.scheduledPM = null;
    modelCreate.scheduledPMId = null;

    if (this.f.HistoryDate.value !== null && this.f.HistoryDate.value !== undefined) {
      modelCreate.dateIn = Globals.GetAPIDate(this.f.HistoryDate.value.jsdate);
    }
    else if (this.f.dateOut.value !== null && this.f.dateOut.value !== undefined) {
      modelCreate.dateIn = Globals.GetAPIDate(this.f.dateOut.value.jsdate);
    }


    if (this.f.dateOut.value !== null && this.f.dateOut.value !== undefined) {
      modelCreate.dateOut = Globals.GetAPIDate(this.f.dateOut.value.jsdate);
    }

    modelCreate.createdOn = Globals.GetAPIDate(this.today);
    modelCreate.createdBy = this.currentUserId;

    modelCreate.modifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.modifiedBy = this.currentUserId;

    modelCreate.remark = this.f.remarks.value;

    if(this.f.hoursout.value && this.hrsTracking){
      modelCreate.hoursout = this.f.hoursout.value;
      modelCreate.hoursin = this.f.hoursout.value;
    }
    else {
      modelCreate.hoursout = 0;
      modelCreate.hoursin = 0;
    }
    modelCreate.outSurfaceDisinfection = this.f.hydraulicDisinfection.value;
    modelCreate.isUploadHistory = true;


    // if (this.f.dateOut.value && this.f.hoursout.value && this.f.hydraulicDisinfection.value != '' && this.f.hydraulicDisinfection.value != '') {
    modelCreate.status = 'Completed';
    // }
    // else {
    //   modelCreate.status = 'In Progress';
    // }


    // attachments
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_PM];
    this.attachment.UploadBy = this.currentUserId;

    modelCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    // notes
    modelCreate.notesDetails = null;
    // parts


    var pmPartDetails = [];
    for (var key in this.parts.PartTrans) {
      pmPartDetails.push({
        totalCost: this.parts.PartTrans[key].PartCost, partName: this.parts.PartTrans[key].PartCode
        , partDescription: this.parts.PartTrans[key].PartDescription, quantity: this.parts.PartTrans[key].quantity
        , equipmentId: this.f.equipmentId.value, createdBy: this.currentUserId
      })
    }
    modelCreate.partsDetails = pmPartDetails;

    // modelCreate.partsDetails = this.parts.PartTrans;

    return modelCreate;
  }

  prepareCreateViewModelRepair() {

    const dmrepairCreate = <any>{};


    dmrepairCreate.DMRepairDetailsId = 0;
    dmrepairCreate.repairId = this.repairId;
    if (this.f.HistoryDate.value !== null && this.f.HistoryDate.value !== undefined) {
      dmrepairCreate.repairStartDate = Globals.GetAPIDate(this.f.HistoryDate.value.jsdate);
    }

    dmrepairCreate.workOrderRefNo = "";

    if (this.f.dateOut.value !== null && this.f.dateOut.value !== undefined) {
      dmrepairCreate.outTimeOfEquipment = Globals.GetAPIDate(this.f.dateOut.value.jsdate);
    }

    dmrepairCreate.createdOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.createdBy = this.currentUserId;

    dmrepairCreate.modifiedOn = Globals.GetAPIDate(this.today);
    dmrepairCreate.modifiedBy = this.currentUserId;

    dmrepairCreate.remark = this.f.remarks.value;

    if(this.f.hoursout.value && this.hrsTracking){
    dmrepairCreate.hoursout = this.f.hoursout.value;
    dmrepairCreate.hoursin = this.f.hoursout.value;
    }
    else{
      dmrepairCreate.hoursout = 0;
      dmrepairCreate.hoursin = 0;
    }

    dmrepairCreate.status = this.status;
    dmrepairCreate.WarrentyStatus = "";
    // dmrepairCreate.loggedInUsers = this.loggedInUsers;
    dmrepairCreate.DivisionId = this.f.divisionId.value;
    dmrepairCreate.equipmentId = this.f.equipmentId.value;
    //dmrepairCreate.nurseId = ;
    //dmrepairCreate.stationId = 119;
    dmrepairCreate.stationId = null;
    dmrepairCreate.inSurfaceDisinfection = "";
    dmrepairCreate.inHydraulicDisinfection = "";
    dmrepairCreate.outSurfaceDisinfection = "";
    dmrepairCreate.outHydraulicDisinfection = this.f.hydraulicDisinfection.value;
    dmrepairCreate.notes = this.f.remarks.value;
    dmrepairCreate.isUploadHistory = true;

    // parts
    dmrepairCreate.dmRepairPartsDetails = this.parts.PartTrans;

    // attachments
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.DM_Repair];
    this.attachment.UploadBy = this.currentUserId;
    dmrepairCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    // console.log(dmrepairCreate);
    return dmrepairCreate;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}

<form
  [formGroup]="DMUpgradeModificationsForm"
  (ngSubmit)="executeDMUpgradeModificationsCreateUpdate('')"
  novalidate
>
  <!-- <RepairPart></RepairPart> -->
  <section class="content-header">
    <h1>
      DM Upgrades/Modifications
      <!-- <sup class="beta">[beta]</sup> -->
    </h1>
  </section>
  <section class="content">
    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary">
        <PageHeader
          [showNote]="false"
          (addNoteClick)="notes.AddNote()"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeDMUpgradeModificationsCreateUpdate('')"
          [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()"
          #pageheader
        ></PageHeader>
        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-sm-12 box-row hidden">
                <label class="col-sm-2 control-label"
                  >DMUpgradeModificationssId</label
                >

                <div class="col-sm-10">
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    name="DMUpgradeModificationssId"
                    [value]="f.DMUpgradeModificationssId"
                    formControlName="DMUpgradeModificationssId"
                  />
                </div>
              </div>
              <div class="box ibox box-info">
                <div class="box-body">
                  <div class="row">
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-4">
                        <label class="col-sm-5 control-label">Event ID:</label>
                        <div class="col-sm-7">
                          <label class="readOnlyText">{{ EventCode }}</label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-5 control-label">Form Date:</label>
                        <div class="col-sm-7">
                          <label class="readOnlyText">
                            {{
                              f.CreatedDate.value.jsdate
                                | date: "dd/MM/yyyy HH:mm"
                            }}
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-5 control-label">Created By:</label>
                        <div class="col-sm-7">
                          <label class="readOnlyText">
                            {{ selectedUsertext }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="box ibox box-info">
                  <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="dmReference">
                        <h4 class="panel-title">
                          <div class="box-header with-border" style="padding:0px;">
                            <h3 class="box-title">Dialysis Unit Details</h3>
                            <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->

                          </div>
                        </h4>
                      </div>
                  </div>
                <div class="box-body">
                  <div class="row">

                    <div class="col-sm-12 box-row" style="display: none;">
                      <div>
                        <label class="col-sm-3 control-label">
                          <span class="text-red inputrequired">*</span>
                          Upgrade Date:
                        </label>
                        <div class="col-sm-7" *ngIf="f.DMUpgradeModificationssId.value > 0">
                            <label class="readOnlyText"> {{eventDateLabel | date:'dd/MM/yyyy'}}</label>
                        </div>
                        <div class="col-sm-7"  *ngIf="f.DMUpgradeModificationssId.value == 0">
                                <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                class="form-control mdInput" style="float:none"
                                angular-mydatepicker name="eventDate"
                                formControlName="eventDate" [options]="myOptions"
                                #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                required/>
                                <span  *ngIf="f.eventDate.errors?.required && (f.eventDate.dirty || f.eventDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                        Required!
                                </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 box-row">
                      <div class="">
                        <label class="col-sm-4 control-label">Division:</label
                        >
                        <div
                          class="col-sm-8"
                          *ngIf="f.DMUpgradeModificationssId.value > 0"
                        >
                          <label class="readOnlyText">{{
                            divisionName
                          }}</label>
                        </div>
                        <div
                          class="col-sm-8"
                          *ngIf="f.DMUpgradeModificationssId.value == 0"
                        >
                          <!--  -->
                          <select
                            class="form-control mdInput"
                            formControlName="selectedDialysisUnits"
                            name="selectedDialysisUnits"
                            (change)="DialysisUnitDropDownChanged()"
                          >
                            <option
                              value="{{ item.value }}"
                              *ngFor="let item of DialysisUnits"
                            >
                              {{ item.text }}
                            </option>
                          </select>

                          <span
                            *ngIf="
                              f.selectedDialysisUnits.errors?.required &&
                              (f.selectedDialysisUnits.dirty ||
                                f.selectedDialysisUnits.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="">
                        <label class="col-sm-4 control-label"
                          >Upgrade
                          or Modification required:</label
                        >
                        <div
                          class="col-sm-8"
                          *ngIf="f.DMUpgradeModificationssId.value > 0"
                        >
                          <label class="readOnlyText">
                            {{ f.remarks.value }}</label
                          >
                        </div>
                        <div
                          class="col-sm-8"
                          *ngIf="f.DMUpgradeModificationssId.value == 0"
                        >
                          <textarea
                            name="remarks"
                            autosize
                            formControlName="remarks"
                            class="form-textarea lgInput"
                            required
                          ></textarea>
                          <span
                            *ngIf="
                              f.remarks.errors?.required &&
                              (f.remarks.dirty ||
                                f.remarks.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="">
                      <label class="col-sm-4 control-label">Estimated time for upgrade/device (Hours):</label>
                      <div class="input-group col-sm-2" style="padding-right: 0 !important;padding-left: 1.2% !important;"
                       *ngIf="f.DMUpgradeModificationssId.value == 0">
                          <span class="input-group-btn">
                              <button type="button" class="quantity-left-minus btn btn-danger btn-number" (click)="MinusHours()"   data-type="minus" data-field="">
                                <span class="glyphicon glyphicon-minus"></span>
                              </button>
                          </span>
                            <input style="display: inline;text-align: center" class="form-control" formControlName="upgradeHours" name="upgradeHours"
                              (keypress)="numberOnly($event)" disabled="disabled"/>
                          <span class="input-group-btn">
                              <button type="button" class="quantity-right-plus btn btn-success btn-number" (click)="PlusHours()" data-type="plus" data-field="">
                                  <span class="glyphicon glyphicon-plus"></span>
                              </button>
                          </span>

                      </div>
                      <div class="col-sm-2" *ngIf="f.DMUpgradeModificationssId.value > 0">
                        <label class="readOnlyText">
                          {{ f.upgradeHours.value }}</label>
                      </div>

                      </div>
                    </div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-offset-4 col-sm-8">
                        <span
                        *ngIf="
                          (f.upgradeHours.dirty || f.upgradeHours.value == '0.00' ||
                            f.upgradeHours.touched || f.upgradeHours.errors?.pattern ||
                            isValidFormSubmitted == false)"
                        [ngClass]="'error'"
                      >
                        Required!
                      </span>
                      </div></div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <div class="box ibox box-info">
                  <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="dmReference">
                        <h4 class="panel-title">
                          <div class="box-header with-border" style="padding:0px;">
                            <h3 class="box-title">Dialysis Machine Details</h3>
                            <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                          </div>
                        </h4>
                      </div>
                  </div>

                  <div class="row">
                    <div class="box-body">
                      <div class="row">
                          <div class="col-sm-12 box-row pull-right">
                              <a href="javascript:void(0)" *ngIf="EquipmentMappingList.length>0 && status != 'Completed'" (click)="UpgradeCompletedClick()"  class="btn btn-default pull-right" id="btnUpgradeCompleted"
                              style="opacity:0.4">Upgrade Completed ? </a>
                          </div>
                        <div class="col-sm-12 box-row">
                          <div class="col-sm-1 text-center">
                            <strong>#</strong>
                          </div>
                          <div class="col-sm-2 text-center">
                            <strong>Dialysis Machine</strong>
                          </div>
                          <div class="col-sm-2 text-center">
                            <strong>Date Completed</strong>
                          </div>
                          <div class="col-sm-3 text-center">
                            <strong>Completed By</strong>
                          </div>

                          <div class="col-sm-3 text-center">
                            <strong>Dialysis Machine OUT Hours</strong>
                          </div>
                          <div class="col-sm-1 text-center"></div>
                        </div>
                      </div>

                      <div class="row" *ngIf="status != 'Completed'">
                        <div class="col-sm-12 box-row">
                          <div class="col-sm-1">&nbsp;</div>
                          <div class="col-sm-2 text-center">
                            <select
                              class="form-control"
                              formControlName="selectedEquipment"
                              name="selectedEquipment"
                              (change)="onselectedEquipmentChange()"
                            >
                              <option
                                value="{{ item.value }}"
                                *ngFor="let item of Equipments"
                              >
                                {{ item.text }}
                              </option>
                            </select>
                            <span *ngIf="ValidEquipId==false" [ngClass]="'error'">
                              Required!
                            </span>
                            <span *ngIf="RepairStatus" [ngClass]="'error'">
                              You cannot Add this Machine, while there is an open Repair still existing in the system.
                            </span>
                          </div>

                          <div class="col-sm-2">
                            <input
                              (focus)="rEquipDate.toggleCalendar()"
                              (click)="rEquipDate.openCalendar()"
                              placeholder="Select a date"
                              class="form-control mdInput"
                              style="float:none"
                              angular-mydatepicker
                              name="EquipDate"
                              formControlName="EquipDate"
                              [options]="myOptions"
                              #rEquipDate="angular-mydatepicker"
                              (dateChanged)="onDateChanged($event)"
                            />
                            <span *ngIf="ValidEquipDate==false" [ngClass]="'error'">
                              Required!
                            </span>
                          </div>
                          <div class="col-sm-3 text-center">
                            <label class="control-label">{{
                              CurrentUser
                            }}</label>
                          </div>
                          <div class="col-sm-3 text-center ">
                            <div class="form-control readOnlyText">
                              <input
                                maxlength="10"
                                style="text-align: center"
                                class="form-control smInput"
                                name="EquipHoursOut"
                                formControlName="EquipHoursOut"
                                (input)="onEquipHoursOutChange($event.target.value)"
                                maxlength="5" (keypress)="numberOnly($event)"
                              />
                              <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                              <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>
                              <span
                              *ngIf="ValidEquipHoursOut==false"
                              [ngClass]="'error'"> <br>
                              Required!
                            </span>
                            <span *ngIf="f.EquipHoursOut.errors?.pattern" [ngClass]="'error'"><br>Invalid
                              number</span>
                            <span *ngIf="f.EquipHoursOut.errors?.GreaterHoursOut"
                                            [ngClass]="'error'">
                          <br>
                                            Hrs IN => previous Hrs Out
                                            </span>
                                            <span *ngIf="check4000HoursValidation()"
                                            [ngClass]="'error'">
                                            <br>
                                            New Hours >= 4000 from previous Hours Out.
                                            </span>
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <button
                              (click)="AddEquipment()"
                              class="btn btn-primary btn-label-left center-block"
                              type="button"
                            >
                              <span><i class="fa fa-plus"></i></span>
                              <b>Add</b>
                            </button>
                          </div>
                        </div>
                        <!-- <div class="col-sm-offset-9 col-sm-3 box-row">

                        </div> -->
                      </div>
                      <div class="clearfix"></div>
                      <div class="box-body" style="padding-top: 20px;">
                      <div
                        *ngFor="
                          let item of EquipmentMappingList;
                          let index = index
                        "
                        class="row"
                      >
                        <div
                          class="col-sm-12 box-row"
                          style="margin-bottom: 0px"
                        >
                          <div class="col-sm-1 text-center">
                            {{ index + 1 }}
                          </div>
                          <div class="col-sm-2 readOnlyText text-center">
                            {{ item.equipmentName }}
                          </div>
                          <div class="col-sm-2 readOnlyText text-center">
                            {{ item.dateCompleted | date: "dd/MM/yyyy" }}
                          </div>
                          <div class="col-sm-3 text-center">
                            {{ item.createdbyname }}
                          </div>
                          <div class="col-sm-3 text-center readOnlyText">
                            {{ item.dmOutHours }}
                          </div>
                          <div class="col-sm-1 text-center">
                            <button
                              (click)="DeleteEquipment(index, item.dmUpgradeModficationInventoryMappingId)"
                              type="button"
                              *ngIf="(status != 'Completed' && blnshowDelete) || item.dmUpgradeModficationInventoryMappingId==0"
                              class="btn btn-danger btn-label-left center-block btn-xs"
                            >
                              <span><i class="fa fa-times"></i></span>
                            </button>
                            <!-- <button
                            (click)="DeleteEquipment(index)"
                            type="button"
                            *ngIf="status != 'Completed'"
                            class="btn btn-danger btn-label-left center-block btn-xs"
                          >
                            <span><i class="fa fa-times"></i></span>
                          </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

              </div>
              <notes
                [isValidForm]="this.DMUpgradeModificationsForm.invalid && f.EquipHoursOut.value != '' && f.EquipHoursOut.value != ''
                && f.DMUpgradeModificationssId.value > 0"
                (addNoteClick)="executeDMUpgradeModificationsCreateUpdate('Notes')"
                #notes
              >
              </notes>
              <attachment (RaiseError)="AttachmentError($event)" #attachment>
              </attachment>
            </div>
          </div>
          <PageHeader
            [showNote]="false"
            (addNoteClick)="notes.AddNote()"
            [showAddNew]="false"
            [showSearch]="false"
            [mainlisturl]="ListURL"
            [showSave]="blnshowSave"
            (SaveEvent)="executeDMUpgradeModificationsCreateUpdate('')"
            [showDelete]="blnshowDelete"
            (DeleteEvent)="DeleteEvent()"
            #pageheader
          ></PageHeader>
        </div>
      </div>
    </div>
  </section>
</form>

import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { AuthService } from './API/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) {}
    intercept(req, next) {
        let token = this.auth.getAccessToken();
        if (token !== undefined) {
            token = token.token;
        } else {
            token = '';
        }
        const authRequest = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
        return next.handle(authRequest);
    }
}

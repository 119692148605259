import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { WPApiService } from '../API/wpapi.service';
import { map } from 'rxjs/operators';


// create your class that extends the angular validator class
export class WaterProcessCustomValidators  {
  static WaterProcessDivisionValidate(wpapi:WPApiService){
    return (control: AbstractControl) => {

    var wpCreate = {Id:control.value};
        return wpapi.checkRecordExist(wpCreate).pipe(map(res => {
          return res==true ? null : { DivisionRecExists: true };
            //return {false res };
           }));
          };
  }

  static GRWaterProcessDivisionValidateWithMain(wpapi:WPApiService,division:AbstractControl){
    return (control: AbstractControl) => {
    var wpCreate = {Id:division.value,str:control.value};
        return wpapi.checkGRRecordExist(wpCreate).pipe(map(res => {
          return res==true ? null : { DivisionMainRecExists: true };
            //return {false res };
           }));
          };
  }

  static GRWaterProcessDivisionValidateWithoutMain(wpapi:WPApiService){
    return (control: AbstractControl) => {

    var wpCreate = {Id:control.value};
        return wpapi.checkRecordExist(wpCreate).pipe(map(res => {
          return res==true ? null : { DivisionRecExists: true };
            //return {false res };
           }));
          };
  }

  static WaterProcessNurseDivisionValidate(wpapi:WPApiService){
    return (control: AbstractControl) => {

    var wpCreate = {Id:control.value};
        return wpapi.checkRecordExistSaturdayWaterprocess(wpCreate).pipe(map(res => {
          return res==true ? null : { DivisionRecExists: true };
            //return {false res };
           }));
          };
  }

  static GreaterRestrictCustomValidation(validateCtl:AbstractControl) {
    return (control: AbstractControl) => {
      return  ((validateCtl.value !== '' && validateCtl.value != null) &&
      (control.value !== '' && control.value != null) &&
      ( parseFloat (control.value) >= parseFloat (validateCtl.value) )) ?  { InGreaterOut : true } : null;
        };
      }

      static Greaterthen10CustomValidation(validateCtl:AbstractControl) {
        return (control: AbstractControl) => {
          return  ((validateCtl.value !== '' && validateCtl.value != null) &&
          (control.value !== '' && control.value != null) &&
          ( parseInt(control.value) >= (parseInt(validateCtl.value)+10) )) ?  { InGreaterOut : true } : null;
            };
          }

      static NumericRangeCustomValidation(MinValue: Number, MaxValue: Number) {
        return (control: AbstractControl) => {
          return  (
          (control.value !== '' && control.value != null) &&
          ( !(parseFloat (control.value) >= MinValue && parseFloat (control.value) <= MaxValue ))) ?  { OutOfRange : true } : null;
            };
          }

      static CheckedSecondShift(validateCtl:AbstractControl){
        return (control: AbstractControl) => {
          return  ((control.value !="" && control.value != null) &&
          ( validateCtl.value == "" || validateCtl.value == null)) ?  { IsRequiredCtl : true } : null;
            };
          }
          static CheckLength(){
                return (control: AbstractControl) => {
                          var len = new String(control.value);
                         // console.log(len.length);
                          return  ((control.value !="" && control.value != null &&  len.length > 10)) ?  { maxLengthVal : true } : null;
                           };
                          }
}



    <div class="modal-header  col-sm-12" >
        <h4 class="modal-title col-sm-8">Pre-Visit Safety Assessment</h4>

        <div class="text-right  col-sm-4" style="padding:0">
          <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(true)">Close</button>
          <button type="button" class="btn btn-primary" style="display: none;">Save</button>
        </div>
    </div>
    <div class="clearfix"></div>
     <div class="modal-body">
        <table class="sturdy">
          <tr>
            <td style="font-weight: bold;text-align: center">No</td>
            <td style="font-weight: bold;">Assessment</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Confirm full name, address and phone number with client.</td>
          </tr>
          <tr>
            <td>2</td>
            <td>For initial visit and/or when address is unfamiliar check location  for possible hazards /threats to safety.</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Confirm purpose of visit.</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Confirm date and  arrival time.</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Assess if client or anyone in home has a cough ( onset within 7 days)  fever, chills, general malaise ( onset within 24 hours).</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Confirm client’s commitment to have any pets removed or secured during the visit.</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Confirm and identify all individuals who will be in the home at the time of the visit.
              </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Discuss and identify any of the following risk factors applicable to client and/or anyone in home during visit.  A) Smoking, B) Alcohol use, C) Substance Abuse, D) Aggressive Behavior
              </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Confirm any children in the home will be supervised or engaged with activities within sight to minimize disruption during assessment and treatment.
              </td>
          </tr>
          <tr>
            <td>10</td>
            <td>Inquire if there are any cultural or faith traditions that should be respected during the visit such as prayer times, removing shoes, appropriate dress etc.
              </td>
          </tr>
          <tr>
            <td>11</td>
            <td>Confirm parking location</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Confirm  house is clearly marked/identified</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Staff completing assessment</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Any risk identified and follow up action required must be documented in patient EMR</td>
          </tr>
        </table>
    </div>



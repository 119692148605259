import {
  Component,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  QueryList,
  ViewChildren,
  NgModule,
  ɵConsole,
  ElementRef
} from '@angular/core';
import {
  DatePipe, DecimalPipe

} from '@angular/common';

import {
  CalendarComponent
} from 'ng-fullcalendar';

// import {
//   Options
// } from 'fullcalendar';
import {
  Router
} from '@angular/router';
import {
  TasksApiService
} from '../API/tasksapi.service';
import {
  PageHeaderComponent
} from '../Core/pageheader.component';
import {
  AppComponent
} from '../app.component';
import {
  AuthService
} from '../API/auth.service';
import {
  Globals
} from '../globals';
// import {
//   Popover,
//   PopoverModule
// } from "ngx-popover";

// import {
//   setRootDomAdapter
// } from '@angular/platform-browser/src/dom/dom_adapter';
import {
  ContactApiService
} from '../API/contactapi.service';

// import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
// import { CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import * as $ from 'jquery';
import 'jqueryui';
import { HomeFieldService } from '../API/homefield.service';
import { HomeService } from "src/app/API/home.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { invalid } from 'moment';
import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import { CommonApiService } from '../API/commonapi.service.';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ForumMessagesDialog } from '../Core/ForumMessages/ForumMessagesDialog';

@Component({
  selector: 'TaskViewer',
  templateUrl: './TaskViewer.component.html',
  styleUrls: [
    './TaskViewerStyle.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TaskViewerComponent {
  showNew: boolean = false;
  DivisionList;
  startDate: any;
  endDate: any;
  showMyEvents: boolean =false;
  showSchedule;
  // stationList;
  stationName;
  stationID;
  //showStationList = false;
  taskID;


  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  //@ViewChildren('customevents') customevents: QueryList < any > ;
  @ViewChildren('dayevents') dayeventsCtl: QueryList<any>;
  RSideContet: string;
  ShowEventDetailL: boolean;
  LSideContet: string;
  constructor(private api: TasksApiService, private router: Router, private auth: AuthService, private contactApi: ContactApiService,
    private datePipe: DatePipe, private homefieldApi: HomeFieldService, private homeApi: HomeService, public dialog: MatDialog,
    private decimalPipe: DecimalPipe, public sanitizer: DomSanitizer, private commonApi: CommonApiService, private elementRef: ElementRef) {

  }

  public forumDialogRef: MatDialogRef<ForumMessagesDialog>;
  // @ViewChild(PageHeaderComponent) pageheader: PageHeaderComponent;
  @ViewChild(CalendarComponent, { static: false }) ucCalendar: CalendarComponent;
  loading = true;
  blnDirtySearch = false;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = '/Task/TaskDetail';
  currentPageNumber = 1;
  ShowSearch = false;
  title = 'Tasks';
  searchText = 'Search Records';
  defaultSort;
  calendarOptions;
  events;
  dayevents;
  myevents;
  allevents;
  currentUserId = this.auth.GetUserId();
  currentEventId = '0';
  designationName = '';
  popleft = 0;
  poptop = 0;
  defaultTaskHome;
  lastdragobject = '';
  ShowEventDetailR = false;
  // @ViewChild("myPopover", { static: true }) myPopover: Popover;
  // dropdownSettings;
  selectedDivisions = '';
  today = new Date();

  month = (this.today.getUTCMonth() + 1).toString();
  year = this.today.getUTCFullYear();
  MonthList = [
    { value: '1', text: 'January' },
    { value: '2', text: 'February' },
    { value: '3', text: 'March' },
    { value: '4', text: 'April' },
    { value: '5', text: 'May' },
    { value: '6', text: 'June' },
    { value: '7', text: 'July' },
    { value: '8', text: 'August' },
    { value: '9', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' }
  ];
  YearList = [];
  latestForums: any;

  TaskOptions = [
    { name: 'All Tasks' },
    { name: 'My Tasks' }
  ]

  TaskSelectedValue = ['All Tasks'];

  ngOnInit() {
  //
    let year = (new Date().getFullYear() + 10);

    for (var i = 2019; i <= year; i++) {
      this.YearList.push({ value: i.toString(), text: i.toString() });
    }

    this.commonApi.GetLatestForums().subscribe(
      success => {
        this.showNew = success.length > 0;
      },
      error => {
        console.log(error);
      });

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      //this.api.GetAutoTaskStationList().subscribe(list => this.stationList = list);
      this.calendarOptions = {
        editable: true,
        eventLimit: true,
        contentHeight: 650,
        droppable: true,
        fixedWeekCount: false,
        block: true,
        firstDay: 1,
        //eventLimitClick:"day",
        eventOrder: ["backgroundColor", "title"],
        // header: {
        //   left: 'prev,next',
        //   center: 'title,headerTitle',
        //   right: 'month,listMonth'
        // },
        header: false,
        buttonText: {
          month: 'Month View',
          listMonth: 'List View',
        },

        events: []
      };

      const accesToken = this.auth.getAccessToken();
      this.designationName = accesToken.DesignationName;

      this.auth.GetCurrentUserDivision().subscribe(division => {
        if (division.length === 1)
          this.fillDivisionList(division[0]);
        else
          this.fillDivisionList();
      }, error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      });

    }, error => {
    });


    this.commonApi.GetLatestForums().subscribe(
      success => {

        this.latestForums = success;
        // if(this.latestForums.length > 0)
        // console.log(this.latestForums);

      },
      error => {
        console.log(error);
      });

      this.TaskSelectedValue = ['All Tasks'];

      this.IsNotificationLinkVisible();
  }

  showNewMessageLink = false;
  IsNotificationLinkVisible() {
    setTimeout(() => {

      if (this.latestForums != undefined && this.latestForums.length > 0 && Globals.lastLoginDateTime < this.latestForums[0].CreationDate) {
        this.showNewMessageLink = true;
      } else {
        this.showNewMessageLink = false;
      }
    }, 3000);

  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.customevents.forEach(function (item) {
    //     // store data so the calendar knows to render an event upon drop
    //     $(item.nativeElement).data('event', {
    //       title: $.trim($(item.nativeElement).text()), // use the element's text as the event title
    //       tasktype: 'station',
    //       id: $.trim($(item.nativeElement).attr("id")),
    //       stick: true // maintain when user navigates (see docs on the renderEvent method)
    //     });
    //     // make the event draggable using jQuery UI
    //     $(item.nativeElement).draggable({
    //       zIndex: 999,
    //       revert: true, // will cause the event to go back to its
    //       revertDuration: 0, //  original position after the drag
    //       appendTo: 'body',
    //       helper: 'clone',
    //     });

    //   });
    // }, 2000);

    // setTimeout(()=>{
    //     // var fcCenter = this.elementRef.nativeElement.querySelector('.fc-center');
    //     // var centerTitle = this.elementRef.nativeElement.querySelector('.CenterTitle');
    //     // fcCenter.insertAdjacentHTML('beforeend', centerTitle);
    // },3000);

    if (this.notes) {
      this.notes.Note = '';
      this.notes.showHours = false;
      this.notes.detailID = '';
    }

    // if (this.myPopover)
    //   this.myPopover.show();
  }

  drop(event) {


    let date = this.today;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const newDateString = event.detail.event.start._i[0] + '-' +
      (event.detail.event.start._i[1] + 1) + '-' +
      event.detail.event.start._i[2] + ' 00:00:00.000Z';

    let newEDateString = newDateString;
    if (event.detail.event.end != null) {
      newEDateString = event.detail.event.end._i[0] + '-' +
        (event.detail.event.end._i[1] + 1) + '-' +
        event.detail.event.end._i[2] + ' 00:00:00.000Z';
    }
    const newdate = new Date(newDateString);
    const newEdate = new Date(newEDateString);
    let olddate = new Date(newDateString);
    let oldEdate = new Date(newEDateString);
    olddate.setDate(olddate.getDate() + (event.detail.duration._days * -1));
    oldEdate.setDate(oldEdate.getDate() + (event.detail.duration._days * -1));

    const eventObj = this.events.filter(
      (item) => item.id == event.detail.event.id && item.tasktype == event.detail.event.tasktype
    );

    if ((eventObj[0].tasktype != 'task' || eventObj[0].tasktype != 'autotask') &&
      eventObj[0].status == 'Completed') {
      eventObj[0].start._d = olddate;

      if (eventObj[0].end == null) {
        eventObj[0].end = eventObj[0].start;
      }
      eventObj[0].end._d = oldEdate;
      AppComponent.addAlert('This task is not allowed to be change.', 'error');
      return false;
    }

    if (eventObj.length > 0 && eventObj[0].completed == true) {
      // let dt = event.detail.event.start._d;
      // dt.setDate( dt.getDate() + (event.detail.duration._days * -1));


      eventObj[0].start._d = olddate;
      if (eventObj[0].end == null) {
        eventObj[0].end = eventObj[0].start;
      }
      eventObj[0].end._d = oldEdate;
      AppComponent.addAlert('Complate task date doesn\'t allow to change', 'error');
      return false;

    } else if (event.detail.event.start < date) {
      if (eventObj.length > 0) {

        //  let dt = event.detail.event.start._d;
        //  dt.setDate( dt.getDate() + (event.detail.duration._days * -1));
        eventObj[0].start._d = olddate;
        if (eventObj[0].end == null) {
          eventObj[0].end = eventObj[0].start;
        }
        eventObj[0].end._d = oldEdate;
        AppComponent.addAlert('Task date should not be less than today', 'error');
        return false;
      }
    } else if (olddate.getMonth() != newdate.getMonth() && this.designationName != "Administrator") {
      if (eventObj.length > 0) {
        //  let dt = event.detail.event.start._d;
        //  dt.setDate( dt.getDate() + (event.detail.duration._days * -1));
        eventObj[0].start._d = olddate;
        if (eventObj[0].end == null) {
          eventObj[0].end = eventObj[0].start;
        }
        eventObj[0].end._d = oldEdate;
        AppComponent.addAlert('Task date should changed to next month.', 'error');
        return false;
      }
    } else if (event && event.detail && event.detail.event && event.detail.event.taskDescription
      && event.detail.event.taskDescription.includes('Microbiology')
      && oldEdate.getMonth() != newEdate.getMonth()) {
      if (eventObj.length > 0) {
        //  let dt = event.detail.event.start._d;
        //  dt.setDate( dt.getDate() + (event.detail.duration._days * -1));
        eventObj[0].start._d = olddate;
        if (eventObj[0].end == null) {
          eventObj[0].end = eventObj[0].start;
        }
        eventObj[0].end._d = oldEdate;
        AppComponent.addAlert('RO/DM Microbiology Task should not changed to previous/next month.', 'error');
        return false;
      }
    }
    else {
      const taskCreate = <any>{};
      taskCreate.taskid = event.detail.event.id;

      taskCreate.StartDate = this.datePipe.transform(new Date(event.detail.event.start.format()), 'yyyy-MM-dd');
      if (event.detail.event.end == null) {
        event.detail.event.end = event.detail.event.start;
      }
      taskCreate.completiondate = this.datePipe.transform(new Date(event.detail.event.end.format()), 'yyyy-MM-dd');
      taskCreate.modifiedon = this.today;
      taskCreate.modifiedBy = this.currentUserId;

      this.api.UpdateTasksDate(taskCreate).subscribe(
        success => {
          this.executeTaskSearch(false);
          AppComponent.addAlert('Task date updated successfully!', 'success');
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  }

  fillDivisionList(defaultValue = '') {
    this.api.FillDivision().subscribe(list => (this.DivisionList = list.filter((item, index) => item.value !== '')));
    const contactCreate = {
      Id: this.currentUserId
    };



    this.contactApi.GetContactDetailsByID(contactCreate).subscribe(
      success => {
        this.defaultTaskHome = success.defaultTaskHome;
        if (this.defaultTaskHome == true) {
          const selectedDivisionID = this.DivisionList.filter(
            x => x.text === 'Home Program'
          );
          if (selectedDivisionID.length > 0)
            this.selectedDivisions = selectedDivisionID[0].value;
        }
        else if (defaultValue) {
          this.selectedDivisions = defaultValue;
          this.executeTaskSearch(false);
        }
      });
  }

  onItemSelect() {
    this.executeTaskSearch(false);
  }

  isFirstTimeLoad =true;
  initialized(eventData) {
    this.startDate = eventData.detail.view.start;
    this.endDate = eventData.detail.view.end;
    this.executeTaskSearch(false);


    if(this.isFirstTimeLoad){
      this.month =(this.today.getUTCMonth() + 1).toString();
      this.year = this.today.getUTCFullYear();
      this.isFirstTimeLoad = false;
    } else {
      this.month = (eventData.detail.view.intervalStart._d.getUTCMonth() + 1).toString();
      this.year = eventData.detail.view.intervalStart._d.getUTCFullYear();
    }
    this.ucCalendar.eventReceive.subscribe(event => {
    });


  }

  getWeekNumber(thisDate) {
    var dt = new Date(thisDate);
    var thisDay = dt.getDate();

    var newDate = dt;
    newDate.setDate(1); // first day of month
    var digit = newDate.getDay();

    var Q = (thisDay + digit) / 7;

    var R = (thisDay + digit) % 7;

    if (R !== 0) return Math.ceil(Q);
    else return Q;
  }

  GetPopupClassName(date) {
    var result = 'right';
    if (this.datePipe.transform(date, 'EE') == "Sat" || this.datePipe.transform(date, 'EE') == "Sun") {
      result = 'left';
    }
    return result;
    // var result = document.getElementsByClassName("fc-rigid");
    // var dayno = -1;
    // var weekNo;
    // for (var _i = 0; _i < result.length; _i++) {
    //   var dayresult = result[_i].getElementsByClassName("fc-day");

    //   for (var _n = 0; _n < dayresult.length; _n++) {

    //     if (dayresult[_n].attributes['data-date'].value == date) {
    //       dayno = _n;
    //       weekNo = _i + 1;
    //       break;
    //     }
    //     if (dayno != -1) {
    //       break;
    //     }
    //   }
    // }
    // let posClassName = 'popbottom';

    // if (weekNo < 4) {
    //   posClassName = 'popbottom';
    // } else {
    //   posClassName = 'poptop';
    // }

    // if (dayno == 6) {
    //   posClassName = posClassName + ' popright';
    // }

    // if (dayno == 0) {
    //   posClassName = posClassName + ' popleft';
    // }
    //return posClassName;
  }

  eventRender(event) {


    if (event.detail.event.tasktype == 'UpdateDate') {
      const updatedEevents = this.events.filter(
        (item) => (item.tasktype + item.id == event.detail.event.id.replace(/lnk/g, '') || item.id == event.detail.event.id)
      );


      this.UpdateDate(updatedEevents[0].id, updatedEevents[0].tasktype, new Date(updatedEevents[0].start), event.detail.event.start._d);
      this.events = this.events.filter((item) => item.tasktype != 'UpdateDate');

      return;
    }
    //   else  if (event.detail.event.tasktype == 'station') {

    //   if (this.getWeekNumber(event.detail.event.start._d) > 4 ||
    //     this.datePipe.transform(event.detail.event.start._d, 'EE') == "Sun") {
    //     this.events = this.events.filter((item) => item.tasktype != 'station');
    //   } else {

    //     let startDateStr = this.datePipe.transform(event.detail.event.start._d, 'yyyy-MM-dd');
    //     // let station = this.stationList.filter((item) => item.dialysisStationName == event.detail.event.title);

    //     // this.router.navigate(['/Task/AutoTaskDetail'], {
    //     //   queryParams: {
    //     //     stationid: station[0].dialysisUnitId,
    //     //     date: startDateStr
    //     //   }
    //     // });
    //   }
    //   return;
    // }
    else {
      let d = event.detail.element[0];
      setTimeout(() => {
        d.parentElement.parentElement.childNodes.forEach(function (node) {

          if (node.className === 'fc-more-cell') {

            const txt = node.innerText;
            node.innerHTML = '<div><a class="fc-more1">' + txt + '</a></div>';
          }
        });
      }, 500);
      if (event.detail.view.name == 'month') {


        var date = this.datePipe.transform(event.detail.event.start._d, 'yyyy-MM-dd');
        event.detail.element[0].innerHTML = '<div class="popuptext ' + this.GetPopupClassName(date) + '" id="myPopup' + event.detail.event.tasktype +
          event.detail.event.id + '">' + event.detail.event.EventHTML + '</div>' + event.detail.element[0].innerHTML;
      } else {

        event.detail.element[0].childNodes[0].innerHTML = '<div class="popuptext popbottomt" id="myPopup' + event.detail.event.tasktype +
          event.detail.event.id + '">' + event.detail.event.EventHTML + '</div> 12:00am';

      }
    }
  }

  eventClick(eventData) {




    this.OpenEventData(eventData.event.tasktype, eventData.event.id, eventData.event);

  }

  UpdateDate(id, type: string, oldDate: Date, newDate: Date) {
    oldDate.setHours(0, 0, 0, 0);
    newDate.setHours(0, 0, 0, 0);
    const eventObj = this.events.filter(
      (item) => item.id == id && item.tasktype == type
    );
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    //const diffDays = Math.abs(oldDate.diff(newDate, 'days'));
    const diffDays = Math.ceil((newDate.getTime() - oldDate.getTime()) / oneDay);
    let oldEndDate = undefined;
    if (eventObj[0].end == null) {
      eventObj[0].end = new Date(this.datePipe.transform(new Date(eventObj[0].start), 'yyyy-MM-dd'));
    }
    oldEndDate = new Date(this.datePipe.transform(new Date(eventObj[0].end), 'yyyy-MM-dd'));
    oldEndDate.setHours(0, 0, 0, 0);
    let newEndDate = oldEndDate;
    newEndDate.setHours(0, 0, 0, 0);
    newEndDate.setDate(newEndDate.getDate() + diffDays);
    newEndDate.setHours(0, 0, 0, 0);

    const date = new Date();


    if (eventObj.length > 0 && eventObj[0].completed == true) {

      AppComponent.addAlert('Complate task date doesn\'t allow to change', 'error');
      return false;
    } else if ((eventObj[0].tasktype != 'task' && eventObj[0].tasktype != 'autotask')) {

      AppComponent.addAlert('This task is not allowed to be change.', 'error');
      return false;
    }
    else if (eventObj[0].start < date) {
      if (eventObj.length > 0) {



        AppComponent.addAlert('Task date should not be less than today', 'error');
        return false;
      }
    } else if (oldDate.getMonth() != newDate.getMonth() && this.designationName != "Administrator") {
      if (eventObj.length > 0) {

        AppComponent.addAlert('Task date should changed to next month.', 'error');
        return false;
      }
    } else {
      const taskCreate = <any>{};
      taskCreate.taskid = id;

      taskCreate.StartDate = this.datePipe.transform(new Date(newDate), 'yyyy-MM-dd');
      taskCreate.completiondate = this.datePipe.transform(new Date(newEndDate), 'yyyy-MM-dd');

      taskCreate.modifiedon = new Date();
      taskCreate.modifiedBy = this.currentUserId;

      this.api.UpdateTasksDate(taskCreate).subscribe(
        success => {
          AppComponent.addAlert('Task date updated successfully!', 'success');
          eventObj[0].start = newDate;
          eventObj[0].end = newEndDate;
          //this.ShowMyEventsFunc();
          this.executeTaskSearch(false);
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  }

  OpenEventData(tasktype, idVal, event) {
    if (this.lastdragobject != 'lnk' + tasktype + idVal) {

      (document.querySelectorAll('.main-header')[0] as HTMLElement).removeAttribute('style');
      if (tasktype == 'task') {
        this.router.navigate([this.AddUrl], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'autotask') {
        //
        let divisionName = event.divisionName.split(':');
        this.stationName = divisionName[1];
        this.taskID = idVal;
        this.homefieldApi.GetHomeFieldMasterByTaskID({ Id: idVal }).subscribe(
          success => {
            //
            if (success == null) {

              let taskDate;
              if (event && event.start)
                taskDate = new Date(event.start.format());

              if (taskDate &&
                (taskDate.getMonth() >= this.today.getMonth() || taskDate >= this.today)) {
                var model = this.prepareModel();
                this.homeApi.GetDialysisStationByStationName(model).subscribe(
                  success => {
                    //
                    this.stationID = success.dialysisUnitId;
                    const modelCreate = this.prepareCreateViewModel();
                    this.homefieldApi.CreateHomeFieldService_Master(modelCreate).subscribe(
                      success => {
                        this.router.navigate(['/HomeFieldService/HomeFieldServiceDetail'], {
                          queryParams: {
                            id: success.hfServiceId,
                            TaskId: idVal
                          }
                        });

                      },
                      error => {
                        if (error.error && error.error.returnMessage) {
                          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
                        }
                        else
                          AppComponent.addAlert(error.message, 'error');

                        AppComponent.HideLoader();
                      });
                  }
                );
              }
              else {
                this.openNote(this.taskID);
              }
            } else {
              this.router.navigate(['/HomeFieldService/HomeFieldServiceDetail'], {
                queryParams: {
                  id: success.hfServiceId,
                  TaskId: idVal
                }
              });
            }
          });
      } else if (tasktype == 'dmrepair') {
        this.router.navigate(['/DialysisMachine/DMRepairDetail'], {
          queryParams: {
            id: idVal,
            SourceList: 'M'
          }
        });
      } else if (tasktype == 'pm') {
        this.router.navigate(['/DialysisMachine/PreventiveMaintenance'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'WPR') {
        this.router.navigate(['/WaterProcess/Repairs/RepairDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'OTR') {
        this.router.navigate(['/ORepairs/Repairs/RepairDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'BAC' || tasktype == 'BACR') {
        this.router.navigate(['/DialysisMachine/BacteriasDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'PYR') {
        this.router.navigate(['/DialysisMachine/PyrogensDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'ROA') {
        this.router.navigate(['/WaterProcess/Analysis/AnalysisDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'UPG') {
        this.router.navigate(['/DialysisMachine/DMUpgradeModificationsDetail'], {
          queryParams: {
            id: idVal
          }
        });
      } else if (tasktype == 'HFS') {
        this.router.navigate(['/HomeFieldService/HomeFieldServiceDetail'], {
          queryParams: {
            id: idVal
          }
        });
      }
    }
  }

  executeTaskSearch(IsAllDivision) {
    AppComponent.ShowLoader();

    const divisionList = this.selectedDivisions;

    const taskSearch = {
      FromDate: this.startDate,
      ToDate: this.endDate,

      currentUserId: this.currentUserId,
      division: divisionList
    };
    this.events = [];
    this.api.GetTaskDetailList(taskSearch).subscribe(pagedData => {

      const calendar = this.ucCalendar;
      const ev = [];
      const _currentPage = this;

      pagedData.taskDetailslst.forEach(function (task) {
        if (task) {
          ev.push({
            title: task.taskTitle ? task.taskTitle.replace(/BMR/g, '') : '',
            titlewithstatus: task.taskTitle ? task.taskTitle.replace(/BMR/g, '') + ' - ' + task.status : '',
            start: task.startDate,
            end: task.completionDate,
            id: task.taskId,
            backgroundColor: task.backcolor,
            borderColor: task.color,
            textColor: '#000',
            EventHTML: _currentPage.GetTaskDetail(task),
            completed: task.completed,
            totalUsers: task.totalUsers,
            tasktype: task.tasktype,
            divisionName: task.divisionName,
            taskTypeName: task.taskTypeName,
            taskDescription: task.taskDescription
          });
        }
      });

      this.allevents = ev;

      this.myevents = ev.filter((item) => item.totalUsers > 0);
      this.ShowMyEventsFunc();
      if (this.startDate._d < new Date() && this.endDate._d > new Date()) {

        this.SetDayEvents(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      } else {
        this.dayevents = [];
      }

      AppComponent.HideLoader();
    },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  RouteClick(value) {
    this.router.navigate([value]);
  }

  mouseOver(event, content) {

    this.currentEventId = event.detail.event.tasktype + event.detail.event.id;
    const eventid = event.detail.event.tasktype + event.detail.event.id;
    if (event.detail.event.tasktype != 'UpdateDate') {
      setTimeout(() => {
        if (eventid == this.currentEventId) {
          //console.log(event.detail.event.EventHTML);

          var date = this.datePipe.transform(event.detail.event.start._d, 'yyyy-MM-dd');
          var side = this.GetPopupClassName(date);
          if (side == 'right') {
            this.ShowEventDetailR = true;
            this.ShowEventDetailL = false;
            this.RSideContet = '<div class="popuptext">' + event.detail.event.EventHTML + '</div>';
            // console.log(this.RSideContet);
          } else {
            this.ShowEventDetailL = true;
            this.ShowEventDetailR = false;
            this.LSideContet = '<div class="popuptext">' + event.detail.event.EventHTML + '</div>';
          }

          // var popup = document.querySelector("#myPopup" + this.currentEventId);
          // if (event.detail.view.name == 'month') {

          //   var result = document.getElementsByClassName("popupcell");

          //   for (var _i = 0; _i < result.length; _i++) {
          //     result[_i].classList.remove('popupcell');

          //   }


          //   var pElement = popup.parentElement;

          //   while (!pElement.classList.contains('fc-rigid')) {
          //     pElement = pElement.parentElement;

          //   }
          //   pElement.classList.add('popupcell');
          // }



          // if (popup != null) {
          //   popup.classList.add("show");
          //   (document.querySelectorAll('.main-header')[0] as HTMLElement).style.zIndex = '0';
          // }
        }
      }, 500);
    }
  }

  mouseOut(event, content) {

    this.ShowEventDetailR = false;
    this.ShowEventDetailL = false;
    this.currentEventId = '0';
    // var popup = document.querySelector("#myPopup" + this.currentEventId);
    // (document.querySelectorAll('.main-header')[0] as HTMLElement).removeAttribute('style');
    // if (popup != null) {
    //   popup.classList.remove("show");
    // }
    // this.currentEventId = '0';
  }

  enter(event) {
    this.currentEventId = event.tasktype + event.id;

    //  var menuItem = document.getElementById(obj);

    //   var top = menuItem.offsetTop;
    //   var left = menuItem.offsetLeft;

    setTimeout(() => {

      if (event.tasktype + event.id == this.currentEventId && 'lnk' + this.currentEventId != this.lastdragobject) {
        this.ShowEventDetailL = true;
        this.ShowEventDetailR = false;
        this.LSideContet = '<div class="popuptext">' + event.EventHTML + '</div>';
        // const popup = document.querySelector("#myPopupr" + this.currentEventId);
        // this.popleft = -215;
        // var elements = document.getElementsByClassName('tasklist')[0];

        // popup.classList.add("show");
        // const pop = document.getElementById("myPopupr" + this.currentEventId);
        // //this.poptop = top - ((pop.offsetHeight / 2) + elements.scrollTop);
        // this.poptop = (pop.offsetHeight / 2) * -1;
        // (document.querySelectorAll('.main-header')[0] as HTMLElement).style.zIndex = '0';
      }
    }, 500);

  }

  leave() {
    this.ShowEventDetailL = false;
    this.currentEventId = '0';
    // var popup = document.querySelector("#myPopupr" + this.currentEventId);
    // (document.querySelectorAll('.main-header')[0] as HTMLElement).removeAttribute('style');
    // popup.classList.remove("show");
    // this.currentEventId = '0';
  }

  ShowMyEventsFunc() {

    if (this.showMyEvents == true) {
      this.events = this.myevents;
    } else {
      this.events = this.allevents;
    }
    if (this.startDate._d < new Date() && this.endDate._d > new Date()) {

      this.SetDayEvents(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    } else {
      this.dayevents = [];
    }
  }

  clickDay(event, jsEvent, view) {
    this.SetDayEvents(event.detail.date.format());

  }

  SetDayEvents(DayString) {

    if (this.showMyEvents == true) {
      if (this.myevents != undefined) {
        this.dayevents = this.myevents.filter(
          (item) => this.datePipe.transform(item.start, 'yyyy-MM-dd') == DayString
        );
      }
    } else {
      if (this.events != undefined) {

        this.dayevents = this.events.filter(
          (item) => this.datePipe.transform(item.start, 'yyyy-MM-dd') == DayString
        );
        // console.log(this.dayevents);
      }
    }

    setTimeout(() => {
      this.dayeventsCtl.forEach((item) => {



        $(item.nativeElement).data('event', {
          title: item.nativeElement.getElementsByClassName('fc-title')[0].innerText, // use the element's text as the event title
          tasktype: 'UpdateDate',
          id: item.nativeElement.getElementsByClassName('fc-event')[0].id, //item.nativeElement.getElementsByClassName('fc-event')[0].id
          stick: true // maintain when user navigates (see docs on the renderEvent method)
        });

        $(item.nativeElement).draggable({
          zIndex: 1999,
          revert: true, // will cause the event to go back to its
          revertDuration: 0,//  original position after the drag,
          start: (event, ui) => {

            this.lastdragobject = ui.helper[0].children[0].id;

          },
          stop: (event, ui) => {

            setTimeout(() => {
              this.lastdragobject = '';

            }, 1000);
          }
        });

      }
      )
    }, 2000);


  }

  getNoteHour(notes) {
    const x = notes.split('|');
    if (x[1] == undefined) {
      x[1] = '';
    }

    return x[1];
  }

  getNoteDesc(notes) {
    const x = notes.split('|');
    return x[0].replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  GetDayList(DivistionName) {

    if (this.dayevents == undefined) {
      this.SetDayEvents(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    }
    return this.dayevents.filter(
      (item) => (item.divisionName.split(':')[0]) == DivistionName || item.divisionName == 'All Division'
    );
  }

  GetDivisionEventCount(DivistionName) {

    if (this.dayevents == undefined) {
      this.SetDayEvents(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    }

    if(this.dayevents){
    return this.dayevents.filter(
      (item) => (item.divisionName.split(':')[0]) == DivistionName || item.divisionName == 'All Division'
    ).length;
    }

    return this.dayevents.length;
  }

  GetDivisionList() {
    if (this.DivisionList != undefined)
      return this.DivisionList.filter((item, index) => item.value === this.selectedDivisions || this.selectedDivisions === "");
  }

  GetTaskDetail(task) {
    //let result = '<div class="box-body"> <div class="row noborder"> <div class="col-sm-12"><h3>{{ati}}</h3></div></div><div class="row noborder"> <div class="col-sm-12"><h4>{{date}}</h4></div></div><div class="row  {{showcategory}}"> <div class="col-sm-12"><b class="col-sm-3 ">ID:</b> <div class="col-sm-7"><b >{{code}}</b></div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-3 ">Created By:</b> <div class="col-sm-7">{{createdby}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-3">Division:</b> <div class="col-sm-7">{{division}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-3 ">Type:</b> <div class="col-sm-7">{{type}}</div></div></div><div class="row {{showcategory}}"> <div class="col-sm-12"><b class="col-sm-3 ">Category:</b> <div class="col-sm-7">{{category}}</div></div></div><div class="row {{showitem}}"> <div class="col-sm-12"><b class="col-sm-3 ">Item:</b> <div class="col-sm-7">{{equipment}}</div></div></div><div class="row {{showassignto}}"> <div class="col-sm-12"><b class="col-sm-3 ">Assign To:</b> <div class="col-sm-7">{{assigneduser}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-3 ">Description:</b><div class="col-sm-7">{{description}}</div></div></div>';
    let result = '<div class="box-body"> <div class="row noborder"> <div class="col-sm-12"><h3>{{ati}}</h3></div></div><div class="row  {{showcategory}}"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">ID:</b> <div class="col-sm-7"><b >{{code}}</b></div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Created By:</b> <div class="col-sm-7">{{createdby}}</div></div></div><div class="row"><div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Created Date:</b> <div class="col-sm-7">{{createdDate}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Division:</b> <div class="col-sm-7">{{division}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Type:</b> <div class="col-sm-7">{{type}}</div></div></div><div class="row {{showitem}}"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Item:</b> <div class="col-sm-7">{{equipment}}</div></div></div><div class="row"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Purpose:</b><div class="col-sm-7">{{description}}</div></div></div>';
    result += '<div class="row"> <div class="col-sm-12"><b class="col-sm-4" style="text-align:right;">Status:</b><div class="col-sm-7">{{status}}</div></div></div>';
    result += '<div class="row"> <div class="col-sm-12"><b class="col-sm-4 " style="text-align:right;">Recurrence: </b><div class="col-sm-7">{{isRecurrence}}</div></div></div>';
    result += '<div class="row"> <div class="col-sm-12"><b class="col-sm-4 " style="text-align:right;">Scheduled Date: </b><div class="col-sm-7">{{date}}</div></div></div>';
    result += '<div class="row"> <div class="col-sm-12"><b class="col-sm-4 " style="text-align:right;">Next Scheduled Date: </b><div class="col-sm-7">{{endAfterRecerrence}}</div></div></div>';
    result += '<div class="row   {{showmasterdate}}" > <div class="col-sm-12"><b class="col-sm-3 ">Master Date:</b><div class="col-sm-7">{{masterdate}}</div></div></div>';
    // result += '<div class="row   {{showdatetracking}}" > <div class="col-sm-12"><b class="col-sm-3 ">Date Tracking:</b></div></div>';
    // result += '<div class="row  noborder {{showdatetracking}}" > <div class="col-sm-12">{{datetracking}}</div></div>';
    // result += '<div class="row   {{showtasknotes}}" > <div class="col-sm-12"><b class="col-sm-3 ">Notes:</b></div></div>';
    result += '<div class="row  noborder {{showtasknotes}}" > <div class="col-sm-12">{{tasknotes}}</div></div>';

    result += '</div>';

    let title = task.taskTitle;
    let x = title ? title.split("-") : "";

    if (x.length > 2) {
      title = x[0] + '-' + x[1] + "<br/>";
      for (let i = 2; i < x.length; i++) {
        if (i == 2) {
          title += x[i];
        } else {
          title += "-" + x[i];
        }
      }
    }

    let re = /{{title}}/gi;
    result = result.replace(re, title);
    re = /{{ati}}/gi;
    result = result.replace(re, title);
    re = /{{date}}/gi;
    if (this.datePipe.transform(task.startDate, 'EEEE d, MMMM yyyy') === this.datePipe.transform(task.completionDate, 'EEEE d, MMMM yyyy')) {
      if (task.status == 'Completed') {
        result = result.replace(re, this.datePipe.transform(
          task.completionDate,
          'dd/MM/yyyy'
        ));
      } else {
        result = result.replace(re, this.datePipe.transform(
          task.createdOn,
          'dd/MM/yyyy'
        ));
      }
    } else {
      result = result.replace(re, this.datePipe.transform(
        task.startDate,
        'EEEE d'
      ) + ' - ' + this.datePipe.transform(
        task.completionDate,
        'dd/MM/yyyy'
      ));
    }


    re = /{{code}}/gi;
    result = result.replace(re, task.taskCode);
    re = /{{createdby}}/gi;
    result = result.replace(re, task.createdbyName);
    re = /{{createdDate}}/gi;
    result = result.replace(re, this.datePipe.transform(task.createdDate, 'dd/MM/yyyy'));
    re = /{{division}}/gi;
    result = result.replace(re, task.divisionName);
    re = /{{type}}/gi;
    result = result.replace(re, task.taskTypeName);
    // re = /{{category}}/gi;
    // result = result.replace(re, task.categoryName);
    // if (task.categoryName == '') {
    //   re = /{{showcategory}}/gi;
    //   result = result.replace(re, 'noborder hidden');
    // } else {
    //   re = /{{showcategory}}/gi;
    //   result = result.replace(re, '');
    // }
    re = /{{equipment}}/gi;
    result = result.replace(re, task.equipmentName);
    if (task.equipmentName == '' || task.tasktype == 'task' || task.tasktype == 'autotask') {
      re = /{{showitem}}/gi;
      result = result.replace(re, 'noborder hidden');
    } else {
      re = /{{showitem}}/gi;
      result = result.replace(re, '');
    }
    re = /{{assigneduser}}/gi;
    result = result.replace(re, task.assignedUser == '' ? 'Not Assigned' : task.assignedUser);
    if (task.tasktype == 'task') {
      re = /{{showassignto}}/gi;
      result = result.replace(re, '');
    } else {
      re = /{{showassignto}}/gi;
      result = result.replace(re, 'noborder hidden');
    }
    re = /{{description}}/gi;
    let desc1 = this.getNoteDesc(task.taskDescription);
    let hours = this.getNoteHour(task.taskDescription);

    if (hours != '' && !isNaN(hours)) {
      hours = this.decimalPipe.transform(hours, '2.2') + ' hr';
      desc1 += ' | ' + hours;
    }
    if(desc1 != '')
      result = result.replace(re, desc1);
    else
      result = result.replace(re, "N/A");

    re = /{{status}}/gi;
    result = result.replace(re, task.status);

     if(task.isRecurrence == true)
     {
      re = /{{isRecurrence}}/gi;
      result = result.replace(re, "Yes");

      if(task.nextScheduledDate != null)
      {
      re = /{{endAfterRecerrence}}/gi;
      result = result.replace(re, this.datePipe.transform(task.nextScheduledDate, 'dd/MM/yyyy'));
      }
      else
      {
        re = /{{endAfterRecerrence}}/gi;
        result = result.replace(re, 'N/A');
      }
     }
     else
     {
      re = /{{isRecurrence}}/gi;
      result = result.replace(re, "N/A");

      re = /{{endAfterRecerrence}}/gi;
      result = result.replace(re, "N/A");
     }



    re = /{{masterdate}}/gi;
    result = result.replace(re, task.equipment == '' ? 'N/A' : task.equipmentName);
    if (task.tasktype == 'autotask') {
      re = /{{showmasterdate}}/gi;
      result = result.replace(re, '');
    } else {
      re = /{{showmasterdate}}/gi;
      result = result.replace(re, 'noborder hidden');
    }

    if(task.taskNotes != null && task.taskNotes != '') {
      var notes ='';
      const lines = task.taskNotes.split('~!@#$');
      notes += '<table width="95%"><tr><th style="text-align:left">Notes</th></tr>';
      const idx = 0;
      if(lines.length > 0)
      {
        for (var i = 0; i < lines.length; i++) {
         notes += '<tr><td>'+ (i+1)+")   "  + lines[i] +'</td></tr>';
       }
      }
      notes += '</table>';

       re = /{{showtasknotes}}/gi;
      result = result.replace(re, '');
      re = /{{tasknotes}}/gi;
      result = result.replace(re, notes == '' ? 'N/A' : notes);
     } else {
          re = /{{showtasknotes}}/gi;
      result = result.replace(re, 'noborder hidden');
     }

    // if (task.tasktype == 'autotask') {
    //   re = /{{showdatetracking}}/gi;
    //   result = result.replace(re, '');
    //   re = /{{datetracking}}/gi;
    //   let backlog = this.GetBackLog(task.assignedUser);
    //   result = result.replace(re, backlog == '' ? 'N/A' : backlog);
    // } else {
    //   re = /{{showdatetracking}}/gi;
    //   result = result.replace(re, 'noborder hidden');
    // }
// console.log(result);

    return result;
  }
  GetBackLog(backlogStr) {
    let result = '';
    if (backlogStr != null && backlogStr != '') {
      const lines = backlogStr.split('|');
      if (lines.length > 0) {
        result = '<table><tr><th style="width:18%;text-align:left !important">User</th><th  style="width:22%;text-align:left !important">Date</th><th  style="width:60%;text-align:left !important">Description</th></tr>';
        lines.forEach(element => {
          const fields = element.split('~');
          result += '<tr><td alt="' + fields[0] + '" title="' + fields[0] + '" >' + fields[0] + '</td><td  alt="' + fields[1] + '  ' + fields[2] + '" title="' + fields[1] + '  ' + fields[2] + '" >' + fields[1] + '  ' + fields[2] + '</td><td  alt="' + fields[3] + '" title="' + fields[3] + '" >' + fields[3] + '</td></tr>';
        });
        result += '</table>';
      }
    }
    return result;
  }

  prepareCreateViewModel() {


    const modelCreate = <any>{};
    modelCreate.HFServiceCreatedOn = new Date();
    // modelCreate.HFServiceCreatedBy = this.currentUserId;
    modelCreate.HFServiceModifiedOn = new Date();
    // modelCreate.HFServiceModifiedBy = this.currentUserId;
    modelCreate.HFServiceStationId = this.stationID;
    modelCreate.HFServiceStationName = this.stationName;
    modelCreate.HFServiceVisitPurpose = 'Maintenance & Testing';
    modelCreate.HFServiceStatus = 'Not Started';
    modelCreate.HFServicelastModifiedSection = 'Pending';
    modelCreate.HFServiceCategory = 'Equipments Checks & Testing';
    modelCreate.HFServiceVisitDetailsEnteredOn = new Date();
    modelCreate.HFServiceVisitDetailsEnteredBy = this.currentUserId;
    modelCreate.TaskId = this.taskID;

    return modelCreate;
  }

  prepareCreateNotesViewModel() {
    const modelCreate = <any>{};
    modelCreate.id = this.notes.detailID;
    modelCreate.description = this.notes.Note;
    modelCreate.moduleName = 'taskDetails';
    modelCreate.createdBy = this.currentUserId;
    modelCreate.modifiedBy = this.currentUserId;

    return modelCreate;
  }

  prepareModel() {
    const model = <any>{};
    model.dialysisStationName = this.stationName;
    return model;
  }

  openNote(taskId) {
    if (this.notes && taskId) {
      this.notes.Note = '';
      this.notes.detailID = taskId;
      this.notes.AddNote();
    }
  }

  saveNote() {
    if (this.notes && this.notes.detailID) {
      let TaskId = this.notes.detailID;
      this.notes.showHours = false;

      const modelCreate = this.prepareCreateNotesViewModel();
      this.commonApi.CreateNotesDetails(modelCreate).subscribe(
        success => {
          this.notes.Note = '';
          this.notes.detailID = '';
          this.executeTaskSearch(false);
          AppComponent.addAlert('Task Notes updated successfully!', 'success');
          AppComponent.HideLoader();

        },
        error => {
          this.notes.Note = '';
          this.notes.detailID = '';
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        });
      // alert('TaskID' + TaskId + ' Note:' + this.notes.Note);
    }
  }

  // toggleStationList() {
  //   this.showStationList = !this.showStationList;
  //   //console.log(this.showStationList);
  // }

  openLatestForumMessages(): void {
    this.forumDialogRef = this.dialog.open(ForumMessagesDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: '70%'
    });
    this.forumDialogRef.afterClosed().subscribe(result => {
    });
  }


  onMonthYearChange() {
    var selectedMonth = parseInt(this.month) - 1;
    var monthDate = new Date(this.year, selectedMonth, 1);
    this.ucCalendar.fullCalendar('gotoDate', monthDate);
  }

  nextClick() {
    this.ucCalendar.fullCalendar('next');
  }

  prevClick() {
    this.ucCalendar.fullCalendar('prev');
  }

  monthViewClick(val) {
    this.ucCalendar.fullCalendar('changeView', val);
  }

  getViewType() {
    if (this.ucCalendar
       && this.ucCalendar['element'].nativeElement
      && this.ucCalendar['element'].nativeElement.classList
      && this.ucCalendar['element'].nativeElement.classList.contains('fc'))
      return this.ucCalendar.fullCalendar('getView').viewSpec.type;
    else
      return 'month';
  }

  enableDisableTask(event) {

    if (event == 'All Tasks') {
      this.showMyEvents = false;
      this.TaskSelectedValue = ['All Tasks'];
      // this.events = this.allevents;
    }
    else {
      this.showMyEvents = true;
      this.TaskSelectedValue = ['My Tasks'];
      // this.events = this.myevents;
    }
    this.ShowMyEventsFunc();
    // if (this.startDate._d < new Date() && this.endDate._d > new Date()) {

    //   this.SetDayEvents(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
    // } else {
    //   this.dayevents = [];
    // }
  }

}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}


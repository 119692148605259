<section class="content-header">
    <h1>
        {{title}}
        <!-- <sup *ngIf="title!=''" class="beta">
            [beta]
        </sup> -->


    </h1>


</section>
<section class="content">


    <section>
        <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->


        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]="true" [addurl]="AddUrl" #pageheader
                    [(searchText)]="searchText" [isSearchOpen]="ShowSearch"
                    (searchShowChange)="onSearchShowChange($event)" [showMainListing]="false"></PageHeader>
                <div id="divSearch row" *ngIf="ShowSearch">


                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Category:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control"
                                                        name="categoryId" [(ngModel)]="page.Category">
                                                        <option value="{{item.text}}"
                                                            *ngFor="let item of CategoryList">{{item.text}}</option>
                                                    </select>
                                            </div>


                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="col-sm-6 control-label">Type:</label>
                                                <div class="col-sm-6">
                                                    <select class="form-control" [(ngModel)]="page.SelectedType">
                                                        <option value="{{item.value}}" *ngFor="let item of TypeList">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                </div>


                                            </div>
                                            <div class="clearfix"></div>
                                        </div>

                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                   <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Product/Part ID:</label>
                                            <div class="col-sm-6">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    [(ngModel)]="page.ProductId"
                                                >
                                            </div>


                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Product/Part Name:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="page.ProductName">
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Description:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="page.ProductDesc">


                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="col-sm-6 control-label">Division:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="page.Division">
                                                    <option value="{{item.value}}" *ngFor="let item of DivisionList">
                                                        {{item.text}}
                                                    </option>
                                                </select>
                                            </div>


                                        </div>
                                        <div class="clearfix"></div>
                                    </div> -->

                                </div>
                            </div>
                        </div>

                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">

                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Vendor:</label>
                                            <div class="col-sm-6">
                                                <select class="form-control" [(ngModel)]="page.Vendor">
                                                    <option value="">Select</option>
                                                    <option value="{{item.contactId}}" *ngFor="let item of VendorList">
                                                        {{item.givenName}}
                                                    </option>
                                                </select>


                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-sm-6">
                                      <div>
                                          <label class="col-sm-6 control-label">Present Equipment Status:</label>
                                          <div class="col-sm-6">
                                              <select class="form-control" [(ngModel)]="page.Status">
                                                  <option value="{{item.value}}" *ngFor="let item of StatusList">
                                                      {{item.text}}
                                                  </option>
                                              </select>


                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12"></div>
                            </div>
                        </div>


                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button class="btn btn-primary" id="btnSearchDetail"
                                        (click)="executeInventorySearch()">
                                        <span>
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <b>Search</b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-6 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3 pull-right noBorderSearchPadding">

                                    <select class="form-control noBorderSearch" [(ngModel)]="page.Division"
                                        (change)="DialysisUnitDropDownChanged()">
                                        <!-- <option value="">Select</option> -->
                                        <option value="{{item.value}}" *ngFor="let item of DivisionList">
                                            {{item.text}}
                                        </option>
                                    </select>

                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}}  {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a style="cursor:pointer" (click)="executeInventorySearchClear()">
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>


                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr *ngFor="let InventoryDetail of rows;let index=index;"
                                    (click)="_onRowClick(InventoryDetail.equipementid)" class="pointer">
                                    <td matTooltip="{{InventoryDetail.equipmentdesc}}" matTooltipShowDelay="500">
                                        {{(page.pageSize*(page.currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td matTooltip="{{InventoryDetail.equipmentdesc}}">
                                        {{InventoryDetail.equipmentcode}}
                                    </td>
                                    <td matTooltip="{{InventoryDetail.equipmentdesc}}">
                                        {{ (InventoryDetail.equipmentname.length>50)? (InventoryDetail.equipmentname | slice:0:50)+'..':(InventoryDetail.equipmentname) }}
                                    </td>
                                    <td  matTooltip="{{InventoryDetail.equipmentdesc}}">{{InventoryDetail.categoryName}}</td>
                                    <td  matTooltip="{{InventoryDetail.equipmentdesc}}">{{InventoryDetail.model}}</td>
                                    <td  matTooltip="{{InventoryDetail.equipmentdesc}}">{{InventoryDetail.vendorName}}</td>
                                    <td  matTooltip="{{InventoryDetail.equipmentdesc}}">{{InventoryDetail.presentstaus}}</td>
                                    <td  matTooltip="{{InventoryDetail.equipmentdesc}}">
                                        {{(InventoryDetail.itemcost |
                                            currency:"CAD":"symbol-narrow")}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span>
                                        <b>
                                            <i>{{page.totalElements}}  {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i>
                                        </b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                        <a style="cursor:pointer" (click)="executeInventorySearchClear()">
                                            (
                                            <span>
                                                <i class="fa fa-trash-o"></i>
                                            </span>
                                            <b>Clear Search</b>
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { HomeFieldService } from 'src/app/API/homefield.service';
import { Component, ViewChild } from '@angular/core';
import { debug } from 'util';
import { AppComponent } from "../../app.component";
import { AuthService } from '../../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm, FormArray, UntypedFormBuilder } from '@angular/forms';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { map } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { Globals, actionType, NoteModuleName, attachmentFormModules } from '../../globals';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { HomeService } from 'src/app/API/home.service';
import { MachineHistoryDialog } from 'src/app/Core/MachineHistory/MachineHistoryDialog';
import { InvApiService } from '../../API/invapi.service';
import { DatePipe } from '@angular/common';
import { TableHeadsService } from '../../Core/tableheads.service';
import { HomeISVContactDetailComponent } from '../../Home/Home_ISV_Contact/HomeISVContactDetail.component';
import { any } from 'underscore';

@Component({
  selector: 'HomeStationDetail',
  templateUrl: './HomeStationDetail.component.html'
})

export class HomeStationDetailComponent implements CanComponentDeactivate {

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  // @ViewChild(HomeISVContactDetailComponent)
  // HomeISVContactDetail: HomeISVContactDetailComponent;

  nextStationIdPrefix: string = "";

  dialogRef: MatDialogRef<ConfirmationDialog>;
  loading = true;
  ListURL = "Home/HomeStationSearch";
  public message: string;
  lastDates = [];
  title = 'Home Station Detail'
  currentUserId = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  formSubmitted: boolean = false;
  validateForm: boolean = false;
  DivisionList = [];
  StationsList = [];
  dmEquipmentList = [];
  roEquipmentList = [];
  dmEquipmentListText = "";
  roEquipmentListText = "";
  blnshowSave = true;
  blnshowDelete = false;
  disabledEquipments = true;
  roPreFilterScheduleDate;
  homeStationHistoryList = [];
  hasMasterDateVaule = false;
  currentPageNumber = 1;
  sortExpression = "dialysisStationName";
  sortDirection = "ASC";
  ShowSearch = false;
  searchText = "Search Records"
  defaultSort;
  tableHeaders;
  MasterDate;
  showSave = false;
  ISVContactDetails: any;
  private today = new Date();
  homeProgramDivision: any;

  WeekList = [
    { value: "", text: "Select" },
    { value: "1", text: "1st" },
    { value: "2", text: "2nd" },
    { value: "3", text: "3rd" },
    { value: "4", text: "4th" }
  ];

  DayList = [
    { value: "", text: "Select" },
    { value: "2", text: "Monday" },
    { value: "3", text: "Tuesday" },
    { value: "4", text: "Wednesday" },
    { value: "5", text: "Thursday" },
    { value: "6", text: "Friday" },
    { value: "7", text: "Saturday" },
  ];

  dialysisStationForm = new UntypedFormGroup({
    dialysisUnitId: new UntypedFormControl('0'),
    divisionId: new UntypedFormControl(''),
    dialysisStationName: new UntypedFormControl('', Validators.required),
    ISVStationId: new UntypedFormControl(''),
    ISVAddress: new UntypedFormControl('', Validators.required),
    ISVCity: new UntypedFormControl('', Validators.required),
    ISVProvince: new UntypedFormControl('', Validators.required),
    ISVPostalCode: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.PostalcodeAlphaRegx)]),
    ISVPhoneNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.PhoneNumberRegex)]),
    ISVAccessCode: new UntypedFormControl(''),
    ISVDirections: new UntypedFormControl(''),
    dmEquipmentId: new UntypedFormControl(''),
    roEquipmentId: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    modifiedOn: new UntypedFormControl(''),
    DMItemCode: new UntypedFormControl(''),
    DMItemSerialNo: new UntypedFormControl(''),
    DMInServiceDate: new UntypedFormControl(''),
    ROItemCode: new UntypedFormControl(''),
    ROItemSerialNo: new UntypedFormControl(''),
    ROInServiceDate: new UntypedFormControl(''),
    ScheduleDate: new UntypedFormControl(''),
    Weekno: new UntypedFormControl(''),
    Dayno: new UntypedFormControl(''),
  });

  machineHistoryDialogRef: MatDialogRef<MachineHistoryDialog>;

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private commonapi: CommonApiService,
    private homeApi: HomeService,
    private invapi: InvApiService,
    private dataGridService: TableHeadsService,
    private homefieldApi: HomeFieldService,
    public dialog: MatDialog, private router: Router) { }



  ngOnInit() {
    AppComponent.loading = true;
    AppComponent.ShowLoader();
    this.notes.showHours = false;
    this.notes.Note = '';
    this.fillDialysisUnit();

    const test = this.route.queryParams.subscribe(params => {
      this.f.dialysisUnitId.setValue(params['id'] || '0');
      if (this.f.dialysisUnitId.value !== '0') {
        this.getISVStationDetails();
        this.fillDMEquipments();
        this.fillROEquipments();

        if (this.f.dialysisUnitId.value === '-1') {
          this.f.dialysisStationName.setValue('Home Pool');
          this.blnshowSave = false;
        } else {
          this.setModel();
          setTimeout(() => {
            this.EnableDisableEquipments();
          }, 500);
        }
        AppComponent.HideLoader();
      }
      else {
        // this.router.navigate([this.ListURL]);
        this.fetchNextStationID();
        AppComponent.HideLoader();
      }
    });
  }

  fillDialysisUnit() {
    this.commonapi.FillDivision().subscribe(list => {
      this.homeProgramDivision = list.filter(
        x => x.text === "Home Program"
      );

      if (this.homeProgramDivision.length > 0) {
        this.f.divisionId.setValue(this.homeProgramDivision[0].value);
      }
    });
  }

  fetchNextStationID() {
    this.homefieldApi.GetHomeFieldServiceNextStationID().subscribe(stationID => {
      this.nextStationIdPrefix = stationID;
    });
  }

  //implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      ((this.dialysisStationForm.dirty ||
        this.notes.Note !== '') && (this.validateForm == false))
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else { return true; }
  }

  getISVStationDetails(){
    this.homefieldApi.GetHomeFieldService_ISVStationDetails({ Id: this.f.dialysisUnitId.value }).subscribe(
      success => {

        if (success) {
          this.ISVContactDetails = success;

          this.f.ISVStationId.setValue(success.isvStationId);
          this.f.dialysisStationName.setValue(success.isvStationName);
          this.f.ISVPhoneNumber.setValue(success.isvPhoneNumber);
          this.f.ISVAddress.setValue(success.isvAddress);
          this.f.ISVProvince.setValue(success.isvProvince);
          this.f.ISVCity.setValue(success.isvCity);
          this.f.ISVPostalCode.setValue(success.isvPostalCode);
          this.f.ISVDirections.setValue(success.isvDirections);
          this.f.ISVAccessCode.setValue(success.isvAccessCode);
        }
      },
      error => {
        AppComponent.HideLoader();
      });
  }

  executeDialysisStationUpdate = function (Source = "") {

    AppComponent.ShowLoader();
    AppComponent.loading = true;
    this.formSubmitted = true;
    console.log(this.f);
    if (this.dialysisStationForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    //
    this.validateForm = true;
    const modelUpdate = this.prepareCreateViewModel();

    if (this.f.dialysisUnitId.value != '0'
    ) {
      //
      AppComponent.HideLoader();
      this.homeApi.UpdateDialysisStationDetails(modelUpdate).subscribe(
        success => {
          this.notes.Note = '';
          AppComponent.loading = false;
          AppComponent.HideLoader();
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {

            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      AppComponent.HideLoader();
      this.homeApi.InsertDialysisStationDetails(modelUpdate).subscribe(
        success => {

          this.f.dialysisUnitId.setValue(success.dialysisUnitId);
          this.f.dialysisStationName.setValue(success.dialysisStationName);

          this.f.dialysisStationName.clearValidators();
          this.f.dialysisStationName.updateValueAndValidity();

          this.notes.Note = '';
          AppComponent.loading = false;
          AppComponent.HideLoader();
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.getISVStationDetails();
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const modelUpdate = <any>{};
    modelUpdate.dialysisUnitId = this.f.dialysisUnitId.value;
    modelUpdate.divisionId = this.f.divisionId.value;
    if (this.f.dialysisUnitId.value == 0) {
      modelUpdate.dialysisStationName = this.nextStationIdPrefix + this.f.dialysisStationName.value.toUpperCase();
    } else {
      modelUpdate.dialysisStationName = this.f.dialysisStationName.value;
    }
    modelUpdate.ISVStationId = this.f.ISVStationId.value;
    modelUpdate.ISVPhoneNumber = this.f.ISVPhoneNumber.value;
    modelUpdate.ISVAddress = this.f.ISVAddress.value;
    modelUpdate.ISVProvince = this.f.ISVProvince.value;
    modelUpdate.ISVCity = this.f.ISVCity.value;
    modelUpdate.ISVPostalCode = this.f.ISVPostalCode.value;
    modelUpdate.ISVDirections = this.f.ISVDirections.value;
    modelUpdate.ISVAccessCode = this.f.ISVAccessCode.value;

    modelUpdate.createdBy = this.currentUserId;
    modelUpdate.modifiedBy = this.currentUserId;

    modelUpdate.dmEquipmentId = this.f.dmEquipmentId.value;
    modelUpdate.roEquipmentId = this.f.roEquipmentId.value;
    modelUpdate.Weekno = this.f.Weekno.value;
    modelUpdate.Dayno = this.f.Dayno.value;
    // const selectedDMEquipment = this.dmEquipmentList.filter(
    //   (item, index) => item.value === this.f.dmEquipmentId.value
    // );
    // if (selectedDMEquipment.length > 0) {
    //   modelUpdate.dmEquipmentName = selectedDMEquipment[0].text;
    // }

    // const selectedROEquipment = this.roEquipmentList.filter(
    //   (item, index) => item.value === this.f.roEquipmentId.value
    // );
    // if (selectedROEquipment.length > 0) {
    //   modelUpdate.roEquipmentName = selectedROEquipment[0].text;
    // }

    modelUpdate.notes = this.notes.Note;
    modelUpdate.modifiedBy = this.currentUserId;
    modelUpdate.modifiedOn = this.today;
    //console.log(modelUpdate);
    return modelUpdate;
  }

  get f() {
    return this.dialysisStationForm.controls;
  }

  roChange() {
    this.fillROValues(this.f.roEquipmentId.value);
  }

  dmChange() {
    this.fillDMValues(this.f.dmEquipmentId.value);
  }

  setModel() {

    AppComponent.ShowLoader();
    this.homeApi.GetDialysisStationByID({ Id: this.f.dialysisUnitId.value }).subscribe(
      success => {
        if (success) {

          this.f.dialysisUnitId.setValue(success.dialysisUnitId);
          this.f.divisionId.setValue(success.divisionId);
          this.f.dialysisStationName.setValue(success.dialysisStationName);
          this.f.dmEquipmentId.setValue(success.dmEquipmentId ? success.dmEquipmentId : '');
          this.f.roEquipmentId.setValue(success.roEquipmentId ? success.roEquipmentId : '');
          this.f.divisionId.setValue(success.divisionId);
          this.f.createdBy.setValue(success.createdBy);
          this.f.modifiedBy.setValue(success.modifiedBy);

          this.fillNotes();
          this.fillDMValues(this.f.dmEquipmentId.value);
          this.fillROValues(this.f.roEquipmentId.value);
          //console.log(success);
          this.f.Weekno.setValue(success.weekno);
          this.f.Dayno.setValue(success.dayno);
          this.MasterDate = "";

          if(success.dayno != null && success.dayno != '')
          {
            this.hasMasterDateVaule =true;
            const dy =  this.DayList.filter((item) => item.value == success.dayno);
            const wk =  this.WeekList.filter((item) => item.value == success.weekno);
            this.MasterDate = wk[0].text + ' ' + dy[0].text;
            // this.f.Weekno.setValidators([Validators.required]);
            // this.f.Weekno.updateValueAndValidity();
            // this.f.Dayno.setValidators([Validators.required]);
            // this.f.Dayno.updateValueAndValidity();
          }
          if (this.f.dmEquipmentId.value && this.f.roEquipmentId.value)
            this.fillOtherLastDateValues(this.f.dialysisUnitId.value, this.f.roEquipmentId.value, this.f.dmEquipmentId.value);

          if (success.status == "InActive") {
            this.blnshowSave = false;
          }

        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

    // this.homeApi.GetHomeStationHistoryDetails({ Id: this.f.dialysisUnitId.value }).subscribe(
    //   list => {

    //     this.homeStationHistoryList = list;
    //     for(var item in list)
    //     {
    //       if(list[item].hfServiceStatus == 'Completed')
    //       {
    //         list[item].hfServiceStatus = 'C';
    //       }
    //       else if(list[item].hfServiceStatus == 'In Progress')
    //       {
    //         list[item].hfServiceStatus = 'IP';
    //       }
    //       else if(list[item].hfServiceStatus == 'Pending')
    //       {
    //         list[item].hfServiceStatus = 'P';
    //       }
    //       else if(list[item].hfServiceStatus == 'Not Started')
    //       {
    //         list[item].hfServiceStatus = 'NS';
    //       }
    //     }
    //   },
    //   error => {
    //     AppComponent.addAlert(error.message, 'error');
    //     AppComponent.HideLoader();
    //   }
    // );
  }

  fillDMValues(EquipId) {
    const inventory = {
      Id: EquipId
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {

          this.f.DMItemSerialNo.setValue(success.serialnumber);
          this.f.DMItemCode.setValue(success.equipmentcode);
          this.f.DMInServiceDate.setValue(success.inservicedate);

          //   if(success.inservicedate!=null)
          //   {
          //   const tempDate = new Date(success.inservicedate);

          //   this.f.DMInServiceDate.setValue({
          //     date: {
          //       year: tempDate.getFullYear(),
          //       month: tempDate.getMonth() + 1,
          //       day: tempDate.getDate()
          //     },
          //     jsdate: success.inservicedate
          //   });
          // }
        }
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
      }
    );
  }

  fillROValues(EquipId) {
    const inventory = {
      Id: EquipId
    };

    this.invapi.GetInventoryByID(inventory).subscribe(
      success => {
        if (success) {

          this.f.ROItemSerialNo.setValue(success.serialnumber);
          this.f.ROItemCode.setValue(success.equipmentcode);
          this.f.ROInServiceDate.setValue(success.inservicedate);

          //   if(success.inservicedate!=null)
          //   {
          //   const tempDate = new Date(success.inservicedate);

          //   this.f.ROInServiceDate.setValue({
          //     date: {
          //       year: tempDate.getFullYear(),
          //       month: tempDate.getMonth() + 1,
          //       day: tempDate.getDate()
          //     },
          //     jsdate: success.inservicedate
          //   });
          // }
        }
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
      }
    );
  }


  fillOtherLastDateValues(staionId, roEquipmentId, dmEquipmentId) {
    const datesModel = {
      DialysisUnitId: staionId,
      ROEquipmentId: roEquipmentId,
      DMEquipmentId: dmEquipmentId
    };

    this.homeApi.GetHome_Station_LastCompletedDates(datesModel).subscribe(
      success => {
        if (success) {

            this.lastDates= success;
        }
      },
      error => {
        if (error.error && error.error.returnMessage)
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        else
          AppComponent.addAlert(error.message, 'error');
      }
    );
  }


  fillNotes() {
    this.homeApi.GetDialysisStationNotes({ Id: this.f.dialysisUnitId.value }).subscribe(list => {

      // list.forEach(function (note, newKey) {
      //   if (note.moduleName == "homeFieldService_Master" && note.description.lastIndexOf('Completed.') > 0)
      //     note.description += '  <span><i class="fa fa-file"></i></span>';

      // }, this);

      this.notes.NoteList = list;
    });
  }


  fillDMEquipments() {
    const obj = {
      DialysisStationId: this.f.dialysisUnitId.value,
      CategoryName: 'Dialysis Machines'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
      this.dmEquipmentList = list;
      this.dmEquipmentListText = this.getListToString(list);
    });
  }

  fillROEquipments() {
    const obj = {
      DialysisStationId: this.f.dialysisUnitId.value,
      CategoryName: 'Water Process'
    };
    this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {

      this.roEquipmentList = list;
      this.roEquipmentListText = this.getListToString(list);
    });
  }

  getListToString(list) {
    return Array.prototype.map.call(list, function (item) { return item.text; }).join(", ");
  }

  EnableDisableEquipments() {
    if (this.disabledEquipments) {

      if(this.f.dmEquipmentId.value == ''){
        this.f.dmEquipmentId.enable();
      } else {
        this.f.dmEquipmentId.disable();
      }

      if(this.f.roEquipmentId.value == ''){
        this.f.roEquipmentId.enable();
      } else {
        this.f.roEquipmentId.disable();
      }


      if(this.f.dmEquipmentId.value == '' || this.f.roEquipmentId.value == ''){
        this.showSave = true;
      } else {
        this.showSave = false;
      }
      // this.f.dmEquipmentId.disable();
      // this.f.roEquipmentId.disable();
    }
    else {
      this.f.dmEquipmentId.enable();
      this.f.roEquipmentId.enable();
    }
    this.disabledEquipments = !this.disabledEquipments;
  }

  OpenHistory(EquipId, serialnumber, equipmentcode, inservicedate) {

    if (EquipId) {
      this.machineHistoryDialogRef = this.dialog.open(MachineHistoryDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
      });

      this.machineHistoryDialogRef.componentInstance.EquipId = EquipId;
      this.machineHistoryDialogRef.componentInstance.SrNo = serialnumber;
      this.machineHistoryDialogRef.componentInstance.MachineName = equipmentcode;
      this.machineHistoryDialogRef.componentInstance.InServiceDate = inservicedate;
      this.machineHistoryDialogRef.componentInstance.dialogRef.updateSize(
        '95%',
        '95%'
      );
      this.machineHistoryDialogRef.afterClosed().subscribe(result => {
        this.machineHistoryDialogRef = null;
      });
    }
    else {
      AppComponent.addAlert('Select Equipment to display History', 'error');
    }
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
  }

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  }



  forceInputUppercase(e) {
    this.commonapi.forceInputUppercase(e);
  }

  lostfocusPostalCode() {
    this.f.ISVPostalCode.setValue(this.f.ISVPostalCode.value.toUpperCase().replace(/\s/g, ''));
    if (this.f.ISVPostalCode.value.indexOf(' ') == -1 && this.f.ISVPostalCode.value.length == 6) {
      let First3 = this.f.ISVPostalCode.value.slice(0, 3);
      let Last3 = this.f.ISVPostalCode.value.slice(3, 6);

      this.f.ISVPostalCode.setValue(First3 + ' ' + Last3);
    }
  }



  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  charOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) {
      return true;
    }
    return false;
  }

  formatPhone(phone) {
    let val = this.f.ISVPhoneNumber.value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    this.f.ISVPhoneNumber.setValue(val);
  }

}

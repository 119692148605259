<div class="sidenav right" *ngIf="ShowEventDetailR == true" [innerHtml]='sanitizer.bypassSecurityTrustHtml(RSideContet)'></div>
<div class="sidenav left" *ngIf="ShowEventDetailL == true" [innerHtml]='sanitizer.bypassSecurityTrustHtml(LSideContet)'></div>
 <section class="content-header">
   <h1 *ngIf="!showNewMessageLink" >{{title}}</h1>
   <h1 class="col-sm-6" *ngIf="showNewMessageLink" >
            {{title}}
            <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
            <!-- <sup  class="beta"><img (click)="openLatestForumMessages()" src="../../assets/img/new16.gif" style="margin-top: 8px;cursor:pointer" width="40"></sup> -->

    </h1>
    <span class="blinking" (click)="openLatestForumMessages()" *ngIf="showNewMessageLink" >
    <!-- <span> -->
    <i class="fa fa-bell" aria-hidden="true"></i>
    New Messages
    <!-- </span> -->
  </span>

</section>
<section class="content">

<section >
  <!-- <section class="content-header">
      <h1>
          {{title}}

      </h1>
      <ol class="breadcrumb">
          <li><a href="Dashboard"><i class="fa fa-home"></i> Home</a></li>
          <li style="color:red;">Options</li>
      </ol>
  </section> -->



      <div class="row">
          <!-- /.box -->
          <div class="box  box-primary">
            <div  class="no-print">
              <div class="box-header pageheader row">

                      <div class="col-sm-12 text-right">



                           <!-- <ng-multiselect-dropdown style="float:right;padding-left:10px;min-width:250px;"
                           [placeholder]="'Select Division(s)'"
                           [data]="DivisionList"
                           [(ngModel)]="selectedDivisions"
                           [settings]="dropdownSettings"
                           (onSelect)="onItemSelect($event)"
                           (onSelectAll)="onItemSelectAll($event)"
                           (onDeSelect) ="onItemSelect($event)"
                         ></ng-multiselect-dropdown> -->
                      </div>
                  </div>
      </div>
      <div class="clearfix"></div>

      <!-- <div class="box-body station-collapse" [ngClass]="{'box-body':true,'station-collapse': !showStationList, 'station-expand': showStationList }" id="dvboxbody" >

        <div class="row " *ngIf="stationList?.length > 0" id="stationList" >
                <div  class="stationConatainer col-sm-9">

                    <div class="row" >
                            <ul class="stationList col-sm-12">
                                    <li  #customevents class="station" *ngFor="let station of stationList">
                                       <div id={{station.dialysisUnitId}} class='fc-event'>{{ station.dialysisStationName }}</div>
                                    </li>
                                </ul>
                    </div>
                </div>
                <div class="col-sm-1 stationbutton">
                    <div class="station-collapse-btn" (click)="toggleStationList()" *ngIf="showStationList"><i class="fa fa-angle-up ng-star-inserted"></i></div>
                    <div class="station-expand-btn" (click)="toggleStationList()" *ngIf="!showStationList"><i class="fa fa-angle-down ng-star-inserted"></i></div>
                </div>

        </div>
      </div> -->
              <!-- <div class="clearfix"></div> -->

              <div class="box-body" id="dvboxbody">

                <div class="row">
                        <div class="col-sm-9">


                          <div *ngIf="calendarOptions">
                              <div class="fc fc-unthemed fc-ltr">
                                    <div class="fc-toolbar fc-header-toolbar">
                                        <div class="fc-left">
                                            <div class="fc-button-group">
                                                <button type="button" class="fc-prev-button fc-button fc-state-default fc-corner-left" (click)="prevClick()">
                                                    <span class="fc-icon fc-icon-left-single-arrow"></span>
                                                </button>
                                                <button type="button" class="fc-next-button fc-button fc-state-default fc-corner-right" (click)="nextClick()">
                                                    <span class="fc-icon fc-icon-right-single-arrow"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="fc-right">
                                            <div class="fc-button-group">
                                                <button type="button" class="fc-month-button fc-button fc-state-default fc-corner-left" [ngClass]="{'fc-state-active': getViewType() === 'month'}" (click)="monthViewClick('month')">Month View</button>
                                                <button type="button" class="fc-listMonth-button fc-button fc-state-default fc-corner-right" (click)="monthViewClick('listMonth')" [ngClass]="{'fc-state-active': getViewType() === 'listMonth'}">List View</button>
                                            </div>
                                        </div>
                                        <div class="fc-center" style="width: 30%;">
                                            <h1></h1>
                                                <select class="form-control noBorderSearch" [(ngModel)]="month" name="month" (change)="onMonthYearChange()" style="	width: auto;
                                                                font-size: 15px;
                                                                text-align-last: center;
                                                                padding-right: 0px;">
                                                    <option value="{{item.value}}" *ngFor="let item of MonthList" style="direction: rtl; ">
                                                         {{item.text}}
                                                    </option>
                                                </select>

                                                <select class="form-control  noBorderSearch" [(ngModel)]="year" name="year" (change)="onMonthYearChange()" style="	width: auto;
                                                                font-size: 15px;
                                                                text-align-last: center;
                                                                padding: 6px 5px;">
                                                    <option value="{{item.value}}" *ngFor="let item of YearList" style="direction: rtl; ">
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                        </div>
                                        <div class="fc-clear"></div>
                                    </div>
                              </div>
                                <ng-fullcalendar #ucCalendar [options]="calendarOptions"
                                 (eventMouseOver)="mouseOver($event)"
                                 (eventMouseOut)="mouseOut($event)"
                                 (eventRender)="eventRender($event)"
                                (eventDrop)="drop($event)"
                                 (dayClick)="clickDay($event, $jsEvent, $view)"
                                (viewRender) = "initialized($event)"
                                 (eventClick)="eventClick($event.detail)"

                                  [(eventsModel)]="events"></ng-fullcalendar>
                        </div>
                        </div>
                        <div class="col-sm-3" id="sidebox">
                        <div class="col-sm-12 box-row">
                        <div class="col-sm-5" style="padding-right: 0px;">
                            <a href="javascript:void(0)"  (click)="RouteClick(AddUrl)" style="width:100%;"  class="btn btn-primary"
                             id="btnAddNew"> <i class="fa fa-plus"></i> &nbsp; Add New </a>
                        </div>
                        <div class="col-sm-6 col-sm-offset-1">
                                <!-- <mat-button-toggle-group [popover]="myPopover" [popoverOnHover]="true" [(ngModel)]="showMyEvents"
                                style="width:100%" (change)="ShowMyEventsFunc()"
                                multiple #group="matButtonToggleGroup">
                                 <mat-button-toggle style="width:100%"  class="btnsuccess" value="true">
                                   My Tasks
                                 </mat-button-toggle>
                                </mat-button-toggle-group> -->

                            <mat-button-toggle-group multiple [value]="TaskSelectedValue">
                                <mat-button-toggle *ngFor="let item of TaskOptions" style="font-weight: normal;"
                                (change)="enableDisableTask(item.name)"  value="{{item.name}}"
                                [ngClass]="{'toggle-switch-success':item.name === 'All Tasks' && TaskSelectedValue[0] == 'All Tasks',
                                'toggle-switch-danger': item.name === 'My Tasks' && TaskSelectedValue[0] == 'My Tasks' }">
                                    {{item.name}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
       <!-- <div class="col-sm-4">
        <mat-button-toggle-group [popover]="myPopover" [popoverOnHover]="true" [(ngModel)]="showSchedule"
        style="width:100%"
        multiple #group="matButtonToggleGroup">
         <mat-button-toggle style="width:100%"  class="btnsuccess" value="true">
           Schedule
         </mat-button-toggle>

</mat-button-toggle-group></div> -->
                                </div>

                        <div class="col-sm-12 box-row noBorderSearchPadding">
                            <select
                                                 class="form-control noBorderSearch"
                                                 [(ngModel)]="selectedDivisions"
                                                 name="selectedDialysisUnits"
                                                 (change)="onItemSelect()" style="width:100%"
                                             >
                                             <option value=''>All Divisions</option>
                                                 <option
                                                     value="{{item.value}}"
                                                     *ngFor="let item of DivisionList"
                                                 >
                                                     {{item.text}}
                                                 </option>
                                             </select>
                                            </div>
                                            <div class="tasklist">
                                            <div class="col-sm-12" style="margin:0px;padding:0px;">
                                                    <div
                                                    *ngFor="let item of GetDivisionList()">
                                                <div class="col-sm-12 box-row box-header" *ngIf="GetDivisionEventCount(item.text) > 0">
                                                    {{item.text}}
                                                </div>
                                                <div class="col-sm-12 box-row" *ngIf="GetDivisionEventCount(item.text) > 0">

                                                            <div #dayevents
                                                            class="fc-event-container" style="margin-left: 3px;margin-right: 2px;"
                                                            *ngFor="let event of GetDayList(item.text)"
                                                        >

                                                        <a id="{{'lnk' + event.tasktype +event.id}}"
                                                        (mouseover)="enter(event)"
                                                        (mouseout)="leave()" class="fc-day-grid-event fc-h-event fc-event fc-start fc-end"
                                                        (click)="OpenEventData(event.tasktype,event.id)"

                                                        [ngStyle]="{'background-color':event.backgroundColor,'border-color':event.borderColor,'color':event.textColor}"
                                                        >
                                                        <!-- <div class="popuptext"
                                                        [style.top.px]="poptop"
                                                        [style.left.px]="popleft"
                                                        id="{{'myPopupr' + event.tasktype +event.id}}" [innerHtml]="event.EventHTML">  </div> -->
                                                        <div class="fc-content"><span class="fc-title">{{event.titlewithstatus}}</span></div></a>
                                                    </div>
                                                    </div>
                                                    </div>

                                            </div>
                                        </div>
                        </div>

                    </div>
              </div>
          </div>
      </div>
      <notes style="display:none"
      (addNoteClick)="saveNote()" #notes></notes>
  </section>


</section>

import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit,ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartsComponent } from '../Shared/PartsControl/PartsComponent';

@Component({
  selector: 'hfs-note-dialog',
  templateUrl: './HFSNoteDialog.html',
  styleUrls: ['./HFSNoteDialog.css']
})
export class HFSNoteDialog implements AfterViewInit {
  constructor(public dialogRef: MatDialogRef<HFSNoteDialog>) {
    //dialogRef.updateSize('700px', 'auto');
  }

  public showHours = true;
  public showParts = false;
  public PrevParts = [];
  public PrevNotes = [];

  VehicleName = '';
  notes = '';
  hours = '';
  errormsg = '';
  @ViewChild(PartsComponent, { static: false })
  NoteParts: PartsComponent;
  @Output() SaveEvent = new EventEmitter<string>();
  @ViewChild('noteId', { static: true }) inputEl: ElementRef;
  public API;
  public DivisionId;
  public EquipementId;
  public CurrentUserId;
  public partscategoryname;
  public status;
  ngOnInit() {
    // if (this.showParts === true) {
    //   this.dialogRef.updateSize('90%', 'auto');
    // }
  }

  ngAfterViewInit() {
    if (this.showParts === true) {
      this.NoteParts.API = this.API;
      this.NoteParts.SelectedDialysisUnits = this.DivisionId;
      this.NoteParts.SelectedEquipment = this.EquipementId;
      this.NoteParts.currentUserId = this.CurrentUserId;
      this.NoteParts.partscategoryname = this.partscategoryname;
      this.NoteParts.status = this.status;
      this.NoteParts.PrevParts = this.PrevParts;
    }
    setTimeout(() => {this.inputEl.nativeElement.focus()},500);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showSaveLink(mainIndex,item,PrevNotes)
  {
    let opt = false;
    PrevNotes.slice().reverse().map(item => item.description).filter((value, index, self) =>{
      if(self.indexOf(value) === mainIndex)
        opt= true;
    });
    return opt;

    // if Only first note enable then comment above lines and uncomment below line
    //return mainIndex == 0;
  }

  AddNotesToTextBox(description,index)
  {

    document.getElementById(index).className="fa fa-plus headerMenu";
    document.getElementById(index).style.pointerEvents = "none";
    this.notes += description + ', ';
    this.notes.substring(0,this.notes.length -2);
  }


  save(description) {

    if(this.notes.lastIndexOf(", ") > 0)
    this.notes =  this.notes.substring(0,this.notes.length -2);

    this.errormsg = '';
    if (this.showHours === true && this.hours === '') {
      this.errormsg = 'Hours Required';
      return false;
    }
    if (this.showHours === true && this.hours.match(/^(\d*)(\.\d{1,2})?$/) == null) {
      this.errormsg = '(##.##) Format';
      return false;
    }
    if (this.notes === '') {
      this.errormsg = 'Note Required';
      return false;
    }

    if (this.showParts === true) {
      if (this.NoteParts.PartSearchTerm != '') {
        this.errormsg = 'Please add selected parts';
        return false;
      }
    }

    if (this.showHours === true) {
      this.SaveEvent.emit(this.notes + '|' + this.hours);
    } else {
      this.SaveEvent.emit(this.notes);
    }
    this.dialogRef.close(true);
  }
  public confirmMessage: string;
}

import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { InvApiService } from '../API/invapi.service';
import { CommonApiService } from '../API/commonapi.service.';


// create your class that extends the angular validator class
export class InventoryCustomValidators  {
  static InventoryItemIDValidate(commonapi:CommonApiService, equipmentCode : string, equipmentType :string){
    return (control: AbstractControl) => {

    if(equipmentCode != '' && equipmentType != '')
    {
        var inventoryCreate = {code:equipmentCode, type:equipmentType};
        return commonapi.GetItemIdByInventory(inventoryCreate).pipe(map(res => {

            return res==true ? null : { ItemID: true };
            //return {false res };
           }));
          };
    }
  }
}


import { Component, ViewChild, Input } from '@angular/core';
import { DMApiService } from 'src/app/API/dmapi.service';
import { TableHeadsService } from '../../tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';


@Component({
  selector: 'PreventiveMaintenanceCommonSearch',
  templateUrl: './PreventiveMaintenanceCommonSearch.component.html',

})
export class PreventiveMaintenanceCommonSearchComponent {

  DMPMDetails = [];
  constructor(private dmapi: DMApiService,
    private commonapi : CommonApiService,
    private auth: AuthService,    
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }


  
@Input() detailsType  ="";
@Input() headerText = "Home Preventive Maintenance";
@Input() AddUrl = "Home/PreventiveMaintenance";

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  // AddUrl = "/DialysisMachine/PreventiveMaintenance";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch = false;
  // title = "Dialysis Machine Preventive Maintenance";
  searchText = "Search Records"
  defaultSort;


  //Search Options //
  PMCode = '';
  selectedDivision = 'All Divisions';
  disableDivision=false;
  selectedEquipment = '';
  dateIn;
  hoursIn = '';
  inHydraulicDisinfection = '';
  inSurfaceDisinfection = '';
  selectedStatus = '';

  DivisionList = [];
  EquipmentList = [];
  StatusList = [];
  insurfacedisOptions = [
    { value: "", text: "Select" },
    { value: "CaviWipes XL", text: "CaviWipes XL"},
    { value: "Virox", text: "Virox" },
    { value: "N/A", text: "N/A" }
  ];

  inhydraulicdisOptions = [
    { value: "", text: "Select" },
    { value: 'Degreasing', text: 'Degreasing' },
    { value: 'Heat Cleaned', text: 'Heat Cleaned' },
    {value: 'N/A', text: 'N/A'}
  ];

  //Search Options //

  ngOnInit() {

    this.auth.GetCurrentUserDivision().subscribe(division => {

    //AppComponent.loading=true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#", "PMCode", "3");
    this.dataGridService.addHeader("PM Code", "PMCode", "9");
    this.dataGridService.addHeader("Division", "divisionname", "14");
    this.dataGridService.addHeader("Equipment", "equipmentName", "10");
    this.dataGridService.addHeader("Date In", "dateIn", "8");
    this.dataGridService.addHeader("Hours In", "hoursIn", "8");
    this.dataGridService.addHeader("In Hydraulic Disinf.", "inHydraulicDisinfection", "13");
    this.dataGridService.addHeader("In Surface Disinf.", "inSurfaceDisinfection", "13");
    this.dataGridService.addHeader("Date Out", "dateOut", "8");
    this.dataGridService.addHeader("Hours Out", "hoursOut", "8");
    this.dataGridService.addHeader("Tech", "createdByName", "10");
    this.dataGridService.addHeader("Status", "status", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("default", true);
    // this.executePreventiveSearch();
    // this.fillDropDowns();
    if(division.length===1)
        this.fillDropDowns(division[0]);
      else
        this.fillDropDowns();

    this.CheckDirtySearch();

  }, error => {
    AppComponent.addAlert(error.message, 'error');
    AppComponent.HideLoader();
  });
  }



  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executePreventiveSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executePreventiveSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";


    this.CheckDirtySearch();

    var dmpmsearch = this.prepareSearch();

    this.dmapi.GetDMPMSearchDetails(dmpmsearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      // console.log(pagedData);
      this.rows = pagedData.dmpmDetails;
      AppComponent.HideLoader()
    }
      ,
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {

    var dmpmsearch = <any>{};
    dmpmsearch.PMCode = this.PMCode;
    
    if (this.detailsType.includes("Home")) {
        this.selectedDivision = "Home Program";
        this.disableDivision = true;
    }
    

    if (this.selectedDivision != undefined && this.selectedDivision != "" && this.selectedDivision != '0' && this.selectedDivision != "All Divisions") {
      dmpmsearch.Division = this.selectedDivision;
    }

    if (this.selectedEquipment != undefined && this.selectedEquipment != "") {
      dmpmsearch.EquipmentId = this.selectedEquipment;
    }

    if (this.dateIn != undefined && this.dateIn != "") {
      dmpmsearch.dateIn = Globals.GetAPIDate(this.dateIn.jsdate);
    }

    dmpmsearch.hoursIn = this.hoursIn;

    if (this.inHydraulicDisinfection != undefined && this.inHydraulicDisinfection != "") {
      dmpmsearch.inHydraulicDisinfection = this.inHydraulicDisinfection;
    }
    if (this.inSurfaceDisinfection != undefined && this.inSurfaceDisinfection != "") {
      dmpmsearch.inSurfaceDisinfection = this.inSurfaceDisinfection;
    }

    if (this.selectedStatus != undefined && this.selectedStatus != "") {
      dmpmsearch.Status = this.selectedStatus;
    }

    // dmpmsearch.type = this.detailsType;
    dmpmsearch.currentPageNumber = this.currentPageNumber;
    dmpmsearch.sortExpression = this.sortExpression;
    dmpmsearch.sortDirection = this.sortDirection;
    dmpmsearch.pageSize = this.pageSize;
    return dmpmsearch;

  }

  setPage() {
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber = this.page.pageNumber;
    this.executePreventiveSearch();
    }
  }

  _onRowClick(row,status,isUploadHistory) {
    if (status == "Completed" && isUploadHistory == true)
    {
      var href = '';
      this.dmapi.PrintPreventivePDF({ Id: row }).subscribe(list => {
        href = Globals.AttachmentUrl + list;
        window.open(href,'_blank');
    }, error => {
        console.log(error);
    })
    }
    else
    {
      this.router.navigate([this.AddUrl], { queryParams: { id: row } });
    }
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect:false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }



  //Search & Select//

  fillDropDowns(division='') {
    this.fillDivisions(division);
    if(division)
      this.fillEquipments(division);
    else
      this.fillEquipments();
    this.fillStatus();
  }


  fillDivisions(defaultValue='') {
    this.commonapi.FillDivision(true).subscribe(list =>{ 
      this.DivisionList = list;
    
      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          this.executePreventiveSearch();
        }
        else
          this.executePreventiveSearch();
      }
      else
          this.executePreventiveSearch();
      // if(!this.detailsType.includes('Home')){
      //   this.DivisionList = list.filter(
      //     x => x.text != "Home Program"
      //   );      
      // }
    });
  }

  fillEquipments(division='0') {
    this.dmapi.GetEquipmentDD(division).subscribe(list => this.EquipmentList = list);
  }

  fillStatus() {
    this.StatusList = [
      { value: "", text: "Select" },
      { value: "In Progress", text: "In Progress" },
      { value: "Completed", text: "Completed" }
    ];

  }


  executePreventiveSearchClear() {

    this.PMCode = '';

    if(!this.detailsType.includes("Home"))
      this.selectedDivision = 'All Divisions';

    this.selectedEquipment = '';
    this.dateIn = '';
    this.hoursIn = '';
    this.inHydraulicDisinfection = '';
    this.inSurfaceDisinfection = '';
    this.selectedStatus = '';

    // this.executePreventiveSearch();
    this.fillDivisions();
    this.fillEquipments();
  }


  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.PMCode != ""
      || (!this.detailsType.includes("Home") &&  this.selectedDivision != "" && this.selectedDivision !== 'All Divisions') 
      || this.selectedEquipment != ""
      || this.selectedStatus != ""
      || (this.dateIn != null && this.dateIn != '')
      || this.hoursIn != ''
      || this.inHydraulicDisinfection != ''
      || this.inSurfaceDisinfection != '') {

      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  //Search & Select //

  CheckFormDate(fdate, edate) {
    return this.commonapi.CompareDates(fdate, edate);
  }

  DialysisUnitDropDownChanged() {
    this.selectedEquipment ="";
    let defaultVal = this.DivisionList.filter(
      x => x.text == this.selectedDivision
    );

    if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
      this.fillEquipments(defaultVal[0].value);
    }
    else{
      this.fillEquipments();
    }
    setTimeout(()=>this.executePreventiveSearch());
  }

}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

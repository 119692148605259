import { DecimalPipe, DatePipe } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { WPApiService } from 'src/app/API/wpapi.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Globals } from '../../globals';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  // moduleId: module.id,
  selector: 'BioPureHX2DataLogs',
  templateUrl: 'BioPureHX2DataLogs.component.html',
  styleUrls: ['BioPureHX2DataLogs.component.css']
})

export class BioPureHX2DataLogsComponent {
  title =  'BioPure HX2 Data Logs';

  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  private router: Router,
  private wpapi: WPApiService,
  public dialog: MatDialog,
  private dataGridService: TableHeadsService,
  private dataGridServiceDisinfection: TableHeadsService

) { }

timer:any;
tableHeaders;
defaultSort;
tableHeadersDisinfection;
defaultSortDisinfection;
sortExpression = 'createdOn';
sortDirection = 'DESC';
sortDirectionDisinfection = 'ASC';
lstHX2AlarmEventData = [];
private today = new Date();
allClearValue = "ALL";
dropdownSettings;
type = 'Fields';

HX2DataLogsForm = new UntypedFormGroup({
  AlarmDate: new UntypedFormControl(''),
  fieldName: new UntypedFormControl(''),
  ALLfieldName: new UntypedFormControl(''),
  HX2Fields: new UntypedFormControl('')
});

public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect: false,
  closeSelectorOnDocumentClick: true,
  disableSince: {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
    day: this.today.getDate() + 1
  }
};

onDateChanged(event: IMyDateModel): void {
  setTimeout(() => {
    this.sortExpression = 'createdOn';
    this.sortDirection = 'DESC';
    this.sortDirectionDisinfection = 'ASC';

    this.FetchAndSetValues();
  }, 500);
  // date selected
}

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

  RouteClick(value) {
    this.router.navigate([value]);
  }

  get f() {
    return this.HX2DataLogsForm.controls;
  }


ngOnInit() {
  setTimeout(() => {
    this.fillHX2Fields();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'fieldName',
      textField: 'fieldLabelName',
      itemsShowLimit: 2,
      allowSearchFilter: false,
      enableCheckAll:false
    };

  const tempDate = this.today;
            this.f.AlarmDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
//

this.SetOption(this.type);
  AppComponent.ShowLoader();
  this.FetchAndSetValues();
  // AppComponent.HideLoader();
  // this.timer = setInterval(() => {
  //   this.FetchAndSetValues();
  // }, 60000);
  }, 500);
}


changeSorting(column) {
//
  if (column === '#') {
      return;
  }
  if(this.type !== 'Disinfection'){
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);

    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
  }
  else {
    this.dataGridServiceDisinfection.changeSorting(column, this.defaultSortDisinfection, this.tableHeadersDisinfection);

    this.defaultSortDisinfection = this.dataGridServiceDisinfection.getSort();
    this.sortDirectionDisinfection = this.dataGridServiceDisinfection.getSortDirection();
    this.sortExpression = this.dataGridServiceDisinfection.getSortExpression();
  }
    this.FetchAndSetValues();

}

ngOnDestroy() {
  clearInterval(this.timer);
}


FetchAndSetValues(){
  // this.spinner.show();
  AppComponent.ShowLoader();
  const  HX2DataLogsSearch = this.prepareSearch();

  if(this.type !== 'Disinfection'){
  this.wpapi.FetchHX2DataLogs(HX2DataLogsSearch).subscribe(
    success => {

      this.lstHX2AlarmEventData = success;

    AppComponent.HideLoader();
    // this.spinner.hide();
    },
    error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    }
  );
} else {
  this.wpapi.FetchHX2Disinfection(HX2DataLogsSearch).subscribe(
    success => {

      this.lstHX2AlarmEventData = success;

    AppComponent.HideLoader();
    // this.spinner.hide();
    },
    error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    }
  );
}
  }

  prepareSearch() {
//
    const HX2AlarmEventSearch = <any>{};
    if (this.f.AlarmDate !== undefined && this.f.AlarmDate.value !== '' ) {
      HX2AlarmEventSearch.alarmdate = Globals.GetAPIDate( this.f.AlarmDate.value.jsdate);
    }
    HX2AlarmEventSearch.SortExpression = this.sortExpression;
    if(this.type !== 'Disinfection')
    HX2AlarmEventSearch.SortDirection = this.sortDirection;
    else
    HX2AlarmEventSearch.SortDirection = this.sortDirectionDisinfection;
    HX2AlarmEventSearch.Fields = this.GetSelectedFields();
    HX2AlarmEventSearch.isAlarms = this.type == 'Alarms';
    HX2AlarmEventSearch.type = this.type;

   // console.log(HX2AlarmEventSearch);
    return HX2AlarmEventSearch;

  }

  onItemSelect(item: any) {
    // console.log(item);
    this.FetchAndSetValues();
  }

  onSelectAll(items: any) {
    // console.log(items);
    this.FetchAndSetValues();
  }

  GetSelectedFields() {
//
    let fieldlist = "";
    if (this.f.HX2Fields.value) {
      if(this.type == 'Fields'){
      this.f.HX2Fields.value.forEach(function (field) {

        if (fieldlist == "") {
          fieldlist = field.fieldName;
        } else {
          fieldlist += "," + field.fieldName ;
        }
      });
    } else if(this.type == 'Disinfection'){
      this.f.HX2Fields.value.forEach(function (field) {
        //
        if (fieldlist == "") {
          fieldlist = field;
        } else {
          fieldlist += "," + field;
        }
      });
    }
    }
    return fieldlist;
  }

  fillHX2Fields() {
    this.wpapi.SelectHX2Fields().subscribe(list => {
      //
      console.log(list);
      this.HX2FieldList = [];
      this.HX2FieldList = list;
    });
  }

  fillHX2DisinfectionFields(){
    this.wpapi.fillHX2DisinfectionFields().subscribe(list => {
      //
      console.log(list);
      this.HX2FieldList = [];
      this.HX2FieldList = list;
    });
  }

  SetOption(Type) {
    this.f.HX2Fields.setValue('');
      this.type = Type;

      if(this.type !=='Disinfection' ){
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'fieldName',
          textField: 'fieldLabelName',
          itemsShowLimit: 2,
          allowSearchFilter: false,
          enableCheckAll:false
        };
        this.fillHX2Fields();

        this.dataGridService.clearHeader();
  this.dataGridService.addHeader('#', 'intId', '5');
  this.dataGridService.addHeader('Field Name', 'fieldLabelName', '35');
  this.dataGridService.addHeader('Field Value', 'fieldDisplayValue', '');
  this.dataGridService.addHeader('Date & Time', 'createdOn', '15');
  this.tableHeaders = this.dataGridService.setTableHeaders();
  this.defaultSort = this.dataGridService.setDefaultSort('createdOn', true);
      }
      else if(this.type == 'Disinfection'){
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'fieldName',
          textField: 'fieldName',
          itemsShowLimit: 2,
          allowSearchFilter: false,
          enableCheckAll:true
        };
        this.fillHX2DisinfectionFields();


  this.dataGridServiceDisinfection.clearHeader();
  this.dataGridServiceDisinfection.addHeader('#', 'intId', '5');
  this.dataGridServiceDisinfection.addHeader('Sequence Step', 'fieldDisplayValue', '');
  this.dataGridServiceDisinfection.addHeader('Date & Time', 'createdOn', '15');
  this.tableHeadersDisinfection = this.dataGridServiceDisinfection.setTableHeaders();
  this.defaultSortDisinfection = this.dataGridServiceDisinfection.setDefaultSort('createdOn', false);
      }

      this.FetchAndSetValues();
  }

  HX2FieldList = [];
}

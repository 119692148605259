import { DecimalPipe } from '@angular/common';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PMPartsComponent } from '../Shared/PMPartsControl/PMPartsComponent';
import { PMNotesModel, PMComponentModel, PMPartsModel } from '../Shared/PMNotesComponent/PMNotesModel';
import { debug } from 'util';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pm-note-dialog',
  templateUrl: './PMNoteDialog.html',
})
export class PMNoteDialog implements AfterViewInit {

  confirmdialogRef: MatDialogRef<ConfirmationDialog>;

  errormsg = '';
  public notesDetails: PMNotesModel;
  public componentDetails: PMComponentModel;
  public partsDetails: PMPartsModel;

  constructor(public dialogRef: MatDialogRef<PMNoteDialog>, public dialog: MatDialog, private decimalpipe:DecimalPipe) {
    //dialogRef.updateSize('700px', 'auto');
    this.notesDetails = new PMNotesModel();
    this.componentDetails = new PMComponentModel();
    this.partsDetails = new PMPartsModel();
  }


  @ViewChild(PMPartsComponent, { static: false })
  NoteParts: PMPartsComponent;
  @Output() SaveEvent = new EventEmitter<PMNotesModel>();


  ngOnInit() {
    if (this.componentDetails.showParts === true) {
      //this.dialogRef.updateSize('90%', 'auto');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.componentDetails.showParts === true) {
        this.NoteParts.partsDetails = this.partsDetails;
        this.NoteParts.componentDetails = this.componentDetails;
        if (this.componentDetails.PrevParts) {
          this.NoteParts.PartTrans = this.componentDetails.PrevParts;
        }
      }
    });
  }


  cancel() {

    if (this.notesDetails.hours || this.notesDetails.description
      || this.NoteParts.PartSearchTerm != '' || this.NoteParts.partsDetails.quantity != '0'
      || this.NoteParts.partsDetails.partDescription
    ) {
      this.confirmdialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.confirmdialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      this.confirmdialogRef.afterClosed().subscribe(res => {

        if (res) {
          this.NoteParts.partsDetails.partDescription = '';
          this.NoteParts.partsDetails.quantity = '0';
          this.NoteParts.partsDetails.totalCost = 0;

          this.dialogRef.close(false);

        }
      });
    } else {
      this.dialogRef.close(false);

    }

  }

  save() {

    this.errormsg = this.NoteParts.noteparterrormsg = '';
    if (this.notesDetails.hours && !this.notesDetails.description) {
      this.errormsg = 'Note Required';
      return false;
    }



    if (this.componentDetails.showParts === true) {
      if (this.NoteParts.PartSearchTerm != '') {
        this.NoteParts.noteparterrormsg = 'Please add selected parts';
        return false;
      }
    }

    if (!this.notesDetails.description && this.NoteParts.PartTrans.length == 0) {
      this.NoteParts.noteparterrormsg = 'Please add parts or note to save';
      return false;
    }

    if (this.errormsg == '' && this.NoteParts.noteparterrormsg == '') {

      this.SaveEvent.emit(this.notesDetails);
      this.dialogRef.close(true);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  public confirmMessage: string;

  PlusHours()
  {
    if(parseFloat(this.notesDetails.hours)<12)
    {
      this.notesDetails.hours = (parseFloat(this.notesDetails.hours.toString()) + 0.25).toString();

    this.notesDetails.hours = this.formatDecimal(this.notesDetails.hours.toString());
  }
}

  MinusHours()
  {
    if(parseFloat(this.notesDetails.hours)>0.0)
    {
      this.notesDetails.hours = (parseFloat(this.notesDetails.hours.toString()) - 0.25).toString();

    this.notesDetails.hours = this.formatDecimal(this.notesDetails.hours.toString());
  }
}

  formatDecimal(val) {
    //let val = this.f[ctl].value;
//     if (val.length > 0) {
//       if (val.indexOf('.') === -1) {
//         val = val + ".0" ;
//       }
//
//       if (val.substring(val.indexOf('.') + 1, val.length - val.indexOf('.') + 1).length  == 1) {
//         val = val + "0" ;
//       }
      return this.decimalpipe.transform(val, '1.2-2');
    // }
  }
}


// import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { PMPartsComponent } from '../Shared/PMPartsControl/PMPartsComponent';
// import { PMNotesModel, PMComponentModel, PMPartsModel } from '../Shared/PMNotesComponent/PMNotesModel';
// import { debug } from 'util';
// import { ConfirmationDialog } from '../ConfirmationDialog';
// import { map } from 'rxjs/operators';

// @Component({
//   selector: 'pm-note-dialog',
//   templateUrl: './PMNoteDialog.html',
// })
// export class PMNoteDialog  implements AfterViewInit {

//   confirmdialogRef: MatDialogRef<ConfirmationDialog>;

//   errormsg='';
//   parterrormsg='';
//   public notesDetails : PMNotesModel;
//   public componentDetails : PMComponentModel;showHours
//   public partsDetails : PMPartsModel;

//   constructor(public dialogRef: MatDialogRef<PMNoteDialog>, public dialog: MatDialog) {
//     dialogRef.updateSize('700px', 'auto');
//     this.notesDetails = new PMNotesModel();
//     this.componentDetails = new PMComponentModel();
//     this.partsDetails = new PMPartsModel();
//   }


//   @ViewChild(PMPartsComponent)
//   NoteParts: PMPartsComponent;
//   @Output() SaveEvent = new EventEmitter<PMNotesModel>();


//   ngOnInit() {
//     if (this.componentDetails.showParts === true) {
//       this.dialogRef.updateSize('90%', 'auto');
//     }
//   }

//   ngAfterViewInit() {

//     if ( this.componentDetails.showParts === true) {
//       this.NoteParts.partsDetails = this.partsDetails;
//       this.NoteParts.componentDetails = this.componentDetails;
//       if(this.componentDetails.PrevParts){
//         this.NoteParts.PartTrans = this.componentDetails.PrevParts;
//       }
//     }
//    }


//    cancel(){

//     if (this.notesDetails.hours || this.notesDetails.description
//     || this.NoteParts.PartSearchTerm != '' || this.NoteParts.partsDetails.quantity != '0'
//     || this.NoteParts.partsDetails.partDescription
//     ) {
//     this.confirmdialogRef = this.dialog.open(ConfirmationDialog, {
//       disableClose: true
//     });
//     this.confirmdialogRef.componentInstance.confirmMessage =
//       'You have unsaved changes! If you leave, your changes will be lost.';
//      this.confirmdialogRef.afterClosed().subscribe(res => {

//         if(res){
//           this.dialogRef.close(false);
//         }
//       });
//     }else{
//       this.dialogRef.close(false);

//     }

//    }

//   save() {

//     this.errormsg = this.parterrormsg = '';
//     if (this.notesDetails.hours && !this.notesDetails.description) {
//       this.errormsg = 'Note Required';
//       return false;
//     }

//     if (this.componentDetails.showParts === true){
//     if (this.NoteParts.PartSearchTerm != '') {
//       this.parterrormsg = 'Please add selected parts';
//       return false;
//     }
//   }
//     if(this.errormsg=='' && this.parterrormsg==''){
//       this.SaveEvent.emit(this.notesDetails);
//       this.dialogRef.close(true);
//     }
//   }

//   numberOnly(event): boolean {
//     const charCode = (event.which) ? event.which : event.keyCode;
//     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
//       return false;
//     }
//     return true;

//   }
//   public confirmMessage:string;
// }

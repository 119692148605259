import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'PreventativeMaintenanceSearch',
  templateUrl: './PreventativeMaintenanceSearch.component.html',
})

export class PreventativeMaintenanceSearchComponent {

}

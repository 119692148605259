<div [formGroup]="purposeSectionForm" novalidate>
  <div class="modal-header  col-sm-12">
    <h4 class="modal-title col-sm-8">Visit Purpose Details</h4>

    <div class="text-right  col-sm-4" style="padding:0">
      <!-- <button type="button" class="btn btn-danger" style="margin-right:5px"
        (click)="dialogRef.close(false)">Close</button>
      <button type="button" class="btn btn-primary" (click)="save()">Save</button> -->
      <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>

    </div>
  </div>

  <div class="modal-body">
    <div *ngIf="errormsg" class="box-row col-sm-12">
      <span [ngClass]="'error'">
        {{errormsg}}
      </span>
    </div>
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Date:</label>
      <div class="col-sm-7">
        <label class="readOnlyText">{{today | date:'dd/MM/yyyy'}}</label>
      </div>
    </div>

    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Technologist:</label>
      <div class="col-sm-7">
        <label class="readOnlyText"> {{UserName}}</label>
      </div>
    </div>

    <div class="col-sm-12 box-row" *ngIf="!isOtherSection">
      <label class="col-sm-5 control-label">Pt. Hm Arrival Time:</label>
      <div class="col-sm-3" *ngIf="purpf.HFServicePurposeDetailId.value == 0">
        <input formControlName="HFServicePTHMArrivalTime" Name="HFServicePTHMArrivalTime" placeholder="Time"
          class="form-control smInput"   [max]="getCurrentTime()" [min]="getToday()"
          [owlDateTimeTrigger]="ArrivalTime" [owlDateTime]="ArrivalTime"/>

          <!-- [ngxTimepicker]="ArrivalTime" [format]="24" -->
        <!-- <ngx-material-timepicker #ArrivalTime></ngx-material-timepicker> -->

 <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(purpf.HFServicePTHMArrivalTime.value)" #ArrivalTime></owl-date-time>
        <span
          *ngIf="purpf.HFServicePTHMArrivalTime.errors?.required && (purpf.HFServicePTHMArrivalTime.dirty || purpf.HFServicePTHMArrivalTime.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>
      <div class="col-sm-1" style="padding: 0.5em 0;" *ngIf="purpf.HFServiceVehicleStartTime.value && purpf.HFServicePurposeDetailId.value == 0">
        <button title="> Start Time: {{purpf.HFServiceVehicleStartTime.value | date:'dd/MM/yyyy HH:mm'}}" class="btn btn-primary center-block btn-xs" style=" display: table;margin: 0 auto;">
          <span>
            <i class="fa fa-exclamation-circle"></i>
          </span>
        </button>
      </div>
      <div class="col-sm-7" *ngIf="purpf.HFServicePurposeDetailId.value > 0">
          <label class="readOnlyText"> {{purpf.HFServicePTHMArrivalTime.value | date:'dd/MM/yyyy HH:mm'}}</label>
        </div>
    </div>
    <div class="col-sm-offset-5 col-sm-7 box-row" *ngIf="purpf.HFServicePTHMArrivalTime.errors?.GreaterDate">
      <span
      [ngClass]="'error'">Pt. Hm Arrival Time > Last Vehicle Start Time</span>
    </div>

    <div class="col-sm-12 box-row" *ngIf="showDepartureDetail && !isOtherSection">
      <label class="col-sm-5 control-label">Pt. Hm Dep. Time:</label>
      <div class="col-sm-3">
        <input formControlName="HFServicePTHMDepartureTime" Name="HFServicePTHMDepartureTime" placeholder="Time"
          class="form-control smInput"   [max]="getCurrentTime()" [min]="getToday()"
          [owlDateTimeTrigger]="depttime" [owlDateTime]="depttime"/>
          <!-- [ngxTimepicker]="depttime" [format]="24" -->
          <!-- <ngx-material-timepicker #depttime></ngx-material-timepicker> -->

   <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(purpf.HFServicePTHMDepartureTime.value)" #depttime></owl-date-time>

        <span
          *ngIf="purpf.HFServicePTHMDepartureTime.errors?.required && (purpf.HFServicePTHMDepartureTime.dirty || purpf.HFServicePTHMDepartureTime.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'">
          Required!
        </span>
      </div>
      <div class="col-sm-1" style="padding: 0.5em 0;" *ngIf="purpf.HFServicePTHMArrivalTime.value">
        <button title="> Pt. Hm Arrival Time: {{purpf.HFServicePTHMArrivalTime.value | date:'dd/MM/yyyy HH:mm'}}" class="btn btn-primary center-block btn-xs" style=" display: table;margin: 0 auto;">
          <span>
            <i class="fa fa-exclamation-circle"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="col-sm-offset-5 col-sm-7 box-row" *ngIf="purpf.HFServicePTHMDepartureTime.errors?.GreaterDate">
      <span
        [ngClass]="'error'">Pt. Hm Departure Time > Pt. Hm Arrival Time</span>
    </div>
    <div class="col-sm-12 box-row" *ngIf="showDepartureDetail">
      <label class="control-label col-sm-5">Purpose Completed:</label>
      <div class="col-sm-4">
        <select class="form-control mdInput" formControlName="HFServicePTHMPurposeCompleted">
          <option value="{{item.value}}" *ngFor="let item of YesNoList">
            {{item.text}}
          </option>
        </select>
        <span
          *ngIf="purpf.HFServicePTHMPurposeCompleted.errors?.required && (purpf.HFServicePTHMPurposeCompleted.dirty || purpf.HFServicePTHMPurposeCompleted.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
      </div>
    </div>
    <div class="col-sm-12 box-row" *ngIf="showDepartureDetail">
        <label class="col-sm-5 control-label">Add another Task?:</label>
        <mat-checkbox class="readOnlyText col-sm-1" (change)="OnNextTaskCheckboxChanged($event)"></mat-checkbox>
        <mat-button-toggle-group *ngIf="IsNextStationDisplay"  value=""  style="margin-left:15px" formControlName='nextStation' (change)="OnNextStationChecked($event)"
        name="nextStation" form #tasktypegroup="matButtonToggleGroup" >
       <mat-button-toggle value="Home" *ngIf="dialysisStationPending.length > 0">Home St.</mat-button-toggle>
       <mat-button-toggle value="Other">Other Task</mat-button-toggle>
     </mat-button-toggle-group>
     <span
          *ngIf="purpf.nextStation.errors?.required && (purpf.nextStation.dirty || purpf.nextStation.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
    </div>
    <div class="col-sm-12 box-row" *ngIf="showDepartureDetail && purpf.nextStation.value=='Home'">
        <label class="col-sm-5 control-label">Next Home Unit:</label>
      <div class="col-sm-7">
        <select class="form-control mdInput" formControlName="HFServiceReferenceHFSId">
        <option value="">Select</option>
        <option value="{{item.hfServiceId}}" *ngFor="let item of dialysisStationPending">
          {{item.hfServiceStationName + ' - ' + item.hfServiceVisitPurpose }}
        </option>
      </select>
      <span
          *ngIf="purpf.HFServiceReferenceHFSId.errors?.required && (purpf.HFServiceReferenceHFSId.dirty || purpf.HFServiceReferenceHFSId.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
      </div>
    </div>
    <div class="col-sm-12 box-row" *ngIf="!isOtherSection">
      <label class="col-sm-5 control-label">Note:</label>
      <div class="col-sm-7">
        <textarea name="note" autosize formControlName="HFServicePurposeDetailNotes" name="HFServicePurposeDetailNotes"
          class="form-textarea" style="min-height: 75px;"></textarea>
          <span
          *ngIf="purpf.HFServicePurposeDetailNotes.errors?.required && (purpf.HFServicePurposeDetailNotes.dirty || purpf.HFServicePurposeDetailNotes.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>

      </div>
    </div>
  </div>
</div>

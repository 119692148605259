<div [formGroup]="returnSectionForm" novalidate>
  <div class="modal-header  col-sm-12">
    <h4 class="modal-title col-sm-8">Return Details</h4>

    <div class="text-right  col-sm-4" style="padding:0">
      <!-- <button type="button" class="btn btn-danger" style="margin-right:5px"
        (click)="dialogRef.close(false)">Close</button>
      <button type="button" class="btn btn-primary" (click)="save()">Save</button> -->
      <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>

    </div>
  </div>

  <div class="modal-body">
    <div *ngIf="errormsg" class="box-row col-sm-12">
      <span [ngClass]="'error'">
        {{errormsg}}
      </span>
    </div>
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Date:</label>
      <div class="col-sm-7">
        <label class="readOnlyText">{{today | date:'dd/MM/yyyy'}}</label>
      </div>
    </div>

    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Technologist:</label>
      <div class="col-sm-7">
        <label class="readOnlyText"> {{UserName}}</label>
      </div>
    </div>

    <div class="col-sm-12 box-row">
      <label class="control-label col-sm-5">GR Return Time:</label>
      <div class="col-sm-3" >
        <input formControlName="HFServiceReturnVehicleEndTime" Name="HFServiceReturnVehicleEndTime"
          placeholder="Time" class="form-control smInput"
          [max]="getCurrentTime()" [min]="getToday()"
          [owlDateTimeTrigger]="ReturnVehicleEndTime" [owlDateTime]="ReturnVehicleEndTime"/>

      <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(rf.HFServiceReturnVehicleEndTime.value)" #ReturnVehicleEndTime></owl-date-time>
          <!-- [ngxTimepicker]="ReturnVehicleEndTime" [format]="24"
        <ngx-material-timepicker #ReturnVehicleEndTime></ngx-material-timepicker> -->

        <span
          *ngIf="rf.HFServiceReturnVehicleEndTime.errors?.required && (rf.HFServiceReturnVehicleEndTime.dirty || rf.HFServiceReturnVehicleEndTime.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
      </div>
      <div class="col-sm-1" style="padding: 0.5em 0;" *ngIf="rf.HFServicePTHMDepartureTime.value">
        <button title="> Pt. Hm Dep.Time: {{rf.HFServicePTHMDepartureTime.value | date:'dd/MM/yyyy HH:mm'}}" class="btn btn-primary center-block btn-xs" style=" display: table;margin: 0 auto;">
          <span>
            <i class="fa fa-exclamation-circle"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="col-sm-offset-5 col-sm-7 box-row" *ngIf="rf.HFServiceReturnVehicleEndTime.errors?.GreaterDate">
      <span
          [ngClass]="'error'">Return Time > Last Pt. Hm Departure Time</span>
    </div>
    <div class="col-sm-12 box-row">
      <label class="control-label col-sm-5">Vehicle End KM:</label>
      <div class="col-sm-7" >
        <input formControlName="HFServiceReturnVehicleEndedKM" class="form-control smInput"
          (keypress)="numberOnly($event)" maxlength="7">
        <label class="readOnlyTextForHours" *ngIf="rf.HFServiceVehiclePrevStartedKm.value">({{rf.HFServiceVehiclePrevStartedKm.value}})</label>
        <span
          *ngIf="rf.HFServiceReturnVehicleEndedKM.errors?.required && (rf.HFServiceReturnVehicleEndedKM.dirty || rf.HFServiceReturnVehicleEndedKM.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
      </div>
    </div>
    <div class="col-sm-offset-5 col-sm-7 box-row" *ngIf="rf.HFServiceReturnVehicleEndedKM.errors?.GreaterKMS">
      <span [ngClass]="'error'">
        End KMs > Start KMs
      </span>
    </div>
    <div class="col-sm-12 box-row">
      <label class="control-label col-sm-5">Fuel Added:</label>
      <div class="col-sm-7" >
        <select class="form-control mdInput" formControlName="HFServiceFuelAdded" (change)="SetValidations()">
          <option value="{{item.value}}" *ngFor="let item of YesNoList">
            {{item.text}}
          </option>
        </select>
        <span
          *ngIf="rf.HFServiceFuelAdded.errors?.required && (rf.HFServiceFuelAdded.dirty || rf.HFServiceFuelAdded.touched ||  isValidFormSubmitted)"
          [ngClass]="'error'"> Required!</span>
      </div>
    </div>
    <div *ngIf="rf.HFServiceFuelAdded.value=='Yes'">
    <div class="col-sm-12 box-row">
        <label class="control-label col-sm-5">Fuel Company:</label>
        <div class="col-sm-7" >
          <select class="form-control mdInput" formControlName="HFServiceFuelCompany"
            Name="HFServiceFuelCompany">
            <option value="{{item.value}}" *ngFor="let item of FuelCompanyList">
              {{item.text}}
            </option>
          </select>
          <span
            *ngIf="rf.HFServiceFuelCompany.errors?.required && (rf.HFServiceFuelCompany.dirty || rf.HFServiceFuelCompany.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'"> Required!</span>
        </div>
    </div>

    <div class="col-sm-12 box-row">
        <label class="control-label col-sm-5">Fuel Amount:</label>
        <div class="col-sm-7" >
          <input name='itemCost' maxlength='6' class='form-control smInput'
            formControlName='HFServiceFuelAmount' (blur)="transformHFServiceFuelAmount()"
            (focusout)="transformHFServiceFuelAmount()" [(value)]="formattedHFServiceFuelAmount"
            (keypress)="numberOnly($event)">
          <span
            *ngIf="rf.HFServiceFuelAmount.errors?.required && (rf.HFServiceFuelAmount.dirty || rf.HFServiceFuelAmount.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'"> Required!</span>
          <span
            *ngIf="rf.HFServiceFuelAmount.errors?.pattern && (rf.HFServiceFuelAmount.dirty || rf.HFServiceFuelAmount.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'"> Required!</span>
        </div>
    </div>
    <div class="col-sm-12 box-row">
        <label class="control-label col-sm-5">Payment By:</label>
        <div class="col-sm-7" >
          <select class="form-control mdInput" formControlName="HFServiceFuelPaymentBy"
            Name="HFServiceFuelPaymentBy">
            <option value="{{item.value}}" *ngFor="let item of PaymentByList">
              {{item.text}}
            </option>
          </select>
          <span
            *ngIf="rf.HFServiceFuelPaymentBy.errors?.required && (rf.HFServiceFuelPaymentBy.dirty || rf.HFServiceFuelPaymentBy.touched ||  isValidFormSubmitted)"
            [ngClass]="'error'"> Required!</span>
        </div>
      </div>

    <div class="col-sm-12 box-row">
        <label class='col-sm-5 control-label'>Add Receipt:</label>

        <div class='col-sm-3'>
          <input type="file" accept="image/x-png,image/gif,image/jpeg" class="FileInput hidden"
            id="ng2Fileuploader" (change)="onFileSelected($event)" #fileInput />
          <button (click)="fileInput.click()"
            class="btn btn-success pull-left" type="button"><i
              class="glyphicon glyphicon-upload"></i>&nbsp;
            Upload</button><br><br>
          <button class="btn btn-danger"
            *ngIf="this.rf.HFServiceFuelReceiptPath.value"
            (click)="clearImage()" type="button"><span class="glyphicon glyphicon-trash"></span>
            &nbsp; Clear &nbsp; </button>
        </div>
        <div class='col-sm-4'>
          <img [src]="GetImagePath()" width="150px" height="150px"
            *ngIf="this.rf.HFServiceFuelReceiptPath.value" (click)="openImageDialog()">
        </div>
    </div>
  </div>
    <div class="col-sm-12 box-row">
      <label class="col-sm-5 control-label">Note:</label>
      <div class="col-sm-7">
        <textarea name="note" autosize formControlName="HFServiceReturnDetailNotes" name="HFServiceReturnDetailNotes" class="form-textarea"
        style="min-height: 75px;"></textarea>
      </div>
    </div>
  </div>
</div>

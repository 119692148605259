import { Component, ViewChild } from '@angular/core';
import { ForumApiService } from '../../API/forumapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'ForumCategorySearch',
  templateUrl: './ForumCategorySearch.component.html',

})
export class ForumCategorySearchComponent {

  constructor( private fmcsapi: ForumApiService , private router: Router, private dataGridService: TableHeadsService){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows ;
  AddUrl = '/Masters/ForumCategory/ForumCategoryDetail';
  currentPageNumber = 1;
  sortExpression = 'ForumCategoryName';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Forum Category';
  defaultSort;

  ngOnInit() {
    // AppComponent.loading=true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'id', '4');
    this.dataGridService.addHeader('Name', 'name', '10');
    this.dataGridService.addHeader('Description', 'description', '20');    
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('name', false);

  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeForumCategorySearch();
  }

  setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeForumCategorySearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';

    const contanctsearch = this.prepareSearch();
    this.fmcsapi.GetAllForumCategories(contanctsearch).subscribe(pagedData  => {
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.forumCategoriesDetails;
        console.log(pagedData);
        AppComponent.HideLoader();
      }
    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );
  }

prepareSearch() {
  // tslint:disable-next-line:prefer-const
  let ForumCategorysearch = <any>{};
  ForumCategorysearch.currentPageNumber = this.currentPageNumber;
  ForumCategorysearch.sortExpression = this.sortExpression;
  ForumCategorysearch.sortDirection = this.sortDirection;
  ForumCategorysearch.PageSize = this.pageSize;
   // console.log(ForumCategorysearch);
  return ForumCategorysearch;
}

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeForumCategorySearch();
  }
   _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

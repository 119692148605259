<form [formGroup]="UploadHistoryForm" novalidate>
  <section class="content-header">
     
    <h1 *ngIf="detailsType == 'Water Process'">{{headerTextWP}}</h1>
    <h1 *ngIf="detailsType == 'other'">{{headerTextOther}}</h1>
  </section>
  <section class="content">
    <div class="row">
      <div class="box  box-primary">
        <PageHeader
          [showNote]="blnshowDelete"
          (addNoteClick)="notes.AddNote()"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeHistoryUploadCreate('')"
          #pageheader
        ></PageHeader>

        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="box ibox box-info">
                <div class="col-sm-12 box-row">
                  <div class="col-sm-4">
                    <label class="col-sm-5 control-label">
                      Form Date:
                    </label>
                    <div class="col-sm-7">
                        <label class="readOnlyText"> {{ formDates | date:'dd/MM/yyyy HH:mm'}}</label>
                      </div>
                  </div>

                  <div class="col-sm-4">
                      <label class="col-sm-5 control-label">Created By:</label>
                      <div class="col-sm-7">
                        <label class="readOnlyText">{{ UserName }}</label>
                      </div>
                  </div>

                  <div class="col-sm-4">
                      <label class="col-sm-5 control-label">Repair/PM ID:</label>
                      <div class="col-sm-7">
                        <label class="readOnlyText">{{ eventId }}</label>
                      </div>
                  </div>
                </div>

                <div class="col-sm-12 box-row">
                  <div class="col-sm-4">
                  <label class="col-sm-5 control-label">
                    <span class="text-red inputrequired">*</span>History Type:
                  </label>
                  <div class="col-sm-7">
                    <select
                      class="form-control mdInput"
                      formControlName="HistoryType"
                      name="HistoryType"
                    >
                      <option
                        value="{{ item.value }}"
                        *ngFor="let item of HistoryTypeList"
                      >
                        {{ item.text }}
                      </option>
                    </select>

                    <span
                      *ngIf="
                        f.HistoryType.errors?.required &&
                        (f.HistoryType.dirty ||
                          f.HistoryType.touched ||
                          isValidFormSubmitted == false)
                      "
                      [ngClass]="'error'"
                    >
                      Required!
                    </span>
                  </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="box ibox box-info">
                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="dmReference">
                      <h4 class="panel-title">
                        <div class="box-header with-border" style="padding:0px;">
                          <h3 class="box-title">Dialysis Unit Details</h3>
                        </div>
                      </h4>
                    </div>
                </div>

                <div class="col-sm-12 box-row">
                  <div class="box-body">
                    <div class="col-sm-4">
                      <label class="col-sm-5 control-label"
                        ><span class="text-red inputrequired">*</span
                        >Division:</label
                      >
                      <div class="col-sm-7">
                        <select
                          class="form-control mdInput"
                          formControlName="divisionId"
                          name="divisionId"
                          (change)="DivisionChanged()"
                        >
                          <option
                            value="{{ item.value }}"
                            *ngFor="let item of DivisionList"
                            >{{ item.text }}</option
                          >
                        </select>
                        <span
                          *ngIf="
                            f.divisionId.errors?.required &&
                            (f.divisionId.dirty ||
                              f.divisionId.touched ||
                              isValidFormSubmitted == false)
                          "
                          [ngClass]="'error'"
                        >
                          Required!
                        </span>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="col-sm-5 control-label"
                        >Start Date:</label
                      >
                      <div class="col-sm-7">
                        <input
                          (focus)="dtHistoryDate.toggleCalendar()"
                          (click)="dtHistoryDate.openCalendar()"
                          placeholder="Select a date"
                          class="form-control mdInput"
                          style="float:none"
                          angular-mydatepicker
                          name="HistoryDate"
                          formControlName="HistoryDate"
                          [options]="myOptions"
                          #dtHistoryDate="angular-mydatepicker"

                        />

                      </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="col-sm-5 control-label"
                          ><span class="text-red inputrequired">*</span
                          >Equipment:</label
                        >
                        <div class="col-sm-7">
                          <select
                            class="form-control mdInput"
                            formControlName="equipmentId"
                            name="equipmentId"
                            (change)="EquipmentDropDownChanged()"
                          >
                            <option
                              value="{{ item.value }}"
                              *ngFor="let item of EquipmentList"
                              >{{ item.text }}</option
                            >
                          </select>

                          <span
                            *ngIf="
                              f.equipmentId.errors?.required &&
                              (f.equipmentId.dirty ||
                                f.equipmentId.touched ||
                                isValidFormSubmitted == false)
                            "
                            [ngClass]="'error'"
                          >
                            Required!
                          </span>
                        </div>
                      </div>

                  </div>
                </div>

                <!-- <div class="col-sm-12 box-row">
                  <div class="box-body"> -->

                    <!-- <div class="col-sm-6">
                      <label class="col-sm-5 control-label"
                        ><span class="text-red inputrequired">*</span>Hours
                        IN:</label
                      >
                      <div class="col-sm-7">
                        <input
                          maxlength="10"
                          class="form-control smInput"
                          name="hoursin"
                          formControlName="hoursin"
                          required
                        />

                        <span  *ngIf="f.hoursin.errors?.required && (f.hoursin.dirty || f.hoursin.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                          Required!
                      </span>
                      <span  *ngIf="f.hoursin.errors?.pattern" [ngClass] = "'error'">
                              Invalid number
                          </span>

                          <span  *ngIf="f.hoursin.errors?.GreaterHoursOut" [ngClass] = "'error'">
                            Hours In on new Repair cannot be less than Hours Out from previous Repair completed.
                    </span>
                      </div>
                    </div> -->
                  <!-- </div>
                </div> -->

                <!-- <div class="col-sm-12 box-row">
                  <div class="box-body">
                    <div class="col-sm-6">
                      <label class="col-sm-5 control-label"
                        ><span class="text-red inputrequired">*</span
                        >Notes:</label
                      >
                      <div class="col-sm-7">
                        <textarea
                          name="remarks"
                          autosize
                          formControlName="remarks"
                          class="form-textarea lgInput"
                          required
                        ></textarea>
                        <span  *ngIf="f.remarks.errors?.required && (f.remarks.dirty || f.remarks.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                          Required!
                      </span>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="col-sm-12 box-row">
                <div class="col-sm-12">
                  <label class="col-md-2  control-label" style="width:14%"
                  ><span class="text-red inputrequired">*</span
                  >Notes:</label
                >
                  <div style="width:86%" class="col-md-9">
                      <textarea name="remarks" autosize formControlName="remarks" class="form-textarea lgInput" required></textarea>
                      <span  *ngIf="f.remarks.errors?.required && (f.remarks.dirty || f.remarks.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                              Required!
                          </span>
                  </div>
                </div>
            </div>
            <div class="clearfix"></div>
              <div class="clearfix"></div>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab">
                    <h4 class="panel-title">
                        <div class="box-header with-border" style="padding: 0px;">
                            <h3 class="box-title container">Replaced Parts</h3>
                        </div>
                    </h4>
                </div>
              </div>
                <Parts_Component #parts *ngIf="ShowParts == true"></Parts_Component>
              
               
              <div class="clearfix"></div>
              <div class="box ibox box-info">
                <div class="panel panel-default">
                    <div class="panel-heading" role="tab">
                      <h4 class="panel-title">
                        <div class="box-header with-border" style="padding:0px;">
                          <h3 class="box-title">Equipment Hours Out Details</h3>
                        </div>
                      </h4>
                    </div>
                </div>
                <div class="col-sm-12 box-row">
                  <div class="box-body">
                    <div class="col-sm-4" *ngIf="hrsTracking">
                      <label class="col-sm-5 control-label">
                          <span class="text-red inputrequired">*</span>Hours Out:</label>
                      <div class="col-sm-7">
                        <input class="form-control smInput"
                        name="hoursout" formControlName="hoursout"  maxlength="5"
                        (keypress)="numberOnly($event)">

                        <span  *ngIf="f.hoursout.errors?.required && (f.hoursout.dirty || f.hoursout.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                          Required!
                      </span>
                      <span *ngIf="f.hoursout.errors?.pattern" [ngClass]="'error'">Invalid number</span>

                    </div>

                    </div>

                    <div class="col-sm-3">
                      <label class="col-sm-5 control-label">
                          <span class="text-red inputrequired">*</span>Date Out:</label>
                      <div class="col-sm-7">
                        <input
                        (focus)="dtOutHistoryDate.toggleCalendar()"
                        (click)="dtOutHistoryDate.openCalendar()"
                        placeholder="Select a date"
                        class="form-control mdInput"
                        style="float:none"
                        angular-mydatepicker
                        name="dateOut"
                        formControlName="dateOut"
                        [options]="myOptions"
                        #dtOutHistoryDate="angular-mydatepicker"
                        required
                      />

                    <span
                      *ngIf="
                        f.dateOut.errors?.required &&
                        (f.dateOut.dirty ||
                          f.dateOut.touched ||
                          isValidFormSubmitted == false)" [ngClass]="'error'">Required!
                    </span>

                    <span *ngIf='f.dateOut.errors?.error || f.dateOut.errors?.InGreaterDateOut'
                      [ngClass]="'error'">
                      Date Out must be
                      greater than or equal
                      to Date In!
                    </span>

                    </div>


                    </div>

                    <div class="col-sm-5">
                      <label class="col-sm-7 control-label"
                        >Out Disinfection:</label
                      >
                      <div class="col-sm-5">
                          <select  *ngIf="status!='Completed'" class="form-control mdInput" formControlName="hydraulicDisinfection" name="hydraulicDisinfection">
                              <option  value="{{item.value}}" *ngFor="let item of hydraulicDisinfectionList">{{item.text}}</option>
                            </select>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <div class="clearfix"></div>
              <attachment (RaiseError)="AttachmentError($event)"  #attachment>

                </attachment>
            </div>
            <PageHeader
            title="title"
            [showNote]="blnshowDelete"
            (addNoteClick)="notes.AddNote()"
            [showAddNew]="false"
            [showSearch]="false"
            [mainlisturl]="ListURL"
            [showSave]="blnshowSave"
            (SaveEvent)="executeHistoryUploadCreate('')"
            #pageheader
          ></PageHeader>
          </div>

        </div>
      </div>
    </div>
  </section>
</form>

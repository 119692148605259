import { Component, ViewChild } from '@angular/core';
import { PreventiveMaintenanceCommonSearchComponent } from 'src/app/Core/Shared/PreventiveMaintenanceCommon/PreventiveMaintenanceCommonSearch.component';


@Component({
  selector: 'HomePreventiveMaintenanceSearch',
  templateUrl: './HomePreventiveMaintenanceSearch.component.html',

})
export class HomePreventiveMaintenanceSearchComponent {
  
}

import { Component, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DMApiService } from '../../API/dmapi.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'Trobleshooting-dialog',
  templateUrl: './TrobleshootingDialog.html',
})
export class TrobleshootingDialog {
  public ErrorList=[];
  public completed =false;
  title="Troubleshooting";
  public ErrorDetail=[];
  CurrnetType="";
  constructor(public dialogRef: MatDialogRef<TrobleshootingDialog>,private dmapi:DMApiService) {
  }
  
  public TrobleshootingData=[];
  
 
  save()
  {
    this.TrobleshootingData=[];
    for (let childObj in this.ErrorDetail){
      for (let childObj1 in this.ErrorDetail[childObj].errorCodeTroubleShottingSteps){
      if(this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].tempresult != "Not Tested")
      {
        this.TrobleshootingData.push({
          troubleshootingId:this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].troubleShottingStepsId,
          dmrepairId:0,
          result:this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].tempresult,
          dmRepairTroubleShootingId:0,
        });
      }
    }
  }
    this.dialogRef.close(true);
  }
  ngOnInit()
  {
    //AppComponent.ShowLoader();
    var errorList = {Ids:this.ErrorList};
    this.dmapi.GetErrorCodeWithSteps(errorList).subscribe(success => 
      {
         // console.log(success);
          this.ErrorDetail=success;
          for (let childObj in this.ErrorDetail){
            for (let childObj1 in this.ErrorDetail[childObj].errorCodeTroubleShottingSteps){
              this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].tempresult="";
              this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].result="";
              var tempObj = this.TrobleshootingData.filter(x => x.troubleshootingId ==
                this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].troubleShottingStepsId);
               // console.log(tempObj,this.TrobleshootingData,this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].troubleShottingStepsId);
              if (tempObj!=undefined && tempObj != null && tempObj.length > 0)
              {
                this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].result=tempObj[0].result;
                this.ErrorDetail[childObj].errorCodeTroubleShottingSteps[childObj1].tempresult=tempObj[0].result;
              }
              
            } 
          }

         // AppComponent.HideLoader();
      },
      error=>
      {
        //console.log(error);
        AppComponent.addAlert(error.message,"error");
       // AppComponent.HideLoader();
      }
    );
  }

}
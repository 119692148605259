<header class="main-header">

    <!-- Logo -->
    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top">

        <a name="techLogo" href="{{defaultPageLink()}}" class="logoContainer">

          <div *ngIf="checkCompany('UHN')" style="display:inline-block;">
            <div class="pull-left">
              <img src="../../assets/img/UHN-logo-White.png" class="logo desk" style="padding:0px 0px;height: 30px;" />
              <img src="../../assets/img/UHN.png" class="logotab" style="padding:0px 1px;height: 25px;" />
            </div>
            <div class="pull-center pull-left">
              <!-- <i class="earth" style="display:inline-block;margin-top:10px;margin-left: 5px"></i> -->
            </div>
            <div class="clear-fix"></div>
          </div>

          <div *ngIf="checkCompany('GR')" style="display:inline-block;">
            <div class="pull-left">
              <img src="../../assets/img/GRHBG.png" class="logo desk" style="padding:0px 1px;margin-top: -10px" />
              <img src="../../assets/img/GRHFlower.png" class="logotab" style="padding:0px 1px;margin-top: -10px" />
            </div>
            <div class="pull-center pull-left">
              <!-- <i class="earth" style="display:inline-block;margin-top:10px;margin-left: 5px"></i> -->
            </div>
            <div class="clear-fix"></div>
          </div>

          <div *ngIf="checkCompany('SCSCDU')" style="display:inline-block;">
            <div class="pull-left">
              <img src="../../assets/img/scscduCOMBOLOGO.png" class="logo desk" style="padding:0px 1px;margin-top: -10px;background-color: white;" />
              <img src="../../assets/img/scscduLogo.png" class="logotab" style="padding:0px 1px;margin-top: -10px !important;width: 100px;height: 60px;" />
            </div>
            <div class="pull-center pull-left">
              <!-- <i class="earth" style="display:inline-block;margin-top:10px;margin-left: 5px"></i> -->
            </div>
            <div class="clear-fix"></div>
          </div>

          <div *ngIf="checkCompany('MRMDemo')" style="display:inline-block;">
              <div class="pull-left">
                       <!-- <span class="logo-lg" style="margin-left:20px;" *ngIf="checkCompany('SC')"> -->
                  <span class="logo desk" style="margin:0px">
                    <b style="display:block;margin-top:10px;">BMR</b>
                    <b style="vertical-align:bottom;font-size:12px;margin-left:1px;margin-right: 10px">TECHNICAL</b>
                    <span class="pull-center">
                        <i class="earth" style="margin-top:-10px;display:inline-block;"></i>
                    </span>
                  </span>
                  <span class="logotab" style="margin:0px">
                      <b style="display:block;margin-top:10px;">BMR</b>
                      <b style="vertical-align:text-bottom;font-size:12px;margin-left:1px;margin-right: 10px">TECHNICAL</b>
                      <!-- <span class="pull-center">
                          <i class="earth" style="margin-top:-10px;display:inline-block;"></i>
                      </span> -->
                  </span>
                      <!-- <img src="../../assets/img/mrmwhite.png" class="logo desk" style="padding:0px 1px;margin-top: -10px" />
                      <img src="../../assets/img/mrmwhite.png" class="logotab" style="padding:0px 1px;margin-top: -10px" /> -->
              </div>
              <div class="pull-center pull-left">
                  <!-- <i class="earth" style="display:inline-block;margin-top:10px;margin-left: 5px"></i> -->
              </div>
              <div class="clear-fix"></div>
          </div>

        </a>
        <!-- Sidebar toggle button-->
        <a href="#" data-toggle="push-menu" role="button">
            <span class="sr-only">Toggle navigation</span>
			   <span class="icon-bar"></span>

            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
</a>
<div class="navbar-header pull-left">
        <button type="button" class="navbar-toggle"
               data-toggle="collapse" data-target=".navbar-collapse">
         <span class="icon-bar"></span>
         <span class="icon-bar"></span>
         <span class="icon-bar"></span>
       </button>

     </div>



        <div name="navBar" [style.width.px]="GetMenuWidth()"  class="collapse navbar-collapse pull-left no-print text-center"  id="navbar-collapse" style="font-size: 12px">
            <ul class="nav navbar-nav" data-widget="tree" style="float:none;display:inline-block" id="iconmenuheader">

                <li *ngIf="checkAvailable('Dashboard')" style="display: none;">
                    <a href="javascript:void(0)" (click)="RouteClick('Dashboard');" style="text-align:center">
                        <i class="fa fa-tachometer headerMenu" style="display:inline-block;"></i>
                        <p style="margin:0px;">Dashboard</p>
                    </a>
                </li>

                <li *ngIf="checkAvailable('User Access')">
                    <a href="javascript:void(0)" (click)="RouteClick('Contact/ContactList');" style="text-align:center">
                        <i class="fa fa-id-card headerMenu" style="display:inline-block;"></i>
                        <p style="margin:0px;">Contact</p>
                    </a>
                    <!-- <a href="javascript:void(0)" (click)="MenuClick('~/user/ContactList.aspx');" style="text-align:center">
                      <i class="fa fa-id-card headerMenu" style="display:inline-block;"></i>
                      <p style="margin:0px;">Contact</p>
                  </a> -->

                </li>

                <li class="dropdown" *ngIf="checkAvailable('Schedule')">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                    <a href="javascript:void(0)" (click)="RouteClick('Schedule/Technologist');" class="dropdown-toggle"  style="text-align:center">
                        <i class="fa fa-calendar headerMenu"></i>
                        <p style="margin:0px;">Schedule</p>
                    </a>
                    <!-- <ul class="dropdown-menu" role="menu" >
                        <li *ngIf="checkAvailable('Staff Schedule')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('Schedule/Staff');"><i class="fa fa-users"></i>Staff</a></li>
                        <li *ngIf="checkAvailable('Technologist Schedule')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('Schedule/Technologist');"><i class="fa fa-users"></i>Technologist</a></li>
                        <li *ngIf="checkAvailable('Staff Schedule')"><a href="javascript:void(0)" (click)="MenuClick('~/schedule/schedulelist.aspx')"><i class="fa fa-users"></i>Staff</a></li>
                         <li><a *ngIf="checkAvailable('Technologist Schedule')" href="javascript:void(0)" (click)="MenuClick('~/schedule/scheduleListTechnologist.aspx');"><i class="fa fa-user"></i>Technologist</a></li>
                     </ul> -->
                </li>
                <li class="dropdown pointer" *ngIf="false">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                    <!-- <a href="javascript:void(0)" (click)="MenuClick('~/bulletin/bulletinSearch.aspx');" class="dropdown-toggle"  style="text-align:center"> -->
                      <a href="javascript:void(0)" (click)="RouteClick('Forum');" style="text-align:center">
                        <i class="fa fa-envelope headerMenu"></i>
                        <!-- <span class="label label-danger" *ngIf="UrgentMessage!=0" >{{UrgentMessage}}</span> -->
                        <p style="margin:0px;">Messages</p>
                    </a>
                </li>

                <li class="dropdown" *ngIf="checkAvailable('Master') && userRole =='Administrator'">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                     <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">

                        <i class="fa fa-user-circle headerMenu"></i>
                        <p style="margin:0px;">Admin</p>
                      </a>
                    <ul class="dropdown-menu" role="menu">

                        <!-- <li *ngIf="checkAvailable('Contact Type')"><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-address-book-o"></i><p>Contacts</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <!-- MenuClick('~/user/usertypelist.aspx') -->
                        <!-- <li *ngIf="checkAvailable('User Designation Rights')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/RoleMenuMapping_DetPage.aspx');"><i class="fa fa-hand-o-up"></i><p>U-Rights</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('User Designation Rights')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/AccessRestricted');"><i class="fa fa-hand-o-up"></i><p>U-Rights</p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- <li *ngIf="checkAvailable('User Designation')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/designationList.aspx');"><i class="fa fa-user-circle"></i><p>U-Designations</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('User Designation')" ><a href="javascript:void(0)" (click)="RouteClick('Admin/UserDesignationSearch');"><i class="fa fa-user-circle"></i><p>U-Designations</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Product Category')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/productCategorySearch.aspx');"><i class="fa fa-product-hunt"></i><p>Products</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <!-- <li *ngIf="checkAvailable('Organizational Unit')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/organizationUnit.aspx');"><i class="fa fa-building-o"></i>Organization</a></li> -->
                        <li *ngIf="checkAvailable('Organizational Unit')" ><a href="javascript:void(0)" (click)="RouteClick('OrganizationalUnit');"><i class="fa fa-building-o"></i><p>Organization</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Division & Dialysis Units')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/divisionList.aspx');"><i class="fa fa-sitemap"></i><p>Divisions</p></a></li> -->
                        <li *ngIf="checkAvailable('Division & Dialysis Units')" ><a href="javascript:void(0)" (click)="RouteClick('Admin/DivisionSearch');"><i class="fa fa-sitemap"></i><p>Divisions</p></a></li>
                        <li *ngIf="checkAvailable('Error Codes')" ><a  href="javascript:void(0)" (click)="RouteClick('Admin/ErrorCodeSearch')"><i class="fa fa-warning"></i><p>Errors</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Schedule Holidays')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/holidayList.aspx');"><i class="fa fa-calendar"></i>Holidays</a></li> -->
                        <!-- <li *ngIf="checkAvailable('Schedule Color')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/scheduleColorList.aspx');"><i class="fa fa-calendar"></i><p>S-Colors</p><span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('Schedule Color')"><a href="javascript:void(0)" (click)="RouteClick('Admin/ScheduleColorSearch');"><i class="fa fa-calendar"></i><p>S-Colors</p></a></li>
                        <li *ngIf="checkAvailable('Schedule UserType')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/scheduleUserTypeSearch.aspx');"><i class="fa fa-user-circle"></i><p>S-UserTypes</p><span class='text-red inputrequired'>*</span></a></li>
                        <li *ngIf="checkAvailable('Notification Triggers')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/NotificationTriggers.aspx');"><i class="fa fa-send-o"></i><p>Triggers</p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- <li *ngIf="checkAvailable('Ticket Notification')" ><a href="javascript:void(0)" (click)="MenuClick('~/masters/TicketNotification.aspx');"><i class="fa fa-ticket"></i>Tickets</a></li> -->
                        <li *ngIf="checkAvailable('Task Type')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('Masters/TaskTypeSearch');"><i class="fa fa-calendar"></i><p>Task</p>&nbsp;<p>Type</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Task Type')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('Masters/ForumCategory/ForumCategorySearch');"><i class="fa fa-calendar"></i><p>Forum Category</p></a></li> -->
                      </ul>
                </li>
                <li *ngIf="checkAvailable('Water Process')"  class="dropdown pointer">
                   <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                     <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                        <i class="fa fa-tint headerMenu"></i>
                        <p style="margin:0px;">H<sub>2</sub>O Process</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <!-- <li *ngIf="checkAvailable('Water Process Nephro Safe')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/Home.aspx');"><i class="fa fa-binoculars"></i><p>Remote View</p></a></li> -->
                        <li *ngIf="checkAvailable('Water Process Nephro Safe')" ><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/RemoteView/RemoteView');"><i class="fa fa-binoculars"></i><p>Remote</p>&nbsp;<p>View</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Daily Record')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/Daily/WPDailySearch');"><i class="fa fa-calendar"></i><p>Daily</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('Water Process Daily Record') && GetSiteName() =='GR'" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/Daily/GRWPDailySearch');"><i class="fa fa-calendar"></i><p>Daily</p></a></li>
                        <li *ngIf="checkAvailable('Water Process Daily Record') && GetSiteName() =='UHN'" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/DailyUnderConstruction');"><i class="fa fa-calendar"></i><p>Daily</p></a></li>
                        <li *ngIf="checkAvailable('Water Process Daily Record') && GetSiteName() =='MRMDemo'" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/Daily/DEWPDailySearch');"><i class="fa fa-calendar"></i><p>Daily</p></a></li>
                        <li *ngIf="checkAvailable('Water Process Daily Record') && GetSiteName() =='SCSCDU'" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/Daily/GRWPDailySearch');"><i class="fa fa-calendar"></i><p>Daily</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Saturday Record')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/saturdayWaterRecordSearch.aspx');"><i class="fa fa-check-square"></i>Nurse Checks</a></li> -->
                        <li *ngIf="checkAvailable('Water Process Saturday Record')" style="cursor:pointer"><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/SaturdayWaterRecordSearch');"><i class="fa fa-check-square"></i><p>Nurse</p>&nbsp;<p>Checks</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Repair/Events')" ><a  href="javascript:void(0)" (click)="MenuClick('~/waterprocess/waterProcessRepairSearch.aspx');" ><i class="fa fa-gears"></i><p>Repair</p></a></li> -->
                        <li *ngIf="checkAvailable('Water Process Repair/Events')" ><a  href="javascript:void(0)" (click)="RouteClick('WaterProcess/Repairs/RepairDetailSearch');" ><i class="fa fa-gears"></i><p>Repair</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Bacteria')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/waterProcessBacteriaSearch.aspx');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li> -->

                        <li *ngIf="checkAvailable('Water Process Bacteria')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/BacteriasSearch');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Pyrogen')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/WaterProcessPryogenListing.aspx');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li> -->
                        <li *ngIf="checkAvailable('Water Process Pyrogen')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/PyrogensSearch');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Analysis')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/waterAnalysisReportSearch.aspx');"><i class="fa fa-pie-chart"></i><p>Analysis </p><span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('Water Analysis')" ><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/Analysis/AnalysisSearch');"><i class="fa fa-pie-chart"></i><p>Analysis</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Warranty Form')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/warrantyFormSearch.aspx');"><i class="fa fa-newspaper-o"></i>Warranty</a></li> -->
                        <!-- <li *ngIf="checkAvailable('Water Process WorkOrder')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/WaterProcessWorkOrderSearch.aspx');"><i class="fa fa-reorder"></i><p>Work Orders</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('Water Process WorkOrder')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-reorder"></i><p>Work</p>&nbsp;<p>Orders</p> <span class='text-red inputrequired'>*</span></a></li>
                        <!-- <li *ngIf="checkAvailable('Water Process Machine History')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/WaterProcessMachineHistory.aspx');"><i class="fa fa-history"></i><p>History</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li ><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/WaterProcessMachineHistory');"><i class="fa fa-history"></i><p>History</p></a></li>

                        <li ><a href="javascript:void(0)" (click)="RouteClick('WaterProcess/WPHistoryUploadSearch');"><i class="fa fa-wrench"></i><p>Upload</p>&nbsp;<p>History</p></a></li>
                        <li ><a href="javascript:void(0)" (click)="RouteClick('Admin/FieldMasterSearch');"><i class="fa fa-tasks"></i><p>Field</p>&nbsp;<p>Master</p></a></li>
                      </ul>
                </li>

                <li *ngIf="checkAvailable('Dialysis Machine')"  class="dropdown">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                     <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                        <i class="fa fa-cog headerMenu"></i>
                        <p style="margin:0px;">D. Machines</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <!-- <li *ngIf="checkAvailable('DM Filter')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMRepairsFilterSearch.aspx');"><i class="fa fa-exclamation-circle"></i>Filter</a></li> -->

                        <li *ngIf="checkAvailable('DM Incident')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/IncidentSearch')"><i class="fa fa-exclamation-circle"></i><p>Incident</p></a></li>
                        <li *ngIf="checkAvailable('DM Repair Form')" style="cursor:pointer" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/DMRepairDetailSearch')"><i class="fa fa-gears"></i><p>Repair</p></a></li>
                        <!-- <li *ngIf="checkAvailable('DM Bacteria')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMBacteriaSearch.aspx');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li> -->
                        <li *ngIf="checkAvailable('DM Bacteria')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/BacteriasSearch');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li>
                        <!-- <li *ngIf="checkAvailable('DM Pyrogen')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMPryogenListing.aspx');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li> -->
                        <li *ngIf="checkAvailable('DM Pyrogen')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/PyrogensSearch');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li>
                        <li *ngIf="checkAvailable('Filter Exchange')" style="cursor:pointer" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/FilterSearch')"><i class="fa fa-gears"></i><p>Filter</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Filter Exchange')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMFilterChangeSearch.aspx');"><i class="fa fa-filter"></i>Filter</a></li> -->
                        <li *ngIf="checkAvailable('Hours Update')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/DMHoursUpdateSearch');"><i class="fa fa-hourglass-1"></i><p>Hours</p></a></li>
                        <!-- <li><a href="javascript:void(0)" (click)="RouteClick('DMTransfer/DMTransferSearch');"><i class="fa fa-exchange"></i><p>Transfers</p><span class='text-red inputrequired'>*</span></a></li> -->
                        <!-- <li *ngIf="checkAvailable('Hours Update')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMHoursUpdateSearch.aspx');"><i class="fa fa-hourglass-1"></i>Hours</a></li> -->
                        <!-- <li *ngIf="checkAvailable('Upgrades/Mods')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMUpgradeModificationsSearch.aspx');"><i class="fa fa-level-up"></i>Upgrades</a></li> -->
                        <li *ngIf="checkAvailable('Upgrades/Mods')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/DMUpgradeModificationsSearch')"><i class="fa fa-level-up"></i><p>Upgrades/</p><p>Modification</p></a></li>
                        <li *ngIf="checkAvailable('Vendor Repair')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-reorder"></i><p>Work</p>&nbsp;<p>Orders</p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- MenuClick('~/DialysisMachine/vendorRepairSearch.aspx') -->
                        <li *ngIf="checkAvailable('Warranty Form Customer')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-newspaper-o"></i><p>Warranty</p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- MenuClick('~/DialysisMachine/warrantyFormSearch.aspx') -->
                        <!-- <li *ngIf="checkAvailable('Dialysis Machine History')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/dmMachineHistory.aspx');"><i class="fa fa-history"></i>History</a></li> -->
                        <li *ngIf="checkAvailable('Dialysis Machine History')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/DMMachineHistory');"><i class="fa fa-history"></i><p>History</p></a></li>

                        <!-- <li *ngIf="checkAvailable('Preventive Maintenance')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/DMPreventiveMaintenanceSearch.aspx');"><i class="fa fa-wrench"></i>Preventive Maintenance</a></li> -->
                        <li *ngIf="checkAvailable('Preventive Maintenance')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/PreventiveMaintenanceSearch');"><i class="fa fa-wrench"></i><p>Preventive</p>&nbsp;<p>Maintenance</p></a></li>
                        <!-- <li ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/FieldServiceSearch');"><i class="fa fa-wrench"></i><p>Home </p><p>Field </p><p>Service </p><span class='text-red inputrequired'>*</span></a></li> -->
                        <li><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/MRMHistoryUploadSearch');"><i class="fa fa-wrench"></i><p>Upload</p>&nbsp;<p>History</p></a></li>
                    </ul>
                </li>

                <li *ngIf="checkAvailable('Home')"  class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                        <i class="fa fa-home headerMenu"></i>
                        <p style="margin:0px;">Hm Program</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <!-- <li ><a href="javascript:void(0)" (click)="RouteClick('HomeFieldService/HomeFieldServiceSearch');"><i class="fa fa-wrench"></i><p>Home</p>&nbsp;<p>Field</p>&nbsp;<p>Service </p><span class='text-red inputrequired'>*</span></a></li> -->
                      <!-- <li ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/FieldServiceSearch');"><i class="fa fa-wrench"></i><p>Home </p><p>Field</p> <p>Service </p><span class='text-red inputrequired'>*</span></a></li> -->
                      <li ><a href="javascript:void(0)" (click)="RouteClick('Home/HomeStationSearch');"><i class="fa fa-tasks"></i><p>Home </p>&nbsp;<p>Stations</p></a></li>
                      <!-- <li ><a href="javascript:void(0)" (click)="RouteClick('Home/PreventiveMaintenanceSearch');"><i class="fa fa-wrench"></i><p>Preventive</p>&nbsp;<p>Maintenance</p></a></li> -->
                      <!-- <li ><a href="javascript:void(0)" (click)="RouteClick('Home/AutoTask/AutoTaskList');"><i class="fa fa-wrench"></i><p>Station</p>&nbsp;<p>Master</p></a></li> -->

                    </ul>
                </li>

                <li *ngIf="checkAvailable('Other')"  class="dropdown">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                  <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                                  <i class="fa fa-wrench headerMenu"></i>
                                  <p style="margin:0px;">O. Repairs</p>
                  </a>
                  <ul class="dropdown-menu" role="menu">
                        <li><a href="javascript:void(0)" (click)="RouteClick('ORepairs/Repairs/RepairDetailSearch');"><i class="fa fa-gears"></i><p>Repair</p></a></li>
                        <li><a href="javascript:void(0)" (click)="RouteClick('ORepairs/OtherMachineHistory');"><i class="fa fa-history"></i><p>History</p></a></li>
                        <li ><a href="javascript:void(0)" (click)="RouteClick('Other/OtherHistoryUploadSearch');"><i class="fa fa-wrench"></i><p>Upload</p>&nbsp;<p>History</p></a></li>
                  </ul>
                </li>

                <li *ngIf="checkAvailable('Central Delivery')"  class="dropdown">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                   <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                        <i class="fa fa-file-excel-o headerMenu"></i>
                        <p style="margin:0px;">Concentrates</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">

                        <!-- <li *ngIf="checkAvailable('Daily Bicarb Tracking')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/centralDeliveryBicarbPowderSearch.aspx');"><i class="fa fa-calendar"></i>Daily</a></li> -->
                        <li *ngIf="checkAvailable('Daily Bicarb Tracking')" ><a href="javascript:void(0)" (click)="RouteClick('Concentrates/Daily/CDBicarbPowderSearch')"><i class="fa fa-calendar"></i><p>Daily</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Acid Drum Tracking')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/centralDeliveryAcidDrumFillingList.aspx');"><i class="fa fa-filter fa-rotate-180"></i>Acid</a></li> -->
                        <li *ngIf="checkAvailable('Acid Drum Tracking')" ><a href="javascript:void(0)" (click)="RouteClick('Concentrates/AcidDrumFilling/AcidDrumSearch')"><i class="fa fa-filter fa-rotate-180"></i><p>Acid</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Central Delivery Bacteria')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/centralDeliveryBacteriaSearch.aspx');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li> -->
                        <li *ngIf="checkAvailable('Central Delivery Bacteria')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/BacteriasSearch');"><i class="fa fa-bug"></i><p>Bacteria</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Central Delivery Pyrogen')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/CDPryogenListing.aspx');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li> -->
                        <li *ngIf="checkAvailable('Central Delivery Pyrogen')" ><a href="javascript:void(0)" (click)="RouteClick('DialysisMachine/PyrogensSearch');"><i class="fa fa-bug"></i><p>Pyrogen</p></a></li>

                        <!-- <li *ngIf="checkAvailable('Central Delivery Repair')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/centralDeliveryRepairSearch.aspx');"><i class="fa fa-gears"></i><p>Repair</p></a></li> -->

                        <li *ngIf="checkAvailable('Central Delivery Repair')" ><a href="javascript:void(0)" (click)="RouteClick('Concentrates/Repairs/RepairDetailSearch');"><i class="fa fa-gears"></i><p>Repair</p></a></li>

                        <!-- <li *ngIf="checkAvailable('Central Delivery Warranty Form')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/WaterProcessPryogenListing.aspx');"><i class="fa fa-wrench"></i> Preventive Maintenance</a></li> -->
                        <!-- <li *ngIf="checkAvailable('Central Delivery Warranty Form')" ><a href="javascript:void(0)" (click)="MenuClick('~/CentralDelivery/warrantyFormSearch.aspx');"><i class="fa fa-newspaper-o"></i>Warranty</a></li> -->
                        <li *ngIf="checkAvailable('Central Delivery WorkOrder')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-reorder"></i><p>Work</p>&nbsp;<p>Orders </p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- MenuClick('~/CentralDelivery/CentralDeliveryWorkOrderSearch.aspx') -->
                        <li *ngIf="checkAvailable('Central Delivery Machine History')" ><a href="javascript:void(0)" (click)="RouteClick('UnderConstruction/UnderConstruction');"><i class="fa fa-history"></i><p>History </p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- MenuClick('~/CentralDelivery/CentralDeliveryMachineHistory.aspx') -->
                      </ul>
                </li>

<!--
                <li *ngIf="checkAvailable('Inventory')"  class="dropdown">
                    <a  href="javascript:void(0)"  (click)="MenuClick('~/inventory/inventorySearch.aspx');" class="dropdown-toggle"  style="text-align:center">
                        <i class="fa fa-stack-overflow headerMenu"></i>
                        <p style="margin:0px;">Inventory</p>
                    </a>
                </li> -->

                <li *ngIf="checkAvailable('Inventory')"  class="dropdown">
                       <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                          <i class="fa fa-stack-overflow headerMenu"></i>
                          <p style="margin:0px;">Inventory</p>
                      </a>
                      <ul class="dropdown-menu" role="menu">
                        <li *ngIf="checkAvailable('Inventory')" ><a href="javascript:void(0)" (click)="RouteClick('Inventory/InventorySearch')"><i class="fa fa-stack-overflow"></i><p>Inventory</p></a></li>
                        <li><a href="javascript:void(0)" (click)="RouteClick('DMTransfer/DMTransferSearch');"><i class="fa fa-exchange"></i><p>Transfers</p></a></li>
                      </ul>
                  </li>


                <li *ngIf="checkAvailable('Tasks/Communication')"  class="dropdown">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                  <a (click)="RouteClick('Task/TaskViewer');" href="javascript:void(0)"   style="text-align:center">
                        <i class="fa fa-calendar headerMenu"></i>
                        <span class="label label-danger" *ngIf="UrgentTasks!=0" >{{UrgentTasks}}</span>
                        <p style="margin:0px;">Task</p>
                    </a>
                    <!-- <a (click)="MenuClick('~/Tasks/taskSearch.aspx');" href="javascript:void(0)" class="dropdown-toggle"  style="text-align:center">
                        <i class="fa fa-calendar headerMenu"></i>
                        <span class="label label-danger" *ngIf="UrgentTasks!=0" >{{UrgentTasks}}</span>
                        <p style="margin:0px;">Task</p>
                    </a> -->
                </li>





                <li *ngIf="checkAvailable('Reports')"  class="dropdown">
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                    <a href="javascript:void(0)" class="dropdown-toggle"  data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" style="text-align:center">
                        <i class="fa fa-bar-chart headerMenu"></i>
                        <p style="margin:0px;">Reports</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <!-- <li *ngIf="checkAvailable('Paging Report')" ><a href="javascript:void(0)" (click)="MenuClick('~/Reports/pagingReportSearch.aspx');"><i class="fa fa-mobile"></i>Pager</a></li> -->
                        <li *ngIf="checkAvailable('Paging Report')"><a href="javascript:void(0)" (click)="RouteClick('Dashboard')"><i class="fa fa-tachometer"></i><p>Peformance</p></a></li>
                        <li *ngIf="checkAvailable('Paging Report')" ><a href="javascript:void(0)" (click)="RouteClick('Reports/PagingReportSearch')"><i class="fa fa-mobile"></i><p>Pager</p></a></li>
                        <!-- <li *ngIf="checkAvailable('Machine Report')" ><a href="javascript:void(0)" (click)="MenuClick('~/DialysisMachine/MachineReport.aspx');"><i class="fa fa-cog"></i><p>DM Report </p><span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('Machine Report')" ><a href="javascript:void(0)" (click)="RouteClick('Reports/DMReportSearch');"><i class="fa fa-cog"></i><p>DM</p>&nbsp;<p>Report </p><span class='text-red inputrequired'>*</span></a></li>
                        <!-- <li *ngIf="checkAvailable('H2O Report')" ><a href="javascript:void(0)" (click)="MenuClick('~/waterprocess/WaterReport.aspx');"><i class="fa fa-tint"></i><p>H2O Report</p> <span class='text-red inputrequired'>*</span></a></li> -->
                        <li *ngIf="checkAvailable('H2O Report')" ><a href="javascript:void(0)" (click)="RouteClick('Reports/H2OReportSearch');"><i class="fa fa-tint"></i><p>H2O</p>&nbsp;<p>Report </p><span class='text-red inputrequired'>*</span></a></li>
                        <li *ngIf="checkAvailable('Calculations')" ><a href="javascript:void(0)"><i class="fa fa-calculator"></i><p>References</p></a></li>
                        <!-- MenuClick('~/Reports/calculations.aspx') -->
                    </ul>
                </li>

                <!-- <li *ngIf="checkAvailable('Reports')">
                  <a href="javascript:void(0)" (click)="RouteClick('Dashboard');" style="text-align:center">
                    <i class="fa fa-bar-chart headerMenu"></i>
                    <p style="margin:0px;">Reports</p>
                  </a>
              </li> -->

                <!-- <li *ngIf="checkAvailable('Support')"  class="dropdown">-->
                  <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                    <!-- <a  href="javascript:void(0)" class="dropdown-toggle"  style="text-align:center">
                        <i class="fa fa-question-circle headerMenu"></i>
                        <p style="margin:0px;">Help</p>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                        <li *ngIf="checkAvailable('Support Tickets')" ><a href="javascript:void(0)" (click)="MenuClick('~/Help/searchTicket.aspx');"><i class="fa fa-ticket"></i><p>Tickets</p></a></li> -->
                        <!-- <li *ngIf="checkAvailable('Forum')" ><a href="javascript:void(0)" (click)="MenuClick('~/Help/Forum.aspx');"><i class="fa fa-wpforms"></i>Forum</a></li> -->
                        <!-- <li *ngIf="checkAvailable('Document Resources')" ><a href="javascript:void(0)" (click)="MenuClick('~/Help/documentsourcedetails.aspx');"><i class="fa fa-file"></i><p>Documents</p></a></li>
                        <li *ngIf="checkAvailable('Help')" ><a href="javascript:void(0)" (click)="MenuClick('~/Help/help.aspx');"><i class="fa fa-desktop"></i><p>Software</p></a></li>
                        <li *ngIf="checkAvailable('Warranty Form Customer')" ><a href="javascript:void(0)" (click)="MenuClick('~/Help/WarrantyFormCustomer.aspx');"><i class="fa fa-user-md"></i><p>Warranty Form</p></a></li>
                    </ul>
                </li> -->

                <li *ngIf="checkAvailable('Search')"  class="dropdown">
                  <a  href="javascript:void(0)" style="text-align:center">
                      <i class="fa fa-search headerMenu"></i>
                      <p style="margin:0px;">Search</p>
                  </a>
                </li>

                <!-- <li *ngIf="checkAvailable('Other')"  class="dropdown">
                  <a href="javascript:void(0)" (click)="openImageDialog(transaction)"
                  aria-hidden="true"   style="text-align:center">
                    <i class="fa fa-warning headerMenu" style="display:inline-block;color:#F44336;"></i>
                      <p style="margin:0px;color:#F44336">Support</p>
                  </a>
              </li> -->


              <li  *ngIf="checkAvailable('Bulletin')" class="dropdown pointer">
                <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                  <!-- <a href="javascript:void(0)" (click)="MenuClick('~/bulletin/bulletinSearch.aspx');" class="dropdown-toggle"  style="text-align:center"> -->
                    <a href="{{ForumURL}}"  target="_blank"  style="text-align:center">
                      <i class="fa fa-comments headerMenu"></i>
                      <!-- <span class="label label-danger" *ngIf="UrgentMessage!=0" >{{UrgentMessage}}</span> -->
                      <p style="margin:0px;">Forum</p>
                  </a>
              </li>

              <li *ngIf="checkCompany('SCSCDU') && IsLiveVersion()" class="dropdown">
                <a   href="{{OldRedirectUrl}}"  target="_blank" style="text-align:center">
                    <i class="fa fa-globe headerMenu"></i>
                    <p style="margin:0px;">BMR V1.5</p>
                </a>
              </li>
              <!-- <li class="dropdown" *ngIf="checkAvailable('Other')" >
                <a href="javascript:void(0)" (click)="RouteClick('Dashboard/DashBoardDetails')">
                    <p>Dashboard</p>
                </a>
            </li> -->

                <!-- <li class="dropdown">
                    <a href="~/user/forgotpassword.aspx" class="dropdown-toggle"  style="text-align:center">
                        <i class="fa fa-folder headerMenu"></i>
                        <p style="margin:0px;">Forgot<br />  Password</p>
                    </a>
                </li> -->
            </ul>

            <!-- /.sidebar -->
        </div>


            <div class="navbar-custom-menu" *ngIf="loginName" (mouseover)="GetTodayHourEfficeincy()">
                <ul class="nav navbar-nav">

                    <!-- User Account: style can be found in dropdown.less -->
                    <li class="dropdown user user-menu">
                      <!--data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"-->
                        <a  href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <!-- <b><span class="hidden-xs" [innerHTML]="loginName"></span></b> -->
                            <img src="./../assets/img/UserIcon.png" class="img-circle" style="width:35px;height:35px;border-radius:50%;">
                            <span class="hidden-xs"></span>
                        </a>
                        <ul class="dropdown-menu" style="width:200px">
                            <!-- User image -->
                            <li class="user-header" style="height: 80px;">
                                <p style="margin-top: 0px;">{{loginNameWithdesg}}<b style="float: right;" *ngIf="userEfficiencyDisplay" >{{Efficiency_Today}}%</b><br>
                                  <small>{{userRole}}</small>
                                 <small>Last Login In: {{lastLoginDateTime | date:'dd/MM/yyyy'}}</small>
                                 <small>
                                   <a *ngIf="GetSiteName() != 'MRMDemo' && showStagingLiveLink()" (click)="setWindowName()" style="color: rgba(255, 255, 255, 0.8); font-size: 12px; text-decoration: underline;margin-top: 0px;">
                                  <span *ngIf="WebSiteType=='Live'" >Staging Site</span>
                                  <span *ngIf="WebSiteType=='Staging'" >Live Site</span>
                                </a>
                                <a *ngIf="GetSiteName() == 'MRMDemo'" style="color: rgba(255, 255, 255, 0.8); font-size: 12px; text-decoration: underline;margin-top: 0px;" href="{{renalUrl}}">
                                    <span>Dashboard</span>
                                  </a>
                                 </small>
                                </p>
                            </li>
                            <!-- <li style="text-align: center; background-color: #3c50ae; color:white;">
                                <small>Last Login - 06:18 AM, 27 Jul 18</small>
                            </li> -->
                            <!-- Menu Footer-->
                            <li class="user-header" style="height:58px;">

                                <div class="pull-center">
                                    <a href="javascript:void(0)" (click)="logout()" class="btn btn-default btn-flat">Log out</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <!-- Control Sidebar Toggle Button -->

                </ul>
            </div>
            <!-- <a id="renalLogo" href="{{renalUrl}}" class="logo" style="float: right;width: auto">
                <img src="../../assets/img/Mrmrenal.png"/>
            </a> -->
    <a name="renalLogo" href="{{renalUrl}}" class="logoContainer logoright" style="float: right;width: auto; display: none !important;">
        <i class="fa fa-home mrmlogo"></i>
      <b class="mrmtab" style="display:block;margin-top:10px;">BMR</b>
      <b class="mrmtab" style="vertical-align:bottom;font-size:12px;margin-right:40px;margin-left:1px">RENAL</b>
      <span class="pull-center mrmtab">
        <i class="fa fa-globe" style="margin-top:-10px;display:inline-block;"></i>
      </span>
    </a>
  </nav>

</header>
<div id="feedback">

    <a href="javascript:void(0)" style="display: none;" (click)="openImageDialog('')"><i class="fa fa-warning headerMenu" style="display:inline-block;"></i>&nbsp;Support</a>
  </div>

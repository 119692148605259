<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>


<div class="col-sm-12" style="margin-top: 20px;margin-bottom: 20px">

<table width="300px" cellpadding="3" cellspacing="3">
      <tbody>



                      <tr *ngFor="let item of ScheduleColors;" >

                        <td style="padding:3px;font-weight:bold">{{item.symbol}}</td><td  style="padding:3px;font-weight:bold" [style.background-color]="item.color">&nbsp;&nbsp;&nbsp;</td><td  style="padding:3px;font-weight:bold">{{item.description}}</td>
                    </tr>

      </tbody>
</table>

</div>


import { Component, ViewChild } from '@angular/core';
import { RPTApiService } from '../../API/rptapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'PagerSearch',
  templateUrl: './PagerSearch.component.html'
})
export class PagerSearchComponent {
  UserList: any;
  constructor(
    private rptapi: RPTApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  IssueDropDownList = [
    { value: '', text: 'Select' },
    { value: 'Water Treatment', text: 'Water Treatment' },
    { value: 'Dialysis Machines', text: 'Dialysis Machines' },
    { value: 'Central Delivery', text: 'Central Delivery' },
    { value: 'BMR Software', text: 'BMR Software' },
    { value: 'Other Computer Issues', text: 'Other Computer Issues' },
    { value: 'See Notes', text: 'See Notes' },
  ];
  PagerDetail = [];
  selectedDivision = 'All Divisions';
  selectedIssue = '';
  selectedPagingStaff = '';
  selectedOnCallStaff = '';
  ReportId = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'Reports/PagingReportDetail';
  workOrderRefNo = '';
  currentPageNumber = 1;
  sortExpression = 'default';
  sortDirection = 'DESC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Search Paging Reports';
  searchText = 'Search Records';
  defaultSort;

  // tslint:disable-next-line:member-ordering
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false
  };

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {

    this.auth.GetCurrentUserDivision().subscribe(division => {

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader('#', 'reportnumber', '4');
      this.dataGridService.addHeader('Report ID', 'reportnumber', '5');
      this.dataGridService.addHeader('Division', 'division', '10');
      this.dataGridService.addHeader('Date', 'date', '8');
      this.dataGridService.addHeader('Issue', 'issue', '8');
      this.dataGridService.addHeader('Paging Staff', 'pagingstaff', '15');
      this.dataGridService.addHeader('On-Call Staff', 'oncallstaff', '15');
      this.dataGridService.addHeader('Issue Status', 'status', '10');
      this.sortDirection = 'DESC';
      this.sortExpression = 'default';
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort('default', true);
      // this.fillSiloes();
      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else
        this.fillDialysisUnit();

      this.fillUserList();
      this.CheckDirtySearch();

    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executePagerSearch();
  }

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executePagerSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const PagerSearch = this.prepareSearch();
    this.rptapi.GetPagerReportsRecords(PagerSearch).subscribe(
      pagedData => {
        // console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.pageReportsDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    const PagerSearch = <any>{};

    PagerSearch.reportnumber = this.ReportId;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      PagerSearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      PagerSearch.todate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision != '0' && this.selectedDivision != 'All Divisions') {
      PagerSearch.divisionText = this.selectedDivision;
    }

    if (this.selectedOnCallStaff !== undefined && this.selectedOnCallStaff !== '') {
      PagerSearch.oncallstaff = this.selectedOnCallStaff;
    }

    if (this.selectedPagingStaff !== undefined && this.selectedPagingStaff !== '') {
      PagerSearch.pagingstaff = this.selectedPagingStaff;
    }

    if (this.selectedIssue !== undefined && this.selectedIssue !== '') {
      PagerSearch.issue = this.selectedIssue;
    }
    PagerSearch.currentPageNumber = this.currentPageNumber;
    PagerSearch.sortExpression = this.sortExpression;
    PagerSearch.sortDirection = this.sortDirection;
    PagerSearch.pageSize = this.pageSize;
    // console.log(PagerSearch);
    return PagerSearch;
  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executePagerSearch();
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue = '') {
    this.commonapi.FillDivision(true).subscribe(list => {

      this.DialysisUnits = list;

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(() => this.executePagerSearch());
        }
      }
    });
  }

  fillUserList() {
    this.rptapi
      .GetUsersDD()
      .subscribe(list => (this.UserList = list));
  }

  executePagerSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.ReportId = '';
    this.FromDate = '';
    this.ToDate = '';
    this.selectedIssue = '';
    this.selectedIssue = '';
    this.selectedOnCallStaff = '';
    this.selectedPagingStaff = '';
    this.executePagerSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision && this.selectedDivision != 'All Divisions')  ||  this.selectedIssue  ||  this.selectedOnCallStaff
      ||  this.selectedPagingStaff
      || this.ReportId  ||
      (this.FromDate && this.FromDate !== null && this.FromDate !== '') ||
      (this.ToDate && this.ToDate !== null && this.ToDate !== '')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }

  DialysisUnitDropDownChanged() {
    this.executePagerSearch();
  }
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

<div class="modal-header  col-sm-12">
  <h4 class="modal-title col-sm-8">Vehicle History</h4>

  <div class="text-right  col-sm-4" style="padding:0">
    <button type="button" class="btn btn-danger" style="margin-right:5px"
      (click)="dialogRef.close(true)">Close</button>
  </div>
</div>
<div class="clearfix"></div>
<div class="modal-body">
  <table class="">
    <tr>
      <td style="font-weight: bold;text-align: center;width: 5%">No</td>
      <td style="font-weight: bold;text-align: center;width: 10%">HFS ID</td>
      <td style="font-weight: bold;text-align: center;width: 10%">Visit Date</td>
      <td style="font-weight: bold;text-align: center;width: 10%">Station Name</td>
      <td style="font-weight: bold;text-align: center;width: 15%">Purpose</td>
      <td style="font-weight: bold;text-align: center;width: 10%">Start KMs</td>
      <td style="font-weight: bold;text-align: center;width: 10%">End KMs</td>
    </tr>
    <tr *ngFor="let item of VehicleHistoryList;let index=index;">
      <td>
        {{(index + 1)}}
      </td>
      <td>
        {{item.hfsId}}
      </td>
      <td>
        {{item.hfServiceVehicleStartTime | date:'dd/MM/yyyy'}}
      </td>
      <td>
        {{item.dialysisStationName}}
      </td>
      <td>
        {{item.hfServiceVisitPurpose}}
      </td>
      <td>
        {{item.hfServiceVehicleStartedKM}}
      </td>
      <td>
        {{item.hfServiceVehicleEndedKM}}
      </td>
    </tr>
    <tr *ngIf="VehicleHistoryList.length <= 0">
        <td colspan="7" style="text-align: center">
         No History Found.
        </td>
      </tr>
  </table>
</div>

import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'MaintenanceCleanOrTestHistory',
  templateUrl: './MaintenanceCleanOrTestHistory.component.html',
  styleUrls: ['./MaintenanceCleanOrTestHistory.component.css']
})

export class MaintenanceCleanOrTestHistory implements AfterViewInit {

  type = '';
  title = '';
  stationId = '';
  stationName = '';
  HFServiceId='';
  historyList = [];
  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    public dialogRef: MatDialogRef<MaintenanceCleanOrTestHistory>
  ) { }

  ngAfterViewInit() {

  }

  setPerformedMaintenanceHistory(type, title, stationId, stationName,hfServiceId) {

    this.type = type;
    this.title = title;
    this.stationId = stationId;
    this.stationName = stationName;
    this.HFServiceId = hfServiceId;
    this.homefieldApi.GetHomeFieldService_Maintenance_PerformedHistoryWithTypeByStationId({ Id: stationId,type: type,HFServiceId : hfServiceId }).subscribe(
      success => {
        if (success && success.length > 0) {
          this.historyList = success;
        } 
      },
      error => {
        AppComponent.HideLoader();
      });
  }
}

<style>
    .mat-tooltip {
        max-width: 100% !important;
    }
    
    .tooltip-dark {
        /* background:#3a4dab !important; */
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        background-color: #124c8d !important;
        hidedelay: 1;
    }
    
    .faplusicon {
        font-size: 22px;
        text-align: center;
        padding-left: 5px;
    }
</style>
<form [formGroup]="repairDetailForm" (ngSubmit)="executeDmrepairCreateUpdate('')" novalidate>


    <!-- <RepairPart></RepairPart> -->
    <section class="content-header" *ngIf="!homeService">
        <h1>
            DM Repair
            <!-- <sup  class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div [ngClass]="{
                      'box box-primary': !homeService

                    }">

                <PageHeader [showNote]="blnshowSave" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeDmrepairCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                    #pageheader *ngIf="!homeService"></PageHeader>

                <div [ngClass]="{
                  'box': !homeService }">

                    <div class="box-body " *ngIf="!homeService">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">dmRepairDetailsId</label>

                                <div class="col-sm-10">
                                    <input type="text" readonly class="form-control" name="dmRepairDetailsId" [value]="f.dmRepairDetailsId" formControlName="dmRepairDetailsId">
                                    <input type="text" readonly class="form-control" name="HFServiceId" [value]="f.HFServiceId" formControlName="HFServiceId">
                                    <input type="text" readonly class="form-control" name="HFServiceId" [value]="f.HFServiceId" formControlName="HFServiceId">
                                </div>
                            </div>
                            <div class=" box ibox box-info">
                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class="col-sm-4">
                                        <label class="col-sm-6 control-label">Event ID:</label>
                                        <div class="col-sm-6">
                                            <label class="readOnlyText"> {{repairId}}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-6 control-label">Form Date:</label>
                                        <div class="col-sm-6">
                                            <label class="readOnlyText"> {{f.formDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-6 control-label">Created By:</label>
                                        <div class="col-sm-6">
                                            <label class="readOnlyText"> {{UserName}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <!-- <div class="col-sm-12 box-row">

                                        <label class="col-sm-2 control-label"><span class="text-red inputrequired">*</span>Start Date:</label>
                                        <div class="col-sm-10" *ngIf="f.dmRepairDetailsId.value > 0">
                                                <label class="readOnlyText"> {{repairStartDateLabel | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-sm-10"  *ngIf="f.dmRepairDetailsId.value == 0">
                                                <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="repairStartDate"
                                                  formControlName="repairStartDate" [options]="myOptions"
                                                  #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                required/>
                                                <span  *ngIf="f.repairStartDate.errors?.required && (f.repairStartDate.dirty || f.repairStartDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                        Required!
                                                    </span>
                                        </div>
                                    </div> -->
                                <div class="clearfix"></div>
                                <!-- <div class="col-sm-12 box-row">
                                    <label class="col-sm-2 control-label"><span class="text-red inputrequired"></span>User Name:</label>
                                    <div class="col-sm-10"  >
                                            <label class="readOnlyText"> {{UserName}}</label>
                                    </div>
                                </div> -->
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    <div class="box-body box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmMailingAddress">
                                <h4 class="panel-title">
                                    <div class="box-header with-border" style="padding: 0px;">
                                        <h3 class="box-title container" *ngIf="!homeService">Dialysis Unit Details</h3>
                                        <h3 class="box-title container" *ngIf="homeService">{{selectedDialysisStationtext}} Details</h3>
                                    </div>
                                </h4>
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Division:</label>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0">
                                            <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0">
                                            <select class="form-control mdInput" formControlName="selectedDialysisUnits" name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                                                    <option  value="{{item.value}}" *ngFor="let item of DialysisUnitsWithoutHome">{{item.text}}</option>
                                                  </select>
                                            <span *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                    Required!
                                                  </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Start Date:</label>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0">
                                            <label class="readOnlyText"> {{repairStartDateLabel | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0">
                                            <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date" class="form-control mdInput" style="float:none" angular-mydatepicker name="repairStartDate" formControlName="repairStartDate" [options]="myOptions"
                                                #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" required/>
                                            <span *ngIf="f.repairStartDate.errors?.required && (f.repairStartDate.dirty || f.repairStartDate.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-12 box-row" *ngIf="!homeService">
                                    <div class="col-sm-6">
                                        <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Equipment:</label>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0">
                                            <label class="readOnlyText"> {{selectedEquipmenttext}}</label>
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0">

                                            <select class="form-control mdInput" formControlName="selectedEquipment" name="selectedEquipment" (change)="EquipmentDropDownChanged()">
                                                <option  value="{{item.value}}" *ngFor="let item of Equipments">{{item.text}}</option>
                                              </select>

                                            <span *ngIf="f.selectedEquipment.errors?.required && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                Required!
                                              </span>
                                            <span *ngIf="f.selectedEquipment.errors?.RepairStatus" [ngClass]="'error'">
                                                You cannot start a new Repair, while there is an open Repair still existing in the system.
                                              </span>
                                        </div>

                                    </div>
                                    <div class="col-sm-6" *ngIf="!homeService">
                                        <label class="col-sm-5 control-label">Station:</label>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0">
                                            <label class="readOnlyText"> {{selectedDialysisStationtext}}</label>
                                            <!-- <label class="readOnlyText" *ngIf="f.selectedDialysisStation.value ==''" > N/A</label> -->
                                        </div>
                                        <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0">
                                            <select class="form-control mdInput" formControlName="selectedDialysisStation" name="selectedDialysisStation">
                                                              <option  value="{{item.value}}" *ngFor="let item of DialysisStation">{{item.text}}</option>
                                                            </select>
                                            <span *ngIf="f.selectedDialysisStation.errors?.required && DetailTypes != 'DM' && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                              Required!
                                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>


                                <!-- <div class="col-sm-12 box-row">

                                        <label class="col-sm-2 control-label"><span class="text-red inputrequired">&nbsp;</span> User: </label>
                                        <div class="col-sm-10" *ngIf="f.dmRepairDetailsId.value > 0">
                                                <label class="readOnlyText"> {{selectedUsertext}}</label>

                                        </div>
                                        <div class="col-sm-10" *ngIf="f.dmRepairDetailsId.value == 0">
                                                    <select class="form-control mdInput" formControlName="selectedUser" name="selectedUser">
                                                            <option  value="{{item.value}}" *ngFor="let item of Users">{{item.text}}</option>
                                                          </select>
                                        </div>

                                    <div class="clearfix"></div>
                                </div> -->
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-12">

                                        <label class="control-label col-md-2" style="width:20.4%"><span class="text-red inputrequired">*</span>Machine Issue:</label>
                                        <div class="col-md-4" style="width:79.55%" *ngIf="((f.dmRepairDetailsId.value > 0 && showRemarks == false)  || (homeService == true && showRemarks == false))">
                                            <label class="readOnlyText" style="word-wrap: break-word;margin-bottom:0px;"> {{f.remarks.value}}</label>
                                        </div>
                                        <div class="col-md-9" *ngIf="(((f.dmRepairDetailsId.value == 0) && (homeService != true)) ||(homeService == true && showRemarks == true && f.dmRepairDetailsId.value == 0) || (showRemarks == true))">
                                            <input name="remarks" style="width:79.55%" formControlName="remarks" class="form-control lgInput" required/>
                                            <span *ngIf="f.remarks.errors?.required && (f.remarks.dirty || f.remarks.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                    Required!
                                                </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>


                            </div>
                        </div>
                    </div>
                    <div class="box-body box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmMailingAddress">
                                <h4 class="panel-title">
                                    <a [ngClass]="collapsedmEquiInClass" data-toggle="collapse" data-parent="#accordion" href="#dmEquipIn" aria-expanded="collapseariaexpanded" aria-controls="collapsedmEquiIn" id="dmEquipInRefID">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title container">Equipment Hours In Details
                                                <i *ngIf="f.hoursin.value || f.selectedinsurfacedis.value || f.selectedinhydraulicdis.value" class="fa fa-plus-square faplusicon"></i>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>

                            <div id="dmEquipIn" *ngIf="hfcompleted==true" [ngClass]="collapsedmEquiIn" role="tabpanel" aria-labelledby="dmEquipOuts">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6" *ngIf="homeService">
                                                <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Equipment:</label>
                                                <div class="col-sm-4">
                                                    <label class="readOnlyText"> {{selectedEquipmenttext}}</label>
                                                </div>
                                                <div class="col-sm-3 readOnlyText" style="text-align:center;    padding: 5px 0;cursor:auto">
                                                    <button class="btn btn-primary  no-print btn-label-left center-block btn-xs" type="button" title="History" (click)="OpenHistory(f.selectedEquipment.value)" style="display:inline-block;" [attr.disabled]="disableControl?'true':null">
                                  <i class="fa fa-history" style="color: #fff;"></i>
                                </button>
                                                    <button class="btn btn-primary  no-print btn-label-left center-block btn-xs" *ngIf="f.dmRepairDetailsId.value == 0" type="button" title="Exchange" (click)="transferStationEquipment('DM')" style="display: inline-block;margin-left: 10px;" [attr.disabled]="disableControl?'true':null">
                                  <i class="fa fa-exchange" style="color: #fff;"></i>
                                </button>
                                                </div>
                                            </div>

                                            <div class="col-sm-6" *ngIf="hrsTracking">
                                                <label class="col-sm-5 control-label"> <span class="text-red inputrequired">*</span>Hours In:</label>
                                                <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0 && f.hoursin.value != 0 && !showHoursInTextBox">
                                                    <label class="readOnlyText"> {{f.hoursin.value}}</label>
                                                </div>
                                                <div class="col-sm-7" *ngIf="(f.dmRepairDetailsId.value == 0 || f.hoursin.value == 0 || showHoursInTextBox) && (hrsTracking)">
                                                    <input maxlength='5' class='form-control smInput' name='hoursin' formControlName='hoursin' (keypress)="numberOnly($event)" (input)="hoursinChange($event.target.value)" />
                                                    <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                                    <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>
                                                    <br/>
                                                    <span *ngIf="f.hoursin.errors?.required && (f.hoursin.dirty || f.hoursin.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                          Required!
                                      </span>
                                                    <span *ngIf="f.hoursin.errors?.pattern" [ngClass]="'error'">
                                              Invalid Hours In
                                          </span>

                                                    <span *ngIf="f.hoursin.errors?.GreaterHoursOut" [ngClass]="'error'">
                                              Hrs IN => previous Hrs Out
                                      </span>
                                                    <span *ngIf="check4000HoursValidation()" [ngClass]="'error'">
                                      New Hours >= 4000 from previous Hours Out.
                                      </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" *ngIf="!homeService">
                                                <label class="col-sm-5 control-label"> Warranty Status:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{WarrentyStatus}}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>IN Surface Disinfection:</label>
                                                <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0 && blnHoursInVal == false ">
                                                    <label class="readOnlyText" *ngIf="f.selectedinsurfacedis.value  !=''"> {{f.selectedinsurfacedis.value}}</label>
                                                    <!-- <label class="readOnlyText" *ngIf="f.selectedinsurfacedis.value  ==''"> N/A</label> -->
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0 ||  blnHoursInVal == true">
                                                    <select class="form-control mdInput" formControlName="selectedinsurfacedis" name="selectedinsurfacedis">
                                                    <option  value="{{item.value}}" *ngFor="let item of insurfacedisOptions">{{item.text}}</option>
                                                  </select>
                                                    <span *ngIf="f.selectedinsurfacedis.errors?.required && (f.selectedinsurfacedis.dirty || f.selectedinsurfacedis.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                    Required!
                                                  </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>IN Hydraulic Disinfection:</label>
                                                <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value > 0 && blnHoursInVal == false">
                                                    <label class="readOnlyText" *ngIf="f.selectedinhydraulicdis.value != ''"> {{f.selectedinhydraulicdis.value}}</label>
                                                    <!-- <label class="readOnlyText" *ngIf="f.selectedinhydraulicdis.value == ''"> N/A</label> -->
                                                </div>
                                                <div class="col-sm-7" *ngIf="f.dmRepairDetailsId.value == 0 || blnHoursInVal == true">
                                                    <select class="form-control mdInput" formControlName="selectedinhydraulicdis" name="selectedinhydraulicdis">
                                          <option  value="{{item.value}}" *ngFor="let item of inhydraulicdisOptions">{{item.text}}</option>
                                      </select>
                                                    <span *ngIf="f.selectedinhydraulicdis.errors?.required && (f.selectedinhydraulicdis.dirty || f.selectedinhydraulicdis.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                          Required!
                                      </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box-body box ibox box-info" *ngIf="((ErrorTrans.length > 0 && status=='Completed') || status!='Completed')">

                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmErrorCodes" tooltip-class="tooltip-dark" tooltipPlacement="top">
                                <h4 class="panel-title">
                                    <a [ngClass]="collapseerr" data-toggle="collapse" data-parent="#accordion" href="#dmErrorCode" aria-expanded="collapseerrexpanded" aria-controls="collapsederrctl">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title container">Error Codes or Alarms and Warning Messages
                                                <span *ngIf="ErrorTrans.length > 0"><i class="fa fa-plus-square faplusicon"></i></span></h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>

                            <div id="dmErrorCode" [ngClass]="collapsederrctl" role="tabpanel" aria-labelledby="dmErrorCode" *ngIf="((ErrorTrans.length > 0 && status=='Completed') || status!='Completed')">
                                <div class="box-body" *ngIf="((ErrorTrans.length > 0 && status=='Completed') || status!='Completed')">

                                    <div class="row">

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-2 text-center">
                                                <strong>Error Code</strong>
                                            </div>
                                            <div class="col-sm-9 text-center">
                                                <strong>Description</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="(status!='Completed')">


                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-2 text-center" id="errorPaneWrapper">
                                                <input type="text" class="form-control" (keyup)="ErrorCodeChange('code')" (focus)='ErrorCodeChange("code",false)' matInput formControlName="ErrorSearchTerm" name="ErrorSearchTerm" [matAutocomplete]="auto">
                                                <mat-autocomplete id="errorPane" (optionSelected)='getErrorPosts($event.option,"code")' #auto="matAutocomplete" style="min-width:800px!important">
                                                    <mat-option *ngFor="let item of errorSearchResult" [value]="item.id">
                                                        {{ item.code}} {{ (duplicateErrorCodes && item.duplicate) ? '- ' + item.desc : '' }}
                                                    </mat-option>
                                                </mat-autocomplete>

                                            </div>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control" name="ErrorDescription" [value]="ErrorSelectedItem.desc" formControlName="ErrorDescription" [matAutocomplete]="autoerrordesc" (keyup)="ErrorCodeChange('desc')" (focus)='ErrorCodeChange("desc",false)'>
                                                <mat-autocomplete id="errorPaneDesc" (optionSelected)='getErrorPosts($event.option,"desc")' #autoerrordesc="matAutocomplete">
                                                    <mat-option *ngFor="let item of errorSearchResult" [value]="item.id">
                                                        {{item.desc}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>
                                            <div class="col-sm-1">
                                                <button (click)="AddError()" type="button" class="btn btn-primary btn-label-left center-block" [attr.disabled]="disableControl?'true':null">
                                                    <span><i class="fa fa-plus"></i></span>
                                                    <b>Add</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let item of ErrorTrans;let index=index;" class="row">


                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-2  text-center">
                                                <label class="readOnlyText"> {{item.ErrorCode}}   </label>
                                            </div>

                                            <div class="col-sm-9">
                                                <label class="readOnlyText"> {{item.errorDescription}}  </label>
                                            </div>
                                            <div class="col-sm-1">

                                                <button class="btn btn-danger btn-label-left center-block btn-xs" type="button" *ngIf="(status!='Completed') && (item.showDelete)" (click)="DeleteError(index)"> <span><i class="fa fa-times"></i></span>


                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box ibox box-info" *ngIf="status != 'Completed'">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference" tooltip-class="tooltip-dark" tooltipPlacement="top">
                                    <h4 class="panel-title">
                                        <a [ngClass]="collapseRef" data-toggle="collapse" data-parent="#accordion" href="#dmReferences" aria-expanded="collapseRefexpanded" aria-controls="collapseRefctl">
                                            <div class="box-header with-border" style="padding: 0px;">
                                                <h3 class="box-title container">Dialysis Machine References
                                                    <!-- <span><i class="fa fa-plus-square faplusicon"></i></span> -->
                                                </h3>
                                            </div>
                                        </a>
                                    </h4>
                                </div>
                                <div id="dmReferences" [ngClass]="collapseRefctl" role="tabpanel" aria-labelledby="dmReferences" *ngIf="status != 'Completed'">

                                    <div class="box-body" *ngIf="status != 'Completed'">
                                        <div class="row">
                                            <div class="col-sm-12 otherLinks box-row">

                                                <div class="col-sm-2 text-center">
                                                    <a *ngIf="f.selectedEquipment.value !='0' && f.selectedEquipment.value !=''" [attr.disabled]="disableControl?'true':null" href="javascript:void(0);" (click)="OpenHistory(f.selectedEquipment.value)"><strong>Machine History</strong></a>
                                                    <strong *ngIf="f.selectedEquipment.value =='0' || f.selectedEquipment.value ==''">Machine History</strong>
                                                </div>
                                                <div class="col-sm-2">
                                                    <!-- <a  href="javascript:void(0);" (click)="ShowCalibration($event)"><strong>Calibrations</strong></a> -->
                                                    <select class="form-control mdInput" formControlName="selectCallibrationDropDownList" name="selectelectricDropDownList" (change)="showImage($event)">
                            <option value="Select">Callibration</option>
                        <option  value="{{item.referenceImageName}}" *ngFor="let item of CallibrationList" (change)="showImage(item)">{{item.referenceName}}</option>
                      </select>
                                                </div>
                                                <div class="col-sm-2">
                                                    <!-- <a  href="javascript:void(0);" (click)="ShowElectrical($event)"><strong>Electrical</strong></a> -->
                                                    <select class="form-control mdInput" formControlName="selectElectricalDropDownList" name="selectElectricalDropDownList" (change)="showImage($event)">
                            <option value="Select">Electrical</option>
                        <option value="{{item.referenceImageName}}" *ngFor="let item of ElectricalList" (change)="showImage(item)">
                            {{item.referenceName}}</option>
                      </select>
                                                </div>
                                                <div class="col-sm-2">
                                                    <select class="form-control mdInput" formControlName="selecthydrolicDropDownList" name="selecthydrolicDropDownList" (change)="showImage($event)">
                          <option value="Select">Hydraulic</option>
                          <option  value="{{item.referenceImageName}}" *ngFor="let item of HydraulicList" >{{item.referenceName}}</option>
                      </select>
                                                </div>

                                                <div class="col-sm-2">
                                                    <select class="form-control mdInput" formControlName="selectMachineLayoutDropDownList" name="selectMachineLayoutDropDownList" (change)="showImage($event)">
                            <option value="Select">MachineLayout</option>
                        <option  value="{{item.referenceImageName}}" *ngFor="let item of MachineLayoutList" (change)="showImage(item)">{{item.referenceName}}</option>
                      </select>
                                                </div>

                                                <div class="col-sm-2 text-center">
                                                    <a href="javascript:void(0);" [attr.disabled]="disableControl?'true':null" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a>

                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <notes hidden [isValidForm] ="this.repairDetailForm.invalid"  (CheckValidForm)="GreaterValueCheck()"  (addNoteClick)="executeDmrepairCreateUpdate('Notes')" #notes>
                    </notes> -->
                    </div>
                    <div class="box-body box ibox box-info">
                        <div class="panel panel-default" tooltip-class="tooltip-dark" tooltipPlacement="top">

                            <div class="panel-heading" role="tab" id="dmParts">
                                <h4 class="panel-title">
                                    <div class="box-header with-border" style="padding: 0px;">
                                        <h3 class="box-title container">Repair Notes & Replaced Parts
                                            <span style="float: right;" *ngIf="parts != undefined && parts.PartTrans && parts.PartTrans.length >0 && totalCost != undefined">Total Cost : {{totalCost | currency}}</span>
                                            <!-- <span *ngIf="(notes &&  notes.NoteList && notes.NoteList.length > 0) || (parts != undefined && parts.PartTrans && parts.PartTrans.length >0)"><i class="fa fa-plus-square faplusicon"></i></span> -->
                                        </h3>
                                    </div>
                                </h4>
                            </div>

                            <notes [isValidForm]="this.repairDetailForm.invalid" (CheckValidForm)="GreaterValueCheck()" (addNoteClick)="executeDmrepairCreateUpdate('Notes')" #notes>
                            </notes>
                            <Parts_Component #parts *ngIf="ShowParts == true"></Parts_Component>


                        </div>
                    </div>

                    <div class="box-body box ibox box-info">

                        <div class="panel panel-default">
                            <!-- <div class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmEquipOut"
                                aria-expanded="false" aria-controls="dmEquipOutRef" > -->
                            <div class="panel-heading" role="tab" id="dmEquipOuts" tooltip-class="tooltip-dark" hide-delay="0" animation-duration="0">
                                <h4 class="panel-title">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmEquipOut" aria-expanded="false" aria-controls="dmEquipOutRef" (click)="dmEquipOutChange($event)" id="dmEquipOutRefID">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title container">Equipment Hours Out Details<span *ngIf="status != 'Completed'" style="font-size: 14px;color: red;margin-left: 15px;"> (Click here for filling the details to Complete Repair) </span>
                                                <i *ngIf="f.hoursout.value || f.outTimeOfEquipment.value || f.selectedOutsurfacedis.value || f.selectedouthydraulicdis.value" style="pointer-events: none;" class="fa fa-plus-square faplusicon"></i>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>
                            <!-- </div> -->
                            <div id="dmEquipOut" *ngIf="hfcompleted==true" class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmEquipOuts">

                                <div class="box-body">

                                    <div class="row">


                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6" *ngIf="hrsTracking">
                                                <label class="col-sm-6 control-label"><span class="text-red inputrequired">*</span>Hours Out: </label>
                                                <div class="col-sm-6" *ngIf="status!='Completed'">
                                                    <input class="form-control smInput" name="hoursout" formControlName="hoursout" maxlength="5" (keypress)="numberOnly($event)">
                                                    <span *ngIf="f.dmRepairDetailsId.value == 0">
                                                    ({{f.hoursin.value}})
                                                </span>
                                                    <span *ngIf="f.dmRepairDetailsId.value != 0">
                                                  ({{selectedEquipmentHours}})
                                              </span>

                                                    <span style=" display: inline-block;margin-left:10px;" class="btn btn-primary center-block btn-xs" (click)="ShowMachineHistory()" *ngIf="f.hoursin.value != '' && selectedEquipmentHours > f.hoursin.value" title="History">
                                                        <span><i class="fa fa-history"></i></span>
                                                    </span>

                                                    <span *ngIf="f.hoursout.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <br/>
                                                    <span *ngIf="f.hoursout.errors?.required && (f.hoursout.dirty || f.hoursout.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                      Required!
                                                    </span>
                                                    <span *ngIf="f.hoursout.errors?.InGreaterOut" [ngClass]="'error'" id="spanInGreaterOut">
                                                      Hrs Out => previous Hrs Out/Hrs In
                                                        </span>
                                                    <span *ngIf="f.hoursout.errors?.pattern" [ngClass]="'error'">Invalid number</span>


                                                    <span *ngIf="checkHoursInOutDifference() && status != 'Completed' && f.hoursin.value == selectedEquipmentHours.toString()" [ngClass]="'error'">
                                                      Hours Out >= Hours In + 100.
                                                        </span>
                                                </div>
                                                <div class="col-sm-6" *ngIf="status=='Completed'">
                                                    <label class="control-label"> {{f.hoursout.value}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-6 control-label"><span class="text-red inputrequired">*</span>Date Out:</label>
                                                <div class="col-sm-6" *ngIf="status=='Completed'">
                                                    <label class="control-label"> {{outTimeOfEquipmentLabel | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6" *ngIf="status!='Completed'" style="padding-left:15px">
                                                    <input (focus)="equipmentdateout.toggleCalendar()" (click)="equipmentdateout.openCalendar()" class="form-control mdInput" style="float:none" placeholder="Select a date" angular-mydatepicker name="outTimeOfEquipment" formControlName="outTimeOfEquipment" [options]="myOptions"
                                                        #equipmentdateout="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />

                                                    <span *ngIf="f.outTimeOfEquipment.errors?.required && (f.outTimeOfEquipment.dirty || f.outTimeOfEquipment.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                            Required!
                                          </span>

                                                    <span *ngIf='f.outTimeOfEquipment.errors?.error || f.outTimeOfEquipment.errors?.InGreaterDateOut' [ngClass]="'error'">
                                          Date Out must be
                                          greater than or equal
                                          to Date In! </span>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>


                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-6 control-label"><span class="text-red inputrequired">*</span>Out Surface Disinfection: </label>
                                                <div class="col-sm-6">
                                                    <label *ngIf="status=='Completed' && f.selectedOutsurfacedis.value != ''" class="control-label"> {{f.selectedOutsurfacedis.value}}</label>
                                                    <!-- <label *ngIf="status=='Completed' && f.selectedOutsurfacedis.value == ''"  class="control-label"> N/A</label> -->

                                                    <select *ngIf="status!='Completed'" class="form-control mdInput" formControlName="selectedOutsurfacedis" name="selectedOutsurfacedis">
                                                            <option  value="{{item.value}}" *ngFor="let item of OutsurfacedisOptions">{{item.text}}</option>
                                                          </select>
                                                    <!-- <span  *ngIf="f.selectedOutsurfacedis.errors?.RequiredStatus" [ngClass] = "'error'">
                                                                Required!
                                                            </span> -->
                                                    <span *ngIf="f.selectedOutsurfacedis.errors?.required && (f.selectedOutsurfacedis.dirty || f.selectedOutsurfacedis.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                              Required!
                                                          </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-6 control-label  longtitle"><span class="text-red inputrequired">*</span>Out Hydraulic Disinfection:</label>
                                                <div class="col-sm-6">
                                                    <label *ngIf="status=='Completed' && f.selectedouthydraulicdis.value !=''" class="control-label"> {{f.selectedouthydraulicdis.value}}</label>
                                                    <!-- <label *ngIf="status=='Completed' && f.selectedouthydraulicdis.value ==''"  class="control-label"> N/A</label> -->

                                                    <select *ngIf="status!='Completed'" class="form-control mdInput" formControlName="selectedouthydraulicdis" name="selectedouthydraulicdis">
                                                                <option  value="{{item.value}}" *ngFor="let item of outhydraulicdisOptions">{{item.text}}</option>
                                                              </select>
                                                    <!-- <span  *ngIf="f.selectedouthydraulicdis.errors?.RequiredStatus" [ngClass] = "'error'">
                                                                    Required!
                                                                </span> -->
                                                    <span *ngIf="f.selectedouthydraulicdis.errors?.required && (f.selectedouthydraulicdis.dirty || f.selectedouthydraulicdis.touched ||  isValidFormSubmitted==false)" [ngClass]="'error'">
                                                                  Required!
                                                              </span>

                                                </div>
                                            </div>

                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="box-body box ibox box-info" *ngIf="!homeService">
                        <div class="panel panel-default" *ngIf="((f.workOrderRefNo.value != '' && status=='Completed') || status!='Completed')">
                            <div class="panel-heading" role="tab" id="dmVendor" tooltip-class="tooltip-dark" tooltipPlacement="top">
                                <h4 class="panel-title">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmVendorRef" aria-expanded="false" aria-controls="dmVendorRef">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title container">Vendor Details
                                                <span *ngIf="f.workOrderRefNo.value != ''"><i class="fa fa-plus-square faplusicon"></i></span>
                                            </h3>
                                        </div>
                                    </a>
                                </h4>
                            </div>

                            <div id="dmVendorRef" class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmVendor">
                                <div class="panel-body">
                                    <div class="col-sm-12 box-row">
                                        <label class="col-sm-2 control-label  longtitle">Vendors Work Order Ref:</label>

                                        <div class="col-sm-10">
                                            <label *ngIf="status=='Completed'" class="control-label"> {{f.workOrderRefNo.value}}</label>
                                            <input type="text" *ngIf="status!='Completed'" class="form-control mdInput" name="workOrderRefNo" formControlName="workOrderRefNo" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-body">
                        <attachment (RaiseError)="AttachmentError($event)" #attachment>
                        </attachment>
                    </div>

                    <PageHeader [showNote]="blnshowSave" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeDmrepairCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                        #pageheader *ngIf="!homeService"></PageHeader>

                </div>
            </div>
        </div>
    </section>

</form>
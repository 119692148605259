import { Component, Input , Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';


@Component({
  selector: 'PageHeader' ,
  templateUrl: './Pageheader.component.html',

})
export class PageHeaderComponent {


  // count the clicks
  private clickTimeout = null;

  constructor( private router: Router, private _location: Location) {}

  @Input() title: string = '';
  @Input('showAddNew') showAddNew: boolean;
  @Input() showMainListing: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() showNote: boolean = false;
  @Input() showSave: boolean = false;
  @Input() showEdit: boolean = false;
  @Input() showPreviousPage: boolean = false;
  @Input() MainListingText: string = 'Main Listing';
  @Input() addurl: string = '';
  @Input() mainlisturl: string = '';
  @Output() searchShowChange = new EventEmitter<boolean>();
  @Output() SaveEvent = new EventEmitter<boolean>();
  @Output() EditEvent = new EventEmitter<boolean>();
  @Output() SearchTextChange = new EventEmitter<string>();
  @Output() addNoteClick = new EventEmitter<boolean>();
  @Input() showDelete: boolean = false;
  @Input() showPrint: boolean = false;
  @Output() DeleteEvent = new EventEmitter<boolean>();
  @Output() PrintEvent = new EventEmitter<boolean>();
  searchTextValue: string = '';
  @Input() isSearchOpen = false;

  RouteClick(value) {
    this.router.navigate([value]);
  }

  @Input()
  get searchText() {
    return this.searchTextValue;
  }
  set searchText(val) {
    this.searchTextValue = val;
    this.SearchTextChange.emit(this.searchTextValue);
  }

  SearchClick() {
     if (this.isSearchOpen) {
        this.searchTextValue = 'Search Records';
     } else {
        this.searchTextValue = 'Hide Search';
     }
     this.isSearchOpen =! this.isSearchOpen;
     this.searchShowChange.emit(this.isSearchOpen);
  }

  PreviousPageClick() {
    this._location.back();
  }

  SaveClick() {

    if (this.clickTimeout) {
      this.setClickTimeout(() => {});
    } else {
      // if timeout doesn't exist, we know it's first click
      // treat as single click until further notice
      this.setClickTimeout((itemId) =>
        this.handleSingleClick(itemId));
    }
  }

  EditClick() {

    this.EditEvent.emit();
 }

  AddNoteClick() {

      this.addNoteClick.emit();
  }
  DeleteClick() {
     this.DeleteEvent.emit();
  }

  Print()
  {
    this.PrintEvent.emit();
  }


  // sets the click timeout and takes a callback
// for what operations you want to complete when
// the click timeout completes
public setClickTimeout(callback) {
  // clear any existing timeout
  clearTimeout(this.clickTimeout);
  this.clickTimeout = setTimeout(() => {
    this.clickTimeout = null;
    callback();
  }, 200);
}
public handleSingleClick(itemId: string) {
   //The actual action that should be performed on click
   this.SaveEvent.emit();
}
}

import { Component, OnInit, ViewChild, Optional, Output, Input, EventEmitter } from '@angular/core';
import { DMApiService } from 'src/app/API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { DMRepairCustomValidators } from 'src/app/Validator/EuipmentRepairStatus';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';

import {
  Globals,
  actionType,

  attachmentFormModules
} from 'src/app/globals';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/API/auth.service';
// import { padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { HomeService } from 'src/app/API/home.service';
import { ContactApiService } from 'src/app/API/contactapi.service';
// import { PropertyBindingType } from '@angular/compiler';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { padNumber } from '../../Helper/utils';

@Component({
  selector: 'Pyrogen',
  templateUrl: './Pyrogen.component.html',
  styleUrls: ['./Pyrogen.component.css']
})
export class PyrogenComponent implements OnInit, CanComponentDeactivate {
  private today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;
  selectedROHours = 0;
  selectedDMHours = 0;


  @Input() FullScreen: boolean = true;
  @Output() SaveEvent = new EventEmitter<any>();
  data = {
    errormsg: ''
  }
  DMRepairStatus = false;
  RepairStatus = false;

  public hrsTracking = false;
  isValidFormSubmitted = null;
  ListURL = 'DialysisMachine/PyrogensSearch';
  status = '';
  stage = 0;
  loading = true;
  DialysisUnits = [];
  DialysisUnitsWithoutHome = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  EventCode = '[AutoNumber]';
  selectedWPEquipment;
  selectedDMEquipment;
  selectedCDEquipment;

  // selectedStation;
  // TestHours = 0;
  //selectedPopulatetype='Dialysis Machines';
  errorAddTest = '';
  selectedUsertext;
  CollectionByName = this.auth.GetUserFirstName();
  CreatedByName = this.auth.GetUserFLName();
  userDesignation = this.auth.GetDesignationName();
  CollectionById = this.currentUserId;
  CollectionRetestByName;
  CollectionByRetestId;
  SampleReadByName;
  SampleReadById;
  SampleReadRetestByName;
  SampleReadByRetestId;
  FunnelStaffByName;
  FunnelStaffById;
  FunnelStaffByNameRetest;
  FunnelStaffByIdRetest;
  DMTestList = [];
  WPTestList = [];
  CDTestList = [];
  OTHTestList = [];

  Users = [];
  Detailtype = '';
  selectedEquipmentHours = 0;
  TestResultList = [
    { value: '', text: 'Select' },
    { value: '(+)', text: '(+)' },
    { value: '(-)', text: '(-)' },
  ];
  TestSensitivitylist = [
    { value: '', text: 'Select' },
    { text: '0.030', value: '0.030' },
    { text: '0.060', value: '0.060' },
    { text: '0.125', value: '0.125' },
    { text: '0.250', value: '0.250' },
    { text: '0.375', value: '0.375' },
    { text: '0.500', value: '0.500' },
    { text: '0.625', value: '0.625' },
    { text: '0.750', value: '0.750' },
    { text: '0.875', value: '0.875' },
    { text: '1.000', value: '1.000' },
    { text: '2.000', value: '2.000' },
    { text: '3.000', value: '3.000' },
    { text: '4.000', value: '4.000' },
    { text: '5.000', value: '5.000' }
  ];
  PyrogenForm = new UntypedFormGroup({
    PyrogenDetailsId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    CollectionDate: new UntypedFormControl('', Validators.required),
    CollectionTime: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.TimeRegex)]),
    CollectionRetestDateT: new UntypedFormControl(''),
    CollectionRetestDate: new UntypedFormControl(''),
    CollectionRetestTime: new UntypedFormControl(''),
    SensitivityLotNumber: new UntypedFormControl(''),
    SensitivityRetestLotNumber: new UntypedFormControl(''),
    ControlLotNumber: new UntypedFormControl(''),
    ControlResetLotNumber: new UntypedFormControl(''),
    TestSensitivity: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    TestSensitivityRetest: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    selectedWPEquipment: new UntypedFormControl(''),
    selectedDMEquipment: new UntypedFormControl(''),
    // selectedStation: new FormControl(''),
    selectedCDEquipment: new UntypedFormControl(''),
    OtherEquipment: new UntypedFormControl(''),
    TestHours: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    selectedPopulatetype: new UntypedFormControl(''),
    TestingLab: new UntypedFormControl('', [Validators.required]),
    ReportId: new UntypedFormControl(''),
    CollectionById: new UntypedFormControl('', [Validators.required]),
    CollectionRetestById: new UntypedFormControl(''),
    TestMethod: new UntypedFormControl('', [Validators.required])
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };
  WPEquipments = [];
  DMEquipments = [];
  CDEquipments = [];

  // Stations = [];
  Populatetypess = [
    { value: '', text: 'Select' },
    { value: 'Dialysis Machines', text: 'Dialysis Machines' },
    { value: 'Water Process', text: 'Water Process' },
    // { value: 'Central Delivery', text: 'Central Delivery' },
    { value: 'Other', text: 'Other' },
  ];
  TestingLabs = [
    { value: '', text: 'Select' },
    { value: 'Internal Lab', text: 'Internal Lab' },
    { value: 'Internal (Tech)', text: 'Internal (Tech)' },
    { value: 'External Lab', text: 'External Lab' },
    { value: 'See Notes', text: 'See Notes' },
  ];

  TestMethodLabel = '';
  TestMethodName = '';
  TestMethods = [
    { value: '', text: 'Select' },
    { value: 'PTS', text: 'Endosafe nexgen-PTS' },
    { value: 'LAL', text: 'Gel-Clot LAL' },
  ];

  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.PyrogenForm.dirty ||

        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }
  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private homeApi: HomeService,
    private contactapi: ContactApiService,
    @Optional() public dialogPyrogenRef: MatDialogRef<PyrogenComponent>,
    private commonapi: CommonApiService
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.DMTestList = [];
    this.WPTestList = [];
    this.CDTestList = [];
    this.OTHTestList = [];

    this.fillDialysisUnit();
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Dialysis Machines');
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Water Process');
    // this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Central Delivery');
    // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    this.fillUser();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.setDateOptions();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.
        if (this.FullScreen)
          this.f.PyrogenDetailsId.setValue(params['id'] || '0');

        const tempDate = this.today;
        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        if (this.f.PyrogenDetailsId.value && this.f.PyrogenDetailsId.value !== '0') {
          this.setModel();
        } else {
          this.auth.GetCurrentUserDivision().subscribe(division => {
            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Dialysis Machines');
              this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Water Process');
              // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
            }

            this.selectedUsertext = this.auth.GetUserFirstName();
            // this.fillEquipment(0);
            //this.f.selectedPopulatetype.setValue('Dialysis Machines');
            this.f.CollectionDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.CollectionTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
            // this.f.SampleReadDate.setValue({
            //   date: {
            //     year: tempDate.getFullYear(),
            //     month: tempDate.getMonth() + 1,
            //     day: tempDate.getDate()
            //   },
            //   jsdate: tempDate
            // });
            //this.f.SampleReadTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()) );
            // this.f.CollectionById.setValue(this.currentUserId);
            if (this.FullScreen) {
              var temp = this.Users.filter(c => c.value == this.currentUserId);
              if (temp.length > 0)
                this.f.CollectionById.setValue(this.currentUserId);
              else
                this.f.CollectionById.setValue("");
            }
            else {
              this.f.CollectionById.setValue(this.currentUserId);
            }
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        }
      });

      this.SetRights();
      setTimeout(() => {
        this.SetModelCompleted = true;
      }, 1500);
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  ngAfterViewInit() {
    // attachment changes
    if (this.attachment) {
      this.attachment.DocId = this.f.PyrogenDetailsId.value;
      this.attachment.moduleName =
        attachmentFormModules[attachmentFormModules.GeneralPyrogen];
      this.attachment.UploadBy = this.currentUserId;
    }

    if (this.notes)
      this.notes.showHours = true;
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.PyrogenForm.controls;
  }

  // fillUser() {
  //   this.dmapi.GetUsersDD().subscribe(list => {
  //     this.Users = list;

  //   });
  // }

  fillUser() {

    let designationName = { str: 'Technologist' };
    this.contactapi.GetContactDetailsByDesignationName(designationName
    ).subscribe(list => {
      this.Users = list;
    });
  }

  AddTest() {
    this.errorAddTest = '';
    switch (this.f.selectedPopulatetype.value) {
      case 'Dialysis Machines':
        if (this.f.selectedDMEquipment.value == '' && this.f.TestHours.value == '' && this.hrsTracking) {
          this.errorAddTest = 'Please enter equipment and hours';
        } else if (this.f.selectedDMEquipment.value == '') {
          this.errorAddTest = 'Please select the equipment from the list.';
        } else if ((this.f.TestHours.value == '' || this.f.TestHours.value == '0') && this.hrsTracking) {
          this.errorAddTest = 'Please enter the hours.';
        } else {
          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {
            if (this.hrsTracking) {
              AppComponent.loading = true;
              var dmrepairCreate = { equipmentId: this.f.selectedDMEquipment.value, hoursin: this.f.TestHours.value, dmrepairdetailsid: 0 };
              // console.log(dmrepairCreate);

              this.dmapi.GetHoursOutOfPreviousRepair(dmrepairCreate).subscribe(
                success => {
                  // console.log(success);
                  AppComponent.loading = false;

                  if (success == false) {
                    this.errorAddTest = 'Hrs IN => previous Hrs Out';

                  } else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.DMEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedDMEquipment.value
                    );
                    this.DMTestList.push({
                      PyrogenMachaineId: 0,
                      PyrogenId: 0,
                      equipmentId: this.f.selectedDMEquipment.value,
                      sampleResult: '',
                      sampleResultReset: '',
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.DMEquipments.findIndex(order => order.value
                      === this.f.selectedDMEquipment.value);
                    this.DMEquipments.splice(index, 1);
                    this.f.selectedDMEquipment.setValue('');
                    this.f.TestHours.setValue('');
                  }
                }

              );
              //  this.errorAddTest = 'Added';
            } else {

              this.selectedEquipmentHours = 0;

              const equi = this.DMEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedDMEquipment.value
              );
              this.DMTestList.push({
                PyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: this.f.selectedDMEquipment.value,
                sampleResult: '',
                sampleResultReset: '',
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.DMEquipments.findIndex(order => order.value
                === this.f.selectedDMEquipment.value);
              this.DMEquipments.splice(index, 1);
              this.f.selectedDMEquipment.setValue('');
              this.f.TestHours.setValue('');
            }
          }

          // this.hrsTracking = false;
        }

        break;
      case 'Water Process':
        if (
          this.f.selectedWPEquipment.value == "" &&
          this.f.TestHours.value == "" && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter equipment and hours";
        } else if (this.f.selectedWPEquipment.value == "") {
          this.errorAddTest = "Please select the equipment from the list.";
        } else if ((
          this.f.TestHours.value == "" ||
          this.f.TestHours.value == "0") && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter the hours.";
        } else {

          if (this.DMRepairStatus || this.RepairStatus) {
          }
          else {
            if (this.hrsTracking) {
              AppComponent.loading = true;
              var dmrepairCreate = {
                equipmentId: this.f.selectedWPEquipment.value,
                hoursin: this.f.TestHours.value,
                dmrepairdetailsid: 0
              };
              // console.log(dmrepairCreate);

              this.dmapi
                .GetHoursOutOfPreviousRepair(dmrepairCreate)
                .subscribe(success => {
                  // console.log(success);
                  AppComponent.loading = false;
                  if (success == false) {
                    this.errorAddTest =
                      "Hrs IN => previous Hrs Out";
                  } else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.WPEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedWPEquipment.value
                    );
                    this.WPTestList.push({
                      PyrogenMachaineId: 0,
                      PyrogenId: 0,
                      equipmentId: this.f.selectedWPEquipment.value,
                      sampleResult: '',
                      sampleResultReset: '',
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.WPEquipments.findIndex(order => order.value
                      === this.f.selectedWPEquipment.value);
                    this.WPEquipments.splice(index, 1);
                    this.f.selectedWPEquipment.setValue('');
                    this.f.TestHours.setValue("");
                  }
                });
              // this.errorAddTest = 'Added';
            } else {

              this.selectedEquipmentHours = 0;

              const equi = this.WPEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedWPEquipment.value
              );
              this.WPTestList.push({
                PyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: this.f.selectedWPEquipment.value,
                sampleResult: '',
                sampleResultReset: '',
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.WPEquipments.findIndex(order => order.value
                === this.f.selectedWPEquipment.value);
              this.WPEquipments.splice(index, 1);
              this.f.selectedWPEquipment.setValue('');
              this.f.TestHours.setValue("");
            }
          }
          // this.hrsTracking = false;
        }
        break;
      case 'Central Delivery':
        if (
          this.f.selectedCDEquipment.value == "" &&
          this.f.TestHours.value == "" && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter equipment and hours";
        } else if (this.f.selectedCDEquipment.value == "") {
          this.errorAddTest = "Please select the equipment from the list.";
        } else if ((
          this.f.TestHours.value == "" ||
          this.f.TestHours.value == "0") && this.hrsTracking
        ) {
          this.errorAddTest = "Please enter the hours.";
        } else {

          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {
            if (this.hrsTracking) {
              AppComponent.loading = true;
              var dmrepairCreate = {
                equipmentId: this.f.selectedCDEquipment.value,
                hoursin: this.f.TestHours.value,
                dmrepairdetailsid: 0
              };
              // console.log(dmrepairCreate);

              this.dmapi
                .GetHoursOutOfPreviousRepair(dmrepairCreate)
                .subscribe(success => {
                  // console.log(success);
                  AppComponent.loading = false;
                  if (success == false) {
                    this.errorAddTest =
                      "Hrs IN => previous Hrs Out";
                  } else {
                    this.selectedEquipmentHours = 0;

                    const equi = this.CDEquipments.filter(
                      // tslint:disable-next-line:triple-equals
                      x => x.value == this.f.selectedCDEquipment.value
                    );
                    this.CDTestList.push({
                      PyrogenMachaineId: 0,
                      PyrogenId: 0,
                      equipmentId: this.f.selectedCDEquipment.value,
                      sampleResult: '',
                      sampleResultReset: '',
                      dialysisMachineHours: this.f.TestHours.value,
                      equipmentName: equi[0].text
                    });
                    const index = this.CDEquipments.findIndex(order => order.value
                      === this.f.selectedCDEquipment.value);
                    this.CDEquipments.splice(index, 1);
                    this.f.selectedCDEquipment.setValue('');
                    this.f.TestHours.setValue("");
                  }
                });
              // this.errorAddTest = 'Added';
            } else {

              this.selectedEquipmentHours = 0;

              const equi = this.CDEquipments.filter(
                // tslint:disable-next-line:triple-equals
                x => x.value == this.f.selectedCDEquipment.value
              );
              this.CDTestList.push({
                PyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: this.f.selectedCDEquipment.value,
                sampleResult: '',
                sampleResultReset: '',
                dialysisMachineHours: '',
                equipmentName: equi[0].text
              });
              const index = this.CDEquipments.findIndex(order => order.value
                === this.f.selectedCDEquipment.value);
              this.CDEquipments.splice(index, 1);
              this.f.selectedCDEquipment.setValue('');
              this.f.TestHours.setValue("");
            }
          }
          // this.hrsTracking = false;
        }
        break;
      case 'Other':
        if (
          this.f.OtherEquipment.value == '') {
          this.errorAddTest = "Please enter equipment name";
        } else {
          if (this.DMRepairStatus || this.RepairStatus) {

          }
          else {
            this.OTHTestList.push({
              PyrogenMachaineId: 0,
              PyrogenId: 0,
              equipmentId: null,
              sampleResult: '',
              sampleResultReset: '',
              dialysisMachineHours: '',
              equipmentName: this.f.OtherEquipment.value
            });

            this.f.OtherEquipment.setValue('');
          }
        }
        break;
      // if (this.f.selectedStation.value == '') {
      //   this.errorAddTest = 'Please select station from the list.';
      // } else {
      //   const station = this.Stations.filter(
      //     // tslint:disable-next-line:triple-equals
      //     x => x.value == this.f.selectedStation.value
      //   );
      //   this.CDTestList.push({
      //     PyrogenDialysisStationsId: 0,
      //     PyrogenId: 0,
      //     dialysisStationId: this.f.selectedStation.value,
      //     sampleResult: '',
      //     sampleResultReset: '',
      //     stationname: station[0].text
      //   });
      //   const index = this.Stations.findIndex(order => order.value
      //     === this.f.selectedStation.value);
      //   this.Stations.splice(index, 1);
      //   this.f.selectedStation.setValue('');
      //   //   this.errorAddTest = 'Added';
      // }
      // break;
      default:
        this.errorAddTest = 'Please enter the populate.';
        break;
    }
  }

  validateMachineRepair() {
    if (this.f.selectedPopulatetype.value == "Dialysis Machines") {
    var dmrepairValidate = {
      equipmentId: this.f.selectedDMEquipment.value,
      dmrepairdetailsid: 0,
      isDMRepair: false
    };

    this.dmapi.GetRepairStatusByEquipmentId(dmrepairValidate).subscribe(res => {

      if (res == true) {
        this.DMRepairStatus = false;
      }
      else { this.DMRepairStatus = true; };
      this.RepairStatus = false;
    });
  }
  else{
    var dmrepairValidate = {
      equipmentId: this.f.selectedWPEquipment.value,
      dmrepairdetailsid: 0,
      isDMRepair: false
    };

    if (this.f.selectedPopulatetype.value == "Central Delivery")
      dmrepairValidate.equipmentId = this.f.selectedCDEquipment.value;
    else if (this.f.selectedPopulatetype.value == "Other")
      dmrepairValidate.equipmentId = this.f.OtherEquipment.value;
    else
      dmrepairValidate.equipmentId = this.f.selectedWPEquipment.value;

    this.dmapi.GetWpOtherRepairStatusByEquipmentId(dmrepairValidate).subscribe(res => {

      if (res == true) {
        this.RepairStatus = false;
      }
      else { this.RepairStatus = true; };
      //return {false res };

      this.DMRepairStatus = false;
    });

  }
}

  selectedPopulatetypeChanged() {
    this.f.TestHours.setValue('');
    this.f.selectedDMEquipment.setValue('');
  }

  onWPEquipmentChanged() {
    var equipmentId = { Id: this.f.selectedWPEquipment.value };
    this.dmapi.GetEquipmentDetailsByID(equipmentId).subscribe(success => {
      this.hrsTracking = success.hrsTracking;
    });

    this.f.TestHours.setValue('');

    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });
  }

  HoursCheckOnQuickSave() {
    this.errorAddTest = '';
    if (this.DMTestList.length > 0) {
      if (!this.DMTestList[0].dialysisMachineHours ||
        this.DMTestList[0].dialysisMachineHours == '0') {
        this.errorAddTest = 'Please enter equipment hours';
        return false;
      } else {

        if (this.selectedDMHours != 0 && parseFloat(this.DMTestList[0].dialysisMachineHours) < parseFloat(this.selectedDMHours.toString())) {
          this.errorAddTest = 'Hrs => previous Hrs Out';
          return false;
        }
      }

    }
    else if (this.WPTestList.length > 0) {
      if (!this.WPTestList[0].dialysisMachineHours ||
        this.WPTestList[0].dialysisMachineHours == '0') {
        this.errorAddTest = 'Please enter equipment hours';
        return false;
      } else {

        if (this.selectedROHours != 0 && parseFloat(this.WPTestList[0].dialysisMachineHours) < parseFloat(this.selectedROHours.toString())) {
          this.errorAddTest = 'Hrs => previous Hrs Out';
          return false;
        }
      }
    }
    return true;
  }

  setModel() {


    AppComponent.ShowLoader();
    const PyrogenCreate = { Id: this.f.PyrogenDetailsId.value };

    if (this.attachment)
      this.attachment.clearQueue();

    if (this.notes)
      this.notes.Note = '';

    this.dmapi.GetPyrogenDetailsByID(PyrogenCreate).subscribe(
      success => {
        // console.log(success);

        this.EventCode = success.eventId;

        this.stage = success.stage;
        if (success.status != 'Failure' && success.status != 'Completed') {
          this.stage = this.stage + 1;
        }
        this.status = success.status;

        if (this.status == 'Completed') {
          this.f.CollectionRetestTime.clearValidators();
          this.f.CollectionRetestTime.updateValueAndValidity();
        }
        let tempDate = new Date(success.createdOn);

        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdOn
        });
        if (success.collectionDate != null || success.collectionDate != '') {
          tempDate = new Date(success.collectionDate);
          this.f.CollectionDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.CollectionTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
        }

        if (!this.FullScreen) {
          this.f.CollectionDate.disable();
          this.f.CollectionTime.disable();
        }



        // this.f.CollectionRetestTime.updateValueAndValidity();
        if (success.callectionRetestDate) {
          tempDate = new Date(success.callectionRetestDate);
          if (tempDate.getFullYear() > 2000) {
            this.f.CollectionRetestDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.CollectionRetestTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
          }

          if (tempDate.getFullYear() > 2000) {
            this.f.CollectionRetestDateT.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: tempDate
            });
            this.f.CollectionRetestTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
          }
        } else {
          // const tempDate = this.today;
          // this.f.CollectionRetestDate.setValue({
          //   date: {
          //     year: tempDate.getFullYear(),
          //     month: tempDate.getMonth() + 1,
          //     day: tempDate.getDate()
          //   },
          //   jsdate: tempDate
          // });
          // this.f.CollectionRetestTime.setValue(padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
        }
        this.CollectionByRetestId = success.collectionRetestEnteredBy;
        this.CollectionRetestByName = success.collectionRetestEnteredByName;
        this.CollectionByName = success.collectionTechEnteredByName;
        this.CreatedByName = success.createdByName;
        this.f.CollectionById.setValue(success.collectionTechEnteredBy);
        // if(success.sampleRetestReadDate != null || success.sampleRetestReadDate !='')
        //   {
        //   tempDate = new Date(success.sampleRetestReadDate);
        //   if(tempDate.getFullYear() > 2000)
        //   {
        //   this.f.SampleReadRetestDate.setValue({
        //     date: {
        //       year: tempDate.getFullYear(),
        //       month: tempDate.getMonth() + 1,
        //       day: tempDate.getDate()
        //     },
        //     jsdate: tempDate
        //   });
        //   this.f.SampleReadRetestTime.setValue( padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
        // }
        // }
        this.SampleReadRetestByName = success.sampleretestreadbyName;
        this.SampleReadByRetestId = success.sampleRetestReadBy;
        this.f.CollectionRetestById.setValue(success.collectionRetestEnteredBy ? success.collectionRetestEnteredBy : '');

        if (this.f.CollectionRetestById.value == '') {
          if (this.FullScreen) {
            var temp = this.Users.filter(c => c.value == this.currentUserId);
            if (temp.length > 0)
              this.f.CollectionRetestById.setValue(this.currentUserId);
            else
              this.f.CollectionRetestById.setValue("");
          }
          else {
            this.f.CollectionRetestById.setValue(this.currentUserId);
          }
        }

        this.f.TestingLab.setValue(success.testinglab);


        // if(this.f.TestingLab.value == 'Internal (Tech)'){
        //   this.f.SensitivityLotNumber.setValidators([Validators.required]);
        //   this.f.SensitivityLotNumber.updateValueAndValidity();
        //   this.f.ControlLotNumber.setValidators([Validators.required]);
        //   this.f.ControlLotNumber.updateValueAndValidity();
        // } else {
        //   this.f.SensitivityLotNumber.clearValidators();
        //   this.f.SensitivityLotNumber.updateValueAndValidity();
        //   this.f.ControlLotNumber.clearValidators();
        //   this.f.ControlLotNumber.updateValueAndValidity();
        // }

        this.f.SensitivityLotNumber.setValue(success.sensitivityLotNumber);
        this.f.ControlLotNumber.setValue(success.controlLotNumber);

        this.f.TestMethod.setValue(success.testMethod);

        var testMethodSelected = this.TestMethods.filter(
          x => x.value === success.testMethod.toString()
        );

        if (testMethodSelected.length > 0) {
          this.TestMethodLabel = testMethodSelected[0].text;
          this.TestMethodName = success.testMethod;
        }

        // var tempForReadUser = this.Users.filter(c=>c.value == this.CollectionByRetestId);
        // if(tempForReadUser.length > 0)
        //   this.f.CollectionRetestById.setValue(success.collectionRetestEnteredBy);
        // else
        // this.f.CollectionRetestById.setValue("");
        // if(success.sampleReadDate != null || success.sampleReadDate !='')
        //   {
        //   tempDate = new Date(success.sampleReadDate);
        //   // console.log(tempDate);
        //   if(tempDate.getFullYear() > 2000)
        //   {
        //   this.f.SampleReadDate.setValue({
        //     date: {
        //       year: tempDate.getFullYear(),
        //       month: tempDate.getMonth() + 1,
        //       day: tempDate.getDate()
        //     },
        //     jsdate: tempDate
        //   });

        //   this.f.SampleReadTime.setValue(padNumber( tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
        // }
        // }
        // this.SampleReadById = success.sampleReadBy;
        // this.f.SampleReadById.setValue(success.sampleReadBy);
        // this.SampleReadByName = success.samplereadbyName;

        //this.EventCode = success.eventcode;
        this.selectedDialysisUnitstext = success.divisionName;

        this.Detailtype = success.detailsType;
        //this.selectedUsertext = success.createByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
        this.selectedDialysisUnitstext = success.divisionName;


        // console.log(this.stage);
        // this.f.FunnelLot.setValue(success.funnelLotNumber);
        // this.f.CassetteLot.setValue(success.cassetteLotNumber);
        // this.f.FunnelLotRetest.setValue(success.funnelRetestLotNumber);
        // this.f.CassetteLotRetest.setValue(success.cassetteRetestLotNumber);
        // this.FunnelStaffById = success.funnelReadby;
        // this.FunnelStaffByName = success.funnelReadbyName;
        // this.FunnelStaffByIdRetest = success.funnelReadretestby;
        // this.FunnelStaffByNameRetest = success.funnelReadretestbyName;

        if (success.testSensitivity != null && success.testSensitivity != 0) {
          this.f.TestSensitivity.setValue(success.testSensitivity);
        }

        this.f.ReportId.setValue(success.reportId);
        // this.TestResultList.push({
        //   text: '(+)',
        //   value: '(+)'
        // });
        // this.TestResultList.push({
        //   text: '(-)',
        //   value: '(-)'
        // });

        const PyrogenDMObj = { Id: this.f.PyrogenDetailsId.value, str: 'Dialysis Machines' };

        this.dmapi
          .GetMachineListByPyrogenID(PyrogenDMObj)
          .subscribe(list => {
            this.DMTestList = list;
          });

        const PyrogenWPObj = { Id: this.f.PyrogenDetailsId.value, str: 'Water Process' };

        this.dmapi
          .GetMachineListByPyrogenID(PyrogenWPObj)
          .subscribe(list => {
            this.WPTestList = list;

          });

        const PyrogenCDObj = { Id: this.f.PyrogenDetailsId.value, str: 'Central Delivery' };

        this.dmapi
          .GetMachineListByPyrogenID(PyrogenCDObj)
          .subscribe(list => {
            this.CDTestList = list;
          });

        const PyrogenOTHDObj = { Id: this.f.PyrogenDetailsId.value, str: 'Other' };

        this.dmapi
          .GetMachineListByPyrogenID(PyrogenOTHDObj)
          .subscribe(list => {
            this.OTHTestList = list;
          });

        const PyrogenObj = { Id: this.f.PyrogenDetailsId.value };

        if (this.attachment) {
          this.dmapi
            .GetAttachmentDetailsbyPyrogenid(PyrogenObj)
            .subscribe(list => {
              this.attachment.PrevAttachments = list;
            });
        }

        if (this.notes) {
          this.dmapi
            .GetNotesbyPyrogenId(PyrogenObj)
            .subscribe(list => {
              this.notes.NoteList = list;
            });
        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  SetModelCompleted = false;

  onDateChanged(event: IMyDateModel): void { }

  CheckSampleValue() {
    let result = true;
    //console.log(this.DMTestList,this.WPTestList);
    this.DMTestList.forEach(function (value) {
      if ((value.sampleResult == '' || value.sampleResult == null) ||
        ((value.postControlResult == '' || value.postControlResult == null) && value.testMethod == 'LAL')) {
        result = false;
      }
    });
    this.WPTestList.forEach(function (value) {
      if ((value.sampleResult == '' || value.sampleResult == null) ||
        ((value.postControlResult == '' || value.postControlResult == null) && value.testMethod == 'LAL')) {
        result = false;
      }
      // if (value.postControlResult == ''  || value.postControlResult == null) {
      //   result = false;
      // }
    });
    this.CDTestList.forEach(function (value) {
      if ((value.sampleResult == '' || value.sampleResult == null) ||
        ((value.postControlResult == '' || value.postControlResult == null) && value.testMethod == 'LAL')) {
        result = false;
      }
      // if (value.postControlResult == ''  || value.postControlResult == null) {
      //   result = false;
      // }
    });
    this.OTHTestList.forEach(function (value) {
      if ((value.sampleResult == '' || value.sampleResult == null) ||
        ((value.postControlResult == '' || value.postControlResult == null) && value.testMethod == 'LAL')) {
        result = false;
      }
      // if (value.postControlResult == ''  || value.postControlResult == null) {
      //   result = false;
      // }
    });
    //console.log(result);
    return result;

  }

  CheckSamleResetValue() {

    let result = true;
    this.DMTestList.forEach(function (value) {
      if (value.sampleResult == '' || value.postControlResult == '') {
        result = false;
      }
    });
    this.WPTestList.forEach(function (value) {
      if (value.sampleResult == '' || value.postControlResult == '') {
        result = false;
      }
    });
    this.CDTestList.forEach(function (value) {
      if (value.sampleResult == '' || value.postControlResult == '') {
        result = false;
      }
    });
    this.OTHTestList.forEach(function (value) {
      if (value.sampleResult == '' || value.postControlResult == '') {
        result = false;
      }
    });
    return result;
  }

  executePyrogenCreateUpdate = function (Source) {

    if (this.status !== 'Completed') {

      AppComponent.loading = true;
      this.isValidFormSubmitted = false;
      this.f.CollectionRetestDateT.updateValueAndValidity();
      this.f.CollectionRetestTime.updateValueAndValidity();
      //this.f.CollectionRetestById.updateValueAndValidity();
      this.f.TestSensitivity.updateValueAndValidity();
      this.f.ReportId.updateValueAndValidity();
      //this.f.CollectionRetestDate.updateValueAndValidity();
      //
      if (this.PyrogenForm.invalid) {
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        this.data.errormsg = Globals.FormErrorMessage;
        AppComponent.loading = false;
        this.scrollHelper.scrollToFirst('ng-invalid');
        AppComponent.ScrollDown();
        return;
      }



      if (this.DMTestList.length == 0 && this.WPTestList.length == 0 && this.CDTestList.length == 0 && this.OTHTestList.length == 0) {
        AppComponent.addAlert('Please add at least one testing device.', 'error');
        this.data.errormsg = "Please add at least one testing device.";
        AppComponent.loading = false;
        return;
      }

      if (!this.FullScreen && !this.HoursCheckOnQuickSave()) {
        AppComponent.loading = false;
        return;
      }
      //
      const sampleValid = this.CheckSampleValue();
      if ((sampleValid || Source !== 'Notes') && this.f.PyrogenDetailsId.value > 0 && this.status !== 'Completed') {
        this.setTestingLab();
        this.setTestingLabRequired();
      }
      if (sampleValid == false
        && ((this.f.ReportId.value != undefined && this.f.ReportId.value != '')
          || (this.f.TestSensitivity.value != undefined && this.f.TestSensitivity.value != ''))) {

        var alertMessage = '';
        if (this.f.TestMethod.value == 'LAL') {
          alertMessage = 'Please select all Result & Pos. Control for the each testing device.';
        } else {
          alertMessage = 'Please select all Result for the each testing device.';
        }
        AppComponent.addAlert(alertMessage, 'error');
        this.data.errormsg = alertMessage;
        AppComponent.loading = false;
        return;
      }



      if (Source !== 'Notes'
        && this.FullScreen
        && this.notes.NoteList.length < 1
        && this.CheckRed() == false) {
        AppComponent.addAlert('There are result(s) that are greater than acceptable limits and require a Note entry to complete this form.', 'error');
        AppComponent.loading = false;
        return;
      }

      if (Source !== 'Notes'
        && this.FullScreen
        && this.notes.NoteList.length < 1
        && this.f.TestingLab.value == 'See Notes') {
        AppComponent.addAlert('Require a Note entry to complete this form.', 'error');
        AppComponent.loading = false;
        return;
      }
      // console.log(sampleValid,this.f.TestingLab.value, this.f.ReportId.value);
      // if (sampleValid == true && this.f.TestingLab.value != 'Internal' &&
      //    ((this.f.ReportId.value === undefined || this.f.ReportId.value == ''))) {
      //     AppComponent.addAlert('Please enter Testing Details.', 'error');
      //     AppComponent.loading = false;
      //     return;
      //   }

      //   if (sampleValid == true && this.f.TestingLab.value === 'Internal' &&
      //   (this.f.TestSensitivity.value === undefined || this.f.TestSensitivity.value === '')) {
      //    AppComponent.addAlert('Please enter Testing Details.', 'error');
      //    AppComponent.loading = false;
      //    return;
      //  }

      if (this.PyrogenForm.invalid) {
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');
        this.data.errormsg = Globals.FormErrorMessage;
        AppComponent.loading = false;
        this.scrollHelper.scrollToFirst('ng-invalid');
        AppComponent.ScrollDown();
        return;
      }
      this.isValidFormSubmitted = true;
    }
    const PyrogenCreate = this.prepareCreateViewModel();
    // if (PyrogenCreate.PyrogenId === '0') {
    AppComponent.HideLoader();
    this.dmapi.GRCreateUpdatePyrogen(PyrogenCreate).subscribe(
      success => {
        //  console.log(success);
        // this.DMRepailDetail = success.dmRepailDetails;

        AppComponent.loading = false;
        AppComponent.addAlert('Data Saved successfully!', 'success');
        if (Source !== 'Notes' && this.FullScreen) {
          this.router.navigate([this.ListURL]);
        } else {

          this.SaveEvent.emit(success);

          if (this.dialogPyrogenRef)
            this.dialogPyrogenRef.close(true);

          this.f.PyrogenDetailsId.setValue(
            success.pyrogendetailsid
          );
          this.setModel();
        }
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
    // } else {
    //   this.dmapi.CreateUpdatePyrogen(PyrogenCreate).subscribe(
    //     success => {
    //      // this.DMRepailDetail = success.dmRepailDetails;
    //       AppComponent.loading = false;
    //       AppComponent.addAlert('Data Saved successfully!', 'success');
    //       if (Source !== 'Notes') {
    //         this.router.navigate([this.ListURL]);
    //       } else {

    //         this.setModel();
    //       }
    //     },
    //     error => {
    //       AppComponent.addAlert(error.message, 'error');
    //       AppComponent.HideLoader();
    //     }
    //   );
    // }
  };

  SetValidatorForNotes() {

    if (this.SetModelCompleted) {
      const sampleValid = this.CheckSampleValue();

      // if(!sampleValid) {
      //   this.PyrogenForm.setErrors({ 'invalid': true });
      // }

      if ((sampleValid) && this.f.PyrogenDetailsId.value > 0 && this.status !== 'Completed') {
        this.setTestingLab();
        this.setTestingLabRequired();
      }

      if (sampleValid === false
        && ((this.f.ReportId.value !== undefined && this.f.ReportId.value !== '')
          || (this.f.TestSensitivity.value !== undefined && this.f.TestSensitivity.value !== ''))) {
        this.PyrogenForm.setErrors({ 'invalid': true });
        return;
        // this.PyrogenForm.
        //  var alertMessage = '';
        // if(this.f.TestMethod.value == 'LAL') {
        //   alertMessage = 'Please select all Result & Pos. Control for the each testing device.';
        // } else {
        //   alertMessage = 'Please select all Result for the each testing device.';
        // }
        // AppComponent.addAlert(alertMessage, 'error');
        // this.data.errormsg = alertMessage;
        // AppComponent.loading = false;
        // return;
      }
    }
  }

  prepareCreateViewModel() {
    const PyrogenCreate = <any>{};

    PyrogenCreate.pyrogendetailsid = this.f.PyrogenDetailsId.value;
    PyrogenCreate.detailstype = this.Detailtype;
    PyrogenCreate.createdon = Globals.GetAPIDate(this.today);
    PyrogenCreate.createdby = this.currentUserId;
    PyrogenCreate.currentUser = this.currentUserId;
    PyrogenCreate.modifiedon = Globals.GetAPIDate(this.today);
    PyrogenCreate.modifiedby = this.currentUserId;
    PyrogenCreate.testinglab = this.f.TestingLab.value;
    PyrogenCreate.reportid = this.f.ReportId.value;
    PyrogenCreate.stage = this.stage;
    PyrogenCreate.collectiontechenteredby = this.f.CollectionById.value;
    PyrogenCreate.divisionid = this.f.selectedDialysisUnits.value;
    PyrogenCreate.eventid = this.EventCode;
    // PyrogenCreate.sensitivitylotnumber = '';
    // PyrogenCreate.sensitivityretestlotnumber = '';
    // PyrogenCreate.controllotnumber = '';
    // PyrogenCreate.controlretestlotnumber = '';
    PyrogenCreate.collectionRetestEnteredBy = this.f.CollectionRetestById.value;


    if (this.f.CollectionDate.value !== '' && this.f.CollectionDate.value !== null && this.f.CollectionDate.value !== undefined) {
      let dt = this.f.CollectionDate.value.jsdate;
      // console.log(dt);
      if (this.f.CollectionTime.value != '') {
        const splitted = this.f.CollectionTime.value.split(':');
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      PyrogenCreate.collectiondate = Globals.GetAPIDate(dt);
      PyrogenCreate.Pyrogen_Step = 'Collection';
    }
    //if (PyrogenCreate.testinglab === 'Internal (Tech)' || PyrogenCreate.testinglab == 'Internal Lab' || PyrogenCreate.testinglab == 'External Lab' || PyrogenCreate.testinglab == 'See Notes') {


    if (this.f.CollectionRetestDateT.value !== '' &&this.f.CollectionRetestDateT.value !== null
    && this.f.CollectionRetestDateT.value !== undefined) {
      let dt = this.f.CollectionRetestDateT.value.jsdate;
      if (this.f.CollectionRetestTime.value != '') {
        // const splitted = this.f.CollectionRetestTime.value.split(':');

        if (this.status !== 'Completed') {
          dt.setHours(this.f.CollectionRetestTime.value.getHours());
          dt.setMinutes(this.f.CollectionRetestTime.value.getMinutes());
        } else {
          const splitted = this.f.CollectionRetestTime.value.split(':');
          dt.setHours(splitted[0]);
          dt.setMinutes(splitted[1]);
        }
      }
      PyrogenCreate.collectionretestdate = Globals.GetAPIDate(dt);
      PyrogenCreate.Pyrogen_Step = 'Read';
    }
    PyrogenCreate.testsensitivity = this.f.TestSensitivity.value;
    PyrogenCreate.collectionretestenteredby = this.f.CollectionRetestById.value;


    if (this.status == 'Completed') {
      PyrogenCreate.Pyrogen_Step = 'Notes';
    }

    // } else {
    //   if (this.f.CollectionRetestDate.value !== null && this.f.CollectionRetestDate.value !== undefined) {
    //     let dt = this.f.CollectionRetestDate.value.jsdate;
    //     PyrogenCreate.collectionretestdate = Globals.GetAPIDate(dt);
    //     PyrogenCreate.reportid = this.f.ReportId.value;
    //   }
    // }
    // PyrogenCreate.testsensitivityretest = this.f.FunnelLotRetest.value;
    PyrogenCreate.machaineList = [];
    this.DMTestList.forEach(function (value) {
      PyrogenCreate.machaineList.push(
        {
          PyrogenMachaineId: value.pyrogenMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          postControlResult: value.postControlResult,
          postControlResultReset: value.postControlResultReset,
          Type: 'Dialysis Machines',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });
    this.WPTestList.forEach(function (value) {
      PyrogenCreate.machaineList.push(
        {
          PyrogenMachaineId: value.pyrogenMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          postControlResult: value.postControlResult,
          postControlResultReset: value.postControlResultReset,
          Type: 'Water Process',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });

    this.CDTestList.forEach(function (value) {
      PyrogenCreate.machaineList.push(
        {
          PyrogenMachaineId: value.pyrogenMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          postControlResult: value.postControlResult,
          postControlResultReset: value.postControlResultReset,
          Type: 'Central Delivery',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });

    this.OTHTestList.forEach(function (value) {
      PyrogenCreate.machaineList.push(
        {
          PyrogenMachaineId: value.pyrogenMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          postControlResult: value.postControlResult,
          postControlResultReset: value.postControlResultReset,
          Type: 'Other',
          dialysisMachineHours: value.dialysisMachineHours,
          equipmentName: value.equipmentName
        }
      );
    });

    PyrogenCreate.stationsList = [];
    // PyrogenCreate.stationsList.push(
    //   {
    //     PyrogenDialysisStationsId: value.pyrogenDialysisStationsId,
    //     dialysisStationId: value.dialysisStationId,
    //     sampleResult: value.sampleResult,
    //     sampleResultReset: value.sampleResultReset,
    //     postControlResult: value.postControlResult,
    //     postControlResultReset: value.postControlResultReset,
    //     Type: 'Central Delivery'
    //   }
    // );

    if (this.notes)
      PyrogenCreate.notes = this.notes.Note;

    // attachments
    if (this.attachment)
      PyrogenCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
    //console.log(PyrogenCreate);

    PyrogenCreate.sensitivitylotnumber = this.f.SensitivityLotNumber.value;
    PyrogenCreate.controllotnumber = this.f.ControlLotNumber.value;

    //
    // var testMethodSelected = this.TestMethods.filter(
    //   x => x.value === this.f.TestMethod.value
    // );

    // if (testMethodSelected.length > 0) {
    //   this.f.TestMethod.setValue(testMethodSelected[0].value);
    // }

    PyrogenCreate.TestMethod = this.f.TestMethod.value;

    return PyrogenCreate;
  }

  DialysisUnitDropDownChanged() {
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Dialysis Machines');
    this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Water Process');
    // this.fillInventoryList(this.f.selectedDialysisUnits.value, 'Central Delivery');

    // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    if (this.f.PyrogenDetailsId.value) {
      this.f.selectedWPEquipment.setValue('');
      this.f.selectedDMEquipment.setValue('');
      this.selectedEquipmentHours = 0;
      this.f.TestHours.setValue('');
    }
  }

  // fillDialysisStation(divisionID) {
  //   if (divisionID != 0) {
  //     this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
  //       this.Stations = list;
  //     });
  //   } else {
  //     this.selectedStation = [{ value: '', text: 'Select' }];
  //     //this.f.selectedDialysisStation.setValue('');
  //   }
  // }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
    this.commonapi.FillDivision().subscribe(list => {
      // if (list.filter(x => x.text == 'Home Program').length != 0) {
      //   list.filter(x => x.text == 'Home Program')[0].text = 'Home Pool';
      // }
      this.DialysisUnitsWithoutHome = list;

      // this.DialysisUnitsWithoutHome = list.filter(
      //   x => x.text != "Home Program"
      // );

    });
  }

  setDialysisUnitAndMachines(name, stationId, type, hours = "", HFSPurpose = "", EquipmentId = "", EquipmentName = "") {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
    this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;
      var selectedDialysisUnitsobjTemp = this.DialysisUnits.filter(
        x => x.text === name
      );
      if (selectedDialysisUnitsobjTemp && selectedDialysisUnitsobjTemp.length > 0) {
        this.selectedDialysisUnitstext = selectedDialysisUnitsobjTemp[0].text;
        this.f.selectedDialysisUnits.setValue(selectedDialysisUnitsobjTemp[0].value);
        this.DialysisUnitDropDownChanged();
        this.f.selectedDialysisUnits.disable();

        if (!this.f.PyrogenDetailsId.value || this.f.PyrogenDetailsId.value == '0') {
          this.f.CollectionDate.setValue({
            date: {
              year: this.today.getFullYear(),
              month: this.today.getMonth() + 1,
              day: this.today.getDate()
            },
            jsdate: this.today
          });
          this.f.CollectionDate.disable();

          this.f.CollectionTime.setValue(this.addZeroBeforeHours(this.today.getHours().toString()) + ":" + this.addZeroBeforeHours(this.today.getMinutes().toString()));
          this.f.CollectionTime.disable();
        }
        else {
          this.f.TestMethod.disable();
        }
        //this.f.TestingLab.disable();
        this.f.CollectionRetestDate.disable();
        this.f.ReportId.disable();

        this.f.CollectionRetestDateT.disable();

        this.f.CollectionRetestTime.disable();

        this.f.TestSensitivity.disable();
        this.f.CollectionRetestById.disable();

        if (HFSPurpose == "Maintenance & Testing")
          this.fetchMachines(stationId, type, hours);
        else if (HFSPurpose == "Renovation & Full Installation")
          this.fetchInstallationMachines(EquipmentId, EquipmentName, type, hours);
      }
    });
  }

  addZeroBeforeHours(n) {
    return (n < 10 ? '0' : '') + n;
  }

  fetchInstallationMachines(EquipmentId, EquipmentName, type, hours) {
    if (type == 'RO') {
      this.WPTestList.push({
        bacteriaMachaineId: 0,
        bacteriaId: 0,
        equipmentId: EquipmentId,
        sampleResult: "",
        sampleResultReset: "",
        dialysisMachineHours: hours,
        equipmentName: EquipmentName,
        hours: hours
      });

      this.GetSelectedRODMHours(type, EquipmentId);
    } else if (type = 'DM') {
      this.DMTestList.push({
        bacteriaMachaineId: 0,
        bacteriaId: 0,
        equipmentId: EquipmentId,
        sampleResult: "",
        sampleResultReset: "",
        dialysisMachineHours: hours,
        equipmentName: EquipmentName,
        hours: hours
      });

      this.GetSelectedRODMHours(type, EquipmentId);
    }

    // this.TestResultList.push({
    //   text: "< 10",
    //   value: "< 10"
    // });

    // while (this.TestResultList.length < 12) {
    //   this.TestResultList.push({
    //     text: (this.TestResultList.length + 8).toString(),
    //     value: (this.TestResultList.length + 8).toString()
    //   });
    // }
    // this.TestResultList.push({
    //   text: "> 20",
    //   value: "> 20"
    // });

  }

  fetchMachines(stationId, type = "", hours) {


    this.homeApi.GetDialysisStationByID({ Id: stationId }).subscribe(
      success => {
        if (success) {
          if (success.dmEquipmentId && (type == "" || type == "DM")) {

            if (this.DMTestList.filter(x => x.equipmentId == success.dmEquipmentId).length <= 0) {
              this.DMTestList.push({
                pyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: success.dmEquipmentId,
                sampleResult: "",
                sampleResultReset: "",
                dialysisMachineHours: hours,
                equipmentName: success.dmEquipmentName,
                hours: hours
              });

              this.GetSelectedRODMHours(type, success.dmEquipmentId);
            }


          }
          if (success.roEquipmentId && (type == "" || type == "RO")) {

            if (this.WPTestList.filter(x => x.equipmentId == success.roEquipmentId).length <= 0) {
              this.WPTestList.push({
                pyrogenMachaineId: 0,
                PyrogenId: 0,
                equipmentId: success.roEquipmentId,
                sampleResult: "",
                sampleResultReset: "",
                equipmentName: success.roEquipmentName,
                dialysisMachineHours: hours,
                hours: hours
              });
              this.GetSelectedRODMHours(type, success.roEquipmentId);
            }

            // this.TestResultList.push({
            //   text: "< 10",
            //   value: "< 10"
            // });

            // while (this.TestResultList.length < 12) {
            //   this.TestResultList.push({
            //     text: (this.TestResultList.length + 8).toString(),
            //     value: (this.TestResultList.length + 8).toString()
            //   });
            // }
            // this.TestResultList.push({
            //   text: "> 20",
            //   value: "> 20"
            // });

          }

        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  UpdateDialysisMachineHoursValue(event) {
    const tempname = event.target.name;
    const name = tempname.split("_");
    if (name[1] == "dm") {
      const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );

      this.DMTestList[index].dialysisMachineHours = event.target.value;

    } else if (name[1] == "wp") {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );

      this.WPTestList[index].dialysisMachineHours = event.target.value;

    }
    this.HoursCheckOnQuickSave();
  }

  GetSelectedRODMHours(type, eqID) {
    var EquipmentId = { Id: eqID };
    this.dmapi.GetmachaineHistoryMaxHours(EquipmentId).subscribe(result => {
      if (result) {
        if (type == "DM")
          this.selectedDMHours = result;
        else
          this.selectedROHours = result;
      } else {
        this.selectedROHours = 0;
        this.selectedDMHours = 0;
      }
    });
  }

  fillInventoryList(DivisionId, Category) {
    const value = {
      DivisionId: DivisionId,
      CategoryName: Category,
      Type: 'Equipment'
    };
    if (DivisionId != 0) {
      var selectedDialysisUnitsobjTemp = this.DialysisUnitsWithoutHome.filter(
        x => x.value === DivisionId
      );
      if (selectedDialysisUnitsobjTemp.length > 0 && selectedDialysisUnitsobjTemp[0].text == 'Home Program') {
        if (Category == "Dialysis Machines") {
          const obj = {
            DialysisStationId: DivisionId,
            CategoryName: 'Dialysis Machines'
          };
          this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
            list.unshift({ text: 'Select', value: '' });
            this.DMEquipments = list;
          });
        }
        if (Category == "Water Process") {
          const obj = {
            DialysisStationId: DivisionId,
            CategoryName: 'Water Process'
          };
          this.homeApi.FillHomeStationEquipmentsByCategory(obj).subscribe(list => {
            list.unshift({ text: 'Select', value: '' });
            this.WPEquipments = list;
          });
        }
      }
      else {
        this.dmapi.GetEquipmentByCategoryDD(value).subscribe(
          list => {
            if (Category == 'Water Process') {
              this.WPEquipments = list;
            } else if (Category == 'Central Delivery') {
              this.CDEquipments = list;
            } else {
              this.DMEquipments = list;
            }
          }
        );
      }
    }
    else {
      this.WPEquipments = [{ value: '', text: 'Select' }];;
      this.DMEquipments = [{ value: '', text: 'Select' }];;
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const PyrogenObj = { Id: this.f.PyrogenDetailsId.value };
        this.dmapi.DeletePyrogenByID(PyrogenObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Pyrogen detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetRights() {
    this.auth.GetPageRights('DM Pyrogen').subscribe(list => {
      if (this.f.PyrogenDetailsId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        // this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        // this.blnshowDelete = false;
      }
    });

    if (this.userDesignation == 'Administrator' && this.f.PyrogenDetailsId.value != '0') {
      this.blnshowDelete = true;
    }
  }
  UpdatePosControlSampleValue(event) {
    //  console.log(event,event.target.name,event.target.value);
    const tempname = event.target.name;
    const name = tempname.split('_');
    if (name[1] == 'dm') {
      const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');
      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        this.DMTestList[index].postControlResult = event.target.value;
      }
      else {
        this.DMTestList[index].postControlResult = event.target.value;
      }

    } else if (name[1] == 'wp') {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');
      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        this.WPTestList[index].postControlResult = event.target.value;
      }
      else {
        this.WPTestList[index].postControlResult = event.target.value;
      }
    } else if (name[1] == 'cd') {
      const index = this.CDTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');

      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        this.CDTestList[index].postControlResult = event.target.value;
      }
      else {
        this.CDTestList[index].postControlResult = event.target.value;
      }
    } else {
      const index = this.OTHTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');
      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        this.OTHTestList[index].postControlResult = event.target.value;
      }
      else {
        this.OTHTestList[index].postControlResult = event.target.value;
      }
    }

  }
  UpdateSampleValue(event) {
    //  console.log(event,event.target.name,event.target.value);
    const tempname = event.target.name;
    const name = tempname.split('_');
    if (name[1] == 'dm') {
      const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');
      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        if (!this.CheckSampleValidValue(tempname)) {
          this.DMTestList[index].sampleResult = event.target.value;
        } else {
          this.DMTestList[index].sampleResult = '';
          event.target.value = '';
        }
      }
      else {
        this.DMTestList[index].sampleResult = event.target.value;

        // this.DMTestList[index].sampleResultReset = event.target.value;
      }

    } else if (name[1] == 'wp') {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      //console.log(index, this.WPTestList);
      const retestIndex = name[0].indexOf('retest');
      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        if (!this.CheckSampleValidValue(tempname)) {
          this.WPTestList[index].sampleResult = event.target.value;
        } else {
          this.WPTestList[index].sampleResult = '';
          event.target.value = '';
        }
        //this.WPTestList[index].sampleResult = event.target.value;
      }
      else {
        this.WPTestList[index].sampleResult = event.target.value;

        // this.WPTestList[index].sampleResultReset = event.target.value;
      }

    } else if (name[1] == 'cd') {
      const index = this.CDTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');

      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        if (!this.CheckSampleValidValue(tempname)) {
          this.CDTestList[index].sampleResult = event.target.value;
        } else {
          this.CDTestList[index].sampleResult = '';
          event.target.value = '';
        }
      }
      else {
        this.CDTestList[index].sampleResult = event.target.value;
      }
    } else {
      const index = this.OTHTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.pyrogenMachaineId == name[2]
      );
      const retestIndex = name[0].indexOf('retest');

      if (retestIndex <= 0 && this.f.TestMethod.value == 'PTS') {
        if (!this.CheckSampleValidValue(tempname)) {
          this.OTHTestList[index].sampleResult = event.target.value;
        } else {
          this.OTHTestList[index].sampleResult = '';
          event.target.value = '';
        }
      }
      else {
        this.OTHTestList[index].sampleResult = event.target.value;
      }
    }

  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  pad(n) {
    return (n < 10) ? ('0' + n) : n;
  }
  CheckRed() {
    let result = true;
    this.DMTestList.forEach(function (value) {

      if (parseFloat(value.sampleResult) >= 0.9) {
        result = false;
      }
    });
    this.WPTestList.forEach(function (value) {
      if (parseFloat(value.sampleResult) >= 0.9) {
        result = false;
      }
    });
    this.CDTestList.forEach(function (value) {
      if (parseFloat(value.sampleResult) >= 0.9) {
        result = false;
      }
    });
    this.OTHTestList.forEach(function (value) {
      if (parseFloat(value.sampleResult) >= 0.9) {
        result = false;
      }
    });
    return result;
  }
  setTestingLab() {
    if (this.f.PyrogenDetailsId.value > 0) {
      //
      //if (this.f.TestingLab.value === 'Internal (Tech)' || this.f.TestingLab.value === 'Internal Lab' || this.f.TestingLab.value === 'External Lab' || this.f.TestingLab.value == 'See Notes') {
      this.f.CollectionRetestDateT.setValidators([
        CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.CollectionRetestTime
        ), CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.CollectionRetestById
        ), CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.TestSensitivity
        )
      ]);

      // this.f.TestSensitivity.setValidators([
      //   CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.CollectionRetestTime
      //   ), CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.CollectionRetestById
      //   ), CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.CollectionRetestDateT
      //   )
      // ]);
      // this.f.CollectionRetestById.setValidators([
      //   CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.CollectionRetestTime
      //   ), CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.CollectionRetestDateT
      //   ), CommonCustomValidators.CheckDateoutCustomValidation(
      //     this.f.TestSensitivity
      //   )
      // ]);
      this.f.CollectionRetestTime.setValidators([Validators.pattern(Globals.TimeRegex),
      CommonCustomValidators.CheckDateoutCustomValidation(
        this.f.CollectionRetestDateT
      ), CommonCustomValidators.CheckDateoutCustomValidation(
        this.f.CollectionRetestById
      ), CommonCustomValidators.CheckDateoutCustomValidation(
        this.f.TestSensitivity
      )
      ]);

      if (this.f.TestingLab.value === 'Internal (Tech)') {

        this.f.ControlLotNumber.setValidators([
          CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.CollectionRetestTime
          ), CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.CollectionRetestDateT
          ), CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.TestSensitivity
          ), CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.CollectionRetestById
          )
        ]);

        this.f.CollectionRetestDateT.setValidators([
          CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.SensitivityLotNumber
          ), CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.ControlLotNumber
          )
        ]);

        if (this.f.TestMethod.value == 'LAL') {
          this.f.TestSensitivity.setValidators([
            CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.SensitivityLotNumber
            ), CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.ControlLotNumber
            )
          ]);

          this.f.SensitivityLotNumber.setValidators([
            CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.CollectionRetestTime
            ), CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.CollectionRetestDateT
            ), CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.TestSensitivity
            ), CommonCustomValidators.CheckDateoutCustomValidation(
              this.f.CollectionRetestById
            )
          ]);
        }

        this.f.CollectionRetestById.setValidators([
          CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.SensitivityLotNumber
          ), CommonCustomValidators.CheckDateoutCustomValidation(
            this.f.ControlLotNumber
          )
        ]);
        this.f.CollectionRetestTime.setValidators([Validators.pattern(Globals.TimeRegex),
        CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.SensitivityLotNumber
        ), CommonCustomValidators.CheckDateoutCustomValidation(
          this.f.ControlLotNumber
        )
        ]);

        this.f.SensitivityLotNumber.updateValueAndValidity();
        this.f.ControlLotNumber.updateValueAndValidity();
      }

      this.f.CollectionRetestDateT.updateValueAndValidity();
      this.f.CollectionRetestTime.updateValueAndValidity();
      this.f.CollectionRetestById.updateValueAndValidity();
      this.f.TestSensitivity.updateValueAndValidity();
      // } else {

      //   this.f.ReportId.setValidators([
      //     CommonCustomValidators.CheckDateoutCustomValidation(
      //       this.f.CollectionRetestDate
      //     )
      //   ]);


      //   this.f.CollectionRetestDate.setValidators([
      //     CommonCustomValidators.CheckDateoutCustomValidation(
      //       this.f.ReportId
      //     )
      //   ]);
      //   this.f.ReportId.updateValueAndValidity();
      //   this.f.CollectionRetestDate.updateValueAndValidity();

      // }
    }
  }
  setTestingLabRequired() {

    // if (this.f.TestingLab.value === 'Internal (Tech)' || this.f.TestingLab.value === 'Internal Lab' || this.f.TestingLab.value === 'External Lab' || this.f.TestingLab.value == 'See Notes') {

    this.f.CollectionRetestDateT.setValidators([
      Validators.required
      , CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        this.f.CollectionDate
      )]);

    this.f.CollectionRetestTime.setValidators([Validators.required, CommonCustomValidators.CheckDateTimeGreaterWithTimeValidationPyrogen(
      this.f.CollectionDate, this.f.CollectionTime, this.f.CollectionRetestDateT, this.f.CollectionRetestTime
    )
    ]);

    if (this.f.TestingLab.value == 'External Lab' || this.f.TestingLab.value == 'Internal Lab' || this.f.TestingLab.value == 'See Notes' ||
      (this.f.TestingLab.value == 'Internal (Tech)' && this.f.TestMethod.value == 'PTS')) {
      this.f.ReportId.setValidators([Validators.required]);
      this.f.ReportId.updateValueAndValidity();
    }

    if (this.f.TestingLab.value === 'Internal (Tech)') {
      if (this.f.TestMethod.value == 'LAL') {
        this.f.SensitivityLotNumber.setValidators([Validators.required]);
        this.f.TestSensitivity.setValidators([Validators.required]);
        this.f.SensitivityLotNumber.updateValueAndValidity();
        this.f.TestSensitivity.updateValueAndValidity();
      }
      this.f.ControlLotNumber.setValidators([Validators.required]);
      this.f.CollectionRetestById.setValidators([Validators.required]);
      this.f.ControlLotNumber.updateValueAndValidity();
      this.f.CollectionRetestById.updateValueAndValidity();
    }

    this.f.CollectionRetestDateT.updateValueAndValidity();
    this.f.CollectionRetestTime.updateValueAndValidity();
    // } else {

    //   this.f.ReportId.setValidators([Validators.required]);


    //   this.f.CollectionRetestDate.setValidators([Validators.required, CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
    //     this.f.CollectionDate)]);
    //   this.f.ReportId.updateValueAndValidity();
    //   this.f.CollectionRetestDate.updateValueAndValidity();

    // }

  }
  GetBracket(str) {
    if (str == null || str == '') {
      return '';
    } else {
      return '(' + str + ')';
    }
  }
  GetColor(ControlName, type) {
    const element = document.getElementsByName(ControlName);
    if (element != null && element != undefined) {
      if (element.length > 0) {
        const val = (<HTMLInputElement>element[0]).value;
        try {
          if (this.f.TestMethod.value == 'LAL') {
            if (type === 'pos')
              return (val === '(-)') ? 'red' : 'black';
            else
              return (val === '(+)') ? 'red' : 'black';

          } else {
            const nval = parseFloat(val);
            return (nval >= 0.9 || this.CheckSampleValidValue(ControlName)) ? 'red' : 'black';
          }
        } catch (error) {
          return "red";
        }
      }
    }
  }
  CheckValidValue(ControlName) {
    const element = document.getElementsByName(ControlName);
    //console.log(ControlName);
    if (element != null && element != undefined) {
      if (element.length > 0) {
        const val = (<HTMLInputElement>element[0]).value;
        if (!this.CheckSampleValidValue(ControlName)) {
          const nval = parseFloat(val);
          //console.log(ControlName, nval >= 0.125);
          return (nval >= 0.9) ? true : false;
        }
        else {
          return false;
        }
      }
    }
    return false;
  }
  CheckSampleValidValue(ControlName) {
    const element = document.getElementsByName(ControlName);
    if (element != null && element != undefined) {
      if (element.length > 0) {
        const val = (<HTMLInputElement>element[0]).value;
        //console.log(ControlName, val,val.match(/^(\d*)(\.\d{1,3})?$/));
        if (val.match(/^(\d*)(\.\d{0,3})?$/) == null && val !== '(+)' && val !== '(-)') {
          return true;
        }
        else {
          return false;
        }
      }
    }
    return false;
  }

  GetColorLabel(val) {
    try {
      const nval = parseFloat(val);
      return (nval >= 0.9 || val == '(+)') ? 'red' : 'black';
    } catch (error) {
      console.log(val);
      return "red";
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46, charCode);
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      return false;
    }
    return true;
  }

  onEquipmentChanged(args) {

    var equipmentId = { Id: args.target.value };
    this.dmapi.GetEquipmentDetailsByID(equipmentId).subscribe(success => {
      this.hrsTracking = success.hrsTracking;
    });

    this.f.TestHours.setValue('');

    this.dmapi.GetmachaineHistoryMaxHours(equipmentId).subscribe(result => {
      if (result) {
        this.selectedEquipmentHours = result;
      } else {
        this.selectedEquipmentHours = 0;
      }
    });
    //this.validateMachineRepair();
  }

  check4000HoursValidation(): boolean {

    if (this.f.TestHours.value - this.selectedEquipmentHours >= 4000) {
      return true;
    } else {
      return false;
    }
  }

  // UpdateCollectionRetestTime(args){
  //
  //   if(args.target.value){
  //     this.f.CollectionRetestTime.setValue(args.target.value);
  //   }
  // }
}

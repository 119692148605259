import { AppComponent } from 'src/app/app.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NotesComponent } from '../../../Core/Shared/NotesComponent/NotesComponent';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickAddWaterAnalysisDialog } from '../../../Core/QuickAddWaterAnalysis/QuickAddWaterAnalysis';
import { WaterProcessAnalysisComponent } from 'src/app/Core/Shared/WaterProcessAnalysisComponent/WaterProcessAnalysisComponent.component';
import { BacteriaComponent } from 'src/app/Core/Shared/BacteriaComponent/Bacteria.component';
import { PyrogenComponent } from 'src/app/Core/Shared/PyrogenComponent/Pyrogen.component';
@Component({
  selector: 'PurposeTesting',
  templateUrl: './purpose-testing.component.html',
  styleUrls: ['./purpose-testing.component.css']
})
export class PurposeTestingComponent implements OnInit {

  mrmHomeId: number = 0;
  quickAddDialogRef: MatDialogRef<WaterProcessAnalysisComponent>;
  quickAddBacteriaDialogRef : MatDialogRef<BacteriaComponent>;
  quickAddPyrogenDialogRef : MatDialogRef<PyrogenComponent>;

  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;

  hpcTest = '';
  dhpcTest = '';
  lalTest = '';
  dlalTest = '';
  todaydate = new Date();
  lstROProduct = [
    { value: '', text: 'Select' },
    { value: 'N/A', text: 'N/A' },
    { value: 'Private Well (TECH.PWP)', text: 'Private Well (TECH.PWP)' },
    { value: 'Municipal (TECH.PWM)', text: 'Municipal (TECH.PWM)' },
  ];
  lstFeed = [
    { value: '', text: 'Select' },
    { value: 'N/A', text: 'N/A' },
    { value: 'Private Well (TECH.FWP)', text: 'Private Well (TECH.FWM)' },
    { value: 'Municipal (TECH.PWM)', text: 'Municipal (TECH.PWM)' },
  ];
  lstTest = [
    { value: '', text: 'Select' },
    { value: 'N/A', text: 'N/A' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];
  lstResult = [{ value: '', text: 'Select' },
  { value: 'Pass', text: 'Pass' },
  { value: 'Fail', text: 'Fail' }];

  lstHPCResult = [
    { value: '', text: 'Select' },
    { value: '<10', text: '<10' },
    { value: '10', text: '10.0' },
    { value: '11', text: '11.0' },
    { value: '12', text: '12.0' },
    { value: '13', text: '13.0' },
    { value: '14', text: '14.0' },
    { value: '15', text: '15.0' },
    { value: '16', text: '16.0' },
    { value: '17', text: '17.0' },
    { value: '18', text: '18.0' },
    { value: '19', text: '19.0' },
    { value: '>20', text: '>20' },

  ];

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  quickAddWaterAnalysisDialog() {
    if (this.mrmHomeId !== 0) {
      this.quickAddDialogRef = this.dialog.open(WaterProcessAnalysisComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '70vh',
        maxHeight: '100vh',
        data: {

        }
      });

      this.quickAddDialogRef.componentInstance.FullScreen = false;
      this.quickAddDialogRef.componentInstance.setDialysisUnitAndStation('Home Program', this.mrmHomeId,"",'0');
      this.quickAddDialogRef.afterClosed().subscribe(result => {
      });

      this.quickAddDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      });
    }
    else {
      AppComponent.addAlert("Select BMR Home Unit First.", "error");
    }
  }

  quickAddBacteriaDialog() {
    if (this.mrmHomeId !== 0) {
      this.quickAddBacteriaDialogRef = this.dialog.open(BacteriaComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '60vh',
        maxHeight: '100vh',       
      });

      this.quickAddBacteriaDialogRef.componentInstance.FullScreen = false;
      this.quickAddBacteriaDialogRef.componentInstance.setDialysisUnitAndMachines('Home Program', this.mrmHomeId,'');
      this.quickAddBacteriaDialogRef.afterClosed().subscribe(result => {
      });

      this.quickAddBacteriaDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      });
    }
    else {
      AppComponent.addAlert("Select BMR Home Unit First.", "error");
    }
  }

  
  quickAddPyrogenDialog() {
    if (this.mrmHomeId !== 0) {
      this.quickAddPyrogenDialogRef = this.dialog.open(PyrogenComponent, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '60vh',
        maxHeight: '100vh'
      });

      this.quickAddPyrogenDialogRef.componentInstance.FullScreen = false;
      this.quickAddPyrogenDialogRef.componentInstance.setDialysisUnitAndMachines('Home Program', this.mrmHomeId,'');
      this.quickAddPyrogenDialogRef.afterClosed().subscribe(result => {
      });

      this.quickAddPyrogenDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      });
    }
    else {
      AppComponent.addAlert("Select BMR Home Unit First.", "error");
    }
  }

  formChange(enable = false) {
    // if (enable)
    //   this.form.enable();
    // else
    //   this.form.disable();
  }
}

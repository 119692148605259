import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'BacteriaSearch',
  templateUrl: './BacteriaSearch.component.html'
})
export class BacteriaSearchComponent {
  UserList: any;
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];

  selectedDivision = 'All Divisions';
  BacteriaCode = '';
  Status = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  SampleReadFromDate;
  SampleReadToDate;
  RetestFromDate;
  RetestToDate;
  RetestSampleReadFromDate;
  RetestSampleReadToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'DialysisMachine/BacteriaDetail';
  currentPageNumber = 1;
  sortExpression = 'default';
  sortDirection = 'ASC';
  pageSize = 25;
  ShowSearch = false;
  title = 'Bacteria';
  searchText = 'Search Records';
  defaultSort;

  // tslint:disable-next-line:member-ordering
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };
  Statuses = [
    { value: '', text: 'Select' },
    { value: 'In Progress', text: 'In Progress' },
    { value: 'Retest', text: 'Retest' },
    { value: 'Completed', text: 'Completed' }
  ];
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'eventId', '4');
    this.dataGridService.addHeader('Event Code', 'eventId', '12');
    this.dataGridService.addHeader('Division', 'divisionName', '18');
    this.dataGridService.addHeader('Date', 'collectionDate', '12');
    this.dataGridService.addHeader('Collection Date (Retest)', 'collectionretestdate', '18');
    this.dataGridService.addHeader('Collection Tech', 'collectionByName', '18');
    this.dataGridService.addHeader('Status', 'status', '12');

    this.sortDirection = 'DESC';
    this.sortExpression = 'collectionDate';
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('Date', true);
    // this.fillSiloes();
    this.executeBacteriaSearch();
    this.fillDialysisUnit();

    this.CheckDirtySearch();
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeBacteriaSearch();
  }

  setSortIndicator = function(column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeBacteriaSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const BacteriaSearch = this.prepareSearch();
    this.dmapi.GetBacteriaDetails(BacteriaSearch).subscribe(
      pagedData => {
        //console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.bacteriaList;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    const BacteriaSearch = <any>{};

    BacteriaSearch.BacteriaCode = this.BacteriaCode;
    BacteriaSearch.Status = this.Status;

    if (this.FromDate !== undefined && this.FromDate !== '') {
      BacteriaSearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '') {
      BacteriaSearch.todate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    if (this.SampleReadFromDate !== undefined && this.SampleReadFromDate !== '') {
      BacteriaSearch.SampleReadFromDate = Globals.GetAPIDate(this.SampleReadFromDate.jsdate);
    }

    if (this.SampleReadToDate !== undefined && this.SampleReadToDate !== '') {
      BacteriaSearch.SampleReadToDate = Globals.GetAPIDate(this.SampleReadToDate.jsdate);
    }

    if (this.RetestFromDate !== undefined && this.RetestFromDate !== '') {
      BacteriaSearch.RetestFromDate = Globals.GetAPIDate(this.RetestFromDate.jsdate);
    }

    if (this.RetestToDate !== undefined && this.RetestToDate !== '') {
      BacteriaSearch.RetestToDate = Globals.GetAPIDate(this.RetestToDate.jsdate);
    }

    if (this.RetestSampleReadFromDate !== undefined && this.RetestSampleReadFromDate !== '') {
      BacteriaSearch.RetestSampleReadFromDate = Globals.GetAPIDate(this.RetestSampleReadFromDate.jsdate);
    }

    if (this.RetestSampleReadToDate !== undefined && this.RetestSampleReadToDate !== '') {
      BacteriaSearch.RetestSampleReadToDate = Globals.GetAPIDate(this.RetestSampleReadToDate.jsdate);
    }

    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      BacteriaSearch.division = this.selectedDivision;
    }


    BacteriaSearch.currentPageNumber = this.currentPageNumber;
    BacteriaSearch.sortExpression = this.sortExpression;
    BacteriaSearch.sortDirection = this.sortDirection;
    BacteriaSearch.pageSize = this.pageSize;

    return BacteriaSearch;
  }

  setPage() {
    if (this.currentPageNumber != this.page.pageNumber) {
      this.currentPageNumber = this.page.pageNumber;
      this.executeBacteriaSearch();
    }
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit() {
    // this.dmapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => (this.DialysisUnits = list));
    this.commonapi.FillDivision(true).subscribe(list => this.DialysisUnits = list);
  }

  fillUserList() {
    this.dmapi
      .GetUsersDD()
      .subscribe(list => (this.UserList = list));
  }

  executeBacteriaSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.BacteriaCode = '';
    this.FromDate = '';
    this.ToDate = '';
    this.SampleReadFromDate = '';
    this.SampleReadToDate = '';
    this.RetestFromDate = '';
    this.RetestToDate = '';
    this.RetestSampleReadFromDate = '';
    this.RetestSampleReadToDate = '';
    this.Status = '';
    this.executeBacteriaSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision !== '' && this.selectedDivision !== 'All Divisions')
      ||  this.Status !== ''
      || this.BacteriaCode !== '' ||
      (this.FromDate !== null && this.FromDate !== '') ||
      (this.ToDate !== null && this.ToDate !== '') ||
      (this.SampleReadFromDate !== null && this.SampleReadFromDate !== '') ||
      (this.SampleReadToDate !== null && this.SampleReadToDate !== '') ||
      (this.RetestFromDate !== null && this.RetestFromDate !== '') ||
      (this.RetestToDate !== null && this.RetestToDate !== '') ||
      (this.RetestSampleReadFromDate !== null && this.RetestSampleReadFromDate !== '') ||
      (this.RetestSampleReadToDate !== null && this.RetestSampleReadToDate !== '')
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

<form [formGroup]="InventoryForm" (ngSubmit)="executeInventoryCreateUpdate('')" novalidate>
    <section class="content-header">
        <h1>
            Inventory
            <!-- <sup class="beta">[beta]</sup> -->
        </h1>
    </section>
    <section class="content">
        <div class="row">
            <div class="box  box-primary">

                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                    (SaveEvent)="executeInventoryCreateUpdate('')" [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()" #pageheader>
                </PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="box ibox box-info">
                                <div class="box-body">
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-8'>

                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Category:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="categoryId"
                                                        name="categoryId" (change)="onCategoryChange()">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of CategoryList">{{item.text}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.categoryId.errors?.required && (f.categoryId.dirty || f.categoryId.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                            </div>

                                            <!-- <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                    class='text-red inputrequired'>*</span>Barcode Scanner:</label>
                                                <barcode-scanner-livestream  style="width: 237px;
                                                transform: translateX(6%) translateY(45%);
                                                height: 100px;"  class="col-sm-7" type="code_128" (valueChanges)="onValueChanges($event)" (started)="onStarted">
                                                <div [hidden]="!barcodeValue" class="col-sm-7">
                                                    {{barcodeValue}}
                                                </div></barcode-scanner-livestream>
                                            </div> -->

                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Inventory Type:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="equipmentType"
                                                        name="equipmentType" (change)="onEquipmentTypeChange()">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of equipmentTypeList">{{item.text}}
                                                        </option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.equipmentType.errors?.required && (f.equipmentType.dirty || f.equipmentType.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Manufacturer:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="manufacturerId"
                                                        name="manufacturerId" (change)="fillAndSetModelList()">
                                                        <option value="">Select</option>
                                                        <option value="-1" class="optionStyle">&#65291; Add new</option>
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of ManufacturerList">{{item.text}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.manufacturerId.errors?.required && (f.manufacturerId.dirty || f.manufacturerId.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                                <!-- <div class="col-sm-1" *ngIf="f.equipementId.value=='0' || f.manufacturerId.value==''">
                                                    <i class="fa fa-plus-circle fa-2x" style="cursor: pointer"  (click)="quickAddDialog('Manufacturer')"></i>
                                                </div> -->
                                            </div>
                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label' *ngIf="f.equipmentType.value == 'PARTS'" ><span
                                                        class='text-red inputrequired'>*</span>Assign To:</label>
                                                        <label class='col-sm-5 control-label' *ngIf="f.equipmentType.value == 'EQUIPMENT' || f.equipmentType.value ==''" ><span
                                                          class='text-red inputrequired'>*</span>Model:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="modelId"
                                                        name="modelId" (change)="modelChange()">
                                                        <option value="">Select</option>
                                                        <option value="-1" class="optionStyle">&#65291; Add new</option>
                                                        <option value="{{item.value}}" *ngFor="let item of ModelList">
                                                            {{item.text}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.modelId.errors?.required && (f.modelId.dirty || f.modelId.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                                <!-- <div class="col-sm-1" *ngIf="f.equipementId.value=='0' || f.modelId.value==''">
                                                    <i class="fa fa-plus-circle fa-2x" style="cursor: pointer" (click)="quickAddDialog('Model')"></i>
                                                </div> -->
                                            </div>

                                            <div class="col-sm-8 box-row" *ngIf="f.equipmentType.value != 'EQUIPMENT'">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Item Name:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <input name='equipmentName' maxlength='80' class='form-control'
                                                        formControlName='equipmentName'>
                                                    <span
                                                        *ngIf='f.equipmentName.errors?.required && (f.equipmentName.dirty || f.equipmentName.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>

                                                </div>

                                            </div>
                                            <div class="col-sm-8 box-row" *ngIf="f.equipmentType.value == 'EQUIPMENT'">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Item Group:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="equipmentName"
                                                        name="equipmentName" (change)="itemGroupChange()">
                                                        <option value="">Select</option>
                                                        <option value="-1" class="optionStyle">&#65291; Add new</option>
                                                        <option value="{{item.text}}"
                                                            *ngFor="let item of ItemGroupList">{{item.text}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf='f.equipmentName.errors?.required && (f.equipmentName.dirty || f.equipmentName.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>

                                                </div>

                                                <!-- *ngIf="f.equipementId.value=='0'" -->
                                                <!-- <div class="col-sm-1" >

                                                    <i class="fa fa-plus-circle fa-2x" style="cursor: pointer" (click)="quickAddDialog('ItemGroup')"></i>
                                                </div> -->
                                            </div>
                                            <div class='col-sm-3'  style="padding: 8px 0" *ngIf="f.equipmentType.value == 'EQUIPMENT' && f.equipmentName.value!='' && f.equipmentName.value!='-1'">
                                                <div class='col-sm-1'  style="padding:0px">


                                                    <!-- <mat-checkbox formControlName="hrsTrackingItemGroup" name="hrsTrackingItemGroup" (change)="hrsTrackingItemGroupChecked()">

                                                      </mat-checkbox> -->
                                                </div>
                                                <!-- <span class='col-sm-11' *ngIf="f.equipmentName.value" >{{ f.hrsTrackingItemGroup.value ? 'Hrs Tracking' : 'Hrs Non Tracking'}}</span> -->

                                            </div>

                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Item
                                                    ID:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <input name='equipmentCode' maxlength='50' class='form-control'
                                                        formControlName='equipmentCode'
                                                        (keyup)="forceInputUppercase($event)"
                                                        (change)="onSerialNumberChange()">
                                                    <span
                                                        *ngIf='f.equipmentCode.errors?.required && (f.equipmentCode.dirty || f.equipmentCode.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span
                                                        *ngIf="f.equipmentCode.errors?.ItemID" [ngClass] = "'error'">
                                                        Item ID already exists.
                                                    </span>
                                                </div>
                                            </div>
                                            <div class='col-sm-3' style="padding: 8px 0" *ngIf="hrsTrackingVisible()">
                                                <div class='col-sm-1'  style="padding:0px" *ngIf="f.equipmentCode.value">
                                                    <mat-checkbox formControlName="hrsTracking" name="hrsTracking">

                                                    </mat-checkbox>
                                                </div>
                                                <span class='col-sm-11' *ngIf="f.equipmentCode.value" >{{ f.hrsTracking.value ? 'Hrs Tracking' : 'Hrs Tracking Required?'}}</span>
                                            </div>
                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'>Prior
                                                    Item ID:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <input name='equipmentPreviousCode' maxlength='50'
                                                        class='form-control' formControlName='equipmentPreviousCode'>
                                                </div>
                                            </div>

                                            <div class="col-sm-8 box-row" *ngIf="f.equipmentType.value == 'EQUIPMENT'">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Serial
                                                    Number:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <input name='serialNumber' maxlength='50' class='form-control'
                                                        (change)="onSerialNumberChange()"
                                                        (keyup)="forceInputUppercase($event)"
                                                        formControlName='serialNumber'>
                                                    <span
                                                        *ngIf="f.serialNumber.errors?.required && (f.serialNumber.dirty || f.serialNumber.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span
                                                        *ngIf="f.serialNumber.errors?.ItemID" [ngClass] = "'error'">
                                                        Serial Number already exists.
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4" *ngIf="f.equipmentType.value == 'EQUIPMENT' && f.equipementId.value=='0'">
                                                <button (click)="openBarcodeScanner()" style="margin-right:20px;"
                                                class="btn btn-success pull-left" type="button"><i
                                                    class="glyphicon glyphicon-barcode"></i>&nbsp;
                                                Scan</button>
                                                <!-- <button (click)="barcodeOpen()"
                                                class="btn btn-success pull-left" type="button"><i
                                                    class="glyphicon glyphicon-barcode"></i>&nbsp;
                                                Scan 2</button> -->
                                            </div>
                                            <br>
                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Status:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="presentStatus"
                                                        name="presentStatus">
                                                        <option value="{{item.value}}" *ngFor="let item of statusList">
                                                            {{item.text}}</option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.presentStatus.errors?.required && (f.presentStatus.dirty || f.presentStatus.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="col-sm-8 box-row"
                                                *ngIf="f.equipmentType.value == 'EQUIPMENT' && CategoryName!='Other Equipment'">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Division:</label>
                                                <div class='col-sm-7 rightPadding'>
                                                    <select class="form-control" formControlName="divisionId"
                                                        name="divisionId">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of DivisionList">{{item.text}}</option>
                                                    </select>

                                                    <span
                                                        *ngIf='f.divisionId.errors?.required && (f.divisionId.dirty || f.divisionId.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-8 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Description:</label>
                                                <div class='col-sm-7'>
                                                    <textarea name='equipmentDesc' rows='3' class="form-textarea"
                                                        formControlName='equipmentDesc' style="width:315%"></textarea>
                                                    <span
                                                        *ngIf="f.equipmentDesc.errors?.required && (f.equipmentDesc.dirty || f.equipmentDesc.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-2"></div> -->
                                        <div class='col-sm-4'>
                                            <div class="imageWrapper">
                                                <img style="width:100%" [src]="GetInventoryImagePath()"
                                                    *ngIf="f.equipmentPicFilePath.value != ''">
                                                <br><br>
                                            </div>
                                            <label class='col-sm-1 control-label'></label>
                                            <div class='col-sm-3'>
                                                <input type="file" accept="image/x-png,image/gif,image/jpeg"
                                                    class="hidden" id="ng2File" (change)="onFileSelected($event)"
                                                    #fileInputImage />
                                                <button (click)="fileInputImage.click()"
                                                    class="btn btn-success pull-left" type="button"><i
                                                        class="glyphicon glyphicon-upload"></i>&nbsp;
                                                    Upload</button>


                                            </div>
                                            <div class="col-md-3" *ngIf="f.equipmentPicFilePath.value != ''"> <button
                                                    class="btn btn-danger" (click)="clearImage()" type="button"><span
                                                        class="glyphicon glyphicon-trash"></span>
                                                    &nbsp; Clear &nbsp; </button></div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span
                                                    class='text-red inputrequired'>*</span>Bar
                                                Code:</label>

                                            <div class='col-sm-7'>
                                                <input name='barCode' maxlength='50' class='form-control'
                                                    formControlName='barCode'>
                                                <span
                                                    *ngIf='f.barCode.errors?.required && (f.barCode.dirty || f.barCode.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Asset Tag:</label>

                                            <div class='col-sm-7'>
                                                <input name='barcode2' maxlength='50' class='form-control'
                                                    formControlName='barcode2'>

                                            </div>
                                        </div>


                                    </div>

                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Purchase
                                                By:</label>

                                            <div class='col-sm-7'>
                                                <select class="form-control" formControlName="modeOfPayment"
                                                    name="modeOfPayment">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of modeOfPaymentList">{{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.modeOfPayment.errors?.required && (f.modeOfPayment.dirty || f.modeOfPayment.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>
                                                POCheque #:</label>

                                            <div class='col-sm-7'>
                                                <input name='paymentDetails' maxlength='80' class='form-control'
                                                    formControlName='paymentDetails'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>
                                                Expense Type ID:</label>

                                            <div class='col-sm-7'>
                                                <input name='expenseCode' maxlength='80' class='form-control'
                                                    formControlName='expenseCode'>
                                                <span
                                                    *ngIf="f.expenseCode.errors?.required && (f.expenseCode.dirty || f.expenseCode.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Purchase
                                                Cost Each:</label>

                                            <div class='col-sm-7'>
                                                <input name='itemCost' maxlength='10' class='form-control'
                                                    formControlName='itemCost' (blur)="transformitemCost()"
                                                    (focusout)="transformitemCost()" [(value)]="formatteditemCost"
                                                    (keypress)="numberOnly($event)">
                                                <span
                                                    *ngIf="f.itemCost.errors?.required && (f.itemCost.dirty || f.itemCost.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span
                                                    *ngIf="f.itemCost.errors?.maxlength && (f.itemCost.dirty || f.itemCost.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Purchase Cost Each must be less than $999,999
                                                </span>
                                            </div>
                                        </div>

                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Previous Cost Each:</label>
                                            <div class='col-sm-7'>
                                                <input name='previousCost' type="text" maxlength='10'
                                                    (keypress)="numberOnly($event)" class='form-control'
                                                    (blur)="transformPreviousCost()"
                                                    (focusout)="transformPreviousCost()"
                                                    [(value)]="formattedPreviousCost" formControlName='previousCost'>
                                                <span
                                                    *ngIf="f.previousCost.errors?.maxlength && (f.previousCost.dirty || f.previousCost.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Previous Cost Each must be less than $999,999
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>

                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span
                                                    class='text-red inputrequired'>*</span>Vendor:</label>
                                            <div class='col-sm-6'>
                                                <select class="form-control" formControlName="vendorId" name="vendorId"
                                                    (change)="onVendorChange()">
                                                    <option value="">Select</option>
                                                    <option value="-1" class="optionStyle">&#65291; Add new</option>
                                                    <option value="{{item.contactId}}" *ngFor="let item of VendorList">
                                                        {{item.givenName}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.vendorId.errors?.required && (f.vendorId.dirty || f.vendorId.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                            <!-- <div class="col-sm-1">
                                              <i class="fa fa-plus-circle fa-2x" style="cursor: pointer"  (click)="quickAddDialog('Vendor')"></i>
                                            </div> -->
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Vendor Id:</label>
                                            <div class='col-sm-7'>
                                                <label class="readOnlyText">
                                                    {{ f.vendorNumber.value }}
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Vendor Phone:</label>

                                            <div class='col-sm-7'>
                                                <label class="readOnlyText">
                                                    {{ f.vendorPhone.value }}
                                                </label>
                                            </div>
                                        </div>


                                    </div>



                                </div>
                            </div>

                            <div class="box ibox box-info" *ngIf="f.equipmentType.value == 'EQUIPMENT'">
                                <div class="box-body">

                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Last Updated Date:</label>
                                            <div class='col-sm-7'>
                                                <input name='lastUpdateDate' class='form-control'
                                                    formControlName='lastUpdateDate'>
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Last Updated Hours:</label>

                                            <div class='col-sm-7'>
                                                <input name='lastUpdateHrs' class='form-control'
                                                    formControlName='lastUpdateHrs'>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>In-Service Date:</label>

                                            <div class='col-sm-7'>
                                                <input (focus)="inServiceDate.toggleCalendar()"
                                                    (click)="inServiceDate.openCalendar()" class="form-control"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="inServiceDate" formControlName="inServiceDate"
                                                    [options]="myOptions" #inServiceDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />
                                                <span
                                                    *ngIf="f.inServiceDate.errors?.required && f.equipmentType.value == 'EQUIPMENT' && (f.inServiceDate.dirty || f.inServiceDate.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>

                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>PM Schedule:</label>

                                            <div class='col-sm-3'>
                                                <input name='PMScheduleStatus' maxlength='5' class='form-control'
                                                    formControlName='PMScheduleStatus' type="text" (keypress)="numberOnly($event)">
                                                <span
                                                    *ngIf="f.PMScheduleStatus.errors?.required && (f.PMScheduleStatus.dirty || f.PMScheduleStatus.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                            <div class="col-sm-3">
                                                <mat-button-toggle-group value="" formControlName="PMScheduleStatusType"
                                                    name="PMScheduleStatusType" form
                                                    #tasktypegroup="matButtonToggleGroup">
                                                    <mat-button-toggle value="{{onDays}}" class="btnsuccess">{{onDays}}
                                                    </mat-button-toggle>
                                                    <mat-button-toggle class="btndanger" value="{{onHours}}">{{onHours}}
                                                    </mat-button-toggle>
                                                </mat-button-toggle-group>
                                                <span
                                                    *ngIf="f.PMScheduleStatusType.errors?.required && (f.PMScheduleStatusType.dirty || f.PMScheduleStatusType.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>

                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Warranty (Labour) Days:</label>

                                            <div class='col-sm-7'>
                                                <input name='warrantyPeriodDays' maxlength='5' class='form-control'
                                                    formControlName='warrantyPeriodDays' type="number">
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Warranty (Labour) Hours:</label>

                                            <div class='col-sm-7'>
                                                <input name='warrantyPeriodHrs' maxlength='5' class='form-control'
                                                    formControlName='warrantyPeriodHrs' type="number">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Warranty (Parts) Days:</label>

                                            <div class='col-sm-7'>
                                                <input name='warrantyPeriodForPartsDays' maxlength='5'
                                                    class='form-control' formControlName='warrantyPeriodForPartsDays'
                                                    type="number">
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Warranty (Parts) Hours:</label>

                                            <div class='col-sm-7'>
                                                <input name='warrantyPeriodForPartsHrs' maxlength='5'
                                                    class='form-control' formControlName='warrantyPeriodForPartsHrs'
                                                    type="number">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>
                                    <!-- <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>PM Schedule:</label>

                                            <div class='col-sm-7'>
                                                <input name='scheduledHours' maxlength='5' class='form-control'
                                                    formControlName='scheduledHours' type="number">
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Date Completed:</label>

                                            <div class='col-sm-7'>
                                                <input name='scheduledHours' maxlength='5' class='form-control'
                                                    formControlName='scheduledHours' type="number">
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-sm-12 box-row"
                                        *ngFor="let schedule of scheduledPMList;let index=index;">
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>
                                                <div *ngIf="index==0">PM Completed:</div>
                                            </label>

                                            <div class='col-sm-7'>
                                                <input class='form-control' disabled value="{{schedule.scheduleHours}}">
                                            </div>
                                        </div>
                                        <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'>Date Completed:</label>
                                            <div class='col-sm-7'>
                                                <input class='form-control' disabled *ngIf="schedule.dateOut != 'NA'"
                                                    value="{{schedule.dateOut | date:'dd/MM/yyyy'}}">

                                                <input class='form-control' disabled *ngIf="schedule.dateOut == 'NA'"
                                                    value="{{schedule.dateOut}}">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <notes [isValidForm]="this.InventoryForm.invalid"
                                (addNoteClick)="executeInventoryCreateUpdate('Notes')" #notes>
                            </notes>
                            <attachment (RaiseError)="AttachmentError($event)" #attachment>


                            </attachment>
                        </div>
                    </div>
                    <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                        [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                        (SaveEvent)="executeInventoryCreateUpdate('')" [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()" #pageheader>
                    </PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from './../services/auth-guard.service';
import { AppComponent } from 'src/app/app.component';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../API/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../globals';

@Component({
  selector: 'sso',
  templateUrl: './sso.component.html'
})
export class SSOComponent implements OnInit {

  constructor(private authentication: AuthService, private router: Router,private route: ActivatedRoute,
    private globals: Globals, private authguard: AuthGuardService, private cookieService: CookieService) { }
  userDesignation = this.authentication.GetDesignationName();
  ngOnInit() {

    var test = this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.

      var cookie = this.cookieService.get('mrmCookies');
      // if(cookie == '')
      //   this.cookieService.set('mrmCookies','mrmrenal', null, null, null, null, null);

        // var cookie1 = this.cookieService.get('mrmCookies');
      var token = params['token']  || "";
      //console.log(token);
      if (token != "")
      {
        if(cookie == "mrmrenal.com") {
          this.login(token);
        } else {
          this.authguard.redirectToInvalidSessionUrl();
        }
        this.cookieService.delete('mrmCookies', '/', '.mrmrenal.com');
        // window.name = '';

      }
      else
      {
        if (Globals.Version=="local")
        {
          this.router.navigate(['login']);
        }
        else if (Globals.Version=="publish")
        {
        location.href=Globals.mrmrenalwebsite;
        }
      }
    });
  }

  login(username) {
    // window.location.reload();

    var tempToken = this.authentication.getAccessToken();
    var tempTokenTime = this.authentication.getAccessTokenExpiryDate();

    var token = (tempToken != undefined && tempToken != null) ? tempToken.token : null;
    this.authentication.UserLogin(username, '',token,tempTokenTime,true).subscribe(
        list => {

        // console.log(list);

       var token = {
          refresh_token: 'refreshtokencode',
          exp: list.expirysDate,
          access_token: {
            token: list.token,
            UserName: list.userName,
            ContactId: list.contactId,
            DesignationId: list.designationId,
            DesignationName: list.designationName,
            Email: list.email,
            FullName: list.fullName,
            FirstName: list.firstName,
            LastName: list.lastName,
            IsAdminPermission : list.isAdminPermission
          }
        }
       // sessionStorage.setItem('token',list.token)
      this.authentication.setSSOEmail(username);
      this.authentication.setToken(token);
      this.userDesignation = this.authentication.GetDesignationName();
       //location.href='/Dashboard';
       if(this.userDesignation == 'Administrator' || this.userDesignation == 'Technologist' || this.userDesignation == 'Senior Technologist' || this.userDesignation == 'Admin-Technologist') {
        location.href = 'Task/TaskViewer';
      } else {
        location.href = 'Home/default';
      }


      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }
        location.href=Globals.mrmrenalwebsite;
        AppComponent.HideLoader();
      }
    );
	}

}

<div class="box-header pageheader ">
    <div class="col-sm-12">

        <div class="col-sm-6 text-left">
            <h3>{{title}}</h3>
        </div>
        <div class="col-sm-6 text-right">
            <button type="button" class="btn btn-primary" style="margin-left: 10px" *ngIf="current!='Alarm'" (click)="ShowAlarmHistory()">Alarm History</button>
            <button type="button" class="btn btn-primary" style="margin-left: 10px" *ngIf="current!='Machine'" (click)="ShowMachineHistory()">Machine History</button>
            <button type="button" class="btn btn-primary" style="margin-left: 10px" *ngIf="current!='Parts'" (click)="ShowPartsHistory()">Parts History</button>
            <button type="button" class="btn btn-danger" style="margin-left: 10px" (click)="dialogRef.close(false)">Close</button>

        </div>
    </div>
</div>
<div class="box-body">
    <div *ngIf="current=='Alarm'" class="row">
        <div class="col-sm-12">
            <div class="col-sm-9 pull-right">
                <div class="col text-right">
                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="col text-left noofrecords">
                    <span><b> <i>{{page.totalElements}}  {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWPDailySearchClear()">
                    (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                    <b>Clear Search</b>
                    </a>)
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12" style="margin-bottom:20px">
        <div class="col-sm-3">Machine <strong>{{MachineName}}</strong></div>
        <div class="col-sm-3">Serial No <strong>{{SrNo}}</strong></div>
        <div class="col-sm-3">In Service Date <strong>{{InServiceDate | date:"dd/MM/yyyy"}}</strong></div>
        <div class="col-sm-3">PM Status <strong [style.backgroundColor]="PMStatusColor" [style.color]="PMStatusTextColor" style="padding: 3px 31px;">{{PMStatusHours}}</strong></div>
    </div>

    <div>


        <table *ngIf="current=='Alarm'" style="width:97%" class="col-sm-12 BlackBorderTable no-footer">

            <thead>
                <tr>
                    <th style="width:10%">Date
                    </th>
                    <th style="width:10%">Time
                    </th>
                    <th style="width:20%">Machine State
                    </th>
                    <th style="width:20%">Disinfection Type
                    </th>
                    <th style="width:30%">Error Code
                    </th>
                    <th style="width:10%">Internal Flag
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of AlarmHistoryData;let index=index;">
                    <td>
                        <div align="center">
                            {{item.date | date:"dd/MM/yyyy"}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            {{ ("2018-01-01T" + item.time) | date:"hh:mm aa"}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            {{item.machineState}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            {{item.disinfectionType}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            {{item.errorCode}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            {{item.InternalFlag}}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <table *ngIf="current=='Parts'" style="width:97%" class="col-sm-12 BlackBorderTable no-footer">
            <thead>
                <tr>
                    <th width="10%">
                        Ref. #
                    </th>
                    <th width="5%">
                        Hours
                    </th>
                    <th width="10%">
                        Date
                    </th>
                    <th width="10%">
                        Part ID
                    </th>
                    <th width="52%">
                        Part Description
                    </th>
                    <th width="5%">
                        Qty.
                    </th>
                    <th width="8%">
                        Tech
                    </th>

                </tr>
            </thead>
            <tbody>                
                <tr *ngFor="let item of PartsHistoryData;let index=index;">
                    <td align="left" valign="top">
                        <a (click)="setlinkMachine(item.formtype,item.remark,item.dmRepairDetailsId)"> {{item.repairid}} </a>
                    </td>
                    <!-- <td align="left"  valign="top" *ngIf="item.formtype !='PreventiveMaintenance'">
                <a href="{{setlinkMachine(item.formtype,item.remark,item.dmRepairDetailsId)}}" target="_blank">  {{item.repairid}}</a>
              </td> -->
                    <td valign="top">
                        <div align="center">
                            {{item.hoursin}}
                        </div>
                    </td>
                    <td>
                        <div align="center">
                            <div *ngFor="let initem of item.partHistorylst;let index=index;">
                                <p> {{initem.transDate | date:"dd/MM/yyyy"}} </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let initem of item.partHistorylst;let index=index;">
                            <p title="{{initem.partName}}"> {{initem.partName}} </p>
                        </div>
                    </td>
                    <td>

                        <div style="text-align:left" *ngFor="let initem of item.partHistorylst;let index=index;">
                            <p title="{{initem.partDesc}}"> {{ (initem.partDesc.length>75)? (initem.partDesc | slice:0:75)+'..':(initem.partDesc) }}</p>
                        </div>

                    </td>
                    <td>
                        <div *ngFor="let initem of item.partHistorylst;let index=index;">
                            <p title="{{initem.quantity}}"> {{initem.quantity}} </p>
                        </div>
                    </td>
                    <td>
                        <div *ngFor="let initem of item.partHistorylst;let index=index;">
                            <p title="{{initem.givenName}}"> {{initem.givenName}} </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="current=='Machine'" style="width:97%" class="col-sm-12 BlackBorderTable no-footer">
<thead>
            <tr>
                <th style="width:15%">Ref. #
                </th>
                <th style="width:10%">Date
                </th>
                <th style="width:10%">Hours
                </th>
                <th style="width:40%">Problem
                </th>
                <th style="width:15%">Tech
                </th>
                <th style="width:10%">Attachments
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of MachineHistoryData;let index=index;">

                <td align="left" valign="top">
                    <a (click)="setlinkMachine(item.formtype,item.remark,item.transid)"> {{item.repairId}} </a></td>
                <!-- <td align="left"  valign="top" *ngIf="item.formtype !='PreventiveMaintenance' && item.formtype !='equipmentDetailsHistory'"> -->
                <!-- <a *ngIf="item.formtype !='DMTransfer'" href="{{setlinkMachine(item.formtype,item.remark,item.transid)}}" target="_blank"> {{item.repairId}} </a> -->
                <!-- <a *ngIf="item.formtype =='DMTransfer'"> {{item.repairId}} </a> -->
                <!-- </td> -->
                <td class="text-center" valign="top">
                    {{item.transDate | date:'dd/MM/yyyy'}}
                </td>
                <td class="text-center" valign="top">
                    {{item.hoursout==0?"-" : item.hoursout}}
                </td>
                <td class="text-left" valign="top">
                    <div style="text-align:left;word-break:break-word;" *ngIf="item.remark.includes('|')">
                        {{item.remark.split('|')[0] }}
                    </div>
                    <div style="text-align:left;word-break:break-word;" *ngIf="!(item.remark.includes('|'))">
                        {{item.remark}}
                    </div>
                </td>
                <td align="left">
                    <div>
                        {{item.givenName}}
                    </div>
                </td>
                <td align="left">
                    <button *ngIf="item.attachmentCount != 0" style=" display: table;margin: 0 auto;" class="btn btn-primary center-block btn-xs" (click)="OpenAttachmentList(item.transid,item.moduleName)" title="Attachments">
                    <span><i class="fa fa-paperclip"></i></span>
                </button>
                </td>


                <!-- <td align="left" >
              <div >
                  {{item.workOrderRefNo}}
              </div>
          </td> -->
            </tr>
        </tbody>
        </table>
        <div class="col-sm-12" *ngIf="current=='Parts'">
            <div class="col-sm-9 text-right">
                <strong>Total Cost:</strong>
            </div>
            <div class="col-sm-3">
                <strong>{{partCost | currency}}</strong>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div *ngIf="current=='Alarm'" class="row">
        <div class="col-sm-12">
            <div class="col-sm-9 pull-right">
                <div class="col text-right">
                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="col text-left noofrecords">
                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWPDailySearchClear()">
                    (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                    <b>Clear Search</b>
                    </a>)
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="box-header col-sm-12">
        <div class="col-sm-6 col-sm-offset-6 text-right">
            <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Close</button>
        </div>
    </div>
</div>


  <ngb-alert style="z-index: 50000000000 !important" *ngIf="alertmessage" [type]="'error'" (close)="alertmessage.successMessage = null">
      {{ alertmessage }}</ngb-alert>

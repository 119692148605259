import { Component, ViewChild } from '@angular/core';
import { HistoryUploadSearchComponent } from '../../Core/Shared/HistoryUpload/HistoryUploadSearch.component';

@Component({
  selector: 'WPHistoryUploadDetailSearch',
  templateUrl: './WPHistoryUploadDetailSearch.component.html',

})
export class WPHistoryUploadDetailSearchComponent {

}

import {
  Component,
  ViewChild,
  OnInit,
  ViewEncapsulation,
  QueryList,
  ViewChildren,
  NgModule
} from '@angular/core';
import {
  DatePipe, DecimalPipe

} from '@angular/common';

import {
  Router
} from '@angular/router';
import {
  TasksApiService
} from '../../API/tasksapi.service';
import {
  PageHeaderComponent
} from '../../Core/pageheader.component';
import {
  AppComponent
} from '../../app.component';
import {
  AuthService
} from '../../API/auth.service';
import {
  Globals
} from '../../globals';
// import {
//   Popover,
//   PopoverModule
// } from "ngx-popover";

// import {
//   setRootDomAdapter
// } from '@angular/platform-browser/src/dom/dom_adapter';
import {
  ContactApiService
} from '../../API/contactapi.service';

// import * as $ from 'jquery';
// import 'jqueryui';
import { HomeFieldService } from '../../API/homefield.service';
import { HomeService } from "src/app/API/home.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
// import { global } from '@angular/core/src/util';
import { DragAndDropModule } from 'angular-draggable-droppable';

@Component({
  selector: 'AutoTaskViewer',
  templateUrl: './AutoTaskViewer.component.html',
  styleUrls: [
    './AutoTaskViewerStyle.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AutoTaskViewerComponent {
  stationList;
  stationName;
  stationID;
  showStationList = false;
  taskID;
  @ViewChildren('dropevents') dropevents: QueryList < any > ;
  @ViewChildren('customevents') customevents: QueryList < any > ;
  @ViewChildren('autotaskEvents') autotaskEvents: QueryList < any > ;
  RSideContet: string;
  ShowEventDetailL: boolean;
  LSideContet: string;
  droppedData: string;

  constructor(private api: TasksApiService, private router: Router, private auth: AuthService, private contactApi: ContactApiService,
    private datePipe: DatePipe,private homefieldApi : HomeFieldService,private homeApi: HomeService,
    private decimalPipe: DecimalPipe,public sanitizer: DomSanitizer) {

  }

  loading = true;
  blnDirtySearch = false;
  tableHeaders;
  page = new Page();
  rows;
  ShowSearch = false;
  title = 'Auto Tasks';
  searchText = 'Search Records';
  defaultSort;
  ShowEventDetailR = false;
  dmEquipmentName = '';
  roEquipmentName = '';
  isStationLocked = true;
  dateText = '';
  onLock = 'Lock';
  offUnLock = 'Unlock';
  dayNo = '';
  weekNo = '';

  dayList: any = [
    { value: '1', text: '01-Jul-2019' },
{ value: '2', text: '02-Jul-2019' },
{ value: '3', text: '03-Jul-2019' },
{ value: '4', text: '04-Jul-2019' },
{ value: '5', text: '05-Jul-2019' },
{ value: '6', text: '06-Jul-2019' },
{ value: '7', text: '08-Jul-2019' },
{ value: '8', text: '09-Jul-2019' },
{ value: '9', text: '10-Jul-2019' },
{ value: '10', text: '11-Jul-2019' },
{ value: '11', text: '12-Jul-2019' },
{ value: '12', text: '13-Jul-2019' },
{ value: '13', text: '15-Jul-2019' },
{ value: '14', text: '16-Jul-2019' },
{ value: '15', text: '17-Jul-2019' },
{ value: '16', text: '18-Jul-2019' },
{ value: '17', text: '19-Jul-2019' },
{ value: '18', text: '20-Jul-2019' },
{ value: '19', text: '22-Jul-2019' },
{ value: '20', text: '23-Jul-2019' },
{ value: '21', text: '24-Jul-2019' },
{ value: '22', text: '25-Jul-2019' },
{ value: '23', text: '26-Jul-2019' },
{ value: '24', text: '27-Jul-2019' }
  ];

  weekDayList: any = [
    { value: '1', text: 'Monday' },
    { value: '2', text: 'Tuesday' },
    { value: '3', text: 'Wednesday' },
    { value: '4', text: 'Thursday' },
    { value: '5', text: 'Friday' },
    { value: '6', text: 'Saturday' }
  ];


  events;
  currentUserId = this.auth.GetUserId();
  currentEventId = '0';
  designationName = '';
  lastdragobject='';
  // dropdownSettings;
  selectedDivisions = '';
  ngOnInit() {
    this.api.GetAutoTaskStationList().subscribe(list => this.stationList = list);
    const accesToken = this.auth.getAccessToken();
    this.designationName = accesToken.DesignationName;
    this.executeTaskSearch(false);

  }

  dropped(event,date) {
    console.log(event,date);

                AppComponent.ShowLoader();
                let station = this.stationList.filter((item) =>
                item.dialysisUnitId == event.dropData);
                let dt = date;
                console.log(this.stationList,station,station.length);
                if(station.length > 0)
                {
                this.router.navigate(['/Home/AutoTask/AutoTaskDetail'], {
                  queryParams: {
                    stationid: station[0].dialysisUnitId,
                    date: dt
                  }
                 });
                } else {
                  this.UpdateDate(event.dropData ,new Date( date));
                }

              AppComponent.HideLoader();

  }

  toggleStationList() {
    this.showStationList = !this.showStationList;
    //console.log(this.showStationList);
  }

  // ngAfterViewInit() {
  //   this.dropevents.forEach(item => {

  //     // make the event draggable using jQuery UI
  //     $(item.nativeElement).droppable({
  //       tolerance: 'touch',
  //       accept:".station",
  //            drop: ( event, ui )=> {
  //               // let station = this.stationList.filter((item) =>
  //               // item.dialysisStationName == $(ui.draggable[0]).data("event").title);
  //               // let dt = this.dayList.filter((item) => item.value ==$(event.target).attr("id"));
  //               // this.router.navigate(['/Home/AutoTask/AutoTaskDetail'], {
  //               //   queryParams: {
  //               //     stationid: station[0].dialysisUnitId,
  //               //     date: dt[0].text
  //               //   }
  //               //  });
  //               // if($(ui.draggable[0]).data("event").tasktype=='station')
  //               // {
  //               //   location.href ='/Home/AutoTask/AutoTaskDetail?stationid=' + station[0].dialysisUnitId
  //               //   + "&date=" + dt[0].text;
  //               // }  else {
  //               //     this.UpdateDate($(ui.draggable[0]).data("event").id ,new Date( dt[0].text));
  //               // }
  //            }
  //     });
  //   });
  //   setTimeout(() => {
  //     this.customevents.forEach(function (item) {
  //       // store data so the calendar knows to render an event upon drop
  //       $(item.nativeElement).data('event', {
  //         title: $.trim($(item.nativeElement).text()), // use the element's text as the event title
  //         tasktype: 'station',
  //         id: $.trim($(item.nativeElement).attr("id")),
  //         stick: true // maintain when user navigates (see docs on the renderEvent method)
  //       });
  //       // make the event draggable using jQuery UI
  //       $(item.nativeElement).draggable({
  //         zIndex: 999,
  //         revert:"invalid", // will cause the event to go back to its
  //         //revertDuration: 0, //  original position after the drag
  //         appendTo: 'body',
  //         helper: 'clone',
  //       });
  //     });
  //   }, 2000);
  // }


  getWeekNumber(thisDate) {
    var dt = new Date(thisDate);
    var thisDay = dt.getDate();

    var newDate = dt;
    newDate.setDate(1); // first day of month
    var digit = newDate.getDay();

    var Q = (thisDay + digit) / 7;

    var R = (thisDay + digit) % 7;

    if (R !== 0) return Math.ceil(Q);
    else return Q;
  }

  GetPopupClassName(date) {

    var result = 'right';
    if(this.datePipe.transform(date, 'EE') == "Sat"||this.datePipe.transform(date, 'EE') == "Fri" || this.datePipe.transform(date, 'EE') == "Thu")
    {
      result = 'left';
    }
    return result;
  }

  UpdateDate(id,newDate:Date)
  {
      if(!this.isStationLocked)
      {
        const taskCreate = < any > {};
        taskCreate.HomeStationID = id;

        taskCreate.WeekNo = this.getWeekNumber(newDate);
        taskCreate.DayNo = newDate.getDay() + 1;

        taskCreate.modifiedon = new Date();
        taskCreate.modifiedBy = this.currentUserId;
        console.log(taskCreate);
        this.api.UpdateAutoTaskBaseDate(taskCreate).subscribe(
          success => {
            this.executeTaskSearch(false);
            AppComponent.addAlert('Auto Task date updated successfully!', 'success');
            //location.href ='/Home/AutoTask/AutoTaskList';

          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      else
      {
        AppComponent.addAlert('Click on Unlock to move any task.', 'error');
        AppComponent.HideLoader();
      }
    }

  executeTaskSearch(IsAllDivision) {
    AppComponent.ShowLoader();
    this.events = [];
    const ev = [];
    const _currentPage = this;

    this.api.GetAutoTaskList().subscribe(pagedData => {
        this.events = pagedData;

        pagedData.forEach(function (task) {
          if (task) {

            // const temp = < any > {};
            // temp.WeekNo = _currentPage.getWeekNumber(task.basedate);
            // temp.weekText = _currentPage.getWeekText(temp.WeekNo - 1);
            // if(temp.weekText == '')
            // {
            //   temp.weekText = '1st Week';
            // }

            // var date = new Date(task.basedate);
            // temp.dayNo = date.getDay() - 1;
            // if(temp.dayNo < 6)
            // {
            //   temp.dayText = _currentPage.weekDayList[temp.dayNo].text;
            // }
            // else
            // {
            //   temp.dayText = 'Saturday';
            // }

            ev.push({
              dialysisUnitId: task.dialysisUnitId,
              dialysisStationName: task.dialysisStationName,
              basedate: task.basedate,
              weekDay: task.weekday,
              EventHTML: _currentPage.GetTaskDetail(task)
            });
          }
        });
        this.events = ev;
        AppComponent.HideLoader();
        // setTimeout(() => {
        // this.autotaskEvents.forEach(function (item) {
        //   // store data so the calendar knows to render an event upon drop
        //   $(item.nativeElement).data('event', {
        //     title: $.trim($(item.nativeElement).text()), // use the element's text as the event title
        //     tasktype: 'updatedate',
        //     id: $.trim($(item.nativeElement).attr("id")),
        //     stick: true // maintain when user navigates (see docs on the renderEvent method)
        //   });
        //   // make the event draggable using jQuery UI
        //   $(item.nativeElement).draggable({
        //     zIndex: 999,
        //     revert:true, // will cause the event to go back to its
        //     revertDuration: 0, //  original position after the drag
        //     appendTo: 'body',
        //     helper: 'clone',
        //   });
        // });},500);

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  RouteClick(value) {
    this.router.navigate([value]);
  }

  // mouseOver(event, content) {

  //   this.currentEventId = event.detail.event.tasktype + event.detail.event.id;
  //   const eventid = event.detail.event.tasktype + event.detail.event.id;
  //   if(event.detail.event.tasktype != 'UpdateDate') {
  //   setTimeout(() => {
  //     if (eventid == this.currentEventId) {
  //       var date = this.datePipe.transform(event.basedate, 'yyyy-MM-dd');
  //       var side = this.GetPopupClassName(date);
  //       if(side == 'right'){
  //         this.ShowEventDetailL =false;
  //         this.RSideContet = '<div class="popuptext">' + event.detail.event.EventHTML + '</div>' ;
  //        } else {
  //         this.ShowEventDetailL =true;
  //         this.LSideContet = '<div class="popuptext">' + event.detail.event.EventHTML + '</div>' ;
  //       }
  //     }
  //   }, 500);
  // }
  // }

  getNoteHour(notes) {
    const x = notes.split('|');
    if (x[1] == undefined) {
      x[1] = '';
    }

    return x[1];
  }

  getNoteDesc(notes) {
    const x = notes.split('|');
    return x[0].replace(/(?:\r\n|\r|\n)/g, '<br>');
  }



  GetEventDataDatewise(date) {

    return this.events.filter((item) => this.datePipe.transform(item.basedate, 'dd-MMM-yyyy') == date);
  }
  getWeekText(Q) {


    switch(Q) {
      // case 1: {
      //    return '1st Week';
      //   // break;
      // }
      case 1: {
        return '2nd Week';
        // break;
      }
      case 2: {
        return '3rd Week';
         //break;
      }
      case 3: {
        return '4th Week';
         //break;
      }
      default: {
        return '';
         //break;
      }
   }
  }


  GetTaskDetail(task) {

  let result = '<div class="box-body"><div class="col-sm-12"><h3>{{dialysisStationName}}</h3></div><div class="col-sm-12"><h3>{{weekDay}}</h3></div><div class="row"><div class="col-sm-12"><b class="col-sm-3 ">DM:</b><div class="col-sm-2"><label class="readOnlyText" style="padding:3px 12px">{{dmEquipmentName}}</label></div><b class="col-sm-3 ">RO:</b><div class="col-sm-2"><label class="readOnlyText" style="padding:3px 12px">{{roEquipmentName}}</label></div></div></div>';
          result += '</div>';
          console.log(task);

          let re = /{{dmEquipmentName}}/gi;
          result = result.replace(re,  task.dmEquipmentName);
          re = /{{dmEquipmentName}}/gi;
          result = result.replace(re,  task.dmEquipmentName);
          re = /{{roEquipmentName}}/gi;
          result = result.replace(re, task.roEquipmentName);
          re = /{{dialysisStationName}}/gi;
          result = result.replace(re, task.dialysisStationName);
          re = /{{weekDay}}/gi;
          result = result.replace(re, task.weekday);
          console.log(result);

          if(task.autoTaskNote != null && task.autoTaskNote != '') {
           const lines = task.autoTaskNote.split('|');
           result += '<table><tr><th style="width:18%;text-align:center !important">User</th><th  style="width:22%;text-align:center !important">Date</th><th  style="width:60%;text-align:center !important">Description</th></tr>';
           if(lines.length > 0)
           {
            lines.forEach(element => {
              const fields = element.split('~');
              result += '<tr><td alt="'+ fields[0] +'" title="'+ fields[0] +'" >'+ fields[0] +'</td><td  alt="'+ fields[1] + '  ' + fields[2] +'" title="'+ fields[1] + '  ' + fields[2] +'" >'+ fields[1] + '  ' + fields[2] +'</td><td  alt="'+ fields[3] +'" title="'+ fields[3] +'" >'+ fields[3] +'</td></tr>';
            });
           }

          result += '</table>';
          }

          return result;

        }

    // this.homeApi.GetHomeStationHistoryDetails({ Id: task.dialysisUnitId }).subscribe(
    //   list => {
    //
    //     //this.homeStationHistoryList = list;
    //     for(var item in list)
    //     {

    //     }
    //   },
    //   error => {
    //     AppComponent.addAlert(error.message, 'error');
    //     AppComponent.HideLoader();
    //   }
    // );
    // setTimeout(() => {

    // }, 1000);


  enter(event)
  {
    this.currentEventId = event.dialysisUnitId;

      setTimeout(() => {
        if(this.currentEventId != '0')
        {
          var date = this.datePipe.transform(event.basedate, 'yyyy-MM-dd');
          var side = this.GetPopupClassName(date);
          if(side == 'right'){
              this.ShowEventDetailL = false;
              this.ShowEventDetailR = true;
              this.RSideContet = '<div class="popuptext">' + event.EventHTML  + '</div>' ;
          }
          else{
              this.ShowEventDetailR = false;
              this.ShowEventDetailL =true;
            this.LSideContet = '<div class="popuptext">' + event.EventHTML  + '</div>' ;
          }
        }
      }, 500);


  }

  leave() {
    this.ShowEventDetailL = false;
    this.ShowEventDetailR = false;
    this.currentEventId = '0';
  }

  redirectToStationAssignment(dialysisUnitId){

    this.router.navigate(['/Home/HomeStationDetail'], {
      queryParams: {
        id: dialysisUnitId
      }
    });
}

  onChangeLock(val: string){

    if(val == 'true')
    {
      this.isStationLocked = false;
    }
    else
    {
      this.isStationLocked = true;
    }

  }

}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}


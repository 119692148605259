import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { ScheduleColorApiService } from 'src/app/API/schColorapi.service';

@Component({
  selector: 'ScheduleColorSearch',
  templateUrl: './ScheduleColorSearch.component.html',
})
export class ScheduleColorSearchComponent {

  ShowSearch = false;
  searchText = 'Search Records';
  searchSymbol ='';
  searchDescription ='';

  blnDirtySearch = false;
  DialysisUnits = [];
  
  constructor( private sColorAPI: ScheduleColorApiService , private router: Router, 
    private commonapi : CommonApiService,
    private dataGridService: TableHeadsService, ){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows ;
  AddUrl = '/Admin/ScheduleColorDetail';
  currentPageNumber = 1;
  sortExpression = 'symbol';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Schedule Color';
  defaultSort;

  ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'scheduleColorId', '5');
    this.dataGridService.addHeader('Symbol', 'symbol', '25');    
    this.dataGridService.addHeader('Color', 'color', '25');
    this.dataGridService.addHeader('Description', 'description', '45');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('symbol', false);
    this.fillDialysisUnit();
    this.CheckDirtySearch();
  }

  executeScheduleColorSearchClear() {
    this.searchDescription = ''; 
    this.searchSymbol = '';   
    this.executeScheduleColorSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.searchSymbol || this.searchDescription      
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  
  fillDialysisUnit() {
    this.commonapi.FillDivision().subscribe(list =>{ 
      this.DialysisUnits = list.filter(
        x => x.text != "Home Program"
      );
    });
  }


  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeScheduleColorSearch();
  }

  setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeScheduleColorSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.ShowSearch = false;
    this.pageheader.searchText = 'Search Records';
    this.CheckDirtySearch();

    const sColorSearch = this.prepareSearch();
    this.sColorAPI.GetScheduleColorSerachDetails(sColorSearch).subscribe(pagedData  => {
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.sColorsList;
         console.log(pagedData);
        AppComponent.HideLoader();
      }
    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );
  }

prepareSearch() {
  // tslint:disable-next-line:prefer-const
  let ScheduleColorsearch = <any>{};
  ScheduleColorsearch.currentPageNumber = this.currentPageNumber;
  ScheduleColorsearch.sortExpression = this.sortExpression;
  ScheduleColorsearch.sortDirection = this.sortDirection;
  ScheduleColorsearch.PageSize = this.pageSize;

  if (this.searchSymbol) {
    ScheduleColorsearch.symbol = this.searchSymbol;
  }

  if (this.searchDescription) {
    ScheduleColorsearch.description = this.searchDescription;
  }
   // console.log(ScheduleColorsearch);
  return ScheduleColorsearch;
}

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeScheduleColorSearch();
  }

   _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

import { Component, OnInit, ViewChild, Input, EventEmitter, Output, Optional } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { WPApiService } from "src/app/API/wpapi.service";
import { CanComponentDeactivate } from "src/app/Core/Shared/CanComponentDeactivate";
import { AttachmentComponent } from "src/app/Core/Shared/AttachmentComponent/AttachmentComponent";
import { NotesComponent } from "src/app/Core/Shared/NotesComponent/NotesComponent";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonCustomValidators } from "src/app/Validator/CommonValidator";
import { ScrollHelper } from "../../Helper/ScrollHelper";
import { ConfirmationDialog } from "../../ConfirmationDialog";
import { AppComponent } from "src/app/app.component";
import { AuthService } from "src/app/API/auth.service";
import { DMApiService } from "src/app/API/dmapi.service";
import { RPTApiService } from "src/app/API/rptapi.service";
import { attachmentFormModules, Globals, actionType } from "src/app/globals";
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  selector: "WaterProcessAnalysis",
  templateUrl: "./WaterProcessAnalysisComponent.html",
  styleUrls: ['./WaterProcessAnalysisComponent.css'],
})
export class WaterProcessAnalysisComponent implements OnInit {

  @ViewChild(AttachmentComponent, { static: false })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: false })
  notes: NotesComponent;

  @Input() FullScreen: boolean = true;
  @Output() SaveEvent = new EventEmitter<any>();

  public today = new Date();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  currentUserName = this.auth.GetUserFirstName();

  isValidFormSubmitted = null;
  ListURL = "WaterProcess/Analysis/AnalysisSearch";
  loading = true;
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = this.today;
  modifiedOn = this.today;
  blnshowSave = false;
  blnshowDelete = false;
  eventId = "[AutoNumber]";
  UserName = '';
  selectedDialysisUnitstext = '';
  collectionByName = '';
  collectionSentName = '';
  selectedDialysisUnitsobj = [];
  DialysisUnits = [];
  DialysisStations = [];
  UserList = [];
  data = {
    errormsg: ''
  }
  openNotes = '';
  selectedDialysisStationtext;

  WaterAnalysisForm = new UntypedFormGroup({
    waterProcessAnalysisId: new UntypedFormControl('0'),
    eventId: new UntypedFormControl(''),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    selectedDialysisStations: new UntypedFormControl('0', Validators.required),
    divisionUnitTechnologist: new UntypedFormControl(''),
    collectionDoneDate: new UntypedFormControl('', Validators.required),
    collectionDoneTime: new UntypedFormControl('', Validators.required),
    collectionDoneBy: new UntypedFormControl('', Validators.required),
    collectionSentDate: new UntypedFormControl('', Validators.required),
    collectionSentTime: new UntypedFormControl('', Validators.required),
    collectionSentBy: new UntypedFormControl('', Validators.required),
    receivedReportDate: new UntypedFormControl(''),
    receivedReportNumber: new UntypedFormControl(''),
    status: new UntypedFormControl(''),
    FormDate: new UntypedFormControl(''),
    result: new UntypedFormControl(''),
  });

  constructor(
    @Optional() public dialogWaterRef: MatDialogRef<WaterProcessAnalysisComponent>,
    private auth: AuthService,
    public dialog: MatDialog,
    private dmapi: DMApiService,
    private rptapi: RPTApiService,
    private wpApi: WPApiService,
    private route: ActivatedRoute,
    private router: Router,
    private commonapi: CommonApiService

  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();
    this.SetWaterAnalysisights();

    this.fillDialysisUnit();
    this.fillDialysisStation(0);
    this.fillUserList(0);
    this.UserName = this.auth.GetUserFirstName();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      this.setDateOptions();
      const test = this.route.queryParams.subscribe(params => {
        // Defaults to 0 if no query param provided.

      if (this.FullScreen)
        this.f.waterProcessAnalysisId.setValue(params['id'] || '0');

        let tempDate = this.today;
        this.f.FormDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });

        if (this.f.waterProcessAnalysisId.value !== '0') {
          this.setModel();
          AppComponent.HideLoader();
        }
        else {
          this.auth.GetCurrentUserDivision().subscribe(division => {
            if (division.length === 1) {
              this.f.selectedDialysisUnits.setValue(division[0]);
              this.fillDialysisStation(this.f.selectedDialysisUnits.value);
              this.fillUserList(this.f.selectedDialysisUnits.value);
            }
            AppComponent.HideLoader();
          }, error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          });
        }
      });

      // attachment changes


      this.setValidtors();
      this.setValidations('');
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  ngAfterViewInit() {
    if (this.attachment) {
      this.attachment.DocId = this.f.waterProcessAnalysisId.value;
      this.attachment.moduleName =
        attachmentFormModules[attachmentFormModules.WaterAnalysisReport];
      this.attachment.UploadBy = this.currentUserId;
    }
    if (this.notes) {
      this.notes.showHours = true;
    }
  }

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.WaterAnalysisForm.dirty || (this.notes && this.notes.Note) ||
        (this.attachment && this.attachment.Queue().length > 0)) &&
      (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }


  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };


  setDateOptions() {
    this.myOptions = {
      dateFormat: 'dd/mm/yyyy',
      closeSelectorOnDateSelect: false,
      disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
    };
  }

  onDateChanged(event: IMyDateModel): void { }

  oncollectionDateSChanged(event: IMyDateModel): void {
    this.f.collectionSentTime.setValue('');
    this.f.collectionSentTime.updateValueAndValidity();
  }

  oncollectionDateChanged(): void {
    this.f.collectionSentTime.setValue('');
    this.f.collectionSentDate.setValue('');
  }

  get f() {
    return this.WaterAnalysisForm.controls;
  }

  fillDialysisUnit() {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      // this.DialysisUnits = list.filter(
      //   x => x.text != "Home Program"
      // );
      this.commonapi.FillDivision().subscribe(list => {
        this.DialysisUnits = list;

      if (this.f.waterProcessAnalysisId.value != '0') {
        this.selectedDialysisUnitsobj = this.DialysisUnits.filter(
          x => x.value === this.f.selectedDialysisUnits.toString()
        );
        if (this.selectedDialysisUnitsobj.length > 0) {
          this.selectedDialysisUnitstext = this.selectedDialysisUnitsobj[0].text;
        }
      }
    });
  }

  setDialysisUnitAndStation(name, stationId, note, waterProcessAnalysisId) {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;
      var selectedDialysisUnitsobjTemp = this.DialysisUnits.filter(
        x => x.text === name
      );
      if (selectedDialysisUnitsobjTemp && selectedDialysisUnitsobjTemp.length > 0) {

        this.selectedDialysisUnitstext = selectedDialysisUnitsobjTemp[0].text;
        this.f.selectedDialysisUnits.setValue(selectedDialysisUnitsobjTemp[0].value);

        if (waterProcessAnalysisId && waterProcessAnalysisId != '0') {

          this.f.waterProcessAnalysisId.setValue(waterProcessAnalysisId);
          this.fillDialysisUnit();
          this.fillDialysisStation(this.f.selectedDialysisUnits.value);
          this.fillUserList(this.f.selectedDialysisUnits.value);
          this.setModel(true);
        }
        else {

          const tempDate = this.today;
          this.f.collectionDoneDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.status.setValue('Open');
          this.f.collectionDoneTime.setValue(this.addZeroBeforeHours(tempDate.getHours().toString()) + ":" + this.addZeroBeforeHours(tempDate.getMinutes().toString()));

          this.f.collectionDoneBy.setValue(this.currentUserId);
          this.DialysisUnitDropDownChanged();
          this.f.selectedDialysisStations.setValue(stationId);

          this.f.selectedDialysisUnits.disable();
          this.f.selectedDialysisStations.disable();
          this.f.collectionDoneTime.disable();
          this.f.collectionDoneDate.disable();
          this.f.collectionDoneBy.disable();
          this.openNotes = note;
        }
      }
    });
  }

  addZeroBeforeHours(n) {
    return (n < 10 ? '0' : '') + n;
  }

  DialysisUnitDropDownChanged() {
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    this.fillUserList(this.f.selectedDialysisUnits.value);
  }

  fillDialysisStation(divisionID) {
    if (divisionID !== 0) {
      if(this.FullScreen){
        this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
          this.DialysisStations = list;
        });
      } else {
        this.dmapi.GetDialyisStationDDAll(divisionID).subscribe(list => {
          this.DialysisStations = list;
        });
      }

    } else {
      this.DialysisStations = [{ value: '0', text: 'Select' }];
      this.f.selectedDialysisStations.setValue('0');
    }
  }

  getToday() {
    let tod = new Date(this.today);
    tod.setHours(0, 0, 0, 0);
    return tod;
  }

  getCurrentTime() {
      let tod = new Date(this.today);
      tod.setHours(59, 59, 59, 0);
      return tod;
  }

  getDisplayTime(val) {
    if (val)
      return new Date(val);
    else
      return this.today;
  }

  fillUserList(DivisionId) {
    if (DivisionId !== 0) {
      this.rptapi
        .GetUsersDDByDivisionID(DivisionId)
        .subscribe(list => {
          this.UserList = list;

          if (this.UserList.filter(x => x.value == this.currentUserId).length <= 0)
            this.UserList.push({ value: this.currentUserId.toString(), text: this.currentUserName });
        });

    } else {
      this.UserList = [{ value: '', text: 'Select' }];
      this.f.collectionSentBy.setValue('');
      this.f.collectionDoneBy.setValue('');
    }

  }

  SetWaterAnalysisights() {
    this.auth.GetPageRights("Water Analysis").subscribe(list => {
      if (this.f.waterProcessAnalysisId.value !== "0") {
        this.blnshowSave = list[actionType.edit] === "1";
        //this.blnshowDelete = list[actionType.delete] === "1";

        const accesToken = this.auth.getAccessToken();
        if (accesToken.DesignationName == "Administrator")
          this.blnshowDelete = true;
        else
          this.blnshowDelete = false;
      } else {
        this.blnshowSave = list[actionType.create] === "1";
        //this.blnshowDelete = false;
      }
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  setValidations(Source) {

    if (Source != 'Notes' &&
      ((this.f.receivedReportDate.value || this.f.result.value || this.f.receivedReportNumber.value)
        || this.f.status.value == 'In Progress')
    ) {
      this.f.receivedReportDate.setValidators([Validators.required,
      CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        this.f.collectionSentDate
      )
      ]);
      this.f.receivedReportDate.updateValueAndValidity();

      this.f.result.setValidators([Validators.required]);
      this.f.result.updateValueAndValidity();

      this.f.receivedReportNumber.setValidators([Validators.required]);
      this.f.receivedReportNumber.updateValueAndValidity();
    }
    else {
      this.f.receivedReportDate.setValidators([
        CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
          this.f.collectionSentDate
        )
      ]);
      this.f.receivedReportDate.updateValueAndValidity();

      this.f.result.clearValidators();
      this.f.result.updateValueAndValidity();

      this.f.receivedReportNumber.clearValidators();
      this.f.receivedReportNumber.updateValueAndValidity();
    }
  }

  executeAnalysisUpdate = function (Source = '') {
    AppComponent.loading = true;

    this.setValidations(Source)
    this.isValidFormSubmitted = false;
    if (this.WaterAnalysisForm.invalid && this.FullScreen) {
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
      this.data.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst("ng-invalid");
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const wpAnalysisCreate = this.prepareCreateViewModel();
    if (this.f.waterProcessAnalysisId.value === "0") {
      this.wpApi.CreateWaterProcessAnalysisRecord(wpAnalysisCreate).subscribe(
        success => {
          console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert("Data Saved successfully!", "success");
          if (Source != 'Notes' && this.FullScreen) {
            this.router.navigate([this.ListURL]);
          } else {

            this.SaveEvent.emit(success);

            if (this.dialogWaterRef)
              this.dialogWaterRef.close(true);

            this.f.waterProcessAnalysisId.setValue(success.waterProcessAnalysisId);
            this.setModel();

            AppComponent.HideLoader();
          }
        },
        error => {
          this.data.errormsg = error.message;
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    } else {
      this.wpApi.UpdateWaterProcessAnalysisRecord(wpAnalysisCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert("Data Saved successfully!", "success");
          if (Source != 'Notes' && this.FullScreen) {
            this.router.navigate([this.ListURL]);
          } else {
            this.SaveEvent.emit(success);

            if (this.dialogWaterRef)
              this.dialogWaterRef.close(true);

            this.f.waterProcessAnalysisId.setValue(success.waterProcessAnalysisId);
            this.setModel();
            AppComponent.HideLoader();
          }

        },
        error => {
          AppComponent.addAlert(error.message, "error");
          AppComponent.HideLoader();
        }
      );
    }
  };


  prepareCreateViewModel() {
    const analysisCreate = <any>{};

    analysisCreate.waterProcessAnalysisId = this.f.waterProcessAnalysisId.value;
    analysisCreate.eventId = this.f.eventId.value;
    analysisCreate.divisonId = this.f.selectedDialysisUnits.value;
    analysisCreate.dialysisStation = this.f.selectedDialysisStations.value;

    analysisCreate.divisionUnitTechnologist = this.UserName;

    if (this.f.collectionDoneDate.value) {
      analysisCreate.collectionDate = Globals.GetAPIDate(
        this.f.collectionDoneDate.value.jsdate
      );
    }

    if(this.f.collectionDoneTime.value){
      if(Date.parse(this.f.collectionDoneTime.value))
        analysisCreate.collectionDoneTime = this.f.collectionDoneTime.value.getHours() + ":" + this.f.collectionDoneTime.value.getMinutes();
      else
      analysisCreate.collectionDoneTime = this.f.collectionDoneTime.value;
    }

    analysisCreate.collectionDoneBy = this.f.collectionDoneBy.value;

    if (this.f.collectionSentDate.value) {
      analysisCreate.collectionSentOn = Globals.GetAPIDate(
        this.f.collectionSentDate.value.jsdate
      );
    }

    if(this.f.collectionSentTime.value)
      analysisCreate.collectionSentTime = this.f.collectionSentTime.value.getHours() + ":" + this.f.collectionSentTime.value.getMinutes();

    if (this.f.collectionSentBy.value != "" && this.f.collectionSentBy.value != "0")
      analysisCreate.collectionSentBy = this.f.collectionSentBy.value;

    analysisCreate.collectionStaff = this.f.collectionDoneBy.value;


    analysisCreate.CreatedBy = this.currentUserId;
    analysisCreate.CreatedOn = Globals.GetAPIDate(this.today);
    analysisCreate.ModifiedBy = this.currentUserId;
    analysisCreate.ModifiedOn = Globals.GetAPIDate(this.today);

    analysisCreate.WPAnalysis_Step = 'Collection';
    if (this.f.receivedReportDate.value && this.f.result.value && this.f.receivedReportNumber.value) {
      analysisCreate.reportNumber = this.f.receivedReportNumber.value;

      if (this.f.receivedReportDate.value) {
        analysisCreate.receiveddate = Globals.GetAPIDate(
          this.f.receivedReportDate.value.jsdate
        );
      }

      analysisCreate.result = this.f.result.value;
      analysisCreate.WPAnalysis_Step = 'Report';
    }

    analysisCreate.status = this.f.status.value;


    if (this.f.status.value == "Open")
      analysisCreate.notes = this.openNotes;
    else {
      if (this.notes)
        analysisCreate.notes = this.notes.Note;
    }
    if (this.attachment)
      analysisCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

      if(this.f.status.value == "Completed") {
        analysisCreate.WPAnalysis_Step = 'Notes';
      } else {
        if(this.f.waterProcessAnalysisId.value != 0 && analysisCreate.WPAnalysis_Step == 'Collection') {
          analysisCreate.WPAnalysis_Step = 'Notes';
        }
        else if(this.f.waterProcessAnalysisId.value == 0 && analysisCreate.WPAnalysis_Step == 'Report') {
          analysisCreate.WPAnalysis_Step = 'Analysis_Full';
        }
      }
    return analysisCreate;
  }

  setModel(disableForm = false) {
    AppComponent.ShowLoader();
    this.wpApi.GetWaterProcessAnalysisByID({ Id: this.f.waterProcessAnalysisId.value }).subscribe(
      success => {
        if (success) {

          if (this.attachment)
            this.attachment.clearQueue();

          this.eventId = success.eventId;
          this.UserName = success.createdByName;
          this.collectionByName = success.collectionByName;
          this.collectionSentName = success.collectionSentName;
          this.selectedDialysisUnitstext = success.divisionName;
          this.selectedDialysisStationtext = success.dialysisStationName;
          this.f.eventId.setValue(success.eventId);
          this.f.selectedDialysisUnits.setValue(success.divisonId);

          this.f.selectedDialysisStations.setValue(success.dialysisStation);

          if (success.status == "Open") {
            this.fillUserList(this.f.selectedDialysisUnits.value);
          }

          const tempFormDate = new Date(success.createdOn);

          this.f.FormDate.setValue({
            date: {
              year: tempFormDate.getFullYear(),
              month: tempFormDate.getMonth() + 1,
              day: tempFormDate.getDate()
            },
            jsdate: tempFormDate
          });

          const tempDate = new Date(success.collectionDate);
          this.f.collectionDoneDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: success.collectionDate
          });
          //
          if (success.collectionSentOn) {
            const tempDates = new Date(success.collectionSentOn);

            setTimeout(() => {
              this.f.collectionSentDate.setValue({
                date: {
                  year: tempDates.getFullYear(),
                  month: tempDates.getMonth() + 1,
                  day: tempDates.getDate()
                },
                jsdate: success.collectionSentOn
              });
            }, 100);
          }
          this.f.collectionDoneBy.setValue(success.collectionDoneBy);
          this.f.collectionSentBy.setValue(success.collectionSentBy);


          if (new Date(success.receiveddate) > new Date(2000, 1, 1)) {
            const tempDater = new Date(success.receiveddate);

            this.f.receivedReportDate.setValue({
              date: {
                year: tempDater.getFullYear(),
                month: tempDater.getMonth() + 1,
                day: tempDater.getDate()
              },
              jsdate: success.receiveddate
            });
          }
          var doneTime = new Date();
          if (success.collectionDoneTime) {
            doneTime.setHours(success.collectionDoneTime.split(':')[0])
            doneTime.setMinutes(success.collectionDoneTime.split(':')[1]);

            this.f.collectionDoneTime.setValue(doneTime);
          }

          var sentTime = new Date();
          if (success.collectionSentTime) {
            sentTime.setHours(success.collectionSentTime.split(':')[0])
            sentTime.setMinutes(success.collectionSentTime.split(':')[1]);

            this.f.collectionSentTime.setValue(sentTime);
          }

          this.f.receivedReportNumber.setValue(success.reportNumber);
          this.f.status.setValue(success.status);

          if (success.result)
            this.f.result.setValue(success.result);

          if (this.notes) {
            this.fillNotes();
            this.notes.Note = '';
          }

          if (this.attachment)
            this.fillAttachments();


          this.SetWaterAnalysisights();

          if (disableForm)
            setTimeout(() => this.WaterAnalysisForm.disable(), 150);



          this.setValidtors();
          this.setValidations('');
        }

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  fillNotes() {

    if (this.notes) {
      this.wpApi.GetWaterAnalsisNotes({ Id: this.f.waterProcessAnalysisId.value }).subscribe(list => {
        if (this.notes)
          this.notes.NoteList = list;

      });
    }
  }

  fillAttachments() {
    if (this.attachment) {
      this.attachment.clearQueue();
      this.wpApi
        .GetWaterAnalysisAttachments({ Id: this.f.waterProcessAnalysisId.value })
        .subscribe(list => {
          this.attachment.PrevAttachments = list;
        });
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const waterObj = { Id: this.f.waterProcessAnalysisId.value };
        this.wpApi.DeleteWaterProcessAnalysisRecord(waterObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Task Type detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  showLabel() {
    return this.f && this.f.waterProcessAnalysisId.value > 0;
  }

  setValidtors() {

      this.f.collectionSentDate.setValidators([
        CommonCustomValidators.CheckDateTimeGreaterValidation(
          this.f.collectionDoneDate, this.f.collectionSentDate
        ), Validators.required
      ]);
      this.f.collectionSentDate.updateValueAndValidity();

      this.f.collectionSentTime.setValidators([
        CommonCustomValidators.CheckDateTimeWPGreaterWithTimeValidation(
          this.f.collectionDoneDate, this.f.collectionDoneTime, this.f.collectionSentDate, this.f.collectionSentTime
        ), Validators.required
      ]);
      this.f.collectionSentTime.updateValueAndValidity();


    this.f.receivedReportDate.setValidators([
      CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        this.f.collectionSentDate
      )
    ]);
    this.f.receivedReportDate.updateValueAndValidity();
  }
}

export function padNumber(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  // export const MY_DATE_FORMATS = {
  //   parse: {
  //     dateInput: 'DD/MM/YYYY',
  //   },
  //   display: {
  //     dateInput: 'DD/MM/YYYY',
  //     monthYearLabel: 'MMM YYYY',
  //     dateA11yLabel: 'DD/MM/YYYY',
  //     monthYearA11yLabel: 'MMMM YYYY',
  //   },
  // };
<section class="content-header">
    <h1>
        {{title}}
    </h1>

</section>
<section class="content">
    <section>
        <div class="row">
            <div class="box  box-primary">
                <PageHeader [title]="title" 
                [(searchText)]="searchText"
                [isSearchOpen]="ShowSearch"
                (searchShowChange)="onSearchShowChange($event)"
                [showAddNew]=true [addurl]='AddUrl' #pageheader
                    [showMainListing]="false"></PageHeader>

                    <div
                    id="divSearch row"
                    *ngIf="ShowSearch"
                >

                    <div class="col-md-12 box ibox box-primary">
                        <div class="box-header">
                            <h3 class="box-title">Search Records</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Symbol:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" maxlength="1" [(ngModel)]="searchSymbol">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div>
                                            <label class="col-sm-6 control-label">Description:</label>
                                            <div class="col-sm-6">
                                                <input type="text" class="form-control" [(ngModel)]="searchDescription">
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>                      

                        <div class="box-body row">
                            <div class="col-sm-12">
                                <div class="col text-center">
                                    <button
                                        class="btn btn-primary"
                                        id="btnSearchDetail"
                                        (click)="executeScheduleColorSearch()"
                                    >
                                        <span>
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <b>Search</b>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="{{pageSize}}" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                    <div class="col text-left noofrecords">
                                        <span><b> <i>{{page.totalElements}}
                                                    {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b>
                                        </span>
                                        <span *ngIf="blnDirtySearch">
                                                <a style="cursor:pointer"
                                                    (click)="executeScheduleColorSearchClear()">
                                                    (<span><i class="fa fa-trash-o"></i></span>
                                                        <b>Clear Search</b>
                                                </a>)
                                        </span>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer"
                            role="grid">
                            <thead>
                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders"
                                        [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">
                                        {{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let sColorDetails of rows;let index=index;"
                                    (click)="_onRowClick(sColorDetails.scheduleColorId)" class="pointer">

                                    <td>
                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>{{sColorDetails.symbol}}</td>
                                    <!-- <td>{{sColorDetails.color}}</td> -->
                                    <td><div style="display: -webkit-inline-box;" [style.background-color]='sColorDetails.color'   [style.width.px]="170" [style.height.px]="25"   ></div></td>
                                    <td style="white-space:normal;">{{sColorDetails.description}}</td>
                                </tr>
                            </tbody>


                        </table>


                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="{{pageSize}}" [boundaryLinks]="true"
                                        [collectionSize]="page.totalElements" [(page)]="page.pageNumber"
                                        (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true">
                                    </ngb-pagination>

                                    
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}}
                                                {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b>
                                    </span>
                                    <span *ngIf="blnDirtySearch">
                                            <a style="cursor:pointer"
                                                (click)="executeScheduleColorSearchClear()">
                                                (<span><i class="fa fa-trash-o"></i></span>
                                                    <b>Clear Search</b>
                                            </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
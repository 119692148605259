import { Component, OnInit, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonCustomValidators } from "../../Validator/CommonValidator";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import { DMRepairCustomValidators } from '../../Validator/EuipmentRepairStatus';

import {
  Globals,
  actionType,

  attachmentFormModules
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { padNumber } from 'src/app/Core/Helper/utils';
// import { padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'BacteriaDetail',
  templateUrl: './BacteriaDetail.component.html',
  styles: [`
  .box {
    margin-bottom:0px;
    }
    .col-sm-5{
      padding-left:0px;
      padding-right:0px;
    }
  `],
})
export class BacteriaDetailComponent implements OnInit, CanComponentDeactivate {
  private today = new Date();

  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  isValidFormSubmitted = null;
  ListURL = 'DialysisMachine/BacteriaSearch';
  status = '';
  stage = 0;
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDialysisUnitstext;
  blnshowSave = false;
  blnshowDelete = false;
  EventCode = '[AutoNumber]';
  selectedWPEquipment;
  selectedDMEquipment;
  selectedStation;
  TestHours=0;
  //selectedPopulatetype='Dialysis Machines';
  errorAddTest='';
  selectedUsertext;
  CollectionByName = this.auth.GetUserFirstName();
  CollectionById=this.currentUserId;
  CollectionRetestByName;
  CollectionByRetestId;
  SampleReadByName;
  SampleReadById;
  SampleReadRetestByName;
  SampleReadByRetestId;
  FunnelStaffByName;
  FunnelStaffById;
  FunnelStaffByNameRetest;
  FunnelStaffByIdRetest;
  DMTestList = [];
  WPTestList = [];
  CDTestList = [];
  Detailtype = '';
  TestResultList = [
    { value: '', text: 'Select' }
  ];
  BacteriaForm = new UntypedFormGroup({
    BacteriasId: new UntypedFormControl(''),
    CreatedDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    CollectionDate: new UntypedFormControl('', Validators.required),
    CollectionTime: new UntypedFormControl('', [Validators.required,Validators.pattern(Globals.TimeRegex)]),
    CollectionRetestDate: new UntypedFormControl(''),
    CollectionRetestTime: new UntypedFormControl(''),
    SampleReadDate: new UntypedFormControl(''),
    SampleReadTime: new UntypedFormControl(''),
    SampleReadRetestDate: new UntypedFormControl(''),
    SampleReadRetestTime: new UntypedFormControl(''),
    FunnelLot: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    CassetteLot: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    FunnelLotRetest: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    CassetteLotRetest: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    selectedWPEquipment: new UntypedFormControl('', ),
    selectedDMEquipment: new UntypedFormControl('', ),
    selectedStation: new UntypedFormControl('',),
    TestHours: new UntypedFormControl('', [Validators.pattern(Globals.NumberRegex)]),
    selectedPopulatetype: new UntypedFormControl(''),
  });

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1 }
  };
  WPEquipments =  [];
  DMEquipments =  [];
  Stations =  [];
  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.BacteriaForm.dirty ||

        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private dmapi: DMApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) {}

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    // attachment changes
    this.attachment.DocId = this.f.BacteriasId.value;
    
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.GeneralBacteria];
    this.attachment.UploadBy = this.currentUserId;
    this.notes.showHours = false;
    this.fillDialysisUnit();
    this.fillInventoryList(this.f.selectedDialysisUnits.value,"Dialysis Machines");
    this.fillInventoryList(this.f.selectedDialysisUnits.value,"Water Process");
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);
    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.f.BacteriasId.setValue(params['id'] || '0');
      const tempDate = new Date();
      this.f.CreatedDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
      if (this.f.BacteriasId.value !== '0') {
        this.setModel();
      } else {
        this.selectedUsertext = this.auth.GetUserFirstName();
        //this.fillEquipment(0);
        this.f.selectedPopulatetype.setValue('Dialysis Machines');
        
        this.f.CollectionDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        this.f.CollectionTime.setValue(padNumber(tempDate.getHours()) + ":" + padNumber(tempDate.getMinutes()) );
      }
    });

    
    
    if (this.f.SampleReadDate != undefined && this.f.SampleReadDate != null) {
      this.f.SampleReadRetestDate.setValidators([Validators.required, CommonCustomValidators.CheckDateOutGreaterDateInInCustomValidation(
        this.f.SampleReadDate
      )]);
    }
    
    this.SetBacteriaights();
    AppComponent.HideLoader();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  get f() {
    return this.BacteriaForm.controls;
  }

  AddTest(){
    this.errorAddTest = '';
    switch (this.f.selectedPopulatetype.value) {
      case 'Dialysis Machines':
 

      if(this.f.selectedDMEquipment.value == '' && this.f.TestHours.value == '')
      {
        this.errorAddTest = 'Please enter equipment and hours';
      } else if (this.f.selectedDMEquipment.value == '') {
        this.errorAddTest = 'Please select the equipment from the list.';
      } else if (this.f.TestHours.value == '' || this.f.TestHours.value == '0') {
        this.errorAddTest = 'Please enter the hours.';
      } else {

        AppComponent.loading = true;
        var dmrepairCreate = {equipmentId:this.f.selectedDMEquipment.value,hoursin:this.f.TestHours.value,dmrepairdetailsid:0};
    // console.log(dmrepairCreate);
         this.dmapi.GetHoursOutOfPreviousRepair(dmrepairCreate).subscribe(
          success => {
            // console.log(success);
            AppComponent.loading = false;
              if(success == false)        
              { 
                this.errorAddTest = 'Please enter greater hours from previous hours';
                
              }
              else {
              
        
        const equi = this.DMEquipments.filter(
          // tslint:disable-next-line:triple-equals
          x => x.value == this.f.selectedDMEquipment.value
        );
        this.DMTestList.push( {
          bacteriaMachaineId: 0,
          bacteriaId: 0,
          equipmentId: this.f.selectedDMEquipment.value,
          sampleResult: '',
          sampleResultReset: '',
          dialysisMachineHours: this.f.TestHours.value,
          equipmentName: equi[0].text
        });
        const index = this.DMEquipments.findIndex(order => order.value 
          === this.f.selectedDMEquipment.value);
    this.DMEquipments.splice(index, 1);
    this.f.selectedDMEquipment.setValue('');
    this.f.TestHours.setValue('');
        }
  }
  
);
      //  this.errorAddTest = 'Added';
      } 

      break;
      case 'Water Process':
      if(this.f.selectedWPEquipment.value == '')
      {
        this.errorAddTest = 'Please select equipment from the list.';
      } else {
        const equi = this.WPEquipments.filter(
          // tslint:disable-next-line:triple-equals
          x => x.value == this.f.selectedWPEquipment.value
        );
        this.WPTestList.push( {
          bacteriaMachaineId: 0,
          bacteriaId: 0,
          equipmentId: this.f.selectedWPEquipment.value,
          sampleResult: '',
          sampleResultReset: '',
          equipmentName: equi[0].text
        });
        const index = this.WPEquipments.findIndex(order => order.value 
          === this.f.selectedWPEquipment.value);
    this.WPEquipments.splice(index, 1);
    this.f.selectedWPEquipment.setValue('');
    
       // this.errorAddTest = 'Added';
      } 
      break;
      case 'Central Delivery':
      if(this.f.selectedStation.value == '')
      {
        this.errorAddTest = 'Please select station from the list.';
      } else {
        const station = this.Stations.filter(
          // tslint:disable-next-line:triple-equals
          x => x.value == this.f.selectedStation.value
        );
        this.CDTestList.push( {
          bacteriaDialysisStationsId: 0,
          bacteriaId: 0,
          dialysisStationId: this.f.selectedStation.value,
          sampleResult: '',
          sampleResultReset: '',
          stationname: station[0].text
        });
        const index = this.Stations.findIndex(order => order.value 
          === this.f.selectedStation.value);
    this.Stations.splice(index, 1);
    this.f.selectedStation.setValue('');
     //   this.errorAddTest = 'Added';
      } 
      break;
      default:
      break;
    }
  }

  setModel() {
    AppComponent.ShowLoader();
    const BacteriaCreate = { Id: this.f.BacteriasId.value };
    this.attachment.clearQueue();
    this.notes.Note = '';
    this.dmapi.GetBacteriaDetailsByID(BacteriaCreate).subscribe(
      success => {
         // console.log(success);
         this.EventCode = success.eventId;
        let tempDate = new Date(success.createdOn);

        this.f.CreatedDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdOn
        });
        if(success.collectionDate != null || success.collectionDate !='')
        {
        tempDate = new Date(success.collectionDate);
        this.f.CollectionDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        this.f.CollectionTime.setValue( padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
      }

      if(success.collectionRetestDate != null || success.collectionRetestDate !='')
        {
        tempDate = new Date(success.collectionRetestDate);
        if(tempDate.getFullYear() > 2000)
        {
        this.f.CollectionRetestDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        this.f.CollectionRetestTime.setValue( padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
      }
      }
      this.CollectionByRetestId = success.collectionRetestBy;
      this.CollectionRetestByName = success.collectionretestenteredbyName;
      if(success.sampleRetestReadDate != null || success.sampleRetestReadDate !='')
        {
        tempDate = new Date(success.sampleRetestReadDate);
        if(tempDate.getFullYear() > 2000)
        {
        this.f.SampleReadRetestDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });
        this.f.SampleReadRetestTime.setValue( padNumber(tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
      }
      }
      this.SampleReadRetestByName = success.sampleretestreadbyName;
      this.SampleReadByRetestId = success.sampleRetestReadBy;
      
      if(success.sampleReadDate != null || success.sampleReadDate !='')
        {
        tempDate = new Date(success.sampleReadDate);
        // console.log(tempDate);
        if(tempDate.getFullYear() > 2000)
        {
        this.f.SampleReadDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: tempDate
        });

        this.f.SampleReadTime.setValue(padNumber( tempDate.getHours()) + ':' + padNumber(tempDate.getMinutes()));
      }
      }
      this.SampleReadById = success.sampleReadBy;
      this.SampleReadByName = success.samplereadbyName;

        //this.EventCode = success.eventcode;
        this.selectedDialysisUnitstext = success.divisionName;
        
        this.Detailtype = success.detailsType;
        //this.selectedUsertext = success.createByName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.divisionId.toString());
        this.selectedDialysisUnitstext = success.divisionName;
        this.stage = success.stage;
        if(success.status != 'Failure' && success.status != "Completed")
        {
          this.stage = this.stage +1;
        }
        // console.log(this.stage);
        this.f.FunnelLot.setValue(success.funnelLotNumber);
        this.f.CassetteLot.setValue(success.cassetteLotNumber);
        this.f.FunnelLotRetest.setValue(success.funnelRetestLotNumber);
        this.f.CassetteLotRetest.setValue(success.cassetteRetestLotNumber);
        this.FunnelStaffById = success.funnelReadby;
        this.FunnelStaffByName = success.funnelReadbyName;
        this.FunnelStaffByIdRetest = success.funnelReadretestby;
        this.FunnelStaffByNameRetest = success.funnelReadretestbyName;
        const BacteriaObj = { Id: this.f.BacteriasId.value };
        if(this.stage==1) {
          this.FunnelStaffById = this.currentUserId;
          this.FunnelStaffByName = this.auth.GetUserFirstName();
          this.f.FunnelLot.setValidators([
            Validators.required,Validators.pattern(Globals.NumberRegex)
          ]);
          this.f.FunnelLot.updateValueAndValidity();
          this.f.CassetteLot.setValidators([
            Validators.required,Validators.pattern(Globals.NumberRegex)
          ]);
          this.f.CassetteLot.updateValueAndValidity();
        } else if (this.stage == 2) {
          this.SampleReadById = this.currentUserId;
          this.SampleReadByName = this.auth.GetUserFirstName();
          // set validation
          this.f.SampleReadDate.setValidators([
            Validators.required
          ]);
          this.f.SampleReadDate.updateValueAndValidity();
          this.f.SampleReadTime.setValidators([
            Validators.required,Validators.pattern(Globals.TimeRegex)
          ]);
          this.f.SampleReadTime.updateValueAndValidity();

          //set date time
          tempDate = new Date();
          
          this.f.SampleReadDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.SampleReadTime.setValue(padNumber(tempDate.getHours()) + ":" + padNumber(tempDate.getMinutes()) );
          
          this.TestResultList.push({
            text: '< 10',
            value: '< 10'
          });
          this.TestResultList.push({
            text: 'TNTC',
            value: 'TNTC'
          });
          while (this.TestResultList.length < 92) {
            this.TestResultList.push({
              text: (this.TestResultList.length + 9).toString(),
              value: (this.TestResultList.length + 9).toString()
            });
          }
        } else if(this.stage==3) {
          this.FunnelStaffByIdRetest = this.currentUserId;
          this.FunnelStaffByNameRetest = this.auth.GetUserFirstName();

          //set validation
          this.f.FunnelLotRetest.setValidators([
            Validators.required,Validators.pattern(Globals.NumberRegex)
          ]);
          this.f.FunnelLotRetest.updateValueAndValidity();
          this.f.CassetteLotRetest.setValidators([
            Validators.required,Validators.pattern(Globals.NumberRegex)
          ]);
          this.f.CassetteLotRetest.updateValueAndValidity();

          this.f.CollectionRetestDate.setValidators([
            Validators.required
          ]);
          this.f.CollectionRetestDate.updateValueAndValidity();
          this.f.CollectionRetestTime.setValidators([
            Validators.required,Validators.pattern(Globals.TimeRegex)
          ]);
          this.f.CollectionRetestTime.updateValueAndValidity();
          
          //set date time
          tempDate = new Date();
          this.f.CollectionRetestDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.CollectionRetestTime.setValue(padNumber(tempDate.getHours())+ ":" + padNumber(tempDate.getMinutes()) );
          this.CollectionRetestByName =  this.auth.GetUserFirstName();
          this.CollectionByRetestId = this.currentUserId;
        } else if (this.stage == 4) {

          this.SampleReadByRetestId = this.currentUserId;
          this.SampleReadRetestByName = this.auth.GetUserFirstName();

          // set validation
          this.f.SampleReadRetestDate.setValidators([
            Validators.required
          ]);
          this.f.SampleReadRetestDate.updateValueAndValidity();
          this.f.SampleReadRetestTime.setValidators([
            Validators.required,Validators.pattern(Globals.TimeRegex)
          ]);
          this.f.SampleReadRetestTime.updateValueAndValidity();

          tempDate = new Date();
          // console.log(tempDate);
          this.f.SampleReadRetestDate.setValue({
            date: {
              year: tempDate.getFullYear(),
              month: tempDate.getMonth() + 1,
              day: tempDate.getDate()
            },
            jsdate: tempDate
          });
          this.f.SampleReadRetestTime.setValue(padNumber(tempDate.getHours()) + ":" + padNumber(tempDate.getMinutes()) );
          this.TestResultList.push({
            text: '< 10',
            value: '< 10'
          });
          this.TestResultList.push({
            text: 'TNTC',
            value: 'TNTC'
          });
          while (this.TestResultList.length < 92) {
            this.TestResultList.push({
              text: (this.TestResultList.length + 9).toString(),
              value: (this.TestResultList.length + 9).toString()
            });
          }
        }


        this.dmapi
          .GetMachineListByBacteriaID(BacteriaObj)
          .subscribe(list => {
            this.DMTestList = list;
          });
          this.dmapi
          .GetWaterMachineListByBacteriaID(BacteriaObj)
          .subscribe(list => {
            this.WPTestList = list;
          });
          this.dmapi
          .GetStationListByBacteriaID(BacteriaObj)
          .subscribe(list => {
            this.CDTestList = list;
          });
        this.dmapi
          .GetAttachmentDetailsbyBacteriaid(BacteriaObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });
          this.dmapi
            .GetNotesbyBacteriaId(BacteriaObj)
            .subscribe(list => {
              this.notes.NoteList = list;
            });
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  Populatetypess = [
    { value: 'Dialysis Machines', text: 'Dialysis Machines' },
    { value: 'Water Process', text: 'Water Process' },
    { value: 'Central Delivery', text: 'Central Delivery' },
    
  ];
  onDateChanged(event: IMyDateModel): void {}

  CheckSampleValue() {
    let result = true;
    this.DMTestList.forEach(function (value) {
      
      if (value.sampleResult == '') {
        result =false;
      }
    }); 
    this.WPTestList.forEach(function (value) {
      if (value.sampleResult == '') {
        result =false;
      }
    }); 
    this.CDTestList.forEach(function (value) {
      if (value.sampleResult == '') {
        result =false;
      }
    }); 
    return result;
  }

  CheckSamleResetValue() {

    let result = true;
    this.DMTestList.forEach(function (value) {
      
      if (value.sampleResultReset == '') {
        result =false;
      }
    }); 
    this.WPTestList.forEach(function (value) {
      if (value.sampleResultReset == '') {
        result =false;
      }
    }); 
    this.CDTestList.forEach(function (value) {
      if (value.sampleResultReset == '') {
        result =false;
      }
    }); 
    return result;
  }

  executeBacteriaCreateUpdate = function(Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;
    
    if (this.BacteriaForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }

    if(this.DMTestList.length == 0 && this.WPTestList.length == 0  && this.CDTestList.length == 0 )
    {
      AppComponent.addAlert('Please add atleast one item to the test result.', 'error');
      AppComponent.loading = false;
      return;
    }
    if ( this.stage == 2 && this.CheckSampleValue() == false) {
      AppComponent.addAlert('Please select value for the each test result.', 'error');
      AppComponent.loading = false;
      return;
    }

    if ( this.stage == 4 && this.CheckSamleResetValue() == false) {
      AppComponent.addAlert('Please select value for the each test result (Reset).', 'error');
      AppComponent.loading = false;
      return;
    }

    this.isValidFormSubmitted = true;

    const BacteriaCreate = this.prepareCreateViewModel();
    // if (BacteriaCreate.BacteriaId === '0') {
      this.dmapi.CreateUpdateBacteria(BacteriaCreate).subscribe(
        success => {
          // console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.f.BacteriasId.setValue(
              success.bacteriaid
            );
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    // } else {
    //   this.dmapi.CreateUpdateBacteria(BacteriaCreate).subscribe(
    //     success => {
    //      // this.DMRepailDetail = success.dmRepailDetails;
    //       AppComponent.loading = false;
    //       AppComponent.addAlert('Data Saved successfully!', 'success');
    //       if (Source !== 'Notes') {
    //         this.router.navigate([this.ListURL]);
    //       } else {
            
    //         this.setModel();
    //       }
    //     },
    //     error => {
    //       AppComponent.addAlert(error.message, 'error');
    //       AppComponent.HideLoader();
    //     }
    //   );
    // }
  };

  prepareCreateViewModel() {
    const BacteriaCreate = <any>{};

    BacteriaCreate.BacteriaId = this.f.BacteriasId.value;
    BacteriaCreate.detailstype = this.Detailtype;
    BacteriaCreate.createdon = new Date();
    BacteriaCreate.createdby = this.currentUserId;
    BacteriaCreate.currentUser = this.currentUserId;
    BacteriaCreate.modifiedon = new Date();
    BacteriaCreate.modifiedby = this.currentUserId;
    BacteriaCreate.funnelReadretestby = this.FunnelStaffByIdRetest;
    BacteriaCreate.cassetteretestlotnumber = this.f.CassetteLotRetest.value;
    BacteriaCreate.funnelReadby = this.FunnelStaffById;
    BacteriaCreate.sampleretestreadby = this.SampleReadByRetestId;
    BacteriaCreate.samplereadby = this.SampleReadById;
    BacteriaCreate.stage = this.stage;  
    if (this.f.CollectionDate.value !== null && this.f.CollectionDate.value !== undefined) {
      let dt = this.f.CollectionDate.value.jsdate;
     // console.log(dt);
      if(this.f.CollectionTime.value != ''){
        const splitted = this.f.CollectionTime.value.split(":"); 
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.collectiondate = Globals.GetAPIDate( dt);
    }
    if (this.f.CollectionRetestDate.value !== null && this.f.CollectionRetestDate.value !== undefined) {
      let dt = this.f.CollectionRetestDate.value.jsdate;
      if(this.f.CollectionRetestTime.value != ''){
        const splitted = this.f.CollectionRetestTime.value.split(":"); 
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.collectionretestdate = Globals.GetAPIDate( dt);
    }
    if (this.f.SampleReadDate.value !== null && this.f.SampleReadDate.value !== undefined) {
      let dt = this.f.SampleReadDate.value.jsdate;
      if(this.f.SampleReadTime.value != ''){
        const splitted = this.f.SampleReadTime.value.split(":"); 
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.samplereaddate = Globals.GetAPIDate(dt);
    }
    if (this.f.SampleReadRetestDate.value !== null && this.f.SampleReadRetestDate.value !== undefined) {
      let dt = this.f.SampleReadRetestDate.value.jsdate;
      if(this.f.SampleReadRetestTime.value != ''){
        const splitted = this.f.SampleReadRetestTime.value.split(":"); 
        dt.setHours(splitted[0]);
        dt.setMinutes(splitted[1]);
      }
      BacteriaCreate.sampleretestreaddate = Globals.GetAPIDate(dt);
      
    }
    // BacteriaCreate.samplereaddate = this.f.samplereaddate.value;
    // BacteriaCreate.sampleretestreaddate = this.f.SampleReadRetestDate.value;
    // BacteriaCreate.collectionretestdate = this.f.collectionretestdate.value;
    // BacteriaCreate.collectiondate = this.f.collectiondate.value;

    BacteriaCreate.cassettelotnumber = this.f.CassetteLot.value;
    BacteriaCreate.funnelretestlotnumber = this.f.FunnelLotRetest.value;
    BacteriaCreate.funnellotnumber = this.f.FunnelLot.value;
    BacteriaCreate.collectionretestby = this.CollectionByRetestId;
    BacteriaCreate.collectionby = this.CollectionById;
    BacteriaCreate.divisionid = this.f.selectedDialysisUnits.value;
    BacteriaCreate.eventid = this.EventCode;
    BacteriaCreate.machaineList = [];
    this.DMTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push(
        {
          bacteriaMachaineId: value.bacteriaMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          Type: 'dm',
          dialysisMachineHours: value.dialysisMachineHours
        }
      );
    }); 
    this.WPTestList.forEach(function (value) {
      BacteriaCreate.machaineList.push(
        {
          bacteriaMachaineId: value.bacteriaMachaineId,
          equipmentId: value.equipmentId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          Type: 'water'
        }
      );
    });
    BacteriaCreate.stationsList =[];
    this.CDTestList.forEach(function (value) {
      BacteriaCreate.stationsList.push(
        {
          bacteriaDialysisStationsId: value.bacteriaDialysisStationsId,
          dialysisStationId: value.dialysisStationId,
          sampleResult: value.sampleResult,
          sampleResultReset: value.sampleResultReset,
          Type: 'Central Delivery'
        }
      );
    });
    BacteriaCreate.notes = this.notes.Note;
    // attachments
    BacteriaCreate.attachmentDetails = this.attachment.GenerateAttachmentList();
   //  console.log(BacteriaCreate);
    return BacteriaCreate;
  }

  DialysisUnitDropDownChanged() {
    this.fillInventoryList(this.f.selectedDialysisUnits.value,"Dialysis Machines");
    this.fillInventoryList(this.f.selectedDialysisUnits.value,"Water Process");
    this.fillDialysisStation(this.f.selectedDialysisUnits.value);
  }

  fillDialysisStation(divisionID) {
    if (divisionID !== 0) {
      this.dmapi.GetDialyisStationDD(divisionID).subscribe(list => {
        this.Stations = list;
      });
    } else {
      this.selectedStation = [{ value: '', text: 'Select' }];
      this.f.selectedDialysisStation.setValue('');
    }
  }

  fillDialysisUnit() {
    this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.DialysisUnits = list;
    });
  }

  fillInventoryList(DivisionId, Category) {
    const value = {
      DivisionId: DivisionId,
      CategoryName: Category,
      Type: 'Equipment'
    };
    
    this.dmapi.GetEquipmentByCategoryDD(value).subscribe(
      list => {
        if(Category=="Water Process") {
        this.WPEquipments = list;
        } else {
          this.DMEquipments = list;
        }
      }
    );
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const BacteriaObj = { Id: this.f.BacteriasId.value };
        this.dmapi.DeleteBacteriaByID(BacteriaObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Bacteria detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetBacteriaights() {
    this.auth.GetPageRights('DM Bacteria').subscribe(list => {
      if (this.f.BacteriasId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }

  UpdateSampleValue(event){
   //  console.log(event,event.target.name,event.target.value);
    const tempname = event.target.name;
    const name = tempname.split("_");
    if(name[1]=="dm") {
     const index = this.DMTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex =  name[0].indexOf("retest");
      if(retestIndex <= 0)
      {
        this.DMTestList[index].sampleResult = event.target.value;
      }
      else
      {
        this.DMTestList[index].sampleResultReset = event.target.value;
      }

    } else if(name[1]=="wp") {
      const index = this.WPTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaMachaineId == name[2]
      );
      const retestIndex =  name[0].indexOf("retest");
      if(retestIndex <= 0)
      {
        this.WPTestList[index].sampleResult = event.target.value;
      }
      else
      {
        this.WPTestList[index].sampleResultReset = event.target.value;
      }
    } else {
      const index = this.CDTestList.findIndex(
        // tslint:disable-next-line:triple-equals
        x => x.bacteriaDialysisStationsId == name[2]
      );
      const retestIndex =  name[0].indexOf("retest");
     
      if(retestIndex <= 0)
      {
        this.CDTestList[index].sampleResult = event.target.value;
      }
      else
      {
        this.CDTestList[index].sampleResultReset = event.target.value;
      }
    }

  }
  
  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  pad(n) {
    return (n < 10) ? ("0" + n) : n;
}
}

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable()
export class AuthService {


    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}
    // UserLogin(userName, token, tempTokenTime, IsEncrypted){
    //    var form = {UserName:userName, jwtToken:token, jwtTokenExpiredTime:tempTokenTime, isEncrypted:IsEncrypted}
    //   return  this.http.post<any>(this.apiUrl + 'AccountService/UserLogin', form);
    // }

    UserLogin(userName, Password, token, tempTokenTime, IsEncrypted){
      var form = {UserName:userName, Password:Password, jwtToken:token, jwtTokenExpiredTime:tempTokenTime, isEncrypted:IsEncrypted}
     return  this.http.post<any>(this.apiUrl + 'AccountService/UserLogin', form);
   }

    GetEncryptedDetailForForum(){
     return  this.http.post<any>(this.apiUrl + 'AccountService/GetEncryptedDetailForForum',"");
   }

    GetPasswordLoggedInUser()
    {
      return  this.http.post<any>(this.apiUrl + 'AccountService/GetPasswordLoggedInUser', "");
    }
    tokenKey="token";
    // this.authService.UserLogin("priyank80@me.com").subscribe(list =>
    //     {
    //       console.log(list);
    //       sessionStorage.setItem('token',list.token)
    //     }
    //   );
    GetMenus(ActiveUserID,designationId,designationName,CurruntPage){
      var form = {activeUserID:ActiveUserID,DesignationId:designationId,DesignationName:designationName,curruntPage:CurruntPage}
     return  this.http.post<any>(this.apiUrl + 'AccountService/GetMenus', form);
   }

    GetPageRights(menuName)
    {
      var obj = {str:menuName}
      //return "00000";
      return  this.http.post<any>(this.apiUrl + 'AccountService/GetMenusRights', obj);
    }

    ValidateConfirmationUser(credential) {
      return  this.http.post<any>(this.apiUrl + 'AccountService/ValidateConfirmationUser', credential);
    }

    GetCurrentUserDivision() {
      return  this.http.post<any>(this.apiUrl + 'UserService/GetContactDivisionsByContactID', {Id:this.GetUserId()});
    }


    GetDashBoardMVC(obj) {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/GetDashBoardMVC',
        obj,
        httpOptions
      );
    }

    GetTasksMonthly() {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/GetTasksMonthly',
        '',
        httpOptions
      );
    }

    GetRepairsMonthly() {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/GetRepairsMonthly',
        '',
        httpOptions
      );
    }

    GetDMRepairsMonthly() {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/GetDMRepairsMonthly',
        '',
        httpOptions
      );
    }

    CheckNotificationForTask() {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/CheckNotificationForTask',
        '',
        httpOptions
      );
    }

    InsertNoteForTaskNotification() {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/InsertNoteForTaskNotification',
        '',
        httpOptions
      );
    }

    GetScheduleDetailsSearchByUsers(obj) {
      return this.http.post<any>(
        this.apiUrl + 'DeskboardService/GetScheduleDetailsSearchByUsers',
        obj,
        httpOptions
      );
    }


    getToken() {
        return sessionStorage.getItem(this.tokenKey);
      }

      setSSOEmail(Email) {

        sessionStorage.setItem("UserEmailEnc",Email);
      }

      GetSSOEmail() {
        if(!this.isAuthenticated)
        {
          return "";
        }
        var token =sessionStorage.getItem("UserEmailEnc");
        if(token != undefined && token !=null )
        {
          return token;
        }
        else
        {
          return "";
        }

      }


      GetUrgentMessages(){

     return  this.http.post<any>(this.apiUrl + 'AccountService/GetUrgentMessages', "");
   }
   GetUrgentTasks(){

    return  this.http.post<any>(this.apiUrl + 'AccountService/GetUrgentTasks', "");
  }

      setToken(token) {
        sessionStorage.setItem(this.tokenKey, JSON.stringify(token));
      }

      getAccessToken() {
        var token =sessionStorage.getItem(this.tokenKey);
        if(token != undefined && token !=null)
        {
        return JSON.parse(sessionStorage.getItem(this.tokenKey))['access_token'];
        }
        else
        {
          return undefined;
        }
      }

      getAccessTokenExpiryDate() {
        var token =sessionStorage.getItem(this.tokenKey);
        if(token != undefined && token !=null)
        {
        return JSON.parse(sessionStorage.getItem(this.tokenKey))['exp'];
        }
        else
        {
          return null;
        }
      }

      GetUserId()
      {

        var token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return  token.ContactId;
        }
      }

      GetDesignationName()
      {
        let token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return  token.DesignationName;
        }
      }

      GetIsAdminPermission()
      {
        let token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return  token.IsAdminPermission;
        }
      }

      //Email
      GetUserEmail()
      {

        var token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return token.Email;
        }
      }
      //FullName
      GetUserName()
      {

        var token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return token.FullName.replace("<br/>", " ");

        }
      }
      GetUserFLName()
      {

        var token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return token.FirstName + ' ' + token.LastName;

        }
      }
      GetUserFirstName()
      {
        var token = this.getAccessToken();
        if(token != null || token != undefined)
        {
          return token.FirstName;
        }
      }

      isAuthenticated() {

        let token = sessionStorage.getItem(this.tokenKey);

        if (token) {
          var token1 = JSON.parse(token);

          if( new Date(token1.exp) < new Date())
          {
            this.removeToken();
            return false;
          }
          else
          {
            return true;
          }
        }
        else {
          return false;
        }
      }

      // refreshToken() {
        // var token = JSON.parse(sessionStorage.getItem(this.tokenKey));
        // token.exp = new Date((new Date().getDate() +1));
        // console.log(token.exp);
        //  this.setToken(token);
      // }

      removeToken() {
        sessionStorage.removeItem(this.tokenKey);
      }

      GetCurrentDateTimeFromServer(){
        return  this.http.post<any>(this.apiUrl + 'CommonService/GetCurrentDateTimeFromServer', "");
      }

}

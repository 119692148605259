import { Component, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DMApiService } from '../../API/dmapi.service';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'Calibrations-dialog',
  templateUrl: './CalibrationsDialog.html',
})
export class CalibrationsDialog {
  public EquipId:string;
  public MachineName:string;
  public completed =false;
  title="Bellco Calibrations Form";
  current="Machine";
  CurrnetType="";
  constructor(public dialogRef: MatDialogRef<CalibrationsDialog>,private dmapi:DMApiService) {
  }
  
  public CalibrationsData=[];
  SetCurrentType(type)
  {
    
    if(type==this.CurrnetType)
    {
      return false;
    }
    else
    {
      this.CurrnetType=type;
      return true;
    }
  }
  save()
  {
    for (let childObj in this.CalibrationsData){
      if(this.CalibrationsData[childObj].tempresult != "Not Tested")
      {
      this.CalibrationsData[childObj].result=this.CalibrationsData[childObj].tempresult;
      }
    }
    this.dialogRef.close(true);
  }
  ngOnInit()
  {
    
  }

}
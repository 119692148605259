import { Component, Input, Output, Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class MenuSharedService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
   constructor() {
     console.log('Menu Shared service started');
   }

   change() {
    console.log('Menus change started');
     this.fire.emit(true);
   }

   getEmittedValue() {
     return this.fire;
   }

     
} 
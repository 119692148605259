
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'


@Injectable()
export class ApiService {

    constructor(private http: HttpClient) {}

    SetUrl(URLs){
       
         this.http.post('http://localhost:16390/api/home/SetURL', URLs).subscribe(res => {
           // console.log(res)
        })
    }

    GetUrl(){
        
        this.http.post('http://localhost:16390/api/home/GetURL', "").subscribe(res => {
           // console.log(res);
            return(res);
        })
    }
    
}
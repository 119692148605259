<style>
 ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #3a4dab !important;
  }
  </style>

<form
    [formGroup]="contactDetailForm"
    (ngSubmit)="executeContactCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Contact
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader

                    [showNote]="false"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    [showEdit]="blnshowEdit"
                    (SaveEvent)="executeContactCreateUpdate('')"
                    (EditEvent)="executeEnableDisablectls(false)"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="clearfix"></div>
                <div class="box">
                  <div class="box-body">
                    <div class="box ibox box-info">
                        <!-- <div class="box-header with-border">
                            <h3 class="box-title">AM</h3>
                        </div> -->
                        <div class="box-body">
                            <div class="col-sm-12 box-row">
                              <div class='col-sm-6'>
                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Contact Type:</label>

                                <div class='col-sm-7'>
                                    <select  name='contactType' class='form-control'
                                        formControlName='contactType'  (change)="ContactTypeDropDownChanged()" >
                                        <option  value="{{item.value}}" *ngFor="let item of ContactTypeList">{{item.text}}</option>
                                    </select>
                                        <span *ngIf='f.contactType.errors?.required && (f.contactType.dirty || f.contactType.touched || isValidFormSubmitted==false)'
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf='f.contactType.errors?.pattern'
                                        [ngClass]="'error'">Invalid value</span>
                                </div>
                            </div>
                            <div class='col-sm-6'>
                              <label class='col-sm-5 control-label'>Contact ID:</label>

                              <div class='col-sm-7' >
                                <label class="readOnlyText"> {{contactnumber}}</label>
                              </div>
                          </div>

                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                              <!-- <div class='col-sm-6' *ngIf="f.contactType.value != 'Vendor' && f.contactType.value != 'Customer'">
                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Title:</label>

                                <div class='col-sm-7' >
                                    <select  name='title' maxlength='dropdown' class='form-control'
                                        formControlName='title' >
                                        <option  value="{{item.value}}" *ngFor="let item of TitleList">{{item.text}}</option>
                                    </select>
                                        <span *ngIf='f.title.errors?.required && (f.title.dirty || f.title.touched || isValidFormSubmitted==false)'
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf='f.title.errors?.pattern'
                                        [ngClass]="'error'">Invalid value</span>
                                </div>
                            </div> -->
                            <div class='col-sm-6'>
                            <!-- <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>{{f.contactType.value=="User"? "Given" : f.contactType.value}} Name:</label> -->
                            <label class='col-sm-5 control-label' *ngIf="f.contactType.value != 'Misc'"><span class='text-red inputrequired'>*</span>{{f.contactType.value == "Vendor"  ? "Vendor" : "First"}} Name:</label>
                            <label class='col-sm-5 control-label' *ngIf="f.contactType.value == 'Misc'"><span class='text-red inputrequired'>*</span>Misc. Name:</label>
                              <div class='col-sm-7' >
                                  <input  name='givenName' maxlength='30' class='form-control' style="text-transform: capitalize;"
                                      formControlName='givenName' ><span *ngIf='f.givenName.errors?.required && (f.givenName.dirty || f.givenName.touched || isValidFormSubmitted==false)'
                                      [ngClass]="'error'"> Required! </span>
                                  <span *ngIf='f.givenName.errors?.pattern'
                                      [ngClass]="'error'">Invalid value</span>
                              </div>
                            </div>

                            <div class="col-sm-6" *ngIf="f.contactType.value === 'User'">
                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Last Name:</label>
                                <div class='col-sm-7' >
                                    <input  name='familyName' maxlength='30' class='form-control' style="text-transform: capitalize;"
                                        formControlName='familyName' ><span *ngIf='f.familyName.errors?.required && (f.familyName.dirty || f.familyName.touched || isValidFormSubmitted==false)'
                                        [ngClass]="'error'"> Required! </span>
                                    <span *ngIf='f.familyName.errors?.pattern'
                                        [ngClass]="'error'">Invalid value</span>
                                </div>
                            </div>

                            <!-- <div class='col-sm-6'>
                              <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>{{f.contactType.value=="User"? "Given" : f.contactType.value}} Name:</label>

                              <div class='col-sm-7' >
                                  <input  name='givenName' maxlength='30' class='form-control' style="text-transform: capitalize;"
                                      formControlName='givenName' ><span *ngIf='f.givenName.errors?.required && (f.givenName.dirty || f.givenName.touched || isValidFormSubmitted==false)'
                                      [ngClass]="'error'"> Required! </span>
                                  <span *ngIf='f.givenName.errors?.pattern'
                                      [ngClass]="'error'">Invalid value</span>
                              </div>
                          </div> -->

                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                             <!-- <div class='col-sm-6' *ngIf="f.contactType.value != 'Vendor'">
                                        <label class='col-sm-5 control-label'>Family Name:</label>
                                        <div class='col-sm-7' *ngIf='f.contactid.value > 0'>
                                            <label class='readOnlyText'> {{familyName}}</label>
                                        </div>
                                        <div class='col-sm-7' >
                                            <input  name='familyName' maxlength='30' class='form-control'
                                                formControlName='familyName' name='familyName'><span *ngIf='f.familyName.errors?.required && (f.familyName.dirty || f.familyName.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.familyName.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>
                                    </div> -->
                                    <div class='col-sm-6' >

                                        <label class='col-sm-5 control-label' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'">Technical Rep.:</label>

                                        <div class='col-sm-7' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'" >
                                            <input  name='familyName' maxlength='30' class='form-control' style="text-transform: capitalize;"
                                                formControlName='familyName' ><span *ngIf='f.familyName.errors?.required && (f.familyName.dirty || f.familyName.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.familyName.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>
                                    </div>

                                    <!-- <div class='col-sm-6' *ngIf="f.contactType.value != 'Vendor' && f.contactType.value != 'Customer'">
                                      <label class='col-sm-5 control-label'>Middle Initials:</label>

                                      <div class='col-sm-7' >
                                          <input  name='miscName' maxlength='30' class='form-control'
                                              formControlName='miscName' ><span *ngIf='f.miscName.errors?.required && (f.miscName.dirty || f.miscName.touched || isValidFormSubmitted==false)'
                                              [ngClass]="'error'"> Required! </span>
                                          <span *ngIf='f.miscName.errors?.pattern'
                                              [ngClass]="'error'">Invalid value</span>
                                      </div>
                                  </div> -->

                                  <div class='col-sm-6' *ngIf="(f.contactType.value == 'Vendor' || f.contactType.value == 'Misc')  && f.contactType.value != 'Customer'">
                                        <label class='col-sm-5 control-label'>Sales Rep.:</label>

                                        <div class='col-sm-7' >
                                            <input  name='miscName' maxlength='30' class='form-control'
                                                formControlName='miscName' ><span *ngIf='f.miscName.errors?.required && (f.miscName.dirty || f.miscName.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.miscName.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6' *ngIf="f.contactType.value == 'Customer' && (f.contactType.value != 'Vendor' || f.contactType.value != 'Misc') ">
                                            <label class='col-sm-5 control-label'>Sales Rep.:</label>

                                            <div class='col-sm-7' >
                                                <input  name='miscName' maxlength='30' class='form-control'
                                                    formControlName='miscName' ><span *ngIf='f.miscName.errors?.required && (f.miscName.dirty || f.miscName.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.miscName.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>


                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row" *ngIf="f.contactType.value == 'Patient'">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Patient Doctor:</label>

                                        <div class='col-sm-7' >
                                            <input  name='patientdoctor' maxlength='30' class='form-control'
                                                formControlName='patientdoctor' ><span *ngIf='f.patientdoctor.errors?.required && (f.patientdoctor.dirty || f.patientdoctor.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.patientdoctor.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Patient Home Hospital:</label>

                                            <div class='col-sm-7' >
                                                <input  name='patienthomehospital' maxlength='30' class='form-control'
                                                    formControlName='patienthomehospital' ><span *ngIf='f.patienthomehospital.errors?.required && (f.patienthomehospital.dirty || f.patienthomehospital.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.patienthomehospital.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-sm-12 box-row">
                              <div class="col-sm-12">
                                <label class='col-sm-2 control-label' style="width: 20.30%;"><span class='text-red inputrequired'>*</span>Division:</label>

                                <div class='col-sm-9' style="width: 79.70%;">
                                <mat-button-toggle-group multiple formControlName="ALLdivisionId">
                                        <mat-button-toggle (change)="SetAllButtons($event)" value="all">{{allClearValue}}</mat-button-toggle>
                                </mat-button-toggle-group>

                                <mat-button-toggle-group multiple formControlName="divisionId" name="divisionId" style="margin-left:2%">
                                    <mat-button-toggle *ngFor="let item of DivisionList" style="border:none;"  value="{{item.value}}" (change)="CheckUncheckAll($event)">
                                        <mat-checkbox [checked]="isChecked(item.value)" disabled="false"></mat-checkbox> {{item.text}}
                                    </mat-button-toggle>
                                  </mat-button-toggle-group>

                                  <span *ngIf='f.divisionId.errors?.required && (f.divisionId.dirty || f.divisionId.touched || isValidFormSubmitted==false)'
                                  [ngClass]="'error'"> Required! </span>

                                  <span *ngIf='f.divisionId.errors?.pattern'
                                  [ngClass]="'error'">Invalid value</span>


                            </div>
                          </div>
                         </div>
                         <div class="col-sm-12 box-row">
                          <div class="col-sm-12">
                            <label class="control-label col-sm-2" style="    width: 20.30%;" *ngIf="f.contactType.value == 'User' && isHomeProgram">Default Tasks - Home:</label>
                            <mat-button-toggle-group class="col-sm-8" value="" formControlName='defaultTaskHome'
                             name="defaultTaskHome" form #tasktypegroup="matButtonToggleGroup" *ngIf="f.contactType.value == 'User' && isHomeProgram">
                            <mat-button-toggle value="true" class="btnsuccess" style="    border: 1px solid black;">{{onYes}}</mat-button-toggle>
                            <mat-button-toggle class="btndanger" value="false" style="    border: 1px solid black;">{{offNo}}</mat-button-toggle>
                          </mat-button-toggle-group>
                          </div></div>
                             <div class="clearfix"></div>
                            <div class="col-sm-12 box-row" *ngIf="f.contactType.value == 'User'">

                                <div class='col-sm-6'>

                                        <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Status  :</label>

                                        <div class='col-sm-7' >
                                            <select  name='status' maxlength='dropdown' class='form-control'
                                                formControlName='status' >
                                                <option  value="{{item.value}}" *ngFor="let item of StatusList">{{item.text}}</option>

                                        </select>
                                            <span *ngIf='f.status.errors?.required && (f.status.dirty || f.status.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.status.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>


                                                            </div>
                                                            <div class='col-sm-6' *ngIf="f.contactType.value == 'User'">
                                                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>User Designation:</label>

                                                                <div class='col-sm-7' >
                                                                    <select  name='designationId' maxlength='dropdown' class='form-control'
                                                                        formControlName='designationId' (change)="designationIdDropDownChanged()"  >
                                                                        <option  value="{{item.value}}" *ngFor="let item of DesignationList">{{item.text}}</option>
                                                                      </select>
                                                                        <span *ngIf='f.designationId.errors?.required && (f.designationId.dirty || f.designationId.touched || isValidFormSubmitted==false)'
                                                                        [ngClass]="'error'"> Required! </span>
                                                                    <span *ngIf='f.designationId.errors?.pattern'
                                                                        [ngClass]="'error'">Invalid value</span>
                                                                </div>
                                                            </div>





                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row" *ngIf="LoggedinUserDesignationID == 'Administrator' && f.contactType.value == 'User'">

                                <div class='col-sm-6' >
                                    <!-- <label class='col-sm-5 control-label'>Allow Staff Schedule Edit:</label>

                                    <div class='col-sm-7' >
                                        <input type="checkbox"  name='allowScheduleEdit' maxlength='checkbox' class="iCheck-helper"
                                            formControlName='allowScheduleEdit'><span *ngIf='f.allowScheduleEdit.errors?.required && (f.allowScheduleEdit.dirty || f.allowScheduleEdit.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.allowScheduleEdit.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div> -->
                                    <div class="clearfix"></div>
                                    <label class='col-sm-5 control-label' >Allow Tech Schedule Edit:</label>

                                    <div class='col-sm-7' >
                                        <input type="checkbox"  name='AllowTechScheduleEdit' maxlength='checkbox' class="iCheck-helper"
                                            formControlName='AllowTechScheduleEdit' ><span *ngIf='f.AllowTechScheduleEdit.errors?.required && (f.AllowTechScheduleEdit.dirty || f.AllowTechScheduleEdit.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.AllowTechScheduleEdit.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div>

                                <div class='col-sm-6' >
                                    <label class='col-sm-5 control-label'>Schedule UserType:</label>

                                    <div class='col-sm-7' >

                                        <select  name='scheduleusertypeid' maxlength='dropdown' class='form-control'
                                            formControlName='scheduleusertypeid'>
                                            <option  value="{{item.value}}" *ngFor="let item of ScheduleUserTypeList">{{item.text}}</option>
                                        </select><span *ngIf='f.scheduleusertypeid.errors?.required && (f.scheduleusertypeid.dirty || f.scheduleusertypeid.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.scheduleusertypeid .errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div>

                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row" *ngIf="f.contactType.value == 'User'">


                                <div class='col-sm-6'>
                                    <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Job Status:</label>

                                    <div class='col-sm-7' >
                                        <select  name='jobstatus' maxlength='dropdown' class='form-control'
                                            formControlName='jobstatus' >
                                            <option  value="" >Select</option>
                                              <option  value="{{item.text}}" *ngFor="let item of JobStatusList">{{item.text}}</option>
                                           </select>
                                            <span *ngIf='f.jobstatus.errors?.required && (f.jobstatus.dirty || f.jobstatus.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.jobstatus.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div>

                                <div class='col-sm-6'>
                                    <label class='col-sm-5 control-label'>Signature:</label>

                                    <div class='col-sm-7' >
                                      <singleattachment (RaiseError)="AttachmentError($event)" [isUploadDisabled]="f.givenName.disabled" #attachment>

                                      </singleattachment>
                                    </div>
                                </div>

                            </div>
                            <div class="clearfix"></div>
                            <div class="col-sm-12 box-row" *ngIf="LoggedinUserDesignationID == 'Administrator' && f.contactType.value == 'User'">
                                <div class="col-sm-6">
                                    <label class="col-sm-5 control-label">Allow Tech User Edit:</label>
                                    <div class="col-sm-7">
                                        <input type="checkbox"  name='IsAdminPermission' maxlength='checkbox' class="iCheck-helper"
                                            formControlName='IsAdminPermission' ><span *ngIf='f.IsAdminPermission.errors?.required && (f.IsAdminPermission.dirty || f.IsAdminPermission.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.IsAdminPermission.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div>

                                <!-- <div class='col-sm-6'>
                                    <label class='col-sm-5 control-label'>Admin Permission:</label>

                                    <div class='col-sm-7' >
                                        <ng-multiselect-dropdown class="mdInput" style="background-color:aliceblue;"
                                        [placeholder]="'Not Selected'"
                                        [data]="NonAdminUserList"
                                        [disabled]="disabled"
                                        formControlName='IsAdminPermission'
                                        [settings]="dropdownSettings"
                                        (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                        name="IsAdminPermission"
                                      >
                                      </ng-multiselect-dropdown>
                                        <span *ngIf='f.IsAdminPermission.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div> -->



                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <!-- <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmMailingAddress">
                                <div class="box-header with-border" style="padding: 0px;">
                                    <h3 class="box-title col-lg-6" >Mailing Address</h3>
                                    <h3 class="box-title col-lg-6" *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'" style="display:none;" >Billing Address ( <input type="checkbox" (change) = "ChangeSameAsMailing()" name='SameAsMailingAddress' maxlength='checkbox' class="iCheck-helper"
                                        formControlName='SameAsMailingAddress' style="margin: 0px;"> Same as Mailing Address ) </h3>
                                </div>
                            </div>

                        </div>

                        <div class="box-body">
                            <div class="col-sm-12 box-row">
                                <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc'">*</span>Mailing Address:</label>

                                            <div class='col-sm-7' >
                                                <textarea  name='mailingaddress' (blur)="UpdateBillingAddress()"  class="form-textarea lgInput" autosize
                                                    formControlName='mailingaddress' ></textarea><span *ngIf='f.mailingaddress.errors?.required && (f.mailingaddress.dirty || f.mailingaddress.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingaddress.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                </div>

                                <div class='col-sm-6' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Customer' || f.contactType.value == 'Misc'" style="display:none;"                                >
                                    <label class='col-sm-5 control-label'>Billing Address :</label>

                                    <div class='col-sm-7' >
                                        <textarea  name='billingaddress'  class="form-textarea lgInput" autosize
                                            formControlName='billingaddress' ></textarea><span *ngIf='f.billingaddress.errors?.required && (f.billingaddress.dirty || f.billingaddress.touched || isValidFormSubmitted==false)'
                                            [ngClass]="'error'"> Required! </span>
                                        <span *ngIf='f.billingaddress.errors?.pattern'
                                            [ngClass]="'error'">Invalid value</span>
                                    </div>
                                </div>

                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc'">*</span>Mailing City:</label>

                                            <div class='col-sm-7' >
                                                <input  name='mailingcity' (blur)="UpdateBillingAddress()" maxlength='30' class='form-control' style="text-transform: capitalize;"
                                                    formControlName='mailingcity' ><span *ngIf='f.mailingcity.errors?.required && (f.mailingcity.dirty || f.mailingcity.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingcity.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>

                                        <div class='col-sm-6' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' ||f.contactType.value == 'Customer'" style="display:none;">
                                                <label class='col-sm-5 control-label'>Billing City:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='billingcity' maxlength='30' class='form-control' style="text-transform: capitalize;"
                                                        formControlName='billingcity' ><span *ngIf='f.billingcity.errors?.required && (f.billingcity.dirty || f.billingcity.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.billingcity.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>



                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc'">*</span>Mailing Country:</label>

                                            <div class='col-sm-7' >
                                                <select  name='mailingcountry' (blur)="UpdateBillingAddress()" maxlength='dropdown' class='form-control'
                                                    formControlName='mailingcountry'  (change)="CountryStateDropDownChanged()">
                                                    <option  value="" >Select</option>
                                                    <option  value="{{item.text}}" *ngFor="let item of CountryList">{{item.text}}</option>
                                                </select>

                                                    <span *ngIf='f.mailingcountry.errors?.required && (f.mailingcountry.dirty || f.mailingcountry.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingcountry.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>
                                        <div class='col-sm-6' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'" style="display:none;">
                                                <label class='col-sm-5 control-label'>Billing Country:</label>

                                                <div class='col-sm-7' >
                                                    <select  name='billingcountry' maxlength='dropdown' class='form-control'
                                                        formControlName='billingcountry'  (change)="CountryStateBillingDropDownChanged()">
                                                        <option  value="{{item.text}}" *ngFor="let item of CountryList">{{item.text}}</option>
                                                        </select>
                                                        <span *ngIf='f.billingcountry.errors?.required && (f.billingcountry.dirty || f.billingcountry.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.billingcountry.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                        </div>



                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc'">*</span>Mailing Postal / Zip Code:</label>

                                            <div class='col-sm-7' >
                                                <input  name='mailingzipCode' maxlength='10' class='form-control'
                                                    formControlName='mailingzipCode' (blur)="UpdateBillingAddress()" ><span *ngIf='f.mailingzipCode.errors?.required && (f.mailingzipCode.dirty || f.mailingzipCode.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingzipCode.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>
                                        <div class='col-sm-6' *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'" style="display:none;">
                                                <label class='col-sm-5 control-label'>Billing Postal / Zip Code:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='billingzipCode' maxlength='10' class='form-control'
                                                        formControlName='billingzipCode' ><span *ngIf='f.billingzipCode.errors?.required && (f.billingzipCode.dirty || f.billingzipCode.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.billingzipCode.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>


                            </div>
                            <div class="clearfix"></div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'   *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc'">*</span>Mailing Province / State:</label>

                                            <div class='col-sm-7' >
                                                <select  name='mailingstate' (blur)="UpdateBillingAddress()" maxlength='dropdown' class='form-control'
                                                    formControlName='mailingstate'>
                                                    <option  value="" >Select</option>
                                                    <option  value="{{item.text}}" *ngFor="let item of StateList">{{item.text}}</option>
                                                </select>
                                                <span *ngIf='f.mailingstate.errors?.required && (f.mailingstate.dirty || f.mailingstate.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingstate.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>
                                        <div class='col-sm-6' *ngIf="f.contactType.value == 'Vendor'  || f.contactType.value == 'Misc' || f.contactType.value == 'Customer'" style="display:none;" >
                                                <label class='col-sm-5 control-label'>Billing Province / State:</label>

                                                <div class='col-sm-7' >
                                                    <select  name='billingstate' maxlength='dropdown' class='form-control'
                                                        formControlName='billingstate' >
                                                        <option  value="{{item.text}}" *ngFor="let item of StateList">{{item.text}}</option>
                                                        </select>
                                                        <span *ngIf='f.billingstate.errors?.required && (f.billingstate.dirty || f.billingstate.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.billingstate.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                        </div>


                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div> -->

                       <div class="box-body box ibox box-info" style="padding:0px;">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmMailingAddress">
                                <h4 class="panel-title">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmMailingAdd"
                                        aria-expanded="false" aria-controls="dmMailingAdd">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title">Mailing Address
                                              <span><i *ngIf="f.mailingcountry.value || f.mailingstate.value || f.mailingcity.value || f.mailingaddress.value || f.mailingzipCode.value  " class="fa fa-plus-square faplusicon"></i></span>
                                            </h3>
                                        </div>
                                      </a>
                                </h4>
                            </div>

                        <div id="dmMailingAdd"  class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmMailingAddress">
                        <div class="panel-body">
                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc' && f.contactType.value !== 'User'">*</span>Mailing Country:</label>

                                        <div class='col-sm-7' >
                                            <select  name='mailingcountry' (blur)="UpdateBillingAddress()" maxlength='dropdown' class='form-control'
                                                formControlName='mailingcountry'  (change)="CountryStateDropDownChanged()">
                                                <option  value="" >Select</option>
                                                <option  value="{{item.text}}" *ngFor="let item of CountryList">{{item.text}}</option>
                                            </select>

                                                <span *ngIf='f.mailingcountry.errors?.required && (f.mailingcountry.dirty || f.mailingcountry.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'"> Required! </span>
                                            <span *ngIf='f.mailingcountry.errors?.pattern'
                                                [ngClass]="'error'">Invalid value</span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'   *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc' && f.contactType.value !== 'User'">*</span>Mailing Province / State:</label>

                                            <div class='col-sm-7' >
                                                <select  name='mailingstate' (blur)="UpdateBillingAddress()" maxlength='dropdown' class='form-control'
                                                    formControlName='mailingstate'>
                                                    <option  value="" >Select</option>
                                                    <option  value="{{item.text}}" *ngFor="let item of StateList">{{item.text}}</option>
                                                </select>
                                                <span *ngIf='f.mailingstate.errors?.required && (f.mailingstate.dirty || f.mailingstate.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingstate.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>
                            </div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc' && f.contactType.value !== 'User'">*</span>Mailing City:</label>

                                            <div class='col-sm-7' >
                                                <input  name='mailingcity' (blur)="UpdateBillingAddress()" maxlength='30' class='form-control' style="text-transform: capitalize;"
                                                    formControlName='mailingcity' ><span *ngIf='f.mailingcity.errors?.required && (f.mailingcity.dirty || f.mailingcity.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingcity.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                    </div>
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc' && f.contactType.value !== 'User'">*</span>Mailing Address:</label>

                                            <div class='col-sm-7' >
                                                <textarea  name='mailingaddress' (blur)="UpdateBillingAddress()"  class="form-textarea" autosize
                                                    formControlName='mailingaddress'></textarea><span *ngIf='f.mailingaddress.errors?.required && (f.mailingaddress.dirty || f.mailingaddress.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingaddress.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                    </div>
                            </div>

                            <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                            <label class='col-sm-5 control-label'><span class='text-red inputrequired'  *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'Misc' && f.contactType.value !== 'User'">*</span>Mailing Postal / Zip Code:</label>

                                            <div class='col-sm-7' >
                                                <input  name='mailingzipCode' maxlength='10' class='form-control'
                                                    formControlName='mailingzipCode' (blur)="UpdateBillingAddress()" ><span *ngIf='f.mailingzipCode.errors?.required && (f.mailingzipCode.dirty || f.mailingzipCode.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'"> Required! </span>
                                                <span *ngIf='f.mailingzipCode.errors?.pattern'
                                                    [ngClass]="'error'">Invalid value</span>
                                            </div>
                                        </div>

                            </div>
                            <div class="clearfix"></div>

                        </div>
                        </div>
                    </div>
                </div>



                    <div class="box ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmContactDetails">
                                    <h4 class="panel-title">

                                        <a [ngClass]="collapsedContactDetailsClass" data-toggle="collapse" data-parent="#accordion" href="#dmContact"
                                            aria-expanded="collapseariaexpanded" aria-controls="collapsedContactDetails">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Contact Details</h3>
                                                <span><i class="fa fa-plus-square faplusicon" *ngIf="f.phone.value || f.extension.value || f.tollFreeNumber.value || f.homePhone.value || f.cellPhone.value || f.pager.value || f.fax.value || f.tollFreeFax.value ||  f.otherNumber.value || f.emailAddress.value"></i></span>
                                            </div>
                                        </a>
                                    </h4>
                                </div>

                            <div id="dmContact"  [ngClass]="collapsedContactDetails" role="tabpanel" aria-labelledby="dmContactDetails">
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'   *ngIf="f.contactType.value !== 'Vendor' && f.contactType.value !== 'User' && f.contactType.value !== 'Misc'">*</span>Work Phone:</label>

                                                <div class='col-sm-4' >
                                                    <input  name='phone' placeholder="(###) ###-####" (blur)="formatPhone('phone')" maxlength='15' class='form-control' (keypress)="numberOnly($event)"
                                                        formControlName='phone' ><span *ngIf='f.phone.errors?.required && (f.phone.dirty || f.phone.touched || isValidFormSubmitted==false)'

                                                        [ngClass]="'error'"> Required! </span>

                                                    <span *ngIf='f.phone.errors?.pattern' [ngClass]="'error'"
                                                        style="margin-left: 2px;">Invalid value</span>
                                                </div>
                                                <div class='col-sm-3' style="padding: 0px">
                                                    <label class='col-sm-5 control-label'><span class='text-red inputrequired'></span>Extn.:</label>
                                                    <div class='col-sm-7' >
                                                        <input  name='extension' maxlength='6' class='form-control' (keypress)="numberOnly($event)"
                                                            formControlName='extension' maxlength="4" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class='col-sm-6' *ngIf="f.contactType.value !== 'User'">
                                                    <label class='col-sm-5 control-label'>Toll Free Phone:</label>

                                                    <div class='col-sm-7' >
                                                        <input  name='tollFreeNumber' placeholder="(###) ###-####" (blur)="formatPhone('tollFreeNumber')" maxlength='20' class='form-control'
                                                            formControlName='tollFreeNumber' ><span *ngIf='f.tollFreeNumber.errors?.required && (f.tollFreeNumber.dirty || f.tollFreeNumber.touched || isValidFormSubmitted==false)'
                                                            [ngClass]="'error'"> Required! </span>
                                                        <span *ngIf='f.tollFreeNumber.errors?.pattern'
                                                            [ngClass]="'error'">Invalid value</span>
                                                    </div>
                                                </div>

                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Home Phone:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='homePhone' placeholder="(###) ###-####"  (blur)="formatPhone('homePhone')" maxlength='20' class='form-control'  (keypress)="numberOnly($event)"
                                                        formControlName='homePhone' ><span *ngIf='f.homePhone.errors?.required && (f.homePhone.dirty || f.homePhone.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.homePhone.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                        </div>
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'   *ngIf="f.contactType.value === 'User' || f.contactType.value === ''">*</span>Cell Phone:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='cellPhone' placeholder="(###) ###-####"  (blur)="formatPhone('cellPhone')" maxlength='20' class='form-control'  (keypress)="numberOnly($event)"
                                                        formControlName='cellPhone'><span *ngIf='f.cellPhone.errors?.required && (f.cellPhone.dirty || f.cellPhone.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.cellPhone.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>


                                </div>
                                <div class="clearfix"></div>




                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Pager:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='pager' (blur)="formatPhone('pager')" maxlength='20' class='form-control'
                                                        formControlName='pager'><span *ngIf='f.pager.errors?.required && (f.pager.dirty || f.pager.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.pager.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>

                                            <div class='col-sm-6'>
                                                    <label class='col-sm-5 control-label'>Fax:</label>

                                                    <div class='col-sm-7' >
                                                        <input  name='fax'  placeholder="(###) ###-####"  maxlength='20' (blur)="formatPhone('fax')" class='form-control'
                                                            formControlName='fax' ><span *ngIf='f.fax.errors?.required && (f.fax.dirty || f.fax.touched || isValidFormSubmitted==false)'
                                                            [ngClass]="'error'"> Required! </span>
                                                        <span *ngIf='f.fax.errors?.pattern'
                                                            [ngClass]="'error'">Invalid value</span>
                                                    </div>
                                            </div>


                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row" *ngIf="f.contactType.value !== 'User'">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Toll Free Fax:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='tollFreeFax' placeholder="(###) ###-####"  maxlength='20' (blur)="formatPhone('tollFreeFax')" class='form-control'
                                                        formControlName='tollFreeFax' ><span *ngIf='f.tollFreeFax.errors?.required && (f.tollFreeFax.dirty || f.tollFreeFax.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.tollFreeFax.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                                    <label class='col-sm-5 control-label'>Other Number:</label>

                                                    <div class='col-sm-7' >
                                                        <input  name='otherNumber'  placeholder="(###) ###-####" (blur)="formatPhone('otherNumber')" maxlength='20' class='form-control'
                                                            formControlName='otherNumber' ><span *ngIf='f.otherNumber.errors?.required && (f.otherNumber.dirty || f.otherNumber.touched || isValidFormSubmitted==false)'
                                                            [ngClass]="'error'"> Required! </span>
                                                        <span *ngIf='f.otherNumber.errors?.pattern'
                                                            [ngClass]="'error'">Invalid value</span>
                                                    </div>
                                                </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="f.contactType.value !== 'User'">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Email:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='emailAddress' maxlength='100' class='form-control'
                                                        formControlName='emailAddress' ><span *ngIf='f.emailAddress.errors?.required && (f.emailAddress.dirty || f.emailAddress.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.emailAddress.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>


                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row" style="display:none;">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Permanent Notes:</label>
                                                <div class='col-sm-7' >
                                                    <textarea  name='permanentNotes' class="form-textarea"  autosize
                                                        formControlName='permanentNotes' ></textarea>
                                                        <span *ngIf='f.permanentNotes.errors?.required && (f.permanentNotes.dirty || f.permanentNotes.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.permanentNotes.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>



                                </div>
                                <div class="clearfix"></div>

                            </div>
                            </div>
                            </div>
                    </div>

                    <div class="box ibox box-info" *ngIf="f.contactType.value == 'User'">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmUserLoginDetails">
                                    <h4 class="panel-title">

                                        <a [ngClass]="collapsedUserLoginClass" data-toggle="collapse" data-parent="#accordion" href="#dmUserLogin"
                                            aria-expanded="collapseariaexpandedUserLogin" aria-controls="collapsedUserLoginDetails">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">User Login Details</h3>
                                            <span><i class="fa fa-plus-square faplusicon" *ngIf='f.contactid.value > 0'></i></span>
                                        </div>
                                        </a>
                                    </h4>
                                </div>
                            <div id="dmUserLogin" [ngClass]="collapsedUserLoginDetails"   role="tabpanel" aria-labelledby="dmUserLoginDetails">
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Username (Email):</label>
                                                <div class='col-sm-7' *ngIf='f.contactid.value > 0'>
                                                  <label class='readOnlyText'> {{f.userName.value}}</label>
                                              </div>
                                              <div class='col-sm-7' *ngIf='f.contactid.value == 0'>
                                                    <input  name='userName' maxlength='40' class='form-control'
                                                        formControlName='userName' autocomplete="false"><span *ngIf='f.userName.errors?.required && (f.userName.dirty || f.userName.touched || isValidFormSubmitted==false)'
                                                        (keyup)="CheckUserNameMismatch()" [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.userName.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Verify Username (Email):</label>
                                              <div class='col-sm-7' *ngIf='f.contactid.value > 0'>
                                                <label class='readOnlyText'> {{f.VerifyContactUsername.value}}</label>
                                            </div>
                                            <div class='col-sm-7' *ngIf='f.contactid.value == 0'>
                                                  <input  name='VerifyContactUsername' maxlength='40' class='form-control'
                                                      formControlName='VerifyContactUsername' autocomplete="false"><span *ngIf='f.VerifyContactUsername.errors?.required && (f.VerifyContactUsername.dirty || f.VerifyContactUsername.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.VerifyContactUsername.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                                <span *ngIf='f.userName.errors?.UserNameMismatch || f.VerifyContactUsername.errors?.UserNameMismatch'
                                                      [ngClass]="'error'">User name not matching</span>
                                              </div>
                                          </div>
                                </div>
                                <div class="clearfix"></div>


                                <div class="col-sm-12 box-row" style="display: none">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>
                                                   <span class='text-red inputrequired'>*</span>Password:</label>

                                                <div class='col-sm-7' >
                                                    <input type="password" name='password' maxlength='20' class='form-control'
                                                        formControlName='password' autocomplete="false" ><span *ngIf='f.password.errors?.required && (f.password.dirty || f.password.touched || isValidFormSubmitted==false)'
                                                        (keyup)="CheckPasswordMismatch()" [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.password.errors?.pattern'
                                                        [ngClass]="'error'">Password should have at least 8 character,one Upper,one Lower and one numeric character</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'>
                                                   <span class='text-red inputrequired'>*</span>Verify Password:</label>

                                              <div class='col-sm-7' >
                                                  <input type="password" name='VerifyPassword' maxlength='20' class='form-control'
                                                      formControlName='VerifyPassword' >

                                                      <span *ngIf='f.VerifyPassword.errors?.required && (f.VerifyPassword.dirty || f.VerifyPassword.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.VerifyPassword.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                                      <span *ngIf='f.password.errors?.PasswordMismatch || f.VerifyPassword.errors?.PasswordMismatch'
                                                      [ngClass]="'error'">Verify Password not matching Required</span>
                                              </div>
                                          </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'><span class='text-red inputrequired'>*</span>Pin ID:</label>

                                                <div class='col-sm-7' >
                                                    <input type="password" name='ContactPinid' maxlength='5' class='form-control'
                                                    formControlName='ContactPinid' autocomplete="nope" (keyup)="CheckpinIDMismatch($event)" ><span *ngIf='f.ContactPinid.errors?.required && (f.ContactPinid.dirty || f.ContactPinid.touched || isValidFormSubmitted==false)'
                                                     [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.ContactPinid.errors?.pattern'
                                                        [ngClass]="'error'">5 Digit Number Only</span>
                                                    <span *ngIf='f.ContactPinid.errors?.PasswordNotValidate'
                                                        [ngClass]="'error'">You can not enter consecutive number for Pin ID</span>
                                                    <span *ngIf='f.ContactPinid.errors?.PinIdNotValidate'
                                                        [ngClass]="'error'">You can not enter 12345</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>

                                                    <span class='text-red inputrequired'>*</span>Verify PinID:</label>
                                                <div class='col-sm-7' >
                                                    <input type="password" name='VerifyPinID' maxlength='20' class='form-control'
                                                        formControlName='VerifyPinID' autocomplete="false" ><span *ngIf='f.VerifyPinID.errors?.required && (f.VerifyPinID.dirty || f.VerifyPinID.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.VerifyPinID.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                        <span *ngIf='f.ContactPinid.errors?.PasswordMismatch || f.VerifyPinID.errors?.PasswordMismatch'
                                                        [ngClass]="'error'">Verify PinID is not matching Required</span>
                                                </div>
                                            </div>

                                    </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>
                                                    <!-- <span class='text-red inputrequired'>*</span> -->
                                                    Hint Question:</label>

                                                <div class='col-sm-7' >
                                                    <select  name='hintquestion' maxlength='dropdown' class='form-control'
                                                        formControlName='hintquestion' >
                                                        <option  value="{{item.value}}" *ngFor="let item of HintQuestionList">{{item.text}}</option>
                                                        </select>
                                                    <span *ngIf='f.hintquestion.errors?.required && (f.hintquestion.dirty || f.hintquestion.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.hintquestion.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'>
                                                  <!-- <span class='text-red inputrequired'>*</span> -->
                                                  Hint Answer:</label>

                                              <div class='col-sm-7' >
                                                  <input  name='hintanswer' maxlength='40' class='form-control' type='password'
                                                      formControlName='hintanswer' ><span *ngIf='f.hintanswer.errors?.required && (f.hintanswer.dirty || f.hintanswer.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.hintanswer.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                              </div>
                                          </div>
                                    </div>
                                <div class="clearfix"></div>



                            </div>
                            </div>
                            </div>
                    </div>

                    <div class="box ibox box-info" *ngIf="f.contactType.value == 'Vendor' || f.contactType.value == 'Misc'">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmOrderingInformation">
                                    <h4 class="panel-title">
                                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmOrderingInfo"
                                        aria-expanded="false" aria-controls="dmOrderingInfo">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">Ordering Information</h3>
                                            <span><i *ngIf="f.PONo.value || f.previousPONo.value || (f.billingMeans.value && f.billingMeans.value !== 'select') || f.billingTerms.value || f.vendoraccountno.value || f.creditcardno.value" class="fa fa-plus-square faplusicon"></i></span>
                                        </div>
                                    </a>
                                    </h4>
                                </div>
                            <div id="dmOrderingInfo"  class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmOrderingInformation">
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>PO #:</label>

                                                <div class='col-sm-7' >
                                                    <input  name='PONo' maxlength='20' class='form-control'
                                                        formControlName='PONo' ><span *ngIf='f.PONo.errors?.required && (f.PONo.dirty || f.PONo.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.PONo.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'>Previous PO #:</label>

                                              <div class='col-sm-7' >
                                                  <input  name='previousPONo' maxlength='20' class='form-control'
                                                      formControlName='previousPONo' ><span *ngIf='f.previousPONo.errors?.required && (f.previousPONo.dirty || f.previousPONo.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.previousPONo.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                              </div>
                                          </div>
                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>Billing Means:</label>

                                                <div class='col-sm-7' >
                                                    <select  name='billingMeans' maxlength='dropdown' class='form-control'
                                                        formControlName='billingMeans' >
                                                        <option  value="{{item.text}}" *ngFor="let item of BillingMeanList">{{item.text}}</option>
                                                        </select>
                                                        <span *ngIf='f.billingMeans.errors?.required && (f.billingMeans.dirty || f.billingMeans.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.billingMeans.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'>Billing Terms:</label>

                                              <div class='col-sm-7' >
                                                  <input  name='billingTerms' maxlength='30' class='form-control'
                                                      formControlName='billingTerms' ><span *ngIf='f.billingTerms.errors?.required && (f.billingTerms.dirty || f.billingTerms.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.billingTerms.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                              </div>
                                          </div>

                                </div>

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                        <div class='col-sm-6'>
                                                <label class='col-sm-5 control-label'>{{f.contactType.value == "Vendor"? "Vendor" : "Misc."}} Account Number</label>

                                                <div class='col-sm-7' >
                                                    <input  name='vendoraccountno' maxlength='10' class='form-control'
                                                        formControlName='vendoraccountno' ><span *ngIf='f.vendoraccountno.errors?.required && (f.vendoraccountno.dirty || f.vendoraccountno.touched || isValidFormSubmitted==false)'
                                                        [ngClass]="'error'"> Required! </span>
                                                    <span *ngIf='f.vendoraccountno.errors?.pattern'
                                                        [ngClass]="'error'">Invalid value</span>
                                                </div>
                                            </div>
                                            <div class='col-sm-6'>
                                              <label class='col-sm-5 control-label'>Credit Card Number:</label>

                                              <div class='col-sm-7' >
                                                  <input  name='creditcardno' maxlength='16' class='form-control'
                                                      formControlName='creditcardno' ><span *ngIf='f.creditcardno.errors?.required && (f.creditcardno.dirty || f.creditcardno.touched || isValidFormSubmitted==false)'
                                                      [ngClass]="'error'"> Required! </span>
                                                  <span *ngIf='f.creditcardno.errors?.pattern'
                                                      [ngClass]="'error'">Invalid value</span>
                                              </div>
                                          </div>
                                </div>
                                <div class="clearfix"></div>

                            </div>
                            </div>
                            </div>
                    </div>
                    <!-- <notes  [isValidForm] ="this.contactDetailForm.invalid" *ngIf="f.contactid.value !=0" (addNoteClick)="executeContactCreateUpdate('Notes')" #notes>
                    </notes> -->

                    <!-- <notes [isValidForm] ="this.contactDetailForm.invalid" (addNoteClick)="executeContactCreateUpdate('Notes')"  #notes>
                    </notes> -->

                    <notes
                    [isValidForm]="this.contactDetailForm.invalid" *ngIf="f.contactid.value !=0 && f.contactType.value != 'User'"
                    (addNoteClick)="executeContactCreateUpdate('Notes')"
                    #notes></notes>

                    <attachment (RaiseError)="AttachmentError($event)"  *ngIf="f.contactid.value !=0 && f.contactType.value != 'User'" #attachment>
                    </attachment>

                  </div>
                </div>
                <PageHeader

                    [showNote]="false"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showEdit]="blnshowEdit"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeContactCreateUpdate('')"
                    (EditEvent)="executeEnableDisablectls(false)"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>

            </div>
        </div>
        <!-- </div> -->
    </section>

</form>

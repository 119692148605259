<div [formGroup]="repairForm" novalidate *ngIf="repairForm" style="border:1px solid lightgray;padding: 10px">
        <div class="modal-header  col-sm-12" >
                <h4 class="modal-title col-sm-4">{{RepairType == 'Dialysis Machines' ? 'DM Repair': (RepairType == 'Water Process' ? 'Water Repair' : 'Repair')}}</h4>
                <div class="col-sm-4 errormsg">
                  <span [ngClass]="'error'">
                    {{errormsg}}
                  </span>
                </div>
                <div class="text-right  col-sm-4" style="padding:0">
                  <!-- <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Close</button>
                  <button type="button" class="btn btn-primary" *ngIf="IsPurposeCompleted != 'Yes'" (click)="save();" >Save</button> -->
                  <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="saveAndClose()">Save / Close</button>
                </div>
        </div>
    <div class="box-body">
        <div class="box-row col-sm-12" >
            <div class="col-sm-6">
              <label class="col-sm-5 control-label">Repair Type:</label>
              <div class="col-sm-7" >
                  <!-- [disabled]="PurposeType == 'Repair'" -->
                <select class="form-control mdInput" [(ngModel)]="RepairType"
                [ngModelOptions]="{standalone: true}"
                (change) = "OnRepairTypeChange()">
                <!-- (change)="EquipmentCategoryChange()" -->
                  <option value="{{item.text}}" *ngFor="let item of RepairCategoryList">
                    {{item.text}}
                  </option>
                </select>
              </div>

            </div>
          </div>
        <div class="clearfix"></div>
        <DMRepairDetail  #dmrepair *ngIf="RepairType == 'Dialysis Machines'"></DMRepairDetail>
        <RepairDetail  #wprepair *ngIf="RepairType == 'Water Process' || RepairType =='Other Equipment'"></RepairDetail>

    </div>

    </div>



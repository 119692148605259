import { Component, ViewChild } from '@angular/core';
import { DMApiService } from '../../API/dmapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'GRPyrogenSearch',
  templateUrl: './GRPyrogenSearch.component.html'
})
export class GRPyrogenSearchComponent {
  UserList: any;
  constructor(
    private dmapi: DMApiService,
    private router: Router,
    private dataGridService: TableHeadsService,
    private commonapi: CommonApiService,
    private auth: AuthService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true })
  pageheader: PageHeaderComponent;
  loading = true;
  DialysisUnits = [];
  
  selectedDivision = 'All Divisions';
  PyrogenCode = '';
  Status = '';
  blnDirtySearch = false;
  FromDate;
  ToDate;
  // SampleReadFromDate;
  // SampleReadToDate;
  // RetestFromDate;
  // RetestToDate;
  // RetestSampleReadFromDate;
  // RetestSampleReadToDate;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = 'DialysisMachine/PyrogensDetail';
  currentPageNumber = 1;
  sortExpression = 'default';
  sortDirection = '';
  pageSize = 25;
  ShowSearch = false;
  title = 'Pyrogen';
  searchText = 'Search Records';
  defaultSort;

  // tslint:disable-next-line:member-ordering
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };
  Statuses = [
    { value: '', text: 'Select' },
    { value: 'In Progress', text: 'In Progress' },
    { value: 'Completed', text: 'Completed' }
  ];
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.auth.GetCurrentUserDivision().subscribe(division => {

    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'eventId', '4');
    this.dataGridService.addHeader('Event Code', 'eventId', '12');
    this.dataGridService.addHeader('Division', 'divisionName', '18');
    this.dataGridService.addHeader('Collection Date', 'collectionDate', '12');
    this.dataGridService.addHeader('Collection Tech', 'collectionByName', '18');
    this.dataGridService.addHeader('Report Date', 'collectionretestdate', '18');
    this.dataGridService.addHeader('Status', 'status', '12');

    this.sortDirection = 'DESC';
    this.sortExpression = 'default';
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('Event Code', true);
    // this.fillSiloes();
    if(division.length===1)
    this.fillDialysisUnit(division[0]);
  else
    this.fillDialysisUnit();

    
    this.CheckDirtySearch();
    this.executePyrogenSearch();
  }, error => {
    AppComponent.addAlert(error.message, 'error');
    AppComponent.HideLoader();
  });
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  changeSorting(column) {
    if (column === '#') {
      return;
    }

    this.dataGridService.changeSorting(
      column,
      this.defaultSort,
      this.tableHeaders
    );
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executePyrogenSearch();
  }

  setSortIndicator = function(column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executePyrogenSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';
    this.ShowSearch = false;
    this.searchText = 'Search Records';
    this.CheckDirtySearch();
    const PyrogenSearch = this.prepareSearch();
    this.dmapi.GetPyrogenDetails(PyrogenSearch).subscribe(
      pagedData => {
         console.log(pagedData);
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.pyrogenList;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    const PyrogenSearch = <any>{};

    PyrogenSearch.PyrogenCode = this.PyrogenCode;
    PyrogenSearch.Status = this.Status;

    if (this.FromDate !== undefined && this.FromDate !== '' && this.FromDate != null) {
      PyrogenSearch.fromdate = Globals.GetAPIDate(this.FromDate.jsdate);
    }

    if (this.ToDate !== undefined && this.ToDate !== '' && this.ToDate != null) {
      PyrogenSearch.todate = Globals.GetAPIDate(this.ToDate.jsdate);
    }

    
    if (this.selectedDivision !== undefined && this.selectedDivision !== '' && this.selectedDivision !== '0' && this.selectedDivision !== 'All Divisions') {
      PyrogenSearch.division = this.selectedDivision;
    }


    PyrogenSearch.currentPageNumber = this.currentPageNumber;
    PyrogenSearch.sortExpression = this.sortExpression;
    PyrogenSearch.sortDirection = this.sortDirection;
    PyrogenSearch.pageSize = this.pageSize;

    return PyrogenSearch;
  }

  setPage() {
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber = this.page.pageNumber;
    this.executePyrogenSearch();
    }
  }
  _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  fillDialysisUnit(defaultValue='') {
    // this.dmapi
    //   .GetDialysisUnitDD()
    //   .subscribe(list => (this.DialysisUnits = list));
    this.commonapi.FillDivision(true).subscribe(list =>{ 
      
      this.DialysisUnits = list;
      
      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          setTimeout(()=> this.executePyrogenSearch());
        }
      }

    });
  }

  fillUserList() {
    this.dmapi
      .GetUsersDD()
      .subscribe(list => (this.UserList = list));
  }

  executePyrogenSearchClear() {
    this.selectedDivision = 'All Divisions';
    this.PyrogenCode = '';
    this.FromDate = '';
    this.ToDate = '';
    
    this.Status = '';
    this.executePyrogenSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.selectedDivision && this.selectedDivision !== 'All Divisions') 
      ||  this.Status
      || this.PyrogenCode ||
      this.FromDate ||
      this.ToDate
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  
  CheckFormDate(fdate, edate) {
    return this.commonapi.CompareDates(fdate, edate);
  }

  DialysisUnitDropDownChanged() {
    this.executePyrogenSearch();
  }
}

export class Page {
  // The number of elements in the page
  size = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The current page number
  pageNumber = 0;
}

import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../API/auth.service';
import { TableHeadsService } from '../Core/tableheads.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  MatButtonToggleModule
} from '@angular/material/button-toggle';
import { ConfirmationDialog } from '../Core/ConfirmationDialog';
import { TasksApiService } from '../API/tasksapi.service';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
// import { Options } from 'fullcalendar';

import { ContactApiService } from '../API/contactapi.service';
import { SchApiService } from '../API/schapi.service';
// import { Console } from '@angular/core/src/console';



@Component({
  selector: 'DashBoardDetails',
  templateUrl: './DashBoardDetails.component.html'
  // styleUrls: ['./DashBoardDetails.css'],
})

export class DashBoardDetailsComponent {
  SchColorList: any;
  constructor(private authService: AuthService, private router: Router,
    private route: ActivatedRoute, private contactApi: ContactApiService, private schapi: SchApiService,
    private globals: Globals, private api: TasksApiService, public dialog: MatDialog, ) {

    if (sessionStorage.getItem("login") && sessionStorage.getItem("login").toString() == "true") {
      sessionStorage.removeItem("login");

      //Comment Below line to stop hard reload on login
      // location.reload(true);
    }

  }

  // @ViewChild(PageHeaderComponent) pageheader: PageHeaderComponent;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  // @ViewChild(CalendarComponent, { static: false }) ucCalendar: CalendarComponent;
  loading = true;
  UserName = '';
  DesignationName = '';
  lastLoginDateTime  = Date.now();
  openDmRepairs = 0;
  openDmH2oProcess = 0;
  openDmConcentrates = 0;
  openDmoRepairs = 0;
  current_date;
  unReadMessage = 0;
  AssignTasks = 0;
  unAssignTasks = 0;
  unTasksoverDue = 0;

  currentUserId = this.authService.GetUserId();
  totTaskCompleted = 0;
  totTasks = 0;

  totDmReapirsCompleted = 0;
  totDmReapirs = 0;

  totDepartMentRepairs = 0;
  MonthtoMonthEfficency = 0;
  MonthtoMonthEfficencyCalculate = 0;


  TaskMonthlybyDivision = [];
  RepairsMonthlybyDivision = [];

  lst = [];
  IsLock = true;
  StartDate = new Date();
  ScheduleType = 1;
  DateList = [];
  SchList: any = [];
  PrevDate: Date;
  NextDate: Date;
  Note = '';
  HolidayList = [];

  TaskSchedule = [];
  TechnicalSchedule = [];
    colorlist = ['#FFFF00', '#00FF00', '#FF0000', '#0000FF', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
  divisionTaskList = [];
  divisionRepairList = [];
  title = 'DashBoard';

  public canvasWidth = 300;
  public bottomLabel = '';

  //TotalTaskCompleted
  public needleValueTotalTaskCompleted = 50
  public centralLabelTotalTaskCompleted = '50%'
  public nameTotalTaskCompleted = 'Total Task Completed';

//DmRepairsCompleted
public needleValueDmRepairsCompleted = 95
public centralLabelDmRepairsCompleted = '95%'
public nameDmRepairsCompleted = 'DM Repairs Completed';

//TotalRepairs
public needleValueTotalRepairs = 94
public centralLabelTotalRepairs = '94'
public nameTotalRepairs = 'Total Department Repairs';

//MonthtoMonthEfficiency
public needleValueMonthtoMonthEfficiency = 90
public signMonthEfficiency = '90%'

public centralLabelMonthtoMonthEfficiency = '95'
public nameTotalMonthtoMonthEfficiency = 'Month/Month Efficiency';


public options = {
  hasNeedle: true,
  outerNeedle: false,
  needleColor: "black",
  //arcColors: ["rgb(255,84,84)","rgb(255,128,0)","rgb(255,255,0)","rgb(61,204,91)","rgb(0,128,255)"],
  //arcDelimiters: [20,40,60,80],
  arcColors: ["rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)",
              "rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)","rgb(217, 30, 24)",
              "rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)",
               "rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)",
               "rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)"],
  arcDelimiters: [2,4,6,8,10,12,14,16,18,20,
                22,24,26,28,30,32,34,36,38,40,
                42,44,46,48,50,52,54,56,58,60,
                62,64,66,68,70,72,74,76,78,80,
                82,84,86,88,90,92,94,96,98],
  //rangeLabel: ["0","100"],
  rangeLabelFontSize: 24
}

public optionsTotalRepairs = {
  hasNeedle: true,
  outerNeedle: false,
  needleColor: "black",
  // arcColors: ["rgb(61,204,91)","rgb(61,204,91)","rgb(255,255,0)","rgb(255,128,0)","rgb(255,84,84)"],
  // arcDelimiters: [20,40,60,80],
  arcColors: ["rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)",
              "rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)",
              "rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)",
              "rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)","rgb(242, 38, 19)",
              "rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)"],
  arcDelimiters: [2,4,6,8,10,12,14,16,18,20,
                  22,24,26,28,30,32,34,36,38,40,
                  42,44,46,48,50,52,54,56,58,60,
                  62,64,66,68,70,72,74,76,78,80,
                  82,84,86,88,90,92,94,96,98],
  //rangeLabel: ["0","100"],
  rangeLabelFontSize: 24
}

public optionsMonthtoMonthEfficiency = {
  hasNeedle: true,
  outerNeedle: false,
  needleColor: "black",
  // arcColors: ["rgb(255,84,84)","rgb(255,84,84)","rgb(61,204,91)","rgb(0,128,255)","rgb(0,128,255)"],
  // arcDelimiters: [20,40,60,80],
  arcColors: ["rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)",
              "rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)","rgb(207, 0, 15)",
              "rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)","rgb(248, 148, 6)",
              "rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)",
              "rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)","rgb(30, 130, 76)"],
  arcDelimiters: [2,4,6,8,10,12,14,16,18,20,
                  22,24,26,28,30,32,34,36,38,40,
                  42,44,46,48,50,52,54,56,58,60,
                  62,64,66,68,70,72,74,76,78,80,
                  82,84,86,88,90,92,94,96,98],
 // rangeLabel: ["-50","50"],
  rangeLabelFontSize: 24
}


checkAvailable(MenuText) {
  return this.lst.filter(x => x === MenuText).length > 0;
}

RouteClick(value) {
  this.router.navigate([value]);
}
MenuClick(value) {
 // AppComponent.ShowLoader();
  this.authService.GetPasswordLoggedInUser().subscribe(list => {
    this.globals.url =
      Globals.oldUrl +
      '/login.aspx?source=MRMWEB' +
      '&returnURL=' +
      value +
      '&UName=' +
      this.authService.GetUserEmail() +
      '&PWD=' +
      list.userPassword;
    this.router.navigate(['Home']);
    // setTimeout(() => AppComponent.HideLoader(), 4000);
  });
}
calendarOptions: CalendarOptions;
DivisionList: any;
  startDate: any;
  endDate: any;
  events;
  ngOnInit() {

    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      events: this.events
    };

    const accesToken = this.authService.getAccessToken();
    if (accesToken != null) {
      this.authService
        .GetMenus(
          accesToken.ContactId,
          accesToken.DesignationId,
          accesToken.DesignationName,
          ''
        )
        .subscribe(list => {
          // tslint:disable-next-line:forin
          for (const childObj in list) {
            this.lst.push(list[childObj].dbName);
            // tslint:disable-next-line:forin
            for (const childObj1 in list[childObj].subMenu) {
              this.lst.push(list[childObj].subMenu[childObj1].dbName);
            }
          }
        });
      }
      this.schapi.GetAllScheduleColors().subscribe(list => {
        this.SchColorList = list;
      });
      const contactCreate = { Id:  accesToken.ContactId};
      this.contactApi.GetContactDetailsByID(contactCreate).subscribe(
        success => {
         this.lastLoginDateTime = success.lastLoginDateTime;
        });

    AppComponent.ShowLoader();
    const userID = { Id: this.authService.GetUserId };
    const userDivision = { UserId: this.authService.GetUserId,divisionID : 2 };


    this.current_date = Date.now();


    this.authService.GetDashBoardMVC(userID).subscribe(pagedDataDashBoardMVC  => {
        //console.log(pagedDataDashBoardMVC);
        this.UserName = decodeURI(accesToken.FullName).replace(
          '<br/>',
          ' '
        );
        this.DesignationName = accesToken.DesignationName;


        this.openDmRepairs = pagedDataDashBoardMVC.dmOpenRepairs;
        this.openDmH2oProcess = pagedDataDashBoardMVC.openRepairsH2oProcess;
        this.openDmConcentrates = pagedDataDashBoardMVC.openRepairsCentralDelivery;
        this.openDmoRepairs = pagedDataDashBoardMVC.openRepairsOther;

        this.unReadMessage = pagedDataDashBoardMVC.unreadMessage;
        this.AssignTasks = pagedDataDashBoardMVC.taskAssigned;
        if(this.AssignTasks > 0)
        {
          this.authService.CheckNotificationForTask().subscribe(count  => {
            if (count == 0) {
              this.dialogRef = this.dialog.open(ConfirmationDialog, {
                disableClose: true
              });

              this.authService.InsertNoteForTaskNotification().subscribe(cnt=>{});

              this.dialogRef.componentInstance.confirmMessage =
                'You have ' + this.AssignTasks + ' incomplete task.';
              this.dialogRef.afterClosed().subscribe(result => {
                this.router.navigate(['Task/TaskViewer']);
                this.dialogRef = null;
              });
            }

          });
        }
        this.unAssignTasks = pagedDataDashBoardMVC.taskUNAssigned;
        this.unTasksoverDue = pagedDataDashBoardMVC.taskOverDue;

        this.totTaskCompleted = pagedDataDashBoardMVC.tottaskCompleted;
        this.totTasks = pagedDataDashBoardMVC.tottasks;

        this.totDmReapirsCompleted = pagedDataDashBoardMVC.totDMRepairsCompleted;
        this.totDmReapirs = pagedDataDashBoardMVC.totDMRepairs;

        this.totDepartMentRepairs = pagedDataDashBoardMVC.totRepairs;
        this.MonthtoMonthEfficency = pagedDataDashBoardMVC.monthtoMonthEfficiency;

        //console.log(this.MonthtoMonthEfficency);

        if ( this.totTasks === 0) {
          this.totTasks = 1;
        }

        if (this.totDmReapirs === 0) {
          this.totDmReapirs = 1;
        }

        // this.needleValueTotalTaskCompleted = (this.totTaskCompleted/this.totTasks) * 100;
        // this.centralLabelTotalTaskCompleted = ((this.totTaskCompleted/this.totTasks) * 100 ).toFixed().toString() + "%";

        // this.needleValueDmRepairsCompleted =  (this.totDmReapirsCompleted/this.totDmReapirs) * 100;
        // this.centralLabelDmRepairsCompleted = ((this.totDmReapirsCompleted/this.totDmReapirs) * 100 ).toFixed().toString() + "%";

        // this.needleValueTotalRepairs = this.totDepartMentRepairs;
        // this.centralLabelTotalRepairs = this.totDepartMentRepairs.toFixed().toString();

        // this.needleValueMonthtoMonthEfficiency = this.MonthtoMonthEfficency;
        // this.MonthtoMonthEfficency = ((this.MonthtoMonthEfficency * 100))

        // if(this.needleValueMonthtoMonthEfficiency == 0)
        // {
        //   this.needleValueMonthtoMonthEfficiency = 50
        //   this.signMonthEfficiency ='';
        // }
        // else if(this.needleValueMonthtoMonthEfficiency > 0)
        // {

        //   this.MonthtoMonthEfficencyCalculate = ((this.MonthtoMonthEfficency * 50) / 100)
        //   this.needleValueMonthtoMonthEfficiency = 50 + this.MonthtoMonthEfficencyCalculate;
        //   this.signMonthEfficiency ='+';
        // }
        // else if(this.needleValueMonthtoMonthEfficiency < 0)
        // {
        //   this.MonthtoMonthEfficencyCalculate = ((this.MonthtoMonthEfficency * 50) / 100)
        //   this.needleValueMonthtoMonthEfficiency = 50 + this.MonthtoMonthEfficencyCalculate;

        //   console.log(this.needleValueMonthtoMonthEfficiency);
        //   this.signMonthEfficiency ='';
        //}

        // this.centralLabelMonthtoMonthEfficiency = this.signMonthEfficiency  + this.MonthtoMonthEfficency.toFixed().toString() + "%";
        AppComponent.HideLoader();
      }    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );


    this.authService.GetTasksMonthly().subscribe(pagedDataTasksMonthly  => {
      //console.log(pagedDataTasksMonthly);
      this.divisionTaskList = pagedDataTasksMonthly;
    }    ,
  error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
    }
  );

  this.authService.GetRepairsMonthly().subscribe(pagedDataRepairsMonthly  => {
    //console.log(pagedDataRepairsMonthly);
    this.divisionRepairList = pagedDataRepairsMonthly;
  },
error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
  }

);


    this.StartDate = null;
    this.SetScheduleList();


    AppComponent.HideLoader();

  }


  initialized(eventData) {
    this.startDate =  eventData.detail.view.start;
    this.endDate =  eventData.detail.view.end;
    this.executeTaskSearch(false);
  }
  executeTaskSearch(IsAllDivision) {
    AppComponent.ShowLoader();

    const divisionList = '';
  //console.log(divisionList);
    const taskSearch = {
      FromDate: this.startDate,
      ToDate: this.endDate,
      loggedInUserId: this.currentUserId,
      division: divisionList
    };
    //console.log(taskSearch);
    this.events = [];
    this.api.GetTaskDetailList(taskSearch).subscribe(pagedData  => {
     // console.log(pagedData);

      // const calendar = this.ucCalendar;
      const ev = [];
      pagedData.taskDetailslst.forEach(function (task) {
        ev.push ( {
   title: task.taskTitle,
   start: task.completionDate,
   end: task.completionDate,
   id: task.taskId,
   backgroundColor: task.backcolor,
   borderColor: task.color,
   textColor: '#000',
 }); });

 this.events = ev;
this.calendarOptions.events = this.events;

 // calendar.fullCalendar('renderEvent', el);
 //      });
//        pagedData.taskDetailslst.filter((item) => item.totalUsers == 0 || item.totalUsers == null).forEach(function (task) {
//         const el = {
//    title: task.taskTitle,
//    start: task.completionDate,
//    end: task.completionDate,
//    id: task.taskId,
//    backgroundColor: task.backcolor,
//    borderColor: task.color,
//    textColor: '#000',
//  };
//  calendar.fullCalendar('renderEvent', el);
//       });
      AppComponent.HideLoader();
 },
         error => {
           AppComponent.addAlert(error.message, 'error');
           AppComponent.HideLoader();
         }
 );
  }
  eventClick(eventData) {
    this.router.navigate(['/Task/TaskViewer'], { queryParams: {} });
  }

  filterScheduleItems(type) {
    return this.SchList.filter(x => x.userDesc === type);
}

SetColor(date, Value) {
  let color = '';
  if (this.HolidayList !== undefined && this.HolidayList.length > 0) {
    if (this.HolidayList.filter(x => x.userDesc === date).length > 0) {
      color = '#ccc';
    }
  }
  if (this.SchColorList.length > 0) {
    let tempVal = Value.substring(0, 1);
    let colorCls = this.SchColorList.filter(x => x.symbol === tempVal);
    if (colorCls.length > 0) {
      color = colorCls[0].color;
    }
  }
  return color;
}

DisplayDesc(value) {
  if (this.SchColorList.length > 0) {
    let tempVal = value.substring(0, 1);
    let colorCls = this.SchColorList.filter(x => x.symbol === tempVal);
    if (colorCls.length > 0) {
      if (value.length > 1) {
        return value.substring(1, value.length);
      } else {
        return '';
      }
    }
  }
  return value;
}

SetScheduleList() {
  AppComponent.ShowLoader();
  let params = <any>{};
  params.startDate = this.StartDate;
  params.division = 8;
  params.ScheduleType = 0;
  params.scheduleusertypeid = 3;
   //console.log(params);
  this.schapi.SetListWeek(params).subscribe(list => {
    this.DateList = list.datesList;
    this.PrevDate = list.prevDate;
    this.NextDate = list.nextDate;
    this.SchList = list.scheduleLst7Days;
    this.Note = list.notes;
    this.HolidayList = list.Holidayslst;
    //console.log(list);
    AppComponent.HideLoader();
  });
}

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'UnderConstruction',
  templateUrl: './UnderConstruction.component.html'
})

export class UnderConstructionComponent implements OnInit{
    ngOnInit() {

    }
}
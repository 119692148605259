<style>
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #3a4dab !important;
  }
</style>
<form
    [formGroup]="FilterForm"
    (ngSubmit)="executeFilterCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            DM Filter Changes
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    (addNoteClick)="notes.AddNote()"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeFilterCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">dmFiltersId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="dmFiltersId"
                                        [value]="f.dmFiltersId"
                                        formControlName="dmFiltersId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info" style="margin-bottom:0px;">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-4">
                                        <label class="col-sm-5 control-label">
                                            Event ID:
                                        </label>

                                        <div class="col-sm-7">
                                            <label class="readOnlyText">{{eventId}}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label class="col-sm-5 control-label">Form Date:</label>
                                        <div class="col-sm-7">
                                            <label class="readOnlyText">
                                                {{f.FormDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                            </label>
                                        </div>
                                        <!-- <div
                                            class="col-sm-7"
                                            *ngIf="f.dmFiltersId.value == 0"
                                        >
                                            <input
                                                (focus)="FilterDate.toggleCalendar()"
                                                (click)="FilterDate.openCalendar()"
                                                placeholder="Select a date"
                                                class="form-control mdInput"
                                                style="float:none"
                                                angular-mydatepicker
                                                name="FilterDate"
                                                formControlName="FilterDate"
                                                [options]="myOptions"
                                                #FilterDate="angular-mydatepicker"
                                                (dateChanged)="onDateChanged($event)"
                                                required
                                            >
                                            <span
                                                *ngIf="f.FilterDate.errors?.required && (f.FilterDate.dirty || f.FilterDate.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span>

                                        </div> -->
                                    </div>
                                    <div class="col-sm-4">
                                            <label class="col-sm-5 control-label">Created By:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{selectedUsertext}}</label>
                                                </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info" style="margin-bottom:0px;">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                        <div class="box-header with-border" style="padding: 0px;">
                                            <h3 class="box-title">Dialysis Unit Details</h3>
                                            <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                                        </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">

                                    <div class="row">
                                       <div class="col-sm-12 box-row">
                                                <div class="col-sm-6">
                                                    <label class="col-sm-5 control-label">
                                                            <span class="text-red inputrequired">*</span>Division:
                                                    </label>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value > 0"
                                                    >
                                                        <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                    </div>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value == 0"
                                                    >

                                                        <select
                                                            class="form-control mdInput"
                                                            formControlName="selectedDialysisUnits"
                                                            name="selectedDialysisUnits"
                                                            (change)="DialysisUnitDropDownChanged()"
                                                        >
                                                            <option
                                                                value="{{item.value}}"
                                                                *ngFor="let item of DialysisUnits"
                                                            >
                                                                {{item.text}}
                                                            </option>
                                                        </select>

                                                        <span
                                                            *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'"
                                                        >
                                                            Required!
                                                        </span>

                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                  <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Filter Date:</label>
                                                  <div class="col-sm-7" *ngIf="f.dmFiltersId.value > 0">
                                                          <label class="readOnlyText"> {{filterStartDateLabel | date:'dd/MM/yyyy'}}</label>
                                                  </div>
                                                  <div class="col-sm-7"  *ngIf="f.dmFiltersId.value == 0">
                                                          <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                                          class="form-control mdInput" style="float:none"
                                                          angular-mydatepicker name="FilterDate"
                                                          formControlName="FilterDate" [options]="myOptions"
                                                          #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                          required/>
                                                          <span  *ngIf="f.FilterDate.errors?.required && (f.FilterDate.dirty || f.FilterDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                                  Required!
                                                              </span>
                                                  </div>
                                              </div>
                                        </div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                    <label class="col-sm-5 control-label">
                                                            <span class="text-red inputrequired">*</span>Equipment:
                                                    </label>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value > 0"
                                                    >
                                                        <label class="readOnlyText">{{selectedEquipmenttext}}</label>
                                                    </div>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value == 0"
                                                    >

                                                        <select
                                                            class="form-control mdInput"
                                                            formControlName="selectedEquipment"
                                                            name="selectedEquipment"
                                                            (change)="equipmentChanged()"
                                                        >
                                                            <option
                                                                value="{{item.value}}"
                                                                *ngFor="let item of Equipments"
                                                            >
                                                                {{item.text}}
                                                            </option>
                                                        </select>

                                                        <span
                                                            *ngIf="f.selectedEquipment.errors?.required && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'"
                                                        >
                                                            Required!
                                                        </span>
                                                        <span  *ngIf="f.selectedEquipment.errors?.RepairStatus" [ngClass] = "'error'">
                                                            You cannot start a new DM Filter Change, while there is an open Repair still existing in the system.
                                                          </span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                  <label class="col-sm-5 control-label">
                                                          <!-- <span class="text-red inputrequired">*</span> -->
                                                          Station:
                                                  </label>
                                                  <div
                                                      class="col-sm-7"
                                                      *ngIf="f.dmFiltersId.value > 0"
                                                  >
                                                      <label class="readOnlyText">{{selectedDialysisStationtext}}</label>
                                                  </div>
                                                  <div
                                                      class="col-sm-7"
                                                      *ngIf="f.dmFiltersId.value == 0"
                                                  >

                                                      <select
                                                          class="form-control mdInput"
                                                          formControlName="selectedDialysisStation"
                                                          name="selectedDialysisStation"
                                                      >
                                                          <option
                                                              value="{{item.value}}"
                                                              *ngFor="let item of DialysisStation"
                                                          >
                                                              {{item.text}}
                                                          </option>
                                                      </select>

                                                      <span
                                                          *ngIf="f.selectedDialysisStation.errors?.required && (f.selectedDialysisStation.dirty || f.selectedDialysisStation.touched ||  isValidFormSubmitted==false)"
                                                          [ngClass]="'error'"
                                                      >
                                                          Required!
                                                      </span>

                                                  </div>
                                              </div>
                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6" *ngIf="hrsTracking">
                                                    <label class="col-sm-5 control-label">
                                                            <span class="text-red inputrequired">*</span>Dialysis Machine Hours:
                                                    </label>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value > 0"
                                                    >
                                                        <label class="readOnlyText">{{f.DMHours.value}}</label>
                                                    </div>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.dmFiltersId.value == 0"
                                                    >

                                                        <input type="text"
                                                            class="form-control smInput"
                                                            formControlName="DMHours"
                                                            name="DMHours" maxlength="5" (keypress)="numberOnly($event)"
                                                        />

                                                        <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                                        <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>
                                                        <br/>
                                                        <span
                                                            *ngIf="f.DMHours.errors?.required && (f.DMHours.dirty || f.DMHours.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'"
                                                        >
                                                            Required!
                                                        </span>
                                                        <span *ngIf="f.DMHours.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                        <span  *ngIf="f.DMHours.errors?.GreaterHoursOut" [ngClass] = "'error'"><br/>
                                                            Hrs IN => previous Hrs Out
                                                  </span>
                                                  <span *ngIf="check4000HoursValidation()"
                                                  [ngClass]="'error'">
                                                  New Hours >= 4000 from previous Hours Out.
                                                  </span>
                                                    </div>
                                                </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info" style="margin-bottom:0px;">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                        <div class="box-header with-border" style="padding:0px;">
                                            <h3 class="box-title">
                                            Filter Details
                                            </h3>
                                            <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                                        </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">

                                    <div class="row">

                                      <div class="col-sm-12 box-row">
                                        <div class="col-sm-4">
                                            <label class="col-sm-3 control-label">
                                                    <span class="text-red inputrequired">*</span>Filter:
                                            </label>
                                            <div
                                                class="col-sm-9"
                                                *ngIf="f.dmFiltersId.value > 0"
                                            >
                                                <label class="readOnlyText">{{f.Filter.value}}</label>
                                            </div>
                                            <div
                                                class="col-sm-9"
                                                *ngIf="f.dmFiltersId.value == 0"
                                            >

                                                <select
                                                    class="form-control mdInput"
                                                    formControlName="Filter"
                                                    name="Filter"
                                                    (change)="GetequipmentCodeDropDownChanged()"
                                                >
                                                    <option
                                                        value="{{item.value}}"
                                                        *ngFor="let item of FilterList"
                                                    >
                                                        {{item.text}}
                                                    </option>
                                                </select>

                                                <span
                                                    *ngIf="f.Filter.errors?.required && (f.Filter.dirty || f.Filter.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="col-sm-6 control-label">
                                                    <span class="text-red inputrequired">*</span>DiaSafe Plus # 1 Lot #:
                                            </label>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="f.dmFiltersId.value > 0"
                                            >
                                                <label class="readOnlyText">{{f.FilterLotNumber.value}}</label>
                                            </div>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="f.dmFiltersId.value == 0"
                                            >

                                                <input
                                                    class="form-control mdInput"
                                                    formControlName="FilterLotNumber"
                                                    name="FilterLotNumber"
                                                    (change)="copyLotNumber()"
                                                >

                                                <span
                                                    *ngIf="f.FilterLotNumber.errors?.required && (f.FilterLotNumber.dirty || f.FilterLotNumber.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="col-sm-6 control-label">
                                                    <span class="text-red inputrequired">*</span>DiaSafe Plus # 2 Lot #:
                                            </label>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="f.dmFiltersId.value > 0"
                                            >
                                                <label class="readOnlyText">{{f.FilterSecondLotNumber.value}}</label>
                                            </div>
                                            <div
                                                class="col-sm-6"
                                                *ngIf="f.dmFiltersId.value == 0"
                                            >

                                                <input
                                                    class="form-control mdInput"
                                                    formControlName="FilterSecondLotNumber"
                                                    name="FilterSecondLotNumber"
                                                    style="max-width: 165px;"
                                                >

                                                <span
                                                    *ngIf="f.FilterSecondLotNumber.errors?.required && (f.FilterSecondLotNumber.dirty || f.FilterSecondLotNumber.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>

                                            </div>

                                        </div>
                                    </div>

                                    </div>

                                </div>
                            </div>

                            <div *ngIf="FilterRecord != null">

                                    <div class="box ibox box-info">
                                            <div class="box-header with-border">
                                                    <h3 class="box-title">Parts</h3>
                                                </div>
                                                <div class="box-body">
                                    <div class="row">
                                            <div class="col-sm-12 box-row">
                                        <div class="col-sm-1 text-center">
                                           <strong>Part ID</strong>
                                        </div>
                                        <div class="col-sm-4 text-center">
                                        <strong>Part Description</strong>
                                        </div>
                                        <div class="col-sm-2 text-center">
                                        <strong>Part Quantity</strong>
                                        </div>
                                        <div class="col-sm-2 text-center">
                                        <strong>Part Cost</strong>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="box-body">
                                            <div class="row">
                                                    <div class="col-sm-12 box-row">
                                                              <div class="col-sm-1 text-center">
                                                                              {{FilterRecord.equipmentCode}}
                                                                           </div>
                                                                           <div class="col-sm-4 text-center">
                                                                                {{FilterRecord.equipmentName}}
                                                                                   </div>

                                                <div class="col-sm-2 text-center">
                                                    {{FilterRecord.quantity}}
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    {{FilterRecord.itemCost * FilterRecord.quantity | currency:"CAD":"symbol-narrow"}}
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                            </div></div></div>
                            <div class="box ibox box-info" style="margin-bottom:0px;">
                              <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                    <h4 class="panel-title">
                                    <div class="box-header with-border" style="padding:0px;">
                                        <h3 class="box-title">
                                            Equipment OUT Details
                                        </h3>
                                        <!-- <h3 class="box-title pull-right"><a  href="javascript:void(0);" (click)="ShowTroubleshooting($event)"><strong>Troubleshooting</strong></a></h3> -->
                                    </div>
                                    </h4>
                                </div>
                              </div>

                              <div class="box-body">

                                  <div class="row">

                                    <div class="col-sm-12 box-row">
                                      <div class="col-sm-6">
                                          <label class="col-sm-5 control-label">
                                                <span class="text-red inputrequired">*</span>Out Hydraulic Disinfection:
                                          </label>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.dmFiltersId.value > 0"
                                          >
                                              <label class="readOnlyText">{{f.Disinfection.value}}</label>
                                          </div>
                                          <div
                                              class="col-sm-7"
                                              *ngIf="f.dmFiltersId.value == 0"
                                          >

                                              <select
                                                  class="form-control mdInput"
                                                  formControlName="Disinfection"
                                                  name="Disinfection"
                                              >
                                                  <option
                                                      value="{{item.value}}"
                                                      *ngFor="let item of DisinfectionList"
                                                  >
                                                      {{item.text}}
                                                  </option>
                                              </select>

                                              <span
                                                  *ngIf="f.Disinfection.errors?.required && (f.Disinfection.dirty || f.Disinfection.touched ||  isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"
                                              >
                                                  Required!
                                              </span>

                                          </div>
                                      </div>

                                  </div>
                                  <div class="clearfix"></div>
                                  </div>

                              </div>
                          </div>

                            <notes
                                [isValidForm]="this.FilterForm.invalid"
                                (addNoteClick)="executeFilterCreateUpdate('Notes')"
                                #notes
                            >

                            </notes>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        (addNoteClick)="notes.AddNote()"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeFilterCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

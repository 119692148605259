<form [formGroup]="TransferForm" novalidate>

    <section class="content-header">
        <h1>
            Transfer
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">
                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                    (SaveEvent)="executeTransferCreateUpdate('')" #pageheader></PageHeader>


                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="box ibox box-info">
                                <div class="box-header with-border">
                                    <div class="col-sm-12 box-row">
                                        <label class="col-sm-3 control-label">Transfer Type:</label>
                                        <div class="col-sm-3" *ngIf="editMode()">
                                            <label class="readOnlyText">{{f.transferType.value}}</label>
                                        </div>
                                        <div class="col-sm-3" *ngIf="!editMode()">
                                            <mat-button-toggle-group formControlName="transferType" name="transferType"
                                                form #tasktypegroup="matButtonToggleGroup"
                                                (change)="onTransferTabChange()">
                                                <mat-button-toggle value="Transfer" class="btnsuccess col-sm-6">
                                                    {{onText}}
                                                </mat-button-toggle>
                                                <mat-button-toggle class="btndanger" value="Exchange">
                                                    {{offText}}
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                        <label class="col-sm-3 control-label">Transfer ID:</label>
                                        <div class="col-sm-3">
                                            <label class="readOnlyText">{{f.transferId.value ? f.transferId.value :
                                            '[AutoNumber]' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                    <h4 class="panel-title">
                                    <div class="box-header with-border" style="padding:0px;">
                                        <h3 class="box-title">Equipment Transfer Details</h3>
                                    </div>
                                    </h4>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <label class="col-sm-3 control-label">Form Date:</label>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <input maxlength="10" class="form-control mdInput"
                                                    value="{{formDate | date:'dd/MM/yyyy'}}" name="formDate"
                                                    formControlName="formDate">

                                            </div>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{formDate | date:'dd/MM/yyyy'}}</label>
                                            </div>

                                        </div>
                                        <div class="col-sm-12 box-row">
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Transfer Date:</label>

                                            <div class="col-sm-3" *ngIf="f.transferType.value == 'Exchange'">
                                                <input (focus)="t.toggleCalendar()" (click)="t.openCalendar()"
                                                    *ngIf="!editMode()" class="col-sm-2 form-control mdInput"
                                                    style="float:none" placeholder="Select a date" angular-mydatepicker
                                                    name="transferDate" formControlName="transferDate"
                                                    [options]="myOptions" #t="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" style="max-width: 165px;" />

                                                <label class="readOnlyText" *ngIf="editMode()">
                                                    {{ dateTransferDateText |
                                                        date:'dd/MM/yyyy'}}</label>

                                                <input type="checkbox" name="previousTransfer"
                                                    class="iCheck-helper mdInput" (change)="OnChangePriorDate()"
                                                    formControlName="previousTransfer" style="margin-left:5px">
                                                <label class="control-label">Prior Date</label>
                                            </div>

                                            <label class="col-sm-3 control-label">Transfer User:</label>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <input maxlength="50" class="form-control mdInput"
                                                    value="{{transferUser}}" disabled>
                                            </div>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{transferUser}}</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 box-row">
                                            <label class="col-sm-3 control-label">
                                                    Category:
                                            </label>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{categoryInName}}</label>
                                            </div>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <select class="form-control mdInput" formControlName="categoryId"
                                                    name="categoryId" (change)="categoryInDropDownChanged()">
                                                    <option value="{{item.value}}" *ngFor="let item of CategoryList">
                                                        {{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.categoryId.errors?.required && (f.categoryId.dirty || f.categoryId.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 box-row">
                                            <!-- <label class="col-sm-3  col-offset-1 control-label"
                                                *ngIf="f.transferType.value == 'Transfer'"><span
                                                    class="text-red inputrequired">*</span>Equipment transfered
                                                from:</label> -->
                                            <label class="col-sm-3 col-offset-1 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment transfered IN
                                                from:</label>
                                            <div class="col-sm-3"
                                                *ngIf="editMode() && f.transferType.value == 'Exchange'">
                                                <label class="readOnlyText">
                                                    {{divisionFromName}}</label>
                                            </div>
                                            <div class="col-sm-3"
                                                *ngIf="!editMode() && f.transferType.value == 'Exchange'">
                                                <select class="form-control mdInput" formControlName="divisionFrom"
                                                    name="divisionFrom" (change)="DialysisUnitFromDropDownChanged()">
                                                    <option value="{{item.value}}" *ngFor="let item of PresentDivision">
                                                        {{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.divisionFrom.errors?.required && (f.divisionFrom.dirty || f.divisionFrom.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>

                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Transfer'">Equipment transfered
                                                to:</label>
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment transfered OUT
                                                to:</label>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{divisionToName}}</label>
                                            </div>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <select class="form-control mdInput" formControlName="divisionTo"
                                                    name="divisionTo" (change)="DialysisUnitToDropDownChanged()">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of transferedDivision">{{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.divisionTo.errors?.required && (f.divisionTo.dirty || f.divisionTo.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>

                                        </div>


                                        <div class="col-sm-12 box-row">
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Transfer'">Equipment Transfered:
                                            </label>
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment In:
                                            </label>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{f.equipmentName.value}}</label>
                                            </div>
                                            <div class="col-sm-3" *ngIf="!editMode()">

                                                <select class="form-control mdInput" formControlName="equipmentId"
                                                    name="equipmentId" (change)="onEquipmentChanged()">
                                                    <option value="{{item.value}}" *ngFor="let item of DialysisMachine">
                                                        {{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.equipmentId.errors?.required && (f.equipmentId.dirty || f.equipmentId.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>

                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment Out:</label>

                                            <div class="col-sm-3"
                                                *ngIf="editMode() && f.transferType.value == 'Exchange'">
                                                <label class="readOnlyText">
                                                    {{f.exchEquipmentName.value}}</label>
                                            </div>
                                            <div class="col-sm-3"
                                                *ngIf="!editMode() && f.transferType.value == 'Exchange'">
                                                <select class="form-control mdInput" formControlName="exchEquipmentId"
                                                    name="exchEquipmentId" (change)="onEquipmentOutChanged()">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of EquipmentOutList">
                                                        {{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.exchEquipmentId.errors?.required && (f.exchEquipmentId.dirty || f.exchEquipmentId.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 box-row" style="display: none;">
                                            <label class="col-sm-3 control-label"><span
                                                    class="text-red inputrequired">*</span>Disinfection
                                                Performed?:</label>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{f.disinfectionOut.value}}</label>
                                            </div>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <select class="form-control mdInput" formControlName="disinfectionOut"
                                                    name="disinfectionOut" (change)="DialysisUnitDropDownChanged()">
                                                    <option value="{{item.value}}"
                                                        *ngFor="let item of disinfectionOptions">{{item.text}}</option>
                                                </select>
                                                <span
                                                    *ngIf="f.disinfectionOut.errors?.required && (f.disinfectionOut.dirty || f.disinfectionOut.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 box-row">
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Transfer'">Equipment transfered
                                                Hours:</label>
                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment In Hours:</label>
                                            <div class="col-sm-3" *ngIf="editMode()">
                                                <label class="readOnlyText">
                                                    {{f.hours.value}}</label>

                                            </div>
                                            <div class="col-sm-3" *ngIf="!editMode()">
                                                <input maxlength="5" class="form-control smInput"
                                                    name="hours" formControlName="hours" (keypress)="numberOnly($event)">
                                                <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                                <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.equipmentId.value">(N/A)</label>
                                                <br/>
                                                <span *ngIf="f.hours.errors?.pattern" [ngClass]="'error'">Invalid
                                                    number</span>
                                                <span
                                                    *ngIf="f.hours.errors?.required && (f.hours.dirty || f.hours.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span  *ngIf="f.hours.errors?.GreaterHoursOut" [ngClass] = "'error'">
                                                    Hrs IN => previous Hrs Out 
                                                </span>
                                                <span *ngIf="check4000HoursValidation(false)"
                                                [ngClass]="'error'">
                                                <br>
                                                New Hours >= 4000 from previous Hours Out.
                                                </span>
                                            </div>


                                            <label class="col-sm-3 control-label"
                                                *ngIf="f.transferType.value == 'Exchange'">Equipment Out Hours:</label>
                                            <div class="col-sm-3"
                                                *ngIf="editMode() && f.transferType.value == 'Exchange'">
                                                <label class="readOnlyText">
                                                    {{f.exchHours.value}}</label>
                                            </div>
                                            <div class="col-sm-3"
                                                *ngIf="!editMode() && f.transferType.value == 'Exchange'">
                                                <input class="form-control smInput" name="exchHours"
                                                    maxlength="5" formControlName="exchHours" (keypress)="numberOnly($event)">
                                                    <label class="readOnlyTextForHours" *ngIf="selectedExchEquipmentHours!=0 && selectedExchEquipmentHours!=null">({{selectedExchEquipmentHours}})</label>
                                                    <label class="readOnlyTextForHours" *ngIf="selectedExchEquipmentHours==0 && f.equipmentId.value">(N/A)</label>
                                                    <br/>
                                                <span  *ngIf="f.exchHours.errors?.MAXHoursOut" [ngClass] = "'error'">
                                                    Hrs IN => previous Hrs Out 
                                                </span>
                                                <span *ngIf="f.exchHours.errors?.pattern" [ngClass]="'error'">Invalid
                                                    number</span>
                                                <span
                                                    *ngIf="f.exchHours.errors?.required && (f.exchHours.dirty || f.exchHours.touched ||  formSubmitted)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span *ngIf="check4000HoursValidation(true)"
                                                [ngClass]="'error'">
                                                <br>
                                                New Hours >= 4000 from previous Hours Out.
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <notes [isValidForm]="this.TransferForm.invalid"
                            (addNoteClick)="executeTransferCreateUpdate('Notes')" #notes></notes>
                        <attachment #attachment name="attachmentdmtransfer">
                        </attachment>
                    </div>


                </div>
            </div>

        </div>
        <PageHeader   [showNote]="false" (addNoteClick)="notes.AddNote()" [mainlisturl]="ListURL"
            [showAddNew]="false" [showSearch]="false" [showSave]="true" (SaveEvent)="executeTransferCreateUpdate()"
            [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
    </section>
</form>

<form
    [formGroup]="PagerForm"
    (ngSubmit)="executePagerCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Paging Reports
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    (addNoteClick)="notes.AddNote()"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executePagerCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">PagersId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="PagersId"
                                        [value]="f.PagersId"
                                        formControlName="PagersId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">

                                <div class="box-body">
                                    <div class="row">
                                        <div
                                            class="col-sm-12 box-row"

                                        >
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Report ID:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{ReportID}}</label>
                                                </div>
                                            </div>
                                            <div
                                                class="col-sm-6"

                                            >
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>On-Call Staff:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{selectedUsertext}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-sm-12 box-row"
                                            *ngIf="f.PagersId.value > 0"
                                        >
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>
                                                    Form Date:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">Form Time:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'HH:mm'}}
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Division:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0"
                                                >
                                                    <!-- " -->
                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Paged Date:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.PagedDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0"
                                                >

                                                <input (focus)="rPagedDate.toggleCalendar()" (click)="rPagedDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none"
                                                angular-mydatepicker name="PagedDate"
                                                  formControlName="PagedDate" [options]="myOptions"
                                                  #rPagedDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                required/>

                                                    <span
                                                        *ngIf="f.PagedDate.errors?.required && (f.PagedDate.dirty || f.PagedDate.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>

                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Paged Time:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.PagedTime.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0"
                                                >

                                                  <input formControlName="PagedTime" Name="PagedTime" placeholder="Select a time" class="form-control mdInput" [ngxTimepicker]="fullTime" [format]="24"   readonly >
                                                  <ngx-material-timepicker #fullTime></ngx-material-timepicker>
                                                  <span
                                                  *ngIf="f.PagedTime.errors?.required && (f.PagedTime.dirty || f.PagedTime.touched ||  isValidFormSubmitted==false)"
                                                  [ngClass]="'error'"
                                              >
                                                  Required!
                                              </span>
                                                <!-- <select
                                                class="form-control smInput"
                                                formControlName="PagedHour"
                                                name="PagedHour"
                                            >
                                                <option
                                                    value="{{item.value}}"
                                                    *ngFor="let item of HourList"
                                                >
                                                    {{item.text}}
                                                </option>
                                            </select>

                                            <span
                                                *ngIf="f.PagedHour.errors?.required && (f.PagedHour.dirty || f.PagedHour.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'"
                                            >
                                                Required!
                                            </span> :
                                            <select
                                            class="form-control smInput"
                                            formControlName="PagedMinute"
                                            name="PagedMinute"
                                        >

                                            <option
                                                value="{{item.value}}"
                                                *ngFor="let item of MinuteList"
                                            >
                                                {{item.text}}
                                            </option>
                                        </select>
                                                    <span
                                                        *ngIf="f.PagedMinute.errors?.required && (f.PagedMinute.dirty || f.PagedMinute.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span> -->

                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">Paging Staff:</label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{SelectedPagingStaffName}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0"
                                                >

                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="SelectedPagingStaff"
                                                        name="SelectedPagingStaff"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of UserList"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.SelectedPagingStaff.errors?.required && (f.SelectedPagingStaff.dirty || f.SelectedPagingStaff.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">
                                                    <span class="text-red inputrequired">*</span>Issue:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{f.selectedIssue.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0"
                                                >

                                                    <select
                                                        type="text"
                                                        class="form-control mdInput"
                                                        formControlName="selectedIssue"
                                                        name="selectedIssue"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of IssueDropDownList"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedIssue.errors?.required && (f.selectedIssue.dirty || f.selectedIssue.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div
                                            class="col-sm-12 box-row"

                                        >
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">                                                    
                                                    Issue Status:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value > 0 &&  f.selectedIssueStatus.value == 'Issue Solved'
                                                    && selectedIssueStatus == 'Issue Solved'"
                                                >
                                                    <label class="readOnlyText">{{f.selectedIssueStatus.value}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.PagersId.value == 0 ||  f.selectedIssueStatus.value != 'Issue Solved'
                                                    || selectedIssueStatus != 'Issue Solved'"

                                                >

                                                    <select
                                                        type="text"
                                                        class="form-control mdInput"
                                                        formControlName="selectedIssueStatus"
                                                        name="selectedIssueStatus"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of IssueStatusDropDownList"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedIssueStatus.errors?.required && (f.selectedIssueStatus.dirty || f.selectedIssueStatus.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>

                            <notes
                                [isValidForm]="this.PagerForm.invalid"
                                (addNoteClick)="executePagerCreateUpdate('Notes')"
                                #notes
                            >

                            </notes>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        (addNoteClick)="notes.AddNote()"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executePagerCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

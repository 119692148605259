import { Component } from '@angular/core';
import { AppComponent } from "../app.component";
import { Globals } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
@Component({
  selector: 'forum',
  templateUrl: './forum.component.html',

})
export class ForumComponent {
  url='';
  constructor( private authentication: AuthService,public globals: Globals,public sanitizer: DomSanitizer){}
  ngOnInit() {
    this.getURL();
  }
  getURL()
  {


    AppComponent.ShowLoader();
    this.globals.internalForumUrl = this.globals.internalForumUrl + "AutoLogin.aspx?username="+ this.authentication.GetUserName() + "&email="+ this.authentication.GetUserEmail();
         //console.log(this.globals.internalForumUrl);
        AppComponent.HideLoader();


  }
}

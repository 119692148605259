import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class FieldMasterApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    GetModelSerachDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/GetModelSerachList',
        SearchOptions,
        httpOptions
      );
    }

    GetFieldMasterSerachDetails(SearchOptions) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/GetFieldMasterSerachDetails',
        SearchOptions,
        httpOptions
      );
    }

    

    CreateFieldMaster(fieldMasterDetails) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/CreateFieldMasterDetails',
        fieldMasterDetails,
        httpOptions
      );
    }


    UpdateFieldMasterDetails(fieldMasterDetails) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/UpdateFieldMasterDetails',
        fieldMasterDetails,
        httpOptions
      );
    }

    DeleteFieldMasterDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/DeleteFieldMasterDetails',
        value,
        httpOptions
      );
    }

    GetFieldMasterByID(fieldMaster) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/GetFieldMasterByID',
        fieldMaster,
        httpOptions
      );
    }
    

    GetFieldMasterEquipmentsByID(fieldMaster) {
      return this.http.post<any>(
        this.apiUrl + 'FieldMasterService/GetFieldMasterEquipmentsByID',
        fieldMaster,
        httpOptions
      );
    }

    GetFieldMasterNotes(value) {
      return this.http.post<any>(
        this.apiUrl +
          'FieldMasterService/GetFieldMasterNotes',
        value,
        httpOptions
      );
    }

    GetFieldMasterAttachments(value) {
      return this.http.post<any>(
        this.apiUrl +
          'FieldMasterService/GetFieldMasterAttachments',
        value,
        httpOptions
      );
    }

  }

import { DMTransferDetailComponent } from './DialysisMachines/DMTransfer/DMTransferDetail.component';
import { getMatTooltipInvalidPositionError, MatTooltipDefaultOptions } from '@angular/material/tooltip';


export class Globals {
    static FormErrorMessage = 'Please check all required fields are populated before continuing.';
    static NumberRegexSingleDecimal = '^[0-9]+(.[0-9]{1,1})?$';
    static NumberRegexSFourPrecisionSingleFraction = '^([0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9])(.[0-9]{1,1})?$';
    static  NumberRegexSinglePrecisionSingleFraction = '^([0-9])(.[0-9]{1,1})?$';
    static NumberRegexDoublePrecisionSingleFraction = '^([0-9]|[0-9][0-9])(.[0-9]{1,1})?$';
    static NumberRegexSinglePrecisionDoubleFraction = '^([0-9])(.[0-9]{1,2})?$';
    static NumberRegexSingleDecimalwithMax = '^([0-9]|[0-9][0-9])(.[0-9]{1,2})?$';
    static NumberRegexDoublePrecisionDoubleFraction = '^([0-9]|[0-9][0-9])(.[0-9]{1,2})?$';
    static NumberRegexThreePrecisionThreeFraction = '^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])(.[0-9]{1,3})?$';

    static PhoneNumberRegex = "^([a-zA-Z0-9_ ()-]){10,}$";
    static AlphaNumericRegex = "^[a-zA-Z0-9/\.]*$";


    static NumberRegex = '^[0-9]+(.[0-9]{0,4})?$';
    static TwoNumberRegex = '^[0-9]|[0-9][0-9]$';
    static ThreeNumberRegex = '^[0-9]|[0-9][0-9]|[0-9][0-9][0-9]$';
    static FIVENumberPrecisionDoubleFractionRegex = '^([0-9]|[0-9][0-9]|[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|[0-9][0-9][0-9][0-9][0-9])(.[0-9]{1,2})?$';
    static NumberNumericRegexWithoutZero = '^[1-9][0-9]*$';
    static NumberNumericRegex = '^[0-9]+?$';
    static EmailRegex = '^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    static HomeStationsRegex = '^MRM[0-9]{3,3}[A-Z]{3,3}$';
    static StationsRegex = '^[A-Z0-9]{1,4}\-[0-9]{1,2}[A-Z]?$';
    static MinDigit = '^(\[0-9]{5})?$';
    // static PasswordRegx = '.*(?=.{8,})(?=.*\[0-9]).*';
    static PasswordRegx = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\[0-9]).{8,}$';
    static PostalcodeRegx = '^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$';
    static PostalcodeReg = '^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$';
    static PostalcodeAlphaRegx = '^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$';
    static TimeRegex = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

    static defaultTimeForAlert = '3000'; // 3 seconds
    static defaultTimeForErrorAlert = '15000';  // 15 seconds
    static invalidSessionUrl = 'http://bmrtw.com';

    static textColor = '#555';

    //   Live Section.
    //   =========================================================

    //  apiUrl:string='http://192.168.1.4/api/';
    //  static oldUrl:string='https://scscduorg.mrmrenal.com';
    //  static apiUrl:string='https://scscdu.mrmrenal.com/core/api/';
    //  static AttachmentUrl:string='https://scscdu.mrmrenal.com/core/uploads/';
    //  static DocumentResourcesUrl:string='https://scscdu.mrmrenal.com/core/DocumentResources/';
    //  static Version='publish';
    // static WorkOrderPFDsUrl:string='https://scscdu.mrmrenal.com/core/workorderpdf/';
    // static mrmrenalwebsite ='https://www.mrmrenal.com/';
    // static mrmrenalLogOutwebsite ='https://www.mrmrenal.com/sso/LogOut';
    // static Website='SC';


    //   Staging Section.
    //   =========================================================
      // static oldUrl:string='https://scscduorgstaging.mrmrenal.com';
      // static apiUrl:string='https://technical.mrmrenal.com/core/api/';
      // static AttachmentUrl:string='https://technical.mrmrenal.com/core/uploads/';
      // static DocumentResourcesUrl:string='https://technical.mrmrenal.com/core/DocumentResources/';
      // static Version='publish';
      // static mrmrenalwebsite ='https://www.mrmrenal.com/';
      // static mrmrenalLogOutwebsite ='https://technical.mrmrenal.com/sso/LogOut';
      // static WorkOrderPFDsUrl:string='https://technical.mrmrenal.com/core/workorderpdf/';
      //static Website='SC';

       //   SCSCDU Live Section.
    //   =========================================================
    // static CurrentSiteUrl:string='https://scscduT.mrmrenal.com';
    // static oldUrl:string='https://scscduorg.mrmrenal.com';
    // static apiUrl:string='https://scscduT.mrmrenal.com/core/api/';
    // static AttachmentUrl:string='https://scscduT.mrmrenal.com/core/uploads/';
    // static DocumentResourcesUrl:string='https://scscduT.mrmrenal.com/core/DocumentResources/';
    // static Version = 'publish';
    // static Website = 'SCSCDU';
    // static siteName = 'SCSCDU';
    // static WebsiteType = 'Live';
    // static LiveUrl:string='https://scscduT.mrmrenal.com/';
    // static StagingUrl:string='https://technical.mrmrenal.com/';

    // static mrmrenalwebsite ='https://mrmrenal.com/';
    // //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
    // static mrmrenalLogOutwebsite ='https://mrmrenal.com';
    // static WorkOrderPFDsUrl:string='https://scscduT.mrmrenal.com/core/workorderpdf/';
    // static logoPath:string='../../assets/img/scscduCOMBOLOGO.png';
    // static OldLiveUrl:string='https://scscdu.mrmrenal.com/';

    // // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
    // internalForumUrl = 'https://scscduTF.mrmrenal.com/';
    // static internalForumAPI = 'https://scscduTF.mrmrenal.com/';


    //   SCSCDU Staging Section.
    //   =========================================================
//     static CurrentSiteUrl:string='https://technical.mrmrenal.com';
//     static oldUrl:string='https://scscduorg.mrmrenal.com';
//     static apiUrl:string='https://technical.mrmrenal.com/core/api/';
//     static AttachmentUrl:string='https://technical.mrmrenal.com/core/uploads/';
//     static DocumentResourcesUrl:string='https://technical.mrmrenal.com/core/DocumentResources/';
//     static Version = 'publish';
//     static Website = 'SCSCDU';
//     static siteName = 'SCSCDU';
//     static WebsiteType = 'Staging';
//     static LiveUrl:string='https://scscduT.mrmrenal.com/';
//     static StagingUrl:string='https://technical.mrmrenal.com/';

//     static mrmrenalwebsite ='https://mrmrenal.com/';
//     //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
//     static mrmrenalLogOutwebsite ='https://mrmrenal.com';
//     static WorkOrderPFDsUrl:string='https://technical.mrmrenal.com/core/workorderpdf/';
//     static logoPath:string='../../assets/img/scscduCOMBOLOGO.png';
//     static OldLiveUrl:string='https://scscdu.mrmrenal.com/';

//     // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
//     internalForumUrl = 'https://scscduTF.mrmrenal.com/';
//     static internalForumAPI = 'https://scscduTF.mrmrenal.com/';

                   //   UHN Staging Section.
    //   =========================================================
//     static CurrentSiteUrl:string='https://uhnS.mrmrenal.com';

//     static oldUrl:string='https://GrandRiverO.mrmrenal.com';
//     static apiUrl:string='https://uhnS.mrmrenal.com/core/api/';
//     static AttachmentUrl:string='https://uhnS.mrmrenal.com/core/uploads/';
//     static DocumentResourcesUrl:string='https://uhnS.mrmrenal.com/core/DocumentResources/';
//     static Version = 'publish';
//     static Website = 'UHN';
//     static siteName = 'UHN';
//     static WebsiteType = 'Staging';
//     static LiveUrl:string='https://uhnT.mrmrenal.com/';
//     static StagingUrl:string='https://uhnS.mrmrenal.com/';

//     static mrmrenalwebsite ='https://mrmrenal.com/';
//     //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
//     static mrmrenalLogOutwebsite ='https://mrmrenal.com';
//     static WorkOrderPFDsUrl:string='https://uhnS.mrmrenal.com/core/workorderpdf/';
//     static logoPath:string='../../assets/img/logo-uhn.png';
//     static OldLiveUrl:string='';

//     // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
//     internalForumUrl = 'https://uhntf.mrmrenal.com/';
// static internalForumAPI = 'https://uhntf.mrmrenal.com/';


               //   UHN Live Section.
    //   =========================================================
//     static CurrentSiteUrl:string='https://uhnT.mrmrenal.com';

//     static oldUrl:string='https://GrandRiverO.mrmrenal.com';
//     static apiUrl:string='https://uhnT.mrmrenal.com/core/api/';
//     static AttachmentUrl:string='https://uhnT.mrmrenal.com/core/uploads/';
//     static DocumentResourcesUrl:string='https://uhnT.mrmrenal.com/core/DocumentResources/';
//     static Version = 'publish';
//     static Website = 'UHN';
//     static siteName = 'UHN';
//     static WebsiteType = 'Live';
//     static LiveUrl:string='https://uhnT.mrmrenal.com/';
//     static StagingUrl:string='https://uhnS.mrmrenal.com/';

//     static mrmrenalwebsite ='https://mrmrenal.com/';
//     //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
//     static mrmrenalLogOutwebsite ='https://mrmrenal.com';
//     static WorkOrderPFDsUrl:string='https://uhnT.mrmrenal.com/core/workorderpdf/';
//     static logoPath:string='../../assets/img/logo-uhn.png';
//     static OldLiveUrl:string='';

//     // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
//     internalForumUrl = 'https://uhntf.mrmrenal.com/';
// static internalForumAPI = 'https://uhntf.mrmrenal.com/';


            //   MRM Staging Demo Section.
    //   =========================================================
//       static CurrentSiteUrl:string='https://demo.mrmrenal.com';
//       static oldUrl:string='https://GrandRiverO.mrmrenal.com';
//       static apiUrl:string='https://demo.mrmrenal.com/core/api/';
//       static AttachmentUrl:string='https://demo.mrmrenal.com/core/uploads/';
//       static DocumentResourcesUrl:string='https://demo.mrmrenal.com/core/DocumentResources/';
//       static Version = 'publish';
//       static Website = 'MRMDemo';
//       static siteName = 'MRM Demo';
//       static WebsiteType = 'Demo';
//       static LiveUrl:string='https://demo.mrmrenal.com/';
//       static StagingUrl:string='https://demo.mrmrenal.com/';
//       static mrmrenalwebsite ='https://mrmrenal.com/';
//       //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
//       static mrmrenalLogOutwebsite ='https://mrmrenal.com';
//       static WorkOrderPFDsUrl:string='https://demo.mrmrenal.com/core/workorderpdf/';
//       static logoPath:string='../../assets/img/MRM2.png';
// static OldLiveUrl:string='';
//       // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
//       internalForumUrl = 'https://uhntf.mrmrenal.com/';
// static internalForumAPI = 'https://uhntf.mrmrenal.com/';

      //   Grand River Live Section.
    //   =========================================================
  //   static CurrentSiteUrl:string='https://GrandRiverT.mrmrenal.com';

  //   static oldUrl:string='https://GrandRiverO.mrmrenal.com';
  //   static apiUrl:string='https://GrandRiverT.mrmrenal.com/core/api/';
  //   static AttachmentUrl:string='https://GrandRiverT.mrmrenal.com/core/uploads/';
  //   static DocumentResourcesUrl:string='https://GrandRiverT.mrmrenal.com/core/DocumentResources/';
  //   static Version='publish';
  //   static Website='GR';
  //   static siteName = 'Grand River';
  //   static WebsiteType = 'Live';
  //   static LiveUrl:string='https://GrandRiverT.mrmrenal.com/';
  //   static StagingUrl:string='https://GrandRiverS.mrmrenal.com/';
  //   static mrmrenalwebsite ='https://grandriverR.mrmrenal.com/';
  //   //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
  //   static mrmrenalLogOutwebsite ='https://mrmrenal.com';
  //   static WorkOrderPFDsUrl:string='https://GrandRiverT.mrmrenal.com/core/workorderpdf/';
  //   static logoPath:string='../../assets/img/GRH.png';
  //   static OldLiveUrl:string='https://GrandRiverT.mrmrenal.com/';

  // //internalForumUrl = 'https://grandrivert.mrmrenal.com/forum/';
  //   internalForumUrl = 'https://foruminternal.mrmrenal.com/';
  //   static internalForumAPI = 'https://foruminternal.mrmrenal.com/';

      //   Grand River Staging Section.
    //   =========================================================
      // static CurrentSiteUrl:string='https://GrandRiverS.mrmrenal.com';

      // static oldUrl:string='https://GrandRiverO.mrmrenal.com';
      // static apiUrl:string='https://GrandRiverS.mrmrenal.com/core/api/';
      // static AttachmentUrl:string='https://GrandRiverS.mrmrenal.com/core/uploads/';
      // static DocumentResourcesUrl:string='https://GrandRiverS.mrmrenal.com/core/DocumentResources/';
      // static Version='publish';
      // static Website='GR';
      // static siteName = 'Grand River';
      // static WebsiteType = 'Staging';
      // static LiveUrl:string='https://GrandRiverT.mrmrenal.com/';
      // static StagingUrl:string='https://GrandRiverS.mrmrenal.com/';
      // static mrmrenalwebsite ='https://grandriverR.mrmrenal.com/';
      // //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
      // static mrmrenalLogOutwebsite ='https://mrmrenal.com';
      // static WorkOrderPFDsUrl:string='https://GrandRiverS.mrmrenal.com/core/workorderpdf/';
      // static logoPath:string='../../assets/img/GRH.png';
      // static OldLiveUrl:string='https://GrandRiverS.mrmrenal.com/';

      // //internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
      // internalForumUrl = 'https://foruminternal.mrmrenal.com/';
      // static internalForumAPI = 'https://foruminternal.mrmrenal.com/';


      
            //   BMR Technical Demo Section.
    //   =========================================================
      static CurrentSiteUrl:string='https://demo.bmrtw.com';
      static oldUrl:string='https://demo.bmrtw.com';
      static apiUrl:string='https://demo.bmrtw.com/core/api/';
      static AttachmentUrl:string='https://demo.bmrtw.com/core/uploads/';
      static DocumentResourcesUrl:string='https://demo.bmrtw.com/core/DocumentResources/';
      static Version = 'publish';
      static Website = 'MRMDemo';
      static siteName = 'MRM Demo';
      static WebsiteType = 'Demo';
      static LiveUrl:string='https://demo.bmrtw.com/';
      static StagingUrl:string='https://demo.bmrtw.com/';
      static mrmrenalwebsite ='https://demo.bmrtw.com/';
      //static mrmrenalLogOutwebsite ='https://GrandRiverT.mrmrenal.com/sso/LogOut';
      static mrmrenalLogOutwebsite ='https://bmrtw.com';
      static WorkOrderPFDsUrl:string='https://demo.bmrtw.com/core/workorderpdf/';
      static logoPath:string='../../assets/img/MRM2.png';
static OldLiveUrl:string='';
      // internalForumUrl = 'https://grandrivers.mrmrenal.com/forum/';
      internalForumUrl = '#';
static internalForumAPI = '#';



      //   Grand River Local Section.
    //   =========================================================
  //   static CurrentSiteUrl:string='http://localhost:4200';

  //   static mrmrenalwebsite = 'https://www.mrmrenal.com/';
  //   // static mrmrenalwebsite = 'http://localhost:44444';
  //   static mrmrenalLogOutwebsite = 'https://www.mrmrenal.com/sso/LogOut';
  //   static Version = 'local';
  //   static apiUrl = 'http://localhost:36657/api/';
  //  // static apiUrl:string='https://GrandRiverS.mrmrenal.com/core/api/';
  //   static AttachmentUrl = 'http://localhost:36657/uploads/';
  //   static DocumentResourcesUrl = 'http://localhost:36657/DocumentResources/';
  //   static oldUrl = 'https://scscduorgstaging.mrmrenal.com';
  //   static WorkOrderPFDsUrl = 'http://localhost:36657/core/workorderpdf/';
  //   // static Website = 'SCSCDU';
  //   // static siteName = 'SCSCDU';
  //     //   static Website='GR';
  //     // static siteName = 'Grand River';
  //   // static WebsiteType = 'Live';
  //   static Website = 'MRMDemo';
  //         static siteName = 'MRM Demo';
  //         static WebsiteType = 'Live';
  // static LiveUrl:string='https://GrandRiverT.mrmrenal.com/';
  // static StagingUrl:string='https://GrandRiverS.mrmrenal.com/';
  // static OldLiveUrl:string='https://scscdu.mrmrenal.com/';
  //  internalForumUrl = 'http://localhost:1501/';
  //  static internalForumAPI = 'http://localhost:1501/';



  static lastLoginDateTime = Date.now();
  url = '';


  public static lstHX2Data = [
    // { field: 'GROUP 1', id: '',  value: '', isHeader: true, format: '' },
    // GROUP 1
    { field: 'RO Date:', id: 'LocalDate',  value: '', isHeader: false, format: '' , col: 1 },
    { field: 'RO Time:', id: 'LocalTime',  value: '', isHeader: false, format: '' , col: 1 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 1  },

    { field: 'Main Status:', id: '{[HX2]PROD_OR_SLEEP}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: 'Matrix Mode:', id: '{[HX2]MATRIX_MODE_ACTIVE_INT}',  value: '', isHeader: false, format: '' , col: 1 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 1  },

    { field: 'Lifetime Feed Flow Total (GAL):', id: '{[HX2]CFI9868_GAL.Total}',  value: '', isHeader: false, format: '8' , col: 1 },
    { field: 'Feed Water Control Valve (% Open):', id: '{[HX2]ZC3402.Val_CVOut}',  value: '', isHeader: false, format: '3' , col: 1 },
    { field: 'Prefilter Inlet Pressure (psi):', id: '{[HX2]PI3600.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 1 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 1  },

    { field: 'RO Feed Condo (uS/cm):', id: '{[HX2]AI3635.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 1 },
    { field: '1st Pass Condo (uS/cm):', id: '{[HX2]AI3665.Val}',  value: '', isHeader: false, format: '3.2-2' , col: 1 },
    { field: '1st Pass Recovery (%):', id: '{[HX2]CFI9858.Val}',  value: '', isHeader: false, format: '3' , col: 1 },
    { field: '1st Pass Array Recovery (%):', id: '{[HX2]CFI9860.Val}',  value: '', isHeader: false, format: '3' , col: 1 },
    { field: '1st Pass Cal. Flow (GPM):', id: '{[HX2]CFI9870.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass Cal. Recycle Flow (GPM):', id: '{[HX2]CFI9872.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass ∆P (psi):', id: '{[HX2]CPI9854.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass Reject Flow (GPM):', id: '{[HX2]FI3655.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass Reject Drain Flow (GPM):', id: '{[HX2]FI3660.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass Pump Outlet Pressure (psi):', id: '{[HX2]PI3645.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 1 },
    { field: '1st Pass Reject Pressure (psi):', id: '{[HX2]PI3650.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 1 },
    { field: '1st Pass Booster Pump Speed (%):', id: '{[HX2]SIC3015.Val_SpeedRef}',  value: '', isHeader: false, format: '3' , col: 1 },
    { field: "1st Pass Product Temp. ('C):", id: '{[HX2]TI3670.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 1 },
    { field: '1st Pass Reject Flow Control Valve (%):', id: '{[HX2]ZC3440.Val_CVOut}',  value: '', isHeader: false, format: '3' , col: 1 },
    { field: '1st Pass Reject Drain Flow Control Valve (%):', id: '{[HX2]ZC3445.Val_CVOut}',  value: '', isHeader: false, format: '3' , col: 1 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 1  },

    { field: 'Alarm Disabled:', id: '{[HX2]STOP_ALARMS_DISABLED}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: 'Alarms:', id: '{[HX2]ALARM[0]}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: '', id: '{[HX2]ALARM[3]}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: '', id: '{[HX2]ALARM[6]}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: '', id: '{[HX2]ALARM[9]}',  value: '', isHeader: false, format: '' , col: 1 },
    { field: '', id: '{[HX2]ALARM[12]}',  value: '', isHeader: false, format: '' , col: 1 },

    // GROUP 2
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '' , col: 2 },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '' , col: 2 },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },

    { field: 'System Sequence Step:', id: '{[HX2]STEP_INT}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: 'User Access:', id: 'ACCESS',  value: '', isHeader: false, format: '' , col: 2 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },

    { field: 'Lifetime Feed Flow Total (L):', id: '{[HX2]CFI9868_LIT.Total}',  value: '', isHeader: false, format: '8' , col: 2 },
    { field: "Feed Water Temp. ('C):", id: '{[HX2]TI3640.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 2 },
    { field: 'Prefilter Outlet Pressure (psi):', id: '{[HX2]PI3605.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 2 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },

    { field: 'RO Rejection (%):', id: '{[HX2]CQI9864.Val}',  value: '', isHeader: false, format: '2.2-2' , col: 2 },
    { field: '2nd Pass Condo (uS/cm):', id: '{[HX2]AI3695.Val}',  value: '', isHeader: false, format: '3.2-2' , col: 2 },
    { field: '2nd Pass Recovery (%):', id: '{[HX2]CFI9862.Val}',  value: '', isHeader: false, format: '3' , col: 2 },
    { field: '2nd Pass ∆P (psi):', id: '{[HX2]CPI9856.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 2 },
    { field: '2nd Pass Reject Flow (GPM):', id: '{[HX2]FI3690.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 2 },
    { field: '2nd Pass Product Flow (gpm):', id: '{[HX2]FI3710.Val}',  value: '', isHeader: false, format: '2.2-2' , col: 2 },
    { field: '2nd Pass Pump Outlet Pressure (psi):', id: '{[HX2]PI3675.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 2 },
    { field: '2nd Pass Reject Pressure (psi):', id: '{[HX2]PI3685.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 2 },
    { field: '2nd Pass Product Pressure (psi):', id: '{[HX2]PI3705.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 2 },
    { field: '2nd Pass Booster Pump Speed (%):', id: '{[HX2]SIC3025.Val_SpeedRef}',  value: '', isHeader: false, format: '3' , col: 2 },
    { field: "2nd Pass Product Temp. ('C):", id: '{[HX2]TI3700.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 2 },
    { field: '2nd Pass Reject Flow Control Valve:', id: '{[HX2]ZC3460.Val_CVOut}',  value: '', isHeader: false, format: '3.1-1' , col: 2 },
    { field: '2nd Pass Product Divert Valve Status:', id: '{[HX2]XS3470.Sts_Opened}',  value: '', isHeader: false, format: '' , col: 2 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 2  },

    { field: 'Alarm Disabled:', id: '{[HX2]STOP_ALARMS_DISABLED}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: '', id: '{[HX2]ALARM[1]}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: '', id: '{[HX2]ALARM[4]}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: '', id: '{[HX2]ALARM[7]}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: '', id: '{[HX2]ALARM[10]}',  value: '', isHeader: false, format: '' , col: 2 },
    { field: '', id: '{[HX2]ALARM[13]}',  value: '', isHeader: false, format: '' , col: 2 },

    //GROUP 3
    { field: 'Local PC Date:', id: 'LocalPCDate',  value: ' ', isHeader: false, format: '', col: 3  },
    { field: 'Local PC Time:', id: 'LocalPCTime',  value: ' ', isHeader: false, format: '', col: 3  },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },

    { field: 'System Mode:', id: '{[HX2]MODE_INT}',  value: '', isHeader: false, format: '' , col: 3 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },

    { field: 'Cal. Feed Flow (gpm):', id: '{[HX2]CFI9866.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 3 },
    { field: 'Heat Exchanger (%):', id: '{[HX2]EC3010.Val_CVOut}',  value: '', isHeader: false, format: '2' , col: 3 },
    { field: 'Prefilter ∆P (psi):', id: '{[HX2]CPI9850.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 3 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },

    { field: 'Loop UF Filter ∆P (psi):', id: '{[HX2]CPI9852.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 3 },
    { field: 'Loop Return Velocity (FPS):', id: '{[HX2]CFI9874.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 3 },
    { field: 'Loop Return Flow (gpm):', id: '{[HX2]FI3620.Val}',  value: '', isHeader: false, format: '2.2-2' , col: 3 },
    { field: 'Loop Filter Outlet Pressure (psi):', id: '{[HX2]PI3715.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 3 },
    { field: "Loop Return Temp. ('C):", id: '{[HX2]TI3630.Val}',  value: '', isHeader: false, format: '2.1-1' , col: 3 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },

    { field: 'Break Tank Level Volume (GAL):', id: '{[HX2]LI3615.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 3 },
    { field: 'System Compressed Air Pressure:', id: '{[HX2]PI6640.Val}',  value: '', isHeader: false, format: '3.1-1' , col: 3 },

    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },
    { field: ' ', id: ' ',  value: ' ', isHeader: true, format: '', col: 3  },

    { field: 'Alarm Reset:', id: '{[HX2]SYSTEM_RESET}',  value: '', isHeader: false, format: '' , col: 3 },
    { field: '', id: '{[HX2]ALARM[2]}',  value: '', isHeader: false, format: '' , col: 3 },
    { field: '', id: '{[HX2]ALARM[5]}',  value: '', isHeader: false, format: '' , col: 3 },
    { field: '', id: '{[HX2]ALARM[8]}',  value: '', isHeader: false, format: '' , col: 3 },
    { field: '', id: '{[HX2]ALARM[11]}',  value: '', isHeader: false, format: '' , col: 3 },
    { field: '', id: '{[HX2]ALARM[14]}',  value: '', isHeader: false, format: '' , col: 3 },

    { field: 'Data From Machine', id: 'DataFromMachine',  value: '', isHeader: false, format: '' , col: 4 },
    { field: 'Date Difference', id: 'DateDiff',  value: '', isHeader: false, format: '' , col: 4 },

    // { field: 'Current Clock Year:', id: 'CURRENT_T_CLOCK_Yr',  value: '', isHeader: false, format: '' , col: 3 },
    // { field: 'Current Clock Month:', id: 'CURRENT_T_CLOCK_Mo',  value: '', isHeader: false, format: '' , col: 3 },
    // { field: 'Current Clock Day:', id: 'CURRENT_T_CLOCK_Da',  value: '', isHeader: false, format: '' , col: 3 },
    // { field: 'Current Clock Hour:', id: 'CURRENT_T_CLOCK_Hr',  value: '', isHeader: false, format: '' , col: 3 },
    // { field: 'Current Clock Min:', id: 'CURRENT_T_CLOCK_Min',  value: '', isHeader: false, format: '' , col: 3 },
    // { field: 'Current Clock Sec:', id: 'CURRENT_T_CLOCK_Sec',  value: '', isHeader: false, format: '' , col: 3 },
];
    constructor( ) {}

    static print() {
      const divToPrint = document.getElementById('mywrapper').innerHTML;
      const newWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      newWindow.document.open();
      newWindow.document.write(`
      <html>
          <head>
            <title>Print tab</title>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">
            <link rel="stylesheet" href="/styles.css">
            <link rel="stylesheet" href="/assets/CSS/AdminLTE.css">
            <style>
              body
              {
                font-size:12px;
              }
          @media print {
            select,input,button,textarea,.no-print,.inputrequired {
               display: none;
            }

          }
            </style>
          </head>
          <body onload="window.print();window.close();">${divToPrint}
          </body>
        </html>
      `);
      newWindow.document.close();
    }

    static GetAPIDate(datestring) {
      // console.log(datestring , datestring instanceof Date);
      if (datestring !== undefined && datestring instanceof Date) {
      const tzDifference =  datestring.getTimezoneOffset() ;
      // console.log(datestring.getTimezoneOffset(),tzDifference);
      return new Date(datestring.getTime() - ((tzDifference * 60 * 1000)  ));
      } else {
        return datestring;
      }
      // return new Date( (new Date(datestring.getTime() - ((tzDifference * 60 * 1000)  )).getTime() -  (tzDifference * 60 * 1000)));
    }

    static GetAPIDateWithoutSeconds(datestring) {
      // console.log(datestring , datestring instanceof Date);
      if (datestring !== undefined && datestring instanceof Date) {
      const tzDifference =  datestring.getTimezoneOffset() ;
      // console.log(datestring.getTimezoneOffset(),tzDifference);
      let filteredDate = new Date(datestring.getTime() - ((tzDifference * 60 * 1000)  ));
        filteredDate.setSeconds(0,0);
      return filteredDate;
      } else {
        return datestring;
      }
      // return new Date( (new Date(datestring.getTime() - ((tzDifference * 60 * 1000)  )).getTime() -  (tzDifference * 60 * 1000)));
    }


    static GetClientDate(datestring) {
      // console.log(datestring , datestring instanceof Date);
      if (datestring !== undefined && datestring instanceof Date) {
      const tzDifference =  datestring.getTimezoneOffset() ;
      // console.log(datestring.getTimezoneOffset(),tzDifference);
      return new Date(datestring.getTime() + ((tzDifference * 60 * 1000)  ));
      } else {
        return datestring;
      }
      // return new Date( (new Date(datestring.getTime() - ((tzDifference * 60 * 1000)  )).getTime() -  (tzDifference * 60 * 1000)));
    }


  }

export  enum actionType {
    visible = 0,
    create = 1,
    edit = 2,
    addnotes = 3,
    delete = 4
}

export enum attachmentFormModules {
        GeneralBacteria,
        GeneralPyrogen,
        CD_BicarbPowder,
        CD_AcidDrum,
        CD_Repair,
        CD_WorkOrder,
        // CD_WorkOrderMail,
        CD_Warranty,
        DM_Incident,
        DM_Repair,
        DM_Filter,
        DM_Hours,
        DM_Upgradation,
        DM_WorkOrder,
        // DM_WorkOrderMail,
        DM_Warranty,
        WP_Repair,
        WP_DailyWater,
        WP_UHNDailyWater,
        WP_SaturdayWater,
        WP_WorkOrder,
        // WP_WorkOrderMail,
        WP_Warranty,
        I_PurchaseOrder,
        I_CheckOut,
        Other_Repair,
        PagingReports,
        Task_Manager,
        Receive_Inventory,
        Bulletin,
        WaterAnalysisReport,
        IC_Ship,
        IC_Receive,
        Error,
        equipmentDetails,
        dmTransferDetails,
        DM_PM,
        contacts,
        designations,
        scheduleColor,
	 errorCode
    }
    export enum NoteModuleName {
        RepairDetail,
        DMRepairDetails,
        waterAnalysisReport,
        dmUpgradeModfication,
        warrantyForm,
        pyrogenDetails,
        bacteriaDetails,
        workOrderDetails,
        workOrderRepairDetails,
        dmFilterChange,
        centralDeliveryBicarbPowder,
        dmIncidentDetails,
        equipmentDetails,
        contacts,
        ReceiveInventory,
        division,
        taskDetails,
        waterprocessdailyrecord,
        waterprocessUHNdailyrecord,
        saturdayWaterRecord,
        pagingReport,
        centralDeliveryAcidDrumFilling,
        scheduleDetails,
        InventoryOrder,
        ShippingOrder,
        InventoryCheckOut,
        dmTransferDetails
    }

    export const customTooltipDefaults: MatTooltipDefaultOptions = {
      showDelay: 1000,  //ms
      hideDelay: 100,  //ms
      touchendHideDelay: 100 //ms
  }

  export const MY_MOMENT_FORMATS  = {
    fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
    datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
    timePickerInput: {hour: '2-digit', minute: '2-digit', hour12: false},
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
};


import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmationDialog } from "../../Core/ConfirmationDialog";
import { CanComponentDeactivate } from "../../Core/Shared/CanComponentDeactivate";
import { ScrollHelper } from "../../Core/Helper/ScrollHelper";
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { Globals, actionType, attachmentFormModules } from "../../globals";
import { AppComponent } from "../../app.component";
import { AuthService } from "../../API/auth.service";
import { DMApiService } from '../../API/dmapi.service';
import { RPTApiService } from '../../API/rptapi.service';
import { WPApiService } from "src/app/API/wpapi.service";
import { AttachmentComponent } from "src/app/Core/Shared/AttachmentComponent/AttachmentComponent";
import { NotesComponent } from "src/app/Core/Shared/NotesComponent/NotesComponent";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonCustomValidators } from "src/app/Validator/CommonValidator";
import { WaterProcessAnalysisComponent } from "src/app/Core/Shared/WaterProcessAnalysisComponent/WaterProcessAnalysisComponent.component";

@Component({
  selector: "AnalysisDetail",
  templateUrl: "./AnalysisDetail.component.html"
})
export class AnalysisDetailComponent implements OnInit {

   @ViewChild(WaterProcessAnalysisComponent, { static: true }) child;


  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.child.canDeactivate();
  }

  constructor() { }

  ngOnInit() {
  }


}

import { HomeFieldService } from '../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';

import { map } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';

@Component({
  selector: 'SectionPrepdetailDialog',
  templateUrl: './section-prepdetail.component.html',
  styleUrls: ['./section-prepdetail.component.css']
})

export class SectionPrepdetailDialog implements AfterViewInit {
  tooltipPosition: TooltipPosition = 'above';
  tooltipText = 'Click To Add';
  matDialogRef: MatDialogRef<ConfirmationDialog>;

  @Output() SaveEvent = new EventEmitter<any>();
  isValidFormSubmitted = false;
  errormsg = "";
  PrepDetailsList = [];


  prepSectionForm = new UntypedFormGroup({
    HFServicePrepDetailId: new UntypedFormControl('0'),
    PrepDate: new UntypedFormControl(''),
    PrepTime: new UntypedFormControl('0.0', [Validators.min(0.5), Validators.required]),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    CreatedByName: new UntypedFormControl(''),
    PrepTechnologists: new UntypedFormControl(''),
    PrepTechnologistIds: new UntypedFormControl(''),
    PrepNotes: new UntypedFormControl('', Validators.required),
    HFServiceId: new UntypedFormControl(''),
    poPlumber: new UntypedFormControl(''),
    poElectrician: new UntypedFormControl(''),
    poMovers: new UntypedFormControl(''),
    poPlumberNote: new UntypedFormControl(''),
    poElectricianNote: new UntypedFormControl(''),
    poMoversNote: new UntypedFormControl(''),
    EnteredByHFServiceId: new UntypedFormControl(''),
  });

  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    public dialogRef: MatDialogRef<SectionPrepdetailDialog>,
    public dialog: MatDialog,
  ) { }

  TechnologistsList = [];
  PrepTimeList = [];
  today = new Date();
  HFServiceStationId = '0';
  HFServiceVisitPurpose = '';
  IsDisplayPODetails = true;
  UserName = this.auth.GetUserName();
  UserId = this.auth.GetUserId();
  dropdownSettings;
  displayList = false;
  //prepTime = '0.5';

  IsPlumberReq = false;
  IsElectricianReq = false;
  IsMoversReq = false;

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'value',
        textField: 'text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 6,
        allowSearchFilter: false,
        enableCheckAll: false
      };

    this.prepf.PrepDate.setValue(this.today);
    this.prepf.CreatedBy.setValue(this.UserId);
    this.prepf.CreatedByName.setValue(this.UserName);

    if ((this.HFServiceVisitPurpose == 'Renovation & Full Installation' ||
      this.HFServiceVisitPurpose == 'Uninstallation')) {
      setTimeout(() => {
        this.homefieldApi.GetHomeFieldService_Station_Installation_Req({ Id: this.HFServiceStationId }).subscribe(
          success => {

            if (success) {
              if (this.IsDisplayPODetails) {
                if (success.plumberReq == 'Yes') {
                  this.prepf.poPlumber.setValidators([Validators.required]);
                  this.prepf.poPlumber.updateValueAndValidity();
                  this.IsPlumberReq = true;
                  this.IsElectricianReq = false;
                  this.IsMoversReq = false;
                }
                if (success.electricianReq == 'Yes') {
                  this.prepf.poElectrician.setValidators([Validators.required]);
                  this.prepf.poElectrician.updateValueAndValidity();
                  this.IsPlumberReq = false;
                  this.IsElectricianReq = true;
                  this.IsMoversReq = false;
                }
                if (success.moversReq == 'Yes') {
                  this.prepf.poMovers.setValidators([Validators.required]);
                  this.prepf.poMovers.updateValueAndValidity();
                  this.IsPlumberReq = false;
                  this.IsElectricianReq = false;
                  this.IsMoversReq = true;
                }
              }
            }
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );

        // this.ShowPODiv = this.DisplayPODetails();
      }, 500);
    }

      this.onFormChanges();
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  ShowPODiv = true;
  DisplayPODetails() {
    this.ShowPODiv = (this.IsDisplayPODetails && (this.HFServiceVisitPurpose == 'Renovation & Full Installation' ||
      this.HFServiceVisitPurpose == 'Uninstallation'));
  }

  prepareCreateViewModel(Source = "") {
    const modelCreate = <any>{};

    modelCreate.HFServicePrepDetailId = this.prepf.HFServicePrepDetailId.value;
    modelCreate.PrepDate = Globals.GetAPIDate(this.prepf.PrepDate.value);
    modelCreate.PrepTime = this.prepf.PrepTime.value;
    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.prepf.CreatedBy.value;
    modelCreate.PrepTechnologists = "";
    modelCreate.PrepTechnologistIds = this.prepf.PrepTechnologists.value;
    modelCreate.EnteredByHFServiceId = this.prepf.EnteredByHFServiceId.value;

    if (this.prepf.PrepTechnologists.value) {
      this.prepf.PrepTechnologists.value.forEach((tech, index) => {
        modelCreate.PrepTechnologists += tech.text;

        if (index != (this.prepf.PrepTechnologists.value.length - 1))
          modelCreate.PrepTechnologists += ", ";
      });
    }
    modelCreate.PrepNotes = this.prepf.PrepNotes.value;
    modelCreate.HFServiceId = this.prepf.HFServiceId.value;

    modelCreate.poPlumber = this.prepf.poPlumber.value;
    modelCreate.poElectrician = this.prepf.poElectrician.value;
    modelCreate.poMovers = this.prepf.poMovers.value;
    modelCreate.poPlumberNote = this.prepf.poPlumberNote.value;
    modelCreate.poElectricianNote = this.prepf.poElectricianNote.value;
    modelCreate.poMoversNote = this.prepf.poMoversNote.value;

    return modelCreate;
  }

  ngAfterViewInit() {

  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  PlusHours() {

    if (this.prepf.PrepTime.value < 3) {
      this.prepf.PrepTime.setValue((parseFloat(this.prepf.PrepTime.value.toString()) + 0.5).toString());
      this.prepf.PrepTime.setValue(this.formatDecimal(this.prepf.PrepTime.value.toString()));
    }
  }

  MinusHours() {

    if (parseFloat(this.prepf.PrepTime.value) > 0.0) {
      this.prepf.PrepTime.setValue((parseFloat(this.prepf.PrepTime.value.toString()) - 0.5).toString());
      this.prepf.PrepTime.setValue(this.formatDecimal(this.prepf.PrepTime.value.toString()));
    }
  }

  formatDecimal(val) {
    if (val.length > 0) {
      if (val.indexOf('.') === -1) {
        val = val + ".0";
      }

      // if (val.length >= 3) {
      //   val = val + "0" ;
      // }
      return val;
    }
  }

  save() {
    AppComponent.loading = true;
    this.isValidFormSubmitted = true;
    if (this.prepSectionForm.invalid) {
      // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      // this.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      return;
    }
    // this.isValidFormSubmitted = true;

    const prepCreate = this.prepareCreateViewModel();
    this.homefieldApi.CreateHomeFieldService_Section_PrepDetails(prepCreate).subscribe(
      success => {
        AppComponent.loading = false;
        this.SaveEvent.emit(success);
        this.dialogRef.close(prepCreate);
      },
      error => {
        if (error.error && error.error.returnMessage) {
          this.errormsg = error.error.returnMessage.toString();
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          this.errormsg = error.message;
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  };


  get prepf() {
    return this.prepSectionForm.controls;
  }

  onFormChanges(): void {

    this.prepSectionForm.valueChanges.subscribe(val => {

      if (this.prepSectionForm.dirty || this.prepf.PrepTime.value != '0.0') {
        this.isValidFormSubmitted = true;
        if (this.prepSectionForm.invalid) {
          // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
          this.errormsg = Globals.FormErrorMessage;
          //AppComponent.loading = false;
          return;
        } else {
          this.errormsg = '';
        }
      }
    });
  }

  saveAndClose() {
    if (this.prepSectionForm.dirty || this.prepf.PrepTime.value != '0.0') {
      if (this.prepSectionForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

          if (result)
            this.dialogRef.close(false);
          else
            this.save();
        });
      }
      else {
        this.save();
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  //** Fetch Section Details */
  fetchPrepDetails(masterHFSId = '') {
    this.homefieldApi.GetHomeFieldService_SectionPrepDetails({ Id: this.prepf.HFServiceId.value }).subscribe(
      success => {
        if (success) {
          // if(masterHFSId)
          //   this.PrepDetailsList = success.filter(x=>x.enteredByHFServiceId == masterHFSId);
          // else
          this.PrepDetailsList = success;
        }
      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }
      }
    );
  }
}

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Globals } from '../../../globals';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialog } from '../../ConfirmationDialog';
import { PMComponentModel, PMPartsModel } from '../PMNotesComponent/PMNotesModel';
import { QuickAddPartDialog } from '../../QuickAddPart/QuickAddPartDialog';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'PM_Parts_Component',
  templateUrl: './PMPartsComponent.html'
})

export class PMPartsComponent {

  quickAddDialogRef: MatDialogRef<QuickAddPartDialog>;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentDate = new Date();
  public PrevParts = [];
  parterrormsg = '';
  noteparterrormsg = '';
  PartSearchTerm = '';
  PartAutoComplete = 'test';
  PartSelectedItem = <any>{};
  public PartTrans = [];
  PartQtyList = [];
  SelectedPart;
  public partSearchResult = [];

  public partsDetails: PMPartsModel;
  public componentDetails: PMComponentModel;


  @Output()
  RaiseError = new EventEmitter();

  constructor(
    public dialog: MatDialog, public auth: AuthService
  ) {
    this.componentDetails = new PMComponentModel();
    this.partsDetails = new PMPartsModel();
  }

  ngOnInit() {
    this.auth.GetCurrentDateTimeFromServer().subscribe(today => {
      this.currentDate = new Date(today);

      while (this.PartQtyList.length <= 5) {
        this.PartQtyList.push({
          text: (this.PartQtyList.length).toString(),
          value: (this.PartQtyList.length).toString()
        });
      }
      this.partsDetails.quantity = '0';

    }, error => {
    });
  }

  PartCodeChange(type) {

    const dmPartCreate = <any>{};
    dmPartCreate.partsSearchtext = this.PartSearchTerm;
    dmPartCreate.divisionId = this.componentDetails.DivisionId;
    dmPartCreate.equipementId = this.componentDetails.equipmentId;
    this.partSearchResult = [];
    const obj = {
      partssearchtext: this.PartSearchTerm,
      partscategoryname: this.componentDetails.partscategoryname,
      partsserachtype: type,
      partsshowinventory: true,
      divisionid: this.componentDetails.DivisionId,
      equipementId: this.componentDetails.equipmentId
    };
    if (type === 'desc') {
      obj.partssearchtext = this.partsDetails.partDescription;
    }
    this.componentDetails.API.GetPartsdetailsDetailssearchByCategory(obj).subscribe(list => {
      this.partSearchResult = list;
    });
  }

  getPartPosts(option, type) {

    if (option && option.value) {
      if (type === 'code') {
        this.PartSelectedItem = this.partSearchResult.filter(
          x => x.equipmentcode === option.value
        )[0];
        this.partsDetails.partDescription = this.PartSelectedItem.equipmentDesc;
      } else {
        this.PartSelectedItem = this.partSearchResult.filter(
          x => x.equipmentDesc === option.value
        )[0];
        this.PartSearchTerm = this.PartSelectedItem.equipmentcode;
      }
      this.SetCost();
    }
  }

  SetCost() {
    if (this.partsDetails.quantity !== '' && this.PartSelectedItem !== undefined && this.PartSelectedItem.itemcost
      && this.partsDetails.partDescription
    ) {
      this.partsDetails.totalCost = parseInt(this.partsDetails.quantity) * this.PartSelectedItem.itemcost;
    }
  }

  AddNewPartInventory() {

    if (this.componentDetails.equipmentId) {
      this.PartSelectedItem.desc = '';
      this.quickAddDialogRef = this.dialog.open(QuickAddPartDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        maxWidth: '60vh',
        maxHeight: '100vh',
        data: {
          category: this.componentDetails.partscategoryname,
          comp_equipmentId: this.componentDetails.equipmentId
        }
      });
      //this.quickAddDialogRef.componentInstance.quickAddPartModel.title = type;
      this.quickAddDialogRef.afterClosed().subscribe(result => {
      });

      this.quickAddDialogRef.componentInstance.SaveEvent.subscribe(evt => {
        this.PartSelectedItem = evt;
        this.partsDetails.partDescription = this.PartSelectedItem.equipmentDesc;
        this.PartSearchTerm = this.PartSelectedItem.equipmentcode;
        this.SetCost();
      });
    }
    else {
      AppComponent.addAlert(Globals.FormErrorMessage, "error");
    }
  }

  AddPart() {

    if (this.PartSearchTerm == '' || this.partsDetails.partDescription == '') {
      this.parterrormsg = 'Please select the part to Add.';
      return false;
    }

    if (!this.partsDetails.quantity || this.partsDetails.quantity == '0') {
      this.parterrormsg = 'Quantity must be greater than zero to Add Part.';
      return false;
    }
    else {
      this.parterrormsg = '';
    }

    if (
      this.PartSelectedItem.equipementid != null &&
      this.PartSelectedItem.equipementid > 0 && this.partsDetails.quantity > '0'
    ) {
      const obj = new PMPartsModel();

      obj.pmId = this.partsDetails.pmId;
      obj.equipmentId = this.PartSelectedItem.equipementid;
      obj.quantity = this.partsDetails.quantity;
      obj.partName = this.PartSelectedItem.equipmentcode;
      obj.partDescription = this.PartSelectedItem.equipmentDesc;
      obj.totalCost = this.partsDetails.totalCost;
      obj.questionId = this.partsDetails.questionId;
      obj.createdBy = this.componentDetails.CurrentUserId;
      obj.createdByName = this.componentDetails.CurrentUserName;
      obj.modifiedBy = this.componentDetails.CurrentUserId;

      obj.showPartDelete = false;
      if (obj.createdBy === this.componentDetails.CurrentUserId) {
        obj.showPartDelete = true;
      }
      this.PartTrans.push(obj);

      this.PartSearchTerm = '';
      this.partsDetails.partDescription = '';
      this.PartSelectedItem.desc = '';
      this.partsDetails.totalCost = 0;
      this.partsDetails.quantity = '0';
      this.partSearchResult = [];
      this.noteparterrormsg = '';
      this.PartSelectedItem = <any>{};
    }
  }

  DeletePart(item) {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PartTrans.splice(this.PartTrans.indexOf(item), 1);
      }
      this.dialogRef = null;
    });
  }


}


// import { Component, Output, EventEmitter, Input } from '@angular/core';
// import { Globals } from '../../../globals';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import { ConfirmationDialog } from '../../ConfirmationDialog';
// import { PMComponentModel, PMPartsModel } from '../PMNotesComponent/PMNotesModel';


// @Component({
//   selector: 'PM_Parts_Component',
//   templateUrl: './PMPartsComponent.html'
// })

// export class PMPartsComponent {
//   dialogRef: MatDialogRef<ConfirmationDialog>;
//   currentDate = new Date();
//   public PrevParts = [];
//   parterrormsg='';
//   noteparterrormsg='';
//   PartSearchTerm = '';
//   PartAutoComplete = 'test';
//   PartSelectedItem = <any>{};
//   public PartTrans = [];
//   PartQtyList = [];
//   SelectedPart;
//   public partSearchResult = [];

//   public partsDetails: PMPartsModel;
//   public componentDetails: PMComponentModel;


//   @Output()
//   RaiseError = new EventEmitter();

//   constructor(
//     public dialog: MatDialog
//   ) {
//     this.componentDetails = new PMComponentModel();
//     this.partsDetails = new PMPartsModel();
//   }

//   ngOnInit() {

//     while (this.PartQtyList.length <= 5) {
//         this.PartQtyList.push({
//           text: (this.PartQtyList.length).toString(),
//           value: (this.PartQtyList.length).toString()
//         });
//     }
//     this.partsDetails.quantity = '0';

//   }

//   PartCodeChange(type) {
//     const dmPartCreate = <any>{};
//     dmPartCreate.partsSearchtext = this.PartSearchTerm;
//     dmPartCreate.divisionId = this.componentDetails.DivisionId;
//     dmPartCreate.equipementId = this.componentDetails.equipmentId;
//     this.partSearchResult = [];
//     const obj = {
//       partssearchtext: this.PartSearchTerm,
//       partscategoryname: this.componentDetails.partscategoryname,
//       partsserachtype: type,
//       partsshowinventory: true,
//       divisionid: this.componentDetails.DivisionId,
//       equipementId : this.componentDetails.equipmentId
//     };
//     if (type === 'desc') {
//       obj.partssearchtext = this.partsDetails.partDescription;
//     }
//     //console.log(obj);
//     this.componentDetails.API.GetPartsdetailsDetailssearchByCategory(obj).subscribe(list => {
//       this.partSearchResult = list;
//     });
//   }

//   getPartPosts(option, type) {
//     if (type === 'code') {
//       this.PartSelectedItem = this.partSearchResult.filter(
//         x => x.equipmentcode === option.value
//       )[0];
//       this.partsDetails.partDescription = this.PartSelectedItem.equipmentDesc;
//     } else {
//       this.PartSelectedItem = this.partSearchResult.filter(
//         x => x.equipmentDesc === option.value
//       )[0];
//       this.PartSearchTerm = this.PartSelectedItem.equipmentcode;
//     }
//     this.SetCost();
//   }

//   SetCost() {
//
//     if (this.partsDetails.quantity !== '' && this.PartSelectedItem !== undefined && this.PartSelectedItem.itemcost
//     && this.partsDetails.partDescription
//     ) {
//       // tslint:disable-next-line:radix
//       this.partsDetails.totalCost = parseInt(this.partsDetails.quantity) * this.PartSelectedItem.itemcost;
//       //console.log(this.partsDetails.totalCost);
//     }
//   }

//   AddPart() {

//     if(this.PartSearchTerm =='' || this.partsDetails.partDescription == '')
//     {
//       this.parterrormsg = 'Please select the part to Add.';
//       return false;
//     }

//  if (!this.partsDetails.quantity || this.partsDetails.quantity == '0') {
//       this.parterrormsg = 'Quantity must be greater than zero to Add Part.';
//       return false;
//     }
//     else{
//       this.parterrormsg = '';
//     }

//     if (
//       this.PartSelectedItem.equipementid != null &&
//       this.PartSelectedItem.equipementid > 0 && this.partsDetails.quantity > '0'
//     ) {
//       // console.log("equip code", this.PartSelectedItem.equipementid);
//       // const temp = this.PartTrans.filter(
//       //   x => (x.equipmentid == this.PartSelectedItem.equipementid || x.equipmentId == this.PartSelectedItem.equipementid)
//       // );
//       // let temp1 = [];
//       // if (this.PrevParts.length > 0) {
//       //   temp1 = this.PrevParts.filter(
//       //     x => (x.equipmentid == this.PartSelectedItem.equipementid || x.equipmentId == this.PartSelectedItem.equipementid)
//       //   );
//       // }

//       // if (temp.length === 0 && temp1.length === 0) {
//         const obj = new PMPartsModel();

//         obj.pmId = this.partsDetails.pmId;
//         obj.equipmentId = this.PartSelectedItem.equipementid;
//         obj.quantity = this.partsDetails.quantity;
//         obj.partName = this.PartSelectedItem.equipmentcode;
//         obj.partDescription = this.PartSelectedItem.equipmentDesc;
//         obj.totalCost = this.partsDetails.totalCost;
//         obj.questionId = this.partsDetails.questionId;
//         obj.createdBy = this.componentDetails.CurrentUserId;
//         obj.createdByName = this.componentDetails.CurrentUserName;
//         //obj.createdOn = new Date();
//         obj.modifiedBy = this.componentDetails.CurrentUserId;
//         //obj.modifiedOn = new Date();

//         obj.showPartDelete = false;
//         if (obj.createdBy === this.componentDetails.CurrentUserId) {
//           obj.showPartDelete = true;
//         }
//         this.PartTrans.push(obj);

//         this.PartSearchTerm = '';
//         this.partsDetails.partDescription = '';
//         this.PartSelectedItem.desc = '';
//         this.partsDetails.totalCost = 0;
//         this.partsDetails.quantity = '0';
//         this.partSearchResult = [];
//         this.noteparterrormsg = '';
//       // }
//     }
//   }

//   DeletePart(item) {
//     this.dialogRef = this.dialog.open(ConfirmationDialog, {
//       disableClose: true
//     });
//     this.dialogRef.componentInstance.confirmMessage =
//       'Are you sure you want to delete?';
//     this.dialogRef.afterClosed().subscribe(result => {
//       if (result) {
//         this.PartTrans.splice(this.PartTrans.indexOf(item), 1);
//       }
//       this.dialogRef = null;
//     });
//   }


// }

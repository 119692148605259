<section class="content-header">
    <h1>
        {{title}}<sup *ngIf="title!=''" class="beta">[demo]</sup>
    </h1>

</section>
<section class="content">
    <section>
        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">
                <PageHeader [title]="title" [showAddNew]=true [addurl]='AddUrl' #pageheader [(searchText)]="searchText"
                    [isSearchOpen]="ShowSearch" (searchShowChange)="onSearchShowChange($event)" [showMainListing]="false"></PageHeader>
              
                <div class="clearfix"></div>
                <div class="box-body" id="dvboxbody">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10"
                                        [directionLinks]="true"></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeDivisionSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                            <thead>
                                <tr>
                                    <th *ngFor="let tableHeader of tableHeaders" [ngClass]="setSortIndicator(tableHeader.Label)"
                                        [style.width.%]="tableHeader.Width" (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let divisionDetail of rows;let index=index;" (click)="_onRowClick(divisionDetail.divisionId)"
                                    class="pointer">

                                    <td>

                                        {{(pageSize*(currentPageNumber-1)) + (index + 1)}}
                                    </td>
                                    <td>
                                        {{divisionDetail.divisionName}}
                                    </td>
                                    <td>{{divisionDetail.address1}}</td>
                                    <td>{{divisionDetail.city}} </td>
                                    <td>{{divisionDetail.state}} </td>
                                    <td>{{divisionDetail.country}} </td>
                                    <td>{{divisionDetail.phone}} </td>
                                    <!-- <td>{{divisionDetail.createdByName}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-9 pull-right">
                                <div class="col text-right">
                                    <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements"
                                        [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10"
                                        [directionLinks]="true"></ngb-pagination>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="col text-left noofrecords">
                                    <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                                    <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeDivisionSearchClear()">
                                            (<span><i class="fa fa-trash-o"></i></span>&nbsp;
                                            <b>Clear Search</b>
                                        </a>)
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

import { DatePipe } from '@angular/common';
import { AuthService } from './../../API/auth.service';
import { Globals } from 'src/app/globals';
import { AppComponent } from './../../app.component';
import { UntypedFormGroup, UntypedFormControl,Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { WPApiService } from 'src/app/API/wpapi.service';
import { Router } from '@angular/router';
@Component({
  selector: 'BioPureHX2Settings',
  templateUrl: './BioPureHX2Settings.component.html',
  styleUrls: ['./BioPureHX2Settings.component.css']
})



export class BioPureHX2SettingsComponent {

title =  'BioPure HX2 Notification Settings';
today = new Date();
isValidFormSubmitted = null;
createdBy = this.authentication.GetUserId();
modifiedBy = this.authentication.GetUserId();

NotificationValues = [
  { text: 'Immediate', value: true },
  { text: 'Custom Time', value: false }
]

HX2NotificationForm = new UntypedFormGroup({
  intId: new UntypedFormControl(0),
  email: new UntypedFormControl('', [Validators.required]),
  phone: new UntypedFormControl('', [Validators.required,Validators.pattern(Globals.PhoneNumberRegex)]),
  isImmediate: new UntypedFormControl(true),
  notificationTime: new UntypedFormControl(''),
  isImmediateConnection: new UntypedFormControl(false),
  connectionNotificationStartTime: new UntypedFormControl(''),
  connectionNotificationEndTime: new UntypedFormControl(''),
  modelId: new UntypedFormControl(''),
  createdBy: new UntypedFormControl(''),
  createdOn: new UntypedFormControl(''),
  modifiedBy: new UntypedFormControl(''),
  modifiedOn: new UntypedFormControl(''),
});

constructor(
  private authentication: AuthService,
  private router: Router,
  private wpapi: WPApiService,
  private datePipe: DatePipe
) {}

minTime: Date;
maxTime: Date;
startAtnotificationTime : Date;
startAtconnectionNotificationStartTime : Date;
startAtconnectionNotificationEndTime : Date;

ngOnInit() {
setTimeout(() => {
  let todMin = new Date(this.today);
  todMin.setHours(0, 0, 0, 0);
  this.minTime = todMin;

  let todMax = new Date(this.today);
  todMax.setHours(23, 59, 59, 0);
  this.maxTime = todMax;

  this.startAtnotificationTime = todMin;
  this.startAtconnectionNotificationStartTime = todMin;
  this.startAtconnectionNotificationEndTime = todMin;


  this.setModel();
}, 500);
}

RouteClick(value) {
  this.router.navigate([value]);
}

getMinTime() {
  let tod = new Date(this.today);
  tod.setHours(0, 0, 0, 0);
  return tod;
}

getMaxTime() {
  let tod = new Date(this.today);
  tod.setHours(23, 59, 59, 0);
  return tod;
}

getDefaultConnectionStartTime(){
  let tod = new Date(this.today);
  tod.setHours(6, 0, 0, 0);
  return tod;
}

getDefaultConnectionEndTime(){
  let tod = new Date(this.today);
  tod.setHours(23, 0, 0, 0);
  return tod;
}

get f() {
  return this.HX2NotificationForm.controls;
}

getDisplayTime(val) {

  if (val)
    return val;
  else
    return this.getMinTime();
}

getDisplayTimeConnection(val, type) {

  if (val!=undefined)
  return val;
  // else{
  //   if(type == 'start'){
  //     this.f.connectionNotificationStartTime.setValue(this.getDefaultConnectionStartTime());
  //     return this.getDefaultConnectionStartTime();
  //   }
  //   else {
  //     this.f.connectionNotificationEndTime.setValue(this.getDefaultConnectionEndTime());
  //     return this.getDefaultConnectionEndTime();
  //   }
  // }
  else
  return this.getMinTime();
}

executeHX2NotificationCreateUpdate = function () {
  AppComponent.loading = true;
  this.isValidFormSubmitted = false;
  if (this.HX2NotificationForm.invalid) {
    AppComponent.addAlert(Globals.FormErrorMessage, 'error');
    AppComponent.loading = false;

    this.scrollHelper.scrollToFirst('ng-invalid');
    AppComponent.ScrollDown();
    return;
  }

  this.isValidFormSubmitted = true;

  if (this.HX2NotificationForm.controls.intId.value === 0
  ) {

    const modelCreate = this.prepareCreateViewModel();
    this.wpapi.CreateHX2Notification(modelCreate).subscribe(
      success => {

        this.HX2NotificationForm.controls.intId.value = success.intId;
        AppComponent.loading = false;
        AppComponent.addAlert('Data Saved successfully!', 'success');
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  } else {
    this.HX2NotificationForm.value.createdBy = this.createdBy;
    this.HX2NotificationForm.value.modifiedBy = this.modifiedBy;
    const modelCreate = this.prepareCreateViewModel();
    this.wpapi.UpdateHX2Notification(modelCreate).subscribe(
      success => {

        AppComponent.loading = false;
        AppComponent.addAlert('Data Saved successfully!', 'success');
        //window.location.reload();
        // this.menuSharedService.change();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }
};


prepareCreateViewModel() {

  const modelCreate = <any>{};
  modelCreate.intId = this.f.intId.value;
  modelCreate.email = this.f.email.value;
  modelCreate.phone = this.f.phone.value;

  if(this.isImmediateNotification){
    modelCreate.isImmediate = true;
  } else {
    modelCreate.notificationTime = this.datePipe.transform(this.f.notificationTime.value, 'HH:mm');
    modelCreate.isImmediate = false;
  }

  if(this.isImmediateConnectionNotification){
    modelCreate.isImmediateConnection = true;
  } else {
    modelCreate.connectionNotificationStartTime = this.datePipe.transform(this.f.connectionNotificationStartTime.value, 'HH:mm');
    modelCreate.connectionNotificationEndTime = this.datePipe.transform(this.f.connectionNotificationEndTime.value, 'HH:mm');
    modelCreate.isImmediateConnection = false;
  }

  modelCreate.modelId = 9;
  modelCreate.createdBy = this.createdBy;
  modelCreate.createdOn = this.today;
  modelCreate.modifiedBy = this.modifiedBy;
  modelCreate.modifiedOn = this.today;
  return modelCreate;
}

setModel(){
  AppComponent.ShowLoader();
    const HX2Notification = { Id: 1, str:'HX2' };
    this.wpapi.GetHX2Notification(HX2Notification).subscribe(
      success => {
        if (success) {

          this.f.intId.setValue(success.intId);
          this.f.email.setValue(success.email);
          this.f.phone.setValue(success.phone);
          this.f.isImmediate.setValue(success.isImmediate);
          this.f.notificationTime.setValue(success.notificationTime);

          // const tempDate = this.today;
          // this.f.notificationTime.setValue({
          //   date: {
          //     year: tempDate.getFullYear(),
          //     month: tempDate.getMonth() + 1,
          //     day: tempDate.getDate(),
          //     hour: new Date(success.notificationTime).getHours(),
          //     minutes: new Date(success.notificationTime).getMinutes()
          //   },
          //   jsdate: tempDate
          // });

          this.startAtnotificationTime = this.f.notificationTime.value;

          this.f.isImmediateConnection.setValue(success.isImmediateConnection);
          this.f.connectionNotificationStartTime.setValue(success.connectionNotificationStartTime);
          this.f.connectionNotificationEndTime.setValue(success.connectionNotificationEndTime);
          this.startAtconnectionNotificationStartTime = this.f.connectionNotificationStartTime.value;
          this.startAtconnectionNotificationEndTime = this.f.connectionNotificationEndTime.value;

          this.enableDisableOptions(this.f.isImmediate.value);
          this.enableDisableOptions(this.f.isImmediateConnection.value, true);

          // this.HX2NotificationForm.setValue({
          //   email: success.email,
          //   phone: success.phone
          //   // ,
          //   // notificationTime: success.notificationTime
          // });
        }
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  isImmediateNotification = true;
  isImmediateConnectionNotification = true;
  enableDisableOptions(event, isConnection = false) {

    if (event == true) {
        if(!isConnection){
          this.isImmediateNotification = true;
          this.f.isImmediate.setValue(true);
          this.f.notificationTime.clearValidators();
          this.f.notificationTime.updateValueAndValidity();
        } else {
          this.isImmediateConnectionNotification = true;
          this.f.isImmediateConnection.setValue(true);
          this.f.connectionNotificationStartTime.clearValidators();
          this.f.connectionNotificationEndTime.clearValidators();
          this.f.connectionNotificationStartTime.updateValueAndValidity();
          this.f.connectionNotificationEndTime.updateValueAndValidity();
        }
    }
    else {
        if(!isConnection){
          this.isImmediateNotification = false;
          this.f.isImmediate.setValue(false);
          this.f.notificationTime.setValidators(Validators.required);
          this.f.notificationTime.updateValueAndValidity();
        } else {
          this.isImmediateConnectionNotification = false;
          this.f.isImmediateConnection.setValue(false);
          this.f.connectionNotificationStartTime.setValidators(Validators.required);
          this.f.connectionNotificationEndTime.setValidators(Validators.required);
          this.f.connectionNotificationStartTime.updateValueAndValidity();
          this.f.connectionNotificationEndTime.updateValueAndValidity();
        }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46 && charCode != 40 && charCode != 41) {
      return false;
    }
    return true;
  }

  formatPhone(phone) {
    let val = this.f[phone].value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    val = val.substring(0, 14);
    this.f[phone].setValue(val);
  }

}

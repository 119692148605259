<style>
  .mat-button-toggle-checked {
      background-color: #3a4dab !important;
      color:white;
  }
</style>
<section class="content-header" id="dhaval">

  <h1 *ngIf="detailsType == 'Home'">
    {{title}}
    <!-- <sup *ngIf="title!=''" class="beta">[beta]</sup> -->
  </h1>
  <h1 *ngIf="detailsType == 'Common'">{{headerText}}</h1>

</section>

<form [formGroup]="preventativeMaintenanceForm" (ngSubmit)="executeSave(this)">
  <section class="content">
    <div class="box  box-primary">
      <PageHeader   [mainlisturl]="ListURL" [showSearch]="btnShowSearch"
        [showSave]="btnshowSave" (SaveEvent)="executeSave(this)" [showMainListing]="btnShowMainList"
        [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
      <!-- <button
        type="button"
        (click)="Print()"
        *ngIf="f.status.value == 'Completed'"
        class="btn btn-primary"
        style="    margin-top: -75px;"
      >
        <b>&nbsp;Print</b>
      </button> -->

      <div class="box">
        <div class="box-body">
          <div>
            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Division:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText">
                        {{ selectedDivisionsText }}</label>
                    </div>

                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <select name="divisionId" maxlength="dropdown" class="form-control" [ngClass]="{
                          'has-danger':
                            f.divisionId.errors?.required &&
                            (submitted ||
                              f.divisionId.dirty ||
                              f.divisionId.touched)
                        }" formControlName="divisionId" required (change)="DivisionDropDownChanged()"
                        [attr.disabled]="detailsType == 'Home'?true:null">
                        <option value="{{ item.value }}" *ngFor="let item of DivisionList">
                          {{ item.text }}
                        </option>
                      </select>
                      <span *ngIf="
                          f.divisionId.errors?.required &&
                          (submitted ||
                            f.divisionId.dirty ||
                            f.divisionId.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Equipment:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText">
                        {{ selectedEquipmentText }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <select name="equipmentId" maxlength="dropdown" class="form-control" [ngClass]="{
                          'has-danger':
                            f.equipmentId.errors?.required &&
                            (submitted ||
                              f.equipmentId.dirty ||
                              f.equipmentId.touched)
                        }" formControlName="equipmentId" (change)="GetScheduledPMHours(f.equipmentId.value)" required>
                        <option value="{{ item.value }}" *ngFor="let item of Equipments">
                          {{ item.text }}
                        </option>
                      </select>
                      <span *ngIf="
                          f.equipmentId.errors?.required &&
                          (submitted ||
                            f.equipmentId.dirty ||
                            f.equipmentId.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                      <span *ngIf="f.equipmentId.errors?.PMStatus" [ngClass]="'error'">
                        You cannot start a new PM, while there is an open PM
                        still existing in the system.
                      </span>
                      <span  *ngIf="f.equipmentId.errors?.RepairStatus" [ngClass] = "'error'">
                        You cannot start a new PM, while there is an open Repair still existing in the system.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="f.PMCode.value != ''">
                  <div class="form-group">
                    <label class="col-md-6 control-label">PM Code:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText"> {{ f.PMCode.value }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="f.type.value == '2008K'">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Type:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText">
                        {{ f.subtype.value }}</label>
                    </div>

                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <select name="subtype" maxlength="dropdown" class="form-control" [ngClass]="{
                          'has-danger':
                            f.subtype.errors?.required &&
                            (submitted ||
                              f.subtype.dirty ||
                              f.subtype.touched)
                        }" formControlName="subtype" required (change)="addTransactions(f.subtype.value)">
                        <option value="{{ item.value }}" *ngFor="let item of SubTypeList">
                          {{ item.text }}
                        </option>
                      </select>
                      <span *ngIf="
                          f.subtype.errors?.required &&
                          (submitted ||
                            f.subtype.dirty ||
                            f.subtype.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Date In:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText">
                        {{ dateInText | date: "dd/MM/yyyy" }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <input (focus)="equipmentdateout.toggleCalendar()" (click)="equipmentdateout.openCalendar()"
                        [ngClass]="{
                          'has-danger':
                            f.dateIn.errors?.required &&
                            (submitted || f.dateIn.dirty || f.dateIn.touched)
                        }" class="form-control" style="float:none" placeholder="Select a date" angular-mydatepicker
                        formControlName="dateIn" name="dateIn" [options]="myOptions"
                        #equipmentdateout="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />

                      <span *ngIf="
                          f.dateIn.errors?.required &&
                          (submitted || f.dateIn.dirty || f.dateIn.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="f.status.value == 'Completed'">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Date Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ dateOutText | date: "dd/MM/yyyy" }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Machine Hours In:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText"> {{ f.hoursIn.value }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <input formControlName="hoursIn" name="hoursIn" maxlength="5" (keypress)="numberOnly($event)"
                        [ngClass]="{
                          'has-danger':
                            f.hoursIn.errors?.required &&
                            (submitted || f.hoursIn.dirty || f.hoursIn.touched)
                        }" class="form-control smInput" />
                      <label class="readOnlyTextForHours"
                        *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                        <label class="readOnlyTextForHours" *ngIf="(selectedEquipmentHours==0 || selectedEquipmentHours==null) && f.equipmentId.value">(N/A)</label>
                      <br />
                      <span *ngIf="
                          f.hoursIn.errors?.required &&
                          (submitted || f.hoursIn.dirty || f.hoursIn.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                      <span *ngIf="f.hoursIn.errors?.GreaterHoursOut" [ngClass]="'error'">
                        Hrs IN => previous Hrs Out
                      </span>
                      <span *ngIf="f.hoursIn.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                      <span *ngIf="check4000HoursValidation()" [ngClass]="'error'">

                        New Hours >= 4000 from previous Hours Out.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="f.status.value == 'Completed'">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Machine Hours Out:</label>

                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ f.hoursOut.value }}</label>
                    </div>
                  </div>
                </div>
                <div class="clearfix" *ngIf="f.status.value == 'Completed'"></div>
                <div class="col-md-6" style="display:none">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Scheduled PM:</label>

                    <div class="col-sm-6">
                      <label class="readOnlyText">{{ f.scheduledPM.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Hydraulic Disinfection In:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText"
                        [style.color]="(f.inHydraulicDisinfection.value == 'No') ? 'red' : 'black'">
                        {{ f.inHydraulicDisinfection.value }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <select [style.color]="(f.inHydraulicDisinfection.value == 'No') ? 'red' : 'black'"
                        class="form-control" [ngClass]="{
                          'has-danger':
                            f.inHydraulicDisinfection.errors?.required &&
                            (submitted ||
                              f.inHydraulicDisinfection.dirty ||
                              f.inHydraulicDisinfection.touched)
                        }" formControlName="inHydraulicDisinfection" name="inHydraulicDisinfection">
                        <option value="" style="color:black">Select</option>
                        <option value="Degreasing" style="color:black">Degreasing</option>
                        <option value="Heat Cleaned" style="color:black">Heat Cleaned</option>
                        <option value="N/A" style="color:black">N/A</option>
                      </select>
                      <!-- <select
                        class="form-control"
                        [ngClass]="{
                          'has-danger':
                            f.inHydraulicDisinfection.errors?.required &&
                            (submitted ||
                              f.inHydraulicDisinfection.dirty ||
                              f.inHydraulicDisinfection.touched)
                        }"
                        formControlName="inHydraulicDisinfection"
                        name="inHydraulicDisinfection"
                      > -->
                      <!-- <option
                          value="{{ item.value }}"
                          *ngFor="let item of inhydraulicdisOptions"
                        >
                          {{ item.text }}
                        </option> -->


                      <!-- </select> -->
                      <span *ngIf="
                          f.inHydraulicDisinfection.errors?.required &&
                          (submitted ||
                            f.inHydraulicDisinfection.dirty ||
                            f.inHydraulicDisinfection.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="f.status.value == 'Completed'">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Hydraulic Disinfection Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ f.outHydraulicDisinfection.value }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 box-row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Surface Disinfection In:</label>
                    <div class="col-sm-6" *ngIf="f.status.value != ''">
                      <label class="readOnlyText"
                        [style.color]="(f.inSurfaceDisinfection.value == 'No') ? 'red' : 'black'">
                        {{ f.inSurfaceDisinfection.value }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == ''">
                      <select [style.color]="(f.inSurfaceDisinfection.value == 'No') ? 'red' : 'black'"
                        class="form-control" formControlName="inSurfaceDisinfection" [ngClass]="{
                          'has-danger':
                            f.inSurfaceDisinfection.errors?.required &&
                            (submitted ||
                              f.inSurfaceDisinfection.dirty ||
                              f.inSurfaceDisinfection.touched)
                        }" name="inSurfaceDisinfection">
                        <option value="" style="color:black">Select</option>
                        <option value="CaviWipes XL" style="color: black;">CaviWipes XL</option>
                        <option value="Virox" style="color:black">Virox</option>
                        <option value="N/A" style="color:red">N/A</option>

                        <!-- <option
                          value="{{ item.value }}"
                          *ngFor="let item of insurfacedisOptions"
                        >
                          {{ item.text }}
                        </option> -->

                      </select>
                      <span *ngIf="
                          f.inSurfaceDisinfection.errors?.required &&
                          (submitted ||
                            f.inSurfaceDisinfection.dirty ||
                            f.inSurfaceDisinfection.touched)
                        " [ngClass]="'error'">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="f.status.value == 'Completed'">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Surface Disinfection Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ f.outSurfaceDisinfection.value }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box ibox box-info">
            <p class='text-center' *ngIf="f.type.value == 'PrismaFlex'">PM is required every 6000 hours of operation or once per year whichever occurs first.</p>
            <div class="qustionsList">
              <table class="table table-responsive">
                <thead class="thead-light mrmTableHeader">
                  <tr>
                    <th class="thead-light" style="width:10%">Sec</th>
                    <th class="thead-light" style="width:66%">Description</th>
                    <th class="thead-light" style="width:5%">Image</th>
                    <th style="width:7%" class="thead-light">Value</th>
                    <th class="thead-light" style="width:5%">Note</th>
                    <th style="width:7%" class="thead-light">Tech</th>
                  </tr>
                </thead>
                <tbody formArrayName="transactions"
                  *ngFor="let transaction of preventativeMaintenanceForm.get('transactions')['controls'];let i = index;let last = last">
                  <tr class="mrmTableinnerHeader"
                    *ngIf="showSection && (i==0 || transaction.controls.section.value != preventativeMaintenanceForm.get('transactions')['controls'][i-1].controls.section.value)">
                    <td>{{ transaction.controls.section.value }}</td>
                    <td colspan="6" class="mrmDescription">
                      {{ transaction.controls.sectionName.value }}
                    </td>
                  </tr>
                  <tr [formGroupName]="i" *ngIf="transaction.controls.questionId.value">
                    <td>{{ transaction.controls.questionId.value }}</td>
                    <td
                      [style.color]="transaction.controls.textColor.value ? transaction.controls.textColor.value : '' "
                      class="mrmDescription" [innerHTML]="transaction.controls.question.value">
                      <!-- -->
                    <i class="fa fa-file-image-o" *ngIf="transaction.controls.imgSrc.value"
                    (click)="openImageDialog(transaction)" aria-hidden="true"></i>
                    </td>
                    <td>
                      <i class="fa fa-file-image-o" *ngIf="transaction.controls.imgSrc.value"
                        (click)="openImageDialog(transaction)" aria-hidden="true"></i>
                    </td>
                    <td>
                      <input formControlName="value" name="value"
                        (keypress)="numberOnlywithCondition($event,(transaction.controls.min.value || transaction.controls.max.value))"
                        *ngIf="
                        !transaction.controls.dropdownValues.value &&
                          transaction.controls.isValue.value &&
                          !transaction.controls.isLocked.value
                        " placeholder="{{
                          transaction.controls.errorValueMessage.value
                        }}" [ngClass]="{
                          'input-danger':
                            transaction.controls.errorValueMessage.value != ''
                        }" type="text" class="mrmGridInput" maxlength="6" />

                      <!-- <select name="value" formControlName="value" class="form-control"
                        style="height: 26px;font-size: 11px;" [ngClass]="{
                        'input-danger':
                          transaction.controls.errorValueMessage.value != ''
                      }" *ngIf="
                      transaction.controls.dropdownValues.value &&
                        transaction.controls.isValue.value &&
                        !transaction.controls.isLocked.value
                      ">
                        <option value="">Select </option>
                        <option value="{{ item }}"
                          *ngFor="let item of transaction.controls.dropdownValues.value.split(',')">
                          {{ item }}
                        </option>
                      </select> -->
                      <label title="{{ transaction.controls.value.value }}" class="textReadOnly"
                        *ngIf="transaction.controls.isLocked.value">{{ transaction.controls.value.value }}</label>
                      <mat-button-toggle-group
                      *ngIf="(transaction.controls.questionId.value =='2.2.0' || transaction.controls.questionId.value =='2.3.0'
                      || transaction.controls.questionId.value =='2.4.0' || transaction.controls.questionId.value =='2.5.0') && !transaction.controls.isLocked.value && (f.type.value == '5008' || f.type.value == '5008S')"
                      name="value" formControlName="value">
                        <mat-button-toggle *ngFor="let item of transaction.controls.dropdownValues.value.split(',')"
                          value="{{item}}">
                          {{item}}
                        </mat-button-toggle>
                      </mat-button-toggle-group>

                    </td>
                    <td>
                      <i [class.disabled]="!btnshowSave"
                        class="fa fa-file-text"
                        (click)="AddEditNote(transaction, !btnshowSave)"
                        id="{{'lnk' + this.stringWithoutDot(transaction.controls.questionId.value)}}"
                        (mouseover)="onMouseHover(transaction)"
                        (mouseout)="onMouseOut()" [ngClass]="{
                          hasNotes: GetHasPartsOrNotes(transaction)
                        }" aria-hidden="true"
                        *ngIf="!transaction.controls.isSubSection.value"
                        ></i>
                      <div class="popuptext" [style.top.px]="poptop"
                        id="{{'notepartsPopup' + this.stringWithoutDot(transaction.controls.questionId.value)}}" [innerHtml]="transaction.controls.notepartsHTML.value">
                      </div>
                    </td>

                    <td title="{{ transaction.controls.createdOn.value | date: 'dd/MM/yyyy HH:mm'}}">

                      <mat-checkbox *ngIf="!transaction.controls.isLocked.value && !transaction.controls.isSubSection.value" formControlName="pin"
                      (change)="onPinChange(transaction)"></mat-checkbox>


                      <div *ngIf="!transaction.controls.isLocked.value && transaction.controls.errorMessage.value"
                        [ngClass]="{
                        'danger':
                          transaction.controls.errorMessage.value != ''
                      }">
                        {{transaction.controls.errorMessage.value}}
                      </div>
                      <label title="{{ transaction.controls.createdOn.value | date: 'dd/MM/yyyy HH:mm'}}"
                        class="textReadOnly"
                        *ngIf="transaction.controls.isLocked.value">{{ transaction.controls.techName.value }}</label>
                    </td>
                    <!-- <td>
                      <input
                        type="password"
                        formControlName="pin"
                        *ngIf="!transaction.controls.isLocked.value"
                        [ngClass]="{
                          'input-danger':
                            transaction.controls.errorMessage.value != ''
                        }"
                        placeholder="{{
                          transaction.controls.errorMessage.value
                        }}"
                        name="pin"
                        class="mrmGridInput"
                        (keyup.enter)="onPinChange(transaction)"
                      />
                      <label
                        class="textReadOnly"
                        *ngIf="transaction.controls.isLocked.value"
                        >{{ transaction.controls.techName.value }}</label
                      >
                    </td> -->
                  </tr>
                  <tr
                    *ngIf="last || transaction.controls.section.value != preventativeMaintenanceForm.get('transactions')['controls'][i+1].controls.section.value">
                    <td style="border: none;"></td>
                  </tr>
                </tbody>
              </table>
            </div>





            <div class="clearfix"></div>
            <br />

            <pmnotes
            [isValidForm]="this.preventativeMaintenanceForm.invalid"
            (addNoteClick)="executeSave()"
            (openNoteClick)="AddEditNote(createTransactions(), false)"
            #notes>
            </pmnotes>

            <div class="clearfix"></div>
            <div class="col-md-12 noPaddingAll">
                <!-- <div class="text-left col-md-6 noPaddingAll">
                  <h4>
                    <b>
                      PART SUMMARY
                      <button _ngcontent-c10="" class="btn btn-primary  no-print btn-label-left center-block btn-xs"
                      style="display: inline-block;" type="button"
                      (click)="AddEditNote(createTransactions(), false)">
                      <span _ngcontent-c10=""><i _ngcontent-c10="" class="fa fa-plus" style="font-size:inherit !important"></i></span>
                      </button>
                  </b></h4>

                </div> -->
                <!-- <div class="text-right col-md-6 noPaddingAll">
                    <button class="btn btn-primary  no-print btn-label-left center-block"
                    style="display: inline-block;" type="button"
                    (click)="AddEditNote(createTransactions(), false)">
                    <span><i class="fa fa-plus" style="font-size:inherit !important"></i> Add New</span>
                    </button>
                  </div> -->
                </div>
            <div class="text-left">
              <h4><b>PART SUMMARY</b></h4>
            </div>
            <PM_Parts_Component #parts [hidden]="true"></PM_Parts_Component>
            <div>
              <table class="table table-responsive">
                <thead class="mrmTableinnerHeader thead-light-second">
                  <tr>
                    <th class="thead-light-second" style="width:10%">Sec</th>
                    <th class="thead-light-second" style="width:10%">Date</th>
                    <th class="thead-light-second" style="width:10%">Tech</th>
                    <th class="thead-light-second" style="width:15%">
                      Part ID
                    </th>
                    <th class="thead-light-second" style="width:60%">
                      Part Description
                    </th>
                    <th class="thead-light-second" style="width:10%">
                      Part Qty.
                    </th>
                    <th class="thead-light-second" style="width:10%">
                      Part Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr *ngFor="
                      let item of notes.componentDetails.AllParts;
                      let index = index
                    " [ngClass]="{ afterComplete: item.isAfterCompletionAdd }"> -->
                    <tr *ngFor="
                    let item of notes.componentDetails.AllParts;
                    let index = index">
                    <td>{{ item.questionId ? item.questionId : 'N/A'  }}</td>
                    <td>{{ item.createdOn | date: "dd/MM/yyyy HH:mm" }}</td>
                    <td>{{ item.createdByName }}</td>
                    <td>{{ item.partName }}</td>
                    <td>
                      <div class="text-left break-words">
                        {{ item.partDescription }}
                      </div>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>
                      <div class="text-right">
                        {{ item.totalCost | currency: "CAD":"symbol-narrow" }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="clearfix"></div>
            <br />
            <div class="col-sm-12 text-right">
              <div class="col-sm-2 col-sm-offset-9">
                <b>Total Cost Parts:</b>
              </div>
              <div class="col-sm-1 text-right">
                <b>{{
                  getPartCostTotal() | currency: "CAD":"symbol-narrow"
                }}</b>
              </div>
            </div>
          </div>
          <div>
            <div class="row" *ngIf="f.status.value != 'Completed'">
              <div class="col-md-12 box-row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Date Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ dateOutText | date: "dd/MM/yyyy" }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value != 'Completed'">
                      <input (focus)="dateOut.toggleCalendar()" (click)="dateOut.openCalendar()" class="form-control"
                        [disableControl]="!transactionStatus || f.Id.value == 0" style="float:none"
                        placeholder="Select a date" angular-mydatepicker formControlName="dateOut" name="dateOut"
                        [options]="myOptions" #dateOut="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />

                      <span *ngIf="
                          f.dateOut.errors?.error ||
                          f.dateOut.errors?.InGreaterDateOut
                        " [ngClass]="'error'">
                        Date Out must be greater than or equal to Date In!
                      </span>
                      <span [ngClass]="'error'"
                      *ngIf="f.dateOut.errors?.required && (submittedOut || f.dateOut.dirty || f.dateOut.touched)">
                      Required!
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="f.status.value != 'Completed'">
              <div class="col-md-12 box-row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Machine Hours Out:</label>

                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText">
                        {{ f.hoursOut.value }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value != 'Completed'">
                      <input formControlName="hoursOut" [disableControl]="!transactionStatus || f.Id.value == 0"
                        name="hoursOut" maxlength="5" (keypress)="numberOnly($event)" class="form-control smInput" />

                        <label class="readOnlyTextForHours"
                        *ngIf="f.hoursIn.value && f.Id.value > 0">({{f.hoursIn.value}})</label>
                      <span *ngIf="
                          f.hoursOut.errors?.error ||
                          f.hoursOut.errors?.InGreaterOut
                        " [ngClass]="'error'">
                        Hours Out must be greater than or equal to Hours In!
                      </span>
                      <span [ngClass]="'error'"
                        *ngIf="f.hoursOut.errors?.required && (submittedOut || f.hoursOut.dirty || f.hoursOut.touched)">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="f.status.value != 'Completed'">
              <div class="col-md-12 box-row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Hydraulic Disinfection Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText"
                        [style.color]="(f.outHydraulicDisinfection.value == 'No') ? 'red' : 'black'">
                        {{ f.outHydraulicDisinfection.value }}
                      </label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value != 'Completed'">
                      <select [style.color]="(f.outHydraulicDisinfection.value == 'No') ? 'red' : 'black'"
                        class="form-control" formControlName="outHydraulicDisinfection"
                        [disableControl]="!transactionStatus || f.Id.value == 0" name="outHydraulicDisinfection">
                        <option value="" style="color:black">Select</option>
                        <option value="Degreasing" style="color:black">Degreasing</option>
                        <option value="Heat Cleaned" style="color:black">Heat Cleaned</option>
                        <option value="N/A" style="color:black;">N/A</option>
                        <!-- <option
                          value="{{ item.value }}"
                          *ngFor="let item of inhydraulicdisOptions"
                        >
                          {{ item.text }}
                        </option> -->

                      </select>
                      <span [ngClass]="'error'"
                      *ngIf="f.outHydraulicDisinfection.errors?.required && (submittedOut || f.outHydraulicDisinfection.dirty || f.outHydraulicDisinfection.touched)">
                      Required!
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="f.status.value != 'Completed'">
              <div class="col-md-12 box-row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Surface Disinfection Out:</label>
                    <div class="col-sm-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText"
                        [style.color]="(f.outSurfaceDisinfection.value == 'No') ? 'red' : 'black'">
                        {{ f.outSurfaceDisinfection.value }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value != 'Completed'">
                      <select [style.color]="(f.outSurfaceDisinfection.value == 'No') ? 'red' : 'black'"
                        class="form-control" [disableControl]="!transactionStatus || f.Id.value == 0"
                        formControlName="outSurfaceDisinfection" name="outSurfaceDisinfection">
                        <option value="" style="color:black">Select</option>
                        <option value="CaviWipes XL" style="color: black;">CaviWipes XL</option>
                        <option value="Virox" style="color:black">Virox</option>
                        <option value="N/A" style="color:red">N/A</option>

                        <!-- <option
                          value="{{ item.value }}"
                          *ngFor="let item of insurfacedisOptions"
                        >
                          {{ item.text }}
                        </option> -->
                      </select>
                      <span [ngClass]="'error'"
                        *ngIf="f.outSurfaceDisinfection.errors?.required && (submittedOut || f.outSurfaceDisinfection.dirty || f.outSurfaceDisinfection.touched)">
                        Required!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="f.status.value != 'Completed'">
              <div class="col-md-12 box-row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-md-6 control-label">Technologist:</label>
                    <div class="col-md-6" *ngIf="f.status.value != 'Completed'">
                      <label class="readOnlyText"> {{ currentUser }}</label>
                    </div>
                    <div class="col-md-6" *ngIf="f.status.value == 'Completed'">
                      <label class="readOnlyText"> {{ f.PMUser.value }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageHeader   [mainlisturl]="ListURL" [showSearch]="btnShowSearch"
        [showSave]="btnshowSave" (SaveEvent)="executeSave(this)" [showMainListing]="btnShowMainList"
        [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
    </div>
  </section>
</form>


<!--
<div >
<div >
    <div class="box-header" style="border-bottom: 1px solid black;" *ngIf="qNotes && qNotes.length > 0">
      <h3 class="box-title"><b>Notes</b></h3>
    </div>
    <div class="box-body">
      <div class="row ng-star-inserted">
        <div class="col-sm-12 box-row">
          <div class="col-sm-2 text-center"><strong>Date</strong></div>
          <div class="col-sm-1 text-center"><strong>User</strong></div>
          <div class="col-sm-1 text-center"><strong>Note</strong></div>
        </div>
      </div>
      <div class="col-sm-12 box-row" *ngFor="let item of qNotes">
        <td class="col-sm-2 text-center">{{ item.createdOn | date:'dd/MM/yyyy HH:mm' }}</td>
        <td  class="col-sm-1 text-center">{{item.createdByName}}</td>
        <td  class="col-sm-5 text-left">{{ item.description }}</td>

      </div>

    </div>
  </div>
<div>
  <div class="box-header" style="border-bottom: 1px solid black;" *ngIf="qParts && qParts.length > 0">
    <h3 class="box-title"><b>Replaced Part</b></h3>
  </div>
  <div class="box-body">
    <div class="row ng-star-inserted">
      <div class="col-sm-12 box-row">
        <div class="col-sm-2 text-center"><strong>Date</strong></div>
        <div class="col-sm-1 text-center"><strong>User</strong></div>
        <div class="col-sm-1 text-center"><strong>Part ID</strong></div>
        <div class="col-sm-5 text-center"><strong>Part Description</strong></div>
        <div class="col-sm-1 text-center"><strong>Part Qty.</strong></div>
        <div class="col-sm-1 text-center"><strong>Part Cost</strong></div>
      </div>
    </div>

    <div class="col-sm-12 box-row" *ngFor="let item of qParts">
      <div class="col-sm-2 text-center ng-star-inserted"> {{item.createdOn | date:'dd/MM/yyyy HH:mm' }}	 </div>
      <div class="col-sm-1 text-center"> {{item.createdByName }} </div>
      <div class="col-sm-1 text-center"> {{item.partName}}	 </div>
      <div class="col-sm-5 text-left break-words">  {{item.partDescription}} </div>
      <div class="col-sm-1 text-center"> {{item.quantity}} </div>
      <div class="col-sm-1 text-right">{{(item.totalCost | currency:"CAD":"symbol-narrow") }} </div>
    </div>
</div>
</div>  -->

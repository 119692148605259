<!-- <h1 md-dialog-title>Confirm</h1>
<div md-dialog-content>{{confirmMessage}}</div>
<div md-dialog-actions>
  <button md-button style="color: #fff;background-color: #153961;" (click)="dialogRef.close(true)">Confirm</button>
  <button md-button (click)="dialogRef.close(false)">Cancel</button>
</div> -->
<div class="modal-header col-sm-12">
    <h4 class="modal-title col-sm-8">{{NoteHeader}}</h4>

      <div class="text-right  col-sm-4" style="padding:0">
        <!-- <button type="button" class="btn btn-danger" style="margin-right:5px" (click)="dialogRef.close(false)">Cancel</button>
        <button type="button" class="btn btn-primary"  (click)="save()">Save</button> -->
        <button type="button" class="btn btn-danger" (click)="saveAndClose()">Save / Close</button>
      </div>

      <!-- <button  type="button" class="col-sm-2 close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      <div class="box-row col-sm-12" *ngIf="showHours==true" >
        <label class="col-sm-8" style="margin-top: 6px;">Estimated time (Hours):</label>
            <div class="input-group col-sm-3">
                <span class="input-group-btn">
                    <button type="button" class="quantity-left-minus btn btn-danger btn-number" (click)="MinusHours()"   data-type="minus" data-field="">
                      <span class="glyphicon glyphicon-minus"></span>
                    </button>
                </span>
                  <input style="display: inline;text-align: center" class="form-control" [(ngModel)]="hours" name="hours"
                    maxlength="3" (keypress)="numberOnly($event)" disabled="disabled"/>
                <span class="input-group-btn">
                    <button type="button" class="quantity-right-plus btn btn-success btn-number" (click)="PlusHours()" data-type="plus" data-field="">
                        <span class="glyphicon glyphicon-plus"></span>
                    </button>
                </span>
            </div>
        <span  class="error">{{errormsg}}</span>

      </div>
      <p>
        <textarea name="note" #noteId autosize  [(ngModel)]="notes" class="form-textarea"></textarea>
      </p>
    </div>
    <Parts_Component  *ngIf="showParts===true" #NoteParts ></Parts_Component>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="dialogRef.close(false)">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="save()">Save</button>
    </div> -->

import { Component, ViewChild } from '@angular/core';
import { AppComponent } from "../app.component";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Globals, actionType } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MailApiService } from '../API/mailapi.service';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '../Core/Shared/CanComponentDeactivate';

import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { map, groupBy } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { CustomValidationStationGroup } from '../Validator/CustomValidationStationGroup';
import { debug } from 'util';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'MailCompose',
  templateUrl: './MailCompose.component.html',
  styleUrls: ['./MailDetail.css'],

})
export class MailComposeComponent {

  dialogRef: MatDialogRef<ConfirmationDialog>;
  state: string;
  stationsCount: number = 25;
  blnshowDelete = false;
  blnshowSave = false;
  blnshowEdit = true;
  ListURL = "/Mail/MailSearch";
  public message: string;
  lockText = "Lock";
  unlockText = "Unlock";
  onText = "Yes";
  offText = "No";
  onColor = "success";
  offColor = "danger";
  lockStations = ['Lock'];
  lockValues = [
    { name: 'Lock' },
    { name: 'Unlock' }
  ]
  CountryList = [];
  StateList = [];
  BacterialLevelList = [];
  createdBy = this.authentication.GetUserId();
  modifiedBy = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  items: UntypedFormArray;
  stationItems: UntypedFormArray;
  formSubmitted: boolean = false;
  validateForm: boolean = false;
  Math: any;
  homeProgram = false;
  opt = false;
  userDesignation = this.authentication.GetDesignationName();



  MailForm = new UntypedFormGroup({
    formName: new UntypedFormControl('', Validators.required),
    toName: new UntypedFormControl('', Validators.required),
    Subject: new UntypedFormControl('', Validators.required),
    Message: new UntypedFormControl('', Validators.required),
    IsMandatory: new UntypedFormControl('',),
  });

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder, private mailApi: MailApiService, private route: ActivatedRoute,
    public dialog: MatDialog,private commonapi : CommonApiService,
    private router: Router) {
    this.Math = Math;
  }


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (
      ((this.MailForm.dirty) && (this.validateForm == false))
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else { return true; }
  }

  executeMailCreateUpdate = function (Source = "") {
    AppComponent.loading = true;
    this.formSubmitted = true;

    if (this.MailForm.invalid) {
      if (this.MailForm.controls.stations && this.MailForm.controls.stations.errors && this.MailForm.controls.stations.errors.duplicate) {
        AppComponent.addAlert(this.MailForm.controls.stations.errors.duplicate, 'error');
      }
      else
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');

      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      this.MailForm.markAsDirty();
      AppComponent.ScrollDown();
      return;
    }


    //
    // this.validateForm = true;
    // if (this.MailForm.controls.divisionId.value === '0'
    // ) {
    //   const modelCreate = this.prepareCreateViewModel();
    //   this.divisionApi.CreateDivisionDetails(modelCreate).subscribe(
    //     success => {
    //       this.notes.Note = '';
    //       this.MailForm.controls.divisionId.value = success.divisionId;

    //       AppComponent.loading = false;
    //       AppComponent.addAlert('Data Saved successfully!', 'success');

    //       if (Source !== 'Notes') {
    //         this.router.navigate([this.ListURL]);
    //       }
    //       else {
    //         this.fillNotes();
    //         this.fetchStations(this.f.divisionId.value);
    //         this.router.navigate(['Admin/Division'], { queryParams: { id: success.divisionId } });
    //       }
    //     },
    //     error => {
    //       if (error.error && error.error.returnMessage) {
    //         AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
    //         this.scrollHelper.scrollToFirst('logo');
    //       }
    //       else
    //         AppComponent.addAlert(error.message, 'error');

    //       AppComponent.HideLoader();
    //     }
    //   );
    // } else {
    //   this.MailForm.value.createdBy = this.createdBy;
    //   this.MailForm.value.modifiedBy = this.modifiedBy;
    //   const modelCreate = this.prepareCreateViewModel();
    //   this.divisionApi.UpdateDivisionDetails(modelCreate).subscribe(
    //     success => {
    //       this.notes.Note = '';
    //       AppComponent.loading = false;
    //       AppComponent.addAlert('Data Saved successfully!', 'success');

    //       if (Source !== 'Notes') {
    //         this.router.navigate([this.ListURL]);
    //       }
    //       else {
    //         this.fillNotes();
    //         this.fetchStations(this.f.divisionId.value);
    //         this.router.navigate(['Admin/Division'], { queryParams: { id: success.divisionId } });
    //       }
    //     },
    //     error => {
    //       if (error.error && error.error.returnMessage) {
    //         AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
    //         this.scrollHelper.scrollToFirst('logo');
    //       }
    //       else
    //         AppComponent.addAlert(error.message, 'error');

    //       AppComponent.HideLoader();
    //     }
    //   );
    // }
  };

  SetPageRights() {
    // this.authentication.GetPageRights('Contact').subscribe(list => {

    //   if (this.f.divisionId.value !== '0') {
    //     this.blnshowSave = list[actionType.edit] === '1';
    //     //this.blnshowDelete = list[actionType.delete] === '1';
    //     this.blnshowEdit = true;

    //   } else {
    //     this.blnshowSave = list[actionType.create] === '1';
    //     //this.blnshowDelete = false;
    //     this.blnshowEdit = false;

    //   }
    // });

    // if (this.userDesignation == 'Administrator' && this.f.divisionId.value != '0') {
    //   this.blnshowDelete = true;
    // }
  }
  prepareCreateViewModel() {
    const modelCreate = <any>{};
    return modelCreate;
  }
  ngOnInit()
  {

  }
  setModel() {
    AppComponent.ShowLoader();
  }
  get f() {
    return this.MailForm.controls;
  }
  onChange(e) {
  }
}

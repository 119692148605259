import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableHeadsService } from 'src/app/Core/tableheads.service';
import { PageHeaderComponent } from 'src/app/Core/pageheader.component';
import { AppComponent } from 'src/app/app.component';
import { DesignationApiService } from 'src/app/API/designationapi.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'DesignationSearch',
  templateUrl: './DesignationSearch.component.html',

})
export class DesignationSearchComponent {

  ShowSearch = false;
  searchText = 'Search Records';
  selectedDivision ='';
  blnDirtySearch = false;
  DialysisUnits = [];
  
  constructor( private designationAPI: DesignationApiService , private router: Router, 
    private commonapi : CommonApiService,
    private dataGridService: TableHeadsService, ){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows ;
  AddUrl = '/Admin/UserDesignationDetail';
  currentPageNumber = 1;
  sortExpression = 'designationName';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'User Designation';
  defaultSort;


  ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'designationId', '5');
    this.dataGridService.addHeader('Designation Name', 'designationName', '65');    
    // this.dataGridService.addHeader('Show All Schedule', 'showAllSchedule', '10');
    // this.dataGridService.addHeader('Division', 'designationName', '50');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('designationName', false);
    this.fillDialysisUnit();
    this.CheckDirtySearch();
  }

  executeDesignationSearchClear() {
    this.selectedDivision = '';   
    this.executeDesignationSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      this.selectedDivision      
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  
  fillDialysisUnit() {
    this.commonapi.FillDivision().subscribe(list =>{ 
      this.DialysisUnits = list.filter(
        x => x.text != "Home Program"
      );
    });
  }


  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeDesignationSearch();
  }

  setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeDesignationSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.ShowSearch = false;
    this.pageheader.searchText = 'Search Records';
    this.CheckDirtySearch();

    const designationSearch = this.prepareSearch();
    this.designationAPI.GetDesignationSerachDetails(designationSearch).subscribe(pagedData  => {
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.designationDetailList;
         console.log(pagedData);
        AppComponent.HideLoader();
      }
    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );
  }

prepareSearch() {
  // tslint:disable-next-line:prefer-const
  let Designationsearch = <any>{};
  Designationsearch.currentPageNumber = this.currentPageNumber;
  Designationsearch.sortExpression = this.sortExpression;
  Designationsearch.sortDirection = this.sortDirection;
  Designationsearch.PageSize = this.pageSize;

  if (this.selectedDivision !== undefined && this.selectedDivision !== '') {
    Designationsearch.divisionName = this.selectedDivision;
  }
   // console.log(Designationsearch);
  return Designationsearch;
}

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeDesignationSearch();
  }

   _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

<div [formGroup]="fullInstallationForm" novalidate *ngIf="fullInstallationForm" style="border:1px solid lightgray;padding: 10px;">

    <div class="clearfix"></div>
    <div class="box-row col-sm-12">
      <div class="col-sm-4"><p class="InnerPurposeHFSHeader">DM & RO ASSIGNMENT</p></div>
    </div>
    <div class="clearfix"></div>
  <div class="box-row marginBottom clearfix">
      <div class="col-sm-offset-2 col-sm-10 box-row">
        <div class="col-sm-4">
          <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Home DM:</label>

          <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
            <select class="form-control mdInput" formControlName="dmEquipmentId" name="dmEquipmentId">
              <option value="">Select</option>
              <option value="{{item.value}}" *ngFor="let item of dmEquipmentList">{{item.text}}</option>
            </select>
            <span *ngIf="fif.dmEquipmentId.errors?.required && (fif.dmEquipmentId.dirty || fif.dmEquipmentId.touched || formSubmitted)" [ngClass]="'error'"> Required!
            </span>
          </div>
          <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
              <label class="readOnlyText" formControlName="dmEquipment"> {{fif.dmEquipment.value}}</label>
            </div>
        </div>
        <div class="col-sm-4">
          <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>Home RO:</label>

          <div class="col-sm-7" *ngIf="IsPurposeCompleted != 'Yes'">
              <select class="form-control mdInput" formControlName="roEquipmentId" name="roEquipmentId">
                  <option value="">Select</option>
                  <option value="{{item.value}}" *ngFor="let item of roEquipmentList">{{item.text}}</option>
              </select>
              <span
              *ngIf="fif.roEquipmentId.errors?.required && (fif.roEquipmentId.dirty || fif.roEquipmentId.touched ||  formSubmitted)"
              [ngClass]="'error'">Required!</span>
          </div>

          <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
              <label class="readOnlyText" formControlName="roEquipment"> {{fif.roEquipment.value}}</label>
            </div>
        </div>
      </div>
      <!-- <div class="col-sm-12 box-row">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Plumber:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poPlumber" name="poPlumber">
                  <span *ngIf="fif.poPlumber.errors?.required && (fif.poPlumber.dirty || fif.poPlumber.touched || formSubmitted)" [ngClass]="'error'"> Required!
                  </span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poPlumber"> {{fif.poPlumber.value}}</label>
              </div>

          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20"  formControlName="poPlumberNote" name="poPlumberNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poPlumberNote"> {{fif.poPlumberNote.value}}</label>
          </div>

      </div>
      <div class="col-sm-12 box-row">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Electrician:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poElectrician" name="poElectrician">
                  <span *ngIf="fif.poElectrician.errors?.required && (fif.poElectrician.dirty || fif.poElectrician.touched || formSubmitted)" [ngClass]="'error'"> Required!
                  </span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poElectrician"> {{fif.poElectrician.value}}</label>
              </div>

          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20" formControlName="poElectricianNote" name="poElectricianNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poElectricianNote"> {{fif.poElectricianNote.value}}</label>
          </div>
      </div>
      <div class="col-sm-12 box-row">
          <div class="col-sm-4">
              <label class="col-sm-5 control-label"><span class="text-red inputrequired">*</span>PO Movers:</label>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted != 'Yes'">
                  <input class="form-control mdInput"  maxlength="10"
                  formControlName="poMovers" name="poMovers">
                  <span *ngIf="fif.poMovers.errors?.required && (fif.poMovers.dirty || fif.poMovers.touched || formSubmitted)" [ngClass]="'error'"> Required!
                  </span>
              </div>
              <div class="col-sm-7"  *ngIf="IsPurposeCompleted == 'Yes'">
                  <label class="readOnlyText" formControlName="poMovers"> {{fif.poMovers.value}}</label>
              </div>

          </div>
          <div class="col-sm-4" *ngIf="IsPurposeCompleted != 'Yes'">
            <input class="form-control mdInput" maxlength="20" formControlName="poMoversNote" name="poMoversNote">
          </div>
          <div class="col-sm-4"  *ngIf="IsPurposeCompleted == 'Yes'">
            <label class="readOnlyText" formControlName="poMoversNote"> {{fif.poMoversNote.value}}</label>
          </div>
      </div> -->

  </div>
<!--
  <attachment (RaiseError)="AttachmentError($event)" #attachment>

  </attachment> -->
</div>

import { Component, ViewChild } from '@angular/core';
import { TableHeadsService } from '../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import { FeedbackDetailApiService } from '../API/feedbackapi.service';
import { NgForm } from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'feedbackdetail',
  templateUrl: './feedbackdetail.component.html',
  styleUrls: ['./feedbackdetail.component.css']
})
export class FeedbackdetailComponent {

  constructor( private feedbackApi:FeedbackDetailApiService,private router:Router, private dataGridService: TableHeadsService){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }


  @ViewChild(PageHeaderComponent, { static: false }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];
    Equipments=[];
  Users=[];
  Parts=[];
  Statuses=[];
  Patients=[];
  feedbackdetail =[];
  selectedDivision = "";
  selectedEquipment = "";
  selectedPart = "";
  selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  RepairId="";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef="";
  OutFromDate;
OutToDate;
tableHeaders;
page = new Page();
rows ;
AddUrl = "/feedback/feedbackdetail";
workOrderRefNo="";
  currentPageNumber = 1;
  sortExpression = "dmrepairdetailsid";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch=false;
  title = "FeedBack Details";
  searchText="Search Records"
defaultSort;

feedbackform = new UntypedFormGroup({
  notetype: new UntypedFormControl('', Validators.required),
  note: new UntypedFormControl('', Validators.required),
  createdBy: new UntypedFormControl(''),
  modifiedBy: new UntypedFormControl(''),
});

ngOnInit() {
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#", "feedbackid","4");
    this.dataGridService.addHeader("Date & Time", "datetime", "10");
    this.dataGridService.addHeader("Note Type", "notetype", "10");
    this.dataGridService.addHeader("Note", "Note", "60");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("Date & Time",true);

    this.setModel();

  }

  
  changeSorting(column){
    if (column =="#")
    {
        return;
    }

      this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
      this.defaultSort = this.dataGridService.getSort();
      this.sortDirection = this.dataGridService.getSortDirection();
      this.sortExpression = this.dataGridService.getSortExpression();
      this.currentPageNumber = 1;
      this.page.pageNumber = 1;
    //  this.executeDmrepairSearch();

  };

  setSortIndicator = function (column) {
    //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  _onRowClick(row) {
    this.router.navigate(['feedback/feedbackdetail'], { queryParams: { id: row} });
  }

  setModel() {
    AppComponent.ShowLoader();
    const feedbackID = { feedbackID: 0 };
    this.feedbackApi.GetFeedbackDetails(feedbackID).subscribe(
      success => {
        if (success) {

            //alert('success'+ success);
           // console.log(success);
            //  this.feedbackform.setValue({
            //    datetime: success.feedbackDateTime,
            //    noteType: success.noteType,
            //    note: success.note
            //  });
        }


        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );


  }

}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

import { FormControl, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { HomeFieldService } from '../API/homefield.service';
import { map } from 'rxjs/operators';

export class CommonCustomValidators {
  static CheckDateoutCustomValidation(

    OutTimeOfEquipment: AbstractControl) {
    //console.log(OutTimeOfEquipment.value);
    return (control: AbstractControl) => {
      // console.log(control.value);
      return ((OutTimeOfEquipment.value != "" && OutTimeOfEquipment.value != null) && (control.value == "" || control.value == null)) ? { RequiredStatus: true } : null;
    };
  };
  static CheckHoursoutGreaterHoursInCustomValidation(
    HoursIN: AbstractControl) {

    return (control: AbstractControl) => {

      return ((HoursIN.value != "" && HoursIN.value != null) &&
        (control.value != "" && control.value != null) &&
        (parseFloat(HoursIN.value) > parseFloat(control.value))) ? { InGreaterOut: true } : null;
    };
  };

  static CheckDateOutGreaterDateInInCustomValidation(
    dateIn: AbstractControl) {
    return (control: AbstractControl) => {
      //
      return (dateIn && dateIn.value && control && control.value && new Date(dateIn.value.date.year, dateIn.value.date.month - 1, dateIn.value.date.day) > new Date(control.value.date.year, control.value.date.month - 1, control.value.date.day)) ? { InGreaterDateOut: true } : null;
    };
  };

  static CheckDateTimeGreaterValidation(
    sourceDate: AbstractControl, destDate: AbstractControl) {
    return (control: AbstractControl) => {
      return (sourceDate && sourceDate.value
        && destDate && destDate.value
        && new Date(sourceDate.value.date.year, sourceDate.value.date.month - 1, sourceDate.value.date.day) > new Date(destDate.value.date.year, destDate.value.date.month - 1, destDate.value.date.day)) ? { GreaterDate: true } : null;
    };
  };

  static CheckDateTimeGreaterNotEqualValidation(
    sourceDate: AbstractControl, destDate: AbstractControl) {
    return (control: AbstractControl) => {
      return (sourceDate && sourceDate.value
        && destDate && destDate.value
        && new Date(sourceDate.value.date.year, sourceDate.value.date.month - 1, sourceDate.value.date.day) >= new Date(destDate.value.date.year, destDate.value.date.month - 1, destDate.value.date.day)) ? { GreaterDate: true } : null;
    };
  };

  static CheckKMSGreaterValidation(
    sourceKMS: AbstractControl, destKMS: AbstractControl) {
    return (control: AbstractControl) => {

      return (sourceKMS && sourceKMS.value
        && destKMS && destKMS.value
        && (parseInt(sourceKMS.value) >= parseInt(destKMS.value))) ? { GreaterKMS: true } : null;
    };
  };

  static CheckDateAndTimeGreaterValidation(
    sourceDate: AbstractControl, destDate: AbstractControl) {
    return (control: AbstractControl) => {
      let srcDate;
      let desDate;
      let today =  srcDate = desDate = new Date();
      if(sourceDate && sourceDate.value && destDate && destDate.value){
        srcDate = Date.parse(sourceDate.value) ? new Date(sourceDate.value).setSeconds(0,0) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), sourceDate.value.split(":")[0], sourceDate.value.split(":")[1], 0);
        desDate = Date.parse(destDate.value) ? new Date(destDate.value).setSeconds(0,0) : new Date(today.getFullYear(), today.getMonth(), today.getDate(), destDate.value.split(":")[0], destDate.value.split(":")[1], 0);
      }

      return (sourceDate && sourceDate.value && destDate && destDate.value
        && srcDate && desDate
        && srcDate > desDate) ? { GreaterDate: true } : null;
    };
  };

  static CheckDateTimeGreaterWithTimeValidation(
    sourceDate: AbstractControl, sourceTime: AbstractControl, destDate: AbstractControl, destTime: AbstractControl) {
    return (control: AbstractControl) => {
      return (sourceDate && sourceDate.value && sourceTime && sourceTime.value
        && destDate && destDate.value && destTime && destTime.value
        && new Date(sourceDate.value.date.year, sourceDate.value.date.month - 1, sourceDate.value.date.day, sourceTime.value.split(":")[0], sourceTime.value.split(":")[1], 0) >
        new Date(destDate.value.date.year, destDate.value.date.month - 1, destDate.value.date.day, destTime.value.split(":")[0], destTime.value.split(":")[1], 0)) ? { GreaterDate: true } : null;
    };
  };

  static CheckDateTimeWPGreaterWithTimeValidation(
    sourceDate: AbstractControl, sourceTime: AbstractControl, destDate: AbstractControl, destTime: AbstractControl) {
    return (control: AbstractControl) => {
      return (sourceDate && sourceDate.value && sourceTime && sourceTime.value
        && destDate && destDate.value && destTime && destTime.value
        && new Date(sourceDate.value.date.year, sourceDate.value.date.month - 1, sourceDate.value.date.day, sourceTime.value.getHours(), sourceTime.value.getMinutes(), 0) >
        new Date(destDate.value.date.year, destDate.value.date.month - 1, destDate.value.date.day, destTime.value.getHours(), destTime.value.getMinutes(), 0)) ? { GreaterDate: true } : null;
    };
  };


  static GetHFS_IsKmsIsGreaterThanPreviousKms(EquipmentId: AbstractControl, HFServiceId: AbstractControl, hfsapi: HomeFieldService) {

    return (control: AbstractControl) => {
        var hfCreate = { equipmentId: EquipmentId.value, kms: control.value, HFServiceId: HFServiceId.value };

        return hfsapi.GetHFS_IsKmsIsGreaterThanPreviousKms(hfCreate).pipe(map(res => {
          return res == true ? null : { IsKmsIsGreaterThanPreviousKms: true };
        }));
    }
  }

  static GetHFS_GREquipmentVehicleIsAvailable(HFServiceId: AbstractControl, hfsapi: HomeFieldService) {

    return (control: AbstractControl) => {
        var hfCreate = { equipmentId: control.value, HFServiceId: HFServiceId.value,kms:'0' };

        return hfsapi.GetHFS_GREquipmentVehicleIsAvailable(hfCreate).pipe(map(res => {
          return res == true ? null : { IsVehicleAvailable: true };
        }));
    }
  }

  static GetHFS_GRHomeStationIsAvailable(hfsapi: HomeFieldService) {

    return (control: AbstractControl) => {
        var hfCreate = { Id: control.value };

        return hfsapi.GetHFS_GRHomeStationIsAvailable(hfCreate).pipe(map(res => {
          return res == true ? null : { IsHFSStationAvailable: true };
        }));
    }
  }

  static CheckDateTimeGreaterWithTimeValidationPyrogen(
    sourceDate: AbstractControl, sourceTime: AbstractControl, destDate: AbstractControl, destTime: AbstractControl) {
    return (control: AbstractControl) => {
      return (sourceDate && sourceDate.value && sourceTime && sourceTime.value
        && destDate && destDate.value && destTime && destTime.value
        && new Date(sourceDate.value.date.year, sourceDate.value.date.month - 1, sourceDate.value.date.day, sourceTime.value.split(":")[0], sourceTime.value.split(":")[1], 0) >
        new Date(destDate.value.date.year, destDate.value.date.month - 1, destDate.value.date.day, destTime.value.getHours(), destTime.value.getMinutes(), 0)) ? { GreaterDate: true } : null;
    };
  };

}


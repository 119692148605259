<form [formGroup]="FieldServiceForm" (ngSubmit)="executeFieldServiceCreateUpdate('')" #mainform novalidate>


  <!-- <RepairPart></RepairPart> -->
  <section class="content-header">
    <h1>
      Home Program Field Service Record<sup class="beta">[demo]</sup>

    </h1>

  </section>
  <section class="content">

    <div class="row">

      <div class="box  box-primary">

        <PageHeader   [showNote]="true" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
          [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeFieldServiceCreateUpdate('')"
          [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-sm-12 box-row hidden">
                <label class="col-sm-2 control-label">FieldServiceDetailsId</label>

                <div class="col-sm-10">
                  <input type="text" readonly class="form-control" name="FieldServiceDetailsId" [value]="f.FieldServiceDetailsId"
                    formControlName="FieldServiceDetailsId">
                </div>
              </div>


              <div class="box ibox box-info">

                <div class="box-body">
                  <div class="row">
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-4">
                        <label class="col-sm-6 control-label">

                          Event ID:
                        </label>

                        <div class="col-sm-6">
                          <label class="readOnlyText">{{EventCode}}</label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-6 control-label">Date:</label>
                        <div class="col-sm-6">
                          <label class="readOnlyText">
                            {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                          </label>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-8 control-label">Current User:</label>
                        <div class="col-sm-4">
                          <label class="readOnlyText">
                            {{CurrentUserName}}
                          </label>
                        </div>
                      </div>

                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-4">
                        <label class="col-sm-6 control-label">

                          Station Location(s):
                        </label>

                        <div class="col-sm-6">
                          <select class="form-control mdInput">
                            <option value="{{item.value}}" *ngFor="let item of DialysisStation">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-6 control-label">Purpose:</label>
                        <div class="col-sm-6">
                          <select class="form-control mdInput">
                            <option value="{{item.value}}" *ngFor="let item of lstPurpose">
                              {{item.text}}
                            </option>
                          </select>
                        </div>

                      </div>
                      <div class="col-sm-4">
                        <label class="col-sm-7 control-label">Verified Hm/Station Tasks:</label>
                        <div class="col-sm-4">
                          <select class="form-control mdInput">
                            <option [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'" value="{{item.value}}"
                              *ngFor="let item of lstYesNo">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                        <div class="col-sm-1"><button  class="btn btn-primary btn-label-left center-block"
                            type="button">
                            <span><i class="fa fa-plus"></i></span>

                          </button></div>
                      </div>
                    </div>
                    <div class="clearfix"></div>

                  </div>

                </div>

              </div>

              <div class="box ibox box-info">

                <div class="box-body">
                  <div class="row">
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">

                          Date:
                        </label>

                        <div class="col-sm-8">
                          <input (focus)="rEventDate.toggleCalendar()" (click)="rEventDate.openCalendar()" placeholder="Select a date"
                            class="form-control mdInput" style="float:none" angular-mydatepicker name="EventDate"
                            formControlName="EventDate" [options]="myOptions" #rEventDate="angular-mydatepicker"
                            (dateChanged)="onDateChanged($event)" />
                          <span *ngIf="f.EventDate.errors?.required && (f.EventDate.dirty || f.EventDate.touched ||  isValidFormSubmitted==false)"
                            [ngClass]="'error'">
                            Required!
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">Technologist(s):</label>
                        <div class="col-sm-8">
                          <ng-multiselect-dropdown [placeholder]="'Select'" [data]="UserList" formControlName='Technologists'
                            [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                            name="Technologists">
                          </ng-multiselect-dropdown>


                        </div>
                      </div>


                    </div>
                    <div class="clearfix"></div>

                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">

                          Prep Time:
                        </label>

                        <div class="col-sm-8">
                          <select class="form-control mdInput">
                            <option value="{{item.value}}" *ngFor="let item of lstPrepTime">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">Start Time:</label>
                        <div class="col-sm-8">
                          <input name="StartTime" />
                        </div>
                      </div>


                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">

                          Vehicle:
                        </label>

                        <div class="col-sm-8">
                          <select class="form-control mdInput">
                            <option value="{{item.value}}" *ngFor="let item of lstPrepTime">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-4 control-label">Vehicle Km START:</label>
                        <div class="col-sm-8">
                          <input name="StartTime" />
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingTwo">
                          <h4 class="panel-title">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"
                              aria-expanded="false" aria-controls="collapseTwo">
                              <strong>Vehicle Inspection</strong>
                            </a>
                          </h4>

                        </div>
                        <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                          <div class="panel-body">
                            <div class="col-sm-12">
                              <div class="col-sm-12 box-row ">

                                <table style="width:100%" align="center" class="table table-responsive">
                                  <tr>
                                    <th class="thead-light" width="85%">
                                      Vehicle Inspection
                                    </th>
                                    <th class="thead-light">Pass</th>
                                    <th class="thead-light">Fail</th>
                                    <th class="thead-light">See Notes</th>
                                  </tr>
                                  <tbody>
                                    <tr>
                                      <td class="mrmDescription" width="85%">
                                        <b>Vehicle.</b><br>
                                        Conduct circle check. Note any damage, leaks under vehicle, or obvious
                                        maintenance issues.
                                      </td>
                                      <td><input width="5%" type="radio" value="Pass" name="rdvehicle"></td>
                                      <td><input width="5%" type="radio" value="Fail" name="rdvehicle"></td>
                                      <td><input width="5%" type="radio" value="See Notes" name="rdvehicle"></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Vehicle.</b><br>Conduct circle check.
                                        Note any damage, leaks under vehicle, or obvious maintenance issues. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdvehicle'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdvehicle'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdvehicle'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Tires.</b><br>Check tire for wear and
                                        pressure. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdtires'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdtires'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdtires'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Load restraint. </b><br>Ensure all
                                        items are securely stored and/or strapped down. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdload_restraint_'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdload_restraint_'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdload_restraint_'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Safety Belts. </b><br>Examine for wear
                                        and proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdsafety_belts_'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdsafety_belts_'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdsafety_belts_'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Fuel. </b><br>Verify enough fuel for
                                        trip plus reserves. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdfuel'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdfuel'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdfuel'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Engine operation.</b><br>Note any
                                        unusual engine noise. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdengine_operation'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdengine_operation'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdengine_operation'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Side Mirrors.</b><br>Clean; correctly
                                        adjusted </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdside_mirrors'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdside_mirrors'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdside_mirrors'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Wiper Blades.</b><br>Test and verify
                                        proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdwiper_blades'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdwiper_blades'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdwiper_blades'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Washer fluid.</b><br>Test and verify
                                        proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdwasher_fluid'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdwasher_fluid'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdwasher_fluid'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Headlights. </b><br>Test running
                                        lights/high beam/low beam. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdheadlights_'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdheadlights_'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdheadlights_'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Brake lights.</b><br>Test and verify
                                        proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdbrake_lights'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdbrake_lights'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdbrake_lights'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Tail Lights/ Turn Signals.</b><br>Test
                                        and verify proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdtail_lights__turn_signals'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdtail_lights__turn_signals'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdtail_lights__turn_signals'></td>
                                    </tr>

                                    <tr>
                                      <td class='mrmDescription' width='85%'> <b>Emergency Light/Flashers.</b><br>Test
                                        and verify proper functioning. </td>
                                      <td><input width='5%' type='radio' value='Pass' name='rdemergency_light_flashers'></td>
                                      <td><input width='5%' type='radio' value='Fail' name='rdemergency_light_flashers'></td>
                                      <td><input width='5%' type='radio' value='See Notes' name='rdemergency_light_flashers'></td>
                                    </tr>
                                    <tr>
                                      <td class='mrmDescription' colspan="4"></td>
                                    </tr>

                                  </tbody>

                                </table>
                                <notes [isValidForm]="f.invalid" (addNoteClick)="executeFieldServiceCreateUpdate('Notes')"
                                  #vnotes></notes>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>

                  </div>

                </div>

              </div>

              <div class="box ibox box-info">

                <div class="box-body">
                  <div class="row">
                        <div class="col-sm-12 box-row">

                                <div class="col-sm-6">
                                  <label class="col-sm-4 control-label" style="text-align:left">Pt Hm Arrival Time:</label>
                                  <div class="col-sm-8">
                                    <input class="smInput" name="StartTime" /> <label>&nbsp;(24Hour Clock)</label>



                                  </div>
                                </div>


                              </div>
                              <div class="clearfix"></div>
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="filtermonthlycheck">
                        <h4 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#filtermonthlycheckChild"
                            aria-expanded="false" aria-controls="filtermonthlycheckChild">
                            <strong>Cart/Filter Monthly Checks</strong>
                          </a>
                        </h4>

                      </div>
                      <div id="filtermonthlycheckChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="filtermonthlycheck">
                        <div class="panel-body">
                          <div class="col-sm-12">
                            <div class="col-sm-12 box-row ">
                              <div class="col-sm-4">
                                <label class="col-sm-6 control-label">Max (psi):</label>
                                <div class="col-sm-6">
                                  <input class="smInput" name="Maxpsi" />
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <label class="col-sm-6 control-label">Min (psi):</label>
                                <div class="col-sm-6">
                                  <input class="smInput" name="Minpsi" />
                                </div>
                              </div>
                              <div class="col-sm-4">
                                <label class="col-sm-6 control-label">Final (psi):</label>
                                <div class="col-sm-6">
                                  <input class="smInput" name="Finalpsi" />
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="col-sm-12 box-row ">
                              <div class="col-sm-6">
                                <label class="col-sm-6 control-label">Verify System for Leaks:</label>
                                <div class="col-sm-6">
                                  <select class="form-control mdInput">
                                    <option [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'"
                                      value="{{item.value}}" *ngFor="let item of lstYesNo">
                                      {{item.text}}
                                    </option>
                                  </select>
                                </div>
                              </div>

                            </div>
                            <div class="clearfix"></div>
                            <div class="col-sm-12 box-row ">
                                <div class="col-sm-4">
                                  <label class="col-sm-6 control-label">Hardness (gpg):</label>
                                  <div class="col-sm-6">
                                    <input class="smInput" name="Maxpsi" />
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <label class="col-sm-6 control-label">Total Chlorine(ppm):</label>
                                  <div class="col-sm-6">
                                    <input class="smInput" name="Minpsi" />
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>
                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-4">
                                  <label class="col-sm-6 control-label">pH:</label>
                                  <div class="col-sm-6">
                                    <input class="smInput" name="Maxpsi" />
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <label class="col-sm-6 control-label">Iron (ug/L):</label>
                                  <div class="col-sm-6">
                                    <input class="smInput" name="Minpsi" />
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <label class="col-sm-6 control-label">Cycle Flood Stop:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'"
                                          value="{{item.value}}" *ngFor="let item of lstYesNo">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                              <div class="box-row col-sm-12  divider"><div class="col-sm-4"><strong>Filters Replaced:</strong></div></div>
                              <div class="clearfix"></div>
                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">50/5 Filters:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>
                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Carbon Filter:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstCarbon">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>

                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">10µ Filters:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>

                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Iron Filters:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>

                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Installed Labels:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>
                              <div class="box-row col-sm-12  divider">
                                  <div class="col-sm-8"><strong>Comprehensive Testing:  Frequency – Well (6 Mths), Municipal (Yearly) </strong></div></div>
                              <div class="clearfix"></div>
                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Feed:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstFeed">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>
                              <div class="col-sm-12 box-row ">
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">RO Product:</label>
                                  <div class="col-sm-6">
                                    <select class="form-control mdInput">
                                        <option value="{{item.value}}" *ngFor="let item of lstROProduct">
                                          {{item.text}}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <label class="col-sm-6 control-label">Date Last Replaced:</label>
                                  <div class="col-sm-6">
                                    <label class="readOnlyText">
                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                      </label>
                                  </div>
                                </div>

                              </div>
                              <div class="clearfix"></div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="row">

                        <div class="panel panel-default">
                          <div class="panel-heading" role="tab" id="romonthlycheck">
                            <h4 class="panel-title">
                              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#romonthlycheckChild"
                                aria-expanded="false" aria-controls="romonthlycheckChild">
                                <strong>RO Monthly Checks</strong>
                              </a>
                            </h4>

                          </div>
                          <div id="romonthlycheckChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="romonthlycheck">
                            <div class="panel-body">
                              <div class="col-sm-12">
                                <div class="col-sm-12 box-row ">
                                  <div class="col-sm-6">
                                    <label class="col-sm-6 control-label">RO ID:</label>
                                    <div class="col-sm-6">
                                            <select class="form-control smInput">
                                                    <option
                                                      value="" >
                                                      Select
                                                    </option>
                                                  </select>
                                    </div>
                                  </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row ">
                                  <div class="col-sm-6">
                                    <label class="col-sm-6 control-label">Feed Conductivity (µS):</label>
                                    <div class="col-sm-6">
                                            <input class="smInput" name="Maxpsi" />
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Product Conductivity (µS):</label>
                                        <div class="col-sm-6">
                                                <input class="smInput" name="Maxpsi" />
                                        </div>
                                      </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row ">
                                    <div class="col-sm-6">
                                      <label class="col-sm-6 control-label">Reject (%):</label>
                                      <div class="col-sm-6">
                                        <input class="smInput" name="Maxpsi" />
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <label class="col-sm-6 control-label">RO Hours:</label>
                                      <div class="col-sm-6">
                                        <input class="smInput" name="Minpsi" />
                                      </div>
                                    </div>

                                  </div>
                                  <div class="clearfix"></div>

                                  <div class="col-sm-12 box-row ">
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">Days Since Heat Clean:</label>
                                          <div class="col-sm-6">
                                            <input class="smInput" name="Maxpsi" />
                                          </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">Days Since Acid Clean:</label>
                                          <div class="col-sm-6">
                                            <input class="smInput" name="Minpsi" />
                                          </div>
                                        </div>

                                      </div>
                                      <div class="clearfix"></div>

                                  <div class="col-sm-12 box-row ">

                                    <div class="col-sm-6">
                                      <label class="col-sm-6 control-label">RO Air Filter:</label>
                                      <div class="col-sm-6">
                                            <select class="form-control mdInput">
                                                    <option value="{{item.value}}" *ngFor="let item of lstYesNo">
                                                      {{item.text}}
                                                    </option>
                                                  </select>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="clearfix"></div>

                                  <div class="col-sm-12 box-row ">
                                    <div class="col-sm-6">
                                      <label class="col-sm-6 control-label">HPC Test Taken</label>
                                      <div class="col-sm-6">
                                        <select class="form-control smInput">
                                            <option value="{{item.value}}"
                                            [style.color]="(item.value == 'Yes' || item.value == 'yes') ? 'red' : 'black'" value="{{item.value}}"
                                             *ngFor="let item of lstYesNo">
                                              {{item.text}}
                                            </option>
                                          </select>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <label class="col-sm-6 control-label">HPC Results:</label>
                                      <div class="col-sm-6">
                                            <select class="form-control smInput">
                                                    <option value="{{item.value}}"
                                                    [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                                                     *ngFor="let item of lstHPCResult">
                                                      {{item.text}}
                                                    </option>
                                                  </select>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="clearfix"></div>
                                  <div class="col-sm-12 box-row ">
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">LAL Test Taken:</label>
                                          <div class="col-sm-6">
                                            <select class="form-control smInput">
                                                <option value="{{item.value}}"

                                                 *ngFor="let item of lstYesNo">
                                                  {{item.text}}
                                                </option>
                                              </select>
                                          </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">LAL Results:</label>
                                          <div class="col-sm-6">
                                                <select class="form-control smInput">
                                                        <option value="{{item.value}}"
                                                         value="{{item.value}}"
                                                         *ngFor="let item of lalResult">
                                                          {{item.text}}
                                                        </option>
                                                      </select>
                                          </div>
                                        </div>

                                      </div>
                                      <div class="clearfix"></div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="row">

                            <div class="panel panel-default">
                              <div class="panel-heading" role="tab" id="dmmonthlycheck">
                                <h4 class="panel-title">
                                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmmonthlycheckChild"
                                    aria-expanded="false" aria-controls="dmmonthlycheckChild">
                                    <strong>Dialysis Machine Monthly Checks</strong>
                                  </a>
                                </h4>

                              </div>
                              <div id="dmmonthlycheckChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmmonthlycheck">
                                <div class="panel-body">
                                  <div class="col-sm-12">
                                    <div class="col-sm-12 box-row ">
                                      <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Machine Model:</label>
                                        <div class="col-sm-6">
                                                <select class="form-control smInput">
                                                        <option
                                                          value="" >
                                                          Select
                                                        </option>
                                                      </select>
                                        </div>
                                      </div>
                                      <div class="col-sm-6">
                                            <label class="col-sm-6 control-label">Machine ID:</label>
                                            <div class="col-sm-6">
                                                    <select class="form-control smInput">
                                                            <option
                                                              value="" >
                                                              Select
                                                            </option>
                                                          </select>
                                            </div>
                                          </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row ">
                                      <div class="col-sm-6">
                                        <label class="col-sm-6 control-label">Previous PM Completed:</label>
                                        <div class="col-sm-6">
                                                <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                      </label>
                                        </div>
                                      </div>

                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-sm-12 box-row ">
                                            <div class="col-sm-6">
                                              <label class="col-sm-6 control-label">DiaSafe:</label>
                                              <div class="col-sm-6">
                                                      <select class="form-control smInput">
                                                              <option
                                                                value="" >
                                                                Select
                                                              </option>
                                                            </select>
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                                  <label class="col-sm-6 control-label">Exterior Condition:</label>
                                                  <div class="col-sm-6">
                                                          <select class="form-control smInput">
                                                                  <option
                                                                    value="" >
                                                                    Select
                                                                  </option>
                                                                </select>
                                                  </div>
                                                </div>
                                          </div>
                                          <div class="clearfix"></div>

                                          <div class="col-sm-12 box-row ">
                                                <div class="col-sm-6">
                                                  <label class="col-sm-6 control-label">Wands Checked:</label>
                                                  <div class="col-sm-6">
                                                          <select class="form-control smInput">
                                                                  <option
                                                                    value="" >
                                                                    Select
                                                                  </option>
                                                                </select>
                                                  </div>
                                                </div>
                                                <div class="col-sm-6">
                                                      <label class="col-sm-6 control-label">Check for Leaks:</label>
                                                      <div class="col-sm-6">
                                                              <select class="form-control smInput">
                                                                      <option
                                                                        value="" >
                                                                        Select
                                                                      </option>
                                                                    </select>
                                                      </div>
                                                    </div>
                                              </div>
                                              <div class="clearfix"></div>

                                              <div class="col-sm-12 box-row ">
                                                    <div class="col-sm-6">
                                                      <label class="col-sm-6 control-label">Verify Bleach Level:</label>
                                                      <div class="col-sm-6">
                                                              <select class="form-control smInput">
                                                                      <option
                                                                        value="" >
                                                                        Select
                                                                      </option>
                                                                    </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                          <label class="col-sm-6 control-label">Clean Air Filter:</label>
                                                          <div class="col-sm-6">
                                                                  <select class="form-control smInput">
                                                                          <option
                                                                            value="" >
                                                                            Select
                                                                          </option>
                                                                        </select>
                                                          </div>
                                                        </div>
                                                  </div>
                                                  <div class="clearfix"></div>

                                      <div class="col-sm-12 box-row ">
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">HPC Test Taken</label>
                                          <div class="col-sm-6">
                                            <select class="form-control mdInput">
                                                <option value="{{item.value}}"
                                                [style.color]="(item.value == 'Yes' || item.value == 'yes') ? 'red' : 'black'" value="{{item.value}}"
                                                 *ngFor="let item of lstYesNo">
                                                  {{item.text}}
                                                </option>
                                              </select>
                                          </div>
                                        </div>
                                        <div class="col-sm-6">
                                          <label class="col-sm-6 control-label">HPC Results:</label>
                                          <div class="col-sm-6">
                                                <select class="form-control smInput">
                                                        <option value="{{item.value}}"
                                                        [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                                                         *ngFor="let item of lstHPCResult">
                                                          {{item.text}}
                                                        </option>
                                                      </select>
                                          </div>
                                        </div>

                                      </div>
                                      <div class="clearfix"></div>
                                      <div class="col-sm-12 box-row ">
                                            <div class="col-sm-6">
                                              <label class="col-sm-6 control-label">LAL Test Taken:</label>
                                              <div class="col-sm-6">
                                                <select class="form-control mdInput">
                                                    <option value="{{item.value}}"

                                                     *ngFor="let item of lstYesNo">
                                                      {{item.text}}
                                                    </option>
                                                  </select>
                                              </div>
                                            </div>
                                            <div class="col-sm-6">
                                              <label class="col-sm-6 control-label">LAL Results:</label>
                                              <div class="col-sm-6">
                                                    <select class="form-control smInput">
                                                            <option value="{{item.value}}"
                                                            [style.color]="(item.value == '>20' || item.value == '> 20') ? 'red' : 'black'" value="{{item.value}}"
                                                             *ngFor="let item of lstHPCResult">
                                                              {{item.text}}
                                                            </option>
                                                          </select>
                                              </div>
                                            </div>

                                          </div>
                                          <div class="clearfix"></div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="row">

                                <div class="panel panel-default">
                                  <div class="panel-heading" role="tab" id="yearlycheck">
                                    <h4 class="panel-title">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#yearlycheckChild"
                                        aria-expanded="false" aria-controls="yearlycheckChild">
                                        <strong>Yearly Checks: </strong>
                                      </a>
                                    </h4>

                                  </div>
                                  <div id="yearlycheckChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="yearlycheck">
                                    <div class="panel-body">
                                      <div class="col-sm-12">
                                        <div class="col-sm-12 box-row ">
                                          <div class="col-sm-6">
                                            <label class="col-sm-6 control-label">RO GFI Plug Tested:</label>
                                            <div class="col-sm-6">
                                                    <select class="form-control smInput">
                                                            <option value="{{item.value}}"
                                                             value="{{item.value}}"
                                                             [style.color]="(item.value == 'Fail' || item.value == 'fail') ? 'red' : 'black'" value="{{item.value}}"
                                                             *ngFor="let item of lalResult">
                                                              {{item.text}}
                                                            </option>
                                                          </select>
                                            </div>
                                          </div>
                                          <div class="col-sm-6">
                                                <label class="col-sm-6 control-label">Date Last Tested: </label>
                                                <div class="col-sm-6">
                                                        <label class="readOnlyText">
                                                                {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                              </label>
                                                </div>
                                              </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row ">
                                                <div class="col-sm-6">
                                                  <label class="col-sm-6 control-label">D. Machine GFI Plug Tested:</label>
                                                  <div class="col-sm-6">
                                                          <select class="form-control smInput">
                                                                  <option value="{{item.value}}"
                                                                   value="{{item.value}}"
                                                                   [style.color]="(item.value == 'Fail' || item.value == 'fail') ? 'red' : 'black'" value="{{item.value}}"
                                                                   *ngFor="let item of lalResult">
                                                                    {{item.text}}
                                                                  </option>
                                                                </select>
                                                  </div>
                                                </div>
                                                <div class="col-sm-6">
                                                      <label class="col-sm-6 control-label">Date Last Tested: </label>
                                                      <div class="col-sm-6">
                                                              <label class="readOnlyText">
                                                                      {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                                    </label>
                                                      </div>
                                                    </div>
                                              </div>
                                              <div class="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="clearfix"></div>

                              <div class="row">

                                    <div class="panel panel-default">
                                      <div class="panel-heading" role="tab" id="equipmentexchange">
                                        <h4 class="panel-title">
                                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#equipmentexchangeChild"
                                            aria-expanded="false" aria-controls="equipmentexchangeChild">
                                            <strong>Equipment Exchange: </strong>
                                          </a>
                                        </h4>

                                      </div>
                                      <div id="equipmentexchangeChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="equipmentexchange">
                                        <div class="panel-body">
                                          <div class="col-sm-12">
                                            <div class="col-sm-12 box-row ">
                                              <div class="col-sm-6">
                                                <label class="col-sm-6 control-label">Equipment Exchange Type:</label>
                                                <div class="col-sm-6">
                                                        <select class="form-control mdInput">
                                                                <option value="{{item.value}}"
                                                                 value="{{item.value}}"
                                                                 *ngFor="let item of EquipmentExchangeTypeResult">
                                                                  {{item.text}}
                                                                </option>
                                                              </select>
                                                </div>
                                              </div>

                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="col-sm-12 box-row ">
                                                    <div class="col-sm-6">
                                                      <label class="col-sm-6 control-label">New Equipment IN:</label>
                                                      <div class="col-sm-6">
                                                              <select class="form-control mdInput">
                                                                      <option value=""
                                                                       >
                                                                        Select
                                                                      </option>
                                                                    </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                          <label class="col-sm-6 control-label">Equipment Hours IN: </label>
                                                          <div class="col-sm-6">
                                                                <input class="smInput" name="Minpsi" />
                                                          </div>
                                                        </div>
                                                  </div>
                                                  <div class="clearfix"></div>
                                                  <div class="col-sm-12 box-row ">
                                                        <div class="col-sm-6">
                                                          <label class="col-sm-6 control-label">Old Equipment OUT:</label>
                                                          <div class="col-sm-6">
                                                                  <select class="form-control mdInput">
                                                                          <option value=""
                                                                           >
                                                                            Select
                                                                          </option>
                                                                        </select>
                                                          </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                              <label class="col-sm-6 control-label">Equipment Hours OUT: </label>
                                                              <div class="col-sm-6">
                                                                    <input class="smInput" name="Minpsi" />
                                                              </div>
                                                            </div>
                                                      </div>
                                                      <div class="clearfix"></div>

                                                      <div class="col-sm-12 box-row ">
                                                            <div class="col-sm-6">
                                                              <label class="col-sm-6 control-label">Verify Patient Service Settings:</label>
                                                              <div class="col-sm-6">
                                                                      <select class="form-control mdInput">
                                                                              <option value="{{item.value}}"
                                                                              [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'"
                                                                               *ngFor="let item of lstYesNo">
                                                                                {{item.text}}
                                                                              </option>
                                                                            </select>
                                                              </div>
                                                            </div>

                                                          </div>
                                                          <div class="clearfix"></div>

                                                          <div class="col-sm-12 box-row ">
                                                                <div class="col-sm-6">
                                                                  <label class="col-sm-6 control-label">Verify Acid Formulations:</label>
                                                                  <div class="col-sm-6">
                                                                          <select class="form-control mdInput">
                                                                                  <option value="{{item.value}}"
                                                                                   value="{{item.value}}"
                                                                                   [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'"
                                                                                   *ngFor="let item of lstYesNo">
                                                                                    {{item.text}}
                                                                                  </option>
                                                                                </select>
                                                                  </div>
                                                                </div>

                                                              </div>
                                                              <div class="clearfix"></div>


                                                              <div class="col-sm-12 box-row ">
                                                                    <div class="col-sm-6">
                                                                      <label class="col-sm-6 control-label">Verify Functioning:</label>
                                                                      <div class="col-sm-6">
                                                                              <select class="form-control mdInput">
                                                                                      <option [style.color]="(item.value == 'Fail' || item.value == 'fail') ? 'red' : 'black'"
                                                                                       value="{{item.value}}"
                                                                                       *ngFor="let item of lalResult">
                                                                                        {{item.text}}
                                                                                      </option>
                                                                                    </select>
                                                                      </div>
                                                                    </div>

                                                                  </div>
                                                                  <div class="clearfix"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="clearfix"></div>
                                  <div class="row">

                                        <div class="panel panel-default">
                                          <div class="panel-heading" role="tab" id="dmnote">
                                            <h4 class="panel-title">
                                              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#dmnoteChild"
                                                aria-expanded="false" aria-controls="dmnoteChild">
                                                <strong>NOTE Section: </strong>
                                              </a>
                                            </h4>

                                          </div>
                                          <div id="dmnoteChild" class="panel-collapse collapse" role="tabpanel" aria-labelledby="dmnote">
                                            <div class="panel-body">
                                                    <notes [isValidForm]="f.invalid" (addNoteClick)="executeFieldServiceCreateUpdate('Notes')" #notes>
                                                        </notes>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="clearfix"></div>

                                      <div class="col-sm-12 box-row">

                                            <div class="col-sm-6">
                                              <label class="col-sm-4 control-label" style="text-align:left">Pt Hm Departure Time:</label>
                                              <div class="col-sm-8">
                                                <input class="smInput" name="StartTime" /> <label>&nbsp;(24Hour Clock)</label>



                                              </div>
                                            </div>


                                          </div>
                                          <div class="clearfix"></div>
                </div>

              </div>

              <div class="box ibox box-info">

                <div class="box-body">
                  <div class="row">
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Total Time at Pt Hm:
                        </label>

                        <div class="col-sm-7">
                          <input readonly class="smInput" name="StartTime" /> <label>&nbsp;(24Hour Clock)</label>
                        </div>
                      </div>


                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          GR Return Time:
                        </label>

                        <div class="col-sm-7">
                          <input  class="smInput" name="StartTime" /> <label>&nbsp;(24Hour Clock)</label>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Total Tech(s) Time:
                        </label>

                        <div class="col-sm-7">
                          <input readonly class="smInput" name="StartTime" /> <label>&nbsp;(24Hour Clock)</label>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Fuel Added During Trip:
                        </label>

                        <div class="col-sm-7">
                          <select class="form-control mdInput">
                            <option value="{{item.value}}"
                             value="{{item.value}}"
                             [style.color]="(item.value == 'No' || item.value == 'no') ? 'red' : 'black'"
                             *ngFor="let item of lstYesNo">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>


                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Fuel Company:
                        </label>

                        <div class="col-sm-7">
                          <select class="form-control mdInput">
                            <option
                             value="{{item.value}}"

                             *ngFor="let item of lstFuelCompany">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Fuel Amount:
                        </label>

                        <div class="col-sm-7">
                          <input readonly class="mdInput" name="StartTime" />
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-12 box-row">
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Payment By:
                        </label>

                        <div class="col-sm-7">
                          <select class="form-control mdInput">
                            <option
                             value="{{item.value}}"

                             *ngFor="let item of lstPaymentBy">
                              {{item.text}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-sm-5 control-label">

                          Add Receipt:
                        </label>

                        <div class="col-sm-7">
                          <singleattachment (RaiseError)="AttachmentError($event)"  #paymentattachment>

                          </singleattachment>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>

                </div>

              </div>

            </div>

            <attachment (RaiseError)="AttachmentError($event)" #attachment>

            </attachment>
          </div>
        </div>
        <PageHeader [showNote]="true" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false"
          [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeFieldServiceCreateUpdate('')"
          [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
      </div>
    </div>

  </section>
</form>

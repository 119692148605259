import { HomeFieldService } from './../../../API/homefield.service';
import { AuthService } from 'src/app/API/auth.service';
import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Globals } from 'src/app/globals';
import { HFSNoteDialog } from 'src/app/Core/HFSNotesModel/HFSNoteDialog';
import { CommonCustomValidators } from 'src/app/Validator/CommonValidator';
import { HFSVehicleHistoryDialog } from './../../Section/HFSVehicleHistoryDialog/HFSVehicleHistoryDialog.component';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';

@Component({
  selector: 'SectionVehicledetailDialog',
  templateUrl: './section-vehicledetail.component.html',
  styleUrls: ['./section-vehicledetail.component.css']
})

export class SectionVehicledetailDialog implements AfterViewInit {

  matDialogRef: MatDialogRef<ConfirmationDialog>;

  @Output() SaveEvent = new EventEmitter<any>();
  isValidFormSubmitted = false;
  errormsg = "";
  PrevFailureNotes = [];
  quickNoteDialogRef: MatDialogRef<HFSNoteDialog>;
  selectedVehiclelastKms = 0;
  notesModule = "Vehicle Detail";


  vehicleSectionForm = new UntypedFormGroup({
    HFServiceVehicleDetailId: new UntypedFormControl('0'),
    HFServiceVehicleId: new UntypedFormControl('', Validators.required),
    HFServiceVehicleName: new UntypedFormControl(''),
    HFServiceReturnVehicleEndTime: new UntypedFormControl(''),
    HFServiceVehicleStartedKM: new UntypedFormControl('', Validators.required),
    HFServiceVehicleStartTime: new UntypedFormControl('', Validators.required),
    // HFServiceVehicleInspectionStatus: new FormControl('', Validators.required),
    HFServiceVehicleInspectionStatusSection: new UntypedFormControl('', Validators.required),
    HFServiceVehicleInspectionNotes: new UntypedFormControl(''),
    CreatedOn: new UntypedFormControl(''),
    CreatedBy: new UntypedFormControl(''),
    HFServiceId: new UntypedFormControl(''),
    HFServiceVehicleEndedKM: new UntypedFormControl(''),
    HFServiceVehicleEndTime: new UntypedFormControl(''),
  });
  constructor(
    private auth: AuthService,
    private homefieldApi: HomeFieldService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SectionVehicledetailDialog>
  ) {
    //dialogRef.updateSize('700px', 'auto');
  }

  notes = '';
  today = new Date();
  UserName = this.auth.GetUserName();
  UserId = this.auth.GetUserId();

  VehicleList = [];
  HFServiceVehicleInspectionStatus = '';


  ngOnInit() {

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);
      //Validations
      this.vf.HFServiceVehicleId.setValidators([Validators.required]);

      this.vf.HFServiceVehicleId.setAsyncValidators([
        CommonCustomValidators.GetHFS_GREquipmentVehicleIsAvailable(
          this.vf.HFServiceId,
          this.homefieldApi
        )]
      );

      this.vf.HFServiceVehicleStartedKM.setValidators([
        Validators.required,
        Validators.pattern(Globals.NumberNumericRegexWithoutZero)
      ]);
      this.vf.HFServiceVehicleStartedKM.setAsyncValidators([
        CommonCustomValidators.GetHFS_IsKmsIsGreaterThanPreviousKms(
          this.vf.HFServiceVehicleId,
          this.vf.HFServiceId,
          this.homefieldApi
        )]
      );


      this.vf.HFServiceVehicleStartTime.setValidators([
        CommonCustomValidators.CheckDateAndTimeGreaterValidation(
          this.vf.HFServiceReturnVehicleEndTime, this.vf.HFServiceVehicleStartTime
        ), Validators.required
      ]);
      //Validations
    }, error => {
      AppComponent.HideLoader();
    });
  }

  //   getCurrentTime(){
  //     return  new Date().getHours() + ":" + new Date().getMinutes();
  // }

  getCurrentTime() {
    return this.today;
  }


  getDisplayTime(val){
    if(val)
      return new Date(val);
    else
      return this.today;
  }

  ngAfterViewInit() {
    // this.HFServiceVehicleId = '';
  }


  VehicleDropDownChanged() {
    //
    if (this.vf.HFServiceVehicleId.value !== '0' && this.vf.HFServiceVehicleId.value !== '') {

      var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.vf.HFServiceVehicleId.value);

      if (selectedVehicles.length > 0)
        this.vf.HFServiceVehicleName.setValue(selectedVehicles[0].equipmentcode);

      AppComponent.ShowLoader();
      this.vf.HFServiceVehicleStartedKM.setValue('');

      var equipmentId = { Id: this.vf.HFServiceVehicleId.value };
      this.homefieldApi.GetHFSMachaineHistoryMaxKms(equipmentId).subscribe(result => {
        if (result) {
          this.selectedVehiclelastKms = result;
        } else {
          this.selectedVehiclelastKms = 0;
        }
        AppComponent.HideLoader();
      },
        error => {
          this.selectedVehiclelastKms = 0;
        });

      //fetch Notes for Selected Vehicle
      const hfsVehicleEquipmentId = { VehicleEquipmentId: this.vf.HFServiceVehicleId.value };
      this.homefieldApi.GetHomeFieldService_MasterNotes(hfsVehicleEquipmentId).subscribe(list => {
        this.PrevFailureNotes = list.filter((item, index) => item.moduleName == 'Vehicle Detail (Failure)');
      });
    }
  }

  prepareCreateViewModel(Source = "") {
    const modelCreate = <any>{};


    modelCreate.HFServiceVehicleDetailId = this.vf.HFServiceVehicleDetailId.value;
    modelCreate.HFServiceVehicleId = this.vf.HFServiceVehicleId.value;

    var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.vf.HFServiceVehicleId.value);

    if (selectedVehicles.length > 0)
      modelCreate.HFServiceVehicleName = selectedVehicles[0].equipmentcode;


    modelCreate.HFServiceVehicleStartedKM = this.vf.HFServiceVehicleStartedKM.value;
    modelCreate.HFServiceVehicleStartTime = Globals.GetAPIDateWithoutSeconds(this.vf.HFServiceVehicleStartTime.value);
    modelCreate.HFServiceVehicleInspectionStatus = this.vf.HFServiceVehicleInspectionStatusSection.value;
    modelCreate.HFServiceVehicleInspectionNotes = this.vf.HFServiceVehicleInspectionNotes.value;
    modelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.CreatedBy = this.UserId;
    modelCreate.HFServiceId = this.vf.HFServiceId.value;
    modelCreate.notesModule = this.notesModule;

    return modelCreate;
  }


  saveAndClose() {
    if (this.vehicleSectionForm.dirty) {
      if (this.vehicleSectionForm.invalid) {
        this.matDialogRef = this.dialog.open(ConfirmationDialog, {
          disableClose: true
        });
        this.matDialogRef.componentInstance.confirmMessage =
          'You have unsaved changes! If you leave, your changes will be lost.';
        this.matDialogRef.afterClosed().subscribe(result => {

            if(result)
              this.dialogRef.close(false);
            else
              this.save();
          });
      }
      else{
          this.save();
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  IsOpenInspectionStatus = false;
  save() {
    AppComponent.loading = true;
    this.isValidFormSubmitted = true;
    if (this.vehicleSectionForm.invalid) {

      if (!this.vf.HFServiceVehicleInspectionStatusSection.valid) {
        this.IsOpenInspectionStatus = true;
      }
      else {
        this.IsOpenInspectionStatus = false;
      }

      // AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      // this.errormsg = Globals.FormErrorMessage;
      AppComponent.loading = false;
      return;
    }

    const prepCreate = this.prepareCreateViewModel();
    this.homefieldApi.CreateHomeFieldService_Section_VehicleDetails(prepCreate).subscribe(
      success => {
        AppComponent.loading = false;
        this.SaveEvent.emit(success);
        this.dialogRef.close(prepCreate);
      },
      error => {
        if (error.error && error.error.returnMessage) {
          this.errormsg = error.error.returnMessage.toString();
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          this.errormsg = error.message;
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  get vf() {
    return this.vehicleSectionForm.controls;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }



  SectionSendVehicleNotesMailToSupervisor() {

    if (this.vf.HFServiceVehicleId.value) {
      this.quickNoteDialogRef = this.dialog.open(HFSNoteDialog, {
        disableClose: true,
        position: { right: '0', top: '0' },
        height: '100%',
        width: '35%',
        maxWidth: '120vh',
        maxHeight: '100vh',
        minWidth: '50vh',
      });

      this.quickNoteDialogRef.componentInstance.showHours = false;
      this.quickNoteDialogRef.componentInstance.showParts = false;

      this.quickNoteDialogRef.componentInstance.PrevNotes = this.PrevFailureNotes;

      var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.vf.HFServiceVehicleId.value);
      if (selectedVehicles.length > 0)
        this.quickNoteDialogRef.componentInstance.VehicleName = selectedVehicles[0].equipmentcode;

      this.quickNoteDialogRef.componentInstance.SaveEvent.subscribe(evt => {

        this.vf.HFServiceVehicleInspectionNotes.setValue(evt);
        this.notesModule = "Vehicle Detail (Failure)";
        this.save();
      });

      this.quickNoteDialogRef.afterClosed().subscribe(result => {
        if (!result) {
          this.vf.HFServiceVehicleInspectionStatusSection.setValue(null);
        }
        this.quickNoteDialogRef = null;
      });

    }
    else {
      AppComponent.addAlert("Select Vehicle First", 'error');
      this.errormsg = "Select Vehicle First";
      setTimeout(()=>{
        this.vf.HFServiceVehicleInspectionStatusSection.setValue(null);
      },500);
    }
  }

  hfsVehicleHistoryDialog: MatDialogRef<HFSVehicleHistoryDialog>;
  OpenVehicleHistoryList() {
    AppComponent.ShowLoader();
    this.hfsVehicleHistoryDialog = this.dialog.open(HFSVehicleHistoryDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '60%',
      maxHeight: '100vh',
      data: this.vf.HFServiceVehicleId.value
    });

    this.hfsVehicleHistoryDialog.afterClosed().subscribe(result => {
    });

    AppComponent.HideLoader();
  }

  getToday() {
    let tod = new Date(this.today);
    tod.setHours(0, 0, 0, 0);
    return tod;
  }
}

import { Component, ViewChild } from '@angular/core';
import { TasksApiService } from '../API/tasksapi.service';
import { TableHeadsService } from '../Core/tableheads.service';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../Core/pageheader.component';
import { AppComponent } from '../app.component';


@Component({
  selector: 'TaskTypeSearch',
  templateUrl: './TaskTypeSearch.component.html',

})
export class TaskTypeSearchComponent {

  constructor( private tasksapi: TasksApiService , private router: Router, private dataGridService: TableHeadsService){
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading = true;
  tableHeaders;
  page = new Page();
  rows ;
  AddUrl = '/Masters/TaskTypeDetail';
  currentPageNumber = 1;
  sortExpression = 'taskTypeName';
  sortDirection = 'ASC';
  pageSize = 25;
  title = 'Task Type';
  defaultSort;


  ngOnInit() {
    // AppComponent.loading=true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader('#', 'id', '4');
    this.dataGridService.addHeader('Task Type Name', 'taskTypeName', '25');
    this.dataGridService.addHeader('Prefix', 'prefix', '8');
    this.dataGridService.addHeader('Border Color', 'color', '27');
    this.dataGridService.addHeader('Back Color', 'color', '27');
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort('taskTypeName', false);

  }



  changeSorting(column) {
    if (column === '#') {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeTaskTypeSearch();
  }

  setSortIndicator = function (column) {
      return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeTaskTypeSearch() {
    AppComponent.ShowLoader();
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = 'Search Records';



    const contanctsearch = this.prepareSearch();
    this.tasksapi.GetTaskTypeList(contanctsearch).subscribe(pagedData  => {
        this.page.totalElements = pagedData.totalRows;
        this.page.totalPages = pagedData.totalPages;
        this.rows = pagedData.taskTypeDetails;
         console.log(pagedData);
        AppComponent.HideLoader();
      }
    ,
    error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
      }
    );
  }

prepareSearch() {
  // tslint:disable-next-line:prefer-const
  let TaskTypesearch = <any>{};
  TaskTypesearch.currentPageNumber = this.currentPageNumber;
  TaskTypesearch.sortExpression = this.sortExpression;
  TaskTypesearch.sortDirection = this.sortDirection;
  TaskTypesearch.PageSize = this.pageSize;
   // console.log(TaskTypesearch);
  return TaskTypesearch;
}

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeTaskTypeSearch();
  }
   _onRowClick(row) {
    this.router.navigate([this.AddUrl], { queryParams: { id: row } });
  }
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

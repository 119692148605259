import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Globals} from '../globals';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'my-auth-token'
    })
  };

@Injectable()
export class OrganizationalUnitApiService {
    apiUrl = Globals.apiUrl;
    constructor(private http: HttpClient) {}

    GetOrgUnitDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'OrganizationalUnitService/GetOrganizationalUnitByID',
        value,
        httpOptions
      );
    }

    CreateOrgUnitDetails(value) {
     //
      return this.http.post<any>(
        this.apiUrl + 'OrganizationalUnitService/CreateOrganizationalUnitDetails',
        value,
        httpOptions
      );
    }

    UpdateOrgUnitDetails(value) {
      return this.http.post<any>(
        this.apiUrl + 'OrganizationalUnitService/UpdateOrganizationalUnitDetails',
        value,
        httpOptions
      );
    }


    FillCountry() {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillCountry',
        '',
        httpOptions
      );
    }

    FillTimeZones() {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/GetTimeZones',
        '',
        httpOptions
      );
    }

    FillState(value) {
      return this.http.post<any>(
        this.apiUrl + 'CommonService/FillState',
        value,
        httpOptions
      );
    }
  }

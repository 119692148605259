<style>
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #3a4dab !important;
}
</style>
<form [formGroup]="BacteriaForm" (ngSubmit)="executeBacteriaCreateUpdate('')" novalidate *ngIf="FullScreen">


    <section class="content-header">
        <h1>
            Bacteria Form Details
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">

            <div class="box  box-primary">

                <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                    [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                    (SaveEvent)="executeBacteriaCreateUpdate('')" [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">BacteriasId</label>

                                <div class="col-sm-10">
                                    <input type="text" readonly class="form-control" name="BacteriasId"
                                        [value]="f.BacteriasId" formControlName="BacteriasId">
                                </div>
                            </div>


                            <div class="box ibox box-info">

                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">

                                                    Event ID:
                                                </label>

                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">{{EventCode}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Date:</label>
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                    </label>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Created By:</label>
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">{{CollectionByName}}</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="box ibox box-info hidden">


                                <div class="box-header with-border">
                                    <h3 class="box-title">Bacteria Form Details</h3>

                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Form Date:</label>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value > 0">
                                                    <label
                                                        class="readOnlyText">{{f.CollectionDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0">
                                                    <input (focus)="rCollectionDate.toggleCalendar()"
                                                        (click)="rCollectionDate.openCalendar()"
                                                        placeholder="Select a date" class="form-control mdInput"
                                                        style="float:none" angular-mydatepicker name="CollectionDate"
                                                        formControlName="CollectionDate" [options]="myOptions"
                                                        #rCollectionDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />
                                                    <span
                                                        *ngIf="f.CollectionDate.errors?.required && (f.CollectionDate.dirty || f.CollectionDate.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-8 control-label">Form Time:</label>
                                                <div class="col-sm-4" *ngIf="f.BacteriasId.value > 0">
                                                    <label class="readOnlyText">{{f.CollectionTime.value}}</label>
                                                </div>
                                                <div class="col-sm-4" *ngIf="f.BacteriasId.value == 0">
                                                    <input class="form-control mdInput" name="CollectionTime"
                                                        formControlName="CollectionTime" />
                                                    <span
                                                        *ngIf="f.CollectionTime.errors?.required && (f.CollectionTime.dirty || f.CollectionTime.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.CollectionTime.errors?.pattern"
                                                        [ngClass]="'error'">Invalid time</span>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Division Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Division:</label>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value > 0">
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0">
                                                    <select class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits"
                                                        (change)="DialysisUnitDropDownChanged()">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnitsWithoutHome">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Lab Facility:</label>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value > 0">
                                                    <label class="readOnlyText">{{f.TestingLab.value}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0">
                                                    <select class="form-control mdInput" formControlName="TestingLab"
                                                        name="TestingLab">
                                                        <option value="{{item.value}}" *ngFor="let item of TestingLabs">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.TestingLab.errors?.required && (f.TestingLab.dirty || f.TestingLab.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4"></div>
                                        </div>
                                        <div class="clearfix"></div>

                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Collection Detail</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Date:</label>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value > 0">
                                                    <label
                                                        class="readOnlyText">{{f.SampleReadDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0">
                                                    <input (focus)="rSampleReadDate.toggleCalendar()"
                                                        (click)="rSampleReadDate.openCalendar()"
                                                        placeholder="Select a date" class="form-control mdInput"
                                                        style="float:none" angular-mydatepicker name="SampleReadDate"
                                                        formControlName="SampleReadDate" [options]="myOptions"
                                                        #rSampleReadDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />
                                                    <span
                                                        *ngIf="f.SampleReadDate.errors?.required && (f.SampleReadDate.dirty || f.SampleReadDate.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection Time:</label>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value > 0">
                                                    <label class="readOnlyText">{{f.SampleReadTime.value}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0">
                                                    <input class="form-control mdInput" name="SampleReadTime"
                                                        formControlName="SampleReadTime" />
                                                    <span
                                                        *ngIf="f.SampleReadTime.errors?.required && (f.SampleReadTime.dirty || f.SampleReadTime.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.SampleReadTime.errors?.pattern"
                                                        [ngClass]="'error'">Invalid time</span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Collection User:</label>
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText"
                                                        *ngIf="f.BacteriasId.value > 0">{{SampleReadByName}}</label>
                                                    <select *ngIf="f.BacteriasId.value == 0"
                                                        class="form-control mdInput" formControlName="SampleReadById"
                                                        name="SampleReadById">
                                                        <option value="{{item.value}}" *ngFor="let item of Users">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <span
                                                        *ngIf="f.SampleReadById.errors?.required && (f.SampleReadById.dirty || f.SampleReadById.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Tested Devices</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">



                                        <div class="col-sm-12 box-row" *ngIf="stage == 0">
                                            <div class="col-sm-12">
                                                <label class="col-sm-2 control-label">Populate:</label>


                                                <div class="col-sm-10">

                                                    <select class="form-control mdInput" style="margin-left: 15px"
                                                        formControlName="selectedPopulatetype"
                                                        name="selectedPopulatetype"
                                                        (change)="selectedPopulatetypeChanged()">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of Populatetypes">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <select style="margin-left: 15px;max-width: 15%;" class="form-control mdInput"
                                                        *ngIf="f.selectedPopulatetype.value == 'Dialysis Machines'"
                                                        formControlName="selectedDMEquipment" name="selectedDMEquipment"
                                                        (change)="onEquipmentChanged($event)">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of DMEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <select style="margin-left: 15px" class="form-control mdInput"
                                                        *ngIf="f.selectedPopulatetype.value == 'Water Process'"
                                                        name="selectedWPEquipment"
                                                        (change)="onEquipmentChanged($event)"
                                                        formControlName="selectedWPEquipment">
                                                        <option value="{{item.value}}"
                                                            *ngFor="let item of WPEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <select style="margin-left: 15px" class="form-control mdInput"
                                                        name="selectedCDEquipment" formControlName="selectedCDEquipment"
                                                        (change)="onEquipmentChanged($event)"
                                                        *ngIf="f.selectedPopulatetype.value == 'Central Delivery'">
                                                        <option value="{{item.value}}" *ngFor="let item of CDEquipments">
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <input style="margin-left: 15px"
                                                    *ngIf="(f.selectedPopulatetype.value == 'Other')"
                                                    class="form-control mdInput" placeholder="Equipment Name"
                                                    name="OtherEquipment" formControlName="OtherEquipment" maxlength="20"/>


                                                    <input style="margin-left: 15px"
                                                        *ngIf="(f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process') && hrsTracking"
                                                        class="form-control smInput" placeholder="Hours"
                                                        name="TestHours" formControlName="TestHours" maxlength="5"
                                                        (keypress)="numberOnly($event)" />



                                                    <label class="readOnlyTextForHours"
                                                        *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null && (f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process' || f.selectedPopulatetype.value == 'Central Delivery') && hrsTracking">({{selectedEquipmentHours}})</label>
                                                    <label class="readOnlyTextForHours"
                                                        *ngIf="selectedEquipmentHours==0 && (f.selectedPopulatetype.value == 'Dialysis Machines' || f.selectedPopulatetype.value == 'Water Process' || f.selectedPopulatetype.value == 'Central Delivery') && (f.selectedDMEquipment.value || f.selectedWPEquipment.value || f.selectedCDEquipment.value) && hrsTracking">(N/A)</label>

                                                    <button style="display: inline-block;margin-left: 15px"
                                                        (click)="AddTest()"
                                                        class="btn btn-primary  no-print btn-label-left center-block "
                                                        type="button">
                                                        <span><i class="fa fa-plus"></i></span>
                                                    </button>
                                                    <span style="margin-left: 20px" [ngClass]="'error'">
                                                        {{errorAddTest}}
                                                    </span>

                                                    <span *ngIf="check4000HoursValidation() && hrsTracking" [ngClass]="'error'">
                                                        New Hours >= 4000 from previous Hours Out.
                                                    </span>
                                                    <!-- <span *ngIf="DMRepairStatus" [ngClass]="'error'">
                                                        You cannot add device, while there is an open Repair still existing in the system.
                                                    </span>
                                                    <span *ngIf="RepairStatus" [ngClass]="'error'">
                                                        You cannot add device, while there is an open Repair still existing in the system.
                                                    </span> -->
                                                </div>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div *ngIf="DMTestList.length > 0 || WPTestList.length > 0 || CDTestList.length > 0 || OTHTestList.length > 0"
                                            class="box-row col-sm-12">
                                            <div class="col-sm-6">

                                                <label class="col-sm-5"> </label>
                                                <!-- <label class="col-sm-6" [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20') ? 'red' : 'black'"
                                                         *ngIf="f.PyrogenDetailsId.value == 0 || status=='Completed'"> {{equip.sampleResult}}</label> -->
                                                <label class="col-sm-4 text-center"> Result</label>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div *ngIf="DMTestList.length > 0" class="box-row col-sm-12 divider">
                                            <div class="col-sm-2">
                                                <strong>Dialysis Machine</strong>
                                            </div>
                                            <label style="color: red;padding-left:30px;" *ngIf="funnelInfoDisplay == false && f.TestingLab.value == 'Internal (Tech)' && f.BacteriasId.value > 0">
                                                Please enter funnel info before select result value.</label>
                                        </div>
                                        <div *ngIf="DMTestList.length > 0" class="clearfix"></div>
                                        <div *ngFor="let equip of DMTestList;" class="box-row col-sm-12">
                                            <div class="col-sm-12">

                                                <label class="col-sm-3"> {{equip.equipmentName}} <span *ngIf="equip.dialysisMachineHours" >[{{equip.dialysisMachineHours}}]</span></label>
                                                <label class="col-sm-9"
                                                    [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'"
                                                    *ngIf="f.BacteriasId.value == 0 || status=='Completed'">
                                                    {{equip.sampleResult}}</label>

                                                <div class="col-sm-9"
                                                    *ngIf="f.BacteriasId.value > 0 && status=='In Progress'">
                                                    <select class="form-control smInput"
                                                        (change)="UpdateSampleValue($event)"
                                                        [style.color]="GetColor('testresult_dm_' + equip.bacteriaMachaineId)"
                                                        name="{{'testresult_dm_' + equip.bacteriaMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == 'TNTC' || item.value == '> 20' || item.value == '> 50' || item.value == '>20 <49') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList" [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <label
                                                        [hidden]="(GetColor('testresult_dm_' + equip.bacteriaMachaineId) !=='red')"
                                                        id="{{'lbltestresult_dm_' + equip.bacteriaMachaineId}}"
                                                        style="color:red;padding-left:10px">Result is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>


                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider" *ngIf="WPTestList.length > 0">
                                            <div class="col-sm-2">
                                                <strong>Water Process</strong>
                                            </div>
                                            <label style="color: red;padding-left:30px;" *ngIf="funnelInfoDisplay == false && f.TestingLab.value == 'Internal (Tech)' && f.BacteriasId.value > 0">
                                                Please enter funnel info before select result value.</label>
                                        </div>

                                        <div class="clearfix" *ngIf="WPTestList.length > 0"></div>
                                        <div *ngFor="let equip of WPTestList;" class="box-row col-sm-12">
                                            <div class="col-sm-12">

                                                <label class="col-sm-3"> {{equip.equipmentName}} <span *ngIf="equip.dialysisMachineHours" >[{{equip.dialysisMachineHours}}]</span></label>
                                                <label class="col-sm-9"
                                                    [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'"
                                                    *ngIf="f.BacteriasId.value == 0 || status=='Completed'">
                                                    {{equip.sampleResult}}</label>
                                                <div class="col-sm-9"
                                                    *ngIf="f.BacteriasId.value > 0 && status=='In Progress'">
                                                    <select class="form-control smInput"
                                                        (change)="UpdateSampleValue($event)"
                                                        [style.color]="GetColor('testresult_wp_' + equip.bacteriaMachaineId)"
                                                        name="{{'testresult_wp_' + equip.bacteriaMachaineId}}">
                                                        <option value="{{item.value}}"
                                                            [style.color]="(item.value == 'TNTC' || item.value == '> 20' || item.value == '> 50' || item.value == '>20 <49') ? 'red' : 'black'"
                                                            *ngFor="let item of TestResultList"  [selected]="item.value == equip.sampleResult">
                                                            {{item.text}}
                                                        </option>
                                                    </select>
                                                    <label
                                                        [hidden]="(GetColor('testresult_wp_' + equip.bacteriaMachaineId) !=='red')"
                                                        id="{{'lbltestresult_wp_' + equip.bacteriaMachaineId}}"
                                                        style="color:red;padding-left:10px">Result is greater than
                                                        acceptable limits and will require a "Note" entry to complete
                                                        form.</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider" *ngIf="CDTestList.length > 0">
                                          <strong>Central Delivery</strong>
                                      </div>

                                      <div class="clearfix" *ngIf="CDTestList.length > 0"></div>
                                      <div *ngFor="let equip of CDTestList;" class="box-row col-sm-12">
                                          <div class="col-sm-12">

                                              <label class="col-sm-3"> {{equip.equipmentName}} <span *ngIf="equip.dialysisMachineHours" >[{{equip.dialysisMachineHours}}]</span></label>
                                              <label class="col-sm-9"
                                                  [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'"
                                                  *ngIf="f.BacteriasId.value == 0 || status=='Completed'">
                                                  {{equip.sampleResult}}</label>
                                              <div class="col-sm-9"
                                                  *ngIf="f.BacteriasId.value > 0 && status=='In Progress'">
                                                  <select class="form-control smInput"
                                                      (change)="UpdateSampleValue($event)"
                                                      [style.color]="GetColor('testresult_cd_' + equip.bacteriaMachaineId)"
                                                      name="{{'testresult_cd_' + equip.bacteriaMachaineId}}">
                                                      <option value="{{item.value}}"
                                                          [style.color]="(item.value == 'TNTC' || item.value == '> 20' || item.value == '> 50' || item.value == '>20 <49') ? 'red' : 'black'"
                                                          *ngFor="let item of TestResultList"  [selected]="item.value == equip.sampleResult">
                                                          {{item.text}}
                                                      </option>
                                                  </select>
                                                  <label
                                                      [hidden]="(GetColor('testresult_cd_' + equip.bacteriaMachaineId) !=='red')"
                                                      id="{{'lbltestresult_cd_' + equip.bacteriaMachaineId}}"
                                                      style="color:red;padding-left:10px">Result is greater than
                                                      acceptable limits and will require a "Note" entry to complete
                                                      form.</label>
                                              </div>
                                          </div>

                                      </div>
                                        <div class="clearfix"></div>
                                        <div class="box-row col-sm-12 divider" *ngIf="OTHTestList.length > 0">
                                          <div class="col-sm-2">
                                            <strong>Other</strong>
                                        </div>
                                          <label style="color: red;padding-left:30px;" *ngIf="funnelInfoDisplay == false && f.TestingLab.value == 'Internal (Tech)' && f.BacteriasId.value > 0">
                                            Please enter funnel info before select result value.</label>
                                      </div>

                                      <div class="clearfix" *ngIf="OTHTestList.length > 0"></div>
                                      <div *ngFor="let equip of OTHTestList;" class="box-row col-sm-12">
                                          <div class="col-sm-12">

                                              <label class="col-sm-3"> {{equip.equipmentName}} <span *ngIf="equip.dialysisMachineHours" >[{{equip.dialysisMachineHours}}]</span></label>
                                              <label class="col-sm-9"
                                                  [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'"
                                                  *ngIf="f.BacteriasId.value == 0 || status=='Completed'">
                                                  {{equip.sampleResult}}</label>
                                              <div class="col-sm-9"
                                                  *ngIf="f.BacteriasId.value > 0 && status=='In Progress'">
                                                  <select class="form-control smInput"
                                                      (change)="UpdateSampleValue($event)"
                                                      [style.color]="GetColor('testresult_oth_' + equip.bacteriaMachaineId)"
                                                      name="{{'testresult_oth_' + equip.bacteriaMachaineId}}">
                                                      <option value="{{item.value}}"
                                                          [style.color]="(item.value == 'TNTC' || item.value == '> 20' || item.value == '> 50' || item.value == '>20 <49') ? 'red' : 'black'"
                                                          *ngFor="let item of TestResultList"  [selected]="item.value == equip.sampleResult">
                                                          {{item.text}}
                                                      </option>
                                                  </select>
                                                  <label
                                                      [hidden]="(GetColor('testresult_oth_' + equip.bacteriaMachaineId) !=='red')"
                                                      id="{{'lbltestresult_oth_' + equip.bacteriaMachaineId}}"
                                                      style="color:red;padding-left:10px">Result is greater than
                                                      acceptable limits and will require a "Note" entry to complete
                                                      form.</label>
                                              </div>
                                          </div>

                                      </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="box ibox box-info" *ngIf="f.TestingLab.value == 'Internal (Tech)'">
                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <a [ngClass]="collapseFunnelClass" data-toggle="collapse" data-parent="#accordion" href="#bacteriaFunnel" id="bacteriaFunnelRefID"
                                            aria-expanded="collapseFunnelexpanded" aria-controls="collapseFunnel" (click)="bacteriaFunnelChange($event)">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 class="box-title">Funnel and Cassette Info<span *ngIf="f.BacteriasId.value == 0" style="font-size: 14px;color: red;margin-left: 15px;"> (Click here for filling the details of Funnel and Cassette Info) </span>
                                                </h3>
                                            </div>
                                            </a>
                                        </h4>
                                    </div>

                                <div id="bacteriaFunnel"  [ngClass]="collapseFunnel"  role="tabpanel" aria-labelledby="bacteriaFunnel">
                                <div class="box-body">
                                    <div class="row" style="margin-top: 20px;">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Funnel Lot #:</label>

                                                <div class="col-sm-5" *ngIf="(status == 'Completed') || (funnelInfoDisplay == true)">
                                                    <label
                                                        class="readOnlyText">{{f.FunnelLot.value}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="(status != 'Completed' &&  funnelInfoDisplay == false)">
                                                    <input class="form-control mdInput"
                                                        style="float:none" maxlength="12"
                                                        formControlName="FunnelLot"/>
                                                    <span
                                                        *ngIf="f.FunnelLot.errors?.required && (f.FunnelLot.dirty || f.FunnelLot.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Cassette Lot #:</label>
                                                <div class="col-sm-5" *ngIf="(status == 'Completed') || (funnelInfoDisplay == true)">
                                                    <label class="readOnlyText">{{f.CassetteLot.value}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="(status != 'Completed' &&  funnelInfoDisplay == false)">
                                                    <input class="form-control mdInput" name="CassetteLot"
                                                        formControlName="CassetteLot" maxlength="12" />
                                                    <span
                                                        *ngIf="f.CassetteLot.errors?.required && (f.CassetteLot.dirty || f.CassetteLot.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-4"></div>
                                        </div>
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Processing Staff:</label>
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">{{FunnelStaffByName}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label">Processing Date:</label>
                                                <!-- <div class="col-sm-4" *ngIf="(status != 'Completed' &&  funnelInfoDisplay == false)">
                                                    <input (focus)="rfunnelReadDate.toggleCalendar()" (click)="rfunnelReadDate.openCalendar()"
                                                        placeholder="Select a date" class="form-control " style="float:none" angular-mydatepicker
                                                        name="funnelReadDate" formControlName="funnelReadDate" [options]="myOptions"
                                                        #rfunnelReadDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />

                                                    <span *ngIf='f.funnelReadDate.errors?.error || f.funnelReadDate.errors?.InGreaterDateOut'
                                                        [ngClass]="'error'">Processing Date must be greater than or equal to Collection Date! </span>
                                                </div> -->
                                                <div class="col-sm-5">
                                                    <label class="readOnlyText">{{f.funnelReadDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>

                                            </div>
                                            <div class="col-sm-4"></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                              </div>
                            </div>

                            <div class="box ibox box-info" *ngIf="f.BacteriasId.value > 0">

                                <div class="panel panel-default">
                                    <div class="panel-heading" role="tab" id="dmReference">
                                        <h4 class="panel-title">
                                            <div class="box-header with-border" style="padding:0px;">
                                                <h3 *ngIf="f.TestingLab.value != 'Internal (Tech)'" class="box-title">Report Details</h3>
                                                <h3 *ngIf="f.TestingLab.value == 'Internal (Tech)'" class="box-title">Read Details</h3>
                                            </div>
                                        </h4>
                                    </div>
                                </div>
                                <div class="box-body">


                                    <div class="row">




                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-7 control-label" *ngIf="f.TestingLab.value != 'Internal (Tech)'">Report Date:</label>
                                                <label class="col-sm-7 control-label" *ngIf="f.TestingLab.value == 'Internal (Tech)'">Read Date:</label>
                                                <div class="col-sm-5" *ngIf="status == 'Completed'">
                                                    <label
                                                        class="readOnlyText">{{f.SampleReadRetestDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-5" *ngIf="status != 'Completed'">
                                                    <input (focus)="rSampleReadRetestDate.toggleCalendar()"
                                                        (click)="rSampleReadRetestDate.openCalendar()"
                                                        placeholder="Select a date" class="form-control mdInput"
                                                        style="float:none" angular-mydatepicker name="SampleReadRetestDate"
                                                        formControlName="SampleReadRetestDate" [options]="myOptions"
                                                        #rSampleReadRetestDate="angular-mydatepicker"
                                                        (dateChanged)="onDateChanged($event)" />
                                                    <span
                                                        *ngIf="f.SampleReadRetestDate.errors?.required && (f.SampleReadRetestDate.dirty || f.SampleReadRetestDate.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.SampleReadRetestDate.errors?.RequiredStatus"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span style="margin-left: 0px;" *ngIf="f.TestingLab.value != 'Internal (Tech)' && (f.SampleReadRetestDate.errors?.error || f.SampleReadRetestDate.errors?.InGreaterDateOut)"
                                                    [ngClass]="'error'">Report Date >= Collection Date </span>
                                                    <span style="margin-left: 0px;" *ngIf="f.TestingLab.value == 'Internal (Tech)' && (f.SampleReadRetestDate.errors?.error || f.SampleReadRetestDate.errors?.InGreaterDateOut)"
                                                    [ngClass]="'error'">Report Date >= Processing Date </span>
                                                </div>

                                            </div>
                                            <div class="col-sm-7" *ngIf="f.TestingLab.value != 'Internal (Tech)'">
                                                <label class="col-sm-4 control-label">Report ID:</label>
                                                <div class="col-sm-5" *ngIf="status == 'Completed'">
                                                    <label class="readOnlyText">{{f.ReportId.value}}</label>
                                                </div>
                                                <div class="col-sm-3" *ngIf="status != 'Completed'">
                                                    <input class="form-control mdInput" name="ReportId"
                                                        formControlName="ReportId" maxlength="10">
                                                    <span
                                                        *ngIf="f.ReportId.errors?.required && (f.ReportId.dirty || f.ReportId.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ReportId.errors?.RequiredStatus" [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.ReportId.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                                </div>
                                                <div class="col-sm-2" style="padding: 6px 0"  *ngIf="status != 'Completed'">
                                                    <div class='col-sm-1'>
                                                        <mat-checkbox formControlName="chkNAReportID" name="chkNAReportID" (change)="changeReportchk()">
                                                        </mat-checkbox>
                                                    </div>
                                                    <span class='col-sm-3'>N/A</span>
                                                </div>
                                            </div>

                                            <div class="col-sm-4" *ngIf="f.TestingLab.value == 'Internal (Tech)'">
                                                <label class="col-sm-7 control-label">Read User:</label>
                                                <label class="readOnlyText">{{FunnelStaffByNameRetest}}</label>

                                            </div>

                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <notes [isValidForm]="SetValidatorForNotes() || this.BacteriaForm.invalid || (
                              this.DMTestList.length == 0 &&
                              this.WPTestList.length == 0 &&
                              this.CDTestList.length == 0
                            )"
                                (addNoteClick)="executeBacteriaCreateUpdate('Notes')" #notes>
                            </notes>
                            <attachment (RaiseError)="AttachmentError($event)" #attachment>

                            </attachment>
                        </div>
                    </div>
                    <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false"
                        [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
                        (SaveEvent)="executeBacteriaCreateUpdate('')" [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                </div>
            </div>
        </div>
    </section>
</form>

<div class="modal-content" *ngIf="!FullScreen">
    <form [formGroup]="BacteriaForm" (ngSubmit)="executeBacteriaCreateUpdate('')" novalidate>
        <div class="modal-header">
            <h4 class="modal-title">Quick Add Bacteria</h4>
            <button type="button" class="close" aria-label="Close" (click)="dialogBacteriaRef.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="data.errormsg" class="box-row col-sm-12">
                <span [ngClass]="'error'">
                    {{data.errormsg}}
                </span>
            </div>
            <div class="box-row col-sm-12">
                <div class="col-sm-6">
                  Division:
                  <select class="form-control " formControlName="selectedDialysisUnits" name="selectedDialysisUnits"
                      (change)="DialysisUnitDropDownChanged()">
                      <option value="{{item.value}}" *ngFor="let item of DialysisUnits">
                          {{item.text}}
                      </option>
                  </select>
                </div>
              <div class="col-sm-6">
                  <span class='text-red inputrequired'>*</span>Lab Facility:
                  <select class="form-control"  formControlName="TestingLab" name="TestingLab">
                      <option value="{{item.value}}" *ngFor="let item of TestingLabs">
                          {{item.text}}
                      </option>
                  </select>
                  <span
                      *ngIf="f.TestingLab.errors?.required && (f.TestingLab.dirty || f.TestingLab.touched ||  isValidFormSubmitted==false)"
                      [ngClass]="'error'">
                      Required!
                  </span>
              </div>
            </div>
            <div *ngIf="DMTestList.length > 0" class="box-row col-sm-12 divider">
                <div class="col-sm-6">
                    <strong>Dialysis Machine</strong>
                </div>
            </div>

            <div *ngFor="let equip of DMTestList;" class="box-row col-sm-12">
                <div class="col-sm-12">

                    <label class="col-sm-5"> {{equip.equipmentName}}
                        <span *ngIf="(f.BacteriasId.value != 0 || status=='Completed') || (equip.hours && equip.hours != '0')">    [{{equip.dialysisMachineHours}}]
                        </span>
                    </label>

                        <div class="col-sm-4"  *ngIf="(!equip.hours || equip.hours == '') && f.BacteriasId.value == 0">
                                <input class="form-control smInput"
                                (change)="UpdateDialysisMachineHoursValue($event)"
                                (keypress)="numberOnly($event)"
                                name="{{'testresult_dm_' + equip.bacteriaMachaineId}}"
                            />
                            <span [ngClass]="'error'">{{errorAddTest}}</span>
                            </div>
                            <div class="col-sm-1" *ngIf="f.BacteriasId.value == 0 && (!equip.hours || equip.hours == '') ">
                                <label class="readOnlyTextForHours" style="padding: 4px 5px;"
                                    *ngIf="selectedDMHours!=0 && selectedDMHours!=null">({{selectedDMHours}})</label>
                                <label class="readOnlyTextForHours" style="padding: 4px 5px;"
                                    *ngIf="selectedDMHours==0">(N/A)</label>
                            </div>
                    <label class="col-sm-6"
                        [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'">
                        {{equip.sampleResult}}</label>
                </div>
            </div>

            <div class="box-row col-sm-12 divider" *ngIf="WPTestList.length > 0">
                <div class="col-sm-6">
                    <strong>Water Process</strong>
                </div>
            </div>

            <div *ngFor="let equip of WPTestList;" class="box-row col-sm-12">
                <div class="col-sm-12">
                    <label class="col-sm-5"> {{equip.equipmentName}}
                            <span *ngIf="(equip.hours && equip.hours != '0') || f.BacteriasId.value != 0">
                                 [{{equip.dialysisMachineHours}}]
                            </span>
                    </label>

                    <div class="col-sm-5" *ngIf="f.BacteriasId.value == 0 && (!equip.hours || equip.hours == '') ">
                            <input class="form-control smInput"
                            (change)="UpdateDialysisMachineHoursValue($event)"
                            (keypress)="numberOnly($event)"
                            name="{{'testresult_wp_' + equip.bacteriaMachaineId}}">
                            <span [ngClass]="'error'">{{errorAddTest}}</span>
                        </div>
                        <div class="col-sm-2" *ngIf="f.BacteriasId.value == 0 && (!equip.hours || equip.hours == '') ">
                            <label class="readOnlyTextForHours" style="padding: 4px 5px;"
                                *ngIf="selectedROHours!=0 && selectedROHours!=null">({{selectedROHours}})</label>
                            <label class="readOnlyTextForHours" style="padding: 4px 5px;"
                                *ngIf="selectedROHours==0">(N/A)</label>
                        </div>
                    <div class="col-sm-6" >
                        <label [style.color]="(equip.sampleResult == 'TNTC' || equip.sampleResult == '> 20' || equip.sampleResult == '> 50' || equip.sampleResult == '>20 <49') ? 'red' : 'black'">{{equip.sampleResult}}</label>
                    </div>
                </div>
            </div>
            <div class="box-row  col-sm-12">
                <div class="col-sm-6">
                    Collection Date:
                    <input (focus)="rSampleReadDate.toggleCalendar()" (click)="rSampleReadDate.openCalendar()"
                        placeholder="Select a date" class="form-control " style="float:none" angular-mydatepicker
                        name="SampleReadDate" formControlName="SampleReadDate" [options]="myOptions"
                        #rSampleReadDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>
                <div class="col-sm-6">
                    Collection Time:
                    <input class="form-control mdInput" name="SampleReadTime" formControlName="SampleReadTime" />
                </div>
                <div class="col-sm-6">Collection User:
                    <input  class="form-control mdInput"  value="{{CollectionByName}}" disabled="disabled">
                    </div>
            </div>

            <div class="box-row  col-sm-12" *ngIf="status == 'Completed' && f.TestingLab.value == 'Internal (Tech)'">
                <div class="col-sm-6">Funnel Lot #:
                 <input class="form-control mdInput" style="float:none" maxlength="12"
                        formControlName="FunnelLot" disabled/>
                </div>
				<div class="col-sm-6">Cassette Lot #:
                 <input class="form-control mdInput" style="float:none" maxlength="12"
                        formControlName="CassetteLot" disabled/>
                </div>
				 <div class="col-sm-6">Processing Staff:
                 <input class="form-control mdInput" style="float:none" maxlength="12"
                        value="FunnelStaffByName" formControlName="FunnelLot" disabled/>
                </div>
				<div class="col-sm-6">Processing Date:
                   <input (focus)="rfunnelReadDate.toggleCalendar()" (click)="rfunnelReadDate.openCalendar()" disabled
                           placeholder="Select a date" class="form-control " style="float:none" angular-mydatepicker
                           name="funnelReadDate" formControlName="funnelReadDate" [options]="myOptions"
                           #rfunnelReadDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>
            </div>			

            <div class="box-row col-sm-12" *ngIf="status == 'Completed'">
                <div class="col-sm-6">{{f.TestingLab.value != 'Internal (Tech)' ? 'Report Date:' : 'Read Date:'}}
                    <input (focus)="rSampleReadRetestDate.toggleCalendar()"
                        (click)="rSampleReadRetestDate.openCalendar()" placeholder="Select a date" class="form-control "
                        style="float:none" angular-mydatepicker name="SampleReadRetestDate"
                        formControlName="SampleReadRetestDate" [options]="myOptions"
                        #rSampleReadRetestDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                </div>

                <div class="col-sm-6" *ngIf="f.TestingLab.value != 'Internal (Tech)'">
                    Report ID:
                    <input class="form-control " name="ReportId" formControlName="ReportId" />
                </div>
                <div class="col-sm-6" *ngIf="f.TestingLab.value == 'Internal (Tech)'">
                    Read User:
                    <input class="form-control " name="ReadUserByName" disabled value="{{FunnelStaffByNameRetest}}" />
                </div>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="dialogBacteriaRef.close(false)">Cancel</button>
            <button type="button" class="btn btn-primary" *ngIf="f.BacteriasId.value == '0' || !f.BacteriasId.value" (click)="executeBacteriaCreateUpdate('')">Save</button>
        </div>
    </form>

</div>


<div class="box ibox box-info" >
        <div class="box-header with-border">
                <h3 class="box-title">Replaced Part</h3>

        </div>
        <div class="box-body">

                <div class="row" *ngIf="PartTrans && PartTrans.length > 0">
                        <div class="col-sm-12 box-row">
                                <div class="col-sm-2 text-center">
                                        <strong>Date</strong>
                                </div>
                                <div class="col-sm-1 text-center" style="padding-left:0px;">
                                        <strong>User</strong>
                                </div>
                                <div class="col-sm-1 text-center">
                                        <strong>Part ID</strong>
                                </div>
                                <div class="col-sm-5 text-center">
                                        <strong>Part Description</strong>
                                </div>

                                <div class="col-sm-1 text-center" style="padding-left:0px;">
                                        <strong>Part Qty.</strong>
                                </div>
                                <div class="col-sm-1 text-center">
                                        <strong>Part Cost</strong>
                                </div>
                        </div>
                </div>
                <!-- <div *ngFor="let item of PartTrans;let index=index;"
                [ngClass]="{'afterComplete': item.isAfterCompletionAdd}"
                class="row"> -->
                        <div *ngFor="let item of PartTrans;let index=index;"
                        class="row">

                        <div class="col-sm-12 box-row">
                                <!-- *ngIf="!componentDetails.locked || !item.isAutoPart"> -->
                                <div class="col-sm-2 text-center" *ngIf="item.createdOn">
                                        {{item.createdOn | date:'dd/MM/yyyy HH:mm' }}

                                </div>
                                <div class="col-sm-2 text-center" *ngIf="!item.createdOn">
                                        {{currentDate | date:'dd/MM/yyyy HH:mm' }}

                                </div>
                                <div class="col-sm-1 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap; padding-left: 0px;" title="{{item.createdByName}}">
                                        {{item.createdByName }}

                                </div>
                                <div class="col-sm-1 text-center" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap; padding-left: 0px;" title="{{item.partName}}">
                                        {{item.partName}}

                                </div>
                                <div class="col-sm-5 text-left" style="text-overflow: ellipsis;overflow: hidden;cursor: pointer;white-space: nowrap; padding-left: 0px;" title="{{item.partDescription}}">
                                        {{item.partDescription}}
                                </div>

                                <div class="col-sm-1 text-center">
                                        {{item.quantity}}
                                </div>
                                <div class="col-sm-1 text-right">
                                        {{(item.totalCost | currency:"CAD":"symbol-narrow") }}
                                </div>
                                <div class="col-sm-1 text-center">
                                        <!-- *ngIf="!componentDetails.locked" -->
                                        <button (click)="DeletePart(item)" type="button" *ngIf="item.showPartDelete"
                                                class="btn btn-danger btn-label-left center-block btn-xs"> <span><i
                                                                class="fa fa-times"></i></span>
                                        </button>
                                </div>
                        </div>
                </div>
                <div class="box-body" style="border-top: 3px solid #d2d6de;">
                        <!-- *ngIf="!componentDetails.locked" -->
                        <div class="row">
                                <div class="col-sm-12 box-row">
                                        <div class="col-sm-2 text-center">
                                                <strong>Part ID</strong>
                                        </div>
                                        <div class="col-sm-5 text-center">
                                                <strong>Part Description</strong>
                                        </div>

                                        <div class="col-sm-2 text-center">
                                                <strong>Part Qty.</strong>
                                        </div>
                                        <div class="col-sm-2 text-center">
                                                <strong>Part Cost</strong>
                                        </div>
                                </div>
                        </div>
                        <div class="row">
                        <div class="col-sm-12 box-row">
                                <div class="col-sm-2 text-center">
                                        <input type="text" class="form-control" (keyup)="PartCodeChange('code')"
                                                [(ngModel)]="PartSearchTerm" matInput="PartSearchTerm" name="PartSearchTerm"
                                                [matAutocomplete]="autopart">
                                        <mat-autocomplete (optionSelected)='getPartPosts($event.option,"code")'
                                                #autopart="matAutocomplete">
                                                <mat-option (click)="AddNewPartInventory()">
                                                                <i class="fa fa-plus"></i> Add new
                                                </mat-option>
                                                <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentcode">
                                                        {{ item.equipmentcode }}
                                                </mat-option>
                                        </mat-autocomplete>

                                </div>
                                <div class="col-sm-5">
                                        <input type="text" (keyup)="PartCodeChange('desc')" class="form-control"
                                                matInput name="partDescription" [value]="PartSelectedItem.desc"
                                                [(ngModel)]="partsDetails.partDescription" [matAutocomplete]="autopartdesc">
                                        <mat-autocomplete (optionSelected)='getPartPosts($event.option,"desc")'
                                                #autopartdesc="matAutocomplete">
                                                <mat-option *ngFor="let item of partSearchResult" [value]="item.equipmentDesc">
                                                        {{ item.equipmentDesc }}
                                                </mat-option>
                                        </mat-autocomplete>
                                </div>

                                <div class="col-sm-2 text-center">
                                        <select class="form-control mdInput center-block" style="width:75px;float:none"
                                                (change)="SetCost()" [(ngModel)]="partsDetails.quantity" name="quantity">
                                                <option value="{{item.value}}" *ngFor="let item of PartQtyList">{{item.text}}</option>
                                        </select>
                                </div>
                                <div class="col-sm-2 text-center ">
                                        <div class="form-control readOnlyText">
                                                {{partsDetails.totalCost | currency:"CAD":"symbol-narrow"}}
                                        </div>

                                </div>
                                <div class="col-sm-1">
                                        <button (click)="AddPart()" class="btn btn-primary btn-label-left center-block"
                                                type="button">
                                                <span><i class="fa fa-plus"></i></span>
                                                <b>Add</b>
                                        </button>
                                </div>

                        </div>
                        </div>
                        <div class="row text-center">
                                <span class="error">{{parterrormsg}}</span>
                                <br>
                                <span class="error" *ngIf="noteparterrormsg">{{noteparterrormsg}}</span>

                        </div>
                </div>

        </div>
</div>

<form
  [formGroup]="GRDailyDetailForm"
  (ngSubmit)="executeGRWPDailyCreateUpdate('', '')"
  #mainform
  novalidate
>
  <!-- <RepairPart></RepairPart> -->
  <section class="content-header">
    <h1>Water Process Daily Record</h1>
  </section>
  <section class="content">
    <div class="row">
      <!-- /.box -->
      <div class="box  box-primary">
        <PageHeader
          [showNote]="false"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeGRWPDailyCreateUpdate('', '')"
          [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()"
          #pageheader
        ></PageHeader>

        <div class="col-md-12 box">
          <div class="col-sm-12 box-row hidden">
            <label class="col-sm-2 control-label"
              >GRWaterprocessDailyrecordsID</label
            >

            <div class="col-sm-10">
              <input
                type="text"
                readonly
                class="form-control"
                name="GRWaterprocessDailyrecordsID"
                [value]="f.GRWaterprocessDailyrecordsID"
                formControlName="GRWaterprocessDailyrecordsID"
              />
            </div>
          </div>
          <div class="box ibox box-info">
            <div class="box-body">
              <div class="col-sm-12 box-row">
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Event ID:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText"> {{ eventId }}</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Form Date:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText">
                      {{
                        f.formDate.value.jsdate | date: "dd/MM/yyyy HH:mm"
                      }}</label
                    >
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">Created By:</label>
                  <div class="col-sm-6">
                    <label class="readOnlyText"> {{ UserName }}</label>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>

              <div class="col-sm-12 box-row">
                <label class="col-sm-2 control-label">
                  <span class="text-red inputrequired">*</span>Division:</label
                >
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut
                  "
                >
                  <label class="readOnlyText">{{ DUName }}</label>
                </div>
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value == 0 && !formLockedOut
                  "
                >
                  <select
                    class="form-control mdInput"
                    formControlName="selectedDialysisUnits"
                    name="selectedDialysisUnits"
                    (change)="DialysisUnitDropDownChanged()"
                  >
                    <option
                      value="{{ item.value }}"
                      *ngFor="let item of DialysisUnits"
                      >{{ item.text }}</option
                    >
                  </select>
                  <span
                    *ngIf="
                      f.selectedDialysisUnits.errors?.required &&
                      (f.selectedDialysisUnits.dirty ||
                        f.selectedDialysisUnits.touched ||
                        isValidFormSubmitted == false)
                    "
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                  <span
                  *ngIf="f.selectedMainDivision.errors?.DivisionMainRecExists && !f.selectedMainDivision.value.includes('MAIN')"
                  [ngClass]="'error'"
                >
                {{ f.selectedMainDivision.value }} record already exist for {{ f.selectedMainDivision.value.includes('SU') ? 'this week' : 'today' }}.
                </span>
                  <span
                    *ngIf="f.selectedDialysisUnits.errors?.DivisionRecExists"
                    [ngClass]="'error'"
                  >
                    Daily record already exist for today.
                  </span>
                </div>
              </div>

              <div class="col-sm-12 box-row" *ngIf="isMainDivison">
                <label class="col-sm-2 control-label">
                  <span class="text-red inputrequired">*</span>Daily
                  Main:</label
                >
                <div
                  class="col-sm-5"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut
                  "
                >
                  <label class="readOnlyText">{{ MainDUName }}</label>
                </div>
                <div
                  class="col-sm-5"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value == 0 && !formLockedOut
                  "
                >
                  <select
                    class="form-control mdInput"
                    formControlName="selectedMainDivision"
                    name="selectedMainDivision"
                    (change)="MainDivisionDropDownChanged()"
                  >
                    <option
                      value="{{ item.value }}"
                      *ngFor="let item of MainDivisions"
                      >{{ item.text }}</option
                    >
                  </select>
                  <span
                    *ngIf="
                      f.selectedMainDivision.errors?.required &&
                      (f.selectedMainDivision.dirty ||
                        f.selectedMainDivision.touched ||
                        isValidFormSubmitted == false)
                    "
                    [ngClass]="'error'"
                  >
                    Required!
                  </span>
                  <span
                    *ngIf="f.selectedMainDivision.errors?.DivisionMainRecExists"
                    [ngClass]="'error'"
                  >
                  {{ f.selectedMainDivision.value }} Daily record already exist for today.
                  </span>
                </div>
              </div>

              <div class="col-sm-12 box-row"  *ngIf="f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut">
                <label class="col-sm-2 control-label"> Status:</label>
                <!-- <label>Condition{{isMainDivison}}</label>
                <label>Condition1{{isMain3EForm}}{{isMain7Form}}</label>
                <label>Condition2{{main3E7DailyForm}}</label>
                <label>status{{Status}}</label> -->
                <div
                  class="col-sm-10"
                  *ngIf="
                    f.GRWaterprocessDailyrecordsID.value > 0 || formLockedOut">
                  <label class="readOnlyText" *ngIf="!isCentreForm && !centreDailyForm">{{ Status }}</label>
                  <!-- <label class="readOnlyText" *ngIf="isMainDivison && (isMain3EForm || isMain7Form) && main3E7DailyForm">
                    {{ main3E7DailyForm.Status }}
                  </label>
                  <label class="readOnlyText" *ngIf="(isPSUDivision || isFSUDivision || isGSUDivision) && satelliteDetail &&
                  (!f.selectedMainDivision.errors?.DivisionMainRecExists || this.f.GRWaterprocessDailyrecordsID.value > 0)">
                    {{ satelliteDetail.Status }}
                  </label>-->
                  <label class="readOnlyText" *ngIf="isCentreForm && centreDailyForm &&
                  (!f.selectedMainDivision.errors?.DivisionMainRecExists || this.f.GRWaterprocessDailyrecordsID.value > 0)">
                    {{ centreDailyForm.Status }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isMainDivison &&  (isMain7Form || (isMain3EForm && !isNewMain3EForm))">
            <WPDailyMain3EDetail #main3E7DailyForm
            [isMainDivison]="isMainDivison" [isMain3EForm]="isMain3EForm" [isMain7Form]="isMain7Form"
            [selectedDialysisUnits] = "f.selectedDialysisUnits.value"
            [selectedMainDivision] = "f.selectedMainDivision.value"
            ></WPDailyMain3EDetail>
          </div>

          <div *ngIf="isMainDivison && isMain3EForm && isNewMain3EForm">
            <HX2Main3EDetail #HX2Main3EForm
            [isMainDivison]="isMainDivison" [isMain3EForm]="isMain3EForm" [isMain7Form]="isMain7Form"
            [selectedDialysisUnits] = "f.selectedDialysisUnits.value"
            [selectedMainDivision] = "f.selectedMainDivision.value"
            ></HX2Main3EDetail>
          </div>

          <!-- <div *ngIf="isFSUDivision">
            <WPDailyFSUDetail></WPDailyFSUDetail>
          </div>
          <div *ngIf="isGSUDivision">
            <WPDailyGSUDetail></WPDailyGSUDetail>
          </div>
          <div *ngIf="isPSUDivision">
            <WPDailyPSUDetail></WPDailyPSUDetail>
          </div> -->

          <div *ngIf="(isPSUDivision || isFSUDivision || isGSUDivision) &&
          (!f.selectedMainDivision.errors?.DivisionMainRecExists || this.f.GRWaterprocessDailyrecordsID.value > 0)">
            <WPDailySatelliteDetail #setelliteDetailForm
            [division]="f.selectedDialysisUnits.value" [divisionShortText]="f.selectedMainDivision.value"
            ></WPDailySatelliteDetail>
          </div>

          <div *ngIf="isCentreForm &&
          (!f.selectedMainDivision.errors?.DivisionMainRecExists || this.f.GRWaterprocessDailyrecordsID.value > 0)">
            <WPDailyDetail #centreDailyForm
            [division]="f.selectedDialysisUnits.value" [divisionShortText]="f.selectedMainDivision.value"
            ></WPDailyDetail>
          </div>
        </div>

        <div class="clearfix"></div>
        <PageHeader
          [showNote]="false"
          [showAddNew]="false"
          [showSearch]="false"
          [mainlisturl]="ListURL"
          [showSave]="blnshowSave"
          (SaveEvent)="executeGRWPDailyCreateUpdate('', '')"
          [showDelete]="blnshowDelete"
          (DeleteEvent)="DeleteEvent()"
          #pageheader1
        ></PageHeader>
      </div>
    </div>
  </section>
</form>

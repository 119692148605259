<form [formGroup]="WaterAnalysisForm" (ngSubmit)="executeAnalysisUpdate('')" novalidate *ngIf="FullScreen">
  <section class="content-header">
    <h1>Water Process Analysis
      <!-- <sup class="beta">[beta]</sup> -->
    </h1>
  </section>

  <section class="content">
    <div class="row">
      <div class="box  box-primary">
        <PageHeader  [showNote]="false" (addNoteClick)="notes.AddNote()"
          [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL" [showSave]="blnshowSave"
          (SaveEvent)="executeAnalysisUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
          #pageheader></PageHeader>

        <div class="box">
          <div class="box-body">
            <div class="row">
              <div class="col-sm-12 box-row hidden">
                <label class="col-sm-2 control-label">waterProcessAnalysisId</label>
                <div class="col-sm-10">
                  <input type="text" readonly class="form-control" name="waterProcessAnalysisId"
                    [value]="f.waterProcessAnalysisId" formControlName="waterProcessAnalysisId" />
                </div>
              </div>
              <div class="box ibox box-info">
                <div class="box-body">
                  <div class="col-sm-12 box-row">
                    <div class="col-sm-4">
                      <label class="col-sm-5 control-label">
                        Event ID:
                      </label>

                      <div class="col-sm-7">
                        <label class="readOnlyText">{{eventId}}</label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="col-sm-5 control-label">Form Date:</label>
                      <div class="col-sm-7">
                        <label class="readOnlyText">
                          {{f.FormDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="col-sm-5 control-label">
                        <span class="text-red inputrequired"></span>
                        Created By:
                      </label>

                      <div class="col-sm-7">
                        <label class="readOnlyText">{{UserName}}</label>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                  </div>
                </div>
              </div>


              <div class="box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="dmReference">
                    <h4 class="panel-title">
                      <div class="box-header with-border" style="padding:0px;">
                        <h3 class="box-title">
                          Dialysis Unit Details
                        </h3>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>


              <div class="col-sm-12 box-row">
                <div class="col-sm-6">
                  <label class="col-sm-5 control-label">Dialysis Unit:</label>
                  <div class="col-sm-7" *ngIf="showLabel()">
                    <label class="readOnlyText">
                      {{ selectedDialysisUnitstext }}</label>
                  </div>
                  <div class="col-sm-7" *ngIf="!showLabel()">
                    <select class="form-control mdInput" formControlName="selectedDialysisUnits"
                      name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()">
                      <option value="{{ item.value }}" *ngFor="let item of DialysisUnits">{{ item.text }}</option>
                    </select>

                    <span *ngIf="
                                  f.selectedDialysisUnits.errors?.required &&
                                  (f.selectedDialysisUnits.dirty ||
                                    f.selectedDialysisUnits.touched ||
                                    isValidFormSubmitted == false)
                                " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-sm-5 control-label">Dialysis Station:</label>
                  <div class="col-sm-7" *ngIf="showLabel()">
                    <label class="readOnlyText">
                      {{ selectedDialysisStationtext ? selectedDialysisStationtext : 'N/A' }}</label>
                  </div>
                  <div class="col-sm-7" *ngIf="!showLabel()">
                    <select class="form-control mdInput" formControlName="selectedDialysisStations"
                      name="selectedDialysisStations">
                      <option value="{{ item.text == 'Select' ? '0' : item.value }}" *ngFor="let item of DialysisStations">
                        {{ item.text == 'Select' ? 'N/A' : item.text }}
                      </option>
                    </select>

                    <span *ngIf="
                                  f.selectedDialysisStations.errors?.required &&
                                  (f.selectedDialysisStations.dirty ||
                                    f.selectedDialysisStations.touched ||
                                    isValidFormSubmitted == false)
                                " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>

              </div>

              <div class="clearfix"></div>



              <div class="box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="dmReference">
                    <h4 class="panel-title">
                      <div class="box-header with-border" style="padding:0px;">
                        <h3 class="box-title">
                          Collection Details
                        </h3>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 box-row">
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Date:
                  </label>
                  <div class="col-sm-6" *ngIf="showLabel()">
                    <label class="readOnlyText">
                      {{ f.collectionDoneDate.value.jsdate | date:'dd/MM/yyyy' }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="!showLabel()">
                    <input #collectionDate="angular-mydatepicker" (focus)="collectionDate.toggleCalendar()"
                      (click)="collectionDate.openCalendar()" angular-mydatepicker name="collectionDoneDate"
                      formControlName="collectionDoneDate" class="form-control mdInput" style="float:none;"
                      (dateChanged)="oncollectionDateChanged()" [options]="myOptions" placeholder="Select Date" />

                      
                    <span *ngIf="
                                f.collectionDoneDate.errors?.required &&
                                (f.collectionDoneDate.dirty ||
                                  f.collectionDoneDate.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Time:
                  </label>
                  <div class="col-sm-6" *ngIf="showLabel()">
                    <label class="readOnlyText">
                      {{ f.collectionDoneTime.value | date:'HH:mm' }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="!showLabel()">
                    <!-- <input formControlName="collectionDoneTime" Name="collectionDoneTime" placeholder="Select Time"
                      class="form-control mdInput" [ngxTimepicker]="collectionTime" [format]="24"
                      (ngModelChange)="oncollectionDateChanged()" />
                    <ngx-material-timepicker #collectionTime></ngx-material-timepicker> -->                    					  
		<input formControlName="collectionDoneTime" Name="collectionDoneTime" placeholder="Select Time"
    class="form-control mdInput"   [max]="getCurrentTime()" 
    [owlDateTimeTrigger]="DoneTime" [owlDateTime]="DoneTime"/>
      <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(f.collectionDoneTime.value)" #DoneTime></owl-date-time>
      <!-- [min]="getToday()" -->
                    <span *ngIf="
                                f.collectionDoneTime.errors?.required &&
                                (f.collectionDoneTime.dirty ||
                                  f.collectionDoneTime.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Staff:
                  </label>
                  <div class="col-sm-6" *ngIf="showLabel()">
                    <label class="readOnlyText">
                      {{ collectionByName  }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="!showLabel()">
                    <select class="form-control mdInput" formControlName="collectionDoneBy" name="collectionDoneBy">
                      <option value="{{ item.value }}" *ngFor="let item of UserList">
                        {{ item.text }}
                      </option>
                    </select>
                    <span *ngIf="
                                f.collectionDoneBy.errors?.required &&
                                (f.collectionDoneBy.dirty ||
                                  f.collectionDoneBy.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>

              </div>

              <div class="col-sm-12 box-row">
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Sent Date:
                  </label>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Completed' || f.status.value == 'In Progress'">
                    <label class="readOnlyText">
                      {{ f.collectionSentDate.value.jsdate | date:'dd/MM/yyyy' }}
                      </label>
                  </div>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Open' || f.waterProcessAnalysisId.value == 0">
                    <input #collectionDateS="angular-mydatepicker" (focus)="collectionDateS.toggleCalendar()"
                      (click)="collectionDateS.openCalendar()" angular-mydatepicker name="collectionSentDate"
                      formControlName="collectionSentDate" class="form-control mdInput" style="float:none;"
                      (dateChanged)="oncollectionDateSChanged($event)" [options]="myOptions"
                      placeholder="Select Date" />
                    <span *ngIf="
                          f.collectionSentDate.errors?.required &&
                          (f.collectionSentDate.dirty ||
                            f.collectionSentDate.touched ||
                            isValidFormSubmitted == false)
                        " [ngClass]="'error'">
                      Required!
                    </span>
                    <span *ngIf="f.collectionSentDate.errors?.GreaterDate" [ngClass]="'error'">Collection Sent Date and
                      Time should be greater or equals to Collection Date and Time!
                    </span>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Sent Time:
                  </label>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Completed' || f.status.value == 'In Progress'">
                    <label class="readOnlyText">
                      {{ f.collectionSentTime.value | date:'HH:mm' }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Open' || f.waterProcessAnalysisId.value == 0">
                    <!-- <input formControlName="collectionSentTime" Name="collectionSentTime" placeholder="Select Time"
                      class="form-control mdInput" [ngxTimepicker]="collectionTimeS" [format]="24" />
                    <ngx-material-timepicker #collectionTimeS></ngx-material-timepicker> -->
                    <input formControlName="collectionSentTime" Name="collectionSentTime" placeholder="Select Time"
                    class="form-control mdInput"   [max]="getCurrentTime()" 
                    [owlDateTimeTrigger]="SentTime" [owlDateTime]="SentTime"/>
              <owl-date-time [pickerType]="'timer'" [startAt]="getDisplayTime(f.collectionSentTime.value)" #SentTime></owl-date-time>
              <!-- [min]="getToday()" -->
                    <span *ngIf="
                                f.collectionSentTime.errors?.required &&
                                (f.collectionSentTime.dirty ||
                                  f.collectionSentTime.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
                      Required!
                    </span>
                    <span *ngIf="f.collectionSentTime.errors?.GreaterDate" [ngClass]="'error'">Collection Sent Date and
                      Time should be greater or equals to Collection Date and Time!
                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Collection
                    Sent By:
                  </label>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Completed' || f.status.value == 'In Progress'">
                    <label class="readOnlyText">
                      {{ collectionSentName }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="f.status.value == 'Open' || f.waterProcessAnalysisId.value == 0">
                    <select class="form-control mdInput" formControlName="collectionSentBy" name="collectionSentBy">
                      <option value="{{ item.value }}" *ngFor="let item of UserList">
                        {{ item.text }}
                      </option>
                    </select>
                    <span *ngIf="
                                f.collectionSentBy.errors?.required &&
                                (f.collectionSentBy.dirty ||
                                  f.collectionSentBy.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
                      Required!
                    </span>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="box ibox box-info">
                <div class="panel panel-default">
                  <div class="panel-heading" role="tab" id="dmReference">
                    <h4 class="panel-title">
                      <div class="box-header with-border" style="padding:0px;">
                        <h3 class="box-title">
                          Report Details
                        </h3>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 box-row">
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Received Report Date:
                  </label>
                  <div class="col-sm-6" *ngIf="f && f.status.value == 'Completed'">
                    <label class="readOnlyText">
                      {{ f.receivedReportDate.value.jsdate | date:'dd/MM/yyyy' }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="f && f.status.value != 'Completed'">
                    <input #ReportDate="angular-mydatepicker" (focus)="ReportDate.toggleCalendar()"
                      (click)="ReportDate.openCalendar()" angular-mydatepicker name="receivedReportDate"
                      formControlName="receivedReportDate" class="form-control mdInput" style="float:none;"
                      (dateChanged)="onDateChanged($event)" [options]="myOptions" placeholder="Select Date" />
                    <span *ngIf="
                          f.receivedReportDate.errors?.required &&
                          (f.receivedReportDate.dirty ||
                            f.receivedReportDate.touched ||
                            isValidFormSubmitted == false)
                        " [ngClass]="'error'">
                      Required!
                    </span>
                    <span *ngIf='f.receivedReportDate.errors?.error || f.receivedReportDate.errors?.InGreaterDateOut'
                      [ngClass]="'error'">Report Received date should be greater or equals to Collection Sent Date!
                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Report Number:
                  </label>
                  <div class="col-sm-6"  *ngIf="f && f.status.value == 'Completed'">
                    <label class="readOnlyText">
                      {{ f.receivedReportNumber.value }}</label>
                  </div>
                  <div class="col-sm-6" *ngIf="f && f.status.value != 'Completed'">
                    <input name='receivedReportNumber' type="text" maxlength="10" class='form-control'
                      formControlName='receivedReportNumber'>
                    <span
                      *ngIf='f.receivedReportNumber.errors?.required && (f.receivedReportNumber.dirty || f.receivedReportNumber.touched ||
                      isValidFormSubmitted == false)'
                      [ngClass]="'error'"> Required! </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="col-sm-6 control-label">
                    Result:
                  </label>
                  <div class="col-sm-6"  *ngIf="f && f.status.value == 'Completed'">
                    <label class="readOnlyText" [style.color]="f.result.value == 'Fail' ? 'red': 'black'">
                      {{ f.result.value }}</label>
                  </div>
                  <div class="col-sm-6"  *ngIf="f && f.status.value != 'Completed'">
                    <select [style.color]="f.result.value == 'Fail' ? 'red': 'black'"
                                      class="form-control mdInput"
                                      formControlName="result"
                                      name="result">
                                      <option value="" style="color:black">Select</option>
                                      <option value="Pass" style="color:black">Pass</option>
                                      <option value="Fail" style="color:red">Fail</option>
                                    </select>
                    <span
                      *ngIf='f.result.errors?.required && (f.result.dirty || f.result.touched || isValidFormSubmitted == false) '
                      [ngClass]="'error'"> Required! </span>
                  </div>
                </div>
              </div>

              <div class="clearfix"></div>

              <notes [isValidForm]="this.WaterAnalysisForm.invalid  && f.status.value != 'In Progress'" (addNoteClick)="executeAnalysisUpdate('Notes')"
                #notes>

              </notes>
              <attachment (RaiseError)="AttachmentError($event)" #attachment>

              </attachment>
            </div>
          </div>
          <PageHeader [showNote]="false" (addNoteClick)="notes.AddNote()" [showAddNew]="false" [showSearch]="false"
            [mainlisturl]="ListURL" [showSave]="blnshowSave" (SaveEvent)="executeAnalysisUpdate('')"
            [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
        </div>
      </div>
    </div>
  </section>
</form>


<div class="modal-content" *ngIf="!FullScreen">
  <form [formGroup]="WaterAnalysisForm" (ngSubmit)="executeAnalysisUpdate('')" novalidate>
    <div class="modal-header">
      <h4 class="modal-title">Quick Add Water Analysis</h4>
      <button type="button" class="close" aria-label="Close" (click)="dialogWaterRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="data.errormsg" class="box-row col-sm-12">
        <span [ngClass]="'error'">
          {{data.errormsg}}
        </span>
      </div>
      <div class="row box-row">
        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Dialysis Unit:
          <select class="form-control" formControlName="selectedDialysisUnits" name="selectedDialysisUnits"
            (change)="DialysisUnitDropDownChanged()">
            <option value="{{ item.value }}" *ngFor="let item of DialysisUnits">{{ item.text }}</option>
          </select>
          <span *ngIf="
                                  f.selectedDialysisUnits.errors?.required &&
                                  (f.selectedDialysisUnits.dirty ||
                                    f.selectedDialysisUnits.touched ||
                                    isValidFormSubmitted == false)
                                " [ngClass]="'error'">
            Required!
          </span>
        </div>
        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Dialysis Station:
          <select class="form-control" formControlName="selectedDialysisStations" name="selectedDialysisStations">
            <option value="{{ item.text == 'Select' ? '0' : item.value }}" *ngFor="let item of DialysisStations">
              {{ item.text == 'Select' ? 'N/A' : item.text }}
            </option>
          </select>

          <span *ngIf="
                                  f.selectedDialysisStations.errors?.required &&
                                  (f.selectedDialysisStations.dirty ||
                                    f.selectedDialysisStations.touched ||
                                    isValidFormSubmitted == false)
                                " [ngClass]="'error'">
            Required!
          </span>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="row box-row">
        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Collection Date:
          <input #collectionDate="angular-mydatepicker" (focus)="collectionDate.toggleCalendar()"
            (click)="collectionDate.openCalendar()" angular-mydatepicker name="collectionDoneDate"
            formControlName="collectionDoneDate" class="form-control mdInput" style="float:none;"
            (dateChanged)="oncollectionDateChanged()" [options]="myOptions" placeholder="Select Date" />
          <span *ngIf="
                                f.collectionDoneDate.errors?.required &&
                                (f.collectionDoneDate.dirty ||
                                  f.collectionDoneDate.touched ||
                                  isValidFormSubmitted == false)
                              " [ngClass]="'error'">
            Required!
          </span>
        </div>
        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Collection Time:
          <input formControlName="collectionDoneTime" Name="collectionDoneTime" placeholder="Select Time"
            class="form-control mdInput" [ngxTimepicker]="collectionTime" [format]="24"
            (ngModelChange)="oncollectionDateChanged()" />
          <ngx-material-timepicker #collectionTime></ngx-material-timepicker>
          <span *ngIf="
                                    f.collectionDoneTime.errors?.required &&
                                    (f.collectionDoneTime.dirty ||
                                      f.collectionDoneTime.touched ||
                                      isValidFormSubmitted == false)
                                  " [ngClass]="'error'">
            Required!
          </span>
        </div>
      </div>

      <div class="clearfix"></div>


      <div class="row box-row " *ngIf="f.receivedReportDate.value" >
        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Collection Sent Date:
          <input #collectionDateS="angular-mydatepicker" (focus)="collectionDateS.toggleCalendar()"
            (click)="collectionDateS.openCalendar()" angular-mydatepicker name="collectionSentDate"
            formControlName="collectionSentDate" class="form-control mdInput" style="float:none;"
            (dateChanged)="oncollectionDateSChanged($event)" [options]="myOptions" placeholder="Select Date" />
            <span *ngIf="
            f.collectionSentDate.errors?.required &&
            (f.collectionSentDate.dirty ||
              f.collectionSentDate.touched ||
              isValidFormSubmitted == false)
          " [ngClass]="'error'">
          Required!
        </span>
        </div>

        <div class="col-sm-6">
          <span class='text-red inputrequired'>*</span>Collection Sent Time:
          <input formControlName="collectionSentTime" Name="collectionSentTime" placeholder="Select Time"
            class="form-control mdInput" [ngxTimepicker]="collectionTimeS" [format]="24" />
          <ngx-material-timepicker #collectionTimeS></ngx-material-timepicker>
          <span *ngIf="
          f.collectionSentTime.errors?.required &&
          (f.collectionSentTime.dirty ||
            f.collectionSentTime.touched ||
            isValidFormSubmitted == false)
        " [ngClass]="'error'">
              Required!
            </span>
        </div>
      </div>

      <div class="row box-row">
          <span *ngIf="f.collectionSentDate.errors?.GreaterDate || f.collectionSentTime.errors?.GreaterDate"
            [ngClass]="'error'">Collection Sent Date and
            Time should be greater or equals to Collection Date and Time!
          </span>
        </div>


      <div class="clearfix"></div>

      <div class="row box-row">
          <div class="col-sm-6">
            <span class='text-red inputrequired'>*</span>Collection Staff:
            <select class="form-control mdInput" formControlName="collectionDoneBy" name="collectionDoneBy">
              <option value="{{ item.value }}" *ngFor="let item of UserList">
                {{ item.text }}
              </option>
            </select>
            <span *ngIf="
                      f.collectionDoneBy.errors?.required &&
                      (f.collectionDoneBy.dirty ||
                        f.collectionDoneBy.touched ||
                        isValidFormSubmitted == false)
                    " [ngClass]="'error'">
              Required!
            </span>
          </div>
          <div class="col-sm-6" *ngIf="f.receivedReportDate.value" >
            <span class='text-red inputrequired'>*</span>Collection Sent By:
            <select class="form-control mdInput" formControlName="collectionSentBy" name="collectionSentBy">
              <option value="{{ item.value }}" *ngFor="let item of UserList">
                {{ item.text }}
              </option>
            </select>
            <span *ngIf="
                      f.collectionSentBy.errors?.required &&
                      (f.collectionSentBy.dirty ||
                        f.collectionSentBy.touched ||
                        isValidFormSubmitted == false)
                    " [ngClass]="'error'">
              Required!
            </span>
          </div>
        </div>

        <div class="clearfix"></div>


      <div class="clearfix"></div>
        <p *ngIf="!f.receivedReportDate.value" class="text-center">By clicking on Save, New Water Process Analysis will be Added.</p>
      <div class="row box-row" *ngIf="f.receivedReportDate.value" >
        <div class="col-sm-6">
          Received Report Date:
          <input #ReportDate="angular-mydatepicker" (focus)="ReportDate.toggleCalendar()"
            (click)="ReportDate.openCalendar()" angular-mydatepicker name="receivedReportDate"
            formControlName="receivedReportDate" class="form-control mdInput" style="float:none;"
            (dateChanged)="onDateChanged($event)" [options]="myOptions" placeholder="Select Date" />
          <span *ngIf="
                          f.receivedReportDate.errors?.required &&
                          (f.receivedReportDate.dirty ||
                            f.receivedReportDate.touched ||
                            isValidFormSubmitted == false) && f.waterProcessAnalysisId.value > 0
                        " [ngClass]="'error'">
            Required!
          </span>
        </div>
        <div class="col-sm-6">
          Report Number:
          <input name='receivedReportNumber' type="text" maxlength="10" class='form-control' placeholder="Report Number"
            formControlName='receivedReportNumber'>
          <span
            *ngIf='f.receivedReportNumber.errors?.required && (f.receivedReportNumber.dirty || f.receivedReportNumber.touched)  && f.waterProcessAnalysisId.value > 0'
            [ngClass]="'error'"> Required! </span>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="dialogWaterRef.close(false)">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="executeAnalysisUpdate('')" *ngIf="f.waterProcessAnalysisId.value == '0'">Save</button>
    </div>
  </form>

</div>

<form [formGroup]="TaskDetailForm" (ngSubmit)="executeTaskCreateUpdate('')" novalidate>


    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
            Tasks
            <!-- <sup class="beta">[beta]</sup> -->


        </h1>


    </section>
    <section class="content" style="padding-bottom: 20px;margin-bottom: 30px">


        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary" style="padding-bottom: 40px">


                <PageHeader [showNote]="false" [showAddNew]="false" [showSearch]="false" [mainlisturl]="ListURL"
                    [showSave]="blnshowSave" [showEdit]="false" (SaveEvent)="executeTaskCreateUpdate('')"
                    [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()" #pageheader></PageHeader>
                <div class="clearfix"></div>
                <div class="box">
                    <div class="box-body">
                        <div class="box ibox box-info">
                            <!-- <div class="box-header with-border">
                            <h3 class="box-title">AM</h3>
                        </div> -->
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Task Id:</label>


                                        <div class='col-sm-9'>

                                            <label class=" readOnlyText">{{tasknumber}}</label>



                                        </div>
                                    </div>
                                </div>


                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="f.taskid.value > 0">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Completed:</label>


                                        <div class='col-sm-9'>

                                            <mat-button-toggle-group [disabled]="OldCompletedStatus=='true'" value=""
                                                (onchange)="RecStatusChange()" class="col-sm-5"
                                                formControlName="completed" name="completed" form
                                                #taskcompletedgroup="matButtonToggleGroup">

                                                <mat-button-toggle value="true" class="btnsuccess">

                                                    {{onYes}}

                                                </mat-button-toggle>

                                                <mat-button-toggle class="btndanger" value="">

                                                    {{offNo}}

                                                </mat-button-toggle>



                                            </mat-button-toggle-group>
                                            <div class='col-sm-7'
                                                *ngIf="f.taskid.value > 0 && OldCompletedStatus != f.completed.value">
                                                <label class="col-sm-7 control-label">Confirmation User Pin ID:</label>

                                                <div class="col-sm-5">
                                                    <input type="password" class="form-control mdInput"
                                                        formControlName="PinID" name="PinID"
                                                        (keypress)="handleInput($event)">



                                                    <span
                                                        *ngIf="f.PinID.errors?.required && (f.PinID.dirty || f.PinID.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'">
                                                        Required!
                                                    </span>
                                                    <span *ngIf="f.PinID.errors?.UserValidate" [ngClass]="'error'">
                                                        Invalid User Pin Id
                                                    </span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                               

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">


                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Created Date:</label>


                                        <div class='col-sm-9'>
                                            <label
                                                class="col-sm-3 readOnlyText">{{createdOn | date:'dd/MM/yyyy HH:mm'}}</label>
                                        </div>
                                    </div>



                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">


                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Created By:</label>


                                        <div class='col-sm-9'>
                                            <label class="readOnlyText">{{CreatedByUser}}</label>
                                        </div>
                                    </div>



                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'><span
                                                class="text-red inputrequired">*</span>Type:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label class="readOnlyText">{{taskTypeName}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <select name='taskType' (change)="RecStatusChange()"
                                                class='form-control mdInput' formControlName='taskType'>
                                                <option value="{{item.value}}" *ngFor="let item of TaskTypeList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf='f.taskType.errors?.required && (f.taskType.dirty || f.taskType.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'><span
                                                class="text-red inputrequired">*</span>Division:</label>
                                        <div class="col-sm-9" *ngIf="!displayEditable()">
                                            <label class="readOnlyText">{{divisionName}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="displayEditable()">
                                            <select name='divisionId' (change)="DivisionDropDownChanged()"
                                                class='form-control mdInput' formControlName='divisionId'>
                                                <option value="{{item.value}}" *ngFor="let item of DivisionList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf='f.divisionId.errors?.required && (f.divisionId.dirty || f.divisionId.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>



                                <div class="col-sm-12 box-row" style="display: none">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'><span
                                                class="text-red inputrequired">*</span>Category:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label class="readOnlyText">{{categoryName}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <select name='category' (change)="CategoryDropDownChanged()"
                                                class='form-control mdInput' formControlName='category'>
                                                <option value="{{item.value}}" *ngFor="let item of CategoryList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf='f.category.errors?.required && (f.category.dirty || f.category.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix" style="display: none"></div>
                                <div class="col-sm-12 box-row" style="display: none">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'><span
                                                class="text-red inputrequired">*</span>Item:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label class="readOnlyText">{{equipmentName}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <select name='itemId' class='form-control mdInput' formControlName='itemId'>
                                                <option value="{{item.value}}" *ngFor="let item of EquipmentList">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf='f.itemId.errors?.required && (f.itemId.dirty || f.itemId.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix" style="display: none"></div>


                                <div class="col-sm-12 box-row"
                                    *ngIf="(designationName == 'Administrator') || (f.taskid.value > 0)">


                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Assign To:</label>


                                        <!-- [disabled]="(f.taskid.value != 0 && this.createdBy != this.currentUserId) || (OldCompletedStatus=='true')" -->
                                        <div class='col-sm-9'>
                                            <label *ngIf="!displayEditable()"
                                                class="readOnlyText">{{GetAssignedUser()}}</label>
                                            <a *ngIf="(designationName != 'Administrator') && f.AssignUserIds.value.length == 0 && (OldCompletedStatus !='true')"
                                                href="javascript:void(0)" (click)="AcceptTaskClick()"
                                                class="btn btn-primary" id="btnAcceptTask">Accept Task</a>
                                            <ng-multiselect-dropdown class="mdInput" style="background-color:aliceblue;"
                                                [placeholder]="'Not Assigned'" [data]="UserList"
                                                *ngIf="displayEditable()" formControlName='AssignUserIds'
                                                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                                (onSelectAll)="onSelectAll($event)" name="AssignUserIds">
                                            </ng-multiselect-dropdown>
                                            <span
                                                *ngIf='f.AssignUserIds.errors?.required && (f.AssignUserIds.dirty || f.AssignUserIds.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf='f.AssignUserIds.errors?.pattern' [ngClass]="'error'">
                                                Invalid value
                                            </span>
                                        </div>
                                    </div>



                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Description:</label>
                                        <div class="col-sm-9" *ngIf="!displayEditable()">
                                            <label class="readOnlyText">{{f.description.value}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="displayEditable()">
                                            <textarea name="description" autosize formControlName="description"
                                                class="form-textarea lgInput" required></textarea>
                                            <span
                                                *ngIf="f.description.errors?.required && (f.description.dirty || f.description.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>

                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'
                                        *ngIf="f.taskid.value == 0 || f.IsRecurrence.value == 'true'">
                                        <label class='col-sm-3 control-label'>Schedule:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label class="readOnlyText">{{schedule}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <!-- <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                          [switch-readonly] = "f.taskid.value != 0" (onchange)="RecStatusChange()"
                                        formControlName='IsRecurrence' name='IsRecurrence'>
                                    </bSwitch> -->
                                            <mat-button-toggle-group value="" (onchange)="RecStatusChange()"
                                                formControlName="IsRecurrence" name="IsRecurrence" form
                                                #tasktypegroup="matButtonToggleGroup">

                                                <mat-button-toggle value="true" class="btnsuccess">

                                                    {{onText}}

                                                </mat-button-toggle>

                                                <mat-button-toggle class="btndanger" value="">

                                                    {{offText}}

                                                </mat-button-toggle>



                                            </mat-button-toggle-group>

                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12 box-row" *ngIf="f.taskid.value > 0">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Scheduled Date:</label>
                                        <div class="col-sm-3">
                                            <label class="readOnlyText" *ngIf="taskTypeName != 'Meeting'">
                                                {{f.DueStartDate.value.jsdate | date:'dd/MM/yyyy'}}
                                                {{f.DueStartDate.value.jsdate ==f.DueDate.value.jsdate ? "" : f.DueDate.value.jsdate | date:' - dd/MM/yyyy'}}
                                            </label>
                                            <label class="readOnlyText" *ngIf="taskTypeName == 'Meeting'">
                                                {{f.DueStartDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                {{f.DueStartDate.value.jsdate ==f.DueDate.value.jsdate ? "" : f.DueDate.value.jsdate | date:' - dd/MM/yyyy HH:mm'}}
                                            </label>
                                        </div>
                                        <div class='col-sm-3' *ngIf="f.taskid.value > 0">
                                            <label class="col-sm-5 control-label">End Date:</label>

                                            <div class="col-sm-7 readOnlyText">
                                                <!-- {{endDate ? (endDate | date:'dd/MM/yyyy') : (f.DueDate.value.jsdate | date:'dd/MM/yyyy')}} -->
                                                {{this.f.ReoEndDate.value.jsdate | date:'dd/MM/yyyy'}}
                                            </div>
                                        </div>

                                        <div class='col-sm-3'
                                            *ngIf="f.taskid.value > 0 && f.IsRecurrence.value == 'true'">
                                            <label class="col-sm-5 control-label">Frequency:</label>

                                            <div class="col-sm-7 readOnlyText">
                                                {{f.ReoEvery.value}} {{f.ReoPattern.value}} x {{recurrenceCount}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row"
                                    *ngIf="f.taskid.value > 0 && f.IsRecurrence.value == 'true'">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Next Scheduled Date :</label>
                                        <div class="col-sm-9">
                                            <label
                                                class="readOnlyText">{{ nextScheduledDate ? (nextScheduledDate | date:'dd/MM/yyyy') : 'N/A'}}</label>
                                        </div>

                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row"
                                    *ngIf="f.IsRecurrence.value != 'true' && f.taskid.value == 0">
                                    <div class='col-sm-12'>
                                        <label class="col-sm-3 control-label" *ngIf="taskTypeName !='Meeting'"><span
                                                class="text-red inputrequired">*</span>Due Date:</label>
                                        <label class="col-sm-3 control-label" *ngIf="taskTypeName == 'Meeting'"><span
                                                class="text-red inputrequired">*</span>Date:</label>

                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label
                                                class="readOnlyText">{{f.DueStartDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0 && taskTypeName !='Meeting'">

                                            <input (focus)="rDueStartDate.toggleCalendar()"
                                                (click)="rDueStartDate.openCalendar()" placeholder="Select a start date"
                                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                                name="DueStartDate" formControlName="DueStartDate" [options]="myOptions"
                                                #rDueStartDate="angular-mydatepicker"
                                                (dateChanged)="onStartDateChanged($event)" />
                                            <input (focus)="rDueDate.toggleCalendar()" style="display:none;"
                                                angular-mydatepicker name="DueDate" formControlName="DueDate"
                                                [options]="myOptions" #rDueDate="angular-mydatepicker"
                                                (dateChanged)="onDateChanged($event)" />
                                            <!-- [ngxTimepicker]="VehicleStartTime" [format]="24" -->
                                            <!-- <ngx-material-timepicker #VehicleStartTime></ngx-material-timepicker> -->


                                            <span
                                                *ngIf="(f.DueStartDate.errors?.required && (f.DueStartDate.dirty || f.DueStartDate.touched ||  isValidFormSubmitted==false))"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0 && taskTypeName=='Meeting'">

                                            <input (focus)="rDueStartDate.toggleCalendar()"
                                                (click)="rDueStartDate.openCalendar()" placeholder="Select a start date"
                                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                                name="DueStartDate" formControlName="DueStartDate" [options]="myOptions"
                                                #rDueStartDate="angular-mydatepicker"
                                                (dateChanged)="onStartDateChanged($event)" />
                                            <input (focus)="rDueDate.toggleCalendar()" (click)="rDueDate.openCalendar()"
                                                placeholder="Select a  date" class="form-control mdInput"
                                                style="float:none;display: none" angular-mydatepicker name="DueDate"
                                                formControlName="DueDate" [options]="myOptions"
                                                #rDueDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)" />
                                            <!-- [ngxTimepicker]="VehicleStartTime" [format]="24" -->
                                            <!-- <ngx-material-timepicker #VehicleStartTime></ngx-material-timepicker> -->


                                            <span
                                                *ngIf="(f.DueStartDate.errors?.required && (f.DueStartDate.dirty || f.DueStartDate.touched ||  isValidFormSubmitted==false))"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-sm-12 box-row"
                                    *ngIf="f.IsRecurrence.value != 'true' && f.taskid.value == 0 && taskTypeName=='Meeting'">

                                    <div class='col-sm-12'>
                                        <!-- <label class="col-sm-3 control-label" *ngIf="taskTypeName !='Meeting'"><span class="text-red inputrequired"  >*</span>Due End Date:</label>
                                <label class="col-sm-3 control-label" *ngIf="taskTypeName=='Meeting'"><span class="text-red inputrequired" >*</span>Select Time:</label> -->
                                        <label class="col-sm-3 control-label"><span
                                                class="text-red inputrequired">*</span>Select Time:</label>


                                        <div class='col-sm-9'>
                                            <input formControlName="MeetingStartTime" Name="MeetingStartTime"
                                                placeholder="Time" class="form-control smInput"
                                                style="max-width: 90px !important;" [max]="getTodayLastTime()"
                                                [min]="getToday()" [owlDateTimeTrigger]="MeetingStartTime"
                                                [owlDateTime]="MeetingStartTime" />
                                            <owl-date-time [pickerType]="'timer'"
                                                [startAt]="getDisplayTime(f.MeetingStartTime.value)" #MeetingStartTime>
                                            </owl-date-time>

                                            <input formControlName="MeetingEndTime" Name="MeetingEndTime"
                                                placeholder="Time" class="form-control smInput"
                                                style="max-width: 90px !important;margin-left: 15px"
                                                [max]="getTodayLastTime()" [min]="getToday()"
                                                [owlDateTimeTrigger]="MeetingEndTime" [owlDateTime]="MeetingEndTime" />
                                            <owl-date-time [pickerType]="'timer'"
                                                [startAt]="getDisplayTime(f.MeetingEndTime.value)" #MeetingEndTime>
                                            </owl-date-time>

                                            <!-- [ngxTimepicker]="VehicleStartTime" [format]="24" -->
                                            <!-- <ngx-material-timepicker #VehicleStartTime></ngx-material-timepicker> -->


                                            <span
                                                *ngIf="(f.MeetingStartTime.errors?.required && (f.MeetingStartTime.dirty || f.MeetingStartTime.touched ||  isValidFormSubmitted==false)) ||
                                        (f.MeetingEndTime.errors?.required && (f.MeetingEndTime.dirty || f.MeetingEndTime.touched ||  isValidFormSubmitted==false)) "
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.MeetingEndTime.errors?.GreaterDate" [ngClass]="'error'">End
                                                Time should be greater or equals to Start Time!
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="box ibox box-info" *ngIf="f.IsRecurrence.value == 'true' && f.taskid.value == 0">
                            <div class="box-header with-border">
                                <h3 class="box-title">Recurrence</h3>
                            </div>
                            <div class="box-body">

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Recurrence Pattern:</label>

                                        <div class='col-sm-9'>
                                            <mat-button-toggle-group value="Daily" (onchange)="RecStatusChange()"
                                                [disabled]="f.taskid.value > 0" formControlName="ReoPattern"
                                                name="ReoPattern" form #patterngroup="matButtonToggleGroup">

                                                <mat-button-toggle value="Daily">

                                                    Daily

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Weekly">

                                                    Weekly

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Monthly">

                                                    Monthly

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Yearly">

                                                    Yearly

                                                </mat-button-toggle>

                                            </mat-button-toggle-group>
                                            <span
                                                *ngIf='f.ReoPattern.errors?.required && (f.ReoPattern.dirty || f.ReoPattern.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Recur Every:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label class="readOnlyText">{{f.ReoEvery.value}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <input name="ReoEvery" maxlength="3" class="form-control smInput"
                                                formControlName="ReoEvery" name="ReoEvery">
                                            <span
                                                *ngIf='f.ReoEvery.errors?.required && (f.ReoEvery.dirty || f.ReoEvery.touched || isValidFormSubmitted==false)'
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'
                                        *ngIf="(f.taskid.value == 0 && f.IsRecurrence.value == 'true' && f.ReoPattern.value == 'Monthly')">
                                        <label class='col-sm-3 control-label'>Recur Schedule:</label>

                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <mat-button-toggle-group value="" formControlName="recurBy" (onchange)="recurByChange()"
                                                name="recurBy" form #tasktypegroup="matButtonToggleGroup">

                                                <mat-button-toggle value="byDay" class="btnsuccess">

                                                    {{onMonthWeekText}}

                                                </mat-button-toggle>

                                                <mat-button-toggle class="btndanger" value="">

                                                    {{offMonthWeekText}}

                                                </mat-button-toggle>



                                            </mat-button-toggle-group>


                                        </div>

                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="(f.ReoPattern.value == 'Monthly' && f.recurBy.value == 'byDay')">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'> </label>
                                        <div class='col-sm-9'>
                                            <mat-button-toggle-group [disabled]="f.taskid.value > 0"
                                                formControlName="TreatmentRecurrenceWeek" name="TreatmentRecurrenceWeek" form
                                                #weekdaysgroup="matButtonToggleGroup"
                                                (change)="GetActualMonthlyDate()">

                                                <mat-button-toggle value="W1">
                                                    1st
                                                </mat-button-toggle>
                                                <mat-button-toggle value="W2">
                                                    2nd
                                                </mat-button-toggle>
                                                <mat-button-toggle value="W3">
                                                    3rd
                                                </mat-button-toggle>
                                                <mat-button-toggle value="W4">
                                                    4th
                                                </mat-button-toggle>

                                            </mat-button-toggle-group>
                                            <span
                                                *ngIf="f.TreatmentRecurrenceWeek.errors?.required && (f.TreatmentRecurrenceWeek.dirty || f.TreatmentRecurrenceWeek.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="(f.ReoPattern.value == 'Monthly' && f.recurBy.value == 'byDay')">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'> </label>
                                        <div class='col-sm-9'>
                                            <mat-button-toggle-group [disabled]="f.taskid.value > 0"
                                                formControlName="ReoWeekDays" name="ReoWeekDays" form
                                                #weekdaysgroup="matButtonToggleGroup"
                                                (change)="GetActualMonthlyDate()">

                                                <mat-button-toggle value="Monday">

                                                    Monday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Tuesday">

                                                    Tuesday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Wednesday">

                                                    Wednesday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Thursday">

                                                    Thursday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Friday">

                                                    Friday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Saturday">

                                                    Saturday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Sunday">

                                                    Sunday

                                                </mat-button-toggle>

                                            </mat-button-toggle-group>
                                            <span
                                                *ngIf="f.ReoWeekDays.errors?.required && (f.ReoWeekDays.dirty || f.ReoWeekDays.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="f.ReoPattern.value == 'Weekly'">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'> </label>
                                        <div class='col-sm-9'>
                                            <mat-button-toggle-group multiple [disabled]="f.taskid.value > 0"
                                                formControlName="ReoWeekDays" name="ReoWeekDays" form
                                                #weekdaysgroup="matButtonToggleGroup">

                                                <mat-button-toggle value="M">

                                                    Monday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="T">

                                                    Tuesday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="W">

                                                    Wednesday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Th">

                                                    Thursday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="F">

                                                    Friday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="Sa">

                                                    Saturday

                                                </mat-button-toggle>

                                                <mat-button-toggle value="S">

                                                    Sunday

                                                </mat-button-toggle>

                                            </mat-button-toggle-group>
                                            <span
                                                *ngIf="f.ReoWeekDays.errors?.required && (f.ReoWeekDays.dirty || f.ReoWeekDays.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12' *ngIf="f.recurBy.value != 'byDay'">
                                        <label class='col-sm-3 control-label'>Start Date:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label
                                                class="readOnlyText">{{f.ReoStartDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <input (focus)="rReoStartDate.toggleCalendar()"
                                                (click)="rReoStartDate.openCalendar()" placeholder="Select a date"
                                                class="form-control mdInput" style="float:none" angular-mydatepicker
                                                name="ReoStartDate" formControlName="ReoStartDate" [options]="myOptions"
                                                #rReoStartDate="angular-mydatepicker" />
                                            <span
                                                *ngIf="f.ReoStartDate.errors?.required && (f.ReoStartDate.dirty || f.ReoStartDate.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                        
                                    </div>
                                    <div class='col-sm-12' *ngIf="(f.ReoPattern.value == 'Monthly' && f.recurBy.value == 'byDay')">
                                        <label class='col-sm-3 control-label'>Start Month:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label
                                                class="readOnlyText">{{f.ReoStartDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class="col-sm-9" *ngIf="f.taskid.value ==0">
                                            <!-- <input type="text" 
                                            #rROPreFiltersLastDate="dpDayPicker"
                                            [dpDayPicker]="dayPickerConfig"
                                            [theme]="'dp-material'"
                                            formControlName="ReoStartMonth"
                                            Name="ReoStartMonth"
                                            [mode]="'month'"
                                            class="form-control mdInput"
                                            placeholder="Last date"
                                            (onChange)="GetActualMonthlyDate()"
                                                    /> -->
                                                    <!-- (dateChanged)="onDateChangedStartDate($event)" -->
                                                     
                                            <label style="color:red;"
                                                    class="readOnlyText" *ngIf="!(f.ReoStartMonth.errors?.error) && f.recurBy.value == 'byDay' && f.ReoPattern.value == 'Monthly' && f.ReoStartDate.value.jsdate != null">Actual Start Date: {{ f.ReoStartDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                            <span *ngIf="f.ReoStartMonth.errors?.error" class="readOnlyText" style="color:red;">
                                                Selected Week and Day is already passed for Selected Month!
                                                </span>

                                                    <!-- <span
                                            *ngIf="f.ReoStartMonth.errors?.required && (f.ReoStartMonth.dirty || f.ReoStartMonth.touched ||  isValidFormSubmitted==false)"
                                            [ngClass]="'error'">
                                            Required!
                                        </span> -->
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row" *ngIf="f.taskid.value == 0 && taskTypeName=='Meeting'">
                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>Select Time:</label>
                                        <div class="col-sm-9" *ngIf="f.taskid.value > 0">
                                            <label
                                                class="readOnlyText">{{f.ReoStartDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                        </div>
                                        <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                            <input formControlName="ReoMeetingStartTime" Name="ReoMeetingStartTime"
                                                placeholder="Time"
                                                *ngIf="f.taskid.value == 0 && taskTypeName=='Meeting'"
                                                class="form-control smInput" style="max-width: 90px !important"
                                                [max]="getTodayLastTime()" [min]="getToday()"
                                                [owlDateTimeTrigger]="ReoMeetingStartTime"
                                                [owlDateTime]="ReoMeetingStartTime" />
                                            <owl-date-time [pickerType]="'timer'"
                                                [startAt]="getDisplayTime(f.ReoMeetingStartTime.value)"
                                                #ReoMeetingStartTime></owl-date-time>

                                            <input formControlName="ReoMeetingEndTime" Name="ReoMeetingEndTime"
                                                *ngIf="f.taskid.value == 0 && taskTypeName=='Meeting'"
                                                placeholder="Time" class="form-control smInput"
                                                style="max-width: 90px !important;margin-left: 15px"
                                                [max]="getTodayLastTime()" [min]="getToday()"
                                                [owlDateTimeTrigger]="ReoMeetingEndTime"
                                                [owlDateTime]="ReoMeetingEndTime" />


                                            <owl-date-time [pickerType]="'timer'"
                                                [startAt]="getDisplayTime(f.ReoMeetingEndTime.value)"
                                                #ReoMeetingEndTime></owl-date-time>

                                            <span
                                                *ngIf="(f.ReoMeetingStartTime.errors?.required && (f.ReoMeetingStartTime.dirty || f.ReoMeetingStartTime.touched ||  isValidFormSubmitted==false))
                                        || (f.ReoMeetingEndTime.errors?.required && (f.ReoMeetingEndTime.dirty || f.ReoMeetingEndTime.touched ||  isValidFormSubmitted==false))"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                            <span *ngIf="f.ReoMeetingEndTime.errors?.GreaterDate"
                                                [ngClass]="'error'">End Time should be greater or equals to Start Time!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">

                                    <div class='col-sm-12'>
                                        <label class='col-sm-3 control-label'>End Date:</label>

                                        <div class='col-sm-9'>
                                            <mat-button-toggle-group (onchange)="RecStatusChange()"
                                                [disabled]="f.taskid.value > 0" formControlName="ReoEndOption"
                                                name="ReoEndOption" form #endOptiongroup="matButtonToggleGroup">

                                                <mat-button-toggle value="date">

                                                    Date By

                                                </mat-button-toggle>

                                                <mat-button-toggle value="occurance">

                                                    End After

                                                </mat-button-toggle>

                                                <!-- <mat-button-toggle value="no">

                                      No end date

                                    </mat-button-toggle> -->



                                            </mat-button-toggle-group>
                                            <span
                                                *ngIf="f.ReoEndOption.errors?.required && (f.ReoEndOption.dirty || f.ReoEndOption.touched ||  isValidFormSubmitted==false)"
                                                [ngClass]="'error'">
                                                Required!
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">


                                    <div class='col-sm-12' *ngIf="f.ReoEndOption.value == 'date'">
                                        <div class='col-sm-12'>
                                            <label class='col-sm-3 control-label'> </label>
                                            <div class='col-sm-9' *ngIf="f.taskid.value > 0">
                                                <label
                                                    class="readOnlyText">{{f.ReoEndDate.value.jsdate | date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                                <input (focus)="rReoEndDate.toggleCalendar()"
                                                    (click)="rReoEndDate.openCalendar()" placeholder="Select a date"
                                                    class="form-control mdInput" style="float:none" angular-mydatepicker
                                                    name="ReoEndDate" formControlName="ReoEndDate" [options]="myOptions"
                                                    #rReoEndDate="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" />
                                                <span
                                                    *ngIf="f.ReoEndDate.errors?.required && (f.ReoEndDate.dirty || f.ReoEndDate.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                                <span *ngIf="f.ReoEndDate.errors?.GreaterDate" [ngClass]="'error'">End
                                                    Date should be greater than Start Date!
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div class='col-sm-12' *ngIf="f.ReoEndOption.value == 'occurance'">
                                        <div class='col-sm-12'>
                                            <label class='col-sm-3 control-label'> </label>
                                            <div class='col-sm-9' *ngIf="f.taskid.value > 0">
                                                <label class="readOnlyText">{{f.ReoOccurance.value}}</label>
                                            </div>
                                            <div class='col-sm-9' *ngIf="f.taskid.value == 0">
                                                <input name="ReoOccurance" maxlength="3" class="form-control smInput"
                                                    formControlName="ReoOccurance" name="ReoOccurance"> occurrences
                                                <span
                                                    *ngIf='f.ReoOccurance.errors?.required && (f.ReoOccurance.dirty || f.ReoOccurance.touched || isValidFormSubmitted==false)'
                                                    [ngClass]="'error'">
                                                    Required!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <notes *ngIf="f.taskid.value > 0"
                            [isValidForm]="this.TaskDetailForm.invalid && (f.taskid.value == '0' || f.taskid.value !== '')"
                            (addNoteClick)="executeTaskCreateUpdate('Notes')" #notes>

                        </notes>
                        <attachment *ngIf="f.taskid.value > 0" (RaiseError)="AttachmentError($event)" #attachment>

                        </attachment>
                    </div>
                </div>

                <PageHeader title="Tasks" [showNote]="false" [showAddNew]="false" [showSearch]="false"
                    [mainlisturl]="ListURL" [showEdit]="false" [showSave]="blnshowSave"
                    (SaveEvent)="executeTaskCreateUpdate('')" [showDelete]="blnshowDelete" (DeleteEvent)="DeleteEvent()"
                    #pageheader></PageHeader>


            </div>
        </div>
        <!-- </div> -->
    </section>


</form>
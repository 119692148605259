<section class="content-header">
  <h1>
    <h1>
      {{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
    <div class="row">
          <div class="box  box-primary" style="overflow-y: auto;">
              <div class="box-header pageheader row" style="max-width: 95%;">
                    <div class="col-sm-1 text-left">

                    </div>
                    <div class="col-sm-10 text-center">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                      <i class="fa fa-picture-o"></i> Layout View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                      <i class="fa fa-table"></i>  Table View
                    </a>
                    <a type="button" class="btn btn-primary"  style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                      <i class="fa fa-th-list"></i> Set Points
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                    (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                    <i class="fa fa-file"></i> Document View
                  </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                      <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                      <i class="fa fa-th-list"></i> Data Logs
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
              (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
              <i class="fa fa-th-list"></i> Notification Settings
            </a>
                  </div>
                  <div class="col-sm-1 text-right">
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                      <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                    </a>
                  </div>
              </div>
              <div class="col-sm-offset-1 col-sm-10">

                  <!-- <div class="col-sm-6"> -->
                      <!-- <table class="table"  border="none"
                      cellspacing="0" cellpadding="0" style="border:0px; table-layout: fixed;" role="grid">
                          <thead>
                              <tr>
                                  <th>Tag #</th>
                                  <th width="40%">Parameter Name</th>
                                  <th>Security</th>
                                  <th>Lower Limit</th>
                                  <th>Upper Limit</th>
                                  <th>Default</th>
                                  <th width="15%">Customer Setting</th>
                                  <th>Unit</th>
                              </tr>
                          </thead>
                          <tbody>
                                  <tr class="pointer">
                                    <td class="empty" ><b>No Tag</b></td>
                                    <td class="empty" style="text-align:left"><b>Softener Configuration</b></td>
                                    <td class="empty" >4</td>
                                    <td class="empty" colspan="2" >Duplex or Single</td>
                                    <td class="empty" >Single</td>
                                    <td class="empty" > </td>
                                    <td class="empty" >No Tag</td>
                                  </tr>
                                  <tr class="pointer">
                                    <td class="empty" ><b>UA 1850</b></td>
                                    <td class="empty" style="text-align:left"><b>General Pre-treatment Interlock RO On/Off</b></td>
                                    <td class="empty" >n/a</td>
                                    <td class="empty" colspan="2" >RO On or RO Off</td>
                                    <td class="empty" >RO Off</td>
                                    <td class="empty" > </td>
                                    <td class="empty" >UA 1850</td>
                                  </tr>
                                  <tr class="pointer">
                                    <td class="empty" ><b>UA 1850</b></td>
                                    <td class="empty" style="text-align:left"><b>General Pre-treatment Interlock RO On/Off</b></td>
                                    <td class="empty" >n/a</td>
                                    <td class="empty" colspan="2" >RO On or RO Off</td>
                                    <td class="empty" >RO Off</td>
                                    <td class="empty" > </td>
                                    <td class="empty" >UA 1850</td>
                                  </tr>
                                  <tr rowspan="2" class="pointer">
                                    <td class="empty" ><b>LAL 3615</b></td>
                                    <td class="empty" style="text-align:left"><b>POU Sanitization Tank Level Low Alert</b></td>
                                    <td class="empty" >5</td>
                                    <td class="empty" colspan="2" >RO On or RO Off</td>
                                    <td class="empty" >RO Off</td>
                                    <td class="empty" > </td>
                                    <td class="empty" >UA 1850</td>
                                  </tr>
                                  <tr *ngFor="let item of lstSetPoints;let index=index;" class="pointer">
                                    <td class="empty" ><b>{{item.tag}}</b></td>
                                    <td class="empty" style="text-align:left"><b>{{item.parameter}}</b></td>
                                    <td class="empty" >{{item.security}}</td>
                                    <td class="empty" colspan="{{item.lower_colspan}}" >{{item.lower}}</td>
                                    <td class="empty" >{{item.default}}</td>
                                    <td class="empty" > </td>
                                    <td class="empty" >{{item.unit}}</td>
                                  </tr>
                          </tbody>
                      </table> -->
                  <!-- </div> -->

                  <table class="table"  border="none"
                  cellspacing="0" cellpadding="0" style="border:1px; table-layout: fixed;" role="grid">

                  <thead>
                      <tr>
                          <th width="10%">Tag #</th>
                          <th width="32%">Parameter Name</th>
                          <th width="7%">Security</th>
                          <th>Lower Limit</th>
                          <th>Upper Limit</th>
                          <th width="11%">Default</th>
                          <th width="13%">Customer Setting</th>
                          <th width="10%">Unit</th>
                      </tr>
                  </thead>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Softener Configuration</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Duplex or Single</td>
                    <td class="empty">Single</td>
                    <td class="empty">Single</td>
                    <td class="empty">No Tag</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">UA 1850</td>
                    <td class="th_left empty">General Pre-treatment Interlock RO On/Off</td>
                    <td class="empty">n/a</td>
                    <td colspan="2" class="empty">RO On or RO Off</td>
                    <td class="empty">RO Off</td>
                    <td class="empty">RO ON</td>
                    <td class="empty">UA 1850</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">LAL 3615</td>
                    <td rowspan="2" class="th_left empty">POU Sanitization Tank Level Low Alert</td>
                    <td rowspan="2" class="empty">5</td>
                    <td class="empty">6</td>
                    <td class="empty">80</td>
                    <td class="empty">25</td>
                    <td rowspan="2" class="empty">10</td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td class="empty">23</td>
                    <td class="empty">302</td>
                    <td class="empty">189</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">LALL 3615</td>
                    <td rowspan="2" class="th_left empty">Tank Level Low Low Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="5" sdnum="1033;0;0">5</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="19" sdnum="1033;0;0">19</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">LAHH 3615</td>
                    <td rowspan="2" class="th_left empty">Tank Level High High Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="82" sdnum="1033;0;0">82</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="310" sdnum="1033;0;0">310</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">CAL 9874</td>
                    <td rowspan="2" class="th_left empty">Loop Return Flow Velocity Low Alert</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.0">1.0</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0.0">10.0</td>
                    <td class="empty" sdval="1.5" sdnum="1033;0;0.0">1.5</td>
                    <td rowspan="2" class="empty">1.3</td>
                    <td class="empty">fts</td>
                  </tr>
                  <tr>
                    <td class="empty" sdval="0.305" sdnum="1033;0;0.000">0.305</td>
                    <td class="empty" sdval="3.05" sdnum="1033;0;0.00">3.05</td>
                    <td class="empty" sdval="0.915" sdnum="1033;0;0.000">0.915</td>
                    <td class="empty">mps</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">CAH 9850</td>
                    <td class="th_left empty">Pre-filter Pressure Differential High Alert</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge;10</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >PAL 3605</td>
                    <td class="th_left empty">Feed Pressure Low Low Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&le; 15</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">TAHH  3610</td>
                    <td rowspan="2" class="th_left empty">Heater Element Temperature High High Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="212" sdnum="1033;0;0">212</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0">100</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">TALL 3630</td>
                    <td rowspan="2" class="th_left empty">Heat San Temperature Low Low Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">CTC1 9800</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">CTC1 9800</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >AAH 3635</td>
                    <td class="th_left empty">Feed Conductivity High Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="1000" sdnum="1033;0;0">1000</td>
                    <td class="empty" sdval="3000" sdnum="1033;0;0">3000</td>
                    <td class="empty">&ge; 2000</td>
                    <td class="empty">2000</td>
                    <td class="empty">&micro;S/cm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">TAHH1 3640</td>
                    <td rowspan="2" class="th_left empty">Feed Temperature High High Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 104</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 40</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">TAHH2 3640</td>
                    <td rowspan="2" class="th_left empty">Heat Sanitization Temperature High High Alarm</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 208</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 98</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >KAHH 3640</td>
                    <td class="th_left empty">Heat Sanitization Ramp Too Long Alarm</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="120" sdnum="1033;0;0">120</td>
                    <td class="empty" sdval="480" sdnum="1033;0;0">480</td>
                    <td class="empty" sdval="240" sdnum="1033;0;0">240</td>
                    <td class="empty">200</td>
                    <td class="empty">min</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PAHH1 3645</td>
                    <td class="th_left empty">1st  Pass Pump Pressure High High Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 250</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PAHH2 3645</td>
                    <td class="th_left empty">1st  Pass Heat Sani Pressure High High Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 30</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >AAH 3665</td>
                    <td class="th_left empty">1st  Pass Product Conductivity High Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.0">1.0</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0.0">100.0</td>
                    <td class="empty">&ge; 10</td>
                    <td class="empty">50</td>
                    <td class="empty">&micro;S/cm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">FALL 3660</td>
                    <td rowspan="2" class="th_left empty">1st  Pass Reject Drain Flow Low Low Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td rowspan="2" colspan="2">Fixed</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.00">1.00</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td class="empty" sdval="3.78" sdnum="1033;0;0.00">3.78</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">CAH 9854</td>
                    <td class="th_left empty">1st Pass Membrane Pressure Differential High Alert</td>
                    <td class="empty">n/a</td>
                    <td class="empty" sdval="5" sdnum="1033;0;0">5</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty">40</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >AAH 3665</td>
                    <td class="th_left empty">1st Pass Product Conductivity High Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.0">1.0</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0.0">100.0</td>
                    <td class="empty">&ge; 10.0</td>
                    <td class="empty">50</td>
                    <td class="empty">&micro;S/cm</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PAHH1 3675</td>
                    <td class="th_left empty">2nd  Pass Pump Pressure High High Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 280</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PAHH2 3675</td>
                    <td class="th_left empty">2nd  Pass Heat Sani Pressure High High Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 30</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >CAH 9856</td>
                    <td class="th_left empty">2nd Pass Membrane Pressure Differential High Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="5" sdnum="1033;0;0">5</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty">20</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >AAH 3695</td>
                    <td class="th_left empty">2nd Pass Product Conductivity High Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.0">1.0</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0.0">100.0</td>
                    <td class="empty">&ge; 10.0</td>
                    <td class="empty">10</td>
                    <td class="empty">&micro;S/cm</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">AAHH 3695</td>
                    <td class="th_left empty">2nd Pass Product Conductivity High High Alarm</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.0">1.0</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0.0">100.0</td>
                    <td class="empty">&ge; 20.0</td>
                    <td class="empty">13</td>
                    <td class="empty">&micro;S/cm</td>
                  </tr>
                  <tr>
                      <td class="th_center empty">CAL 9864</td>
                      <td class="th_left empty">System Percent Rejection Low Aler</td>
                      <td class="empty" ><br></td>
                      <td class="empty" sdval="1" sdnum="1033;0;0.0"><br></td>
                      <td class="empty" sdval="100" sdnum="1033;0;0.0"><br></td>
                      <td class="empty"><br></td>
                      <td class="empty">95</td>
                      <td class="empty">%</td>
                    </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">TALL 3700</td>
                    <td rowspan="2" class="th_left empty">Heat San Temperature Low Low Alarm</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">CTC1 9880</td>
                    <td class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">CTC1 9880</td>
                    <td class="empty"><br></td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >PAHH 3705</td>
                    <td class="th_left empty">2nd Pass Product Pressure High High Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 95</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">FALL 3710</td>
                    <td rowspan="2" class="th_left empty">2nd Pass Product Flow Low Low Alarm</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0.00">1.00</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="3.78" sdnum="1033;0;0.00">3.78</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >CAH 9852</td>
                    <td class="th_left empty">Loop Filter Pressure Differential High Alert</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&ge; 15</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >PAL 6640</td>
                    <td class="th_left empty">Compressed Air Pressure Low Alert</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="85" sdnum="1033;0;0">85</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PALL1 6440</td>
                    <td class="th_left empty">Compressed Air Pressure Low Low Alarm</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="75" sdnum="1033;0;0">75</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">PALL2 6440</td>
                    <td class="th_left empty">Compressed Air Pressure Low Low Alarm</td>
                    <td class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="65" sdnum="1033;0;0">65</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3615</td>
                    <td rowspan="2" class="th_left empty">Loop And Point Of Use Heat Sanitization Tank Full</td>
                    <td rowspan="2" class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="80" sdnum="1033;0;0">80</td>
                    <td class="empty" sdval="80" sdnum="1033;0;0">80</td>
                    <td rowspan="2" class="empty">80</td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td class="empty" sdval="113" sdnum="1033;0;0">113</td>
                    <td class="empty" sdval="302" sdnum="1033;0;0">302</td>
                    <td class="empty" sdval="302" sdnum="1033;0;0">302</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP2 3615</td>
                    <td rowspan="2" class="th_left empty">RO And Loop Heat San Tank Full</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="113" sdnum="1033;0;0">113</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP3 3615</td>
                    <td rowspan="2" class="th_left empty">Chemical Clean Tank Full</td>
                    <td rowspan="2" class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="80" sdnum="1033;0;0">80</td>
                    <td class="empty" sdval="80" sdnum="1033;0;0">80</td>
                    <td rowspan="2" class="empty">40</td>
                    <td class="empty">gallons</td>
                  </tr>

                  <tr>
                    <td class="empty" sdval="113" sdnum="1033;0;0">113</td>
                    <td class="empty" sdval="302" sdnum="1033;0;0">302</td>
                    <td class="empty" sdval="302" sdnum="1033;0;0">302</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                      <td class="th_center empty">SP4 3615</td>
                      <td class="th_left empty">Break Tank Gallons/Inch of WC</td>
                      <td class="empty" sdval="2" sdnum="1033;0;0"><br></td>
                      <td class="empty" sdval="30" sdnum="1033;0;0"><br></td>
                      <td class="empty" sdval="80" sdnum="1033;0;0"><br></td>
                      <td class="empty" sdval="80" sdnum="1033;0;0"><br></td>
                      <td class="empty">2.6</td>
                      <td class="empty">GPI</td>
                    </tr>
                  <tr>
                    <td class="th_center empty" >SP1 3402</td>
                    <td class="th_left empty">Heat San Cool Down Feed Water Valve % Open</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0">0</td>
                    <td class="empty" sdval="100" sdnum="1033;0;0">100</td>
                    <td class="empty" sdval="20" sdnum="1033;0;0">20</td>
                    <td class="empty">20</td>
                    <td class="empty">%</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">CTC1 9880</td>
                    <td rowspan="2" class="th_left empty">Heat San Temperature Low Setpoint</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">SP19880<br>minus 9</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">SP19880<br>minus 5</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 9880</td>
                    <td rowspan="2" class="th_left empty">Heat San Temperature Hold</td>
                    <td rowspan="2" class="empty">5</td>
                    <td class="empty" sdval="149" sdnum="1033;0;0">149</td>
                    <td class="empty" sdval="185" sdnum="1033;0;0">185</td>
                    <td class="empty" sdval="176" sdnum="1033;0;0">176</td>
                    <td rowspan="2" class="empty">80</td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td class="empty" sdval="65" sdnum="1033;0;0">65</td>
                    <td class="empty" sdval="85" sdnum="1033;0;0">85</td>
                    <td class="empty" sdval="80" sdnum="1033;0;0">80</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">SP2 9880</td>
                    <td class="th_left empty">Heat San Hold Time</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="20" sdnum="1033;0;0">20</td>
                    <td class="empty" sdval="120" sdnum="1033;0;0">120</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty"><br></td>
                    <td class="empty">min</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP3 9880</td>
                    <td rowspan="2" class="th_left empty">Heat San Cool Down Temperature</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="113" sdnum="1033;0;0">113</td>
                    <td rowspan="2" class="empty">60</td>
                    <td class="empty">&deg;F</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="45" sdnum="1033;0;0">45</td>
                    <td class="empty">&deg;C</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">SP4 9880</td>
                    <td class="th_left empty">Membrane Heat San Pressure</td>
                    <td class="empty">n/a</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty" sdval="25" sdnum="1033;0;0">25</td>
                    <td class="empty"><br></td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >SP5 9880</td>
                    <td class="th_left empty">Membrane Clean/San Pressure</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty" sdval="50" sdnum="1033;0;0">50</td>
                    <td class="empty">60</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">SP6 9880</td>
                    <td class="th_left empty">System High Recovery</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">Fixed</td>
                    <td class="empty">&gt;300=75<br>300=80<br>150=85<br>100=90<br>50=95</td>
                    <td class="empty"><br></td>
                    <td class="empty">&micro;S/cm = %</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >SP7 9880</td>
                    <td class="th_left empty">Line Rinse Pump Speed</td>
                    <td class="empty">4</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0">0</td>
                    <td class="empty" sdval="50" sdnum="1033;0;0">50</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty">25</td>
                    <td class="empty">%</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP8 9880</td>
                    <td rowspan="2" class="th_left empty">Tank Empty Level</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td rowspan="2" colspan="2">Fixed</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gallons</td>
                  </tr>
                  <tr>
                    <td class="empty" sdval="37.8" sdnum="1033;0;0.0">37.8</td>
                    <td class="empty">litres</td>
                  </tr>
                  <tr>
                      <td class="th_center empty" >SP2 9885</td>
                      <td class="th_left empty">POU Loop Heat Santization Hold Time</td>
                      <td class="empty"><br></td>
                      <td class="empty" sdval="0" sdnum="1033;0;0"><br></td>
                      <td class="empty" sdval="50" sdnum="1033;0;0"><br></td>
                      <td class="empty" sdval="10" sdnum="1033;0;0"><br></td>
                      <td class="empty"><br></td>
                      <td class="empty">min</td>
                    </tr>

                  <tr>
                    <td class="th_center empty" >SP1 3645</td>
                    <td class="th_left empty">1st Pass Pump Pressure</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="250" sdnum="1033;0;0">250</td>
                    <td class="empty" sdval="150" sdnum="1033;0;0">150</td>
                    <td class="empty">140</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >SP2 3645</td>
                    <td class="th_left empty">Loop Heat San Pressure</td>
                    <td class="empty">4</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty" sdval="99" sdnum="1033;0;0">99</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty">50</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >SP1 3675</td>
                    <td class="th_left empty">2nd Pass Pump Pressure</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="250" sdnum="1033;0;0">250</td>
                    <td class="empty" sdval="150" sdnum="1033;0;0">150</td>
                    <td class="empty">100</td>
                    <td class="empty">psig</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3655</td>
                    <td rowspan="2" class="th_left empty">1st Pass Reject Flow</td>
                    <td rowspan="2" class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="3">Factory Set</td>
                    <td rowspan="2" class="empty">30</td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td colspan="3">Factory Set</td>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3660</td>
                    <td rowspan="2" class="th_left empty">1st Pass Reject Drain Flow</td>
                    <td rowspan="2" class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="3">Factory Set</td>
                    <td rowspan="2" class="empty">2.7</td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td colspan="3">Factory Set</td>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">CFC1 3660</td>
                    <td rowspan="2" class="th_left empty">1st  Pass Reject To Drain Flush Flow</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" rowspan="2">Factory Set</td>
                    <td rowspan="2" rowspan="2" class="empty">1.25 multiply SP1<br>3660</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">CFC2 3660</td>
                    <td rowspan="2" class="th_left empty">1st  Pass Reject To Drain Bypass Flow</td>
                    <td rowspan="2" class="empty">n/a</td>
                    <td colspan="2" rowspan="2">Factory Set</td>
                    <td rowspan="2" class="empty">SP1 3710</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3690</td>
                    <td rowspan="2" class="th_left empty">2nd Pass Recycle Flow</td>
                    <td rowspan="2" class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="3">Factory Set</td>
                    <td rowspan="2" class="empty">3</td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td colspan="3">Factory Set</td>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3710</td>
                    <td rowspan="2" class="th_left empty">2nd Pass Maximum Product Flow</td>
                    <td rowspan="2" class="empty">5</td>
                    <td colspan="3">Factory Set</td>
                    <td rowspan="2" class="empty">5</td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td colspan="3">Factory Set</td>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td rowspan="2" class="th_center empty">SP1 3620</td>
                    <td rowspan="2" class="th_left empty">Loop Return Minimum Flow</td>
                    <td rowspan="2" class="empty" sdval="4" sdnum="1033;0;0">4</td>
                    <td class="empty" sdval="1" sdnum="1033;0;0">1</td>
                    <td class="empty" sdval="20" sdnum="1033;0;0">20</td>
                    <td class="empty" sdval="3" sdnum="1033;0;0">3</td>
                    <td rowspan="2" class="empty"><br></td>
                    <td class="empty">gpm</td>
                  </tr>
                  <tr>
                    <td class="empty">4</td>
                    <td class="empty" sdval="76" sdnum="1033;0;0">76</td>
                    <td class="empty" sdval="11" sdnum="1033;0;0">11</td>
                    <td class="empty">lpm</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Feed Low Pressure Auto Retry</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0">0</td>
                    <td class="empty" sdval="5" sdnum="1033;0;0">5</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0">0</td>
                    <td class="empty">5</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Feed Low Pressure Retry Delay Time</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty">15</td>
                    <td class="empty">min</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >No Tag</td>
                    <td class="th_left empty">Auto Flush Cycle Time</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty" sdval="120" sdnum="1033;0;0">120</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty">60</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >No Tag</td>
                    <td class="th_left empty">Auto Flush Duration</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="60" sdnum="1033;0;0">60</td>
                    <td class="empty" sdval="900" sdnum="1033;0;0">900</td>
                    <td class="empty" sdval="90" sdnum="1033;0;0">90</td>
                    <td class="empty">240</td>
                    <td class="empty">sec</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Loop Enabled?</td>
                    <td class="empty">n/a</td>
                    <td colspan="2" class="empty">Yes or No</td>
                    <td class="empty">Yes</td>
                    <td class="empty">Yes</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">RO Flush With Pump</td>
                    <td class="empty">4</td>
                    <td colspan="2" class="empty">Yes or No</td>
                    <td class="empty">Yes</td>
                    <td class="empty">Yes</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Cleaning Elapse Timer</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0">0</td>
                    <td class="empty" sdval="120" sdnum="1033;0;0">120</td>
                    <td class="empty" sdval="30" sdnum="1033;0;0">30</td>
                    <td class="empty">30</td>
                    <td class="empty">min</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Temperature Display Units</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">&deg;C or &deg;F</td>
                    <td class="empty">&deg;F</td>
                    <td class="empty">&deg;C</td>
                    <td class="empty">&deg;C or &deg;F</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Volume Display Units</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">Litres or gallons</td>
                    <td class="empty">gallons</td>
                    <td class="empty">Gallons</td>
                    <td class="empty">Litres or gallons</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Velocity Display Units</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">fps or mps</td>
                    <td class="empty">fps</td>
                    <td class="empty">FPS</td>
                    <td class="empty">fps or mps</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >No Tag</td>
                    <td class="th_left empty">Stop Alarm &ndash; Enable/Disable</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">Enable or Disable</td>
                    <td class="empty">Enable</td>
                    <td class="empty">Enabled</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Stop Alarm Disable Duration (Remaining &ndash; * : *)</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty" sdval="120" sdnum="1033;0;0">120</td>
                    <td class="empty" sdval="10" sdnum="1033;0;0">10</td>
                    <td class="empty">120</td>
                    <td class="empty">min</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Loop Pipe Internal Diameter</td>
                    <td class="empty">4</td>
                    <td class="empty" sdval="0" sdnum="1033;0;0.00">0.00</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0.00">2.00</td>
                    <td class="empty" sdval="0.625" sdnum="1033;0;0.000">0.625</td>
                    <td class="empty">0.68</td>
                    <td class="empty">Inch</td>
                  </tr>
                  <tr>
                    <td class="th_center empty" >No Tag</td>
                    <td class="th_left empty">15 Minute Water Sample Alert</td>
                    <td class="empty" sdval="2" sdnum="1033;0;0">2</td>
                    <td colspan="2" class="empty">Yes or  No</td>
                    <td class="empty">No</td>
                    <td class="empty">No</td>
                    <td class="empty">No Unit</td>
                  </tr>
                  <tr>
                    <td class="th_center empty">No Tag</td>
                    <td class="th_left empty">Loop Filter Option</td>
                    <td class="empty">n/a</td>
                    <td colspan="2" class="empty">Enable or Disable</td>
                    <td class="empty">Disable</td>
                    <td class="empty"><br></td>
                    <td class="empty">No Unit</td>
                  </tr>
                </table>
          </div>
      </div>
    </div>
  </section>
</section>

import { Component } from '@angular/core';
import { AppComponent } from "../app.component";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Globals } from '../globals';
import { AuthService } from '../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { OrganizationalUnitApiService } from '../API/organizationalunitapi.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// import { Ng2ImgMaxService } from 'ng2-img-max';
import { MenuSharedService } from '../Core/services/menu-shared.service';
@Component({
  selector: 'OrganizationalUnit',
  templateUrl: './OrganizationalUnit.component.html',
})
export class OrganizationalUnitComponent {
  public imagePath;
  imgURL: any;
  state: string;
  public message: string;
  onText = "Yes";
  offText = "No";

  offDisplay = "Off";
  onDisplay = "On";

  onTextLogin = "Password";
  offTextLogin = "PinId";
  onColor = "success";
  offColor = "danger";
  CountryList = [];
  TimeZonesList = [];
  StateList = [];
  AllSelected = [];
  createdBy = this.authentication.GetUserId();
  modifiedBy = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  formattedHourlyRate = '';
  formattedTechDailyHours = '';

  menuPermissionList = [
    { dbName: "Dashboard", desc: "Dashboard" },
    { dbName: "User Access", desc: "Contact" },
    { dbName: "Schedule", desc: "Schedule" },
    { dbName: "Bulletin", desc: "Messages" },
    { dbName: "Water Process", desc: "H2O Process" },
    { dbName: "Dialysis Machine", desc: "D.Machines " },
    { dbName: "Home", desc: "Home" },
    { dbName: "Other", desc: "O.Repairs" },
    { dbName: "Central Delivery", desc: "Concentrates" },
    { dbName: "Inventory", desc: "Inventory" },
    { dbName: "Tasks/Communication", desc: "Task" },
    { dbName: "Reports", desc: "Reports" },
    { dbName: "Search", desc: "Search" },
    // { dbName: "Support", desc: "Help Support" },
     // { dbName: "Master", desc: "System Admin" },
  ];


  organizationUnitForm = new UntypedFormGroup({
    organizationalUnitId: new UntypedFormControl(0),
    unitName: new UntypedFormControl('', Validators.required),
    address1: new UntypedFormControl('', Validators.required),
    address2: new UntypedFormControl(''),
    city: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    state: new UntypedFormControl('', Validators.required),
    zipcode: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', [Validators.required, Validators.pattern(Globals.PhoneNumberRegex)]),
    extension: new UntypedFormControl(''),
    tollfreenumber: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    fax: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    tollfreefax: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    othernumber: new UntypedFormControl('', Validators.pattern(Globals.PhoneNumberRegex)),
    permanentnotes: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    IsCaptchaRequired: new UntypedFormControl(false),
    IsPassword: new UntypedFormControl(true),
    IsTwoStepAuth: new UntypedFormControl(false),
    Logo: new UntypedFormControl(''),
    menuIds: new UntypedFormControl(''),
    isHomeProgram : new UntypedFormControl(''),
    TimeZone : new UntypedFormControl(''),
    techHourlyRate : new UntypedFormControl(''),
    isEfficiencyDisplay : new UntypedFormControl(''),
    techDailyHours : new UntypedFormControl('')
  });

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer
    , private orgUnitApi: OrganizationalUnitApiService, 
    // private ng2ImgMax: Ng2ImgMaxService,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    public menuSharedService : MenuSharedService
    ) { }

  executeOrganizationUnitCreateUpdate = function () {
    AppComponent.loading = true;

    if (this.organizationUnitForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }

    if (this.organizationUnitForm.controls.organizationalUnitId.value === 0
    ) {

      const modelCreate = this.prepareCreateViewModel();
      this.orgUnitApi.CreateOrgUnitDetails(modelCreate).subscribe(
        success => {

          this.organizationUnitForm.controls.organizationalUnitId.value = success.organizationalUnitId;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.organizationUnitForm.value.createdBy = this.createdBy;
      this.organizationUnitForm.value.modifiedBy = this.modifiedBy;
      const modelCreate = this.prepareCreateViewModel();
      this.orgUnitApi.UpdateOrgUnitDetails(modelCreate).subscribe(
        success => {

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          //window.location.reload();
          this.menuSharedService.change();
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };


  prepareCreateViewModel() {

    const modelCreate = <any>{};
    modelCreate.unitName = this.f.unitName.value;
    modelCreate.address1 = this.f.address1.value;
    modelCreate.address2 = this.f.address2.value;
    modelCreate.city = this.f.city.value;
    modelCreate.state = this.f.state.value;
    modelCreate.zipCode = this.f.zipcode.value;
    modelCreate.country = this.f.country.value;
    modelCreate.phone = this.f.phone.value;
    modelCreate.extension = this.f.extension.value;
    modelCreate.tollFreeNumber = this.f.tollfreenumber.value;
    modelCreate.fax = this.f.fax.value;
    modelCreate.tollFreeFax = this.f.tollfreefax.value;
    modelCreate.otherNumber = this.f.othernumber.value;
    modelCreate.permanentNotes = this.f.permanentnotes.value;
    modelCreate.createdBy = this.createdBy;
    modelCreate.modifiedBy = this.modifiedBy;
    modelCreate.organizationalUnitId = this.f.organizationalUnitId.value;
    modelCreate.IsCaptchaRequired = this.f.IsCaptchaRequired ? this.f.IsCaptchaRequired.value : false;
    modelCreate.IsPassword = this.f.IsPassword ? this.f.IsPassword.value : false;
    modelCreate.IsTwoStepAuth = this.f.IsTwoStepAuth ? this.f.IsTwoStepAuth.value : false;
    modelCreate.isHomeProgram = this.f.isHomeProgram ? this.f.isHomeProgram.value : false;
    modelCreate.Logo = this.imgURL;
    modelCreate.TimeZone = this.f.TimeZone.value;
    modelCreate.menuIds = this.f.menuIds.value;
    modelCreate.techHourlyRate = this.f.techHourlyRate.value;
    modelCreate.isEfficiencyDisplay = this.f.isEfficiencyDisplay ? this.f.isEfficiencyDisplay.value : false;

    modelCreate.techDailyHours = this.f.techDailyHours.value;
    return modelCreate;
  }

  transformhourlyCost() {
    if (this.f.techHourlyRate.value && this.f.techHourlyRate.value != 0) {
      this.formattedHourlyRate = this.currencyPipe.transform(this.currenctyToNumber(this.f.techHourlyRate.value), '$', '$', '1.2-2');
    }
    else
    {
      this.f.techHourlyRate.setValue('');
    }
  }

  transformtechDailyHours() {
    if (this.f.techDailyHours.value && this.f.techDailyHours.value != 0) {

      this.f.techDailyHours.setValue(this.decimalPipe.transform(this.f.techDailyHours.value,'1.2-2'));
    }
    else
    {
      this.f.techDailyHours.setValue('');
    }
  }



  currenctyToNumber(value) { return Number(value.toString().replace(/[^0-9.-]+/g, "")); }

  ngOnInit() {

    this.setModel();
  }

  onFileSelected(event) {
    AppComponent.ShowLoader();
    var files = event.target.files;
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      AppComponent.addAlert("Only images are supported.", 'error');
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;


    let image = event.target.files[0];
    // this.ng2ImgMax.resizeImage(image, 1000, 100).subscribe(
    //   result => {
    //     // this.uploadedImage = result;
    //     var reader = new FileReader();
    //     reader.readAsDataURL(result);
    //     reader.onload = (_event) => {
    //       this.imgURL = reader.result;
    //       AppComponent.HideLoader();
    //     }
    //   },
    //   error => {
    //     // console.log('😢 Oh no!', error);
    //     AppComponent.HideLoader();
    //   }
    // );

  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //  console.log(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error);
    };
  }

  formatPhone(phone) {
    let val = this.f[phone].value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    this.f[phone].setValue(val);
  }

  clearImage() {
    this.imgURL = "";
    document.getElementById('ng2Fileuploader')['value'] = '';
  }

  setModel() {

    AppComponent.ShowLoader();
    this.fillCountryList();
    this.fillTimeZones();
    const orgUnit = { Id: 1 };
    this.orgUnitApi.GetOrgUnitDetails(orgUnit).subscribe(
      success => {
        if (success) {


          this.organizationUnitForm.setValue({
            unitName: success.unitname,
            address1: success.address1,
            address2: success.address2,
            city: success.city,
            state: success.state,
            zipcode: success.zipcode,
            country: success.country,
            phone: success.phone,
            extension: success.extension,
            tollfreenumber: success.tollfreenumber,
            fax: success.fax,
            tollfreefax: success.tollfreefax,
            othernumber: success.othernumber,
            permanentnotes: success.permanentnotes,
            createdBy: this.createdBy,
            modifiedBy: this.modifiedBy,
            organizationalUnitId: success.organizationalunitid,
            IsCaptchaRequired: success.isCaptchaRequired ? success.isCaptchaRequired : false,
            IsPassword: success.isPassword ? success.isPassword : false,
            IsTwoStepAuth: success.isTwoStepAuth ? success.isTwoStepAuth : false,
            isHomeProgram: success.isHomeProgram ? success.isHomeProgram : false,
            Logo: success.logo ? success.logo : '',
            // TimeZone : success.timeZone ? success.timeZone : "Eastern Standard Time",
            TimeZone : success.timeZone ? success.timeZone : '',
            menuIds: success.menuIds,
            techHourlyRate: this.currencyPipe.transform(this.currenctyToNumber(success.techHourlyRate), '$', '$', '1.2-2') ,
            isEfficiencyDisplay: success.userEfficiencyDisplay ? success.userEfficiencyDisplay : false,
            techDailyHours: success.technicalDailyHours
          });


          this.fillCountryStateList(this.f.country.value);
          this.state = success.state;
          this.imgURL = success.logo;

          if (this.menuPermissionList.length == success.menuIds.length) {
            this.AllSelected = ['all'];
          }
        }


        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
    this.fillCountryStateList(this.f.country.value);

  }
  fillCountryList() {
    this.orgUnitApi.FillCountry().subscribe(list => (this.CountryList = list));
  }

  fillTimeZones() {
    this.orgUnitApi.FillTimeZones().subscribe(list => (this.TimeZonesList = list));
  }

  fillCountryStateList(CountryId) {

    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.orgUnitApi
        .FillState(CountryIds)
        .subscribe(list => (this.StateList = list));
    } else {
      this.StateList = [];

    }
  }

  CountryStateDropDownChanged() {
    this.fillCountryStateList(this.f.country.value);
  }


  get f() {
    return this.organizationUnitForm.controls;
  }

  onChange(e) {

  }

  CheckUnCheckMenu(item){
    if (this.menuPermissionList.length == this.f.menuIds.value.length) {
      this.AllSelected = ['all'];
    }
    else{
      this.AllSelected = [];
    }
  }

  SetAllButtons(args) {

    if (args.source.checked == true) {
      let menuIdList = [];
      for (let childObj in this.menuPermissionList) {
        menuIdList.push(this.menuPermissionList[childObj].dbName);
      }
      this.f.menuIds.setValue(menuIdList);
    }
    else {
      this.f.menuIds.reset();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanComponentDeactivate } from 'src/app/Core/Shared/CanComponentDeactivate';
import { ScrollHelper } from 'src/app/Core/Helper/ScrollHelper';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { AuthService } from 'src/app/API/auth.service';
import { AppComponent } from 'src/app/app.component';
import { Globals, actionType, attachmentFormModules } from 'src/app/globals';
import { NotesComponent } from 'src/app/Core/Shared/NotesComponent/NotesComponent';
import { SingleAttachmentComponent } from 'src/app/Core/Shared/SingleAttachmentComponent/SingleAttachmentComponent';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { ContactApiService } from 'src/app/API/contactapi.service';
import { ScheduleColorApiService } from 'src/app/API/schColorapi.service';

@Component({
  selector: 'ScheduleColorDetail',
  templateUrl: './ScheduleColorDetail.component.html'
})

export class ScheduleColorDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  isValidFormSubmitted = null;

  public data = [{text: 'Select',    value: ''  },
    { text: 'Vivid Tangerine' , value: '#ff9478'}
  , { text: 'New York Pink' , value: '#e08283'}
  , { text: 'Sunglo' , value: '#e26a6a'}
  , { text: 'Soft Red' , value: '#ec644b'}];

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  @ViewChild(AttachmentComponent, { static: true })
  attachmentList: AttachmentComponent;

  ListURL = 'Admin/ScheduleColorSearch';
  loading = true;
  today = new Date();
  blnshowSave = true;
  blnshowDelete = false;
  title = "Schedule Color Detail";
  DivisionList = [];


  ScheduleColorForm = new UntypedFormGroup({
    scheduleColorId: new UntypedFormControl(''),
    symbol: new UntypedFormControl('', Validators.required),
    color: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    createdOn: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    isDelete: new UntypedFormControl(''),
  });

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.ScheduleColorForm.dirty ||
        (this.notes !== undefined && this.notes.Note !== '' ) || this.attachmentList.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private sColorAPI: ScheduleColorApiService,
    private contactApi: ContactApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService
  ) { }

  ngOnInit() {
    AppComponent.ShowLoader();

    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.today = new Date(currentDate);

      const test = this.route.queryParams.subscribe(params => {
        this.f.scheduleColorId.setValue(params['id'] || '0');
        if (this.f.scheduleColorId.value !== '0') {
          this.setModel();
        }
      });

      this.SetScheduleColorrights();

      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();

    if(this.notes){
      this.notes.Note = '';
      this.notes.showHours = false;
    }

    if (this.attachmentList) {
      this.attachmentList.DocId = this.f.scheduleColorId.value;
      this.attachmentList.moduleName =
        attachmentFormModules[attachmentFormModules.scheduleColor];
      this.attachmentList.UploadBy = this.currentUserId;
    }

  }

  get f() {
    return this.ScheduleColorForm.controls;
  }


  setModel() {
    AppComponent.ShowLoader();
    const ScheduleColorCreate = { Id: this.f.scheduleColorId.value };

    this.sColorAPI.GetScheduleColor(ScheduleColorCreate).subscribe(
      success => {

        if(success){

          this.f.scheduleColorId.setValue(success.scheduleColorId);
          this.f.description.setValue(success.description);
        this.f.symbol.setValue(success.symbol);
        this.f.color.setValue(success.color);
        this.f.createdOn.setValue(success.createdon);
        this.f.createdBy.setValue(success.createdby);
        this.f.isDelete.setValue(success.isdelete);



        if(this.notes){
          this.notes.Note = '';
          this.notes.showHours = false;
          this.sColorAPI.GetScheduleColorNotes(ScheduleColorCreate).subscribe(list => {
            this.notes.NoteList = list;
          });
        }

        if(this.attachmentList)
        {
          this.attachmentList.clearQueue();
          this.sColorAPI
          .GetScheduleColorAttachments(ScheduleColorCreate)
          .subscribe(list => {
            this.attachmentList.PrevAttachments = list;
          });
        }

        AppComponent.HideLoader();
        }
        else{
          AppComponent.HideLoader();
        }
      },
      error => {

        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  executeScheduleColorCreateUpdate = function (Source) {
    AppComponent.loading = true;
    this.isValidFormSubmitted = false;

    if (this.ScheduleColorForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }

    this.isValidFormSubmitted = true;

    const ScheduleColorCreate = this.prepareCreateViewModel();
    if (ScheduleColorCreate.scheduleColorId &&  ScheduleColorCreate.scheduleColorId === '0') {
      this.sColorAPI.CreateScheduleColor(ScheduleColorCreate).subscribe(
        success => {
          console.log(success);
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else{
            this.f.scheduleColorId.setValue(success.scheduleColorId);
            this.setModel();
          }
        },
        error => {

          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.sColorAPI.UpdateScheduleColor(ScheduleColorCreate).subscribe(
        success => {
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else{
            this.setModel();
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const ScheduleColorCreate = <any>{};
    ScheduleColorCreate.scheduleColorId = this.f.scheduleColorId.value;
    ScheduleColorCreate.symbol = this.f.symbol.value;
    ScheduleColorCreate.color = this.f.color.value;
    ScheduleColorCreate.description = this.f.description.value;
    ScheduleColorCreate.isDelete = this.f.isDelete.value;
    ScheduleColorCreate.createdOn = this.f.createdOn.value;
    ScheduleColorCreate.createdBy = this.f.createdBy.value ? this.f.createdBy.value : this.currentUserId;
    ScheduleColorCreate.modifiedBy = this.currentUserId;


    if(this.notes)
      ScheduleColorCreate.notes = this.notes.Note;
    if(this.attachmentList)
      ScheduleColorCreate.attachmentDetails = this.attachmentList.GenerateAttachmentList();

    return ScheduleColorCreate;
  }

  setColor(event){
    this.f.color.setValue(event);
  }

  getColor(){
    return this.f.color.value.toString();
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const ScheduleColorObj = { Id: this.f.scheduleColorId.value };
        this.sColorAPI.DeleteScheduleColor(ScheduleColorObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Schedule Color deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }

  SetScheduleColorrights() {
  //   this.auth.GetPageRights('Schedule Color Form').subscribe(list => {
  //     if (this.f.scheduleColorId.value !== '0') {
  //       this.blnshowSave = list[actionType.edit] === '1';
  //       // this.blnshowDelete = list[actionType.delete] === '1';
  //     } else {
  //       this.blnshowSave = list[actionType.create] === '1';
  //       // this.blnshowDelete = false;
  //     }
  //   });

  //   if (this.userScheduleColor == 'Administrator' && this.f.scheduleColorId.value != '0') {
  //     this.blnshowDelete = true;
  //   }
   }
}

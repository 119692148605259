
import { Component, ViewChild } from '@angular/core';
import { DMTransferService } from '../../API/dmtapi.service';
import { TableHeadsService } from '../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../Core/pageheader.component';
import { AppComponent } from '../../app.component';
import { Globals } from '../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { InvApiService } from '../../API/invapi.service';
import { DMApiService } from 'src/app/API/dmapi.service';

@Component({
  selector: 'DMTransferSearch',
  templateUrl: './DMTransferSearch.component.html',

})
export class DMTransferSearchComponent {

  TransferTypes = [
    { value: '', text: 'Select' },
    { value: 'Transfer', text: 'Transfer' },
    { value: 'Exchange', text: 'Exchange' }
  ];

  TransferDetails = [];
  Users=[];

  constructor(private dmtApi: DMTransferService, private commonapi: CommonApiService, private invapi: InvApiService,
    private dmapi: DMApiService,
    private router: Router, private dataGridService: TableHeadsService) {
    this.page.pageNumber = 0;
    this.page.size = 25;

  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  blnDirtySearch: boolean;
  loading = true;
  tableHeaders;
  page = new Page();
  rows;
  AddUrl = "/DMTransfer/DMTransferDetail";
  workOrderRefNo = "";
  currentPageNumber = 1;
  sortExpression = "transferdate";
  sortDirection = "DESC";
  pageSize = 25;
  ShowSearch = false;
  title = "Transfers";
  searchText = "Search Records"
  defaultSort;



  //Search Options //
  transferDate;
  equipmentId = '';
  divisionTo = '';
  divisionFrom = '';
  categoryId = '';
  transferType = '';
  transferId = '';
  exchEquipmentId = '';
  createdBy = '';

  DivisionList = [];
  EquipmentList = [];
  CategoryList = [];

  ngOnInit() {
    AppComponent.loading = true;
    this.dataGridService.clearHeader();
    this.dataGridService.addHeader("#", "transferDate", "4");
    this.dataGridService.addHeader("Transfer Id", "transferId", "10");
    this.dataGridService.addHeader("Transfer Date", "transferDate", "10");
    this.dataGridService.addHeader("Type", "transferType", "10");
    this.dataGridService.addHeader("Transfer To", "divisionto", "10");
    this.dataGridService.addHeader("Transfer From", "divisionfrom", "10");
    this.dataGridService.addHeader("Category", "categoryinname", "10");
    this.dataGridService.addHeader("Equipment In", "equipmentName", "10");
    this.dataGridService.addHeader("Equipment Out", "exchEquipmentName", "10");
    this.dataGridService.addHeader("User", "createdByName", "10");
    this.tableHeaders = this.dataGridService.setTableHeaders();
    this.defaultSort = this.dataGridService.setDefaultSort("Transfer Date", true);
    this.CheckDirtySearch();
    this.fillDropDowns();

  }

  onSearchShowChange(value) {
    this.ShowSearch = value;
  }

  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: 'dd/mm/yyyy',
    closeSelectorOnDateSelect: false,
    disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
  };

  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  changeSorting(column) {
    if (column == "#") {
      return;
    }
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executeTransferSearch();
  };

  setSortIndicator = function (column) {
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
  };

  executeTransferSearch() {
    AppComponent.ShowLoader()
    this.pageheader.isSearchOpen = false;
    this.pageheader.searchText = "Search Records";
    this.ShowSearch = false;
    this.searchText = "Search Records";
    this.CheckDirtySearch();

    var TransferSearch = this.prepareSearch();

    this.dmtApi.GetDMTransferSearchDetails(TransferSearch).subscribe(pagedData => {
      this.page.totalElements = pagedData.totalRows;
      this.page.totalPages = pagedData.totalPages;
      this.rows = pagedData.dmTransferDetails;
      AppComponent.HideLoader()
    },
      error => {
        AppComponent.addAlert(error.message, "error");
        AppComponent.HideLoader();
      }
    );
  }

  prepareSearch() {
    var TransferSearch = <any>{};

    if (this.transferDate != undefined && this.transferDate != "") {
      TransferSearch.transferDate = Globals.GetAPIDate(this.transferDate.jsdate);
    }

    if (this.divisionTo !== undefined && this.divisionTo !== '') {
      TransferSearch.divisionTo = this.divisionTo;
    }

    if (this.divisionFrom !== undefined && this.divisionFrom !== '') {
      TransferSearch.divisionFrom = this.divisionFrom;
    }

    if (this.equipmentId !== undefined && this.equipmentId !== '') {
      TransferSearch.equipmentId = this.equipmentId;
    }

    if (this.exchEquipmentId !== undefined && this.exchEquipmentId !== '') {
      TransferSearch.exchEquipmentId = this.exchEquipmentId;
    }

    if (this.categoryId !== undefined && this.categoryId !== '') {
      TransferSearch.categoryId = this.categoryId;
    }

    if (this.createdBy !== undefined && this.createdBy !== '') {
      TransferSearch.createdBy = this.createdBy;
    }

    TransferSearch.transferId = this.transferId;
    TransferSearch.transferType = this.transferType;


    TransferSearch.currentPageNumber = this.currentPageNumber;
    TransferSearch.sortExpression = this.sortExpression;
    TransferSearch.sortDirection = this.sortDirection;
    TransferSearch.pageSize = this.pageSize;
    return TransferSearch;

  }

  setPage() {
    this.currentPageNumber = this.page.pageNumber;
    this.executeTransferSearch();
  }

  _onRowClick(row) {
    this.router.navigate(['DMTransfer/DMTransferDetail'], { queryParams: { id: row } });
  }

  //Search & Select//

  fillDropDowns() {
    this.fillDivisions();
    this.fillEquipments();
    this.fillCategories();
    this.fillUser();
  }


  fillUser()
  {
    this.dmapi.GetUsersDD().subscribe(list => this.Users=list);
  }
  fillDivisions() {
    this.commonapi.FillDivision().subscribe(list => this.DivisionList = list.filter(
      x => x.text !== 'Home Program'
    )
    );
  }

  fillEquipments() {
    const obj = {
      DivisionId: 0,
      CategoryName: '',
      Type: 'EQUIPMENT',
    };
    this.dmtApi.FillEquipmentsByCategory(obj).subscribe(list => {
        this.EquipmentList = list;
    });
  }

  fillCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      this.CategoryList = list;
    });
  }


  executeTransferSearchClear() {
    this.equipmentId = '';
    this.exchEquipmentId = '';
    this.divisionFrom = '';
    this.divisionTo = '';
    this.transferDate = '';
    this.transferType = '';
    this.transferId = '';
    this.createdBy = '';
    this.categoryId = '';

    this.executeTransferSearch();
  }

  CheckDirtySearch() {
    this.blnDirtySearch = false;

    if (
      (this.transferDate != null && this.transferDate != "")
      || this.equipmentId != ''
      || this.exchEquipmentId != ''
      || this.divisionFrom != ''
      || this.divisionTo != ''
      || this.transferType != ''
      || this.transferId != ''
      || this.createdBy != ''
      || this.categoryId != ''
    ) {
      this.blnDirtySearch = true;
    }
    return this.blnDirtySearch;
  }
  //Search & Select //
}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

import { Component, ViewChild } from '@angular/core';
import { AppComponent } from "../../app.component";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Globals, actionType } from '../../globals';
import { AuthService } from '../../API/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgForm, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { DivisionApiService } from '../../API/divisionapi.service';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';

import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { map, groupBy } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { CustomValidationStationGroup } from '../../Validator/CustomValidationStationGroup';
import { debug } from 'util';
import { CommonApiService } from 'src/app/API/commonapi.service.';


@Component({
  selector: 'DivisionDetail',
  templateUrl: './DivisionDetail.component.html',
  styleUrls: ['./DivisionDetail.css'],

})
export class DivisionDetailComponent {

  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;

  dialogRef: MatDialogRef<ConfirmationDialog>;


  state: string;
  stationsCount: number = 25;
  blnshowDelete = false;
  blnshowSave = false;
  blnshowEdit = true;
  ListURL = "/Admin/DivisionSearch";
  public message: string;
  lockText = "Lock";
  unlockText = "Unlock";
  onText = "Yes";
  offText = "No";
  onColor = "success";
  offColor = "danger";
  lockStations = ['Lock'];
  lockValues = [
    { name: 'Lock' },
    { name: 'Unlock' }
  ]
  CountryList = [];
  StateList = [];
  BacterialLevelList = [];
  createdBy = this.authentication.GetUserId();
  modifiedBy = this.authentication.GetUserId();
  private scrollHelper: ScrollHelper = new ScrollHelper();
  items: UntypedFormArray;
  stationItems: UntypedFormArray;
  formSubmitted: boolean = false;
  validateForm: boolean = false;
  Math: any;
  homeProgram = false;
  opt = false;
  userDesignation = this.authentication.GetDesignationName();


  public data = [{
    text: 'Select',
    value: ''
  }, {
    text: 'Monza',
    value: '#cf000f'
  }, {
    text: 'RebeccaPurple',
    value: '#663399'
  }, {
    text: 'Kournikova',
    value: '#fad859'
  }, {
    text: 'Hoki',
    value: '#67809f'
  }, {
    text: 'Salem',
    value: '#1e824c'
  }, {
    text: 'Sherpa Blue',
    value: '#013243'
  }, {
    text: 'Spray',
    value: '#81cfe0'
  }];

  divisionForm = new UntypedFormGroup({
    divisionId: new UntypedFormControl(0),
    divisionName: new UntypedFormControl('', Validators.required),
    address1: new UntypedFormControl('', Validators.required),
    address2: new UntypedFormControl(''),
    city: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required),
    state: new UntypedFormControl('', Validators.required),
    zipcode: new UntypedFormControl('', Validators.required),
    phone: new UntypedFormControl('', Validators.required),
    phoneExtension: new UntypedFormControl(''),
    tollFreeNumber: new UntypedFormControl(''),
    fax: new UntypedFormControl(''),
    tollFreeFax: new UntypedFormControl(''),
    otherNumber: new UntypedFormControl(''),
    permanentNotes: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(''),
    modifiedBy: new UntypedFormControl(''),
    costcenter: new UntypedFormControl('', Validators.required),
    h2oDaily: new UntypedFormControl(false),
    isCentralAcid: new UntypedFormControl(false),
    bacteriaLevel: new UntypedFormControl(''),
    color: new UntypedFormControl(''),
    containers: new UntypedFormArray([]),
    stations: new UntypedFormArray([]),
    // stations: new FormArray([], CustomValidationStationGroup("")),
    defaultStation: new UntypedFormControl('')
  });

  constructor(private authentication: AuthService, public globals: Globals, public sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder, private divisionApi: DivisionApiService, private route: ActivatedRoute,
    public dialog: MatDialog,private commonapi : CommonApiService,
    private router: Router) {
    this.Math = Math;
  }


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {

    if (
      ((this.divisionForm.dirty ||
        this.notes.Note !== '') && (this.validateForm == false))
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
        , panelClass: 'grApplication'
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          return res;
        })
      );
    } else { return true; }
  }

  SetPageRights() {
    this.authentication.GetPageRights('Contact').subscribe(list => {

      if (this.f.divisionId.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        //this.blnshowDelete = list[actionType.delete] === '1';
        this.blnshowEdit = true;

      } else {
        this.blnshowSave = list[actionType.create] === '1';
        //this.blnshowDelete = false;
        this.blnshowEdit = false;

      }
    });

    if (this.userDesignation == 'Administrator' && this.f.divisionId.value != '0') {
      this.blnshowDelete = true;
    }
  }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const divisionObj = { Id: this.f.divisionId.value };
        this.divisionApi.DeleteDivision(divisionObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'Division detail deleted successfully!',
              'success'
            );
            this.router.navigate(['Admin/DivisionSearch']);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }
  executeDivisionCreateUpdate = function (Source = "") {
    AppComponent.loading = true;
    this.formSubmitted = true;

    if (this.divisionForm.invalid) {
      if (this.divisionForm.controls.stations && this.divisionForm.controls.stations.errors && this.divisionForm.controls.stations.errors.duplicate) {
        AppComponent.addAlert(this.divisionForm.controls.stations.errors.duplicate, 'error');
      }
      else
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');

      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      this.divisionForm.markAsDirty();
      AppComponent.ScrollDown();
      return;
    }

    if (this.divisionForm.controls.isCentralAcid.value
      &&
      this.divisionForm.controls.containers.value.filter((obj) => obj.containerName !== '').length <= 0) {
      AppComponent.addAlert("Please Enter atleast Container Name", 'error');
      AppComponent.loading = false;
      this.scrollHelper.scrollToFirst('container');
      AppComponent.ScrollDown();
      return;
    }

    if (this.divisionForm.controls.stations.value.filter((obj) => obj.dialysisStationName === '').length > 0) {
      this.scrollHelper.scrollToFirst('logo');
      AppComponent.addAlert("Please Specify All Stations Name", 'error');

      AppComponent.loading = false;
      AppComponent.ScrollDown();
      return;
    }

    this.validateForm = true;
    if (this.divisionForm.controls.divisionId.value === '0'
    ) {
      const modelCreate = this.prepareCreateViewModel();
      this.divisionApi.CreateDivisionDetails(modelCreate).subscribe(
        success => {
          this.notes.Note = '';
          this.divisionForm.controls.divisionId.value = success.divisionId;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.fillNotes();
            this.fetchStations(this.f.divisionId.value);
            this.router.navigate(['Admin/Division'], { queryParams: { id: success.divisionId } });
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      this.divisionForm.value.createdBy = this.createdBy;
      this.divisionForm.value.modifiedBy = this.modifiedBy;
      const modelCreate = this.prepareCreateViewModel();
      this.divisionApi.UpdateDivisionDetails(modelCreate).subscribe(
        success => {
          this.notes.Note = '';
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          }
          else {
            this.fillNotes();
            this.fetchStations(this.f.divisionId.value);
            this.router.navigate(['Admin/Division'], { queryParams: { id: success.divisionId } });
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    }
  };


  prepareCreateViewModel() {

    const modelCreate = <any>{};
    modelCreate.divisionName = this.f.divisionName.value;
    modelCreate.address = this.f.address1.value;
    modelCreate.address2 = this.f.address2.value;
    modelCreate.city = this.f.city.value;
    modelCreate.state = this.f.state.value;
    modelCreate.zipcode = this.f.zipcode.value;
    modelCreate.country = this.f.country.value;
    modelCreate.phone = this.f.phone.value;
    modelCreate.phoneExtension = this.f.phoneExtension.value;
    modelCreate.tollFreeNumber = this.f.tollFreeNumber.value;
    modelCreate.fax = this.f.fax.value;
    modelCreate.tollFreeFax = this.f.tollFreeFax.value;
    modelCreate.otherNumber = this.f.otherNumber.value;
    modelCreate.permanentNotes = this.f.permanentNotes.value;
    modelCreate.createdBy = this.createdBy;
    modelCreate.modifiedBy = this.modifiedBy;

    modelCreate.divisionId = this.f.divisionId.value;

    modelCreate.costcenter = this.f.costcenter.value;
    modelCreate.bacteriaLevel = this.f.bacteriaLevel.value;
    modelCreate.color = this.f.color.value;
    modelCreate.defaultStation = this.f.defaultStation.value;

    modelCreate.h2oDaily = this.f.h2oDaily ? this.f.h2oDaily.value : false;
    modelCreate.isCentralAcid = this.f.isCentralAcid ? this.f.isCentralAcid.value : false;

    modelCreate.containers = this.f.containers.value;
    //modelCreate.stations = this.f.stations.value;
    modelCreate.stations = this.getStationsWithDirtyField();

    modelCreate.permanentNotes = this.notes.Note;
    return modelCreate;
  }


  ngOnInit() {
    AppComponent.ShowLoader();

    this.notes.showHours = false;
    this.notes.Note = '';
    const test = this.route.queryParams.subscribe(params => {
      this.f.divisionId.setValue(params['id'] || '0');
      this.SetPageRights();
      if (this.f.divisionId.value !== '0') {
        this.setModel();
      } else {
        this.fillCountryList();
        this.addContainers();
        this.addStations();
        this.lockStations = ['Lock'];

        // this.fetchStations();

        this.fillBacterialLevels();
        AppComponent.HideLoader();
      }
      // this.scrollHelper.scrollToFirst('logo');
    });

    this.f.defaultStation.disable();
    this.enableDisableStations('Lock');
    // AppComponent.HideLoader();
  }


  formatPhone(phone) {
    let val = this.f[phone].value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    this.f[phone].setValue(val);
  }


  setModel() {
    AppComponent.ShowLoader();
    this.fillCountryList();
    this.fillBacterialLevels();
    const division = { Id: this.f.divisionId.value };
    this.divisionApi.GetDivisionByID(division).subscribe(
      success => {
        if (success) {

          setTimeout(() => {
            this.fillCountryStateList(success.country);
          }, 500);

          this.f.divisionName.setValue(success.divisionName);
          this.homeProgram = success.isHomeProgram;
          if (this.isHomeProgram()) {
            this.f.divisionName.disable();
          }
          else {
            this.f.divisionName.enable();
          }
          this.f.costcenter.disable();
          this.f.address1.setValue(success.address1);
          this.f.address2.setValue(success.address2);
          this.f.city.setValue(success.city);
          this.f.state.setValue(success.state);
          this.f.zipcode.setValue(success.zipcode);
          this.f.country.setValue(success.country);
          this.f.phone.setValue(success.phone);
          this.f.phoneExtension.setValue(success.phoneExtension);
          this.f.tollFreeNumber.setValue(success.tollFreeNumber);
          this.f.fax.setValue(success.fax);
          this.f.tollFreeFax.setValue(success.tollFreeFax);
          this.f.tollFreeNumber.setValue(success.tollFreeNumber);
          this.f.otherNumber.setValue(success.otherNumber);
          this.f.permanentNotes.setValue(success.permanentNotes);
          this.f.createdBy.setValue(success.createdBy);
          this.f.modifiedBy.setValue(success.modifiedBy);
          this.f.divisionId.setValue(success.divisionId);
          this.f.costcenter.setValue(success.costcenter);
          this.f.color.setValue(success.color);
          this.f.defaultStation.setValue(success.defaultStation);


          if (success.bacteriaLevel)
            this.f.bacteriaLevel.setValue(success.bacteriaLevel);

          if (success.h2oDaily)
            this.f.h2oDaily.setValue(success.h2oDaily);

          if (success.isCentralAcid)
            this.f.isCentralAcid.setValue(success.isCentralAcid);


          this.fillNotes();
          //this.fillCountryStateList(this.f.country.value);
          this.addContainers();
          // this.addStations();
          this.fetchContainers(success.divisionId);
          this.fetchStations(success.divisionId);
          this.state = success.state;

          if(Globals.Website == 'GR') {
              this.f.stations.setValidators([CustomValidationStationGroup(success.divisionName)]);
              this.f.stations.updateValueAndValidity();
          }

        }

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );




  }
  fillCountryList() {
    this.divisionApi.FillCountry().subscribe(list => (this.CountryList = list));
  }

  fillBacterialLevels() {
    // for (var i = 11; i <= 40; i++) {
    //   this.BacterialLevelList.push({ text: i, value: i })
    // }

    for (var i = 11; i <= 30; i++) {
      this.BacterialLevelList.push({ text: i, value: i })
    }
  }

  fillCountryStateList(CountryId) {

    const filterCountry = this.CountryList.filter(
      (item, index) => item.text === CountryId
    );
    if (filterCountry.length > 0) {
      const CountryIds = { Id: filterCountry[0].value };
      this.divisionApi
        .FillState(CountryIds)
        .subscribe(list => (this.StateList = list));
    } else {
      this.StateList = [];

    }
  }

  fillNotes() {
    this.divisionApi.GetDivisionNotes({ Id: this.f.divisionId.value }).subscribe(list => {
      this.notes.NoteList = list;
    });
  }
  CountryStateDropDownChanged() {
    this.fillCountryStateList(this.f.country.value);
  }


  get f() {
    return this.divisionForm.controls;
  }

  onChange(e) {

  }

  //Containers Section
  addContainers(): void {
    this.items = this.divisionForm.get('containers') as UntypedFormArray;
    while (this.items.length !== 0) {
      this.items.removeAt(0)
    }

    for (var i = 1; i <= 5; i++) {
      this.items.push(this.formBuilder.group({
        containerId: 0,
        divisionId: this.f.divisionId.value,
        containerName: '',
        createdBy: this.createdBy,
        createdOn: '',
        modifiedBy: this.createdBy,
        modifiedOn: new UntypedFormControl('')
      }))
    };
  }



  fetchContainers(division) {
    AppComponent.ShowLoader();
    var divisionId = { Id: division };

    this.divisionApi.GetContainerDetailsByDivisionId(divisionId).subscribe(
      success => {
        var containerData = success;
        this.items = this.divisionForm.get('containers') as UntypedFormArray;
        if (containerData && containerData.length > 0) {
          containerData.forEach(function (container, newKey) {

            this.items.controls[newKey].controls.containerId.setValue(container.containerId);
            this.items.controls[newKey].controls.divisionId.setValue(container.divisionId);
            this.items.controls[newKey].controls.containerName.setValue(container.containerName);
            this.items.controls[newKey].controls.createdBy.setValue(container.createdBy);
            this.items.controls[newKey].controls.modifiedOn.setValue(container.modifiedOn);
          }, this);
        }
        AppComponent.HideLoader();

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  //Containers End

  //Stations Start

  onStationCountChange() {
    let oldStationData = Object.assign([], this.divisionForm.get('stations')['controls']);
    if (this.stationsCount < oldStationData.length) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });

      this.dialogRef.componentInstance.confirmMessage =
        'Stations specified is less than current stations, some of your stations will be lost.';
      return this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateStations();
        }
        else {
          this.stationsCount = oldStationData.length;
        }
      });
    }
    else {
      this.updateStations();
    }
  }

  updateStations() {
    // if (this.stationsCount <= 99 && this.stationsCount > 0) {
    let oldStationData = Object.assign([], this.divisionForm.get('stations')['controls']);
    this.addStations();

    this.stationItems = this.divisionForm.get('stations') as UntypedFormArray;

    if (oldStationData && oldStationData.length > 0) {
      oldStationData.forEach(function (station, newKey) {
        if (this.stationItems.controls[newKey]) {
          this.stationItems.controls[newKey].controls.dialysisUnitId.setValue(station.controls.dialysisUnitId.value);
          this.stationItems.controls[newKey].controls.divisionId.setValue(station.controls.divisionId.value);
          this.stationItems.controls[newKey].controls.dialysisStationName.setValue(station.controls.dialysisStationName.value);
          this.stationItems.controls[newKey].controls.createdBy.setValue(station.controls.createdBy.value);
          this.stationItems.controls[newKey].controls.modifiedBy.setValue(station.controls.modifiedBy.value);
          this.stationItems.controls[newKey].controls.createdOn.setValue(station.controls.createdOn.value);
          this.stationItems.controls[newKey].controls.modifiedOn.setValue(station.controls.modifiedOn.value);
          this.stationItems.controls[newKey].controls.dmEquipmentId.setValue(station.controls.dmEquipmentId.value);
          this.stationItems.controls[newKey].controls.roEquipmenId.setValue(station.controls.roEquipmenId.value);
          this.stationItems.controls[newKey].controls.isDirty.setValue(station.dirty);
        }
      }, this);
    }
    // }
  }

  addStations(): void {
    this.stationItems = this.divisionForm.get('stations') as UntypedFormArray;
    while (this.stationItems.length !== 0) {
      this.stationItems.removeAt(0);
    }

    for (var i = 1; i <= this.stationsCount; i++) {
      this.stationItems.push(this.formBuilder.group({
        dialysisUnitId: 0,
        divisionId: this.f.divisionId.value,
        dialysisStationName: '',
        createdBy: this.createdBy,
        createdOn: '',
        modifiedBy: this.createdBy,
        modifiedOn: '',
        isDirty: false,
        dmEquipmentId: '',
        roEquipmenId: ''
      }))
    };
  }

  addOneStation() {
    this.stationItems = this.divisionForm.get('stations') as UntypedFormArray;
    this.stationItems.push(this.formBuilder.group({
      dialysisUnitId: 0,
      divisionId: this.f.divisionId.value,
      dialysisStationName: '',
      createdBy: this.createdBy,
      createdOn: '',
      modifiedBy: this.createdBy,
      modifiedOn: '',
      isDirty: false,
      dmEquipmentId: '',
      roEquipmenId: ''
    }));

    this.stationsCount = this.stationItems['controls'].length;
  }

  fetchStations(division) {
    AppComponent.ShowLoader();

    var divisionId = { Id: division };

    this.divisionApi.GetStationsDetailsByDivisionId(divisionId).subscribe(
      success => {
        var stationData = success;
        this.stationsCount = stationData.length;

        if (stationData && stationData.length > 0) {

          this.stationItems = this.divisionForm.get('stations') as UntypedFormArray;

          while (this.stationItems.length !== 0) {
            this.stationItems.removeAt(0);
          }

          stationData.forEach(function (station, newKey) {

            if (this.stationItems.controls[newKey]) {
              this.stationItems.controls[newKey].controls.dialysisUnitId.setValue(station.dialysisunitid);
              this.stationItems.controls[newKey].controls.divisionId.setValue(station.divisionid);
              this.stationItems.controls[newKey].controls.dialysisStationName.setValue(station.dialysisstationname);
              this.stationItems.controls[newKey].controls.createdBy.setValue(station.createdby);
              this.stationItems.controls[newKey].controls.modifiedBy.setValue(station.modifiedby);
              this.stationItems.controls[newKey].controls.createdOn.setValue(station.createdon);
              this.stationItems.controls[newKey].controls.modifiedOn.setValue(station.modifiedon);
              this.stationItems.controls[newKey].controls.dmEquipmentId.setValue(station.dmEquipmentId);
              this.stationItems.controls[newKey].controls.roEquipmentId.setValue(station.roEquipmentId);
            }
            else {
              this.stationItems.push(this.formBuilder.group({
                dialysisUnitId: station.dialysisunitid,
                divisionId: station.divisionid,
                dialysisStationName: station.dialysisstationname,
                createdBy: station.createdby,
                createdOn: station.createdon,
                modifiedBy: this.createdBy,
                modifiedOn: '',
                isDirty: false,
                dmEquipmentId: '',
                roEquipmenId: ''
              }));
            }
          }, this);
        }
        this.opt = false;
        AppComponent.HideLoader();

      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  getStationCount() {
    return Math.ceil(this.divisionForm.get('stations')['controls'].length / 4);
  }

  getStationsArray() {
    return new Array(Math.ceil(this.divisionForm.get('stations')['controls'].length / 5));
  }

  getAllStationsLength() {
    return this.divisionForm.get('stations')['controls'].length;
  }
  //Stations End

  ngAfterViewChecked() {
    this.scrollHelper.doScroll();
  }

  disableEditStations() {
    if (!this.opt && this.f.stations['controls'].length > 0) {
      this.enableDisableStations(this.lockStations[0]);
      this.opt = true;
    }
    if (this.userDesignation != 'Administrator') {
      return true;
    }
    else {
      return false;
    }
  }

  enableDisableStations(event) {
    if (event == 'Lock' && this.validateStations()) {
      this.lockStations = ['Lock'];
      this.f.stations.disable();
    }
    else {
      this.lockStations = ['Unlock'];
      this.f.stations.enable();
    }
  }

  isHomeProgram() {
    return (this.homeProgram && this.f.divisionName.value == 'Home Program');
  }

  GetSiteName() {
    return Globals.Website;
  }

  removeStation(station) {
    if (station && station.controls && station.controls.dialysisStationName.value) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });

      this.dialogRef.componentInstance.confirmMessage =
        'Are you sure you want to delete Station : ' + station.controls.dialysisStationName.value + ' ?';
      return this.dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.removeStationFromArray(station);
        }
      });
    }
    else {
      this.removeStationFromArray(station);
    }
  }

  removeStationFromArray(station) {
    if (station) {
      this.stationItems = this.divisionForm.get('stations') as UntypedFormArray;

      if (station.value && station.value.dialysisUnitId) {
        if (this.validateFormData()) {
          this.stationItems.removeAt(this.stationItems['controls'].findIndex(s => s === station));
          this.stationsCount = this.stationItems['controls'].length;
          this.executeDivisionCreateUpdate("Notes");
        }
      }
      else {
        this.stationItems.removeAt(this.stationItems['controls'].findIndex(s => s === station));
        this.stationsCount = this.stationItems['controls'].length;
        // AppComponent.addAlert('Data Saved successfully!', 'success');
      }
    }
  }

  forceInputUppercase(e)
  {
    if(this.GetSiteName() != 'SCSCDU')
      this.commonapi.forceInputUppercase(e);
  }

  validateStations() {
    if (this.divisionForm.controls.stations && this.divisionForm.controls.stations.errors && this.divisionForm.controls.stations.errors.duplicate) {
      if (this.f.divisionId.value !== '0')
        AppComponent.addAlert(this.divisionForm.controls.stations.errors.duplicate, 'error');

      return false;
    }
    else if (this.divisionForm.controls.stations.value.filter((obj) => obj.dialysisStationName === '').length > 0) {
      if (this.f.divisionId.value !== '0')
        AppComponent.addAlert("Please Specify All Stations Name", 'error');

      return false;
    }
    return true;
  }


  validateFormData() {

    if (this.divisionForm.invalid) {
      if (this.divisionForm.controls.stations && this.divisionForm.controls.stations.errors && this.divisionForm.controls.stations.errors.duplicate) {
        AppComponent.addAlert(this.divisionForm.controls.stations.errors.duplicate, 'error');
      }
      else
        AppComponent.addAlert(Globals.FormErrorMessage, 'error');

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return false;
    }
    else if (this.divisionForm.controls.isCentralAcid.value
      &&
      this.divisionForm.controls.containers.value.filter((obj) => obj.containerName !== '').length <= 0) {
      AppComponent.addAlert("Please Enter atleast Container Name", 'error');
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return false;
    }
    else if (this.divisionForm.controls.stations.value.filter((obj) => obj.dialysisStationName === '').length > 0) {
      AppComponent.addAlert("Please Specify All Stations Name", 'error');
      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return false;
    }
    return true;
  }

  getStationsWithDirtyField() {
    let stations = this.divisionForm.get('stations');
    stations['controls'].forEach(function (station, newKey) {
      if (!station.controls.isDirty.value) {
        station.controls.isDirty.setValue(station.dirty);
      }
      station.controls.dialysisStationName.setValue(station.controls.dialysisStationName.value.toUpperCase());
    });

    return stations.value;
  }
}

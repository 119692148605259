<section class="content-header">
  <h1>
    <h1>
      {{ title }}
      <!-- <sup *ngIf="title != ''" class="beta">[beta]</sup> -->
    </h1>
  </h1>
</section>
<section>
  <section class="content">
    <div class="row">
          <div class="box  box-primary" style="overflow-y: auto;">
              <div class="box-header pageheader row" style="max-width: 95%;">
                  <div class="col-sm-1 text-left">

                  </div>
                  <div class="col-sm-10 text-center">
                      <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2')">
                      <i class="fa fa-picture-o"></i> Layout View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Table')">
                      <i class="fa fa-table"></i>  Table View
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2SetPoints')">
                      <i class="fa fa-th-list"></i> Set Points
                    </a>
                    <a type="button" class="btn btn-primary"  style="pointer-events:none;opacity: 0.5;max-width: fit-content;"
                    (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Document')">
                    <i class="fa fa-file"></i> Document View
                  </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Alarms')">
                      <i class="fa fa-exclamation-circle"></i> Alarm & Event History
                    </a>

                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2DataLogs')">
                      <i class="fa fa-th-list"></i> Data Logs
                    </a>
                    <a type="button" class="btn btn-primary" style="max-width: fit-content;"
                    (click)="RouteClick('WaterProcess/RemoteView/BioPureHX2Settings')">
                    <i class="fa fa-th-list"></i> Notification Settings
                  </a>
                  </div>
                  <div class="col-sm-1 text-right">
                    <a type="button" class="btn btn-primary"  style="max-width: fit-content;"
                      (click)="RouteClick('WaterProcess/RemoteView/RemoteView')">
                      <i class="fa fa-th" aria-hidden="true"></i>  Main Page
                    </a>
                  </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-6 col-sm-2" style="text-align: center;">
                       <div class="dvImageMachine">
                        <a
                        (click)="openURLDialog('HX2 Compressor Inlet Filter Maintenance',SetAttachmentUrl('HX2 Compressor Inlet Filter Maintenance.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                      <span class="spanMachine" title="HX2 Compressor Inlet Filter Maintenance">HX2 Compressor Inlet Filter Maintenance</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                        <div class="dvImageMachine">
                          <a
                          (click)="openURLDialog('HX2 Compressor Outlet Filter Maintenance',SetAttachmentUrl('HX2 Compressor Outlet Filter Maintenance.pdf'))">
                          <i class="fa fa-file-pdf-o imgClass"></i>
                        </a>
                      <span class="spanMachine" title="HX2 Compressor Outlet Filter Maintenance">HX2 Compressor Outlet Filter Maintenance</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                      <div class="dvImageMachine">
                        <a
                          (click)="openURLDialog('HX2 Membrane and Loop Cleaning and Sanitization',SetAttachmentUrl('HX2 Membrane and Loop Cleaning and Sanitization.pdf'))">
                          <i class="fa fa-file-pdf-o imgClass"></i>
                        </a>
                      <span class="spanMachine" title="HX2 Membrane and Loop Cleaning and Sanitization">HX2 Membrane and Loop Cleaning and Sanitization</span>
                      </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                      <a
                        (click)="openURLDialog('HX2 Membrane Fouling and Cleaning Sanitization Frequency',SetAttachmentUrl('HX2 Membrane Fouling and Cleaning Sanitization Frequency.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                    <span class="spanMachine" title="HX2 Membrane Fouling and Cleaning Sanitization Frequency">HX2 Membrane Fouling and Cleaning Sanitization Frequency</span>
                  </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                    <a
                      (click)="openURLDialog('HX2 Pre Filter Change',SetAttachmentUrl('HX2 Pre Filter Change.pdf'))">
                      <i class="fa fa-file-pdf-o imgClass"></i>
                    </a>
                    <span class="spanMachine" title="HX2 Pre Filter Change" style="padding-top: 3px;">HX2 Pre Filter Change</span>
                  </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                      <a
                        (click)="openURLDialog('HX2 Pre Loop Filter Change',SetAttachmentUrl('HX2 Pre Loop Filter Change.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                    <span class="spanMachine" title="HX2 Pre Loop Filter Change">HX2 Pre Loop Filter Change</span>
                  </div>
                  </div>
                </div>

                <div class="row" style="margin-top:3%;">
                  <div class="col-6 col-sm-2" style="text-align: center;">
                       <div class="dvImageMachine">
                        <a
                          (click)="openURLDialog('HX2 Compressor Inlet Filter Maintenance',SetAttachmentUrl('HX2 Compressor Inlet Filter Maintenance.pdf'))">
                          <i class="fa fa-file-pdf-o imgClass"></i>
                        </a>
                      <span class="spanMachine" title="HX2 Purging the Compressor System of Condensate">HX2 Purging the Compressor System of Condensate</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                        <div class="dvImageMachine">
                          <a
                            (click)="openURLDialog('HX2 Relief Valve Test',SetAttachmentUrl('HX2 Relief Valve Test.pdf'))">
                            <i class="fa fa-file-pdf-o imgClass"></i>
                          </a>
                      <span class="spanMachine" title="HX2 Relief Valve Test" style="padding-top: 3px;">HX2 Relief Valve Test</span>
                    </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                      <div class="dvImageMachine">
                        <a
                          (click)="openURLDialog('HX2 Service Schedule',SetAttachmentUrl('HX2 Service Schedule.pdf'))">
                          <i class="fa fa-file-pdf-o imgClass"></i>
                        </a>
                      <span class="spanMachine" title="HX2 Service Schedule" style="padding-top: 3px;">HX2 Service Schedule</span>
                      </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                      <a
                        (click)="openURLDialog('HX2 Spare Parts',SetAttachmentUrl('HX2 Spare Parts.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                    <span class="spanMachine" title="HX2 Spare Parts" style="padding-top: 3px;">HX2 Spare Parts</span>
                  </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                      <a
                        (click)="openURLDialog('HX2 Status Messages',SetAttachmentUrl('HX2 Status Messages.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                    <span class="spanMachine" title="HX2 Status Messages" style="padding-top: 3px;">HX2 Status Messages</span>
                  </div>
                  </div>
                  <div class="col-6 col-sm-2" style="text-align: center;">
                    <div class="dvImageMachine">
                      <a
                        (click)="openURLDialog('HX2 Troubleshooting',SetAttachmentUrl('HX2 Troubleshooting.pdf'))">
                        <i class="fa fa-file-pdf-o imgClass"></i>
                      </a>
                    <span class="spanMachine" title="HX2 Troubleshooting" style="padding-top: 3px;">HX2 Troubleshooting</span>
                  </div>
                  </div>
                </div>
              </div>
              <div class="box-body" style="pointer-events: none;margin-top: 3%;">
                <attachment  #attachment></attachment>
                      </div>
              <!-- <div class="box-body" id="dvboxbody">

                <div class="row">
                        <div class="col-sm-offset-2 col-sm-8">
                    <div class="col-sm-6 pull-right">
                        <div class="col text-right">
                            <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                        </div>
                     </div>

                     <div class="col-sm-6">
                            <div class="col text-left noofrecords" >
                            <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                            <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWaterAnalysisSearchClear()">
                            (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                            <b>Clear Search</b>
                            </a>)
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
              <div class="col-sm-offset-2 col-lg-8">
                <table id="OptionList" class="table table-bordered table-striped dataTable no-footer" role="grid">
                    <thead>


                        <tr>
                            <th *ngFor="let tableHeader of tableHeaders"

                                [ngClass]="setSortIndicator(tableHeader.Label)"
                                [style.width.%]="tableHeader.Width"
                                (click)="changeSorting(tableHeader.Label)">{{tableHeader.Label}}</th>
                        </tr>
                    </thead>
                        <tbody>
                            <tr *ngFor="let waterAnalysis of rows;let index=index;"
                            (click)="_onRowClick(waterAnalysis.waterprocessanalysisid)" class="pointer">
                                <td>
                                    {{(page.pageSize*(page.currentPageNumber-1)) + (index + 1)}}
                                </td>
                                <td>{{waterAnalysis.eventid}}</td>
                                <td [style.color]="waterAnalysis.result == 'Fail' ? 'red': 'black'">{{waterAnalysis.result}}</td>
                                <td>{{waterAnalysis.divisionName}}</td>
                                <td >{{waterAnalysis.collectiondate | date:'dd/MM/yyyy'}}</td>
                                <td >{{waterAnalysis.receiveddate | date:'dd/MM/yyyy'}}</td>
                                <td>{{waterAnalysis.reportNumber}}</td>
                                <td >{{waterAnalysis.createdbyName}} </td>

                                <td matTooltip="{{waterAnalysis.status}}">
                                  <div class="progressbar-wrapper">
                                      <ul class="progressBar">

                                          <li [ngClass]="{'progressBarlightGreen': waterAnalysis.status == 'Completed','progressBarlightBlue': waterAnalysis.status == 'In Progress', 'progressbarEmptyRed': waterAnalysis.status == 'Open'}">
                                              <i [ngClass]="{'fa-check': waterAnalysis.status == 'Completed', 'fa-arrow-right':waterAnalysis.status == 'In Progress', 'fa':waterAnalysis.status != '' }">
                                              </i>
                                          </li>
                                      </ul>
                                  </div>
                              </td>
                                </tr>
                        </tbody>


                </table>


              </div>
                <div class="row">
                    <div class="col-sm-offset-2 col-sm-8">
                <div class="col-sm-9 pull-right">
                    <div class="col text-right">
                        <ngb-pagination pageSize="25" [boundaryLinks]="true" [collectionSize]="page.totalElements" [(page)]="page.pageNumber" (pageChange)="setPage()" [maxSize]="10" [directionLinks]="true"></ngb-pagination>
                    </div>
                 </div>
                 <div class="col-sm-3">
                        <div class="col text-left noofrecords" >
                        <span><b> <i>{{page.totalElements}} {{page.totalElements > 1 ? 'Records' : 'Record'}} Found.</i></b></span>
                        <span *ngIf="blnDirtySearch"><a style="cursor:pointer" (click)="executeWaterAnalysisSearchClear()">
                        (<span ><i class="fa fa-trash-o"></i></span>&nbsp;
                        <b>Clear Search</b>
                        </a>)
                        </span>
                        </div>
                    </div>
                    </div>
                </div>
          </div> -->
          <!-- <attachment (RaiseError)="AttachmentError($event)" #attachment>

          </attachment> -->
              <!-- <div class="container">
                <div class="row">
              <div class="col-12 col-sm-8 col-lg-5">
                <h6 class="text-muted">List Group with Images</h6>
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Don Quixote
                    <div class="image-parent">
                        <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/don_quixote.jpg" class="img-fluid" alt="quixote">
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    As I Lay Dying
                    <div class="image-parent">
                        <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/as_I_lay.jpg" class="img-fluid" alt="lay">
                      </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Things Fall Apart
                    <div class="image-parent">
                        <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/things_fall_apart.jpg" class="img-fluid" alt="things">
                      </div>
                </li>
                </ul>
              </div></div></div> -->
              <!-- <div class="col-sm-12">
          <div class="col-sm-4">
              <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                  <thead>
                      <tr>
                          <th>Field Name</th>
                          <th>Field Value</th>
                      </tr>
                  </thead>
                  <tbody>
                          <tr *ngFor="let item of filterHX2List(1);let index=index;" class="pointer">
                                  <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                                  <td class="emptyvalue" matTooltip="{{item.value}}" style="text-align:left;max-width: 150px;width: 150px;">
                                    {{ (item.value.length>14)? (item.value | slice:0:14)+'..':(item.value) }}
                                  </td>
                          </tr>
                  </tbody>
              </table>
          </div>
          <div class="col-sm-4">
            <table class="table table-bordered table-striped dataTable no-footer" role="grid">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th>Field Value</th>
                    </tr>
                </thead>
                <tbody>
                        <tr *ngFor="let item of filterHX2List(2);let index=index;" class="pointer">
                                <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                                <td class="emptyvalue" matTooltip="{{item.value}}" style="text-align:left;max-width: 150px;width: 150px;">
                                  {{ (item.value.length>14)? (item.value | slice:0:14)+'..':(item.value) }}
                                </td>
                        </tr>
                </tbody>
            </table>
        </div>
        <div class="col-sm-4">
          <table class="table table-bordered table-striped dataTable no-footer" role="grid">
              <thead>
                  <tr>
                      <th>Field Name</th>
                      <th>Field Value</th>
                  </tr>
              </thead>
              <tbody>
                      <tr *ngFor="let item of filterHX2List(3);let index=index;" class="pointer">
                              <td class="empty" style="text-align:right" ><b>{{item.field}}</b></td>
                              <td class="emptyvalue" matTooltip="{{item.value}}" style="text-align:left;max-width: 150px;width: 150px;">
                                {{ (item.value.length>14)? (item.value | slice:0:14)+'..':(item.value) }}
                              </td>
                      </tr>
              </tbody>
          </table>
      </div>
              </div> -->
      </div>
    </div>
  </section>
</section>

import { informationdialog } from './Section/informationdialog/informationdialog.component';
import { PurposeEquipmentExchangeComponent } from './Purpose/purpose-equipmentexchange/purpose-equipmentexchange.component';
import { DMApiService } from './../API/dmapi.service';
import { Validators } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel, IMyOptions } from 'angular-mydatepicker';
import { map, filter } from 'rxjs/operators';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AuthService } from './../API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { InvApiService } from 'src/app/API/invapi.service';
import { ContactApiService } from './../API/contactapi.service';
import { AttachmentComponent } from 'src/app/Core/Shared/AttachmentComponent/AttachmentComponent';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/Core/ConfirmationDialog';
import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CanComponentDeactivate } from './../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { ScrollHelper } from '../Core/Helper/ScrollHelper';
import { QuickAddModelDialog } from '../Core/QuickAddModel/QuickAddModelDialog';
import { NotesComponent } from '../Core/Shared/NotesComponent/NotesComponent';
import {
  Router, ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import { HomeService } from '../API/home.service';
import { NoteDialog } from '../Core/NotesModel/NoteDialog';
import { PurposeTestingComponent } from './Purpose/purpose-testing/purpose-testing.component';
import { AppComponent } from '../app.component';
import { Globals } from '../globals';
import { HomeFieldService } from '../API/homefield.service';
import { PurposeInitialSiteVisitComponent } from './Purpose/purpose-initialsitevisit/purpose-initialsitevisit.component';
import { PurposeFullInstallationComponent } from './Purpose/purpose-fullinstallation/purpose-fullinstallation.component';
import { PurposeUninstallationComponent } from './Purpose/purpose-uninstallation/purpose-uninstallation.component';
import { CommonCustomValidators } from '../Validator/CommonValidator';
import { HFSNoteDialog } from '../Core/HFSNotesModel/HFSNoteDialog';
import { debug } from 'util';
import { PurposeMaintenanceComponent } from './Purpose/purpose-maintenance/purpose-maintenance.component';
import { PurposeRenovationComponent } from './Purpose/purpose-renovation/purpose-renovation.component';
import { PurposeOtherComponent } from './Purpose/purpose-other/purpose-other.component';
import { PurposeRepairComponent } from './Purpose/purpose-repair/purpose-repair.component';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
// import { Ng2ImgMaxService } from 'ng2-img-max';
import { ImageViewerDialog } from '../Core/Shared/ImageViewerDialog/ImageViewerDialog';
import { SectionPrepdetailDialog } from './Section/section-prepdetail/section-prepdetail.component';
import { SectionVehicledetailDialog } from './Section/section-vehicledetail/section-vehicledetail.component';
import { SectionPurposeDetailDialog } from './Section/section-purposedetail/section-purposedetail.component';
import { SectionReturnDetailDialog } from './Section/section-returndetail/section-returndetail.component';
import { HFSVehicleHistoryDialog } from './Section/HFSVehicleHistoryDialog/HFSVehicleHistoryDialog.component';
// import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { WPApiService } from '../API/wpapi.service';
import { PanelPurposeDetailComponent } from './Section/panel-purpose-detail/panel-purpose-detail.component';
import * as _ from 'underscore';

@Component({
  selector: 'HomeFieldServiceDetail',
  templateUrl: './HomeFieldServiceDetail.component.html',
  styleUrls: ['./HomeFieldService.css']
})

export class HomeFieldServiceDetailComponent implements OnInit, CanComponentDeactivate {
  otherHFSPurposeList = [];
  otherHFSSectionList = [];
  allOtherHFSList = [];

  @ViewChildren('panelPurposeDetailComponent') panelChildrens: QueryList<PanelPurposeDetailComponent>;
  isAnyPanelEnable: boolean = false;
  formSubmitted: boolean = false;
  ReturnDetailsEntered = true;
  PurposeDetailsEntered = true;
  PurposeDetailsEnteredInProgress = false;

  VehicleDetailsEntered = true;
  addHomeUnit = false;
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  CurrentUser = this.auth.GetUserName();
  quickNoteDialogRef: MatDialogRef<HFSNoteDialog>;
  nextStationIdPrefix: string = "";
  noteModule = "";
  DivisionList = [];
  selectedDivisionObj;
  prepTimeSummary;
  travellingTimeToPtHome;
  travellingTimeSpentAtPtHome;
  travellingTimeToHospital;
  MasterHFSCodes = "";

  @ViewChild('attachmentVisitPurposePlus', { static: false }) attachmentVisitPurposePlus: AttachmentComponent;
  @ViewChild('attachmentReturnDetails', { static: true }) attachmentReturnDetails: AttachmentComponent;

  @ViewChild('notesVisitPurpose', { static: true }) notesVisitPurpose: NotesComponent;
  @ViewChild('notesVisitPrep', { static: true }) notesVisitPrep: NotesComponent;
  @ViewChild('notesVisitVehicles', { static: true }) notesVisitVehicles: NotesComponent;
  @ViewChild('notesVisitPurposePlus', { static: true }) notesVisitPurposePlus: NotesComponent;
  @ViewChild('notesReturnDetails', { static: true }) notesReturnDetails: NotesComponent;
  // @ViewChildren('notesVisitPurposePlus') notesVisitPurposePlusChildren: QueryList<NotesComponent>;


  @ViewChild(PurposeTestingComponent, { static: false })
  purposeTesting: PurposeTestingComponent;

  @ViewChild(PurposeInitialSiteVisitComponent, { static: false })
  purposeInitialSiteVisit: PurposeInitialSiteVisitComponent;

  @ViewChild(PurposeFullInstallationComponent, { static: false })
  purposeFullInstallation: PurposeFullInstallationComponent;

  @ViewChild(PurposeUninstallationComponent, { static: false })
  purposeUnInstallation: PurposeUninstallationComponent;

  @ViewChild(PurposeEquipmentExchangeComponent, { static: false })
  purposeEquipmentExchange: PurposeEquipmentExchangeComponent;

  @ViewChild(PurposeMaintenanceComponent, { static: false })
  purposeMaintenance: PurposeMaintenanceComponent;

  @ViewChild(PurposeRenovationComponent, { static: false })
  purposeRenovation: PurposeRenovationComponent;

  @ViewChild(PurposeOtherComponent, { static: false })
  purposeOther: PurposeOtherComponent;

  @ViewChild(PurposeRepairComponent, { static: false })
  purposeRepair: PurposeRepairComponent;

  isValidFormSubmitted = null;
  ListURL = 'HomeFieldService/HomeFieldServiceSearch';
  status = '';
  loading = true;
  blnshowSave = false;
  blnshowDelete = false;
  public today = new Date();
  HFServiceCode = "[AutoNumber]";
  HFServiceId = '0';
  selectedVehiclelastKms = 0;
  UserName = this.auth.GetUserName();
  HFServicePrepUser = this.auth.GetUserName();;
  tooltipText = "Click To View";
  dialysisStations = [];
  dialysisStationOthers = [];
  allNotesList = [];
  VehicleFailureNotes = [];
  PartTrans = [];
  HFServiceVisitDateLabel = '';
  HFServiceVehicleInspectionStatusValue = '';
  maintenanceCategories = [
    { value: '', text: 'Select' },
    // { value: 'RO Checks & Testing', text: 'RO Checks & Testing' },
    // { value: 'Dialysis Machine Checks & Testing', text: 'Dialysis Machine Checks & Testing' },
    { value: 'Equipments Checks & Testing', text: 'Equipments Checks & Testing' },
    { value: 'Cart/Filters Checks', text: 'Cart/Filters Checks' },
    { value: 'Comprehensive Water Testing', text: 'Comprehensive Water Testing' },
    { value: 'Yearly Checks', text: 'Yearly Checks' },
  ];

  EquipmentExchangeCategoryList = [];
  RepairCategoryList = [];
  visitPurposeFinalList = [];

  visitPurposeAllList = [
    { value: '', text: 'Select' },
    { value: 'Initial Site Visit', text: 'Initial Site Visit' },
    { value: 'Renovation & Full Installation', text: 'Renovation & Full Installation' },
    // { value: 'Full Installation', text: 'Full Installation' },
    { value: 'Equipment Exchange', text: 'Equipment Exchange' },
    { value: 'Maintenance & Testing', text: 'Maintenance & Testing' },
    { value: 'Repair', text: 'Repair' },
    // { value: 'Testing', text: 'Testing' },
    { value: 'Other', text: 'Other' },
    { value: 'Uninstallation', text: 'Uninstallation' },
  ];


  visitPurposeWithoutRenovationListComboBox = [
    // { value: '', text: 'Select' },
    // { value: 'Initial Site Visit', text: 'Initial Site Visit' },
    // { value: 'Full Installation', text: 'Full Installation' },
    { value: 'Equipment Exchange', text: 'Equipment Exchange', isFormSubmitted: false },
    { value: 'Maintenance & Testing', text: 'Maintenance & Testing', isFormSubmitted: false },
    { value: 'Repair', text: 'Repair', isFormSubmitted: false },
    // { value: 'Testing', text: 'Testing' },
    { value: 'Other', text: 'Other', isFormSubmitted: false },
    { value: 'Uninstallation', text: 'Uninstallation', isFormSubmitted: false },
  ];

  visitPurposeWithoutRenovationList = [
    // { value: '', text: 'Select' },
    // { value: 'Initial Site Visit', text: 'Initial Site Visit' },
    // { value: 'Full Installation', text: 'Full Installation' },
    { value: 'Equipment Exchange', text: 'Equipment Exchange', isFormSubmitted: false },
    { value: 'Maintenance & Testing', text: 'Maintenance & Testing', isFormSubmitted: false },
    { value: 'Repair', text: 'Repair', isFormSubmitted: false },
    // { value: 'Testing', text: 'Testing' },
    { value: 'Other', text: 'Other', isFormSubmitted: false },
    // { value: 'Uninstallation', text: 'Uninstallation', isFormSubmitted: false },
  ];

  visitPurposeRenovationList = [
    { value: 'Renovation & Full Installation', text: 'Renovation & Full Installation', isFormSubmitted: false },
  ];

  visitNotification = [
    { value: '', text: 'Select' },
    { value: 'Spoke', text: 'Spoke' },
    { value: 'Left Message', text: 'Left Message' },
    // { value: 'Sent Email', text: 'Sent Email' },
    // { value: 'N/A', text: 'N/A' },
    // { value: 'See Notes', text: 'See Notes' }
  ];

  purposeSelectedId = "";

  MRMHomeStationName = '';
  MRMHomeStationNameOther = '';
  HomeFieldServiceVisitDate = this.today;
  dropdownSettings;
  TotalCalculatedKMs = 0;
  TechnologistsList = [];
  isCollapsed = {
    Section1: false,
    Section2: false,
    Section3: false,
    Section4: false,
    Section4Test: false,
    Section5: false,
    Section6: false,
    Section7: false,
    Section8: false
  };

  // isCollapsedSection1= false;
  // isCollapsedSection2 = true;

  PrepTimeList = [
    { value: '', text: 'Select' },
    { value: '0.5', text: '0.5' },
    { value: '1.0', text: '1.0' },
    { value: '1.5', text: '1.5' },
    { value: '2.0', text: '2.0' },
    { value: '2.5', text: '2.5' },
    { value: '3.0', text: '3.0' },
  ];

  VehicleList = [];

  NotStartedStationList = [
    { value: '', text: 'Select' },
  ];

  YesNoList = [
    { value: '', text: 'Select' },
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' },
  ];

  FuelCompanyList = [
    { value: '', text: 'Select' },
    { value: 'Canadian Tire', text: 'Canadian Tire' },
    { value: 'Chevron', text: 'Chevron' },
    { value: 'Esso', text: 'Esso' },
    { value: 'Husky', text: 'Husky' },
    { value: 'Petro-Canada', text: 'Petro-Canada' },
    { value: 'Pioneer', text: 'Pioneer' },
    { value: 'Shell Canada', text: 'Shell Canada' },
    { value: 'Ultramar', text: 'Ultramar' },
    { value: 'Other', text: 'Other' },
  ];


  PaymentByList = [
    { value: '', text: 'Select' },
    { value: 'Visa', text: 'Visa' },
    { value: 'Mastercard', text: 'Mastercard' },
    { value: 'Cash', text: 'Cash' },
    { value: 'Husky', text: 'Husky' },
  ];

  formattedHFServiceFuelAmount = '';
  HFServiceTechnologists = '';

  totalTime: string = '';
  totalTechTime: string = '';
  VehicleListSummary: string = '';
  totalPrepDetailTime: string = '';
  totalTechTestingTime: string = '';
  totalTravellingTime: string = '';

  PrepDetailsList = [];
  VehicleDetailsList = [];
  PurposeDetailsList = [];
  ReturnDetailsList = [];

  HomeFieldServiceForm = new UntypedFormGroup({
    HFServiceId: new UntypedFormControl('0'),
    HFServiceCode: new UntypedFormControl(''),
    HFServiceStationId: new UntypedFormControl(''),
    HFServiceStationName: new UntypedFormControl(''),
    HFServiceVisitPurpose: new UntypedFormControl(''),
    HFServiceVisitDate: new UntypedFormControl(''),
    HFServiceVisitNotification: new UntypedFormControl(''),
    HFServiceVisitNotificationNotes: new UntypedFormControl(''),
    HFServiceVisitDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVisitDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceVehiclePrepTime: new UntypedFormControl(''),
    HFServiceVehiclePrepDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVehiclePrepDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceVehicleStartTime: new UntypedFormControl(''),
    HFServiceVehicleTechnologistId: new UntypedFormControl(''),
    HFServiceVehicleTechnologistName: new UntypedFormControl(''),
    HFServiceVehicleId: new UntypedFormControl(''),
    HFServiceVehicleName: new UntypedFormControl(''),
    HFServiceVehicleStartedKM: new UntypedFormControl(''),
    HFServiceVehicleInspectionStatus: new UntypedFormControl(null),
    HFServiceVehicleInspectionNotes: new UntypedFormControl(''),
    HFServiceVehicleDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceVehicleDetailsEnteredBy: new UntypedFormControl(''),
    HFServicePTHMArrivalTime: new UntypedFormControl(''),
    HFServicePTHMDepartureTime: new UntypedFormControl(''),
    HFServicePTHMPurposeCompleted: new UntypedFormControl(''),
    HFServiceNextStep: new UntypedFormControl(''),
    HFServicePTHMPurposeCompletedOn: new UntypedFormControl(''),
    HFServicePTHMPurposeCompletedBy: new UntypedFormControl(''),
    HFServiceReturnVehicleEndTime: new UntypedFormControl(''),
    HFServiceReturnVehicleEndedKM: new UntypedFormControl(''),
    HFServiceFuelAdded: new UntypedFormControl(''),
    HFServiceFuelCompany: new UntypedFormControl(''),
    HFServiceFuelAmount: new UntypedFormControl(''),
    HFServiceFuelPaymentBy: new UntypedFormControl(''),
    HFServiceFuelReceiptPath: new UntypedFormControl(''),
    HFServiceReturnDetailsEnteredOn: new UntypedFormControl(''),
    HFServiceReturnDetailsEnteredBy: new UntypedFormControl(''),
    HFServiceStatus: new UntypedFormControl(''),
    HFServiceCreatedBy: new UntypedFormControl(''),
    HFServiceCreatedOn: new UntypedFormControl(''),
    HFServiceModifiedBy: new UntypedFormControl(''),
    HFServiceModifiedOn: new UntypedFormControl(''),
    HFServicelastModifiedSection: new UntypedFormControl(''),
    HFServiceCategory: new UntypedFormControl(''),
    HFServiceTaskCategory: new UntypedFormControl(''),
    HFServiceIssue: new UntypedFormControl(''),
    IsDelete: new UntypedFormControl(''),
    HFServiceOtherStationId: new UntypedFormControl(''),
    ISVStationId: new UntypedFormControl(''),
    ISVPhoneNumber: new UntypedFormControl(''),
    ISVAddress: new UntypedFormControl(''),
    ISVProvince: new UntypedFormControl('Ontario'),
    ISVCity: new UntypedFormControl(''),
    ISVPostalCode: new UntypedFormControl(''),
    ISVDirections: new UntypedFormControl(''),
    ISVAccessCode: new UntypedFormControl(''),
    HFServiceVisitNotificationEnteredOn: new UntypedFormControl(''),
    HFServiceVisitNotificationEnteredBy: new UntypedFormControl(''),
    poPlumber: new UntypedFormControl(''),
    poElectrician: new UntypedFormControl(''),
    poMovers: new UntypedFormControl(''),
    poPlumberNote: new UntypedFormControl(''),
    poElectricianNote: new UntypedFormControl(''),
    poMoversNote: new UntypedFormControl(''),
    IsReferencedHFS: new UntypedFormControl(false),
    TaskId: new UntypedFormControl(''),
  });

  constructor(
    private dmapi: DMApiService,
    private wpapi: WPApiService,
    private homeApi: HomeService,
    private homefieldApi: HomeFieldService,
    private contactApi: ContactApiService,
    private invapi: InvApiService,
    private commonapi: CommonApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private currencyPipe: CurrencyPipe,
    // private ng2ImgMax: Ng2ImgMaxService,
    private datePipe: DatePipe,

  ) { }

  currenctyToNumber(value) {

    if (value && value != null)
      return Number(value.toString().replace(/[^0-9.-]+/g, ""));
  }


  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // if (
    //   (this.HomeFieldServiceForm.dirty || this.notes.Note ||
    //     this.attachment.Queue().length > 0) &&
    //   (this.isValidFormSubmitted == false || this.isValidFormSubmitted == null)
    // ) {
    //   this.dialogRef = this.dialog.open(ConfirmationDialog, {
    //     disableClose: true
    //   });
    //   this.dialogRef.componentInstance.confirmMessage =
    //     'You have unsaved changes! If you leave, your changes will be lost.';
    //   return this.dialogRef.afterClosed().pipe(
    //     map((res: any) => {
    //       // console.log('res', res);
    //       return res;
    //     })
    //   );
    // } else {
    return true;
    // }
  }

  get f() {
    return this.HomeFieldServiceForm.controls;
  }

  ngOnInit() {

    AppComponent.ShowLoader();
    this.auth.GetCurrentDateTimeFromServer().subscribe(currentDate => {
      this.HomeFieldServiceVisitDate = this.today = new Date(currentDate);

      this.fillEquipmentCategories();
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'value',
        textField: 'text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: false,
        enableCheckAll: false
      };

      // this.f.HFServiceVehicleInspectionStatus.setValue('true');
      this.fillUserList();

      this.fillVehicleList();

      this.fillDialysisStations();
      this.visitPurposeFinalList = this.visitPurposeAllList;

      // this.f.HFServiceVisitDate.setValue(this.HomeFieldServiceVisitDate);
      this.f.HFServiceCreatedOn.setValue(this.HomeFieldServiceVisitDate);
      this.f.ISVProvince.disable();
      const test = this.route.queryParams.subscribe(params => {

        this.f.HFServiceId.setValue(params['id'] || '0');

        if (this.f.HFServiceId.value !== '0') {
          this.setModel();
        }
        else {
          this.fetchNextStationID();
          this.isCollapsed.Section1 = true;
          AppComponent.HideLoader();

          this.f.HFServiceStationId.setAsyncValidators([
            CommonCustomValidators.GetHFS_GRHomeStationIsAvailable(
              this.homefieldApi
            )
          ]);
        }
        this.SetValidations();
      });


      // this.notes.showHours = false;
      // this.notesSection1.showHours = false;
      // this.notesSection2.showHours = false;
      AppComponent.HideLoader();
    }, error => {
      AppComponent.HideLoader();
    });
  }

  isAdditionalAddVehicle = false;
  isAdditionalAddReturn = false;
  isAdditionalAddArrival = false;
  isAdditionalAddDeparture = false;

  ngAfterViewInit() {
    this.setNotes();
    this.setAttachmentModule();
  }


  fillEquipmentCategories() {
    this.invapi.FillCategoryList().subscribe(list => {
      // this.EquipmentCategoryList = list.filter(
      //   x => x.text !== 'Other Equipment'
      // );
      this.EquipmentExchangeCategoryList = list.filter(
        x => x.text !== 'Other Equipment'
      );

      this.RepairCategoryList = this.EquipmentExchangeCategoryList;

    });

    if (!this.f.HFServiceCategory.value && this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {
      this.f.HFServiceCategory.setValue('');
    }

  }
  public myOptions: IAngularMyDpOptions = {
    // other options...
    dateFormat: "dd/mm/yyyy",
    closeSelectorOnDateSelect: true,
    closeSelectorOnDocumentClick: true,
    // disableUntil: {
    //   year: this.today.getFullYear(),
    //   month: this.today.getMonth() + 1,
    //   day: this.today.getDate() - 1
    // }
  };

  getCopyOfOptions(): IMyOptions {
    return JSON.parse(JSON.stringify(this.myOptions));
  }

  onDateChanged(event: IMyDateModel): void { }

  onItemSelect(item: any) {
    // console.log(item);
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  OnVisitNotificationChange($event) {
    console.log($event);
    if ($event.checked) {
      this.f.HFServiceVisitNotification.setValue('Spoke');
    }
    else {
      this.f.HFServiceVisitNotification.setValue('');
    }
  }

  fillUserList() {
    this.dmapi.GetUsersDD().subscribe(list => {
      this.TechnologistsList = list.filter((item, index) => item.value !== '' && item.value != this.currentUserId);
    });
  }
  fillVehicleList() {
    AppComponent.ShowLoader();
    var InventorySearch = <any>{};

    InventorySearch.name = "Vehicle";
    InventorySearch.currentPageNumber = 1;
    InventorySearch.sortExpression = 'equipmentname';
    InventorySearch.sortDirection = 'ASC';
    InventorySearch.pageSize = 100;
    this.invapi.GetInventoryDetails(InventorySearch).subscribe(
      pagedData => {
        this.VehicleList = pagedData.inventoryDetails;
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

  }

  fillDialysisStations() {

    AppComponent.ShowLoader();
    var dsSearch = <any>{};

    dsSearch.divisionName = "Home Program";
    dsSearch.currentPageNumber = 1;
    dsSearch.sortExpression = 'dialysisStationName';
    dsSearch.sortDirection = 'ASC';
    dsSearch.pageSize = 10000;

    // if (this.f.HFServiceVisitPurpose.value == 'Full Installation' || this.f.HFServiceVisitPurpose.value == "Renovation")
    //   dsSearch.status = "Pending";
    // if (this.f.HFServiceVisitPurpose.value == 'Uninstallation')
    //   dsSearch.status = "Active";

    this.homeApi.GetDialysisStationSearchDetails(dsSearch).subscribe(pagedData => {
      this.dialysisStations = pagedData.dialysisStations.filter((item, index) => (item.status != null && item.status !== 'InActive'));
      AppComponent.HideLoader();
    },
      error => {
        AppComponent.HideLoader();
      });
  }

  fetchNextStationID() {
    this.homefieldApi.GetHomeFieldServiceNextStationID().subscribe(stationID => {
      this.nextStationIdPrefix = stationID;
    });
  }

  homeUnitChange(event) {
    // this.f.HFServiceOtherStationId.setValue('');
    // this.dialysisStationOthers = this.dialysisStations.filter((item, index) => item.dialysisUnitId != this.f.HFServiceStationId.value.toString());
    var tempStationName = this.dialysisStations.filter((item, index) => item.dialysisUnitId == this.f.HFServiceStationId.value.toString());

    var tempStationStatus = '';
    if (tempStationName.length > 0) {
      this.MRMHomeStationName = tempStationName[0].dialysisStationName;
      tempStationStatus = tempStationName[0].status;
    }
    this.setChildMRMID();

    if (this.f.HFServiceId.value === "0") {
      if (tempStationStatus == 'Pending') {
        this.visitPurposeFinalList = this.visitPurposeRenovationList;

        if (this.visitPurposeFinalList.filter(c => c.value == '').length <= 0)
          this.visitPurposeFinalList.unshift({ value: '', text: 'Select' });
      }
      else if (tempStationStatus == 'Active') {
        this.visitPurposeFinalList = this.visitPurposeWithoutRenovationListComboBox.filter((item, index) => item.value != 'Initial Site Visit');

        if (this.visitPurposeFinalList.filter(c => c.value == '').length <= 0)
          this.visitPurposeFinalList.unshift({ value: '', text: 'Select' });
      }
      else {
        this.visitPurposeFinalList = this.visitPurposeWithoutRenovationListComboBox;

        if (this.visitPurposeFinalList.filter(c => c.value == '').length <= 0)
          this.visitPurposeFinalList.unshift({ value: '', text: 'Select' });
      }

      if (this.f.HFServiceStationId.value === 'Add New') {
        this.f.HFServiceVisitPurpose.setValue('Initial Site Visit');
        this.f.HFServiceVisitPurpose.disable();
        this.MRMHomeStationName = '';
        this.ISV_Error = '';
        this.visitPurposeChange();
      }
      else {
        this.f.HFServiceVisitPurpose.setValue('');
        this.visitPurposeChange();
        this.getISVStationDetails();
      }
    }
  }

  otherHomeUnitChange() {
    var tempStationName = this.dialysisStationOthers.filter((item, index) => item.dialysisUnitId == this.f.HFServiceOtherStationId.value.toString());

    if (tempStationName.length > 0)
      this.MRMHomeStationNameOther = tempStationName[0].dialysisStationName;
  }

  VehicleDropDownChanged() {
    if (this.f.HFServiceVehicleId.value !== '0' && this.f.HFServiceVehicleId.value !== '') {

      AppComponent.ShowLoader();
      this.f.HFServiceVehicleStartedKM.setValue('');

      var equipmentId = { Id: this.f.HFServiceVehicleId.value };
      this.homefieldApi.GetHFSMachaineHistoryMaxKms(equipmentId).subscribe(result => {
        if (result) {
          this.selectedVehiclelastKms = result;
        } else {
          this.selectedVehiclelastKms = 0;
        }
        AppComponent.HideLoader();
      });

      this.fetchNotes();

      var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.f.HFServiceVehicleId.value);

      if (selectedVehicles.length > 0)
        this.f.HFServiceVehicleName.setValue(selectedVehicles[0].equipmentcode);

      if (this.f.HFServiceId.value && this.f.HFServiceId.value <= '0')
        this.f.HFServiceVehicleInspectionStatus.setValue(null);
    }
  }

  visitPurposeChange() {
    this.fillEquipmentCategories();
    this.fillDialysisStations();
    this.setChildMRMID();
    this.SetValidations();

    this.f.HFServiceCategory.setValue('');
    this.f.HFServiceIssue.setValue('');

    this.notesVisitPurposePlus.showHours = false;
    if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
      this.notesVisitPurposePlus.PlusNoteHeader = "Additional Notes";
    else
      this.notesVisitPurposePlus.PlusNoteHeader = "Notes";

    // this.notesVisitPurposePlusChildren.forEach((child) => {
    //   if (child) {
    //     child.showHours = false;

    //     if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
    //       child.PlusNoteHeader = "Additional Notes";
    //     else
    //       child.PlusNoteHeader = "Notes";
    //   }
    // });
  }

  SetValidations() {
    this.clearValidations();

    if (this.f.HFServiceStatus.value == 'Completed') {
      // Validation for Completion not required
    }
    else if (this.f.HFServicePTHMPurposeCompletedOn.value) {

      this.HFServicePTHMPurposeCompletedChange();
      // this.f.HFServiceReturnVehicleEndedKM.setValidators([
      //   CommonCustomValidators.CheckHoursoutGreaterHoursInCustomValidation(
      //     this.f.HFServiceVehicleStartedKM
      //   ),
      //   Validators.required,
      //   Validators.pattern(Globals.NumberNumericRegexWithoutZero)]
      // );
      this.f.HFServiceReturnVehicleEndedKM.setValidators([
        Validators.required,
        Validators.pattern(Globals.NumberNumericRegexWithoutZero)]
      );

      // this.f.HFServiceReturnVehicleEndTime.setValidators([Validators.required]);
      this.f.HFServiceReturnVehicleEndTime.setValidators([Validators.required]);
      this.f.HFServiceFuelAdded.setValidators([Validators.required]);

      // if (this.f.HFServiceFuelAdded.value == "Yes") {
      //   this.f.HFServiceFuelAdded.setValidators([Validators.required]);
      //   this.f.HFServiceFuelCompany.setValidators([Validators.required]);
      //   this.f.HFServiceFuelAmount.setValidators([Validators.required]);
      //   this.f.HFServiceFuelPaymentBy.setValidators([Validators.required]);
      // }
    }
    else if (this.f.HFServiceVehicleDetailsEnteredOn.value) {
      // this.f.HFServicePTHMArrivalTime.setValidators([Validators.required]);
      this.f.HFServicePTHMArrivalTime.setValidators([Validators.required
      ]);

      // this.f.HFServicePTHMDepartureTime.setValidators([Validators.required]);
      this.f.HFServicePTHMDepartureTime.setValidators([Validators.required
      ]);
      this.f.HFServicePTHMPurposeCompleted.setValidators([Validators.required]);
    }
    else if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke' && this.f.HFServicelastModifiedSection.value !== 'Pending') {
      this.f.HFServiceVehicleId.setValidators([Validators.required]);

      // this.f.HFServiceVehicleId.setAsyncValidators([
      //   CommonCustomValidators.GetHFS_GREquipmentVehicleIsAvailable(
      //     this.f.HFServiceId,
      //     this.homefieldApi
      //   )]
      // );

      this.f.HFServiceVehicleStartedKM.setValidators([
        Validators.required,
        Validators.pattern(Globals.NumberNumericRegexWithoutZero)
      ]);
      // this.f.HFServiceVehicleStartedKM.setAsyncValidators([
      //   CommonCustomValidators.GetHFS_IsKmsIsGreaterThanPreviousKms(
      //     this.f.HFServiceVehicleId,
      //     this.f.HFServiceId,
      //     this.homefieldApi
      //   )]
      // );

      this.f.HFServiceVehicleStartTime.setValidators([Validators.required]);
      this.f.HFServiceVehicleInspectionStatus.setValidators([Validators.required]);
      this.f.HFServiceVehicleStartTime.setValue(this.today);
    }
    else if (this.f.HFServiceVisitDetailsEnteredOn.value) {
      this.f.HFServiceVehiclePrepTime.setValidators([Validators.required]);
      this.f.HFServiceVisitNotification.setValidators([Validators.required]);
      this.f.HFServiceVisitPurpose.setValidators([Validators.required]);
    }
    else {
      if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
        this.f.ISVPhoneNumber.setValidators([Validators.required, Validators.pattern(Globals.PhoneNumberRegex)]);
        this.f.ISVAddress.setValidators([Validators.required]);
        this.f.ISVCity.setValidators([Validators.required]);
        // this.f.ISVDirections.setValidators([Validators.required]);
        this.f.ISVPostalCode.setValidators([Validators.pattern(Globals.PostalcodeAlphaRegx), Validators.required]);
        this.f.HFServiceStationName.setValidators([Validators.required]);
      }
      else {
        this.f.HFServiceStationId.setValidators([Validators.required]);
        this.f.HFServiceVisitPurpose.setValidators([Validators.required]);
      }

      if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing' ||
        this.f.HFServiceVisitPurpose.value == 'Equipment Exchange' ||
        this.f.HFServiceVisitPurpose.value == 'Repair') {
        this.f.HFServiceCategory.setValidators([Validators.required]);
      }

      if (this.f.HFServiceVisitPurpose.value == 'Repair') {
        this.f.HFServiceIssue.setValidators([Validators.required]);
      }
    }

    this.updateValidations();
  }

  clearValidations() {
    this.f.HFServiceVisitNotification.clearValidators();
    this.f.HFServiceStationId.clearValidators();
    this.f.HFServiceStationName.clearValidators();
    this.f.HFServiceVisitPurpose.clearValidators();
    this.f.HFServiceVisitDate.clearValidators();
    this.f.HFServiceVehiclePrepTime.clearValidators();
    this.f.HFServiceVehicleStartTime.clearValidators();
    this.f.HFServiceVehicleId.clearValidators();
    this.f.HFServiceVehicleStartedKM.clearValidators();
    this.f.HFServiceVehicleInspectionStatus.clearValidators();
    this.f.HFServicePTHMArrivalTime.clearValidators();
    this.f.HFServicePTHMDepartureTime.clearValidators();
    this.f.HFServicePTHMPurposeCompleted.clearValidators();
    this.f.HFServiceReturnVehicleEndTime.clearValidators();
    this.f.HFServiceReturnVehicleEndedKM.clearValidators();
    this.f.HFServiceFuelAdded.clearValidators();
    this.f.HFServiceFuelCompany.clearValidators();
    this.f.HFServiceFuelAmount.clearValidators();
    this.f.HFServiceFuelPaymentBy.clearValidators();
    this.f.HFServiceFuelReceiptPath.clearValidators();
    this.f.ISVPhoneNumber.clearValidators();
    this.f.ISVAddress.clearValidators();
    this.f.ISVCity.clearValidators();
    this.f.ISVPostalCode.clearValidators();
    this.f.ISVDirections.clearValidators();
    this.f.HFServiceCategory.clearValidators();
    this.f.HFServiceIssue.clearValidators();

    this.updateValidations();

  }

  updateValidations() {
    this.f.HFServiceVisitNotification.updateValueAndValidity();
    this.f.HFServiceStationId.updateValueAndValidity();
    this.f.HFServiceStationName.updateValueAndValidity();
    this.f.HFServiceVisitPurpose.updateValueAndValidity();
    this.f.HFServiceVisitDate.updateValueAndValidity();
    this.f.HFServiceVehiclePrepTime.updateValueAndValidity();
    this.f.HFServiceVehicleStartTime.updateValueAndValidity();
    this.f.HFServiceVehicleId.updateValueAndValidity();
    this.f.HFServiceVehicleStartedKM.updateValueAndValidity();
    this.f.HFServiceVehicleInspectionStatus.updateValueAndValidity();
    this.f.HFServicePTHMArrivalTime.updateValueAndValidity();
    this.f.HFServicePTHMDepartureTime.updateValueAndValidity();
    this.f.HFServicePTHMPurposeCompleted.updateValueAndValidity();
    this.f.HFServiceReturnVehicleEndTime.updateValueAndValidity();
    this.f.HFServiceReturnVehicleEndedKM.updateValueAndValidity();
    this.f.HFServiceFuelAdded.updateValueAndValidity();
    this.f.HFServiceFuelCompany.updateValueAndValidity();
    this.f.HFServiceFuelAmount.updateValueAndValidity();
    this.f.HFServiceFuelPaymentBy.updateValueAndValidity();
    this.f.HFServiceFuelReceiptPath.updateValueAndValidity();
    this.f.ISVPhoneNumber.updateValueAndValidity();
    this.f.ISVAddress.updateValueAndValidity();
    this.f.ISVCity.updateValueAndValidity();
    this.f.ISVPostalCode.updateValueAndValidity();
    this.f.ISVDirections.updateValueAndValidity();
    this.f.HFServiceCategory.updateValueAndValidity();
    this.f.HFServiceIssue.updateValueAndValidity();
  }

  setChildMRMID() {

    setTimeout(() => {

      if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Testing' && this.purposeTesting)
        this.purposeTesting.mrmHomeId = this.f.HFServiceStationId.value;
      else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Initial Site Visit' && this.purposeInitialSiteVisit) {
        this.purposeInitialSiteVisit.insvf.HFServiceId.setValue(this.f.HFServiceId.value);
        this.purposeInitialSiteVisit.currentUserId = this.currentUserId;
        this.purposeInitialSiteVisit.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
        this.purposeInitialSiteVisit.setModel();
      }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation' && this.purposeFullInstallation) {
      //   this.purposeFullInstallation.fif.HFServiceId.setValue(this.f.HFServiceId.value);
      //   this.purposeFullInstallation.fif.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeFullInstallation.currentUserId = this.currentUserId;
      //   this.purposeFullInstallation.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

      //   this.purposeFullInstallation.setModel();
      // }
      else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Uninstallation' && this.purposeUnInstallation) {
        this.purposeUnInstallation.fif.HFServiceId.setValue(this.f.HFServiceId.value);
        this.purposeUnInstallation.fif.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
        this.purposeUnInstallation.currentUserId = this.currentUserId;
        this.purposeUnInstallation.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

        this.purposeUnInstallation.setModel();
      }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Equipment Exchange' && this.purposeEquipmentExchange) {
      //   this.purposeEquipmentExchange.f.HFServiceId.setValue(this.f.HFServiceId.value);
      //   this.purposeEquipmentExchange.f.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeEquipmentExchange.currentUserId = this.currentUserId;
      //   this.purposeEquipmentExchange.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
      //   this.purposeEquipmentExchange.setModel();
      // }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing' && this.purposeMaintenance) {
      //   this.purposeMaintenance.mntf.HFServiceId.setValue(this.f.HFServiceId.value);
      //   this.purposeMaintenance.mntf.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeMaintenance.mntf.dialysisUnitName.setValue(this.MRMHomeStationName);
      //   this.purposeMaintenance.currentUserId = this.currentUserId;
      //   this.purposeMaintenance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
      //   this.purposeMaintenance.expandDefaultSection(this.f.HFServiceCategory.value);
      //   this.purposeMaintenance.setModel();
      // }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Renovation' && this.purposeRenovation) {
      //   this.purposeRenovation.rf.HFServiceID.setValue(this.f.HFServiceId.value);
      //   this.purposeRenovation.rf.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeRenovation.currentUserId = this.currentUserId;
      //   this.purposeRenovation.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
      //   this.purposeRenovation.setModel();
      // }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Repair' && this.purposeRepair) {
      //   this.purposeRepair.RepairType = this.f.HFServiceCategory.value;

      //   if (this.f.HFServiceCategory.value === 'Dialysis Machines') {
      //     setTimeout(() => {
      //       this.HFServicePTHMPurposeCompletedChange();
      //       this.purposeRepair.dmrepair.f.HFServiceId.setValue(this.f.HFServiceId.value);
      //       this.purposeRepair.dmrepair.f.selectedDialysisStation.setValue(this.f.HFServiceStationId.value);
      //       this.purposeRepair.dmrepair.f.remarks.setValue(this.f.HFServiceIssue.value);
      //       this.purposeRepair.dmrepair.selectedDialysisStationtext = this.MRMHomeStationName;
      //       // this.purposeRepair.dmrepair.f.repairStartDate.setValue({
      //       //   date:  this.f.HFServiceVisitDate.value.date,
      //       //   jsdate: this.f.HFServiceVisitDate.value.jsdate
      //       // });
      //       this.purposeRepair.SetModel(this.f.HFServiceId.value);
      //       setTimeout(() => {
      //         this.PartTrans = this.purposeRepair.dmrepair.parts.PartTrans;
      //         console.log(this.PartTrans);
      //         console.log('test parttrans');
      //       }, 3000);

      //       this.homeApi.GetDialysisStationByID({ Id: this.f.HFServiceStationId.value }).subscribe(
      //         success => {
      //           if (success) {
      //             this.purposeRepair.dmrepair.f.selectedEquipment.setValue(success.dmEquipmentId ? success.dmEquipmentId : '');
      //             this.purposeRepair.dmrepair.EquipmentDropDownChanged();
      //           }
      //         },
      //         error => {
      //           AppComponent.addAlert(error.message, 'error');
      //           AppComponent.HideLoader();
      //         }
      //       );
      //     }, 3000);
      //   } else {
      //     setTimeout(() => {
      //       this.HFServicePTHMPurposeCompletedChange();
      //       this.purposeRepair.wprepair.f.HFServiceId.setValue(this.f.HFServiceId.value);
      //       this.purposeRepair.wprepair.f.remarks.setValue(this.f.HFServiceIssue.value);
      //       if (this.f.HFServiceCategory.value === 'Other Equipment') {
      //         this.purposeRepair.wprepair.detailsType = 'Other';
      //       }
      //       this.PartTrans = this.purposeRepair.wprepair.parts.PartTrans;
      //       this.purposeRepair.wprepair.f.selectedRepairEvent.setValue('R');
      //       this.purposeRepair.wprepair.f.timeStarted.setValue({
      //         date: this.f.HFServiceVisitDate.value.date,
      //         jsdate: this.f.HFServiceVisitDate.value.jsdate
      //       });
      //       this.purposeRepair.wprepair.f.formDate.setValue({
      //         date: this.f.HFServiceVisitDate.value.date,
      //         jsdate: this.f.HFServiceVisitDate.value.jsdate
      //       });
      //       this.purposeRepair.wprepair.selectedDialysisStationId = this.f.HFServiceStationId.value;
      //       this.purposeRepair.SetModel(this.f.HFServiceId.value);
      //       this.purposeRepair.wprepair.selectedDialysisStationtext = this.MRMHomeStationName;
      //       this.homeApi.GetDialysisStationByID({ Id: this.f.HFServiceStationId.value }).subscribe(
      //         success => {
      //           if (success) {
      //             this.purposeRepair.wprepair.f.selectedEquipment.setValue(success.roEquipmentId ? success.roEquipmentId : '');
      //             console.log(success.roEquipmentId);
      //             this.purposeRepair.wprepair.EquipmentDropDownChanged();
      //           }
      //         },
      //         error => {
      //           AppComponent.addAlert(error.message, 'error');
      //           AppComponent.HideLoader();
      //         }
      //       );
      //     }, 3000);
      //   }
      // }
      // else if (this.f.HFServiceId.value && this.f.HFServiceStationId.value && this.f.HFServiceVisitPurpose.value == 'Other' && this.purposeOther) {
      //   this.purposeOther.of.HFServiceID.setValue(this.f.HFServiceId.value);
      //   this.purposeOther.of.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
      //   this.purposeOther.currentUserId = this.currentUserId;
      //   this.purposeOther.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
      //   this.purposeOther.setModel();
      // }

      let puposeSelction = this.visitPurposeAllList.filter((item, index) => item.value == this.f.HFServiceVisitPurpose.value);

      if (puposeSelction.length > 0) {
        this.purposeSelectedId = puposeSelction[0].value;
      }

      this.onChangeArrival();


    }, 1000);
  }

  AddHomeUnit() {
    if (this.f.HFServiceOtherStationId.value)
      this.addHomeUnit = true;
  }

  EquipmentCategoryChange() {
    if (this.purposeEquipmentExchange) {
      this.purposeEquipmentExchange.f.EquipmentExchangeType.setValue(this.f.HFServiceCategory.value);

      this.purposeEquipmentExchange.EquipmentExchangeChangeEvent();
    }
    //this.purposeRepair.RepairType = this.f.HFServiceCategory.value;
    //  console.log(this.purposeRepair.RepairType);
  }

  transformHFServiceFuelAmount() {
    if (this.f.HFServiceFuelAmount.value) {
      this.formattedHFServiceFuelAmount = this.currencyPipe.transform(
        this.currenctyToNumber(this.f.HFServiceFuelAmount.value), 'CAD', '$', '1.2-2');
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  charOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) {
      return true;
    }
    return false;
  }

  SendVehicleNotesMailToSupervisor() {
    // this.quickAddDialogRef = this.dialog.open(NoteDialog, {
    //   disableClose: true,
    //   position: { right: '0', top: '0' },
    //   height: '100%',
    //   maxWidth: '100vh',
    //   maxHeight: '100vh',
    //   data: {
    //     showHours: false
    //   }
    // });
    // this.quickAddDialogRef.componentInstance.showHours = false;
    //this.notesVisitVehicles.AddNote();

    this.quickNoteDialogRef = this.dialog.open(HFSNoteDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '35%',
      maxWidth: '120vh',
      maxHeight: '100vh',
      minWidth: '50vh',
    });

    this.quickNoteDialogRef.componentInstance.showHours = false;
    this.quickNoteDialogRef.componentInstance.showParts = false;



    this.quickNoteDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      this.noteModule = "Vehicle Detail (Failure)";
      this.executeHomeFieldServiceCreateUpdate('Section4', true);
    });
    this.quickNoteDialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.f.HFServiceVehicleInspectionStatus.setValue(null);
      }
      this.quickNoteDialogRef = null;
    });
    this.quickNoteDialogRef.componentInstance.PrevNotes = this.VehicleFailureNotes;

    var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.f.HFServiceVehicleId.value);
    if (selectedVehicles.length > 0)
      this.quickNoteDialogRef.componentInstance.VehicleName = selectedVehicles[0].equipmentcode;

  }

  executeHomeFieldServiceCreateUpdate = function (Source = '', isNotes = false, skipValidation = false) {

    AppComponent.loading = true;
    this.formSubmitted = true;

    if (this.notesVisitPurposePlus.Note && this.notesVisitPurposePlus.Note.includes('Other Purpose - '))
      this.AddOtherPurpose(this.notesVisitPurposePlus.Note);

    if (this.f.HFServicePTHMPurposeCompletedOn.value && (this.f.HFServiceFuelAmount.value == '0' || this.f.HFServiceFuelAmount.value == '$0.00'
      || this.f.HFServiceFuelAmount.value == '0.00'
    )) {
      this.f.HFServiceFuelAmount.setValue('');
    }

    // && !this.f.HFServiceVisitDetailsEnteredOn.value
    if (!isNotes || (Source == 'Section2')) {
      if ((this.HomeFieldServiceForm.invalid && !skipValidation) ||
        (Source == 'Section4' && this.isAdditionalAddVehicle) ||
        (Source == 'Section6' && !this.ReturnDetailsEntered) ||
        (Source == 'Section5' && (this.PurposeDetailsEnteredInProgress || !this.PurposeDetailsEntered))) {

        if (Source == 'Section3' && !this.f.HFServiceVehiclePrepTime.valid) {
          this.OpenPrepDetail();
          return;
        }

        if (Source == 'Section4' && this.isAdditionalAddVehicle && this.ReturnDetailsEntered) {
          this.OpenVehicleDetail();
          return;
        }

        if (Source == 'Section5' && !this.PurposeDetailsEntered) {
          this.OpenPurposeDetail();
          return;
        }

        if (Source == 'Section5' && this.PurposeDetailsEnteredInProgress) {

          if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
            this.purposeInitialSiteVisit.save().then(() => {
              if (!this.purposeInitialSiteVisit.ISVForm.invalid && this.PurposeDetailsEnteredInProgress) {
                this.OpenPurposeDetail(true);
                return;
              }
              this.afterSaveEvent(Source, isNotes, skipValidation);
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }
          // else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
          //   this.purposeFullInstallation.save().then(() => {
          //     if (!this.purposeFullInstallation.fullInstallationForm.invalid && this.PurposeDetailsEnteredInProgress) {
          //       this.OpenPurposeDetail(true);
          //       return;
          //     }
          //     this.afterSaveEvent(Source, isNotes, skipValidation);
          //   }, () => {
          //     AppComponent.addAlert("Error", 'error');
          //     AppComponent.HideLoader();
          //   });
          // }
          else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {

            this.purposeUnInstallation.save().then(() => {
              if (!this.purposeUnInstallation.unInstallationForm.invalid && this.PurposeDetailsEnteredInProgress) {

                this.OpenPurposeDetail(true);
                return;
              }
              this.afterSaveEvent(Source, isNotes, skipValidation);
            }, () => {
              AppComponent.addAlert("Error", 'error');
              AppComponent.HideLoader();
            });
          }
          else {
            this.OpenPurposeDetail(true);
            return;
          }
        }




        if (Source == 'Section6' && (!this.f.HFServiceReturnVehicleEndTime.valid || !this.ReturnDetailsEntered)) {
          this.OpenReturnDetail();
          return;
        }

        if (Source == 'Section5' || Source == 'Section2') {
          if (!isNotes || (Source == 'Section5' && ((this.f.HFServiceVisitPurpose.value == 'Initial Site Visit' &&
            !this.purposeInitialSiteVisit.ISVForm.invalid && this.PurposeDetailsEnteredInProgress)
            || (this.f.HFServiceVisitPurpose.value == 'Uninstallation' &&
              !this.purposeUnInstallation.unInstallationForm.invalid && this.PurposeDetailsEnteredInProgress)))) {
            AppComponent.HideLoader();
            this.scrollHelper.scrollToFirst('ng-invalid');
            AppComponent.ScrollDown();
            return;
          }
          else {
            AppComponent.addAlert(Globals.FormErrorMessage, 'error');
            AppComponent.loading = false;
            this.scrollHelper.scrollToFirst('ng-invalid');
            AppComponent.ScrollDown();
            return;
          }
        }
      }
    }
    AppComponent.ShowLoader();
    // if (!this.f.HFServiceVisitPurpose.value ||
    //   (!this.f.HFServiceStationId.value && !this.f.HFServiceStationName.value) ||
    //   !this.f.HFServiceVisitNotification.value || this.f.ISVPostalCode.errors
    //   || this.f.HFServiceVehicleId.errors || this.f.HFServiceVehicleStartedKM.errors || this.f.HFServiceReturnVehicleEndedKM.errors
    // ) {
    //   AppComponent.addAlert(Globals.FormErrorMessage, 'error');
    //   AppComponent.loading = false;

    //   this.scrollHelper.scrollToFirst('ng-invalid');
    //   AppComponent.ScrollDown();
    //   return;
    // }

    this.validateForm = true;

    let skipCurrentSectionSave = (this.getNextSaveSection() == Source || !skipValidation) ? false : true;
    const modelCreate = this.prepareCreateViewModel(Source, isNotes, skipCurrentSectionSave);

    if (this.f.HFServiceId.value === '0'
    ) {
      this.homefieldApi.CreateHomeFieldService_Master(modelCreate).subscribe(
        success => {
          this.f.HFServiceId.value = success.hfServiceId;
          if (Source == "Section5" && !isNotes) {
            if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
              this.purposeInitialSiteVisit.save().then(() => {
                this.afterSaveEvent(Source, isNotes, skipValidation);
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
              // this.purposeFullInstallation.save().then(() => {
              this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {
              //this.afterSaveEvent(Source, isNotes, skipValidation);
              this.purposeUnInstallation.save().then(() => {
                this.afterSaveEvent(Source, isNotes, skipValidation);
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {

              // if (this.f.HFServicePTHMPurposeCompleted.value == "Yes" && this.f.HFServiceStatus.value != "Completed")
              //   this.purposeEquipmentExchange.purposeCompleted = true;


              this.afterSaveEvent(Source, isNotes, skipValidation);
              // this.purposeEquipmentExchange.save().then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing') {
              this.afterSaveEvent(Source, isNotes, skipValidation);
              // this.purposeMaintenance.save('').then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            // else if (this.f.HFServiceVisitPurpose.value == 'Renovation') {
            //   this.purposeRenovation.save().then(() => {
            //     this.afterSaveEvent(Source, isNotes,skipValidation);
            //   }, () => {
            //     AppComponent.addAlert("Error", 'error');
            //     AppComponent.HideLoader();
            //   });
            // }
            else if (this.f.HFServiceVisitPurpose.value == 'Other') {
              this.afterSaveEvent(Source, isNotes, skipValidation);
              // this.purposeOther.save().then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Repair') {
              //this.purposeRepair.save(this.f.HFServiceId.value);
              // .then(() => {
              //   this.afterSaveEvent(Source, isNotes,skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else {
              this.afterSaveEvent(Source, isNotes, skipValidation);
            }
          }
          else {
            this.afterSaveEvent(Source, isNotes, skipValidation);
          }
        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
            this.scrollHelper.scrollToFirst('logo');
          }
          else
            AppComponent.addAlert(error.message, 'error');

          AppComponent.HideLoader();
        }
      );
    } else {
      AppComponent.HideLoader();
      this.homefieldApi.UpdateHomeFieldService_Master(modelCreate).subscribe(
        success => {

          if (Source == "Section5" && !isNotes) {
            if (this.f.HFServiceVisitPurpose.value == 'Initial Site Visit') {
              this.purposeInitialSiteVisit.save().then(() => {
                this.afterSaveEvent(Source, isNotes, skipValidation);
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation') {
              // this.purposeFullInstallation.save().then(() => {
              this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Uninstallation') {
              // this.afterSaveEvent(Source, isNotes, skipValidation);
              this.purposeUnInstallation.save().then(() => {
                this.afterSaveEvent(Source, isNotes, skipValidation);
              }, () => {
                AppComponent.addAlert("Error", 'error');
                AppComponent.HideLoader();
              });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {

              // if (this.f.HFServicePTHMPurposeCompleted.value == "Yes" && this.f.HFServiceStatus.value != "Completed")
              //   this.purposeEquipmentExchange.purposeCompleted = true;

              this.afterSaveEvent(Source, isNotes, skipValidation);

              // this.purposeEquipmentExchange.save().then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing') {
              this.afterSaveEvent(Source, isNotes, skipValidation);

              // this.purposeMaintenance.save().then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            // else if (this.f.HFServiceVisitPurpose.value == 'Renovation') {
            //   this.purposeRenovation.save().then(() => {
            //     this.afterSaveEvent(Source, isNotes,skipValidation);
            //   }, () => {
            //     AppComponent.addAlert("Error", 'error');
            //     AppComponent.HideLoader();
            //   });
            // }
            else if (this.f.HFServiceVisitPurpose.value == 'Other') {
              this.afterSaveEvent(Source, isNotes, skipValidation);

              // this.purposeOther.save().then(() => {
              //   this.afterSaveEvent(Source, isNotes, skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else if (this.f.HFServiceVisitPurpose.value == 'Repair') {
              this.afterSaveEvent(Source, isNotes, skipValidation);
              // this.purposeRepair.save(this.f.HFServiceId.value);
              // .then(() => {
              //   this.afterSaveEvent(Source, isNotes,skipValidation);
              // }, () => {
              //   AppComponent.addAlert("Error", 'error');
              //   AppComponent.HideLoader();
              // });
            }
            else {
              this.afterSaveEvent(Source, isNotes, skipValidation);
            }
          }
          else {
            this.afterSaveEvent(Source, isNotes, skipValidation);
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }

    // this.ExpandSection(Source, false);
    AppComponent.HideLoader();
  }


  afterSaveEvent(Source, isNotes, stopRedirect) {

    AppComponent.loading = false;
    AppComponent.addAlert('Data Saved successfully!', 'success');
    // if (isNotes != true || (this.noteModule == 'Vehicle Detail (Failure)' && isNotes)) {
    //   this.router.navigate([this.ListURL]);
    // }

    if ((!stopRedirect && !isNotes) || (isNotes && Source == 'Section2' && !this.f.HFServiceVisitDetailsEnteredOn.value)) {
      this.router.navigate([this.ListURL]);
    } else {
      this.ExpandSection(Source, false);
      setTimeout(() => {
        this.setModel();
      });
    }
  }

  formatPhone(phone) {
    let val = this.f.ISVPhoneNumber.value;
    if (val.indexOf('(') === -1) {
      if (val.length >= 10) {
        val =
          '(' +
          val.substring(0, 3) +
          ') ' +
          val.substring(3, 6) +
          '-' +
          val.substring(6, 10);
      }
    }
    this.f.ISVPhoneNumber.setValue(val);
  }

  forceInputUppercase(e) {
    this.commonapi.forceInputUppercase(e);
  }

  lostfocusPostalCode() {
    this.f.ISVPostalCode.setValue(this.f.ISVPostalCode.value.toUpperCase().replace(/\s/g, ''));
    if (this.f.ISVPostalCode.value.indexOf(' ') == -1 && this.f.ISVPostalCode.value.length == 6) {
      let First3 = this.f.ISVPostalCode.value.slice(0, 3);
      let Last3 = this.f.ISVPostalCode.value.slice(3, 6);

      this.f.ISVPostalCode.setValue(First3 + ' ' + Last3);
    }
  }

  prepareCreateViewModel(Source = "", isNotes, skipSaveCurrentSection) {
    const modelCreate = <any>{};
    modelCreate.HfServiceId = this.f.HFServiceId.value;
    modelCreate.HFServiceStationId = this.f.HFServiceStationId.value;

    if (this.f.HFServiceId.value != '0')
      modelCreate.HFServiceStationName = this.MRMHomeStationName;
    else
      modelCreate.HFServiceStationName = this.nextStationIdPrefix + this.f.HFServiceStationName.value.toUpperCase();

    modelCreate.HFServiceVisitPurpose = this.f.HFServiceVisitPurpose.value;
    modelCreate.HFServiceVisitNotification = this.f.HFServiceVisitNotification.value;
    modelCreate.HFServiceCode = this.f.HFServiceCode.value;

    if (this.f.HFServiceVisitDate.value) {
      modelCreate.HFServiceVisitDate = Globals.GetAPIDateWithoutSeconds(this.f.HFServiceVisitDate.value.jsdate);
    }

    modelCreate.ISVStationId = this.f.ISVStationId.value;
    modelCreate.ISVPhoneNumber = this.f.ISVPhoneNumber.value;
    modelCreate.ISVAddress = this.f.ISVAddress.value;
    modelCreate.ISVProvince = this.f.ISVProvince.value;
    modelCreate.ISVCity = this.f.ISVCity.value;
    modelCreate.ISVPostalCode = (this.f.ISVPostalCode.value) ? this.f.ISVPostalCode.value.toUpperCase() : '';
    modelCreate.ISVDirections = this.f.ISVDirections.value;
    modelCreate.ISVAccessCode = this.f.ISVAccessCode.value;

    if (this.f.HFServiceVisitPurpose.value) {
      modelCreate.HFServiceVisitDetailsEnteredOn = Globals.GetAPIDate(this.today);
      modelCreate.HFServiceVisitDetailsEnteredBy = this.currentUserId;
    }
    else {
      modelCreate.HFServiceVisitDetailsEnteredOn = this.f.HFServiceVisitDetailsEnteredOn.value;
      modelCreate.HFServiceVisitDetailsEnteredBy = this.f.HFServiceVisitDetailsEnteredBy.value;
    }

    modelCreate.HFServiceVehiclePrepTime = this.f.HFServiceVehiclePrepTime.value;

    if (this.f.HFServiceVehiclePrepTime.value != '' && this.f.HFServiceVisitNotification.value && !skipSaveCurrentSection) {
      modelCreate.HFServiceVehiclePrepDetailsEnteredOn = Globals.GetAPIDate(this.today);
      modelCreate.HFServiceVehiclePrepDetailsEnteredBy = this.currentUserId;
    }
    else {
      modelCreate.HFServiceVehiclePrepDetailsEnteredOn = this.f.HFServiceVehiclePrepDetailsEnteredOn.value;
      modelCreate.HFServiceVehiclePrepDetailsEnteredBy = this.f.HFServiceVehiclePrepDetailsEnteredBy.value;
    }

    modelCreate.poPlumber = this.f.poPlumber.value;
    modelCreate.poElectrician = this.f.poElectrician.value;
    modelCreate.poMovers = this.f.poMovers.value;
    modelCreate.poPlumberNote = this.f.poPlumberNote.value;
    modelCreate.poElectricianNote = this.f.poElectricianNote.value;
    modelCreate.poMoversNote = this.f.poMoversNote.value;

    modelCreate.HFServiceVehicleStartTime = Globals.GetAPIDateWithoutSeconds(this.f.HFServiceVehicleStartTime.value);
    modelCreate.HFServiceVehicleId = this.f.HFServiceVehicleId.value;

    var selectedVehicles = this.VehicleList.filter((item, index) => item.equipementid.toString() == this.f.HFServiceVehicleId.value);

    if (selectedVehicles.length > 0)
      modelCreate.HFServiceVehicleName = selectedVehicles[0].equipmentcode;

    // modelCreate.HFServiceVehicleName = this.f.HFServiceVehicleName.value;
    modelCreate.HFServiceVehicleStartedKM = this.f.HFServiceVehicleStartedKM.value;
    modelCreate.HFServiceVehicleInspectionStatus = this.f.HFServiceVehicleInspectionStatus.value;
    //
    if (this.f.HFServiceVehicleStartTime.value && this.f.HFServiceVehicleStartedKM.value && this.f.HFServiceVehicleId.value
      && this.f.HFServiceVehicleInspectionStatus.valid && !skipSaveCurrentSection) {
      modelCreate.HFServiceVehicleDetailsEnteredOn = Globals.GetAPIDate(this.today);
      modelCreate.HFServiceVehicleDetailsEnteredBy = this.currentUserId;
    }
    else {
      modelCreate.HFServiceVehicleDetailsEnteredOn = this.f.HFServiceVehicleDetailsEnteredOn.value;
      modelCreate.HFServiceVehicleDetailsEnteredBy = this.f.HFServiceVehicleDetailsEnteredBy.value;
    }

    modelCreate.HFServicePTHMArrivalTime = this.f.HFServicePTHMArrivalTime.value;
    modelCreate.HFServicePTHMDepartureTime = this.f.HFServicePTHMDepartureTime.value;
    modelCreate.HFServicePTHMPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    modelCreate.HFServiceReturnVehicleEndTime = this.f.HFServiceReturnVehicleEndTime.value;
    modelCreate.HFServiceReturnVehicleEndedKM = this.f.HFServiceReturnVehicleEndedKM.value;
    modelCreate.HFServiceFuelAdded = this.f.HFServiceFuelAdded.value;
    modelCreate.HFServiceFuelCompany = this.f.HFServiceFuelCompany.value;
    modelCreate.HFServiceFuelAmount = this.currenctyToNumber(this.f.HFServiceFuelAmount.value);
    modelCreate.HFServiceFuelPaymentBy = this.f.HFServiceFuelPaymentBy.value;

    modelCreate.HFServiceFuelReceiptPath = this.f.HFServiceFuelReceiptPath.value;
    modelCreate.HFServiceCategory = this.f.HFServiceCategory.value;
    modelCreate.HFServiceIssue = this.f.HFServiceIssue.value;


    if (this.f.HFServiceStatus.value === 'Completed') {
      modelCreate.HFServiceStatus = this.f.HFServiceStatus.value;
      modelCreate.HFServiceReturnDetailsEnteredOn = this.f.HFServiceReturnDetailsEnteredOn.value;
      modelCreate.HFServiceReturnDetailsEnteredBy = this.f.HFServiceReturnDetailsEnteredBy.value;
    } else if (this.f.HFServiceReturnVehicleEndedKM.value && this.f.HFServiceReturnVehicleEndTime.value
      && this.f.HFServiceFuelAdded.value
    ) {
      if (this.f.HFServiceFuelAdded.value == "Yes" && !skipSaveCurrentSection) {

        modelCreate.HFServiceReturnDetailsEnteredOn = Globals.GetAPIDate(this.today);
        modelCreate.HFServiceReturnDetailsEnteredBy = this.currentUserId;
        if (this.f.HFServiceFuelCompany.value && this.f.HFServiceFuelAmount.value && this.f.HFServiceFuelPaymentBy.value
          && this.f.HFServicePTHMPurposeCompleted.value == "Yes" && (this.ReturnDetailsEntered || (Source == "Section6" && !isNotes))) {
          modelCreate.HFServiceStatus = 'Completed';
        }
        else {
          modelCreate.HFServiceStatus = 'In Progress';
        }
      }
      else {
        if (this.f.HFServicePTHMPurposeCompleted.value == "Yes" && (this.ReturnDetailsEntered || (Source == "Section6" && !isNotes)) && !skipSaveCurrentSection) {
          modelCreate.HFServiceStatus = 'Completed';
          modelCreate.HFServiceReturnDetailsEnteredOn = Globals.GetAPIDate(this.today);
          modelCreate.HFServiceReturnDetailsEnteredBy = this.currentUserId;
        }
        else {
          modelCreate.HFServiceStatus = 'In Progress';
          if (this.f.HFServicePTHMPurposeCompleted.value) {
            modelCreate.HFServiceReturnDetailsEnteredOn = Globals.GetAPIDate(this.today);
            modelCreate.HFServiceReturnDetailsEnteredBy = this.currentUserId;
          }
          else {
            modelCreate.HFServiceReturnDetailsEnteredOn = this.f.HFServiceReturnDetailsEnteredOn.value;
            modelCreate.HFServiceReturnDetailsEnteredBy = this.f.HFServiceReturnDetailsEnteredBy.value;
          }
        }
      }
    }
    else if (this.f.HFServiceVehiclePrepTime.value) {
      if (this.f.HFServiceVisitNotification.value === 'Spoke') {
        //modelCreate.HFServiceStatus = 'In Progress';
        modelCreate.HFServiceStatus = 'Pending';
      }
      else {
        //modelCreate.HFServiceStatus = 'Pending';
        modelCreate.HFServiceStatus = 'Not Started';
      }

      if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value) {
        modelCreate.HFServiceStatus = 'In Progress';
      }
      modelCreate.HFServiceReturnDetailsEnteredOn = this.f.HFServiceReturnDetailsEnteredOn.value;
      modelCreate.HFServiceReturnDetailsEnteredBy = this.f.HFServiceReturnDetailsEnteredBy.value;

    }
    else {
      //modelCreate.HFServiceStatus = 'Pending';
      modelCreate.HFServiceStatus = 'Not Started';
      modelCreate.HFServiceReturnDetailsEnteredOn = this.f.HFServiceReturnDetailsEnteredOn.value;
      modelCreate.HFServiceReturnDetailsEnteredBy = this.f.HFServiceReturnDetailsEnteredBy.value;
    }

    if (this.f.HFServiceVehiclePrepTime.value && this.f.HFServiceVisitNotification.value === 'Spoke' &&
      !this.f.HFServiceVisitNotificationEnteredOn.value) {
      modelCreate.HFServiceVisitNotificationEnteredOn = Globals.GetAPIDate(this.today);
      modelCreate.HFServiceVisitNotificationEnteredBy = this.currentUserId;
    }
    else {
      modelCreate.HFServiceVisitNotificationEnteredOn = this.f.HFServiceVisitNotificationEnteredOn.value;
      modelCreate.HFServiceVisitNotificationEnteredBy = this.f.HFServiceVisitNotificationEnteredBy.value;
    }
    modelCreate.HFServiceCreatedOn = Globals.GetAPIDate(this.today);
    modelCreate.HFServiceCreatedBy = this.currentUserId;
    modelCreate.HFServiceModifiedOn = Globals.GetAPIDate(this.today);
    modelCreate.HFServiceModifiedBy = this.currentUserId;

    modelCreate.HomeFieldService_Technologists = [];

    if (this.f.HFServicePTHMPurposeCompleted.value) {
      modelCreate.HFServicePTHMPurposeCompletedOn = Globals.GetAPIDate(this.today);
      modelCreate.HFServicePTHMPurposeCompletedBy = this.currentUserId;
    }
    else {
      modelCreate.HFServicePTHMPurposeCompletedOn = this.f.HFServicePTHMPurposeCompletedOn.value;
      modelCreate.HFServicePTHMPurposeCompletedBy = this.f.HFServicePTHMPurposeCompletedBy.value;
    }

    modelCreate.HFServiceVehicleTechnologistId = this.f.HFServiceVehicleTechnologistId.value;

    modelCreate.isHFSReferenced = this.f.IsReferencedHFS.value;
    modelCreate.TaskId = this.f.TaskId.value;
    modelCreate.HFServiceVehicleTechnologistName = "";
    if (this.f.HFServiceVehicleTechnologistId.value) {
      this.f.HFServiceVehicleTechnologistId.value.forEach((tech, newKey) => {
        var o = <any>{};
        o.HFServiceId = this.f.HFServiceId.value;
        o.TechnologistId = tech.value;
        o.CreatedOn = this.today;
        o.CreatedBy = this.currentUserId;
        o.ModifiedOn = this.today;
        o.ModifiedBy = this.currentUserId;
        modelCreate.HomeFieldService_Technologists.push(o);

        modelCreate.HFServiceVehicleTechnologistName += tech.text;

        if ((newKey + 1) != this.f.HFServiceVehicleTechnologistId.value.length)
          modelCreate.HFServiceVehicleTechnologistName += ", ";
      });
    }

    switch (Source) {
      case "Section2": {
        modelCreate.note = this.notesVisitPurpose.Note;
        modelCreate.noteModule = "Purpose Detail";
        if (!isNotes || (isNotes && !this.f.HFServiceVisitDetailsEnteredOn.value))
          this.f.HFServicelastModifiedSection.setValue("Pending");
        break;
      }
      case "Section3": {
        modelCreate.note = this.notesVisitPrep.Note;
        modelCreate.noteModule = "Prep Detail";

        if (!isNotes && (this.f.HFServicelastModifiedSection.value == 'ReturnDetails' || this.f.HFServicelastModifiedSection.value == 'Pending')) {
          if (this.f.HFServiceVehiclePrepTime.value && this.f.HFServiceVisitNotification.value === 'Spoke')
            this.f.HFServicelastModifiedSection.setValue("VisitPrepDetails");
          else
            this.f.HFServicelastModifiedSection.setValue("Pending");
        }
        break;
      }
      case "Section4": {
        modelCreate.note = this.notesVisitVehicles.Note;

        if (this.noteModule) {
          modelCreate.noteModule = this.noteModule;
          modelCreate.note = this.quickNoteDialogRef.componentInstance.notes;
        }
        else
          modelCreate.noteModule = "Vehicle Detail";

        // this.noteModule = "";
        if (!isNotes) {
          if (this.VehicleDetailsList.length > 0)
            this.f.HFServicelastModifiedSection.setValue("VisitVehicleDetails");
          else
            this.f.HFServicelastModifiedSection.setValue("VisitPrepDetails");
        }
        break;
      }
      case "Section5": {
        // if (this.f.HFServiceVisitPurpose.value != 'Repair') {
        // this.notesVisitPurposePlusChildren.forEach((child) => {
        //   if (child.Note) {
        //     modelCreate.note = child.Note;
        //   }
        // });
        // }
        modelCreate.note = this.notesVisitPurposePlus.Note;
        if (this.noteModule)
          modelCreate.noteModule = this.noteModule;
        else
          modelCreate.noteModule = "Visit Purpose Detail";

        if (!isNotes) {
          if (this.f.HFServicePTHMPurposeCompleted.value)
            this.f.HFServicelastModifiedSection.setValue("VisitPurposePlusDetails");
          else
            this.f.HFServicelastModifiedSection.setValue("VisitVehicleDetails");
        }
        break;
      }
      case "Section6": {
        modelCreate.note = this.notesReturnDetails.Note;
        modelCreate.noteModule = "Return Details";
        if (this.VehicleDetailsList.length > 0 && (!isNotes || !this.f.HFServiceReturnDetailsEnteredOn.value)) {
          if (this.VehicleDetailsList.filter(x => x.hfServiceVehicleEndTime == null) && this.VehicleDetailsList.filter(x => x.hfServiceVehicleEndTime == null)[0])
            modelCreate.HFServiceVehicleDetailId = this.VehicleDetailsList.filter(x => x.hfServiceVehicleEndTime == null)[0].hfServiceVehicleDetailId;
        }

        if (!isNotes) {
          if (this.f.HFServicePTHMPurposeCompletedOn.value)
            this.f.HFServicelastModifiedSection.setValue("ReturnDetails");
          else
            this.f.HFServicelastModifiedSection.setValue("VisitPurposePlusDetails");
        }
        break;
      }
      default: {
        if (this.f.HFServiceStatus.value == 'Completed')
          this.f.HFServicelastModifiedSection.setValue("Completed");
        break;
      }
    }

    modelCreate.HFServicelastModifiedSection = this.f.HFServicelastModifiedSection.value;
    if (this.f.HFServiceVisitPurpose.value != 'Repair') {
      var attachmentVisitPurposePlus = this.attachmentVisitPurposePlus.GenerateAttachmentList();
      var attachmentReturnDetails = this.attachmentReturnDetails.GenerateAttachmentList();
      modelCreate.attachmentDetails = attachmentVisitPurposePlus.concat(attachmentReturnDetails);
    }


    let returnlength = this.ReturnDetailsList && this.ReturnDetailsList.length ? ++this.ReturnDetailsList.length : 0;
    if (modelCreate.HFServiceStatus && modelCreate.HFServiceStatus.includes("In Progress")
      && this.ReturnDetailsList && returnlength > 1) {
      var count = this.ordinal_suffix_of(returnlength);
      modelCreate.HFServiceStatus = "In Progress (" + count + ")";
    }
    return modelCreate;
  }

  ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  setModel() {
    AppComponent.ShowLoader();
    this.fillDialysisStations();
    // this.fillOtherDialysisStations();
    this.homefieldApi.GetHomeFieldService_MasterByID({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        if (success) {
          if (success.masterHFSCodes)
            this.MasterHFSCodes = success.masterHFSCodes

          // Visit Purpose Details
          if (success.hfServiceVisitDate) {

            const tempDate = new Date(success.hfServiceVisitDate);

            // let copy = this.getCopyOfOptions();
            // copy.disableUntil = {
            //   year: tempDate.getFullYear(),
            //   month: tempDate.getMonth() + 1,
            //   day: tempDate.getDate() - 2
            // };
            // this.myOptions = copy;

            this.f.HFServiceVisitDate.setValue({
              date: {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth() + 1,
                day: tempDate.getDate()
              },
              jsdate: success.hfServiceVisitDate
            });
            this.HFServiceVisitDateLabel = success.hfServiceVisitDate;


          }

          this.HFServicePrepUser = success.hfServicePrepUser;
          if (success.hfServiceFormUser)
            this.UserName = success.hfServiceFormUser;
          else
            this.UserName = 'Auto';
          this.MRMHomeStationName = success.stationName;
          this.HFServiceCode = success.hfServiceCode;
          this.f.HFServiceCode.setValue(success.hfServiceCode);
          this.f.HFServiceStationId.setValue(success.hfServiceStationId);
          this.f.HFServiceVisitPurpose.setValue(success.hfServiceVisitPurpose);
          this.f.HFServiceVisitNotification.setValue(success.hfServiceVisitNotification);
          this.f.HFServiceCreatedOn.setValue(success.hfServiceCreatedOn);
          this.f.HFServiceCreatedBy.setValue(success.hfServiceCreatedBy);
          this.f.HFServiceStatus.setValue(success.hfServiceStatus);
          this.f.HFServiceVisitNotificationEnteredOn.setValue(success.hfServiceVisitNotificationEnteredOn);
          this.f.HFServiceVisitNotificationEnteredBy.setValue(success.hfServiceVisitNotificationEnteredBy);


          if (this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation')
            this.visitPurposeWithoutRenovationList = this.visitPurposeRenovationList;

          this.f.HFServiceIssue.setValue(success.hfServiceIssue);

          this.f.ISVStationId.setValue(success.isvStationId);
          this.f.ISVPhoneNumber.setValue(success.isvPhoneNumber);
          this.f.ISVAddress.setValue(success.isvAddress);
          if (success.isvProvince == null)
            this.f.ISVProvince.setValue('Ontario');
          else
            this.f.ISVProvince.setValue(success.isvProvince);

          this.f.ISVCity.setValue(success.isvCity);
          this.f.ISVPostalCode.setValue(success.isvPostalCode);
          this.f.ISVDirections.setValue(success.isvDirections);
          this.f.ISVAccessCode.setValue(success.isvAccessCode);

          // this.f.HFServiceVehicleTechnologistId.setValue(success.hfServiceVehicleTechnologistId);

          this.f.HFServiceVisitDetailsEnteredOn.setValue(success.hfServiceVisitDetailsEnteredOn);
          this.f.HFServiceVisitDetailsEnteredBy.setValue(success.hfServiceVisitDetailsEnteredBy);

          // Visit Prep Details
          this.f.HFServiceVehiclePrepTime.setValue(success.hfServiceVehiclePrepTime);

          this.f.HFServiceVehiclePrepDetailsEnteredOn.setValue(success.hfServiceVehiclePrepDetailsEnteredOn);
          this.f.HFServiceVehiclePrepDetailsEnteredBy.setValue(success.hfServiceVehiclePrepDetailsEnteredBy);

          // if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value) {
          //   this.f.HFServiceOtherStationId.disable();
          //   document.getElementById("btnAddOtherStation").style.pointerEvents = "none";
          // }
          // Visit Vehicle Details
          //this.f.HFServiceVehicleStartTime.setValue(this.homefieldApi.convertDateTimeToTime(success.hfServiceVehicleStartTime));
          this.f.HFServiceVehicleStartTime.setValue(success.hfServiceVehicleStartTime);

          if (success.hfServiceVehicleId)
            this.f.HFServiceVehicleId.setValue(success.hfServiceVehicleId);

          this.f.HFServiceVehicleName.setValue(success.hfServiceVehicleName);
          this.f.HFServiceVehicleStartedKM.setValue(success.hfServiceVehicleStartedKM);
          if (success.hfServiceVehicleInspectionStatus != null) {
            this.HFServiceVehicleInspectionStatusValue = success.hfServiceVehicleInspectionStatus.toString();
            this.f.HFServiceVehicleInspectionStatus.setValue(this.HFServiceVehicleInspectionStatusValue);
          }

          this.f.HFServiceVehicleDetailsEnteredOn.setValue(success.hfServiceVehicleDetailsEnteredOn);
          this.f.HFServiceVehicleDetailsEnteredBy.setValue(success.hfServiceVehicleDetailsEnteredBy);

          if (this.f.HFServiceVehicleDetailsEnteredOn.value) {
            if (!success.hfServiceVisitDate) {
              this.f.HFServiceVisitDate.setValue({
                date: {
                  year: this.today.getFullYear(),
                  month: this.today.getMonth() + 1,
                  day: this.today.getDate()
                },
                jsdate: this.today
              });

              this.HFServiceVisitDateLabel = this.f.HFServiceVisitDate.value.jsdate;

            }

            if (document.getElementById("HFServiceVehicleInspectionStatusId"))
              document.getElementById("HFServiceVehicleInspectionStatusId").style.pointerEvents = "none";
          }
          // Visit Inner Purpose Details

          if (success.hfServicePTHMArrivalTime && success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMArrivalTime.setValue(success.hfServicePTHMArrivalTime);
          else if (success.hfServicePTHMArrivalTime && !success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMArrivalTime.setValue(this.homefieldApi.convertDateTimeToTime(success.hfServicePTHMArrivalTime));

          this.f.HFServicePTHMDepartureTime.setValue(success.hfServicePTHMDepartureTime);
          this.f.HFServicePTHMPurposeCompleted.setValue(success.hfServicePTHMPurposeCompleted);

          if (success.hfServicePTHMPurposeCompletedOn)
            this.f.HFServicePTHMPurposeCompletedOn.setValue(success.hfServicePTHMPurposeCompletedOn);

          // Return Details
          //this.f.HFServiceReturnVehicleEndTime.setValue(this.homefieldApi.convertDateTimeToTime(success.hfServiceReturnVehicleEndTime));

          if (success.hfServiceReturnVehicleEndTime)
            this.f.HFServiceReturnVehicleEndTime.setValue(success.hfServiceReturnVehicleEndTime);

          this.f.HFServiceReturnVehicleEndedKM.setValue(success.hfServiceReturnVehicleEndedKM);
          this.f.HFServiceFuelAdded.setValue(success.hfServiceFuelAdded);
          this.f.HFServiceFuelCompany.setValue(success.hfServiceFuelCompany);
          this.f.HFServiceFuelAmount.setValue(success.hfServiceFuelAmount);
          this.f.HFServiceFuelPaymentBy.setValue(success.hfServiceFuelPaymentBy);
          this.f.HFServiceReturnDetailsEnteredOn.setValue(success.hfServiceReturnDetailsEnteredOn);
          this.f.HFServiceReturnDetailsEnteredBy.setValue(success.hfServiceReturnDetailsEnteredBy);
          this.f.HFServiceCategory.setValue(success.hfServiceCategory);
          this.f.HFServiceTaskCategory.setValue(success.hfServiceTaskCategory);
          this.f.TaskId.setValue(success.taskId);

          this.f.HFServiceFuelReceiptPath.setValue(success.hfServiceFuelReceiptPath);
          this.GetImagePath();


          this.f.poPlumber.setValue(success.poPlumber ? success.poPlumber : 'N/A');
          this.f.poElectrician.setValue(success.poElectrician ? success.poElectrician : 'N/A');
          this.f.poMovers.setValue(success.poMovers ? success.poMovers : 'N/A');
          this.f.poPlumberNote.setValue(success.poPlumberNote ? success.poPlumberNote : '');
          this.f.poElectricianNote.setValue(success.poElectricianNote ? success.poElectricianNote : '');
          this.f.poMoversNote.setValue(success.poMoversNote ? success.poMoversNote : '');

          //this.f.IsReferencedHFS.setValue(success.isReferencedHFS == 0 ? false:true);
          this.f.IsReferencedHFS.setValue(success.isHFSReferenced);
          if (this.f.HFServiceVisitPurpose.value == "Repair")
            this.fetchRepairParts();


          setTimeout(() => {

            // this.homeUnitChange();
            this.clearNotes();
            this.fetchNotes();
            this.fetchAttachments();
            if (success.hfServiceCategory) {
              this.EquipmentCategoryChange();
            }


            this.setChildMRMID();
            this.fetchTechnologiest();
            this.transformHFServiceFuelAmount();
            this.fetchPrepDetails();
            this.fetchVehicleDetails();
            this.fetchPurposeSectionDetails();
            this.fetchReturnSectionDetails();
            this.fetchCompletedPurposes();
            this.getTechTestingTime();
            this.getISVStationDetails();
            this.fetchReferenceHFS_Purposes().then(() => {
              setTimeout(() => {

                this.f.HFServicelastModifiedSection.setValue(success.hfServicelastModifiedSection);
                this.formSubmitted = false;

                if (this.f.HFServiceStatus.value == 'Completed' || !this.isAnyPanelEnable) {
                  if (this.f.HFServiceStatus.value == 'Completed') {
                    this.ExpandSection("Section6", false);
                  }
                  else if (this.f.HFServicePTHMPurposeCompletedOn.value) {
                    if (success.hfServicelastModifiedSection == "ReturnDetails") {
                      this.ExpandSection("Section2", false);
                      // this.OpenPrepDetail();
                    }
                    if (success.hfServicelastModifiedSection == "VisitPrepDetails") {
                      this.ExpandSection("Section3", false);
                      this.f.HFServiceVehicleId.setValue('');
                      // this.OpenVehicleDetail();
                    }
                    if (success.hfServicelastModifiedSection == "VisitVehicleDetails") {
                      this.ExpandSection("Section4", false);
                      // if(!this.PurposeDetailsEntered && !this.PurposeDetailsEnteredInProgress)
                      //   this.OpenPurposeDetail();
                      // else if(this.PurposeDetailsEnteredInProgress)
                      //   this.OpenPurposeDetail(true);
                    }
                    if (success.hfServicelastModifiedSection == "VisitPurposePlusDetails") {
                      this.ExpandSection("Section5", false);
                      // this.OpenReturnDetail();
                    }
                  }
                  else if (this.f.HFServiceVehicleDetailsEnteredOn.value) {
                    this.ExpandSection("Section4", false);
                    // if(!this.f.HFServicePTHMArrivalTime.value)
                    //   this.OpenPurposeDetail();
                    // else
                    //   this.OpenPurposeDetail(true);
                  }
                  else if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke') {
                    this.ExpandSection("Section3", false);
                    // this.OpenVehicleDetail();
                  }
                  else if (this.f.HFServiceVisitDetailsEnteredOn.value) {
                    this.ExpandSection("Section2", false);
                    // this.OpenPrepDetail();
                  }
                  else {
                    this.ExpandSection("Section1", false);
                  }

                }
                this.HFServicePTHMPurposeCompletedChange();
                if (this.f.HFServiceStatus.value == 'Completed') {
                  this.TotalCalculatedKMs = this.f.HFServiceReturnVehicleEndedKM.value - this.f.HFServiceVehicleStartedKM.value;
                }

                if (this.f.HFServiceStatus.value !== 'Completed') {
                  this.SetValidations();
                }

                this.isBluePanel();
                this.fetchPurposeSectionDetails();
                this.fetchReturnSectionDetails();


                if (this.f.HFServiceStatus.value == 'Completed') {
                  // setTimeout(() => {
                  this.calculateTotalTime();
                  this.calculateTotalTechTime();
                  // }, 500);
                }
              }, 1500);
            });
          }, 500);

          // switch (success.hfServicelastModifiedSection) {
          //   case "Pending": {
          //     this.ExpandSection("Section1", false);
          //     break;
          //   }
          //   case "VisitPurposeDetails": {
          //     this.ExpandSection("Section2", false);
          //     break;
          //   }
          //   case "VisitPrepDetails": {
          //     this.ExpandSection("Section3", false);
          //     break;
          //   }
          //   case "VisitVehicleDetails": {
          //     this.ExpandSection("Section4", false);
          //     break;
          //   }
          //   case "VisitPurposePlusDetails": {
          //     this.ExpandSection("Section5", false);
          //     break;
          //   }
          //   case "ReturnDetails": {
          //     this.ExpandSection("Section6", false);
          //     break;
          //   }
          //   case "Completed": {
          //     this.ExpandSection("Section6", false);
          //     break;
          //   }
          //   default: {
          //     this.isCollapsed.Section1 = true;
          //     break;
          //   }
          // }
        }

        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
    // AppComponent.HideLoader();
  }

  techTestingTime = 0;
  getTechTestingTime() {
    this.homefieldApi.GetHomeFieldService_MasterTechTestingTime({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        if (success) {

          this.techTestingTime = success;
        }
      },
      error => {
        AppComponent.HideLoader();
      });
  }

  getDepartureTime() {
    if (Date.parse(this.f.HFServicePTHMDepartureTime.value))
      return this.datePipe.transform(this.f.HFServicePTHMDepartureTime.value, 'dd/MM/yyyy HH:mm');
    else
      return this.f.HFServicePTHMDepartureTime.value;
  }

  DeleteEvent() {

  }

  HFServicePTHMPurposeCompletedChange() {
    //
    // console.log(this.f.HFServicePTHMPurposeCompleted.value);
    if (this.f.HFServiceVisitPurpose.value === 'Repair') {
      if (this.f.HFServiceCategory.value === 'Dialysis Machines') {
        if (this.purposeRepair && this.purposeRepair.dmrepair) {
          this.purposeRepair.dmrepair.SetHomeServiceHoursoutValidation(
            this.f.HFServicePTHMPurposeCompleted.value === 'Yes'
          );
        }
      }
      else {
        if (this.purposeRepair && this.purposeRepair.wprepair) {
          this.purposeRepair.wprepair.SetHomeServiceHoursoutValidation(
            this.f.HFServicePTHMPurposeCompleted.value === 'Yes'
          );
        }
      }
    }
  }

  clearNotes() {
    this.notesReturnDetails.Note = '';
    this.notesVisitPurpose.Note = '';
    this.notesVisitPrep.Note = '';
    this.notesVisitVehicles.Note = '';
    this.notesVisitPurposePlus.Note = '';

    if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
      this.notesVisitPurposePlus.PlusNoteHeader = "Additional Notes";
    else
      this.notesVisitPurposePlus.PlusNoteHeader = "Notes";

    // this.notesVisitPurposePlusChildren.forEach((child) => {
    //   child.Note = '';

    //   if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
    //     child.PlusNoteHeader = "Additional Notes";
    //   else
    //     child.PlusNoteHeader = "Notes";
    // });
  }


  fetchTechnologiest() {
    this.homefieldApi.GetHomeFieldService_MasterTechnologiest({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        if (success && success.length > 0) {
          var o = [];
          success.forEach(element => {
            this.TechnologistsList.forEach(tech => {
              if (element.technologistId.toString() == tech.value)
                o.push(tech);
            });
          });
          this.f.HFServiceVehicleTechnologistId.setValue(o);
        }
      },
      error => {
        AppComponent.HideLoader();
      });
  }

  ExpandSection(Section = 'Section1', manually = true) {
    //
    if (Globals.Website == 'MRMDemo') {
      if (this.isCollapsed[Section])
        this.isCollapsed[Section] = false;
      else
        this.isCollapsed[Section] = true;

      return;
    }
    if (!manually) {
      for (var key in this.isCollapsed) {
        this.isCollapsed[key] = false;
      }
    }
    setTimeout(() => {

      if (this.isSectionEnable(Section)) {
        this.isCollapsed[Section] = !this.isCollapsed[Section];
      }
      else {
        this.isCollapsed[Section] = this.isCollapsed[Section];
      }
    });

  }

  isSectionEnable(Section) {
    //
    switch (Section) {
      case "Section2":
        if (this.f.HFServiceVisitDetailsEnteredOn.value)
          return true;
        else
          return false;
      case "Section3":
        if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke' && this.f.HFServicelastModifiedSection.value !== 'Pending')
          return true;
        else
          return false;
      case "Section4":
        if (this.f.HFServiceVehicleDetailsEnteredOn.value)
          return true;
        else
          return false;
      case "Section5":
        if (this.f.HFServicePTHMPurposeCompletedOn.value)
          return true;
        else
          return false;
      case "Section6":
        if (this.f.HFServiceReturnDetailsEnteredOn.value)
          return true;
        else
          return false;
      default:
        return true;
    }
  }

  isBluePanelSection1 = true;
  isBluePanelSection2 = false;
  isBluePanelSection3 = false;
  isBluePanelSection4 = false;
  isBluePanelSection5 = false;
  isBluePanelSection6 = false;
  isBluePanelSection7 = false;

  isBluePanel() {

    if (this.f.HFServiceStatus.value == 'Completed') {

      this.isBluePanelSection1 = true;
      this.isBluePanelSection2 = true;
      this.isBluePanelSection3 = true;
      this.isBluePanelSection4 = true;
      this.isBluePanelSection5 = true;
      this.isBluePanelSection6 = true;
      this.isBluePanelSection7 = true;

    } else {

      this.isBluePanelSection1 = true;
      this.isBluePanelSection2 = false;
      this.isBluePanelSection3 = false;
      this.isBluePanelSection4 = false;
      this.isBluePanelSection5 = false;
      this.isBluePanelSection6 = false;
      this.isBluePanelSection7 = true;


      if (!this.isAnyPanelEnable) {
        if (this.f.HFServicelastModifiedSection.value == 'Pending') {
          this.isBluePanelSection2 = true;
          this.isBluePanelSection1 = false;
        }
        else
          this.isBluePanelSection2 = false;

        if (this.f.HFServicelastModifiedSection.value == 'VisitPrepDetails') {
          this.isBluePanelSection3 = true;
          this.isBluePanelSection1 = false;
        }
        else
          this.isBluePanelSection3 = false;

        if (this.f.HFServicelastModifiedSection.value == 'VisitVehicleDetails') {
          this.isBluePanelSection4 = true;
          this.isBluePanelSection1 = false;
        }
        else
          this.isBluePanelSection4 = false;

        if (this.f.HFServicelastModifiedSection.value == 'VisitPurposePlusDetails') {
          this.isBluePanelSection5 = true;
          this.isBluePanelSection1 = false;
        }
        else
          this.isBluePanelSection5 = false;

        if (this.f.HFServicelastModifiedSection.value == 'ReturnDetails') {
          if (this.f.HFServicePTHMPurposeCompletedOn.value) {
            this.isBluePanelSection2 = true;
            this.isBluePanelSection1 = false;
          } else {
            this.isBluePanelSection6 = true;
            this.isBluePanelSection1 = false;
          }
        }
        else
          this.isBluePanelSection6 = false;
      }
      else {
        this.isBluePanelSection1 = false;
      }
    }
  }

  setNotes() {
    this.notesReturnDetails.showHours = false;
    this.notesVisitPurpose.showHours = false;
    this.notesVisitPrep.showHours = false;
    this.notesVisitVehicles.showHours = false;
    this.notesVisitPurposePlus.showHours = false;

    if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
      this.notesVisitPurposePlus.PlusNoteHeader = "Additional Notes";
    else
      this.notesVisitPurposePlus.PlusNoteHeader = "Notes";

    // this.notesVisitPurposePlusChildren.forEach((child) => {
    //   child.showHours = false;

    //   if (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing')
    //     child.PlusNoteHeader = "Additional Notes";
    //   else
    //     child.PlusNoteHeader = "Notes";
    // });

  }



  fetchNotes() {
    if (this.f.HFServiceId.value && this.f.HFServiceId.value != '0') {
      const hfs = { Id: this.f.HFServiceId.value };
      this.homefieldApi.GetHomeFieldService_MasterNotes(hfs).subscribe(list => {
        if (list.length > 0) {
          this.allNotesList = list;
          this.notesVisitPurpose.NoteList = list.filter((item, index) => item.moduleName == 'Purpose Detail');
          this.notesVisitPrep.NoteList = list.filter((item, index) => item.moduleName == 'Prep Detail');
          this.notesVisitVehicles.NoteList = list.filter((item, index) => item.moduleName == 'Vehicle Detail' || item.moduleName == 'Vehicle Detail (Failure)');
          this.notesReturnDetails.NoteList = list.filter((item, index) => item.moduleName == 'Return Details');
          this.notesVisitPurposePlus.NoteList = list.filter((item, index) => item.moduleName == 'Visit Purpose Detail');
          // this.notesVisitPurposePlusChildren.forEach((child) => { child.NoteList = list.filter((item, index) => item.moduleName == 'Visit Purpose Detail') });
        }
      });
    }
    AppComponent.HideLoader();

    // //fetch Notes for Selected Vehicle
    // if (this.f.HFServiceVehicleId.value && this.f.HFServiceVehicleId.value != '0') {
    //   const hfsVehicleEquipmentId = { VehicleEquipmentId: this.f.HFServiceVehicleId.value };
    //   this.homefieldApi.GetHomeFieldService_MasterNotes(hfsVehicleEquipmentId).subscribe(list => {
    //     this.VehicleFailureNotes = list.filter((item, index) => item.moduleName == 'Vehicle Detail (Failure)');
    //     AppComponent.HideLoader();
    //   });
    // }
  }

  fetchAttachments() {
    if (this.f.HFServiceVisitPurpose.value != 'Repair') {
      this.attachmentVisitPurposePlus.clearQueue();
      this.attachmentReturnDetails.clearQueue();

      const hfs = { Id: this.f.HFServiceId.value };
      this.homefieldApi.GetHomeFieldService_MasterAttachments(hfs).subscribe(list => {
        if (list.length > 0) {
          this.attachmentVisitPurposePlus.PrevAttachments = list.filter((item, index) => item.modulename == 'HFS_VisitPurposePlus');
          this.attachmentReturnDetails.PrevAttachments = list.filter((item, index) => item.modulename == 'HFS_ReturnDetails');
        }
      });
    }
  }

  setAttachmentModule() {
    this.attachmentVisitPurposePlus.DocId = this.f.HFServiceId.value;
    this.attachmentReturnDetails.DocId = this.f.HFServiceId.value;

    this.attachmentVisitPurposePlus.moduleName = "HFS_VisitPurposePlus";
    this.attachmentReturnDetails.moduleName = "HFS_ReturnDetails";

    this.attachmentVisitPurposePlus.UploadBy = this.currentUserId;
    this.attachmentReturnDetails.UploadBy = this.currentUserId;

    this.attachmentVisitPurposePlus.uploadID = "ng2Fileuploader1";
    this.attachmentReturnDetails.uploadID = "ng2Fileuploader2";


  }


  onChangeArrival(): void {

    switch (this.f.HFServiceVisitPurpose.value) {
      case "Initial Site Visit": {
        if (this.purposeInitialSiteVisit)
          this.purposeInitialSiteVisit.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Renovation & Full Installation": {
        if (this.purposeFullInstallation)
          this.purposeFullInstallation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Uninstallation": {
        if (this.purposeUnInstallation)
          this.purposeUnInstallation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Equipment Exchange": {
        if (this.purposeEquipmentExchange)
          this.purposeEquipmentExchange.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Maintenance & Testing": {
        if (this.purposeMaintenance) {
          this.purposeMaintenance.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
          this.purposeMaintenance.PtHomeArrivalTime = this.f.HFServicePTHMArrivalTime.value;
        }
        break;
      }
      // case "Renovation": {
      //   if (this.purposeRenovation)
      //     this.purposeRenovation.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
      //   break;
      // }
      case "Other": {
        if (this.purposeOther)
          this.purposeOther.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      case "Repair": {
        if (this.purposeRepair)
          this.purposeRepair.formChange(this.f.HFServicePTHMArrivalTime.value != '' && this.f.HFServicePTHMArrivalTime.valid);
        break;
      }
      default: {
        break;
      }
    }

    if (this.f.HFServicePTHMArrivalTime.value != '') {

      this.f.HFServicePTHMPurposeCompleted.enable();
      this.f.HFServicePTHMDepartureTime.enable();
      this.f.HFServiceNextStep.enable();
    }
    else {
      this.f.HFServicePTHMPurposeCompleted.disable();
      this.f.HFServicePTHMDepartureTime.disable();
      this.f.HFServiceNextStep.disable();
    }
  }


  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }


  //** Receipt Upload */
  clearImage() {
    this.f.HFServiceFuelReceiptPath.setValue("");
    document.getElementById('ng2Fileuploader')['value'] = '';
  }


  onFileSelected(event) {
    AppComponent.ShowLoader();
    var files = event.target.files;

    const frmData = new FormData();

    for (var i = 0; i < files.length; i++) {
      frmData.append("file", files[i]);
    }

    this.invapi.FileUpload(frmData).subscribe(
      success => {

        this.f.HFServiceFuelReceiptPath.setValue(success.uploadfilename);
        //this.f.HFServiceFuelReceiptFileName.setValue(success.originalfilename);
        this.GetImagePath();
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );

  }

  GetImagePath() {
    return Globals.AttachmentUrl + '/' + this.f.HFServiceFuelReceiptPath.value;
  }

  openImageDialog(imagePath): void {

    const dialogRef = this.dialog.open(ImageViewerDialog, {
      position: { right: '0', top: '0' },
      height: '100%',
      width: "620px",
      data: {
        imgURL: Globals.AttachmentUrl + '/' + imagePath,
        title: 'Receipt'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('Image dialog was closed');
    });
  }
  //** Receipt Upload */



  //** Open Sections */
  SectionPrepdetailDialogRef: MatDialogRef<SectionPrepdetailDialog>;
  OpenPrepDetail() {

    AppComponent.ShowLoader();

    // EquipId, SerialNo, Name, InserviceDate
    this.SectionPrepdetailDialogRef = this.dialog.open(SectionPrepdetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    if (this.PrepDetailsList.length > 0)
      this.SectionPrepdetailDialogRef.componentInstance.IsDisplayPODetails = false;
    else
      this.SectionPrepdetailDialogRef.componentInstance.IsDisplayPODetails = true;

    this.SectionPrepdetailDialogRef.componentInstance.PrepTimeList = this.PrepTimeList;
    this.SectionPrepdetailDialogRef.componentInstance.TechnologistsList = this.TechnologistsList;

    this.SectionPrepdetailDialogRef.componentInstance.dropdownSettings = this.dropdownSettings;
    this.SectionPrepdetailDialogRef.componentInstance.HFServiceStationId = this.f.HFServiceStationId.value;
    this.SectionPrepdetailDialogRef.componentInstance.HFServiceVisitPurpose = this.f.HFServiceVisitPurpose.value;
    this.SectionPrepdetailDialogRef.componentInstance.DisplayPODetails();
    this.SectionPrepdetailDialogRef.componentInstance.prepf.HFServiceId.setValue(this.f.HFServiceId.value);
    this.SectionPrepdetailDialogRef.componentInstance.prepf.EnteredByHFServiceId.setValue(this.f.HFServiceId.value);
    this.SectionPrepdetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      AppComponent.addAlert('Data Saved successfully!', 'success');
      this.fetchPrepDetails();

      // if(this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke')
      //   this.executeHomeFieldServiceCreateUpdate('Section3');

      //
      //       if ((this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke') ||
      //       (this.f.HFServiceVisitNotification.value === 'Spoke' && this.PrepDetailsList.length > 0))
      //         this.executeHomeFieldServiceCreateUpdate('Section3', false);
    });
    // this.SectionPrepdetailDialogRef.componentInstance.dialogRef.updateSize(
    //   '95%',
    //   '95%'
    // );
    this.SectionPrepdetailDialogRef.afterClosed().subscribe(result => {

      if (result && !this.f.HFServiceVehiclePrepDetailsEnteredOn.value) {
        this.f.HFServiceVehiclePrepTime.setValue(result['PrepTime']);
        this.f.HFServiceVehicleTechnologistId.setValue(result['PrepTechnologistIds']);

        this.f.poPlumber.setValue(result['poPlumber']);
        this.f.poElectrician.setValue(result['poElectrician']);
        this.f.poMovers.setValue(result['poMovers']);
        this.f.poPlumberNote.setValue(result['poPlumberNote']);
        this.f.poElectricianNote.setValue(result['poElectricianNote']);
        this.f.poMoversNote.setValue(result['poMoversNote']);
      }


      if (result && ((this.f.HFServiceVehiclePrepDetailsEnteredOn.value && this.f.HFServiceVisitNotification.value === 'Spoke') ||
        (this.f.HFServiceVisitNotification.value === 'Spoke' && this.PrepDetailsList.length > 0)))
        this.executeHomeFieldServiceCreateUpdate('Section3', false);
      this.SectionPrepdetailDialogRef = null;
    });

    AppComponent.HideLoader();

  }


  SectionVehicledetailDialogRef: MatDialogRef<SectionVehicledetailDialog>;
  OpenVehicleDetail() {

    AppComponent.ShowLoader();

    // EquipId, SerialNo, Name, InserviceDate
    this.SectionVehicledetailDialogRef = this.dialog.open(SectionVehicledetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '75%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    if (this.ReturnDetailsList && this.ReturnDetailsList.length > 0 && this.ReturnDetailsList[0] && this.ReturnDetailsList[0].hfServiceReturnVehicleEndTime)
      this.SectionVehicledetailDialogRef.componentInstance.vf.HFServiceReturnVehicleEndTime.setValue(this.ReturnDetailsList[0].hfServiceReturnVehicleEndTime);

    this.SectionVehicledetailDialogRef.componentInstance.VehicleList = this.VehicleList;
    this.SectionVehicledetailDialogRef.componentInstance.PrevFailureNotes = this.VehicleFailureNotes;
    // this.SectionVehicledetailDialogRef.componentInstance.vf.HFServiceVehicleStartTime.setValue(new Date());
    this.SectionVehicledetailDialogRef.componentInstance.vf.HFServiceId.setValue(this.f.HFServiceId.value);
    this.SectionVehicledetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      AppComponent.addAlert('Data Saved successfully!', 'success');
      this.fetchVehicleDetails();
      this.fetchNotes();
    });
    this.SectionVehicledetailDialogRef.afterClosed().subscribe(result => {

      if (result && !this.f.HFServiceVehicleDetailsEnteredOn.value) {
        this.f.HFServiceVehicleId.setValue(result['HFServiceVehicleId']);
        this.f.HFServiceVehicleStartedKM.setValue(result['HFServiceVehicleStartedKM']);
        this.f.HFServiceVehicleStartTime.setValue(result['HFServiceVehicleStartTime']);
        this.f.HFServiceVehicleInspectionStatus.setValue(result['HFServiceVehicleInspectionStatus']);
      }

      if (result) {

        this.fetchVehicleDetails().then(() => {
          this.executeHomeFieldServiceCreateUpdate('Section4');
        });


      }

      this.SectionVehicledetailDialogRef = null;
    });

    AppComponent.HideLoader();

  }

  SectionPurposeDetailDialogRef: MatDialogRef<SectionPurposeDetailDialog>;
  OpenPurposeDetail(inProgress = false) {
    if (inProgress && (this.f.HFServiceVisitPurpose.value != 'Initial Site Visit' &&
      this.f.HFServiceVisitPurpose.value != 'Uninstallation')) {
      var PurposeCompleted = this.visitPurposeWithoutRenovationList.filter((item, index) => item.text == this.f.HFServiceVisitPurpose.value);
      if (PurposeCompleted && !PurposeCompleted[0].isFormSubmitted && (this.f.HFServiceVisitPurpose.value == 'Maintenance & Testing' || this.f.HFServiceVisitPurpose.value == 'Renovation & Full Installation')) {
        AppComponent.addAlert('Before Departure, Tech needs to complete "' + this.f.HFServiceVisitPurpose.value + '" purpose.', 'error');
        this.purposeSelectedId = this.f.HFServiceVisitPurpose.value;
        setTimeout(() => {
          this.purposeSelection(PurposeCompleted[0]);
        }, 1000);
        AppComponent.HideLoader();
        return;
      }

      var purposeSubmitted = this.visitPurposeWithoutRenovationList.filter((item, index) => item.isFormSubmitted == true);
      if ((purposeSubmitted && purposeSubmitted.length == 0) && (this.notesVisitPurposePlus.NoteList.length == 0)) {
        AppComponent.addAlert('Before Departure, Tech needs to complete atleast one purpose or need to add one note.', 'error');
        AppComponent.HideLoader();
        return;
      }
    }

    AppComponent.ShowLoader();
    this.SectionPurposeDetailDialogRef = this.dialog.open(SectionPurposeDetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    this.SectionPurposeDetailDialogRef.componentInstance.showDepartureDetail = inProgress;
    this.SectionPurposeDetailDialogRef.componentInstance.YesNoList = this.YesNoList;
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceId.setValue(this.f.HFServiceId.value);
    this.SectionPurposeDetailDialogRef.componentInstance.purpf.DialysisUnitId.setValue(this.f.HFServiceStationId.value);

    this.SectionPurposeDetailDialogRef.componentInstance.purpf.EnteredByHFServiceId.setValue(this.f.HFServiceId.value);


    if (this.VehicleDetailsList && this.VehicleDetailsList.length > 0 && this.VehicleDetailsList[0] && this.VehicleDetailsList[0].hfServiceVehicleStartTime)
      this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServiceVehicleStartTime.setValue(this.VehicleDetailsList[0].hfServiceVehicleStartTime);

    // if (inProgress)
    //   this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServicePTHMDepartureTime.setValue(new Date());
    // else
    //   this.SectionPurposeDetailDialogRef.componentInstance.purpf.HFServicePTHMArrivalTime.setValue(new Date());

    let inProgressPurposeDetails = this.PurposeDetailsList.filter(x => x.hfServicePTHMDepartureTime == '' || x.hfServicePTHMDepartureTime == null);
    this.SectionPurposeDetailDialogRef.componentInstance.setModel(inProgressPurposeDetails, inProgress);

    this.SectionPurposeDetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      if (evt.hfServicePTHMPurposeCompleted) {
        this.f.HFServicePTHMPurposeCompleted.setValue(evt.hfServicePTHMPurposeCompleted);
      }
      this.fetchPurposeSectionDetails();
      // this.executeHomeFieldServiceCreateUpdate('Section5', false, true);
      AppComponent.addAlert('Data Saved successfully!', 'success');
    });
    this.SectionPurposeDetailDialogRef.afterClosed().subscribe(result => {


      if (result && !this.f.HFServicePTHMArrivalTime.value && result['HFServicePTHMArrivalTime']) {
        this.f.HFServicePTHMArrivalTime.setValue(result['HFServicePTHMArrivalTime']);
      }

      if (result && !this.f.HFServicePTHMDepartureTime.value && result['HFServicePTHMDepartureTime']) {
        this.f.HFServicePTHMDepartureTime.setValue(result['HFServicePTHMDepartureTime']);
        this.f.HFServicePTHMPurposeCompleted.setValue(result['HFServicePTHMPurposeCompleted']);
      }
      this.onChangeArrival();
      this.setChildMRMID();
      this.SectionPurposeDetailDialogRef = null;
      //
      if (inProgress && result) {
        this.f.HFServiceReturnVehicleEndedKM.clearValidators();
        this.f.HFServiceReturnVehicleEndTime.clearValidators();
        this.f.HFServiceFuelAdded.clearValidators();
        this.f.HFServiceReturnVehicleEndedKM.updateValueAndValidity();
        this.f.HFServiceReturnVehicleEndTime.updateValueAndValidity();
        this.f.HFServiceFuelAdded.updateValueAndValidity();
        this.executeHomeFieldServiceCreateUpdate('Section5');
      }
    });

    AppComponent.HideLoader();

  }

  SectionReturnDetailDialogRef: MatDialogRef<SectionReturnDetailDialog>;
  OpenReturnDetail() {

    AppComponent.ShowLoader();
    this.SectionReturnDetailDialogRef = this.dialog.open(SectionReturnDetailDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '34%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });


    this.SectionReturnDetailDialogRef.componentInstance.rf.HFServiceId.setValue(this.f.HFServiceId.value);
    // this.SectionReturnDetailDialogRef.componentInstance.rf.HFServiceReturnVehicleEndTime.setValue(new Date());

    // if (this.PurposeDetailsList && this.PurposeDetailsList.length > 0 && this.PurposeDetailsList[0] && this.PurposeDetailsList[0].hfServicePTHMDepartureTime)
    //   this.SectionReturnDetailDialogRef.componentInstance.rf.HFServicePTHMDepartureTime.setValue(this.PurposeDetailsList[0].hfServicePTHMDepartureTime);

    if (this.PurposeDetailsList && this.PurposeDetailsList.length > 0 && this.f.HFServicePTHMDepartureTime.value)
      this.SectionReturnDetailDialogRef.componentInstance.rf.HFServicePTHMDepartureTime.setValue(this.f.HFServicePTHMDepartureTime.value);


    if (this.VehicleDetailsList && this.VehicleDetailsList.length > 0 && this.VehicleDetailsList[0] && this.VehicleDetailsList[0].hfServiceVehicleStartedKM)
      this.SectionReturnDetailDialogRef.componentInstance.rf.HFServiceVehiclePrevStartedKm.setValue(this.VehicleDetailsList[0].hfServiceVehicleStartedKM);

    this.SectionReturnDetailDialogRef.componentInstance.YesNoList = this.YesNoList;
    this.SectionReturnDetailDialogRef.componentInstance.FuelCompanyList = this.FuelCompanyList;
    this.SectionReturnDetailDialogRef.componentInstance.PaymentByList = this.PaymentByList;
    this.SectionReturnDetailDialogRef.componentInstance.HFServiceVehicleDetailId = this.VehicleDetailsList.filter(x => x.hfServiceVehicleEndTime == null)[0].hfServiceVehicleDetailId;

    this.SectionReturnDetailDialogRef.componentInstance.SaveEvent.subscribe(evt => {
      this.fetchReturnSectionDetails();
      AppComponent.addAlert('Data Saved successfully!', 'success');

      setTimeout(() => {
        this.ReturnDetailsEntered = true;
        // this.executeHomeFieldServiceCreateUpdate('Section6');
      });
    });
    this.SectionReturnDetailDialogRef.afterClosed().subscribe(result => {

      if (result && !this.f.HFServiceReturnDetailsEnteredOn.value) {

        this.f.HFServiceReturnVehicleEndTime.setValue(result['HFServiceReturnVehicleEndTime']);
        this.f.HFServiceReturnVehicleEndedKM.setValue(result['HFServiceReturnVehicleEndedKM']);
        this.f.HFServiceFuelAdded.setValue(result['HFServiceFuelAdded']);
        this.f.HFServiceFuelCompany.setValue(result['HFServiceFuelCompany']);
        this.f.HFServiceFuelAmount.setValue(result['HFServiceFuelAmount']);
        this.f.HFServiceFuelPaymentBy.setValue(result['HFServiceFuelPaymentBy']);
        this.f.HFServiceFuelCompany.setValue(result['HFServiceFuelCompany']);
        this.f.HFServiceFuelReceiptPath.setValue(result['HFServiceFuelReceiptPath']);
      }


      if (result) {
        this.executeHomeFieldServiceCreateUpdate('Section6');
      }
      this.SectionReturnDetailDialogRef = null;
    });

    AppComponent.HideLoader();
  }

  informationdialogRef: MatDialogRef<informationdialog>;
  OpenQuestionList() {
    AppComponent.ShowLoader();
    this.informationdialogRef = this.dialog.open(informationdialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '50%',
      maxHeight: '100vh'
    });

    this.informationdialogRef.afterClosed().subscribe(result => {
      this.informationdialogRef = null;
    });

    AppComponent.HideLoader();
  }

  hfsVehicleHistoryDialog: MatDialogRef<HFSVehicleHistoryDialog>;
  OpenVehicleHistoryList() {
    AppComponent.ShowLoader();
    this.hfsVehicleHistoryDialog = this.dialog.open(HFSVehicleHistoryDialog, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '60%',
      maxHeight: '100vh',
      data: this.f.HFServiceVehicleId.value
    });

    this.hfsVehicleHistoryDialog.afterClosed().subscribe(result => {
    });

    AppComponent.HideLoader();
  }

  //** Open Sections */


  //** display Sections Condition Functions */
  displayVehicleAdd() {
    if ((this.f.HFServiceVehicleDetailsEnteredOn.value && this.f.HFServicePTHMPurposeCompleted.value != 'Yes'
      && this.f.HFServiceReturnDetailsEnteredOn.value && !this.VehicleDetailsEntered) || (this.f.HFServiceVehiclePrepDetailsEnteredOn.value && !this.f.HFServiceVehicleDetailsEnteredOn.value))
      return true;
    else
      return false;
  }

  displayMultiPurposeAdd() {

    if (
      (this.f.HFServicePTHMPurposeCompletedOn.value
        && this.f.HFServicePTHMPurposeCompleted.value != 'Yes'
        && (this.f.HFServiceVehicleDetailsEnteredOn.value && this.VehicleDetailsEntered)
        && (!this.f.HFServiceReturnDetailsEnteredOn.value || !this.ReturnDetailsEntered)
        && !this.PurposeDetailsEntered) ||
      (!this.f.HFServicePTHMPurposeCompletedOn.value && !this.f.HFServicePTHMArrivalTime.value && this.f.HFServiceVehicleDetailsEnteredOn.value))
      return true;
    else
      return false;
  }

  displayMultiPurposeDepartureAdd() {

    if (
      (this.f.HFServicePTHMPurposeCompletedOn.value
        && this.f.HFServicePTHMPurposeCompleted.value != 'Yes'
        && (this.f.HFServiceVehicleDetailsEnteredOn.value && this.VehicleDetailsEntered)
        && (!this.f.HFServiceReturnDetailsEnteredOn.value || !this.ReturnDetailsEntered)
        && this.PurposeDetailsEntered && this.PurposeDetailsEnteredInProgress)
      || (!this.f.HFServicePTHMPurposeCompletedOn.value && this.f.HFServicePTHMArrivalTime.value && this.f.HFServiceVehicleDetailsEnteredOn.value))
      return true;
    else
      return false;
  }

  displayMultiReturnsAdd() {

    // setTimeout(() => {
    if ((this.f.HFServiceReturnDetailsEnteredOn.value
      && this.f.HFServiceStatus.value != 'Completed'
      && !this.ReturnDetailsEntered && this.PurposeDetailsEntered
      && !this.PurposeDetailsEnteredInProgress) ||
      (!this.f.HFServiceReturnDetailsEnteredOn.value && this.f.HFServicePTHMPurposeCompletedOn.value && this.f.HFServiceStatus.value != 'Completed'))
      return true;
    else
      return false;
    // });
  }

  //** display Sections Condition Functions */

  //** Fetch Section Details */
  fetchPrepDetails() {

    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_SectionPrepDetails({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        this.PrepDetailsList = success;

        setTimeout(() => {
          let prepTime = 0;
          this.PrepDetailsList.forEach((item, newKey) => {
            prepTime += parseFloat(item.prepTime);
          });

          this.totalPrepDetailTime = prepTime.toString();
        }, 100);

        if (this.PrepDetailsList && this.PrepDetailsList.length > 0) {

          this.f.HFServiceVehiclePrepTime.setValue(this.PrepDetailsList[0].prepTime);
        }

        this.refreshSections();
        AppComponent.HideLoader();

      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  fetchVehicleDetails() {
    return new Promise((resolve, reject) => {

      AppComponent.ShowLoader();
      this.homefieldApi.GetHomeFieldService_SectionVehicleDetails({ Id: this.f.HFServiceId.value }).subscribe(
        success => {


          this.VehicleDetailsList = success;
          var vehicleList = '';
          this.VehicleDetailsList.forEach((item, vKey) => {

            if (vehicleList.indexOf(item.hfServiceVehicleName) < 0)
              vehicleList += item.hfServiceVehicleName + ', ';
          });
          this.VehicleListSummary = vehicleList.substr(0, vehicleList.length - 2);

          if (this.VehicleDetailsList && this.VehicleDetailsList.length > 0) {

            this.f.HFServiceVehicleId.setValue(this.VehicleDetailsList[0].hfServiceVehicleId);
            this.f.HFServiceVehicleStartedKM.setValue(this.VehicleDetailsList[0].hfServiceVehicleStartedKM);
            this.f.HFServiceVehicleStartTime.setValue(this.VehicleDetailsList[0].hfServiceVehicleStartTime);
            this.f.HFServiceVehicleInspectionStatus.setValue(this.VehicleDetailsList[0].hfServiceVehicleInspectionStatus);
          }
          this.refreshSections();

          setTimeout(() => {
            this.isAdditionalAddVehicle = this.displayVehicleAdd();
            resolve(true);

          }, 100);

          AppComponent.HideLoader();

        },
        error => {
          if (error.error && error.error.returnMessage) {
            AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
          }
          else {
            AppComponent.addAlert(error.message, 'error');
          }
          resolve(true);
          AppComponent.HideLoader();
        }
      );
    });
  }



  fetchPurposeSectionDetails() {

    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_SectionPurposeDetails({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        this.PurposeDetailsList = success;
        this.fetchVehicleDetails();
        this.refreshSections();

        //
        if (this.PurposeDetailsList && this.PurposeDetailsList.length > 0) {

          this.f.HFServicePTHMArrivalTime.setValue(this.PurposeDetailsList[0].hfServicePTHMArrivalTime);
          this.f.HFServicePTHMPurposeCompleted.setValue(this.PurposeDetailsList[0].hfServicePTHMPurposeCompleted);

          if (!this.f.HFServicePTHMDepartureTime.value)
            this.f.HFServicePTHMDepartureTime.setValue(this.PurposeDetailsList[0].hfServicePTHMDepartureTime);

        }

        setTimeout(() => {

          this.isAdditionalAddArrival = this.displayMultiPurposeAdd();
          this.isAdditionalAddDeparture = this.displayMultiPurposeDepartureAdd();
        }, 100);
        AppComponent.HideLoader();

      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  fetchReturnSectionDetails() {

    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_SectionReturnDetails({ Id: this.f.HFServiceId.value }).subscribe(
      success => {
        this.ReturnDetailsList = success;

        if (this.ReturnDetailsList && this.ReturnDetailsList.length > 0) {

          this.f.HFServiceReturnVehicleEndTime.setValue(this.ReturnDetailsList[0].hfServiceReturnVehicleEndTime);
          this.f.HFServiceReturnVehicleEndedKM.setValue(this.ReturnDetailsList[0].hfServiceReturnVehicleEndedKM);
          this.f.HFServiceFuelAdded.setValue(this.ReturnDetailsList[0].hfServiceFuelAdded);
          this.f.HFServiceFuelCompany.setValue(this.ReturnDetailsList[0].hfServiceFuelCompany);
          this.f.HFServiceFuelAmount.setValue(this.ReturnDetailsList[0].hfServiceFuelAmount);
          this.f.HFServiceFuelPaymentBy.setValue(this.ReturnDetailsList[0].hfServiceFuelPaymentBy);
          this.f.HFServiceFuelCompany.setValue(this.ReturnDetailsList[0].hfServiceFuelCompany);
          this.f.HFServiceFuelReceiptPath.setValue(this.ReturnDetailsList[0].hfServiceFuelReceiptPath);

        }

        setTimeout(() => {
          this.isAdditionalAddReturn = this.displayMultiReturnsAdd();
        }, 100);

        this.fetchVehicleDetails();
        AppComponent.HideLoader();

      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  fetchCompletedPurposes() {
    AppComponent.ShowLoader();
    this.homefieldApi.GetHomeFieldService_PurposeListbyServiceID({ Id: this.f.HFServiceId.value }).subscribe(
      success => {

        if (success) {
          success.forEach((i, newKey) => {
            let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == i);
            if (completedPurpose.length > 0) {
              completedPurpose[0].isFormSubmitted = true;
            }
          });

          if (this.f.HFServiceStatus.value === 'Completed' || this.f.HFServicePTHMPurposeCompleted.value === 'Yes') {
            this.visitPurposeWithoutRenovationList = this.visitPurposeWithoutRenovationList.filter((item, index) => item.isFormSubmitted == true);
          }
        }
        AppComponent.HideLoader();
      },
      error => {
        if (error.error && error.error.returnMessage) {
          AppComponent.addAlert(error.error.returnMessage.toString(), 'error');
        }
        else {
          AppComponent.addAlert(error.message, 'error');
        }

        AppComponent.HideLoader();
      }
    );
  }

  refreshSections() {

    if (this.VehicleDetailsList && this.VehicleDetailsList.filter(x => x.hfServiceVehicleStartTime && x.hfServiceVehicleEndTime).length == this.VehicleDetailsList.length) {
      this.VehicleDetailsEntered = false;
      this.ReturnDetailsEntered = true;
    }
    else {
      this.ReturnDetailsEntered = false;
      this.VehicleDetailsEntered = true;
    }


    if (this.PurposeDetailsList.length == this.VehicleDetailsList.length && !this.f.IsReferencedHFS.value) {
      this.PurposeDetailsEntered = true;
      this.PurposeDetailsEnteredInProgress = this.PurposeDetailsList.filter(x => x.hfServicePTHMDepartureTime == '' || x.hfServicePTHMDepartureTime == null).length > 0;
    }
    else
      this.PurposeDetailsEntered = false;



    // if (this.f.HFServiceStatus.value == 'Completed') {
    //   setTimeout(() => {
    //     this.calculateTotalTime();
    //     this.calculateTotalTechTime();
    //   }, 500);
    // }
  }

  //** Fetch Section Details */

  //** Calculate Summary */
  calculateTotalTime() {

    if (this.PurposeDetailsList && this.PurposeDetailsList.length > 0) {
      this.totalTime = '';
      let totalMsTime = 0;
      this.PurposeDetailsList.forEach((item, newKey) => {
        if (item.hfServicePTHMDepartureTime && item.hfServicePTHMArrivalTime) {
          let diffDateInMs = Date.parse(item.hfServicePTHMDepartureTime) - Date.parse(item.hfServicePTHMArrivalTime);
          totalMsTime += diffDateInMs;
        }
      });
      this.totalTime = this.msToTime(totalMsTime);
    }

  }

  displaySaveButton(type) {

    if (type == "VisitPurposePlus") {
      if (this.f.HFServiceStatus.value == 'Completed') {
        if (this.attachmentVisitPurposePlus && this.attachmentVisitPurposePlus.uploader &&
          this.attachmentVisitPurposePlus.uploader.queue && this.attachmentVisitPurposePlus.uploader.queue.length > 0) {
          return true;
        }
      }
      else {
        if (this.f.HFServicePTHMPurposeCompleted.value == 'Yes') {
          return true;
        }
      }
    }
    else {
      if (this.f.HFServiceStatus.value == 'Completed') {
        if (this.attachmentReturnDetails && this.attachmentReturnDetails.uploader &&
          this.attachmentReturnDetails.uploader.queue && this.attachmentReturnDetails.uploader.queue.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  msToTime(duration: number) {

    //Get hours from milliseconds
    var hours = duration / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.round(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    // //Get remainder from minutes and convert to seconds
    // var seconds = (minutes - absoluteMinutes) * 60;
    // var absoluteSeconds = Math.floor(seconds);
    // var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

    // return h + ':' + m + ':' + s;
    return h + ':' + m;

  }

  calculateTotalTechTime() {
    //
    if (this.PrepDetailsList && this.PrepDetailsList.length > 0
      && this.VehicleDetailsList && this.VehicleDetailsList.length > 0
      && this.PurposeDetailsList && this.PurposeDetailsList.length > 0
      && this.ReturnDetailsList && this.ReturnDetailsList.length > 0
    ) {
      //
      let totalPrepTime = 0;
      this.PrepDetailsList.forEach((item, newKey) => {
        totalPrepTime += parseFloat(item.prepTime) * (60 * 60 * 1000);
      });

      let totalTestingTime = 0;

      totalTestingTime = parseFloat(this.techTestingTime.toString()) * (60 * 60 * 1000);
      this.totalTechTestingTime = this.msToTime(totalTestingTime);
      let totTechTime = 0;
      let totTravelTime = 0;
      let totPatientHomeTime = 0;
      this.VehicleDetailsList.forEach((vItem, vKey) => {
        this.PurposeDetailsList.forEach((purpItem, purpKey) => {
          this.ReturnDetailsList.forEach((rItem, rKey) => {
            if (vKey == rKey && purpKey == rKey) {

              if (vItem.hfServiceVehicleStartTime && purpItem.hfServicePTHMArrivalTime
                && purpItem.hfServicePTHMDepartureTime && rItem.hfServiceReturnVehicleEndTime
                && Date.parse(vItem.hfServiceVehicleStartTime) && Date.parse(purpItem.hfServicePTHMArrivalTime)
                && Date.parse(purpItem.hfServicePTHMDepartureTime) && Date.parse(rItem.hfServiceReturnVehicleEndTime)
              ) {

                totTechTime += Date.parse(purpItem.hfServicePTHMArrivalTime) - Date.parse(vItem.hfServiceVehicleStartTime);
                totTechTime += Date.parse(purpItem.hfServicePTHMDepartureTime) - Date.parse(purpItem.hfServicePTHMArrivalTime);
                totTechTime += Date.parse(rItem.hfServiceReturnVehicleEndTime) - Date.parse(purpItem.hfServicePTHMDepartureTime);

                if (rItem.isAddedFromMasterHFS == null && vItem.isAddedFromMasterHFS == null) {
                  totTravelTime += Date.parse(purpItem.hfServicePTHMArrivalTime) - Date.parse(vItem.hfServiceVehicleStartTime);
                  totTravelTime += Date.parse(rItem.hfServiceReturnVehicleEndTime) - Date.parse(purpItem.hfServicePTHMDepartureTime);
                }
                this.prepTimeSummary = this.msToTime(totalPrepTime);
                this.totalTravellingTime = this.msToTime(totTravelTime);

                // this.travellingTimeToPtHome += this.msToTime(Date.parse(purpItem.hfServicePTHMArrivalTime) - Date.parse(vItem.hfServiceVehicleStartTime));
                totPatientHomeTime += Date.parse(purpItem.hfServicePTHMDepartureTime) - Date.parse(purpItem.hfServicePTHMArrivalTime);
                // this.travellingTimeToHospital += this.msToTime(Date.parse(rItem.hfServiceReturnVehicleEndTime) - Date.parse(purpItem.hfServicePTHMDepartureTime));
              }
            }
          });
        });
      });

      this.travellingTimeSpentAtPtHome = this.msToTime(totPatientHomeTime);
      totTechTime = totTravelTime + totalPrepTime + totalTestingTime + totPatientHomeTime;


      //** Comment this if Child Stations Total included **//
      if (this.f.HFServiceVehicleTechnologistId.value && this.f.HFServiceVehicleTechnologistId.value.length > 0)
        this.totalTechTime = this.msToTime(totTechTime * this.f.HFServiceVehicleTechnologistId.value.length);
      else
        this.totalTechTime = this.msToTime(totTechTime);
      //** Comment this if Child Stations Total  included **//


      //** UnComment this if Child Stations Total included **//
      // if (this.f.HFServiceVehicleTechnologistId.value && this.f.HFServiceVehicleTechnologistId.value.length > 0)
      //   totTechTime = totTechTime * this.f.HFServiceVehicleTechnologistId.value.length;

      // this.panelChildrens.forEach((panelChild) => {
      //     totTechTime += panelChild.totTechTimeDigits;
      // });

      // this.totalTechTime = this.msToTime(totTechTime);
      //** UnComment this if Child Stations Total **//

    }
  }
  //** Calculate Summary */

  getNextSaveSection() {

    if (this.f.HFServiceStatus.value == 'Completed')
      return "Section6";
    else if (this.f.HFServicePTHMPurposeCompletedOn.value && this.f.HFServicePTHMPurposeCompleted.value == 'Yes')
      return "Section6";
    else if (this.f.HFServiceVehicleDetailsEnteredOn.value)
      return "Section5";
    else if (this.f.HFServiceVehiclePrepDetailsEnteredOn.value)
      return "Section4";
    else if (this.f.HFServiceVisitDetailsEnteredOn.value)
      return "Section3";
    else
      return "Section2";
  }

  getArrivalTime() {
    return this.f.HFServicePTHMArrivalTime.value;
  }

  getCurrentTime() {
    return this.today.getHours() + ":" + this.today.getMinutes();
  }

  purposeSelection(item) {

    if (item.value == "Equipment Exchange") {
      this.OpenEquipmentExchangePurpose();
    }
    else if (item.value == "Maintenance & Testing") {
      this.OpenMaintenancePurpose(item.value);
    }
    else if (item.value == "Repair") {
      this.OpenRepairPurpose();
    }
    else if (item.value == "Renovation & Full Installation") {
      this.OpenMaintenancePurpose(item.value);
    }
    else if (item.value == "Other") {
      // this.OpenOtherPurpose();

      this.notesVisitPurposePlus.AddNote('Other Purpose - ');


    }
  }

  getExchangeTypeValue(text) {
    var ExchangeType = this.EquipmentExchangeCategoryList.filter(
      (item, index) => item.text == text);

    if (ExchangeType.length > 0)
      return ExchangeType[0].value;
    else
      return 0;
  }



  AddOtherPurpose(value) {
    const RModelCreate = <any>{};
    RModelCreate.HFServiceID = this.f.HFServiceId.value;
    RModelCreate.DialysisUnitId = this.f.HFServiceStationId.value;
    RModelCreate.Issue = value ? value.replace('Other Purpose - ', '') : '';
    RModelCreate.CreatedOn = Globals.GetAPIDate(this.today);
    RModelCreate.CreatedBy = this.currentUserId;
    RModelCreate.ModifiedOn = Globals.GetAPIDate(this.today);
    RModelCreate.ModifiedBy = this.currentUserId;

    this.homefieldApi.CreateHomeFieldService_Other(RModelCreate).subscribe(
      success => {
        let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Other");
        if (completedPurpose.length > 0) {
          completedPurpose[0].isFormSubmitted = true;
        }
        AppComponent.addAlert('Data Saved successfully!', 'success');
      },
      error => {

      }
    );

  }

  //** Open Purpose Dialogs */

  PurposeEquipmentExchangeComponentDialogRef: MatDialogRef<PurposeEquipmentExchangeComponent>;
  OpenEquipmentExchangePurpose() {

    AppComponent.ShowLoader();

    this.PurposeEquipmentExchangeComponentDialogRef = this.dialog.open(PurposeEquipmentExchangeComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '70%',
      maxHeight: '100vh'
    });



    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.HFServiceId.setValue(this.f.HFServiceId.value);
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

    if (this.f.HFServiceVisitPurpose.value == 'Equipment Exchange') {
      this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.f.EquipmentExchangeType.setValue(this.getExchangeTypeValue(this.f.HFServiceCategory.value));
      this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.EquipmentExchangeTypeChanged();
    }

    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.setModel();
    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.formChange(this.f.HFServicePTHMPurposeCompleted.value != 'Yes');
    // this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.EquipmentExchangeChangeEvent();
    this.PurposeEquipmentExchangeComponentDialogRef.afterClosed().subscribe(result => {
    });

    this.PurposeEquipmentExchangeComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Equipment Exchange");
      if (completedPurpose.length > 0) {
        completedPurpose[0].isFormSubmitted = true;
      }

      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    AppComponent.HideLoader();

  }

  PurposeRepairComponentDialogRef: MatDialogRef<PurposeRepairComponent>;
  OpenRepairPurpose() {

    AppComponent.ShowLoader();


    this.PurposeRepairComponentDialogRef = this.dialog.open(PurposeRepairComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '90%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == 'Repair');
    let isFormSubmitted = false;
    if (completedPurpose.length > 0) {
      isFormSubmitted = completedPurpose[0].isFormSubmitted;
    }

    this.PurposeRepairComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    this.PurposeRepairComponentDialogRef.componentInstance.isFormSubmitted = isFormSubmitted;
    this.PurposeRepairComponentDialogRef.componentInstance.PurposeType = this.f.HFServiceVisitPurpose.value;
    if (this.f.HFServiceVisitPurpose.value == 'Repair') {
      this.PurposeRepairComponentDialogRef.componentInstance.RepairType = this.f.HFServiceCategory.value;
    }
    this.PurposeRepairComponentDialogRef.componentInstance.RepairCategoryList = this.RepairCategoryList;
    this.PurposeRepairComponentDialogRef.componentInstance.HFServiceId = this.f.HFServiceId.value;
    this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisStation = this.f.HFServiceStationId.value;
    this.PurposeRepairComponentDialogRef.componentInstance.remarks = this.f.HFServiceIssue.value;
    this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisStationtext = this.MRMHomeStationName;
    if (this.f.HFServicePTHMPurposeCompleted.value == 'Yes')
      this.PurposeRepairComponentDialogRef.componentInstance.fetchCompletedTypes();

    // this.dmapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {
      this.DivisionList = list;
      this.selectedDivisionObj = this.DivisionList.filter(x => x.text === 'Home Program');
      this.PurposeRepairComponentDialogRef.componentInstance.selectedDialysisUnits = this.selectedDivisionObj[0].value;
    });


    this.PurposeRepairComponentDialogRef.afterClosed().subscribe(result => {
      if (result)
        this.PartTrans = result;

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == 'Repair');
      if (!(completedPurpose && completedPurpose.length > 0 && completedPurpose[0].isFormSubmitted == true))
        this.fetchCompletedPurposes();

    });

    AppComponent.HideLoader();
  }

  PurposeMaintenanceComponentDialogRef: MatDialogRef<PurposeMaintenanceComponent>;
  OpenMaintenancePurpose(HFSPurpose) {

    AppComponent.ShowLoader();

    this.PurposeMaintenanceComponentDialogRef = this.dialog.open(PurposeMaintenanceComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '95%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    // this.PurposeMaintenanceComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {
    //   AppComponent.addAlert('Data Saved successfully!', 'success');
    // });
    //
    this.PurposeMaintenanceComponentDialogRef.componentInstance.HFSPurpose = HFSPurpose;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.HFServiceTaskCategory = this.f.HFServiceTaskCategory.value;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.HFServiceId.setValue(this.f.HFServiceId.value);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.DialysisUnitName.setValue(this.MRMHomeStationName);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.mntf.dialysisUnitName.setValue(this.MRMHomeStationName);
    this.PurposeMaintenanceComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;
    this.PurposeMaintenanceComponentDialogRef.componentInstance.PtHomeArrivalTime = this.f.HFServicePTHMArrivalTime.value;

    this.PurposeMaintenanceComponentDialogRef.componentInstance.setModel();
    this.PurposeMaintenanceComponentDialogRef.componentInstance.formChange(this.f.HFServicePTHMPurposeCompleted.value != 'Yes');

    this.PurposeMaintenanceComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == HFSPurpose);
      if (completedPurpose.length > 0) {
        completedPurpose[0].isFormSubmitted = true;
      }


      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    this.PurposeMaintenanceComponentDialogRef.componentInstance.addNoteClick.subscribe(evt => {

      this.notesVisitPurposePlus.Note = evt.note;
      // this.notesVisitPurposePlusChildren.forEach((child) => {
      //   child.Note = evt.note;
      // });
      this.noteModule = evt.noteModule;
      this.executeHomeFieldServiceCreateUpdate('Section5', true, true);
    });

    // this.PurposeMaintenanceComponentDialogRef.componentInstance.save('');

    this.PurposeMaintenanceComponentDialogRef.afterClosed().subscribe(result => {
      this.noteModule = '';
    });

    AppComponent.HideLoader();

  }

  // UninstallationComponentDialogRef: MatDialogRef<PurposeUninstallationComponent>;
  // OpenUninstallationPurpose() {

  //   AppComponent.ShowLoader();

  //   this.UninstallationComponentDialogRef = this.dialog.open(PurposeUninstallationComponent, {
  //     disableClose: true,
  //     position: { right: '0', top: '0' },
  //     height: '100%',
  //     width: '54%',
  //     // maxWidth: '60vh',
  //     maxHeight: '100vh'
  //   });

  //   this.UninstallationComponentDialogRef.componentInstance.fif.HFServiceId.setValue(this.f.HFServiceId.value);
  //   this.UninstallationComponentDialogRef.componentInstance.fif.dialysisUnitId.setValue(this.f.HFServiceStationId.value);
  //   this.UninstallationComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
  //   this.UninstallationComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

  //   this.UninstallationComponentDialogRef.componentInstance.setModel();
  //   this.UninstallationComponentDialogRef.componentInstance.formChange(this.f.HFServicePTHMPurposeCompleted.value != 'Yes');

  //   this.UninstallationComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

  //     let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Uninstallation");
  //     if (completedPurpose.length > 0) {
  //       completedPurpose[0].isFormSubmitted = true;
  //     }


  //     AppComponent.addAlert('Data Saved successfully!', 'success');
  //   });

  //   this.UninstallationComponentDialogRef.afterClosed().subscribe(result => {
  //   });

  //   AppComponent.HideLoader();

  // }

  PurposeOtherComponentDialogRef: MatDialogRef<PurposeOtherComponent>;
  OpenOtherPurpose() {

    AppComponent.ShowLoader();

    this.PurposeOtherComponentDialogRef = this.dialog.open(PurposeOtherComponent, {
      disableClose: true,
      position: { right: '0', top: '0' },
      height: '100%',
      width: '54%',
      // maxWidth: '60vh',
      maxHeight: '100vh'
    });

    this.PurposeOtherComponentDialogRef.componentInstance.of.HFServiceID.setValue(this.f.HFServiceId.value);
    this.PurposeOtherComponentDialogRef.componentInstance.of.DialysisUnitId.setValue(this.f.HFServiceStationId.value);
    this.PurposeOtherComponentDialogRef.componentInstance.currentUserId = this.currentUserId;
    this.PurposeOtherComponentDialogRef.componentInstance.IsPurposeCompleted = this.f.HFServicePTHMPurposeCompleted.value;

    this.PurposeOtherComponentDialogRef.componentInstance.setModel();

    this.PurposeOtherComponentDialogRef.componentInstance.SaveEvent.subscribe(evt => {

      let completedPurpose = this.visitPurposeWithoutRenovationList.filter((item, index) => item.value == "Other");
      if (completedPurpose.length > 0) {
        completedPurpose[0].isFormSubmitted = true;
      }
      AppComponent.addAlert('Data Saved successfully!', 'success');
    });

    this.PurposeOtherComponentDialogRef.afterClosed().subscribe(result => {
    });

    AppComponent.HideLoader();

  }

  //** Open Purpose Dialogs */

  fetchRepairParts() {
    if (this.f.HFServiceVisitPurpose.value == "Repair" && this.f.HFServiceCategory.value == "Dialysis Machines") {
      this.dmapi.GetDMRepairIdFromHomeserviceId({ Id: this.f.HFServiceId.value }).subscribe(
        success => {

          this.dmapi
            .GetRepairPartsDetailsbydmRepairDetailid({ Id: success.id })
            .subscribe(list => {
              //console.log(list);
              for (let childObj in list) {
                var temp = list[childObj];
                var obj = <any>{};
                obj.DMRepairDetailsId = temp.dmRepairDetailsId;
                obj.equipmentid = temp.equipmentId;
                obj.quantity = temp.quantity;
                obj.PartCode = temp.equipmentcode;
                obj.PartDescription = temp.equipmentDesc;
                obj.PartCost = temp.itemCost;
                obj.createdBy = temp.createdBy;
                obj.createdByName = temp.createdByName;
                obj.createdOn = temp.createdOn;
                obj.modifiedBy = temp.modifiedBy;
                obj.modifiedOn = temp.modifiedOn;
                obj.LocationText = "0";
                obj.showPartDelete = false;
                // tslint:disable-next-line:triple-equals
                if (obj.createdBy == this.currentUserId) {
                  obj.showPartDelete = true;
                }
                this.PartTrans.push(obj);
              }

            });
        });
    }
    else if (this.f.HFServiceVisitPurpose.value == "Repair" && this.f.HFServiceCategory.value != "Dialysis Machines") {
      this.wpapi.GetRepairIdFromHomeserviceId({ Id: this.f.HFServiceId.value }).subscribe(
        success => {

          this.wpapi
            .GetRepairPartsDetailsbydmRepairDetailid({ Id: success.id })
            .subscribe(list => {
              for (let childObj in list) {
                var temp = list[childObj];
                var obj = <any>{};
                obj.RepairDetailsId = temp.RepairDetailsId;
                obj.equipmentid = temp.equipmentId;
                obj.quantity = temp.quantity;
                obj.PartCode = temp.equipmentCode;
                obj.PartDescription = temp.equipmentDesc;
                obj.PartCost = temp.itemCost;
                obj.createdBy = temp.createdBy;
                obj.createdByName = temp.createdByName;
                obj.createdOn = temp.repairPartDetailsEnteredOn;
                obj.modifiedBy = temp.modifiedBy;
                obj.modifiedOn = temp.modifiedOn;
                obj.LocationText = '0';
                obj.showPartDelete = false;
                // tslint:disable-next-line:triple-equals
                if (obj.createdBy == this.currentUserId) {
                  obj.showPartDelete = true;
                }
                this.PartTrans.push(obj);
              }

            });
        });
    }
  }

  fetchReferenceHFS_Purposes() {
    return new Promise((resolve, reject) => {
      const hfs = { HFServiceId: this.f.HFServiceId.value };
      this.homefieldApi.GetHomeFieldService_Master_Reference_Purpose_Select(hfs).subscribe(list => {
        this.otherHFSPurposeList = list;
        this.fetchReferenceHFS_OtherSections().then(() => {
          if (this.otherHFSSectionList.filter(x => !x.modifiedOn).length > 0 && this.f.HFServiceStatus.value != 'Completed')
            this.isAnyPanelEnable = true;
          this.afterAllPurposeLoadEvent().then(() => {
            resolve(true);
          });
        });

      }, error => {
        resolve(false);
      });
    });
  }


  fetchReferenceHFS_OtherSections() {
    return new Promise((resolve, reject) => {

      const hfs = { Id: this.f.HFServiceId.value };
      this.homefieldApi.GetHomeFieldService_OtherbyServiceID(hfs).subscribe(list => {

        // if (this.f.HFServiceStatus.value != 'Completed') {
        //   this.otherHFSSectionList = list.filter(x => x.isSection == true && !x.modifiedOn);
        // }
        // else
        this.otherHFSSectionList = list.filter(x => x.isSection == true);

        this.allOtherHFSList = _.sortBy(this.otherHFSSectionList.concat(this.otherHFSPurposeList), 'createdOn');
        console.log(this.allOtherHFSList);
        resolve(true);
      }, error => {
        resolve(false);
      });

    });
  }

  previousVehicleDetails() {
    let vehicleDetails = this.VehicleDetailsList.filter(x => x.hfServiceVehicleEndTime == null);
    if (vehicleDetails)
      return vehicleDetails[0];
    else
      return null;
  }

  getVehicleList() {
    return this.VehicleDetailsList.filter(x => x.isAddedFromMasterHFS != true).length > 0;
  }

  getReturnList() {
    return this.ReturnDetailsList.filter(x => x.isAddedFromMasterHFS != true).length > 0;
  }

  afterAllPurposeLoadEvent() {
    return new Promise(async (resolve, reject) => {

      if (this.otherHFSPurposeList.length > 0) {

        let opt = false;
        while (opt == false) {
          await this.fetchAllPurposeToDisplayReturnList().then((done: boolean) => {
            opt = done;
          }, error => {

          });
        }

        //  setTimeout(() => {

        //  },1000);

        resolve(true);
      }
      else {
        // this.isAnyPanelEnable = false;
        resolve(false);
      }

    });
  }


  async fetchAllPurposeToDisplayReturnList() {
    return new Promise((resolve, reject) => {

      if (this.panelChildrens.length == this.otherHFSPurposeList.length) {
        this.panelChildrens.forEach((panelChild) => {
          if (panelChild && !this.isAnyPanelEnable) {

            panelChild.fetchPurposeSectionDetails(false).then(() => {
              // this.isAnyPanelEnable = true;
              let panelEnabled = panelChild.isAdditionalAddArrival || panelChild.isAdditionalAddDeparture;

              if (panelEnabled)
                this.isAnyPanelEnable = panelEnabled;

              // console.log("Prep List");
              // console.log(panelChild.PrepDetailsList);
              // console.log(panelChild.techTestingTime);

              this.calculateChildTotalTechTime(panelChild);
            }, (err) => {
              AppComponent.addAlert("Error", err);
              AppComponent.HideLoader();
            });
          }
        });
        // console.log("Panel Enabled : " + this.isAnyPanelEnable);
        setTimeout(() => resolve(true), 1000);
      }
      else
        setTimeout(() => { resolve(false); }, 1000);
    });
  }

  onPanelSave(evt) {
    this.f.HFServicelastModifiedSection.setValue('VisitPurposePlusDetails');

    if (evt && evt.hfServicePTHMPurposeCompleted && evt.hfServicePTHMDepartureTime) {
      this.f.HFServicePTHMDepartureTime.setValue(evt.hfServicePTHMDepartureTime);
      this.executeHomeFieldServiceCreateUpdate('', false, false);
    }
    else
      this.executeHomeFieldServiceCreateUpdate('', false, true);
  }

  onOtherSectionSave(evt) {
    this.executeHomeFieldServiceCreateUpdate('', false, false);
  }

  calculateChildTotalTechTime(panelChild: PanelPurposeDetailComponent) {

    if (panelChild.PrepDetailsList
      && panelChild.PurposeDetailsList && panelChild.PurposeDetailsList.length > 0
    ) {

      let totalPrepTime = 0;
      panelChild.PrepDetailsList.forEach((item, newKey) => {
        totalPrepTime += parseFloat(item.prepTime) * (60 * 60 * 1000);
      });

      panelChild.totalPrepTime = totalPrepTime;

      let totalTestingTime = 0;

      totalTestingTime = parseFloat(panelChild.techTestingTime.toString()) * (60 * 60 * 1000);
      panelChild.totalTechTestingTime = this.msToTime(totalTestingTime);

      let totTechTime = 0;
      let totTravelTime = 0;


      panelChild.PurposeDetailsList.forEach((purpItem, purpKey) => {

        totTechTime += Date.parse(purpItem.hfServicePTHMDepartureTime) - Date.parse(purpItem.hfServicePTHMArrivalTime);

        panelChild.prepTimeSummary = this.msToTime(totalPrepTime);
        panelChild.totalTravellingTime = this.msToTime(totTravelTime);

        //panelChild.travellingTimeToPtHome = this.msToTime(Date.parse(purpItem.hfServicePTHMArrivalTime) - Date.parse(vItem.hfServiceVehicleStartTime));
        panelChild.travellingTimeSpentAtPtHome = this.msToTime(Date.parse(purpItem.hfServicePTHMDepartureTime) - Date.parse(purpItem.hfServicePTHMArrivalTime));
        //panelChild.travellingTimeToHospital = this.msToTime(Date.parse(rItem.hfServiceReturnVehicleEndTime) - Date.parse(purpItem.hfServicePTHMDepartureTime));

      });


      totTechTime = totTechTime + totalPrepTime + totalTestingTime;

      if (panelChild.f.HFServiceVehicleTechnologistId.value && panelChild.f.HFServiceVehicleTechnologistId.value.length > 0) {
        panelChild.totalTechTime = this.msToTime(totTechTime * panelChild.f.HFServiceVehicleTechnologistId.value.length);
        panelChild.totTechTimeDigits = totTechTime * panelChild.f.HFServiceVehicleTechnologistId.value.length;
      }
      else {
        panelChild.totalTechTime = this.msToTime(totTechTime);
        panelChild.totTechTimeDigits = totTechTime;
      }
    }
  }

  ISV_Address = '';
  ISV_City = '';
  ISV_State = '';
  ISV_ZipCode = '';
  ISV_Phone = '';
  ISV_Direction = '';
  ISV_DMEquipment = '';
  ISV_ROEquipment = '';
  ISV_Error = '';

  getISVStationDetails(){
    this.homefieldApi.GetHomeFieldService_ISVStationDetails({ Id: this.f.HFServiceStationId.value }).subscribe(
      success => {

        if (success) {
          this.ISV_Address = success.isvAddress;
          this.ISV_City = success.isvCity;
          this.ISV_State = success.isvProvince;
          this.ISV_ZipCode = success.isvPostalCode;
          this.ISV_Phone = success.isvPhoneNumber;
          this.ISV_Direction = success.isvDirections == '' ? 'N/A':success.isvDirections;
          this.ISV_DMEquipment = success.dmEquipmentId;
          this.ISV_ROEquipment = success.roEquipmentId;

          if(success.isActive) {
            if(this.ISV_DMEquipment == null && this.ISV_ROEquipment == null) {
              this.ISV_Error = 'DM & RO are not assigned to this Station';
            } else if(this.ISV_DMEquipment == null) {
              this.ISV_Error = 'DM is not assigned to this Station';
            } else if(this.ISV_ROEquipment == null) {
              this.ISV_Error = 'RO is not assigned to this Station';
            }
          }
        }
      },
      error => {
        AppComponent.HideLoader();
      });

  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CDApiService } from '../../API/cdapi.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../Core/ConfirmationDialog';
import { WaterProcessCustomValidators } from '../../Validator/WaterProcessCustomValidators';
import { ValidateUser } from '../../Validator/ValidateUser';

import { NotesComponent } from '../../Core/Shared/NotesComponent/NotesComponent';
import { AttachmentComponent } from '../../Core/Shared/AttachmentComponent/AttachmentComponent';
import { CanComponentDeactivate } from '../../Core/Shared/CanComponentDeactivate';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollHelper } from '../../Core/Helper/ScrollHelper';

import {
  Globals,
  actionType,
  NoteModuleName,
  attachmentFormModules
} from '../../globals';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../API/auth.service';
import { CommonApiService } from 'src/app/API/commonapi.service.';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'DailyDetailComponent',
  templateUrl: './DailyDetail.component.html'
})
export class DailyDetailComponent implements OnInit, CanComponentDeactivate {
  private scrollHelper: ScrollHelper = new ScrollHelper();
  dialogRef: MatDialogRef<ConfirmationDialog>;
  currentUserId = this.auth.GetUserId();
  @ViewChild(AttachmentComponent, { static: true })
  attachment: AttachmentComponent;
  @ViewChild(NotesComponent, { static: true })
  notes: NotesComponent;
  isValidFormSubmitted = null;
  ListURL = 'Concentrates/Daily/CDBicarbPowderSearch';
  status = '';
  loading = true;
  DialysisUnits = [];
  createdBy = this.currentUserId;
  modifiedBy = this.currentUserId;
  createdOn = new Date();
  modifiedOn = new Date();
  selectedDialysisUnitstext;
  selectedUsertext;
  selectedAcidContainerTxt;
  blnshowSave = false;
  blnshowDelete = false;
  ConfirmationUserId = 0;
  eventId = '[AutoNumber]';
  VendorList = [];
  VendorName = '';
  UnitList = [
    { value: '', text: 'Select' },
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' }
  ];
  SizeList = [
    { value: '', text: 'Select' },
    { value: '6', text: '6 Kg.' },
    { value: '10', text: '10 Kg.' }
  ];

  DailyForm = new UntypedFormGroup({
    Id: new UntypedFormControl(''),
    DailyDate: new UntypedFormControl('', Validators.required),
    selectedDialysisUnits: new UntypedFormControl('', Validators.required),
    LotNumber: new UntypedFormControl('', [Validators.required]),
    selectedVender: new UntypedFormControl('', Validators.required),
    SelectedNumberOfUnit: new UntypedFormControl('', [
      Validators.required
    ]),
    SelectedSize: new UntypedFormControl('', [
      Validators.required
    ])
  });

  // implementation of canDeactivate
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (
      (this.DailyForm.dirty ||
        this.notes.Note !== '' ||
        this.attachment.Queue().length > 0) &&
      this.isValidFormSubmitted === false
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true
      });
      this.dialogRef.componentInstance.confirmMessage =
        'You have unsaved changes! If you leave, your changes will be lost.';
      return this.dialogRef.afterClosed().pipe(
        map((res: any) => {
          // console.log('res', res);
          return res;
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private cdapi: CDApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private commonapi: CommonApiService
  ) {}

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked(): void {
    this.scrollHelper.doScroll();
  }

  ngOnInit() {
    AppComponent.ShowLoader();
    // this.uploader.autoUpload = true;
    this.SetPageRights();
    this.notes.showHours = true;
    // attachment changes
    this.attachment.DocId = this.f.Id.value;
    this.attachment.moduleName =
      attachmentFormModules[attachmentFormModules.CD_BicarbPowder];
    this.attachment.UploadBy = this.currentUserId;

    const test = this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.fillVendorList();
      this.fillDialysisUnit();
      this.f.Id.setValue(params['id'] || '0');
      const tempDate = new Date();
      this.f.DailyDate.setValue({
        date: {
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          day: tempDate.getDate()
        },
        jsdate: tempDate
      });
      if (this.f.Id.value !== '0') {
        this.setModel();
      } else {
        this.selectedUsertext = this.auth.GetUserName();

        this.auth.GetCurrentUserDivision().subscribe(division => {
          if (division.length === 1) {
            this.f.selectedDialysisUnits.setValue(division[0]);
          }
          else {
          }
        }, error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        });
        // this.fillEquipment(0);
      }
    });

    AppComponent.HideLoader();
  }

  get f() {
    return this.DailyForm.controls;
  }

  setModel() {
    AppComponent.ShowLoader();
    const DailyCreate = { Id: this.f.Id.value };
    console.log(this.cdapi);
    this.cdapi.GetCentralDeliveryBicarbPowderByID(DailyCreate).subscribe(
      success => {
         console.log(success);
        this.f.Id.setValue(success.centraldeliverybicarbpowderid);

        this.notes.Note = '';
        this.attachment.clearQueue();
        const tempDate = new Date(success.createdon);

        this.f.DailyDate.setValue({
          date: {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
          },
          jsdate: success.createdon
        });

        this.eventId = success.eventid;
        this.f.SelectedNumberOfUnit.setValue(success.numberofbags);
        this.f.SelectedSize.setValue(success.bagsize);
        this.f.selectedVender.setValue(success.vendorId);
        this.VendorName = success.vendorName;
        this.selectedUsertext = success.staffName;
        this.createdOn = success.createdOn;
        this.createdBy = success.createdBy;
        this.modifiedOn = success.modifiedOn;
        this.modifiedBy = success.modifiedBy;
        this.f.selectedDialysisUnits.setValue(success.divisionid.toString());
        this.selectedDialysisUnitstext = success.divisionName;
        this.f.LotNumber.setValue(success.lotnumber);
        // this.f.UserName.setValue(success.confirmationuser);

        const DailyObj = { Id: this.f.Id.value };
        this.cdapi
          .GetAttachemnetsByByCentralDeliveryBicarbPowderId(DailyObj)
          .subscribe(list => {
            this.attachment.PrevAttachments = list;
          });

        this.cdapi
          .GetNotesByCentralDeliveryBicarbPowderId(DailyObj)
          .subscribe(list => {
            this.notes.NoteList = list;
          });
        AppComponent.HideLoader();
      },
      error => {
        AppComponent.addAlert(error.message, 'error');
        AppComponent.HideLoader();
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {}

  fillDialysisUnit() {
    // this.cdapi.GetDialysisUnitDD().subscribe(list => {
      this.commonapi.FillDivision().subscribe(list => {
      this.DialysisUnits = list;
    });
  }

  fillVendorList() {
    this.cdapi.FillVendorList().subscribe(list => {
      this.VendorList = list;
    });
  }

  executeDailyCreateUpdate = function(Source) {
    AppComponent.loading = true;

    this.isValidFormSubmitted = false;
    if (this.DailyForm.invalid) {
      AppComponent.addAlert(Globals.FormErrorMessage, 'error');
      AppComponent.loading = false;

      this.scrollHelper.scrollToFirst('ng-invalid');
      AppComponent.ScrollDown();
      return;
    }
    this.isValidFormSubmitted = true;

    const DailyCreate = this.prepareCreateViewModel();
    console.log(DailyCreate);
    if (DailyCreate.centraldeliverybicarbpowderid === '0') {

      this.cdapi.CreateCentralDeliveryBicarbPowder(DailyCreate).subscribe(
        success => {
           console.log(success);
          // this.DMRepailDetail = success.dmRepailDetails;

          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');

          if (Source !== 'Notes') {
             this.router.navigate([this.ListURL]);
          } else {
            this.f.Id.setValue(success.centraldeliverybicarbpowderid);
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    } else {
      this.cdapi.UpdateCentralDeliveryBicarbPowder(DailyCreate).subscribe(
        success => {
          this.DMRepailDetail = success.dmRepailDetails;
          AppComponent.loading = false;
          AppComponent.addAlert('Data Saved successfully!', 'success');
          if (Source !== 'Notes') {
            this.router.navigate([this.ListURL]);
          } else {
            this.setModel();
          }
        },
        error => {
          AppComponent.addAlert(error.message, 'error');
          AppComponent.HideLoader();
        }
      );
    }
  };

  prepareCreateViewModel() {
    const DailyCreate = <any>{};

    DailyCreate.centraldeliverybicarbpowderid = this.f.Id.value;
    console.log(this.f.Id.value);
    DailyCreate.eventid = this.eventId;

    DailyCreate.createdon = new Date();
    DailyCreate.createdby = this.currentUserId;

    DailyCreate.modifiedon = new Date();
    DailyCreate.modifiedby = this.currentUserId;
    DailyCreate.technologist = this.currentUserId;
    // DailyCreate.status = this.f.FormDesignation.value;
    DailyCreate.divisionid = this.f.selectedDialysisUnits.value;
    DailyCreate.bagsize = this.f.SelectedSize.value;
    DailyCreate.lotnumber = this.f.LotNumber.value;
    DailyCreate.numberofbags = this.f.SelectedNumberOfUnit.value;
    DailyCreate.vendorId = this.f.selectedVender.value;
   // DailyCreate.volumeadded = this.f.selectedDrumNo.value;
    DailyCreate.note = this.notes.Note;
    // attachments
    DailyCreate.attachmentDetails = this.attachment.GenerateAttachmentList();

    return DailyCreate;
  }

  // DialysisUnitDropDownChanged() {
  //   this.FillAcidContainer(this.f.selectedDialysisUnits.value);
  //   // this.fillDialysisStation(this.f.selectedDialysisUnits.value);
  // }

  // FillAcidContainer(DialysisUnit) {
  //   const num = { Id: DialysisUnit };
  //   this.cdapi.FillAcidLevelOptions(num).subscribe(list => {
  //     this.AcidContainerList = list;
  //     // console.log(list);
  //   });
  // }

  DeleteEvent() {
    this.dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true
    });
    this.dialogRef.componentInstance.confirmMessage =
      'Are you sure you want to delete?';
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        AppComponent.ShowLoader();
        const DailyObj = { Id: this.f.Id.value };
        this.cdapi.DeleteCentralDeliveryBicarbPowderbyid(DailyObj).subscribe(
          list => {
            AppComponent.HideLoader();
            AppComponent.addAlert(
              'CD bicarb powder detail deleted successfully!',
              'success'
            );
            this.router.navigate([this.ListURL]);
          },
          error => {
            AppComponent.addAlert(error.message, 'error');
            AppComponent.HideLoader();
          }
        );
      }
      this.dialogRef = null;
    });
  }

  SetPageRights() {
    this.auth.GetPageRights('Daily Bicarb Tracking').subscribe(list => {
      if (this.f.Id.value !== '0') {
        this.blnshowSave = list[actionType.edit] === '1';
        this.blnshowDelete = list[actionType.delete] === '1';
      } else {
        this.blnshowSave = list[actionType.create] === '1';
        this.blnshowDelete = false;
      }
    });
  }
  AttachmentError(Error) {
    AppComponent.addAlert(Error, 'error');
  }
  // GreaterValueCheck(){
  //   this.f.hoursout.updateValueAndValidity();
  //   this.f.selectedOutsurfacedis.updateValueAndValidity();
  //   this.f.selectedouthydraulicdis.updateValueAndValidity();

  // }
}

import { Component,Input , Output, ViewChild } from '@angular/core';
import { WPApiService } from '../../../API/wpapi.service';
import { DMApiService } from '../../../API/dmapi.service';
import { TableHeadsService } from '../../../Core/tableheads.service';
import { IMyDateModel, IAngularMyDpOptions } from 'angular-mydatepicker';
import { Router } from '@angular/router';
import { PageHeaderComponent } from '../../../Core/pageheader.component';
import { AppComponent } from '../../../app.component';
import { Globals } from '../../../globals';
import { CommonApiService } from 'src/app/API/commonapi.service.';
import { AuthService } from 'src/app/API/auth.service';

@Component({
  selector: 'RepairDetailSearch',
  templateUrl: './RepairDetailSearch.component.html',

})
export class RepairDetailSearchComponent {

  constructor(private dmapi: DMApiService, private wpapi: WPApiService, private router: Router, private dataGridService: TableHeadsService, private commonapi: CommonApiService, private auth: AuthService) {
    this.page.pageNumber = 0;
    this.page.size = 25;
  }

  @ViewChild(PageHeaderComponent, { static: true }) pageheader: PageHeaderComponent;
  loading=true;
  DialysisUnits=[];
    Equipments=[];
  Users=[];
  Parts=[];
  Statuses=[];
  Patients=[];
  WPRepailDetail =[];
  selectedDivision = 'All Divisions';
  selectedEquipment = "";
  selectedPart = "";
  //selectedPatient = "";
  selectedStatus = "";
  selectedUser = "";
  RepairId="";
  blnDirtySearch = false;
  FromDate;
  ToDate;
  VendorOrderRef="";
  OutFromDate;
OutToDate;
tableHeaders;
page = new Page();
rows ;


workOrderRefNo="";
//todo
@Input() detailsType  ="Water Process"
@Input() headertext = "Water Process  Repair / Event / PM";
@Input() headerTextOther = "Other Event / Repair";
@Input() headerTextCD = 'Central Delivery  Repair/Event';
@Input() AddUrl = "/WaterProcess/Repairs/RepairDetail";
  currentPageNumber = 1;
  sortExpression = "default";
  sortDirection = "ASC";
  pageSize = 25;
  ShowSearch=false;

  searchText="Search Records"
defaultSort;

  ngOnInit() {
    //AppComponent.loading=true;
    this.auth.GetCurrentUserDivision().subscribe(division => {

      this.dataGridService.clearHeader();
      this.dataGridService.addHeader("#", "repairid", "4");
      this.dataGridService.addHeader("Repair ID", "repairid", "10");
      this.dataGridService.addHeader("Division", "divisionname", "10");
      this.dataGridService.addHeader("Tech", "createdbyName", "10");
      this.dataGridService.addHeader("Date IN", "timeStarted", "15");
      this.dataGridService.addHeader("Date Out", "timeFinished", "15");
      this.dataGridService.addHeader("Equipment", "equipmentCode", "25");
      this.dataGridService.addHeader("Status", "status", "20%");
      this.tableHeaders = this.dataGridService.setTableHeaders();
      this.defaultSort = this.dataGridService.setDefaultSort("default", true);
      //console.log(this.tableHeaders);
      // this.setPage({ offset: 0 });
      // this.executerepairSearch();
      if (division.length === 1)
        this.fillDialysisUnit(division[0]);
      else{
        this.fillDialysisUnit();
        this.fillEquipment();
      }
      this.fillParts();

      this.fillStatus();
      this.CheckDirtySearch();
    }, error => {
      AppComponent.addAlert(error.message, 'error');
      AppComponent.HideLoader();
    });
  }

  onSearchShowChange(value)
  {
    this.ShowSearch=value;
  }

  CheckFormDate(fdate, edate) {
    return this.commonapi.CompareDates(fdate, edate);
  }

changeSorting(column){
  if (column =="#")
  {
      return;
  }
  //console.log(this.tableHeaders);
    this.dataGridService.changeSorting(column, this.defaultSort, this.tableHeaders);
    this.defaultSort = this.dataGridService.getSort();
    this.sortDirection = this.dataGridService.getSortDirection();
    this.sortExpression = this.dataGridService.getSortExpression();
    this.currentPageNumber = 1;
    this.page.pageNumber = 1;
    this.executerepairSearch();

};

setSortIndicator = function (column) {
  //  console.log(this.dataGridService.setSortIndicator(column, this.defaultSort));
    return this.dataGridService.setSortIndicator(column, this.defaultSort);
};

  executerepairSearch()
{
  AppComponent.ShowLoader()
  this.pageheader.isSearchOpen=false;
  this.pageheader.searchText="Search Records";
  this.ShowSearch=false;
  this.searchText="Search Records";
  this.CheckDirtySearch();
  var  repairsearch = this.prepareSearch();


  this.wpapi.GetWaterProcessRepairsRecords(repairsearch).subscribe(pagedData  =>
    {this.page.totalElements= pagedData.totalRows;
      this.page.totalPages= pagedData.totalPages;
    //  console.log(pagedData);
    this.rows=pagedData.repailDetails;
    AppComponent.HideLoader()
  }
  ,
          error=>
          {
            AppComponent.addAlert(error.message,"error");
            AppComponent.HideLoader();
          }
  );
}

prepareSearch() {

  var repairsearch =<any>{};
  repairsearch.RepairId = this.RepairId;
  repairsearch.RepairType = this.detailsType;


  if (this.FromDate != undefined && this.FromDate !="" )
  {
    repairsearch.FromDate = Globals.GetAPIDate(this.FromDate.jsdate);
  }
  if (this.ToDate != undefined && this.ToDate !="" )
  {
    repairsearch.ToDate = Globals.GetAPIDate( this.ToDate.jsdate);
  }
  repairsearch.VendorOrderRef = this.VendorOrderRef;

  if (this.selectedDivision != undefined && this.selectedDivision !="" && this.selectedDivision != 'All Divisions' ) {
      repairsearch.Division = this.selectedDivision;
  }


  if (this.selectedEquipment != undefined && this.selectedEquipment !="") {
      repairsearch.EquipmentId = this.selectedEquipment;
  }

  if (this.selectedPart != undefined && this.selectedPart != "") {
      repairsearch.PartId = this.selectedPart;
  }

  if (this.selectedUser != undefined && this.selectedUser != "") {
      repairsearch.createdBy = this.selectedUser;
  }

  // if (this.selectedPatient != undefined && this.selectedPatient != "") {
  //     repairsearch.patientId = this.selectedPatient;
  // }

  if (this.selectedStatus != undefined && this.selectedStatus != "") {
      repairsearch.Status = this.selectedStatus;
  }
  if (this.OutFromDate != undefined && this.OutFromDate !="" )
  {
    repairsearch.inTimeOfEquipement = Globals.GetAPIDate( this.OutFromDate.jsdate);
  }
  if (this.OutToDate != undefined && this.OutToDate !="" )
  {
    repairsearch.equipmentDateOut = Globals.GetAPIDate( this.OutToDate.jsdate );
  }
  repairsearch.workOrderRefNo = this.workOrderRefNo;
  repairsearch.currentPageNumber = this.currentPageNumber;
  repairsearch.sortExpression = this.sortExpression;
  repairsearch.sortDirection = this.sortDirection;
  repairsearch.pageSize = this.pageSize;
  // console.log(repairsearch);
  return repairsearch;

}

  setPage(){
    if(this.currentPageNumber != this.page.pageNumber)
    {
    this.currentPageNumber=this.page.pageNumber;
    //this.page.pageNumber = pageInfo.offset;
    this.executerepairSearch();
    }
}
 _onRowClick(row) {

      this.router.navigate([this.AddUrl], { queryParams: { id: row} });
}


public myOptions: IAngularMyDpOptions = {
  // other options...
  dateFormat: 'dd/mm/yyyy',
  closeSelectorOnDateSelect:false,
  disableSince: { year:  new Date().getFullYear(), month:  new Date().getMonth() + 1, day:  new Date().getDate() + 1 }
};



onDateChanged(event: IMyDateModel): void {
  // date selected
}

  fillDialysisUnit(defaultValue = '') {
    // this.dmapi.GetDialysisUnitDD().subscribe(list => this.DialysisUnits=list);
    this.commonapi.FillDivision(true).subscribe(list => {
      this.DialysisUnits = list;

      if (defaultValue) {
        let defaultVal = list.filter(
          x => x.value == defaultValue
        );

        if (defaultVal && defaultVal.length > 0 && defaultVal[0]) {
          this.selectedDivision = defaultVal[0].text;
          this.fillEquipment();
          this.executerepairSearch();
        }
        else
          this.executerepairSearch();
      }
      else
        this.executerepairSearch();
    });
  }

  fillEquipment() {

    let divisionId;
    let division = this.DialysisUnits.filter(
      x => x.text == this.selectedDivision
    );

    if (division && division.length > 0 && division[0]) {
      divisionId = division[0].value;
    }

    const value = {
      DivisionId: divisionId,
      CategoryName: this.detailsType,
      Type: 'EQUIPMENT'
    };
    this.wpapi.GetEquipmentByCategoryDD(value).subscribe(
      list => (this.Equipments = list)
    );

  }



  fillParts()
  {
    const value = {
      DivisionId: this.selectedDivision,
      CategoryName: this.detailsType,
      Type: 'PARTS'
    };
    this.wpapi.GetPartsDDByCategory(value).subscribe(list => this.Parts=list);


  }

  fillStatus()
  {
    //this.repairapi.GetStatusDD().subscribe(list => this.Statuses=list);
    this.Statuses= [
      { value: "", text: "Select" },
      { value: "In Progress", text: "In Progress" },
      { value: "Completed", text: "Completed" }
    ];

  }



  executerepairsearchClear()
  {
    this.selectedDivision = 'All Divisions';
    this.selectedEquipment = "";
    this.selectedPart = "";

    this.selectedStatus = "";
    this.selectedUser = "";
    this.RepairId = "";
    this.FromDate = "";
    this.ToDate = "";
    this.VendorOrderRef = "";
    this.OutFromDate = "";
    this.OutToDate = "";
    // this.executerepairSearch();
    this.fillDialysisUnit();
    this.fillEquipment();

  }

  CheckDirtySearch()
  {
    this.blnDirtySearch= false;

    if(
      (this.selectedDivision != "" && this.selectedDivision !== 'All Divisions')
    || this.selectedEquipment  != ""
    || this.selectedPart != ""
    //|| this.selectedPatient != ""
    || this.selectedStatus != ""
    || this.selectedUser != ""
    || this.RepairId != ""
    || (this.FromDate != null && this.FromDate != "")
    || (this.ToDate != null && this.ToDate != "")
    || this.VendorOrderRef != ""
    || (this.OutFromDate != null && this.OutFromDate != "")
    || (this.OutToDate != null && this.OutToDate != ""))
    {
      this.blnDirtySearch = true;

    }

    return this.blnDirtySearch;




  }

  DialysisUnitDropDownChanged() {
    this.selectedEquipment = "";
    this.fillEquipment();
    setTimeout(() => this.executerepairSearch());
  }

}

export class Page {
  //The number of elements in the page
  size: number = 0;
  //The total number of elements
  totalElements: number = 0;
  //The total number of pages
  totalPages: number = 0;
  //The current page number
  pageNumber: number = 0;
}

<style>
    .mrmDescription {
        text-align: left;
        white-space: normal;
    }

    .mrmTableHeader {
        font-size: 17px;
    }

    .mrmTableinnerHeader {
        background: #ebf1dd;
        font-size: 16px;
        font-weight: 600;
        border: #ebf1dd 1px solid;
    }

    i {
        cursor: pointer;
        font-size: 24px;
    }

    .thead-light-second {

        border: 1px solid black;
    }


</style>

    <div class="col-md-12 noPaddingAll">
        <div class="text-left col-md-6 noPaddingAll">
          <h4>
            <b>
                NOTE SUMMARY
              <!-- <button _ngcontent-c10="" class="btn btn-primary  no-print btn-label-left center-block btn-xs"
              style="display: inline-block;" type="button"
              (click)="openNotes()">
              <span _ngcontent-c10=""><i _ngcontent-c10="" class="fa fa-plus" style="font-size:inherit !important"></i></span>
              </button> -->
          </b></h4>

        </div>
        <div class="text-right col-md-6 noPaddingAll">
            <button class="btn btn-primary  no-print btn-label-left center-block"
            style="display: inline-block;" type="button"
            (click)="openNotes()">
            <span><i class="fa fa-plus" style="font-size:inherit !important"></i> Add Notes & Parts</span>
            </button>
        </div>
    </div>

<!-- <div class="text-left"><h4><b>NOTE SUMMARY</b></h4></div> -->
<div>
    <table class="table" style="width:100%;table-layout: fixed;">
        <thead class="mrmTableinnerHeader thead-light-second">
            <tr>
                <th class="thead-light-second" style="width:10%">Sec</th>
                <th class="thead-light-second" style="width:10%">Date</th>
                <th class="thead-light-second" style="width:10%">Tech</th>
                <th *ngIf="notesDetails.showHours!=false" class="thead-light-second" style="width:10%">Hours</th>
                <th class="thead-light-second" style="width:60%;word-wrap: break-word;margin-bottom:0px;">Note</th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr *ngFor="let item of NoteList ;let index=index"
            [ngClass]="{'afterComplete': item.isAfterCompletionAdd}"
            > -->
                <tr *ngFor="let item of NoteList ;let index=index">
                <td>{{ item.questionId ? item.questionId : 'N/A'}}</td>
                <td>{{ item.createdOn | date:'dd/MM/yyyy HH:mm' }}</td>
                <td>{{item.createdByName}}</td>
                <td *ngIf="notesDetails.showHours!=false">
                    <div *ngIf="item.hours">
                        {{item.hours | number:'2.2'}}
                    </div>
                </td>
                <td style="width:100%;word-wrap: break-word;margin-bottom:0px;white-space:pre-wrap;text-align: left">{{ item.description }}</td>
            </tr>
        </tbody>


    </table>
</div>

<form
    [formGroup]="DMHourForm"
    (ngSubmit)="executeDMHourCreateUpdate('')"
    novalidate
>

    <!-- <RepairPart></RepairPart> -->
    <section class="content-header">
        <h1>
          DM Hours Update
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <!-- /.box -->
            <div class="box  box-primary">

                <PageHeader
                    [showNote]="false"
                    [showAddNew]="false"
                    [showSearch]="false"
                    [mainlisturl]="ListURL"
                    [showSave]="blnshowSave"
                    (SaveEvent)="executeDMHourCreateUpdate('')"
                    [showDelete]="blnshowDelete"
                    (DeleteEvent)="DeleteEvent()"
                    #pageheader
                ></PageHeader>
                <div class="box">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-sm-12 box-row hidden">
                                <label class="col-sm-2 control-label">DMHoursId</label>

                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        readonly
                                        class="form-control"
                                        name="DMHoursId"
                                        [value]="f.DMHoursId"
                                        formControlName="DMHoursId"
                                    >
                                </div>
                            </div>

                            <div class="box ibox box-info">
                                <div class="col-sm-12 box-row"></div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="box ibox box-info">

                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-sm-12 box-row">
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">
                                                    Event ID:
                                                </label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">{{EventCode}}</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="col-sm-5 control-label">Form Date:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy HH:mm'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                    <label class="col-sm-5 control-label">
                                                        Created By:
                                                        </label>
                                                        <div class="col-sm-7">
                                                            <label class="readOnlyText">{{selectedUsertext}}</label>
                                                        </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>

                                        <!-- <div
                                            class="col-sm-12 box-row"
                                            *ngIf="f.DMHoursId.value > 0"
                                        >
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">

                                                    Form Date:
                                                </label>

                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'dd/MM/yyyy'}}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">Form Time:</label>
                                                <div class="col-sm-7">
                                                    <label class="readOnlyText">
                                                        {{f.CreatedDate.value.jsdate | date:'HH:mm'}}
                                                    </label>
                                                </div>

                                            </div>
                                        </div> -->
                                        <div class="clearfix"></div>
                                        <div class="col-sm-12 box-row" style="margin-top:20px;">
                                            <div class="col-sm-6">
                                                <label class="col-sm-5 control-label">Division:
                                                </label>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.DMHoursId.value > 0"
                                                >
                                                    <label class="readOnlyText">{{selectedDialysisUnitstext}}</label>
                                                </div>
                                                <div
                                                    class="col-sm-7"
                                                    *ngIf="f.DMHoursId.value == 0"
                                                >
                                                    <!--  -->
                                                    <select
                                                        class="form-control mdInput"
                                                        formControlName="selectedDialysisUnits"
                                                        name="selectedDialysisUnits" (change)="DialysisUnitDropDownChanged()"
                                                    >
                                                        <option
                                                            value="{{item.value}}"
                                                            *ngFor="let item of DialysisUnits"
                                                        >
                                                            {{item.text}}
                                                        </option>
                                                    </select>

                                                    <span
                                                        *ngIf="f.selectedDialysisUnits.errors?.required && (f.selectedDialysisUnits.dirty || f.selectedDialysisUnits.touched ||  isValidFormSubmitted==false)"
                                                        [ngClass]="'error'"
                                                    >
                                                        Required!
                                                    </span>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="col-sm-6 control-label">Event Date:</label>
                                                <div class="col-sm-6" *ngIf="f.DMHoursId.value > 0">
                                                        <label class="readOnlyText"> {{hourStartDateLabel | date:'dd/MM/yyyy'}}</label>
                                                </div>
                                                <div class="col-sm-6"  *ngIf="f.DMHoursId.value == 0">
                                                        <input (focus)="rStartDate.toggleCalendar()" (click)="rStartDate.openCalendar()" placeholder="Select a date"
                                                        class="form-control mdInput" style="float:none"
                                                        angular-mydatepicker name="HourStartDate"
                                                        formControlName="HourStartDate" [options]="myOptions"
                                                        #rStartDate="angular-mydatepicker" (dateChanged)="onDateChanged($event)"
                                                        required/>
                                                        <span  *ngIf="f.HourStartDate.errors?.required && (f.HourStartDate.dirty || f.HourStartDate.touched ||  isValidFormSubmitted==false)" [ngClass] = "'error'">
                                                                Required!
                                                        </span>
                                                </div>
                                        </div>
                                        </div>
                                            <div class="col-sm-12 box-row">
                                            <div class="col-sm-6">
                                                    <label class="col-sm-5 control-label">Dialysis Machine:</label>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.DMHoursId.value > 0"
                                                    >
                                                        <label class="readOnlyText">{{selectedEquipmenttext}}</label>
                                                    </div>
                                                    <div
                                                        class="col-sm-7"
                                                        *ngIf="f.DMHoursId.value == 0"
                                                    >

                                                        <select
                                                            class="form-control mdInput"
                                                            formControlName="selectedEquipment"
                                                            name="selectedEquipment" (change)="onEquipmentChanged()"
                                                        >
                                                            <option
                                                                value="{{item.value}}"
                                                                *ngFor="let item of Equipments"
                                                            >
                                                                {{item.text}}
                                                            </option>
                                                        </select>

                                                        <span
                                                            *ngIf="f.selectedEquipment.errors?.required && (f.selectedEquipment.dirty || f.selectedEquipment.touched ||  isValidFormSubmitted==false)"
                                                            [ngClass]="'error'"
                                                        >
                                                            Required!
                                                        </span>
                                                        <span  *ngIf="f.selectedEquipment.errors?.RepairStatus" [ngClass] = "'error'">
                                                            You cannot start a new DM Hours Update, while there is an open Repair still existing in the system.
                                                          </span>

                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                        <label class="col-sm-6 control-label">Dialysis Machine Hours:
                                                        </label>
                                                        <div
                                                            class="col-sm-6"
                                                            *ngIf="f.DMHoursId.value > 0"
                                                        >
                                                            <label class="readOnlyText">{{f.Hours.value}}</label>

                                                        </div>
                                                        <div
                                                            class="col-sm-6"
                                                            *ngIf="f.DMHoursId.value == 0"
                                                        >

                                                            <input
                                                                class="form-control smInput"
                                                                formControlName="Hours"
                                                                name="Hours" maxlength="5" (keypress)="numberOnly($event)"
                                                            >
                                                            <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours!=0 && selectedEquipmentHours!=null">({{selectedEquipmentHours}})</label>
                                                            <label class="readOnlyTextForHours" *ngIf="selectedEquipmentHours==0 && f.selectedEquipment.value">(N/A)</label>
                                                        </div>
                                                    </div>
                                            </div>

                                        <div class="clearfix"></div>

                                        <div class="col-sm-12 box-row" *ngIf="f.DMHoursId.value == 0">
                                          <div class="col-sm-offset-6 col-sm-6">
                                            <div class="col-sm-6"></div>
                                            <div class="col-sm-6">
                                                    <span
                                                    *ngIf="f.Hours.errors?.required && (f.Hours.dirty || f.Hours.touched ||  isValidFormSubmitted==false)"
                                                    [ngClass]="'error'"
                                                >
                                                    Required!
                                                </span>
                                                <span *ngIf="f.Hours.errors?.pattern" [ngClass]="'error'">Invalid number</span>
                                              <span *ngIf="f.Hours.errors?.GreaterHoursOut"
                                              [ngClass]="'error'">
                                              Hrs IN => previous Hrs Out 
                                              </span>
                                              <span *ngIf="check4000HoursValidation()"
                                              [ngClass]="'error'">
                                              New Hours >= 4000 from previous Hours Out.
                                              </span>
                                            </div>

                                          </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <attachment
                                (RaiseError)="AttachmentError($event)"
                                #attachment
                            >

                            </attachment>
                        </div>
                    </div>
                    <PageHeader
                        [showNote]="false"
                        [showAddNew]="false"
                        [showSearch]="false"
                        [mainlisturl]="ListURL"
                        [showSave]="blnshowSave"
                        (SaveEvent)="executeDMHourCreateUpdate('')"
                        [showDelete]="blnshowDelete"
                        (DeleteEvent)="DeleteEvent()"
                        #pageheader
                    ></PageHeader>
                </div>
            </div>

    </section>
</form>

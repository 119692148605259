<form [formGroup]="organizationUnitForm" (ngSubmit)="executeOrganizationUnitCreateUpdate()" novalidate  class="OrganizationForm">

    <section class="content-header">
        <h1>
            Organizational Unit
            <!-- <sup class="beta">[beta]</sup> -->

        </h1>

    </section>
    <section class="content">

        <div class="row">
            <div class="box  box-primary">

                <PageHeader  [showNote]="false" [showMainListing]="false" [showAddNew]="false"
                    [showSearch]="false" [showSave]="true" (SaveEvent)="executeOrganizationUnitCreateUpdate()"
                    [showDelete]="false" #pageheader></PageHeader>

                <div class="clearfix"></div>
                <div class="box">
                    <div class="box-body">

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <!-- <h3 class="box-title col-lg-6">
                                        </h3> -->
                                    <h3 class="box-title">Organizational Unit</h3>
                                </div>
                                </h4>
                            </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Unit
                                            Name:</label>
                                        <div class='col-sm-7'>

                                            <input name='unitName' maxlength='80' required class='form-control'
                                                formControlName='unitName'>
                                            <span
                                                *ngIf='f.unitName.errors?.required && (f.unitName.dirty || f.unitName.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Time Zone:</label>

                                        <div class='col-sm-7'>
                                            <select name='TimeZone' maxlength='dropdown' class='form-control'
                                                formControlName='TimeZone' required>
                                                <option value="">Select</option>
                                                <option value="{{item.value}}" *ngFor="let item of TimeZonesList">
                                                    {{item.text}}</option>
                                            </select>
                                            <span
                                                *ngIf='f.TimeZone.errors?.required && (f.TimeZone.dirty || f.TimeZone.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                            <h4 class="panel-title">
                            <div class="box-header with-border" style="padding:0px;">
                                <h3 class="box-title">Organization Options</h3>
                            </div>
                            </h4>
                            </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6">
                                        <label class='col-sm-5 control-label'><span
                                            class='text-red inputrequired'>*</span>Tech Hourly Rate</label>

                                    <div class='col-sm-4'>
                                        <textarea name='techHourlyRate' required class="form-textarea lgInput" autosize
                                            formControlName='techHourlyRate'
                                            (focusout)="transformhourlyCost()"
                                            (blur)="transformhourlyCost()" [(value)]="formattedHourlyRate" (keypress)="numberOnly($event)"></textarea>
                                        <!-- <span
                                            *ngIf='f.techHourlyRate.errors?.required && (f.techHourlyRate.dirty || f.techHourlyRate.touched)'
                                            [ngClass]="'error'"> Required! </span> -->
                                    </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>ReCaptcha Required?:</label>
                                                <div class='col-sm-7'>
                                                    <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                        (onChangeState)="onChange($event)"
                                                        formControlName='IsCaptchaRequired' name='IsCaptchaRequired'>
                                                    </bSwitch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Login Using:</label>
                                                <div class='col-sm-7'>
                                                    <bSwitch [switch-off-text]="offTextLogin"
                                                        [switch-on-text]="onTextLogin" [switch-on-color]="onColor"
                                                        [switch-off-color]="offColor" (onChangeState)="onChange($event)"
                                                        formControlName='IsPassword' name='IsPassword'>
                                                    </bSwitch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>Two
                                                    Step Authenticaltion?:</label>
                                                <div class='col-sm-7'>
                                                    <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                        (onChangeState)="onChange($event)"
                                                        formControlName='IsTwoStepAuth' name='IsTwoStepAuth'>
                                                    </bSwitch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-body">
                                                <div class="col-sm-12 box-row">
                                                    <label class='col-sm-7 control-label'><span
                                                            class='text-red inputrequired'>*</span>Home Program Division Required ?:</label>
                                                    <div class='col-sm-5'>
                                                        <bSwitch [switch-off-text]="offText" [switch-on-text]="onText"
                                                            [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                            (onChangeState)="onChange($event)"
                                                            formControlName='isHomeProgram' name='isHomeProgram'>
                                                        </bSwitch>
                                                    </div>
                                                </div>
                                        </div>

                                    <div class="box-body">
                                            <div class="col-sm-12 box-row">
                                                <label class='col-sm-5 control-label'><span
                                                        class='text-red inputrequired'>*</span>User Efficiency Display:</label>
                                                <div class='col-sm-5'>
                                                    <bSwitch [switch-off-text]="offDisplay" [switch-on-text]="onDisplay"
                                                        [switch-on-color]="onColor" [switch-off-color]="offColor"
                                                        (onChangeState)="onChange($event)"
                                                        formControlName='isEfficiencyDisplay' name='isEfficiencyDisplay'>
                                                    </bSwitch>
                                                </div>
                                            </div>
                                    </div>






                                    </div>
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Logo:</label>
                                        <div class='col-sm-3'>
                                            <input type="file" accept="image/x-png,image/gif,image/jpeg"
                                                class="FileInput hidden" id="ng2Fileuploader"
                                                (change)="onFileSelected($event)" #fileInput />
                                            <button (click)="fileInput.click()" class="btn btn-success pull-left"
                                                type="button"><i class="glyphicon glyphicon-upload"></i>&nbsp;
                                                Upload</button><br><br>
                                            <button class="btn btn-danger" *ngIf="imgURL" (click)="clearImage()"
                                                type="button"><span class="glyphicon glyphicon-trash"></span>
                                                &nbsp; Clear &nbsp; </button>
                                        </div>
                                        <div class='col-sm-4'>
                                            <img [src]="imgURL" *ngIf="imgURL">
                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12 box-row">
                                    <div class="col-sm-6">
                                        <label class='col-sm-5 control-label'><span
                                            class='text-red inputrequired'>*</span>Technical Daily Hours:</label>

                                    <div class='col-sm-4'>
                                        <textarea name='techDailyHours' required class="form-textarea lgInput" autosize
                                            formControlName='techDailyHours'
                                            (focusout)="transformtechDailyHours()"
                                            (blur)="transformtechDailyHours()" (keypress)="numberOnly($event)"></textarea>
                                    </div>
                                    <span
                                    *ngIf='f.techDailyHours.errors?.required && (f.techDailyHours.dirty || f.techDailyHours.touched)'
                                    [ngClass]="'error'"> Required! </span>
                                    </div>
                                </div>

                                    <div class="col-sm-12 box-row" style="padding-right: 0px">
                                        <label class='col-sm-2 control-label' style="min-width: 21.25%;"><span
                                                class='text-red inputrequired'>*</span>Menu Permission:</label>
                                        <div class='col-sm-9' style="padding: 0px;width:78%">
                                            <mat-button-toggle-group multiple [value]="AllSelected">
                                                <mat-button-toggle value="all" (change)="SetAllButtons($event)">ALL
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>

                                            <mat-button-toggle-group multiple formControlName="menuIds"
                                                name="menuIds" style="margin-left:1%">
                                                <mat-button-toggle *ngFor="let item of menuPermissionList" (change)="CheckUnCheckMenu(item)"
                                                    value="{{item.dbName}}">
                                                    {{item.desc}}
                                                </mat-button-toggle>
                                            </mat-button-toggle-group>

                                            <span
                                                *ngIf='f.menuIds.errors?.required && (f.menuIds.dirty || f.menuIds.touched)'
                                                [ngClass]="'error'"> Required! </span>

                                            <span *ngIf='f.menuIds.errors?.pattern' [ngClass]="'error'">Invalid
                                                value</span>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title">Address Information</h3>
                                </div>
                                </h4>
                            </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Address
                                            1:</label>

                                        <div class='col-sm-7'>
                                            <textarea name='address1' required class="form-textarea lgInput" autosize
                                                formControlName='address1'></textarea>
                                            <span
                                                *ngIf='f.address1.errors?.required && (f.address1.dirty || f.address1.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Address 2:</label>

                                        <div class='col-sm-7'>
                                            <textarea name='address2' class="form-textarea lgInput" autosize
                                                formControlName='address2'></textarea>

                                        </div>
                                    </div>

                                </div>

                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>City:</label>

                                        <div class='col-sm-7'>
                                            <input name='city' maxlength='50' required class='form-control'
                                                formControlName='city'>
                                            <span *ngIf='f.city.errors?.required && (f.city.dirty || f.city.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Country:</label>

                                        <div class='col-sm-7'>
                                            <select name='country' maxlength='dropdown' class='form-control'
                                                formControlName='country' required
                                                (change)="CountryStateDropDownChanged()">
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of CountryList">
                                                    {{item.text}}</option>
                                            </select>
                                            <span
                                                *ngIf='f.country.errors?.required && (f.country.dirty || f.country.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Postal/Zip
                                            Code:</label>

                                        <div class='col-sm-7'>
                                            <input name='zipcode' maxlength='30' required class='form-control'
                                                formControlName='zipcode'>
                                            <span
                                                *ngIf='f.zipcode.errors?.required && (f.zipcode.dirty || f.zipcode.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Province/State:</label>

                                        <div class='col-sm-7'>
                                            <select name='state' required maxlength='dropdown' class='form-control'
                                                formControlName='state'>
                                                <option value="">Select</option>
                                                <option value="{{item.text}}" *ngFor="let item of StateList">
                                                    {{item.text}}</option>

                                            </select>
                                            <span *ngIf='f.state.errors?.required && (f.state.dirty || f.state.touched)'
                                                [ngClass]="'error'"> Required! </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title">Contact Information</h3>
                                </div>
                                </h4>
                            </div>
                        </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'><span
                                                class='text-red inputrequired'>*</span>Phone:</label>

                                        <div class='col-sm-7'>
                                            <input name='phone' (blur)="formatPhone('phone')" placeholder="(###) ###-####"
                                            (keypress)="numberOnly($event)" class="form-control"
                                                required formControlName='phone'>

                                            <span *ngIf='f.phone.errors?.required && (f.phone.dirty || f.phone.touched)'
                                                [ngClass]="'error'"> Required! </span>

                                            <span *ngIf='f.phone.errors?.pattern' [ngClass]="'error'"
                                                style="margin-left: 2px;">Invalid value</span>

                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Phone Extension:</label>

                                        <div class='col-sm-7'>
                                            <input name='extension' (blur)="formatPhone('extension')" (keypress)="numberOnly($event)" maxlength='5'
                                                class='form-control' formControlName='extension'>
                                        </div>
                                    </div>

                                </div>

                                <div class="clearfix"></div>

                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Toll Free Number:</label>

                                        <div class='col-sm-7'>
                                            <input name='tollfreenumber' (blur)="formatPhone('tollfreenumber')" placeholder="(###) ###-####"
                                                maxlength='30' class='form-control' formControlName='tollfreenumber'>
                                            <span *ngIf='f.tollfreenumber.errors?.pattern' [ngClass]="'error'"
                                                style="margin-left: 2px;">Invalid value</span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Fax:</label>

                                        <div class='col-sm-7'>
                                            <input name='fax' (blur)="formatPhone('fax')" maxlength='30' placeholder="(###) ###-####"
                                                class='form-control' formControlName='fax'>
                                            <span *ngIf='f.fax.errors?.pattern' [ngClass]="'error'"
                                                style="margin-left: 2px;">Invalid value</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Toll Free Fax:</label>

                                        <div class='col-sm-7'>
                                            <input name='tollfreefax' (blur)="formatPhone('tollfreefax')" maxlength='30' placeholder="(###) ###-####"
                                                class='form-control' formControlName='tollfreefax'>
                                            <span *ngIf='f.tollfreefax.errors?.pattern' [ngClass]="'error'"
                                                style="margin-left: 2px;">Invalid value</span>
                                        </div>
                                    </div>

                                    <div class='col-sm-6'>
                                        <label class='col-sm-5 control-label'>Other Number:</label>

                                        <div class='col-sm-7'>
                                            <input name='othernumber' (blur)="formatPhone('othernumber')" maxlength='30' placeholder="(###) ###-####"
                                                class='form-control' formControlName='othernumber'>
                                                <span *ngIf='f.othernumber.errors?.pattern' [ngClass]="'error'"
                                                style="margin-left: 2px;">Invalid value</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="box ibox box-info">
                            <div class="panel panel-default">
                                <div class="panel-heading" role="tab" id="dmReference">
                                <h4 class="panel-title">
                                <div class="box-header with-border" style="padding:0px;">
                                    <h3 class="box-title">Notes</h3>
                                </div>
                                </h4>
                                </div>
                            </div>
                            <div class="box-body">
                                <div class="col-sm-12 box-row">
                                    <div class='col-sm-12'>
                                        <label class='control-label col-md-2' style="width:20.5%">Permanent
                                            Notes:</label>
                                        <div style="width:79.5%" class="col-md-9">
                                            <textarea name='permanentnotes' rows='5' class="form-textarea"
                                                formControlName='permanentnotes'></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PageHeader   [showNote]="false" [showMainListing]="false" [showAddNew]="false"
                    [showSearch]="false" [showSave]="true" (SaveEvent)="executeOrganizationUnitCreateUpdate()"
                    [showDelete]="false" #pageheader></PageHeader>

            </div>
        </div>
    </section>

</form>
